import { useMemo } from 'react';
import { IListItem } from '../types';

export const useStringsAsList = (strings?: string[]): IListItem[] => {
	return useMemo<IListItem[]>(
		() =>
			strings?.map((str) => {
				return {
					label: str,
					value: str
				} as IListItem;
			}) || [],
		[strings]
	);
};
