import { object, string, number, lazy } from 'yup';
import { alphaNumericRegex } from '../../constants/regexes';
import { emptyStringToNull } from '../../tools/yupHelpers';
import { SpecimenForm } from './specimens.types';
import * as api from './specimens.api';

export const specimenSchema = lazy((specimen: SpecimenForm) => {
	const params = specimen.dimensions ? Object.keys(specimen.dimensions) : [];

	return object().shape({
		uuid: string().nullable().optional(),
		specimenId: string()
			.required('Specimen ID is a required field')
			.max(30)
			.matches(alphaNumericRegex, 'Is not in correct format')
			.test('checkSpecimenIdUniqness', 'Specimen ID is used', function (value, form) {
				if (!value) return true;
				return api.checkId(value, form?.parent?.uuid);
			}),
		specimenTypeUuid: string().nullable().required('Specimen type is a required field'),
		specimenMaterial: string().nullable(),
		testId: string().nullable().optional(),
		batchId: string()
			.nullable()
			.max(30)
			.matches(alphaNumericRegex, 'Is not in correct format')
			.test('checkBatchId', 'Batch ID is a required field', function (value, form) {
				if (form?.parent?.quantity > 1 && !value) {
					return false;
				}
				return true;
			})
			.test('checkSpecimenBatchIdUniqness', 'Batch ID is used', function (value, form) {
				if (!value) return true;
				return api.checkBatchId(value, form?.parent?.uuid);
			}),
		quantity: number()
			.transform(emptyStringToNull)
			.nullable()
			.integer()
			.min(1, 'Minimum value of quantity is 1')
			.max(100, 'Maximum value of quantity is 100')
			.required('Quantity is a required field'),
		dimensions: params.length
			? object()
					.shape(
						params.reduce(
							(paramSchemas, paramName) => ({
								...paramSchemas,
								[paramName]: number().transform(emptyStringToNull).min(0).required(`${paramName} is a required field`)
							}),
							{}
						)
					)
					.required()
			: object()
	});
});
