import { Button, Form, InputText, Modal } from '@tactun/ui';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { GroupsFormType } from '../group.types';
import { groupSchema } from '../group.schemas';
import styles from './GroupCreateEditModal.module.scss';

export interface GroupCreateEditModalProps {
	modalId: string;
	group: GroupsFormType;
	onSave: (data: GroupsFormType) => void;
}

const createEditGroupFormId = 'createEditGroupForm';

const GroupCreateEditModal = ({ modalId, group, onSave }: GroupCreateEditModalProps) => {
	const { t } = useTranslation('groups');
	const {
		reset,
		register,
		formState: { errors },
		handleSubmit
	} = useForm({
		defaultValues: group,
		mode: 'onBlur',
		resolver: yupResolver(groupSchema)
	});

	const handleCloseModal = useCallback(() => Modal.hide(modalId), [modalId]);

	useEffect(() => {
		if (group) {
			reset(group);
		}
	}, [group, reset]);

	return (
		<Modal id={modalId} onClose={handleCloseModal}>
			<Modal.Header>{group.name ? t('Rename Group') : t('Create Group')}</Modal.Header>
			<Modal.Content>
				<Form className={styles.formContainer} id={createEditGroupFormId} onSubmit={handleSubmit(onSave)}>
					<input type="hidden" {...register('id')} />
					<input type="hidden" {...register('type')} />
					<input type="hidden" {...register('itemIds')} />
					<InputText
						className={styles.inputContainer}
						label={t('Group Name')}
						error={errors.name?.message}
						{...register('name')}
					/>
				</Form>
			</Modal.Content>
			<Modal.Footer>
				<Button onClick={handleCloseModal} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button type="submit" form={createEditGroupFormId} color="secondary" variant="contained">
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default GroupCreateEditModal;
