import { memo } from 'react';
import cx from 'classnames';
import styles from './Icon.module.scss';

export interface IIconProps {
	className?: string;
	activeClassName?: string;
	id?: string;
	name: string;
	prefix?: string;
	style?: React.CSSProperties;
	size?: number | string;
	tabIndex?: number;
	color?: string;
	hoverColor?: string;
	activeColor?: string;
	isSpin?: boolean;
	onClick?(event: React.MouseEvent): void;
	disabled?: boolean;
	'data-testid'?: string;
	'data-pr-tooltip'?: string;
}

const Icon: React.FC<IIconProps> = (props) => {
	const {
		className,
		isSpin,
		prefix = 't-icon',
		name,
		style,
		color = 'inherit',
		hoverColor,
		onClick,
		disabled = false,
		size = 20,
		...rest
	} = props;
	const activeColor = disabled ? '#fff' : hoverColor ?? color;
	return (
		<i
			onClick={onClick}
			role="button"
			className={cx(
				prefix,
				`${prefix}-${name}`,
				styles.icon,
				{ [styles.spin as string]: isSpin, [styles.clickable]: onClick, [styles.disabled]: disabled },
				className
			)}
			style={
				{
					fontSize: size,
					width: size,
					'--color': color,
					'--hover-color': activeColor,
					...style
				} as React.CSSProperties
			}
			{...rest}
		/>
	);
};

export default Object.assign(memo(Icon), { displayName: 'Icon' });
