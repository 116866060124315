import { CalculationRequest, CalculationResponse, calculationService } from '@tactun/grpc-client';
import { grpc } from '@improbable-eng/grpc-web';
import { UnaryOutput } from '@improbable-eng/grpc-web/dist/typings/unary';
import { ProtobufMessage } from '@improbable-eng/grpc-web/dist/typings/message';
import { tError } from '../../tools/logger';

export const evalFormula = async (formula: string): Promise<number> =>
	new Promise((resolve) => {
		if (process.env.REACT_APP_DEVICE_URL) {
			const calculateReqeust = new CalculationRequest();
			calculateReqeust.setExpr(`${formula}*1.0`);

			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

			grpc.unary(calculationService.Calculation.Calculate, {
				request: calculateReqeust,
				host: process.env.REACT_APP_DEVICE_URL,
				transport: myTransport,
				onEnd: (output: UnaryOutput<ProtobufMessage>) => {
					if (output.status === grpc.Code.OK) {
						const res = output.message?.toObject() as CalculationResponse.AsObject;

						resolve(res ? res.result : 1);
					} else {
						tError(
							`GRPC stream end code: "${output.status.toString()}" \nGRPC stream end message: "${output.message}" \n `
						);
						resolve(1);
					}
				}
			});
		}
	});
