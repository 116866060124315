export enum TestActionsActionTypes {
	EDIT = 'EDIT_SEQUENCER_ACTION',
	DELETE = 'DELETE_SEQUENCER_ACTION',
	MOVE = 'MOVE_SEQUENCER_ACTION',
	CREATE_ACTION = 'CREATE_SEQUENCER_ACTION',
	CREATE_CALCULATION = 'CREATE_SEQUENCER_CALCULATION',
	DUPLICATE = 'DUPLICATE_SEQUENCER_ACTION'
}

export enum TestActionLocations {
	PRE_TEST = 0,
	POST_TEST,
	PRE_STAGE,
	STAGE,
	POST_STAGE
}
