import { useCallback, useMemo, useState } from 'react';
import { CalculatorOperatorValues, CalculatorValueTypes } from './calculator.enums';
import { CalculatorTerm, CalculatorValueItem } from './calculator.types';
import {
	calculatorStringValueToList,
	calculatorValueListToString,
	calculatorValueListToUserFriendly
} from './calculator.converters';

export const useCalculator = (initialValue: string = '') => {
	const [valueList, setValueList] = useState<CalculatorValueItem[]>(calculatorStringValueToList(initialValue));

	const reset = useCallback((value: string = '') => {
		setValueList(calculatorStringValueToList(value));
	}, []);

	const remove = useCallback((position = -1) => {
		setValueList((list) => {
			const newList = position < 0 ? list.slice(0, -1) : [...list.slice(0, position), ...list.slice(position + 1)];
			return newList.length && newList[newList.length - 1].type === CalculatorValueTypes.NEGATIVE_SIGN
				? newList.slice(0, -1)
				: newList;
		});
	}, []);

	const add = useCallback((newItem: CalculatorValueItem, position?: number) => {
		setValueList((list) => {
			const index = typeof position === 'undefined' ? list.length : position;

			return [...list.slice(0, index), newItem, ...list.slice(index)];
		});
	}, []);

	const parenthesisStack = useMemo(() => {
		return valueList.reduce((s, item) => s + (item.value === '(' ? 1 : item.value === ')' ? -1 : 0), 0);
	}, [valueList]);

	const lastItemType = useMemo(() => {
		if (!valueList.length) return;

		const copy = [...valueList];
		const last = copy.pop() as CalculatorValueItem;

		if (last?.type === CalculatorValueTypes.OPERATOR && last.value === CalculatorOperatorValues.SQR) {
			return CalculatorValueTypes.CONST;
		}
		return last?.type;
	}, [valueList]);

	const lastTerm: CalculatorTerm | undefined = useMemo(() => {
		if (!valueList.length) return;

		const copy = [...valueList];
		const last = copy.pop() as CalculatorValueItem;

		if ([CalculatorValueTypes.DIGIT, CalculatorValueTypes.DECIMAL_POINT].includes(last.type)) {
			let value = last.value;
			let current = copy.pop();
			while (current && [CalculatorValueTypes.DIGIT, CalculatorValueTypes.DECIMAL_POINT].includes(current.type)) {
				value = current.value + value;
				current = copy.pop();
			}

			return {
				type: CalculatorValueTypes.NUMBER,
				value,
				position: current ? copy.length + 1 : 0,
				prevItem: current
			};
		}

		if (last.type === CalculatorValueTypes.CLOSE_PARENTHESIS) {
			let current;
			let closedParenthesis = 1;
			while ((current = copy.pop())) {
				if (current.type === CalculatorValueTypes.CLOSE_PARENTHESIS) {
					closedParenthesis += 1;
				} else if (current.type === CalculatorValueTypes.OPEN_PARENTHESIS && closedParenthesis === 1) {
					const checkForMethod = copy.pop();
					const position = checkForMethod?.type === CalculatorValueTypes.FUNCTION ? copy.length : copy.length + 1;
					const prevItem = checkForMethod?.type === CalculatorValueTypes.FUNCTION ? copy.pop() : checkForMethod;

					return {
						type: CalculatorValueTypes.CLOSE_PARENTHESIS,
						value: '()',
						position,
						prevItem
					};
				} else if (current.type === CalculatorValueTypes.OPEN_PARENTHESIS) {
					closedParenthesis -= 1;
				}
			}
		}

		return {
			...last,
			position: copy.length,
			prevItem: copy.pop()
		};
	}, [valueList]);

	const allowedOperations = {
		[CalculatorValueTypes.DIGIT]:
			!lastItemType ||
			[CalculatorValueTypes.OPEN_PARENTHESIS, CalculatorValueTypes.OPERATOR].includes(
				lastItemType as CalculatorValueTypes
			) ||
			(lastTerm?.type === CalculatorValueTypes.NUMBER && lastTerm?.value !== '0'),
		[CalculatorValueTypes.CONST]:
			!lastItemType ||
			[CalculatorValueTypes.OPEN_PARENTHESIS, CalculatorValueTypes.OPERATOR].includes(
				lastItemType as CalculatorValueTypes
			),
		[CalculatorValueTypes.VARIABLE]:
			!lastItemType ||
			[CalculatorValueTypes.OPEN_PARENTHESIS, CalculatorValueTypes.OPERATOR].includes(
				lastItemType as CalculatorValueTypes
			),
		[CalculatorValueTypes.CLOSE_PARENTHESIS]:
			parenthesisStack > 0 &&
			[
				CalculatorValueTypes.DIGIT,
				CalculatorValueTypes.CLOSE_PARENTHESIS,
				CalculatorValueTypes.CONST,
				CalculatorValueTypes.VARIABLE
			].includes(lastItemType as CalculatorValueTypes),
		[CalculatorValueTypes.OPEN_PARENTHESIS]:
			!lastItemType ||
			[CalculatorValueTypes.OPEN_PARENTHESIS, CalculatorValueTypes.OPERATOR, CalculatorValueTypes.FUNCTION].includes(
				lastItemType as CalculatorValueTypes
			),
		[CalculatorValueTypes.OPERATOR]:
			lastItemType &&
			[
				CalculatorValueTypes.DIGIT,
				CalculatorValueTypes.CONST,
				CalculatorValueTypes.VARIABLE,
				CalculatorValueTypes.CLOSE_PARENTHESIS
			].includes(lastItemType as CalculatorValueTypes),
		[CalculatorValueTypes.FUNCTION]:
			!lastItemType ||
			[CalculatorValueTypes.OPEN_PARENTHESIS, CalculatorValueTypes.OPERATOR].includes(
				lastItemType as CalculatorValueTypes
			),
		[CalculatorValueTypes.NEGATIVE_SIGN]:
			lastTerm &&
			[
				CalculatorValueTypes.NUMBER,
				CalculatorValueTypes.CLOSE_PARENTHESIS,
				CalculatorValueTypes.CONST,
				CalculatorValueTypes.VARIABLE
			].includes(lastTerm.type) &&
			lastTerm.prevItem?.type !== CalculatorValueTypes.OPERATOR,
		[CalculatorValueTypes.DECIMAL_POINT]:
			lastTerm && lastTerm.type === CalculatorValueTypes.NUMBER && !lastTerm.value.includes('.')
	};

	const toggleLastTermNegativeSign = useCallback(() => {
		if (!lastTerm) return;
		if (lastTerm?.prevItem?.type === CalculatorValueTypes.NEGATIVE_SIGN) {
			remove(lastTerm.position - 1);
		} else {
			add({ type: CalculatorValueTypes.NEGATIVE_SIGN, value: '-' }, lastTerm.position);
		}
	}, [lastTerm, remove, add]);

	const isValid =
		!parenthesisStack &&
		lastItemType &&
		[
			CalculatorValueTypes.DIGIT,
			CalculatorValueTypes.VARIABLE,
			CalculatorValueTypes.CONST,
			CalculatorValueTypes.CLOSE_PARENTHESIS
		].includes(lastItemType);

	const value = useMemo(() => calculatorValueListToString(valueList), [valueList]);
	const valueUserFriendly = useMemo(() => calculatorValueListToUserFriendly(valueList), [valueList]);

	return {
		add,
		remove,
		reset,
		toggleLastTermNegativeSign,
		value,
		valueUserFriendly,
		isValid,
		allowedOperations
	};
};
