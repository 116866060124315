import axios from '../../httpCommon';
import { TestDataLoggingRequestDto, TestDataLoggingResponseDto } from './testDataLogging.types';

export const checkName = async (name: string, parentId: string, id?: string): Promise<boolean> => {
	const response = await axios.post('/quasar/tests/stages/logs/isNameUnique', { id, name, parentId });
	return response.data === true;
};

export const getTestDataLoggings = async (stageId: string) => {
	const response = await axios.get(`/quasar/tests/stages/${stageId}/logs`);

	return response.data as TestDataLoggingResponseDto[];
};

export const getTestDataLogging = async (id: string) => {
	const response = await axios.get(`/quasar/tests/stages/logs/${id}`);

	return response.data as TestDataLoggingResponseDto;
};

export const createTestDataLogging = async (dataLogConfig: TestDataLoggingRequestDto) => {
	const response = await axios.post('/quasar/tests/stages/logs', dataLogConfig);
	return response.data as TestDataLoggingResponseDto;
};

export const updateTestDataLogging = async (dataLogConfig: TestDataLoggingRequestDto, id: string) => {
	const response = await axios.put(`/quasar/tests/stages/logs/${id}`, dataLogConfig);
	return response.data as TestDataLoggingResponseDto;
};
