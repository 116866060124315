import { object, string, mixed, array, number, ref } from 'yup';
import { ActuatorCalibrationTypes, ActuatorsTypes } from './actuators.enums';
import * as api from './actuators.api';
import { alphaNumericRegex } from '../../constants/regexes';
import { emptyStringToNull } from '../../tools/yupHelpers';

export const actuatorFormSchema = object({
	id: string().notRequired(),
	name: string()
		.required()
		.max(30)
		.matches(alphaNumericRegex, 'Is not in correct format')
		.test('checkActuatorUniqueness', 'Name is used', function (value, form) {
			if (!value) return true;
			return api.checkName(value, form?.parent.stationId, form?.parent?.id);
		}),
	type: mixed<ActuatorsTypes>().required(),
	stationChannelId: string().required('Channel is a required field'),
	stationId: string().required(),
	serialNumber: string().nonNullable().max(100).matches(alphaNumericRegex, 'Is not in correct format'),
	manufacturer: string().notRequired().max(30).matches(alphaNumericRegex, 'Is not in correct format'),
	s1EnableBitChIds: array()
		.of(string().required('Channel is a required field'))
		.required('Channel is a required field')
		.nullable(),
	s2EnableBitChIds: array()
		.of(string().required('Channel is a required field'))
		.required('Channel is a required field')
		.nullable()
		.test('checkBitsNotSame', 'Enable bits must be different', (value, form) => {
			return (!form?.parent.s1EnableBitChId && !value) || form?.parent.s1EnableBitChId !== value;
		})
}).required();

export const actuatorCalibrationInputSchema = object({
	usd: number()
		.transform(emptyStringToNull)
		.required('Control value is a required field')
		.min(ref('lowerLimit'), ({ min }) => `Control value must be greater than or equal to ${min}`)
		.max(ref('upperLimit'), ({ max }) => `Control value must be less than or equal to ${max}`),
	csd: number().transform(emptyStringToNull).required('Calibrated value is a required field')
});

export const actuatorCalibrationFormSchema = object({
	type: mixed<ActuatorCalibrationTypes>().required(),
	unitId: string().required('Unit is a required field'),
	actuatorId: string().required('Actuator is a required field'),
	calibrationData: array()
		.min(2, 'At least 2 points are required for the Piecewise calibration')
		.required('At least 2 points are required for the Piecewise calibration')
}).required();
