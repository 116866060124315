import React, { memo, forwardRef, InputHTMLAttributes } from 'react';
import type { Except } from 'type-fest';
import cx from 'classnames';

import FieldFrame2, { IFieldFrameProps } from '../FieldFrame2';

import styles from './InputText.module.scss';

export interface IInputTextProps
	extends Except<IFieldFrameProps, 'children' | 'onMouseDown'>,
		Except<InputHTMLAttributes<HTMLInputElement>, 'onFocus' | 'onBlur' | 'size' | 'type'> {
	textarea?: boolean;
	maxLength?: number;
}

const InputText = forwardRef<HTMLInputElement | HTMLTextAreaElement, IInputTextProps>(
	(
		{
			className,
			containerClassName,
			label,
			error,
			disabled,
			size,
			style,
			leftIcon,
			rightIcon,
			errorPosition,
			onFocus,
			onBlur,
			textarea,
			maxLength,
			...inputProps
		},
		ref
	) => {
		const length = String(inputProps.value).length || 0;

		return (
			<FieldFrame2
				label={label}
				style={style}
				className={cx(className, styles.input)}
				containerClassName={containerClassName}
				errorPosition={errorPosition}
				error={error}
				disabled={disabled}
				size={size}
				leftIcon={leftIcon}
				rightIcon={rightIcon}
				onFocus={onFocus}
				onBlur={onBlur}
			>
				{({ withLabel, isFocused, ...defaultProps }) => (
					<>
						{React.createElement(textarea ? 'textarea' : 'input', {
							...defaultProps,
							...inputProps,
							ref
						})}
						{maxLength !== undefined && textarea && (
							<div className={cx(styles.countDown, { [styles.warning]: length && maxLength && length > maxLength })}>
								{length}/{maxLength}
							</div>
						)}
					</>
				)}
			</FieldFrame2>
		);
	}
);

InputText.defaultProps = {
	textarea: false
};

export default Object.assign(memo(InputText), { displayName: 'InputText' });
