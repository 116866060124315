import React, { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import CalculationsTable from '../components/CalculationsTable';
import { CalculationListItem, CalculationResponseDto } from '../calculation.types';
import { CalculationsTableActionTypes } from '../calculation.enums';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { useStationName } from '../../Stations/stations.hooks';
import { responsesListItemConverter } from '../calculation.converters';
import { useCalculations } from '../calculation.hooks';

const CalculationsTableContainer: React.FC = () => {
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const queryClient = useQueryClient();
	const { stationId } = useParams();

	const handleAction = useCallback(
		(type: CalculationsTableActionTypes, data?: CalculationListItem) => {
			switch (type) {
				case CalculationsTableActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case CalculationsTableActionTypes.CREATE:
					navigate('./create');
					break;
				case CalculationsTableActionTypes.DELETE:
					setEntityForDelete(data as IEntity);
					break;
				case CalculationsTableActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
			}
		},
		[navigate]
	);

	const { calculations, isCalculationsLoading } = useCalculations(stationId);
	const data = useMemo(() => {
		return calculations?.map((calculation) => responsesListItemConverter(calculation));
	}, [calculations]);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handlePostDelete = useCallback(
		(id: string) => {
			queryClient.setQueryData<CalculationResponseDto[]>(
				['calculations', { stationId }],
				(old) => old?.filter((st) => st.uuid !== id)
			);
		},
		[queryClient, stationId]
	);

	const { stationName } = useStationName(stationId);

	return (
		<>
			<CalculationsTable
				stationName={stationName}
				onAction={handleAction}
				data={data}
				isLoading={isCalculationsLoading}
			/>
			<EntityDeleter
				onDeleted={handlePostDelete}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.CALCULATION}
				entity={entityForDelete}
			/>
		</>
	);
};

export default React.memo(CalculationsTableContainer);
