import React, { useMemo, useRef } from 'react';
import type { FC } from 'react';
import styles from './ActionCard.module.scss';
import { InputText, KebabMenu } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

interface ActionCardProps {
	id: any;
	name?: string;
	label: string;
	property: string;
	index: number;
	dragType: string;
	onEdit: () => void;
	onDelete: () => void;
	moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
	index: number;
	id: string;
	type: string;
}

const ActionCard: FC<ActionCardProps> = ({
	id,
	name,
	label,
	property,
	onEdit,
	onDelete,
	moveCard,
	index,
	dragType
}) => {
	const { t } = useTranslation('actions');

	const ref = useRef<HTMLDivElement>(null);

	const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
		accept: dragType,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId()
			};
		},
		hover(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			moveCard(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		}
	});

	const [, drag, preview] = useDrag({
		type: dragType,
		item: () => {
			return { id, index };
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging()
		})
	});

	const actions = useMemo(() => {
		return [
			{ label: t('Edit'), command: () => onEdit() },
			{ label: t('Delete'), command: () => onDelete() }
		];
	}, [t, onEdit, onDelete]);

	drag(drop(ref));

	return (
		<div ref={preview} className={styles.container + ' handle'}>
			{name && <InputText data-testid="ruleActionName" className={styles.name} name={name} value={name} disabled />}
			<InputText data-testid="ruleActionLabel" className={styles.label} name={label} value={label} disabled />
			<InputText data-testid="ruleActionValue" className={styles.value} name={property} value={property} disabled />
			<div className="iconMore">
				<KebabMenu data-testid="ruleActionMore" model={actions} />
			</div>
			<div data-testid="ruleActionDrag" className={styles.dragHandler} ref={ref}></div>
		</div>
	);
};

export default ActionCard;
