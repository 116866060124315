import { Converter } from '../../types';
import {
	TestInformationResponseDto,
	TestInformationFormType,
	TestInformationRequestDto,
	TestListItem,
	TestListDto,
	ITestPrePostInfo
} from './tests.types';
import { TestStatusType } from './tests.enums';

export const responseFormConverter: Converter<TestInformationResponseDto, TestInformationFormType> = (response) => {
	return {
		id: response.id,
		name: response.name,
		standard: response.std,
		station: response.stationId,
		axis: response.axes?.map((axis) => axis.id),
		specimenType: response.specimenType.id,
		unitSystem: response.unitSystem,
		timeUnit: response.timeUnit.id,
		dataStorage: response.dsType,
		storageFullBehavior: response.dsFullBh,
		description: response.description,
		imageId: ''
	};
};

export const formRequestConverter: Converter<TestInformationFormType, TestInformationRequestDto> = (form) => {
	return {
		id: form.id,
		name: form.name,
		std: form.standard,
		stationId: form.station,
		axes: form.axis,
		specimenTypeId: form.specimenType,
		unitSystem: form.unitSystem,
		timeUnitId: form.timeUnit,
		dsType: form.dataStorage,
		dsFullBh: form.storageFullBehavior,
		description: form.description,
		imageId: ''
	};
};

export const responseListItemConverter: Converter<TestListDto, TestListItem> = (response) => {
	return {
		id: response.id,
		name: response.name,
		standard: response.std,
		stationName: response.stationName,
		specimenType: response.specimenType,
		createdDate: response.createdDate,
		specimenTypeId: response.specimenTypeId,
		status: TestStatusType[response.status],
		groups: response.groups
	};
};

export const testResponseDtoToITestPrePostInfoConverter: Converter<TestInformationResponseDto, ITestPrePostInfo> = (
	response
) => {
	return {
		preLogsCount: response.preStage.logs?.length,
		postLogsCount: response.postStage.logs?.length,
		preActionsCount: response.preStage.mainStageActions?.length,
		postActionsCount: response.postStage.mainStageActions?.length,
		preStageId: response.preStage.id,
		postStageId: response.postStage.id
	};
};
