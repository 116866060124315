import { WebSystemRequest, StationRequest, HashedStation } from '@tactun/grpc-client';
import { cloneDeep } from 'lodash';
import { StationResponseDto, StationFormType, StationRequestDto, StationListItem } from './station.types';
import { sensorResponsesToSensorGRPCsConverter } from '../Sensors/sensors.converters.grpc';
import { measurementResponsesToMeasurementGRPCsConverter } from '../Measurements';
import { actuatorsResponsesToActuatorsGRPCsConverter } from '../Actuators';
import { axesResponsesToAxesGRPCsConverter } from '../Axes';
import { VariableResponseDto, variableResponsesToVariableGRPCsConverter } from '../Variables';
import { auxiliaryDeviceResponsesToGRPCsConverter } from '../AuxiliaryDevices/auxiliaryDevice.converters';
import { rulesResponseToGRPCRequestConverter, StationRuleResponseDto } from '../Rules';
import { stationChannelResponsesToStationChannelsGRPCConverter } from '../StationChannels';
import { UnitSystemTypes } from '../Units';
import { buttonWidgetDtoToStateVariables, buttonWidgetRuleDtoToRuleDto } from '../ButtonWidget';
import { calculateMessageHash } from '../../tools/protobufHelpers';

export const stationsResponseToListItem = (response: StationResponseDto): StationListItem => {
	return {
		id: response.id,
		name: response.name,
		type: response.type,
		controllerModel: response.controllerModel,
		createdDate: response.createdDate,
		groups: response.groups,
		isLinked: response.product !== null
	};
};

export const stationsResponseToForm = (response: StationResponseDto): StationFormType => {
	return {
		id: response.id,
		name: response.name,
		type: response.type,
		controllerModel: response.controllerModel,
		description: response.description,
		unitSystem: response.unitSystem,
		specimenTypes: response.specimenTypes?.map((st) => st.id)
	};
};

export const stationsFormToRequest = (input: StationFormType): StationRequestDto => {
	return {
		name: input.name,
		controllerModel: input.controllerModel,
		type: input.type,
		description: input.description,
		specimenTypes: input.specimenTypes,
		unitSystem: input.unitSystem || UnitSystemTypes.METRIC
	};
};

export const stationResponseToGRPC = (station: StationResponseDto): StationRequest => {
	const stationDto = new StationRequest();
	const variables: VariableResponseDto[] = station.variables ? cloneDeep(station.variables) : [];
	const stationRules: StationRuleResponseDto[] = station.stationRules ? cloneDeep(station.stationRules) : [];

	stationDto.setId(station.id);
	stationDto.setName(station.name);
	stationDto.setUnitSystem(station.unitSystem);
	//stationDto.setControllerModel(station.controllerModel);
	if (station.widgets.length) {
		station.widgets.forEach((widget) => {
			const widgetVariables = buttonWidgetDtoToStateVariables(widget);
			variables.push(...widgetVariables);
			const widgetRules = buttonWidgetRuleDtoToRuleDto(widget);
			stationRules.push(...widgetRules);
		});
	}

	if (station.stationChannels.length)
		stationDto.setStationChannelsList(stationChannelResponsesToStationChannelsGRPCConverter(station.stationChannels));
	if (station.measurements.length)
		stationDto.setMeasurementsList(measurementResponsesToMeasurementGRPCsConverter(station.measurements));
	if (station.actuators.length)
		stationDto.setActuatorsList(actuatorsResponsesToActuatorsGRPCsConverter(station.actuators));
	if (station.axes.length) stationDto.setAxesList(axesResponsesToAxesGRPCsConverter(station.axes));
	if (variables.length) stationDto.setVariablesList(variableResponsesToVariableGRPCsConverter(variables));
	if (station.sensors.length) stationDto.setSensorsList(sensorResponsesToSensorGRPCsConverter(station.sensors));
	if (station.auxiliaryDevices.length)
		stationDto.setAuxDevicesList(auxiliaryDeviceResponsesToGRPCsConverter(station.auxiliaryDevices));
	if (stationRules.length) stationDto.setRulesList(rulesResponseToGRPCRequestConverter(stationRules));

	return stationDto;
};

export const stationResponseListToGRPC = (stations: StationResponseDto[]): WebSystemRequest => {
	const systemRequest = new WebSystemRequest();
	systemRequest.setId('1');
	systemRequest.setMultiUser(true);

	stations.forEach((station) => {
		const stationConfig = stationResponseToGRPC(station);
		const stationConfigHash = calculateMessageHash(stationConfig);

		const hashedStation = new HashedStation();
		hashedStation.setHash(stationConfigHash);
		hashedStation.setStation(stationConfig);

		systemRequest.addStations(hashedStation);
	});

	return systemRequest;
};
