import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { duplicateStation, getStations } from '../station.api';
import StationsTable from '../components/StationsTable';
import { StationListItem } from '../station.types';
import { StationCrudActionTypes } from '../station.enums';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { stationsResponseToListItem } from '../station.converters';
import { GroupActionTypes, GroupContextProvider, GroupsTypes } from '../../Group';
import { useConnectionStore } from '../../Connection/connection.store';
import { useConnectedStationStatus } from '../../Connection/connection.hooks';

const StationsTableContainer: React.FC = () => {
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const { connectedDevice } = useConnectionStore();
	const { stationStatus } = useConnectedStationStatus();
	const queryClient = useQueryClient();
	const stationTableRef = useRef<{ scrollTo(index: number): void }>(null);

	const { data, isLoading } = useQuery({
		queryKey: ['stations'],
		queryFn: async () => {
			const resp = await getStations();
			return resp?.map((station) => stationsResponseToListItem(station)) ?? [];
		}
	});

	const handleAction = useCallback(
		async (type: StationCrudActionTypes, data?: StationListItem) => {
			switch (type) {
				case StationCrudActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case StationCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case StationCrudActionTypes.DUPLICATE:
					const newStation = await duplicateStation(data?.id as string);

					queryClient.setQueryData<StationListItem[]>(['stations'], (old) => {
						if (!old) return undefined;

						//TODO: Ugly hack: find a better way to scroll to the new station
						setTimeout(() => {
							stationTableRef.current?.scrollTo(old.length);
						}, 300);
						return [...old, stationsResponseToListItem(newStation)];
					});

					break;
				case StationCrudActionTypes.DELETE:
					setEntityForDelete(data as IEntity);
					break;
				case StationCrudActionTypes.RUN_STATION:
					navigate(`./${data?.id}/dashboard`);
					break;
				case StationCrudActionTypes.CREATE_PRODUCT:
					navigate(`/products/create/${data?.id}`);
					break;
			}
		},
		[navigate, queryClient]
	);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handlePostDelete = useCallback(
		(id: string) => {
			queryClient.setQueryData<StationListItem[]>(['stations'], (old) => {
				return old?.filter((st) => st.id !== id);
			});
		},
		[queryClient]
	);

	const handleGroupingAction = useCallback(
		(action: GroupActionTypes) => {
			switch (action) {
				case GroupActionTypes.ADD_TO_GROUP:
				case GroupActionTypes.REMOVE_FROM_GROUP:
				case GroupActionTypes.CREATE_GROUP:
				case GroupActionTypes.RENAME_GROUP:
				case GroupActionTypes.DELETE_GROUP:
				case GroupActionTypes.BATCH_UPDATE_GROUPS:
					queryClient.invalidateQueries({ queryKey: ['stations'] });
					break;
			}
		},
		[queryClient]
	);

	return (
		<>
			<GroupContextProvider type={GroupsTypes.STATION} onAction={handleGroupingAction}>
				<StationsTable
					ref={stationTableRef}
					onAction={handleAction}
					data={data || []}
					isLoading={isLoading}
					connectedModel={connectedDevice?.model}
					connectedStationId={stationStatus?.id}
				/>
			</GroupContextProvider>
			<EntityDeleter
				onDeleted={handlePostDelete}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.STATION}
				entity={entityForDelete}
			/>
		</>
	);
};

export default React.memo(StationsTableContainer);
