import { Form, InputText, Dropdown } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { AxisFormType } from '../../axes.types';
import { IListItem, IListItemNumber } from '../../../../types';
import styles from './AxisForm.module.scss';

interface AxisFormProps {
	formId: string;
	form: UseFormReturn<AxisFormType>;
	actuatorOptions: IListItem[];
	types: IListItemNumber[];
	onSave: () => void;
}

const AxisForm: React.FC<AxisFormProps> = ({ formId, types, actuatorOptions, form, onSave }) => {
	const { t } = useTranslation('axes');
	const {
		control,
		formState: { errors },
		register
	} = form;

	return (
		<Form className={styles.container} onSubmit={onSave} id={formId}>
			<input type="hidden" {...register('stationId')} />
			<Controller
				name="name"
				control={control}
				render={({ field }) => (
					<InputText data-testid="nameId" label={t('Name*')} {...field} error={errors.name?.message} />
				)}
			/>
			<Controller
				name="type"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						data-testid="type"
						options={types}
						label={t('Type*')}
						error={errors.type?.message}
						filter
					/>
				)}
			/>
			<Controller
				name="actuatorId"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						options={actuatorOptions}
						label={t('Actuator*')}
						data-testid="actuatorId"
						error={errors.actuatorId?.message}
						filter
					/>
				)}
			/>
		</Form>
	);
};

export default AxisForm;
