import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useOnce } from '../../../hooks';
import { deleteEmptyFields } from '../../../tools';
import { useStationName } from '../../Stations/stations.hooks';
import * as api from '../customWidget.api';
import { ICustomWidgetForm, CustomWidgetDto } from '../customWidget.types';
import { widgetFormSchema } from '../customWidget.schemas';
import { useWidget } from '../customWidget.hooks';
import CustomWidgetForm from '../components/CustomWidgetForm';
import { formRequestConverter, responseFormConverter } from '../customWidget.converters';
import { customWidgetDefaultValues } from '../customWidget.const';

const CustomWidgetFormContainer: React.FC = () => {
	const navigate = useNavigate();
	const { stationId, widgetId } = useParams();
	const { widget: currentWidgetDto } = useWidget(widgetId, stationId);

	const { stationName } = useStationName(stationId);

	const form = useForm<ICustomWidgetForm>({
		defaultValues: { ...customWidgetDefaultValues, stationId },
		mode: 'onBlur',
		resolver: yupResolver(widgetFormSchema)
	});
	const { handleSubmit, reset } = form;

	const createMutation = useMutation({
		mutationFn: (widget: CustomWidgetDto) => api.createWidget(widget),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (widget: CustomWidgetDto) => api.updateWidget(widget),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/widgets`);
	}, [navigate, stationId]);

	const handleReset = useCallback(() => {
		if (widgetId && currentWidgetDto) {
			reset({ ...responseFormConverter(currentWidgetDto), stationId }, { keepDirty: true, keepTouched: true });
		} else if (!widgetId) {
			reset({ ...customWidgetDefaultValues, stationId }, { keepDirty: true, keepTouched: true });
		}
	}, [currentWidgetDto, reset, stationId, widgetId]);

	const handleSave = handleSubmit((data) => {
		const widget = deleteEmptyFields<CustomWidgetDto>(formRequestConverter(data));

		if (widget.uuid) {
			updateMutation.mutate(widget);
		} else {
			createMutation.mutate(widget);
		}
	});

	const initTrigger = useOnce(!!currentWidgetDto);

	// Setup data for edit
	useEffect(() => {
		if (initTrigger) {
			handleReset();
		}
	}, [initTrigger, handleReset]);

	return (
		<FormProvider {...form}>
			<CustomWidgetForm
				isCreate={!widgetId}
				isLoading={createMutation.isPending || updateMutation.isPending}
				onBack={handleBack}
				onSave={handleSave}
				stationName={stationName}
			/>
		</FormProvider>
	);
};

export default React.memo(CustomWidgetFormContainer);
