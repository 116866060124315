import React, { useEffect, useRef } from 'react';
import type { FC } from 'react';
import { InputNumber, Switch, Button, GroupInputFrame } from '@tactun/ui';
import { CalibrationInputState } from '../../calibrations.types';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './CalibrationForm.module.scss';

const classes = 'flexRow flexFull';

export interface CalibrationInputProps {
	onSubmit: () => void;
	calibrationData: number;
	form: UseFormReturn<CalibrationInputState>;
}

const CalibrationInput: FC<CalibrationInputProps> = ({ onSubmit, calibrationData, form }) => {
	const { t } = useTranslation();

	const {
		control,
		trigger,
		formState: { errors },
		setValue,
		watch
	} = form;
	const formId = useRef('calibrationInputForm');
	const isRealTime = watch('isRealTime');

	useEffect(() => {
		if (isRealTime) {
			setValue('uncalibratedData', calibrationData);
			trigger('uncalibratedData');
		}
	}, [calibrationData, isRealTime, setValue, trigger]);

	return (
		<>
			<div className={styles.flexRow}>
				<div className="flexLabel">{t('Real-time uncalibrated data')}</div>
				<GroupInputFrame>
					<Controller
						name="isRealTime"
						control={control}
						render={({ field }) => (
							<Switch
								className={styles.switchCustom}
								onChange={(event) => field.onChange(event.value)}
								checked={!!field.value}
								data-testid="realTime"
							/>
						)}
					/>
					<Controller
						name="uncalibratedData"
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								error={errors.uncalibratedData?.message}
								disabled={isRealTime}
								isInteger={!isRealTime}
								data-testid="uncalibratedData"
							/>
						)}
					/>
				</GroupInputFrame>
			</div>
			<div className={classes}>
				<Controller
					name="calibratedData"
					control={control}
					render={({ field }) => (
						<InputNumber
							{...field}
							label={t('Calibrated data*')}
							error={errors.calibratedData?.message}
							data-testid="calibratedData"
						/>
					)}
				/>

				<Button
					type="button"
					icon="t-icon-add"
					form={formId.current}
					className={styles.addData}
					data-testid="addData"
					onClick={() => {
						onSubmit();
					}}
				/>
			</div>
		</>
	);
};

export default React.memo(CalibrationInput);
