import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as api from '../customers.api';
import Axis from '../components/Customers';

const AxesContainer: React.FC = () => {
	const navigate = useNavigate();
	const { customerId } = useParams();
	const isCreate = !customerId;

	const form = useForm<Record<string, string>>({
		mode: 'onBlur'
	});
	const { handleSubmit, reset } = form;

	const { data } = useQuery<Record<string, string>, Error>({
		queryKey: ['customer', { customerId }],
		queryFn: async () => {
			const resp = await api.getCustomer(customerId as string);
			return resp;
		},
		enabled: !!customerId
	});

	const handleBack = useCallback(() => {
		navigate('/customers');
	}, [navigate]);

	const handleSave = handleSubmit((data) => {
		if (customerId) {
			//Update
		} else {
			//Create
		}
	});

	useEffect(() => {
		if (customerId) {
			reset({ ...data }, { keepDirty: true, keepTouched: true });
		}
	}, [customerId, data, reset]);

	return <Axis isCreate={isCreate} isLoading={false} form={form} onBack={handleBack} onSave={handleSave} />;
};

export default React.memo(AxesContainer);
