import { memo, useCallback, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { debounce } from 'lodash';

const debouncedChangeHandler = debounce((onChange: (newColor: string) => void, hex: string) => {
	onChange(hex);
}, 300);

interface IColorPickerProps {
	initialColor: string;
	onChange: (newColor: string) => void;
}

const ColorPicker = ({ onChange, initialColor }: IColorPickerProps) => {
	const [color, setColor] = useState(initialColor);
	const handleChange = useCallback(
		({ hex }: ColorResult) => {
			setColor(hex);
			debouncedChangeHandler(onChange, hex);
		},
		[onChange]
	);
	return <SketchPicker onChange={handleChange} color={color} />;
};

export default memo(ColorPicker);
