import { object, string, array } from 'yup';
import { alphaNumericRegex } from '../../constants/regexes';
import * as api from './specimenTypes.api';

export const dimensionSchema = object({
	id: string().optional().nullable(),
	name: string()
		.required()
		.max(30)
		.matches(alphaNumericRegex, 'Is not in correct format')
		.test('dimensionUniqueName', function (value, form) {
			if (!form?.parent?.dimensions?.length) return true;

			if (form?.parent?.dimensions?.some((d: string) => d === value)) {
				return this.createError({
					message: `The name "${value}" is already taken. Please choose a different name.`
				});
			}
			return true;
		}),
	dimensions: array().of(string().required()).optional(),
	unit: string().required()
});

export const specimenTypeSchema = object().shape({
	id: string().optional(),
	name: string()
		.required()
		.max(30)
		.matches(alphaNumericRegex, 'Is not in correct format')
		.test('checkSpecimenTypeUniqness', 'Name is used', function (value, form) {
			if (!value) return true;
			return api.checkName(value, form?.parent?.id);
		}),
	gaugeLengthEquation: string().required('Gauge length equation is a required field'),
	gaugeLengthEquationUnitId: string().required('Gauge length equation unit ID is a required field'),
	areaEquation: string().required('Area equation is a required field'),
	areaEquationUnitId: string().required('Area equation unit ID is a required field'),
	dimensions: array().of(dimensionSchema.required()).min(1, 'There should be least 1 Parameter'),
	image: string().notRequired(),
	imageUrl: string().optional(),
	imageId: string().optional()
});
