import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as api from './specimenTypes.api';
import { ISpecimenTypeResponse } from './specimenTypes.types';

export const useSpecimenTypes = () => {
	const { data: specimenTypes, isLoading: isSpecimenTypesLoading } = useQuery({
		queryKey: ['specimenTypes'],
		queryFn: () => api.getSpecimenTypes()
	});

	const queryClient = useQueryClient();

	const updateSpecimenTypes = () => {
		queryClient.invalidateQueries({ queryKey: ['specimenTypes'] });
	};

	return {
		specimenTypes,
		isSpecimenTypesLoading,
		updateSpecimenTypes
	};
};

export const useSpecimenType = (specimenId?: string | null) => {
	const { data: specimenType, isLoading: isSpecimenTypeLoading } = useQuery<ISpecimenTypeResponse>({
		queryKey: ['specimenType', { id: specimenId }],
		// When we provide enable option - !!stationId, the stationId is guaranteed
		queryFn: () => api.getSpecimenType(specimenId as string),
		enabled: !!specimenId
	});

	const queryClient = useQueryClient();

	const updateSpecimenType = () => {
		queryClient.invalidateQueries({ queryKey: ['specimenType', { id: specimenId }] });
	};

	return {
		specimenType,
		isSpecimenTypeLoading,
		updateSpecimenType
	};
};
