import { useEffect, useState, useCallback, useMemo } from 'react';
import { Store } from 'tauri-plugin-store-api';

export const useTauriStore = (storeName: string) => {
	const [store, setStore] = useState<Store>();
	useEffect(() => {
		if (window.__TAURI_INVOKE__) {
			setStore(new Store(storeName));
		}
	}, [storeName]);

	const get = useCallback(
		async (key: string) => {
			return window.__TAURI_INVOKE__ ? await store?.get(key) : null;
		},
		[store]
	);

	const set = useCallback(
		async (key: string, value: any) => {
			return window.__TAURI_INVOKE__ ? await store?.set(key, value) : null;
		},
		[store]
	);

	const remove = useCallback(
		async (key: string) => {
			return window.__TAURI_INVOKE__ ? await store?.delete(key) : null;
		},
		[store]
	);

	const clear = useCallback(async () => {
		return window.__TAURI_INVOKE__ ? await store?.clear() : null;
	}, [store]);

	const save = useCallback(async () => {
		return window.__TAURI_INVOKE__ ? await store?.save() : null;
	}, [store]);

	return useMemo(
		() => ({
			get,
			set,
			save,
			remove,
			clear
		}),
		[clear, get, remove, save, set]
	);
};
