import { FC, useCallback, useMemo } from 'react';
import { useModalData } from '@tactun/ui';
import TestSequencerRulesCreateEditModal from '../components/TestSequencerRulesCreateEditModal';
import RuleCreateEdit, {
	RuleListItem,
	RuleType,
	StationRuleRequestDto,
	TestRuleRequestDto,
	TestRuleResponseDto
} from '../../Rules';
import { createEditTestSequencerRulesModalId } from '../testSequencerRules.const';
import { useTestSequencerRule, useTestSequencerRules } from '../testSequencerRules.hooks';
import { testSequencerRuleSchema } from '../testSequencerRules.schemas';
import { useTest } from '../../Tests/tests.hooks';
import { StageTypes } from '../../Tests/tests.enums';

interface TestSequencerRulesCreateEditContainerProps {
	testId?: string;
	onClose: () => void;
	onSaveSuccess: () => void;
	existingRules: RuleListItem[];
}

const testSequencerRulesFormId = 'testSequencerRulesFormId';

const TestSequencerRulesCreateEditContainer: FC<TestSequencerRulesCreateEditContainerProps> = ({
	testId,
	onClose,
	onSaveSuccess,
	existingRules
}) => {
	const data = useModalData(createEditTestSequencerRulesModalId);
	const { testDto } = useTest(testId);
	const { testSequencerRule } = useTestSequencerRule(data?.id);
	const { isCreating, isUpdating, saveRule } = useTestSequencerRules(
		testId,
		data?.stageId,
		data?.stageType,
		onSaveSuccess
	);

	const handleSave = useCallback(
		(rule: TestRuleRequestDto | StationRuleRequestDto) => {
			saveRule(rule as TestRuleRequestDto, data.id, data.isCopy);
		},
		[data, saveRule]
	);

	const ruleCopy = useMemo(() => {
		const copy = { ...testSequencerRule } as TestRuleResponseDto;
		if (data?.isCopy) {
			copy.name = `${copy.name}_copy`;
		}
		return testSequencerRule ? copy : undefined;
	}, [testSequencerRule, data?.isCopy]);

	const formContext = useMemo(
		() => ({
			items: existingRules
		}),
		[existingRules]
	);

	return (
		<TestSequencerRulesCreateEditModal
			formId={testSequencerRulesFormId}
			isCreate={!testSequencerRule?.uuid}
			name={testSequencerRule?.name}
			isLoading={isCreating || isUpdating}
			onClose={onClose}
		>
			{data && (
				<RuleCreateEdit
					isInTest
					testId={testId}
					ruleType={data?.stageType === StageTypes.GLOBAL ? RuleType.TEST : RuleType.STAGE}
					stationId={testDto?.stationId}
					formId={testSequencerRulesFormId}
					rule={ruleCopy}
					ruleId={data?.isCopy ? undefined : data?.id}
					ruleSchema={testSequencerRuleSchema}
					formContext={formContext}
					onSave={handleSave}
				/>
			)}
		</TestSequencerRulesCreateEditModal>
	);
};

export default TestSequencerRulesCreateEditContainer;
