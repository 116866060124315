import { map } from 'rxjs/operators';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { grpc } from '@improbable-eng/grpc-web';
import { StationStatus, StatusRequest, StatusResponse, systemService } from '@tactun/grpc-client';
import {
	changeIp,
	discover,
	rebootDevice,
	enableDhcp,
	startTunnel,
	stopTunnel,
	getNetworkInfo,
	stopSync
} from './connection.api';
import { useConnectionStore } from './connection.store';
import { IDevice, IDeviceNetwork, IStationStatus } from './connection.types';
import { statusConnectionFailedInterval, statusConnectionUpdateInterval } from '../../configs/systemConfigs';
import { initIntervalManager } from '../../tools/IntervalManager';
import { tError, tInfo } from '../../tools/logger';

// Use this hook in application only ones for info related with connected device use useConnectionStore()
export const useDeviceConnection = () => {
	const { connectedDevice, connectionRef, stationsSubject, setConnectionRef, setConnectedDevice } =
		useConnectionStore();

	// Save connected device to local storage
	const saveConnectedDevice = React.useCallback((device: IDevice | null) => {
		if (device) {
			localStorage.setItem('device', JSON.stringify(device));
		} else {
			localStorage.removeItem('device');
		}
	}, []);

	const disconnect = React.useCallback(
		async (withoutStored: boolean = false) => {
			connectionRef?.close();
			setConnectionRef(null);
			await stopTunnel();

			setConnectedDevice(null);
			if (!withoutStored) {
				saveConnectedDevice(null);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[connectionRef]
	);

	const stopNatsSync = React.useCallback(() => {
		stopSync();
	}, []);

	const connectStatusStream = useCallback(() => {
		if (process.env.REACT_APP_DEVICE_URL) {
			const myTransport = grpc.CrossBrowserHttpTransport({});
			const request = new StatusRequest();
			request.setPeriod(statusConnectionUpdateInterval);

			const { clear, start, restart } = initIntervalManager(statusConnectionFailedInterval);

			const systemStatusRequestRef = grpc.invoke(systemService.WebSystem.Status, {
				request: request,
				host: process.env.REACT_APP_DEVICE_URL as string,
				transport: myTransport,
				onEnd: (code: grpc.Code, message: string) => {
					tError(`GRPC status stream end code: "${code.toString()}" \nGRPC stream end message: "${message}" \n `);
					if (code !== grpc.Code.OK) {
						tError(`Code: ${code.toString()}, Message: ${message}`);
					}
				},
				onMessage(res: StatusResponse) {
					restart();
					const systemStatus = res.toObject();

					if (systemStatus.stationsList) {
						stationsSubject.next(systemStatus.stationsList);
					}
					if (systemStatus.serverBusy) {
						tError('Status stream returns "SERVER BUSY" response');
					}
				}
			});
			start(() => {
				clear();
				disconnect(true);
			});

			setConnectionRef(systemStatusRequestRef);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disconnect, stationsSubject]);

	const connect = React.useCallback(async (device: IDevice) => {
		const isTunnelOpen = await startTunnel(device.ip);
		tInfo('Start tunnel to device');
		if (isTunnelOpen) {
			connectStatusStream();
			setConnectedDevice(device);
			saveConnectedDevice(device);
		} else {
			toast.error('Could not open tunnel!');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const reboot = React.useCallback(async () => {
		const isRebooted = await rebootDevice();
		if (isRebooted) {
			await stopTunnel();
			setConnectedDevice(null);
		} else {
			toast.error('Could not reboot device.');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const enableDHCP = React.useCallback(async (device: IDevice) => {
		const isEnabled = await enableDhcp();
		if (isEnabled) {
			toast.success('DHCP enabled.');
		} else {
			toast.error('Could not enable DHCP.');
		}
	}, []);

	useEffect(() => {
		const deviceJson = localStorage.getItem('device');
		const device = deviceJson ? JSON.parse(deviceJson) : undefined;
		if (device) {
			connect(device);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return () => {
			disconnect(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return React.useMemo(
		() => ({ connect, disconnect, stopNatsSync, reboot, enableDHCP, connectedDevice }),
		[connect, disconnect, reboot, enableDHCP, stopNatsSync, connectedDevice]
	);
};

export const useDiscoverDevices = () => {
	const [isDiscovering, setIsDiscovering] = React.useState(false);
	const [devices, setDevices] = React.useState<IDevice[]>([]);

	const discoverDevices = React.useCallback(async () => {
		setDevices([]);
		setIsDiscovering(true);

		const devices = await discover();

		setDevices(devices);
		setIsDiscovering(false);
	}, []);

	return React.useMemo(() => ({ discoverDevices, devices, isDiscovering }), [discoverDevices, devices, isDiscovering]);
};

export const useChangeIp = () => {
	const { connectedDevice, setConnectedDevice } = useConnectionStore();
	const changeDeviceIp = React.useCallback(
		async (ip: string, mask: string, gateway: string) => {
			const isUpdated = await changeIp(ip, mask, gateway);

			if (isUpdated && connectedDevice !== null) {
				setConnectedDevice(null);
			}

			return isUpdated;
		},
		[connectedDevice, setConnectedDevice]
	);

	return React.useMemo(() => ({ changeDeviceIp }), [changeDeviceIp]);
};

export const useNetworkInfo = (isConnected: boolean) => {
	const [networkInfo, setNetworkInfo] = React.useState<IDeviceNetwork | null>(null);

	useEffect(() => {
		async function getDeviceNetworkInfo() {
			const info = await getNetworkInfo();
			if (info) {
				setNetworkInfo(info);
			} else {
				toast.error('Could not get network info.');
			}
		}
		if (isConnected) {
			getDeviceNetworkInfo();
		}

		return () => {
			setNetworkInfo(null);
		};
	}, [isConnected]);

	return networkInfo;
};

export const useSystemStatus = () => {
	const { connectionRef, stationsSubject, addSubscription, removeSubscription } = useConnectionStore();

	const subscribeToStationsStatuses = useCallback(
		(stationIdForSubscription: string | null, next: (v: StationStatus.AsObject | undefined) => void) => {
			const subscription = stationsSubject
				.pipe(
					map((data) =>
						data.find(
							({ stationId }) =>
								(stationIdForSubscription === null && stationId !== undefined) || stationId === stationIdForSubscription
						)
					)
				)
				.subscribe({ next });

			addSubscription(subscription);

			return () => {
				removeSubscription(subscription);
			};
		},
		[stationsSubject, addSubscription, removeSubscription]
	);

	return useMemo(
		() => ({ subscribeToStationsStatuses, isStreamOpen: connectionRef !== null }),
		[connectionRef, subscribeToStationsStatuses]
	);
};

export const useRunningTestInfo = () => {
	const { connectionRef, stationsSubject, addSubscription, removeSubscription } = useConnectionStore();

	const subscribeToStationsStatuses = useCallback(
		(testIdToSubscribe: string | null, next: (v: { testId: string; specimenId: string } | undefined) => void) => {
			const subscription = stationsSubject
				.pipe(
					map((data: StationStatus.AsObject[]) => {
						const info = data.find(({ testId }) => testId === testIdToSubscribe);
						if (info) {
							return { testId: info.testId, specimenId: info.specimenId };
						}
					})
				)
				.subscribe({ next });

			addSubscription(subscription);

			return () => {
				removeSubscription(subscription);
			};
		},
		[stationsSubject, addSubscription, removeSubscription]
	);

	return useMemo(
		() => ({ subscribeToStationsStatuses, isStreamOpen: connectionRef !== null }),
		[connectionRef, subscribeToStationsStatuses]
	);
};

export const useConnectedStationStatus = () => {
	const { subscribeToStationsStatuses } = useSystemStatus();
	const [station, setStation] = useState<IStationStatus>();
	const stationIdRef = useRef<IStationStatus>();

	// Subscribes to the stations statuses
	useEffect(() => {
		let unsubscribe = subscribeToStationsStatuses(null, (data) => {
			if (data?.stationId === undefined && stationIdRef.current !== undefined) {
				stationIdRef.current = undefined;
				setStation(undefined);
			} else if (
				data?.stationId !== undefined &&
				(stationIdRef.current?.id !== data?.stationId || stationIdRef.current?.hash !== data?.hash)
			) {
				const stationStatus = { id: data?.stationId, hash: data?.hash, state: data.state };
				stationIdRef.current = stationStatus;
				setStation(stationStatus);
			}
		});

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [subscribeToStationsStatuses]);

	return { stationStatus: station };
};
