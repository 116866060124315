import { Subject, Subscription } from 'rxjs';
import { map, filter, debounceTime } from 'rxjs/operators';
import { grpc } from '@improbable-eng/grpc-web';
import {
	dataFlowService,
	Event,
	TriggerTypeMap,
	Flow,
	Graph,
	Readout,
	EntryKey,
	FlowConfig,
	Stage
} from '@tactun/grpc-client';
import { Channel } from './dashboard.types';
import { tError, tInfo } from '../../tools/logger';

/**
 * Graph data subscriptions
 */

interface GraphSubscription {
	subscription: Subscription;
	keys: EntryKey.AsObject[];
}

const graphSubject = new Subject<Graph.AsObject[]>();
let graphSubscriptions: GraphSubscription[] = [];

export const subscribeToGraph = (
	widgetId: string,
	channels: Channel[],
	graphPeriod: number,
	next: (v: Graph.AsObject[]) => void
) => {
	period = graphPeriod ? graphPeriod : 100;
	const subscription = graphSubject
		.pipe(
			map((data) => {
				return data.filter(({ key }) => key?.widgetId === widgetId);
			}),
			filter((data) => !!data.length)
		)
		.subscribe({ next });

	const graphSubscription = {
		subscription,
		keys: channels.map(({ id, type }) => ({
			channelId: id,
			type: type,
			widgetId: widgetId
		}))
	};
	graphSubscriptions.push(graphSubscription);
	config.next(true);

	return {
		unsubscribe() {
			graphSubscriptions = graphSubscriptions.filter((gs) => gs !== graphSubscription);
			subscription.unsubscribe();
			config.next(true);
		}
	};
};

/**
 * Readout data subscriptions
 */

interface ReadoutSubscription {
	subscription: Subscription;
	key: EntryKey.AsObject;
}

const readoutSubject = new Subject<Readout.AsObject[]>();
let readoutSubscriptions: ReadoutSubscription[] = [];

export const subscribeToReadout = (widgetId: string, channel: Channel, next: (v: Readout.AsObject) => void) => {
	const subscription = readoutSubject
		.pipe(
			map((data) => data.find(({ key }) => key?.widgetId === widgetId)),
			filter((data) => !!data),
			map((data) => data as Readout.AsObject)
		)
		.subscribe({ next });

	const readoutSubscription = {
		subscription,
		key: {
			channelId: channel.id,
			type: channel.type,
			widgetId
		}
	};
	readoutSubscriptions.push(readoutSubscription);
	config.next(true);

	return {
		unsubscribe() {
			readoutSubscriptions = readoutSubscriptions.filter((gs) => gs !== readoutSubscription);
			subscription.unsubscribe();
			config.next(true);
		}
	};
};

/**
 * Stage data subscriptions
 */

const stageSubject = new Subject<Stage.AsObject | null>();
let stageSubscriptions: Subscription[] = [];

export const subscribeToStage = (next: (v: Stage.AsObject | null) => void) => {
	const subscription = stageSubject
		.pipe(
			map((data) => {
				return data;
			})
		)
		.subscribe({ next });

	stageSubscriptions.push(subscription);
	config.next(true);

	return {
		unsubscribe() {
			stageSubscriptions = stageSubscriptions.filter((gs) => gs !== subscription);
			subscription.unsubscribe();
			config.next(true);
		}
	};
};

/**
 * Events subscriptions
 * If passing triggerTypes as null, all events will be returned
 */

const eventSubject = new Subject<Event.AsObject[]>();
let silentSubscriptions: Subscription[] = [];
let eventSubscriptions: Subscription[] = [];
export const subscribeToEvents = (
	triggerTypes: TriggerTypeMap[keyof TriggerTypeMap][] | null = null,
	next: (v: Event.AsObject[]) => void,
	silent?: boolean
) => {
	const subscription = eventSubject
		.pipe(
			map((data) => {
				return data.filter(
					({ trigger }) =>
						trigger?.type && ((triggerTypes && triggerTypes.includes(trigger.type)) || triggerTypes === null)
				);
			}),
			filter((data) => !!data.length)
		)
		.subscribe({ next });

	if (silent) {
		silentSubscriptions.push(subscription);
	} else {
		eventSubscriptions.push(subscription);
	}
	config.next(true);

	return {
		unsubscribe() {
			eventSubscriptions = eventSubscriptions.filter((gs) => gs !== subscription);
			subscription.unsubscribe();
			config.next(true);
		}
	};
};

/**
 * GRPC requests
 */

const config = new Subject();
let period = 100;

const buildRequest = () => {
	const flowConfigRequest = new FlowConfig();

	flowConfigRequest.setGraphsList(
		graphSubscriptions.reduce((all: EntryKey[], { keys }) => {
			return [
				...all,
				...keys.map((key) => {
					const eKey = new EntryKey();
					eKey.setChannelId(key.channelId);
					eKey.setType(key.type);
					eKey.setWidgetId(key.widgetId);
					return eKey;
				})
			];
		}, [])
	);
	flowConfigRequest.setReadoutsList(
		readoutSubscriptions.map(({ key }) => {
			const eKey = new EntryKey();
			eKey.setChannelId(key.channelId);
			eKey.setType(key.type);
			eKey.setWidgetId(key.widgetId);
			return eKey;
		})
	);

	flowConfigRequest.setEnableEvents(true);

	flowConfigRequest.setPeriod(period);

	return flowConfigRequest;
};

let streamOpen = false;
let getDataFlowGrpcRequest: grpc.Request;

const initDataFlow = () => {
	if (process.env.REACT_APP_DEVICE_URL) {
		const myTransport = grpc.CrossBrowserHttpTransport({});
		streamOpen = true;
		const request = buildRequest();

		getDataFlowGrpcRequest = grpc.invoke(dataFlowService.DataFlow.Subscribe, {
			request: request,
			host: process.env.REACT_APP_DEVICE_URL as string,
			transport: myTransport,
			onEnd: (code: grpc.Code, message: string) => {
				streamOpen = false;
				tError(`GRPC stream end code: "${code.toString()}" \nGRPC stream end message: "${message}" \n `);

				if (code !== grpc.Code.OK) {
					tError(`Code: ${code.toString()}, Message: ${message}`);
				}
			},
			onMessage(res: grpc.ProtobufMessage) {
				const data = res.toObject() as Flow.AsObject;

				if (data.readoutsList.length) {
					readoutSubject.next(data.readoutsList);
				}
				if (data.graphsList.length) {
					graphSubject.next(data.graphsList);
				}
				if (data.eventsList.length) {
					eventSubject.next(data.eventsList);
				}

				stageSubject.next(data.stage || null);
			}
		});
	}
};

const updateDataFlowConfig = () => {
	if (process.env.REACT_APP_DEVICE_URL) {
		const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });
		const request = buildRequest();
		grpc.unary(dataFlowService.DataFlow.Configure, {
			request: request,
			host: process.env.REACT_APP_DEVICE_URL as string,
			transport: myTransport,
			onEnd: (output: grpc.UnaryOutput<grpc.ProtobufMessage>) => {
				if (output.status !== grpc.Code.OK) {
					tError(
						`GRPC stream end code: "${output.status.toString()}" \nGRPC stream end message: "${output.message}" \n `
					);
				}
			}
		});
	}
};

const closeDataFlowConfig = () => {
	streamOpen = false;
	getDataFlowGrpcRequest?.close();
};

config.pipe(debounceTime(1)).subscribe({
	next: () => {
		const noSubscribers = !readoutSubscriptions.length && !graphSubscriptions.length && !eventSubscriptions.length;
		if (noSubscribers) {
			tInfo('No subscriptions closing stream');

			closeDataFlowConfig();
		} else if (!streamOpen) {
			tInfo('Open Stream');
			initDataFlow();
		} else {
			updateDataFlowConfig();
		}
	}
});

export const updateDataFlowStream = () => {
	config.next(true);
};
