import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { ActuatorFormType } from '../actuators.types';
import { actuatorFormSchema } from '../actuators.schemas';
import ActuatorForm from '../components/ActuatorForm';
import {
	useActuator,
	useActuatorsManufacturers,
	useAvailableStationChannelsForActuatorType,
	useSaveActuator
} from '../actuators.hooks';
import { useEntitiesAsList, useNumberEnumList, useStringsAsList } from '../../../hooks';
import { responseFormConverter } from '../actuators.converters';
import { useStationName } from '../../Stations/stations.hooks';
import { ActuatorsTypes } from '../actuators.enums';
import { useTranslation } from 'react-i18next';
import { ChannelType, Direction, IStationChannelResponseDto, useUnusedStationChannels } from '../../StationChannels';
import { useCurrentEntityInOptions } from '../../../tools';
import CreateEditPageTemplate from '../../../components/CreateEditPageTemplate';

const defaultValues: Partial<ActuatorFormType> = {
	name: '',
	manufacturer: '',
	stationChannelId: undefined,
	type: undefined,
	stationId: '',
	serialNumber: '',
	s1EnableBitChIds: null,
	s2EnableBitChIds: null
};

const actuatorFormId = 'actuatorFormId';

const ActuatorContainer: React.FC = () => {
	const { t } = useTranslation('actuators');
	const navigate = useNavigate();
	const { stationId, actuatorId } = useParams();
	const { stationName } = useStationName(stationId);
	const { actuatorDto: currentActuatorDto } = useActuator(actuatorId);

	const form = useForm<ActuatorFormType>({
		defaultValues: { ...defaultValues, stationId },
		mode: 'onBlur',
		resolver: yupResolver(actuatorFormSchema)
	});
	const { watch, setValue, handleSubmit, reset } = form;
	const type = watch('type');

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/actuators`);
	}, [navigate, stationId]);

	const { save, isLoading } = useSaveActuator(actuatorId, handleBack);
	const handleSave = handleSubmit((data) => save(data));

	// Setup data for edit
	useEffect(() => {
		if (currentActuatorDto && stationId) {
			reset({ ...responseFormConverter(currentActuatorDto), stationId });
		}
	}, [currentActuatorDto, reset, stationId]);

	// Reset form with default values on unmount
	useEffect(() => {
		return () => reset({ ...defaultValues, stationId: '' });
	}, [reset]);

	const { availableStationChannels } = useAvailableStationChannelsForActuatorType(stationId, type);
	const stationChannels = useCurrentEntityInOptions<IStationChannelResponseDto>(
		availableStationChannels,
		currentActuatorDto?.type === type ? currentActuatorDto?.stationChannel : undefined
	);
	const stationChannelsOptions = useEntitiesAsList(stationChannels);

	const stationChannelId = watch('stationChannelId');
	useEffect(() => {
		if (
			stationChannelsOptions &&
			stationChannelId &&
			!stationChannelsOptions.find(({ value }) => value === stationChannelId)
		) {
			setValue('stationChannelId', '');
		}
	}, [setValue, stationChannelId, stationChannelsOptions]);

	const { stationChannels: actuatorEnableBits } = useUnusedStationChannels(
		stationId,
		[ChannelType.DIGITAL_IO_5V, ChannelType.DIGITAL_IO_24V],
		Direction.OUTPUT
	);
	const enableBitsOptions = useEntitiesAsList(actuatorEnableBits);

	const manufacturers = useActuatorsManufacturers();
	const manufacturerOptions = useStringsAsList(manufacturers);

	const actuatorsTypes = useNumberEnumList(ActuatorsTypes);

	const title = useMemo<string>(() => {
		return !actuatorId ? t('Create Actuator') : t('Edit Actuator');
	}, [actuatorId, t]);

	return (
		<CreateEditPageTemplate
			isLoading={isLoading}
			title={title}
			stationName={stationName}
			formId={actuatorFormId}
			onBack={handleBack}
		>
			<ActuatorForm
				formId={actuatorFormId}
				form={form}
				types={actuatorsTypes}
				enableBitsOptions={enableBitsOptions}
				stationChannels={stationChannelsOptions}
				onSave={handleSave}
				manufacturerOptions={manufacturerOptions}
			/>
		</CreateEditPageTemplate>
	);
};

export default React.memo(ActuatorContainer);
