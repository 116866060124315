import axios from '../../httpCommon';
import { VariableRequestDto, VariableResponseDto } from './variables.types';

export const getStationVariables = async (stationId?: string) => {
	const response = await axios.get(`/cassiopeia/station-variable/stations/${stationId}`, {
		params: { page: 0, size: 100 }
	});

	return response.data.response.data as VariableResponseDto[];
};

export const createVariable = async (variable: VariableRequestDto) => {
	const response = await axios.post('/cassiopeia/station-variable', variable);
	return response.data.response as VariableResponseDto;
};

export const updateVariable = async (variable: VariableRequestDto) => {
	const response = await axios.put('/cassiopeia/station-variable', variable);
	return response.data.response as VariableResponseDto;
};

export const getVariable = async (id: string) => {
	const response = await axios.get(`/cassiopeia/station-variable/${id}`);

	return response.data.response as VariableResponseDto;
};

export const checkName = async (name: string, stationId: string, id?: string): Promise<boolean> => {
	const response = await axios.get('/cassiopeia/station-variable/isNameUnique', {
		params: { name: name, stationUuid: stationId, uuid: id }
	});
	return response.data.response.isUnique === true;
};

export const checkTesVarName = async (name: string, testId: string, id?: string): Promise<boolean> => {
	const response = await axios.get('/cassiopeia/test-variable/isNameUnique', {
		params: { name: name, testUuid: testId, uuid: id }
	});
	return response.data.response.isUnique === true;
};

export const getTestVariables = async (testId: string) => {
	const response = await axios.get(`/cassiopeia/test-variable/tests/${testId}`);
	return response.data.response.data as VariableResponseDto[];
};

export const createTestVariable = async (variable: VariableRequestDto, testId: string) => {
	const response = await axios.post('/cassiopeia/test-variable', variable);
	return response.data.response as VariableResponseDto;
};

export const updateTestVariable = async (variable: VariableRequestDto) => {
	const response = await axios.put('/cassiopeia/test-variable', variable);
	return response.data.response as VariableResponseDto;
};

export const getTestVariable = async (id: string) => {
	const response = await axios.get(`/cassiopeia/test-variable/${id}`);

	return response.data.response as VariableResponseDto;
};
