import axios from '../../httpCommon';
import {
	TestLoopResponseDto,
	TestLoopRequestDto,
	TestLoopValidationRequestDto,
	TestLoopValidateResponseDto
} from './testLoops.types';

export const getTestLoops = async (testId: string) => {
	const response = await axios.get(`/quasar/tests/${testId}/loops`);

	return response.data as TestLoopResponseDto[];
};

export const createTestLoop = async (variable: TestLoopRequestDto, testId: string) => {
	const response = await axios.post(`/quasar/tests/${testId}/loops`, variable);

	return response.data as TestLoopResponseDto;
};

export const updateTestLoop = async (variable: TestLoopRequestDto, variableId: string) => {
	const response = await axios.put(`/quasar/tests/loops/${variableId}`, variable);
	return response.data as TestLoopResponseDto;
};

export const getTestLoop = async (id: string) => {
	const response = await axios.get(`/quasar/tests/loops/${id}`);

	return response.data as TestLoopResponseDto;
};

export const validateTestLoop = async (
	loop: TestLoopValidationRequestDto,
	testId: string
): Promise<TestLoopValidateResponseDto> => {
	try {
		const response = await axios.post(`/quasar/tests/${testId}/loops/validate`, loop);

		return response.data as TestLoopValidateResponseDto;
	} catch (error) {
		// @ts-ignore
		if (error?.response?.status >= 500) {
			return { message: 'Server error', result: false };
		}

		return { message: 'Unknown error', result: false };
	}
};
