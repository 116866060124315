import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UnlinkResult, UnlinkResultMap } from '@tactun/grpc-client';
import { toast } from 'react-toastify';
import ProductsTable from '../components/ProductsTable';
import { IProductListItem, useBuildProduct, useDownloadProduct, useProducts } from '../';
import { ProductCrudActions } from '../products.enums';
import { useConnectionStore } from '../../Connection/connection.store';
import { ConfirmationModal } from '../../../components';
import { deleteConfirmModalId } from '../products.constants';
import { unlinkDevice } from '../products.grpc';
import { getKeyByValue } from '../../../tools/grpcHelper';
import { useKeycloakContext } from '@tactun/keycloak-axios-provider';

const ProductsTableContainer: FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('products');
	const [entityForDelete, setEntityForDelete] = useState<IProductListItem | undefined>();
	const [isDeleting, setIsDeleting] = useState<boolean>();
	const { isLoading, products = [], deleteProduct: deleteFromCache } = useProducts();
	const { connectedDevice } = useConnectionStore();
	const keycloakContext = useKeycloakContext();
	const { build } = useBuildProduct();
	const { download, isDownloading } = useDownloadProduct();

	const handleAction = useCallback(
		(type: ProductCrudActions, data?: IProductListItem) => {
			switch (type) {
				case ProductCrudActions.CREATE:
					navigate('./create');
					break;
				case ProductCrudActions.EDIT:
					navigate(`./edit/${data?.id}`);
					break;
				case ProductCrudActions.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case ProductCrudActions.DELETE:
					if (data) {
						setEntityForDelete(data);
						ConfirmationModal.show(deleteConfirmModalId);
					}
					break;
				case ProductCrudActions.BUILD:
					if (data) {
						build(data.id);
					}
					break;
				case ProductCrudActions.DOWNLOAD:
					if (data) {
						download(data.id);
					}
					break;
			}
		},
		[build, download, navigate]
	);

	const handleDeleteConfirmation = useCallback(async () => {
		if (entityForDelete) {
			setIsDeleting(true);
			const unlinkResult = await unlinkDevice(
				entityForDelete,
				process.env.REACT_APP_API_BASE_URL as string,
				keycloakContext?.keycloak?.token as string
			);
			if (unlinkResult.status === UnlinkResult.UNLINK_SUCCESS) {
				deleteFromCache(entityForDelete.id);
			} else {
				toast.error(
					t('Unlink error {{error}}', { error: getKeyByValue<UnlinkResultMap>(UnlinkResult, unlinkResult.status) })
				);
			}
			setIsDeleting(false);
			setEntityForDelete(undefined);
			ConfirmationModal.hide(deleteConfirmModalId);
		}
	}, [deleteFromCache, entityForDelete, keycloakContext?.keycloak?.token, t]);

	return (
		<>
			<ProductsTable
				onAction={handleAction}
				data={products}
				isLoading={isLoading}
				isDownloading={isDownloading}
				connectedSerialNumber={connectedDevice?.sn}
			/>
			<ConfirmationModal
				title={t('Warning')}
				confirmBtnText={t('Ok')}
				id={deleteConfirmModalId}
				onConfirm={handleDeleteConfirmation}
				handleClose={() => ConfirmationModal.hide(deleteConfirmModalId)}
				isLoading={isDeleting}
			>
				{t('You are about to delete "{{productName}}" product. This action is irreversible.', {
					productName: entityForDelete?.name
				})}
			</ConfirmationModal>
		</>
	);
};

export default ProductsTableContainer;
