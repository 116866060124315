import React, { useRef } from 'react';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

const defaultStyle = {
	cursor: 'move'
};

export interface DragItemProps {
	children: React.ReactNode;
	style: Record<string, any>;
	index: number;
	group: string;
	move: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
	index: number;
	id: string;
	type: string;
}

const DragAndDrop: React.FC<DragItemProps> = ({ children, index, move, group, style }) => {
	// Drag & drop
	const ref = useRef<HTMLDivElement>(null);

	const [{ handlerId }, drop] = useDrop<
		DragItem,
		void,
		{
			handlerId: Identifier | null;
		}
	>({
		accept: group,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId()
			};
		},
		hover(item: DragItem, monitor) {
			if (!ref.current || item.index === index) return;

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			const dragIndex = item.index;
			const hoverIndex = index;
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

			// Time to actually perform the action
			move(dragIndex, hoverIndex);

			item.index = hoverIndex;
		}
	});

	const [{ isDragging }, drag] = useDrag({
		type: group,
		item: () => ({ index }),
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging()
		})
	});

	const opacity = isDragging ? 0 : 1;
	drag(drop(ref));

	return (
		<div ref={ref} style={{ ...defaultStyle, ...style, opacity }} data-handler-id={handlerId}>
			{children}
		</div>
	);
};

export default DragAndDrop;
