import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { CalibrationFormType, CalibrationRequestDto } from '../calibrations.types';
import { calibrationFormSchema } from '../calibrations.schemas';
import { useCalibration, useSaveCalibration } from '../calibrations.hooks';
import { useNumberEnumList } from '../../../hooks';
import { formRequestConverter, responseFormConverter } from '../calibrations.converters';
import { useSensorCalibration } from '../calibrations.grpc';
import { useSensor } from '../../Sensors';
import { CalibrationsTypes } from '../calibrations.enums';
import { ChannelType } from '../../StationChannels';
import { Button, Modal, useIsChanged } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import CalibrationForm from '../components/CalibrationForm';

const shuntCalibrationModalId = 'shunt-calibration-warning';

const defaultValues = {
	type: undefined,
	unitId: undefined,
	tedsId: '',
	offset: 0,
	slope: 1,
	data: [],
	sensorId: undefined,
	isShuntCalibrationEnabled: false,
	isManualTedsIdEnabled: undefined
};

export interface ICalibrationContainerProps {
	stationId?: string;
	sensorId?: string;
	isInWidget?: boolean;
	onSave?: (calibration: CalibrationRequestDto, calibrationId?: string) => void;
}

const CalibrationContainer: React.FC<ICalibrationContainerProps> = ({
	stationId,
	sensorId,
	onSave,
	isInWidget = false
}) => {
	const { t } = useTranslation('calibrations');
	const navigate = useNavigate();

	const { sensor: currentSensor } = useSensor(sensorId);
	const { connect, disConnect, isConnected, data: calibrationData } = useSensorCalibration();
	const { calibrationDto, updateCalibration } = useCalibration(sensorId);

	const form = useForm<CalibrationFormType>({
		defaultValues: { ...defaultValues, sensorId },
		mode: 'onBlur',
		resolver: yupResolver(calibrationFormSchema)
	});
	const { handleSubmit, reset } = form;
	const isShuntCalibrationEnabled = form.watch('isShuntCalibrationEnabled');
	const isInversePolarity = form.watch('isInversePolarity');
	const calibrationsTypes = useNumberEnumList(CalibrationsTypes);

	const { save } = useSaveCalibration(sensorId, () => {
		updateCalibration();
		navigate(`/stations/${stationId}/sensors`);
	});

	const handleSave = handleSubmit((data) => {
		const filteredData = formRequestConverter(data);

		if (isInWidget && onSave) {
			onSave(filteredData, calibrationDto?.id);
		} else {
			save(filteredData, data.id);
		}
	});

	const handleCalibrationModeChange = useCallback(
		(isRealTime: boolean) => {
			if (!currentSensor) return;

			if (isRealTime) {
				disConnect();

				connect(currentSensor, isShuntCalibrationEnabled, isInversePolarity || false, !isInWidget);
			} else {
				disConnect();
			}
		},
		[connect, currentSensor, disConnect, isShuntCalibrationEnabled, isInversePolarity, isInWidget]
	);

	const handleShuntCalibrationConfirmation = useCallback(() => {
		Modal.hide(shuntCalibrationModalId);
		handleCalibrationModeChange(isConnected);
	}, [handleCalibrationModeChange, isConnected]);

	// Handle Calibration stream disConnection
	useEffect(() => {
		return () => {
			disConnect();
		};
	}, [disConnect]);

	// Setup data for edit
	useEffect(() => {
		if (calibrationDto && sensorId) {
			reset(
				{ ...defaultValues, ...responseFormConverter(calibrationDto), sensorId },
				{
					keepDirty: false,
					keepTouched: false
				}
			);
		}
	}, [calibrationDto, reset, sensorId]);

	// for First Time init inverse polarity
	useEffect(() => {
		if (!calibrationDto && currentSensor) {
			reset(
				{ ...defaultValues, isInversePolarity: currentSensor.inversePolarity, sensorId },
				{
					keepDirty: false,
					keepTouched: false
				}
			);
		}
	}, [calibrationDto, currentSensor, reset, sensorId]);

	useEffect(() => {
		return () => {
			reset({ ...defaultValues });
		};
	}, [reset]);

	// Shunt Calibration Change
	const isShuntCalibrationChanged = useIsChanged(isShuntCalibrationEnabled);
	useEffect(() => {
		if (isConnected && isShuntCalibrationChanged) {
			if (isShuntCalibrationEnabled) {
				Modal.show(shuntCalibrationModalId);
			} else {
				handleCalibrationModeChange(isConnected);
			}
		}
	}, [handleCalibrationModeChange, isConnected, isShuntCalibrationChanged, isShuntCalibrationEnabled]);

	// Shunt Calibration Change
	const isInversePolarityChanged = useIsChanged(isInversePolarity);
	useEffect(() => {
		if (isConnected && isInversePolarityChanged) {
			handleCalibrationModeChange(isConnected);
		}
	}, [handleCalibrationModeChange, isConnected, isInversePolarityChanged]);

	return (
		<>
			<CalibrationForm
				onCalibrationModeChange={handleCalibrationModeChange}
				form={form}
				types={calibrationsTypes}
				onSave={handleSave}
				calibrationData={calibrationData}
				sensor={currentSensor}
				isSignalConditioning={currentSensor?.stationChannel?.type === ChannelType.SIGNAL_CONDITIONING}
				isInWidget={isInWidget}
			/>
			<Modal id={shuntCalibrationModalId}>
				<Modal.Header>{t('Warning')}</Modal.Header>
				<Modal.Content>
					{t('Please note that Shunt calibration will be disabled once the calibration is completed.')}
				</Modal.Content>
				<Modal.Footer>
					<Button color="secondary" variant="contained" onClick={handleShuntCalibrationConfirmation}>
						{t('Ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default React.memo(CalibrationContainer);
