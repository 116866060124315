import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, useModalData } from '@tactun/ui';
import ControllerEditModal from '../components/ControllerEditModal';
import ControllerEditModalContent from '../components/ControllerEditModalContent';
import { controllerEditSchema } from '../connection.schemas';
import { controllerEditModalId, editDefaultValues } from '../connection.const';
import { controllerEditFormTypeToDevice, deviceToControllerEditForm } from '../connection.converters';
import { useManuallyAddedDevicesStore } from '../connection.store';

const ControllerEditContainer = () => {
	const modalData = useModalData(controllerEditModalId);
	const { updateDevice } = useManuallyAddedDevicesStore();
	const form = useForm({
		defaultValues: editDefaultValues,
		mode: 'onBlur',
		resolver: yupResolver(controllerEditSchema)
	});
	const { reset } = form;

	const handleSaveEdit = form.handleSubmit(async (data) => {
		if (data.isManuallyAdded) {
			updateDevice(controllerEditFormTypeToDevice(data));
		}
		Modal.hide(controllerEditModalId);
	});

	useEffect(() => {
		reset(modalData?.device ? deviceToControllerEditForm(modalData.device) : editDefaultValues);
	}, [modalData, reset]);

	return (
		<ControllerEditModal>
			<ControllerEditModalContent onSave={handleSaveEdit} form={form} />
		</ControllerEditModal>
	);
};

export default ControllerEditContainer;
