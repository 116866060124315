import { GraphTools, ScaleTypes, Axis } from './graphWidget.enums';
import { AxisNames } from './graphWidget.types';

export const GraphZoomToolModeMap: { [key: string]: 'x' | 'y' | 'xy' } = {
	[GraphTools.ZOOM_X]: 'x',
	[GraphTools.ZOOM_Y]: 'y',
	[GraphTools.ZOOM_XY]: 'xy'
};

export const GraphPlotActions = {
	TOGGLE_VISIBILITY: 'toggle_visibility',
	DELETE: 'delete',
	CHANGE_COLOR: 'change_color'
};

export const DateRate = { min: 10, max: 1000 };
export const dataPointsMaxCount = DateRate.max * 10 * 30;

export const ScaleTypeOptions = [
	{ value: ScaleTypes.LINEAR, label: 'Linear' },
	{ value: ScaleTypes.LOGARITHMIC, label: 'Logarithmic' }
];

export const ChartAxisNames: AxisNames = {
	[Axis.X]: 'X',
	[Axis.LEFT_Y]: 'Left Y',
	[Axis.RIGHT_Y]: 'Right Y'
};

export const GraphColors = ['#829F23', '#4990B8', '#F35909', '#B9D561', '#84C7EC'];
