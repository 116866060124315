import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import cx from 'classnames';
import type { Except } from 'type-fest';
import { TreeSelect, TreeSelectProps } from 'primereact/treeselect';
import FieldFrame2, { IFieldFrameProps } from '../FieldFrame2';
import Icon from '../../Icon';
import styles from './TreeDropdown.module.scss';
export interface TreeDropdownProps
	extends Except<IFieldFrameProps, 'children' | 'onMouseDown'>,
		Except<TreeSelectProps, 'onBlur' | 'onFocus' | 'size'> {}

const TreeDropdown = forwardRef<HTMLInputElement, TreeDropdownProps>(
	(
		{
			label,
			style,
			className,
			containerClassName,
			errorPosition,
			error,
			disabled,
			size,
			leftIcon,
			rightIcon,
			onFocus,
			onBlur,
			showClear,
			...inputProps
		},
		ref
	) => {
		const innerRef = React.useRef<HTMLInputElement>(null);
		const treeSelectRef = React.useRef<TreeSelect>(null);

		useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);

		const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement, Element>) => {
			e?.stopPropagation();
		}, []);

		const handleHide = useCallback(() => {
			if (innerRef.current && onBlur) {
				onBlur({} as React.FocusEvent<HTMLElement, Element>);
			}
		}, [onBlur]);

		return (
			<FieldFrame2
				label={label}
				style={style}
				className={cx(className, styles.input)}
				containerClassName={containerClassName}
				errorPosition={errorPosition}
				error={error}
				disabled={disabled}
				size={size}
				leftIcon={
					showClear && inputProps.value && Object.keys(inputProps.value).length ? (
						<Icon
							name="zoom-delete"
							className={styles.clearIcon}
							size={12}
							onClick={() => {
								treeSelectRef.current?.clear();
							}}
						/>
					) : undefined
				}
				rightIcon={rightIcon}
				onFocus={onFocus}
				onBlur={onBlur}
			>
				{({ withLabel, isFocused, ...defaultProps }) => {
					return (
						<TreeSelect {...defaultProps} {...inputProps} onBlur={handleBlur} onHide={handleHide} ref={treeSelectRef} />
					);
				}}
			</FieldFrame2>
		);
	}
);
TreeDropdown.displayName = 'TreeDropdown';

export default React.memo(TreeDropdown);
