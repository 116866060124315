import { TreeNode } from 'primereact/treenode';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MeasurementResponseDto } from '../Measurements';
import { WaveformEndChannelType } from './waveforms.enums';
import { TestMeasurementResponseDto } from '../TestMeasurements';

export const createControllerName = (controllerName: string, prefix?: string) =>
	prefix ? `${prefix}.${controllerName}` : controllerName;

export const useControllerName = (controllerName: string, prefix?: string) => {
	return useMemo(() => createControllerName(controllerName, prefix), [controllerName, prefix]);
};

export const useEndChannel = (
	meas?: (MeasurementResponseDto | TestMeasurementResponseDto)[],
	withoutTime?: boolean
) => {
	const { t } = useTranslation();

	return useMemo(() => {
		const options: TreeNode[] = [] as TreeNode[];
		if (meas?.length) {
			options.push({
				label: t('Measurements'),
				selectable: false,
				key: WaveformEndChannelType.MEASUREMENT,
				children: meas.map(({ id, name }) => ({
					key: id,
					label: name,
					selectable: true
				}))
			});
		}
		options.push({ label: t('Set Point'), selectable: true, key: WaveformEndChannelType.SET_POINT });
		if (withoutTime !== true) {
			options.push({ label: t('Time'), selectable: true, key: WaveformEndChannelType.TIME });
		}

		return options;
	}, [meas, t, withoutTime]);
};

export const useEndChannelMeta = (
	endChannel: string | WaveformEndChannelType,
	defaultTimeUnitName: string,
	feedUnitName: string,
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[]
) => {
	const endChannelType = useMemo(() => {
		if (endChannel === undefined || endChannel === null) return null;

		if (endChannel === WaveformEndChannelType.TIME || endChannel === WaveformEndChannelType.SET_POINT) {
			return endChannel as WaveformEndChannelType;
		} else {
			return WaveformEndChannelType.MEASUREMENT;
		}
	}, [endChannel]);

	const endChannelUnit = useMemo(() => {
		switch (endChannelType) {
			case WaveformEndChannelType.TIME:
				return defaultTimeUnitName;
			case WaveformEndChannelType.SET_POINT:
				return feedUnitName;
			case WaveformEndChannelType.MEASUREMENT:
				const measurement = measurements.find((measurement) => measurement.id === endChannel);
				return measurement ? measurement.unit.name : '';
		}
	}, [defaultTimeUnitName, endChannel, endChannelType, feedUnitName, measurements]);

	return { endChannelType, endChannelUnit };
};

export const useIsInvertEndConditionDisabled = (
	endChannel: NonNullable<string | WaveformEndChannelType | undefined>,
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[],
	controlChannelSensorId?: string
) => {
	return useMemo(() => {
		if (endChannel === WaveformEndChannelType.SET_POINT || endChannel === WaveformEndChannelType.TIME) {
			return true;
		} else {
			const endChannelMeasurementSensorId = measurements.find((measurement) => measurement.id === endChannel)?.sensor
				?.id;
			return endChannelMeasurementSensorId !== undefined && controlChannelSensorId === endChannelMeasurementSensorId;
		}
	}, [controlChannelSensorId, endChannel, measurements]);
};
