import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlStageFormType } from '../controlStage.types';
import AxesConfigurationForm from '../components/AxesConfigurationForm';
import { useAxisControlChannels, useControlChannel } from '../../ControlChannels';
import { useEntitiesAsList } from '../../../hooks';
import { controlChannelToAxisConfigurationForm } from '../controlStage.converters';

interface AxesConfigurationContainerProps {
	axisId: string;
	index: number;
	isRunning?: boolean;
	isTestStage?: boolean;
	updateConfig?: (index: number, changed: string) => void;
}

const AxesConfigurationContainer: React.FC<AxesConfigurationContainerProps> = ({
	axisId,
	updateConfig,
	index,
	isTestStage,
	isRunning
}) => {
	const { controlChannels } = useAxisControlChannels(axisId);
	const controlChannelsOptions = useEntitiesAsList(controlChannels);
	const { watch, getValues, reset } = useFormContext<ControlStageFormType>();
	const axesConfigs = watch('axesConfigs');
	const current = axesConfigs[index];
	const { controlChannel } = useControlChannel(current.controlChannelId);

	useEffect(() => {
		if (controlChannel) {
			const csData = getValues();
			const currentAxis = csData.axesConfigs.find((ac) => ac.axisId === axisId);

			if (currentAxis) {
				//TODO: its ugly but fast (convertor mutating second argument if its passed)
				controlChannelToAxisConfigurationForm(controlChannel, currentAxis, !isTestStage);
			}

			reset(csData, { keepDirty: true, keepTouched: true });
		}
	}, [axisId, controlChannel, getValues, isTestStage, reset]);

	return (
		<AxesConfigurationForm
			index={index}
			controlChannels={controlChannelsOptions}
			controlChannel={controlChannel}
			isRunning={isRunning}
			updateConfig={updateConfig}
		/>
	);
};

export default AxesConfigurationContainer;
