import React, { useEffect } from 'react';
import { InputNumber, RadioSelect } from '@tactun/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { useNumberEnumList } from '../../../../hooks';
import { ButtonForm } from '../../buttonWidget.types';
import { FrameOrientation } from '../../buttonWidget.enums';
import { customWidgetDefaultButton } from '../../buttonWidget.const';
import styles from './ButtonWidgetForm.module.scss';
import CustomButtonForm from '../CustomButtonForm';
import { ICustomWidgetForm } from '../../../CustomWidgets';

interface ButtonWidgetFormProps {
	frameIndex: number;
	stationId: string;
}

const ButtonWidgetForm: React.FC<ButtonWidgetFormProps> = ({ frameIndex, stationId }) => {
	const { t } = useTranslation('widgets');
	const {
		watch,
		setValue,
		getValues,
		control,
		formState: { errors }
	} = useFormContext<ICustomWidgetForm>();

	const buttonOrientations = useNumberEnumList(FrameOrientation, 'widgets');

	const activeSubFrame = watch('activeSubframe');
	const activeButton = watch('activeButton');
	// TODO check why the number of buttons is not number
	const numberOfButtonsNumber = watch(`subFrames.${frameIndex}.numberOfButtons`);
	const numberOfButtons = numberOfButtonsNumber ? numberOfButtonsNumber + '' : undefined;

	useEffect(() => {
		if (numberOfButtons && parseInt(numberOfButtons, 10) > 0) {
			let buttons: ButtonForm[] = [];
			const widgetForm = getValues() as ICustomWidgetForm;
			const current = widgetForm.subFrames?.[frameIndex].buttons;

			new Array(parseInt(numberOfButtons, 10)).fill(0).forEach((_, index) => {
				const button = current?.[index] || customWidgetDefaultButton;

				buttons.push(button);
			});

			widgetForm.subFrames[frameIndex].buttons = buttons;

			setValue('subFrames', widgetForm.subFrames);
		}
	}, [frameIndex, getValues, numberOfButtons, setValue]);

	const hasActiveButton = activeButton !== undefined && activeButton !== null && activeSubFrame === frameIndex;

	return (
		<div className={styles.btnWidgetContainer} key={`${frameIndex}-${activeButton}`}>
			<div className={styles.subframeForm}>
				<Controller
					name={`subFrames.${frameIndex}.orientation`}
					control={control}
					render={({ field }) => (
						<RadioSelect
							{...field}
							label={t('Button orientation in subframe')}
							className={styles.radioCustom}
							data-testid={'subFrames.orientation'}
							options={buttonOrientations}
						/>
					)}
				/>
				<Controller
					name={`subFrames.${frameIndex}.numberOfButtons`}
					control={control}
					render={({ field }) => (
						<InputNumber
							className={cx(styles.numberInput, {
								[styles.disableMinus]: numberOfButtons === '1' || !numberOfButtons,
								[styles.disablePlus]: numberOfButtons === '6'
							})}
							data-testid={'subFrames.numberOfButtons'}
							{...field}
							label={t('Number of buttons in the subframe*')}
							error={errors.subFrames?.[frameIndex]?.numberOfButtons?.message}
						/>
					)}
				/>
			</div>
			{hasActiveButton && <CustomButtonForm frameIndex={frameIndex} stationId={stationId} />}
		</div>
	);
};

export default ButtonWidgetForm;
