import { object, string, mixed, array, number } from 'yup';
import { ButtonDefaultState, ButtonShape, FrameOrientation } from '../ButtonWidget/buttonWidget.enums';

export const buttonWidgetButtonForm = object().shape({
	id: string(),
	name: string(),
	defaultState: mixed<ButtonDefaultState>(),
	shape: mixed<ButtonShape>(),
	releasedColor: string(),
	pressedColor: string(),
	releasedText: string(),
	releasedTextColor: string(),
	pressedText: string(),
	pressedTextColor: string(),
	releasedIcon: string().nullable(),
	pressedIcon: string().nullable(),
	tooltip: string(),
	rules: array()
});

export const buttonWidgetSubframeFormSchema = object()
	.shape({
		id: string(),
		width: number(),
		height: number(),
		orientation: mixed<FrameOrientation>().required(),
		numberOfButtons: number().min(1),
		buttons: array().of(buttonWidgetButtonForm)
	})
	.required();
