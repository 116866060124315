import React from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { testsMeasurementsTableModalId } from '../../testMeasurements.const';
import styles from './TestMeasurementsModal.module.scss';

interface ITestMeasurementsModalProps {
	children: React.ReactNode;
}

const TestMeasurementsModal: React.FC<ITestMeasurementsModalProps> = ({ children }) => {
	const { t } = useTranslation('testMeasurements');

	return (
		<Modal id={testsMeasurementsTableModalId} className={styles.testMeasurementsForm} shouldCloseOnOverlayClick={false}>
			<Modal.Header>{t('Measurements')}</Modal.Header>
			<Modal.Content>{children}</Modal.Content>
			<Modal.Footer>
				<Button onClick={() => Modal.hide(testsMeasurementsTableModalId)} color="secondary" variant="contained">
					{t('Done')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(TestMeasurementsModal);
