import { ChannelType } from './stationChannels.enums';

export const rangeToUserFriendlyMap: Record<string, string> = {
	_0_5_V: '0 ÷ 5 V',
	_0_10_V: '0 ÷ 10 V',
	_PM_2_5_V: '±2.5 V',
	_PM_5_V: '±5 V',
	_PM_10_V: '±10 V',
	_0_6_V: '0 ÷ 6 V',
	_0_12_V: '0 ÷ 12 V',
	_M_6_0_V: '-6 ÷ 0 V',
	_M_12_0_V: '-12 ÷ 0 V',
	_PM_3_V: '±3 V',
	_PM_6_V: '±6 V',
	_PM_12_V: '±12 V',
	_PM_24_V: '±24 V',
	_0_20_mA: '0 ÷ 20 mA',
	_0_24_mA: '0 ÷ 24 mA',
	_4_20_mA: '4 ÷ 20 mA',
	_PM_24_mA: '±24 mA',
	_0_3_mA: '0 ÷ 3.125 mA',
	_0_6_mA: '0 ÷ 6.25 mA',
	_0_12_mA: '0 ÷ 12.5 mA',
	_0_25_mA: '0 ÷ 25 mA',
	_0_50_mA: '0 ÷ 50 mA',
	_0_100_mA: '0 ÷ 100 mA',
	_0_200_mA: '0 ÷ 200 mA',
	_PM_50_mA: '±50 mA',
	_PM_100_mA: '±100 mA'
};

export const analogOutChannels = [
	ChannelType.ANALOG_OUT_10V,
	ChannelType.ANALOG_OUT_10V_24MA,
	ChannelType.ANALOG_OUT_300MA,
	ChannelType.ANALOG_OUT_100MA
];

export const currentBasedAnalogOutChannels = [ChannelType.ANALOG_OUT_300MA, ChannelType.ANALOG_OUT_100MA];
