import {
	AxisPosition,
	AxisScrollStrategies,
	AxisTickStrategies,
	ChartXY,
	ColorHEX,
	MouseStyles,
	SolidFill,
	SolidLine,
	Themes,
	UIBackground,
	emptyFill
} from '@arction/lcjs';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { LCContext } from './LightningChartProvider';
import { GraphInfo, SeriesInfo } from './realTimeLineGraph.types';
import { useCharts } from './realTimeLineGraph.store';
import { GraphActions } from './realTimeLineGraph.enums';

const getTimeWindowSizeInMS = (timeWindowSizeSecond?: number) => (timeWindowSizeSecond ?? 60) * 1000;

export const useRealTimeLineGraph = (container: HTMLDivElement | null, graphId?: string) => {
	const lcHost = useContext(LCContext);
	const isInit = useRef(false);
	const {
		graphs,
		initGraph,
		deleteGraph,
		addSeries,
		removeSeries,
		getSerries,
		getAllSerries,
		getAllSerriesWithIds,
		pauseGraph,
		resumeGraph,
		isPaused
	} = useCharts();

	const currentGraph = useMemo(() => graphs.get(graphId ?? ''), [graphs, graphId]);

	// This is the only place where the chart is initialized
	useEffect(() => {
		if (isInit.current === false && graphId && lcHost != null && container != null && !currentGraph && initGraph) {
			const graph = lcHost
				.ChartXY({
					theme: Themes.light,
					container
				})
				.setTitle('');
			initGraph(graphId, graph);
			isInit.current = true;
		} else if (currentGraph && graphId) {
			isInit.current = true;
		}
	}, [container, lcHost, initGraph, graphId, currentGraph]);

	// This is the only place where the chart is disposed
	useEffect(() => {
		return () => {
			if (currentGraph != null) {
				deleteGraph(graphId ?? '');
			}
		};
	}, [currentGraph, deleteGraph, graphId]);

	const addData = useCallback(
		(
			plotId: string,
			points: {
				[key: string]: unknown;
			}[]
		) => {
			if (graphId) {
				const currentSeries = getSerries(graphId, plotId);
				currentSeries?.appendJSON(points);
			}
		},
		[getSerries, graphId]
	);

	const addSamples = useCallback(
		(plotId: string, samples: { xValues: number[]; yValues: number[] }) => {
			if (graphId) {
				const currentSeries = getSerries(graphId, plotId);
				currentSeries?.appendSamples(samples);
			}
		},
		[getSerries, graphId]
	);

	const configureChart = useCallback(
		(configs: GraphInfo) => {
			if (currentGraph) {
				// Configure X Axis
				if (configs.xAxisType) {
					if (configs.xAxisType === 'number') {
						currentGraph.getDefaultAxisX()?.setAnimationScroll(false);
					} else {
						const timeWindowSizeMsec = getTimeWindowSizeInMS(configs?.timeWindowSize);

						currentGraph
							.getDefaultAxisX()
							?.setTickStrategy(AxisTickStrategies.Time)
							?.setScrollStrategy(AxisScrollStrategies.progressive)
							?.setDefaultInterval({ start: -1 * timeWindowSizeMsec, end: 0, stopAxisAfter: false })
							?.setAnimationScroll(false);
					}
				}
				// Configure X Axis Title
				if (configs.xAxisTitle) {
					currentGraph.getDefaultAxisX()?.setTitle(configs.xAxisTitle).setMouseInteractions(false);
				}
				// Configure Left Y Axis
				if (configs.leftYAxisType !== undefined || configs.leftYAxisTitle !== undefined) {
					currentGraph
						.getDefaultAxisY()
						.setMouseInteractions(false)
						.setAnimationScroll(false)
						.setTitle(configs.leftYAxisTitle ?? '')
						.setTickStrategy(AxisTickStrategies.Numeric, (numericTicks) =>
							numericTicks.setFormattingFunction((val) => val.toFixed(2))
						);
				}
				if (configs.rightYAxisType !== undefined || configs.rightYAxisTitle !== undefined) {
					const rightAxis = currentGraph.getAxes(AxisPosition.Right)[0];
					if (rightAxis) {
						rightAxis
							.setMouseInteractions(false)
							.setAnimationScroll(false)
							.setTitle(configs.rightYAxisTitle ?? '')
							.setTickStrategy(AxisTickStrategies.Numeric, (numericTicks) =>
								numericTicks.setFormattingFunction((val) => val.toFixed(2))
							);
					} else {
						currentGraph
							.addAxisY({ opposite: true, type: 'linear' })
							.setMouseInteractions(false)
							.setAnimationScroll(false)
							.setTitle(configs.rightYAxisTitle ?? '')
							.setTickStrategy(AxisTickStrategies.Numeric, (numericTicks) =>
								numericTicks.setFormattingFunction((val) => val.toFixed(2))
							);
					}
				}

				currentGraph
					.setMouseInteractionPan(false)
					.setMousePanStyle(MouseStyles.Default)
					.setMouseInteractionWheelZoom(false)
					.setMouseInteractionRectangleFit(false)
					.setMouseInteractionRectangleZoom(false);
			}
			currentGraph?.setPadding({ bottom: 40 });
		},
		[currentGraph]
	);

	const configureSeries = useCallback(
		(series: SeriesInfo) => {
			if (getSerries && graphId) {
				const currentSeries = getSerries(graphId, series.id);
				if (currentSeries !== undefined) {
					currentSeries
						.setAreaFillStyle(emptyFill)
						.setPointSize(0)
						.setStrokeStyle(
							new SolidLine({
								thickness: 2,
								fillStyle: new SolidFill({ color: ColorHEX(series.color) })
							})
						)
						.setName(series.name)
						.setVisible(series.isVisible);
				}
			}
		},
		[getSerries, graphId]
	);

	const applyAction = useCallback(
		(graphAction: GraphActions, configs?: GraphInfo) => {
			if (!currentGraph) return;
			currentGraph.getDefaultAxisX().setMouseInteractions(false);
			currentGraph.forEachAxisY((y) => y.setMouseInteractions(false));

			function resetZoom(graph: ChartXY<UIBackground>) {
				graph.forEachAxis((y) => y.fit(200));
				graph.getDefaultAxisX().setStopped(false);
			}

			switch (graphAction) {
				case GraphActions.GRAPH_PAUSE:
					if (graphId) {
						pauseGraph(graphId);
					}
					break;
				case GraphActions.GRAPH_RESUME:
					if (graphId) {
						resumeGraph(graphId);
					}
					break;
				case GraphActions.GRAPH_REFRESH:
					if (graphId) {
						const allSeries = getAllSerries(graphId);

						allSeries?.forEach((series) => {
							series.clear();
						});
						resetZoom(currentGraph);
					}

					break;
				case GraphActions.GRAPH_RESTART:
					{
						const allSeries = graphId ? getAllSerries(graphId) : [];
						allSeries?.forEach((series) => {
							series.clear();
						});
						const timeWindowSizeMsec = getTimeWindowSizeInMS(configs?.timeWindowSize);
						currentGraph.getDefaultAxisX().setInterval({
							start: -1 * timeWindowSizeMsec,
							end: 0,
							stopAxisAfter: false
						});
					}
					break;
				case GraphActions.GRAPH_ACTIVATE_CURSOR:
					currentGraph
						.setMouseInteractionPan(false)
						.setMousePanStyle(MouseStyles.Default)
						.setMouseInteractionWheelZoom(false)
						.setMouseInteractionRectangleFit(false)
						.setMouseInteractionRectangleZoom(false);

					currentGraph.getDefaultAxisX()?.setStopped(false);

					break;
				case GraphActions.GRAPH_ACTIVATE_MOVE:
					currentGraph
						.setMouseInteractionPan(true)
						.setMousePanStyle(MouseStyles.Move)
						.setMouseInteractionRectangleFit(false)
						.setMouseInteractionWheelZoom(false)
						.setMouseInteractionRectangleZoom(false);
					break;
				case GraphActions.GRAPH_ACTIVATE_ZOOM_RESET:
					resetZoom(currentGraph);
					break;
				case GraphActions.GRAPH_ACTIVATE_ZOOM_XY:
					currentGraph
						.setMouseInteractionPan(false)
						.setMousePanStyle(MouseStyles.ZoomIn)
						.setMouseInteractionRectangleFit(false)
						.setMouseInteractionWheelZoom(true)
						.setMouseInteractionRectangleZoom(true);

					currentGraph.getDefaultAxisX()?.setStopped(false);
					break;
				case GraphActions.GRAPH_ACTIVATE_ZOOM_X:
					currentGraph.getDefaultAxisX().setMouseInteractions(true);
					break;
				case GraphActions.GRAPH_ACTIVATE_ZOOM_Y:
					currentGraph.forEachAxisY((y) => y.setMouseInteractions(true));
					break;
				case GraphActions.GRAPH_CLEAR:
					const allSeries = graphId ? getAllSerriesWithIds(graphId) : undefined;
					if (allSeries) {
						Array.from(allSeries?.entries()).forEach(([id, series]) => {
							series.clear();
							series.dispose();
							removeSeries(graphId ?? '', id);
						});
					}
					break;
				case GraphActions.GRAPH_SNAPSHOT:
					currentGraph.saveToFile(`Screenshot_${new Date().toISOString()}`);
					break;
			}
		},
		[currentGraph, getAllSerries, getAllSerriesWithIds, graphId, pauseGraph, removeSeries, resumeGraph]
	);

	const addNewSeries = useCallback(
		(seriesInfo: SeriesInfo) => {
			if (graphId && currentGraph) {
				const series = getSerries(graphId, seriesInfo.id);
				if (!series) {
					const newSeries = currentGraph
						.addPointLineAreaSeries({
							dataPattern: 'ProgressiveX',
							yAxis:
								seriesInfo.rightYAxisTitle !== undefined
									? currentGraph.getAxes().find((axis) => axis.getTitle() === seriesInfo.rightYAxisTitle)
									: currentGraph.getDefaultAxisY()
						})
						.setMaxSampleCount({ mode: 'auto', max: 10000000 });
					addSeries(graphId, seriesInfo.id, newSeries);
				}
				configureSeries(seriesInfo);
			}
		},
		[addSeries, getSerries, configureSeries, graphId, currentGraph]
	);

	const hideSeries = useCallback(
		(seriesId: string) => {
			if (graphId) {
				const series = getSerries(graphId, seriesId);
				series?.setVisible(false);
			}
		},
		[getSerries, graphId]
	);

	const showSeries = useCallback(
		(seriesId: string) => {
			if (graphId) {
				const series = getSerries(graphId, seriesId);
				series?.setVisible(true);
			}
		},
		[getSerries, graphId]
	);

	const updateSeries = useCallback(
		(seriesList: SeriesInfo[]) => {
			if (graphId) {
				const allSeries = getAllSerriesWithIds(graphId);
				const allIds = new Set(allSeries?.keys());
				seriesList.forEach((series) => {
					if (allSeries?.has(series.id)) {
						configureSeries(series);
					} else {
						addNewSeries(series);
					}
				});

				const removedSeries = Array.from(allIds).filter((id) => !seriesList.some((series) => series.id === id));

				removedSeries.forEach((id) => {
					removeSeries(graphId, id);
				});
			}
		},
		[graphId, getAllSerriesWithIds, configureSeries, addNewSeries, removeSeries]
	);

	return useMemo(
		() => ({
			chart: currentGraph,
			addSeries: addNewSeries,
			configureSeries,
			removeSeries,
			addData,
			addSamples,
			showSeries,
			hideSeries,
			updateSeries,
			configureChart,
			applyAction,
			pauseGraph,
			resumeGraph,
			isPaused
		}),
		[
			currentGraph,
			addNewSeries,
			configureSeries,
			removeSeries,
			addData,
			addSamples,
			showSeries,
			hideSeries,
			updateSeries,
			configureChart,
			applyAction,
			pauseGraph,
			resumeGraph,
			isPaused
		]
	);
};
