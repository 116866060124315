export enum TestsTableActionTypes {
	EDIT = 'EDIT_STATION',
	RUN_TEST = 'RUN_TEST',
	CREATE = 'CREATE_STATION',
	DUPLICATE = 'DUPLICATE_STATION',
	DELETE = 'DELETE_STATION'
}

export enum StageActionTypes {
	EDIT_STAGE = 'EDIT_STAGE',
	CREATE_STAGE = 'CREATE_STAGE',
	DELETE_STAGE = 'DELETE_STAGE',
	DUPLICATE_STAGE = 'DUPLICATE_STAGE',
	RESET_STAGE = 'RESET_STAGE'
}

export enum StageConfigurationActionTypes {
	CONFIGURE_LOGGING = 'CONFIGURE_LOGGING',
	CONFIGURE_ACTION = 'CONFIGURE_ACTION',
	CONFIGURE_RULES = 'CONFIGURE_RULES',
	CONFIGURE_LOOPING = 'CONFIGURE_LOOPING',
	CONFIGURE_VARIABLES = 'CONFIGURE_VARIABLES'
}

export enum TestPrePostActionTypes {
	CONFIGURE_TEST_PRE_LOGGING = 'CONFIGURE_TEST_PRE_LOGGING',
	CONFIGURE_TEST_POST_LOGGING = 'CONFIGURE_TEST_POST_LOGGING',
	CONFIGURE_TEST_PRE_ACTIONS = 'CONFIGURE_TEST_PRE_ACTIONS',
	CONFIGURE_TEST_POST_ACTIONS = 'CONFIGURE_TEST_POST_ACTIONS',
	CONFIGURE_TEST_MEASUREMENTS = 'CONFIGURE_TEST_MEASUREMENTS',
	CONFIGURE_TEST_VARIABLES = 'CONFIGURE_TEST_VARIABLES',
	CONFIGURE_TEST_LOOPS = 'CONFIGURE_TEST_LOOPS'
}

export enum StageTypes {
	PRE,
	POST,
	MAIN,
	GLOBAL
}

export enum StageAxisActionTypes {
	ADD_AXIS = 'ADD_AXIS',
	EDIT_AXIS = 'EDIT_AXIS',
	DELETE_AXIS = 'DELETE_AXIS'
}

export enum DataStorageTypes {
	SD_CARD = 0
	// USB
}

export enum StorageFullBehaviorTypes {
	CONTINUE_TEST = 0,
	PAUSE_TEST,
	OVERWRITE_OLDEST_DATA,
	TERMINATE_TEST
}

export enum TestModals {
	SELECT_TEST_MODAL = 'SELECT_TEST_MODAL'
}

export enum TestStatusType {
	DRAFT = 0,
	READY
}
