import { FC, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import OrdersTable from '../components/OrdersTable';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { getOrders } from '../orders.api';
import { OrderCrudActionTypes } from '../orders.enums';

const OrdersTableContainer: FC = () => {
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<Record<string, string> | undefined>();
	const queryClient = useQueryClient();

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			queryClient.setQueryData<{ id: string }[]>(['orders'], (old) => {
				if (!old) return undefined;
				return old.filter((hc) => hc.id !== id);
			});
		},
		[queryClient]
	);

	const { data, isLoading } = useQuery<Record<string, string>[], Error>({
		queryKey: ['orders'],
		queryFn: async () => {
			const resp = await getOrders();
			return resp;
		}
	});

	const handleAction = useCallback(
		(type: OrderCrudActionTypes, data?: Record<string, string>) => {
			switch (type) {
				case OrderCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case OrderCrudActionTypes.EDIT:
					navigate(`./edit/${data?.id}`);
					break;
				case OrderCrudActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case OrderCrudActionTypes.DELETE:
					if (data) {
						setEntityForDelete(data);
					}
					break;
			}
		},
		[navigate]
	);

	return (
		<>
			<OrdersTable onAction={handleAction} data={data} isLoading={isLoading} />
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.AXES}
				entity={entityForDelete as unknown as IEntity}
			/>
		</>
	);
};

export default OrdersTableContainer;
