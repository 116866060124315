import React from 'react';
import { PageLayout, Form, InputText, Dropdown, Button, BottomBar, InfoBadge, InfoBadgeIcons } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './CustomWidgetForm.module.scss';
import { WidgetsTypes } from '../../customWidget.enums';
import { useNumberEnumList } from '../../../../hooks';
import { ICustomWidgetForm } from '../../customWidget.types';
import LayoutDropdown from '../LayoutDropdown';
import SubframesForm from '../CustomWidgetSubframesForm';
import CustomWidgetPreview from '../CustomWidgetPreview';

interface CustomWidgetProps {
	isCreate: boolean;
	isLoading: boolean;
	onBack: () => void;
	onSave: () => void;
	stationName: string;
}

const formId = 'widgetsForm';
const CustomWidgetForm: React.FC<CustomWidgetProps> = ({ isCreate, isLoading, onBack, onSave, stationName }) => {
	const { t } = useTranslation('widgets');
	const widgetTypesList = useNumberEnumList(WidgetsTypes, 'widgets');

	const {
		control,
		formState: { errors },
		register
	} = useFormContext<ICustomWidgetForm>();

	return (
		<>
			<PageLayout info={stationName}>
				<PageLayout.Header title={isCreate ? t('Create Widget') : t('Edit Widget')} onBack={onBack} />
				<div className={styles.container}>
					<div>
						<Form onSubmit={onSave} id={formId} className={styles.topForm}>
							<input type="hidden" {...register('stationId')} />
							<Controller
								name="name"
								control={control}
								render={({ field }) => (
									<InputText data-testid="nameId" label={t('Widget name*')} {...field} error={errors.name?.message} />
								)}
							/>
							<Controller
								name="type"
								control={control}
								render={({ field }) => (
									<Dropdown
										{...field}
										data-testid="type"
										options={widgetTypesList}
										label={t('Widget type*')}
										error={errors.type?.message}
										filter
									/>
								)}
							/>
							<Controller
								name="layoutType"
								control={control}
								render={({ field }) => (
									<LayoutDropdown
										{...field}
										data-testid="layoutType"
										label={t('Widget layout*')}
										error={errors.layoutType?.message}
									/>
								)}
							/>
						</Form>
						<div />
						<SubframesForm />
					</div>
					<CustomWidgetPreview />
				</div>
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button data-testid="cancelBtn" label={t('Cancel')} variant="contained" color="success" onClick={onBack} />
				<Button
					data-testid="saveBtn"
					label={t('Save')}
					variant="contained"
					color="secondary"
					type="submit"
					form={formId}
				/>
			</BottomBar>
		</>
	);
};

export default CustomWidgetForm;
