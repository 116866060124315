import { FC, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import UsersTable from '../components/UsersTable';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { getUsers } from '../users.api';
import { UserCrudActionTypes } from '../users.enums';

const UsersTableContainer: FC = () => {
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<Record<string, string> | undefined>();
	const queryClient = useQueryClient();

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			queryClient.setQueryData<{ id: string }[]>(['users'], (old) => {
				if (!old) return undefined;
				return old.filter((hc) => hc.id !== id);
			});
		},
		[queryClient]
	);

	const { data, isLoading } = useQuery<Record<string, string>[], Error>({
		queryKey: ['users'],
		queryFn: async () => await getUsers()
	});

	const handleAction = useCallback(
		(type: UserCrudActionTypes, data?: Record<string, string>) => {
			switch (type) {
				case UserCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case UserCrudActionTypes.EDIT:
					navigate(`./edit/${data?.id}`);
					break;
				case UserCrudActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case UserCrudActionTypes.DELETE:
					if (data) {
						setEntityForDelete(data);
					}
					break;
			}
		},
		[navigate]
	);

	return (
		<>
			<UsersTable onAction={handleAction} data={data} isLoading={isLoading} />
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.AXES}
				entity={entityForDelete as unknown as IEntity}
			/>
		</>
	);
};

export default UsersTableContainer;
