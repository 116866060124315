import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxisFormType } from '../axes.types';
import { axisFormSchema } from '../axes.schemas';
import Axis from '../components/AxisForm';
import { useAxis, useSaveAxis } from '../axes.hooks';
import { useEntitiesAsList, useNumberEnumList } from '../../../hooks';
import { useCurrentEntityInOptions } from '../../../tools';
import { responseFormConverter } from '../axes.converters';
import { useActuators } from '../../Actuators/actuators.hooks';
import { useStationName } from '../../Stations/stations.hooks';
import { AxesTypes } from '../axes.enums';
import { IActuatorResponseDto } from '../../Actuators';
import CreateEditPageTemplate from '../../../components/CreateEditPageTemplate';

const defaultValues: Partial<AxisFormType> = {
	name: '',
	actuatorId: undefined,
	type: undefined,
	stationId: ''
};

const axisFormId = 'axisFormId';

const AxesContainer: React.FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { stationId, axisId } = useParams();
	const { stationName } = useStationName(stationId);

	const form = useForm<AxisFormType>({
		defaultValues: { ...defaultValues, stationId },
		mode: 'onBlur',
		resolver: yupResolver(axisFormSchema)
	});
	const { handleSubmit, reset } = form;
	const { axisDto: currentAxisDto } = useAxis(axisId);

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/axes`);
	}, [navigate, stationId]);

	const { save, isLoading } = useSaveAxis(axisId, handleBack);
	const handleSave = handleSubmit((data: AxisFormType) => save(data));

	const handleReset = useCallback(() => {
		if (currentAxisDto) {
			reset({ ...responseFormConverter(currentAxisDto), stationId }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultValues, stationId }, { keepDirty: true, keepTouched: true });
		}
	}, [currentAxisDto, reset, stationId]);

	// Setup data for edit
	useEffect(() => {
		if (currentAxisDto) {
			handleReset();
		}
	}, [currentAxisDto, handleReset]);

	const axesTypes = useNumberEnumList(AxesTypes);

	const { actuators } = useActuators(stationId, true);
	const availableActuators = useCurrentEntityInOptions<IActuatorResponseDto>(actuators || [], currentAxisDto?.actuator);
	const actuatorOptions = useEntitiesAsList(availableActuators);

	return (
		<CreateEditPageTemplate
			isLoading={isLoading}
			title={!axisId ? t('Create Axis') : t('Edit Axis')}
			stationName={stationName}
			formId={axisFormId}
			onBack={handleBack}
		>
			<Axis
				formId={axisFormId}
				form={form}
				onSave={handleSave}
				types={axesTypes || []}
				actuatorOptions={actuatorOptions}
			/>
		</CreateEditPageTemplate>
	);
};

export default React.memo(AxesContainer);
