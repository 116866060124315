import React, { memo, forwardRef, InputHTMLAttributes, useImperativeHandle, useCallback, useEffect } from 'react';
import type { Except } from 'type-fest';
import cx from 'classnames';
import FieldFrame2, { IFieldFrameProps } from '../FieldFrame2';

import styles from './InputNumber.module.scss';

export interface IInputNumberProps
	extends Except<IFieldFrameProps, 'children' | 'onMouseDown'>,
		Except<InputHTMLAttributes<HTMLInputElement>, 'onFocus' | 'onBlur' | 'size' | 'type' | 'value'> {
	isInteger?: boolean;
	value?: number | null;
	suffix?: string;
}

const InputNumber = forwardRef<HTMLInputElement, IInputNumberProps>(
	(
		{
			className,
			containerClassName,
			label,
			error,
			disabled,
			size,
			style,
			leftIcon,
			rightIcon,
			errorPosition,
			onFocus,
			onBlur,
			isInteger,
			...inputProps
		},
		ref
	) => {
		const innerRef = React.useRef<HTMLInputElement>(null);
		useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);

		const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (!innerRef.current) return;
			if ((e.target as HTMLButtonElement).name === 'step-up') {
				e.preventDefault();

				innerRef.current.step = '1';
				innerRef.current?.stepUp();
				innerRef.current.step = 'any';
				innerRef.current?.dispatchEvent(new Event('change', { bubbles: true, composed: true }));
			}
			if ((e.target as HTMLButtonElement).name === 'step-down') {
				e.preventDefault();
				innerRef.current.step = '1';
				innerRef.current?.stepDown();
				innerRef.current.step = 'any';
				innerRef.current?.dispatchEvent(new Event('change', { bubbles: true }));
			}
		}, []);

		// Preventing entering special symbols when isInteger is true
		useEffect(() => {
			const input = innerRef.current;

			function removeSpecialSymbols(e: KeyboardEvent) {
				if (e.key.match(/^[,]$|^[.]$|^[e]$|^[E]$/)) {
					e.preventDefault();
				}
			}

			function disableScroll(e: WheelEvent) {
				e.preventDefault();
			}

			if (input) {
				if (isInteger) input.addEventListener('keydown', removeSpecialSymbols);

				input.addEventListener('wheel', disableScroll);
			}

			return () => {
				if (input) {
					if (isInteger) input.removeEventListener('keydown', removeSpecialSymbols);

					input.addEventListener('wheel', disableScroll);
				}
			};
		}, [isInteger]);

		return (
			<FieldFrame2
				label={label}
				style={style}
				className={cx(className, styles.input)}
				containerClassName={containerClassName}
				errorPosition={errorPosition}
				error={error}
				disabled={disabled}
				size={size}
				leftIcon={leftIcon}
				rightIcon={rightIcon}
				onFocus={onFocus}
				onBlur={onBlur}
				onMouseDown={handleMouseDown}
			>
				{({ withLabel, isFocused, ...defaultProps }) => (
					<>
						{React.createElement('input', {
							type: 'number',
							...defaultProps,
							...inputProps,
							value: inputProps.value !== null ? inputProps.value : '',
							ref: innerRef,
							step: 'any'
						})}
						<button title="decrease" className={styles.stepDown} type="button" name="step-down" tabIndex={-1}></button>
						<button title="increase" className={styles.stepUp} type="button" name="step-up" tabIndex={-1}></button>
					</>
				)}
			</FieldFrame2>
		);
	}
);

InputNumber.defaultProps = {
	isInteger: false
};

export default Object.assign(memo(InputNumber), { displayName: 'InputNumber' });
