import { object, string, mixed, array } from 'yup';
import * as api from './dashboardTypes.api';
import { alphaNumericRegex } from '../../constants/regexes';
import { DashboardDisplayingMode } from './dashboardTypes.enums';
import { Widget } from '../Dashboard/dashboard.types';

export const dashboardTypeFormSchema = object({
	id: string().optional(),
	name: string()
		.required()
		.max(30)
		.matches(alphaNumericRegex, 'Is not in correct format')
		.test('checkDashboardTypeUniqueness', 'Name is used', function (value, form) {
			if (!value) return true;
			return api.checkName(value, form?.parent?.id);
		}),
	mode: mixed<DashboardDisplayingMode>().required(),
	description: string(),
	widgets: array().of(mixed<Widget>().required())
}).required();
