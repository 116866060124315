import { Button, Modal } from '@tactun/ui';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IListItem } from '../../../../types';
import MeasurementForm from '../../../Measurements/components/Measurement/MeasurementForm';
import { SensorResponseDto } from '../../../Sensors/sensors.types';
import { QuantityResponseDto } from '../../../Units';
import styles from './TestMeasurementsForm.module.scss';
import { TestMeasurementFormType } from '../../testMeasurements.types';

interface TestMeasurementsTableProps {
	form: UseFormReturn<TestMeasurementFormType>;
	sensors: SensorResponseDto[];
	quantities: QuantityResponseDto[];
	variables: IListItem[];
	onSave: () => void;
}

const modalId = 'testMeasurementsFormId';

const TestMeasurementsForm: React.FC<TestMeasurementsTableProps> = ({
	form,
	sensors,
	quantities,
	variables,
	onSave
}) => {
	const { t } = useTranslation('testMeasurements');

	return (
		<Modal id={modalId} className={styles.testMeasurementsForm} shouldCloseOnOverlayClick={false}>
			<Modal.Header>{t('Edit a Measurement ')}</Modal.Header>
			<Modal.Content>
				<FormProvider {...form}>
					<MeasurementForm
						formId={modalId}
						onSave={onSave}
						sensors={sensors}
						variables={variables}
						quantities={quantities}
						isForTest
					/>
				</FormProvider>
			</Modal.Content>
			<Modal.Footer>
				<Button onClick={() => Modal.hide(modalId)} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button type="submit" color="secondary" variant="contained" form={modalId}>
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default TestMeasurementsForm;
