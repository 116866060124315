import styles from './Loader.module.scss';

const Loader: React.FC = () => {
	return (
		<div className={styles.container}>
			<div className={styles.spinner}></div>
		</div>
	);
};

Loader.displayName = 'Loader';

export default Loader;
