import axios from '../../httpCommon';
import { WidgetRequestDto, WidgetResponseDto } from './widgetsConfiguration.types';
import { WidgetsTypes } from './widgetsConfiguration.enums';

const mockWidgets: WidgetResponseDto[] = [
	{
		uuid: 'new-control-axis',
		name: 'Control Axis',
		widget: {
			type: WidgetsTypes.CONTROL_AXIS
		},
		stationUuid: ''
	},
	{
		uuid: 'new-control-test',
		name: 'Control Test',
		widget: {
			type: WidgetsTypes.CONTROL_TEST,
			startStopButton: true,
			pauseResumeButton: true,
			jumpToNextStageButton: true,
			generateReportButton: true
		},
		stationUuid: ''
	}
];

export const getStationWidgets = async (stationId?: string): Promise<WidgetResponseDto[]> => {
	const { data } = await axios.get('/cassiopeia/station-widget/search/findByStationUuid', {
		params: { stationUuid: stationId }
	});

	const widgets = data._embedded['station-widget'];

	const missingWidgets = mockWidgets.filter(
		({ widget: mockWidget }) => !widgets.find(({ widget }: WidgetResponseDto) => widget.type === mockWidget.type)
	);

	return [...widgets, ...missingWidgets] as WidgetResponseDto[];
};

export const getWidget = async (id: string): Promise<WidgetResponseDto> => {
	const mockWidget = mockWidgets.find(({ uuid }) => uuid === id);
	if (mockWidget) {
		return mockWidget;
	}

	const response = await axios.get(`/cassiopeia/station-widget/${id}`);

	return response.data as WidgetResponseDto;
};

export const createWidget = async (widget: WidgetRequestDto) => {
	const response = await axios.post('/cassiopeia/station-widget', widget);
	return response.data;
};

export const updateWidget = async (widget: WidgetRequestDto, id: string) => {
	const mockWidget = mockWidgets.find(({ uuid }) => uuid === id);
	if (mockWidget) {
		return await createWidget(widget);
	}

	const response = await axios.post('/cassiopeia/station-widget', { uuid: id, ...widget });
	return response.data;
};
