import React, { useCallback, useMemo } from 'react';
import { DataTable, IconButton, KebabMenu, PageLayout } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { ControlChannelListItem } from '../../controlChannel.types';
import { ControlChannelsTableActionTypes } from '../../controlChannel.enums';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { useTableConfigs } from '../../../../hooks';

interface IControlChannelsTableProps {
	onAction: (type: ControlChannelsTableActionTypes, data?: ControlChannelListItem) => void;
	data?: ControlChannelListItem[];
	isLoading: boolean;
	stationName: string;
}

const ControlChannelsTable: React.FC<IControlChannelsTableProps> = ({ data, isLoading, onAction, stationName }) => {
	const { t } = useTranslation('controlChannel');

	const transColumns = useMemo<ITableColumns<ControlChannelListItem>[]>(() => {
		return [
			{
				field: 'setPoint',
				header: t('Setpoint'),
				body: (data: ControlChannelListItem) => (data.setPoint === 'WAVEFORM' ? t('Waveform') : data.setPoint)
			},
			{ field: 'output', header: t('Output') },
			{ field: 'feedback', header: t('Feedback') }
		];
	}, [t]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<ControlChannelListItem>(transColumns, data);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(ControlChannelsTableActionTypes.DELETE, data)
			}
		],
		[onAction, t]
	);

	const handleCreate = useCallback(() => {
		onAction(ControlChannelsTableActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={stationName}>
			<PageLayout.Header title={t('Controls')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('Control')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>

			<DataTable
				visibleRowsCount={8}
				value={data}
				loading={isLoading}
				globalFilter={filter}
				dataKey="id"
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Control name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(ControlChannelsTableActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="duplicate"
							fontSize="1.2rem"
							title={t('Duplicate')}
							disabled
							onClick={() => onAction(ControlChannelsTableActionTypes.DUPLICATE, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default React.memo(ControlChannelsTable);
