import React, { useCallback, useMemo } from 'react';
import { AddButton, Checkbox, DataTable, IconButton } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { PrimeIcons } from 'primereact/api';
import cx from 'classnames';
import ActionCard from '../../../Actions/components/ActionCard';
import { TestActionsActionTypes } from '../../testActions.enums';
import { ITableColumns } from '../../../../types';
import {
	ActionAxisProperties,
	ActionControlChannelProperties,
	ActionDigitalOutputProperties,
	ActionDtoObjectTypes,
	ActionMeasurementProperties,
	ActionObjectTypes,
	actionResponseConverter,
	ActionStageProperties,
	ActionStationProperties,
	ActionTestProperties,
	CalculationActionDto
} from '../../../Actions';
import { MeasurementResponseDto } from '../../../Measurements';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';
import { IStationChannelResponseDto } from '../../../StationChannels';
import { ControlChannelResponseDto } from '../../../ControlChannels';
import { RuleRequestDtoBase, RuleResponseDtoBase } from '../../../Rules';
import { CalculationTypes } from '../../../Calculations';
import styles from './TestActionsTable.module.scss';

interface ITestActionsTableProps {
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	digitalChannels: IStationChannelResponseDto[];
	controlChannels: ControlChannelResponseDto[];
	onAction: (
		type: TestActionsActionTypes,
		data?: RuleRequestDtoBase | RuleResponseDtoBase,
		index?: number,
		to?: number
	) => void;
	existingActions: (RuleRequestDtoBase | RuleResponseDtoBase)[];
	currentStageActions: (RuleRequestDtoBase | RuleResponseDtoBase)[];
	isLoadingExistents: boolean;
	enableCalculation?: boolean;
	enableAction?: boolean;
	inParallel?: boolean;
	changeInParallel?: (v: boolean) => void;
	isLoadingCurrentStageActions: boolean;
}

const TestActionsTable: React.FC<ITestActionsTableProps> = ({
	digitalChannels,
	controlChannels,
	measurements,
	enableCalculation,
	enableAction,
	onAction,
	existingActions,
	currentStageActions,
	isLoadingExistents,
	inParallel,
	changeInParallel,
	isLoadingCurrentStageActions
}) => {
	const { t } = useTranslation(['actions', 'testActions']);
	const { t: calculationT } = useTranslation('calculation');

	const transColumns = useMemo<ITableColumns<RuleRequestDtoBase | RuleResponseDtoBase>[]>(() => {
		return [
			{
				field: 'name',
				header:
					enableAction && enableCalculation
						? t('Action / Calculation Name')
						: enableAction
						? t('Action Name')
						: t('Calculation Name')
			},
			{
				field: 'type',
				header: t('Action Type'),
				body: (action) =>
					action.rulePositiveActions?.[0] ? t(ActionDtoObjectTypes[action.rulePositiveActions?.[0]?.type]) : ''
			}
		];
	}, [enableAction, enableCalculation, t]);

	const columnComponents = transColumns.map((col) => <DataTable.Column resizeable key={col.field} {...col} />);

	const handleDelete = useCallback(
		(entity: RuleRequestDtoBase | RuleResponseDtoBase, index: number) =>
			onAction(TestActionsActionTypes.DELETE, entity, index),
		[onAction]
	);
	const handleEdit = useCallback(
		(entity: RuleRequestDtoBase | RuleResponseDtoBase, index: number) =>
			onAction(TestActionsActionTypes.EDIT, entity, index),
		[onAction]
	);
	const handleMove = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			onAction(TestActionsActionTypes.MOVE, undefined, dragIndex, hoverIndex);
		},
		[onAction]
	);

	const getLabel = useCallback(
		(rule: RuleRequestDtoBase | RuleResponseDtoBase) => {
			const action = rule.rulePositiveActions?.[0] ? actionResponseConverter(rule.rulePositiveActions?.[0]) : undefined;
			if (action?.objectType === undefined || action.objectType === null) {
				return '';
			}
			switch (action.objectType) {
				case ActionObjectTypes.DIGITAL_OUTPUT:
					const selectedChannelId = action.objectId || '';
					const selectedChannel = digitalChannels.find((channel) => channel.id === selectedChannelId);
					return t('Digital output: {{name}}', { name: selectedChannel?.name || '' });

				case ActionObjectTypes.TEST:
					return t('Test');

				case ActionObjectTypes.CONTROL_CHANNEL:
					const selectedControlChannelId = action.objectId || '';
					const selectedControlChannel = controlChannels.find((channel) => channel.id === selectedControlChannelId);
					return t('Control: {{name}}', { name: selectedControlChannel?.name || '' });

				case ActionObjectTypes.MEASUREMENT:
					const measurementlId = action.objectId || '';
					const meas = measurements.find((measurement) => measurement.id === measurementlId);
					return t('Measurement: {{name}}', { name: meas?.name || '' });

				case ActionObjectTypes.MESSAGE_BOX:
					return t('Message box');

				case ActionObjectTypes.CALCULATION:
					return 'Calculation';

				case ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION:
					return t('Message box with confirmation');

				case ActionObjectTypes.STATION:
					return t('Station');

				case ActionObjectTypes.STAGE:
					return t('Stage');

				case ActionObjectTypes.AXIS:
					return t('Axis');

				default:
					return '';
			}
		},
		[digitalChannels, t, controlChannels, measurements]
	);

	const getValueLabel = useCallback(
		(rule: RuleRequestDtoBase | RuleResponseDtoBase): string => {
			const action = rule.rulePositiveActions?.[0] ? actionResponseConverter(rule.rulePositiveActions?.[0]) : undefined;
			if (!action) return '';
			const isPropertyMissing = action.property === undefined || action.property === null;

			if (isPropertyMissing && action.objectType === ActionObjectTypes.MESSAGE_BOX) {
				return action.message || '';
			} else if (isPropertyMissing && action.objectType === ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION) {
				return action.message || '';
			} else if (action.property === undefined || action.property === null) {
				return '';
			} else {
				switch (action.objectType) {
					case ActionObjectTypes.DIGITAL_OUTPUT:
						return isPropertyMissing ? '' : t(ActionDigitalOutputProperties[action.property]);
					case ActionObjectTypes.TEST:
						return t(ActionTestProperties[action.property]);
					case ActionObjectTypes.MEASUREMENT:
						return t(ActionMeasurementProperties[action.property]);
					case ActionObjectTypes.CONTROL_CHANNEL:
						return t(ActionControlChannelProperties[action.property]);
					case ActionObjectTypes.STATION:
						return t(ActionStationProperties[action.property]);
					case ActionObjectTypes.STAGE:
						return t(ActionStageProperties[action.property]);
					case ActionObjectTypes.AXIS:
						return t(ActionAxisProperties[action.property]);
					case ActionObjectTypes.CALCULATION:
						const calculationType = (action as CalculationActionDto).calculation?.type;
						return calculationType !== undefined ? calculationT(CalculationTypes[calculationType]) : '';
					default:
						return '';
				}
			}
		},
		[calculationT, t]
	);

	return (
		<>
			<div className={styles.title}>
				{enableAction && enableCalculation
					? t('Existing actions and calculations')
					: enableAction
					? t('Existing actions')
					: t('Existing calculations')}
			</div>
			<DataTable
				value={existingActions}
				loading={isLoadingExistents}
				dataKey="id"
				showGridlines
				columnResizeMode="fit"
				emptyMessage={
					enableAction && enableCalculation
						? t('No actions or calculations are configured in the given test')
						: enableAction
						? t('No actions are configured in the given test')
						: t('No calculations are configured in the given test')
				}
			>
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="duplicate"
							fontSize="1.2rem"
							title={t('Duplicate')}
							onClick={() => onAction(TestActionsActionTypes.DUPLICATE, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
			</DataTable>

			<div className={styles.currentTitleWrapper}>
				<div className={styles.title}>
					{enableAction && enableCalculation
						? t('Actions and calculations of the current stage:')
						: enableAction
						? t('Actions of the current stage:')
						: t('Calculations of the current stage:')}
				</div>
				{changeInParallel && (
					<div className={styles.checkboxWrap}>
						<Checkbox
							checked={!!inParallel}
							disabled
							onChange={() => changeInParallel(!inParallel)}
							data-testid="ruleRunParallel"
						/>
						<span>{t('Run in parallel')}</span>
					</div>
				)}
			</div>

			<div className={styles.existingActionWrapper}>
				{isLoadingCurrentStageActions ? (
					<i className={cx(PrimeIcons.SPINNER, 'pi-spin', styles.icon)} />
				) : (
					currentStageActions.map((action, index) => (
						<ActionCard
							name={action.name}
							label={getLabel(action)}
							property={getValueLabel(action)}
							onEdit={() => handleEdit(action, index)}
							onDelete={() => handleDelete(action, index)}
							key={index}
							id={index}
							index={index}
							moveCard={handleMove}
							dragType="actions-calculations-cards"
						/>
					))
				)}
			</div>
			<div className={styles.addNewContainer}>
				{enableAction && (
					<AddButton type="button" onClick={() => onAction(TestActionsActionTypes.CREATE_ACTION)} label={t('Action')} />
				)}
				{enableCalculation && (
					<AddButton
						type="button"
						onClick={() => onAction(TestActionsActionTypes.CREATE_CALCULATION)}
						label={t('Calculation')}
					/>
				)}
			</div>
		</>
	);
};

export default React.memo(TestActionsTable);
