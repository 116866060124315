import axios from '../../httpCommon';
import { TestMeasurementRequestDto, TestMeasurementResponseDto } from './testMeasurements.types';

export const getTestMeasurements = async (testId: string): Promise<TestMeasurementResponseDto[]> => {
	const response = await axios.get(`/quasar/tests/${testId}/m`);

	return response.data;
};

export const updateTestMeasurement = async (measurement: TestMeasurementRequestDto, measurementId: string) => {
	const response = await axios.put(`/quasar/tests/m/${measurementId}`, measurement);
	return response.data;
};

export const getTestMeasurement = async (measurementId: string) => {
	const response = await axios.get(`/quasar/tests/m/${measurementId}`);
	return response.data;
};
