import { grpc } from '@improbable-eng/grpc-web';
import { ProtobufMessage } from '@improbable-eng/grpc-web/dist/typings/message';
import { UnaryOutput } from '@improbable-eng/grpc-web/dist/typings/unary';
import { controlStageService } from '@tactun/grpc-client';
import { useCallback, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IAxisResponseDto } from '../Axes';
import { ControlChannelResponseDto } from '../ControlChannels';
import { ControlStageError } from '../ControlStageWidget/controlStage.grpc';
import { WaveformDirection } from '../Waveforms';
import { axesControlChannelToControlStageStartConverter, axisToStopConverter } from './controlAxis.converters';
import { tError } from '../../tools/logger';

export const useControlAxis = () => {
	const [isStarted, setIsStarted] = useState<boolean>(false);
	const [error, setError] = useState<ControlStageError>();
	const grpcRequestRef = useRef<grpc.Request>();

	const start = useCallback(
		(axes: IAxisResponseDto, controlChannel: ControlChannelResponseDto, dir: WaveformDirection, speed: number) => {
			if (process.env.REACT_APP_DEVICE_URL && !isStarted) {
				const startRequest = axesControlChannelToControlStageStartConverter(axes, controlChannel, dir, speed);
				const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

				grpcRequestRef.current = grpc.unary(controlStageService.WebControlStage.StartStage, {
					request: startRequest,
					host: process.env.REACT_APP_DEVICE_URL,
					transport: myTransport,
					onEnd: (output: UnaryOutput<ProtobufMessage>) => {
						if (output.status === grpc.Code.OK) {
							setIsStarted(true);
						} else {
							tError(
								`GRPC stream end code: "${output.status.toString()}" \nGRPC stream end message: "${output.message}" \n `
							);

							setError({ code: output.status.toString(), message: output.statusMessage ?? '' });
						}
					}
				});
			}
		},
		[isStarted]
	);

	const stop = useCallback(
		(axes: IAxisResponseDto) => {
			if (process.env.REACT_APP_DEVICE_URL && isStarted) {
				const stopRequest = axisToStopConverter(axes);
				const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

				grpcRequestRef.current = grpc.unary(controlStageService.WebControlStage.StopStage, {
					request: stopRequest,
					host: process.env.REACT_APP_DEVICE_URL,
					transport: myTransport,
					onEnd: (output: UnaryOutput<ProtobufMessage>) => {
						if (output.status === grpc.Code.OK) {
							setIsStarted(false);
						} else {
							toast.error(output.statusMessage || '');
							tError(
								`GRPC stream end code: "${output.status.toString()}" \nGRPC stream end message: "${output.message}" \n `
							);

							setError({ code: output.status.toString(), message: output.statusMessage ?? '' });
						}
					}
				});
			}
		},
		[isStarted]
	);
	return useMemo(() => {
		return { start, stop, isStarted, error };
	}, [start, stop, isStarted, error]);
};
