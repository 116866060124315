import React from 'react';
import type { FC } from 'react';
import { InputNumber, Button, GroupInputFrame, InputText } from '@tactun/ui';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IActuatorCalibrationTuple } from '../../actuators.types';

import styles from './ActuatorCalibrationForm.module.scss';

const classes = 'flexRow flexFull';
export interface ActuatorCalibrationInputProps {
	onSubmit: () => void;
	form: UseFormReturn<IActuatorCalibrationTuple>;
	controlValueUnit?: string;
}

const ActuatorCalibrationInput: FC<ActuatorCalibrationInputProps> = ({ onSubmit, form, controlValueUnit }) => {
	const { t } = useTranslation('actuators');
	const {
		control,
		formState: { errors }
	} = form;

	return (
		<>
			<div className={styles.flexRow}>
				<div className="flexLabel">{t('Control value*')}</div>
				<GroupInputFrame>
					<Controller
						name="usd"
						control={control}
						render={({ field }) => <InputNumber {...field} error={errors.usd?.message} data-testid="usd" />}
					/>
					<InputText data-testid="usd-unit" value={controlValueUnit} disabled />
				</GroupInputFrame>
			</div>
			<div className={classes}>
				<Controller
					name="csd"
					control={control}
					render={({ field }) => (
						<InputNumber {...field} label={t('Calibrated value*')} error={errors.csd?.message} data-testid="csd" />
					)}
				/>

				<Button
					type="button"
					icon="t-icon-add"
					className={styles.addData}
					data-testid="addData"
					onClick={() => {
						onSubmit();
					}}
				/>
			</div>
		</>
	);
};

export default React.memo(ActuatorCalibrationInput);
