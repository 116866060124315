import React from 'react';
import { Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { specimenSelectionModalId } from '../../specimenSelection.const';
import styles from './SpecimenSelectionModal.module.scss';
import SpecimenSelectionModalContainer from '../../containers/SpecimenSelectionModalContainer';

const SpecimenSelectionModal: React.FC = () => {
	const { t } = useTranslation('specimens');

	return (
		<Modal id={specimenSelectionModalId} className={styles.container} shouldCloseOnOverlayClick={false}>
			<Modal.Header>{t('Select specimen')}</Modal.Header>
			<SpecimenSelectionModalContainer />
		</Modal>
	);
};

export default React.memo(SpecimenSelectionModal);
