import { FC, useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { useStationName } from '../../Stations/stations.hooks';
import WidgetsConfigurationTable from '../components/CustomWidgetsTable';
import { CustomWidgetsListItem, CustomWidgetDto } from '../customWidget.types';
import { WidgetCrudActionTypes } from '../customWidget.enums';
import { useWidgets } from '../customWidget.hooks';
import { IStep, useWizard } from '@tactun/ui';
import { widgetsPath } from '../../Stations/station.const';

const CustomWidgetsTableContainer: FC = () => {
	const navigate = useNavigate();
	const { stationId } = useParams();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const queryClient = useQueryClient();
	const { widgets, isWidgetsLoading } = useWidgets(stationId);
	const { stationName } = useStationName(stationId);

	const { beforeNextStep } = useWizard();
	beforeNextStep((step: IStep) => {
		if (step.path === widgetsPath) {
			navigate('/stations');
			return Promise.resolve(false);
		} else {
			return Promise.resolve(true);
		}
	});

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			queryClient.setQueryData<CustomWidgetDto[]>(['widgets', { stationId }], (old) => {
				if (!old) return undefined;
				return old.filter((hc) => hc.uuid !== id);
			});
		},
		[queryClient, stationId]
	);

	const handleAction = useCallback(
		(type: WidgetCrudActionTypes, data?: CustomWidgetsListItem) => {
			switch (type) {
				case WidgetCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case WidgetCrudActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case WidgetCrudActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case WidgetCrudActionTypes.DELETE:
					setEntityForDelete(data as IEntity);
					break;
			}
		},
		[navigate]
	);

	return (
		<>
			<WidgetsConfigurationTable
				onAction={handleAction}
				data={widgets}
				isLoading={isWidgetsLoading}
				stationName={stationName}
			/>
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.CUSTOM_WIDGETS}
				entity={entityForDelete}
			/>
		</>
	);
};

export default CustomWidgetsTableContainer;
