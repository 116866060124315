import * as React from 'react';
import { WidgetItem } from '../../dashboard.types';

const WidgetContext = React.createContext<{
	id: string;
	widget: WidgetItem | null;
	updateWidget: (id: string, widget: Partial<WidgetItem>) => void;
	removeWidget: (id: string) => void;
	addWidget: (widget: WidgetItem) => void;
}>({
	id: '',
	widget: null,
	updateWidget: () => {},
	removeWidget: () => {},
	addWidget: () => {}
});

WidgetContext.displayName = 'WidgetContext';

export default WidgetContext;
