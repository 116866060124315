import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';
import { WidgetControlAxisForm } from '../../widgetsConfiguration.types';
import { useAxes } from '../../../Axes/axes.hooks';
import ButtonConfigForm from '../ButtonConfigForm';
import styles from './ControlAxisForm.module.scss';

interface ControlAxisFormProps {
	form: UseFormReturn<WidgetControlAxisForm>;
}

const ControlAxisForm: React.FC<ControlAxisFormProps> = ({ form }) => {
	const { watch } = form;
	const stationId = watch('stationId');
	const { axes } = useAxes(stationId);

	const [activeTab, setActiveTab] = useState(0);
	const handleTabChange = useCallback((e: TabViewTabChangeEvent) => {
		setActiveTab(e.index);
	}, []);

	return (
		<TabView className={styles.tabView} renderActiveOnly={false} onTabChange={handleTabChange} activeIndex={activeTab}>
			{axes?.map((axis, index) => (
				<TabPanel header={axis.name} key={axis.id}>
					<ButtonConfigForm form={form} index={index} />
				</TabPanel>
			))}
		</TabView>
	);
};

export default ControlAxisForm;
