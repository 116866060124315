import { WidgetTypes } from '../Dashboard/dashboard.enums';

export const generalInfoPath = '/components-settings/dashboard-types/:dashboardTypeId';
export const generalInfoCreatePath = '/components-settings/dashboard-types/create';
export const dashboardPath = '/components-settings/dashboard-types/:dashboardTypeId/dashboard';
export const dashboardGeneralInfoFormId = 'dashboardGeneralInfoFormId';
export const dashboardTypeDefaultValues = {
	id: undefined,
	name: '',
	description: ''
};

export const excludedWidgetTypes = [WidgetTypes.BUTTON_WIDGET, WidgetTypes.SENSOR_CALIBRATION];
