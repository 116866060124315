import React from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { createEditTestSequencerRulesModalId } from '../../testSequencerRules.const';
import styles from './TestSequencerRulesCreateEditModal.module.scss';

interface TestSequencerRulesCreateEditModalProps {
	isCreate: boolean;
	name?: string;
	isLoading: boolean;
	children: React.ReactNode;
	formId: string;
	onClose: () => void;
	onAfterClose?: () => void;
}

const TestSequencerRulesCreateEditModal: React.FC<TestSequencerRulesCreateEditModalProps> = ({
	isCreate,
	isLoading,
	name,
	children,
	formId,
	onClose,
	onAfterClose
}) => {
	const { t } = useTranslation('testSequencerRules');

	return (
		<Modal
			id={createEditTestSequencerRulesModalId}
			className={styles.container}
			shouldCloseOnOverlayClick={false}
			onClose={onClose}
			onAfterClose={onAfterClose}
			hidePrevModals
		>
			<Modal.Header>{isCreate ? t('Create a Rule') : t('Edit "{{name}}" Rule', { name })}</Modal.Header>
			<Modal.Content className={styles.innerCont}>{children}</Modal.Content>
			<Modal.Footer>
				<Button onClick={onClose} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button color="secondary" variant="contained" loading={isLoading} type="submit" form={formId}>
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(TestSequencerRulesCreateEditModal);
