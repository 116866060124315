import { object, string, mixed } from 'yup';
import * as api from './axes.api';
import { AxesTypes } from './axes.enums';
import { alphaNumericRegex } from '../../constants/regexes';

export const axisFormSchema = object({
	id: string().notRequired(),
	name: string()
		.required()
		.max(30)
		.matches(alphaNumericRegex, 'Is not in correct format')
		.test('checkAxisUniqueness', 'Name is used', function (value, form) {
			if (!value) return true;
			return api.checkName(value, form?.parent.stationId, form?.parent?.id);
		}),
	type: mixed<AxesTypes>().required(),
	stationId: string().required(),
	actuatorId: string().required('Actuator is a reuired field')
}).required();
