import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '@tactun/ui';
import TestVariablesTable from '../components/TestVariablesTable';
import TestVariableCreateEditModal from '../components/TestVariableCreateEditModal';
import VariableForm from '../../Variables/components/VariableForm';
import { VariableFormType, VariableListItem, VariableRequestDto } from '../../Variables/variables.types';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { VariableTableActionTypes } from '../../Variables/variables.enums';
import { useTestVariable, useTestVariables } from '../../Variables/variables.hooks';
import { variableFormSchema } from '../../Variables/variables.schemas';
import { formRequestConverter, responseFormConverter } from '../../Variables/variables.converters';
import { deleteEmptyFields } from '../../../tools';
import { createEditTestVariablesModalId } from '../testVariables.const';

interface TestVariablesModalContainerProps {
	testId?: string;
}

const defaultValues = {
	name: '',
	type: undefined,
	defaultValue: ''
};

const TestVariablesContainer: FC<TestVariablesModalContainerProps> = ({ testId }) => {
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const [entityIdForEdit, setEntityIdForEdit] = useState<string | undefined>();
	const form = useForm<VariableFormType>({
		defaultValues: { ...defaultValues, testId },
		mode: 'onBlur',
		resolver: yupResolver(variableFormSchema)
	});
	const { reset, setValue } = form;

	const resetDefaultValues = useCallback(() => {
		setValue('id', '');
		setValue('name', '');
		setValue('defaultValue', '');
	}, [setValue]);

	// handel TestVariableCreateEditModal close
	const handleClose = useCallback(() => {
		setEntityIdForEdit(undefined);
		resetDefaultValues();
		Modal.hide(createEditTestVariablesModalId);
	}, [resetDefaultValues]);

	//  handel TestVariableCreateEditModal save
	const handleSuccessSave = useCallback(() => {
		handleClose();
	}, [handleClose]);

	const { testVariables, isLoading, isCreating, isUpdating, deleteTestVariable, saveTestVariable } = useTestVariables(
		testId,
		handleSuccessSave
	);

	// load variable for edit
	const { testVariable } = useTestVariable(entityIdForEdit);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			deleteTestVariable(id);
		},
		[deleteTestVariable]
	);

	const handleAction = useCallback((type: VariableTableActionTypes, data?: VariableListItem) => {
		switch (type) {
			case VariableTableActionTypes.CREATE:
				Modal.show(createEditTestVariablesModalId);
				break;
			case VariableTableActionTypes.EDIT:
				setEntityIdForEdit(data?.id);
				break;
			case VariableTableActionTypes.DUPLICATE:
				alert('Duplicate functional is not implemented yet.');
				break;
			case VariableTableActionTypes.DELETE:
				setEntityForDelete(data);
				break;
		}
	}, []);

	const handleSave = useCallback(() => {
		form.handleSubmit((data) => {
			const filteredData = deleteEmptyFields<VariableRequestDto>(formRequestConverter(data));
			if (data?.id) {
				saveTestVariable(filteredData, data.id);
			} else {
				saveTestVariable(filteredData);
			}
		})();
	}, [form, saveTestVariable]);

	// if variable available for edit open edit modal
	useEffect(() => {
		if (testVariable) {
			reset(responseFormConverter(testVariable), { keepDirty: true, keepTouched: true });
			Modal.show(createEditTestVariablesModalId);
		}
	}, [reset, testId, testVariable]);

	// set testId for variable
	useEffect(() => {
		if (testId) {
			setValue('testId', testId);
		}
	}, [setValue, testId]);

	return (
		<>
			<TestVariablesTable onAction={handleAction} data={testVariables} isLoading={isLoading} />
			<TestVariableCreateEditModal
				modalId={createEditTestVariablesModalId}
				isCreate={!testVariable?.uuid}
				name={testVariable?.name}
				isLoading={isCreating || isUpdating}
				onSave={handleSave}
				onClose={handleClose}
			>
				<VariableForm form={form} isForModal />
			</TestVariableCreateEditModal>
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.TEST_VARIABLE}
				entity={entityForDelete}
			/>
		</>
	);
};

export default TestVariablesContainer;
