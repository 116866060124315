import { Converter } from '../../types';
import { TestStageAxisConfigResponseDto, TestStageConfigs, TestStageResponseDto } from '../Tests';
import { TestAxesConfigurationFormType, TestStageAxisConfigRequestDto } from './testAxesConfiguration.type';
import { waveformFormToRequestConverter, waveformResponseToFormConverter } from '../Waveforms';
import { responseControlAlgorithmsFormConverter } from '../ControlAlgorithms';
import { formControlAlgorithmsExternalConverter } from '../ControlAlgorithms/controlAlgorithms.converters';

export const testAxesConfigurationFormToRequestConverter = (
	response: TestAxesConfigurationFormType
): TestStageAxisConfigRequestDto => {
	return {
		ccId: response.ccId,
		axisId: response.axisId,
		stageId: response.stageId,
		stageCompleteFlag: response.isFinishStageTrigger === undefined ? false : response.isFinishStageTrigger,
		useStationParams: response.useStationParams,
		wf: waveformFormToRequestConverter(response.wf),
		controlAlgorithm: response.controlAlgorithmsForm
			? formControlAlgorithmsExternalConverter(response.controlAlgorithmsForm)
			: undefined
	};
};

export const testStageAxisConfigResponseDtoToForm = (
	response: TestStageAxisConfigResponseDto
): TestAxesConfigurationFormType => {
	return {
		id: response.id,
		ccId: response.cc.id,
		axisId: response.axis.id,
		stageId: response.id,
		isFinishStageTrigger: response.stageCompleteFlag,
		useStationParams: response.useStationParams,
		wf: waveformResponseToFormConverter(response.wf),
		controlAlgorithmsForm: responseControlAlgorithmsFormConverter(response.controlAlgorithm)
	};
};

export const testStageAxisConfigsResponseDtoToForms: Converter<
	TestStageAxisConfigResponseDto[],
	TestAxesConfigurationFormType[]
> = (configs) => {
	return configs.map((config) => testStageAxisConfigResponseDtoToForm(config));
};

export const testStageConfigResponseDtoToTestStageConfigs: Converter<TestStageResponseDto, TestStageConfigs> = (
	stage
) => {
	return { ...stage, axesConfigs: testStageAxisConfigsResponseDtoToForms(stage.axesConfigs) };
};

export const testStageConfigResponseDtosToTestStageConfigs: Converter<TestStageResponseDto[], TestStageConfigs[]> = (
	stages
) => {
	return stages.map((stage) => testStageConfigResponseDtoToTestStageConfigs(stage));
};
