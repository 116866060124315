import { useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Wizard } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import DashboardTypeWizardContainer from './containers/DashboardTypeWizardContainer';
import DashboardTypeContainer from './containers/DashboardTypeContainer';
import DashboardTypeDashboardContainer from './containers/DashboardTypeDashboardContainer';
import * as paths from './dashboardTypes.const';

const useSteps = () => {
	const { t } = useTranslation('station');

	const steps = useMemo(() => {
		return [
			{ path: paths.generalInfoPath, name: t('General Information') },
			{ path: paths.dashboardPath, name: t('Dashboard template') }
		];
	}, [t]);

	return steps;
};

const DashboardTypeRouts: React.FC = () => {
	const steps = useSteps();

	const createSteps = useMemo(
		() =>
			steps.map((step) => {
				return { ...step, path: step.path === paths.generalInfoPath ? paths.generalInfoCreatePath : step.path };
			}),
		[steps]
	);

	return (
		<Routes>
			<Route
				path="create"
				element={
					<Wizard steps={createSteps}>
						<DashboardTypeWizardContainer />
					</Wizard>
				}
			>
				<Route index element={<DashboardTypeContainer />} />
			</Route>
			<Route
				path=":dashboardTypeId/*"
				element={
					<Wizard steps={steps}>
						<DashboardTypeWizardContainer />
					</Wizard>
				}
			>
				<Route index element={<DashboardTypeContainer />} />
				<Route path="dashboard" element={<DashboardTypeDashboardContainer />} />
			</Route>
		</Routes>
	);
};

export default DashboardTypeRouts;
