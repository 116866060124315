import { Converter } from '../../types';
import { responseControlAlgorithmsFormConverter } from '../ControlAlgorithms';
import { formControlAlgorithmsExternalConverter } from '../ControlAlgorithms/controlAlgorithms.converters';
import { ControlChannelSetPointType } from './controlChannel.enums';
import {
	ControlChannelResponseDto,
	ControlChannelListItem,
	ControlChannelForm,
	ControlChannelRequestDto
} from './controlChannel.types';

export const responsesListConverter: Converter<ControlChannelResponseDto[], ControlChannelListItem[]> = (responses) => {
	if (!responses) return [];

	return responses.map((response) => ({
		id: response.id,
		name: response.name,
		setPoint:
			response.setPointType === ControlChannelSetPointType.WAVEFORM
				? ControlChannelSetPointType[response.setPointType]
				: response.setPointMeasurement?.name ?? '',
		feedback: response?.feedbackMeasurement?.name,
		output: response.axis.name
	}));
};

export const responseFormConverter: Converter<ControlChannelResponseDto, ControlChannelForm> = (input) => {
	return {
		id: input.id,
		stationId: input.stationId,
		name: input.name,
		axisId: input.axis.id,
		setPoint:
			input.setPointType === ControlChannelSetPointType.WAVEFORM
				? String(input.setPointType)
				: input.setPointMeasurement?.id || '',
		isRateLimitEnabled: input.rateLimit !== undefined,
		rateLimit: input.rateLimit,
		feedbackId: input?.feedbackMeasurement?.id,
		controlAlgorithmsForm: responseControlAlgorithmsFormConverter(input.controlAlgorithm)
	};
};

export const formRequestConverter: Converter<ControlChannelForm, ControlChannelRequestDto> = (input) => {
	const request = {
		name: input.name,
		axisId: input.axisId,
		setPointType:
			input.setPoint === String(ControlChannelSetPointType.WAVEFORM)
				? ControlChannelSetPointType.WAVEFORM
				: ControlChannelSetPointType.MEASUREMENT,
		setPointMeasurementId:
			input.setPoint !== String(ControlChannelSetPointType.WAVEFORM) ? (input.setPoint as string) : undefined,
		rateLimit: input.isRateLimitEnabled ? input.rateLimit : undefined,
		feedbackMeasurementId: input.feedbackId !== null ? input.feedbackId : undefined,
		stationId: input.stationId,
		controlAlgorithm: input.controlAlgorithmsForm
			? formControlAlgorithmsExternalConverter(input.controlAlgorithmsForm)
			: undefined
	};
	return request;
};
