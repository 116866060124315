export enum WidgetsTypes {
	BUTTON_WIDGET = 10
	// GRAPH_WIDGET,
	// READOUT_WIDGET,
	// TABLE_WIDGET,
	// VIDEO_PLAYER_WIDGET
}

export enum WidgetsLayoutTypes {
	SINGLE,
	DOUBLE_VERTICAL,
	DOUBLE_HORIZONTAL,
	QUADRO
}

export enum WidgetsStatuses {
	CONFIGURED = 0,
	NOT_CONFIGURED
}

export enum WidgetCrudActionTypes {
	EDIT = 'EDIT_WIDGET',
	CREATE = 'CREATE_WIDGET',
	DUPLICATE = 'DUPLICATE_WIDGET',
	DELETE = 'DELETE_WIDGET'
}

export enum ButtonWidgetRuleActionTypes {
	EDIT = 'EDIT_RULE',
	DELETE = 'DELETE_RULE',
	CREATE = 'CREATE_RULE'
}
