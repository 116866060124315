import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Icon, InputNumber } from '@tactun/ui';
import { Controller, UseFormReturn } from 'react-hook-form';
import { OffsetCalibrationFormType } from '../../offsetCalibration.types';
import { IListItem } from '../../../../types';
import { OffsetCalibrationStatus } from '../../offsetCalibration.enums';
import styles from './OffsetCalibrationContent.module.scss';

interface OffsetCalibrationContentProps {
	form: UseFormReturn<OffsetCalibrationFormType>;
	calibrationStatus: OffsetCalibrationStatus;
	axes: IListItem[];
	offsetUnit?: string;
	onCalibrate: () => void;
	onSave: () => void;
}

const OffsetCalibrationContent = ({
	form,
	calibrationStatus,
	axes,
	offsetUnit,
	onCalibrate,
	onSave
}: OffsetCalibrationContentProps) => {
	const { t } = useTranslation('offsetCalibration');
	const isRunning = calibrationStatus === OffsetCalibrationStatus.IN_PROGRESS;
	const {
		control,
		formState: { errors },
		register
	} = form;

	return (
		<div className={styles.container}>
			<div className={styles.inputRow}>
				<Controller
					name="axisId"
					control={control}
					render={({ field }) => (
						<Dropdown
							{...field}
							options={axes}
							label={t('Axis name*')}
							data-testid="axisId"
							disabled={isRunning}
							error={errors.axisId?.message}
						/>
					)}
				/>
				<Icon onClick={onCalibrate} name="calibrate" size={18} className={styles.actionBtn} />
			</div>
			<div className={styles.inputRow}>
				<InputNumber
					{...register('offset')}
					label={t('Offset ({{offsetUnit}})', { offsetUnit })}
					data-testid="offsetId"
					error={errors.offset?.message}
					disabled={isRunning}
				/>
				<Icon onClick={onSave} name="save" size={18} className={styles.actionBtn} disabled={isRunning} />
			</div>
		</div>
	);
};

export default OffsetCalibrationContent;
