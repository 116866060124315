import { useEffect, useCallback, useState, ReactNode, useMemo, useContext } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import cx from 'classnames';
import { Button } from '../../';
import { showModal, hideModal } from './utils';
import ModalContent, { IModalContentProps } from './ModalContent';
import ModalFooter, { IModalFooterProps } from './ModalFooter';
import ModalHeader, { IModalHeaderProps } from './ModalHeader';
import styles from './Modal.module.scss';
import { HideModal, ShowModal } from './modal.types';
import { ModalContext } from './modal.context';

export interface IModalProps extends Omit<ReactModalProps, 'isOpen'> {
	className?: string;
	children: ReactNode;
	id: string;
	isOpen?: boolean;
	onClose?: () => void;
	isFitContent?: boolean;
	isLarge?: boolean;
	hidePrevModals?: boolean;
}

export interface IModalSubComponents {
	Header: React.FC<IModalHeaderProps>;
	Content: React.FC<IModalContentProps>;
	Footer: React.FC<IModalFooterProps>;
	show: ShowModal;
	hide: HideModal;
}

const Modal: React.FC<IModalProps> & IModalSubComponents = ({
	onClose,
	id,
	isOpen,
	className,
	children,
	isLarge = false,
	isFitContent = false,
	hidePrevModals = false,
	...props
}) => {
	const [isShown, setIsShown] = useState(Boolean(isOpen));
	const { removeModalData, updateModalData } = useContext(ModalContext);

	const handleClose = useCallback(() => {
		if (onClose) {
			onClose();
		} else {
			setIsShown(false);
		}
	}, [onClose]);

	useEffect(() => {
		const handleShow = (e: CustomEvent) => {
			if (id === e.detail.id) {
				if (updateModalData) {
					updateModalData(e.detail.id, e.detail.data);
				}
				setIsShown(true);
			}
		};

		const handleHide = (e: CustomEvent) => {
			if (id === e.detail.id) {
				if (removeModalData) {
					removeModalData(e.detail.id);
				}
				setIsShown(false);
			}
		};

		document.addEventListener('showModal', handleShow as EventListener);
		document.addEventListener('hideModal', handleHide as EventListener);

		return () => {
			document.removeEventListener('showModal', handleShow as EventListener);
			document.removeEventListener('hideModal', handleShow as EventListener);
		};
	}, [id, removeModalData, updateModalData]);

	const classes = useMemo(() => {
		return cx(styles.modal, className, { [styles.fitContent]: isFitContent, [styles.large]: isLarge });
	}, [className, isFitContent, isLarge]);

	useEffect(() => {
		setIsShown(Boolean(isOpen));
	}, [isOpen]);

	return (
		<ReactModal
			ariaHideApp={false}
			className={classes}
			portalClassName={cx({
				[styles.hidePrevModals]: hidePrevModals && isShown,
				[styles.openModalPortal]: isShown
			})}
			onRequestClose={handleClose}
			{...props}
			isOpen={isShown}
			overlayClassName={cx(styles.overlay)}
		>
			<Button onClick={handleClose} rounded icon="t-icon-zoom-delete" className={styles.closeIcon} />
			{isShown && children}
		</ReactModal>
	);
};

Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;
Modal.show = showModal;
Modal.hide = hideModal;

export default Modal;
