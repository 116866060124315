import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import {
	getTestDataLogging,
	getTestDataLoggings,
	createTestDataLogging,
	updateTestDataLogging
} from './testDataLogging.api';
import {
	TestDataLoggingListItem,
	TestDataLoggingRequestDto,
	TestDataLoggingResponseDto
} from './testDataLogging.types';
import { responseListItemConverter } from './testDataLogging.converters';
import { toast } from 'react-toastify';
import { StageTypes } from '../Tests/tests.enums';

export const useTestDataLoggings = (
	testId?: string,
	stageId?: string,
	stageType?: StageTypes,
	onSaveSuccess?: () => void
) => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const { data, isLoading } = useQuery<TestDataLoggingListItem[], Error>({
		queryKey: ['test-dataLogging', { stageId, stageType }],
		queryFn: async () => {
			const resp = await getTestDataLoggings(stageId as string);
			return resp
				.filter((logging) => logging.stageType === stageType)
				.map((logging) => responseListItemConverter(logging, t));
		},
		enabled: !!stageId && stageType !== undefined
	});

	const addNewItem = useCallback(
		(newVar: TestDataLoggingListItem) => {
			queryClient.setQueryData<TestDataLoggingListItem[]>(['test-dataLogging', { stageId, stageType }], (old) => {
				if (!old) return undefined;
				return [newVar, ...old];
			});
		},
		[queryClient, stageId, stageType]
	);

	const updateItem = useCallback(
		(newVar: TestDataLoggingListItem) => {
			queryClient.setQueryData<TestDataLoggingListItem[]>(['test-dataLogging', { stageId, stageType }], (old) => {
				return old?.map((o) => (o.id === newVar.id ? newVar : o));
			});
		},
		[queryClient, stageId, stageType]
	);

	const updateDataLoggingInTest = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: ['test', { testId }] });
		queryClient.invalidateQueries({ queryKey: ['test-stages', { testId }] });
	}, [queryClient, testId]);

	const createMutation = useMutation({
		mutationFn: async (data: TestDataLoggingRequestDto) => {
			const { id } = await createTestDataLogging(data);
			return await getTestDataLogging(id);
		},
		onSuccess: (newVar) => {
			addNewItem(responseListItemConverter(newVar, t));
			updateDataLoggingInTest();
			if (onSaveSuccess) {
				onSaveSuccess();
			}
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { dataLogging: TestDataLoggingRequestDto; dataLoggingId: string }) =>
			updateTestDataLogging(data.dataLogging, data.dataLoggingId),
		onSuccess: (updatedVar) => {
			updateItem(responseListItemConverter(updatedVar, t));
			if (onSaveSuccess) {
				onSaveSuccess();
			}
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const saveDataLogging = useCallback(
		(data: TestDataLoggingRequestDto, variableId?: string) => {
			if (variableId) {
				updateMutation.mutate({
					dataLogging: data,
					dataLoggingId: variableId
				});
			} else {
				if (testId) {
					createMutation.mutate(data);
				}
			}
		},
		[createMutation, testId, updateMutation]
	);

	const deleteDataLogging = useCallback(
		(id: string) => {
			queryClient.setQueryData<TestDataLoggingListItem[]>(['test-dataLogging', { stageId, stageType }], (old) => {
				return old?.filter((hc) => hc.id !== id);
			});
			updateDataLoggingInTest();
		},
		[queryClient, stageId, stageType, updateDataLoggingInTest]
	);

	return useMemo(
		() => ({
			isLoading,
			isCreating: createMutation.isPending,
			isUpdating: updateMutation.isPending,
			testDataLoggings: data,
			saveDataLogging,
			deleteDataLogging
		}),
		[createMutation.isPending, data, deleteDataLogging, isLoading, saveDataLogging, updateMutation.isPending]
	);
};

export const useTestDataLogging = (dataLoggingId?: string) => {
	const { data: testDataLogging, isLoading: isTestDataLoggingLoading } = useQuery<TestDataLoggingResponseDto>({
		queryKey: ['test-dataLogging', { dataLoggingId }],
		queryFn: () => getTestDataLogging(dataLoggingId as string),
		enabled: !!dataLoggingId
	});

	return {
		testDataLogging,
		isTestDataLoggingLoading
	};
};
