export enum VariablesTypes {
	NUMERIC = 0,
	STRING,
	BOOLEAN
}

export enum VariableTableActionTypes {
	EDIT = 'EDIT_VARIABLE',
	DELETE = 'DELETE_VARIABLE',
	CREATE = 'CREATE_VARIABLE',
	DUPLICATE = 'DUPLICATE_VARIABLE'
}
