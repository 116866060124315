import { useEffect, useMemo } from 'react';
import { CollapseProps, Dropdown, TcCollapse } from '@tactun/ui';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IListItem } from '../../../../types';
import { ControlChannelResponseDto, ControlChannelSetPointType } from '../../../ControlChannels';
import WaveformsContainer, { WaveformFormErrors } from '../../../Waveforms';
import { ControlStageFormType } from '../../controlStage.types';
import ControlAlgorithmsContainer, { ControlAlgorithmsAdditional } from '../../../ControlAlgorithms';
import { useAxis } from '../../../Axes/axes.hooks';
import { analogOutChannels, currentBasedAnalogOutChannels } from '../../../StationChannels/stationChannels.const';
import { useChangedField } from '../../controlStage.hooks';
import styles from './AxesConfigurationForm.module.scss';

interface AxesConfigurationFormProps {
	index: number;
	controlChannel?: ControlChannelResponseDto;
	controlChannels: IListItem[];
	isRunning?: boolean;
	updateConfig?: (index: number, changed: string) => void;
}

const AxesConfigurationForm: React.FC<AxesConfigurationFormProps> = ({
	controlChannels,
	index,
	controlChannel,
	updateConfig,
	isRunning
}) => {
	const { t } = useTranslation('sensors');
	const {
		control,
		formState: { errors, isDirty },
		watch
	} = useFormContext<ControlStageFormType>();
	const stationId = watch('stationId');
	const testId = watch('testId');
	const axesConfig = watch(`axesConfigs.${index}`);
	const ccId = watch(`axesConfigs.${index}.controlChannelId`);
	const { axisDto } = useAxis(axesConfig.axisId);
	const wfChangedField = useChangedField(`axesConfigs.${index}.waveform`, isRunning || false, isDirty);
	const asymmetricPidChangedField = useChangedField(
		`axesConfigs.${index}.controlAlgorithmsForm.asymmetricPid`,
		isRunning || false,
		isDirty
	);
	const pidChangedField = useChangedField(
		`axesConfigs.${index}.controlAlgorithmsForm.pid`,
		isRunning || false,
		isDirty
	);
	const ditherChangedField = useChangedField(
		`axesConfigs.${index}.controlAlgorithmsForm.dither`,
		isRunning || false,
		isDirty
	);

	const additionalAlgorithms = useWatch({
		control,
		name: `axesConfigs.${index}.controlAlgorithmsForm.additionalAlgorithms`
	});
	const isAdaptive =
		additionalAlgorithms?.includes(ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE) ||
		additionalAlgorithms?.includes(ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN);

	useEffect(() => {
		if (wfChangedField !== null) {
			updateConfig?.(index, wfChangedField.field);
		}
	}, [index, updateConfig, wfChangedField]);

	useEffect(() => {
		if (asymmetricPidChangedField !== null) {
			updateConfig?.(index, asymmetricPidChangedField.field);
		}
	}, [index, updateConfig, asymmetricPidChangedField]);

	useEffect(() => {
		if (pidChangedField !== null) {
			updateConfig?.(index, pidChangedField.field);
		}
	}, [index, updateConfig, pidChangedField]);

	useEffect(() => {
		if (ditherChangedField !== null) {
			updateConfig?.(index, ditherChangedField.field);
		}
	}, [index, updateConfig, ditherChangedField]);

	const isAxisBasedOnAnalogOut = useMemo(() => {
		const type = axisDto?.actuator?.stationChannel?.type;
		return type !== undefined && analogOutChannels.includes(type);
	}, [axisDto]);

	const accordionItems: CollapseProps['items'] = useMemo(() => {
		const items = [
			{
				label: t('Control Properties'),
				forceRender: true,
				children: (
					<div className={styles.nestedFormContainer}>
						<ControlAlgorithmsContainer
							formPrefix={`axesConfigs.${index}.controlAlgorithmsForm`}
							stationId={stationId}
							ccId={ccId}
							isRunning={isRunning}
							isAxisBasedOnAnalogOut={isAxisBasedOnAnalogOut}
							isOpenLoopAvailable={isAxisBasedOnAnalogOut}
							isControlWithCurrent={currentBasedAnalogOutChannels.some(
								(t) => t === controlChannel?.axis.actuator.stationChannel?.type
							)}
							errors={errors.axesConfigs ? errors.axesConfigs[index]?.controlAlgorithmsForm : undefined}
							isControlStage
						/>
					</div>
				)
			}
		];

		if (controlChannel && controlChannel?.setPointType === ControlChannelSetPointType.WAVEFORM && stationId) {
			items.push({
				label: t('Waveform Properties'),

				forceRender: false,
				children: (
					<div className={styles.nestedFormContainer}>
						<WaveformsContainer
							stationId={stationId}
							testId={testId}
							formPrefix={`axesConfigs.${index}.waveform`}
							controlChannelId={controlChannel.id}
							errors={errors.axesConfigs ? (errors.axesConfigs[index]?.waveform as WaveformFormErrors) : undefined}
							isRunning={isRunning}
							isAdaptive={isAdaptive}
							isControlStage
						/>
					</div>
				)
			});
		}

		return items;
	}, [
		ccId,
		controlChannel,
		errors.axesConfigs,
		index,
		isAdaptive,
		isAxisBasedOnAnalogOut,
		isRunning,
		stationId,
		t,
		testId
	]);

	return (
		<>
			<div className={styles.axesContainer}>
				<Controller
					name={`axesConfigs.${index}.controlChannelId`}
					control={control}
					render={({ field }) => (
						<Dropdown
							className={styles.controlChannel}
							{...field}
							options={controlChannels}
							label={t('Control*')}
							data-testid="controlChannelId"
							error={errors.axesConfigs ? errors.axesConfigs[index]?.controlChannelId?.message : undefined}
							filter
							disabled={isRunning}
						/>
					)}
				/>
			</div>
			<TcCollapse items={accordionItems} />
		</>
	);
};

export default AxesConfigurationForm;
