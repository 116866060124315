import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
	IActuatorRequestDto,
	IActuatorResponseDto,
	ActuatorsTypes,
	formRequestConverter,
	ActuatorFormType,
	IActuatorCalibrationResponseDto
} from '.';
import { deleteEmptyFields } from '../../tools';
import * as api from './actuators.api';
import { IStationChannelResponseDto } from '../StationChannels';

export const useActuators = (stationId?: string, unUsedOnly: boolean = false) => {
	const { data: actuators, isLoading: isActuatorsLoading } = useQuery({
		queryKey: ['actuators', { stationId }],
		// When we provide enable option - !!actuatorId, the actuatorId is guaranteed
		queryFn: () => api.getActuatorsForStation(stationId as string, unUsedOnly),
		enabled: !!stationId
	});

	const queryClient = useQueryClient();

	const updateActuators = () => {
		queryClient.invalidateQueries({ queryKey: ['actuators', { stationId }] });
	};

	const deleteActuator = useCallback(
		(id: string) => {
			queryClient.setQueryData<{ id: string }[]>(['actuators', { stationId }], (old) => {
				if (!old) return undefined;
				return old?.filter((st) => st.id !== id);
			});
		},
		[queryClient, stationId]
	);

	return {
		actuators,
		isActuatorsLoading,
		deleteActuator,
		updateActuators
	};
};

export const useActuator = (actuatorId?: string) => {
	const { data: actuatorDto, isLoading: isActuatorLoading } = useQuery<IActuatorResponseDto>({
		queryKey: ['actuator', { actuatorId }],
		// When we provide enable option - !!actuatorId, the actuatorId is guaranteed
		queryFn: () => api.getActuator(actuatorId as string),
		enabled: !!actuatorId
	});

	const queryClient = useQueryClient();

	const updateActuator = () => {
		queryClient.invalidateQueries({ queryKey: ['actuators', { actuatorId }] });
	};

	return {
		actuatorDto,
		isActuatorLoading,
		updateActuator
	};
};

export const useSaveActuator = (actuatorId?: string, onSaveSuccess?: () => void) => {
	const createMutation = useMutation({
		mutationFn: (actuator: IActuatorRequestDto) => api.createActuator(actuator),
		onSuccess: () => {
			if (onSaveSuccess) onSaveSuccess();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { actuator: IActuatorRequestDto; actuatorId: string }) =>
			api.updateActuator(data.actuator, data.actuatorId),

		onSuccess: () => {
			if (onSaveSuccess) onSaveSuccess();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const save = useCallback(
		(data: ActuatorFormType) => {
			const filteredData = deleteEmptyFields<IActuatorRequestDto>(formRequestConverter(data));
			if (actuatorId) {
				updateMutation.mutate({
					actuator: filteredData,
					actuatorId
				});
			} else {
				createMutation.mutate(filteredData);
			}
		},
		[actuatorId, createMutation, updateMutation]
	);

	return {
		save,
		isLoading: createMutation.isPending || updateMutation.isPending
	};
};

export const useAvailableStationChannelsForActuatorType = (stationId?: string, type?: ActuatorsTypes) => {
	const { data: availableStationChannels = [], isLoading: isAvailableStationChannelsLoading } = useQuery<
		IStationChannelResponseDto[]
	>({
		queryKey: ['available-channels', { stationId, type }],
		queryFn: () => api.getAvailableStationChannels(stationId as string, type as ActuatorsTypes),

		enabled: type !== undefined && !!stationId
	});

	return {
		availableStationChannels,
		isAvailableStationChannelsLoading
	};
};

export const useActuatorsManufacturers = () => {
	const { data } = useQuery({
		queryKey: ['actuatorsManufacturers'],
		queryFn: () => api.getActuatorsManufacturers()
	});

	return data || [];
};

export const useActuatorsCalibration = (actuatorId?: string) => {
	const queryKey = useMemo(() => ['actuator-calibrations', { actuatorId }], [actuatorId]);

	const { data: calibrationDto, isLoading: isCalibrationLoading } = useQuery<IActuatorCalibrationResponseDto>({
		queryKey,
		queryFn: () => api.getActuatorCalibration(actuatorId as string),
		enabled: !!actuatorId
	});

	const queryClient = useQueryClient();

	const updateCalibration = useCallback(() => {
		queryClient.invalidateQueries({ queryKey });
	}, [queryClient, queryKey]);

	return {
		calibrationDto,
		isCalibrationLoading,
		updateCalibration
	};
};
