import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuxiliaryDevicesTable from '../components/AuxiliaryDevicesTable';
import { IAuxiliaryDeviceListItem } from '../auxiliaryDevice.types';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { AuxiliaryDeviceTableActionTypes } from '../auxiliaryDevice.enums';
import { useStationName } from '../../Stations';
import { useStationAuxiliaryDevices } from '../auxiliaryDevice.hooks';
import { responseListItemConverter } from '../auxiliaryDevice.converters';

const AuxiliaryDevicesTableContainer: React.FC = () => {
	const navigate = useNavigate();
	const { stationId } = useParams();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const { stationName } = useStationName(stationId);
	const { stationAuxiliaryDevicesResponse, isStationAuxiliaryDevicesLoading, deleteAuxiliaryDevice } =
		useStationAuxiliaryDevices(stationId);
	const auxiliaryDevicesList = useMemo(
		() => stationAuxiliaryDevicesResponse?.map((auxiliaryDevice) => responseListItemConverter(auxiliaryDevice)),
		[stationAuxiliaryDevicesResponse]
	);

	const handleAction = useCallback(
		(type: AuxiliaryDeviceTableActionTypes, data?: IAuxiliaryDeviceListItem) => {
			switch (type) {
				case AuxiliaryDeviceTableActionTypes.CRETE:
					navigate('./create');
					break;
				case AuxiliaryDeviceTableActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case AuxiliaryDeviceTableActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case AuxiliaryDeviceTableActionTypes.DELETE:
					// @ts-ignore
					setEntityForDelete(data);
					break;
			}
		},
		[navigate]
	);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			deleteAuxiliaryDevice(id);
			handleDeleteCancel();
		},
		[deleteAuxiliaryDevice, handleDeleteCancel]
	);

	return (
		<>
			<AuxiliaryDevicesTable
				onAction={handleAction}
				data={auxiliaryDevicesList}
				isLoading={isStationAuxiliaryDevicesLoading}
				stationName={stationName}
			/>
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.AUXILIARY_DEVICE}
				entity={entityForDelete}
				checkUsage
			/>
		</>
	);
};

export default React.memo(AuxiliaryDevicesTableContainer);
