import React from 'react';
import styles from './ControlButton.module.scss';

interface IActionButtonProps extends React.HTMLProps<HTMLButtonElement> {
	icon: string;
}

const ControlButton: React.FC<IActionButtonProps> = ({ icon, ...other }) => {
	return <button {...other} className={`${styles.actionButton} ${icon}`} type="button" />;
};

export default React.memo(ControlButton);
