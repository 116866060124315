import { FC, useCallback, useMemo } from 'react';
import { IconButton } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { ISpecimenTypeResponse } from '../../specimenTypes.types';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { SpecimenTypeCrudActionTypes } from '../../specimenTypes.enums';
import { useTableConfigs } from '../../../../hooks';
import { calculatorValueToUserFriendly } from '../../../Calculator';

interface ISpecimenTypesTableProps {
	onAction: (type: SpecimenTypeCrudActionTypes, data?: ISpecimenTypeResponse) => void;
	data?: ISpecimenTypeResponse[];
	isLoading: boolean;
}

const SpecimenTypesTable: FC<ISpecimenTypesTableProps> = ({ data, isLoading, onAction }) => {
	const { t } = useTranslation('specimenTypes');

	const transColumns = useMemo<ITableColumns<ISpecimenTypeResponse>[]>(() => {
		return [
			{
				field: 'gaugeLengthEquation',
				header: t('Gauge length equation'),
				body: (rawData) => calculatorValueToUserFriendly(rawData.gaugeLengthEquation)
			},
			{
				field: 'areaEquation',
				header: t('Area equation'),
				body: (rawData) => calculatorValueToUserFriendly(rawData.areaEquation)
			}
		];
	}, [t]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<ISpecimenTypeResponse>(transColumns, data);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Duplicate'),
				icon: 't-icon-duplicate',
				disabled: true,
				command: () => onAction(SpecimenTypeCrudActionTypes.DUPLICATE, data)
			},
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(SpecimenTypeCrudActionTypes.DELETE, data)
			}
		],
		[t, onAction]
	);

	const handleCreate = useCallback(() => {
		onAction(SpecimenTypeCrudActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={t('Components')}>
			<PageLayout.Header title={t('Specimen Types')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('specimen type')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={data}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Specimen type name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(SpecimenTypeCrudActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default SpecimenTypesTable;
