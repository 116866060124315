import axios from '../../httpCommon';
import { IStationChannelResponseDto } from '../StationChannels';
import { IAxisRequestDto, IAxisResponseDto } from './axes.types';

export const getAxesForStation = async (stationId?: string): Promise<IAxisResponseDto[]> => {
	const response = await axios.get(`/quasar/axis/station/${stationId}`);

	return response.data as IAxisResponseDto[];
};

export const createAxis = async (axis: IAxisRequestDto) => {
	const response = await axios.post('/quasar/axis', axis);
	return response.data;
};

export const updateAxis = async (axis: IAxisRequestDto, axisId: string) => {
	const response = await axios.put(`/quasar/axis/${axisId}`, axis);
	return response.data;
};

export const getAxis = async (id?: string) => {
	const response = await axios.get(`/quasar/axis/${id}`);

	return response.data;
};

export const getAxesForTest = async (testId?: string): Promise<IAxisResponseDto[]> => {
	const response = await axios.get(`/quasar/tests/${testId}/axis`);

	return response.data as IAxisResponseDto[];
};

export const getAvailableStationChannels = async (stationId: string, type: string) => {
	const response = await axios.get(`/quasar/axis/available-station-channels/${stationId}/${type}`);

	return response.data as IStationChannelResponseDto[];
};

export const checkName = async (name: string, stationId?: string, id?: string): Promise<boolean> => {
	const response = await axios.post('/quasar/axis/isNameUnique', { id, parentId: stationId, name });
	return response.data === true;
};
