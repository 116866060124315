export interface IHeaderMenuProps {
	children?: any;
	label: string;
	target?: string;
}

// the component used for receiving props and converting them to "Primereact" "Menu Model" object
// that kind of architecture is defined to make possible migration to our own components in the future and provide the ability to use components in a declarative manner
const HeaderMenu: React.FC<IHeaderMenuProps> = ({ children }) => {
	return children;
};

export default HeaderMenu;
