import { WaveformType, WaveformValueType } from './waveforms.enums';

const rampFormDefaultValues = {
	type: WaveformType.RAMP,
	directionRel: false,
	defMode: undefined,
	rampDirection: undefined,
	rateType: WaveformValueType.VALUE,
	rateValue: undefined,
	rateLimit: null,
	rateVariableId: undefined,
	endChannel: undefined,
	endChannelValueType: WaveformValueType.VALUE,
	endChannelValue: undefined,
	endChannelVariableId: undefined,
	isInvertEndCondition: false
};

const dwellFormDefaultValues = {
	type: WaveformType.DWELL,
	durationValue: undefined,
	durationVariableId: undefined,
	durationType: WaveformValueType.VALUE
};

const constantFormDefaultValues = {
	type: WaveformType.CONSTANT,
	durationValue: undefined,
	durationVariableId: undefined,
	value: undefined,
	variableId: undefined,
	durationType: WaveformValueType.VALUE,
	valueType: WaveformValueType.VALUE
};

const sineFormDefaultValues = {
	type: WaveformType.SINE,
	defMode: undefined,
	amplitudeType: WaveformValueType.VALUE,
	amplitudeValue: undefined,
	amplitudeVariableId: undefined,
	meanType: WaveformValueType.VALUE,
	meanValue: undefined,
	meanVariableId: undefined,
	frequency: undefined,
	startingPhase: 0,
	endingPhase: 0,
	numberOfCycles: undefined
};

const duoRampFormDefaultValues = {
	type: WaveformType.DUO_RAMP,
	defMode: undefined,
	direction: undefined,
	upRateType: WaveformValueType.VALUE,
	upRateValue: undefined,
	upRateVariableId: undefined,
	downRateType: WaveformValueType.VALUE,
	downRateValue: undefined,
	downRateVariableId: undefined,
	endChannel: undefined,
	upEndChannelValueType: WaveformValueType.VALUE,
	upEndChannelValue: undefined,
	upEndChannelVariableId: undefined,
	lowerEndChannelValueType: WaveformValueType.VALUE,
	lowerEndChannelValue: undefined,
	lowerEndChannelVariableId: undefined,
	isInvertEndCondition: false,
	numberOfCycles: undefined
};

const haverSineFormDefaultValues = {
	type: WaveformType.HAVER_SINE,
	defMode: undefined,
	direction: undefined,
	durationType: WaveformValueType.VALUE,
	durationValue: undefined,
	durationVariableId: undefined,
	amplitudeType: WaveformValueType.VALUE,
	amplitudeValue: undefined,
	amplitudeVariableId: undefined,
	meanType: WaveformValueType.VALUE,
	meanValue: undefined,
	meanVariableId: undefined
};

const duoHaverSineFormDefaultValues = {
	type: WaveformType.DUO_HAVER_SINE,
	defMode: undefined,
	direction: undefined,
	upDurationType: WaveformValueType.VALUE,
	upDurationValue: undefined,
	upDurationVariableId: undefined,
	downDurationType: WaveformValueType.VALUE,
	downDurationValue: undefined,
	downDurationVariableId: undefined,
	amplitudeType: WaveformValueType.VALUE,
	amplitudeValue: undefined,
	amplitudeVariableId: undefined,
	meanType: WaveformValueType.VALUE,
	meanValue: undefined,
	meanVariableId: undefined,
	numberOfCycles: undefined
};

const taperedSineFormDefaultValues = {
	type: WaveformType.TAPERED_SINE,
	defMode: undefined,
	amplitudeType: WaveformValueType.VALUE,
	amplitudeValue: undefined,
	amplitudeVariableId: undefined,
	meanType: WaveformValueType.VALUE,
	meanValue: undefined,
	meanVariableId: undefined,
	frequency: undefined,
	envelopFrequency: undefined,
	numberOfCycles: undefined
};

const squareSineFormDefaultValue = {
	type: WaveformType.SWEEP_SINE,
	defMode: undefined,
	direction: undefined,
	amplitudeType: WaveformValueType.VALUE,
	amplitudeValue: undefined,
	amplitudeVariableId: undefined,
	meanType: WaveformValueType.VALUE,
	meanValue: undefined,
	meanVariableId: undefined,
	frequency: undefined,
	numberOfCycles: undefined
};

const sweepSineFormDefaultValue = {
	type: WaveformType.SWEEP_SINE,
	defMode: undefined,
	amplitudeType: WaveformValueType.VALUE,
	amplitudeValue: undefined,
	amplitudeVariableId: undefined,
	meanType: WaveformValueType.VALUE,
	meanValue: undefined,
	meanVariableId: undefined,
	startFrequency: undefined,
	endFrequency: undefined,
	phase: undefined,
	numberOfCycles: undefined
};

export const getDefaultValuesByType = (type?: WaveformType) => {
	if (type === WaveformType.RAMP) {
		return rampFormDefaultValues;
	}
	if (type === WaveformType.DWELL) {
		return dwellFormDefaultValues;
	}
	if (type === WaveformType.CONSTANT) {
		return constantFormDefaultValues;
	}
	if (type === WaveformType.SINE) {
		return sineFormDefaultValues;
	}
	if (type === WaveformType.DUO_RAMP) {
		return duoRampFormDefaultValues;
	}
	if (type === WaveformType.HAVER_SINE) {
		return haverSineFormDefaultValues;
	}
	if (type === WaveformType.DUO_HAVER_SINE) {
		return duoHaverSineFormDefaultValues;
	}
	if (type === WaveformType.TAPERED_SINE) {
		return taperedSineFormDefaultValues;
	}
	if (type === WaveformType.SQUAREWAVE) {
		return squareSineFormDefaultValue;
	}
	if (type === WaveformType.SWEEP_SINE) {
		return sweepSineFormDefaultValue;
	}

	return { type: undefined };
};

export const getFieldByFormPrefix = (field: object, prefix: string | undefined): Record<string, any> | null => {
	if (prefix === undefined) return field;

	const prefixParts = prefix.split('.');
	if (prefixParts.length === 1) {
		return field?.[prefix as keyof typeof field];
	} else {
		let currentField = field;
		for (let i = 0; i < prefixParts.length; i++) {
			currentField = currentField?.[prefixParts[i] as keyof typeof currentField];
			if (currentField === undefined) {
				return null;
			}
		}
		return currentField;
	}
};
