import React, { useCallback } from 'react';
import { DataTable, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { DataTableSelectionSingleChangeEvent, DataTableValueArray } from 'primereact/datatable';
import { SpecimenListItem } from '../../../Specimens/specimens.types';
import { CrudActions } from '../../../../components';
import styles from './SpecimenSelectionModalContent.module.scss';

interface ISpecimenSelectionModalContentProps {
	specimens: SpecimenListItem[];
	isLoading: boolean;
	selected?: SpecimenListItem;
	onSelect: (specimen: SpecimenListItem) => void;
	onCreate: () => void;
}

const SpecimenSelectionModalContent: React.FC<ISpecimenSelectionModalContentProps> = ({
	specimens,
	selected,
	isLoading,
	onSelect,
	onCreate
}) => {
	const { t } = useTranslation('specimens');

	const handleSpecimenSelection = useCallback(
		(event: DataTableSelectionSingleChangeEvent<DataTableValueArray>) => {
			onSelect(event.value as SpecimenListItem);
		},
		[onSelect]
	);

	return (
		<Modal.Content className={styles.container}>
			<div className={styles.label}>
				{t('Please select single specimen or a batch to be tested or create new specimen')}
			</div>
			<DataTable
				visibleRowsCount={6}
				value={specimens}
				loading={isLoading}
				dataKey="id"
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSpecimenSelection}
				selectionMode="single"
			>
				<DataTable.Column selectionMode="single" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Specimen ID')} />
				<DataTable.Column sortable resizeable field="type" header={t('Specimen type')} />
				<DataTable.Column sortable resizeable field="material" header={t('Specimen material')} />
				{/*<DataTable.Column*/}
				{/*	sortable*/}
				{/*	resizeable*/}
				{/*	body={(specimen: SpecimenListItem) => (*/}
				{/*		<Tag*/}
				{/*			className={specimen.isBatch ? styles.batchTag : styles.singleTag}*/}
				{/*			severity="success"*/}
				{/*			value={specimen.isBatch ? t('batch') : t('single')}*/}
				{/*			rounded*/}
				{/*		/>*/}
				{/*	)}*/}
				{/*	header={t('Batch Relation')}*/}
				{/*/>*/}
				<DataTable.Column exportable={false} align="right" headerStyle={{ width: '3em' }} />
			</DataTable>
			<div className={styles.createBtnContainer}>
				<CrudActions onCreate={onCreate} entityLabel={t('Specimen')} />
			</div>
		</Modal.Content>
	);
};

export default React.memo(SpecimenSelectionModalContent);
