import { createContext, useEffect, useRef, useState, useId } from 'react';
import { LightningChart, lightningChart } from '@arction/lcjs';
import styles from './LightningChartProvider.module.scss';
import { tError } from '../../tools/logger';

const licenseInfo = {
	// Valid until 2/10/2025
	license: process.env.REACT_APP_LCJS_LICENSE_KEY
};
export const LCContext = createContext<LightningChart | null>(null);

export const LCProvider = ({ children }: { children: React.ReactNode }) => {
	const [canvasState, setCanvasState] = useState<HTMLCanvasElement | null>(null);
	const lcRef = useRef<LightningChart | null>(null);
	const id = useId();
	const [lcState, setLcState] = useState<LightningChart | null>(null);

	useEffect(() => {
		if (!lcRef.current && canvasState) {
			try {
				lcRef.current = lightningChart({
					...licenseInfo,
					sharedContextOptions: {
						canvas: canvasState,
						useIndividualCanvas: false
					},
					overrideInteractionMouseButtons: {
						chartXYPanMouseButton: 0
					},
					warnings: true
				});
				setLcState(lcRef.current);
			} catch (e) {
				tError(e);
			}
		}

		return () => {
			if (lcRef.current && 'dispose' in lcRef.current) {
				lcRef.current.dispose();
				lcRef.current = null;
				setLcState(null);
			}
		};
	}, [canvasState]);

	return (
		<>
			<canvas className={styles.canvasContainer} key={id} ref={(newRef) => setCanvasState(newRef)}></canvas>
			<LCContext.Provider value={lcState}>{children}</LCContext.Provider>
		</>
	);
};
