import { Button, Checkbox } from '@tactun/ui';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGroup } from '../group.hooks';
import { IMenuItemGroup } from '../group.types';
import styles from './GroupMenu.module.scss';

interface GroupMenuForm {
	groups: IMenuItemGroup[];
}

const GroupMenu = () => {
	const { t } = useTranslation('groups');
	const menu = useRef<Menu>(null);
	const { create, update, groupMenuItems } = useGroup();
	const form = useForm<GroupMenuForm>({ mode: 'onChange', defaultValues: { groups: groupMenuItems } });
	const {
		control,
		reset,
		formState: { dirtyFields },
		handleSubmit
	} = form;
	const { fields } = useFieldArray({
		control,
		name: 'groups'
	});

	useEffect(() => {
		reset({ groups: groupMenuItems });
	}, [groupMenuItems, reset]);

	const handleUpdate = useCallback(
		(data: GroupMenuForm) => {
			menu.current?.hide({} as SyntheticEvent);

			const addedGroupIds = data.groups
				?.filter((group, index) => dirtyFields.groups?.[index] && group.checked)
				.map((group) => group.id);
			const removedGroupIds = data.groups
				?.filter((group, index) => dirtyFields.groups?.[index] && !group.checked)
				.map((group) => group.id);

			update(addedGroupIds, removedGroupIds);
		},
		[dirtyFields.groups, update]
	);

	const items: MenuItem[] = useMemo(
		() => [
			{
				label: t('Add to a group'),
				className: styles.groupName,
				items: [
					...fields?.map((group, index) => ({
						template: (
							<label>
								<Controller
									render={({ field }) => (
										<Checkbox label={group.name} className={styles.groupListItem} checked={!!field.value} {...field} />
									)}
									name={`groups.${index}.checked`}
									control={control}
								/>
							</label>
						)
					})),
					{
						template: (
							<div className={styles.groupContainerAddButton}>
								{dirtyFields?.groups ? (
									<Button
										icon="t-icon-check"
										className={styles.groupAddButton}
										pt={{
											icon: { className: styles.groupAddButtonIcon }
										}}
										label={t('Ok')}
										variant="text"
										color="secondary"
										onClick={handleSubmit(handleUpdate)}
									/>
								) : (
									<Button
										icon="t-icon-plus"
										className={styles.groupAddButton}
										pt={{
											icon: { className: styles.groupAddButtonIcon }
										}}
										label={t('Create New Group')}
										variant="text"
										color="secondary"
										onClick={create}
									/>
								)}
							</div>
						)
					}
				]
			}
		],
		[control, create, dirtyFields?.groups, fields, handleSubmit, handleUpdate, t]
	);

	return (
		groupMenuItems && (
			<>
				<Menu className={styles.menuContainer} model={items} popup ref={menu} />
				<Button
					label={t('Group')}
					variant="text"
					color="success"
					icon="t-icon-group"
					onClick={(e) => menu.current?.toggle?.(e)}
					className={styles.btn}
					pt={{
						icon: { className: styles.btnIcon }
					}}
				/>
			</>
		)
	);
};

export default GroupMenu;
