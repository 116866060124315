import { Converter } from '../../types';
import { CalculationDto, CalculationRequestDto, CalculationResponseDto, CalculationTypes } from '../Calculations';
import { RuleConditionType, RuleRequestDtoBase, RuleResponseDtoBase, RuleType } from '../Rules';
import { ActionListItem } from './testActions.types';
import { TestActionLocations } from './testActions.enums';
import { StageTypes } from '../Tests';
import { ActionCalculationProperties, ActionDtoObjectTypes, ActionRunType, CalculationActionDto } from '../Actions';
import { ConditionLogic } from '../Conditions';

export const actionResponseToListItemConverter: Converter<RuleRequestDtoBase | RuleResponseDtoBase, ActionListItem> = (
	response
) => {
	return {
		id: (response as RuleResponseDtoBase).uuid,
		name: response.name,
		type: RuleConditionType[response.ruleConditionType],
		isCalculation: false
	};
};

export const calculationResponseToListItemConverter: Converter<CalculationResponseDto, ActionListItem> = (response) => {
	return {
		id: response.uuid,
		name: response.name,
		type: CalculationTypes[response.calculation.type],
		isCalculation: true
	};
};

export const testActionLocationToStageTypes: Converter<TestActionLocations, StageTypes> = (location) => {
	if (location === TestActionLocations.PRE_STAGE) return StageTypes.PRE;
	if (location === TestActionLocations.POST_STAGE) return StageTypes.POST;

	return StageTypes.MAIN;
};

export const calculationToTestAction: Converter<CalculationRequestDto, RuleRequestDtoBase> = (calc) => {
	return {
		name: calc.name,
		ruleType: RuleType.TEST,
		triggers: [],
		conditionBondType: ConditionLogic.AND,
		ruleConditionType: RuleConditionType.UNCONDITIONAL,
		positiveRunType: ActionRunType.PARALLEL,
		rulePositiveActions: [
			{
				type: ActionDtoObjectTypes.CALCULATION,
				action: ActionCalculationProperties.RUN,
				calculation: calc.calculation
			}
		]
	};
};

export const testActionToCalculation: Converter<RuleResponseDtoBase, CalculationResponseDto> = (action) => {
	const calculationAction = action.rulePositiveActions?.length
		? (action.rulePositiveActions[0] as CalculationActionDto)
		: undefined;

	return {
		uuid: action.uuid,
		name: action.name,
		stationUuid: '',
		calculation: calculationAction?.calculation as CalculationDto
	};
};

export const actionToTestAction: Converter<TestActionLocations, StageTypes> = (location) => {
	if (location === TestActionLocations.PRE_STAGE) return StageTypes.PRE;
	if (location === TestActionLocations.POST_STAGE) return StageTypes.POST;

	return StageTypes.MAIN;
};
