import {
	MeasurementPropAmpChange,
	MeasurementPropInRange,
	MeasurementPropLessThan,
	MeasurementPropMeanChange,
	MeasurementPropMoreThan,
	MeasurementPropOutOfRange,
	MeasurementTriggerType,
	SpecimenTriggerType,
	TriggerAuxDevice,
	TriggerAxis,
	TriggerConnection,
	TriggerDigitalInput,
	TriggerDto,
	TriggerMeasurement,
	TriggerSpecimen,
	TriggerSpecimenBreak,
	TriggerSpecimenYield,
	TriggerStation,
	TriggerTest,
	TriggerType,
	TriggerVariable,
	VariableRequest,
	VariableType
} from '@tactun/grpc-client';
import { Converter } from '../../types';
import {
	TriggerAuxDeviceProperties,
	TriggerAxisProperties,
	TriggerConnectionProperties,
	TriggerDigitalInputProperties,
	TriggerDtoObjectTypes,
	TriggerMeasurementInOutRangeProperties,
	TriggerMeasurementMoreThanLessThanProperties,
	TriggerMeasurementProperties,
	TriggerObjectTypes,
	TriggerSpecimenProperties,
	TriggerStationProperties,
	TriggerTestProperties,
	TriggerUIButtonProperties,
	TriggerVariableProperties
} from './triggers.enums';
import { RuleTrigger, RuleTriggerDto, SpecimenBreakTriggerDto, SpecimenYieldTriggerDto } from './triggers.types';
import { VariablesTypes } from '../Variables';

export const triggerFormRequestConverter = (form: RuleTrigger): RuleTriggerDto => {
	if (form.objectType === undefined || form.objectType === null) {
		throw new Error('Trigger request converter: missing object type');
	}

	switch (form.objectType) {
		case TriggerObjectTypes.DIGITAL_INPUT:
			return {
				uuid: form.id,
				type: TriggerDtoObjectTypes.DIGITAL_INPUT,
				diChannelUuid: form.objectId as string,
				diProp: form.property as TriggerDigitalInputProperties
			};

		case TriggerObjectTypes.AUX_DEVICE:
			return {
				uuid: form.id,
				type: TriggerDtoObjectTypes.AUX_DEVICE,
				auxDeviceUuid: form.objectId as string,
				auxDeviceProp: form.property as TriggerAuxDeviceProperties
			};

		case TriggerObjectTypes.SPECIMEN:
			return form.property === TriggerSpecimenProperties.BREAK
				? ({
						uuid: form.id,
						type: TriggerDtoObjectTypes.SPECIMEN_BREAK,
						measurementUuid: form.objectId,
						threshold: form.threshold,
						dropPercentage: form.dropPercentage
				  } as SpecimenBreakTriggerDto)
				: ({
						uuid: form.id,
						type: TriggerDtoObjectTypes.SPECIMEN_YIELD,
						stressMeasurementUuid: form.stressMeasurementId,
						strainMeasurementUuid: form.strainMeasurementId,
						thresholdMeasurementUuid: form.thresholdMeasurementId,
						threshold: form.threshold
				  } as SpecimenYieldTriggerDto);

		case TriggerObjectTypes.MEASUREMENT: {
			switch (form.property as TriggerMeasurementProperties) {
				case TriggerMeasurementProperties.IN_RANGE:
				case TriggerMeasurementProperties.OUT_OF_RANGE:
					return {
						uuid: form.id,
						type: TriggerDtoObjectTypes.MEASUREMENT_IN_OUT_RANGE,
						measurementUuid: form.objectId as string,
						condition:
							form.property === TriggerMeasurementProperties.IN_RANGE
								? TriggerMeasurementInOutRangeProperties.IN_RANGE
								: TriggerMeasurementInOutRangeProperties.OUT_OF_RANGE,
						upperLimit: form.upperLimit as number,
						lowerLimit: form.lowerLimit as number
					};
				case TriggerMeasurementProperties.MORE_THAN:
				case TriggerMeasurementProperties.LESS_THAN:
					return {
						uuid: form.id,
						type: TriggerDtoObjectTypes.MEASUREMENT_MORE_THAN_LESS_THAN,
						measurementUuid: form.objectId as string,
						condition:
							form.property === TriggerMeasurementProperties.LESS_THAN
								? TriggerMeasurementMoreThanLessThanProperties.LESS_THAN
								: TriggerMeasurementMoreThanLessThanProperties.MORE_THAN,
						threshold: form.threshold as number
					};
				case TriggerMeasurementProperties.AMPLITUDE_CHANGE:
					return {
						uuid: form.id,
						type: TriggerDtoObjectTypes.MEASUREMENT_AMPLITUDE_CHANGE,
						measurementUuid: form.objectId as string,
						amplitudeChange: form.amplitudeChange as number,
						delayCycles: form.delayCycles as number,
						changeCycles: form.changeCycles as number,
						referenceCycles: form.referenceCycles as number,
						axisUuid: form.referenceAxis as string
					};
				case TriggerMeasurementProperties.MEAN_CHANGE:
					return {
						uuid: form.id,
						type: TriggerDtoObjectTypes.MEASUREMENT_MEAN_CHANGE,
						measurementUuid: form.objectId as string,
						maxMeanShift: form.maxMeanShift as number,
						delayCycles: form.delayCycles as number,
						changeCycles: form.changeCycles as number,
						referenceCycles: form.referenceCycles as number,
						axisUuid: form.referenceAxis as string
					};
			}
			break;
		}

		case TriggerObjectTypes.TEST:
			return {
				uuid: form.id,
				type: TriggerDtoObjectTypes.TEST,
				testState: form.property as TriggerTestProperties
			};

		case TriggerObjectTypes.CONNECTION:
			return {
				uuid: form.id,
				type: TriggerDtoObjectTypes.CONNECTION,
				connectionState: form.property as TriggerConnectionProperties
			};

		case TriggerObjectTypes.AXIS:
			return {
				uuid: form.id,
				type: TriggerDtoObjectTypes.AXIS,
				axisUuid: form.objectId as string,
				action: form.property as TriggerAxisProperties
			};

		case TriggerObjectTypes.STATION:
			return {
				uuid: form.id,
				type: TriggerDtoObjectTypes.STATION,
				action: form.property as TriggerStationProperties
			};

		case TriggerObjectTypes.UI_BUTTON:
			return {
				uuid: form.id,
				type: TriggerDtoObjectTypes.UI_BUTTON,
				buttonState: form.property as TriggerUIButtonProperties
			};

		case TriggerObjectTypes.VARIABLE:
			return {
				uuid: form.id,
				type: TriggerDtoObjectTypes.VARIABLE,
				condition: form.property as TriggerVariableProperties,
				varUuid: form.objectId as string,
				varType:
					typeof form.variableValue === 'string'
						? VariablesTypes.STRING
						: typeof form.variableValue === 'boolean'
						? VariablesTypes.BOOLEAN
						: VariablesTypes.NUMERIC,
				value: form.variableValue?.toString() as string
			};
	}
};

export const triggerResponseConverter = (response: RuleTriggerDto): RuleTrigger => {
	switch (response.type) {
		case TriggerDtoObjectTypes.DIGITAL_INPUT:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.DIGITAL_INPUT,
				property: response.diProp,
				objectId: response.diChannelUuid
			};
		case TriggerDtoObjectTypes.AUX_DEVICE:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.AUX_DEVICE,
				property: response.auxDeviceProp,
				objectId: response.auxDeviceUuid
			};
		case TriggerDtoObjectTypes.SPECIMEN_BREAK:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.SPECIMEN,
				property: TriggerSpecimenProperties.BREAK,
				objectId: response.measurementUuid,
				threshold: response.threshold,
				dropPercentage: response.dropPercentage
			};
		case TriggerDtoObjectTypes.SPECIMEN_YIELD:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.SPECIMEN,
				property: TriggerSpecimenProperties.YIELD,
				threshold: response.threshold,
				stressMeasurementId: response.stressMeasurementUuid,
				strainMeasurementId: response.strainMeasurementUuid,
				thresholdMeasurementId: response.thresholdMeasurementUuid
			};
		case TriggerDtoObjectTypes.MEASUREMENT_IN_OUT_RANGE:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.MEASUREMENT,
				objectId: response.measurementUuid,
				property:
					response.condition === TriggerMeasurementInOutRangeProperties.IN_RANGE
						? TriggerMeasurementProperties.IN_RANGE
						: TriggerMeasurementProperties.OUT_OF_RANGE,
				upperLimit: response.upperLimit,
				lowerLimit: response.lowerLimit
			};
		case TriggerDtoObjectTypes.MEASUREMENT_MORE_THAN_LESS_THAN:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.MEASUREMENT,
				objectId: response.measurementUuid,
				property:
					response.condition === TriggerMeasurementMoreThanLessThanProperties.LESS_THAN
						? TriggerMeasurementProperties.LESS_THAN
						: TriggerMeasurementProperties.MORE_THAN,
				threshold: response.threshold
			};
		case TriggerDtoObjectTypes.MEASUREMENT_AMPLITUDE_CHANGE:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.MEASUREMENT,
				objectId: response.measurementUuid,
				property: TriggerMeasurementProperties.AMPLITUDE_CHANGE,
				amplitudeChange: response.amplitudeChange,
				delayCycles: response.delayCycles,
				changeCycles: response.changeCycles,
				referenceCycles: response.referenceCycles,
				referenceAxis: response.axisUuid
			};
		case TriggerDtoObjectTypes.MEASUREMENT_MEAN_CHANGE:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.MEASUREMENT,
				objectId: response.measurementUuid,
				property: TriggerMeasurementProperties.MEAN_CHANGE,
				maxMeanShift: response.maxMeanShift,
				delayCycles: response.delayCycles,
				changeCycles: response.changeCycles,
				referenceCycles: response.referenceCycles,
				referenceAxis: response.axisUuid
			};
		case TriggerDtoObjectTypes.TEST:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.TEST,
				property: response.testState
			};
		case TriggerDtoObjectTypes.CONNECTION:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.CONNECTION,
				property: response.connectionState
			};
		case TriggerDtoObjectTypes.AXIS:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.AXIS,
				objectId: response.axisUuid,
				property: response.action
			};
		case TriggerDtoObjectTypes.STATION:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.STATION,
				property: response.action
			};
		case TriggerDtoObjectTypes.UI_BUTTON:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.UI_BUTTON,
				property: response.buttonState
			};
		case TriggerDtoObjectTypes.VARIABLE:
			return {
				id: response.uuid,
				objectType: TriggerObjectTypes.VARIABLE,
				objectId: response.varUuid,
				variableValue:
					response.varType === VariablesTypes.BOOLEAN
						? response.value === 'true'
						: response.varType === VariablesTypes.NUMERIC
						? Number(response.value)
						: response.value,
				property: response.condition
			};
	}
};

export const triggerResponseToGRPCRequestConverter: Converter<RuleTriggerDto, TriggerDto> = (triggerResp) => {
	const trigger = new TriggerDto();
	trigger.setId(triggerResp.uuid as string);

	switch (triggerResp.type) {
		case TriggerDtoObjectTypes.DIGITAL_INPUT:
			trigger.setType(TriggerType.TRG_DIGITAL_INPUT);

			const triggerDigitalInput = new TriggerDigitalInput();
			triggerDigitalInput.setChannelId(triggerResp.diChannelUuid);
			triggerDigitalInput.setDiProp(triggerResp.diProp);
			trigger.setTriggerDi(triggerDigitalInput);

			break;
		case TriggerDtoObjectTypes.AUX_DEVICE:
			trigger.setType(TriggerType.TRG_AUX_DEVICE);

			const triggerAuxDevice = new TriggerAuxDevice();
			triggerAuxDevice.setAuxDevId(triggerResp.auxDeviceUuid);
			triggerAuxDevice.setAuxDevProp(triggerResp.auxDeviceProp);
			// TODO Aux device object needed here
			trigger.setTriggerAux(triggerAuxDevice);

			break;
		case TriggerDtoObjectTypes.SPECIMEN_BREAK:
		case TriggerDtoObjectTypes.SPECIMEN_YIELD:
			trigger.setType(TriggerType.TRG_SPECIMEN);

			const triggerSpecimen = new TriggerSpecimen();
			if (triggerResp.type === TriggerDtoObjectTypes.SPECIMEN_BREAK) {
				const triggerSpecimenBreak = new TriggerSpecimenBreak();
				triggerSpecimenBreak.setDrop(triggerResp.dropPercentage);
				triggerSpecimenBreak.setMeasId(triggerResp.measurementUuid);
				triggerSpecimenBreak.setThreshold(triggerResp.threshold);
				triggerSpecimen.setType(SpecimenTriggerType.SPECIMEN_BREAK);
				triggerSpecimen.setBreak(triggerSpecimenBreak);
			}
			if (triggerResp.type === TriggerDtoObjectTypes.SPECIMEN_YIELD) {
				const triggerSpecimenYield = new TriggerSpecimenYield();
				triggerSpecimenYield.setStrainMeasId(triggerResp.strainMeasurementUuid);
				triggerSpecimenYield.setStressMeasId(triggerResp.stressMeasurementUuid);
				triggerSpecimenYield.setThreshold(triggerResp.threshold);
				triggerSpecimenYield.setThresholdMeasId(triggerResp.thresholdMeasurementUuid);
				triggerSpecimen.setType(SpecimenTriggerType.SPECIMEN_YIELD);
				triggerSpecimen.setYield(triggerSpecimenYield);
			}
			trigger.setTriggerSpecimen(triggerSpecimen);

			break;
		case TriggerDtoObjectTypes.MEASUREMENT_MORE_THAN_LESS_THAN:
		case TriggerDtoObjectTypes.MEASUREMENT_IN_OUT_RANGE:
		case TriggerDtoObjectTypes.MEASUREMENT_MEAN_CHANGE:
		case TriggerDtoObjectTypes.MEASUREMENT_AMPLITUDE_CHANGE:
			trigger.setType(TriggerType.TRG_MEASUREMENT);

			const triggerMeasurement = new TriggerMeasurement();
			triggerMeasurement.setMeasId(triggerResp.measurementUuid);

			if (triggerResp.type === TriggerDtoObjectTypes.MEASUREMENT_MORE_THAN_LESS_THAN) {
				if (triggerResp.condition === TriggerMeasurementMoreThanLessThanProperties.MORE_THAN) {
					triggerMeasurement.setType(MeasurementTriggerType.TRG_MORE_THAN);
					const moreThan = new MeasurementPropMoreThan();
					moreThan.setUpperThreshold(triggerResp.threshold);
					triggerMeasurement.setMoreThan(moreThan);
				}

				if (triggerResp.condition === TriggerMeasurementMoreThanLessThanProperties.LESS_THAN) {
					triggerMeasurement.setType(MeasurementTriggerType.TRG_LESS_THAN);
					const lessThan = new MeasurementPropLessThan();
					lessThan.setLowerThreshold(triggerResp.threshold);
					triggerMeasurement.setLessThan(lessThan);
				}
			}

			if (triggerResp.type === TriggerDtoObjectTypes.MEASUREMENT_IN_OUT_RANGE) {
				if (triggerResp.condition === TriggerMeasurementInOutRangeProperties.IN_RANGE) {
					triggerMeasurement.setType(MeasurementTriggerType.TRG_IN_RANGE);
					const inRange = new MeasurementPropInRange();
					inRange.setLowerLimit(triggerResp.lowerLimit);
					inRange.setUpperLimit(triggerResp.upperLimit);
					triggerMeasurement.setInRange(inRange);
				}

				if (triggerResp.condition === TriggerMeasurementInOutRangeProperties.OUT_OF_RANGE) {
					triggerMeasurement.setType(MeasurementTriggerType.TRG_OUT_OF_RANGE);
					const outOfRange = new MeasurementPropOutOfRange();
					outOfRange.setLowerLimit(triggerResp.lowerLimit);
					outOfRange.setUpperLimit(triggerResp.upperLimit);
					triggerMeasurement.setOutOfRange(outOfRange);
				}
			}

			if (triggerResp.type === TriggerDtoObjectTypes.MEASUREMENT_AMPLITUDE_CHANGE) {
				triggerMeasurement.setType(MeasurementTriggerType.TRG_AMPLITUDE_CHANGE);
				const ampChange = new MeasurementPropAmpChange();
				ampChange.setAmpChange(triggerResp.amplitudeChange);
				ampChange.setChangeCycles(triggerResp.changeCycles);
				ampChange.setDelayCycles(triggerResp.delayCycles);
				ampChange.setRefCycles(triggerResp.referenceCycles);
				ampChange.setAxisId(triggerResp.axisUuid);
				triggerMeasurement.setAmpChange(ampChange);
			}

			if (triggerResp.type === TriggerDtoObjectTypes.MEASUREMENT_MEAN_CHANGE) {
				triggerMeasurement.setType(MeasurementTriggerType.TRG_MEAN_CHANGE);
				const meanChange = new MeasurementPropMeanChange();
				meanChange.setMaxMeanShift(triggerResp.maxMeanShift);
				meanChange.setChangeCycles(triggerResp.changeCycles);
				meanChange.setDelayCycles(triggerResp.delayCycles);
				meanChange.setRefCycles(triggerResp.referenceCycles);
				meanChange.setAxisId(triggerResp.axisUuid);
				triggerMeasurement.setMeanChange(meanChange);
			}
			trigger.setTriggerMeas(triggerMeasurement);

			break;
		case TriggerDtoObjectTypes.TEST:
			trigger.setType(TriggerType.TRG_TEST);

			const triggerTest = new TriggerTest();
			triggerTest.setTestProp(triggerResp.testState);
			trigger.setTriggerTest(triggerTest);

			break;
		case TriggerDtoObjectTypes.CONNECTION:
			trigger.setType(TriggerType.TRG_CONNECTION);

			const triggerConnection = new TriggerConnection();
			triggerConnection.setConnProp(triggerResp.connectionState);
			triggerConnection.setTimeoutSec(0);
			trigger.setTriggerConn(triggerConnection);

			break;
		case TriggerDtoObjectTypes.AXIS:
			trigger.setType(TriggerType.TRG_AXIS);

			const triggerAxis = new TriggerAxis();
			triggerAxis.setAxisProp(triggerResp.action);
			triggerAxis.setAxisId(triggerResp.axisUuid);
			trigger.setTriggerAxis(triggerAxis);

			break;
		case TriggerDtoObjectTypes.STATION:
			trigger.setType(TriggerType.TRG_STATION);

			const triggerStation = new TriggerStation();
			triggerStation.setStationProp(triggerResp.action);
			trigger.setTriggerStation(triggerStation);

			break;
		case TriggerDtoObjectTypes.VARIABLE:
			trigger.setType(TriggerType.TRG_VARIABLE);

			const variableTrigger = new TriggerVariable();

			variableTrigger.setPredicate(triggerResp.condition);

			const variable = new VariableRequest();
			variable.setId(triggerResp.varUuid);
			variable.setName(triggerResp.varUuid);
			if (triggerResp.varType === VariablesTypes.BOOLEAN) {
				variable.setType(VariableType.VARIABLE_TYPE_BOOLEAN);
				variable.setDefaultValBool(triggerResp.value === 'true');
			} else if (triggerResp.varType === VariablesTypes.NUMERIC) {
				variable.setType(VariableType.VARIABLE_TYPE_NUMERIC);
				variable.setDefaultValNum(Number(triggerResp.value));
			} else if (triggerResp.varType === VariablesTypes.STRING) {
				variable.setType(VariableType.VARIABLE_TYPE_STRING);
				variable.setDefaultValStr(triggerResp.value as string);
			}

			variableTrigger.setVariable(variable);
			trigger.setTriggerVariable(variableTrigger);

			break;
	}

	return trigger;
};
