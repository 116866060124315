import { Routes, Route } from 'react-router-dom';
import UsersContainer from './containers/UsersContainer';
import UsersTableContainer from './containers/UsersTableContainer';

const UsersRouts: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<UsersTableContainer />} />
			<Route path="create" element={<UsersContainer />} />
			<Route path="edit/:userId" element={<UsersContainer />} />
		</Routes>
	);
};

export default UsersRouts;
