import { Button, DataTable, Modal, StatusLabel, StatusLabelTypes } from '@tactun/ui';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportResultType } from '../testReportConfiguration.enums';
import { ResultsTableRow } from '../testReportConfiguration.types';
import styles from './TestReportConfiguration.module.scss';

export interface ResultsTableProps {
	statuses: {
		variables?: boolean;
		specimenParams?: boolean;
		calculations?: boolean;
	};
}

const ResultsTable: FC<ResultsTableProps> = ({ statuses }) => {
	const { t } = useTranslation('testConfiguration');

	const resultsTableData = useMemo<ResultsTableRow[]>(() => {
		return [
			{
				tableName: t('Calculations'),
				table: ReportResultType.CALCULATION_RESULT,
				isConfigured: statuses?.calculations || false
			},
			{
				tableName: t('Specimen Parameters'),
				table: ReportResultType.SPECIMEN_RESULT,
				isConfigured: statuses?.specimenParams || false
			},
			{
				tableName: t('Variables'),
				table: ReportResultType.VARIABLE_RESULT,
				isConfigured: statuses?.variables || false
			}
		];
	}, [t, statuses]);

	const handleOpenTable = (tableType: ReportResultType) => {
		Modal.show(tableType);
	};

	return (
		<>
			<h3 className={styles.sectionLabel}>{t('Result tables')}</h3>
			<DataTable value={resultsTableData} showGridlines columnResizeMode="fit">
				<DataTable.Column header={t('Table name')} field="tableName" />
				<DataTable.Column
					header={t('State')}
					body={(rowData) => (
						<StatusLabel type={rowData.isConfigured ? StatusLabelTypes.SUCCESS : StatusLabelTypes.GRAY}>
							{rowData.isConfigured ? t('CONFIGURED') : t('NOT CONFIGURED')}
						</StatusLabel>
					)}
				/>
				<DataTable.Column
					align="right"
					body={(rowData) => (
						<Button variant="text" color="secondary" type="button" onClick={() => handleOpenTable(rowData.table)}>
							{rowData.isConfigured ? t('Edit') : t('Configure')}
						</Button>
					)}
				/>
			</DataTable>
		</>
	);
};

export default ResultsTable;
