import React, { useCallback, useMemo } from 'react';
import { Button, DataTable, KebabMenu, StatusLabel, StatusLabelSizes, StatusLabelTypes } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@tactun/ui';
import { TestSequencerRulesActionTypes } from '../../testSequencerRules.enums';
import { ITableColumns } from '../../../../types';
import { RuleListItem, RuleStatus } from '../../../Rules';
import styles from './TestSequencerRulesTable.module.scss';

interface ITestSequencerRulesTableProps {
	onAction: (type: TestSequencerRulesActionTypes, data?: RuleListItem) => void;
	existingRules: RuleListItem[];
	currentStageRules: RuleListItem[];
	isGlobal: boolean;
	isLoadingExistingRules: boolean;
	isLoadingCurrentStageRules: boolean;
}

const TestSequencerRulesTable: React.FC<ITestSequencerRulesTableProps> = ({
	onAction,
	isGlobal,
	existingRules,
	currentStageRules,
	isLoadingExistingRules,
	isLoadingCurrentStageRules
}) => {
	const { t } = useTranslation(['testSequencerRules', 'ruleTrigger']);

	const transColumns = useMemo<ITableColumns<RuleListItem>[]>(() => {
		return [
			{ field: 'name', header: t('Rule Name') },
			{
				field: 'ruleTrigger',
				header: t('Rule Trigger'),
				body: (data: RuleListItem) =>
					data.triggerTypes.map((triggerType) => t(triggerType, { ns: 'ruleTrigger' })).join(', ')
			},
			{
				field: 'status',
				header: t('Status'),
				body: (data: RuleListItem) => {
					const statusLabelState =
						data.status === RuleStatus.ENABLED
							? { type: StatusLabelTypes.SUCCESS, text: t('Active') }
							: { type: StatusLabelTypes.GRAY, text: t('Disabled') };
					return (
						<StatusLabel type={statusLabelState.type} size={StatusLabelSizes.SMALL}>
							{t(statusLabelState.text)}
						</StatusLabel>
					);
				}
			}
		];
	}, [t]);

	const columnComponents = transColumns.map((col) => <DataTable.Column resizeable key={col.field} {...col} />);

	const createMenuItems = useCallback(
		(data: any) => [
			{ label: t('Edit'), command: () => onAction(TestSequencerRulesActionTypes.EDIT, data) },

			{
				label: t('Duplicate'),
				disabled: true,
				icon: 't-icon-duplicate',
				command: () => onAction(TestSequencerRulesActionTypes.DUPLICATE, data)
			},
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(TestSequencerRulesActionTypes.DELETE, data)
			}
		],
		[onAction, t]
	);

	return (
		<>
			<div className={styles.title}>{t('Existing rules')}</div>
			<DataTable
				value={existingRules}
				loading={isLoadingExistingRules}
				dataKey="id"
				showGridlines
				columnResizeMode="fit"
			>
				{columnComponents}
				<DataTable.Column
					resizeable
					field="createdInStation"
					header={t('Rule Origin')}
					body={(data: RuleListItem) => {
						return data.createdInStation ? t('Test Rules') : t('Test Configuration');
					}}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="duplicate"
							fontSize="1.2rem"
							title={t('Duplicate')}
							onClick={() => onAction(TestSequencerRulesActionTypes.CREATE_FROM, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
			</DataTable>
			<div className={styles.title}>{isGlobal ? t('Rules of the current test') : t('Rules of the current stage')}</div>
			<DataTable
				value={currentStageRules}
				loading={isLoadingCurrentStageRules}
				dataKey="id"
				showGridlines
				columnResizeMode="fit"
			>
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(TestSequencerRulesActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="toggle_on"
							fontSize="1.2rem"
							title={rowData.status === RuleStatus.ENABLED ? t('Disable') : t('Enable')}
							onClick={() => onAction(TestSequencerRulesActionTypes.UPDATE_STATUS, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="duplicate"
							fontSize="1.2rem"
							title={t('Duplicate')}
							disabled
							onClick={() => onAction(TestSequencerRulesActionTypes.CREATE_FROM, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
			<Button
				variant="contained"
				color="primary"
				icon="t-icon-add"
				label={t('Create rule')}
				onClick={() => onAction(TestSequencerRulesActionTypes.CREATE)}
				className={styles.createBtn}
			/>
			<p className={styles.warningMessage}>
				{t('Please note that rules created here are for this Test only and won’t be saved in the system')}
			</p>
		</>
	);
};

export default React.memo(TestSequencerRulesTable);
