import { Modal, ModalContextProvider } from '@tactun/ui';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../../components/ConfirmationModal';
import TestActions, { TestActionLocations, testActionsModalId } from '../../TestActions';
import TestDataLogging, { testDataLoggingModalId } from '../../TestDataLogging';
import TestSequencerRules, { testSequencerRulesModalId } from '../../TestSequencerRules';
import TestMeasurements, { testsMeasurementsTableModalId } from '../../TestMeasurements';
import TestVariables, { testVariablesModalId } from '../../TestVariables';
import TestLoopsContainer, { testLoopModalId } from '../../TestLoops';
import TestConfiguration from '../components/TestConfiguration';
import { useTestAxis } from '../../Axes/axes.hooks';
import { createStage, deleteStage, deleteStageAxis, updateStage } from '../tests.api';
import {
	StageActionTypes,
	StageAxisActionTypes,
	StageConfigurationActionTypes,
	StageTypes,
	TestPrePostActionTypes
} from '../tests.enums';
import { useTest, useTestStages } from '../tests.hooks';
import { TestStageAxisConfigParams, TestStageConfigs, TestStageRequestDto } from '../tests.types';
import TestAxesConfiguration from '../../TestAxesConfiguration';
import { testAxesConfigurationModalId } from '../../TestAxesConfiguration/testAxesConfiguration.const';
import { TestAxesConfigurationFormType } from '../../TestAxesConfiguration/testAxesConfiguration.type';
import { testStageConfigResponseDtosToTestStageConfigs } from '../../TestAxesConfiguration/testAxesConfiguration.converters';
import { testResponseDtoToITestPrePostInfoConverter } from '../tests.converters';

const axisConfigConfirmModalId = 'confirmAxisConfigDelete';
const stageConfirmModalId = 'confirmStageDelete';

const TestConfigurationContainer: React.FC = () => {
	const { testId } = useParams();
	const { t } = useTranslation('test');
	const [axisIdForDelete, setAxisIdForDelete] = useState<string>();
	const [stageForDelete, setStageForDelete] = useState<TestStageConfigs>();
	const [axisConfigParams, setAxisConfigParams] = useState<TestStageAxisConfigParams>();
	const { axes } = useTestAxis(testId);
	const { testDto } = useTest(testId);
	const testPreStageId = testDto?.preStage.id;
	const testPostStageId = testDto?.postStage.id;

	const { stages, invalidateStage } = useTestStages(testId);
	const allStages = useMemo(() => {
		return stages?.length === undefined || stages?.length === 0
			? [{ id: 'temp', name: 'Stage# 1', axesConfigs: [] } as TestStageConfigs]
			: testStageConfigResponseDtosToTestStageConfigs(stages);
	}, [stages]);

	const createMutation = useMutation({
		mutationFn: (stage: TestStageRequestDto) => createStage(stage),
		onSuccess: () => invalidateStage(),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (stage: TestStageRequestDto) => updateStage(stage),
		onSuccess: () => invalidateStage(),
		onError: (e: Error) => toast.error(e.message)
	});

	const handleConfigureTestPrePostAction = useCallback(
		(actionType: TestPrePostActionTypes) => {
			switch (actionType) {
				case TestPrePostActionTypes.CONFIGURE_TEST_MEASUREMENTS:
					Modal.show(testsMeasurementsTableModalId);
					break;
				case TestPrePostActionTypes.CONFIGURE_TEST_VARIABLES:
					Modal.show(testVariablesModalId);
					break;
				case TestPrePostActionTypes.CONFIGURE_TEST_LOOPS:
					Modal.show(testLoopModalId);
					break;
				case TestPrePostActionTypes.CONFIGURE_TEST_PRE_ACTIONS:
					Modal.show(testActionsModalId, {
						location: TestActionLocations.PRE_TEST,
						stageType: StageTypes.MAIN,
						testId,
						stageId: testPreStageId
					});
					break;
				case TestPrePostActionTypes.CONFIGURE_TEST_POST_ACTIONS:
					Modal.show(testActionsModalId, {
						location: TestActionLocations.POST_TEST,
						stageType: StageTypes.MAIN,
						testId,
						stageId: testPostStageId
					});
					break;
				case TestPrePostActionTypes.CONFIGURE_TEST_PRE_LOGGING:
					Modal.show(testDataLoggingModalId, { stageType: StageTypes.MAIN, testId, stageId: testPreStageId });
					break;
				case TestPrePostActionTypes.CONFIGURE_TEST_POST_LOGGING:
					Modal.show(testDataLoggingModalId, { stageType: StageTypes.MAIN, testId, stageId: testPostStageId });
					break;
			}
		},
		[testId, testPostStageId, testPreStageId]
	);

	const handleConfigureStageAction = useCallback(
		(actionType: StageConfigurationActionTypes, stageType: StageTypes, stageId?: string) => {
			switch (actionType) {
				case StageConfigurationActionTypes.CONFIGURE_LOGGING:
					Modal.show(testDataLoggingModalId, { stageType, stageId });
					break;
				case StageConfigurationActionTypes.CONFIGURE_RULES:
					Modal.show(testSequencerRulesModalId, { stageType, stageId });
					break;
				case StageConfigurationActionTypes.CONFIGURE_ACTION:
					Modal.show(testActionsModalId, {
						location:
							stageType === StageTypes.PRE
								? TestActionLocations.PRE_STAGE
								: stageType === StageTypes.POST
								? TestActionLocations.POST_STAGE
								: TestActionLocations.STAGE,
						stageType,
						stageId
					});
					break;
			}
		},
		[]
	);

	const handleStageAction = useCallback(
		async (actionType: StageActionTypes, data?: TestStageConfigs) => {
			switch (actionType) {
				case StageActionTypes.CREATE_STAGE:
					await createMutation.mutateAsync({
						testId
					} as TestStageRequestDto);

					break;
				case StageActionTypes.EDIT_STAGE:
					if (data) {
						await updateMutation.mutateAsync({ name: data.name, testId } as TestStageRequestDto);
					}
					break;
				case StageActionTypes.DELETE_STAGE:
					if (data) {
						setStageForDelete(data);
						ConfirmationModal.show(stageConfirmModalId);
					}
					break;
				case StageActionTypes.DUPLICATE_STAGE:
					alert('Duplicate functional is not implemented yet.');
					break;
			}
		},
		[createMutation, testId, updateMutation]
	);

	const handleStageAxisAction = useCallback(
		async (actionType: StageAxisActionTypes, stageId: string, axis?: TestAxesConfigurationFormType) => {
			switch (actionType) {
				case StageAxisActionTypes.ADD_AXIS:
					if (axis?.axisId) {
						setAxisConfigParams({ axisId: axis?.axisId, stageId });
						Modal.show(testAxesConfigurationModalId);
					}
					break;
				case StageAxisActionTypes.EDIT_AXIS:
					if (axis?.axisId) {
						setAxisConfigParams({ axisId: axis?.axisId, stageId, axisConfig: axis });
						Modal.show(testAxesConfigurationModalId);
					}
					break;
				case StageAxisActionTypes.DELETE_AXIS:
					if (axis) {
						setAxisIdForDelete(axis.id);
						ConfirmationModal.show(axisConfigConfirmModalId);
					}

					break;
			}
		},
		[]
	);

	const handleAxisConfigDelete = useCallback(async () => {
		if (axisIdForDelete) {
			await deleteStageAxis(axisIdForDelete);
			invalidateStage();
			setAxisIdForDelete(undefined);
		}
		ConfirmationModal.hide(axisConfigConfirmModalId);
	}, [axisIdForDelete, invalidateStage]);

	const handleStageDelete = useCallback(async () => {
		if (stageForDelete) {
			await deleteStage(stageForDelete.id);
			invalidateStage();
		}
		ConfirmationModal.hide(stageConfirmModalId);
	}, [invalidateStage, stageForDelete]);

	useEffect(() => {
		if (stages?.length === 0) {
			createMutation.mutateAsync({ name: 'Stage 1', testId } as TestStageRequestDto);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stages?.length, testId]);

	const testPrePostInfo = useMemo(() => {
		if (!testDto) return null;
		return testResponseDtoToITestPrePostInfoConverter(testDto);
	}, [testDto]);

	return (
		<>
			{testId && (
				<>
					<TestConfiguration
						axes={axes}
						stages={allStages}
						test={testDto}
						testPrePostInfo={testPrePostInfo}
						onConfigureTestPrePostAction={handleConfigureTestPrePostAction}
						onConfigureStageAction={handleConfigureStageAction}
						onStageAction={handleStageAction}
						onStageAxisAction={handleStageAxisAction}
					/>
					<ModalContextProvider>
						<TestAxesConfiguration
							axisId={axisConfigParams?.axisId}
							stageId={axisConfigParams?.stageId}
							testId={testId}
							axisConfig={axisConfigParams?.axisConfig}
						/>
						<TestDataLogging testId={testId} />
						<TestActions testId={testId} />
						<TestSequencerRules testId={testId} />
					</ModalContextProvider>
					<TestVariables testId={testId} />
					<TestMeasurements testId={testId} />
					<TestLoopsContainer testId={testId} />
				</>
			)}
			<ConfirmationModal title={t('Warning')} id={axisConfigConfirmModalId} onConfirm={handleAxisConfigDelete}>
				{t('Are you sure you want to delete this item?')}
			</ConfirmationModal>
			<ConfirmationModal title={t('Warning')} id={stageConfirmModalId} onConfirm={handleStageDelete}>
				{t('You are about to delete {{stageName}} stage. This action is irreversible. Are you sure?', {
					stageName: stageForDelete?.name
				})}
			</ConfirmationModal>
		</>
	);
};

export default React.memo(TestConfigurationContainer);
