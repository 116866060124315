import { Converter } from '../../types';
import { CalibrationStatus, SensorsTypes } from './sensors.enums';
import {
	SensorAverageFilter,
	SensorFormType,
	SensorListItem,
	SensorRequestDto,
	SensorResponseDto
} from './sensors.types';

export const responseListItemConverter: Converter<SensorResponseDto, SensorListItem> = (response) => {
	return {
		id: response.id,
		name: response.name,
		type: SensorsTypes[response.type],
		stationChannelName: response.stationChannel?.name,
		calibrationStatus: response.calibrations?.length ? CalibrationStatus.CALIBRATED : CalibrationStatus.UNCALIBRATED
	};
};

export const responseFormConverter: Converter<SensorResponseDto, SensorFormType> = (response) => {
	const {
		stationChannel,
		showRawData,
		inversePolarity,
		averagingFilter,
		averagingFilterPoints,
		manufacturer,
		calibrations,
		...formResponse
	} = response;

	const form: SensorFormType = {
		...formResponse,
		unitId: formResponse.unit?.id || '',
		stationChannelId: stationChannel?.id || '',
		isInversePolarity: inversePolarity,
		isShowRawData: showRawData,
		isAveragingFilter: averagingFilter,
		averagingFilterPoint: averagingFilterPoints,
		manufacturer: manufacturer || ''
	};

	return form;
};

const getAverageFilterPoints = ({ isAveragingFilter, averagingFilterPoint }: SensorFormType): SensorAverageFilter => {
	if (isAveragingFilter) {
		return {
			averagingFilter: isAveragingFilter,
			averagingFilterPoints: averagingFilterPoint
		};
	} else {
		return {
			averagingFilter: isAveragingFilter === null ? undefined : isAveragingFilter
		};
	}
};

export const formRequestConverter = (form: SensorFormType): SensorRequestDto => {
	const { isAveragingFilter, isInversePolarity, isShowRawData, averagingFilterPoint, ...commonForm } = form;

	const request: SensorRequestDto = {
		...commonForm,
		manufacturer: commonForm.manufacturer || undefined,
		serialNumber: commonForm.serialNumber || undefined,
		showRawData: isShowRawData === null ? undefined : isShowRawData
	};

	switch (commonForm.type) {
		case SensorsTypes.DIFFERENTIAL_ENCODER:
		case SensorsTypes.SINGLE_ENDED_ENCODER:
			return {
				...request,
				inversePolarity: isInversePolarity === null ? undefined : isInversePolarity
			};
		case SensorsTypes.GENERIC_SENSOR:
			return {
				...request,
				...getAverageFilterPoints(form)
			};
		case SensorsTypes.LVDT:
			return {
				...request,
				inversePolarity: isInversePolarity === null ? undefined : isInversePolarity,
				...getAverageFilterPoints(form)
			};
		case SensorsTypes.LOAD_CELL:
			return {
				...request,
				inversePolarity: isInversePolarity === null ? undefined : isInversePolarity,
				...getAverageFilterPoints(form)
			};
	}
};
