import { Dropdown, GroupInputFrame, InputNumber, InputText } from '@tactun/ui';
import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNumberEnumList, useEntitiesAsList } from '../../../hooks';
import { ControlChannelResponseDto } from '../../ControlChannels';
import { useDefaultTimeUnitName } from '../../Units/units.hooks';
import { VariableResponseDto } from '../../Variables';
import { WaveformValueType } from '../waveforms.enums';
import { createControllerName, useControllerName } from '../waveforms.hooks';
import { getFieldByFormPrefix } from '../waveforms.tools';
import { WaveformConstantFormErrors } from '../waveforms.types';

export interface ConstantWaveformFormViewProps {
	formPrefix?: string;
	variables: VariableResponseDto[];
	controlChannel: ControlChannelResponseDto;
	errors?: WaveformConstantFormErrors;
	isRunning?: boolean;
	isControlStage?: boolean;
}

const ConstantWaveformFormView: FC<ConstantWaveformFormViewProps> = ({
	variables,
	formPrefix,
	errors,
	controlChannel,
	isRunning,
	isControlStage = false
}) => {
	const { t } = useTranslation('waveforms');
	const {
		control,
		watch,
		setValue,
		formState: { dirtyFields }
	} = useFormContext();
	const typeOptions = useNumberEnumList(WaveformValueType, 'waveforms');
	const variablesOptions = useEntitiesAsList(variables);
	const defaultTimeUnitName = useDefaultTimeUnitName();

	const valueTypeCN = useControllerName('valueType', formPrefix);
	const valueType = watch(valueTypeCN);

	const durationTypeCN = useControllerName('durationType', formPrefix);
	const durationType = watch(durationTypeCN);

	const durTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.durationType;
	useEffect(() => {
		if (durTypeDirtyField) {
			setValue(createControllerName('durationValue', formPrefix), null);
			setValue(createControllerName('durationVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, durTypeDirtyField]);

	const valTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.valueType;
	useEffect(() => {
		if (valTypeDirtyField) {
			setValue(createControllerName('value', formPrefix), null);
			setValue(createControllerName('variableId', formPrefix), null);
		}
	}, [formPrefix, setValue, valTypeDirtyField]);

	const feedbackOrAxisUnit = controlChannel.feedbackMeasurement?.unit.name
		? controlChannel.feedbackMeasurement?.unit.name
		: controlChannel.axis.actuator.calibrationResponseDto?.unit?.name;

	return (
		<>
			<div />
			<Controller
				name={valueTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Value*')}
						options={typeOptions}
						data-testid="valueTypeCN"
						error={errors?.valueType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{valueType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('variableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								label={t('Value variable*')}
								{...field}
								options={variablesOptions}
								error={errors?.variableId?.message}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('value', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber {...field} label={t('Constant value*')} error={errors?.value?.message} />
						)}
					/>
				)}
				<InputText disabled value={feedbackOrAxisUnit} />
			</GroupInputFrame>
			<Controller
				name={durationTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Duration*')}
						options={typeOptions}
						data-testid="durationTypeCN"
						error={errors?.durationType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{durationType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('durationVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								label={t('Duration variable*')}
								options={variablesOptions}
								error={errors?.durationVariableId?.message}
								{...field}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('durationValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber {...field} label={t('Duration constant value*')} error={errors?.durationValue?.message} />
						)}
					/>
				)}
				<InputText disabled value={defaultTimeUnitName} />
			</GroupInputFrame>
		</>
	);
};

export default ConstantWaveformFormView;
