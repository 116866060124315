import { useEffect, useState } from 'react';
import { IUserData } from '../keycloakAxios';
import useKeycloakContext from './useKeycloakContext';

const useUser = () => {
	const [user, setUser] = useState<IUserData | null>(null);
	const context = useKeycloakContext();

	useEffect(() => {
		if (context && context.keycloak.authenticated) {
			setUser(context.getUserData());
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context?.keycloak.authenticated]);
	return user;
};

export default useUser;
