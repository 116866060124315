import { Dropdown, GroupInputFrame, InputNumber, InputText } from '@tactun/ui';
import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNumberEnumList, useEntitiesAsList } from '../../../hooks';
import { useDefaultTimeUnitName } from '../../Units/units.hooks';
import { VariableResponseDto } from '../../Variables';
import { WaveformValueType } from '../waveforms.enums';
import { createControllerName, useControllerName } from '../waveforms.hooks';
import { getFieldByFormPrefix } from '../waveforms.tools';
import { WaveformDwellFormErrors } from '../waveforms.types';

export interface DwellWaveformFormViewProps {
	formPrefix?: string;
	variables: VariableResponseDto[];
	errors?: WaveformDwellFormErrors;
	isRunning?: boolean;
	isControlStage?: boolean;
}

const DwellWaveformFormView: FC<DwellWaveformFormViewProps> = ({
	variables,
	formPrefix,
	errors,
	isRunning,
	isControlStage = false
}) => {
	const { t } = useTranslation('waveforms');
	const {
		control,
		watch,
		setValue,
		formState: { dirtyFields }
	} = useFormContext();
	const durationTypeOptions = useNumberEnumList(WaveformValueType, 'waveforms');
	const defaultTimeUnitName = useDefaultTimeUnitName();
	const variablesOptions = useEntitiesAsList(variables);

	// CN - means Controller Name
	const durationTypeCN = useControllerName('durationType', formPrefix);
	const durationType = watch(durationTypeCN);

	const typeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.durationType;
	useEffect(() => {
		if (typeDirtyField) {
			setValue(createControllerName('durationValue', formPrefix), null);
			setValue(createControllerName('durationVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, typeDirtyField]);

	return (
		<>
			<div />
			<Controller
				name={durationTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Duration*')}
						options={durationTypeOptions}
						data-testid="durationTypeCN"
						error={errors?.durationType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{durationType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('durationVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								label={t('Duration variable*')}
								options={variablesOptions}
								error={errors?.durationVariableId?.message}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('durationValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber {...field} label={t('Duration constant value*')} error={errors?.durationValue?.message} />
						)}
					/>
				)}
				<InputText disabled value={defaultTimeUnitName} />
			</GroupInputFrame>
		</>
	);
};

export default DwellWaveformFormView;
