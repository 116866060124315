import { IconButton, StatusLabel, StatusLabelTypes } from '@tactun/ui';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useConnectionStore } from '../../Connection/connection.store';
import { ConfirmationModal } from '../../../components';
import {
	notConfiguredConfirmationModalId,
	notConnectedConfirmationModalId,
	outdatedConfiguredConfirmationModalId
} from '../dashboardButton.consts';
import { controllerSelectionModalId } from '../../Connection/connection.const';
import { useConfigureDevice } from '../../Stations';
import styles from './DashboardButton.module.scss';
import { useConnectedDeviceStatus } from '../dashboardButton.hooks';
import { StationStatuses } from '../dashboardButton.enums';

const DashboardButtonContainer = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { connectedDevice } = useConnectionStore();
	const { status, stationId } = useConnectedDeviceStatus();

	const handleGoDashboard = useCallback(() => {
		if (!connectedDevice) {
			ConfirmationModal.show(notConnectedConfirmationModalId);
		} else if (
			connectedDevice &&
			(status === StationStatuses.UNCONFIGURED || status === StationStatuses.UNKNOWN_STATION)
		) {
			ConfirmationModal.show(notConfiguredConfirmationModalId);
		} else if (status === StationStatuses.OUTDATED) {
			ConfirmationModal.show(outdatedConfiguredConfirmationModalId);
		} else if (connectedDevice && status === StationStatuses.CONFIGURED) {
			navigate(`/stations/${stationId}/dashboard`);
		}
	}, [connectedDevice, navigate, stationId, status]);

	const handleConfigurationResult = useCallback(
		(data: { isConfigured: boolean; stationId: string }) => {
			if (data.isConfigured) {
				navigate(`/stations/${data.stationId}/dashboard`);
			}
			ConfirmationModal.hide(outdatedConfiguredConfirmationModalId);
		},
		[navigate]
	);

	const { configure, isLoading } = useConfigureDevice(handleConfigurationResult);

	const handleConfigureAndOpenDashboard = useCallback(() => {
		if (stationId) {
			configure(stationId);
		}
	}, [configure, stationId]);

	const statusLabelState = useMemo(() => {
		/* switch (state) {
			case StationState.ACTIVE:
				return {
					color: StatusLabelTypes.PRIMARY,
					text: t('IN PROGRESS'),
					icon: 'attention',
					describe: t(
						'There is active movement on one of the axes. While the station is in Active state test cannot be initiated.'
					)
				};
			case StationState.IN_TEST:
				return {
					color: StatusLabelTypes.WARNING,
					text: t('IN PROGRESS'),
					icon: 'attention',
					describe: t('Station is in Test mode. Most of the actions not related to the test control are not allowed')
				};
			case StationState.SAFE:
				return {
					color: StatusLabelTypes.YELLOW,
					text: t('SAFE MODE'),
					icon: 'warning-triangle',
					describe: t('While the station is in Safe mode the test cannot be initiated.')
				};
		}
 */
		switch (status) {
			case StationStatuses.UNCONFIGURED:
				return {
					color: StatusLabelTypes.GRAY,
					text: t('UNCONFIGURED'),
					icon: 'attention',
					describe: t('Station is not configured.')
				};
			case StationStatuses.CONFIGURED:
				return {
					color: StatusLabelTypes.SUCCESS,
					text: t('CONFIGURED'),
					icon: 'success-white',
					describe: t('Station is configured.')
				};
			case StationStatuses.OUTDATED:
				return {
					color: StatusLabelTypes.YELLOW,
					text: t('OUTDATED'),
					icon: 'warning-triangle',
					describe: t('Station is configured, but configuration is outdated.')
				};
			case StationStatuses.UNKNOWN_STATION:
				return {
					color: StatusLabelTypes.WARNING,
					text: t('UNKNOWN STATION'),
					icon: 'warning-triangle',
					describe: t('Configured Station is unknown by the system.')
				};
			default:
				return null;
		}
	}, [status, t]);

	return (
		<div className={styles.container}>
			{connectedDevice?.ip && (
				<IconButton
					className={styles.btn}
					fontSize="1.8rem"
					title={t('Open dashboard')}
					icon="dashboard"
					data-testid="goDashboardBtn"
					onClick={handleGoDashboard}
				/>
			)}
			{connectedDevice?.id && statusLabelState && (
				<StatusLabel type={statusLabelState.color} icon={statusLabelState.icon} tooltip={statusLabelState.describe}>
					{t(statusLabelState.text)}
				</StatusLabel>
			)}
			<ConfirmationModal
				title={t('Warning')}
				confirmBtnText={t('Ok')}
				id={notConnectedConfirmationModalId}
				onConfirm={() => {
					ConfirmationModal.hide(notConnectedConfirmationModalId);
					ConfirmationModal.show(controllerSelectionModalId);
				}}
			>
				{t('Controller not detected. Please connect a controller')}
			</ConfirmationModal>
			<ConfirmationModal
				title={t('Warning')}
				confirmBtnText={t('Ok')}
				id={notConfiguredConfirmationModalId}
				onConfirm={() => ConfirmationModal.hide(notConfiguredConfirmationModalId)}
			>
				{t('The connected Controller is not configured, please go to the Stations section to configure it')}
			</ConfirmationModal>
			<ConfirmationModal
				title={t('Warning')}
				confirmBtnText={t('Ok')}
				id={outdatedConfiguredConfirmationModalId}
				onConfirm={handleConfigureAndOpenDashboard}
				isLoading={isLoading}
			>
				{t('Your current configuration is outdated and will be automatically updated')}
			</ConfirmationModal>
		</div>
	);
};

export default React.memo(DashboardButtonContainer);
