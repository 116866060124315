import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '@tactun/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { deleteEmptyFields } from '../../../tools';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import TestLoopCreateEditModal from '../components/TestLoopCreateEditModal';
import TestLoopForm from '../components/TestLoopForm';
import TestLoopsModal from '../components/TestLoopsModal';
import { TestLoopListItem, TestLoopRequestDto, TestLoopFormType } from '../testLoops.types';
import { TestLoopRunForCalculationDto, TestLoopsActionTypes } from '../testLoops.enums';
import { useTestLoop, useTestLoops } from '../testLoops.hooks';
import {
	testLoopFormRequestConverter,
	testLoopResponseFormConverter,
	testLoopResponseListItemConverter
} from '../testLoops.converters';
import { testLoopSchema } from '../testLoops.schemas';
import { createEditTestLoopModalId } from '../testLoops.const';

interface ITestLoopsContainerProps {
	testId: string;
}

const TestLoopsContainer: React.FC<ITestLoopsContainerProps> = ({ testId }) => {
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const [entityIdForEdit, setEntityIdForEdit] = useState<string | undefined>();

	const form = useForm<TestLoopFormType>({
		mode: 'onBlur',
		resolver: yupResolver(testLoopSchema),
		defaultValues: { testId, iter: 2, runCalcFor: TestLoopRunForCalculationDto.ALL_ITERATIONS }
	});
	const { reset } = form;
	const handleClose = useCallback(() => {
		setEntityIdForEdit(undefined);
		reset({
			id: '',
			testId,
			startStageId: '',
			endStageId: '',
			iter: 2,
			runCalcFor: TestLoopRunForCalculationDto.ALL_ITERATIONS
		});
		Modal.hide(createEditTestLoopModalId);
	}, [reset, testId]);
	//  handle TestLoopsCreateEditModal save
	const handleSuccessSave = useCallback(() => {
		handleClose();
	}, [handleClose]);
	const { loops, isLoading, deleteTestLoop, isCreating, isUpdating, saveTestLoop } = useTestLoops(
		testId,
		handleSuccessSave
	);
	const { testLoop } = useTestLoop(entityIdForEdit);
	const loopsList: TestLoopListItem[] | undefined = useMemo(() => {
		if (loops) {
			return loops.map((item) => testLoopResponseListItemConverter(item));
		}
	}, [loops]);
	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			deleteTestLoop(id);
		},
		[deleteTestLoop]
	);

	const handleSave = useCallback(() => {
		form.handleSubmit((data) => {
			const filteredData = deleteEmptyFields<TestLoopRequestDto>(testLoopFormRequestConverter(data));
			if (data?.id) {
				saveTestLoop(filteredData, data?.id);
			} else {
				saveTestLoop(filteredData);
			}
		})();
	}, [form, saveTestLoop]);

	const handleAction = useCallback((type: TestLoopsActionTypes, data?: TestLoopListItem, index?: number) => {
		switch (type) {
			case TestLoopsActionTypes.CREATE:
				Modal.show(createEditTestLoopModalId);
				break;
			case TestLoopsActionTypes.EDIT:
				setEntityIdForEdit(data?.id);
				break;
			case TestLoopsActionTypes.DELETE:
				if (data && typeof index === 'number') {
					setEntityForDelete({ name: `Loop ${index + 1}`, id: data.id });
				}
				break;
		}
	}, []);

	// if loop available for edit open edit modal
	useEffect(() => {
		if (testLoop) {
			reset(testLoopResponseFormConverter(testLoop), { keepDirty: true, keepTouched: true });
			Modal.show(createEditTestLoopModalId);
		}
	}, [reset, testId, testLoop]);

	return (
		<>
			<TestLoopsModal isLoading={isLoading} testId={testId as string} onAction={handleAction} data={loopsList} />
			<TestLoopCreateEditModal
				isCreate={!entityIdForEdit}
				isLoading={isCreating || isUpdating}
				onSave={handleSave}
				onClose={handleClose}
			>
				<TestLoopForm form={form} testId={testId} isCreate={!entityIdForEdit} />
			</TestLoopCreateEditModal>
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.TEST_LOOP}
				entity={entityForDelete}
			/>
		</>
	);
};

export default TestLoopsContainer;
