import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import InfoIcon from './InfoIcon';
import styles from './StageInfoBar.module.scss';
import { TestActionLocations } from '../../../TestActions';

interface StageInfoBarProps {
	className?: string;
	location?: TestActionLocations;
	variant?: 'horizontal' | 'vertical';
	onAddLog?: () => void;
	onAddRule?: () => void;
	onAddAction?: () => void;
	actionsCount?: number;
	rulesCount?: number;
	logsCount?: number;
}

const StageInfoBar: React.FC<StageInfoBarProps> = ({
	className,
	location,
	variant = 'vertical',
	onAddLog,
	onAddRule,
	onAddAction,
	logsCount = 0,
	actionsCount = 0,
	rulesCount = 0
}) => {
	const { t } = useTranslation('test');

	return (
		<div
			className={cx(
				styles.stageInfoBar,
				{
					[styles.horizontal]: variant === 'horizontal',
					[styles.vertical]: variant === 'vertical'
				},
				className
			)}
		>
			{location === TestActionLocations.PRE_TEST && <div className={styles.stageLabel}>Pre</div>}
			{location === TestActionLocations.POST_TEST && <div className={styles.stageLabel}>Post</div>}
			{(location === TestActionLocations.PRE_TEST || location === TestActionLocations.POST_TEST) && (
				<InfoIcon
					icon=""
					data-testid="iconCalculation"
					tooltip={t('{{count}} calculations', { count: actionsCount })}
					onClick={onAddAction}
					className={cx({ [styles.iconCalcAction]: actionsCount, [styles.iconCalcActionEmpty]: !actionsCount })}
				/>
			)}
			{(location === TestActionLocations.PRE_STAGE || location === TestActionLocations.POST_STAGE) && (
				<InfoIcon
					icon="action"
					data-testid="iconAction"
					tooltip={t('{{count}} action', { count: actionsCount })}
					onClick={onAddAction}
					className={cx({ [styles.noItems]: !actionsCount })}
				/>
			)}
			{location === TestActionLocations.STAGE && (
				<InfoIcon
					icon=""
					data-testid="iconCalculationCount"
					tooltip={t('{{count}} calculations', { count: actionsCount })}
					onClick={onAddAction}
					className={cx({ [styles.iconCalc]: actionsCount, [styles.iconCalcEmpty]: !actionsCount })}
				/>
			)}
			{onAddRule && (
				<InfoIcon
					icon="rule"
					data-testid="iconRule"
					tooltip={t('{{count}} rules', { count: rulesCount })}
					onClick={onAddRule}
					className={cx({ [styles.noItems]: !rulesCount })}
				/>
			)}
			{onAddLog && (
				<InfoIcon
					icon="data-logging"
					data-testid="iconDataLogging"
					tooltip={t('{{count}} logs', { count: logsCount })}
					onClick={onAddLog}
					className={cx({ [styles.noItems]: !logsCount })}
				/>
			)}
		</div>
	);
};

export default React.memo(StageInfoBar);
