import { Button, Form, InputText, Modal } from '@tactun/ui';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import styles from './CreateModal.module.scss';
import * as yup from 'yup';

export interface GroupCreateEditModalProps {
	modalId: string;
	label?: string;
	onSave: (value: string) => void;
}

const createEditGroupFormId = 'createEditGroupForm';

const schema = yup
	.object({
		name: yup.string().required().max(30)
	})
	.required();

const GroupCreateEditModal = ({ modalId, label, onSave }: GroupCreateEditModalProps) => {
	const { t } = useTranslation('common');
	const {
		reset,
		register,
		formState: { errors },
		handleSubmit
	} = useForm({
		defaultValues: {
			name: ''
		},
		mode: 'onBlur',
		resolver: yupResolver(schema)
	});

	const handleCloseModal = useCallback(() => {
		reset({ name: '' });
		Modal.hide(modalId);
	}, [modalId, reset]);

	const onSubmit = handleSubmit((data) => onSave(data.name.trim()));

	return (
		<Modal id={modalId} onClose={handleCloseModal}>
			<Modal.Header>
				{t('Create')} {label || ''}
			</Modal.Header>
			<Modal.Content>
				<Form
					className={styles.formContainer}
					id={createEditGroupFormId}
					onSubmit={(e) => {
						e.stopPropagation();
						onSubmit(e);
					}}
				>
					<InputText
						className={styles.inputContainer}
						label={(label ? `${label} ` : '') + t('Name')}
						error={errors.name?.message}
						data-testid="createItemInput"
						{...register('name')}
					/>
				</Form>
			</Modal.Content>
			<Modal.Footer>
				<Button data-testid="createItemCancel" onClick={handleCloseModal} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button
					data-testid="createItemSave"
					type="submit"
					form={createEditGroupFormId}
					color="secondary"
					variant="contained"
				>
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default GroupCreateEditModal;
