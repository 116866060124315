import React from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { controllerAddManuallyModalId, controllerAddManuallyFormId } from '../../connection.const';
import styles from './ControllerAddManuallyModal.module.scss';

interface IControllerAddManuallyModalProps {
	children: React.ReactNode;
}

const ControllerAddManuallyModal: React.FC<IControllerAddManuallyModalProps> = ({ children }) => {
	const { t } = useTranslation('connection');

	return (
		<Modal id={controllerAddManuallyModalId} className={styles.modalContainer} shouldCloseOnOverlayClick={false}>
			<Modal.Header>{t('Add Controller Manually')}</Modal.Header>
			<Modal.Content>{children}</Modal.Content>
			<Modal.Footer>
				<Button
					className="btnCancel"
					onClick={() => Modal.hide(controllerAddManuallyModalId)}
					color="success"
					variant="text"
				>
					{t('Cancel')}
				</Button>
				<Button color="secondary" variant="contained" form={controllerAddManuallyFormId} type="submit">
					{t('Add')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(ControllerAddManuallyModal);
