import React, { FC, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { MeasurementResponseDto } from '../measurements.types';
import * as api from '../measurements.api';
import MeasurementsTable from '../components/MeasurementsTable';
import { MeasurementCrudActionTypes } from '../measurements.enums';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { useStationName } from '../../Stations/stations.hooks';

const MeasurementsTableContainer: FC = () => {
	const navigate = useNavigate();
	const { stationId } = useParams();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const queryClient = useQueryClient();

	const { stationName } = useStationName(stationId);

	const handleAction = useCallback(
		(type: MeasurementCrudActionTypes, data?: MeasurementResponseDto) => {
			switch (type) {
				case MeasurementCrudActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case MeasurementCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case MeasurementCrudActionTypes.DELETE:
					setEntityForDelete(data as IEntity);
					break;
				case MeasurementCrudActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
			}
		},
		[navigate]
	);

	const { data, isLoading } = useQuery<MeasurementResponseDto[], Error>({
		queryKey: ['measurements', stationId],
		queryFn: () => {
			if (stationId) {
				return api.getMeasurementsForStation(stationId);
			} else {
				throw new Error('Get measurements: need station id!');
			}
		}
	});

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handlePostDelete = useCallback(
		(id: string) => {
			queryClient.setQueryData<MeasurementResponseDto[]>(
				['measurements', stationId],
				(old) => old?.filter((st) => st.id !== id)
			);
		},
		[queryClient, stationId]
	);

	return (
		<>
			<MeasurementsTable onAction={handleAction} data={data} isLoading={isLoading} stationName={stationName} />
			<EntityDeleter
				onDeleted={handlePostDelete}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.MEASUREMENT}
				entity={entityForDelete}
				checkUsage
			/>
		</>
	);
};

export default MeasurementsTableContainer;
