import { FC } from 'react';
import { InputNumber } from '@tactun/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AsymmetricPIDAlgFormErrors } from '../../controlAlgorithms.types';
import styles from './AsymmetricPidAlgForm.module.scss';

interface AsymmetricPidAlgFormProps {
	formPrefix: string;
	errors?: AsymmetricPIDAlgFormErrors;
	disabled?: boolean;
}

const AsymmetricPidAlgForm: FC<AsymmetricPidAlgFormProps> = ({ formPrefix, errors, disabled = false }) => {
	const { t } = useTranslation('controlAlgorithm');

	const { control } = useFormContext();

	return (
		<>
			<div className={styles.formLabel}>{t('Asymmetric PID')}</div>
			<Controller
				name={`${formPrefix}.asymmetricPid.pGainForward`}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Proportional gain for forward control*')}
						error={errors?.pGainForward?.message as string}
						data-testid="pGainForward"
						min={0}
						disabled={disabled}
					/>
				)}
			/>
			<Controller
				name={`${formPrefix}.asymmetricPid.pGainReverse`}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Proportional gain for reverse control*')}
						error={errors?.pGainReverse?.message as string}
						data-testid="pGainReverse"
						min={0}
						disabled={disabled}
					/>
				)}
			/>
			<Controller
				name={`${formPrefix}.asymmetricPid.integralGain`}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Integral gain (Ki)*')}
						error={errors?.integralGain?.message as string}
						data-testid="integralGain"
						min={0}
						disabled={disabled}
					/>
				)}
			/>
			<Controller
				name={`${formPrefix}.asymmetricPid.derivativeGain`}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Derivative gain (Kd)*')}
						error={errors?.derivativeGain?.message as string}
						data-testid="derivativeGain"
						min={0}
						disabled={disabled}
					/>
				)}
			/>
			<div></div>
		</>
	);
};

export default AsymmetricPidAlgForm;
