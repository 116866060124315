import { FC, useEffect } from 'react';
import { Dropdown, GroupInputFrame, InputNumber, InputText } from '@tactun/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEntitiesAsList, useNumberEnumList } from '../../../hooks';
import { ControlChannelResponseDto } from '../../ControlChannels';
import { useDefaultTimeUnitName } from '../../Units/units.hooks';
import { VariableResponseDto } from '../../Variables';
import { WaveformDefinitionMode, WaveformDirection, WaveformValueType } from '../waveforms.enums';
import { createControllerName, useControllerName } from '../waveforms.hooks';
import { WaveformHaversineFormErrors } from '../waveforms.types';
import { getFieldByFormPrefix } from '../waveforms.tools';

export interface HaversineFormViewProps {
	variables: VariableResponseDto[];
	controlChannel: ControlChannelResponseDto;
	formPrefix?: string;
	errors?: WaveformHaversineFormErrors;
	isRunning?: boolean;
	isControlStage?: boolean;
}

const HaversineFormView: FC<HaversineFormViewProps> = ({
	variables,
	controlChannel,
	formPrefix,
	errors,
	isRunning,
	isControlStage = false
}) => {
	const { t } = useTranslation('waveforms');
	const {
		control,
		watch,
		formState: { dirtyFields },
		setValue
	} = useFormContext();
	const defaultTimeUnitName = useDefaultTimeUnitName();

	const difinitionModeOptions = useNumberEnumList(WaveformDefinitionMode);
	const directionOptions = useNumberEnumList(WaveformDirection);
	const typeOptions = useNumberEnumList(WaveformValueType, 'waveforms');
	const variablesOptions = useEntitiesAsList(variables);

	const durationTypeCN = useControllerName('durationType', formPrefix);
	const amplitudeTypeCN = useControllerName('amplitudeType', formPrefix);
	const meanTypeCN = useControllerName('meanType', formPrefix);
	const durationType = watch(durationTypeCN);
	const amplitudeType = watch(amplitudeTypeCN);
	const meanType = watch(meanTypeCN);

	const feedbackOrAxisUnit = controlChannel.feedbackMeasurement?.unit.name
		? controlChannel.feedbackMeasurement?.unit.name
		: controlChannel.axis.actuator.calibrationResponseDto?.unit?.name;

	const durationTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.durationType;
	useEffect(() => {
		if (durationTypeDirtyField) {
			setValue(createControllerName('durationValue', formPrefix), null);
			setValue(createControllerName('durationVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, durationTypeDirtyField]);

	const amplitudeTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.amplitudeType;
	useEffect(() => {
		if (amplitudeTypeDirtyField) {
			setValue(createControllerName('amplitudeValue', formPrefix), null);
			setValue(createControllerName('amplitudeVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, amplitudeTypeDirtyField]);

	const meanTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.meanType;
	useEffect(() => {
		if (meanTypeDirtyField) {
			setValue(createControllerName('meanValue', formPrefix), null);
			setValue(createControllerName('meanVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, meanTypeDirtyField]);

	return (
		<>
			<div />
			<Controller
				name={createControllerName('defMode', formPrefix)}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Definition mode*')}
						options={difinitionModeOptions}
						error={errors?.defMode?.message}
						data-testid="defMode"
						{...field}
						disabled={isRunning}
					/>
				)}
			/>
			<Controller
				name={createControllerName('direction', formPrefix)}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Direction*')}
						options={directionOptions}
						error={errors?.direction?.message}
						data-testid="direction"
						{...field}
						disabled={isRunning}
					/>
				)}
			/>
			<Controller
				name={durationTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Duration*')}
						options={typeOptions}
						data-testid="durationTypeCN"
						error={errors?.durationType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{durationType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('durationVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								label={t('Duration variable*')}
								data-testid="durationVariableId"
								options={variablesOptions}
								error={errors?.durationVariableId?.message}
								{...field}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('durationValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber
								data-testid="durationValue"
								{...field}
								label={t('Duration constant value*')}
								error={errors?.durationValue?.message}
							/>
						)}
					/>
				)}
				<InputText data-testid="defaultTimeUnitName" disabled value={defaultTimeUnitName} />
			</GroupInputFrame>
			<Controller
				name={amplitudeTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Amplitude*')}
						options={typeOptions}
						data-testid="amplitudeTypeCN"
						error={errors?.amplitudeType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{amplitudeType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('amplitudeVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								data-testid="amplitudeVariableId"
								label={t('Amplitude variable*')}
								options={variablesOptions}
								error={errors?.amplitudeVariableId?.message}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('amplitudeValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber
								data-testid="amplitudeValue"
								{...field}
								label={t('Amplitude constant value*')}
								error={errors?.amplitudeValue?.message}
							/>
						)}
					/>
				)}
				<InputText data-testid="feedbackOrAxisUnit" disabled value={feedbackOrAxisUnit} />
			</GroupInputFrame>
			<Controller
				name={meanTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Mean*')}
						options={typeOptions}
						data-testid="meanTypeCN"
						error={errors?.meanType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{meanType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('meanVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								data-testid="meanVariableId"
								{...field}
								label={t('Mean variable*')}
								options={variablesOptions}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('meanValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber
								data-testid="meanValue"
								{...field}
								label={t('Mean constant value*')}
								error={errors?.meanValue?.message}
							/>
						)}
					/>
				)}
				<InputText data-testid="feedbackOrAxisUnit" disabled value={feedbackOrAxisUnit} />
			</GroupInputFrame>
		</>
	);
};

export default HaversineFormView;
