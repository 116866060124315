import axios from '../../httpCommon';
import { IDashboardTypeRequest, IDashboardTypeResponse } from './dashboardTypes.types';

export const getDashboardTypes = async (): Promise<IDashboardTypeResponse[]> => {
	const response = await axios.get('/cassiopeia/dashboard-type', {
		params: { page: 0, size: 400 }
	});

	return response.data.response.data as IDashboardTypeResponse[];
};

export const getDashboardType = async (id?: string): Promise<IDashboardTypeResponse | undefined> => {
	const response = await axios.get(`/cassiopeia/dashboard-type/${id}`);
	return response.data.response;
};

export const createDashboardType = async (dashboardType: IDashboardTypeRequest): Promise<IDashboardTypeResponse> => {
	const response = await axios.post('/cassiopeia/dashboard-type', dashboardType);
	return response.data.response;
};

export const updateDashboardType = async (dashboardType: IDashboardTypeRequest): Promise<IDashboardTypeResponse> => {
	const response = await axios.put('/cassiopeia/dashboard-type', dashboardType);
	return response.data.response;
};

export const checkName = async (name: string, id?: string): Promise<boolean> => {
	const response = await axios.post('/cassiopeia/dashboard-type/isNameUnique', { uuid: id ? id : undefined, name });
	return response.data.response.unique;
};
