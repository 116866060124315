import { Routes, Route, Navigate } from 'react-router-dom';
import SpecimensTableContainer from './containers/SpecimensTableContainer';
import SpecimensContainer from './containers/SpecimensContainer';
import { SpecimenModes } from './specimens.enums';

const SpecimensRouts: React.FC = () => {
	return (
		<Routes>
			<Route index element={<Navigate replace to={SpecimenModes.SINGLE} />} />
			<Route path=":mode" element={<SpecimensTableContainer />} />
			<Route path=":mode/create" element={<SpecimensContainer />} />
			<Route path=":mode/edit/:uuid" element={<SpecimensContainer />} />
		</Routes>
	);
};

export default SpecimensRouts;
