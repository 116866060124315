import React, { FC } from 'react';
import { PageLayout } from '@tactun/ui';
import GraphCrudContainer from '../../containers/GraphTableContainer';
import styles from './TestDashboardConfiguration.module.scss';
import ReadoutCrudContainer from '../../containers/ReadoutTableContainer';
import { useTest } from '../../../Tests/tests.hooks';

interface TestDashboardConfigurationProps {
	testId: string;
}

const TestDashboardConfiguration: FC<TestDashboardConfigurationProps> = ({ testId }) => {
	const { testDto } = useTest(testId);
	return (
		<PageLayout info={testDto?.name}>
			<PageLayout.Header title="Dashboard configuration" />
			<div className={`${styles.graphWrapper} ${styles.container}`}>
				<GraphCrudContainer />
			</div>
			<div className={`${styles.readoutWrapper} ${styles.container}`}>
				<ReadoutCrudContainer />
			</div>
		</PageLayout>
	);
};

export default React.memo(TestDashboardConfiguration);
