import React from 'react';
import styles from './Layout.module.scss';

export interface ILayoutProps {
	children: React.ReactNode;
}

export interface ILayoutSubComponents {
	Body: React.FC<ILayoutProps>;
}

const LayoutBody: React.FC<ILayoutProps> = ({ children }) => {
	return <div className={styles.body}>{children}</div>;
};

const Layout: React.FC<ILayoutProps> & ILayoutSubComponents = ({ children }) => {
	return <div className={styles.container}>{children}</div>;
};

Layout.Body = LayoutBody;

export default Layout;
