import { FC, HTMLAttributes, ReactNode } from 'react';
import cx from 'classnames';

import styles from './ModalFooter.module.scss';

export interface IModalFooterProps extends HTMLAttributes<HTMLElement> {
	children: ReactNode;
}

const ModalFooter: FC<IModalFooterProps> = ({ className, children, ...props }) => {
	return (
		<div className={cx(styles.modalFooter, className)} {...props}>
			{children}
		</div>
	);
};

ModalFooter.displayName = 'ModalFooter';

export default ModalFooter;
