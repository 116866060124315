import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNumberEnumList } from '../../hooks';
import * as api from './tests.api';
import { DataStorageTypes, StorageFullBehaviorTypes } from './tests.enums';
import { TestInformationResponseDto, TestListDto, TestStageResponseDto } from './tests.types';

export const useTests = (isDisabled: boolean = false) => {
	const { data: tests, isLoading: isTestsLoading } = useQuery({
		queryKey: ['tests'],
		queryFn: () => api.getTests(),
		enabled: !isDisabled
	});

	const queryClient = useQueryClient();

	const updateTests = () => {
		queryClient.invalidateQueries({ queryKey: ['tests'] });
	};

	const deleteTest = useCallback(
		(id: string) => {
			queryClient.setQueryData<TestListDto[]>(['tests'], (old) => {
				return old?.filter((st) => st.id !== id);
			});
		},
		[queryClient]
	);

	return {
		tests,
		isTestsLoading,
		deleteTest,
		updateTests
	};
};

export const useTest = (testId?: string) => {
	const { data: testDto, isLoading: isTestLoading } = useQuery<TestInformationResponseDto>({
		queryKey: ['test', { testId }],
		// When we provide enable option - !!testId, the testId is guaranteed
		queryFn: () => api.getTest(testId as string),
		enabled: !!testId
	});

	const queryClient = useQueryClient();

	const updateTest = () => {
		queryClient.invalidateQueries({ queryKey: ['test', { testId }] });
	};

	return {
		testDto,
		isTestLoading,
		updateTest
	};
};

export const useTestStandards = () => {
	const { data: testStandards, isLoading: isTestStandardsLoading } = useQuery<string[]>({
		queryKey: ['testStandards'],
		queryFn: () => api.getTestStandards()
	});

	return {
		testStandards: testStandards || [],
		isTestStandardsLoading
	};
};

export const useTestStages = (testId?: string) => {
	const { data: stages, isLoading: isStagesLoading } = useQuery<TestStageResponseDto[]>({
		queryKey: ['test-stages', { testId }],
		// When we provide enable option - !!stationId, the stationId is guaranteed
		queryFn: () => api.getTestStages(testId as string),
		enabled: !!testId
	});
	const queryClient = useQueryClient();

	const invalidateStage = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: ['test-stages', { testId }] });
	}, [queryClient, testId]);

	return {
		stages,
		invalidateStage,
		isStagesLoading
	};
};

export const useDataStorageTypeOptions = () => {
	const dataStorageTypes = useNumberEnumList(DataStorageTypes);
	return { dataStorageTypes };
};

export const useStorageFullBehaviorTypeOptions = () => {
	const storageFullBehaviorTypes = useNumberEnumList(StorageFullBehaviorTypes);
	return { storageFullBehaviorTypes };
};
