import { useMemo, useEffect, FormEvent } from 'react';
import isObject from 'lodash/isObject';
import { useFormContext } from 'react-hook-form';
import SpecimenFormThin from '../components/SpecimenFormThin';
import SpecimenFormWide from '../components/SpecimenFormWide';
import { SpecimenFormType } from '../specimens.types';
import { useSpecimenType, useSpecimenTypes } from '../../SpecimenTypes';
import { useEntitiesAsList } from '../../../hooks';
import { useMaterials } from '../specimens.hooks';
import { useTests } from '../../Tests/tests.hooks';
import { makeTwoColumnsMatrix } from '../../../tools';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

interface ISpecimenFormContainerProps {
	isForDashboard: boolean;
	onSave: (event: FormEvent<HTMLFormElement>) => void;
}

const SpecimenFormContainer: React.FC<ISpecimenFormContainerProps> = ({ onSave, isForDashboard = false }) => {
	const { width } = useWindowDimensions();
	const {
		watch,
		setValue,
		formState: { dirtyFields }
	} = useFormContext<SpecimenFormType>();

	// const quantity = watch('quantity');
	const uuid = watch('uuid');
	const { specimenTypes = [] } = useSpecimenTypes();
	const specimenTypeOptions = useEntitiesAsList(specimenTypes);
	const { materials } = useMaterials();
	const materialOptions = useEntitiesAsList(materials);
	const currentSpecimenTypeId = watch('specimenTypeUuid');
	const { specimenType } = useSpecimenType(currentSpecimenTypeId);
	const { tests = [] } = useTests();
	const testFiltered = useMemo(() => {
		if (!tests) return [];

		return tests.filter((test) => test.specimenTypeId === currentSpecimenTypeId);
	}, [currentSpecimenTypeId, tests]);
	const testOptions = useEntitiesAsList(testFiltered);

	const dimensionFields = useMemo(() => {
		if (specimenType === undefined || !isObject(specimenType.dimensions)) {
			return [];
		}
		const fields = Object.keys(specimenType.dimensions).map((key) => {
			return {
				unit: specimenType.dimensions[key].name,
				name: key,
				field: `dimensions.${key}`
			};
		});

		return makeTwoColumnsMatrix<{ field: string; unit: string; name: string }>(fields);
	}, [specimenType]);

	useEffect(() => {
		if (!uuid) {
			const dimensionKeys = specimenType?.dimensions ? Object.keys(specimenType.dimensions) : [];
			const dimensions = dimensionKeys.reduce(
				(obj: Record<string, undefined>, key) => ({
					...obj,
					[key]: undefined
				}),
				{}
			);
			setValue('dimensions', dimensions);
		}
	}, [specimenType, uuid, setValue]);

	useEffect(() => {
		if (currentSpecimenTypeId && dirtyFields.specimenTypeUuid) setValue('testId', '');
	}, [currentSpecimenTypeId, dirtyFields.specimenTypeUuid, setValue]);

	return isForDashboard || (width && width < 1680) ? (
		<SpecimenFormThin
			specimenTypes={specimenTypeOptions}
			materials={materialOptions}
			tests={testOptions}
			dimensions={dimensionFields}
			onSave={onSave}
			isForDashboard={isForDashboard}
		/>
	) : (
		<SpecimenFormWide
			specimenTypes={specimenTypeOptions}
			materials={materialOptions}
			tests={testOptions}
			dimensions={dimensionFields}
			onSave={onSave}
		/>
	);
};

export default SpecimenFormContainer;
