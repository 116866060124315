import styles from './BottomBar.module.scss';

export interface IBottomBarProps {
	children: React.ReactNode;
}

const BottomBar: React.FC<IBottomBarProps> = ({ children }) => {
	return <div className={styles.container}>{children}</div>;
};

export default BottomBar;
