import { Widget } from './dashboard.types';

export const findEmptySpace = (layout: Widget[], width: number, height: number): { x: number; y: number } => {
	const matrix: boolean[][] = [];

	/**
	 * Built a boolean matrix of the grid, where `true` indicates the used spaces
	 */
	layout.forEach(({ w, h, x, y }) => {
		for (let i = y; i < y + h; i++) {
			matrix[i] = matrix[i] || [];
			for (let j = x; j < x + w; j++) {
				matrix[i][j] = true;
			}
		}
	});

	/**
	 * Loop over matrix from left->right top->bottom and find first free space for given width and height
	 */
	for (let y = 0; y < matrix.length; y++) {
		const r = matrix[y];
		for (let x = 0; x <= 24 - width; x++) {
			if (
				!r?.[x] &&
				matrix.slice(y, Math.min(matrix.length, y + height)).every((row) => row.slice(x, x + width).every((v) => !v))
			) {
				return { x, y };
			}
		}
	}
	return { x: 0, y: matrix.length };
};
