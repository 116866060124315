import React, { useCallback, useMemo } from 'react';
import { Header } from '@tactun/ui';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useKeycloakContext } from '@tactun/keycloak-axios-provider';
import Layout from '../components/Layout';
import { AccountMenu } from '../../../components';
import Connection from '../../Connection';
import DashboardButtonContainer from '../../DashboardButton';
import { useSyncDeviceInfoWithTauri } from '../../../hooks/useSyncDeviceInfoWithTauri';
import { useIsLocalDeployment } from '../../../hooks/useIsLocalDeployment';

const LayoutContainer: React.FC = () => {
	const { t } = useTranslation('controlChannel');
	const navigate = useNavigate();
	const keycloakContext = useKeycloakContext();
	useSyncDeviceInfoWithTauri();
	const isLocalDeployment = useIsLocalDeployment();

	const handleLogout = useCallback(() => {
		keycloakContext?.logout();
	}, [keycloakContext]);

	const items = useMemo(() => {
		let menuItems = [{ label: t('Logout'), command: handleLogout }];

		if (!isLocalDeployment) {
			menuItems = [
				{ label: t('Controllers'), command: () => navigate('/controllers') },
				{ label: t('Products'), command: () => navigate('/products') },
				{ label: t('Components'), command: () => navigate('/components-settings/units') },
				// { label: t('Account Details'), disabled: true, icon: 't-icon-under-construction' },
				// {
				// 	label: t('User Management'),
				// 	disabled: true,
				// 	command: () => navigate('/users'),
				// 	icon: 't-icon-under-construction'
				// },
				// { label: t('Guides and Support'), disabled: true, icon: 't-icon-under-construction' },
				...menuItems
			];
		}
		return menuItems;
	}, [t, handleLogout, isLocalDeployment, navigate]);

	return (
		<Layout>
			<Header logoClickPath="/">
				<Header.Navigation>
					{/* <Header.Menu label={t('Customers and Orders')}>
						<Header.MenuItem label={t('Customers')} target="/customers" />
						<Header.MenuItem label={t('Orders')} target="/orders" />
					</Header.Menu> */}
					{/* <Header.Menu label={t('Systems and Stations')}>
						<Header.MenuItem label={t('Systems')} target="/systems" />
						<Header.MenuItem label={t('Feature Sets')} target="/featuresets" />
						<Header.MenuItem label={t('Stations')} target="/stations" />
					</Header.Menu> */}
					<Header.Menu label={t('Stations')} target="/stations" />
					<Header.Menu label={t('Specimens')} target="/specimen-list" />
					<Header.Menu label={t('Tests')} target="/tests" />
				</Header.Navigation>
				<Header.LeftNavigation>
					<DashboardButtonContainer />
					<Connection />
					<AccountMenu items={items} />
				</Header.LeftNavigation>
			</Header>
			<Layout.Body>
				<Outlet />
			</Layout.Body>
		</Layout>
	);
};

export default React.memo(LayoutContainer);
