import { Button } from '@tactun/ui';
import { INamedEntity } from '../../../types';
import { useGroup } from '../group.hooks';
import styles from './GroupTableCell.module.scss';

interface IGroupTableCellProps {
	groups: INamedEntity[];
}

const GroupTableCell = ({ groups }: IGroupTableCellProps) => {
	const { selectGroup } = useGroup();

	return (
		<>
			{groups.map((group) => (
				<Button
					key={group.id}
					className={styles.tableGroupFilterButton}
					color="success"
					variant="text"
					onClick={() => selectGroup(group)}
					label={group.name}
				/>
			))}
		</>
	);
};

export default GroupTableCell;
