import React, { useCallback, useMemo, useState, useEffect } from 'react';
import cx from 'classnames';
import { getElementWidth } from '../../../../tools';
import { IAxisResponseDto } from '../../../Axes';
import { TestAxesConfigurationFormType } from '../../../TestAxesConfiguration/testAxesConfiguration.type';
import { useTestLoops } from '../../../TestLoops';
import {
	StageActionTypes,
	StageAxisActionTypes,
	StageConfigurationActionTypes,
	StageTypes,
	TestPrePostActionTypes
} from '../../tests.enums';
import { ITestPrePostInfo, TestInformationResponseDto, TestStageConfigs } from '../../tests.types';
import EmptyStageCard from '../EmptyStageCard';
import StageCard from '../StageCard';
import StageInfoBar from '../StageInfoBar';
import TestLoopLine from '../TestLoopLine';
import { TestActionLocations } from '../../../TestActions';
import GlobalConfigurationPanel from '../GlobalConfigurationPanel';
import styles from './TestConfiguration.module.scss';

interface TestConfigurationProps {
	axes?: IAxisResponseDto[];
	test?: TestInformationResponseDto;
	stages?: TestStageConfigs[];
	testPrePostInfo: ITestPrePostInfo | null;
	onConfigureTestPrePostAction: (actionType: TestPrePostActionTypes) => void;
	onConfigureStageAction: (actionType: StageConfigurationActionTypes, stageType: StageTypes, stageId?: string) => void;
	onStageAction: (actionType: StageActionTypes, data?: TestStageConfigs) => void;
	onStageAxisAction: (type: StageAxisActionTypes, stageId: string, axis?: TestAxesConfigurationFormType) => void;
}

const TestConfiguration: React.FC<TestConfigurationProps> = ({
	onConfigureTestPrePostAction,
	onConfigureStageAction,
	onStageAxisAction,
	onStageAction,
	testPrePostInfo,
	test,
	axes,
	stages
}) => {
	const [itemWidth, setItemWidth] = useState(0);
	const [element, setElement] = useState<HTMLDivElement | null>(null);

	const { loops } = useTestLoops(test?.id);

	const handleAddStage = useCallback(() => {
		onStageAction(StageActionTypes.CREATE_STAGE);
	}, [onStageAction]);

	const axesIds = useMemo(() => axes?.map((axis) => axis.id) || [], [axes]);

	useEffect(() => {
		const resizeObserver = new ResizeObserver(([div]) => {
			setItemWidth(getElementWidth(div.target as HTMLDivElement));
		});
		if (element) {
			resizeObserver.observe(element);
		}
		return () => {
			resizeObserver.disconnect();
		};
	}, [element]);

	return (
		<>
			<div className={styles.sequencerContainer}>
				<GlobalConfigurationPanel
					testId={test?.id}
					testName={test?.name}
					onConfigureTestPrePostAction={onConfigureTestPrePostAction}
				/>
				<div className={styles.mainContainer}>
					<div className={styles.testPreContainer}>
						<StageInfoBar
							location={TestActionLocations.PRE_TEST}
							actionsCount={testPrePostInfo?.preActionsCount}
							logsCount={testPrePostInfo?.preLogsCount}
							onAddLog={() => onConfigureTestPrePostAction(TestPrePostActionTypes.CONFIGURE_TEST_PRE_LOGGING)}
							onAddAction={() => onConfigureTestPrePostAction(TestPrePostActionTypes.CONFIGURE_TEST_PRE_ACTIONS)}
							className={styles.stageInfo}
						/>
						{axes?.map((axis) => (
							<div className={cx(styles.axisNameContainer, styles.withLines)}>
								<p>{axis.name}</p>
							</div>
						))}
					</div>
					<div className={styles.stagesContainer}>
						<div
							className={cx(styles.loopWrapper, {
								[styles.singleLoop]: loops?.length === 1,
								[styles.doubleLoop]: loops?.length === 2
							})}
						>
							{stages?.length && loops?.length ? (
								<TestLoopLine itemWidth={itemWidth} loopsList={loops} stages={stages} />
							) : null}
						</div>
						<div className={styles.stageCardWrapper}>
							{stages?.map((stage) => (
								<StageCard
									key={stage.id}
									axesIds={axesIds}
									ref={(node) => setElement(node)}
									stage={stage}
									onStageAction={onStageAction}
									onConfigureStageAction={onConfigureStageAction}
									onStageAxisAction={onStageAxisAction}
								/>
							))}
							<EmptyStageCard onClick={handleAddStage} />
						</div>
					</div>
					<div className={styles.testPreContainer}>
						<StageInfoBar
							location={TestActionLocations.POST_TEST}
							actionsCount={testPrePostInfo?.postActionsCount}
							logsCount={testPrePostInfo?.postLogsCount}
							onAddLog={() => onConfigureTestPrePostAction(TestPrePostActionTypes.CONFIGURE_TEST_POST_LOGGING)}
							onAddAction={() => onConfigureTestPrePostAction(TestPrePostActionTypes.CONFIGURE_TEST_POST_ACTIONS)}
							className={styles.stageInfo}
						/>
						{axes?.map(() => <div className={styles.axisNameContainer} />)}
					</div>
				</div>
			</div>
		</>
	);
};

export default React.memo(TestConfiguration);
