import axios from '../../httpCommon';
import { StationFormType, StationResponseDto, StationRequestDto } from './station.types';
import { getButtonWidgets } from '../ButtonWidget';

export const getStationById = async (id?: string): Promise<StationResponseDto> => {
	const response = await axios.get(`/quasar/stations/${id}`);

	return response.data;
};

export const getFullStationById = async (id?: string): Promise<StationResponseDto> => {
	const [response, widgets] = await Promise.all([axios.get(`/quasar/stations/${id}/full`), getButtonWidgets(id)]);

	response.data.widgets = widgets;

	return response.data;
};

export const checkName = async (name: string, id?: string): Promise<boolean> => {
	const response = await axios.post('/quasar/stations/isNameUnique', { id, name });
	return response.data === true;
};

export const getStations = async (): Promise<StationResponseDto[]> => {
	const response = await axios.get('/quasar/stations');

	return response.data as StationResponseDto[];
};

export const getStationControllerModel = async (model?: string): Promise<StationResponseDto[]> => {
	const response = await axios.get(`/quasar/stations/byControllerModel?controllerModel=${model}`);

	return response.data as StationResponseDto[];
};

export const createStation = async (system: StationRequestDto): Promise<StationFormType> => {
	const response = await axios.post('/quasar/stations', system);

	return response.data;
};

export const updateStation = async (system: StationRequestDto, id: string): Promise<StationFormType> => {
	const response = await axios.put(`/quasar/stations/${id}`, system);

	return response.data;
};

export const duplicateStation = async (id: string): Promise<StationResponseDto> => {
	const response = await axios.post(`/quasar/stations/${id}/duplicate`);

	return response.data;
};

export const getDevices = async (): Promise<string[]> => {
	const response = await axios.get('/quasar/devices');

	return response.data;
};

export const getStationTypes = async (): Promise<string[]> => {
	const response = await axios.get('/quasar/stations/station-types');

	return response.data as string[];
};
