import React from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { controllerConfigureNetworkFormId, controllerConfigureNetworkModalId } from '../../connection.const';
import styles from './ControllerConfigureNetworkModal.module.scss';

interface IControllerConfigureNetworkModalProps {
	children: React.ReactNode;
}

const ControllerConfigureNetworkModal: React.FC<IControllerConfigureNetworkModalProps> = ({ children }) => {
	const { t } = useTranslation('connection');

	return (
		<Modal
			id={controllerConfigureNetworkModalId}
			className={styles.modalContainer}
			shouldCloseOnOverlayClick={false}
			onClose={() => Modal.hide(controllerConfigureNetworkModalId)}
		>
			<Modal.Header>{t('Configure Controller Network')}</Modal.Header>
			<Modal.Content>{children}</Modal.Content>
			<Modal.Footer>
				<Button
					className="btnCancel"
					onClick={() => Modal.hide(controllerConfigureNetworkModalId)}
					color="success"
					variant="text"
				>
					{t('Cancel')}
				</Button>
				<Button color="secondary" variant="contained" form={controllerConfigureNetworkFormId} type="submit">
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(ControllerConfigureNetworkModal);
