export const getFeaturesets = async (): Promise<Record<string, string>[]> => {
	return new Promise((resolve) =>
		resolve([
			{ id: '1', name: 'Static Feature Set', date: '12/04/2023, 11:04:01', group: '' },
			{ id: '2', name: 'Dynamic Feature Set', date: '12/01/2023, 07:04:50', group: '' },
			{ id: '3', name: 'Essential', date: '21/02/2023, 03:12:16', group: '' },
			{ id: '4', name: 'Advanced', date: '1/02/2023, 05:22:36', group: '' }
		])
	);
};

export const getFeatureset = async (id: string): Promise<Record<string, string>> => {
	return new Promise((resolve) =>
		resolve({ id: '2', name: 'Dynamic Feature Set', date: '12/06/2023, 13:04:56', group: '' })
	);
};
