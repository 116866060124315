import * as yup from 'yup';
import { waveformSchema } from '../Waveforms';
import { TestAxesConfigurationFormType } from './testAxesConfiguration.type';
import { controlAlgBaseSchema } from '../ControlAlgorithms/controlAlgorithms.schemas';

export const testAxesConfigurationBaseSchema = yup.object({
	id: yup.string().optional(),
	axisId: yup.string().required('Axis is a required field'),
	stageId: yup.string().required('Stage is a required field'),
	ccId: yup.string().required('Control is a required field'),
	useStationParams: yup.boolean().required(),
	isFinishStageTrigger: yup.boolean().optional(),
	controlAlgorithmsForm: controlAlgBaseSchema.required()
});

export const testAxesConfigurationSchema = yup.lazy((testAxis: TestAxesConfigurationFormType) => {
	return testAxesConfigurationBaseSchema.concat(
		yup.object({ wf: yup.lazy((value) => waveformSchema.resolve({ value: value?.type }).required()) })
	);
});
