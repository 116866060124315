import { ControllerStatuses } from './controllers.enums';
import { IControllerListItem, IControllerResponseDto } from './controllers.types';

export const controllerDtoToListItems = (response: IControllerResponseDto): IControllerListItem => {
	return {
		id: response.uuid,
		name: response.model,
		serialNumber: response.serialNumber,
		model: response.model,
		purchaseDate: response.purchasedAt,
		status: response.linked ? ControllerStatuses.LINKED : ControllerStatuses.AVAILABLE
	};
};
