import { FC } from 'react';
import { GroupInputFrame, InputNumber, InputText } from '@tactun/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DitherAlgFormErrors } from '../../controlAlgorithms.types';
import styles from './DitherAlgForm.module.scss';

interface DitherAlgFormProps {
	formPrefix: string;
	disabled?: boolean;
	isControlWithCurrent: boolean;
	errors?: DitherAlgFormErrors;
}

const DitherAlgForm: FC<DitherAlgFormProps> = ({ formPrefix, errors, disabled = false, isControlWithCurrent }) => {
	const { t } = useTranslation('controlAlgorithm');
	const { control } = useFormContext();

	return (
		<>
			<div className={styles.formLabel}>{t('Dither')}</div>
			<GroupInputFrame>
				<Controller
					name={`${formPrefix}.dither.amplitude`}
					control={control}
					render={({ field }) => (
						<InputNumber
							{...field}
							label={t('Amplitude*')}
							error={errors?.amplitude?.message as string}
							data-testid="amplitude"
							disabled={disabled}
						/>
					)}
				/>
				<InputText disabled value={isControlWithCurrent ? 'mA' : 'mV'} />
			</GroupInputFrame>

			<GroupInputFrame>
				<Controller
					name={`${formPrefix}.dither.frequency`}
					control={control}
					render={({ field }) => (
						<InputNumber
							{...field}
							label={t('Frequency*')}
							error={errors?.frequency?.message as string}
							data-testid="frequency"
							disabled={disabled}
						/>
					)}
				/>
				<InputText disabled value="Hz" />
			</GroupInputFrame>
		</>
	);
};

export default DitherAlgForm;
