import EventViewerWidget from '../components/EventViewerWidget';
import { useEvents } from '../eventViewer.hooks';
import { WidgetProps } from '../../Dashboard/dashboard.types';

const EventViewerContainer: React.FC<WidgetProps> = ({ metadata }) => {
	const events = useEvents(metadata.isType);

	return <EventViewerWidget events={events} isType={metadata.isType} />;
};

export default EventViewerContainer;
