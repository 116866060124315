import React, { FC } from 'react';
import { InputNumber } from '@tactun/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PidAlgFormErrors } from '../../controlAlgorithms.types';
import styles from './PidAlgorithmForm.module.scss';

interface PidAlgorithmFormProps {
	formPrefix: string;
	errors?: PidAlgFormErrors;
	disabled?: boolean;
}

const PidAlgorithmForm: FC<PidAlgorithmFormProps> = ({ formPrefix, errors, disabled = false }) => {
	const { t } = useTranslation('controlAlgorithm');
	const { control } = useFormContext();

	return (
		<>
			<div className={styles.formLabel}>{t('PID')}</div>
			<Controller
				name={`${formPrefix}.pid.kp`}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Proportional gain (Kp)*')}
						error={errors?.kp?.message}
						data-testid="kp"
						min={0}
						disabled={disabled}
					/>
				)}
			/>
			<Controller
				name={`${formPrefix}.pid.ki`}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Integral gain (Ki)*')}
						error={errors?.ki?.message}
						data-testid="ki"
						min={0}
						disabled={disabled}
					/>
				)}
			/>
			<Controller
				name={`${formPrefix}.pid.kd`}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Derivative gain (Kd)*')}
						error={errors?.kd?.message}
						data-testid="kd"
						min={0}
						disabled={disabled}
					/>
				)}
			/>
			<div></div>
		</>
	);
};

export default PidAlgorithmForm;
