import { boolean, mixed, number, object, string } from 'yup';
import { alphaNumericRegex } from '../../constants/regexes';
import { emptyStringToNull } from '../../tools/yupHelpers';
import { controlAlgBaseSchema } from '../ControlAlgorithms/controlAlgorithms.schemas';
import * as api from './controlChannel.api';
import { ControlChannelSetPointType } from './controlChannel.enums';

export const controlChannelSchema = object({
	id: string(),
	name: string()
		.required()
		.matches(alphaNumericRegex, 'Is not in correct format')
		.max(30)
		.test('checkControlChannelUniqueness', 'Name is used', function (value, form) {
			if (!value) return true;
			return api.checkName(value, form?.parent?.stationId, form?.parent?.id);
		}),
	stationId: string().required(),
	axisId: string().required('Output is a required field'),
	setPoint: mixed<string>().required('Setpoint is a required field'),
	rateLimit: number()
		.transform(emptyStringToNull)
		.when(['setPoint', 'isRateLimitEnabled'], {
			is: (type: string, isRateLimitEnabled: boolean) =>
				type === String(ControlChannelSetPointType.WAVEFORM) && isRateLimitEnabled,
			then: (schema) => schema.moreThan(0).required(),
			otherwise: (schema) => schema.nullable()
		}),
	feedbackId: string()
		.optional()
		.nullable()
		.when('isAxisBasedOnAnalogOut', {
			is: false,
			then: (schema) => schema.required('Feedback is a required field')
		}),
	controlAlgorithmsForm: controlAlgBaseSchema.required(),
	isRateLimitEnabled: boolean(),
	isAxisBasedOnAnalogOut: boolean()
});
