import { FC, useCallback, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { SensorListItem, SensorResponseDto } from '../sensors.types';
import SensorsTable from '../components/SensorsTable';
import { SensorActionTypes } from '../sensors.enums';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { useStationName } from '../../Stations/stations.hooks';
import { responseListItemConverter } from '../sensors.converters';
import { useSensors } from '../sensors.hooks';

const SensorsTableContainer: FC = () => {
	const navigate = useNavigate();
	const { stationId } = useParams();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const queryClient = useQueryClient();

	const { stationName } = useStationName(stationId);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			queryClient.setQueryData<SensorResponseDto[]>(['sensors', { stationId }], (old) => {
				if (!old) return undefined;
				return old.filter((hc) => hc.id !== id);
			});
		},
		[queryClient, stationId]
	);

	const { isSensorsLoading, sensors } = useSensors(stationId);
	const sensorsList = useMemo(
		() => (sensors ? sensors.map((sensor) => responseListItemConverter(sensor)) : []),
		[sensors]
	);

	const handleAction = useCallback(
		(type: SensorActionTypes, data?: SensorListItem) => {
			switch (type) {
				case SensorActionTypes.CREATE:
					navigate('./create');
					break;
				case SensorActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case SensorActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case SensorActionTypes.DELETE:
					setEntityForDelete(data);
					break;
				case SensorActionTypes.OPEN_CALIBRATION:
					navigate(`./${data?.id}/calibration/`);
			}
		},
		[navigate]
	);

	return (
		<>
			<SensorsTable onAction={handleAction} data={sensorsList} isLoading={isSensorsLoading} stationName={stationName} />
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.SENSOR}
				entity={entityForDelete}
				checkUsage
			/>
		</>
	);
};

export default SensorsTableContainer;
