import React, { useEffect, useMemo } from 'react';
import { Form, InputText, Dropdown, InputNumber } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import cx from 'classnames';
import { VariableFormType } from '../../variables.types';
import { VariablesTypes } from '../../variables.enums';
import styles from './VariableForm.module.scss';
import { useNumberEnumList } from '../../../../hooks';

interface VariablePageProps {
	form: UseFormReturn<VariableFormType>;
	isForModal?: boolean;
}

const formId = 'variableForm';
const VariableForm: React.FC<VariablePageProps> = ({ form, isForModal = false }) => {
	const { t } = useTranslation('variables');
	const {
		control,
		register,
		watch,
		setValue,
		formState: { errors, dirtyFields }
	} = form;
	const id = watch('id');
	const varType = watch('type');
	const variablesTypes = useNumberEnumList(VariablesTypes);
	const filteredTypes = useMemo(
		() => variablesTypes.filter((v) => v.value !== VariablesTypes.BOOLEAN),
		[variablesTypes]
	);

	useEffect(() => {
		if (dirtyFields.type) {
			setValue('defaultValue', '');
		}
	}, [dirtyFields.type, setValue]);

	return (
		<Form className={cx({ [styles.container]: !isForModal, [styles.modalContainer]: isForModal })} id={formId}>
			<input type="hidden" {...register('id')} />
			<input type="hidden" {...register('stationId')} />
			<input type="hidden" {...register('testId')} />

			<Controller
				name="name"
				control={control}
				render={({ field }) => (
					<InputText
						className={styles.labelContainer}
						data-testid="nameId"
						label={t('Name*')}
						{...field}
						error={errors.name?.message}
					/>
				)}
			/>
			<Controller
				name="type"
				control={control}
				render={({ field }) => (
					<Dropdown
						data-testid="type"
						className={styles.labelContainer}
						{...field}
						options={filteredTypes}
						label={t('Type*')}
						error={errors.type?.message}
						filter
						disabled={!!id}
					/>
				)}
			/>

			<Controller
				name="defaultValue"
				control={control}
				render={({ field }) => {
					return varType === VariablesTypes.STRING ? (
						<InputText
							className={styles.labelContainer}
							label={t('Default value*')}
							{...field}
							error={errors.defaultValue?.message}
							data-testid="defaultValue"
						/>
					) : (
						<InputNumber
							{...field}
							value={field.value ? Number(field.value) : undefined}
							className={styles.labelContainer}
							label={t('Default value*')}
							error={errors.defaultValue?.message}
							data-testid="defaultValue"
						/>
					);
				}}
			/>
		</Form>
	);
};

export default VariableForm;
