import React, { useMemo } from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { createEditTestActionsModalId } from '../../testActions.const';
import styles from './TestActionsCreateEditModal.module.scss';

interface TestActionsCreateEditModalProps {
	isCreate: boolean;
	name?: string;
	isLoading: boolean;
	isCalculation?: boolean;
	children: React.ReactNode;
	formId: string;
	onClose: () => void;
	onAfterClose?: () => void;
}

const TestActionsCreateEditModal: React.FC<TestActionsCreateEditModalProps> = ({
	isCreate,
	name,
	isLoading,
	isCalculation = false,
	children,
	formId,
	onClose,
	onAfterClose
}) => {
	const { t } = useTranslation('testActions');
	const entityName = useMemo(() => {
		if (isCalculation) {
			return isCreate ? t('a Calculation') : t('Calculation');
		} else {
			return isCreate ? t('an Action') : t('Action');
		}
	}, [isCalculation, isCreate, t]);

	return (
		<Modal
			id={createEditTestActionsModalId}
			className={styles.container}
			shouldCloseOnOverlayClick={false}
			onClose={onClose}
			onAfterClose={onAfterClose}
			hidePrevModals
		>
			<Modal.Header>
				{isCreate
					? t('Create {{entityName}}', { entityName })
					: t('Edit "{{name}}" {{entityName}}', { name, entityName })}
			</Modal.Header>
			<Modal.Content className={styles.createTestAction}>{children}</Modal.Content>
			<Modal.Footer>
				<Button onClick={onClose} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button color="secondary" variant="contained" loading={isLoading} type="submit" form={formId}>
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(TestActionsCreateEditModal);
