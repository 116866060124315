import { WidgetTypeDefinition } from './dashboard.types';
import { WidgetTypes } from './dashboard.enums';
import GraphSettingsModal from '../GraphWidget/components/GraphSettingsModal';
import ReadoutSettingsModal from '../ReadoutWidget/components/ReadoutSettingsModal';
import ButtonWidgetTableContainer from '../ButtonWidget/containers/ButtonWidgetTableContainer';

import resultsTableIcon from './assets/results-table-icon.svg';
import stageInfoIcon from './assets/stage-info-icon.svg';
import testInfoIcon from './assets/test-info-icon.svg';
import safetyIcon from './assets/safety-icon.svg';
import controlStageIcon from './assets/control-stage-icon.svg';
import controlTestIcon from './assets/control-test-icon.svg';
import graphIcon from './assets/graph-icon.svg';
import readoutIcon from './assets/readout-icon.svg';
import eventViewerIcon from './assets/event-viewer-icon.svg';
import controlAxisIcon from './assets/control-axis-icon.svg';
import sensorCalibrationIcon from './assets/sensor-calibration-icon.svg';
import buttonWidgetIcon from './assets/button-widget-icon.svg';
import offsetCalibrationIcon from './assets/offset-calibration-icon.svg';

export const ResultsTableWidgetDefinition = {
	initialSize: {
		w: 12,
		h: 6
	},
	type: WidgetTypes.RESULTS,
	icon: resultsTableIcon,
	title: 'Results Table',
	description: 'A data visualization tool, allowing to see the data channels in a real-time view.',
	maxPerDashboard: 1,
	enableInTest: true,
	enableInStation: false
};

export const StageInformationWidgetDefinition = {
	initialSize: {
		w: 12,
		h: 6
	},
	type: WidgetTypes.STAGE_INFORMATION,
	icon: stageInfoIcon,
	title: 'Stage information',
	description: 'A data visualization tool, allowing to see the data channels in a real-time view.',
	maxPerDashboard: 1,
	enableInTest: true,
	enableInStation: false
};

export const TestInformationWidgetDefinition = {
	initialSize: {
		w: 6,
		h: 4
	},
	type: WidgetTypes.TEST_INFORMATION,
	icon: testInfoIcon,
	title: 'Test information',
	description:
		'Shows test-related information such as Test name, Specimen ID, Test running time and Estimated remaining time.',
	maxPerDashboard: 1,
	enableInTest: true,
	enableInStation: false
};

export const SafetyWidgetDefinition = {
	initialSize: {
		w: 2,
		h: 4
	},
	type: WidgetTypes.SAFETY,
	icon: safetyIcon,
	title: 'Safety',
	description: 'An indicator, displaying emergency stop and limit switches statuses.',
	maxPerDashboard: 1,
	enableInTest: true,
	enableInStation: true
};

export const ControlStageWidgetDefinition = {
	initialSize: {
		w: 12,
		h: 10
	},
	type: WidgetTypes.CONTROL_STAGE,
	icon: controlStageIcon,
	title: 'Control Stage',
	description: 'Axis operation tool. Also allows to configure the axes in real-time mode.',
	maxPerDashboard: 1,
	enableInTest: true,
	enableInStation: true
};

export const ControlTestWidgetDefinition = {
	initialSize: {
		w: 3,
		h: 7
	},
	type: WidgetTypes.CONTROL_TEST,
	icon: controlTestIcon,
	title: 'Control Test',
	description: 'A remote, allowing to control the test process, as well as generate a report.',
	maxPerDashboard: 1,
	enableInTest: true,
	enableInStation: false
};

export const GraphWidgetDefinition = {
	initialSize: {
		w: 16,
		h: 12
	},
	type: WidgetTypes.GRAPH,
	settingsModal: GraphSettingsModal,
	icon: graphIcon,
	title: 'Graph',
	description: 'A data visualization tool, allowing to see the plotted data based on selected data channels.',
	maxPerDashboard: 10,
	enableInTest: true,
	enableInStation: true
};

export const ReadoutWidgetDefinition = {
	initialSize: {
		w: 5,
		h: 5
	},
	type: WidgetTypes.READOUT,
	icon: readoutIcon,
	title: 'Readout',
	description: 'A data visualization tool, allowing to see the data channels in a real-time view.',
	maxPerDashboard: Infinity,
	enableInTest: true,
	enableInStation: true,
	settingsModal: ReadoutSettingsModal
};

export const EventViewerWidgetDefinition = {
	initialSize: {
		w: 12,
		h: 6
	},
	type: WidgetTypes.EVENT_VIEWER,
	icon: eventViewerIcon,
	title: 'Event Viewer',
	description: 'A data visualization tool, allowing to see the data channels in a real-time view.',
	maxPerDashboard: 1,
	enableInTest: true,
	enableInStation: true
};

export const ControlAxisWidgetDefinition = {
	initialSize: {
		w: 4,
		h: 14
	},
	type: WidgetTypes.CONTROL_AXIS,
	icon: controlAxisIcon,
	title: 'Control Axis',
	description: 'A data visualization tool, allowing to see the data channels in a real-time view.',
	maxPerDashboard: 1,
	enableInTest: false,
	enableInStation: false
};

export const SensorCalibrationWidgetDefinition = {
	initialSize: {
		w: 0,
		h: 0
	},
	type: WidgetTypes.SENSOR_CALIBRATION,
	icon: sensorCalibrationIcon,
	title: 'Sensor Calibration',
	description: 'A sensor calibration tool. Allows to configure the sensor in real-time mode.',
	maxPerDashboard: 1,
	enableInTest: true,
	enableInStation: true
};

export const ButtonWidgetDefinition: WidgetTypeDefinition = {
	initialSize: {
		w: 3,
		h: 10
	},
	type: WidgetTypes.BUTTON_WIDGET,
	icon: buttonWidgetIcon,
	title: 'Button Widget',
	description: 'A number of widgets created by user, that can be added to the dashboard',
	maxPerDashboard: 100,
	enableInTest: true,
	enableInStation: true,
	settingsModal: ButtonWidgetTableContainer
};

export const OffsetCalibrationWidgetDefinition = {
	initialSize: {
		w: 4,
		h: 6
	},
	type: WidgetTypes.OFFSET_CALIBRATION,
	icon: offsetCalibrationIcon,
	title: 'Offset Calibration',
	description: 'An offset calibration tool. Allows to automatically calibrate and adjust Axis offset.',
	maxPerDashboard: 1,
	enableInTest: true,
	enableInStation: true
};

export const widgetDefinitions: WidgetTypeDefinition[] = [
	GraphWidgetDefinition,
	EventViewerWidgetDefinition,
	ResultsTableWidgetDefinition,
	StageInformationWidgetDefinition,
	SafetyWidgetDefinition,
	TestInformationWidgetDefinition,
	ControlTestWidgetDefinition,
	ControlStageWidgetDefinition,
	ControlAxisWidgetDefinition,
	ReadoutWidgetDefinition,
	ButtonWidgetDefinition,
	SensorCalibrationWidgetDefinition,
	OffsetCalibrationWidgetDefinition
];
