import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@tactun/ui';
import { PageLayout } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { IAxisListItem } from '../../axes.types';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { AxesCrudActionTypes } from '../../axes.enums';
import { useTableConfigs } from '../../../../hooks';

interface AxesTableProps {
	onAction: (type: AxesCrudActionTypes, data?: IAxisListItem) => void;
	data?: IAxisListItem[];
	isLoading: boolean;
	stationName: string;
	maxAxesAllowed?: number;
}

const AxesTable: FC<AxesTableProps> = ({ data, isLoading, onAction, stationName, maxAxesAllowed }) => {
	const { t } = useTranslation('axes');

	const formattedData = useMemo(() => data?.map((item) => ({ ...item, type: t(item.type) })), [data, t]);

	const transColumns = useMemo<ITableColumns<IAxisListItem>[]>(() => {
		return [
			{ field: 'type', header: t('Axis type') },
			{ field: 'actuatorName', header: t('Actuator') }
		];
	}, [t]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<IAxisListItem>(transColumns, formattedData);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(AxesCrudActionTypes.DELETE, data)
			}
		],
		[t, onAction]
	);

	const handleCreate = useCallback(() => {
		onAction(AxesCrudActionTypes.CREATE);
	}, [onAction]);

	const isCreateDisabled = !maxAxesAllowed || maxAxesAllowed <= (formattedData?.length || 0);

	return (
		<PageLayout info={stationName}>
			<PageLayout.Header title={t('Axes')}>
				<CrudActions onCreate={handleCreate} isCreateDisabled={isCreateDisabled} entityLabel={t('Axis')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={formattedData}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Axis name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(AxesCrudActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="duplicate"
							fontSize="1.2rem"
							title={t('Duplicate')}
							disabled
							onClick={() => onAction(AxesCrudActionTypes.DUPLICATE, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default AxesTable;
