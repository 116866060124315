export enum ActuatorsTypes {
	DC_PWM = 0,
	SERVO_ELECTRIC = 2
}

export enum ActuatorCalibrationTypes {
	PIECEWISE = 0
}

export enum ActuatorCalibrationStatus {
	UNCALIBRATED = 'UNCALIBRATED',
	CALIBRATED = 'CALIBRATED',
	NOT_APPLICABLE = 'NOT_APPLICABLE'
}

export enum ActuatorCrudActionTypes {
	EDIT = 'EDIT_ACTUATOR',
	CREATE = 'CREATE_ACTUATOR',
	DUPLICATE = 'DUPLICATE_ACTUATOR',
	DELETE = 'DELETE_ACTUATOR',
	OPEN_CALIBRATION = 'OPEN_CALIBRATION'
}
