import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout, DataTable } from '@tactun/ui';
import { Tag } from 'primereact/tag';
import { TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { ControllerCrudActionTypes, ControllerStatuses } from '../../controllers.enums';
import { useTableConfigs } from '../../../../hooks';
import { IControllerListItem } from '../../controllers.types';
import styles from './ControllersTable.module.scss';

interface ControllersTableProps {
	onAction: (type: ControllerCrudActionTypes, data?: IControllerListItem) => void;
	data: IControllerListItem[];
	isLoading: boolean;
}

const ControllersTable: FC<ControllersTableProps> = ({ data, isLoading, onAction }) => {
	const { t } = useTranslation(['controllers', 'common']);

	const transColumns = useMemo<ITableColumns<IControllerListItem>[]>(() => {
		return [
			{ field: 'name', header: t('Controller Name') },
			{ field: 'serialNumber', header: t('Controller S/N') },
			{ field: 'model', header: t('Controller Model') },
			{ field: 'purchaseDate', header: t('Purchase Date') },
			{
				field: 'status',
				header: t('Status'),
				body: (controller: IControllerListItem) => (
					<Tag
						className={controller.status === ControllerStatuses.LINKED ? styles.linked : styles.available}
						severity="info"
						value={t(ControllerStatuses[controller.status])}
						rounded
					/>
				)
			}
		];
	}, [t]);

	const {
		filter,
		selected,
		columnComponents,
		dataFilteredByGroups,
		handleSelectionChange,
		handleFilterChange,
		handleCancel
	} = useTableConfigs<IControllerListItem>(transColumns, data);

	const transData = useMemo(
		() =>
			dataFilteredByGroups?.map((d) => ({
				...d,
				purchaseDate: t('common:dateTime', { date: new Date(d.purchaseDate) })
			})),
		[dataFilteredByGroups, t]
	);

	return (
		<PageLayout info={t('My Account')}>
			<PageLayout.Header title={t('Controllers')}></PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={transData}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
				data-testid="controllersTable"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				{columnComponents}
			</DataTable>
		</PageLayout>
	);
};

export default ControllersTable;
