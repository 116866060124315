import React, { forwardRef, Ref } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './Widget.module.scss';

interface WidgetContentProps {
	className?: string;
	children: React.ReactNode;
	showFooter?: boolean;
	footer?: React.ReactNode;
	isType?: boolean;
}

const WidgetContent = forwardRef<HTMLDivElement, WidgetContentProps>(
	({ className, children, footer, showFooter, isType }, ref: Ref<HTMLDivElement>) => {
		const { t } = useTranslation('widget');
		return (
			<>
				<div
					ref={ref}
					className={classNames(styles.widgetContent, className, {
						[styles.withFooter]: showFooter || footer
					})}
				>
					{children}
					{isType && (
						<div className={styles.voidOverlay}>
							<div className={styles.containerBackground}>{t('Sample data')}</div>
						</div>
					)}
				</div>
				{(showFooter || footer) && <div className={styles.widgetFooter}>{footer}</div>}
			</>
		);
	}
);

export default React.memo(WidgetContent);
