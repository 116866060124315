import React, { FC, useCallback, useMemo } from 'react';
import { Form, Modal, useModalData } from '@tactun/ui';
import TestActionsTable from '../components/TestActionsTable';
import {
	createEditTestActionsModalId,
	TestActionLocations,
	TestActionsActionTypes,
	testActionsTable,
	testActionsModalId,
	useCurrentStageActions,
	useTestExistingActions
} from '..';
import TestActionsCreateEditContainer from './TestActionsCreateEditContainer';
import { testActionLocationToStageTypes } from '../testActions.converters';
import { RuleRequestDtoBase, RuleResponseDtoBase } from '../../Rules';
import { useDigitalOutputChannels } from '../../Sensors';
import { useTestMeasurements } from '../../TestMeasurements/testMeasurements.hooks';
import { useControlChannels } from '../../ControlChannels';
import { useTest } from '../../Tests/tests.hooks';
import { postStageActions, postTestActions, preStageActions, preTestActions } from '../../Actions/actions.const';
import { ActionObjectTypes, ActionOptions, ActionProperty, actionResponseConverter } from '../../Actions';

interface ITestActionsTableContainerProps {
	testId?: string;
	stationId?: string;
}

const TestActionsTableContainer: FC<ITestActionsTableContainerProps> = ({ testId }) => {
	const modalData = useModalData(testActionsModalId);
	const handleClose = useCallback(() => {
		Modal.hide(createEditTestActionsModalId);
	}, []);

	const { testDto } = useTest(testId);
	const { testMeasurements } = useTestMeasurements(testId);
	const { digitalOutputChannels } = useDigitalOutputChannels(testDto?.stationId);
	const { controlChannels } = useControlChannels(testDto?.stationId);

	const stageType = testActionLocationToStageTypes(modalData?.location);
	const { actions, isLoading, deleteAction, saveAction, moveAction, saveActions, inParallel, updateInParallel } =
		useCurrentStageActions(testId, modalData?.stageId, stageType, () => Modal.hide(testActionsModalId));
	const { testActions, isTestActionsLoading } = useTestExistingActions(testId, modalData?.stageId, stageType);

	const availableActionTypes: ActionOptions = useMemo(() => {
		if (modalData?.location === TestActionLocations.PRE_STAGE) return preStageActions;
		if (modalData?.location === TestActionLocations.POST_STAGE) return postStageActions;
		if (modalData?.location === TestActionLocations.PRE_TEST) return preTestActions;
		if (modalData?.location === TestActionLocations.POST_TEST) return postTestActions;
		return {};
	}, [modalData?.location]);

	const existingActions = useMemo(() => {
		if (testActions && actions) {
			const filteredTestActions = testActions.filter((rule) => {
				if (!rule.rulePositiveActions?.[0]) return false;

				const action = actionResponseConverter(rule.rulePositiveActions[0]);
				if (action.objectType === ActionObjectTypes.CALCULATION) {
					return (
						modalData?.location === TestActionLocations.PRE_TEST ||
						modalData?.location === TestActionLocations.POST_TEST
					);
				}
				if (
					action.objectType !== null &&
					action.objectType !== undefined &&
					availableActionTypes[action.objectType as ActionObjectTypes]
				) {
					const properties = availableActionTypes[action.objectType as ActionObjectTypes] as ActionProperty[];
					return properties.length === 0 || !!properties.includes(action.property as number);
				}
				return false;
			});
			return [...filteredTestActions, ...actions];
		}
	}, [actions, availableActionTypes, modalData?.location, testActions]);

	const enableCalculation =
		modalData.location !== TestActionLocations.PRE_STAGE && modalData.location !== TestActionLocations.POST_STAGE;
	const enableAction = modalData.location !== TestActionLocations.STAGE;

	const handleAction = useCallback(
		(type: TestActionsActionTypes, action?: RuleResponseDtoBase | RuleRequestDtoBase, index?: number, to?: number) => {
			switch (type) {
				case TestActionsActionTypes.CREATE_ACTION:
					Modal.show(createEditTestActionsModalId, {
						stageId: modalData?.stageId,
						actionLocation: modalData?.location,
						isAction: true
					});
					break;
				case TestActionsActionTypes.CREATE_CALCULATION:
					Modal.show(createEditTestActionsModalId, {
						stageId: modalData?.stageId,
						actionLocation: modalData?.location,
						isAction: false
					});
					break;
				case TestActionsActionTypes.DUPLICATE:
					Modal.show(createEditTestActionsModalId, {
						action: action,
						stageId: modalData?.stageId,
						actionLocation: modalData?.location,
						isCopy: true
					});
					break;
				case TestActionsActionTypes.EDIT:
					Modal.show(createEditTestActionsModalId, {
						index: index,
						action: actions[index as number],
						stageId: modalData?.stageId,
						actionLocation: modalData?.location,
						isCopy: false
					});
					break;
				case TestActionsActionTypes.DELETE:
					deleteAction(index as number);
					break;
				case TestActionsActionTypes.MOVE:
					moveAction(index as number, to as number);
					break;
			}
		},
		[actions, deleteAction, modalData?.location, modalData?.stageId, moveAction]
	);

	const save = useCallback(
		(action: RuleRequestDtoBase, index: number) => {
			saveAction(action, index);
			handleClose();
		},
		[handleClose, saveAction]
	);

	// Create mutation that saves the item update/create based on id.
	const onSubmit = useCallback(
		(e: React.FormEvent) => {
			e.preventDefault();
			saveActions();
		},
		[saveActions]
	);

	return (
		<>
			<Form onSubmit={onSubmit} id={testActionsTable} />
			<TestActionsTable
				measurements={testMeasurements || []}
				digitalChannels={digitalOutputChannels || []}
				controlChannels={controlChannels || []}
				onAction={handleAction}
				existingActions={existingActions || []}
				currentStageActions={actions}
				isLoadingCurrentStageActions={isLoading}
				isLoadingExistents={isTestActionsLoading}
				enableCalculation={enableCalculation}
				enableAction={enableAction}
				inParallel={inParallel}
				changeInParallel={updateInParallel}
			/>
			<TestActionsCreateEditContainer testId={testId} onClose={handleClose} onSave={save} actions={actions} />
		</>
	);
};

export default TestActionsTableContainer;
