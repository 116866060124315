export enum UnitCrudActionTypes {
	EDIT = 'EDIT_UNIT',
	CREATE = 'CREATE_UNIT',
	DUPLICATE = 'DUPLICATE_UNIT',
	DELETE = 'DELETE_UNIT'
}

export enum UnitSystemTypes {
	NONE = 0,
	METRIC,
	IMPERIAL
}
