import React, { ReactNode, useCallback } from 'react';
import classNames from 'classnames';

import styles from './CalculatorButton.module.scss';
import { CalculatorValueTypes } from '../../calculator.enums';

interface CalculatorButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'onClick' | 'type'> {
	onClick: (type?: CalculatorValueTypes, value?: string) => void;
	type?: CalculatorValueTypes;
	value?: string;
	children: ReactNode;
	className?: string;
	blueText?: boolean;
	whiteBack?: boolean;
	doubleWidth?: boolean;
}

const CalculatorButton: React.FC<CalculatorButtonProps> = ({
	children,
	className,
	blueText,
	whiteBack,
	doubleWidth,
	type,
	value,
	onClick,
	...rest
}) => {
	const onButtonClick = useCallback(() => {
		onClick(type, value);
	}, [type, value, onClick]);

	return (
		<button
			onClick={onButtonClick}
			className={classNames(styles.button, className, {
				[styles.blueText]: blueText,
				[styles.whiteBack]: whiteBack,
				[styles.doubleWidth]: doubleWidth
			})}
			{...rest}
			type="button"
		>
			{children}
		</button>
	);
};

export default CalculatorButton;
