import React, { useCallback } from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';

export interface IWarningModalProps {
	title?: string;
	children?: React.ReactNode;
	id: string;
	className?: string;
	confirmBtnText?: string;
	handleClose?: () => void;
}

interface IWarningModalSub {
	show: (modalId: string) => void;
	hide: (modalId: string) => void;
}

const WarningModal: React.FC<IWarningModalProps> & IWarningModalSub = ({
	className,
	children,
	id,
	title,
	confirmBtnText,
	handleClose
}) => {
	const { t } = useTranslation();

	const close = useCallback(() => {
		if (handleClose) {
			handleClose();
		} else {
			Modal.hide(id);
		}
	}, [handleClose, id]);

	return (
		<Modal id={id} onClose={handleClose}>
			<Modal.Header>{title}</Modal.Header>
			<Modal.Content className={className}>{children}</Modal.Content>
			<Modal.Footer>
				<Button onClick={close} color="secondary" variant="contained">
					{confirmBtnText ?? t('Ok')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

WarningModal.show = (modalId) => {
	Modal.show(modalId);
};
WarningModal.hide = (modalId) => {
	Modal.hide(modalId);
};

export default WarningModal;
