import { Nullable } from '../../types';
import {
	TriggerStationProperties,
	TriggerAuxDeviceProperties,
	TriggerConnectionProperties,
	TriggerDigitalInputProperties,
	TriggerMeasurementProperties,
	TriggerObjectTypes,
	TriggerTestProperties,
	TriggerSpecimenProperties,
	TriggerAxisProperties,
	TriggerDtoObjectTypes,
	TriggerMeasurementInOutRangeProperties,
	TriggerMeasurementMoreThanLessThanProperties,
	TriggerVariableProperties,
	TriggerUIButtonProperties
} from './triggers.enums';
import { VariablesTypes } from '../Variables';

export type TriggerProperty =
	| TriggerDigitalInputProperties
	| TriggerMeasurementProperties
	| TriggerTestProperties
	| TriggerConnectionProperties
	| TriggerAuxDeviceProperties
	| TriggerStationProperties
	| TriggerSpecimenProperties
	| TriggerAxisProperties
	| TriggerUIButtonProperties
	| TriggerVariableProperties;

export const triggerPropertyMap = {
	...TriggerDigitalInputProperties,
	...TriggerTestProperties,
	...TriggerConnectionProperties,
	...TriggerMeasurementProperties,
	...TriggerAuxDeviceProperties,
	...TriggerStationProperties,
	...TriggerSpecimenProperties,
	...TriggerAxisProperties
};

export type TriggerOptions = Partial<Record<TriggerObjectTypes, TriggerProperty[]>>;

export type RuleTrigger = Nullable<{
	objectType?: TriggerObjectTypes;
	objectId?: string;
	property?: TriggerProperty;
	lowerLimit?: number;
	upperLimit?: number;
	amplitudeChange?: number;
	maxMeanShift?: number;
	delayCycles?: number;
	changeCycles?: number;
	referenceCycles?: number;
	referenceAxis?: string;
	threshold?: number;
	dropPercentage?: number;
	stressMeasurementId?: string;
	strainMeasurementId?: string;
	thresholdMeasurementId?: string;
	variableValue?: string | number | boolean;
}> & {
	id?: string;
};

export interface DigitalInputTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.DIGITAL_INPUT;
	diProp: TriggerDigitalInputProperties;
	diChannelUuid: string;
}

export interface AuxDeviceTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.AUX_DEVICE;
	auxDeviceProp: TriggerAuxDeviceProperties;
	auxDeviceUuid: string;
}

export interface SpecimenBreakTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.SPECIMEN_BREAK;
	measurementUuid: string;
	threshold: number;
	dropPercentage: number;
}

export interface SpecimenYieldTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.SPECIMEN_YIELD;
	stressMeasurementUuid: string;
	strainMeasurementUuid: string;
	thresholdMeasurementUuid: string;
	threshold: number;
}

export interface MeasurementInOutRangeTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.MEASUREMENT_IN_OUT_RANGE;
	measurementUuid: string;
	condition: TriggerMeasurementInOutRangeProperties;
	upperLimit: number;
	lowerLimit: number;
}

export interface MeasurementMoreThanLessThanTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.MEASUREMENT_MORE_THAN_LESS_THAN;
	measurementUuid: string;
	condition: TriggerMeasurementMoreThanLessThanProperties;
	threshold: number;
}

export interface MeasurementAmplitudeChangeTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.MEASUREMENT_AMPLITUDE_CHANGE;
	measurementUuid: string;
	amplitudeChange: number;
	delayCycles: number;
	changeCycles: number;
	referenceCycles: number;
	axisUuid: string;
}

export interface MeasurementMeanChangeTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.MEASUREMENT_MEAN_CHANGE;
	measurementUuid: string;
	maxMeanShift: number;
	delayCycles: number;
	changeCycles: number;
	referenceCycles: number;
	axisUuid: string;
}

export interface TestTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.TEST;
	testState: TriggerTestProperties;
}

export interface ConnectionTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.CONNECTION;
	connectionState: TriggerConnectionProperties;
}

export interface AxisTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.AXIS;
	action: TriggerAxisProperties;
	axisUuid: string;
}

export interface StationTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.STATION;
	action: TriggerStationProperties;
}

export interface UIButtonTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.UI_BUTTON;
	buttonState: TriggerUIButtonProperties;
}

export interface VariableTriggerDto {
	uuid?: string;
	type: TriggerDtoObjectTypes.VARIABLE;
	condition: TriggerVariableProperties;
	varUuid: string;
	varType: VariablesTypes;
	value: string;
}

export type RuleTriggerDto =
	| DigitalInputTriggerDto
	| AuxDeviceTriggerDto
	| SpecimenBreakTriggerDto
	| SpecimenYieldTriggerDto
	| MeasurementInOutRangeTriggerDto
	| MeasurementMoreThanLessThanTriggerDto
	| MeasurementAmplitudeChangeTriggerDto
	| MeasurementMeanChangeTriggerDto
	| TestTriggerDto
	| ConnectionTriggerDto
	| AxisTriggerDto
	| StationTriggerDto
	| UIButtonTriggerDto
	| VariableTriggerDto;
