import { PageLayout, Button, Form, Checkbox, Dropdown, InputText, Modal } from '@tactun/ui';
import { FC, useCallback, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useNumberEnumList } from '../../../hooks';
import { IImageUploadResponse, useImageUploader } from '../../ImageUploader';
import { ReportFormat, ReportResultType } from '../testReportConfiguration.enums';
import {
	ReportSpecimenParams,
	TestReportCalculationFormType,
	TestReportConfigurationForm,
	VariableReportConfig
} from '../testReportConfiguration.types';
import { GeneralField, GeneralFieldProps } from './GeneralField';
import ResultsTable from './ResultsTable';
import CalculationReportConfigurationTable from './CalculationReportConfigurationTable';
import SpecimenReportConfigurationTable from './SpecimenReportConfigurationTable';
import VariablesReportConfigurationTable from './VariablesReportConfigurationTable';
import { TestInformationResponseDto } from '../../Tests';
import CompanyLogo from './assets/icon-logo-customer.svg';
import CompanyLogoSplash from './assets/icon-logo-customer-splash.svg';
import styles from './TestReportConfiguration.module.scss';

// import VariablesReportConfigurationTable from './VariablesReportConfigurationTable';

export interface TestReportConfigurationProps {
	form: UseFormReturn<TestReportConfigurationForm>;
	test: TestInformationResponseDto;
}

const TestReportConfiguration: FC<TestReportConfigurationProps> = ({ form, test }) => {
	const { t } = useTranslation('testConfiguration');

	const {
		control,
		setValue,
		watch,
		register,
		formState: { errors }
	} = form;

	const calculationFormValues = watch('calculationResults');
	const specimenFormValues = watch('specimenParamsResults');
	const variablesFormValues = watch('variableResults');
	const handleLogoChange = useCallback(
		(response: IImageUploadResponse | null) => {
			setValue('companyLogo', response?.url || '');
			setValue('companyLogoId', response?.id || '');
		},
		[setValue]
	);
	const companyLogoURL = watch('companyLogo');
	const { inputProps, draggableElementProps, onChangeClick, onDelete, displayedImage, ref } =
		useImageUploader<HTMLDivElement>(CompanyLogo, CompanyLogoSplash, handleLogoChange, companyLogoURL);

	const generalInformation = useMemo<GeneralFieldProps[]>(() => {
		return [
			{
				label: t('Test Name'),
				value: t('Test number one')
			},
			{
				label: t('Operator Name'),
				value: t('Operator name'),
				description: t('The system will use the name of the logged-in user who runs the test.')
			},
			{
				label: t('Report Date'),
				value: t('Dd/Mm/Yy, Hh:Mm:Ss'),
				description: t('The system will show the date when the test was executed in the format of Dd/Mm/Yy Hh:Mm:Ss.')
			},
			{
				label: t('Test Date'),
				value: t('Dd/Mm/Yy, Hh:Mm:Ss'),
				description: t('The system will show the date when the test was executed in the format of Dd/Mm/Yy Hh:Mm:Ss.')
			}
		];
	}, [t]);

	const formatOptions = useNumberEnumList(ReportFormat);

	const handleUpdateCalculations = (calculations: TestReportCalculationFormType[]) => {
		Modal.hide(ReportResultType.CALCULATION_RESULT);
		setValue('calculationResults', calculations);
	};

	const handleUpdateSpecimen = (specimenParamsResults: ReportSpecimenParams) => {
		Modal.hide(ReportResultType.SPECIMEN_RESULT);
		setValue('specimenParamsResults', specimenParamsResults);
	};

	const statuses = useMemo(
		() => ({
			calculations: !!calculationFormValues?.length,
			specimenParams: !!Object.keys(specimenFormValues)?.length,
			variables: !!variablesFormValues?.length
		}),
		[calculationFormValues, specimenFormValues, variablesFormValues]
	);

	const handleUpdateVariable = (variableResults: VariableReportConfig[]) => {
		Modal.hide(ReportResultType.VARIABLE_RESULT);
		setValue('variableResults', variableResults);
	};

	return (
		<>
			<PageLayout info={test.name}>
				<PageLayout.Header title={t('Report')} />
				<h3 className={styles.sectionLabel}>{t('General')}</h3>
				<div className={styles.logoContainer}>
					<input ref={ref} {...inputProps} />
					<div className="companyLogo" {...draggableElementProps}>
						<div>Company logo</div>
						<div className={styles.customerLogo} style={{ backgroundImage: `url("${displayedImage}")` }}></div>
					</div>
					<Button onClick={onChangeClick} label={t('Change image')} variant="text" color="secondary" />
					<Button onClick={onDelete} label={t('Remove')} variant="text" color="danger" />
				</div>

				<div className={styles.generalContainer}>
					{generalInformation.map((field, index) => (
						<GeneralField key={index} {...field} />
					))}
				</div>

				<h3 className={styles.sectionLabel}>{t('Additional Information')}</h3>

				<div className={styles.subsectionLabel}>{t('Include in the Report')}</div>
				<Form>
					<div className={styles.additionalContainer}>
						<input type="hidden" {...register('companyLogoId')} />
						<Controller
							name="additionalInformation.includeTestStandard"
							control={control}
							render={({ field }) => (
								<Checkbox
									label={t('Test Standard')}
									{...field}
									checked={!!field.value}
									className={styles.checkboxCont}
								/>
							)}
						/>
						<Controller
							name="additionalInformation.includeTestDescription"
							control={control}
							render={({ field }) => (
								<Checkbox
									label={t('Test Description')}
									{...field}
									checked={!!field.value}
									className={styles.checkboxCont}
								/>
							)}
						/>
						<Controller
							name="additionalInformation.includeSpecimenType"
							control={control}
							render={({ field }) => (
								<Checkbox
									label={t('Specimen Type')}
									{...field}
									checked={!!field.value}
									className={styles.checkboxCont}
								/>
							)}
						/>
						<Controller
							name="additionalInformation.includeMaterial"
							control={control}
							render={({ field }) => (
								<Checkbox
									label={t('Specimen Material')}
									{...field}
									checked={!!field.value}
									className={styles.checkboxCont}
								/>
							)}
						/>
						<Controller
							name="additionalInformation.includeSpecimenImage"
							control={control}
							render={({ field }) => (
								<Checkbox
									label={t('Specimen Image')}
									{...field}
									checked={!!field.value}
									className={styles.checkboxCont}
								/>
							)}
						/>
						<Controller
							name="additionalInformation.includeBatch"
							control={control}
							render={({ field }) => (
								<Checkbox
									label={t('Specimen Batch')}
									{...field}
									checked={!!field.value}
									className={styles.checkboxCont}
								/>
							)}
						/>
						<Controller
							name="additionalInformation.includeGraphData"
							control={control}
							render={({ field }) => (
								<Checkbox label={t('Graph Data')} {...field} checked={!!field.value} className={styles.checkboxCont} />
							)}
						/>
					</div>

					<InputText
						{...register('additionalInformation.customerInformation')}
						textarea
						label={t('Customer information')}
						error={errors.additionalInformation?.customerInformation?.message}
						className={cx(styles.fullWidthField, styles.paddingTop)}
					/>

					<div className={styles.fullWidthField}>
						<ResultsTable statuses={statuses} />
						<CalculationReportConfigurationTable onSubmit={handleUpdateCalculations} values={calculationFormValues} />
						<SpecimenReportConfigurationTable values={specimenFormValues} onSubmit={handleUpdateSpecimen} />
						<VariablesReportConfigurationTable values={variablesFormValues} onSubmit={handleUpdateVariable} />
					</div>
				</Form>
				<div className={cx(styles.container, styles.paddingTop)}>
					<Controller
						control={control}
						name="fileOutput.outputFormat"
						render={({ field }) => <Dropdown {...field} label={t('Report format*')} options={formatOptions} />}
					/>
					<Controller
						control={control}
						name="fileOutput.outputLocation"
						render={({ field }) => (
							<InputText {...field} label={t('Saving location*')} error={errors.fileOutput?.outputLocation?.message} />
						)}
					/>
				</div>
			</PageLayout>
		</>
	);
};

export default TestReportConfiguration;
