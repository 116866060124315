import { memo, ButtonHTMLAttributes } from 'react';
import type { FC } from 'react';
import { Icon } from '@tactun/ui';
import cx from 'classnames';
import styles from './AddButton.module.scss';

export interface AddButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
	label?: string;
	isError?: boolean;
}

const AddButton: FC<AddButtonProps> = ({ className, label, isError = false, ...buttonProps }) => {
	return (
		<button
			data-testid="ruleaddBtn"
			className={cx(styles.addButton, className, { [styles.error]: isError, [styles.disabled]: buttonProps.disabled })}
			{...buttonProps}
		>
			<Icon name="plus" size={32} />
			{label && <span className={styles.label}>{label}</span>}
		</button>
	);
};

export default memo(AddButton);
