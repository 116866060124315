import React, { useMemo } from 'react';
import { Checkbox, Dropdown, Form, Switch } from '@tactun/ui';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IListItem } from '../../../../types';
import { ControlChannelResponseDto } from '../../../ControlChannels';
import WaveformFormView, { WaveformFormErrors } from '../../../Waveforms';
import { TestAxesConfigurationFormType } from '../../testAxesConfiguration.type';
import { testAxesConfigurationModalId } from '../../testAxesConfiguration.const';
import ControlAlgorithmsContainer, { ControlAlgorithmsAdditional } from '../../../ControlAlgorithms';
import { analogOutChannels, currentBasedAnalogOutChannels } from '../../../StationChannels/stationChannels.const';
import { IAxisResponseDto } from '../../../Axes';
import styles from './TestAxesConfigurationForm.module.scss';

interface TestAxesConfigurationFormProps {
	controlChannel?: ControlChannelResponseDto;
	controlChannels: IListItem[];
	testId: string;
	axisDto?: IAxisResponseDto;
	onSave: (data: TestAxesConfigurationFormType) => void;
}

const TestAxesConfigurationForm: React.FC<TestAxesConfigurationFormProps> = ({
	controlChannels,
	controlChannel,
	testId,
	axisDto,
	onSave
}) => {
	const { t } = useTranslation('testAxesConfiguration');
	const {
		register,
		control,
		formState: { errors },
		handleSubmit,
		watch
	} = useFormContext<TestAxesConfigurationFormType>();
	const isAxisBasedOnAnalogOut = useMemo(() => {
		const type = axisDto?.actuator?.stationChannel?.type;
		return type !== undefined && analogOutChannels.includes(type);
	}, [axisDto]);

	const additionalAlgorithms = useWatch({ control, name: 'controlAlgorithmsForm.additionalAlgorithms' });
	const isAdaptive =
		additionalAlgorithms?.includes(ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE) ||
		additionalAlgorithms?.includes(ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN);

	const ccId = watch('ccId');
	const useStationParams = watch('useStationParams');

	return (
		<Form className={styles.container} onSubmit={handleSubmit(onSave)} id={testAxesConfigurationModalId}>
			<input type="hidden" {...register('stageId')} />
			<input type="hidden" {...register('axisId')} />
			<Controller
				name={'ccId'}
				control={control}
				render={({ field }) => (
					<Dropdown
						className={styles.dropdown}
						{...field}
						options={controlChannels}
						label={t('Control name*')}
						data-testid="controlChannelId"
						error={errors.ccId?.message}
						filter
					/>
				)}
			/>

			<Controller
				name={'useStationParams'}
				control={control}
				render={({ field }) => (
					<Switch
						label={t('Use station parameters')}
						inputId={field.name}
						data-testid="useStationParams"
						onChange={(e) => field.onChange(e.value)}
						checked={!!field.value}
					/>
				)}
			/>

			{controlChannel && (
				<>
					<ControlAlgorithmsContainer
						formPrefix="controlAlgorithmsForm"
						testId={testId}
						ccId={ccId}
						isControlWithCurrent={currentBasedAnalogOutChannels.some(
							(t) => t === controlChannel.axis.actuator.stationChannel?.type
						)}
						isAxisBasedOnAnalogOut={isAxisBasedOnAnalogOut}
						isOpenLoopAvailable={isAxisBasedOnAnalogOut}
						isDisabled={useStationParams}
						errors={errors.controlAlgorithmsForm}
						// providing isControlStage tru to disable control type dropdown
						isControlStage
					/>
					<Form.Divider />
					<WaveformFormView
						formPrefix={'wf'}
						controlChannelId={controlChannel?.id}
						errors={errors.wf ? (errors.wf as WaveformFormErrors) : undefined}
						testId={testId}
						isAdaptive={isAdaptive}
					/>
					<Form.Divider />
				</>
			)}
			<div className={styles.completeStage}>
				<Controller
					name="isFinishStageTrigger"
					control={control}
					render={({ field }) => (
						<Checkbox
							data-testid="completeStage"
							label={t('Complete the stage once the axis is completed')}
							{...field}
							checked={!!field.value}
						/>
					)}
				/>
			</div>
		</Form>
	);
};

export default TestAxesConfigurationForm;
