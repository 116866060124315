import { Form, InputText, Dropdown } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller, useWatch } from 'react-hook-form';
import { ActuatorFormType } from '../../actuators.types';
import { IListItem, IListItemNumber } from '../../../../types';
import { useExcludeOptions } from '../../../../hooks/useExcludeOptions';
import styles from './ActuatorForm.module.scss';

interface IActuatorFormProps {
	formId: string;
	form: UseFormReturn<ActuatorFormType>;
	types: IListItemNumber[];
	stationChannels: IListItem[];
	manufacturerOptions: IListItem[];
	enableBitsOptions: IListItem[];
	onSave: () => void;
}

const ActuatorForm: React.FC<IActuatorFormProps> = ({
	formId,
	form,
	types,
	stationChannels,
	manufacturerOptions,
	enableBitsOptions,
	onSave
}) => {
	const { t } = useTranslation('actuators');
	const {
		control,
		formState: { errors },
		register
	} = form;
	const s1EnableBitChIds = useWatch({ name: 's1EnableBitChIds', control });
	const s2EnableBitChIds = useWatch({ name: 's2EnableBitChIds', control });

	const s1EnableBitOptions = useExcludeOptions(enableBitsOptions, s2EnableBitChIds);
	const s2EnableBitOptions = useExcludeOptions(enableBitsOptions, s1EnableBitChIds);

	return (
		<Form className={styles.container} id={formId} onSubmit={onSave}>
			<input type="hidden" {...register('stationId')} />
			<Controller
				name="name"
				control={control}
				render={({ field }) => (
					<InputText data-testid="nameId" label={t('Name*')} {...field} error={errors.name?.message} />
				)}
			/>
			<Controller
				name="type"
				control={control}
				render={({ field }) => (
					<Dropdown
						data-testid="type"
						{...field}
						options={types}
						label={t('Type*')}
						error={errors.type?.message}
						filter
					/>
				)}
			/>
			<Controller
				name="manufacturer"
				control={control}
				render={({ field }) => (
					<Dropdown
						data-testid="manufacturer"
						label={t('Manufacturer')}
						{...field}
						options={manufacturerOptions}
						error={errors.manufacturer?.message}
						editable
						itemName={t('Manufacturer')}
					/>
				)}
			/>
			<Controller
				name="serialNumber"
				control={control}
				render={({ field }) => (
					<InputText
						data-testid="serialNumber"
						label={t('Serial number')}
						{...field}
						error={errors.serialNumber?.message}
					/>
				)}
			/>
			<Controller
				name="stationChannelId"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						options={stationChannels}
						data-testid="stationChannelId"
						label={t('Channel*')}
						error={errors.stationChannelId?.message}
						filter
					/>
				)}
			/>
			<Controller
				name="s1EnableBitChIds"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						options={s1EnableBitOptions}
						data-testid="s1EnableBitChId"
						label={t('First stage enable bit')}
						error={errors.s1EnableBitChIds?.message}
						filter
						isMulti
					/>
				)}
			/>
			<Controller
				name="s2EnableBitChIds"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						options={s2EnableBitOptions}
						data-testid="s2EnableBitChId"
						label={t('Second stage enable bit')}
						error={errors.s2EnableBitChIds?.message}
						filter
						isMulti
					/>
				)}
			/>
		</Form>
	);
};

export default ActuatorForm;
