import { SpecimenStatusesDto } from './specimens.enums';
import { SpecimenStatusInfo } from './specimens.types';

export const specimenCreateEditFormId = 'specimenCreateEditFormId';

export const specimenStatusInfo: Record<SpecimenStatusesDto, SpecimenStatusInfo> = {
	[SpecimenStatusesDto.TESTED]: { className: 'tested', label: 'Tested' },
	[SpecimenStatusesDto.NOT_TESTED]: { className: 'notTested', label: 'Not Tested' },
	[SpecimenStatusesDto.TEST_IN_PROGRESS]: { className: 'inProgress', label: 'Test in progress' },
	[SpecimenStatusesDto.BROKEN]: { className: 'broken', label: 'Broken' }
};

export const Materials: { name: string; id: string }[] = [
	{ id: 'iron', name: 'Iron' },
	{ id: 'rubber', name: 'Rubber' },
	{ id: 'limestone', name: 'Limestone' },
	{ id: 'concrete', name: 'Concrete' },
	{ id: 'plastic', name: 'Plastic' },
	{ id: 'glass', name: 'Glass' }
];
