// package: device_utils
// file: device_utils.proto

var device_utils_pb = require("./device_utils_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var DeviceInfo = (function () {
  function DeviceInfo() {}
  DeviceInfo.serviceName = "device_utils.DeviceInfo";
  return DeviceInfo;
}());

DeviceInfo.update_hostname = {
  methodName: "update_hostname",
  service: DeviceInfo,
  requestStream: false,
  responseStream: false,
  requestType: device_utils_pb.UpdateDeviceHostnameRequest,
  responseType: common_pb.Void
};

DeviceInfo.get_hostname = {
  methodName: "get_hostname",
  service: DeviceInfo,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Void,
  responseType: device_utils_pb.GetHostnameResponse
};

DeviceInfo.reboot = {
  methodName: "reboot",
  service: DeviceInfo,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Void,
  responseType: common_pb.Void
};

DeviceInfo.heartbeat = {
  methodName: "heartbeat",
  service: DeviceInfo,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Void,
  responseType: common_pb.Void
};

DeviceInfo.change_ip = {
  methodName: "change_ip",
  service: DeviceInfo,
  requestStream: false,
  responseStream: false,
  requestType: device_utils_pb.ChangeIpRequest,
  responseType: common_pb.Void
};

DeviceInfo.enable_dhcp = {
  methodName: "enable_dhcp",
  service: DeviceInfo,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Void,
  responseType: common_pb.Void
};

DeviceInfo.get_network_config = {
  methodName: "get_network_config",
  service: DeviceInfo,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.Void,
  responseType: device_utils_pb.DeviceNetworkResponse
};

DeviceInfo.StreamDeviceLogs = {
  methodName: "StreamDeviceLogs",
  service: DeviceInfo,
  requestStream: false,
  responseStream: true,
  requestType: device_utils_pb.DeviceLogRequest,
  responseType: device_utils_pb.DeviceLogResponse
};

exports.DeviceInfo = DeviceInfo;

function DeviceInfoClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

DeviceInfoClient.prototype.update_hostname = function update_hostname(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceInfo.update_hostname, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceInfoClient.prototype.get_hostname = function get_hostname(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceInfo.get_hostname, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceInfoClient.prototype.reboot = function reboot(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceInfo.reboot, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceInfoClient.prototype.heartbeat = function heartbeat(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceInfo.heartbeat, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceInfoClient.prototype.change_ip = function change_ip(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceInfo.change_ip, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceInfoClient.prototype.enable_dhcp = function enable_dhcp(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceInfo.enable_dhcp, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceInfoClient.prototype.get_network_config = function get_network_config(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceInfo.get_network_config, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceInfoClient.prototype.streamDeviceLogs = function streamDeviceLogs(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(DeviceInfo.StreamDeviceLogs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.DeviceInfoClient = DeviceInfoClient;

