import { FC, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import ControllersTable from '../components/ControllersTable';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { getControllers } from '../controllers.api';
import { ControllerCrudActionTypes } from '../controllers.enums';
import { IControllerListItem } from '../controllers.types';
import { controllerDtoToListItems } from '../controllers.converters';

const ControllersTableContainer: FC = () => {
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<IControllerListItem | undefined>();
	const queryClient = useQueryClient();

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			queryClient.setQueryData<{ id: string }[]>(['controllers'], (old) => {
				if (!old) return undefined;
				return old.filter((hc) => hc.id !== id);
			});
		},
		[queryClient]
	);

	const { data = [], isLoading } = useQuery<IControllerListItem[], Error>({
		queryKey: ['controllers'],
		queryFn: async () => {
			const resp = await getControllers();
			return resp.map((c) => controllerDtoToListItems(c));
		}
	});

	const handleAction = useCallback(
		(type: ControllerCrudActionTypes, data?: IControllerListItem) => {
			switch (type) {
				case ControllerCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case ControllerCrudActionTypes.EDIT:
					navigate(`./edit/${data?.id}`);
					break;
				case ControllerCrudActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case ControllerCrudActionTypes.DELETE:
					if (data) {
						setEntityForDelete(data);
					}
					break;
			}
		},
		[navigate]
	);

	return (
		<>
			<ControllersTable onAction={handleAction} data={data} isLoading={isLoading} />
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.CONTROLLERS}
				entity={entityForDelete as IEntity}
			/>
		</>
	);
};

export default ControllersTableContainer;
