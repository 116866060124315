export const generalInfoPath = '/stations/:stationId';
export const generalInfoCreatePath = '/stations/create';
export const stationChannelsPath = '/stations/:stationId/station-channels';
export const sensorsPath = '/stations/:stationId/sensors';
export const actuatorsPath = '/stations/:stationId/actuators';
export const axesPath = '/stations/:stationId/axes';
export const controlChannelsPath = '/stations/:stationId/control-channels';
export const calculationsPath = '/stations/:stationId/calculations';
export const variablesPath = '/stations/:stationId/variables';
export const measurementsPath = '/stations/:stationId/measurements';
export const auxiliaryDevicesPath = '/stations/:stationId/auxiliary-devices';
export const stationRulesPath = '/stations/:stationId/rules';
export const widgetsPath = '/stations/:stationId/widgets';
// export const permissionsPath = '/stations/:stationId/permissions';
