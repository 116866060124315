import React from 'react';
import { Form, InputText, Dropdown } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { AuxiliaryDeviceFormType } from '../../auxiliaryDevice.types';
import { IListItem, IListItemNumber } from '../../../../types';
import { AuxiliaryDeviceType } from '../../auxiliaryDevice.enums';
import styles from './AuxiliaryDeviceForm.module.scss';

interface IAuxiliaryDeviceFormProps {
	formId: string;
	form: UseFormReturn<AuxiliaryDeviceFormType>;
	stationChannelOptions: IListItem[];
	typesOptions: IListItemNumber[];
	axisOptions: IListItem[];
	directionOptions: IListItemNumber[];
	onSave: () => void;
}

const AuxiliaryDeviceForm: React.FC<IAuxiliaryDeviceFormProps> = ({
	formId,
	form,
	stationChannelOptions,
	typesOptions,
	axisOptions,
	directionOptions,
	onSave
}) => {
	const { t } = useTranslation('auxiliaryDevice');
	const {
		control,
		formState: { errors },
		watch,
		register
	} = form;
	const type = watch('type');

	return (
		<Form className={styles.container} onSubmit={onSave} id={formId}>
			<input type="hidden" {...register('stationId')} />
			<input type="hidden" {...register('id')} />
			<Controller
				name="name"
				control={control}
				render={({ field }) => (
					<InputText data-testid="nameId" label={t('Auxiliary device name*')} {...field} error={errors.name?.message} />
				)}
			/>
			<Controller
				name="type"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						data-testid="type"
						options={typesOptions}
						label={t('Auxiliary device type*')}
						error={errors.type?.message}
						filter
					/>
				)}
			/>
			{type === AuxiliaryDeviceType.MECHANICAL_LIMITS && (
				<>
					<Controller
						name="axisId"
						control={control}
						render={({ field }) => (
							<Dropdown
								data-testid="axisId"
								{...field}
								label={t('Axis*')}
								options={axisOptions}
								error={errors.axisId?.message}
								filter
							/>
						)}
					/>
					<Controller
						name="direction"
						control={control}
						render={({ field }) => (
							<Dropdown
								data-testid="direction"
								{...field}
								label={t('Direction*')}
								options={directionOptions}
								error={errors.direction?.message}
								filter
							/>
						)}
					/>

					<Controller
						name="channelId"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								data-testid="channelId"
								label={t('Digital channel*')}
								options={stationChannelOptions}
								error={errors.channelId?.message}
								filter
							/>
						)}
					/>
				</>
			)}
			{type === AuxiliaryDeviceType.PHYSICAL_E_STOP && (
				<Controller
					name="channelId"
					control={control}
					render={({ field }) => (
						<Dropdown
							data-testid="channelId"
							{...field}
							label={t('Digital channel*')}
							options={stationChannelOptions}
							error={errors.channelId?.message}
							filter
						/>
					)}
				/>
			)}
		</Form>
	);
};

export default AuxiliaryDeviceForm;
