import { IProductListItem, IProductRequestDto, IProductResponseDto, ProductFormType } from './products.types';

export const productDtoToListItems = (response: IProductResponseDto): IProductListItem => {
	return {
		id: response.uuid,
		name: response.productName,
		model: response.controller.model,
		serialNumber: response.controller.serialNumber,
		stationName: response.station.name,
		stationId: response.station.id,
		signature: response.controller.signature,
		version: response.productVersion,
		createDate: response.createdAt,
		status: response.readinessStatus
	};
};

export const productResponseToForm = (response: IProductResponseDto): ProductFormType => {
	return {
		id: response.uuid,
		name: response.productName,
		controllerSerialNumber: response.controller.serialNumber,
		stationId: response.station.id,
		model: response.controller.model,
		version: response.productVersion,
		platform: response.appPlatform
	};
};

export const productFormToRequest = (form: ProductFormType): IProductRequestDto => ({
	uuid: form.id,
	productName: form.name,
	stationUuid: form.stationId,
	controllerSerialNumber: form.controllerSerialNumber || '',
	productVersion: form.version,
	appPlatform: form.platform
});
