import { useRef } from 'react';
import { Menu, MenuProps } from 'primereact/menu';
import cx from 'classnames';
import Button from '../FormElements/Button';
import styles from './KebabMenu.module.scss';
export interface IKebabMenuProps extends MenuProps {
	className?: string;
	isVertical?: boolean;
}

const KebabMenu: React.FC<IKebabMenuProps> = ({ className, isVertical = false, ...rest }) => {
	const menu = useRef<Menu>(null);

	return (
		<>
			<Menu popup ref={menu} {...rest} className={cx(styles.field, className)} />
			<Button
				variant="text"
				color="primary"
				icon={isVertical ? 't-icon-properties-vertical' : 't-icon-properties-horizontal'}
				className={styles.button}
				onClick={(event) => {
					event.preventDefault();
					menu?.current?.toggle(event);
				}}
			/>
		</>
	);
};

KebabMenu.displayName = 'KebabMenu';

export default KebabMenu;
