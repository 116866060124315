import axios from '../../httpCommon';
import { IStationChannelResponseDto } from '../StationChannels';
import { CalibrationRequestDto, CalibrationResponseDto } from './calibrations.types';

const url = '/quasar/calibrations';

export const getCalibrationsForStation = async (stationId: string) => {
	const response = await axios.get(`/calibrations/station/${stationId}`);

	return response.data as CalibrationResponseDto[];
};

export const createCalibration = async (calibration: CalibrationRequestDto) => {
	const response = await axios.post(url, calibration);
	return response.data;
};

export const updateCalibration = async (calibration: CalibrationRequestDto, calibrationId: string) => {
	const response = await axios.put(`${url}/${calibrationId}`, calibration);
	return response.data;
};

export const getCalibration = async (id: string) => {
	const response = await axios.get(`${url}/${id}`);

	return response.data as CalibrationResponseDto;
};

export const getAvailableStationChannels = async (stationId: string, type: string) => {
	const response = await axios.get(`${url}/available-hardware-channels/${stationId}/${type}`);

	return response.data as IStationChannelResponseDto[];
};

export const getSensorCalibration = async (sensorId: string) => {
	const response = await axios.get(`${url}/sensor/${sensorId}`);

	return response.data?.length ? (response.data[0] as CalibrationResponseDto) : null;
};
