import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { ITableColumns } from '../../../../types';
import { TestLoopsActionTypes } from '../../testLoops.enums';
import { TestLoopListItem } from '../../testLoops.types';

interface LoopsTableProps {
	onAction: (type: TestLoopsActionTypes, data?: TestLoopListItem, index?: number) => void;
	data?: TestLoopListItem[];
	columns: ITableColumns[];
	isLoading: boolean;
}

const LoopsTable: FC<LoopsTableProps> = ({ data, isLoading, columns, onAction }) => {
	const { t } = useTranslation('testLoops');

	const createMenuItems = useCallback(
		(data: any, index: number) => [
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(TestLoopsActionTypes.DELETE, data, index)
			}
		],
		[onAction, t]
	);

	const columnComponents = columns.map((col) => <DataTable.Column sortable resizeable key={col.field} {...col} />);

	return (
		<DataTable value={data} loading={isLoading} dataKey="id" showGridlines columnResizeMode="fit">
			<DataTable.Column
				header={t('N')}
				body={(rowData, { rowIndex }) => String(rowIndex + 1)}
				exportable={false}
				align="right"
				headerStyle={{ width: '3em' }}
			/>
			{columnComponents}
			<DataTable.Column
				body={(rowData) => (
					<IconButton
						icon="edit"
						fontSize="1.2rem"
						title={t('Edit')}
						onClick={() => onAction(TestLoopsActionTypes.EDIT, rowData)}
					/>
				)}
				exportable={false}
				align="right"
				headerStyle={{ width: '3em' }}
			/>
			<DataTable.Column
				body={(rowData, { rowIndex }) => <KebabMenu model={createMenuItems(rowData, rowIndex)} />}
				exportable={false}
				align="right"
				headerStyle={{ width: '3em' }}
			/>
		</DataTable>
	);
};

export default React.memo(LoopsTable);
