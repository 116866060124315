import { useCallback } from 'react';
import { DataTable, KebabMenu, RadioButton } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@tactun/ui';
import { UnitFormType } from '../../units.types';
import styles from './UnitTypeTable.module.scss';

interface UnitTypeTableProps {
	name: string;
	units: UnitFormType[];
	onDeleteUnit: (unit: UnitFormType) => void;
	onEditUnit: (unit: UnitFormType) => void;
	onDefaultChange: (unit: UnitFormType) => void;
	isSystemQuantity: boolean;
}

const UnitTypeTable: React.FC<UnitTypeTableProps> = ({
	name,
	units,
	isSystemQuantity,
	onDeleteUnit,
	onEditUnit,
	onDefaultChange
}) => {
	const { t } = useTranslation('units');

	const createMenuItems = useCallback(
		(data: UnitFormType) => [
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onDeleteUnit(data),
				disabled: data.isDefault
			}
		],
		[t, onDeleteUnit]
	);

	return (
		<div className={styles.container}>
			<label className={styles.label}>{name}</label>
			<DataTable value={units} dataKey="id" showGridlines>
				<DataTable.Column field="name" header={t('Unit')} />
				<DataTable.Column field="scale" header={t('Scale')} />
				<DataTable.Column field="offset" header={t('Offset')} />
				<DataTable.Column
					body={(rowData) => {
						return (
							<RadioButton
								name="isDefault"
								checked={rowData.isDefault}
								onChange={() => {
									onDefaultChange(rowData);
								}}
							/>
						);
					}}
					field="isDefault"
					header={t('Default')}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton icon="edit" fontSize="1.2rem" title={t('Edit')} onClick={() => onEditUnit(rowData)} />
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				{!isSystemQuantity && (
					<DataTable.Column
						body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
						exportable={false}
						align="right"
						headerStyle={{ width: '1em' }}
					/>
				)}
			</DataTable>
		</div>
	);
};

export default UnitTypeTable;
