import React, { useCallback, useMemo } from 'react';
import { IconButton } from '@tactun/ui';
import { PageLayout } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { CrudActions, TableFilters } from '../../../../components';
import { IStationChannel, StationChannelListItem } from '../../stationChannels.types';
import { StationChannelCrudActionTypes } from '../../stationChannels.enums';
import { ITableColumns } from '../../../../types';
import { useTableConfigs } from '../../../../hooks';
import { GroupTableCell } from '../../../Group';
import { useIsLocalDeployment } from '../../../../hooks/useIsLocalDeployment';

interface IStationChannelsTableProps {
	onAction: (type: StationChannelCrudActionTypes, data?: IStationChannel) => void;
	data?: StationChannelListItem[];
	isLoading: boolean;
	stationName: string;
}

const StationChannelsTable: React.FC<IStationChannelsTableProps> = ({ data, stationName, isLoading, onAction }) => {
	const { t } = useTranslation('stationChannel');
	const isDisabled = useIsLocalDeployment();
	const formattedData = useMemo(() => data?.map((item) => ({ ...item, type: t(item.type) })), [data, t]);

	const transColumns = useMemo<ITableColumns<StationChannelListItem>[]>(() => {
		const columns = [
			{
				field: 'type',
				header: t('Type')
			},
			{ field: 'channelName', header: t('Controller channel number') }
		] as ITableColumns<StationChannelListItem>[];

		if (formattedData?.some((d) => d.groups && d.groups.length > 0)) {
			columns.push({
				field: 'group',
				header: t('Group'),
				sortable: false,
				body: (data: StationChannelListItem) => <GroupTableCell groups={data.groups} />
			});
		}

		return columns;
	}, [formattedData, t]);

	const {
		filter,
		selected,
		columnComponents,
		dataFilteredByGroups,
		handleSelectionChange,
		handleFilterChange,
		handleCancel
	} = useTableConfigs<StationChannelListItem>(transColumns, formattedData);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(StationChannelCrudActionTypes.DELETE, data),
				disabled: isDisabled
			}
		],
		[t, isDisabled, onAction]
	);

	const handleCreate = useCallback(() => {
		onAction(StationChannelCrudActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={stationName}>
			<PageLayout.Header title="Channels">
				<CrudActions onCreate={handleCreate} isCreateDisabled={isDisabled} entityLabel={t('Channel')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={dataFilteredByGroups}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(StationChannelCrudActionTypes.EDIT, rowData)}
							disabled={isDisabled}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="duplicate"
							fontSize="1.2rem"
							title={t('Duplicate')}
							disabled
							onClick={() => onAction(StationChannelCrudActionTypes.DUPLICATE, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default React.memo(StationChannelsTable);
