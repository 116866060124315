import { useQuery, useQueryClient } from '@tanstack/react-query';
import { WidgetResponseDto } from './widgetsConfiguration.types';
import { WidgetsTypes } from './widgetsConfiguration.enums';
import * as api from './widgetsConfiguration.api';
import { getAxesForStation } from '../Axes';
import { axesToControlAxisConfig } from './widgetsConfiguration.converters';

export const useWidgets = (stationId?: string) => {
	const { data: widgets, isLoading: isWidgetsLoading } = useQuery({
		queryKey: ['widgets', { stationId }],
		queryFn: () => api.getStationWidgets(stationId as string),
		enabled: !!stationId
	});

	const queryClient = useQueryClient();

	const updateWidgets = () => {
		queryClient.invalidateQueries({ queryKey: ['widgets', { stationId }] });
	};

	return {
		widgets,
		isWidgetsLoading,
		updateAxes: updateWidgets
	};
};

export const useWidget = (widgetId?: string, stationId?: string) => {
	const { data: widget, isLoading: isWidgetLoading } = useQuery<WidgetResponseDto | undefined>({
		queryKey: ['widget', widgetId],
		queryFn: async (): Promise<WidgetResponseDto> => {
			const response = await api.getWidget(widgetId as string);

			if (response.widget?.type === WidgetsTypes.CONTROL_AXIS) {
				const axes = await getAxesForStation(stationId);
				const { widget } = response;
				return {
					...response,
					widget: {
						type: WidgetsTypes.CONTROL_AXIS,
						items: axes.map((axis) => {
							const existingConfig = widget.items?.find(({ axisUuid }) => axisUuid === axis.id);
							return axesToControlAxisConfig(axis, existingConfig, axes.length > 1);
						})
					}
				};
			}

			return response;
		},
		enabled: !!widgetId
	});

	const queryClient = useQueryClient();

	const updateWidget = () => {
		queryClient.invalidateQueries({ queryKey: ['widget', { widgetId }] });
	};

	return {
		widget,
		isWidgetLoading,
		updateWidget
	};
};
