export enum StatusLabelTypes {
	YELLOW = 'yellow',
	GRAY = 'gray',
	PRIMARY = 'primary',
	SUCCESS = 'success',
	WARNING = 'warning'
}

export enum StatusLabelSizes {
	SMALL = 'small',
	STANDARD = 'standard'
}
