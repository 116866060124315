import { FC, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import CustomersTable from '../components/CustomersTable';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { getCustomers } from '../customers.api';
import { CustomerCrudActionTypes } from '../customers.enums';

const CustomersTableContainer: FC = () => {
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<Record<string, string> | undefined>();
	const queryClient = useQueryClient();

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			queryClient.setQueryData<{ id: string }[]>(['customers'], (old) => {
				if (!old) return undefined;
				return old.filter((hc) => hc.id !== id);
			});
		},
		[queryClient]
	);

	const { data, isLoading } = useQuery<Record<string, string>[], Error>({
		queryKey: ['customers'],
		queryFn: async () => {
			const resp = await getCustomers();
			return resp;
		}
	});

	const handleAction = useCallback(
		(type: CustomerCrudActionTypes, data?: Record<string, string>) => {
			switch (type) {
				case CustomerCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case CustomerCrudActionTypes.EDIT:
					navigate(`./edit/${data?.id}`);
					break;
				case CustomerCrudActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case CustomerCrudActionTypes.OPEN_ORDER_HISTORY:
					navigate('./order-history');
					break;
				case CustomerCrudActionTypes.DELETE:
					if (data) {
						setEntityForDelete(data);
					}
					break;
			}
		},
		[navigate]
	);

	return (
		<>
			<CustomersTable onAction={handleAction} data={data} isLoading={isLoading} />
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.AXES}
				entity={entityForDelete as unknown as IEntity}
			/>
		</>
	);
};

export default CustomersTableContainer;
