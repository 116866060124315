// source: system.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var rule_pb = require('./rule_pb.js');
goog.object.extend(proto, rule_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var error_pb = require('./error_pb.js');
goog.object.extend(proto, error_pb);
goog.exportSymbol('proto.system.ActuatorRequest', null, global);
goog.exportSymbol('proto.system.ActuatorType', null, global);
goog.exportSymbol('proto.system.ActuatorTypeDcPwm', null, global);
goog.exportSymbol('proto.system.ActuatorTypePiezoDriver', null, global);
goog.exportSymbol('proto.system.ActuatorTypeServoElectric', null, global);
goog.exportSymbol('proto.system.ActuatorTypeServoHydraulic', null, global);
goog.exportSymbol('proto.system.ActuatorTypeStepper', null, global);
goog.exportSymbol('proto.system.AuxDeviceRequest', null, global);
goog.exportSymbol('proto.system.AuxiliaryDeviceType', null, global);
goog.exportSymbol('proto.system.AveragingFilterPoints', null, global);
goog.exportSymbol('proto.system.AxisRequest', null, global);
goog.exportSymbol('proto.system.AxisType', null, global);
goog.exportSymbol('proto.system.CalibrationSensorRequest', null, global);
goog.exportSymbol('proto.system.DataInType', null, global);
goog.exportSymbol('proto.system.DataInValueType', null, global);
goog.exportSymbol('proto.system.DcPwmChannelType', null, global);
goog.exportSymbol('proto.system.FormulaType', null, global);
goog.exportSymbol('proto.system.GenericSensorChannelType', null, global);
goog.exportSymbol('proto.system.HashedStation', null, global);
goog.exportSymbol('proto.system.MeasurementRequest', null, global);
goog.exportSymbol('proto.system.SensorCrackOpeningDisp', null, global);
goog.exportSymbol('proto.system.SensorExtensometer', null, global);
goog.exportSymbol('proto.system.SensorGaugeMeter', null, global);
goog.exportSymbol('proto.system.SensorGeneric', null, global);
goog.exportSymbol('proto.system.SensorIEPE', null, global);
goog.exportSymbol('proto.system.SensorLVDT', null, global);
goog.exportSymbol('proto.system.SensorLoadCell', null, global);
goog.exportSymbol('proto.system.SensorPressureTransducer', null, global);
goog.exportSymbol('proto.system.SensorRequest', null, global);
goog.exportSymbol('proto.system.SensorSeDiffEncoder', null, global);
goog.exportSymbol('proto.system.SensorStrainGauge', null, global);
goog.exportSymbol('proto.system.SensorTemperaturePT100', null, global);
goog.exportSymbol('proto.system.SensorType', null, global);
goog.exportSymbol('proto.system.SensorVideoExtensometer', null, global);
goog.exportSymbol('proto.system.ServoChannelType', null, global);
goog.exportSymbol('proto.system.SpecimenParameter', null, global);
goog.exportSymbol('proto.system.StationChannelRequest', null, global);
goog.exportSymbol('proto.system.StationChannelRequest.ChannelsCase', null, global);
goog.exportSymbol('proto.system.StationRequest', null, global);
goog.exportSymbol('proto.system.StationStatus', null, global);
goog.exportSymbol('proto.system.StationType', null, global);
goog.exportSymbol('proto.system.StatusRequest', null, global);
goog.exportSymbol('proto.system.StatusResponse', null, global);
goog.exportSymbol('proto.system.WebSensorCalibrationResponse', null, global);
goog.exportSymbol('proto.system.WebSystemRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.CalibrationSensorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.CalibrationSensorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.CalibrationSensorRequest.displayName = 'proto.system.CalibrationSensorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.StatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.StatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.StatusRequest.displayName = 'proto.system.StatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.StatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.system.StatusResponse.repeatedFields_, null);
};
goog.inherits(proto.system.StatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.StatusResponse.displayName = 'proto.system.StatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.StationStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.system.StationStatus.repeatedFields_, null);
};
goog.inherits(proto.system.StationStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.StationStatus.displayName = 'proto.system.StationStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.WebSensorCalibrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.WebSensorCalibrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.WebSensorCalibrationResponse.displayName = 'proto.system.WebSensorCalibrationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.WebSystemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.system.WebSystemRequest.repeatedFields_, null);
};
goog.inherits(proto.system.WebSystemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.WebSystemRequest.displayName = 'proto.system.WebSystemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.HashedStation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.HashedStation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.HashedStation.displayName = 'proto.system.HashedStation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.StationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.system.StationRequest.repeatedFields_, null);
};
goog.inherits(proto.system.StationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.StationRequest.displayName = 'proto.system.StationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.StationChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.system.StationChannelRequest.oneofGroups_);
};
goog.inherits(proto.system.StationChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.StationChannelRequest.displayName = 'proto.system.StationChannelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.SensorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.SensorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.SensorRequest.displayName = 'proto.system.SensorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.SensorSeDiffEncoder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.SensorSeDiffEncoder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.SensorSeDiffEncoder.displayName = 'proto.system.SensorSeDiffEncoder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.SensorLoadCell = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.SensorLoadCell, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.SensorLoadCell.displayName = 'proto.system.SensorLoadCell';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.SensorGeneric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.SensorGeneric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.SensorGeneric.displayName = 'proto.system.SensorGeneric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.SensorLVDT = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.SensorLVDT, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.SensorLVDT.displayName = 'proto.system.SensorLVDT';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.SensorStrainGauge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.SensorStrainGauge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.SensorStrainGauge.displayName = 'proto.system.SensorStrainGauge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.SensorExtensometer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.SensorExtensometer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.SensorExtensometer.displayName = 'proto.system.SensorExtensometer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.SensorGaugeMeter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.SensorGaugeMeter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.SensorGaugeMeter.displayName = 'proto.system.SensorGaugeMeter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.SensorPressureTransducer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.SensorPressureTransducer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.SensorPressureTransducer.displayName = 'proto.system.SensorPressureTransducer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.SensorTemperaturePT100 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.SensorTemperaturePT100, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.SensorTemperaturePT100.displayName = 'proto.system.SensorTemperaturePT100';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.SensorIEPE = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.SensorIEPE, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.SensorIEPE.displayName = 'proto.system.SensorIEPE';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.SensorCrackOpeningDisp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.SensorCrackOpeningDisp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.SensorCrackOpeningDisp.displayName = 'proto.system.SensorCrackOpeningDisp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.SensorVideoExtensometer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.SensorVideoExtensometer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.SensorVideoExtensometer.displayName = 'proto.system.SensorVideoExtensometer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.MeasurementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.MeasurementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.MeasurementRequest.displayName = 'proto.system.MeasurementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.ActuatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.system.ActuatorRequest.repeatedFields_, null);
};
goog.inherits(proto.system.ActuatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.ActuatorRequest.displayName = 'proto.system.ActuatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.ActuatorTypeDcPwm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.ActuatorTypeDcPwm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.ActuatorTypeDcPwm.displayName = 'proto.system.ActuatorTypeDcPwm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.ActuatorTypeStepper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.ActuatorTypeStepper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.ActuatorTypeStepper.displayName = 'proto.system.ActuatorTypeStepper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.ActuatorTypeServoElectric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.ActuatorTypeServoElectric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.ActuatorTypeServoElectric.displayName = 'proto.system.ActuatorTypeServoElectric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.ActuatorTypeServoHydraulic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.ActuatorTypeServoHydraulic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.ActuatorTypeServoHydraulic.displayName = 'proto.system.ActuatorTypeServoHydraulic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.ActuatorTypePiezoDriver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.ActuatorTypePiezoDriver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.ActuatorTypePiezoDriver.displayName = 'proto.system.ActuatorTypePiezoDriver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.AxisRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.AxisRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.AxisRequest.displayName = 'proto.system.AxisRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.system.AuxDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.system.AuxDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.system.AuxDeviceRequest.displayName = 'proto.system.AuxDeviceRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.CalibrationSensorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.system.CalibrationSensorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.CalibrationSensorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.CalibrationSensorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sensorRequest: (f = msg.getSensorRequest()) && proto.system.SensorRequest.toObject(includeInstance, f),
    configure: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.CalibrationSensorRequest}
 */
proto.system.CalibrationSensorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.CalibrationSensorRequest;
  return proto.system.CalibrationSensorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.CalibrationSensorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.CalibrationSensorRequest}
 */
proto.system.CalibrationSensorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.system.SensorRequest;
      reader.readMessage(value,proto.system.SensorRequest.deserializeBinaryFromReader);
      msg.setSensorRequest(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfigure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.CalibrationSensorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.CalibrationSensorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.CalibrationSensorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.CalibrationSensorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSensorRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.system.SensorRequest.serializeBinaryToWriter
    );
  }
  f = message.getConfigure();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional SensorRequest sensor_request = 1;
 * @return {?proto.system.SensorRequest}
 */
proto.system.CalibrationSensorRequest.prototype.getSensorRequest = function() {
  return /** @type{?proto.system.SensorRequest} */ (
    jspb.Message.getWrapperField(this, proto.system.SensorRequest, 1));
};


/**
 * @param {?proto.system.SensorRequest|undefined} value
 * @return {!proto.system.CalibrationSensorRequest} returns this
*/
proto.system.CalibrationSensorRequest.prototype.setSensorRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.CalibrationSensorRequest} returns this
 */
proto.system.CalibrationSensorRequest.prototype.clearSensorRequest = function() {
  return this.setSensorRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.CalibrationSensorRequest.prototype.hasSensorRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool configure = 2;
 * @return {boolean}
 */
proto.system.CalibrationSensorRequest.prototype.getConfigure = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.CalibrationSensorRequest} returns this
 */
proto.system.CalibrationSensorRequest.prototype.setConfigure = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.StatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.system.StatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.StatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.StatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    period: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.StatusRequest}
 */
proto.system.StatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.StatusRequest;
  return proto.system.StatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.StatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.StatusRequest}
 */
proto.system.StatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.StatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.StatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.StatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.StatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeriod();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 period = 1;
 * @return {number}
 */
proto.system.StatusRequest.prototype.getPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.system.StatusRequest} returns this
 */
proto.system.StatusRequest.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.system.StatusResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.StatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.system.StatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.StatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.StatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationsList: jspb.Message.toObjectList(msg.getStationsList(),
    proto.system.StationStatus.toObject, includeInstance),
    serverBusy: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.StatusResponse}
 */
proto.system.StatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.StatusResponse;
  return proto.system.StatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.StatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.StatusResponse}
 */
proto.system.StatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.system.StationStatus;
      reader.readMessage(value,proto.system.StationStatus.deserializeBinaryFromReader);
      msg.addStations(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setServerBusy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.StatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.StatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.StatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.StatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.system.StationStatus.serializeBinaryToWriter
    );
  }
  f = message.getServerBusy();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated StationStatus stations = 1;
 * @return {!Array<!proto.system.StationStatus>}
 */
proto.system.StatusResponse.prototype.getStationsList = function() {
  return /** @type{!Array<!proto.system.StationStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.system.StationStatus, 1));
};


/**
 * @param {!Array<!proto.system.StationStatus>} value
 * @return {!proto.system.StatusResponse} returns this
*/
proto.system.StatusResponse.prototype.setStationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.system.StationStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.system.StationStatus}
 */
proto.system.StatusResponse.prototype.addStations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.system.StationStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.system.StatusResponse} returns this
 */
proto.system.StatusResponse.prototype.clearStationsList = function() {
  return this.setStationsList([]);
};


/**
 * optional bool server_busy = 2;
 * @return {boolean}
 */
proto.system.StatusResponse.prototype.getServerBusy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.StatusResponse} returns this
 */
proto.system.StatusResponse.prototype.setServerBusy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.system.StationStatus.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.StationStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.system.StationStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.StationStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.StationStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stationName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    testId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    testName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    specimenId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    specimenName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    state: jspb.Message.getFieldWithDefault(msg, 7, 0),
    variablesList: jspb.Message.toObjectList(msg.getVariablesList(),
    common_pb.VariableRequest.toObject, includeInstance),
    hash: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.StationStatus}
 */
proto.system.StationStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.StationStatus;
  return proto.system.StationStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.StationStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.StationStatus}
 */
proto.system.StationStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecimenId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecimenName(value);
      break;
    case 7:
      var value = /** @type {!proto.common.StationState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 8:
      var value = new common_pb.VariableRequest;
      reader.readMessage(value,common_pb.VariableRequest.deserializeBinaryFromReader);
      msg.addVariables(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.StationStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.StationStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.StationStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.StationStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStationName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTestId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTestName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSpecimenId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSpecimenName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      common_pb.VariableRequest.serializeBinaryToWriter
    );
  }
  f = message.getHash();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional string station_id = 1;
 * @return {string}
 */
proto.system.StationStatus.prototype.getStationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.StationStatus} returns this
 */
proto.system.StationStatus.prototype.setStationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string station_name = 2;
 * @return {string}
 */
proto.system.StationStatus.prototype.getStationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.StationStatus} returns this
 */
proto.system.StationStatus.prototype.setStationName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string test_id = 3;
 * @return {string}
 */
proto.system.StationStatus.prototype.getTestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.StationStatus} returns this
 */
proto.system.StationStatus.prototype.setTestId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string test_name = 4;
 * @return {string}
 */
proto.system.StationStatus.prototype.getTestName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.StationStatus} returns this
 */
proto.system.StationStatus.prototype.setTestName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string specimen_id = 5;
 * @return {string}
 */
proto.system.StationStatus.prototype.getSpecimenId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.StationStatus} returns this
 */
proto.system.StationStatus.prototype.setSpecimenId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string specimen_name = 6;
 * @return {string}
 */
proto.system.StationStatus.prototype.getSpecimenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.StationStatus} returns this
 */
proto.system.StationStatus.prototype.setSpecimenName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional common.StationState state = 7;
 * @return {!proto.common.StationState}
 */
proto.system.StationStatus.prototype.getState = function() {
  return /** @type {!proto.common.StationState} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.StationState} value
 * @return {!proto.system.StationStatus} returns this
 */
proto.system.StationStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated common.VariableRequest variables = 8;
 * @return {!Array<!proto.common.VariableRequest>}
 */
proto.system.StationStatus.prototype.getVariablesList = function() {
  return /** @type{!Array<!proto.common.VariableRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.VariableRequest, 8));
};


/**
 * @param {!Array<!proto.common.VariableRequest>} value
 * @return {!proto.system.StationStatus} returns this
*/
proto.system.StationStatus.prototype.setVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.VariableRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.VariableRequest}
 */
proto.system.StationStatus.prototype.addVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.VariableRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.system.StationStatus} returns this
 */
proto.system.StationStatus.prototype.clearVariablesList = function() {
  return this.setVariablesList([]);
};


/**
 * optional uint32 hash = 9;
 * @return {number}
 */
proto.system.StationStatus.prototype.getHash = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.system.StationStatus} returns this
 */
proto.system.StationStatus.prototype.setHash = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.WebSensorCalibrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.system.WebSensorCalibrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.WebSensorCalibrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.WebSensorCalibrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.WebSensorCalibrationResponse}
 */
proto.system.WebSensorCalibrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.WebSensorCalibrationResponse;
  return proto.system.WebSensorCalibrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.WebSensorCalibrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.WebSensorCalibrationResponse}
 */
proto.system.WebSensorCalibrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.WebSensorCalibrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.WebSensorCalibrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.WebSensorCalibrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.WebSensorCalibrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 data = 1;
 * @return {number}
 */
proto.system.WebSensorCalibrationResponse.prototype.getData = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.system.WebSensorCalibrationResponse} returns this
 */
proto.system.WebSensorCalibrationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.system.WebSystemRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.WebSystemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.system.WebSystemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.WebSystemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.WebSystemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stationsList: jspb.Message.toObjectList(msg.getStationsList(),
    proto.system.HashedStation.toObject, includeInstance),
    multiUser: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    model: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.WebSystemRequest}
 */
proto.system.WebSystemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.WebSystemRequest;
  return proto.system.WebSystemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.WebSystemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.WebSystemRequest}
 */
proto.system.WebSystemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = new proto.system.HashedStation;
      reader.readMessage(value,proto.system.HashedStation.deserializeBinaryFromReader);
      msg.addStations(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMultiUser(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.WebSystemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.WebSystemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.WebSystemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.WebSystemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.system.HashedStation.serializeBinaryToWriter
    );
  }
  f = message.getMultiUser();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.system.WebSystemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.WebSystemRequest} returns this
 */
proto.system.WebSystemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated HashedStation stations = 4;
 * @return {!Array<!proto.system.HashedStation>}
 */
proto.system.WebSystemRequest.prototype.getStationsList = function() {
  return /** @type{!Array<!proto.system.HashedStation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.system.HashedStation, 4));
};


/**
 * @param {!Array<!proto.system.HashedStation>} value
 * @return {!proto.system.WebSystemRequest} returns this
*/
proto.system.WebSystemRequest.prototype.setStationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.system.HashedStation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.system.HashedStation}
 */
proto.system.WebSystemRequest.prototype.addStations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.system.HashedStation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.system.WebSystemRequest} returns this
 */
proto.system.WebSystemRequest.prototype.clearStationsList = function() {
  return this.setStationsList([]);
};


/**
 * optional bool multi_user = 5;
 * @return {boolean}
 */
proto.system.WebSystemRequest.prototype.getMultiUser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.WebSystemRequest} returns this
 */
proto.system.WebSystemRequest.prototype.setMultiUser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string model = 6;
 * @return {string}
 */
proto.system.WebSystemRequest.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.WebSystemRequest} returns this
 */
proto.system.WebSystemRequest.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.HashedStation.prototype.toObject = function(opt_includeInstance) {
  return proto.system.HashedStation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.HashedStation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.HashedStation.toObject = function(includeInstance, msg) {
  var f, obj = {
    hash: jspb.Message.getFieldWithDefault(msg, 1, 0),
    station: (f = msg.getStation()) && proto.system.StationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.HashedStation}
 */
proto.system.HashedStation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.HashedStation;
  return proto.system.HashedStation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.HashedStation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.HashedStation}
 */
proto.system.HashedStation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHash(value);
      break;
    case 2:
      var value = new proto.system.StationRequest;
      reader.readMessage(value,proto.system.StationRequest.deserializeBinaryFromReader);
      msg.setStation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.HashedStation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.HashedStation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.HashedStation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.HashedStation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHash();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.system.StationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 hash = 1;
 * @return {number}
 */
proto.system.HashedStation.prototype.getHash = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.system.HashedStation} returns this
 */
proto.system.HashedStation.prototype.setHash = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional StationRequest station = 2;
 * @return {?proto.system.StationRequest}
 */
proto.system.HashedStation.prototype.getStation = function() {
  return /** @type{?proto.system.StationRequest} */ (
    jspb.Message.getWrapperField(this, proto.system.StationRequest, 2));
};


/**
 * @param {?proto.system.StationRequest|undefined} value
 * @return {!proto.system.HashedStation} returns this
*/
proto.system.HashedStation.prototype.setStation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.HashedStation} returns this
 */
proto.system.HashedStation.prototype.clearStation = function() {
  return this.setStation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.HashedStation.prototype.hasStation = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.system.StationRequest.repeatedFields_ = [9,10,11,12,13,2,3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.StationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.system.StationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.StationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.StationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unitSystem: jspb.Message.getFieldWithDefault(msg, 8, 0),
    stationChannelsList: jspb.Message.toObjectList(msg.getStationChannelsList(),
    proto.system.StationChannelRequest.toObject, includeInstance),
    sensorsList: jspb.Message.toObjectList(msg.getSensorsList(),
    proto.system.SensorRequest.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    proto.system.MeasurementRequest.toObject, includeInstance),
    actuatorsList: jspb.Message.toObjectList(msg.getActuatorsList(),
    proto.system.ActuatorRequest.toObject, includeInstance),
    axesList: jspb.Message.toObjectList(msg.getAxesList(),
    proto.system.AxisRequest.toObject, includeInstance),
    auxDevicesList: jspb.Message.toObjectList(msg.getAuxDevicesList(),
    proto.system.AuxDeviceRequest.toObject, includeInstance),
    variablesList: jspb.Message.toObjectList(msg.getVariablesList(),
    common_pb.VariableRequest.toObject, includeInstance),
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    rule_pb.Rule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.StationRequest}
 */
proto.system.StationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.StationRequest;
  return proto.system.StationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.StationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.StationRequest}
 */
proto.system.StationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {!proto.common.UnitSystemType} */ (reader.readEnum());
      msg.setUnitSystem(value);
      break;
    case 9:
      var value = new proto.system.StationChannelRequest;
      reader.readMessage(value,proto.system.StationChannelRequest.deserializeBinaryFromReader);
      msg.addStationChannels(value);
      break;
    case 10:
      var value = new proto.system.SensorRequest;
      reader.readMessage(value,proto.system.SensorRequest.deserializeBinaryFromReader);
      msg.addSensors(value);
      break;
    case 11:
      var value = new proto.system.MeasurementRequest;
      reader.readMessage(value,proto.system.MeasurementRequest.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 12:
      var value = new proto.system.ActuatorRequest;
      reader.readMessage(value,proto.system.ActuatorRequest.deserializeBinaryFromReader);
      msg.addActuators(value);
      break;
    case 13:
      var value = new proto.system.AxisRequest;
      reader.readMessage(value,proto.system.AxisRequest.deserializeBinaryFromReader);
      msg.addAxes(value);
      break;
    case 2:
      var value = new proto.system.AuxDeviceRequest;
      reader.readMessage(value,proto.system.AuxDeviceRequest.deserializeBinaryFromReader);
      msg.addAuxDevices(value);
      break;
    case 3:
      var value = new common_pb.VariableRequest;
      reader.readMessage(value,common_pb.VariableRequest.deserializeBinaryFromReader);
      msg.addVariables(value);
      break;
    case 6:
      var value = new rule_pb.Rule;
      reader.readMessage(value,rule_pb.Rule.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.StationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.StationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.StationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.StationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnitSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getStationChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.system.StationChannelRequest.serializeBinaryToWriter
    );
  }
  f = message.getSensorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.system.SensorRequest.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.system.MeasurementRequest.serializeBinaryToWriter
    );
  }
  f = message.getActuatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.system.ActuatorRequest.serializeBinaryToWriter
    );
  }
  f = message.getAxesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.system.AxisRequest.serializeBinaryToWriter
    );
  }
  f = message.getAuxDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.system.AuxDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      common_pb.VariableRequest.serializeBinaryToWriter
    );
  }
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      rule_pb.Rule.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.system.StationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.StationRequest} returns this
 */
proto.system.StationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.system.StationRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.StationRequest} returns this
 */
proto.system.StationRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.UnitSystemType unit_system = 8;
 * @return {!proto.common.UnitSystemType}
 */
proto.system.StationRequest.prototype.getUnitSystem = function() {
  return /** @type {!proto.common.UnitSystemType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.UnitSystemType} value
 * @return {!proto.system.StationRequest} returns this
 */
proto.system.StationRequest.prototype.setUnitSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated StationChannelRequest station_channels = 9;
 * @return {!Array<!proto.system.StationChannelRequest>}
 */
proto.system.StationRequest.prototype.getStationChannelsList = function() {
  return /** @type{!Array<!proto.system.StationChannelRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.system.StationChannelRequest, 9));
};


/**
 * @param {!Array<!proto.system.StationChannelRequest>} value
 * @return {!proto.system.StationRequest} returns this
*/
proto.system.StationRequest.prototype.setStationChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.system.StationChannelRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.system.StationChannelRequest}
 */
proto.system.StationRequest.prototype.addStationChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.system.StationChannelRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.system.StationRequest} returns this
 */
proto.system.StationRequest.prototype.clearStationChannelsList = function() {
  return this.setStationChannelsList([]);
};


/**
 * repeated SensorRequest sensors = 10;
 * @return {!Array<!proto.system.SensorRequest>}
 */
proto.system.StationRequest.prototype.getSensorsList = function() {
  return /** @type{!Array<!proto.system.SensorRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.system.SensorRequest, 10));
};


/**
 * @param {!Array<!proto.system.SensorRequest>} value
 * @return {!proto.system.StationRequest} returns this
*/
proto.system.StationRequest.prototype.setSensorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.system.SensorRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.system.SensorRequest}
 */
proto.system.StationRequest.prototype.addSensors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.system.SensorRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.system.StationRequest} returns this
 */
proto.system.StationRequest.prototype.clearSensorsList = function() {
  return this.setSensorsList([]);
};


/**
 * repeated MeasurementRequest measurements = 11;
 * @return {!Array<!proto.system.MeasurementRequest>}
 */
proto.system.StationRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.system.MeasurementRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.system.MeasurementRequest, 11));
};


/**
 * @param {!Array<!proto.system.MeasurementRequest>} value
 * @return {!proto.system.StationRequest} returns this
*/
proto.system.StationRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.system.MeasurementRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.system.MeasurementRequest}
 */
proto.system.StationRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.system.MeasurementRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.system.StationRequest} returns this
 */
proto.system.StationRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * repeated ActuatorRequest actuators = 12;
 * @return {!Array<!proto.system.ActuatorRequest>}
 */
proto.system.StationRequest.prototype.getActuatorsList = function() {
  return /** @type{!Array<!proto.system.ActuatorRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.system.ActuatorRequest, 12));
};


/**
 * @param {!Array<!proto.system.ActuatorRequest>} value
 * @return {!proto.system.StationRequest} returns this
*/
proto.system.StationRequest.prototype.setActuatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.system.ActuatorRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.system.ActuatorRequest}
 */
proto.system.StationRequest.prototype.addActuators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.system.ActuatorRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.system.StationRequest} returns this
 */
proto.system.StationRequest.prototype.clearActuatorsList = function() {
  return this.setActuatorsList([]);
};


/**
 * repeated AxisRequest axes = 13;
 * @return {!Array<!proto.system.AxisRequest>}
 */
proto.system.StationRequest.prototype.getAxesList = function() {
  return /** @type{!Array<!proto.system.AxisRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.system.AxisRequest, 13));
};


/**
 * @param {!Array<!proto.system.AxisRequest>} value
 * @return {!proto.system.StationRequest} returns this
*/
proto.system.StationRequest.prototype.setAxesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.system.AxisRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.system.AxisRequest}
 */
proto.system.StationRequest.prototype.addAxes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.system.AxisRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.system.StationRequest} returns this
 */
proto.system.StationRequest.prototype.clearAxesList = function() {
  return this.setAxesList([]);
};


/**
 * repeated AuxDeviceRequest aux_devices = 2;
 * @return {!Array<!proto.system.AuxDeviceRequest>}
 */
proto.system.StationRequest.prototype.getAuxDevicesList = function() {
  return /** @type{!Array<!proto.system.AuxDeviceRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.system.AuxDeviceRequest, 2));
};


/**
 * @param {!Array<!proto.system.AuxDeviceRequest>} value
 * @return {!proto.system.StationRequest} returns this
*/
proto.system.StationRequest.prototype.setAuxDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.system.AuxDeviceRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.system.AuxDeviceRequest}
 */
proto.system.StationRequest.prototype.addAuxDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.system.AuxDeviceRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.system.StationRequest} returns this
 */
proto.system.StationRequest.prototype.clearAuxDevicesList = function() {
  return this.setAuxDevicesList([]);
};


/**
 * repeated common.VariableRequest variables = 3;
 * @return {!Array<!proto.common.VariableRequest>}
 */
proto.system.StationRequest.prototype.getVariablesList = function() {
  return /** @type{!Array<!proto.common.VariableRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.VariableRequest, 3));
};


/**
 * @param {!Array<!proto.common.VariableRequest>} value
 * @return {!proto.system.StationRequest} returns this
*/
proto.system.StationRequest.prototype.setVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.VariableRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.VariableRequest}
 */
proto.system.StationRequest.prototype.addVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.VariableRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.system.StationRequest} returns this
 */
proto.system.StationRequest.prototype.clearVariablesList = function() {
  return this.setVariablesList([]);
};


/**
 * repeated rule.Rule rules = 6;
 * @return {!Array<!proto.rule.Rule>}
 */
proto.system.StationRequest.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.rule.Rule>} */ (
    jspb.Message.getRepeatedWrapperField(this, rule_pb.Rule, 6));
};


/**
 * @param {!Array<!proto.rule.Rule>} value
 * @return {!proto.system.StationRequest} returns this
*/
proto.system.StationRequest.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.rule.Rule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rule.Rule}
 */
proto.system.StationRequest.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.rule.Rule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.system.StationRequest} returns this
 */
proto.system.StationRequest.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.system.StationChannelRequest.oneofGroups_ = [[50,51,52,53,54,55]];

/**
 * @enum {number}
 */
proto.system.StationChannelRequest.ChannelsCase = {
  CHANNELS_NOT_SET: 0,
  DIO5V: 50,
  DIO24V: 51,
  DIGITALIN24V: 52,
  RELAY: 53,
  ANALOGOUTPUT10V: 54,
  ANALOGOUTPUT100MA: 55
};

/**
 * @return {proto.system.StationChannelRequest.ChannelsCase}
 */
proto.system.StationChannelRequest.prototype.getChannelsCase = function() {
  return /** @type {proto.system.StationChannelRequest.ChannelsCase} */(jspb.Message.computeOneofCase(this, proto.system.StationChannelRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.StationChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.system.StationChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.StationChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.StationChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hw: (f = msg.getHw()) && common_pb.HardwareChannel.toObject(includeInstance, f),
    dio5v: (f = msg.getDio5v()) && common_pb.StationChannelDigitalIO5V.toObject(includeInstance, f),
    dio24v: (f = msg.getDio24v()) && common_pb.StationChannelDigitalIO24V.toObject(includeInstance, f),
    digitalin24v: (f = msg.getDigitalin24v()) && common_pb.StationChannelDigitalIn24V.toObject(includeInstance, f),
    relay: (f = msg.getRelay()) && common_pb.StationChannelRelay.toObject(includeInstance, f),
    analogoutput10v: (f = msg.getAnalogoutput10v()) && common_pb.StationChannelAnalogOut10V.toObject(includeInstance, f),
    analogoutput100ma: (f = msg.getAnalogoutput100ma()) && common_pb.StationChannelAnalogOut100MA.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.StationChannelRequest}
 */
proto.system.StationChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.StationChannelRequest;
  return proto.system.StationChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.StationChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.StationChannelRequest}
 */
proto.system.StationChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new common_pb.HardwareChannel;
      reader.readMessage(value,common_pb.HardwareChannel.deserializeBinaryFromReader);
      msg.setHw(value);
      break;
    case 50:
      var value = new common_pb.StationChannelDigitalIO5V;
      reader.readMessage(value,common_pb.StationChannelDigitalIO5V.deserializeBinaryFromReader);
      msg.setDio5v(value);
      break;
    case 51:
      var value = new common_pb.StationChannelDigitalIO24V;
      reader.readMessage(value,common_pb.StationChannelDigitalIO24V.deserializeBinaryFromReader);
      msg.setDio24v(value);
      break;
    case 52:
      var value = new common_pb.StationChannelDigitalIn24V;
      reader.readMessage(value,common_pb.StationChannelDigitalIn24V.deserializeBinaryFromReader);
      msg.setDigitalin24v(value);
      break;
    case 53:
      var value = new common_pb.StationChannelRelay;
      reader.readMessage(value,common_pb.StationChannelRelay.deserializeBinaryFromReader);
      msg.setRelay(value);
      break;
    case 54:
      var value = new common_pb.StationChannelAnalogOut10V;
      reader.readMessage(value,common_pb.StationChannelAnalogOut10V.deserializeBinaryFromReader);
      msg.setAnalogoutput10v(value);
      break;
    case 55:
      var value = new common_pb.StationChannelAnalogOut100MA;
      reader.readMessage(value,common_pb.StationChannelAnalogOut100MA.deserializeBinaryFromReader);
      msg.setAnalogoutput100ma(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.StationChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.StationChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.StationChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.StationChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHw();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.HardwareChannel.serializeBinaryToWriter
    );
  }
  f = message.getDio5v();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      common_pb.StationChannelDigitalIO5V.serializeBinaryToWriter
    );
  }
  f = message.getDio24v();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      common_pb.StationChannelDigitalIO24V.serializeBinaryToWriter
    );
  }
  f = message.getDigitalin24v();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      common_pb.StationChannelDigitalIn24V.serializeBinaryToWriter
    );
  }
  f = message.getRelay();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      common_pb.StationChannelRelay.serializeBinaryToWriter
    );
  }
  f = message.getAnalogoutput10v();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      common_pb.StationChannelAnalogOut10V.serializeBinaryToWriter
    );
  }
  f = message.getAnalogoutput100ma();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      common_pb.StationChannelAnalogOut100MA.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.system.StationChannelRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.StationChannelRequest} returns this
 */
proto.system.StationChannelRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.system.StationChannelRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.StationChannelRequest} returns this
 */
proto.system.StationChannelRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.HardwareChannel hw = 4;
 * @return {?proto.common.HardwareChannel}
 */
proto.system.StationChannelRequest.prototype.getHw = function() {
  return /** @type{?proto.common.HardwareChannel} */ (
    jspb.Message.getWrapperField(this, common_pb.HardwareChannel, 4));
};


/**
 * @param {?proto.common.HardwareChannel|undefined} value
 * @return {!proto.system.StationChannelRequest} returns this
*/
proto.system.StationChannelRequest.prototype.setHw = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.StationChannelRequest} returns this
 */
proto.system.StationChannelRequest.prototype.clearHw = function() {
  return this.setHw(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.StationChannelRequest.prototype.hasHw = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.StationChannelDigitalIO5V dio5v = 50;
 * @return {?proto.common.StationChannelDigitalIO5V}
 */
proto.system.StationChannelRequest.prototype.getDio5v = function() {
  return /** @type{?proto.common.StationChannelDigitalIO5V} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelDigitalIO5V, 50));
};


/**
 * @param {?proto.common.StationChannelDigitalIO5V|undefined} value
 * @return {!proto.system.StationChannelRequest} returns this
*/
proto.system.StationChannelRequest.prototype.setDio5v = function(value) {
  return jspb.Message.setOneofWrapperField(this, 50, proto.system.StationChannelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.StationChannelRequest} returns this
 */
proto.system.StationChannelRequest.prototype.clearDio5v = function() {
  return this.setDio5v(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.StationChannelRequest.prototype.hasDio5v = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional common.StationChannelDigitalIO24V dio24v = 51;
 * @return {?proto.common.StationChannelDigitalIO24V}
 */
proto.system.StationChannelRequest.prototype.getDio24v = function() {
  return /** @type{?proto.common.StationChannelDigitalIO24V} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelDigitalIO24V, 51));
};


/**
 * @param {?proto.common.StationChannelDigitalIO24V|undefined} value
 * @return {!proto.system.StationChannelRequest} returns this
*/
proto.system.StationChannelRequest.prototype.setDio24v = function(value) {
  return jspb.Message.setOneofWrapperField(this, 51, proto.system.StationChannelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.StationChannelRequest} returns this
 */
proto.system.StationChannelRequest.prototype.clearDio24v = function() {
  return this.setDio24v(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.StationChannelRequest.prototype.hasDio24v = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional common.StationChannelDigitalIn24V digitalIn24v = 52;
 * @return {?proto.common.StationChannelDigitalIn24V}
 */
proto.system.StationChannelRequest.prototype.getDigitalin24v = function() {
  return /** @type{?proto.common.StationChannelDigitalIn24V} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelDigitalIn24V, 52));
};


/**
 * @param {?proto.common.StationChannelDigitalIn24V|undefined} value
 * @return {!proto.system.StationChannelRequest} returns this
*/
proto.system.StationChannelRequest.prototype.setDigitalin24v = function(value) {
  return jspb.Message.setOneofWrapperField(this, 52, proto.system.StationChannelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.StationChannelRequest} returns this
 */
proto.system.StationChannelRequest.prototype.clearDigitalin24v = function() {
  return this.setDigitalin24v(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.StationChannelRequest.prototype.hasDigitalin24v = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional common.StationChannelRelay relay = 53;
 * @return {?proto.common.StationChannelRelay}
 */
proto.system.StationChannelRequest.prototype.getRelay = function() {
  return /** @type{?proto.common.StationChannelRelay} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelRelay, 53));
};


/**
 * @param {?proto.common.StationChannelRelay|undefined} value
 * @return {!proto.system.StationChannelRequest} returns this
*/
proto.system.StationChannelRequest.prototype.setRelay = function(value) {
  return jspb.Message.setOneofWrapperField(this, 53, proto.system.StationChannelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.StationChannelRequest} returns this
 */
proto.system.StationChannelRequest.prototype.clearRelay = function() {
  return this.setRelay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.StationChannelRequest.prototype.hasRelay = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional common.StationChannelAnalogOut10V analogOutput10v = 54;
 * @return {?proto.common.StationChannelAnalogOut10V}
 */
proto.system.StationChannelRequest.prototype.getAnalogoutput10v = function() {
  return /** @type{?proto.common.StationChannelAnalogOut10V} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelAnalogOut10V, 54));
};


/**
 * @param {?proto.common.StationChannelAnalogOut10V|undefined} value
 * @return {!proto.system.StationChannelRequest} returns this
*/
proto.system.StationChannelRequest.prototype.setAnalogoutput10v = function(value) {
  return jspb.Message.setOneofWrapperField(this, 54, proto.system.StationChannelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.StationChannelRequest} returns this
 */
proto.system.StationChannelRequest.prototype.clearAnalogoutput10v = function() {
  return this.setAnalogoutput10v(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.StationChannelRequest.prototype.hasAnalogoutput10v = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional common.StationChannelAnalogOut100MA analogOutput100ma = 55;
 * @return {?proto.common.StationChannelAnalogOut100MA}
 */
proto.system.StationChannelRequest.prototype.getAnalogoutput100ma = function() {
  return /** @type{?proto.common.StationChannelAnalogOut100MA} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelAnalogOut100MA, 55));
};


/**
 * @param {?proto.common.StationChannelAnalogOut100MA|undefined} value
 * @return {!proto.system.StationChannelRequest} returns this
*/
proto.system.StationChannelRequest.prototype.setAnalogoutput100ma = function(value) {
  return jspb.Message.setOneofWrapperField(this, 55, proto.system.StationChannelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.StationChannelRequest} returns this
 */
proto.system.StationChannelRequest.prototype.clearAnalogoutput100ma = function() {
  return this.setAnalogoutput100ma(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.StationChannelRequest.prototype.hasAnalogoutput100ma = function() {
  return jspb.Message.getField(this, 55) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.SensorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.system.SensorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.SensorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    calibrations: (f = msg.getCalibrations()) && common_pb.CalibrationRequest.toObject(includeInstance, f),
    channelId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    hw: (f = msg.getHw()) && common_pb.HardwareChannel.toObject(includeInstance, f),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    encoder: (f = msg.getEncoder()) && proto.system.SensorSeDiffEncoder.toObject(includeInstance, f),
    loadCell: (f = msg.getLoadCell()) && proto.system.SensorLoadCell.toObject(includeInstance, f),
    strainGauge: (f = msg.getStrainGauge()) && proto.system.SensorStrainGauge.toObject(includeInstance, f),
    extensometer: (f = msg.getExtensometer()) && proto.system.SensorExtensometer.toObject(includeInstance, f),
    lvdt: (f = msg.getLvdt()) && proto.system.SensorLVDT.toObject(includeInstance, f),
    generic: (f = msg.getGeneric()) && proto.system.SensorGeneric.toObject(includeInstance, f),
    gaugeMeter: (f = msg.getGaugeMeter()) && proto.system.SensorGaugeMeter.toObject(includeInstance, f),
    pressureTransducer: (f = msg.getPressureTransducer()) && proto.system.SensorPressureTransducer.toObject(includeInstance, f),
    temperaturePt100: (f = msg.getTemperaturePt100()) && proto.system.SensorTemperaturePT100.toObject(includeInstance, f),
    iepe: (f = msg.getIepe()) && proto.system.SensorIEPE.toObject(includeInstance, f),
    crackOpening: (f = msg.getCrackOpening()) && proto.system.SensorCrackOpeningDisp.toObject(includeInstance, f),
    videoExtensometer: (f = msg.getVideoExtensometer()) && proto.system.SensorVideoExtensometer.toObject(includeInstance, f),
    inverseReading: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    showRawData: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    unit: (f = msg.getUnit()) && common_pb.UnitFormula.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.SensorRequest}
 */
proto.system.SensorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.SensorRequest;
  return proto.system.SensorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.SensorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.SensorRequest}
 */
proto.system.SensorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.system.SensorType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = new common_pb.CalibrationRequest;
      reader.readMessage(value,common_pb.CalibrationRequest.deserializeBinaryFromReader);
      msg.setCalibrations(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 6:
      var value = new common_pb.HardwareChannel;
      reader.readMessage(value,common_pb.HardwareChannel.deserializeBinaryFromReader);
      msg.setHw(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 50:
      var value = new proto.system.SensorSeDiffEncoder;
      reader.readMessage(value,proto.system.SensorSeDiffEncoder.deserializeBinaryFromReader);
      msg.setEncoder(value);
      break;
    case 52:
      var value = new proto.system.SensorLoadCell;
      reader.readMessage(value,proto.system.SensorLoadCell.deserializeBinaryFromReader);
      msg.setLoadCell(value);
      break;
    case 53:
      var value = new proto.system.SensorStrainGauge;
      reader.readMessage(value,proto.system.SensorStrainGauge.deserializeBinaryFromReader);
      msg.setStrainGauge(value);
      break;
    case 54:
      var value = new proto.system.SensorExtensometer;
      reader.readMessage(value,proto.system.SensorExtensometer.deserializeBinaryFromReader);
      msg.setExtensometer(value);
      break;
    case 55:
      var value = new proto.system.SensorLVDT;
      reader.readMessage(value,proto.system.SensorLVDT.deserializeBinaryFromReader);
      msg.setLvdt(value);
      break;
    case 56:
      var value = new proto.system.SensorGeneric;
      reader.readMessage(value,proto.system.SensorGeneric.deserializeBinaryFromReader);
      msg.setGeneric(value);
      break;
    case 57:
      var value = new proto.system.SensorGaugeMeter;
      reader.readMessage(value,proto.system.SensorGaugeMeter.deserializeBinaryFromReader);
      msg.setGaugeMeter(value);
      break;
    case 58:
      var value = new proto.system.SensorPressureTransducer;
      reader.readMessage(value,proto.system.SensorPressureTransducer.deserializeBinaryFromReader);
      msg.setPressureTransducer(value);
      break;
    case 59:
      var value = new proto.system.SensorTemperaturePT100;
      reader.readMessage(value,proto.system.SensorTemperaturePT100.deserializeBinaryFromReader);
      msg.setTemperaturePt100(value);
      break;
    case 60:
      var value = new proto.system.SensorIEPE;
      reader.readMessage(value,proto.system.SensorIEPE.deserializeBinaryFromReader);
      msg.setIepe(value);
      break;
    case 61:
      var value = new proto.system.SensorCrackOpeningDisp;
      reader.readMessage(value,proto.system.SensorCrackOpeningDisp.deserializeBinaryFromReader);
      msg.setCrackOpening(value);
      break;
    case 62:
      var value = new proto.system.SensorVideoExtensometer;
      reader.readMessage(value,proto.system.SensorVideoExtensometer.deserializeBinaryFromReader);
      msg.setVideoExtensometer(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInverseReading(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowRawData(value);
      break;
    case 10:
      var value = new common_pb.UnitFormula;
      reader.readMessage(value,common_pb.UnitFormula.deserializeBinaryFromReader);
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.SensorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.SensorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.SensorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCalibrations();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.CalibrationRequest.serializeBinaryToWriter
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHw();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_pb.HardwareChannel.serializeBinaryToWriter
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEncoder();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.system.SensorSeDiffEncoder.serializeBinaryToWriter
    );
  }
  f = message.getLoadCell();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.system.SensorLoadCell.serializeBinaryToWriter
    );
  }
  f = message.getStrainGauge();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.system.SensorStrainGauge.serializeBinaryToWriter
    );
  }
  f = message.getExtensometer();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.system.SensorExtensometer.serializeBinaryToWriter
    );
  }
  f = message.getLvdt();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      proto.system.SensorLVDT.serializeBinaryToWriter
    );
  }
  f = message.getGeneric();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      proto.system.SensorGeneric.serializeBinaryToWriter
    );
  }
  f = message.getGaugeMeter();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      proto.system.SensorGaugeMeter.serializeBinaryToWriter
    );
  }
  f = message.getPressureTransducer();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      proto.system.SensorPressureTransducer.serializeBinaryToWriter
    );
  }
  f = message.getTemperaturePt100();
  if (f != null) {
    writer.writeMessage(
      59,
      f,
      proto.system.SensorTemperaturePT100.serializeBinaryToWriter
    );
  }
  f = message.getIepe();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      proto.system.SensorIEPE.serializeBinaryToWriter
    );
  }
  f = message.getCrackOpening();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      proto.system.SensorCrackOpeningDisp.serializeBinaryToWriter
    );
  }
  f = message.getVideoExtensometer();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      proto.system.SensorVideoExtensometer.serializeBinaryToWriter
    );
  }
  f = message.getInverseReading();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getShowRawData();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getUnit();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_pb.UnitFormula.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.system.SensorRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.system.SensorRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SensorType type = 3;
 * @return {!proto.system.SensorType}
 */
proto.system.SensorRequest.prototype.getType = function() {
  return /** @type {!proto.system.SensorType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.system.SensorType} value
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional common.CalibrationRequest calibrations = 4;
 * @return {?proto.common.CalibrationRequest}
 */
proto.system.SensorRequest.prototype.getCalibrations = function() {
  return /** @type{?proto.common.CalibrationRequest} */ (
    jspb.Message.getWrapperField(this, common_pb.CalibrationRequest, 4));
};


/**
 * @param {?proto.common.CalibrationRequest|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setCalibrations = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearCalibrations = function() {
  return this.setCalibrations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasCalibrations = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string channel_id = 5;
 * @return {string}
 */
proto.system.SensorRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional common.HardwareChannel hw = 6;
 * @return {?proto.common.HardwareChannel}
 */
proto.system.SensorRequest.prototype.getHw = function() {
  return /** @type{?proto.common.HardwareChannel} */ (
    jspb.Message.getWrapperField(this, common_pb.HardwareChannel, 6));
};


/**
 * @param {?proto.common.HardwareChannel|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setHw = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearHw = function() {
  return this.setHw(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasHw = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string serial_number = 7;
 * @return {string}
 */
proto.system.SensorRequest.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional SensorSeDiffEncoder encoder = 50;
 * @return {?proto.system.SensorSeDiffEncoder}
 */
proto.system.SensorRequest.prototype.getEncoder = function() {
  return /** @type{?proto.system.SensorSeDiffEncoder} */ (
    jspb.Message.getWrapperField(this, proto.system.SensorSeDiffEncoder, 50));
};


/**
 * @param {?proto.system.SensorSeDiffEncoder|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setEncoder = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearEncoder = function() {
  return this.setEncoder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasEncoder = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional SensorLoadCell load_cell = 52;
 * @return {?proto.system.SensorLoadCell}
 */
proto.system.SensorRequest.prototype.getLoadCell = function() {
  return /** @type{?proto.system.SensorLoadCell} */ (
    jspb.Message.getWrapperField(this, proto.system.SensorLoadCell, 52));
};


/**
 * @param {?proto.system.SensorLoadCell|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setLoadCell = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearLoadCell = function() {
  return this.setLoadCell(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasLoadCell = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional SensorStrainGauge strain_gauge = 53;
 * @return {?proto.system.SensorStrainGauge}
 */
proto.system.SensorRequest.prototype.getStrainGauge = function() {
  return /** @type{?proto.system.SensorStrainGauge} */ (
    jspb.Message.getWrapperField(this, proto.system.SensorStrainGauge, 53));
};


/**
 * @param {?proto.system.SensorStrainGauge|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setStrainGauge = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearStrainGauge = function() {
  return this.setStrainGauge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasStrainGauge = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional SensorExtensometer extensometer = 54;
 * @return {?proto.system.SensorExtensometer}
 */
proto.system.SensorRequest.prototype.getExtensometer = function() {
  return /** @type{?proto.system.SensorExtensometer} */ (
    jspb.Message.getWrapperField(this, proto.system.SensorExtensometer, 54));
};


/**
 * @param {?proto.system.SensorExtensometer|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setExtensometer = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearExtensometer = function() {
  return this.setExtensometer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasExtensometer = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional SensorLVDT lvdt = 55;
 * @return {?proto.system.SensorLVDT}
 */
proto.system.SensorRequest.prototype.getLvdt = function() {
  return /** @type{?proto.system.SensorLVDT} */ (
    jspb.Message.getWrapperField(this, proto.system.SensorLVDT, 55));
};


/**
 * @param {?proto.system.SensorLVDT|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setLvdt = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearLvdt = function() {
  return this.setLvdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasLvdt = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional SensorGeneric generic = 56;
 * @return {?proto.system.SensorGeneric}
 */
proto.system.SensorRequest.prototype.getGeneric = function() {
  return /** @type{?proto.system.SensorGeneric} */ (
    jspb.Message.getWrapperField(this, proto.system.SensorGeneric, 56));
};


/**
 * @param {?proto.system.SensorGeneric|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setGeneric = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearGeneric = function() {
  return this.setGeneric(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasGeneric = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional SensorGaugeMeter gauge_meter = 57;
 * @return {?proto.system.SensorGaugeMeter}
 */
proto.system.SensorRequest.prototype.getGaugeMeter = function() {
  return /** @type{?proto.system.SensorGaugeMeter} */ (
    jspb.Message.getWrapperField(this, proto.system.SensorGaugeMeter, 57));
};


/**
 * @param {?proto.system.SensorGaugeMeter|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setGaugeMeter = function(value) {
  return jspb.Message.setWrapperField(this, 57, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearGaugeMeter = function() {
  return this.setGaugeMeter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasGaugeMeter = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional SensorPressureTransducer pressure_transducer = 58;
 * @return {?proto.system.SensorPressureTransducer}
 */
proto.system.SensorRequest.prototype.getPressureTransducer = function() {
  return /** @type{?proto.system.SensorPressureTransducer} */ (
    jspb.Message.getWrapperField(this, proto.system.SensorPressureTransducer, 58));
};


/**
 * @param {?proto.system.SensorPressureTransducer|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setPressureTransducer = function(value) {
  return jspb.Message.setWrapperField(this, 58, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearPressureTransducer = function() {
  return this.setPressureTransducer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasPressureTransducer = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional SensorTemperaturePT100 temperature_PT100 = 59;
 * @return {?proto.system.SensorTemperaturePT100}
 */
proto.system.SensorRequest.prototype.getTemperaturePt100 = function() {
  return /** @type{?proto.system.SensorTemperaturePT100} */ (
    jspb.Message.getWrapperField(this, proto.system.SensorTemperaturePT100, 59));
};


/**
 * @param {?proto.system.SensorTemperaturePT100|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setTemperaturePt100 = function(value) {
  return jspb.Message.setWrapperField(this, 59, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearTemperaturePt100 = function() {
  return this.setTemperaturePt100(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasTemperaturePt100 = function() {
  return jspb.Message.getField(this, 59) != null;
};


/**
 * optional SensorIEPE iepe = 60;
 * @return {?proto.system.SensorIEPE}
 */
proto.system.SensorRequest.prototype.getIepe = function() {
  return /** @type{?proto.system.SensorIEPE} */ (
    jspb.Message.getWrapperField(this, proto.system.SensorIEPE, 60));
};


/**
 * @param {?proto.system.SensorIEPE|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setIepe = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearIepe = function() {
  return this.setIepe(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasIepe = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional SensorCrackOpeningDisp crack_opening = 61;
 * @return {?proto.system.SensorCrackOpeningDisp}
 */
proto.system.SensorRequest.prototype.getCrackOpening = function() {
  return /** @type{?proto.system.SensorCrackOpeningDisp} */ (
    jspb.Message.getWrapperField(this, proto.system.SensorCrackOpeningDisp, 61));
};


/**
 * @param {?proto.system.SensorCrackOpeningDisp|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setCrackOpening = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearCrackOpening = function() {
  return this.setCrackOpening(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasCrackOpening = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional SensorVideoExtensometer video_extensometer = 62;
 * @return {?proto.system.SensorVideoExtensometer}
 */
proto.system.SensorRequest.prototype.getVideoExtensometer = function() {
  return /** @type{?proto.system.SensorVideoExtensometer} */ (
    jspb.Message.getWrapperField(this, proto.system.SensorVideoExtensometer, 62));
};


/**
 * @param {?proto.system.SensorVideoExtensometer|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setVideoExtensometer = function(value) {
  return jspb.Message.setWrapperField(this, 62, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearVideoExtensometer = function() {
  return this.setVideoExtensometer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasVideoExtensometer = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional bool inverse_reading = 8;
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.getInverseReading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.setInverseReading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool show_raw_data = 9;
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.getShowRawData = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.setShowRawData = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional common.UnitFormula unit = 10;
 * @return {?proto.common.UnitFormula}
 */
proto.system.SensorRequest.prototype.getUnit = function() {
  return /** @type{?proto.common.UnitFormula} */ (
    jspb.Message.getWrapperField(this, common_pb.UnitFormula, 10));
};


/**
 * @param {?proto.common.UnitFormula|undefined} value
 * @return {!proto.system.SensorRequest} returns this
*/
proto.system.SensorRequest.prototype.setUnit = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorRequest} returns this
 */
proto.system.SensorRequest.prototype.clearUnit = function() {
  return this.setUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorRequest.prototype.hasUnit = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.SensorSeDiffEncoder.prototype.toObject = function(opt_includeInstance) {
  return proto.system.SensorSeDiffEncoder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.SensorSeDiffEncoder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorSeDiffEncoder.toObject = function(includeInstance, msg) {
  var f, obj = {
    inversePolarity: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    channel: (f = msg.getChannel()) && common_pb.StationChannelEncoder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.SensorSeDiffEncoder}
 */
proto.system.SensorSeDiffEncoder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.SensorSeDiffEncoder;
  return proto.system.SensorSeDiffEncoder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.SensorSeDiffEncoder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.SensorSeDiffEncoder}
 */
proto.system.SensorSeDiffEncoder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInversePolarity(value);
      break;
    case 2:
      var value = new common_pb.StationChannelEncoder;
      reader.readMessage(value,common_pb.StationChannelEncoder.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.SensorSeDiffEncoder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.SensorSeDiffEncoder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.SensorSeDiffEncoder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorSeDiffEncoder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInversePolarity();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.StationChannelEncoder.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool inverse_polarity = 1;
 * @return {boolean}
 */
proto.system.SensorSeDiffEncoder.prototype.getInversePolarity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.SensorSeDiffEncoder} returns this
 */
proto.system.SensorSeDiffEncoder.prototype.setInversePolarity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional common.StationChannelEncoder channel = 2;
 * @return {?proto.common.StationChannelEncoder}
 */
proto.system.SensorSeDiffEncoder.prototype.getChannel = function() {
  return /** @type{?proto.common.StationChannelEncoder} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelEncoder, 2));
};


/**
 * @param {?proto.common.StationChannelEncoder|undefined} value
 * @return {!proto.system.SensorSeDiffEncoder} returns this
*/
proto.system.SensorSeDiffEncoder.prototype.setChannel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorSeDiffEncoder} returns this
 */
proto.system.SensorSeDiffEncoder.prototype.clearChannel = function() {
  return this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorSeDiffEncoder.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.SensorLoadCell.prototype.toObject = function(opt_includeInstance) {
  return proto.system.SensorLoadCell.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.SensorLoadCell} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorLoadCell.toObject = function(includeInstance, msg) {
  var f, obj = {
    inversePolarity: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    averagingFilter: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    averagingFilterPoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
    channel: (f = msg.getChannel()) && common_pb.StationChannelSignalConditioning.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.SensorLoadCell}
 */
proto.system.SensorLoadCell.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.SensorLoadCell;
  return proto.system.SensorLoadCell.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.SensorLoadCell} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.SensorLoadCell}
 */
proto.system.SensorLoadCell.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInversePolarity(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAveragingFilter(value);
      break;
    case 3:
      var value = /** @type {!proto.system.AveragingFilterPoints} */ (reader.readEnum());
      msg.setAveragingFilterPoints(value);
      break;
    case 4:
      var value = new common_pb.StationChannelSignalConditioning;
      reader.readMessage(value,common_pb.StationChannelSignalConditioning.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.SensorLoadCell.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.SensorLoadCell.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.SensorLoadCell} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorLoadCell.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInversePolarity();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAveragingFilter();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAveragingFilterPoints();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.StationChannelSignalConditioning.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool inverse_polarity = 1;
 * @return {boolean}
 */
proto.system.SensorLoadCell.prototype.getInversePolarity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.SensorLoadCell} returns this
 */
proto.system.SensorLoadCell.prototype.setInversePolarity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool averaging_filter = 2;
 * @return {boolean}
 */
proto.system.SensorLoadCell.prototype.getAveragingFilter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.SensorLoadCell} returns this
 */
proto.system.SensorLoadCell.prototype.setAveragingFilter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional AveragingFilterPoints averaging_filter_points = 3;
 * @return {!proto.system.AveragingFilterPoints}
 */
proto.system.SensorLoadCell.prototype.getAveragingFilterPoints = function() {
  return /** @type {!proto.system.AveragingFilterPoints} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.system.AveragingFilterPoints} value
 * @return {!proto.system.SensorLoadCell} returns this
 */
proto.system.SensorLoadCell.prototype.setAveragingFilterPoints = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional common.StationChannelSignalConditioning channel = 4;
 * @return {?proto.common.StationChannelSignalConditioning}
 */
proto.system.SensorLoadCell.prototype.getChannel = function() {
  return /** @type{?proto.common.StationChannelSignalConditioning} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelSignalConditioning, 4));
};


/**
 * @param {?proto.common.StationChannelSignalConditioning|undefined} value
 * @return {!proto.system.SensorLoadCell} returns this
*/
proto.system.SensorLoadCell.prototype.setChannel = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorLoadCell} returns this
 */
proto.system.SensorLoadCell.prototype.clearChannel = function() {
  return this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorLoadCell.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.SensorGeneric.prototype.toObject = function(opt_includeInstance) {
  return proto.system.SensorGeneric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.SensorGeneric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorGeneric.toObject = function(includeInstance, msg) {
  var f, obj = {
    inversePolarity: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    averagingFilter: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    averagingFilterPoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
    chanelType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    analogin10ksps: (f = msg.getAnalogin10ksps()) && common_pb.StationChannelAnalogIn10Ksps.toObject(includeInstance, f),
    analogin10v24ma: (f = msg.getAnalogin10v24ma()) && common_pb.StationChannelAnalogIn10V24MA.toObject(includeInstance, f),
    analogin60ksps: (f = msg.getAnalogin60ksps()) && common_pb.StationChannelAnalogIn60Ksps.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.SensorGeneric}
 */
proto.system.SensorGeneric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.SensorGeneric;
  return proto.system.SensorGeneric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.SensorGeneric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.SensorGeneric}
 */
proto.system.SensorGeneric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInversePolarity(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAveragingFilter(value);
      break;
    case 3:
      var value = /** @type {!proto.system.AveragingFilterPoints} */ (reader.readEnum());
      msg.setAveragingFilterPoints(value);
      break;
    case 4:
      var value = /** @type {!proto.system.GenericSensorChannelType} */ (reader.readEnum());
      msg.setChanelType(value);
      break;
    case 50:
      var value = new common_pb.StationChannelAnalogIn10Ksps;
      reader.readMessage(value,common_pb.StationChannelAnalogIn10Ksps.deserializeBinaryFromReader);
      msg.setAnalogin10ksps(value);
      break;
    case 51:
      var value = new common_pb.StationChannelAnalogIn10V24MA;
      reader.readMessage(value,common_pb.StationChannelAnalogIn10V24MA.deserializeBinaryFromReader);
      msg.setAnalogin10v24ma(value);
      break;
    case 52:
      var value = new common_pb.StationChannelAnalogIn60Ksps;
      reader.readMessage(value,common_pb.StationChannelAnalogIn60Ksps.deserializeBinaryFromReader);
      msg.setAnalogin60ksps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.SensorGeneric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.SensorGeneric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.SensorGeneric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorGeneric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInversePolarity();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAveragingFilter();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAveragingFilterPoints();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getChanelType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAnalogin10ksps();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      common_pb.StationChannelAnalogIn10Ksps.serializeBinaryToWriter
    );
  }
  f = message.getAnalogin10v24ma();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      common_pb.StationChannelAnalogIn10V24MA.serializeBinaryToWriter
    );
  }
  f = message.getAnalogin60ksps();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      common_pb.StationChannelAnalogIn60Ksps.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool inverse_polarity = 1;
 * @return {boolean}
 */
proto.system.SensorGeneric.prototype.getInversePolarity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.SensorGeneric} returns this
 */
proto.system.SensorGeneric.prototype.setInversePolarity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool averaging_filter = 2;
 * @return {boolean}
 */
proto.system.SensorGeneric.prototype.getAveragingFilter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.SensorGeneric} returns this
 */
proto.system.SensorGeneric.prototype.setAveragingFilter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional AveragingFilterPoints averaging_filter_points = 3;
 * @return {!proto.system.AveragingFilterPoints}
 */
proto.system.SensorGeneric.prototype.getAveragingFilterPoints = function() {
  return /** @type {!proto.system.AveragingFilterPoints} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.system.AveragingFilterPoints} value
 * @return {!proto.system.SensorGeneric} returns this
 */
proto.system.SensorGeneric.prototype.setAveragingFilterPoints = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional GenericSensorChannelType chanel_type = 4;
 * @return {!proto.system.GenericSensorChannelType}
 */
proto.system.SensorGeneric.prototype.getChanelType = function() {
  return /** @type {!proto.system.GenericSensorChannelType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.system.GenericSensorChannelType} value
 * @return {!proto.system.SensorGeneric} returns this
 */
proto.system.SensorGeneric.prototype.setChanelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional common.StationChannelAnalogIn10Ksps analogIn10Ksps = 50;
 * @return {?proto.common.StationChannelAnalogIn10Ksps}
 */
proto.system.SensorGeneric.prototype.getAnalogin10ksps = function() {
  return /** @type{?proto.common.StationChannelAnalogIn10Ksps} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelAnalogIn10Ksps, 50));
};


/**
 * @param {?proto.common.StationChannelAnalogIn10Ksps|undefined} value
 * @return {!proto.system.SensorGeneric} returns this
*/
proto.system.SensorGeneric.prototype.setAnalogin10ksps = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorGeneric} returns this
 */
proto.system.SensorGeneric.prototype.clearAnalogin10ksps = function() {
  return this.setAnalogin10ksps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorGeneric.prototype.hasAnalogin10ksps = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional common.StationChannelAnalogIn10V24MA analogIn10V24MA = 51;
 * @return {?proto.common.StationChannelAnalogIn10V24MA}
 */
proto.system.SensorGeneric.prototype.getAnalogin10v24ma = function() {
  return /** @type{?proto.common.StationChannelAnalogIn10V24MA} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelAnalogIn10V24MA, 51));
};


/**
 * @param {?proto.common.StationChannelAnalogIn10V24MA|undefined} value
 * @return {!proto.system.SensorGeneric} returns this
*/
proto.system.SensorGeneric.prototype.setAnalogin10v24ma = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorGeneric} returns this
 */
proto.system.SensorGeneric.prototype.clearAnalogin10v24ma = function() {
  return this.setAnalogin10v24ma(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorGeneric.prototype.hasAnalogin10v24ma = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional common.StationChannelAnalogIn60Ksps analogIn60Ksps = 52;
 * @return {?proto.common.StationChannelAnalogIn60Ksps}
 */
proto.system.SensorGeneric.prototype.getAnalogin60ksps = function() {
  return /** @type{?proto.common.StationChannelAnalogIn60Ksps} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelAnalogIn60Ksps, 52));
};


/**
 * @param {?proto.common.StationChannelAnalogIn60Ksps|undefined} value
 * @return {!proto.system.SensorGeneric} returns this
*/
proto.system.SensorGeneric.prototype.setAnalogin60ksps = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorGeneric} returns this
 */
proto.system.SensorGeneric.prototype.clearAnalogin60ksps = function() {
  return this.setAnalogin60ksps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorGeneric.prototype.hasAnalogin60ksps = function() {
  return jspb.Message.getField(this, 52) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.SensorLVDT.prototype.toObject = function(opt_includeInstance) {
  return proto.system.SensorLVDT.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.SensorLVDT} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorLVDT.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: (f = msg.getChannel()) && common_pb.StationChannelLVDT.toObject(includeInstance, f),
    averagingFilterPoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inversePolarity: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    averagingFilter: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.SensorLVDT}
 */
proto.system.SensorLVDT.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.SensorLVDT;
  return proto.system.SensorLVDT.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.SensorLVDT} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.SensorLVDT}
 */
proto.system.SensorLVDT.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.StationChannelLVDT;
      reader.readMessage(value,common_pb.StationChannelLVDT.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 2:
      var value = /** @type {!proto.system.AveragingFilterPoints} */ (reader.readEnum());
      msg.setAveragingFilterPoints(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInversePolarity(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAveragingFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.SensorLVDT.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.SensorLVDT.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.SensorLVDT} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorLVDT.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.StationChannelLVDT.serializeBinaryToWriter
    );
  }
  f = message.getAveragingFilterPoints();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getInversePolarity();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAveragingFilter();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional common.StationChannelLVDT channel = 1;
 * @return {?proto.common.StationChannelLVDT}
 */
proto.system.SensorLVDT.prototype.getChannel = function() {
  return /** @type{?proto.common.StationChannelLVDT} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelLVDT, 1));
};


/**
 * @param {?proto.common.StationChannelLVDT|undefined} value
 * @return {!proto.system.SensorLVDT} returns this
*/
proto.system.SensorLVDT.prototype.setChannel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.SensorLVDT} returns this
 */
proto.system.SensorLVDT.prototype.clearChannel = function() {
  return this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorLVDT.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AveragingFilterPoints averaging_filter_points = 2;
 * @return {!proto.system.AveragingFilterPoints}
 */
proto.system.SensorLVDT.prototype.getAveragingFilterPoints = function() {
  return /** @type {!proto.system.AveragingFilterPoints} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.system.AveragingFilterPoints} value
 * @return {!proto.system.SensorLVDT} returns this
 */
proto.system.SensorLVDT.prototype.setAveragingFilterPoints = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool inverse_polarity = 3;
 * @return {boolean}
 */
proto.system.SensorLVDT.prototype.getInversePolarity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.SensorLVDT} returns this
 */
proto.system.SensorLVDT.prototype.setInversePolarity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool averaging_filter = 4;
 * @return {boolean}
 */
proto.system.SensorLVDT.prototype.getAveragingFilter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.SensorLVDT} returns this
 */
proto.system.SensorLVDT.prototype.setAveragingFilter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.SensorStrainGauge.prototype.toObject = function(opt_includeInstance) {
  return proto.system.SensorStrainGauge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.SensorStrainGauge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorStrainGauge.toObject = function(includeInstance, msg) {
  var f, obj = {
    tedsId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.SensorStrainGauge}
 */
proto.system.SensorStrainGauge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.SensorStrainGauge;
  return proto.system.SensorStrainGauge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.SensorStrainGauge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.SensorStrainGauge}
 */
proto.system.SensorStrainGauge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTedsId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.SensorStrainGauge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.SensorStrainGauge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.SensorStrainGauge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorStrainGauge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTedsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string teds_id = 1;
 * @return {string}
 */
proto.system.SensorStrainGauge.prototype.getTedsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.SensorStrainGauge} returns this
 */
proto.system.SensorStrainGauge.prototype.setTedsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.SensorExtensometer.prototype.toObject = function(opt_includeInstance) {
  return proto.system.SensorExtensometer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.SensorExtensometer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorExtensometer.toObject = function(includeInstance, msg) {
  var f, obj = {
    tedsId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.SensorExtensometer}
 */
proto.system.SensorExtensometer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.SensorExtensometer;
  return proto.system.SensorExtensometer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.SensorExtensometer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.SensorExtensometer}
 */
proto.system.SensorExtensometer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTedsId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.SensorExtensometer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.SensorExtensometer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.SensorExtensometer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorExtensometer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTedsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string teds_id = 1;
 * @return {string}
 */
proto.system.SensorExtensometer.prototype.getTedsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.SensorExtensometer} returns this
 */
proto.system.SensorExtensometer.prototype.setTedsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.SensorGaugeMeter.prototype.toObject = function(opt_includeInstance) {
  return proto.system.SensorGaugeMeter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.SensorGaugeMeter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorGaugeMeter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.SensorGaugeMeter}
 */
proto.system.SensorGaugeMeter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.SensorGaugeMeter;
  return proto.system.SensorGaugeMeter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.SensorGaugeMeter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.SensorGaugeMeter}
 */
proto.system.SensorGaugeMeter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.SensorGaugeMeter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.SensorGaugeMeter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.SensorGaugeMeter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorGaugeMeter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.system.SensorGaugeMeter.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.SensorGaugeMeter} returns this
 */
proto.system.SensorGaugeMeter.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.system.SensorGaugeMeter} returns this
 */
proto.system.SensorGaugeMeter.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorGaugeMeter.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.SensorPressureTransducer.prototype.toObject = function(opt_includeInstance) {
  return proto.system.SensorPressureTransducer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.SensorPressureTransducer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorPressureTransducer.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.SensorPressureTransducer}
 */
proto.system.SensorPressureTransducer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.SensorPressureTransducer;
  return proto.system.SensorPressureTransducer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.SensorPressureTransducer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.SensorPressureTransducer}
 */
proto.system.SensorPressureTransducer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.SensorPressureTransducer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.SensorPressureTransducer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.SensorPressureTransducer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorPressureTransducer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.system.SensorPressureTransducer.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.SensorPressureTransducer} returns this
 */
proto.system.SensorPressureTransducer.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.system.SensorPressureTransducer} returns this
 */
proto.system.SensorPressureTransducer.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorPressureTransducer.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.SensorTemperaturePT100.prototype.toObject = function(opt_includeInstance) {
  return proto.system.SensorTemperaturePT100.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.SensorTemperaturePT100} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorTemperaturePT100.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.SensorTemperaturePT100}
 */
proto.system.SensorTemperaturePT100.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.SensorTemperaturePT100;
  return proto.system.SensorTemperaturePT100.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.SensorTemperaturePT100} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.SensorTemperaturePT100}
 */
proto.system.SensorTemperaturePT100.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.SensorTemperaturePT100.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.SensorTemperaturePT100.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.SensorTemperaturePT100} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorTemperaturePT100.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.system.SensorTemperaturePT100.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.SensorTemperaturePT100} returns this
 */
proto.system.SensorTemperaturePT100.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.system.SensorTemperaturePT100} returns this
 */
proto.system.SensorTemperaturePT100.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorTemperaturePT100.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.SensorIEPE.prototype.toObject = function(opt_includeInstance) {
  return proto.system.SensorIEPE.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.SensorIEPE} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorIEPE.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.SensorIEPE}
 */
proto.system.SensorIEPE.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.SensorIEPE;
  return proto.system.SensorIEPE.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.SensorIEPE} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.SensorIEPE}
 */
proto.system.SensorIEPE.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.SensorIEPE.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.SensorIEPE.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.SensorIEPE} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorIEPE.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.system.SensorIEPE.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.SensorIEPE} returns this
 */
proto.system.SensorIEPE.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.system.SensorIEPE} returns this
 */
proto.system.SensorIEPE.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorIEPE.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.SensorCrackOpeningDisp.prototype.toObject = function(opt_includeInstance) {
  return proto.system.SensorCrackOpeningDisp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.SensorCrackOpeningDisp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorCrackOpeningDisp.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.SensorCrackOpeningDisp}
 */
proto.system.SensorCrackOpeningDisp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.SensorCrackOpeningDisp;
  return proto.system.SensorCrackOpeningDisp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.SensorCrackOpeningDisp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.SensorCrackOpeningDisp}
 */
proto.system.SensorCrackOpeningDisp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.SensorCrackOpeningDisp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.SensorCrackOpeningDisp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.SensorCrackOpeningDisp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorCrackOpeningDisp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.system.SensorCrackOpeningDisp.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.SensorCrackOpeningDisp} returns this
 */
proto.system.SensorCrackOpeningDisp.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.system.SensorCrackOpeningDisp} returns this
 */
proto.system.SensorCrackOpeningDisp.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorCrackOpeningDisp.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.SensorVideoExtensometer.prototype.toObject = function(opt_includeInstance) {
  return proto.system.SensorVideoExtensometer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.SensorVideoExtensometer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorVideoExtensometer.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.SensorVideoExtensometer}
 */
proto.system.SensorVideoExtensometer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.SensorVideoExtensometer;
  return proto.system.SensorVideoExtensometer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.SensorVideoExtensometer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.SensorVideoExtensometer}
 */
proto.system.SensorVideoExtensometer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.SensorVideoExtensometer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.SensorVideoExtensometer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.SensorVideoExtensometer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.SensorVideoExtensometer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.system.SensorVideoExtensometer.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.SensorVideoExtensometer} returns this
 */
proto.system.SensorVideoExtensometer.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.system.SensorVideoExtensometer} returns this
 */
proto.system.SensorVideoExtensometer.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.SensorVideoExtensometer.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.MeasurementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.system.MeasurementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.MeasurementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.MeasurementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    applyFormula: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    formulaType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sensorId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dataIn1ValueType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dataIn1: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    dataIn1VariableId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dataIn1SpecimenParamType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    dataIn2ValueType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    dataIn2: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    dataIn2VariableId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    dataIn2SpecimenParamType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    unitName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    inverseReading: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    limitsEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    lowerLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    upperLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    parentId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    unit: (f = msg.getUnit()) && common_pb.UnitFormula.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.MeasurementRequest}
 */
proto.system.MeasurementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.MeasurementRequest;
  return proto.system.MeasurementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.MeasurementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.MeasurementRequest}
 */
proto.system.MeasurementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApplyFormula(value);
      break;
    case 4:
      var value = /** @type {!proto.system.FormulaType} */ (reader.readEnum());
      msg.setFormulaType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSensorId(value);
      break;
    case 6:
      var value = /** @type {!proto.system.DataInValueType} */ (reader.readEnum());
      msg.setDataIn1ValueType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDataIn1(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataIn1VariableId(value);
      break;
    case 9:
      var value = /** @type {!proto.system.SpecimenParameter} */ (reader.readEnum());
      msg.setDataIn1SpecimenParamType(value);
      break;
    case 10:
      var value = /** @type {!proto.system.DataInValueType} */ (reader.readEnum());
      msg.setDataIn2ValueType(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDataIn2(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataIn2VariableId(value);
      break;
    case 13:
      var value = /** @type {!proto.system.SpecimenParameter} */ (reader.readEnum());
      msg.setDataIn2SpecimenParamType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInverseReading(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLimitsEnabled(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerLimit(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperLimit(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 21:
      var value = new common_pb.UnitFormula;
      reader.readMessage(value,common_pb.UnitFormula.deserializeBinaryFromReader);
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.MeasurementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.MeasurementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.MeasurementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.MeasurementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApplyFormula();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getFormulaType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSensorId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDataIn1ValueType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {!proto.system.SpecimenParameter} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getDataIn2ValueType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {!proto.system.SpecimenParameter} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getInverseReading();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getLimitsEnabled();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getLowerLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getUpperLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getParentId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getUnit();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      common_pb.UnitFormula.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.system.MeasurementRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.system.MeasurementRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool apply_formula = 3;
 * @return {boolean}
 */
proto.system.MeasurementRequest.prototype.getApplyFormula = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setApplyFormula = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional FormulaType formula_type = 4;
 * @return {!proto.system.FormulaType}
 */
proto.system.MeasurementRequest.prototype.getFormulaType = function() {
  return /** @type {!proto.system.FormulaType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.system.FormulaType} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setFormulaType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string sensor_id = 5;
 * @return {string}
 */
proto.system.MeasurementRequest.prototype.getSensorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setSensorId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional DataInValueType data_in1_value_type = 6;
 * @return {!proto.system.DataInValueType}
 */
proto.system.MeasurementRequest.prototype.getDataIn1ValueType = function() {
  return /** @type {!proto.system.DataInValueType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.system.DataInValueType} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setDataIn1ValueType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional float data_in1 = 7;
 * @return {number}
 */
proto.system.MeasurementRequest.prototype.getDataIn1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setDataIn1 = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.clearDataIn1 = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.MeasurementRequest.prototype.hasDataIn1 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string data_in1_variable_id = 8;
 * @return {string}
 */
proto.system.MeasurementRequest.prototype.getDataIn1VariableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setDataIn1VariableId = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.clearDataIn1VariableId = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.MeasurementRequest.prototype.hasDataIn1VariableId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional SpecimenParameter data_in1_specimen_param_type = 9;
 * @return {!proto.system.SpecimenParameter}
 */
proto.system.MeasurementRequest.prototype.getDataIn1SpecimenParamType = function() {
  return /** @type {!proto.system.SpecimenParameter} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.system.SpecimenParameter} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setDataIn1SpecimenParamType = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.clearDataIn1SpecimenParamType = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.MeasurementRequest.prototype.hasDataIn1SpecimenParamType = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional DataInValueType data_in2_value_type = 10;
 * @return {!proto.system.DataInValueType}
 */
proto.system.MeasurementRequest.prototype.getDataIn2ValueType = function() {
  return /** @type {!proto.system.DataInValueType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.system.DataInValueType} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setDataIn2ValueType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional float data_in2 = 11;
 * @return {number}
 */
proto.system.MeasurementRequest.prototype.getDataIn2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setDataIn2 = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.clearDataIn2 = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.MeasurementRequest.prototype.hasDataIn2 = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string data_in2_variable_id = 12;
 * @return {string}
 */
proto.system.MeasurementRequest.prototype.getDataIn2VariableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setDataIn2VariableId = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.clearDataIn2VariableId = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.MeasurementRequest.prototype.hasDataIn2VariableId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional SpecimenParameter data_in2_specimen_param_type = 13;
 * @return {!proto.system.SpecimenParameter}
 */
proto.system.MeasurementRequest.prototype.getDataIn2SpecimenParamType = function() {
  return /** @type {!proto.system.SpecimenParameter} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.system.SpecimenParameter} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setDataIn2SpecimenParamType = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.clearDataIn2SpecimenParamType = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.MeasurementRequest.prototype.hasDataIn2SpecimenParamType = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string unit_name = 14;
 * @return {string}
 */
proto.system.MeasurementRequest.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool inverse_reading = 16;
 * @return {boolean}
 */
proto.system.MeasurementRequest.prototype.getInverseReading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setInverseReading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool limits_enabled = 17;
 * @return {boolean}
 */
proto.system.MeasurementRequest.prototype.getLimitsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setLimitsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional float lower_limit = 18;
 * @return {number}
 */
proto.system.MeasurementRequest.prototype.getLowerLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setLowerLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float upper_limit = 19;
 * @return {number}
 */
proto.system.MeasurementRequest.prototype.getUpperLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setUpperLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional string parent_id = 20;
 * @return {string}
 */
proto.system.MeasurementRequest.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.setParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional common.UnitFormula unit = 21;
 * @return {?proto.common.UnitFormula}
 */
proto.system.MeasurementRequest.prototype.getUnit = function() {
  return /** @type{?proto.common.UnitFormula} */ (
    jspb.Message.getWrapperField(this, common_pb.UnitFormula, 21));
};


/**
 * @param {?proto.common.UnitFormula|undefined} value
 * @return {!proto.system.MeasurementRequest} returns this
*/
proto.system.MeasurementRequest.prototype.setUnit = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.MeasurementRequest} returns this
 */
proto.system.MeasurementRequest.prototype.clearUnit = function() {
  return this.setUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.MeasurementRequest.prototype.hasUnit = function() {
  return jspb.Message.getField(this, 21) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.system.ActuatorRequest.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.ActuatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.system.ActuatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.ActuatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.ActuatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    channelId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    hw: (f = msg.getHw()) && common_pb.HardwareChannel.toObject(includeInstance, f),
    dcpwm: (f = msg.getDcpwm()) && proto.system.ActuatorTypeDcPwm.toObject(includeInstance, f),
    stepper: (f = msg.getStepper()) && proto.system.ActuatorTypeStepper.toObject(includeInstance, f),
    servoElectric: (f = msg.getServoElectric()) && proto.system.ActuatorTypeServoElectric.toObject(includeInstance, f),
    servoHydraulic: (f = msg.getServoHydraulic()) && proto.system.ActuatorTypeServoHydraulic.toObject(includeInstance, f),
    piezo: (f = msg.getPiezo()) && proto.system.ActuatorTypePiezoDriver.toObject(includeInstance, f),
    manufacturer: jspb.Message.getFieldWithDefault(msg, 6, ""),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    ebit1IdList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    ebit2IdList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    calibration: (f = msg.getCalibration()) && common_pb.Calibration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.ActuatorRequest}
 */
proto.system.ActuatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.ActuatorRequest;
  return proto.system.ActuatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.ActuatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.ActuatorRequest}
 */
proto.system.ActuatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.system.ActuatorType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 5:
      var value = new common_pb.HardwareChannel;
      reader.readMessage(value,common_pb.HardwareChannel.deserializeBinaryFromReader);
      msg.setHw(value);
      break;
    case 50:
      var value = new proto.system.ActuatorTypeDcPwm;
      reader.readMessage(value,proto.system.ActuatorTypeDcPwm.deserializeBinaryFromReader);
      msg.setDcpwm(value);
      break;
    case 51:
      var value = new proto.system.ActuatorTypeStepper;
      reader.readMessage(value,proto.system.ActuatorTypeStepper.deserializeBinaryFromReader);
      msg.setStepper(value);
      break;
    case 52:
      var value = new proto.system.ActuatorTypeServoElectric;
      reader.readMessage(value,proto.system.ActuatorTypeServoElectric.deserializeBinaryFromReader);
      msg.setServoElectric(value);
      break;
    case 53:
      var value = new proto.system.ActuatorTypeServoHydraulic;
      reader.readMessage(value,proto.system.ActuatorTypeServoHydraulic.deserializeBinaryFromReader);
      msg.setServoHydraulic(value);
      break;
    case 55:
      var value = new proto.system.ActuatorTypePiezoDriver;
      reader.readMessage(value,proto.system.ActuatorTypePiezoDriver.deserializeBinaryFromReader);
      msg.setPiezo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturer(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addEbit1Id(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addEbit2Id(value);
      break;
    case 13:
      var value = new common_pb.Calibration;
      reader.readMessage(value,common_pb.Calibration.deserializeBinaryFromReader);
      msg.setCalibration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.ActuatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.ActuatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.ActuatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.ActuatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHw();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.HardwareChannel.serializeBinaryToWriter
    );
  }
  f = message.getDcpwm();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.system.ActuatorTypeDcPwm.serializeBinaryToWriter
    );
  }
  f = message.getStepper();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.system.ActuatorTypeStepper.serializeBinaryToWriter
    );
  }
  f = message.getServoElectric();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.system.ActuatorTypeServoElectric.serializeBinaryToWriter
    );
  }
  f = message.getServoHydraulic();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.system.ActuatorTypeServoHydraulic.serializeBinaryToWriter
    );
  }
  f = message.getPiezo();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      proto.system.ActuatorTypePiezoDriver.serializeBinaryToWriter
    );
  }
  f = message.getManufacturer();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEbit1IdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getEbit2IdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getCalibration();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      common_pb.Calibration.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.system.ActuatorRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.system.ActuatorRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ActuatorType type = 3;
 * @return {!proto.system.ActuatorType}
 */
proto.system.ActuatorRequest.prototype.getType = function() {
  return /** @type {!proto.system.ActuatorType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.system.ActuatorType} value
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string channel_id = 4;
 * @return {string}
 */
proto.system.ActuatorRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.HardwareChannel hw = 5;
 * @return {?proto.common.HardwareChannel}
 */
proto.system.ActuatorRequest.prototype.getHw = function() {
  return /** @type{?proto.common.HardwareChannel} */ (
    jspb.Message.getWrapperField(this, common_pb.HardwareChannel, 5));
};


/**
 * @param {?proto.common.HardwareChannel|undefined} value
 * @return {!proto.system.ActuatorRequest} returns this
*/
proto.system.ActuatorRequest.prototype.setHw = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.clearHw = function() {
  return this.setHw(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorRequest.prototype.hasHw = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ActuatorTypeDcPwm dcpwm = 50;
 * @return {?proto.system.ActuatorTypeDcPwm}
 */
proto.system.ActuatorRequest.prototype.getDcpwm = function() {
  return /** @type{?proto.system.ActuatorTypeDcPwm} */ (
    jspb.Message.getWrapperField(this, proto.system.ActuatorTypeDcPwm, 50));
};


/**
 * @param {?proto.system.ActuatorTypeDcPwm|undefined} value
 * @return {!proto.system.ActuatorRequest} returns this
*/
proto.system.ActuatorRequest.prototype.setDcpwm = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.clearDcpwm = function() {
  return this.setDcpwm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorRequest.prototype.hasDcpwm = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional ActuatorTypeStepper stepper = 51;
 * @return {?proto.system.ActuatorTypeStepper}
 */
proto.system.ActuatorRequest.prototype.getStepper = function() {
  return /** @type{?proto.system.ActuatorTypeStepper} */ (
    jspb.Message.getWrapperField(this, proto.system.ActuatorTypeStepper, 51));
};


/**
 * @param {?proto.system.ActuatorTypeStepper|undefined} value
 * @return {!proto.system.ActuatorRequest} returns this
*/
proto.system.ActuatorRequest.prototype.setStepper = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.clearStepper = function() {
  return this.setStepper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorRequest.prototype.hasStepper = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional ActuatorTypeServoElectric servo_electric = 52;
 * @return {?proto.system.ActuatorTypeServoElectric}
 */
proto.system.ActuatorRequest.prototype.getServoElectric = function() {
  return /** @type{?proto.system.ActuatorTypeServoElectric} */ (
    jspb.Message.getWrapperField(this, proto.system.ActuatorTypeServoElectric, 52));
};


/**
 * @param {?proto.system.ActuatorTypeServoElectric|undefined} value
 * @return {!proto.system.ActuatorRequest} returns this
*/
proto.system.ActuatorRequest.prototype.setServoElectric = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.clearServoElectric = function() {
  return this.setServoElectric(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorRequest.prototype.hasServoElectric = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional ActuatorTypeServoHydraulic servo_hydraulic = 53;
 * @return {?proto.system.ActuatorTypeServoHydraulic}
 */
proto.system.ActuatorRequest.prototype.getServoHydraulic = function() {
  return /** @type{?proto.system.ActuatorTypeServoHydraulic} */ (
    jspb.Message.getWrapperField(this, proto.system.ActuatorTypeServoHydraulic, 53));
};


/**
 * @param {?proto.system.ActuatorTypeServoHydraulic|undefined} value
 * @return {!proto.system.ActuatorRequest} returns this
*/
proto.system.ActuatorRequest.prototype.setServoHydraulic = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.clearServoHydraulic = function() {
  return this.setServoHydraulic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorRequest.prototype.hasServoHydraulic = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional ActuatorTypePiezoDriver piezo = 55;
 * @return {?proto.system.ActuatorTypePiezoDriver}
 */
proto.system.ActuatorRequest.prototype.getPiezo = function() {
  return /** @type{?proto.system.ActuatorTypePiezoDriver} */ (
    jspb.Message.getWrapperField(this, proto.system.ActuatorTypePiezoDriver, 55));
};


/**
 * @param {?proto.system.ActuatorTypePiezoDriver|undefined} value
 * @return {!proto.system.ActuatorRequest} returns this
*/
proto.system.ActuatorRequest.prototype.setPiezo = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.clearPiezo = function() {
  return this.setPiezo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorRequest.prototype.hasPiezo = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional string manufacturer = 6;
 * @return {string}
 */
proto.system.ActuatorRequest.prototype.getManufacturer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.setManufacturer = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string serial_number = 7;
 * @return {string}
 */
proto.system.ActuatorRequest.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string ebit1_id = 11;
 * @return {!Array<string>}
 */
proto.system.ActuatorRequest.prototype.getEbit1IdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.setEbit1IdList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.addEbit1Id = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.clearEbit1IdList = function() {
  return this.setEbit1IdList([]);
};


/**
 * repeated string ebit2_id = 12;
 * @return {!Array<string>}
 */
proto.system.ActuatorRequest.prototype.getEbit2IdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.setEbit2IdList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.addEbit2Id = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.clearEbit2IdList = function() {
  return this.setEbit2IdList([]);
};


/**
 * optional common.Calibration calibration = 13;
 * @return {?proto.common.Calibration}
 */
proto.system.ActuatorRequest.prototype.getCalibration = function() {
  return /** @type{?proto.common.Calibration} */ (
    jspb.Message.getWrapperField(this, common_pb.Calibration, 13));
};


/**
 * @param {?proto.common.Calibration|undefined} value
 * @return {!proto.system.ActuatorRequest} returns this
*/
proto.system.ActuatorRequest.prototype.setCalibration = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorRequest} returns this
 */
proto.system.ActuatorRequest.prototype.clearCalibration = function() {
  return this.setCalibration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorRequest.prototype.hasCalibration = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.ActuatorTypeDcPwm.prototype.toObject = function(opt_includeInstance) {
  return proto.system.ActuatorTypeDcPwm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.ActuatorTypeDcPwm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.ActuatorTypeDcPwm.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dcpwm30: (f = msg.getDcpwm30()) && common_pb.StationChannelDcPwm30V.toObject(includeInstance, f),
    dcpwm50: (f = msg.getDcpwm50()) && common_pb.StationChannelDcPwm50V.toObject(includeInstance, f),
    dcpwm65: (f = msg.getDcpwm65()) && common_pb.StationChannelDcPwm65V.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.ActuatorTypeDcPwm}
 */
proto.system.ActuatorTypeDcPwm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.ActuatorTypeDcPwm;
  return proto.system.ActuatorTypeDcPwm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.ActuatorTypeDcPwm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.ActuatorTypeDcPwm}
 */
proto.system.ActuatorTypeDcPwm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.system.DcPwmChannelType} */ (reader.readEnum());
      msg.setChannelType(value);
      break;
    case 50:
      var value = new common_pb.StationChannelDcPwm30V;
      reader.readMessage(value,common_pb.StationChannelDcPwm30V.deserializeBinaryFromReader);
      msg.setDcpwm30(value);
      break;
    case 51:
      var value = new common_pb.StationChannelDcPwm50V;
      reader.readMessage(value,common_pb.StationChannelDcPwm50V.deserializeBinaryFromReader);
      msg.setDcpwm50(value);
      break;
    case 52:
      var value = new common_pb.StationChannelDcPwm65V;
      reader.readMessage(value,common_pb.StationChannelDcPwm65V.deserializeBinaryFromReader);
      msg.setDcpwm65(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.ActuatorTypeDcPwm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.ActuatorTypeDcPwm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.ActuatorTypeDcPwm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.ActuatorTypeDcPwm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDcpwm30();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      common_pb.StationChannelDcPwm30V.serializeBinaryToWriter
    );
  }
  f = message.getDcpwm50();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      common_pb.StationChannelDcPwm50V.serializeBinaryToWriter
    );
  }
  f = message.getDcpwm65();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      common_pb.StationChannelDcPwm65V.serializeBinaryToWriter
    );
  }
};


/**
 * optional DcPwmChannelType channel_type = 1;
 * @return {!proto.system.DcPwmChannelType}
 */
proto.system.ActuatorTypeDcPwm.prototype.getChannelType = function() {
  return /** @type {!proto.system.DcPwmChannelType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.system.DcPwmChannelType} value
 * @return {!proto.system.ActuatorTypeDcPwm} returns this
 */
proto.system.ActuatorTypeDcPwm.prototype.setChannelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional common.StationChannelDcPwm30V dcpwm30 = 50;
 * @return {?proto.common.StationChannelDcPwm30V}
 */
proto.system.ActuatorTypeDcPwm.prototype.getDcpwm30 = function() {
  return /** @type{?proto.common.StationChannelDcPwm30V} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelDcPwm30V, 50));
};


/**
 * @param {?proto.common.StationChannelDcPwm30V|undefined} value
 * @return {!proto.system.ActuatorTypeDcPwm} returns this
*/
proto.system.ActuatorTypeDcPwm.prototype.setDcpwm30 = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorTypeDcPwm} returns this
 */
proto.system.ActuatorTypeDcPwm.prototype.clearDcpwm30 = function() {
  return this.setDcpwm30(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorTypeDcPwm.prototype.hasDcpwm30 = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional common.StationChannelDcPwm50V dcpwm50 = 51;
 * @return {?proto.common.StationChannelDcPwm50V}
 */
proto.system.ActuatorTypeDcPwm.prototype.getDcpwm50 = function() {
  return /** @type{?proto.common.StationChannelDcPwm50V} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelDcPwm50V, 51));
};


/**
 * @param {?proto.common.StationChannelDcPwm50V|undefined} value
 * @return {!proto.system.ActuatorTypeDcPwm} returns this
*/
proto.system.ActuatorTypeDcPwm.prototype.setDcpwm50 = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorTypeDcPwm} returns this
 */
proto.system.ActuatorTypeDcPwm.prototype.clearDcpwm50 = function() {
  return this.setDcpwm50(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorTypeDcPwm.prototype.hasDcpwm50 = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional common.StationChannelDcPwm65V dcpwm65 = 52;
 * @return {?proto.common.StationChannelDcPwm65V}
 */
proto.system.ActuatorTypeDcPwm.prototype.getDcpwm65 = function() {
  return /** @type{?proto.common.StationChannelDcPwm65V} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelDcPwm65V, 52));
};


/**
 * @param {?proto.common.StationChannelDcPwm65V|undefined} value
 * @return {!proto.system.ActuatorTypeDcPwm} returns this
*/
proto.system.ActuatorTypeDcPwm.prototype.setDcpwm65 = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorTypeDcPwm} returns this
 */
proto.system.ActuatorTypeDcPwm.prototype.clearDcpwm65 = function() {
  return this.setDcpwm65(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorTypeDcPwm.prototype.hasDcpwm65 = function() {
  return jspb.Message.getField(this, 52) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.ActuatorTypeStepper.prototype.toObject = function(opt_includeInstance) {
  return proto.system.ActuatorTypeStepper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.ActuatorTypeStepper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.ActuatorTypeStepper.toObject = function(includeInstance, msg) {
  var f, obj = {
    stepper: (f = msg.getStepper()) && common_pb.StationChannelStepper.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.ActuatorTypeStepper}
 */
proto.system.ActuatorTypeStepper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.ActuatorTypeStepper;
  return proto.system.ActuatorTypeStepper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.ActuatorTypeStepper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.ActuatorTypeStepper}
 */
proto.system.ActuatorTypeStepper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 50:
      var value = new common_pb.StationChannelStepper;
      reader.readMessage(value,common_pb.StationChannelStepper.deserializeBinaryFromReader);
      msg.setStepper(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.ActuatorTypeStepper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.ActuatorTypeStepper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.ActuatorTypeStepper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.ActuatorTypeStepper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepper();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      common_pb.StationChannelStepper.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.StationChannelStepper stepper = 50;
 * @return {?proto.common.StationChannelStepper}
 */
proto.system.ActuatorTypeStepper.prototype.getStepper = function() {
  return /** @type{?proto.common.StationChannelStepper} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelStepper, 50));
};


/**
 * @param {?proto.common.StationChannelStepper|undefined} value
 * @return {!proto.system.ActuatorTypeStepper} returns this
*/
proto.system.ActuatorTypeStepper.prototype.setStepper = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorTypeStepper} returns this
 */
proto.system.ActuatorTypeStepper.prototype.clearStepper = function() {
  return this.setStepper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorTypeStepper.prototype.hasStepper = function() {
  return jspb.Message.getField(this, 50) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.ActuatorTypeServoElectric.prototype.toObject = function(opt_includeInstance) {
  return proto.system.ActuatorTypeServoElectric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.ActuatorTypeServoElectric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.ActuatorTypeServoElectric.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    analogout10v: (f = msg.getAnalogout10v()) && common_pb.StationChannelAnalogOut10V.toObject(includeInstance, f),
    analogout10v24ma: (f = msg.getAnalogout10v24ma()) && common_pb.StationChannelAnalogOut10V24MA.toObject(includeInstance, f),
    analogout100ma: (f = msg.getAnalogout100ma()) && common_pb.StationChannelAnalogOut100MA.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.ActuatorTypeServoElectric}
 */
proto.system.ActuatorTypeServoElectric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.ActuatorTypeServoElectric;
  return proto.system.ActuatorTypeServoElectric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.ActuatorTypeServoElectric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.ActuatorTypeServoElectric}
 */
proto.system.ActuatorTypeServoElectric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.system.ServoChannelType} */ (reader.readEnum());
      msg.setChannelType(value);
      break;
    case 50:
      var value = new common_pb.StationChannelAnalogOut10V;
      reader.readMessage(value,common_pb.StationChannelAnalogOut10V.deserializeBinaryFromReader);
      msg.setAnalogout10v(value);
      break;
    case 51:
      var value = new common_pb.StationChannelAnalogOut10V24MA;
      reader.readMessage(value,common_pb.StationChannelAnalogOut10V24MA.deserializeBinaryFromReader);
      msg.setAnalogout10v24ma(value);
      break;
    case 52:
      var value = new common_pb.StationChannelAnalogOut100MA;
      reader.readMessage(value,common_pb.StationChannelAnalogOut100MA.deserializeBinaryFromReader);
      msg.setAnalogout100ma(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.ActuatorTypeServoElectric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.ActuatorTypeServoElectric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.ActuatorTypeServoElectric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.ActuatorTypeServoElectric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAnalogout10v();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      common_pb.StationChannelAnalogOut10V.serializeBinaryToWriter
    );
  }
  f = message.getAnalogout10v24ma();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      common_pb.StationChannelAnalogOut10V24MA.serializeBinaryToWriter
    );
  }
  f = message.getAnalogout100ma();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      common_pb.StationChannelAnalogOut100MA.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServoChannelType channel_type = 1;
 * @return {!proto.system.ServoChannelType}
 */
proto.system.ActuatorTypeServoElectric.prototype.getChannelType = function() {
  return /** @type {!proto.system.ServoChannelType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.system.ServoChannelType} value
 * @return {!proto.system.ActuatorTypeServoElectric} returns this
 */
proto.system.ActuatorTypeServoElectric.prototype.setChannelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional common.StationChannelAnalogOut10V analogOut10V = 50;
 * @return {?proto.common.StationChannelAnalogOut10V}
 */
proto.system.ActuatorTypeServoElectric.prototype.getAnalogout10v = function() {
  return /** @type{?proto.common.StationChannelAnalogOut10V} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelAnalogOut10V, 50));
};


/**
 * @param {?proto.common.StationChannelAnalogOut10V|undefined} value
 * @return {!proto.system.ActuatorTypeServoElectric} returns this
*/
proto.system.ActuatorTypeServoElectric.prototype.setAnalogout10v = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorTypeServoElectric} returns this
 */
proto.system.ActuatorTypeServoElectric.prototype.clearAnalogout10v = function() {
  return this.setAnalogout10v(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorTypeServoElectric.prototype.hasAnalogout10v = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional common.StationChannelAnalogOut10V24MA analogOut10V24MA = 51;
 * @return {?proto.common.StationChannelAnalogOut10V24MA}
 */
proto.system.ActuatorTypeServoElectric.prototype.getAnalogout10v24ma = function() {
  return /** @type{?proto.common.StationChannelAnalogOut10V24MA} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelAnalogOut10V24MA, 51));
};


/**
 * @param {?proto.common.StationChannelAnalogOut10V24MA|undefined} value
 * @return {!proto.system.ActuatorTypeServoElectric} returns this
*/
proto.system.ActuatorTypeServoElectric.prototype.setAnalogout10v24ma = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorTypeServoElectric} returns this
 */
proto.system.ActuatorTypeServoElectric.prototype.clearAnalogout10v24ma = function() {
  return this.setAnalogout10v24ma(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorTypeServoElectric.prototype.hasAnalogout10v24ma = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional common.StationChannelAnalogOut100MA analogOut100MA = 52;
 * @return {?proto.common.StationChannelAnalogOut100MA}
 */
proto.system.ActuatorTypeServoElectric.prototype.getAnalogout100ma = function() {
  return /** @type{?proto.common.StationChannelAnalogOut100MA} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelAnalogOut100MA, 52));
};


/**
 * @param {?proto.common.StationChannelAnalogOut100MA|undefined} value
 * @return {!proto.system.ActuatorTypeServoElectric} returns this
*/
proto.system.ActuatorTypeServoElectric.prototype.setAnalogout100ma = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorTypeServoElectric} returns this
 */
proto.system.ActuatorTypeServoElectric.prototype.clearAnalogout100ma = function() {
  return this.setAnalogout100ma(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorTypeServoElectric.prototype.hasAnalogout100ma = function() {
  return jspb.Message.getField(this, 52) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.ActuatorTypeServoHydraulic.prototype.toObject = function(opt_includeInstance) {
  return proto.system.ActuatorTypeServoHydraulic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.ActuatorTypeServoHydraulic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.ActuatorTypeServoHydraulic.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    analogout10v: (f = msg.getAnalogout10v()) && common_pb.StationChannelAnalogOut10V.toObject(includeInstance, f),
    analogout10v24ma: (f = msg.getAnalogout10v24ma()) && common_pb.StationChannelAnalogOut10V24MA.toObject(includeInstance, f),
    analogout100ma: (f = msg.getAnalogout100ma()) && common_pb.StationChannelAnalogOut100MA.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.ActuatorTypeServoHydraulic}
 */
proto.system.ActuatorTypeServoHydraulic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.ActuatorTypeServoHydraulic;
  return proto.system.ActuatorTypeServoHydraulic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.ActuatorTypeServoHydraulic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.ActuatorTypeServoHydraulic}
 */
proto.system.ActuatorTypeServoHydraulic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.system.ServoChannelType} */ (reader.readEnum());
      msg.setChannelType(value);
      break;
    case 50:
      var value = new common_pb.StationChannelAnalogOut10V;
      reader.readMessage(value,common_pb.StationChannelAnalogOut10V.deserializeBinaryFromReader);
      msg.setAnalogout10v(value);
      break;
    case 51:
      var value = new common_pb.StationChannelAnalogOut10V24MA;
      reader.readMessage(value,common_pb.StationChannelAnalogOut10V24MA.deserializeBinaryFromReader);
      msg.setAnalogout10v24ma(value);
      break;
    case 52:
      var value = new common_pb.StationChannelAnalogOut100MA;
      reader.readMessage(value,common_pb.StationChannelAnalogOut100MA.deserializeBinaryFromReader);
      msg.setAnalogout100ma(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.ActuatorTypeServoHydraulic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.ActuatorTypeServoHydraulic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.ActuatorTypeServoHydraulic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.ActuatorTypeServoHydraulic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAnalogout10v();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      common_pb.StationChannelAnalogOut10V.serializeBinaryToWriter
    );
  }
  f = message.getAnalogout10v24ma();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      common_pb.StationChannelAnalogOut10V24MA.serializeBinaryToWriter
    );
  }
  f = message.getAnalogout100ma();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      common_pb.StationChannelAnalogOut100MA.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServoChannelType channel_type = 1;
 * @return {!proto.system.ServoChannelType}
 */
proto.system.ActuatorTypeServoHydraulic.prototype.getChannelType = function() {
  return /** @type {!proto.system.ServoChannelType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.system.ServoChannelType} value
 * @return {!proto.system.ActuatorTypeServoHydraulic} returns this
 */
proto.system.ActuatorTypeServoHydraulic.prototype.setChannelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional common.StationChannelAnalogOut10V analogOut10V = 50;
 * @return {?proto.common.StationChannelAnalogOut10V}
 */
proto.system.ActuatorTypeServoHydraulic.prototype.getAnalogout10v = function() {
  return /** @type{?proto.common.StationChannelAnalogOut10V} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelAnalogOut10V, 50));
};


/**
 * @param {?proto.common.StationChannelAnalogOut10V|undefined} value
 * @return {!proto.system.ActuatorTypeServoHydraulic} returns this
*/
proto.system.ActuatorTypeServoHydraulic.prototype.setAnalogout10v = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorTypeServoHydraulic} returns this
 */
proto.system.ActuatorTypeServoHydraulic.prototype.clearAnalogout10v = function() {
  return this.setAnalogout10v(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorTypeServoHydraulic.prototype.hasAnalogout10v = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional common.StationChannelAnalogOut10V24MA analogOut10V24MA = 51;
 * @return {?proto.common.StationChannelAnalogOut10V24MA}
 */
proto.system.ActuatorTypeServoHydraulic.prototype.getAnalogout10v24ma = function() {
  return /** @type{?proto.common.StationChannelAnalogOut10V24MA} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelAnalogOut10V24MA, 51));
};


/**
 * @param {?proto.common.StationChannelAnalogOut10V24MA|undefined} value
 * @return {!proto.system.ActuatorTypeServoHydraulic} returns this
*/
proto.system.ActuatorTypeServoHydraulic.prototype.setAnalogout10v24ma = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorTypeServoHydraulic} returns this
 */
proto.system.ActuatorTypeServoHydraulic.prototype.clearAnalogout10v24ma = function() {
  return this.setAnalogout10v24ma(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorTypeServoHydraulic.prototype.hasAnalogout10v24ma = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional common.StationChannelAnalogOut100MA analogOut100MA = 52;
 * @return {?proto.common.StationChannelAnalogOut100MA}
 */
proto.system.ActuatorTypeServoHydraulic.prototype.getAnalogout100ma = function() {
  return /** @type{?proto.common.StationChannelAnalogOut100MA} */ (
    jspb.Message.getWrapperField(this, common_pb.StationChannelAnalogOut100MA, 52));
};


/**
 * @param {?proto.common.StationChannelAnalogOut100MA|undefined} value
 * @return {!proto.system.ActuatorTypeServoHydraulic} returns this
*/
proto.system.ActuatorTypeServoHydraulic.prototype.setAnalogout100ma = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.system.ActuatorTypeServoHydraulic} returns this
 */
proto.system.ActuatorTypeServoHydraulic.prototype.clearAnalogout100ma = function() {
  return this.setAnalogout100ma(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorTypeServoHydraulic.prototype.hasAnalogout100ma = function() {
  return jspb.Message.getField(this, 52) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.ActuatorTypePiezoDriver.prototype.toObject = function(opt_includeInstance) {
  return proto.system.ActuatorTypePiezoDriver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.ActuatorTypePiezoDriver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.ActuatorTypePiezoDriver.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.ActuatorTypePiezoDriver}
 */
proto.system.ActuatorTypePiezoDriver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.ActuatorTypePiezoDriver;
  return proto.system.ActuatorTypePiezoDriver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.ActuatorTypePiezoDriver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.ActuatorTypePiezoDriver}
 */
proto.system.ActuatorTypePiezoDriver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.ActuatorTypePiezoDriver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.ActuatorTypePiezoDriver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.ActuatorTypePiezoDriver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.ActuatorTypePiezoDriver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.system.ActuatorTypePiezoDriver.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.ActuatorTypePiezoDriver} returns this
 */
proto.system.ActuatorTypePiezoDriver.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.system.ActuatorTypePiezoDriver} returns this
 */
proto.system.ActuatorTypePiezoDriver.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.system.ActuatorTypePiezoDriver.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.AxisRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.system.AxisRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.AxisRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.AxisRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actuatorId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    setpointIndex: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cycleIndex: jspb.Message.getFieldWithDefault(msg, 6, 0),
    stageIndex: jspb.Message.getFieldWithDefault(msg, 7, 0),
    completeIndex: jspb.Message.getFieldWithDefault(msg, 8, 0),
    sharedmemAddr: jspb.Message.getFieldWithDefault(msg, 9, 0),
    compSetpointIndex: jspb.Message.getFieldWithDefault(msg, 10, 0),
    measurementIdIndex: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.AxisRequest}
 */
proto.system.AxisRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.AxisRequest;
  return proto.system.AxisRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.AxisRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.AxisRequest}
 */
proto.system.AxisRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.system.AxisType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuatorId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSetpointIndex(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCycleIndex(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStageIndex(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCompleteIndex(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSharedmemAddr(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCompSetpointIndex(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMeasurementIdIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.AxisRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.AxisRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.AxisRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.AxisRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getActuatorId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSetpointIndex();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getCycleIndex();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getStageIndex();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getCompleteIndex();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getSharedmemAddr();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getCompSetpointIndex();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getMeasurementIdIndex();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.system.AxisRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.AxisRequest} returns this
 */
proto.system.AxisRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.system.AxisRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.AxisRequest} returns this
 */
proto.system.AxisRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AxisType type = 3;
 * @return {!proto.system.AxisType}
 */
proto.system.AxisRequest.prototype.getType = function() {
  return /** @type {!proto.system.AxisType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.system.AxisType} value
 * @return {!proto.system.AxisRequest} returns this
 */
proto.system.AxisRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string actuator_id = 4;
 * @return {string}
 */
proto.system.AxisRequest.prototype.getActuatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.AxisRequest} returns this
 */
proto.system.AxisRequest.prototype.setActuatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 setpoint_index = 5;
 * @return {number}
 */
proto.system.AxisRequest.prototype.getSetpointIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.system.AxisRequest} returns this
 */
proto.system.AxisRequest.prototype.setSetpointIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 cycle_index = 6;
 * @return {number}
 */
proto.system.AxisRequest.prototype.getCycleIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.system.AxisRequest} returns this
 */
proto.system.AxisRequest.prototype.setCycleIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 stage_index = 7;
 * @return {number}
 */
proto.system.AxisRequest.prototype.getStageIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.system.AxisRequest} returns this
 */
proto.system.AxisRequest.prototype.setStageIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 complete_index = 8;
 * @return {number}
 */
proto.system.AxisRequest.prototype.getCompleteIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.system.AxisRequest} returns this
 */
proto.system.AxisRequest.prototype.setCompleteIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 sharedmem_addr = 9;
 * @return {number}
 */
proto.system.AxisRequest.prototype.getSharedmemAddr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.system.AxisRequest} returns this
 */
proto.system.AxisRequest.prototype.setSharedmemAddr = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 comp_setpoint_index = 10;
 * @return {number}
 */
proto.system.AxisRequest.prototype.getCompSetpointIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.system.AxisRequest} returns this
 */
proto.system.AxisRequest.prototype.setCompSetpointIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 measurement_id_index = 11;
 * @return {number}
 */
proto.system.AxisRequest.prototype.getMeasurementIdIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.system.AxisRequest} returns this
 */
proto.system.AxisRequest.prototype.setMeasurementIdIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.system.AuxDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.system.AuxDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.system.AuxDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.AuxDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    axisId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    direction: jspb.Message.getFieldWithDefault(msg, 7, 0),
    channelId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.system.AuxDeviceRequest}
 */
proto.system.AuxDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.system.AuxDeviceRequest;
  return proto.system.AuxDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.system.AuxDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.system.AuxDeviceRequest}
 */
proto.system.AuxDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.system.AuxiliaryDeviceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    case 7:
      var value = /** @type {!proto.common.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.system.AuxDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.system.AuxDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.system.AuxDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.system.AuxDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAxisId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.system.AuxDeviceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.AuxDeviceRequest} returns this
 */
proto.system.AuxDeviceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.system.AuxDeviceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.AuxDeviceRequest} returns this
 */
proto.system.AuxDeviceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AuxiliaryDeviceType type = 3;
 * @return {!proto.system.AuxiliaryDeviceType}
 */
proto.system.AuxDeviceRequest.prototype.getType = function() {
  return /** @type {!proto.system.AuxiliaryDeviceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.system.AuxiliaryDeviceType} value
 * @return {!proto.system.AuxDeviceRequest} returns this
 */
proto.system.AuxDeviceRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string axis_id = 6;
 * @return {string}
 */
proto.system.AuxDeviceRequest.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.AuxDeviceRequest} returns this
 */
proto.system.AuxDeviceRequest.prototype.setAxisId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional common.Direction direction = 7;
 * @return {!proto.common.Direction}
 */
proto.system.AuxDeviceRequest.prototype.getDirection = function() {
  return /** @type {!proto.common.Direction} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.Direction} value
 * @return {!proto.system.AuxDeviceRequest} returns this
 */
proto.system.AuxDeviceRequest.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string channel_id = 8;
 * @return {string}
 */
proto.system.AuxDeviceRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.system.AuxDeviceRequest} returns this
 */
proto.system.AuxDeviceRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * @enum {number}
 */
proto.system.StationType = {
  STATION_TYPE_SINGLE_AXIS_STATIC_MACHINE: 0,
  STATION_TYPE_SINGLE_AXIS_DYNAMIC_MACHINE: 1,
  STATION_TYPE_PLANAR_BIAXIAL_MACHINE: 2,
  STATION_TYPE_AXIAL_TORSIONAL_MACHINE: 3,
  STATION_TYPE_MULTI_AXIS_MACHINE: 4,
  STATION_TYPE_PIEZO_DRIVER: 5
};

/**
 * @enum {number}
 */
proto.system.GenericSensorChannelType = {
  ANALOG_IN_10KSPS: 0,
  ANALOG_IN_10V24MA: 1,
  ANALOG_IN_60KSPS: 2
};

/**
 * @enum {number}
 */
proto.system.SensorType = {
  SENSOR_TYPE_DIFFERENTIAL_ENCODER: 0,
  SENSOR_TYPE_SINGLE_ENDED_ENCODER: 1,
  SENSOR_TYPE_LOAD_CELL: 2,
  SENSOR_TYPE_STRAIN_GAUGE: 3,
  SENSOR_TYPE_EXTENSOMETER: 4,
  SENSOR_TYPE_LVDT_BASED_DISPLACEMENT_SENSOR: 5,
  SENSOR_TYPE_GENERIC_SENSOR: 6,
  SENSOR_TYPE_GAUGE_METER: 7,
  SENSOR_TYPE_PRESSURE_TRANSDUCER: 8,
  SENSOR_TYPE_TEMPERATURE_SENSOR_PT100: 9,
  SENSOR_TYPE_INTEGRATED_ELECTRONICS_PIEZO_ELECTRIC_IEPE_SENSOR: 10,
  SENSOR_TYPE_CRACK_OPENING_DISPLACEMENT_SENSOR: 11,
  SENSOR_TYPE_VIDEO_EXTENSOMETER: 12
};

/**
 * @enum {number}
 */
proto.system.AveragingFilterPoints = {
  AVERAGING_FILTER_POINTS_2: 0,
  AVERAGING_FILTER_POINTS_4: 1,
  AVERAGING_FILTER_POINTS_8: 2,
  AVERAGING_FILTER_POINTS_16: 3,
  AVERAGING_FILTER_POINTS_32: 4,
  AVERAGING_FILTER_POINTS_64: 5,
  AVERAGING_FILTER_POINTS_128: 6
};

/**
 * @enum {number}
 */
proto.system.DataInValueType = {
  VALUE: 0,
  VARIABLE: 1,
  SPECIMEN_PARAM: 2
};

/**
 * @enum {number}
 */
proto.system.FormulaType = {
  FORMULA_TYPE_SENSOR_DATA: 0,
  FORMULA_TYPE_KX_PLUS_B: 1,
  FORMULA_TYPE_X_DIV_K: 2
};

/**
 * @enum {number}
 */
proto.system.DataInType = {
  DATA_IN_TYPE_NUMERIC: 0,
  DATA_IN_TYPE_VARIABLE: 1,
  DATA_IN_TYPE_SPECIMEN_PARAM: 2
};

/**
 * @enum {number}
 */
proto.system.SpecimenParameter = {
  SPECIMEN_PARAMETER_AREA: 0,
  SPECIMEN_PARAMETER_GAUGE_LENGTH: 1
};

/**
 * @enum {number}
 */
proto.system.DcPwmChannelType = {
  DCPWM_30V: 0,
  DCPWM_50V: 1,
  DCPWM_65V: 2
};

/**
 * @enum {number}
 */
proto.system.ServoChannelType = {
  ANALOG_OUT_10V: 0,
  ANALOG_OUT_10V_24MA: 1,
  ANALOG_OUT_100MA: 2
};

/**
 * @enum {number}
 */
proto.system.ActuatorType = {
  ACTUATOR_TYPE_DC_PWM: 0,
  ACTUATOR_TYPE_STEPPER: 1,
  ACTUATOR_TYPE_SERVO_ELECTRIC: 2,
  ACTUATOR_TYPE_SERVO_HYDRAULIC: 3,
  ACTUATOR_TYPE_PIEZO: 4,
  ACTUATOR_TYPE_TRANSDUCER_TORQUE_MOTOR: 5,
  ACTUATOR_TYPE_MICROSTEPPING_MOTOR: 6,
  ACTUATOR_TYPE_EC_MOTOR: 7,
  ACTUATOR_TYPE_SOLID_STATE_RELAY: 8
};

/**
 * @enum {number}
 */
proto.system.AxisType = {
  AXIS_TYPE_AXIAL: 0,
  AXIS_TYPE_TORSIONAL: 1
};

/**
 * @enum {number}
 */
proto.system.AuxiliaryDeviceType = {
  MECHANICAL_LIMITS: 0,
  PHYSICAL_E_STOP: 1
};

goog.object.extend(exports, proto.system);
