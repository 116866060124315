import React, { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useStationName } from '../../Stations';
import { StationRuleResponseDto, ruleTableConverter, RuleStatus, RuleListItem } from '../../Rules';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import StationRulesTable from '../components/StationRulesTable';
import { useStationStationRules } from '../stationRules.hooks';
import * as api from '../stationRules.api';
import { StationRulesTableActionTypes } from '../stationRules.enums';

const StationRulesTableContainer: React.FC = () => {
	const navigate = useNavigate();
	const { stationId } = useParams();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const queryClient = useQueryClient();

	const { stationName } = useStationName(stationId);

	const { stationStationRulesResponse, isStationStationRulesLoading, stationStationRulesQueryKey, updateRuleStatus } =
		useStationStationRules(stationId || '');

	const tableData = useMemo(() => {
		return stationStationRulesResponse?.map((rule) => ruleTableConverter(rule)) || [];
	}, [stationStationRulesResponse]);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			queryClient.setQueryData<StationRuleResponseDto[]>(stationStationRulesQueryKey, (old) => {
				if (!old) return undefined;
				return old.filter((hc) => hc.uuid !== id);
			});
			setEntityForDelete(undefined);
		},
		[queryClient, setEntityForDelete, stationStationRulesQueryKey]
	);

	const handleAction = useCallback(
		(type: StationRulesTableActionTypes, data?: RuleListItem) => {
			switch (type) {
				case StationRulesTableActionTypes.CREATE:
					navigate('./create');
					break;
				case StationRulesTableActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case StationRulesTableActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case StationRulesTableActionTypes.DELETE:
					setEntityForDelete(data);
					break;
				case StationRulesTableActionTypes.UPDATE_STATUS:
					if (data?.id !== undefined) {
						const newStatus = data.status === RuleStatus.ENABLED ? RuleStatus.DISABLED : RuleStatus.ENABLED;

						api.updateRuleStatus(data.id, newStatus).then((rule) => {
							updateRuleStatus(rule as StationRuleResponseDto);
						});
					}
			}
		},
		[navigate, updateRuleStatus]
	);

	return (
		<>
			<StationRulesTable
				onAction={handleAction}
				data={tableData}
				isLoading={isStationStationRulesLoading}
				stationName={stationName}
			/>
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.RULE}
				entity={entityForDelete}
			/>
		</>
	);
};

export default React.memo(StationRulesTableContainer);
