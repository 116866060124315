import React, { useCallback } from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { createEditTestLoopModalId } from '../../testLoops.const';
import styles from './TestLoopCreateEditModal.module.scss';

interface TestLoopCreateEditModalPropsProps {
	isCreate: boolean;
	isLoading: boolean;
	onSave: () => void;
	onClose: () => void;
	children: React.ReactElement;
}

const TestLoopCreateEditModal: React.FC<TestLoopCreateEditModalPropsProps> = ({
	isCreate,
	isLoading,
	onSave,
	onClose,
	children
}) => {
	const { t } = useTranslation('testLoops');

	const handleClose = useCallback(() => {
		Modal.hide(createEditTestLoopModalId);
		onClose();
	}, [onClose]);

	return (
		<Modal
			id={createEditTestLoopModalId}
			className={styles.container}
			shouldCloseOnOverlayClick={false}
			onClose={handleClose}
		>
			<Modal.Header>{isCreate ? t('Create a Loop') : t('Edit a Loop')}</Modal.Header>
			<Modal.Content>{children}</Modal.Content>
			<Modal.Footer>
				<Button onClick={handleClose} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button onClick={onSave} color="secondary" variant="contained" loading={isLoading}>
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(TestLoopCreateEditModal);
