import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useIsLocalDeployment } from '../../../hooks/useIsLocalDeployment';
import { useStations } from '../stations.hooks';
import StationsTableContainer from './StationsTableContainer';

const StationsMainPageContainer = () => {
	const isLocal = useIsLocalDeployment();
	const navigate = useNavigate();
	const { stations } = useStations(isLocal);

	useEffect(() => {
		if (isLocal && stations?.length) {
			navigate(`/stations/${stations[0].id}`);
		}
	}, [isLocal, navigate, stations]);

	return isLocal ? null : <StationsTableContainer />;
};

export default StationsMainPageContainer;
