import React, { useMemo } from 'react';
import { PageLayout, Form, InputText, Dropdown, Button, BottomBar, InfoBadge, InfoBadgeIcons } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { SystemFormType } from '../../system.types';
import styles from './System.module.scss';

interface ISystemProps {
	isCreate: boolean;
	isLoading: boolean;
	form: UseFormReturn<SystemFormType>;
	devices?: string[];
	onBack: () => void;
	onSave: () => void;
}

const System: React.FC<ISystemProps> = ({ devices = [], isCreate, isLoading, form, onBack, onSave }) => {
	const { t } = useTranslation('system');
	const {
		control,
		formState: { errors },
		register
	} = form;

	const title = useMemo<string>(() => {
		return isCreate ? t('Create System') : t('Edit System');
	}, [isCreate, t]);

	return (
		<>
			<PageLayout info={t('System')}>
				<PageLayout.Header title={title} onBack={onBack} />
				<Form className={styles.container} onSubmit={onSave} id="systemForm">
					<input type="hidden" {...register('id')} />
					<Controller
						name="name"
						control={control}
						data-testid="nameId"
						render={({ field }) => <InputText label={t('System name')} {...field} error={errors.name?.message} />}
					/>
					<Controller
						name="controllerModel"
						control={control}
						data-testid="controllerModel"
						render={({ field }) => (
							<Dropdown
								{...field}
								options={devices}
								label={t('Controller model')}
								error={errors.controllerModel?.message}
								filter
							/>
						)}
					/>
					<Controller
						name="copyrightInformation"
						control={control}
						data-testid="copyrightInformation"
						render={({ field }) => (
							<InputText label={t('Copyright information')} {...field} error={errors.copyrightInformation?.message} />
						)}
					/>
					<Controller
						name="version"
						control={control}
						data-testid="version"
						render={({ field }) => <InputText label={t('Version')} {...field} error={errors.version?.message} />}
					/>
					<Controller
						name="description"
						control={control}
						data-testid="description"
						render={({ field }) => (
							<InputText label={t('Description')} {...field} error={errors.description?.message} />
						)}
					/>
				</Form>
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button data-testid="cancelBtn" label={t('Cancel')} variant="contained" color="success" onClick={onBack} />
				<Button
					data-testid="saveBtn"
					label={t('Save')}
					variant="contained"
					color="secondary"
					type="submit"
					form="systemForm"
				/>
			</BottomBar>
		</>
	);
};

export default React.memo(System);
