import { Converter } from '../../types';
import { CalculationListItem } from '../Calculations';
import { ISpecimenTypeResponse } from '../SpecimenTypes';
import { PrecisionType } from './testReportConfiguration.enums';
import {
	TestReportConfigurationResponse,
	TestReportConfigurationForm,
	TestReportVariablesForm,
	TestReportCalculationFormType,
	ReportSpecimenParams,
	VariableReportConfig,
	TestReportConfigurationRequest,
	TestReportSpecimenFormType,
	TestReportCalculationFormListType,
	TestReportSpecimenFormListType
} from './testReportConfiguration.types';
import { VariableListItem } from '../Variables';

export const responseFormConverter: Converter<TestReportConfigurationResponse, TestReportConfigurationForm> = (
	input
) => {
	return {
		uuid: input.uuid,
		pipelineUuid: input.pipelineUuid,
		pipelineName: input.pipelineName,
		companyLogo: input.companyLogo,
		companyLogoId: input.companyLogoId,
		calculationResults: input.results.calculations,
		specimenParamsResults: input.results.specimenParams,
		variableResults: input.results.variables,
		additionalInformation: input.additionalInformation,
		fileOutput: input.fileOutput
	};
};

export const formRequestConverter: Converter<TestReportConfigurationForm, TestReportConfigurationRequest> = (input) => {
	return {
		uuid: input.uuid,
		pipelineUuid: input.pipelineUuid,
		pipelineName: input.pipelineName,
		companyLogo: input.companyLogo,
		companyLogoId: input.companyLogoId,
		additionalInformation: input.additionalInformation,
		fileOutput: input.fileOutput,
		results: {
			calculations: input.calculationResults,
			specimenParams: input.specimenParamsResults,
			variables: input.variableResults
		}
	};
};

export const calculationItemReportConfigConverter: Converter<CalculationListItem, TestReportCalculationFormType> = (
	input
) => {
	return {
		id: input.id,
		name: input.name,
		isSelected: false,
		precisionType: PrecisionType.DECIMAL_PLACES,
		includeInStatistics: false,
		includeSettings: false,
		precision: 0
	};
};

export const variableItemReportConfigConverter: Converter<VariableListItem, VariableReportConfig> = (input) => {
	return {
		id: input.id,
		name: input.name,
		type: PrecisionType.DECIMAL_PLACES
	};
};

export const specimenTypeResponseReportItemConverter: Converter<ISpecimenTypeResponse, TestReportSpecimenFormType[]> = (
	input
) => {
	return Object.keys(input.dimensions).map((name) => ({
		name,
		precisionType: PrecisionType.DECIMAL_PLACES,
		precision: 0,
		isSelected: false
	}));
};

export const formCalculationFormConverter: Converter<TestReportConfigurationForm, TestReportCalculationFormListType> = (
	input
) => {
	return {
		calculationResults: input.calculationResults.map((item) => ({ ...item, isSelected: false }))
	};
};

export const formSpecimenFormConverter: Converter<TestReportConfigurationForm, TestReportSpecimenFormListType> = (
	input
) => {
	return {
		// @ts-ignore
		specimenParamsResults: Object.keys(input.specimenParamsResults).map((name) => ({ name, ...input[name] }))
	};
};

export const formVariableFormConverter: Converter<TestReportConfigurationForm, TestReportVariablesForm> = (input) => {
	return {
		variableResults: input.variableResults.map((item) => ({ ...item, isSelected: false }))
	};
};

export const specimenReportListItemConverter: Converter<TestReportSpecimenFormType[], ReportSpecimenParams> = (
	input
) => {
	return input.reduce<ReportSpecimenParams>((res, current) => {
		const { name, ...config } = current;
		res[name] = config;
		return res;
	}, {});
};

export const specimenParamsConverter: Converter<ReportSpecimenParams, TestReportSpecimenFormType[]> = (input) => {
	return Object.keys(input).map((name) => ({
		...input[name],
		name,
		isSelected: false
	}));
};
