import React, { useCallback } from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { testAxesConfigurationModalId } from '../../testAxesConfiguration.const';
import styles from './TestAxesConfigurationModal.module.scss';

interface ITestAxesConfigurationModalProps {
	isEdit?: boolean;
	children: React.ReactNode;
}

const TestAxesConfigurationModal: React.FC<ITestAxesConfigurationModalProps> = ({ children, isEdit }) => {
	const { t } = useTranslation('dataLoggings');

	const handleClose = useCallback(() => {
		Modal.hide(testAxesConfigurationModalId);
	}, []);

	return (
		<Modal id={testAxesConfigurationModalId} className={styles.container} shouldCloseOnOverlayClick={false}>
			<Modal.Header>{isEdit ? t('Edit Axis Configuration') : t('Add Axis Configuration')}</Modal.Header>
			<Modal.Content>{children}</Modal.Content>
			<Modal.Footer>
				<Button onClick={handleClose} color="success" variant="text" data-testid="cancelAxisId">
					{t('Cancel')}
				</Button>
				<Button
					type="submit"
					color="secondary"
					variant="contained"
					form={testAxesConfigurationModalId}
					data-testid="saveAxisId"
				>
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(TestAxesConfigurationModal);
