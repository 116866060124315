import React, { useMemo } from 'react';
import type { FC } from 'react';
import styles from './ConditionCard.module.scss';
import { InputText, KebabMenu } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import {
	Condition,
	ConditionsAuxDeviceProperties,
	ConditionsConnectionProperties,
	ConditionsDigitalInputProperties,
	ConditionsObjectTypes,
	ConditionsStationProperties,
	ConditionsTestProperties,
	ConditionsUIButtonProperties,
	EqualConditions,
	InRangeConditions
} from '../../conditions.enums';
import { getEntityName } from '../../../../tools';
import { MeasurementResponseDto } from '../../../Measurements';
import { CalculationResponseDto } from '../../../Calculations';
import { IStationChannelResponseDto } from '../../../StationChannels';
import { IAuxiliaryDeviceResponseDto } from '../../../AuxiliaryDevices';
import { RuleCondition } from '../../conditions.types';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';

interface ConditionCardProps {
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	calculations: CalculationResponseDto[];
	digitalChannels: IStationChannelResponseDto[];
	auxDevices: IAuxiliaryDeviceResponseDto[];
	ruleCondition: RuleCondition;
	onEdit: () => void;
	onDelete: () => void;
}

const ConditionCard: FC<ConditionCardProps> = ({
	ruleCondition,
	measurements,
	calculations,
	digitalChannels,
	auxDevices,
	onEdit,
	onDelete
}) => {
	const { t } = useTranslation('rulesCondition');
	const { objectType, objectId, condition, property, operation, lowerLimit, upperLimit, threshold } = ruleCondition;

	const label = useMemo(() => {
		if (objectType === undefined || objectType === null) {
			return '';
		}

		switch (objectType) {
			case ConditionsObjectTypes.DIGITAL_INPUT:
				return t('Digital input: {{name}}', { name: getEntityName(digitalChannels, objectId) });

			case ConditionsObjectTypes.CALCULATION:
				return t('Calculation: {{name}}', { name: getEntityName(calculations, objectId) });

			case ConditionsObjectTypes.MEASUREMENT:
				return t('Measurement: {{name}}', { name: getEntityName(measurements, objectId) });

			case ConditionsObjectTypes.AUX_DEVICE:
				return t('Auxiliary device: {{name}}', { name: getEntityName(auxDevices, objectId) });

			default:
				return t(ConditionsObjectTypes[objectType]);
		}
	}, [objectType, digitalChannels, objectId, calculations, measurements, auxDevices, t]);

	const conditionLabel = useMemo(() => {
		switch (objectType) {
			case ConditionsObjectTypes.STATION:
				return t(EqualConditions[operation as EqualConditions]);
			case ConditionsObjectTypes.CALCULATION:
				return t(InRangeConditions[condition as InRangeConditions]);
			case ConditionsObjectTypes.MEASUREMENT:
				return t(Condition[condition as Condition]);
			default:
				return t(EqualConditions[EqualConditions.EQUALS]);
		}
	}, [condition, objectType, operation, t]);

	const value = useMemo(() => {
		switch (objectType) {
			case ConditionsObjectTypes.STATION:
				return t(ConditionsStationProperties[property as ConditionsStationProperties]);
			case ConditionsObjectTypes.DIGITAL_INPUT:
				return t(ConditionsDigitalInputProperties[property as ConditionsDigitalInputProperties]);
			case ConditionsObjectTypes.TEST:
				return t(ConditionsTestProperties[property as ConditionsTestProperties]);
			case ConditionsObjectTypes.AUX_DEVICE:
				return t(ConditionsAuxDeviceProperties[property as ConditionsAuxDeviceProperties]);
			case ConditionsObjectTypes.CONNECTION:
				return t(ConditionsConnectionProperties[property as ConditionsConnectionProperties]);
			case ConditionsObjectTypes.CALCULATION: {
				const selectedMeasurement = measurements.find((measurement) => measurement.id === objectId);
				return `${lowerLimit} - ${upperLimit} ${selectedMeasurement?.unit.name}`;
			}
			case ConditionsObjectTypes.MEASUREMENT:
				const selectedMeasurement = measurements.find((measurement) => measurement.id === objectId);
				return condition === Condition.IN_RANGE || condition === Condition.OUT_OF_RANGE
					? `${lowerLimit} - ${upperLimit} ${selectedMeasurement?.unit.name}`
					: `${threshold} ${selectedMeasurement?.unit.name}`;
			case ConditionsObjectTypes.UI_BUTTON:
				return t(ConditionsUIButtonProperties[property as ConditionsUIButtonProperties]);
			default:
				return '';
		}
	}, [condition, lowerLimit, measurements, objectId, objectType, property, t, threshold, upperLimit]);

	const actions = useMemo(() => {
		return [
			{ label: t('Edit'), command: () => onEdit() },
			{ label: t('Delete'), command: () => onDelete() }
		];
	}, [t, onEdit, onDelete]);
	return (
		<div className={styles.container}>
			<InputText data-testid="ruleConditionLabel" className={styles.label} name={label} value={label} disabled />
			<InputText
				data-testid="ruleConditioCond"
				className={styles.condition}
				name={conditionLabel}
				value={conditionLabel}
				disabled
			/>
			<InputText data-testid="ruleConditionValue" className={styles.value} name={value} value={value} disabled />
			<div className="iconMore">
				<KebabMenu data-testid="ruleConditionMore" model={actions} />
			</div>
		</div>
	);
};

export default ConditionCard;
