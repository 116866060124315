import React, { useMemo } from 'react';
import { Form, InputText, Dropdown, Switch, DropdownOptionString } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { SelectItem } from 'primereact/selectitem';
import { IStationChannelResponseDto, ControllerChannelMeta } from '../../../StationChannels';
import { isFieldVisible } from './sensorFieldMapping';
import { SensorsTypes } from '../../sensors.enums';
import { IListItem } from '../../../../types';
import { useNumberEnumList } from '../../../../hooks';
import { SensorFormType } from '../../sensors.types';
import styles from './SensorForm.module.scss';
import { typesWithInversePolarity } from '../../../Calibrations';

interface ISensorFormProps {
	formId: string;
	form: UseFormReturn<SensorFormType>;
	onSave: () => void;
	stationChannels: IStationChannelResponseDto[];
	stationChannelMeta?: ControllerChannelMeta;
	manufacturerOptions: IListItem[];
	quantitiesOptions: DropdownOptionString[];
}

const SensorForm: React.FC<ISensorFormProps> = ({
	formId,
	form,
	onSave,
	stationChannels,
	stationChannelMeta,
	manufacturerOptions,
	quantitiesOptions
}) => {
	const { t } = useTranslation('sensors');
	const {
		control,
		formState: { errors },
		watch,
		register
	} = form;

	const type = watch('type');
	const isAveragingFilter = watch('isAveragingFilter');

	const typeOption = useNumberEnumList(SensorsTypes);

	const stationChannelsOptions = useMemo<SelectItem[] | undefined>(() => {
		return stationChannels.map((channel) => ({
			label: channel.name,
			value: channel.id
		}));
	}, [stationChannels]);

	return (
		<Form className={styles.container} onSubmit={onSave} id={formId}>
			<input type="hidden" {...register('stationId')} />
			<input type="hidden" {...register('id')} />
			<Controller
				name="name"
				control={control}
				render={({ field }) => (
					<InputText data-testid="nameId" label={t('Sensor name*')} {...field} error={errors.name?.message} />
				)}
			/>
			<Controller
				name="type"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						data-testid="type"
						options={typeOption}
						label={t('Sensor type*')}
						error={errors.type?.message}
						filter
					/>
				)}
			/>
			<Controller
				name="stationChannelId"
				control={control}
				render={({ field }) => (
					<>
						<Dropdown
							{...field}
							options={stationChannelsOptions}
							label={t('Channel*')}
							data-testid="stationChannelId"
							error={errors.stationChannelId?.message}
							filter
						/>
					</>
				)}
			/>
			<Controller
				name="unitId"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						options={quantitiesOptions}
						label={t('Unit*')}
						error={errors.unitId?.message}
						data-testid="unit"
					/>
				)}
			/>
			<Controller
				name="manufacturer"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						data-testid="manufacturer"
						label={t('Manufacturer')}
						options={manufacturerOptions}
						error={errors.manufacturer?.message}
						editable
						itemName={t('Manufacturer')}
					/>
				)}
			/>
			<Controller
				name="serialNumber"
				control={control}
				render={({ field }) => (
					<InputText
						data-testid="serialNumber"
						label={t('Serial number')}
						{...field}
						error={errors.serialNumber?.message}
					/>
				)}
			/>
			<Controller
				name="isShowRawData"
				control={control}
				render={({ field }) => (
					<Switch
						label={t('Show uncalibrated data')}
						inputId={field.name}
						data-testid="isShowRawData"
						onChange={(e) => field.onChange(e.value)}
						className={styles.switchCustomForm}
						checked={!!field.value}
					/>
				)}
			/>

			{isFieldVisible('averagingFilterPoint', type) && (
				<div className={styles.averagingFilter}>
					<Controller
						name="isAveragingFilter"
						control={control}
						render={({ field }) => (
							<>
								<Switch
									label={t('Averaging Filter')}
									inputId={field.name}
									data-testid="isAveragingFilter"
									onChange={(e) => field.onChange(e.value)}
									className={styles.switchCustomForm}
									checked={!!field.value}
								/>
							</>
						)}
					/>
					{isAveragingFilter && (
						<Controller
							name="averagingFilterPoint"
							control={control}
							render={({ field }) => (
								<Dropdown
									{...field}
									options={stationChannelMeta?.averagingFilterPoints}
									data-testid="averagingFilterPoint"
									error={errors.averagingFilterPoint?.message}
									className={styles.avFilter}
									filter
								/>
							)}
						/>
					)}
				</div>
			)}
			{typesWithInversePolarity.some((t) => t === type) && (
				<Controller
					name="isInversePolarity"
					control={control}
					render={({ field }) => (
						<Switch
							label={t('Inverse Polarity')}
							inputId={field.name}
							data-testid="isInversePolarity"
							onChange={(e) => field.onChange(e.value)}
							className={styles.switchCustomForm}
							checked={!!field.value}
						/>
					)}
				/>
			)}
		</Form>
	);
};

export default React.memo(SensorForm);
