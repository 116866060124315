import { useMemo } from 'react';
import { StationStatuses } from './dashboardButton.enums';
import { useConnectedStationStatus } from '../Connection/connection.hooks';
import { useStationHash } from '../Stations';

export const useConnectedDeviceStatus = () => {
	const { stationStatus } = useConnectedStationStatus();
	const { hash, isStationNotFound } = useStationHash(stationStatus?.id);

	return useMemo(() => {
		const isConfigured = stationStatus !== undefined;
		const isOutdated = hash && stationStatus?.hash && hash !== stationStatus?.hash && isConfigured;
		let status = StationStatuses.UNCONFIGURED;
		if (isConfigured) {
			if (isStationNotFound) {
				status = StationStatuses.UNKNOWN_STATION;
			} else if (isOutdated) {
				status = StationStatuses.OUTDATED;
			} else if (hash !== undefined && hash === stationStatus?.hash) {
				status = StationStatuses.CONFIGURED;
			}
		}
		return { status, stationId: stationStatus?.id, state: stationStatus?.state };
	}, [hash, isStationNotFound, stationStatus]);
};
