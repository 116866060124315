import React, { useCallback, useMemo } from 'react';
import { Button, DataTable, KebabMenu } from '@tactun/ui';
import { IconButton } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { TestDataLoggingListItem } from '../../testDataLogging.types';
import { TestDataLoggingActionTypes } from '../../testDataLogging.enums';
import { ITableColumns } from '../../../../types';

import styles from './TestDataLoggingTable.module.scss';

interface ITestDataLoggingTableProps {
	onAction: (type: TestDataLoggingActionTypes, data?: TestDataLoggingListItem) => void;
	data?: TestDataLoggingListItem[];
	isLoading: boolean;
}

const TestDataLoggingTable: React.FC<ITestDataLoggingTableProps> = ({ onAction, data, isLoading }) => {
	const { t } = useTranslation('dataLoggings');
	const { t: common } = useTranslation('common');

	const transColumns = useMemo<ITableColumns<TestDataLoggingListItem>[]>(() => {
		return [
			{ field: 'name', header: t('Data Logging Name') },
			{ field: 'channels', header: t('Data Channels') },
			{
				field: 'type',
				header: t('Data Logging Type'),
				body: (data: TestDataLoggingListItem) => {
					return common(data.type);
				}
			}
		];
	}, [t, common]);

	const columnComponents = transColumns.map((col) => <DataTable.Column resizeable key={col.field} {...col} />);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(TestDataLoggingActionTypes.DELETE, data)
			}
		],
		[onAction, t]
	);

	return (
		<>
			<div className={styles.title}>{t('Data loggings of the current stage')}</div>
			<DataTable
				className={styles.dataLoggingTable}
				value={data}
				loading={isLoading}
				dataKey="id"
				showGridlines
				columnResizeMode="fit"
			>
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(TestDataLoggingActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="duplicate"
							fontSize="1.2rem"
							title={t('Duplicate')}
							disabled
							onClick={() => onAction(TestDataLoggingActionTypes.DUPLICATE, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
			<Button
				variant="contained"
				color="primary"
				icon="t-icon-add"
				label={t('Create a data logging')}
				onClick={() => onAction(TestDataLoggingActionTypes.CREATE)}
				className={styles.createBtn}
			/>
		</>
	);
};

export default React.memo(TestDataLoggingTable);
