import { ChannelType } from '@tactun/grpc-client';
import { difference } from 'lodash';
import { IAxisResponseDto } from '../Axes';
import { Channel } from '../Dashboard/dashboard.types';
import { DataChannelDropdownKeyValue } from '../DataChannel';
import { MeasurementResponseDto, SimplifiedMeasurement } from '../Measurements';
import { QuantityResponseDto } from '../Units';
import { GraphColors } from './graphWidget.constants';

export const generatePlotId = (channel: Channel) => `${channel.id}_${channel.type}`;

export const getPLotColorGenerator = (initialUsedColors: string[] = []) => {
	let usedColors = [...initialUsedColors];
	return () => {
		const unusedColors = difference(GraphColors, usedColors);
		const color = unusedColors[0] || GraphColors[0];
		usedColors.push(color);

		return color;
	};
};

export const generatePlotTitle = (
	channel: Channel,
	measurements: MeasurementResponseDto[],
	axes: IAxisResponseDto[],
	timeUnit?: string
) => {
	switch (channel.type) {
		case ChannelType.MEASUREMENT: {
			const measurement = measurements.find(({ id }) => id === channel.id);
			if (!measurement) return '';

			const name = measurement?.name || '';
			const unit = measurement?.unit?.name || '';
			return `${name} (${unit})`;
		}
		case ChannelType.TIME: {
			return `Time ${timeUnit ? `(${timeUnit})` : ''}`;
		}
		case ChannelType.CYCLE:
		case ChannelType.SET_POINT:
		case ChannelType.COMP_SET_POINT:
			const axis = axes.find(({ id }) => id === channel.id);
			return axis?.name || '';
		default:
			return '';
	}
};

export const generateAxisTitle = (
	channel: Channel,
	measurements: MeasurementResponseDto[],
	quantities: QuantityResponseDto[],
	timeUnit?: string
) => {
	if (!channel) return '';

	switch (channel.type) {
		case ChannelType.MEASUREMENT: {
			const measurement = measurements.find(({ id }) => id === channel.id);
			if (!measurement) return '';

			const quantity = quantities.find(({ id }) => id === measurement.unit.quantity);

			return `${quantity?.name} (${measurement?.unit?.name})`;
		}
		case ChannelType.TIME: {
			return `Time ${timeUnit ? `(${timeUnit})` : ''}`;
		}
		case ChannelType.CYCLE:
			return 'Cycle';
		case ChannelType.SET_POINT:
			return 'SP';
		case ChannelType.COMP_SET_POINT:
			return 'CSP';
		default:
			return '';
	}
};

export const validateGraphChannelInput = (
	dataChannels: DataChannelDropdownKeyValue[],
	simplifiedMeasurements: Record<string, SimplifiedMeasurement>
): { isValid: boolean; errorMessage: string } => {
	const hasTimeError = dataChannels.reduce<{ hasOtherChannelType: boolean; hasError: boolean }>(
		(res, channel) => {
			if (res.hasError) return res;
			if (res.hasOtherChannelType && channel.type === ChannelType.TIME) {
				res.hasError = true;
			}
			if (!res.hasOtherChannelType && channel.type !== ChannelType.TIME) {
				res.hasOtherChannelType = true;
			}

			return res;
		},
		{
			hasOtherChannelType: false,
			hasError: false
		}
	).hasError;
	const hasCycleError = dataChannels.reduce<{ hasOtherChannelType: boolean; hasError: boolean }>(
		(res, channel) => {
			if (res.hasError) return res;
			if (res.hasOtherChannelType && channel.type === ChannelType.CYCLE) {
				res.hasError = true;
			}
			if (!res.hasOtherChannelType && channel.type !== ChannelType.CYCLE) {
				res.hasOtherChannelType = true;
			}

			return res;
		},
		{
			hasOtherChannelType: false,
			hasError: false
		}
	).hasError;

	if (hasCycleError) {
		return { isValid: false, errorMessage: 'The Cycle cannot combined to the other type of channels' };
	}
	if (hasTimeError) {
		return { isValid: false, errorMessage: 'The Time cannot combined to the other type of channels' };
	}

	let isValid = true;
	let errorMessage = '';
	const measurementPlot = dataChannels.find((channel) => channel.type === ChannelType.MEASUREMENT);
	const axisMeasurement = measurementPlot?.id ? simplifiedMeasurements[measurementPlot.id] : undefined;
	if (axisMeasurement) {
		dataChannels.forEach((value) => {
			if (
				value.type === ChannelType.MEASUREMENT &&
				(simplifiedMeasurements[value.id]?.quantityId !== axisMeasurement.quantityId ||
					simplifiedMeasurements[value.id]?.unitId !== axisMeasurement.unitId)
			) {
				errorMessage = 'Data channels must be same quantity and unit';
				isValid = false;
			}
		});

		return { isValid, errorMessage };
	}

	return { isValid, errorMessage };
};
