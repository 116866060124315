import axios from '../../httpCommon';
import { IAxisResponseDto } from '../Axes';
import { AuxiliaryDeviceType, AuxiliaryDeviceDirection } from './auxiliaryDevice.enums';
import { IAuxiliaryDeviceResponseDto } from './auxiliaryDevice.types';

const url = '/quasar/auxiliary-devices';

export const getAuxiliaryDevice = async (id?: string): Promise<IAuxiliaryDeviceResponseDto> => {
	const response = await axios.get(`${url}/${id}`);

	return response.data;
};

export const getStationAuxiliaryDevices = async (stationId?: string): Promise<IAuxiliaryDeviceResponseDto[]> => {
	if (!stationId) {
		throw new Error('Auxiliary devices api: Station ID is missing');
	}

	const response = await axios.get(`${url}/station/${stationId}`);

	return response.data;
};

export const checkName = async (name: string, stationId?: string, id?: string): Promise<boolean> => {
	const response = await axios.post(`${url}/isNameUnique`, { id, parentId: stationId, name });
	return response.data === true;
};

export const createAuxiliaryDevice = async (auxiliaryDevice: any): Promise<IAuxiliaryDeviceResponseDto> => {
	const response = await axios.post(url, auxiliaryDevice);
	return response.data;
};

export const updateAuxiliaryDevice = async (auxiliaryDevice: any, id: string): Promise<IAuxiliaryDeviceResponseDto> => {
	const response = await axios.put(`${url}/${id}`, auxiliaryDevice);

	return response.data;
};

export const getTypes = async ({ stationId }: { stationId?: string }): Promise<AuxiliaryDeviceType[]> => {
	const response = await axios.get(`${url}/station/${stationId}/types`);

	return response.data;
};

export const getDirections = async ({
	stationId,
	axisId
}: {
	stationId?: string;
	axisId?: string;
}): Promise<AuxiliaryDeviceDirection[]> => {
	const response = await axios.get(`${url}/station/${stationId}/${axisId}/directions`);

	return response.data;
};

export const getAxes = async ({
	stationId,
	auxiliaryDeviceId
}: {
	stationId?: string;
	auxiliaryDeviceId?: string;
}): Promise<IAxisResponseDto[]> => {
	const response = await axios.get(`${url}/station/${stationId}/axis/${auxiliaryDeviceId || ''}`);

	return response.data;
};
