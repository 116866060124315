export const getElementWidth = (element: HTMLElement | null): number => {
	if (!element) return 0;
	const styles = window.getComputedStyle(element);
	return (
		element.offsetWidth +
		parseFloat(styles.marginLeft) +
		parseFloat(styles.marginRight) +
		parseFloat(styles.borderLeftWidth) +
		parseFloat(styles.borderRightWidth)
	);
};
