export const generalInfoPath = '/featuresets/:featuresetId';
export const generalInfoCreatePath = '/featuresets/create';
export const stationConfigurationPath = '/featuresets/:featuresetId/station-configuration';
export const testConfigurationPath = '/featuresets/:featuresetId/test-configuration';
export const testAnalysisPath = '/featuresets/:featuresetId/test-analysis';
export const triggersPath = '/featuresets/:featuresetId/triggers';
export const actionsPath = '/featuresets/:featuresetId/actions';
export const specimensPath = '/featuresets/:featuresetId/specimens';
export const calculationsPath = '/featuresets/:featuresetId/calculations';
export const widgetsPath = '/featuresets/:featuresetId/widgets';
