import {
	ControlStageRequest,
	AxisInfo,
	WFProps,
	WF_Ramp,
	DefinitionMode,
	EndChannelType,
	StopControlStage,
	ValOrId
} from '@tactun/grpc-client';
import { Converter } from '../../types';
import { IAxisResponseDto } from '../Axes';
import { responseControlAlgorithmsFormConverter } from '../ControlAlgorithms';
import { controlAlgorithmsFormTypeToAxisInfo } from '../ControlAlgorithms/controlAlgorithms.converters';
import { ControlChannelResponseDto } from '../ControlChannels';
import { WaveformDirection } from '../Waveforms';

export const axesControlChannelToControlStageStartConverter = (
	axes: IAxisResponseDto,
	controlChannel: ControlChannelResponseDto,
	dir: WaveformDirection,
	speed: number
): ControlStageRequest => {
	const startRequest = new ControlStageRequest();
	startRequest.setStationId(axes.stationId as string);

	const axis = new AxisInfo();
	axis.setAxisId(axes.id);
	axis.setAxisIndex(axes.axisIndex);
	if (controlChannel.feedbackMeasurement) {
		axis.setFbMeasurement(controlChannel.feedbackMeasurement.id);
	}

	const wf = new WFProps();

	const wfRamp = new WF_Ramp();
	wfRamp.setDefMode(DefinitionMode.ABSOLUTE);
	wfRamp.setDir(dir);

	const rate = new ValOrId();
	rate.setValue(speed);
	wfRamp.setRate(rate);

	wfRamp.setEndChannelType(EndChannelType.SET_POINT);

	const endVal = new ValOrId();
	endVal.setValue(dir === WaveformDirection.UP ? Infinity : -Infinity);
	wfRamp.setEnd(endVal);

	wf.setWfRamp(wfRamp);

	axis.setWfProps(wf);

	if (controlChannel.controlAlgorithm) {
		const controlAlgorithmsForm = responseControlAlgorithmsFormConverter(controlChannel.controlAlgorithm);
		controlAlgorithmsFormTypeToAxisInfo(controlAlgorithmsForm, axis);
	}

	startRequest.setAxesList([axis]);

	return startRequest;
};

export const axisToStopConverter: Converter<IAxisResponseDto, StopControlStage> = (axes: IAxisResponseDto) => {
	const stopRequest = new StopControlStage();
	stopRequest.setAxisIndexList([axes.axisIndex]);

	return stopRequest;
};
