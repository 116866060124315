import { FC, useCallback, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import * as api from '../specimens.api';
import { deleteEmptyFields } from '../../../tools';
import Specimens from '../components/Specimens/Specimens';
import { formRequestConverter, responseFormConverter } from '../specimens.converters';
import { SpecimenCreateErrors, SpecimenEditErrors, SpecimenModes } from '../specimens.enums';
import { useSpecimen } from '../specimens.hooks';
import { specimenSchema } from '../specimens.schemas';
import { SpecimenForm, SpecimenRequestDto } from '../specimens.types';

const SpecimensContainer: FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('specimens');
	const { mode, uuid } = useParams();
	const { specimen, isSpecimenLoading } = useSpecimen(uuid);
	const form = useForm<SpecimenForm>({
		mode: 'onBlur',
		//@ts-ignore
		resolver: yupResolver(specimenSchema),
		defaultValues: { mode: mode as SpecimenModes, quantity: 1 }
	});
	const { reset, handleSubmit, watch } = form;
	const quantity = watch('quantity');

	const handleBack = useCallback(() => {
		const specimenMode = quantity > 1 ? SpecimenModes.BATCH : SpecimenModes.SINGLE;
		navigate(`/specimen-list/${specimenMode}`);
	}, [navigate, quantity]);

	const handleSaveSuccess = useCallback(() => {
		const specimenMode = quantity > 1 ? SpecimenModes.BATCH : SpecimenModes.SINGLE;
		navigate(`/specimen-list/${specimenMode}`);
	}, [quantity, navigate]);

	const createMutation = useMutation({
		mutationFn: (specimen: SpecimenRequestDto) => api.createSpecimen(specimen),
		onSuccess: () => handleSaveSuccess(),
		onError: (e: Error & { response: any }) => {
			toast.error(
				SpecimenCreateErrors[e?.response?.data?.error] ? t(SpecimenCreateErrors[e?.response?.data?.error]) : e.message
			);
		}
	});

	const updateMutation = useMutation({
		mutationFn: (specimen: SpecimenRequestDto) => api.updateSpecimen(specimen),
		onSuccess: () => handleSaveSuccess(),
		onError: (e: Error & { response: any }) => {
			toast.error(
				SpecimenEditErrors[e?.response?.data?.error] ? t(SpecimenEditErrors[e?.response?.data?.error]) : e.message
			);
		}
	});

	const handleSave = handleSubmit((data) => {
		const filteredData = deleteEmptyFields<SpecimenRequestDto>(formRequestConverter(data));

		if (uuid) {
			updateMutation.mutate(filteredData);
		} else {
			createMutation.mutate(filteredData);
		}
	});

	useEffect(() => {
		if (specimen) {
			reset(responseFormConverter(specimen));
		}
	}, [reset, specimen]);

	return (
		<FormProvider {...form}>
			<Specimens isLoading={isSpecimenLoading} isCreate={!uuid} onBack={handleBack} onSave={handleSave} />
		</FormProvider>
	);
};

export default SpecimensContainer;
