import { Converter } from '../../types';
import { CalculatorValueItem } from './calculator.types';
import {
	CalculatorConstValues,
	CalculatorDigitValues,
	CalculatorFunctionValues,
	CalculatorFunctionValuesUserFriendly,
	CalculatorOperatorValues,
	CalculatorValueTypes
} from './calculator.enums';

export const calculatorValueToUserFriendly: Converter<string, string> = (str) => {
	return calculatorValueListToUserFriendly(calculatorStringValueToList(str));
};

export const calculatorValueReplaceVariableNames = (str: string, variablesMap: Record<string, string>): string => {
	const listValue = calculatorStringValueToList(str);

	const newListValue = listValue.map((item) => {
		if (item.type !== CalculatorValueTypes.VARIABLE) return item;
		return {
			...item,
			value: variablesMap[item.value] || item.value
		};
	});
	return calculatorValueListToString(newListValue);
};

export const calculatorValueReplaceVariableValues = (str: string, variablesMap: Record<string, number>): string => {
	const listValue = calculatorStringValueToList(str);

	return listValue
		.map((item) => {
			if (item.type !== CalculatorValueTypes.VARIABLE) return item.value;
			return variablesMap[item.value]?.toString() || '1.0';
		})
		.join('');
};

export const calculatorValueListToUserFriendly: Converter<CalculatorValueItem[], string> = (list) => {
	return list
		.map(({ type, value }) => {
			if (type === CalculatorValueTypes.CONST && value === CalculatorConstValues.PI) {
				return 'π';
			}
			if (type === CalculatorValueTypes.FUNCTION) {
				return CalculatorFunctionValuesUserFriendly[value as keyof typeof CalculatorFunctionValuesUserFriendly];
			}
			return value;
		})
		.join('');
};

export const calculatorValueListToString: Converter<CalculatorValueItem[], string> = (list) => {
	return list
		.map(({ type, value }) => {
			if (CalculatorValueTypes.VARIABLE === type) {
				return `{{${value}}}`;
			}
			return value;
		})
		.join('');
};

export const calculatorStringValueToList: Converter<string, CalculatorValueItem[]> = (value) => {
	let string = value;
	let prevString = '';
	const list = [];

	strLoop: while (string) {
		if (string.startsWith('-') && (!prevString || prevString.startsWith('('))) {
			list.push({ type: CalculatorValueTypes.NEGATIVE_SIGN, value: '-' });
			prevString = string;
			string = string.substring(1);
			continue;
		}

		for (let key in CalculatorOperatorValues) {
			const operator: CalculatorOperatorValues = CalculatorOperatorValues[key as keyof typeof CalculatorOperatorValues];
			if (string.startsWith(operator)) {
				list.push({ type: CalculatorValueTypes.OPERATOR, value: operator });
				prevString = string;
				string = string.substring(operator.length);
				continue strLoop;
			}
		}

		for (let key in CalculatorFunctionValues) {
			const func: CalculatorFunctionValues = CalculatorFunctionValues[key as keyof typeof CalculatorFunctionValues];
			if (string.startsWith(func)) {
				list.push({ type: CalculatorValueTypes.FUNCTION, value: func });
				prevString = string;
				string = string.substring(func.length);
				continue strLoop;
			}
		}

		for (let key in CalculatorConstValues) {
			const term: CalculatorConstValues = CalculatorConstValues[key as keyof typeof CalculatorConstValues];
			if (string.startsWith(term)) {
				list.push({ type: CalculatorValueTypes.CONST, value: term });
				prevString = string;
				string = string.substring(term.length);
				continue strLoop;
			}
		}

		for (let key in CalculatorDigitValues) {
			const digit: CalculatorDigitValues = CalculatorDigitValues[key as keyof typeof CalculatorDigitValues];
			if (string.startsWith(digit)) {
				list.push({ type: CalculatorValueTypes.DIGIT, value: digit });
				prevString = string;
				string = string.substring(digit.length);
				continue strLoop;
			}
		}

		if (string.startsWith('(')) {
			list.push({ type: CalculatorValueTypes.OPEN_PARENTHESIS, value: '(' });
			prevString = string;
			string = string.substring(1);
			continue;
		}

		if (string.startsWith(')')) {
			list.push({ type: CalculatorValueTypes.CLOSE_PARENTHESIS, value: ')' });
			prevString = string;
			string = string.substring(1);
			continue;
		}

		if (string.startsWith('.')) {
			list.push({ type: CalculatorValueTypes.DECIMAL_POINT, value: '.' });
			prevString = string;
			string = string.substring(1);
			continue;
		}

		if (string.startsWith('{{')) {
			const variable = string.substring(2, string.indexOf('}'));
			list.push({ type: CalculatorValueTypes.VARIABLE, value: variable });
			prevString = string;
			string = string.substring(variable.length + 4);
			continue;
		}

		string = string.substring(1);
	}

	return list;
};
