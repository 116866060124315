import { useEffect, useMemo } from 'react';
import {
	Form,
	InputText,
	Switch,
	Dropdown,
	InputNumber,
	DropdownOptionString,
	GroupInputFrame,
	useIsChanged
} from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { IListItem } from '../../../../types';
import { FormulaTypes, FormulaVariablesTypes } from '../../measurements.enums';
import { QuantityResponseDto } from '../../../Units';
import MeasurementVariable from './MeasurementVariable';
import { useEntityOptions, useNumberEnumList } from '../../../../hooks';
import { SensorResponseDto } from '../../../Sensors/sensors.types';
import styles from './Measurement.module.scss';
import { MeasurementFormType } from '../../measurements.types';

interface MeasurementFormProps {
	formId: string;
	sensors: SensorResponseDto[];
	quantities: QuantityResponseDto[];
	variables: IListItem[];
	isForTest?: boolean;
	onSave: () => void;
}

const MeasurementForm: React.FC<MeasurementFormProps> = ({
	formId,
	onSave,
	sensors,
	variables,
	quantities,
	isForTest = false
}) => {
	const { t } = useTranslation('measurements');
	const variableTypeOptions = useNumberEnumList(FormulaVariablesTypes);
	const formulaOptions = useNumberEnumList(FormulaTypes, 'measurements');
	const sensorsOptions = useEntityOptions(sensors);
	const {
		control,
		watch,
		register,
		setValue,
		formState: { errors, dirtyFields }
	} = useFormContext<MeasurementFormType>();
	const isApplyFormula = watch('isApplyFormula');
	const isLimitEnabled = watch('isLimitEnabled');
	const formulaType = watch('formulaType');
	const unitId = watch('unitId');
	const formulaVariableType = watch('dataIn1.formulaVariableType');
	const formulaVariableType2 = watch('dataIn2.formulaVariableType');
	const selectedSensorId = watch('sensorId');
	const selectedSensor = useMemo<SensorResponseDto | undefined>(
		() => (selectedSensorId && sensors?.length ? sensors.find((sensor) => sensor.id === selectedSensorId) : undefined),
		[selectedSensorId, sensors]
	);

	// filter quantities related to apply formula
	const quantitiesOptions = useMemo<DropdownOptionString[]>(() => {
		if (!(quantities && selectedSensor)) return [];
		let filteredQuantities;
		if (!isApplyFormula) {
			filteredQuantities = quantities.filter((q) => q.id === selectedSensor.unit?.quantity);
		} else {
			filteredQuantities = quantities;
		}

		return filteredQuantities.map((quantity) => ({
			label: quantity.name,
			options: quantity.units.map((unit) => ({ label: unit.name, value: unit.id }))
		}));
	}, [quantities, selectedSensor, isApplyFormula]);

	const selectedUnit = useMemo<string>(() => {
		let unitName = '';

		if (unitId) {
			quantities.forEach((q) => {
				const unit = q.units.find((u) => u.id === unitId);
				if (unit) {
					unitName = ` ${unit.name}`;
				}
			});
		}
		return unitName;
	}, [quantities, unitId]);

	// Reset Data 1 and Data 2 when Apply Formula is disabled
	useEffect(() => {
		if (!isApplyFormula) {
			setValue('formulaType', null);
			setValue('dataIn1', null);
			setValue('dataIn2', null);
		}
	}, [isApplyFormula, setValue]);

	// Reset Data 1 and Data 2 when formulaType changing
	useEffect(() => {
		if (formulaType === null) {
			setValue('dataIn2', null);
			setValue('dataIn1', null);
		} else if (formulaType === FormulaTypes.DIVIDE) {
			setValue('dataIn2', null);
		}
	}, [formulaType, setValue]);

	// Reset Data 1 fields when formulaVariableType changing
	const isFormulaVariableTypeChanged = useIsChanged(formulaVariableType) && dirtyFields.dataIn1;
	useEffect(() => {
		if (formulaType !== null && isFormulaVariableTypeChanged) {
			setValue('dataIn1.data', null);
			setValue('dataIn1.variableId', null);
			setValue('dataIn1.specimenParamType', null);
		}
	}, [formulaType, isFormulaVariableTypeChanged, setValue]);

	// Reset Data 2 fields when formulaVariableType2 changing
	const isFormulaVariableType2Changed = useIsChanged(formulaVariableType2) && dirtyFields.dataIn2;
	useEffect(() => {
		if (formulaType === FormulaTypes.LINEAR && isFormulaVariableType2Changed) {
			setValue('dataIn2.data', null);
			setValue('dataIn2.variableId', null);
			setValue('dataIn2.specimenParamType', null);
		}
	}, [formulaType, isFormulaVariableType2Changed, setValue]);

	const isLimitEnabledChanged = useIsChanged(isLimitEnabled);
	useEffect(() => {
		if (!isLimitEnabled && isLimitEnabledChanged) {
			setValue('upperLimit', null);
			setValue('lowerLimit', null);
		}
	}, [isLimitEnabledChanged, isLimitEnabled, setValue]);

	// set selected sensor unit when sensor changed
	const sensorIdChanged = useIsChanged(selectedSensorId) && dirtyFields.sensorId;
	useEffect(() => {
		const sensorUnitId = selectedSensor?.unit?.id;
		if (sensorUnitId && sensorIdChanged) {
			setValue('unitId', sensorUnitId);
		}
	}, [selectedSensor, setValue, sensorIdChanged]);

	return (
		<Form className={styles.container} onSubmit={onSave} id={formId}>
			<input type="hidden" {...register('id')} />
			<input type="hidden" {...register('stationId')} />
			<Controller
				name="name"
				control={control}
				render={({ field }) => (
					<InputText
						data-testid="nameId"
						className={styles.input}
						label={t('Name*')}
						{...field}
						error={errors.name?.message}
						disabled={isForTest}
					/>
				)}
			/>
			<Controller
				name="sensorId"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						className={styles.input}
						options={sensorsOptions}
						label={t('Sensor*')}
						data-testid="sensorId"
						error={errors.sensorId?.message}
					/>
				)}
			/>
			<div className={styles.formula}>
				<Controller
					name="isApplyFormula"
					control={control}
					render={({ field }) => (
						<Switch
							label={t('Apply formula')}
							inputId={field.name}
							data-testid="isApplyFormula"
							onChange={(e) => field.onChange(e.value)}
							className={styles.switchCustomForm}
							checked={!!field.value}
							disabled={isForTest}
						/>
					)}
				/>
				{isApplyFormula && (
					<Controller
						name="formulaType"
						control={control}
						render={({ field }) => (
							<Dropdown
								data-testid="formulaType"
								className="formulaTypeDropdown"
								{...field}
								options={formulaOptions}
								label={t('')}
								error={errors.formulaType?.message}
								disabled={isForTest}
							/>
						)}
					/>
				)}
			</div>
			<div className={styles.dividerContainer}></div>
			{isApplyFormula && (
				<>
					<Controller
						name="dataIn1.formulaVariableType"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								data-testid="dataIn1FormulaVariableType"
								options={variableTypeOptions}
								label={t('Data type 1*')}
								error={errors.dataIn1?.formulaVariableType?.message}
								disabled={isForTest}
							/>
						)}
					/>
					<MeasurementVariable
						variables={variables}
						variableType={formulaVariableType}
						control={control}
						errors={errors.dataIn1}
						objName="dataIn1"
					/>
					{formulaType === FormulaTypes.LINEAR && (
						<>
							<Controller
								name="dataIn2.formulaVariableType"
								control={control}
								render={({ field }) => (
									<Dropdown
										{...field}
										options={variableTypeOptions}
										data-testid="dataIn2FormulaVariableType"
										label={t('Data type 2*')}
										error={errors.dataIn2?.formulaVariableType?.message}
										disabled={isForTest}
									/>
								)}
							/>
							<MeasurementVariable
								variables={variables}
								variableType={formulaVariableType2}
								control={control}
								errors={errors.dataIn2}
								objName="dataIn2"
							/>
						</>
					)}
					<div className={styles.dividerContainer}></div>
				</>
			)}

			<Controller
				name="unitId"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						options={quantitiesOptions}
						label={t('Measurement unit*')}
						data-testid="unitId"
						error={errors.unitId?.message}
					/>
				)}
			/>
			<Controller
				name="isLimitEnabled"
				control={control}
				render={({ field }) => (
					<Switch
						label={t('Limits')}
						inputId={field.name}
						data-testid="isLimitEnabled"
						onChange={(e) => field.onChange(e.value)}
						className={styles.switchCustomForm}
						checked={!!field.value}
						disabled={isForTest}
					/>
				)}
			/>
			{isLimitEnabled && (
				<>
					<GroupInputFrame>
						<Controller
							name="upperLimit"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Upper limit*')}
									error={errors.upperLimit?.message}
									data-testid="upperLimit"
									className={styles.limit}
									disabled={isForTest}
								/>
							)}
						/>
						<InputText disabled value={selectedUnit} />
					</GroupInputFrame>
					<GroupInputFrame>
						<Controller
							name="lowerLimit"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Lower limit*')}
									error={errors.lowerLimit?.message}
									data-testid="lowerLimit"
									className={styles.limit}
									disabled={isForTest}
								/>
							)}
						/>
						<InputText disabled value={selectedUnit} />
					</GroupInputFrame>
				</>
			)}

			<Controller
				name="isInverseReading"
				control={control}
				render={({ field }) => (
					<Switch
						label={t('Inverse reading')}
						inputId={field.name}
						data-testid="isInverseReading"
						onChange={(e) => field.onChange(e.value)}
						className={styles.switchCustomForm}
						checked={!!field.value}
						disabled={isForTest}
					/>
				)}
			/>
		</Form>
	);
};

export default MeasurementForm;
