import React, { useContext } from 'react';
import KeycloakContext from '../../context';

const withKeycloakContext = (Component: React.ComponentType) => {
	return (props: any) => {
		const context = useContext(KeycloakContext);
		return <Component {...props} keycloakContext={context} />;
	};
};

withKeycloakContext.displayName = 'withKeycloakContext';

export default withKeycloakContext;
