import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Button, DataTable, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataTableSelectionMultipleChangeEvent, DataTableValueArray } from 'primereact/datatable';
import { variableReportListSchema } from '../../testReportConfiguration.schemas';

import { TestReportVariablesForm, VariableReportConfig } from '../../testReportConfiguration.types';
import { PrecisionType, ReportResultType } from '../../testReportConfiguration.enums';
import { useTestVariables } from '../../../Variables';
import { variableItemReportConfigConverter } from '../../testReportConfiguration.converters';
import styles from './VariableReportConfigurationTable.module.scss';

export interface VariableReportConfigurationTableProps {
	values: VariableReportConfig[];
	onSubmit: (data: VariableReportConfig[]) => void;
}

const VariableReportConfigurationTable: FC<VariableReportConfigurationTableProps> = ({ values, onSubmit }) => {
	const { testId } = useParams();
	const { isLoading, testVariables } = useTestVariables(testId);
	const allVariables = useMemo<VariableReportConfig[]>(() => {
		return testVariables?.map((item) => variableItemReportConfigConverter(item)) || [];
	}, [testVariables]);
	const { t } = useTranslation('testConfiguration');
	const variableForm = useForm<TestReportVariablesForm>({
		//@ts-ignore // TODO: fix this when implement report configuration
		resolver: yupResolver(variableReportListSchema)
	});
	const { setValue, handleSubmit, watch, reset, getValues } = variableForm;
	const variables = watch('variableResults');

	const handleSelection = (event: DataTableSelectionMultipleChangeEvent<DataTableValueArray>) => {
		const newValues = generateTableData(getValues().variableResults, event.value as VariableReportConfig[]);
		setValue('variableResults', newValues);
	};

	const handleCloseModal = useCallback(() => {
		const variableResults = generateTableData(allVariables, values);
		reset({ variableResults });
		Modal.hide(ReportResultType.VARIABLE_RESULT);
	}, [reset, values, allVariables]);

	const handleUpdate = handleSubmit((data) => {
		const selectedValues = data.variableResults
			.filter(({ isSelected }) => isSelected)
			.map(({ isSelected, ...item }) => item);
		onSubmit(selectedValues);
	});

	const selectedRows = useMemo(() => {
		return variables?.filter(({ isSelected }) => isSelected) || [];
	}, [variables]);

	useEffect(() => {
		const newValues = generateTableData(allVariables, values);
		setValue('variableResults', newValues);
	}, [values, setValue, allVariables]);

	return (
		<>
			<Modal id={ReportResultType.VARIABLE_RESULT} onClose={handleCloseModal} className={styles.modalCalculation}>
				<Modal.Header>{t('Select Variables to be Included in the Report')}</Modal.Header>
				<Modal.Content>
					<DataTable
						value={variables}
						loading={isLoading}
						dataKey="id"
						showGridlines
						columnResizeMode="fit"
						selection={selectedRows}
						onSelectionChange={handleSelection}
						selectionMode="multiple"
					>
						<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
						<DataTable.Column header={t('Calculation Name')} field="name" resizeable />
						<DataTable.Column header={t('Precision Type')} body={({ type }) => PrecisionType[type]} resizeable />
					</DataTable>
				</Modal.Content>
				<Modal.Footer>
					<Button type="button" variant="text" color="success" onClick={handleCloseModal}>
						{t('Cancel')}
					</Button>
					<Button type="button" label={t('Save')} onClick={handleUpdate} />
				</Modal.Footer>
			</Modal>
		</>
	);
};

const generateTableData = (allVariables: VariableReportConfig[], selectedVariables: VariableReportConfig[]) => {
	return allVariables?.map((item) => {
		const selectedItem = selectedVariables.find(({ id }) => id === item.id);
		if (selectedItem) {
			return {
				...selectedItem,
				isSelected: true
			};
		}

		return { ...item, isSelected: false };
	});
};

export default VariableReportConfigurationTable;
