import React from 'react';
import cx from 'classnames';
import styles from './HeaderLeftNavigation.module.scss';

export interface IHeaderLeftNavigationProps {
	className?: string;
	children: React.ReactNode;
}

const HeaderLeftNavigation: React.FC<IHeaderLeftNavigationProps> = ({ className, children }) => {
	return <div className={cx(styles.container, className)}>{children}</div>;
};

export default HeaderLeftNavigation;
