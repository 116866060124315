export enum PrecisionType {
	SIGNIFICANT_DIGITS = 0,
	DECIMAL_PLACES
}

export enum ReportResultType {
	CALCULATION_RESULT = 'REPORT_RESULT_CALCULATION',
	SPECIMEN_RESULT = 'REPORT_RESULT_SPECIMEN',
	VARIABLE_RESULT = 'REPORT_RESULT_VARIABLE'
}

export enum ReportResultTypeForm {
	CALCULATION_RESULT = 'report-calculation-result-form',
	SPECIMEN_RESULT = 'report-specimen-result-form',
	VARIABLE_RESULT = 'variable-specimen-form'
}

export enum ReportFormat {
	HTML = 0,
	PDF,
	CSV
}

export enum ReportType {
	HTML = 'HTML',
	PDF = 'PDF',
	CSV = 'CSV'
}

export enum VarType {
	NUMERIC = 'NUMERIC',
	STRING = 'STRING',
	BOOLEAN = 'BOOLEAN'
}

export enum SpecimenStatus {
	NOT_TESTED = 'NOT_TESTED',
	TEST_IN_PROGRESS = 'TEST_IN_PROGRESS',
	BROKEN = 'BROKEN',
	TESTED = 'TESTED'
}
