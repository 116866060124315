// package: rule
// file: rule.proto

var rule_pb = require("./rule_pb");
var error_pb = require("./error_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var WebRules = (function () {
  function WebRules() {}
  WebRules.serviceName = "rule.WebRules";
  return WebRules;
}());

WebRules.Apply = {
  methodName: "Apply",
  service: WebRules,
  requestStream: false,
  responseStream: false,
  requestType: rule_pb.RulesRequest,
  responseType: error_pb.Error
};

exports.WebRules = WebRules;

function WebRulesClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

WebRulesClient.prototype.apply = function apply(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebRules.Apply, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.WebRulesClient = WebRulesClient;

