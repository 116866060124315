import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CustomWidgetDto } from './customWidget.types';
import * as api from './customWidget.api';
import { responseListItemConverter } from './customWidget.converters';
import {
	heightPxToRow,
	heightRowToPx,
	ROW_HEIGHT,
	widthColToPx,
	widthPxToCol
} from '../Dashboard/dashboard.converters';
import { useColumnWidth } from '../Dashboard/dashboard.hooks';
import { useMemo } from 'react';

export const useWidgets = (stationId?: string) => {
	const { data, isLoading: isWidgetsLoading } = useQuery({
		queryKey: ['widgets', { stationId }],
		queryFn: () => api.getStationWidgets(stationId as string),
		enabled: !!stationId
	});

	const queryClient = useQueryClient();

	const updateWidgets = () => {
		queryClient.invalidateQueries({ queryKey: ['widgets', { stationId }] });
	};

	return {
		widgets: data?.map((widget) => responseListItemConverter(widget)),
		isWidgetsLoading,
		updateAxes: updateWidgets
	};
};

export const useWidget = (widgetId?: string, stationId?: string) => {
	const { data: widget, isLoading: isWidgetLoading } = useQuery<CustomWidgetDto | undefined>({
		queryKey: ['widget', widgetId],
		queryFn: async (): Promise<CustomWidgetDto> => await api.getWidget(widgetId as string),
		enabled: !!widgetId
	});

	const queryClient = useQueryClient();

	const updateWidget = () => {
		queryClient.invalidateQueries({ queryKey: ['widget', { widgetId }] });
	};

	return {
		widget,
		isWidgetLoading,
		updateWidget
	};
};

export const useWidgetSizes = (
	ref: React.MutableRefObject<HTMLDivElement | null>,
	{
		hasHeader,
		hasFooter
	}: {
		hasHeader?: boolean;
		hasFooter?: boolean;
	} = {}
) => {
	const columnWidth = useColumnWidth(ref);
	const extraHeight = 2 + (hasHeader ? 32 : 0) + (hasFooter ? 32 : 0);

	return useMemo(
		() => ({
			getContentWidth: (col: number) => {
				return widthColToPx(col, columnWidth) - 2;
			},
			getValidContentWidth: (width: number) => {
				const newColW = widthPxToCol(width + 2, columnWidth);
				return widthColToPx(newColW, columnWidth) - 2;
			},
			contentWidthToWidgetCol: (width: number) => {
				const gutter = window.innerWidth >= 1920 ? 16 : 12;
				return Math.ceil((width + 2 + gutter) / (columnWidth + gutter));
			},
			getContentHeight: (row: number) => {
				return heightRowToPx(row) - extraHeight;
			},
			getValidContentHeight: (height: number) => {
				const newRowH = heightPxToRow(height + extraHeight);
				return heightRowToPx(newRowH) - extraHeight;
			},
			contentHeightToWidgetRow: (height: number) => {
				return Math.ceil((height + extraHeight + 16) / (ROW_HEIGHT + 16));
			}
		}),
		[columnWidth, extraHeight]
	);
};
