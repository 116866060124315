import React, { useCallback, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getStationChannels } from '../stationChannels.api';
import StationChannelsTable from '../components/StationChannelsTable';
import { IStationChannel } from '../stationChannels.types';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { StationChannelCrudActionTypes } from '../stationChannels.enums';
import { useStationName } from '../../Stations/stations.hooks';
import { responseListItemConverter } from '../stationChannels.converters';
import { GroupActionTypes, GroupContextProvider, GroupsTypes } from '../../Group';

const StationChannelsTableContainer: React.FC = () => {
	const navigate = useNavigate();
	const { stationId } = useParams();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const queryClient = useQueryClient();

	const { stationName } = useStationName(stationId);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			queryClient.setQueryData<IStationChannel[]>(['station-channels', stationId], (old) => {
				if (!old) return undefined;
				return old.filter((hc) => hc.id !== id);
			});
			setEntityForDelete(undefined);
		},
		[queryClient, stationId, setEntityForDelete]
	);

	const { data, isLoading } = useQuery({
		queryKey: ['station-channels', stationId],
		queryFn: async () => {
			const resp = await getStationChannels(stationId);
			return resp.map((stationChannel) => responseListItemConverter(stationChannel));
		}
	});

	const handleAction = useCallback(
		(type: StationChannelCrudActionTypes, data?: IStationChannel) => {
			switch (type) {
				case StationChannelCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case StationChannelCrudActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case StationChannelCrudActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case StationChannelCrudActionTypes.DELETE:
					setEntityForDelete(data as IStationChannel);
					break;
			}
		},
		[navigate]
	);

	const handleGroupingAction = useCallback(
		(action: GroupActionTypes) => {
			switch (action) {
				case GroupActionTypes.ADD_TO_GROUP:
				case GroupActionTypes.REMOVE_FROM_GROUP:
				case GroupActionTypes.CREATE_GROUP:
				case GroupActionTypes.RENAME_GROUP:
				case GroupActionTypes.DELETE_GROUP:
				case GroupActionTypes.BATCH_UPDATE_GROUPS:
					queryClient.invalidateQueries({ queryKey: ['station-channels', stationId] });
					break;
			}
		},
		[queryClient, stationId]
	);

	return (
		<>
			<GroupContextProvider type={GroupsTypes.STATION_CHANNEL} onAction={handleGroupingAction}>
				<StationChannelsTable onAction={handleAction} data={data} isLoading={isLoading} stationName={stationName} />
			</GroupContextProvider>
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.STATION_CHANNEL}
				entity={entityForDelete}
				checkUsage
			/>
		</>
	);
};

export default React.memo(StationChannelsTableContainer);
