import { FC, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Form, Dropdown } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGraphs } from '../../../TestDashboardConfiguration/testDashboardConfiguration.hooks';
import { graphResponseFormConverter } from '../../../TestDashboardConfiguration/testDashboardConfiguration.converter';
import { GraphFormType } from '../../graphWidget.types';
import styles from './GraphSettingsModal.module.scss';

interface GraphFormProps {
	form: UseFormReturn<GraphFormType>;
}

const formId = 'graphForm';

const GraphAddExisting: FC<GraphFormProps> = ({ form }) => {
	const { t } = useTranslation('dashboard');
	const [selectedGraph, setSelectedGraph] = useState<string | null>(null);
	const { testId } = useParams();
	const { graphs } = useGraphs<GraphFormType>(testId, graphResponseFormConverter);
	const { reset } = form;
	const options = useMemo(
		() =>
			graphs
				.filter(({ id, name }) => id !== undefined)
				.map(({ id, name }, i) => ({
					value: id as string,
					label: name
				})),
		[graphs]
	);

	const handleDropdownChange = (value: string | null) => {
		reset(graphs.find(({ id }) => id === value));
		setSelectedGraph(value);
	};

	return (
		<>
			<Form className={styles.container} id={formId}>
				<Dropdown
					name="graph"
					value={selectedGraph}
					onChange={handleDropdownChange}
					label={t('Existing Graphs *')}
					options={options}
				/>
			</Form>
		</>
	);
};

export default GraphAddExisting;
