// package: logging
// file: logging.proto

var logging_pb = require("./logging_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Logging = (function () {
  function Logging() {}
  Logging.serviceName = "logging.Logging";
  return Logging;
}());

Logging.ListLoggedTests = {
  methodName: "ListLoggedTests",
  service: Logging,
  requestStream: false,
  responseStream: false,
  requestType: logging_pb.ListLoggedTestsRequest,
  responseType: logging_pb.ListLoggedTestsResponse
};

Logging.StreamLogs = {
  methodName: "StreamLogs",
  service: Logging,
  requestStream: false,
  responseStream: false,
  requestType: logging_pb.StreamLogsRequest,
  responseType: logging_pb.StreamLogsResponse
};

Logging.DeleteLogs = {
  methodName: "DeleteLogs",
  service: Logging,
  requestStream: false,
  responseStream: false,
  requestType: logging_pb.DeleteLogsRequest,
  responseType: logging_pb.LoggingVoid
};

exports.Logging = Logging;

function LoggingClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

LoggingClient.prototype.listLoggedTests = function listLoggedTests(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Logging.ListLoggedTests, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LoggingClient.prototype.streamLogs = function streamLogs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Logging.StreamLogs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LoggingClient.prototype.deleteLogs = function deleteLogs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Logging.DeleteLogs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.LoggingClient = LoggingClient;

