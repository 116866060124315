import { VariableRequest } from '@tactun/grpc-client';
import { VariablesTypes } from './variables.enums';
import { VariableResponseDto, VariableFormType, VariableRequestDto, VariableListItem } from './variables.types';

export const responseListItemConverter = (input: VariableResponseDto): VariableListItem => ({
	id: input.uuid,
	name: input.name,
	type: VariablesTypes[input.type],
	defaultValue: input.defaultValue
});

export const responseFormConverter = (input: VariableResponseDto): VariableFormType => {
	return {
		id: input.uuid,
		name: input.name,
		type: input.type,
		defaultValue: input.defaultValue === undefined ? '' : input.defaultValue,
		stationId: input.stationUuid,
		testId: input.testUuid
	};
};

export const formRequestConverter = (input: VariableFormType): VariableRequestDto => {
	const request = {
		uuid: input.id,
		name: input.name,
		type: input.type,
		defaultValue: input.defaultValue
	} as VariableRequestDto;

	if (input.stationId) {
		request.stationUuid = input.stationId;
	}

	if (input.testId) {
		request.testUuid = input.testId;
	}
	return request;
};

export const variableResponseToVariableGRPCConverter = (variable: VariableResponseDto): VariableRequest => {
	const variableDto = new VariableRequest();
	variableDto.setId(variable.uuid);
	variableDto.setName(variable.name);
	variableDto.setType(variable.type);
	if (variable.defaultValue !== undefined) {
		if (variable.type === VariablesTypes.BOOLEAN) variableDto.setDefaultValBool(variable.defaultValue === 'true');
		if (variable.type === VariablesTypes.NUMERIC && variable.defaultValue)
			variableDto.setDefaultValNum(parseFloat(variable.defaultValue));
		if (variable.type === VariablesTypes.STRING) variableDto.setDefaultValStr(variable.defaultValue as string);
	}

	return variableDto;
};

export const variableResponsesToVariableGRPCsConverter = (variables: VariableResponseDto[]): VariableRequest[] => {
	return variables.map((variable) => variableResponseToVariableGRPCConverter(variable));
};
