import { object, string, array, number, mixed, boolean } from 'yup';
import { alphaNumericRegex, unitRegex } from '../../constants/regexes';
import { emptyStringToNull } from '../../tools/yupHelpers';
import * as api from './units.api';
import { UnitSystemTypes } from './units.enums';

export const unitFormSchema = object({
	id: string().optional(),
	name: string()
		.required()
		.max(30)
		.matches(unitRegex, 'Is not in correct format')
		.test('checkUnitUniqueness', 'The name is already taken', function (value, form) {
			if (!value || !form?.parent?.parentId) return true;
			return api.isUnitNameUnique(value, form?.parent?.id || undefined, form?.parent?.parentId);
		}),
	scale: number().transform(emptyStringToNull).nullable().required(),
	offset: number().transform(emptyStringToNull).nullable(),
	systemType: mixed<UnitSystemTypes>().required(),
	isDefault: boolean().optional(),
	// tmpId is for react-hook-form, do not touch
	tmpId: string().optional(),
	parentId: string().optional(),
	units: array().of(mixed<Record<string, any>>()).optional()
});

export const quantityFormSchema = object({
	id: string().optional(),
	name: string()
		.required()
		.max(30)
		.matches(alphaNumericRegex, 'Is not in correct format')
		.test('checkQuantityUniqueness', 'The name is already taken', function (value, form) {
			if (!value) return true;
			return api.isQuantityNameUnique(value, form?.parent?.id);
		}),
	units: array().of(unitFormSchema).min(1, 'Must have at least 1 Unit').required()
});
