import { Dropdown, InputNumber } from '@tactun/ui';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RuleAction } from '../../actions.types';
import { IListItem } from '../../../../types';
import { Properties } from '../../actions.enums';

export interface MeasurementFormProps {
	form: UseFormReturn<RuleAction>;
	variablesOptions: IListItem[];
	unit?: string;
}

const MeasurementForm: FC<MeasurementFormProps> = ({ form, unit, variablesOptions }) => {
	const { t } = useTranslation();
	const {
		control,
		watch,
		formState: { errors }
	} = form;

	const property = watch('property');

	return (
		<>
			{property === Properties.TARE && (
				<Controller
					name="tareWeight"
					control={control}
					render={({ field }) => (
						<InputNumber
							{...field}
							label={t('Tare to measurement, {{unit}}*', { unit })}
							error={errors.tareWeight?.message}
						/>
					)}
				/>
			)}
			{property === Properties.GET_MEASUREMENT_VALUE && (
				<Controller
					name="variableId"
					control={control}
					render={({ field }) => (
						<Dropdown {...field} label={t('Variable*')} options={variablesOptions} error={errors.variableId?.message} />
					)}
				/>
			)}
		</>
	);
};

export default MeasurementForm;
