import React, { useCallback, useMemo, useState } from 'react';
import {
	ColorPicker2,
	Dropdown,
	IconPicker,
	Form,
	InputText,
	DataTable,
	KebabMenu,
	Button,
	Modal,
	IconButton,
	StatusLabelTypes,
	StatusLabelSizes,
	StatusLabel
} from '@tactun/ui';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useNumberEnumList } from '../../../../hooks';
import { ITableColumns } from '../../../../types';
import RuleCreateEdit, {
	RuleConditionType,
	RuleStatus,
	RuleType,
	StationRuleRequestDto,
	StationRuleResponseDto,
	TestRuleRequestDto
} from '../../../Rules';
import { ButtonDefaultState, ButtonShape } from '../../buttonWidget.enums';
import { createEditButtonRuleFormId, createEditButtonRuleModalId } from '../../buttonWidget.const';
import ButtonRuleCreateEditModal from '../ButtonRuleCreateEditModal/ButtonRuleCreateEditModal';
import { ICustomWidgetForm } from '../../../CustomWidgets';
import { responseListItemConverter } from '../../buttonWidget.converters';
import { ButtonRuleListItem } from '../../buttonWidget.types';
import styles from './CustomButtonForm.module.scss';

interface CustomButtonFormProps {
	frameIndex: number;
	stationId: string;
}

const CustomButtonForm: React.FC<CustomButtonFormProps> = ({ frameIndex, stationId }) => {
	const { t } = useTranslation('widgets');
	const { t: common } = useTranslation('common');
	const {
		watch,
		control,
		formState: { errors }
	} = useFormContext<ICustomWidgetForm>();

	const activeButton = watch('activeButton') as number;

	const buttonDefaultStates = useNumberEnumList(ButtonDefaultState, 'widgets');
	const buttonShapes = useNumberEnumList(ButtonShape, 'widgets');

	// Rules
	const {
		fields: rules,
		append,
		remove,
		update
	} = useFieldArray({ control, name: `subFrames.${frameIndex}.buttons.${activeButton}.rules` });

	const ruleList = useMemo(() => {
		return rules?.map((rule) => responseListItemConverter(rule));
	}, [rules]);

	const createMenuItems = useCallback(
		(data: ButtonRuleListItem, index: number) => [
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => remove(index)
			}
		],
		[remove, t]
	);

	const transColumns = useMemo<ITableColumns<ButtonRuleListItem>[]>(() => {
		return [
			{ field: 'name', header: t('Rule Name') },
			{
				field: 'ruleConditionType',
				header: t('Rule Type'),
				body: (data) => {
					return common(RuleConditionType[data.ruleConditionType]);
				}
			},
			{
				field: 'status',
				header: t('Status'),
				body: (data: ButtonRuleListItem) => {
					const statusLabelState =
						data.status === RuleStatus.ENABLED
							? { type: StatusLabelTypes.SUCCESS, text: t('Active') }
							: { type: StatusLabelTypes.GRAY, text: t('Disabled') };
					return (
						<StatusLabel type={statusLabelState.type} size={StatusLabelSizes.SMALL}>
							{t(statusLabelState.text)}
						</StatusLabel>
					);
				}
			}
		];
	}, [t, common]);

	const columnComponents = transColumns.map((col) => <DataTable.Column resizeable key={col.field} {...col} />);

	const [editRuleIndex, setEditRuleIndex] = useState<number | undefined>(undefined);
	const editRule = useMemo(
		() => (editRuleIndex !== undefined ? rules[editRuleIndex] : undefined),
		[editRuleIndex, rules]
	);

	const handleClose = useCallback(() => {
		setEditRuleIndex(undefined);
		Modal.hide(createEditButtonRuleModalId);
	}, []);

	const onAddRule = useCallback((e: React.MouseEvent) => {
		e.preventDefault();
		Modal.show(createEditButtonRuleModalId, {});
	}, []);

	const handleSave = useCallback(
		(rule: TestRuleRequestDto | StationRuleRequestDto) => {
			editRuleIndex !== undefined
				? update(editRuleIndex, rule as StationRuleRequestDto)
				: append(rule as StationRuleRequestDto);
			setEditRuleIndex(undefined);
			Modal.hide(createEditButtonRuleModalId);
		},
		[append, editRuleIndex, update]
	);

	const handleToggleRuleStatus = useCallback(
		(rule: ButtonRuleListItem) => {
			const ruleIndex = rules.findIndex((r) => r.id === rule.id);
			const ruleForUpdate = rules[ruleIndex];

			const updatedRule = {
				...ruleForUpdate,
				ruleStatus: rule.status === RuleStatus.ENABLED ? RuleStatus.DISABLED : RuleStatus.ENABLED
			};

			update(ruleIndex, updatedRule);
		},
		[rules, update]
	);

	return (
		<>
			<Form.Divider />
			<h3 className={styles.buttonTitle}>{t(`Custom Button ${activeButton + 1} Configuration:`)}</h3>
			<div className={styles.subframeForm}>
				<Controller
					name={`subFrames.${frameIndex}.buttons.${activeButton}.name`}
					control={control}
					render={({ field }) => (
						<InputText
							data-testid="nameId"
							label={t('Button name*')}
							{...field}
							error={errors.subFrames?.[frameIndex]?.buttons?.[activeButton]?.name?.message}
						/>
					)}
				/>
				<Controller
					name={`subFrames.${frameIndex}.buttons.${activeButton}.tooltip`}
					control={control}
					render={({ field }) => (
						<InputText
							data-testid="tooltip"
							label={t('Tooltip text')}
							{...field}
							error={errors.subFrames?.[frameIndex]?.buttons?.[activeButton]?.tooltip?.message}
						/>
					)}
				/>
				<Controller
					control={control}
					name={`subFrames.${frameIndex}.buttons.${activeButton}.defaultState`}
					render={({ field }) => (
						<Dropdown
							{...field}
							label={t('Button default state*')}
							options={buttonDefaultStates}
							data-testid="defaultState"
							error={errors.subFrames?.[frameIndex]?.buttons?.[activeButton]?.defaultState?.message}
						/>
					)}
				/>
				<Controller
					control={control}
					name={`subFrames.${frameIndex}.buttons.${activeButton}.shape`}
					render={({ field }) => (
						<Dropdown
							{...field}
							label={t('Button shape*')}
							options={buttonShapes}
							data-testid="shape"
							error={errors.subFrames?.[frameIndex]?.buttons?.[activeButton]?.shape?.message}
						/>
					)}
				/>
				<Controller
					control={control}
					name={`subFrames.${frameIndex}.buttons.${activeButton}.releasedColor`}
					render={({ field }) => <ColorPicker2 label={t('Button background color for Released state*')} {...field} />}
				/>
				<Controller
					control={control}
					name={`subFrames.${frameIndex}.buttons.${activeButton}.pressedColor`}
					render={({ field }) => <ColorPicker2 label={t('Button background color for Pressed state*')} {...field} />}
				/>
				<Controller
					control={control}
					name={`subFrames.${frameIndex}.buttons.${activeButton}.releasedIcon`}
					render={({ field }) => <IconPicker label={t('Icon for Released state')} {...field} />}
				/>
				<Controller
					control={control}
					name={`subFrames.${frameIndex}.buttons.${activeButton}.pressedIcon`}
					render={({ field }) => <IconPicker label={t('Icon for Pressed state')} {...field} />}
				/>
				<Controller
					name={`subFrames.${frameIndex}.buttons.${activeButton}.releasedText`}
					control={control}
					render={({ field }) => (
						<InputText
							data-testid="releasedText"
							label={t('Button text for Released state')}
							{...field}
							error={errors.subFrames?.[frameIndex]?.buttons?.[activeButton]?.releasedText?.message}
						/>
					)}
				/>
				<Controller
					name={`subFrames.${frameIndex}.buttons.${activeButton}.pressedText`}
					control={control}
					render={({ field }) => (
						<InputText
							data-testid="pressedText"
							label={t('Button text for Pressed state')}
							{...field}
							error={errors.subFrames?.[frameIndex]?.buttons?.[activeButton]?.pressedText?.message}
						/>
					)}
				/>
				<Controller
					name={`subFrames.${frameIndex}.buttons.${activeButton}.releasedTextColor`}
					control={control}
					render={({ field }) => <ColorPicker2 label={t('Button text color for released state*')} {...field} />}
				/>
				<Controller
					name={`subFrames.${frameIndex}.buttons.${activeButton}.pressedTextColor`}
					control={control}
					render={({ field }) => <ColorPicker2 label={t('Button text color for pressed state*')} {...field} />}
				/>
			</div>
			<div className={styles.formLabel}>{t('Rules*')}</div>
			<DataTable
				value={ruleList}
				dataKey="id"
				showGridlines
				columnResizeMode="fit"
				emptyMessage={t('No Rules are configured in the given button')}
			>
				{columnComponents}
				<DataTable.Column
					body={(_rowData, { rowIndex }) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => {
								setEditRuleIndex(rowIndex);
								Modal.show(createEditButtonRuleModalId, {});
							}}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="toggle_on"
							fontSize="1.2rem"
							title={rowData.status === RuleStatus.ENABLED ? t('Disable') : t('Enable')}
							onClick={() => handleToggleRuleStatus(rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
				<DataTable.Column
					body={(rowData, options) => <KebabMenu model={createMenuItems(rowData, options.rowIndex)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
			<Button
				variant="contained"
				color="primary"
				icon="t-icon-add"
				label={t('Create rule')}
				onClick={onAddRule}
				className={styles.createBtn}
			/>
			<ButtonRuleCreateEditModal
				formId={createEditButtonRuleFormId}
				isCreate={editRuleIndex === undefined}
				name={editRule?.name}
				onClose={handleClose}
			>
				<RuleCreateEdit
					stationId={stationId}
					formId={createEditButtonRuleFormId}
					rule={editRule as StationRuleResponseDto}
					isButtonRule
					ruleType={RuleType.STATION}
					// ruleSchema={testSequencerRuleSchema}
					onSave={handleSave}
				/>
			</ButtonRuleCreateEditModal>
		</>
	);
};

export default CustomButtonForm;
