import { FC, HTMLAttributes, ReactNode } from 'react';
import cx from 'classnames';

import styles from './ModalHeader.module.scss';

export interface IModalHeaderProps extends HTMLAttributes<HTMLElement> {
	children: ReactNode;
}

const ModalHeader: FC<IModalHeaderProps> = ({ className, children, ...props }) => {
	return (
		<div className={cx(styles.modalHeader, className)} {...props}>
			{children}
		</div>
	);
};
ModalHeader.displayName = 'ModalHeader';

export default ModalHeader;
