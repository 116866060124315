import React from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { controllerEditFormId, controllerEditModalId } from '../../connection.const';
import styles from './ControllerEditModal.module.scss';

interface IControllerEditModalProps {
	children: React.ReactNode;
}

const ControllerEditModal: React.FC<IControllerEditModalProps> = ({ children }) => {
	const { t } = useTranslation('connection');

	return (
		<Modal id={controllerEditModalId} className={styles.modalContainer} shouldCloseOnOverlayClick={false}>
			<Modal.Header>{t('Edit Controller')}</Modal.Header>
			<Modal.Content>{children}</Modal.Content>
			<Modal.Footer>
				<Button className="btnCancel" onClick={() => Modal.hide(controllerEditModalId)} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button color="secondary" variant="contained" form={controllerEditFormId} type="submit">
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(ControllerEditModal);
