import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '@tactun/ui';
import { VariableListItem } from '../../variables.types';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { VariableTableActionTypes } from '../../variables.enums';
import VariablesTable from '../VariablesTable/VariablesTable';
import { useTableConfigs } from '../../../../hooks';

interface VariablesTablePageProps {
	onAction: (type: VariableTableActionTypes, data?: VariableListItem) => void;
	data?: VariableListItem[];
	isLoading: boolean;
	stationName: string;
}

const VariablesTablePage: FC<VariablesTablePageProps> = ({ data, stationName, isLoading, onAction }) => {
	const { t } = useTranslation('variables');
	const { t: common } = useTranslation('common');

	const transColumns = useMemo<ITableColumns<VariableListItem>[]>(() => {
		return [
			{
				field: 'type',
				header: t('Variable type'),
				body: (data: VariableListItem) => {
					return common(data.type);
				}
			},
			{ field: 'defaultValue', header: t('Default value') }
		];
	}, [t, common]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<VariableListItem>(transColumns, data);

	const handleCreate = useCallback(() => {
		onAction(VariableTableActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={stationName}>
			<PageLayout.Header title={t('Variables')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('Variable')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<VariablesTable
				columns={columnComponents}
				filter={filter}
				data={data}
				isLoading={isLoading}
				selected={selected}
				onAction={onAction}
				onSelect={handleSelectionChange}
				hasSelection
			/>
		</PageLayout>
	);
};

export default React.memo(VariablesTablePage);
