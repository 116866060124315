import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, InputText } from '@tactun/ui';
import { controllerEditFormId } from '../../connection.const';
import { UseFormReturn } from 'react-hook-form';
import { ControllerEditFormType } from '../../connection.types';
import styles from './ControllerEditModalContent.module.scss';

interface IControllerEditModalContentProps {
	onSave: () => void;
	form: UseFormReturn<ControllerEditFormType>;
}

const ControllerEditModalContent: React.FC<IControllerEditModalContentProps> = ({ onSave, form }) => {
	const { t } = useTranslation('connection');
	const {
		watch,
		register,
		formState: { errors }
	} = form;
	const isDHCPEnabled = watch('isDHCPEnabled');
	const isManuallyAdded = watch('isManuallyAdded');

	return (
		<Form className={styles.container} id={controllerEditFormId} onSubmit={onSave}>
			<input type="hidden" {...register('isDHCPEnabled')} />
			<input type="hidden" {...register('isManuallyAdded')} />
			<InputText
				disabled={!isManuallyAdded}
				data-testid="nameId"
				label={t('Controller name')}
				{...register('name')}
				error={errors.name?.message}
			/>
			<InputText
				disabled={!isManuallyAdded}
				data-testid="modelId"
				label={t('Controller model')}
				{...register('model')}
				error={errors.model?.message}
			/>
			<InputText
				disabled={!isManuallyAdded}
				data-testid="snId"
				label={t('Controller serial number')}
				{...register('sn')}
				error={errors.sn?.message}
			/>
			<InputText
				disabled={isDHCPEnabled}
				data-testid="ip"
				label={t('IP address')}
				{...register('ip')}
				error={errors.ip?.message}
			/>
			<InputText
				disabled={isDHCPEnabled}
				data-testid="gateway"
				label={t('Gateway')}
				{...register('gateway')}
				error={errors.gateway?.message}
			/>
			<InputText
				disabled={isDHCPEnabled}
				data-testid="mask"
				label={t('Subnet mask')}
				{...register('mask')}
				error={errors.mask?.message}
			/>
		</Form>
	);
};

export default ControllerEditModalContent;
