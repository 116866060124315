import { useCallback, useEffect, useRef, useState } from 'react';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useAxes, useTestAxis } from '../../Axes/axes.hooks';
import ControlStage from '../components/ControlStage';
import AxesConfigurationContainer from './AxesConfigurationContainer';
import { useControlStage } from '../controlStage.grpc';
import { ControlStageActionTypes } from '../controlStage.enums';
import {
	axisConfigurationFormToControlChannelRequest,
	testStageAxisToAxisConfigurationForm
} from '../controlStage.converters';
import { controlStageSchema } from '../controlStage.schemas';
import { ControlChannelRequestDto, updateControlChannel } from '../../ControlChannels';
import WidgetHeader from '../../Dashboard/components/Widget/WidgetHeader';
import WidgetContent from '../../Dashboard/components/Widget/WidgetContent';
import { useParams } from 'react-router-dom';
import { WidgetProps } from '../../Dashboard/dashboard.types';
import { getDefaultValuesByType } from '../../Waveforms';
import { useTest, useTestStages } from '../../Tests/tests.hooks';
import { AxisConfigurationFormType } from '../controlStage.types';
import { tError } from '../../../tools/logger';

const debouncedUpdate = debounce(
	(
		updateFn: (config: AxisConfigurationFormType, changed: string, stationId: string) => void,
		config: AxisConfigurationFormType,
		changed: string,
		stationId: string
	) => {
		updateFn(config, changed, stationId);
	},
	1000
);

const ControlStageContainer: React.FC<WidgetProps> = ({ updateHeight, height, metadata: { isType } }) => {
	const { t } = useTranslation();
	const { stationId, testId } = useParams();
	const { testDto } = useTest(testId);
	const { stages } = useTestStages(testId);
	const accordionRef = useRef<HTMLDivElement>(null);
	const [activeTab, setActiveTab] = useState<number>(0);
	const { axes: stationAxes } = useAxes(stationId);
	const { axes: testAxes } = useTestAxis(testId);
	const axes = stationAxes || testAxes;
	const form = useForm({ mode: 'onBlur', resolver: yupResolver(controlStageSchema) });
	const { control, reset, watch, getValues, trigger } = form;
	const { fields } = useFieldArray({
		control,
		name: 'axesConfigs'
	});
	const axesConfigs = watch('axesConfigs');
	const isTestingMode = testId !== undefined;

	const onStageStart = useCallback(
		(stageId: string) => {
			const currentStage = stages?.find(({ id }) => id === stageId);

			if (currentStage) {
				const axisConfigs = currentStage.axesConfigs.map((axixConfig) =>
					testStageAxisToAxisConfigurationForm(axixConfig)
				);

				reset({
					testId,
					stationId: testDto?.stationId,
					axesConfigs: axisConfigs
				});
			}
		},
		[reset, stages, testDto?.stationId, testId]
	);
	const { start, stop, update, currentStage, error } = useControlStage(onStageStart, isType);

	// reset form when mounted station stage control
	useEffect(() => {
		if (axes?.length && stationId) {
			reset({
				stationId: stationId,
				axesConfigs: axes.map((a) => ({
					axisId: a.id,
					axisName: a.name,
					axisIndex: a.axisIndex,
					controlChannelId: '',
					waveform: getDefaultValuesByType()
				}))
			});
		}
	}, [axes, reset, stationId]);

	// reset form when stage changed in test
	useEffect(() => {
		if (axes?.length && testDto && !currentStage) {
			reset({
				testId: testDto?.id,
				stationId: testDto?.stationId,
				axesConfigs: axes.map((a) => ({
					axisId: a.id,
					axisName: a.name,
					axisIndex: a.axisIndex,
					controlChannelId: '',
					waveform: getDefaultValuesByType()
				}))
			});
		}
	}, [axes, reset, stationId, testDto, currentStage]);

	useEffect(() => {
		toast.error(error?.message);
	}, [error]);

	const updateMutation = useMutation({
		mutationFn: (data: { controlChannel: ControlChannelRequestDto; controlChannelId: string }) =>
			updateControlChannel(data.controlChannel, data.controlChannelId),
		onSuccess: (data) => toast.success(`Control "${data.name}" saved successfully.`),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateChannel = useCallback(() => {
		const data = getValues();

		if (data.stationId) {
			const currentAxesConfig = axesConfigs[activeTab];
			const request = axisConfigurationFormToControlChannelRequest(currentAxesConfig);
			updateMutation.mutate({ controlChannel: request, controlChannelId: currentAxesConfig.controlChannelId });
		}
	}, [activeTab, axesConfigs, getValues, updateMutation]);

	const handleAction = useCallback(
		(type: ControlStageActionTypes) => {
			switch (type) {
				case ControlStageActionTypes.START_STATION:
					{
						const data = getValues();
						if (data.stationId) {
							controlStageSchema
								.validate(data)
								.then(() => {
									trigger();
									const request = { ...data, axesConfigs: axesConfigs };
									reset(data);
									start(request);
								})
								.catch((e) => {
									trigger();
								});
						}
					}
					break;
				case ControlStageActionTypes.STOP_STATION:
					{
						const data = getValues();
						if (data.stationId) {
							const request = { ...data, axesConfigs: axesConfigs };
							stop(request);
						}
					}
					break;
				case ControlStageActionTypes.SAVE_CONTROL_CHANNEL:
					updateChannel();
					break;
			}
		},
		[updateChannel, getValues, trigger, axesConfigs, reset, start, stop]
	);

	const handleTabChange = useCallback((e: TabViewTabChangeEvent) => {
		setActiveTab(e.index);
	}, []);

	// Handle resize
	useEffect(() => {
		const refCopy = accordionRef.current;
		const resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				const newHeight = entry.contentRect.height + 20;
				//TODO: ugly hack to prevent infinite loop
				if (height - newHeight > 10 || height - newHeight < -10) {
					updateHeight(newHeight);
				}
			}
		});

		if (refCopy && !isType) {
			resizeObserver.observe(refCopy);
		}

		return () => {
			if (refCopy && !isType) {
				resizeObserver.unobserve(refCopy);
			}
		};
	}, [updateHeight, height, isType]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const updateConfig = useCallback(
		(index: number, changed: string) => {
			if (stationId || testDto) {
				const csData = getValues();
				controlStageSchema
					.validate(csData)
					.then(() => {
						trigger();
						const config = csData.axesConfigs[index];

						// Because we don't need to update the dither amplitude change, only the dither amplitude Raw needs to be sent to the device
						if (changed !== 'amplitude') {
							debouncedUpdate(update, config, changed, (stationId || testDto?.stationId) as string);
						}
					})
					.catch((e) => {
						tError(e);

						trigger();
					});
			}
		},
		[stationId, testDto, getValues, trigger, update]
	);

	return (
		<>
			<WidgetHeader>{t('Control stage')}</WidgetHeader>
			<WidgetContent isType={isType}>
				<FormProvider {...form}>
					<div ref={accordionRef}>
						<ControlStage isTestingMode={isTestingMode} isRunning={!!currentStage} onAction={handleAction}>
							<TabView renderActiveOnly={false} onTabChange={handleTabChange} activeIndex={activeTab}>
								{fields?.map((axisConfig, index) => (
									<TabPanel
										header={axisConfig.axisName}
										key={axisConfig.axisId}
										// headerClassName={cx({ controlStageSelectedTab: axesConfigs[index].isAxesEnabled })}
									>
										<AxesConfigurationContainer
											axisId={axisConfig.axisId}
											index={index}
											isRunning={!!currentStage}
											isTestStage={!!currentStage?.id}
											updateConfig={updateConfig}
										/>
									</TabPanel>
								))}
							</TabView>
						</ControlStage>
					</div>
				</FormProvider>
			</WidgetContent>
		</>
	);
};

export default ControlStageContainer;
