import {
	Calibration,
	CalibrationRequest,
	CalibrationTupleDto as CalibrationTupleGRPC,
	LinearCalibration,
	PiecewiseCalibration
} from '@tactun/grpc-client';
import { Converter } from '../../types';
import { CalibrationsTypes } from './calibrations.enums';
import {
	CalibrationResponseDto,
	CalibrationFormType,
	CalibrationRequestDto,
	PiecewiceCalibrationPair,
	CalibrationTupleDto
} from './calibrations.types';

export const responseFormConverter = (response: CalibrationResponseDto, sensorId?: string): CalibrationFormType => {
	switch (response.type) {
		case CalibrationsTypes.LINEAR:
			const linearForm: CalibrationFormType = {
				id: response.id,
				type: CalibrationsTypes.LINEAR,
				offset: response.offset,
				sensorId: sensorId ?? '',
				slope: response.slopeOrScaleFactor,
				tedsId: response.tedsId,
				isManualTedsIdEnabled: response.manualIdEntry,
				isInversePolarity: response.inversePolarity
			};
			return linearForm;

		case CalibrationsTypes.PIECEWISE:
			const piecewiseForm: CalibrationFormType = {
				id: response.id,
				type: CalibrationsTypes.PIECEWISE,
				sensorId: sensorId ?? '',
				tedsId: response.tedsId,
				isManualTedsIdEnabled: response.manualIdEntry,
				isShuntCalibrationEnabled: false,
				data: response.calibrationData.map((data) => ({
					calibratedData: data.csd,
					uncalibratedData: data.usd
				})),
				isInversePolarity: response.inversePolarity
			};
			return piecewiseForm;
	}
};

export const dataConverter = (clientData: PiecewiceCalibrationPair[]): CalibrationTupleDto[] => {
	return clientData.map((data) => {
		return {
			usd: data.uncalibratedData,
			csd: data.calibratedData
		};
	});
};

export const formRequestConverter = (form: CalibrationFormType): CalibrationRequestDto => {
	switch (form.type) {
		case CalibrationsTypes.LINEAR:
			const linearRequest: CalibrationRequestDto = {
				type: CalibrationsTypes.LINEAR,
				offset: form.offset,
				sensorId: form.sensorId,
				slopeOrScaleFactor: form.slope,
				tedsId: form.tedsId,
				manualIdEntry: form.isManualTedsIdEnabled,
				inversePolarity: form.isInversePolarity
			};
			return linearRequest;

		case CalibrationsTypes.PIECEWISE:
			const piecewiseRequest: CalibrationRequestDto = {
				type: CalibrationsTypes.PIECEWISE,
				sensorId: form.sensorId,
				calibrationData: dataConverter(form.data as PiecewiceCalibrationPair[]),
				tedsId: form.tedsId,
				manualIdEntry: form.isManualTedsIdEnabled,
				inversePolarity: form.isInversePolarity
			};
			return piecewiseRequest;
	}
};

export const calibrationDataToCalibrationTupleGRPCConverter: Converter<
	CalibrationTupleDto[],
	CalibrationTupleGRPC[]
> = (calibrationTuples) => {
	return calibrationTuples.map((calibration) => {
		const calibrationTupleRequest = new CalibrationTupleGRPC();
		calibrationTupleRequest.setUsd(calibration.usd);
		calibrationTupleRequest.setCsd(calibration.csd);

		return calibrationTupleRequest;
	});
};

export const calibrationResponseToCalibrationGRPCConverter: Converter<
	Partial<CalibrationResponseDto>,
	CalibrationRequest
> = (calibrationResp) => {
	const calibrationRequest = new CalibrationRequest();

	if (calibrationResp.tedsId) {
		calibrationRequest.setTedsId(calibrationResp.tedsId);
	}
	if (calibrationResp.sensor) {
		calibrationRequest.setSensorId(calibrationResp.sensor.id);
	}

	const calibration = new Calibration();

	if (calibrationResp.id) {
		calibration.setCalibrationId(calibrationResp.id);
	}
	if (calibrationResp.type) {
		calibration.setCalibrationType(calibrationResp.type);
	}

	if (calibrationResp.type === CalibrationsTypes.LINEAR) {
		const linearCalibration = new LinearCalibration();
		if (calibrationResp.offset !== undefined) {
			linearCalibration.setOffset(calibrationResp.offset);
		}
		if (calibrationResp.slopeOrScaleFactor) {
			linearCalibration.setSlope(calibrationResp.slopeOrScaleFactor);
		}

		calibration.setLinearCalibration(linearCalibration);
	}

	if (calibrationResp.type === CalibrationsTypes.PIECEWISE) {
		const piecwiseCalibration = new PiecewiseCalibration();

		if (calibrationResp.calibrationData) {
			for (const cdata of calibrationResp.calibrationData) {
				const calibrationTuple = new CalibrationTupleGRPC();
				calibrationTuple.setCsd(cdata.csd);
				calibrationTuple.setUsd(cdata.usd);
				piecwiseCalibration.addCalibrationData(calibrationTuple);
			}
		}

		calibration.setPiecewiseCalibration(piecwiseCalibration);
	}
	calibrationRequest.setShuntCalibration(false);
	calibrationRequest.setCalibration(calibration);

	return calibrationRequest;
};

export const calibrationRequestToCalibrationGRPCConverter = (calibrationResp: CalibrationRequestDto): Calibration => {
	const calibration = new Calibration();

	calibration.setCalibrationId('111');

	calibration.setCalibrationType(calibrationResp.type);

	if (calibrationResp.type === CalibrationsTypes.LINEAR) {
		const linearCalibration = new LinearCalibration();
		if (calibrationResp.offset !== undefined) {
			linearCalibration.setOffset(calibrationResp.offset);
		}
		if (calibrationResp.slopeOrScaleFactor) {
			linearCalibration.setSlope(calibrationResp.slopeOrScaleFactor);
		}

		calibration.setLinearCalibration(linearCalibration);
	}

	if (calibrationResp.type === CalibrationsTypes.PIECEWISE) {
		const piecwiseCalibration = new PiecewiseCalibration();

		if (calibrationResp.calibrationData) {
			const calTuple = calibrationDataToCalibrationTupleGRPCConverter(calibrationResp.calibrationData);
			piecwiseCalibration.setCalibrationDataList(calTuple);
		}

		calibration.setPiecewiseCalibration(piecwiseCalibration);
	}

	return calibration;
};
