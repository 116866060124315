export const controllerSelectionModalId = 'controllerSelectionModal';
export const controllerEditModalId = 'controllerEditModalId';
export const controllerEditFormId = 'controllerEditFormId';
export const controllerAddManuallyModalId = 'controllerAddManuallyModalId';
export const controllerAddManuallyFormId = 'controllerAddManuallyFormId';
export const controllerConfigureNetworkFormId = 'controllerConfigureNetworkFormId';
export const controllerConfigureNetworkModalId = 'controllerConfigureNetworkModalId';
export const controllerLogConfirmationModalId = 'controllerLogConfirmationModalId';

export const configureNetworkDefaultValues = {
	ip: '',
	mask: '',
	gateway: '',
	isDHCPEnabled: false,
	sn: '',
	isManuallyAdded: false
};

export const editDefaultValues = {
	id: undefined,
	ip: '',
	mask: '255.255.255.0',
	gateway: '',
	isDHCPEnabled: false,
	name: '',
	model: '',
	sn: '',
	isManuallyAdded: false
};

export const addManuallyDefaultValues = {
	ip: '',
	mask: '',
	gateway: '',
	name: '',
	model: ''
};
