import { useMemo, useRef } from 'react';
import { PageLayout, Form, Switch, Dropdown } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import styles from './StationConfiguration.module.scss';

interface AxesProps {
	isCreate?: boolean;
	form: UseFormReturn<Record<string, string>>;
}

const StationConfiguration: React.FC<AxesProps> = ({ isCreate, form }) => {
	const { t } = useTranslation('axes');
	const formId = useRef('axesForm');
	const { control, register } = form;

	const title = useMemo<string>(() => {
		return isCreate ? t('Create a Feature-Set') : t('Edit a Feature-Set');
	}, [isCreate, t]);

	return (
		<>
			<PageLayout info={title}>
				<PageLayout.Header title={t('Station Configuration')} />
				<Form className={styles.container} id={formId.current}>
					<input type="hidden" {...register('stationId')} />
					<div>Sensors Creation</div>
					<Controller
						name="type1"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<div>Sensor Calibration</div>
					<Controller
						name="type2"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<div>Measurements Creation</div>
					<Controller
						name="type3"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<div>Real-time Filters Creation</div>
					<Controller
						name="type4"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<div>Actuators Creation</div>
					<Controller
						name="type5"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<div>Axis Creation</div>
					<Controller
						name="type6"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<div>Control Algorithms Creation</div>
					<Controller
						name="type7"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<div>Setpoints Creation</div>
					<Controller
						name="type8"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<div>PID Autotuning</div>
					<Controller
						name="type9"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Camera Support</div>
					<Controller
						name="type10"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<div>Auxiliary Devices Creation</div>
					<Controller
						name="type11"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
				</Form>
			</PageLayout>
		</>
	);
};

export default StationConfiguration;
