import React from 'react';
import TestDataLoggingModal from '../TestDataLoggingModal';
import TestDataLoggingContainer from '../../containers/TestDataLoggingContainer';

interface ITestDataLoggingTableModalProps {
	testId?: string;
}

const TestDataLogging: React.FC<ITestDataLoggingTableModalProps> = ({ testId }) => {
	return (
		<TestDataLoggingModal>
			<TestDataLoggingContainer testId={testId} />
		</TestDataLoggingModal>
	);
};

export default React.memo(TestDataLogging);
