import { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useModalData } from '@tactun/ui';
import { useTestDataLogging, useTestDataLoggings } from '../testDataLogging.hooks';
import { TestDataLoggingFormType } from '../testDataLogging.types';
import TestDataLoggingCreateEditModal from '../components/TestDataLoggingCreateEditModal';
import { formRequestConverter } from '../testDataLogging.converters';
import { useTestAxis } from '../../Axes/axes.hooks';
import { testDataLoggingSchema } from '../testDataLogging.schemas';
import { createEditDataLoggingModalId } from '../testDataLogging.const';
import { responseFormConverter } from '../testDataLogging.converters';
import { DataLoggingTypes } from '../testDataLogging.enums';
import { useTimeQuantity } from '../../Units';
import { useEntityOptions } from '../../../hooks';
import { useTestMeasurements } from '../../TestMeasurements/testMeasurements.hooks';

interface TestDataLoggingContainerProps {
	testId?: string;
	onClose: () => void;
	onSaveSuccess: () => void;
}

const defaultValues = {
	id: '',
	name: '',
	dataChannels: [],
	dataLoggingType: DataLoggingTypes.TIMED,
	rate: undefined,
	interval: undefined,
	dur: undefined,
	stageId: ''
};

const TestDataLoggingContainer: FC<TestDataLoggingContainerProps> = ({ testId, onClose, onSaveSuccess }) => {
	const form = useForm<TestDataLoggingFormType>({
		defaultValues: { ...defaultValues, testId },
		mode: 'onBlur',
		resolver: yupResolver(testDataLoggingSchema)
	});
	const {
		setValue,
		reset,
		watch,
		formState: { dirtyFields }
	} = form;

	const { testMeasurements = [] } = useTestMeasurements(testId);
	const { axes = [] } = useTestAxis(testId);
	const data = useModalData(createEditDataLoggingModalId);
	const { testDataLogging } = useTestDataLogging(data?.id);
	const { isCreating, isUpdating, saveDataLogging } = useTestDataLoggings(
		testId,
		data?.stageId,
		data?.stageType,
		onSaveSuccess
	);
	const dataLoggingType = watch('dataLoggingType');
	const { timeQuantity } = useTimeQuantity();
	const timeUnits = useEntityOptions(timeQuantity?.units);

	const handleSave = useCallback(() => {
		form.handleSubmit((data) => {
			const filteredData = formRequestConverter(data);
			if (data?.id) {
				saveDataLogging(filteredData, data.id);
			} else {
				saveDataLogging(filteredData);
			}
		})();
	}, [form, saveDataLogging]);

	const handleAfterClose = useCallback(() => {
		reset({ ...defaultValues, timeUnits: timeQuantity?.units, testId }, { keepDirty: true, keepTouched: true });
	}, [reset, testId, timeQuantity?.units]);

	useEffect(() => {
		if (data) {
			setValue('stageId', data.stageId);
			setValue('stageType', data.stageType);
		}
	}, [data, setValue]);

	useEffect(() => {
		if (timeQuantity?.units) {
			setValue('timeUnits', timeQuantity?.units);
		}
	}, [setValue, timeQuantity?.units]);

	useEffect(() => {
		if (testId) {
			setValue('testId', testId);
		}
	}, [setValue, testId]);

	useEffect(() => {
		if (dataLoggingType && dirtyFields.dataLoggingType) {
			setValue('rate', null);
			setValue('interval', null);
			setValue('dur', null);
		}
	}, [dataLoggingType, dirtyFields.dataLoggingType, setValue]);

	useEffect(() => {
		if (testDataLogging && timeQuantity?.units.length) {
			reset(
				{ ...responseFormConverter(testDataLogging), timeUnits: timeQuantity?.units, testId },
				{ keepDirty: true, keepTouched: true }
			);
		}
	}, [testDataLogging, reset, timeQuantity?.units, testId]);

	return (
		<TestDataLoggingCreateEditModal
			form={form}
			name={testDataLogging?.name}
			isCreate={!testDataLogging?.id}
			isLoading={isCreating || isUpdating}
			measurements={testMeasurements}
			axes={axes}
			timeUnits={timeUnits}
			onSave={handleSave}
			onClose={onClose}
			onAfterClose={handleAfterClose}
		/>
	);
};

export default TestDataLoggingContainer;
