import { useMemo } from 'react';
import * as api from './testDashboardConfiguration.api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Converter } from '../../types';
import { GraphResponseDto, ReadoutResponseDto } from './testDashboardConfiguration.types';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export const useReadouts = <T>(testId = '', converter: (input: ReadoutResponseDto, t: TFunction) => T) => {
	const { t } = useTranslation();
	const { data, isLoading, fetchStatus } = useQuery({
		queryKey: ['readouts', { testId }],
		queryFn: async () => {
			return await api.getReadoutsForTest(testId);
		},
		enabled: !!testId
	});

	const readouts = useMemo(() => {
		return data?.map?.((item) => converter(item, t)) || [];
	}, [data, converter, t]);

	const queryClient = useQueryClient();

	const updateReadouts = () => {
		queryClient.invalidateQueries({ queryKey: ['readouts'] });
	};

	return {
		readouts,
		isReadoutsLoading: fetchStatus === 'fetching' && isLoading,
		updateReadouts
	};
};

export const useReadout = <T>(readoutId = '', converter: (xyz: ReadoutResponseDto, t: TFunction) => T) => {
	const { t } = useTranslation();
	const { data, isLoading: isReadoutLoading } = useQuery({
		queryKey: ['readouts', readoutId],
		queryFn: async () => {
			return await api.getReadoutForTest(readoutId);
		},
		enabled: !!readoutId
	});

	const readout = useMemo(() => {
		return data && converter(data, t);
	}, [data, converter, t]);

	const queryClient = useQueryClient();

	const updateReadout = () => {
		queryClient.invalidateQueries({ queryKey: ['readouts'] });
	};

	return {
		readout,
		isReadoutLoading,
		updateReadout
	};
};

export const useGraphs = <T>(testId = '', converter: Converter<GraphResponseDto, T>) => {
	const { data, isLoading, fetchStatus } = useQuery({
		queryKey: ['graphs', { testId }],
		queryFn: async () => {
			return await api.getGraphsForTest(testId);
		},
		enabled: !!testId
	});
	const graphs = useMemo(() => {
		return data?.map?.((item) => converter(item)) || [];
	}, [data, converter]);

	const queryClient = useQueryClient();

	const updateGraphs = () => {
		queryClient.invalidateQueries({ queryKey: ['graphs'] });
	};

	return {
		graphs,
		isGraphsLoading: fetchStatus === 'fetching' && isLoading,
		updateGraphs
	};
};

export const useGraph = <T>(graphId = '', converter: Converter<GraphResponseDto, T>) => {
	const { data, isLoading: isGraphsLoading } = useQuery({
		queryKey: ['graphs', graphId],
		queryFn: async () => {
			return await api.getGraphForTest(graphId);
		},
		enabled: !!graphId
	});

	const graph = useMemo(() => {
		return data && converter(data);
	}, [data, converter]);

	const queryClient = useQueryClient();

	const updateGraph = () => {
		queryClient.invalidateQueries({ queryKey: ['graphs', graphId] });
	};

	return {
		graph,
		isGraphsLoading,
		updateGraph
	};
};
