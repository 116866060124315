import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { AnyObjectSchema } from 'yup';
import Calculation from '../components/Calculation';
import { calculationSchema } from '../calculation.schemas';
import { CalculationFormType, CalculationRequestDto, CalculationResponseDto } from '../calculation.types';
import { responseFormConverter } from '../calculation.converters';
import { CalculationTypes, ReferencePointTypes, StatisticalCalcRanges } from '../calculation.enums';
import { useQuantities } from '../../Units';
import { useMeasurements } from '../../Measurements';
import { deleteEmptyFields } from '../../../tools';
import { formRequestConverter } from '../calculation.converters';
import { CALCULATION_TYPE_GROUPE } from '../calculation.const';
import { DropdownOptionNumber } from '@tactun/ui';
import { useAxes, useTestAxis } from '../../Axes/axes.hooks';
import { useNumberEnumList } from '../../../hooks';
import { useTestMeasurements } from '../../TestMeasurements/testMeasurements.hooks';

interface CalculationFormContainerProps {
	formId: string;
	calculation?: CalculationResponseDto;
	stationId?: string;
	testId?: string;
	formSchema?: AnyObjectSchema;
	formContext?: any;
	onSave: (data: CalculationRequestDto, id?: string) => void;
}

const defaultValues: CalculationFormType = {
	id: undefined,
	name: '',
	stationId: '',
	testId: '',
	type: CalculationTypes.ENERGY,
	measurementId1: '',
	measurementId2: undefined,
	unit: '',
	limitDefinition: undefined,
	nominalValue: undefined,
	variation: undefined,
	upperLimit: undefined,
	lowerLimit: undefined,
	successFailCriteria: false
};

const CalculationFormContainer: React.FC<CalculationFormContainerProps> = ({
	formId,
	calculation,
	stationId,
	testId,
	formContext,
	formSchema,
	onSave
}) => {
	const { t } = useTranslation('calculation');

	const form = useForm<CalculationFormType>({
		defaultValues: { ...defaultValues, stationId, testId },
		mode: 'onBlur',
		context: formContext,
		resolver: formSchema ? yupResolver(formSchema) : yupResolver(calculationSchema)
	});
	const { handleSubmit, reset } = form;
	const handleReset = useCallback(() => {
		if (calculation) {
			reset({ ...responseFormConverter(calculation), stationId, testId }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultValues, stationId, testId }, { keepDirty: true, keepTouched: true });
		}
	}, [calculation, reset, stationId, testId]);

	const handleSave = handleSubmit((data) => {
		const filteredData = deleteEmptyFields<CalculationRequestDto>(formRequestConverter(data));
		onSave(filteredData, data.id);
	});

	//init current system for edit
	useEffect(() => {
		if (calculation) {
			handleReset();
		}
	}, [calculation, handleReset]);

	const { measurements } = useMeasurements(stationId);
	const { testMeasurements } = useTestMeasurements(testId);
	const { axes } = useAxes(stationId);
	const { axes: testAxes } = useTestAxis(testId);
	const { quantities } = useQuantities();
	const referencePointTypes = useNumberEnumList(ReferencePointTypes);
	const statisticalCalcRanges = useNumberEnumList(StatisticalCalcRanges);
	const calculationTypes = useMemo(() => {
		return Object.entries(CALCULATION_TYPE_GROUPE).map(([key, value]) => {
			return {
				label: t(key),
				options: value.map((type) => ({
					value: type,
					label: t(CalculationTypes[type])
				}))
			};
		}) as DropdownOptionNumber[];
	}, [t]);

	return (
		<Calculation
			formId={formId}
			form={form}
			calculationTypes={calculationTypes}
			statisticalCalcRanges={statisticalCalcRanges}
			measurements={measurements || testMeasurements || []}
			quantities={quantities || []}
			axes={axes || testAxes || []}
			referencePointTypes={referencePointTypes}
			onSave={handleSave}
		/>
	);
};

export default React.memo(CalculationFormContainer);
