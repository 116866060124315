import { InputText } from '@tactun/ui';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { RuleAction } from '../../actions.types';
import { useTranslation } from 'react-i18next';
import styles from './MessageBoxActionForm.module.scss';

export interface MessageBoxActionFormProps {
	form: UseFormReturn<RuleAction>;
}

const MessageBoxActionForm: FC<MessageBoxActionFormProps> = ({ form }) => {
	const { t } = useTranslation();
	const {
		control,
		formState: { errors }
	} = form;

	return (
		<Controller
			name="message"
			control={control}
			render={({ field }) => (
				<InputText
					{...field}
					textarea
					data-testid="message"
					label={t('Your message*')}
					error={errors.message?.message}
					className={styles.messageTextArea}
				/>
			)}
		/>
	);
};

export default MessageBoxActionForm;
