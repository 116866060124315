import axios from '../../httpCommon';
import { MeasurementResponseDto } from '../Measurements';
import { UnitBase } from '../Units';
import { CalculationRequestDto, CalculationResponseDto } from './calculation.types';

export const getCalculations = async (stationId?: string): Promise<CalculationResponseDto[]> => {
	const response = await axios.get('/cassiopeia/calc/search/findAllByStationUuid', {
		params: {
			stationUuid: stationId
		}
	});

	return response.data._embedded.calc as CalculationResponseDto[];
};

export const getCalculationsByUuids = async (uuids: string[]): Promise<CalculationResponseDto[]> => {
	const params = new URLSearchParams();
	uuids.forEach((uuid) => params.append('uuids', uuid));
	const response = await axios.get('/cassiopeia/calc/search/findAllByUuidIn', {
		params
	});
	return response.data._embedded.calc as CalculationResponseDto[];
};

export const getCalculationById = async (id?: string): Promise<CalculationResponseDto> => {
	const response = await axios.get(`/cassiopeia/calc/${id}`);

	return response.data;
};

export const getMeasurements = async (stationId: string): Promise<MeasurementResponseDto[]> => {
	const response = await axios.get(`/quasar/measurements/station/${stationId}`);

	return response.data?.length ? response.data : [];
};

export const getUnits = async (): Promise<UnitBase[]> => {
	const response = await axios.get('/quasar/units');

	return response.data?.length ? response.data : [];
};

export const createCalculation = async (calculation: CalculationRequestDto): Promise<CalculationResponseDto> => {
	const response = await axios.post('/cassiopeia/calc', calculation);

	return response.data;
};

export const updateCalculation = async (
	calculation: CalculationRequestDto,
	id: string
): Promise<CalculationResponseDto> => {
	const response = await axios.post('/cassiopeia/calc', {
		uuid: id,
		...calculation
	});

	return response.data;
};

export const checkName = async (name: string, parentId: string, id?: string): Promise<boolean> => {
	const response = await axios.post('/cassiopeia/calc/isNameUnique', {
		id: id || null,
		name,
		parentId
	});
	return response.data === true;
};
