import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { grpc } from '@improbable-eng/grpc-web';
import { controlStageService, ErrorType, Error as GrpcError } from '@tactun/grpc-client';
import { UnaryOutput } from '@improbable-eng/grpc-web/dist/typings/unary';
import { ProtobufMessage } from '@improbable-eng/grpc-web/dist/typings/message';
import {
	axesConfigToControlStageUpdateConverter,
	controlStageFormToControlStageStartConverter,
	controlStageFormToControlStageStopConverter
} from './controlStage.converters';
import { ControlStageFormType, AxisConfigurationFormType } from './controlStage.types';
import { grpcErrorToMessage } from '../../tools/grpcHelper';
import { tError } from '../../tools/logger';
import { subscribeToStage } from '../Dashboard/dashboard.grpc';

export interface ControlStageError {
	code: string;
	message: string;
}

export interface ControlStageData {
	data: number;
}

export const useStageState = (isDisabled: boolean = false) => {
	const [currentStage, setCurrentStage] = useState<{ id: string; sequence: number } | null>(null);
	const currentStageRef = useRef<{ id: string; sequence: number } | null>(null);

	useEffect(() => {
		let subscription: { unsubscribe(): void };

		if (!isDisabled) {
			subscription = subscribeToStage((stage) => {
				if (stage === null) {
					setCurrentStage(null);
					currentStageRef.current = null;
				} else if (currentStageRef.current?.id !== stage.id || currentStageRef.current?.sequence !== stage.sequence) {
					const newStage = {
						id: stage.id,
						sequence: stage.sequence
					};
					setCurrentStage(newStage);
					currentStageRef.current = newStage;
				}
			});
		}

		return () => {
			if (subscription) {
				subscription.unsubscribe();
			}
		};
	}, [isDisabled]);

	return currentStage;
};

export const useControlStage = (onStageStart: (stageId: string) => void, isDisabled: boolean = false) => {
	const [data, setData] = useState<number>(0);
	const [error, setError] = useState<ControlStageError>();
	const grpcRequestRef = useRef<grpc.Request>();

	const currentStage = useStageState(isDisabled);

	const start = useCallback(
		(controlStage: ControlStageFormType) => {
			if (process.env.REACT_APP_DEVICE_URL && !currentStage) {
				const startRequest = controlStageFormToControlStageStartConverter(controlStage);
				const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

				grpcRequestRef.current = grpc.unary(controlStageService.WebControlStage.StartStage, {
					request: startRequest,
					host: process.env.REACT_APP_DEVICE_URL,
					transport: myTransport,
					onEnd: (output: UnaryOutput<GrpcError>) => {
						if (output.message?.getErrorType() === ErrorType.OK) {
							setData(1);
						} else {
							const message = grpcErrorToMessage(output.message, output.statusMessage);
							setError({
								code: output.message?.getErrorType().toString() ?? '',
								message: message
							});
							setData(0);
						}
					}
				});
			}
		},
		[currentStage]
	);

	const stop = useCallback(
		(controlStage: ControlStageFormType) => {
			if (process.env.REACT_APP_DEVICE_URL && currentStage) {
				const stopRequest = controlStageFormToControlStageStopConverter(controlStage);
				const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

				grpcRequestRef.current = grpc.unary(controlStageService.WebControlStage.StopStage, {
					request: stopRequest,
					host: process.env.REACT_APP_DEVICE_URL,
					transport: myTransport,
					onEnd: (output: UnaryOutput<ProtobufMessage>) => {
						if (output.status === grpc.Code.OK) {
							setData(0);
						} else {
							toast.error(output.statusMessage || '');
							tError(
								`GRPC stream end code: "${output.status.toString()}" \nGRPC stream end message: "${output.message}" \n `
							);

							setError({ code: output.status.toString(), message: output.statusMessage ?? '' });
						}
					}
				});
			}
		},
		[currentStage]
	);

	const update = useCallback((axesConfig: AxisConfigurationFormType, changedKey: string, stationId: string) => {
		if (process.env.REACT_APP_DEVICE_URL) {
			const updateRequest = axesConfigToControlStageUpdateConverter(axesConfig, changedKey, stationId);
			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

			grpcRequestRef.current = grpc.unary(controlStageService.WebControlStage.UpdateStageConfig, {
				request: updateRequest,
				host: process.env.REACT_APP_DEVICE_URL,
				transport: myTransport,
				onEnd: (output: UnaryOutput<ProtobufMessage>) => {
					if (output.status !== grpc.Code.OK) {
						tError(
							`GRPC stream end code: "${output.status.toString()}" \nGRPC stream end message: "${output.message}" \n `
						);

						setError({ code: output.status.toString(), message: output.statusMessage ?? '' });
					}
				}
			});
		}
	}, []);

	useEffect(() => {
		if (currentStage) onStageStart(currentStage.id);
	}, [currentStage, onStageStart]);

	return useMemo(() => {
		return { start, stop, update, currentStage, data, error };
	}, [start, stop, update, currentStage, data, error]);
};
