import React from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { controllerSelectionModalId } from '../../connection.const';
import styles from './ControllerSelectionModal.module.scss';

interface IControllerSelectionModalProps {
	children: React.ReactNode;
	onAfterOpen?: () => void;
}

const ControllerSelectionModal: React.FC<IControllerSelectionModalProps> = ({ children, onAfterOpen }) => {
	const { t } = useTranslation('connection');

	return (
		<Modal
			id={controllerSelectionModalId}
			className={styles.modalContainer}
			shouldCloseOnOverlayClick={false}
			onAfterOpen={onAfterOpen}
		>
			<Modal.Header>{t('Choose a Controller')}</Modal.Header>
			<Modal.Content>{children}</Modal.Content>
			<Modal.Footer>
				<Button
					className="btnCancel"
					onClick={() => Modal.hide(controllerSelectionModalId)}
					color="success"
					variant="text"
				>
					{t('Cancel')}
				</Button>
				<Button onClick={() => Modal.hide(controllerSelectionModalId)} color="secondary" variant="contained">
					{t('Done')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(ControllerSelectionModal);
