import { FC, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { QuantityResponseDto } from '../units.types';
import * as api from '../units.api';
import UnitsTable from '../components/UnitsTable';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { UnitCrudActionTypes } from '../units.enums';

const UnitsTableContainer: FC = () => {
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const queryClient = useQueryClient();

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			queryClient.setQueryData<QuantityResponseDto[]>(['quantities'], (old) => {
				if (!old) return undefined;
				return old.filter((hc) => hc.id !== id);
			});
		},
		[queryClient]
	);

	const { data, isLoading } = useQuery<QuantityResponseDto[], Error>({
		queryKey: ['quantities'],
		queryFn: () => api.getQuantities()
	});

	const handleAction = useCallback(
		(type: UnitCrudActionTypes, quantity?: QuantityResponseDto) => {
			switch (type) {
				case UnitCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case UnitCrudActionTypes.EDIT:
					navigate(`./${quantity?.id}`);
					break;
				case UnitCrudActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case UnitCrudActionTypes.DELETE:
					setEntityForDelete(quantity as QuantityResponseDto);
					break;
			}
		},
		[navigate]
	);

	return (
		<>
			<UnitsTable onAction={handleAction} data={data} isLoading={isLoading} />
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.QUANTITY}
				entity={entityForDelete}
				checkUsage
			/>
		</>
	);
};

export default UnitsTableContainer;
