export enum StationCrudActionTypes {
	EDIT = 'EDIT_STATION',
	CREATE = 'CREATE_STATION',
	DUPLICATE = 'DUPLICATE_STATION',
	DELETE = 'DELETE_STATION',
	RUN_STATION = 'RUN_STATION',
	CREATE_PRODUCT = 'CREATE_PRODUCT'
}

export enum ControllerModels {
	CONTROLLER_MODEL_TCM_30101_A = 0,
	CONTROLLER_MODEL_TCM_30201_A,
	CONTROLLER_MODEL_TCM_30201_B,
	CONTROLLER_MODEL_TCM_30401_A,
	CONTROLLER_MODEL_TCM_30401_B,
	CONTROLLER_MODEL_TCM_30401_C,
	CONTROLLER_MODEL_TCM_30401_D,
	CONTROLLER_MODEL_TCM_30401_E,
	CONTROLLER_MODEL_TCM_40101_A,
	CONTROLLER_MODEL_TCM_40101_B,
	CONTROLLER_MODEL_TCM_40101_C,
	CONTROLLER_MODEL_TCM_40101_D,
	CONTROLLER_MODEL_TCM_40101_E,
	CONTROLLER_MODEL_TCM_40101_F,
	CONTROLLER_MODEL_TCM_40101_G,
	CONTROLLER_MODEL_TCM_40201_A,
	CONTROLLER_MODEL_TCM_40201_B,
	CONTROLLER_MODEL_TCM_50201_A,
	CONTROLLER_MODEL_TCM_50401_A,
	CONTROLLER_MODEL_TCM_50801_A,
	CONTROLLER_MODEL_TCM_50801_B,
	CONTROLLER_MODEL_TCM_50801_C,
	CONTROLLER_MODEL_TCM_51601_A,
	CONTROLLER_MODEL_TCM_51601_B,
	CONTROLLER_MODEL_TCM_51601_C,
	CONTROLLER_MODEL_SUPER_TACTUN,
	CONTROLLER_MODEL_UNSUPPORTED
}
