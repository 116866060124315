import { IListItemNumber } from '../../types';
import { ScaleTypes } from '../GraphWidget/graphWidget.enums';
import { GraphFormType } from '../GraphWidget/graphWidget.types';
import { GraphTypes } from './testDashboardConfiguration.enums';

export const GraphTypesOptions: IListItemNumber[] = [{ label: 'XY Graph', value: GraphTypes.XY }];

export const graphDefaultValues = {
	name: '',
	graphType: GraphTypes.XY,
	autoScaleX: true,
	autoScaleY: true,
	cmin: null,
	cmax: null,
	min: null,
	max: null,
	dcX: [],
	dcY1: [],
	dcY2: [],
	limitLines: false,
	rate: 10,
	timeUnitId: '',
	timeWndSize: 10,
	xOffset: false,
	offset: null,
	scaleType: ScaleTypes.LINEAR,
	simplifiedMeasurements: {}
} as GraphFormType;
