import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout, DataTable, IconButton, KebabMenu } from '@tactun/ui';
import { Tag } from 'primereact/tag';
import { IProductListItem } from '../../';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { useTableConfigs } from '../../../../hooks';
import { ProductCrudActions, ProductStatuses } from '../../products.enums';
import styles from './ProductsTable.module.scss';

const getStatusClass = (status: ProductStatuses) => {
	let statusClass = styles.inProgress;
	if (status === ProductStatuses.READY_FOR_DOWNLOAD) {
		statusClass = styles.ready;
	}

	return statusClass;
};

interface IProductsTableProps {
	onAction: (type: ProductCrudActions, data?: IProductListItem) => void;
	data: IProductListItem[];
	isLoading: boolean;
	isDownloading: boolean;
	connectedSerialNumber?: string;
}

const ProductsTable: FC<IProductsTableProps> = ({
	data,
	isLoading,
	isDownloading,
	connectedSerialNumber,
	onAction
}) => {
	const { t } = useTranslation('products');

	const transColumns = useMemo<ITableColumns<IProductListItem>[]>(() => {
		return [
			{ field: 'name', header: t('Product Name') },
			{ field: 'model', header: t('Controller Model') },
			{ field: 'serialNumber', header: t('Controller S/N') },
			{ field: 'stationName', header: t('StationName') },
			{ field: 'version', header: t('Product Version') },
			{ field: 'createDate', header: t('Create Date') },
			{
				field: 'status',
				header: t('Status'),
				body: (controller: IProductListItem) => (
					<Tag
						className={getStatusClass(controller.status)}
						severity="info"
						value={t(ProductStatuses[controller.status]).toUpperCase()}
						rounded
					/>
				)
			}
		];
	}, [t]);

	const {
		filter,
		selected,
		columnComponents,
		dataFilteredByGroups,
		handleSelectionChange,
		handleFilterChange,
		handleCancel
	} = useTableConfigs<IProductListItem>(transColumns, data);

	const handleCreate = useCallback(() => {
		onAction(ProductCrudActions.CREATE);
	}, [onAction]);

	const createMenuItems = useCallback(
		(data: IProductListItem) => [
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(ProductCrudActions.DELETE, data),
				disabled: data.serialNumber !== connectedSerialNumber
			}
		],
		[connectedSerialNumber, onAction, t]
	);

	const transData = useMemo(
		() =>
			dataFilteredByGroups?.map((d) => ({
				...d,
				createDate: t('common:dateTime', { date: new Date(d.createDate) })
			})),
		[dataFilteredByGroups, t]
	);

	return (
		<PageLayout info={t('My Account')}>
			<PageLayout.Header title={t('Products')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('Product')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				value={transData}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							disabled
							onClick={() => onAction(ProductCrudActions.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="generate"
							fontSize="1.2rem"
							title={t('Build')}
							onClick={() => onAction(ProductCrudActions.BUILD, rowData)}
							disabled={rowData.status !== ProductStatuses.PENDING}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData: IProductListItem) => (
						<IconButton
							icon={'download'}
							fontSize="1.2rem"
							title={t('Download')}
							onClick={() => onAction(ProductCrudActions.DOWNLOAD, rowData)}
							disabled={rowData.status !== ProductStatuses.READY_FOR_DOWNLOAD || isDownloading}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default ProductsTable;
