export const showModal = (id: string, data?: object): void => {
	document.dispatchEvent(
		new CustomEvent('showModal', {
			detail: { id, data }
		})
	);
};

export const hideModal = (id: string): void => {
	document.dispatchEvent(
		new CustomEvent('hideModal', {
			detail: { id }
		})
	);
};
