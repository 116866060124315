import { useEffect, useRef } from 'react';

/**
 *
 * @param {boolean} expression - dependencies that you whant to use once
 * @example
 * const initTrigger = useOnce(!!currentSensor);
 *
 * // Setup data for edit
 * useEffect(() => {
 * 	if (initTrigger) {
 * 		handleReset();
 * 	}
 * }, [initTrigger, handleReset]);
 *
 */
export const useOnce = (expression: boolean) => {
	const ref = useRef(false);

	if (expression && !ref.current) {
		ref.current = true;
	}

	useEffect(() => {
		return () => {
			ref.current = false;
		};
	}, []);

	return ref.current;
};
