import { INamedEntity } from '../types';

export const getEntityName = <T extends Partial<INamedEntity>>(entities: T[], id?: string | null) => {
	if (id === null || id === undefined) {
		return '';
	}
	const selectedEntity = entities.find((channel) => channel.id === id);

	return selectedEntity?.name || '';
};
