import { ChannelType } from '../StationChannels';
import { SensorsTypes } from './sensors.enums';

export const SENSORS_TYPES = Object.values(SensorsTypes);

export const SENSOR_CHANNELS_MAP = {
	[SensorsTypes.SINGLE_ENDED_ENCODER]: [ChannelType.SINGLE_ENDED_ENCODER],
	[SensorsTypes.DIFFERENTIAL_ENCODER]: [ChannelType.DIFFERENTIAL_ENCODER],
	[SensorsTypes.LOAD_CELL]: [ChannelType.SIGNAL_CONDITIONING],
	[SensorsTypes.LVDT]: [ChannelType.LVDT],
	[SensorsTypes.GENERIC_SENSOR]: [
		ChannelType.ANALOG_IN_10KSPS,
		ChannelType.ANALOG_IN_10V_24MA,
		ChannelType.ANALOG_IN_60KSPS
	]
};
