export enum TriggerObjectTypes {
	DIGITAL_INPUT = 0,
	AUX_DEVICE,
	SPECIMEN,
	MEASUREMENT,
	TEST,
	CONNECTION,
	AXIS,
	STATION,
	UI_BUTTON,
	VARIABLE
}

export enum TriggerDtoObjectTypes {
	DIGITAL_INPUT = 0,
	AUX_DEVICE,
	SPECIMEN_BREAK,
	SPECIMEN_YIELD,
	MEASUREMENT_IN_OUT_RANGE,
	MEASUREMENT_MORE_THAN_LESS_THAN,
	MEASUREMENT_AMPLITUDE_CHANGE,
	MEASUREMENT_MEAN_CHANGE,
	TEST,
	CONNECTION,
	AXIS,
	STATION,
	UI_BUTTON,
	USER_EVENT,
	VARIABLE
}

export enum TriggerDigitalInputProperties {
	ON = 0,
	OFF
}

export enum TriggerStationProperties {
	IN_TEST = 0,
	IDLE,
	ACTIVE,
	SAFE
}

export enum TriggerTestProperties {
	STARTED = 0,
	STOPPED,
	RESUMED,
	PAUSED
}

export enum TriggerAuxDeviceProperties {
	ON = 0,
	OFF
}

export enum TriggerConnectionProperties {
	CONNECTED = 0,
	DISCONNECTED
}

export enum TriggerSpecimenProperties {
	BREAK = 0,
	YIELD
}

export enum TriggerMeasurementProperties {
	MORE_THAN = 0,
	LESS_THAN,
	IN_RANGE,
	OUT_OF_RANGE,
	AMPLITUDE_CHANGE,
	MEAN_CHANGE
}

export enum TriggerAxisProperties {
	COMPLETED = 0
}

export enum TriggerMeasurementInOutRangeProperties {
	IN_RANGE,
	OUT_OF_RANGE
}

export enum TriggerMeasurementMoreThanLessThanProperties {
	MORE_THAN,
	LESS_THAN
}

export enum TriggerVariableProperties {
	EQUALS = 0,
	NOT_EQUALS
}

export enum TriggerUIButtonProperties {
	PRESSED = 0,
	RELEASED
}
