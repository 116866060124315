import React, { useCallback, useMemo } from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import TestLoopsTable from '../TestLoopsTable/TestLoopsTable';
import { TestLoopListItem } from '../../testLoops.types';
import { TestLoopsActionTypes } from '../../testLoops.enums';
import { ITableColumns } from '../../../../types';
import { testLoopModalId } from '../../testLoops.const';

import styles from './TestLoopsModal.module.scss';

interface TestLoopsModalProps {
	onAction: (type: TestLoopsActionTypes, data?: TestLoopListItem, index?: number) => void;
	testId: string;
	data?: TestLoopListItem[];
	isLoading: boolean;
}

const TestLoopsModal: React.FC<TestLoopsModalProps> = ({ onAction, data, isLoading }) => {
	const { t } = useTranslation('testLoops');

	const transColumns = useMemo<ITableColumns[]>(() => {
		return [
			{ field: 'loopStart', header: t('Loop start') },
			{ field: 'loopEnd', header: t('Loop end') },
			{ field: 'numberOfIterations', header: t('Number of Iterations') },
			{ field: 'runCalculationFor', header: t('Run Calculation For') }
		];
	}, [t]);

	const handleClose = useCallback(() => {
		Modal.hide(testLoopModalId);
	}, []);

	return (
		<Modal id={testLoopModalId} className={styles.container} shouldCloseOnOverlayClick={false}>
			<Modal.Header>{t('Loops')}</Modal.Header>
			<Modal.Content className={styles.content}>
				<div className={styles.title}>{t('Loops of the current test')}</div>
				<TestLoopsTable columns={transColumns} data={data} isLoading={isLoading} onAction={onAction} />
				<Button
					variant="contained"
					color="primary"
					icon="t-icon-add"
					label={t('Create Loop')}
					onClick={() => onAction(TestLoopsActionTypes.CREATE)}
					className={styles.createBtn}
				/>
			</Modal.Content>
			<Modal.Footer>
				<Button onClick={handleClose} color="secondary" variant="contained">
					{t('Done')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(TestLoopsModal);
