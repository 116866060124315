import { KeycloakInitOptions } from 'keycloak-js';
import React, { useState, useEffect } from 'react';
import { AxiosInstance } from 'axios';
import KeycloakContext, { IKeycloakContext } from '../../context';
import { useUserActivity, useInitKeycloak } from '../../hooks';
import Loader from '../Loader';

export interface IKeycloakProviderProps {
	children: ((props: IKeycloakContext | null) => React.ReactNode) | React.ReactNode;
	loader?: React.ReactNode;
	configUrl: string;
	options?: KeycloakInitOptions;
	onLogout?: () => void;
	trackUserActivity?: boolean;
	sessionIdleMilliseconds?: number;
	axiosInstance?: AxiosInstance;
}

const KeycloakProvider: React.FC<IKeycloakProviderProps> = ({
	children,
	loader = <Loader />,
	configUrl,
	options,
	onLogout,
	trackUserActivity = false,
	sessionIdleMilliseconds,
	axiosInstance
}) => {
	const [logoutTimer, setLogoutTimer] = useState<NodeJS.Timeout>();
	const keycloak = useInitKeycloak({ configUrl, options, axiosInstance });

	useUserActivity(() => {
		if (!keycloak) {
			return;
		}
		if (trackUserActivity) {
			keycloak.validateToken();
		}

		if (logoutTimer) {
			clearTimeout(logoutTimer);
		}

		if (sessionIdleMilliseconds) {
			const timer = setTimeout(() => keycloak.validateToken(), sessionIdleMilliseconds);
			setLogoutTimer(timer);
		}
	}, 10000);

	useEffect(() => {
		if (!keycloak) {
			return;
		}
		keycloak.onAuthLogout = () => {
			if (onLogout) {
				// for what case need skip native keycloak logout
				onLogout();
			} else {
				keycloak.logout();
			}
		};
	}, [keycloak, onLogout]);

	const context = keycloak
		? ({
				getUserData: keycloak.getUserData,
				keycloak: keycloak.kc,
				login: keycloak.login,
				logout: keycloak.logout
		  } as IKeycloakContext)
		: null;

	return (
		<KeycloakContext.Provider value={context}>
			{typeof children === 'function' ? children(context) : (keycloak && children) || loader}
		</KeycloakContext.Provider>
	);
};

KeycloakProvider.displayName = 'KeycloakProvider';

export default React.memo(KeycloakProvider);
