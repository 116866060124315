import React, { forwardRef, memo, useMemo } from 'react';
import { SelectInstance } from 'react-select';
import styles from './IconPicker.module.scss';
import Dropdown, { DropdownProps } from '../Dropdown';
import IconPickerOption from './IconPickerOption';

const icons = [
	'grey-chevron-down-large',
	'grey-chevron-up-large',
	'grey-fast-down-large',
	'grey-fast-up-large',
	'grey-counter-clockwise-left-large',
	'grey-counter-clockwise-left-fast-large',
	'grey-counter-clockwise-right-large',
	'grey-counter-clockwise-right-fast-large',
	'grey-home-large',
	'grey-home-close-large'
];

const IconPicker = forwardRef(
	(props: DropdownProps<string, false>, ref: React.ForwardedRef<SelectInstance<string, false>>) => {
		const iconOptions = useMemo(
			() =>
				icons.map((icon) => ({
					value: icon,
					label: `${icon.charAt(0).toUpperCase() + icon.slice(1)}.svg`
				})),
			[]
		);

		return (
			<Dropdown
				{...props}
				ref={ref}
				options={iconOptions}
				panelClassName={styles.dropdownPanel}
				OptionComponent={IconPickerOption}
			/>
		);
	}
);

export default memo(IconPicker);
