import axios from '../../httpCommon';
import { StationRuleRequestDto, StationRuleResponseDto, RuleType, TestRuleResponseDto } from '../Rules';

const url = '/cassiopeia/rules';

export const checkName = async (name: string, parentId: string, type: RuleType, id?: string): Promise<boolean> => {
	const response = await axios.post(`${url}/isNameUnique`, { id, name, parentId, type });
	return response.data === true;
};

export const createStationRule = async (stationRule: StationRuleRequestDto): Promise<StationRuleResponseDto> => {
	const response = await axios.post(url, stationRule);
	return response.data;
};

export const updateStationRule = async (
	stationRule: StationRuleRequestDto,
	id: string
): Promise<StationRuleResponseDto> => {
	const response = await axios.post(url, { uuid: id, ...stationRule });

	return response.data;
};

export const getStationRule = async (id: string): Promise<StationRuleResponseDto> => {
	const response = await axios.get(`${url}/search/findByUuid`, {
		params: {
			uuid: id
		}
	});

	return response.data;
};

export const getStationRulesByType = async (stationId: string, type: RuleType): Promise<StationRuleResponseDto[]> => {
	const baseUrl = `${url}/search/findAllByRuleStationUuidAndRuleType`;

	const response = await axios.get(baseUrl, {
		params: {
			ruleType: type === RuleType.STATION ? 'STATION' : 'TEST',
			ruleStationUuid: stationId
		}
	});

	return response.data?._embedded.rules;
};

export const getStationRules = async (ruleStationUuid: string): Promise<StationRuleResponseDto[]> => {
	const baseUrl = `${url}/search/findAllByRuleStationUuid`;

	const response = await axios.get(baseUrl, {
		params: { ruleStationUuid }
	});

	return response.data?._embedded.rules;
};

export const updateRuleStatus = async (
	ruleUuid: string,
	ruleStatus: number
): Promise<StationRuleResponseDto | TestRuleResponseDto> => {
	const response = await axios.patch(`${url}/status`, { ruleUuid, ruleStatus });

	return response.data;
};
