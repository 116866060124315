import { object, string, mixed } from 'yup';
import { checkName } from './';
import { alphaNumericRegex } from '../../constants/regexes';
import { ProductPlatforms } from './products.enums';

export const productFormSchema = object({
	id: string().optional(),
	name: string()
		.required('Name is a required field')
		.max(30)
		.matches(alphaNumericRegex, 'Is not in correct format')
		.test('checkProductUniqueness', 'Name is used', function (value, form) {
			if (!value) return true;
			return checkName(value, form?.parent?.id);
		}),
	controllerSerialNumber: string(),
	model: string().required('Controller model is a required field'),
	stationId: string().required('Station name is a required field'),
	version: string().required('Product version is a required field'),
	platform: mixed<ProductPlatforms>().required('Application platform is a required field')
}).required();
