import React, { useCallback, useMemo } from 'react';
import { IStep, normalizePath, Stepper, StepStatuses, useWizard } from '@tactun/ui';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WizardLayout from '../../../components/WizardLayout';
import { useDashboardType } from '../dashboardTypes.hooks';
import * as paths from '../dashboardTypes.const';

const DashboardTypeWizardContainer: React.FC = () => {
	const { t } = useTranslation('dashboardTypes');
	const navigate = useNavigate();
	const { dashboardTypeId } = useParams();
	const { steps, nextStep, previousStep, isLastStep, isFirstStep, isLoading, activeStep } = useWizard();

	const handleNext = useCallback(() => {
		nextStep();
	}, [nextStep]);

	const handlePrevious = useCallback(() => {
		previousStep();
	}, [previousStep]);

	const handleBack = useCallback(() => {
		navigate('/components-settings/dashboard-types');
	}, [navigate]);

	const normalizedSteps = useMemo(() => {
		return steps.map((step) => ({ ...step, normalizedPath: normalizePath(step.path, { dashboardTypeId }) }));
	}, [dashboardTypeId, steps]);

	const { dashboardType: current } = useDashboardType(dashboardTypeId);

	const getStepStatus = (step: IStep) => {
		if (!dashboardTypeId && step.path === paths.generalInfoCreatePath) {
			return StepStatuses.IN_PROGRESS;
		}

		switch (step.path) {
			case paths.generalInfoPath:
				return StepStatuses.COMPLETED;
			case paths.dashboardPath:
				return current?.widgets?.length ? StepStatuses.COMPLETED : StepStatuses.EMPTY;
			default:
				return StepStatuses.EMPTY;
		}
	};

	return (
		<WizardLayout
			backLabel={t('Back to Dashboard types')}
			onNext={handleNext}
			onPrevious={handlePrevious}
			onBack={handleBack}
			isSaving={isLoading}
			isPreviousDisabled={isFirstStep || isLoading}
			isNextDisabled={isLoading}
			isLastStep={isLastStep}
			activeStep={steps[activeStep].path}
		>
			<Stepper>
				{normalizedSteps?.map((step) => (
					<Stepper.Step
						label={step.name}
						path={step.normalizedPath}
						key={step.normalizedPath}
						disabled={!dashboardTypeId}
						status={getStepStatus(step)}
					/>
				))}
			</Stepper>
		</WizardLayout>
	);
};

export default React.memo(DashboardTypeWizardContainer);
