import React, { useCallback, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Modal } from '@tactun/ui';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { GraphRequestDto, GraphResponseDto } from '../../testDashboardConfiguration.types';
import styles from './CreateGraphModal.module.scss';
import * as api from '../../testDashboardConfiguration.api';
import { deleteEmptyFields } from '../../../../tools';
import { graphFormRequestConverter, graphResponseFormConverter } from '../../testDashboardConfiguration.converter';
import { useGraph } from '../../testDashboardConfiguration.hooks';
import GraphForm from '../GraphForm';
import { graphDefaultValues } from '../../testDashboardConfiguration.constants';
import { useMeasurements } from '../../../Measurements';
import { useAxes } from '../../../Axes/axes.hooks';
import { Modals } from '../../testDashboardConfiguration.enums';
import { GraphFormType } from '../../../GraphWidget/graphWidget.types';
import { graphSettingsSchema } from '../../../GraphWidget/graphWidget.schemas';

interface CreateGraphModalProps {
	graphId?: string;
	testId: string;
	stationId: string;
	onSaveSuccess: (readout: GraphResponseDto) => void;
	onSaveError: (e: Error) => void;
	handleClose: () => void;
}

const CreateGraphModal: React.FC<CreateGraphModalProps> = ({
	onSaveError,
	onSaveSuccess,
	graphId,
	stationId,
	handleClose,
	testId
}) => {
	const { t } = useTranslation('dashboardConfiguration');
	const isEdit = !!graphId;
	const { graph } = useGraph<GraphFormType>(graphId, graphResponseFormConverter);

	const form = useForm({
		mode: 'onBlur',
		resolver: yupResolver(graphSettingsSchema),
		defaultValues: {
			...graphDefaultValues,
			testId
		}
	});
	const { handleSubmit, reset } = form;
	const { measurements = [] } = useMeasurements(stationId);
	const { axes = [] } = useAxes(stationId);
	const createMutation = useMutation({
		mutationKey: ['saveGraph'],
		mutationFn: (graph: GraphRequestDto) => api.createGraph(graph),
		onSuccess: onSaveSuccess,
		onError: onSaveError
	});
	const updateMutation = useMutation({
		mutationKey: ['saveGraph'],
		mutationFn: ({ graph, graphId }: { graph: GraphRequestDto; graphId: string }) => api.updateGraph(graph, graphId),
		onSuccess: onSaveSuccess,
		onError: onSaveError
	});

	const handleSave = useCallback(() => {
		handleSubmit((data) => {
			const filteredData = deleteEmptyFields<GraphRequestDto>(graphFormRequestConverter(data));
			if (graphId) {
				updateMutation.mutate({ graph: filteredData, graphId });
			} else {
				createMutation.mutate(filteredData);
			}
		})();
	}, [handleSubmit, createMutation, updateMutation, graphId]);

	const handleClearForm = useCallback(() => {
		reset({
			...graphDefaultValues,
			testId
		});
	}, [reset, testId]);

	useEffect(() => {
		if (graph) {
			reset(graph);
		}
	}, [reset, graph]);

	return (
		<Modal id={Modals.CREATE_GRAPH_MODAL} className={styles.modal} onClose={handleClose} onAfterClose={handleClearForm}>
			<Modal.Header>{isEdit ? t('Edit a Graph') : t('Create new Graph')}</Modal.Header>
			<Modal.Content>
				<GraphForm measurements={measurements} axes={axes} form={form} isAdvanced />
			</Modal.Content>
			<Modal.Footer>
				<Button onClick={handleClose} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button onClick={handleSave} color="secondary" variant="contained">
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(CreateGraphModal);
