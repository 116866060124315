import React, { FC, useEffect, useMemo } from 'react';
import { Dropdown, GroupInputFrame, InputNumber, InputText } from '@tactun/ui';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IListItem } from '../../../../types';
import { AdaptiveAmplitudeAlgFormErrors } from '../../controlAlgorithms.types';
import styles from './AdaptiveAmplitudeAlgForm.module.scss';
import { MeasurementResponseDto } from '../../../Measurements';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';

interface AdaptiveAmplitudeAlgFormProps {
	formPrefix: string;
	isWithMean: boolean;
	disabled: boolean;
	measurementsList?: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	errors?: AdaptiveAmplitudeAlgFormErrors;
}

const AdaptiveAmplitudeAlgForm: FC<AdaptiveAmplitudeAlgFormProps> = ({
	formPrefix,
	measurementsList,
	isWithMean,
	disabled,
	errors
}) => {
	const { t } = useTranslation('controlAlgorithm');
	const { control, register, trigger, watch, setValue } = useFormContext();

	const feedbackList = useMemo<IListItem[]>(
		() =>
			measurementsList?.map((measurement) => ({
				label: measurement.name,
				value: measurement.id
			})) || [],
		[measurementsList]
	);

	const feedbackMeasurementId = useWatch({ control, name: `${formPrefix}.adaptiveAmplitude.fbId` });
	const feedbackMeasurement = useMemo(() => {
		return measurementsList?.find((m) => m.id === feedbackMeasurementId);
	}, [measurementsList, feedbackMeasurementId]);

	const allowedError = watch(`${formPrefix}.adaptiveAmplitude.allowedError`);
	useEffect(() => {
		if (feedbackMeasurement) {
			setValue(
				`${formPrefix}.adaptiveAmplitude.minFeedbackValue`,
				feedbackMeasurement.lowerLimit !== undefined && feedbackMeasurement.lowerLimit !== null
					? feedbackMeasurement.lowerLimit
					: Number.MIN_SAFE_INTEGER
			);
			setValue(
				`${formPrefix}.adaptiveAmplitude.maxFeedbackValue`,
				feedbackMeasurement.upperLimit !== undefined && feedbackMeasurement.upperLimit !== null
					? feedbackMeasurement.upperLimit
					: Number.MAX_SAFE_INTEGER
			);
			if (allowedError) {
				trigger(`${formPrefix}.adaptiveAmplitude.allowedError`);
			}
		}
	}, [feedbackMeasurement, formPrefix, setValue, trigger, allowedError]);

	return (
		<>
			<div className={styles.formLabel}>{isWithMean ? t('Adaptive Amplitude and Mean') : t('Adaptive Amplitude')}</div>
			<input type="hidden" {...register(`${formPrefix}.adaptiveAmplitude.minFeedbackValue`)} />
			<input type="hidden" {...register(`${formPrefix}.adaptiveAmplitude.maxFeedbackValue`)} />
			<Controller
				name={`${formPrefix}.adaptiveAmplitude.fbId`}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Feedback measurement*')}
						{...field}
						options={feedbackList}
						error={errors?.fbId?.message}
						data-testid="fbId"
						disabled={disabled}
						filter
					/>
				)}
			/>
			<Controller
				name={`${formPrefix}.adaptiveAmplitude.adjustmentPeriod`}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Adjustment period*')}
						error={errors?.adjustmentPeriod?.message}
						data-testid="adjustmentPeriod"
						disabled={disabled}
						isInteger
					/>
				)}
			/>
			<Controller
				name={`${formPrefix}.adaptiveAmplitude.step`}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Amplitude step (%)*')}
						error={errors?.step?.message}
						data-testid="step"
						disabled={disabled}
					/>
				)}
			/>
			{isWithMean && (
				<Controller
					name={`${formPrefix}.adaptiveAmplitude.meanStep`}
					control={control}
					render={({ field }) => (
						<InputNumber
							{...field}
							label={t('Mean step (%)*')}
							error={errors?.meanStep?.message}
							data-testid="meanStep"
							disabled={disabled}
						/>
					)}
				/>
			)}
			<Controller
				name={`${formPrefix}.adaptiveAmplitude.targetValue`}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Target amplitude*')}
						error={errors?.targetValue?.message}
						data-testid="targetValue"
						disabled={disabled}
					/>
				)}
			/>
			{isWithMean && (
				<Controller
					name={`${formPrefix}.adaptiveAmplitude.meanTargetVal`}
					control={control}
					render={({ field }) => (
						<InputNumber
							{...field}
							label={t('Target mean*')}
							error={errors?.meanTargetVal?.message}
							data-testid="meanTargetVal"
							disabled={disabled}
						/>
					)}
				/>
			)}
			<Controller
				name={`${formPrefix}.adaptiveAmplitude.allowedError`}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Allowed amplitude error (%)*')}
						error={errors?.allowedError?.message as string}
						data-testid="allowedError"
						disabled={disabled}
					/>
				)}
			/>
			{isWithMean && (
				<GroupInputFrame>
					<Controller
						name={`${formPrefix}.adaptiveAmplitude.meanAllowedError`}
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								label={t('Allowed mean error*')}
								error={errors?.meanAllowedError?.message}
								data-testid="meanAllowedError"
								disabled={disabled}
							/>
						)}
					/>
					<InputText data-testid="allowedErrorUnit" disabled value={feedbackMeasurement?.unit.name || ''} />
				</GroupInputFrame>
			)}
		</>
	);
};

export default AdaptiveAmplitudeAlgForm;
