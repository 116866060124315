import React, { memo } from 'react';
import { OptionProps } from 'react-select';
import cx from 'classnames';
import styles from './LayoutDropdown.module.scss';
import { IDropdownOption } from '@tactun/ui/src/components/FormElements/Dropdown';
import { WidgetsLayoutTypes } from '../../customWidget.enums';
import Single from '../../assets/layout-single.svg';
import DoubleHorizontal from '../../assets/layout-double-horizontal.svg';
import DoubleVertical from '../../assets/layout-double-vertical.svg';
import Quadro from '../../assets/layout-quadro.svg';

const layoutSvgMap: Record<WidgetsLayoutTypes, string> = {
	[WidgetsLayoutTypes.SINGLE]: Single,
	[WidgetsLayoutTypes.DOUBLE_HORIZONTAL]: DoubleHorizontal,
	[WidgetsLayoutTypes.DOUBLE_VERTICAL]: DoubleVertical,
	[WidgetsLayoutTypes.QUADRO]: Quadro
};

const LayoutDropdownOption: React.FC<OptionProps<IDropdownOption<number>>> = (props) => {
	const img = layoutSvgMap[props.data.value as WidgetsLayoutTypes];

	return (
		<div
			className={cx(styles.layoutItem, {
				[styles.selected]: props.isSelected
			})}
			{...props.innerProps}
		>
			<img alt="" src={img} />
		</div>
	);
};

export default memo(LayoutDropdownOption);
