// source: logging.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.logging.DeleteLogsRequest', null, global);
goog.exportSymbol('proto.logging.ListLoggedTestsRequest', null, global);
goog.exportSymbol('proto.logging.ListLoggedTestsResponse', null, global);
goog.exportSymbol('proto.logging.LoggingVoid', null, global);
goog.exportSymbol('proto.logging.MediaType', null, global);
goog.exportSymbol('proto.logging.SpecimenTestResultLog', null, global);
goog.exportSymbol('proto.logging.SpecimenTestResultTopic', null, global);
goog.exportSymbol('proto.logging.StorageSpaceInfo', null, global);
goog.exportSymbol('proto.logging.StorageType', null, global);
goog.exportSymbol('proto.logging.StreamLogsRequest', null, global);
goog.exportSymbol('proto.logging.StreamLogsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.logging.LoggingVoid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.logging.LoggingVoid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.logging.LoggingVoid.displayName = 'proto.logging.LoggingVoid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.logging.ListLoggedTestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.logging.ListLoggedTestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.logging.ListLoggedTestsRequest.displayName = 'proto.logging.ListLoggedTestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.logging.ListLoggedTestsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.logging.ListLoggedTestsResponse.repeatedFields_, null);
};
goog.inherits(proto.logging.ListLoggedTestsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.logging.ListLoggedTestsResponse.displayName = 'proto.logging.ListLoggedTestsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.logging.StorageSpaceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.logging.StorageSpaceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.logging.StorageSpaceInfo.displayName = 'proto.logging.StorageSpaceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.logging.SpecimenTestResultLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.logging.SpecimenTestResultLog.repeatedFields_, null);
};
goog.inherits(proto.logging.SpecimenTestResultLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.logging.SpecimenTestResultLog.displayName = 'proto.logging.SpecimenTestResultLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.logging.SpecimenTestResultTopic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.logging.SpecimenTestResultTopic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.logging.SpecimenTestResultTopic.displayName = 'proto.logging.SpecimenTestResultTopic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.logging.StreamLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.logging.StreamLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.logging.StreamLogsRequest.displayName = 'proto.logging.StreamLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.logging.DeleteLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.logging.DeleteLogsRequest.repeatedFields_, null);
};
goog.inherits(proto.logging.DeleteLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.logging.DeleteLogsRequest.displayName = 'proto.logging.DeleteLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.logging.StreamLogsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.logging.StreamLogsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.logging.StreamLogsResponse.displayName = 'proto.logging.StreamLogsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.logging.LoggingVoid.prototype.toObject = function(opt_includeInstance) {
  return proto.logging.LoggingVoid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.logging.LoggingVoid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.LoggingVoid.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.logging.LoggingVoid}
 */
proto.logging.LoggingVoid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.logging.LoggingVoid;
  return proto.logging.LoggingVoid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.logging.LoggingVoid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.logging.LoggingVoid}
 */
proto.logging.LoggingVoid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.logging.LoggingVoid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.logging.LoggingVoid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.logging.LoggingVoid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.LoggingVoid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.logging.ListLoggedTestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.logging.ListLoggedTestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.logging.ListLoggedTestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.ListLoggedTestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.logging.ListLoggedTestsRequest}
 */
proto.logging.ListLoggedTestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.logging.ListLoggedTestsRequest;
  return proto.logging.ListLoggedTestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.logging.ListLoggedTestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.logging.ListLoggedTestsRequest}
 */
proto.logging.ListLoggedTestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.logging.ListLoggedTestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.logging.ListLoggedTestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.logging.ListLoggedTestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.ListLoggedTestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string filter = 1;
 * @return {string}
 */
proto.logging.ListLoggedTestsRequest.prototype.getFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.logging.ListLoggedTestsRequest} returns this
 */
proto.logging.ListLoggedTestsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.logging.ListLoggedTestsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.logging.ListLoggedTestsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.logging.ListLoggedTestsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.logging.ListLoggedTestsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.ListLoggedTestsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    logsList: jspb.Message.toObjectList(msg.getLogsList(),
    proto.logging.SpecimenTestResultLog.toObject, includeInstance),
    spaceInfoList: jspb.Message.toObjectList(msg.getSpaceInfoList(),
    proto.logging.StorageSpaceInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.logging.ListLoggedTestsResponse}
 */
proto.logging.ListLoggedTestsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.logging.ListLoggedTestsResponse;
  return proto.logging.ListLoggedTestsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.logging.ListLoggedTestsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.logging.ListLoggedTestsResponse}
 */
proto.logging.ListLoggedTestsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.logging.SpecimenTestResultLog;
      reader.readMessage(value,proto.logging.SpecimenTestResultLog.deserializeBinaryFromReader);
      msg.addLogs(value);
      break;
    case 2:
      var value = new proto.logging.StorageSpaceInfo;
      reader.readMessage(value,proto.logging.StorageSpaceInfo.deserializeBinaryFromReader);
      msg.addSpaceInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.logging.ListLoggedTestsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.logging.ListLoggedTestsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.logging.ListLoggedTestsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.ListLoggedTestsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.logging.SpecimenTestResultLog.serializeBinaryToWriter
    );
  }
  f = message.getSpaceInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.logging.StorageSpaceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SpecimenTestResultLog logs = 1;
 * @return {!Array<!proto.logging.SpecimenTestResultLog>}
 */
proto.logging.ListLoggedTestsResponse.prototype.getLogsList = function() {
  return /** @type{!Array<!proto.logging.SpecimenTestResultLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.logging.SpecimenTestResultLog, 1));
};


/**
 * @param {!Array<!proto.logging.SpecimenTestResultLog>} value
 * @return {!proto.logging.ListLoggedTestsResponse} returns this
*/
proto.logging.ListLoggedTestsResponse.prototype.setLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.logging.SpecimenTestResultLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.logging.SpecimenTestResultLog}
 */
proto.logging.ListLoggedTestsResponse.prototype.addLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.logging.SpecimenTestResultLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.logging.ListLoggedTestsResponse} returns this
 */
proto.logging.ListLoggedTestsResponse.prototype.clearLogsList = function() {
  return this.setLogsList([]);
};


/**
 * repeated StorageSpaceInfo space_info = 2;
 * @return {!Array<!proto.logging.StorageSpaceInfo>}
 */
proto.logging.ListLoggedTestsResponse.prototype.getSpaceInfoList = function() {
  return /** @type{!Array<!proto.logging.StorageSpaceInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.logging.StorageSpaceInfo, 2));
};


/**
 * @param {!Array<!proto.logging.StorageSpaceInfo>} value
 * @return {!proto.logging.ListLoggedTestsResponse} returns this
*/
proto.logging.ListLoggedTestsResponse.prototype.setSpaceInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.logging.StorageSpaceInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.logging.StorageSpaceInfo}
 */
proto.logging.ListLoggedTestsResponse.prototype.addSpaceInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.logging.StorageSpaceInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.logging.ListLoggedTestsResponse} returns this
 */
proto.logging.ListLoggedTestsResponse.prototype.clearSpaceInfoList = function() {
  return this.setSpaceInfoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.logging.StorageSpaceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.logging.StorageSpaceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.logging.StorageSpaceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.StorageSpaceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalSpace: jspb.Message.getFieldWithDefault(msg, 1, 0),
    availableSpace: jspb.Message.getFieldWithDefault(msg, 2, 0),
    path: jspb.Message.getFieldWithDefault(msg, 3, ""),
    storageType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    mediaType: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.logging.StorageSpaceInfo}
 */
proto.logging.StorageSpaceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.logging.StorageSpaceInfo;
  return proto.logging.StorageSpaceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.logging.StorageSpaceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.logging.StorageSpaceInfo}
 */
proto.logging.StorageSpaceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalSpace(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAvailableSpace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 5:
      var value = /** @type {!proto.logging.StorageType} */ (reader.readEnum());
      msg.setStorageType(value);
      break;
    case 6:
      var value = /** @type {!proto.logging.MediaType} */ (reader.readEnum());
      msg.setMediaType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.logging.StorageSpaceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.logging.StorageSpaceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.logging.StorageSpaceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.StorageSpaceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalSpace();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getAvailableSpace();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStorageType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getMediaType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional uint64 total_space = 1;
 * @return {number}
 */
proto.logging.StorageSpaceInfo.prototype.getTotalSpace = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.logging.StorageSpaceInfo} returns this
 */
proto.logging.StorageSpaceInfo.prototype.setTotalSpace = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 available_space = 2;
 * @return {number}
 */
proto.logging.StorageSpaceInfo.prototype.getAvailableSpace = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.logging.StorageSpaceInfo} returns this
 */
proto.logging.StorageSpaceInfo.prototype.setAvailableSpace = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string path = 3;
 * @return {string}
 */
proto.logging.StorageSpaceInfo.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.logging.StorageSpaceInfo} returns this
 */
proto.logging.StorageSpaceInfo.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional StorageType storage_type = 5;
 * @return {!proto.logging.StorageType}
 */
proto.logging.StorageSpaceInfo.prototype.getStorageType = function() {
  return /** @type {!proto.logging.StorageType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.logging.StorageType} value
 * @return {!proto.logging.StorageSpaceInfo} returns this
 */
proto.logging.StorageSpaceInfo.prototype.setStorageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional MediaType media_type = 6;
 * @return {!proto.logging.MediaType}
 */
proto.logging.StorageSpaceInfo.prototype.getMediaType = function() {
  return /** @type {!proto.logging.MediaType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.logging.MediaType} value
 * @return {!proto.logging.StorageSpaceInfo} returns this
 */
proto.logging.StorageSpaceInfo.prototype.setMediaType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.logging.SpecimenTestResultLog.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.logging.SpecimenTestResultLog.prototype.toObject = function(opt_includeInstance) {
  return proto.logging.SpecimenTestResultLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.logging.SpecimenTestResultLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.SpecimenTestResultLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    logId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    topicsList: jspb.Message.toObjectList(msg.getTopicsList(),
    proto.logging.SpecimenTestResultTopic.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.logging.SpecimenTestResultLog}
 */
proto.logging.SpecimenTestResultLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.logging.SpecimenTestResultLog;
  return proto.logging.SpecimenTestResultLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.logging.SpecimenTestResultLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.logging.SpecimenTestResultLog}
 */
proto.logging.SpecimenTestResultLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogId(value);
      break;
    case 3:
      var value = new proto.logging.SpecimenTestResultTopic;
      reader.readMessage(value,proto.logging.SpecimenTestResultTopic.deserializeBinaryFromReader);
      msg.addTopics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.logging.SpecimenTestResultLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.logging.SpecimenTestResultLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.logging.SpecimenTestResultLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.SpecimenTestResultLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.logging.SpecimenTestResultTopic.serializeBinaryToWriter
    );
  }
};


/**
 * optional string log_id = 2;
 * @return {string}
 */
proto.logging.SpecimenTestResultLog.prototype.getLogId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.logging.SpecimenTestResultLog} returns this
 */
proto.logging.SpecimenTestResultLog.prototype.setLogId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SpecimenTestResultTopic topics = 3;
 * @return {!Array<!proto.logging.SpecimenTestResultTopic>}
 */
proto.logging.SpecimenTestResultLog.prototype.getTopicsList = function() {
  return /** @type{!Array<!proto.logging.SpecimenTestResultTopic>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.logging.SpecimenTestResultTopic, 3));
};


/**
 * @param {!Array<!proto.logging.SpecimenTestResultTopic>} value
 * @return {!proto.logging.SpecimenTestResultLog} returns this
*/
proto.logging.SpecimenTestResultLog.prototype.setTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.logging.SpecimenTestResultTopic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.logging.SpecimenTestResultTopic}
 */
proto.logging.SpecimenTestResultLog.prototype.addTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.logging.SpecimenTestResultTopic, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.logging.SpecimenTestResultLog} returns this
 */
proto.logging.SpecimenTestResultLog.prototype.clearTopicsList = function() {
  return this.setTopicsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.logging.SpecimenTestResultTopic.prototype.toObject = function(opt_includeInstance) {
  return proto.logging.SpecimenTestResultTopic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.logging.SpecimenTestResultTopic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.SpecimenTestResultTopic.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: jspb.Message.getFieldWithDefault(msg, 1, ""),
    msgCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.logging.SpecimenTestResultTopic}
 */
proto.logging.SpecimenTestResultTopic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.logging.SpecimenTestResultTopic;
  return proto.logging.SpecimenTestResultTopic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.logging.SpecimenTestResultTopic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.logging.SpecimenTestResultTopic}
 */
proto.logging.SpecimenTestResultTopic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMsgCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.logging.SpecimenTestResultTopic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.logging.SpecimenTestResultTopic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.logging.SpecimenTestResultTopic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.SpecimenTestResultTopic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMsgCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string topic = 1;
 * @return {string}
 */
proto.logging.SpecimenTestResultTopic.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.logging.SpecimenTestResultTopic} returns this
 */
proto.logging.SpecimenTestResultTopic.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 msg_count = 2;
 * @return {number}
 */
proto.logging.SpecimenTestResultTopic.prototype.getMsgCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.logging.SpecimenTestResultTopic} returns this
 */
proto.logging.SpecimenTestResultTopic.prototype.setMsgCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.logging.StreamLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.logging.StreamLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.logging.StreamLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.StreamLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    logId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    topic: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.logging.StreamLogsRequest}
 */
proto.logging.StreamLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.logging.StreamLogsRequest;
  return proto.logging.StreamLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.logging.StreamLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.logging.StreamLogsRequest}
 */
proto.logging.StreamLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.logging.StreamLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.logging.StreamLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.logging.StreamLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.StreamLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string log_id = 1;
 * @return {string}
 */
proto.logging.StreamLogsRequest.prototype.getLogId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.logging.StreamLogsRequest} returns this
 */
proto.logging.StreamLogsRequest.prototype.setLogId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string topic = 2;
 * @return {string}
 */
proto.logging.StreamLogsRequest.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.logging.StreamLogsRequest} returns this
 */
proto.logging.StreamLogsRequest.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.logging.DeleteLogsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.logging.DeleteLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.logging.DeleteLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.logging.DeleteLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.DeleteLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    logIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.logging.DeleteLogsRequest}
 */
proto.logging.DeleteLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.logging.DeleteLogsRequest;
  return proto.logging.DeleteLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.logging.DeleteLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.logging.DeleteLogsRequest}
 */
proto.logging.DeleteLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addLogId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.logging.DeleteLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.logging.DeleteLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.logging.DeleteLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.DeleteLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string log_id = 1;
 * @return {!Array<string>}
 */
proto.logging.DeleteLogsRequest.prototype.getLogIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.logging.DeleteLogsRequest} returns this
 */
proto.logging.DeleteLogsRequest.prototype.setLogIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.logging.DeleteLogsRequest} returns this
 */
proto.logging.DeleteLogsRequest.prototype.addLogId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.logging.DeleteLogsRequest} returns this
 */
proto.logging.DeleteLogsRequest.prototype.clearLogIdList = function() {
  return this.setLogIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.logging.StreamLogsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.logging.StreamLogsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.logging.StreamLogsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.StreamLogsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.logging.StreamLogsResponse}
 */
proto.logging.StreamLogsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.logging.StreamLogsResponse;
  return proto.logging.StreamLogsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.logging.StreamLogsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.logging.StreamLogsResponse}
 */
proto.logging.StreamLogsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.logging.StreamLogsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.logging.StreamLogsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.logging.StreamLogsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.logging.StreamLogsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.logging.StorageType = {
  STORAGE_TYPE_USER: 0,
  STORAGE_TYPE_SYSTEM: 1,
  STORAGE_TYPE_TEMP: 2
};

/**
 * @enum {number}
 */
proto.logging.MediaType = {
  MEDIA_TYPE_SD_CARD: 0,
  MEDIA_TYPE_USB: 1,
  MEDIA_TYPE_INTERNAL: 2,
  MEDIA_TYPE_UNKNOWN: 3
};

goog.object.extend(exports, proto.logging);
