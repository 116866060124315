import axios from '../../httpCommon';
import { IProductDownloadUrl, IProductRequestDto, IProductResponseDto } from './';

export const getProducts = async (): Promise<IProductResponseDto[]> => {
	const response = await axios.get('/cassiopeia/product', {
		params: { page: 0, size: 100 }
	});
	return response.data.response.data as IProductResponseDto[];
};

export const getProduct = async (id: string): Promise<IProductResponseDto> => {
	const response = await axios.get(`/cassiopeia/product/me/${id}`);

	return response.data.response as IProductResponseDto;
};

export const checkName = async (prop: string, uuid?: string): Promise<boolean> => {
	const response = await axios.post('/cassiopeia/product/productNameIsUnique', { prop, uuid });
	return response.data.response.isUnique === true;
};

export const createProduct = async (product: IProductRequestDto): Promise<IProductResponseDto> => {
	const response = await axios.post('/cassiopeia/product', product);
	return response.data.response as IProductResponseDto;
};

export const updateProduct = async (product: IProductRequestDto): Promise<IProductResponseDto> => {
	const response = await axios.put('/cassiopeia/product', product);
	return response.data.response;
};

export const buildProduct = async (uuid: string): Promise<IProductResponseDto> => {
	const response = await axios.put('/cassiopeia/product/build', { uuid });
	return response.data.response;
};

export const getDownloadUrl = async (uuid: string): Promise<IProductDownloadUrl> => {
	const response = await axios.get(`/cassiopeia/product/me/download/${uuid}`);
	return response.data.response;
};
