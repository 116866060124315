export enum ControlChannelsTableActionTypes {
	EDIT = 'EDIT',
	DELETE = 'DELETE',
	DUPLICATE = 'DUPLICATE',
	CREATE = 'CREATE'
}

export enum ControlChannelSetPointType {
	WAVEFORM = 0,
	MEASUREMENT
}

export enum ControlChannelWaveformTypes {
	RAMP = 'Ramp'
}

export enum Directions {
	UP = 'Up',
	DOWN = 'Down'
}
