import axios from '../../httpCommon';
import { IStationChannelResponseDto } from '../StationChannels';
import { SensorRequestDto, SensorResponseDto } from './sensors.types';

export const checkName = async (name: string, id?: string, parentId?: string): Promise<boolean> => {
	const response = await axios.post('/quasar/sensors/isNameUnique', { id, name, parentId });
	return response.data === true;
};

export const getSensorsForStation = async (stationId?: string) => {
	const response = await axios.get(`/quasar/sensors/station/${stationId}`);

	return response.data as SensorResponseDto[];
};

export const createSensor = async (sensor: SensorRequestDto) => {
	const response = await axios.post('/quasar/sensors', sensor);
	return response.data as SensorResponseDto;
};

export const updateSensor = async (sensor: SensorRequestDto, sensorId: string) => {
	const response = await axios.put(`/quasar/sensors/${sensorId}`, sensor);
	return response.data as SensorResponseDto;
};

export const getSensor = async (id: string) => {
	const response = await axios.get(`/quasar/sensors/${id}`);

	return response.data as SensorResponseDto;
};

export const getAvailableStationChannels = async (stationId: string) => {
	const response = await axios.get(`/quasar/sensors/available-station-channels/${stationId}`);

	return response.data as IStationChannelResponseDto[];
};

export const getSensorsManufacturers = async () => {
	const response = await axios.get('/quasar/sensors/manufacturers');

	return response.data as string[];
};
