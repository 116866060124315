import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIsChanged } from '@tactun/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import StationChannel from '../components/StationChannel';
import { stationChannelSchema } from '../stationChannels.schemas';
import {
	ControllerChannelMeta,
	IChannel,
	StationChannelFormType,
	IStationChannelResponseDto,
	StationChannelRequestDto
} from '../stationChannels.types';
import { IListItem } from '../../../types';
import * as api from '../stationChannels.api';
import {
	controllerChannelDtoToControllerChannelMetaConverter,
	formRequestConverter,
	responseFormConverter
} from '../stationChannels.converters';
import { useStationName } from '../../Stations/stations.hooks';
import WarningModal from '../../../components/WarningModal';

const defaultValues = {
	id: undefined,
	name: '',
	type: undefined,
	channelId: undefined,
	direction: undefined,
	activeState: undefined,
	defaultState: undefined,
	pullUpResistor: false,
	specificType: undefined,
	range: undefined,
	samplingRate: undefined,
	stationId: '',
	bridgeType: undefined,
	offset: 0,
	frequency: 10,
	limit: 0,
	excitationAmplitude: undefined,
	excitationFrequency: undefined
};

const warningModalId = 'stationWarningModalId';

const StationChannelContainer: React.FC = () => {
	const { t } = useTranslation('stationChannel');
	const { stationId, channelId } = useParams();
	const navigate = useNavigate();

	const { stationName } = useStationName(stationId);
	const { data: currentChannel } = useQuery<IStationChannelResponseDto, Error>({
		queryKey: ['channel', channelId],
		queryFn: async () => {
			const channelData = await api.getChannelById(channelId);
			const checkUseResp = await api.checkIsChannelInUse(channelId);
			const isUsed =
				checkUseResp?.entityNameAndIds?.ACTUATOR?.length > 0 || checkUseResp?.entityNameAndIds?.SENSOR?.length > 0;

			return {
				...channelData,
				isUsed
			};
		},
		enabled: !!channelId
	});

	const form = useForm<StationChannelFormType>({
		defaultValues: { ...defaultValues, stationId },
		mode: 'onBlur',
		resolver: yupResolver(stationChannelSchema)
	});

	const {
		watch,
		handleSubmit,
		reset,
		getValues,
		formState: { isDirty }
	} = form;
	const type = watch('type');
	// const direction = watch('direction');
	const isTypeChanged = useIsChanged(type);
	// const isDirectionChanged = useIsChanged(direction);
	const isCreate = !channelId;

	const { data: station } = useQuery<{ controllerModel: string }, Error>({
		queryKey: ['station', stationId],
		queryFn: () => api.getStationById(stationId)
	});

	// Get channels additional params for each type
	const { data: controllerChannels } = useQuery<ControllerChannelMeta[], Error>({
		queryKey: ['controllerChannels', { controllerModel: station?.controllerModel }],
		queryFn: async () => {
			const deviceResp = await api.getDeviceByModel(station?.controllerModel);
			return deviceResp.controllerChannels?.map((channel) =>
				controllerChannelDtoToControllerChannelMetaConverter(channel, t)
			);
		},
		enabled: !!station?.controllerModel
	});

	const { data: unusedChannels } = useQuery<IChannel[], Error>({
		queryKey: ['channels', { typeId: type, stationId }],
		queryFn: () => api.getUnusedChannels(stationId, type),
		enabled: type !== undefined
	});

	const createMutation = useMutation({
		mutationFn: (stationChannel: StationChannelRequestDto) => api.createStationChannel(stationChannel),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});
	const updateMutation = useMutation({
		mutationFn: (data: { stationChannel: StationChannelRequestDto; channelId: string }) =>
			api.updateStationChannel(data.stationChannel, data.channelId),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/station-channels`);
	}, [navigate, stationId]);

	const handleReset = useCallback(() => {
		if (currentChannel) {
			reset({ ...responseFormConverter(currentChannel) }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultValues, stationId }, { keepDirty: true, keepTouched: true });
		}
	}, [currentChannel, reset, stationId]);

	const handleSave = handleSubmit((data) => {
		const requestData = formRequestConverter(data);
		if (channelId) {
			data.id = undefined;

			updateMutation.mutate({ stationChannel: requestData, channelId });
		} else {
			createMutation.mutate(requestData);
		}
	});

	const channels = useMemo<IListItem[]>(() => {
		const unusedChannelsList =
			unusedChannels?.map<IListItem>((channel) => ({ label: `Channel ${channel.seq}`, value: channel.id })) ?? [];

		// add used channel to the channel list for edit mode
		if (
			currentChannel?.channel &&
			currentChannel.channel?.type === type &&
			!unusedChannelsList.some((ch) => ch.value === currentChannel.channel.id)
		) {
			unusedChannelsList.unshift({
				label: `Channel ${currentChannel.channel.seq}`,
				value: currentChannel.channel.id
			});
		}
		return unusedChannelsList;
	}, [unusedChannels, currentChannel, type]);

	//init current channel for edit
	useEffect(() => {
		if (currentChannel) {
			handleReset();
		}
	}, [currentChannel, handleReset]);

	// show error when no empty channel
	const [error, setError] = useState('');
	useEffect(() => {
		if (type !== undefined && type !== null && unusedChannels && channels.length === 0) {
			WarningModal.show(warningModalId);
			setError('No unused channels, please select another channel type.');
		}
	}, [channels, watch, t, type, unusedChannels]);

	// reset form data after type change
	useEffect(() => {
		if (isTypeChanged && isDirty) {
			const currentFormData = getValues();
			reset(
				{ ...defaultValues, name: currentFormData.name, type: currentFormData.type, stationId },
				{ keepDirty: true, keepTouched: true }
			);
		}
	}, [reset, isTypeChanged, getValues, stationId, isDirty]);

	return (
		<>
			<StationChannel
				isCreate={isCreate}
				isLoading={createMutation.isPending || updateMutation.isPending}
				form={form}
				channels={channels}
				isStationChannelInUse={currentChannel?.isUsed}
				channelData={controllerChannels}
				onBack={handleBack}
				onSave={handleSave}
				stationName={stationName}
			/>
			<WarningModal title={t('Validation Errors')} id={warningModalId}>
				{error}
			</WarningModal>
		</>
	);
};

export default React.memo(StationChannelContainer);
