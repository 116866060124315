import { Routes, Route } from 'react-router-dom';
import OrdersContainer from './containers/OrdersContainer';
import OrdersTableContainer from './containers/OrdersTableContainer';

const OrdersRouts: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<OrdersTableContainer />} />
			<Route path="create" element={<OrdersContainer />} />
			<Route path="edit/:orderId" element={<OrdersContainer />} />
		</Routes>
	);
};

export default OrdersRouts;
