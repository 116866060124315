import { TreeTable as PrimTreeTable, TreeTableProps } from 'primereact/treetable';
import cx from 'classnames';
import Column, { IColumnProps } from './Column';
import styles from './TreeTable.module.scss';

export interface ITreeTableProps extends TreeTableProps {
	className?: string;
	children: React.ReactNode;
}

export interface ITreeTableSubComponents {
	Column: React.FC<IColumnProps>;
}

const TreeTable: React.FC<ITreeTableProps> & ITreeTableSubComponents = ({ children, className, ...rest }) => {
	return (
		<PrimTreeTable className={cx(styles.container, className)} {...rest}>
			{children}
		</PrimTreeTable>
	);
};

TreeTable.displayName = 'TreeTable';
TreeTable.Column = Column;

export default TreeTable;
