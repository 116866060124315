import { IUserRoles } from '../keycloakAxios';

export const hasRoles = (roles?: IUserRoles, userRoles?: IUserRoles) => {
	if (!userRoles) {
		return false;
	}
	if (!roles) {
		return true;
	}
	return Object.keys(userRoles).some(
		(clientName) =>
			userRoles[clientName] &&
			roles[clientName] &&
			userRoles[clientName].some((role: string) => roles[clientName].some((pr: string) => role === pr))
	);
};
