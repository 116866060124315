import React, { useCallback, useMemo } from 'react';

import { ButtonForm } from '../../buttonWidget.types';
import CustomButton from '../CustomButton';
import { useDashboardVariables } from '../../../Dashboard/dashboard.hooks';
import {
	buttonToDisableStateVariableName,
	buttonToPressStateVariableName,
	buttonWidgetRulesDtoToEventActions
} from '../../buttonWidget.converters';
import { ButtonDefaultState } from '../../buttonWidget.enums';
import { StationRuleResponseDto, useExecuteRules } from '../../../Rules';
import { useControlChannels } from '../../../ControlChannels';
import { useStationId } from '../../../Stations';

export interface CustomButtonProps {
	button: ButtonForm;
	width?: number;
	height?: number;
}

const CustomButtonContainer: React.FC<CustomButtonProps> = ({ button, width, height }) => {
	const { id, rules = [] } = button;
	const stationId = useStationId();

	const { execute } = useExecuteRules(stationId);

	const { controlChannels } = useControlChannels(stationId);

	const pressStateVariableName = buttonToPressStateVariableName(id as string);
	const disableStateVariableName = buttonToDisableStateVariableName(id as string);
	const pressed = useDashboardVariables((vars) => vars[pressStateVariableName] === ButtonDefaultState.PRESSED);
	const disabled = useDashboardVariables((vars) => !!vars[disableStateVariableName]);

	const { press: pressRules, release: releaseRules } = useMemo(
		() => buttonWidgetRulesDtoToEventActions(rules as StationRuleResponseDto[], id as string, controlChannels),
		[id, rules, controlChannels]
	);

	const onClick = useCallback(() => {
		if (pressRules.length && !releaseRules.length) execute(pressRules);
	}, [execute, pressRules, releaseRules]);

	const onMouseUp = useCallback(() => {
		document.documentElement.removeEventListener('mouseup', onMouseUp);
		execute(releaseRules);
	}, [execute, releaseRules]);

	const onMouseDown = useCallback(() => {
		if (pressRules.length && releaseRules.length) {
			document.documentElement.addEventListener('mouseup', onMouseUp);
			execute(pressRules);
		}
	}, [execute, onMouseUp, pressRules, releaseRules]);

	return (
		<CustomButton
			button={button}
			onClick={onClick}
			onMouseDown={onMouseDown}
			pressed={pressed}
			disabled={disabled}
			tooltip={button.tooltip}
			width={width}
			height={height}
		/>
	);
};

export default CustomButtonContainer;
