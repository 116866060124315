import React, { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CreateEditPageTemplate from '../../../components/CreateEditPageTemplate';
import { useStationName } from '../../Stations';
import RuleCreateEdit, { StationRuleRequestDto, TestRuleRequestDto } from '../../Rules';
import { useStationRule } from '../stationRules.hooks';
import { stationRuleSchema } from '../stationRules.schemas';
import * as api from '../stationRules.api';

const StationRuleContainer: React.FC = () => {
	const { t } = useTranslation('stationRules');
	const { stationId, stationRuleId } = useParams();
	const navigate = useNavigate();
	const { stationName } = useStationName(stationId);
	const { stationRuleDto } = useStationRule(stationRuleId);
	const isCreate = !stationRuleId;

	const createMutation = useMutation({
		mutationFn: (stationRule: StationRuleRequestDto) => api.createStationRule(stationRule),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { stationRule: StationRuleRequestDto; id: string }) =>
			api.updateStationRule(data.stationRule, data.id),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const handleSave = useCallback(
		(rule: StationRuleRequestDto | TestRuleRequestDto) => {
			if (!stationRuleId) {
				createMutation.mutate(rule as StationRuleRequestDto);
			} else {
				updateMutation.mutate({
					stationRule: rule as StationRuleRequestDto,
					id: stationRuleId
				});
			}
		},
		[createMutation, stationRuleId, updateMutation]
	);

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/rules`);
	}, [navigate, stationId]);

	return (
		<CreateEditPageTemplate
			isLoading={createMutation.isPending || updateMutation.isPending}
			title={isCreate ? t('Create Rule') : t('Edit Rule')}
			stationName={stationName}
			formId="stationRuleForm"
			onBack={handleBack}
		>
			<RuleCreateEdit
				isStationRule
				stationId={stationId}
				formId="stationRuleForm"
				rule={stationRuleDto}
				ruleId={stationRuleId}
				ruleSchema={stationRuleSchema}
				onSave={handleSave}
			/>
		</CreateEditPageTemplate>
	);
};

export default React.memo(StationRuleContainer);
