import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { grpc } from '@improbable-eng/grpc-web';
import { systemService, Error as GrpcError, ErrorType } from '@tactun/grpc-client';
import { UnaryOutput } from '@improbable-eng/grpc-web/dist/typings/unary';
import { StationResponseDto } from './station.types';
import { stationResponseListToGRPC } from './station.converters';
import { grpcErrorToMessage } from '../../tools/grpcHelper';

export interface IStationConfigurationError {
	code: string;
	message: string;
}

export interface IStationConfigurationData {
	data: number;
}

type UseStationConfigurationCallback = (data: { isConfigured: boolean; stationId: string }) => void;

export const useStationConfiguration = (onEnd: UseStationConfigurationCallback) => {
	const [error, setError] = useState<IStationConfigurationError>();
	const onEndRef = useRef<UseStationConfigurationCallback>();

	useEffect(() => {
		onEndRef.current = onEnd;
	}, [onEnd]);

	const configure = useCallback((station: StationResponseDto) => {
		if (process.env.REACT_APP_DEVICE_URL) {
			const configurationRequest = stationResponseListToGRPC([station]);
			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

			grpc.unary(systemService.WebSystem.Configure, {
				request: configurationRequest,
				host: process.env.REACT_APP_DEVICE_URL,
				transport: myTransport,
				//debug: true,
				onEnd: (output: UnaryOutput<GrpcError>) => {
					if (output.message?.getErrorType() === ErrorType.OK) {
						if (onEndRef.current) onEndRef.current({ isConfigured: true, stationId: station.id });
					} else {
						const message = grpcErrorToMessage(output.message, output.statusMessage);
						setError({
							code: output.message?.getErrorType().toString() ?? '',
							message: message
						});
						if (onEndRef.current) onEndRef.current({ isConfigured: false, stationId: station.id });
					}
				}
			});
		}
	}, []);

	const response = useMemo(() => {
		return { configure, error };
	}, [configure, error]);

	return response;
};
