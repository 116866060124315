// source: common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.common.ActiveOrDefaultState', null, global);
goog.exportSymbol('proto.common.Adaptive', null, global);
goog.exportSymbol('proto.common.AiAndServoDrvrType', null, global);
goog.exportSymbol('proto.common.AlgoOpenLoop', null, global);
goog.exportSymbol('proto.common.AlgoPID', null, global);
goog.exportSymbol('proto.common.AlgorithmType', null, global);
goog.exportSymbol('proto.common.AsymmetricPid', null, global);
goog.exportSymbol('proto.common.AxisInfo', null, global);
goog.exportSymbol('proto.common.BridgeType', null, global);
goog.exportSymbol('proto.common.Calibration', null, global);
goog.exportSymbol('proto.common.CalibrationRequest', null, global);
goog.exportSymbol('proto.common.CalibrationTupleDto', null, global);
goog.exportSymbol('proto.common.CalibrationType', null, global);
goog.exportSymbol('proto.common.ControlInfo', null, global);
goog.exportSymbol('proto.common.DefinitionMode', null, global);
goog.exportSymbol('proto.common.Direction', null, global);
goog.exportSymbol('proto.common.Dither', null, global);
goog.exportSymbol('proto.common.EndChannelType', null, global);
goog.exportSymbol('proto.common.ExcitationVoltage', null, global);
goog.exportSymbol('proto.common.FeedForwardDto', null, global);
goog.exportSymbol('proto.common.Gain', null, global);
goog.exportSymbol('proto.common.HardwareChannel', null, global);
goog.exportSymbol('proto.common.IODirection', null, global);
goog.exportSymbol('proto.common.LinearCalibration', null, global);
goog.exportSymbol('proto.common.PartNumbers', null, global);
goog.exportSymbol('proto.common.PgaGain', null, global);
goog.exportSymbol('proto.common.PiecewiseCalibration', null, global);
goog.exportSymbol('proto.common.PullupResistor', null, global);
goog.exportSymbol('proto.common.RampDirection', null, global);
goog.exportSymbol('proto.common.Range', null, global);
goog.exportSymbol('proto.common.SamplingRate', null, global);
goog.exportSymbol('proto.common.SetPointType', null, global);
goog.exportSymbol('proto.common.StationChannelAnalogIn10Ksps', null, global);
goog.exportSymbol('proto.common.StationChannelAnalogIn10V24MA', null, global);
goog.exportSymbol('proto.common.StationChannelAnalogIn60Ksps', null, global);
goog.exportSymbol('proto.common.StationChannelAnalogOut100MA', null, global);
goog.exportSymbol('proto.common.StationChannelAnalogOut10V', null, global);
goog.exportSymbol('proto.common.StationChannelAnalogOut10V24MA', null, global);
goog.exportSymbol('proto.common.StationChannelDcPwm30V', null, global);
goog.exportSymbol('proto.common.StationChannelDcPwm50V', null, global);
goog.exportSymbol('proto.common.StationChannelDcPwm65V', null, global);
goog.exportSymbol('proto.common.StationChannelDigitalIO24V', null, global);
goog.exportSymbol('proto.common.StationChannelDigitalIO5V', null, global);
goog.exportSymbol('proto.common.StationChannelDigitalIn24V', null, global);
goog.exportSymbol('proto.common.StationChannelEncoder', null, global);
goog.exportSymbol('proto.common.StationChannelLVDT', null, global);
goog.exportSymbol('proto.common.StationChannelRelay', null, global);
goog.exportSymbol('proto.common.StationChannelSignalConditioning', null, global);
goog.exportSymbol('proto.common.StationChannelStepper', null, global);
goog.exportSymbol('proto.common.StationChannelType', null, global);
goog.exportSymbol('proto.common.StationState', null, global);
goog.exportSymbol('proto.common.UnitFormula', null, global);
goog.exportSymbol('proto.common.UnitSystemType', null, global);
goog.exportSymbol('proto.common.ValOrId', null, global);
goog.exportSymbol('proto.common.ValOrId.ValueTypeCase', null, global);
goog.exportSymbol('proto.common.VariablePredicate', null, global);
goog.exportSymbol('proto.common.VariableRequest', null, global);
goog.exportSymbol('proto.common.VariableType', null, global);
goog.exportSymbol('proto.common.Void', null, global);
goog.exportSymbol('proto.common.WFProps', null, global);
goog.exportSymbol('proto.common.WFProps.WaveformCase', null, global);
goog.exportSymbol('proto.common.WF_Constant', null, global);
goog.exportSymbol('proto.common.WF_DuoHaverSine', null, global);
goog.exportSymbol('proto.common.WF_DuoRamp', null, global);
goog.exportSymbol('proto.common.WF_Dwell', null, global);
goog.exportSymbol('proto.common.WF_HaverSine', null, global);
goog.exportSymbol('proto.common.WF_Ramp', null, global);
goog.exportSymbol('proto.common.WF_Sine', null, global);
goog.exportSymbol('proto.common.WF_Squarewave', null, global);
goog.exportSymbol('proto.common.WF_SweepSine', null, global);
goog.exportSymbol('proto.common.WF_TaperedSine', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ValOrId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.ValOrId.oneofGroups_);
};
goog.inherits(proto.common.ValOrId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ValOrId.displayName = 'proto.common.ValOrId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Void = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Void, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Void.displayName = 'proto.common.Void';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.HardwareChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.HardwareChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.HardwareChannel.displayName = 'proto.common.HardwareChannel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelSignalConditioning = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelSignalConditioning, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelSignalConditioning.displayName = 'proto.common.StationChannelSignalConditioning';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelAnalogIn10Ksps = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelAnalogIn10Ksps, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelAnalogIn10Ksps.displayName = 'proto.common.StationChannelAnalogIn10Ksps';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelDigitalIO5V = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelDigitalIO5V, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelDigitalIO5V.displayName = 'proto.common.StationChannelDigitalIO5V';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelDigitalIO24V = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelDigitalIO24V, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelDigitalIO24V.displayName = 'proto.common.StationChannelDigitalIO24V';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelRelay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelRelay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelRelay.displayName = 'proto.common.StationChannelRelay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelDigitalIn24V = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelDigitalIn24V, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelDigitalIn24V.displayName = 'proto.common.StationChannelDigitalIn24V';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelAnalogIn10V24MA = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelAnalogIn10V24MA, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelAnalogIn10V24MA.displayName = 'proto.common.StationChannelAnalogIn10V24MA';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelAnalogOut10V = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelAnalogOut10V, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelAnalogOut10V.displayName = 'proto.common.StationChannelAnalogOut10V';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelAnalogOut100MA = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelAnalogOut100MA, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelAnalogOut100MA.displayName = 'proto.common.StationChannelAnalogOut100MA';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelAnalogOut10V24MA = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelAnalogOut10V24MA, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelAnalogOut10V24MA.displayName = 'proto.common.StationChannelAnalogOut10V24MA';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelDcPwm30V = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelDcPwm30V, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelDcPwm30V.displayName = 'proto.common.StationChannelDcPwm30V';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelDcPwm50V = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelDcPwm50V, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelDcPwm50V.displayName = 'proto.common.StationChannelDcPwm50V';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelDcPwm65V = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelDcPwm65V, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelDcPwm65V.displayName = 'proto.common.StationChannelDcPwm65V';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelStepper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelStepper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelStepper.displayName = 'proto.common.StationChannelStepper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelEncoder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelEncoder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelEncoder.displayName = 'proto.common.StationChannelEncoder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelAnalogIn60Ksps = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelAnalogIn60Ksps, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelAnalogIn60Ksps.displayName = 'proto.common.StationChannelAnalogIn60Ksps';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StationChannelLVDT = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StationChannelLVDT, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StationChannelLVDT.displayName = 'proto.common.StationChannelLVDT';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.WFProps = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.WFProps.oneofGroups_);
};
goog.inherits(proto.common.WFProps, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.WFProps.displayName = 'proto.common.WFProps';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.WF_Ramp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.WF_Ramp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.WF_Ramp.displayName = 'proto.common.WF_Ramp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.WF_Dwell = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.WF_Dwell, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.WF_Dwell.displayName = 'proto.common.WF_Dwell';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.WF_Constant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.WF_Constant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.WF_Constant.displayName = 'proto.common.WF_Constant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.WF_Sine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.WF_Sine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.WF_Sine.displayName = 'proto.common.WF_Sine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.WF_DuoRamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.WF_DuoRamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.WF_DuoRamp.displayName = 'proto.common.WF_DuoRamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.WF_HaverSine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.WF_HaverSine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.WF_HaverSine.displayName = 'proto.common.WF_HaverSine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.WF_DuoHaverSine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.WF_DuoHaverSine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.WF_DuoHaverSine.displayName = 'proto.common.WF_DuoHaverSine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.WF_TaperedSine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.WF_TaperedSine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.WF_TaperedSine.displayName = 'proto.common.WF_TaperedSine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.WF_SweepSine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.WF_SweepSine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.WF_SweepSine.displayName = 'proto.common.WF_SweepSine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.WF_Squarewave = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.WF_Squarewave, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.WF_Squarewave.displayName = 'proto.common.WF_Squarewave';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AlgoPID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AlgoPID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AlgoPID.displayName = 'proto.common.AlgoPID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AlgoOpenLoop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AlgoOpenLoop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AlgoOpenLoop.displayName = 'proto.common.AlgoOpenLoop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Dither = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Dither, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Dither.displayName = 'proto.common.Dither';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AsymmetricPid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AsymmetricPid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AsymmetricPid.displayName = 'proto.common.AsymmetricPid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.FeedForwardDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.FeedForwardDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.FeedForwardDto.displayName = 'proto.common.FeedForwardDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Adaptive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Adaptive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Adaptive.displayName = 'proto.common.Adaptive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Calibration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Calibration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Calibration.displayName = 'proto.common.Calibration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.CalibrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.CalibrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.CalibrationRequest.displayName = 'proto.common.CalibrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.LinearCalibration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.LinearCalibration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.LinearCalibration.displayName = 'proto.common.LinearCalibration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.PiecewiseCalibration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.PiecewiseCalibration.repeatedFields_, null);
};
goog.inherits(proto.common.PiecewiseCalibration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.PiecewiseCalibration.displayName = 'proto.common.PiecewiseCalibration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.CalibrationTupleDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.CalibrationTupleDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.CalibrationTupleDto.displayName = 'proto.common.CalibrationTupleDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AxisInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AxisInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AxisInfo.displayName = 'proto.common.AxisInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ControlInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.ControlInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ControlInfo.displayName = 'proto.common.ControlInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.UnitFormula = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.UnitFormula, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.UnitFormula.displayName = 'proto.common.UnitFormula';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.VariableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.VariableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.VariableRequest.displayName = 'proto.common.VariableRequest';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.ValOrId.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.common.ValOrId.ValueTypeCase = {
  VALUE_TYPE_NOT_SET: 0,
  VALUE: 1,
  VAR_ID: 2
};

/**
 * @return {proto.common.ValOrId.ValueTypeCase}
 */
proto.common.ValOrId.prototype.getValueTypeCase = function() {
  return /** @type {proto.common.ValOrId.ValueTypeCase} */(jspb.Message.computeOneofCase(this, proto.common.ValOrId.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ValOrId.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ValOrId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ValOrId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ValOrId.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    varId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ValOrId}
 */
proto.common.ValOrId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ValOrId;
  return proto.common.ValOrId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ValOrId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ValOrId}
 */
proto.common.ValOrId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVarId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ValOrId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ValOrId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ValOrId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ValOrId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional float value = 1;
 * @return {number}
 */
proto.common.ValOrId.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.ValOrId} returns this
 */
proto.common.ValOrId.prototype.setValue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.common.ValOrId.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.ValOrId} returns this
 */
proto.common.ValOrId.prototype.clearValue = function() {
  return jspb.Message.setOneofField(this, 1, proto.common.ValOrId.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ValOrId.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string var_id = 2;
 * @return {string}
 */
proto.common.ValOrId.prototype.getVarId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ValOrId} returns this
 */
proto.common.ValOrId.prototype.setVarId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.common.ValOrId.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.ValOrId} returns this
 */
proto.common.ValOrId.prototype.clearVarId = function() {
  return jspb.Message.setOneofField(this, 2, proto.common.ValOrId.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ValOrId.prototype.hasVarId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Void.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Void.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Void} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Void.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Void}
 */
proto.common.Void.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Void;
  return proto.common.Void.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Void} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Void}
 */
proto.common.Void.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Void.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Void.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Void} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Void.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.HardwareChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.common.HardwareChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.HardwareChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.HardwareChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    partNumber: jspb.Message.getFieldWithDefault(msg, 18, 0),
    boardIndex: jspb.Message.getFieldWithDefault(msg, 19, 0),
    componentIndex: jspb.Message.getFieldWithDefault(msg, 20, 0),
    channelIndex: jspb.Message.getFieldWithDefault(msg, 21, 0),
    dmaIndex: jspb.Message.getFieldWithDefault(msg, 22, 0),
    dmaChannelIndex: jspb.Message.getFieldWithDefault(msg, 23, 0),
    sharedMemAddr: jspb.Message.getFieldWithDefault(msg, 24, 0),
    sensorIndex: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.HardwareChannel}
 */
proto.common.HardwareChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.HardwareChannel;
  return proto.common.HardwareChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.HardwareChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.HardwareChannel}
 */
proto.common.HardwareChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 18:
      var value = /** @type {!proto.common.PartNumbers} */ (reader.readEnum());
      msg.setPartNumber(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBoardIndex(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setComponentIndex(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannelIndex(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDmaIndex(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDmaChannelIndex(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSharedMemAddr(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSensorIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.HardwareChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.HardwareChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.HardwareChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.HardwareChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartNumber();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getBoardIndex();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
  f = message.getComponentIndex();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
  f = message.getChannelIndex();
  if (f !== 0) {
    writer.writeUint32(
      21,
      f
    );
  }
  f = message.getDmaIndex();
  if (f !== 0) {
    writer.writeUint32(
      22,
      f
    );
  }
  f = message.getDmaChannelIndex();
  if (f !== 0) {
    writer.writeUint32(
      23,
      f
    );
  }
  f = message.getSharedMemAddr();
  if (f !== 0) {
    writer.writeUint64(
      24,
      f
    );
  }
  f = message.getSensorIndex();
  if (f !== 0) {
    writer.writeUint32(
      25,
      f
    );
  }
};


/**
 * optional PartNumbers part_number = 18;
 * @return {!proto.common.PartNumbers}
 */
proto.common.HardwareChannel.prototype.getPartNumber = function() {
  return /** @type {!proto.common.PartNumbers} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.common.PartNumbers} value
 * @return {!proto.common.HardwareChannel} returns this
 */
proto.common.HardwareChannel.prototype.setPartNumber = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional uint32 board_index = 19;
 * @return {number}
 */
proto.common.HardwareChannel.prototype.getBoardIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.HardwareChannel} returns this
 */
proto.common.HardwareChannel.prototype.setBoardIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional uint32 component_index = 20;
 * @return {number}
 */
proto.common.HardwareChannel.prototype.getComponentIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.HardwareChannel} returns this
 */
proto.common.HardwareChannel.prototype.setComponentIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint32 channel_index = 21;
 * @return {number}
 */
proto.common.HardwareChannel.prototype.getChannelIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.HardwareChannel} returns this
 */
proto.common.HardwareChannel.prototype.setChannelIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint32 dma_index = 22;
 * @return {number}
 */
proto.common.HardwareChannel.prototype.getDmaIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.HardwareChannel} returns this
 */
proto.common.HardwareChannel.prototype.setDmaIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional uint32 dma_channel_index = 23;
 * @return {number}
 */
proto.common.HardwareChannel.prototype.getDmaChannelIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.HardwareChannel} returns this
 */
proto.common.HardwareChannel.prototype.setDmaChannelIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional uint64 shared_mem_addr = 24;
 * @return {number}
 */
proto.common.HardwareChannel.prototype.getSharedMemAddr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.HardwareChannel} returns this
 */
proto.common.HardwareChannel.prototype.setSharedMemAddr = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional uint32 sensor_index = 25;
 * @return {number}
 */
proto.common.HardwareChannel.prototype.getSensorIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.HardwareChannel} returns this
 */
proto.common.HardwareChannel.prototype.setSensorIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelSignalConditioning.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelSignalConditioning.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelSignalConditioning} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelSignalConditioning.toObject = function(includeInstance, msg) {
  var f, obj = {
    bridgeType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    excitationVoltage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gain: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pgaGain: jspb.Message.getFieldWithDefault(msg, 4, 0),
    refPgaGain: jspb.Message.getFieldWithDefault(msg, 5, 0),
    samplingRate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tedsIndex: jspb.Message.getFieldWithDefault(msg, 7, 0),
    tedsAddr: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelSignalConditioning}
 */
proto.common.StationChannelSignalConditioning.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelSignalConditioning;
  return proto.common.StationChannelSignalConditioning.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelSignalConditioning} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelSignalConditioning}
 */
proto.common.StationChannelSignalConditioning.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.BridgeType} */ (reader.readEnum());
      msg.setBridgeType(value);
      break;
    case 2:
      var value = /** @type {!proto.common.ExcitationVoltage} */ (reader.readEnum());
      msg.setExcitationVoltage(value);
      break;
    case 3:
      var value = /** @type {!proto.common.Gain} */ (reader.readEnum());
      msg.setGain(value);
      break;
    case 4:
      var value = /** @type {!proto.common.PgaGain} */ (reader.readEnum());
      msg.setPgaGain(value);
      break;
    case 5:
      var value = /** @type {!proto.common.PgaGain} */ (reader.readEnum());
      msg.setRefPgaGain(value);
      break;
    case 6:
      var value = /** @type {!proto.common.SamplingRate} */ (reader.readEnum());
      msg.setSamplingRate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTedsIndex(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTedsAddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelSignalConditioning.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelSignalConditioning.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelSignalConditioning} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelSignalConditioning.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBridgeType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getExcitationVoltage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getGain();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPgaGain();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getRefPgaGain();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSamplingRate();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTedsIndex();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTedsAddr();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
};


/**
 * optional BridgeType bridge_type = 1;
 * @return {!proto.common.BridgeType}
 */
proto.common.StationChannelSignalConditioning.prototype.getBridgeType = function() {
  return /** @type {!proto.common.BridgeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.BridgeType} value
 * @return {!proto.common.StationChannelSignalConditioning} returns this
 */
proto.common.StationChannelSignalConditioning.prototype.setBridgeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ExcitationVoltage excitation_voltage = 2;
 * @return {!proto.common.ExcitationVoltage}
 */
proto.common.StationChannelSignalConditioning.prototype.getExcitationVoltage = function() {
  return /** @type {!proto.common.ExcitationVoltage} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.ExcitationVoltage} value
 * @return {!proto.common.StationChannelSignalConditioning} returns this
 */
proto.common.StationChannelSignalConditioning.prototype.setExcitationVoltage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Gain gain = 3;
 * @return {!proto.common.Gain}
 */
proto.common.StationChannelSignalConditioning.prototype.getGain = function() {
  return /** @type {!proto.common.Gain} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.Gain} value
 * @return {!proto.common.StationChannelSignalConditioning} returns this
 */
proto.common.StationChannelSignalConditioning.prototype.setGain = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional PgaGain pga_gain = 4;
 * @return {!proto.common.PgaGain}
 */
proto.common.StationChannelSignalConditioning.prototype.getPgaGain = function() {
  return /** @type {!proto.common.PgaGain} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.PgaGain} value
 * @return {!proto.common.StationChannelSignalConditioning} returns this
 */
proto.common.StationChannelSignalConditioning.prototype.setPgaGain = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional PgaGain ref_pga_gain = 5;
 * @return {!proto.common.PgaGain}
 */
proto.common.StationChannelSignalConditioning.prototype.getRefPgaGain = function() {
  return /** @type {!proto.common.PgaGain} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.PgaGain} value
 * @return {!proto.common.StationChannelSignalConditioning} returns this
 */
proto.common.StationChannelSignalConditioning.prototype.setRefPgaGain = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional SamplingRate sampling_rate = 6;
 * @return {!proto.common.SamplingRate}
 */
proto.common.StationChannelSignalConditioning.prototype.getSamplingRate = function() {
  return /** @type {!proto.common.SamplingRate} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.SamplingRate} value
 * @return {!proto.common.StationChannelSignalConditioning} returns this
 */
proto.common.StationChannelSignalConditioning.prototype.setSamplingRate = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional uint32 teds_index = 7;
 * @return {number}
 */
proto.common.StationChannelSignalConditioning.prototype.getTedsIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelSignalConditioning} returns this
 */
proto.common.StationChannelSignalConditioning.prototype.setTedsIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 teds_addr = 8;
 * @return {number}
 */
proto.common.StationChannelSignalConditioning.prototype.getTedsAddr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelSignalConditioning} returns this
 */
proto.common.StationChannelSignalConditioning.prototype.setTedsAddr = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelAnalogIn10Ksps.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelAnalogIn10Ksps.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelAnalogIn10Ksps} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelAnalogIn10Ksps.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    range: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelAnalogIn10Ksps}
 */
proto.common.StationChannelAnalogIn10Ksps.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelAnalogIn10Ksps;
  return proto.common.StationChannelAnalogIn10Ksps.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelAnalogIn10Ksps} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelAnalogIn10Ksps}
 */
proto.common.StationChannelAnalogIn10Ksps.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.AiAndServoDrvrType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Range} */ (reader.readEnum());
      msg.setRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelAnalogIn10Ksps.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelAnalogIn10Ksps.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelAnalogIn10Ksps} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelAnalogIn10Ksps.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional AiAndServoDrvrType type = 1;
 * @return {!proto.common.AiAndServoDrvrType}
 */
proto.common.StationChannelAnalogIn10Ksps.prototype.getType = function() {
  return /** @type {!proto.common.AiAndServoDrvrType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.AiAndServoDrvrType} value
 * @return {!proto.common.StationChannelAnalogIn10Ksps} returns this
 */
proto.common.StationChannelAnalogIn10Ksps.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Range range = 2;
 * @return {!proto.common.Range}
 */
proto.common.StationChannelAnalogIn10Ksps.prototype.getRange = function() {
  return /** @type {!proto.common.Range} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Range} value
 * @return {!proto.common.StationChannelAnalogIn10Ksps} returns this
 */
proto.common.StationChannelAnalogIn10Ksps.prototype.setRange = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelDigitalIO5V.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelDigitalIO5V.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelDigitalIO5V} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelDigitalIO5V.toObject = function(includeInstance, msg) {
  var f, obj = {
    direction: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activeState: jspb.Message.getFieldWithDefault(msg, 2, 0),
    defaultState: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pullUpResistor: jspb.Message.getFieldWithDefault(msg, 4, 0),
    bouncingLatency: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelDigitalIO5V}
 */
proto.common.StationChannelDigitalIO5V.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelDigitalIO5V;
  return proto.common.StationChannelDigitalIO5V.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelDigitalIO5V} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelDigitalIO5V}
 */
proto.common.StationChannelDigitalIO5V.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.IODirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 2:
      var value = /** @type {!proto.common.ActiveOrDefaultState} */ (reader.readEnum());
      msg.setActiveState(value);
      break;
    case 3:
      var value = /** @type {!proto.common.ActiveOrDefaultState} */ (reader.readEnum());
      msg.setDefaultState(value);
      break;
    case 4:
      var value = /** @type {!proto.common.PullupResistor} */ (reader.readEnum());
      msg.setPullUpResistor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBouncingLatency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelDigitalIO5V.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelDigitalIO5V.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelDigitalIO5V} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelDigitalIO5V.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getActiveState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDefaultState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPullUpResistor();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getBouncingLatency();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional IODirection direction = 1;
 * @return {!proto.common.IODirection}
 */
proto.common.StationChannelDigitalIO5V.prototype.getDirection = function() {
  return /** @type {!proto.common.IODirection} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.IODirection} value
 * @return {!proto.common.StationChannelDigitalIO5V} returns this
 */
proto.common.StationChannelDigitalIO5V.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ActiveOrDefaultState active_state = 2;
 * @return {!proto.common.ActiveOrDefaultState}
 */
proto.common.StationChannelDigitalIO5V.prototype.getActiveState = function() {
  return /** @type {!proto.common.ActiveOrDefaultState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.ActiveOrDefaultState} value
 * @return {!proto.common.StationChannelDigitalIO5V} returns this
 */
proto.common.StationChannelDigitalIO5V.prototype.setActiveState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ActiveOrDefaultState default_state = 3;
 * @return {!proto.common.ActiveOrDefaultState}
 */
proto.common.StationChannelDigitalIO5V.prototype.getDefaultState = function() {
  return /** @type {!proto.common.ActiveOrDefaultState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.ActiveOrDefaultState} value
 * @return {!proto.common.StationChannelDigitalIO5V} returns this
 */
proto.common.StationChannelDigitalIO5V.prototype.setDefaultState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional PullupResistor pull_up_resistor = 4;
 * @return {!proto.common.PullupResistor}
 */
proto.common.StationChannelDigitalIO5V.prototype.getPullUpResistor = function() {
  return /** @type {!proto.common.PullupResistor} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.PullupResistor} value
 * @return {!proto.common.StationChannelDigitalIO5V} returns this
 */
proto.common.StationChannelDigitalIO5V.prototype.setPullUpResistor = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional uint32 bouncing_latency = 5;
 * @return {number}
 */
proto.common.StationChannelDigitalIO5V.prototype.getBouncingLatency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelDigitalIO5V} returns this
 */
proto.common.StationChannelDigitalIO5V.prototype.setBouncingLatency = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelDigitalIO24V.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelDigitalIO24V.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelDigitalIO24V} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelDigitalIO24V.toObject = function(includeInstance, msg) {
  var f, obj = {
    direction: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activeState: jspb.Message.getFieldWithDefault(msg, 2, 0),
    defaultState: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bouncingLatency: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelDigitalIO24V}
 */
proto.common.StationChannelDigitalIO24V.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelDigitalIO24V;
  return proto.common.StationChannelDigitalIO24V.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelDigitalIO24V} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelDigitalIO24V}
 */
proto.common.StationChannelDigitalIO24V.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.IODirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 2:
      var value = /** @type {!proto.common.ActiveOrDefaultState} */ (reader.readEnum());
      msg.setActiveState(value);
      break;
    case 3:
      var value = /** @type {!proto.common.ActiveOrDefaultState} */ (reader.readEnum());
      msg.setDefaultState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBouncingLatency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelDigitalIO24V.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelDigitalIO24V.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelDigitalIO24V} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelDigitalIO24V.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getActiveState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDefaultState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getBouncingLatency();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional IODirection direction = 1;
 * @return {!proto.common.IODirection}
 */
proto.common.StationChannelDigitalIO24V.prototype.getDirection = function() {
  return /** @type {!proto.common.IODirection} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.IODirection} value
 * @return {!proto.common.StationChannelDigitalIO24V} returns this
 */
proto.common.StationChannelDigitalIO24V.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ActiveOrDefaultState active_state = 2;
 * @return {!proto.common.ActiveOrDefaultState}
 */
proto.common.StationChannelDigitalIO24V.prototype.getActiveState = function() {
  return /** @type {!proto.common.ActiveOrDefaultState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.ActiveOrDefaultState} value
 * @return {!proto.common.StationChannelDigitalIO24V} returns this
 */
proto.common.StationChannelDigitalIO24V.prototype.setActiveState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ActiveOrDefaultState default_state = 3;
 * @return {!proto.common.ActiveOrDefaultState}
 */
proto.common.StationChannelDigitalIO24V.prototype.getDefaultState = function() {
  return /** @type {!proto.common.ActiveOrDefaultState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.ActiveOrDefaultState} value
 * @return {!proto.common.StationChannelDigitalIO24V} returns this
 */
proto.common.StationChannelDigitalIO24V.prototype.setDefaultState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 bouncing_latency = 4;
 * @return {number}
 */
proto.common.StationChannelDigitalIO24V.prototype.getBouncingLatency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelDigitalIO24V} returns this
 */
proto.common.StationChannelDigitalIO24V.prototype.setBouncingLatency = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelRelay.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelRelay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelRelay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelRelay.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeState: jspb.Message.getFieldWithDefault(msg, 1, 0),
    defaultState: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelRelay}
 */
proto.common.StationChannelRelay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelRelay;
  return proto.common.StationChannelRelay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelRelay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelRelay}
 */
proto.common.StationChannelRelay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.ActiveOrDefaultState} */ (reader.readEnum());
      msg.setActiveState(value);
      break;
    case 2:
      var value = /** @type {!proto.common.ActiveOrDefaultState} */ (reader.readEnum());
      msg.setDefaultState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelRelay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelRelay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelRelay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelRelay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDefaultState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional ActiveOrDefaultState active_state = 1;
 * @return {!proto.common.ActiveOrDefaultState}
 */
proto.common.StationChannelRelay.prototype.getActiveState = function() {
  return /** @type {!proto.common.ActiveOrDefaultState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.ActiveOrDefaultState} value
 * @return {!proto.common.StationChannelRelay} returns this
 */
proto.common.StationChannelRelay.prototype.setActiveState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ActiveOrDefaultState default_state = 2;
 * @return {!proto.common.ActiveOrDefaultState}
 */
proto.common.StationChannelRelay.prototype.getDefaultState = function() {
  return /** @type {!proto.common.ActiveOrDefaultState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.ActiveOrDefaultState} value
 * @return {!proto.common.StationChannelRelay} returns this
 */
proto.common.StationChannelRelay.prototype.setDefaultState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelDigitalIn24V.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelDigitalIn24V.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelDigitalIn24V} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelDigitalIn24V.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeState: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bouncingLatency: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelDigitalIn24V}
 */
proto.common.StationChannelDigitalIn24V.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelDigitalIn24V;
  return proto.common.StationChannelDigitalIn24V.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelDigitalIn24V} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelDigitalIn24V}
 */
proto.common.StationChannelDigitalIn24V.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.ActiveOrDefaultState} */ (reader.readEnum());
      msg.setActiveState(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBouncingLatency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelDigitalIn24V.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelDigitalIn24V.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelDigitalIn24V} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelDigitalIn24V.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBouncingLatency();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional ActiveOrDefaultState active_state = 1;
 * @return {!proto.common.ActiveOrDefaultState}
 */
proto.common.StationChannelDigitalIn24V.prototype.getActiveState = function() {
  return /** @type {!proto.common.ActiveOrDefaultState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.ActiveOrDefaultState} value
 * @return {!proto.common.StationChannelDigitalIn24V} returns this
 */
proto.common.StationChannelDigitalIn24V.prototype.setActiveState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 bouncing_latency = 2;
 * @return {number}
 */
proto.common.StationChannelDigitalIn24V.prototype.getBouncingLatency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelDigitalIn24V} returns this
 */
proto.common.StationChannelDigitalIn24V.prototype.setBouncingLatency = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelAnalogIn10V24MA.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelAnalogIn10V24MA.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelAnalogIn10V24MA} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelAnalogIn10V24MA.toObject = function(includeInstance, msg) {
  var f, obj = {
    range: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelAnalogIn10V24MA}
 */
proto.common.StationChannelAnalogIn10V24MA.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelAnalogIn10V24MA;
  return proto.common.StationChannelAnalogIn10V24MA.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelAnalogIn10V24MA} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelAnalogIn10V24MA}
 */
proto.common.StationChannelAnalogIn10V24MA.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Range} */ (reader.readEnum());
      msg.setRange(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelAnalogIn10V24MA.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelAnalogIn10V24MA.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelAnalogIn10V24MA} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelAnalogIn10V24MA.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional Range range = 1;
 * @return {!proto.common.Range}
 */
proto.common.StationChannelAnalogIn10V24MA.prototype.getRange = function() {
  return /** @type {!proto.common.Range} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Range} value
 * @return {!proto.common.StationChannelAnalogIn10V24MA} returns this
 */
proto.common.StationChannelAnalogIn10V24MA.prototype.setRange = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float offset = 2;
 * @return {number}
 */
proto.common.StationChannelAnalogIn10V24MA.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelAnalogIn10V24MA} returns this
 */
proto.common.StationChannelAnalogIn10V24MA.prototype.setOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float limit = 3;
 * @return {number}
 */
proto.common.StationChannelAnalogIn10V24MA.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelAnalogIn10V24MA} returns this
 */
proto.common.StationChannelAnalogIn10V24MA.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelAnalogOut10V.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelAnalogOut10V.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelAnalogOut10V} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelAnalogOut10V.toObject = function(includeInstance, msg) {
  var f, obj = {
    range: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelAnalogOut10V}
 */
proto.common.StationChannelAnalogOut10V.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelAnalogOut10V;
  return proto.common.StationChannelAnalogOut10V.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelAnalogOut10V} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelAnalogOut10V}
 */
proto.common.StationChannelAnalogOut10V.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Range} */ (reader.readEnum());
      msg.setRange(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelAnalogOut10V.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelAnalogOut10V.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelAnalogOut10V} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelAnalogOut10V.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional Range range = 1;
 * @return {!proto.common.Range}
 */
proto.common.StationChannelAnalogOut10V.prototype.getRange = function() {
  return /** @type {!proto.common.Range} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Range} value
 * @return {!proto.common.StationChannelAnalogOut10V} returns this
 */
proto.common.StationChannelAnalogOut10V.prototype.setRange = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float offset = 2;
 * @return {number}
 */
proto.common.StationChannelAnalogOut10V.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelAnalogOut10V} returns this
 */
proto.common.StationChannelAnalogOut10V.prototype.setOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float limit = 3;
 * @return {number}
 */
proto.common.StationChannelAnalogOut10V.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelAnalogOut10V} returns this
 */
proto.common.StationChannelAnalogOut10V.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelAnalogOut100MA.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelAnalogOut100MA.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelAnalogOut100MA} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelAnalogOut100MA.toObject = function(includeInstance, msg) {
  var f, obj = {
    range: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelAnalogOut100MA}
 */
proto.common.StationChannelAnalogOut100MA.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelAnalogOut100MA;
  return proto.common.StationChannelAnalogOut100MA.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelAnalogOut100MA} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelAnalogOut100MA}
 */
proto.common.StationChannelAnalogOut100MA.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Range} */ (reader.readEnum());
      msg.setRange(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelAnalogOut100MA.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelAnalogOut100MA.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelAnalogOut100MA} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelAnalogOut100MA.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional Range range = 1;
 * @return {!proto.common.Range}
 */
proto.common.StationChannelAnalogOut100MA.prototype.getRange = function() {
  return /** @type {!proto.common.Range} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Range} value
 * @return {!proto.common.StationChannelAnalogOut100MA} returns this
 */
proto.common.StationChannelAnalogOut100MA.prototype.setRange = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float offset = 2;
 * @return {number}
 */
proto.common.StationChannelAnalogOut100MA.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelAnalogOut100MA} returns this
 */
proto.common.StationChannelAnalogOut100MA.prototype.setOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float limit = 3;
 * @return {number}
 */
proto.common.StationChannelAnalogOut100MA.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelAnalogOut100MA} returns this
 */
proto.common.StationChannelAnalogOut100MA.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelAnalogOut10V24MA.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelAnalogOut10V24MA.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelAnalogOut10V24MA} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelAnalogOut10V24MA.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    range: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offset: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelAnalogOut10V24MA}
 */
proto.common.StationChannelAnalogOut10V24MA.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelAnalogOut10V24MA;
  return proto.common.StationChannelAnalogOut10V24MA.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelAnalogOut10V24MA} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelAnalogOut10V24MA}
 */
proto.common.StationChannelAnalogOut10V24MA.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.AiAndServoDrvrType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Range} */ (reader.readEnum());
      msg.setRange(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOffset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelAnalogOut10V24MA.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelAnalogOut10V24MA.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelAnalogOut10V24MA} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelAnalogOut10V24MA.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional AiAndServoDrvrType type = 1;
 * @return {!proto.common.AiAndServoDrvrType}
 */
proto.common.StationChannelAnalogOut10V24MA.prototype.getType = function() {
  return /** @type {!proto.common.AiAndServoDrvrType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.AiAndServoDrvrType} value
 * @return {!proto.common.StationChannelAnalogOut10V24MA} returns this
 */
proto.common.StationChannelAnalogOut10V24MA.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Range range = 2;
 * @return {!proto.common.Range}
 */
proto.common.StationChannelAnalogOut10V24MA.prototype.getRange = function() {
  return /** @type {!proto.common.Range} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Range} value
 * @return {!proto.common.StationChannelAnalogOut10V24MA} returns this
 */
proto.common.StationChannelAnalogOut10V24MA.prototype.setRange = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional float offset = 3;
 * @return {number}
 */
proto.common.StationChannelAnalogOut10V24MA.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelAnalogOut10V24MA} returns this
 */
proto.common.StationChannelAnalogOut10V24MA.prototype.setOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float limit = 4;
 * @return {number}
 */
proto.common.StationChannelAnalogOut10V24MA.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelAnalogOut10V24MA} returns this
 */
proto.common.StationChannelAnalogOut10V24MA.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelDcPwm30V.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelDcPwm30V.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelDcPwm30V} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelDcPwm30V.toObject = function(includeInstance, msg) {
  var f, obj = {
    pwmFreq: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelDcPwm30V}
 */
proto.common.StationChannelDcPwm30V.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelDcPwm30V;
  return proto.common.StationChannelDcPwm30V.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelDcPwm30V} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelDcPwm30V}
 */
proto.common.StationChannelDcPwm30V.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPwmFreq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelDcPwm30V.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelDcPwm30V.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelDcPwm30V} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelDcPwm30V.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPwmFreq();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 pwm_freq = 1;
 * @return {number}
 */
proto.common.StationChannelDcPwm30V.prototype.getPwmFreq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelDcPwm30V} returns this
 */
proto.common.StationChannelDcPwm30V.prototype.setPwmFreq = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelDcPwm50V.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelDcPwm50V.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelDcPwm50V} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelDcPwm50V.toObject = function(includeInstance, msg) {
  var f, obj = {
    pwmFreq: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelDcPwm50V}
 */
proto.common.StationChannelDcPwm50V.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelDcPwm50V;
  return proto.common.StationChannelDcPwm50V.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelDcPwm50V} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelDcPwm50V}
 */
proto.common.StationChannelDcPwm50V.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPwmFreq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelDcPwm50V.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelDcPwm50V.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelDcPwm50V} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelDcPwm50V.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPwmFreq();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 pwm_freq = 1;
 * @return {number}
 */
proto.common.StationChannelDcPwm50V.prototype.getPwmFreq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelDcPwm50V} returns this
 */
proto.common.StationChannelDcPwm50V.prototype.setPwmFreq = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelDcPwm65V.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelDcPwm65V.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelDcPwm65V} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelDcPwm65V.toObject = function(includeInstance, msg) {
  var f, obj = {
    pwmFreq: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelDcPwm65V}
 */
proto.common.StationChannelDcPwm65V.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelDcPwm65V;
  return proto.common.StationChannelDcPwm65V.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelDcPwm65V} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelDcPwm65V}
 */
proto.common.StationChannelDcPwm65V.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPwmFreq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelDcPwm65V.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelDcPwm65V.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelDcPwm65V} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelDcPwm65V.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPwmFreq();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 pwm_freq = 1;
 * @return {number}
 */
proto.common.StationChannelDcPwm65V.prototype.getPwmFreq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelDcPwm65V} returns this
 */
proto.common.StationChannelDcPwm65V.prototype.setPwmFreq = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelStepper.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelStepper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelStepper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelStepper.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelStepper}
 */
proto.common.StationChannelStepper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelStepper;
  return proto.common.StationChannelStepper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelStepper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelStepper}
 */
proto.common.StationChannelStepper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelStepper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelStepper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelStepper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelStepper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.common.StationChannelStepper.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.StationChannelStepper} returns this
 */
proto.common.StationChannelStepper.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.StationChannelStepper} returns this
 */
proto.common.StationChannelStepper.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.StationChannelStepper.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelEncoder.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelEncoder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelEncoder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelEncoder.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelEncoder}
 */
proto.common.StationChannelEncoder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelEncoder;
  return proto.common.StationChannelEncoder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelEncoder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelEncoder}
 */
proto.common.StationChannelEncoder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelEncoder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelEncoder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelEncoder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelEncoder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.common.StationChannelEncoder.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.StationChannelEncoder} returns this
 */
proto.common.StationChannelEncoder.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.StationChannelEncoder} returns this
 */
proto.common.StationChannelEncoder.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.StationChannelEncoder.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelAnalogIn60Ksps.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelAnalogIn60Ksps.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelAnalogIn60Ksps} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelAnalogIn60Ksps.toObject = function(includeInstance, msg) {
  var f, obj = {
    range: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelAnalogIn60Ksps}
 */
proto.common.StationChannelAnalogIn60Ksps.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelAnalogIn60Ksps;
  return proto.common.StationChannelAnalogIn60Ksps.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelAnalogIn60Ksps} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelAnalogIn60Ksps}
 */
proto.common.StationChannelAnalogIn60Ksps.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Range} */ (reader.readEnum());
      msg.setRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelAnalogIn60Ksps.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelAnalogIn60Ksps.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelAnalogIn60Ksps} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelAnalogIn60Ksps.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional Range range = 1;
 * @return {!proto.common.Range}
 */
proto.common.StationChannelAnalogIn60Ksps.prototype.getRange = function() {
  return /** @type {!proto.common.Range} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Range} value
 * @return {!proto.common.StationChannelAnalogIn60Ksps} returns this
 */
proto.common.StationChannelAnalogIn60Ksps.prototype.setRange = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StationChannelLVDT.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StationChannelLVDT.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StationChannelLVDT} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelLVDT.toObject = function(includeInstance, msg) {
  var f, obj = {
    freq: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    amp: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    spiAddress: jspb.Message.getFieldWithDefault(msg, 4, 0),
    shiftregAddress: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StationChannelLVDT}
 */
proto.common.StationChannelLVDT.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StationChannelLVDT;
  return proto.common.StationChannelLVDT.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StationChannelLVDT} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StationChannelLVDT}
 */
proto.common.StationChannelLVDT.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFreq(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmp(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpiAddress(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShiftregAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StationChannelLVDT.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StationChannelLVDT.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StationChannelLVDT} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StationChannelLVDT.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFreq();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getAmp();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getSpiAddress();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getShiftregAddress();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional float freq = 2;
 * @return {number}
 */
proto.common.StationChannelLVDT.prototype.getFreq = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelLVDT} returns this
 */
proto.common.StationChannelLVDT.prototype.setFreq = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float amp = 3;
 * @return {number}
 */
proto.common.StationChannelLVDT.prototype.getAmp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelLVDT} returns this
 */
proto.common.StationChannelLVDT.prototype.setAmp = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 spi_address = 4;
 * @return {number}
 */
proto.common.StationChannelLVDT.prototype.getSpiAddress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelLVDT} returns this
 */
proto.common.StationChannelLVDT.prototype.setSpiAddress = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 shiftreg_address = 5;
 * @return {number}
 */
proto.common.StationChannelLVDT.prototype.getShiftregAddress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.StationChannelLVDT} returns this
 */
proto.common.StationChannelLVDT.prototype.setShiftregAddress = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.WFProps.oneofGroups_ = [[50,51,52,53,54,55,56,57,58,59]];

/**
 * @enum {number}
 */
proto.common.WFProps.WaveformCase = {
  WAVEFORM_NOT_SET: 0,
  WF_RAMP: 50,
  WF_DWELL: 51,
  WF_CONSTANT: 52,
  WF_SINE: 53,
  WF_DUO_RAMP: 54,
  WF_HAVER_SINE: 55,
  WF_DUO_HAVER_SINE: 56,
  WF_TAPERED_SINE: 57,
  WF_SWEEP_SINE: 58,
  WF_SQUAREWAVE: 59
};

/**
 * @return {proto.common.WFProps.WaveformCase}
 */
proto.common.WFProps.prototype.getWaveformCase = function() {
  return /** @type {proto.common.WFProps.WaveformCase} */(jspb.Message.computeOneofCase(this, proto.common.WFProps.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.WFProps.prototype.toObject = function(opt_includeInstance) {
  return proto.common.WFProps.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.WFProps} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WFProps.toObject = function(includeInstance, msg) {
  var f, obj = {
    wfRamp: (f = msg.getWfRamp()) && proto.common.WF_Ramp.toObject(includeInstance, f),
    wfDwell: (f = msg.getWfDwell()) && proto.common.WF_Dwell.toObject(includeInstance, f),
    wfConstant: (f = msg.getWfConstant()) && proto.common.WF_Constant.toObject(includeInstance, f),
    wfSine: (f = msg.getWfSine()) && proto.common.WF_Sine.toObject(includeInstance, f),
    wfDuoRamp: (f = msg.getWfDuoRamp()) && proto.common.WF_DuoRamp.toObject(includeInstance, f),
    wfHaverSine: (f = msg.getWfHaverSine()) && proto.common.WF_HaverSine.toObject(includeInstance, f),
    wfDuoHaverSine: (f = msg.getWfDuoHaverSine()) && proto.common.WF_DuoHaverSine.toObject(includeInstance, f),
    wfTaperedSine: (f = msg.getWfTaperedSine()) && proto.common.WF_TaperedSine.toObject(includeInstance, f),
    wfSweepSine: (f = msg.getWfSweepSine()) && proto.common.WF_SweepSine.toObject(includeInstance, f),
    wfSquarewave: (f = msg.getWfSquarewave()) && proto.common.WF_Squarewave.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.WFProps}
 */
proto.common.WFProps.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.WFProps;
  return proto.common.WFProps.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.WFProps} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.WFProps}
 */
proto.common.WFProps.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 50:
      var value = new proto.common.WF_Ramp;
      reader.readMessage(value,proto.common.WF_Ramp.deserializeBinaryFromReader);
      msg.setWfRamp(value);
      break;
    case 51:
      var value = new proto.common.WF_Dwell;
      reader.readMessage(value,proto.common.WF_Dwell.deserializeBinaryFromReader);
      msg.setWfDwell(value);
      break;
    case 52:
      var value = new proto.common.WF_Constant;
      reader.readMessage(value,proto.common.WF_Constant.deserializeBinaryFromReader);
      msg.setWfConstant(value);
      break;
    case 53:
      var value = new proto.common.WF_Sine;
      reader.readMessage(value,proto.common.WF_Sine.deserializeBinaryFromReader);
      msg.setWfSine(value);
      break;
    case 54:
      var value = new proto.common.WF_DuoRamp;
      reader.readMessage(value,proto.common.WF_DuoRamp.deserializeBinaryFromReader);
      msg.setWfDuoRamp(value);
      break;
    case 55:
      var value = new proto.common.WF_HaverSine;
      reader.readMessage(value,proto.common.WF_HaverSine.deserializeBinaryFromReader);
      msg.setWfHaverSine(value);
      break;
    case 56:
      var value = new proto.common.WF_DuoHaverSine;
      reader.readMessage(value,proto.common.WF_DuoHaverSine.deserializeBinaryFromReader);
      msg.setWfDuoHaverSine(value);
      break;
    case 57:
      var value = new proto.common.WF_TaperedSine;
      reader.readMessage(value,proto.common.WF_TaperedSine.deserializeBinaryFromReader);
      msg.setWfTaperedSine(value);
      break;
    case 58:
      var value = new proto.common.WF_SweepSine;
      reader.readMessage(value,proto.common.WF_SweepSine.deserializeBinaryFromReader);
      msg.setWfSweepSine(value);
      break;
    case 59:
      var value = new proto.common.WF_Squarewave;
      reader.readMessage(value,proto.common.WF_Squarewave.deserializeBinaryFromReader);
      msg.setWfSquarewave(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.WFProps.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.WFProps.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.WFProps} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WFProps.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWfRamp();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.common.WF_Ramp.serializeBinaryToWriter
    );
  }
  f = message.getWfDwell();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.common.WF_Dwell.serializeBinaryToWriter
    );
  }
  f = message.getWfConstant();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.common.WF_Constant.serializeBinaryToWriter
    );
  }
  f = message.getWfSine();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.common.WF_Sine.serializeBinaryToWriter
    );
  }
  f = message.getWfDuoRamp();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.common.WF_DuoRamp.serializeBinaryToWriter
    );
  }
  f = message.getWfHaverSine();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      proto.common.WF_HaverSine.serializeBinaryToWriter
    );
  }
  f = message.getWfDuoHaverSine();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      proto.common.WF_DuoHaverSine.serializeBinaryToWriter
    );
  }
  f = message.getWfTaperedSine();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      proto.common.WF_TaperedSine.serializeBinaryToWriter
    );
  }
  f = message.getWfSweepSine();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      proto.common.WF_SweepSine.serializeBinaryToWriter
    );
  }
  f = message.getWfSquarewave();
  if (f != null) {
    writer.writeMessage(
      59,
      f,
      proto.common.WF_Squarewave.serializeBinaryToWriter
    );
  }
};


/**
 * optional WF_Ramp wf_ramp = 50;
 * @return {?proto.common.WF_Ramp}
 */
proto.common.WFProps.prototype.getWfRamp = function() {
  return /** @type{?proto.common.WF_Ramp} */ (
    jspb.Message.getWrapperField(this, proto.common.WF_Ramp, 50));
};


/**
 * @param {?proto.common.WF_Ramp|undefined} value
 * @return {!proto.common.WFProps} returns this
*/
proto.common.WFProps.prototype.setWfRamp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 50, proto.common.WFProps.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WFProps} returns this
 */
proto.common.WFProps.prototype.clearWfRamp = function() {
  return this.setWfRamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WFProps.prototype.hasWfRamp = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional WF_Dwell wf_dwell = 51;
 * @return {?proto.common.WF_Dwell}
 */
proto.common.WFProps.prototype.getWfDwell = function() {
  return /** @type{?proto.common.WF_Dwell} */ (
    jspb.Message.getWrapperField(this, proto.common.WF_Dwell, 51));
};


/**
 * @param {?proto.common.WF_Dwell|undefined} value
 * @return {!proto.common.WFProps} returns this
*/
proto.common.WFProps.prototype.setWfDwell = function(value) {
  return jspb.Message.setOneofWrapperField(this, 51, proto.common.WFProps.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WFProps} returns this
 */
proto.common.WFProps.prototype.clearWfDwell = function() {
  return this.setWfDwell(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WFProps.prototype.hasWfDwell = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional WF_Constant wf_constant = 52;
 * @return {?proto.common.WF_Constant}
 */
proto.common.WFProps.prototype.getWfConstant = function() {
  return /** @type{?proto.common.WF_Constant} */ (
    jspb.Message.getWrapperField(this, proto.common.WF_Constant, 52));
};


/**
 * @param {?proto.common.WF_Constant|undefined} value
 * @return {!proto.common.WFProps} returns this
*/
proto.common.WFProps.prototype.setWfConstant = function(value) {
  return jspb.Message.setOneofWrapperField(this, 52, proto.common.WFProps.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WFProps} returns this
 */
proto.common.WFProps.prototype.clearWfConstant = function() {
  return this.setWfConstant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WFProps.prototype.hasWfConstant = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional WF_Sine wf_sine = 53;
 * @return {?proto.common.WF_Sine}
 */
proto.common.WFProps.prototype.getWfSine = function() {
  return /** @type{?proto.common.WF_Sine} */ (
    jspb.Message.getWrapperField(this, proto.common.WF_Sine, 53));
};


/**
 * @param {?proto.common.WF_Sine|undefined} value
 * @return {!proto.common.WFProps} returns this
*/
proto.common.WFProps.prototype.setWfSine = function(value) {
  return jspb.Message.setOneofWrapperField(this, 53, proto.common.WFProps.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WFProps} returns this
 */
proto.common.WFProps.prototype.clearWfSine = function() {
  return this.setWfSine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WFProps.prototype.hasWfSine = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional WF_DuoRamp wf_duo_ramp = 54;
 * @return {?proto.common.WF_DuoRamp}
 */
proto.common.WFProps.prototype.getWfDuoRamp = function() {
  return /** @type{?proto.common.WF_DuoRamp} */ (
    jspb.Message.getWrapperField(this, proto.common.WF_DuoRamp, 54));
};


/**
 * @param {?proto.common.WF_DuoRamp|undefined} value
 * @return {!proto.common.WFProps} returns this
*/
proto.common.WFProps.prototype.setWfDuoRamp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 54, proto.common.WFProps.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WFProps} returns this
 */
proto.common.WFProps.prototype.clearWfDuoRamp = function() {
  return this.setWfDuoRamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WFProps.prototype.hasWfDuoRamp = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional WF_HaverSine wf_haver_sine = 55;
 * @return {?proto.common.WF_HaverSine}
 */
proto.common.WFProps.prototype.getWfHaverSine = function() {
  return /** @type{?proto.common.WF_HaverSine} */ (
    jspb.Message.getWrapperField(this, proto.common.WF_HaverSine, 55));
};


/**
 * @param {?proto.common.WF_HaverSine|undefined} value
 * @return {!proto.common.WFProps} returns this
*/
proto.common.WFProps.prototype.setWfHaverSine = function(value) {
  return jspb.Message.setOneofWrapperField(this, 55, proto.common.WFProps.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WFProps} returns this
 */
proto.common.WFProps.prototype.clearWfHaverSine = function() {
  return this.setWfHaverSine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WFProps.prototype.hasWfHaverSine = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional WF_DuoHaverSine wf_duo_haver_sine = 56;
 * @return {?proto.common.WF_DuoHaverSine}
 */
proto.common.WFProps.prototype.getWfDuoHaverSine = function() {
  return /** @type{?proto.common.WF_DuoHaverSine} */ (
    jspb.Message.getWrapperField(this, proto.common.WF_DuoHaverSine, 56));
};


/**
 * @param {?proto.common.WF_DuoHaverSine|undefined} value
 * @return {!proto.common.WFProps} returns this
*/
proto.common.WFProps.prototype.setWfDuoHaverSine = function(value) {
  return jspb.Message.setOneofWrapperField(this, 56, proto.common.WFProps.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WFProps} returns this
 */
proto.common.WFProps.prototype.clearWfDuoHaverSine = function() {
  return this.setWfDuoHaverSine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WFProps.prototype.hasWfDuoHaverSine = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional WF_TaperedSine wf_tapered_sine = 57;
 * @return {?proto.common.WF_TaperedSine}
 */
proto.common.WFProps.prototype.getWfTaperedSine = function() {
  return /** @type{?proto.common.WF_TaperedSine} */ (
    jspb.Message.getWrapperField(this, proto.common.WF_TaperedSine, 57));
};


/**
 * @param {?proto.common.WF_TaperedSine|undefined} value
 * @return {!proto.common.WFProps} returns this
*/
proto.common.WFProps.prototype.setWfTaperedSine = function(value) {
  return jspb.Message.setOneofWrapperField(this, 57, proto.common.WFProps.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WFProps} returns this
 */
proto.common.WFProps.prototype.clearWfTaperedSine = function() {
  return this.setWfTaperedSine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WFProps.prototype.hasWfTaperedSine = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional WF_SweepSine wf_sweep_sine = 58;
 * @return {?proto.common.WF_SweepSine}
 */
proto.common.WFProps.prototype.getWfSweepSine = function() {
  return /** @type{?proto.common.WF_SweepSine} */ (
    jspb.Message.getWrapperField(this, proto.common.WF_SweepSine, 58));
};


/**
 * @param {?proto.common.WF_SweepSine|undefined} value
 * @return {!proto.common.WFProps} returns this
*/
proto.common.WFProps.prototype.setWfSweepSine = function(value) {
  return jspb.Message.setOneofWrapperField(this, 58, proto.common.WFProps.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WFProps} returns this
 */
proto.common.WFProps.prototype.clearWfSweepSine = function() {
  return this.setWfSweepSine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WFProps.prototype.hasWfSweepSine = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional WF_Squarewave wf_squarewave = 59;
 * @return {?proto.common.WF_Squarewave}
 */
proto.common.WFProps.prototype.getWfSquarewave = function() {
  return /** @type{?proto.common.WF_Squarewave} */ (
    jspb.Message.getWrapperField(this, proto.common.WF_Squarewave, 59));
};


/**
 * @param {?proto.common.WF_Squarewave|undefined} value
 * @return {!proto.common.WFProps} returns this
*/
proto.common.WFProps.prototype.setWfSquarewave = function(value) {
  return jspb.Message.setOneofWrapperField(this, 59, proto.common.WFProps.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WFProps} returns this
 */
proto.common.WFProps.prototype.clearWfSquarewave = function() {
  return this.setWfSquarewave(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WFProps.prototype.hasWfSquarewave = function() {
  return jspb.Message.getField(this, 59) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.WF_Ramp.prototype.toObject = function(opt_includeInstance) {
  return proto.common.WF_Ramp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.WF_Ramp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_Ramp.toObject = function(includeInstance, msg) {
  var f, obj = {
    defMode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dir: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rate: (f = msg.getRate()) && proto.common.ValOrId.toObject(includeInstance, f),
    endChannelType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    end: (f = msg.getEnd()) && proto.common.ValOrId.toObject(includeInstance, f),
    measId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    invEndCond: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    directRelationship: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.WF_Ramp}
 */
proto.common.WF_Ramp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.WF_Ramp;
  return proto.common.WF_Ramp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.WF_Ramp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.WF_Ramp}
 */
proto.common.WF_Ramp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.DefinitionMode} */ (reader.readEnum());
      msg.setDefMode(value);
      break;
    case 2:
      var value = /** @type {!proto.common.RampDirection} */ (reader.readEnum());
      msg.setDir(value);
      break;
    case 3:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setRate(value);
      break;
    case 6:
      var value = /** @type {!proto.common.EndChannelType} */ (reader.readEnum());
      msg.setEndChannelType(value);
      break;
    case 7:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasId(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInvEndCond(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDirectRelationship(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.WF_Ramp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.WF_Ramp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.WF_Ramp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_Ramp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDir();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getEndChannelType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInvEndCond();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional DefinitionMode def_mode = 1;
 * @return {!proto.common.DefinitionMode}
 */
proto.common.WF_Ramp.prototype.getDefMode = function() {
  return /** @type {!proto.common.DefinitionMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.DefinitionMode} value
 * @return {!proto.common.WF_Ramp} returns this
 */
proto.common.WF_Ramp.prototype.setDefMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional RampDirection dir = 2;
 * @return {!proto.common.RampDirection}
 */
proto.common.WF_Ramp.prototype.getDir = function() {
  return /** @type {!proto.common.RampDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.RampDirection} value
 * @return {!proto.common.WF_Ramp} returns this
 */
proto.common.WF_Ramp.prototype.setDir = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ValOrId rate = 3;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_Ramp.prototype.getRate = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 3));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_Ramp} returns this
*/
proto.common.WF_Ramp.prototype.setRate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_Ramp} returns this
 */
proto.common.WF_Ramp.prototype.clearRate = function() {
  return this.setRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_Ramp.prototype.hasRate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional EndChannelType end_channel_type = 6;
 * @return {!proto.common.EndChannelType}
 */
proto.common.WF_Ramp.prototype.getEndChannelType = function() {
  return /** @type {!proto.common.EndChannelType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.EndChannelType} value
 * @return {!proto.common.WF_Ramp} returns this
 */
proto.common.WF_Ramp.prototype.setEndChannelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ValOrId end = 7;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_Ramp.prototype.getEnd = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 7));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_Ramp} returns this
*/
proto.common.WF_Ramp.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_Ramp} returns this
 */
proto.common.WF_Ramp.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_Ramp.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string meas_id = 10;
 * @return {string}
 */
proto.common.WF_Ramp.prototype.getMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.WF_Ramp} returns this
 */
proto.common.WF_Ramp.prototype.setMeasId = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.WF_Ramp} returns this
 */
proto.common.WF_Ramp.prototype.clearMeasId = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_Ramp.prototype.hasMeasId = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool inv_end_cond = 11;
 * @return {boolean}
 */
proto.common.WF_Ramp.prototype.getInvEndCond = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.WF_Ramp} returns this
 */
proto.common.WF_Ramp.prototype.setInvEndCond = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool direct_relationship = 13;
 * @return {boolean}
 */
proto.common.WF_Ramp.prototype.getDirectRelationship = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.WF_Ramp} returns this
 */
proto.common.WF_Ramp.prototype.setDirectRelationship = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.WF_Ramp} returns this
 */
proto.common.WF_Ramp.prototype.clearDirectRelationship = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_Ramp.prototype.hasDirectRelationship = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.WF_Dwell.prototype.toObject = function(opt_includeInstance) {
  return proto.common.WF_Dwell.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.WF_Dwell} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_Dwell.toObject = function(includeInstance, msg) {
  var f, obj = {
    duration: (f = msg.getDuration()) && proto.common.ValOrId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.WF_Dwell}
 */
proto.common.WF_Dwell.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.WF_Dwell;
  return proto.common.WF_Dwell.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.WF_Dwell} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.WF_Dwell}
 */
proto.common.WF_Dwell.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.WF_Dwell.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.WF_Dwell.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.WF_Dwell} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_Dwell.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDuration();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
};


/**
 * optional ValOrId duration = 1;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_Dwell.prototype.getDuration = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 1));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_Dwell} returns this
*/
proto.common.WF_Dwell.prototype.setDuration = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_Dwell} returns this
 */
proto.common.WF_Dwell.prototype.clearDuration = function() {
  return this.setDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_Dwell.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.WF_Constant.prototype.toObject = function(opt_includeInstance) {
  return proto.common.WF_Constant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.WF_Constant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_Constant.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && proto.common.ValOrId.toObject(includeInstance, f),
    duration: (f = msg.getDuration()) && proto.common.ValOrId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.WF_Constant}
 */
proto.common.WF_Constant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.WF_Constant;
  return proto.common.WF_Constant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.WF_Constant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.WF_Constant}
 */
proto.common.WF_Constant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.WF_Constant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.WF_Constant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.WF_Constant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_Constant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
};


/**
 * optional ValOrId value = 1;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_Constant.prototype.getValue = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 1));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_Constant} returns this
*/
proto.common.WF_Constant.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_Constant} returns this
 */
proto.common.WF_Constant.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_Constant.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ValOrId duration = 2;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_Constant.prototype.getDuration = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 2));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_Constant} returns this
*/
proto.common.WF_Constant.prototype.setDuration = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_Constant} returns this
 */
proto.common.WF_Constant.prototype.clearDuration = function() {
  return this.setDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_Constant.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.WF_Sine.prototype.toObject = function(opt_includeInstance) {
  return proto.common.WF_Sine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.WF_Sine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_Sine.toObject = function(includeInstance, msg) {
  var f, obj = {
    defMode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amplitude: (f = msg.getAmplitude()) && proto.common.ValOrId.toObject(includeInstance, f),
    mean: (f = msg.getMean()) && proto.common.ValOrId.toObject(includeInstance, f),
    freqVal: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    startPhase: jspb.Message.getFieldWithDefault(msg, 9, 0),
    endPhase: jspb.Message.getFieldWithDefault(msg, 10, 0),
    cycles: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.WF_Sine}
 */
proto.common.WF_Sine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.WF_Sine;
  return proto.common.WF_Sine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.WF_Sine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.WF_Sine}
 */
proto.common.WF_Sine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.DefinitionMode} */ (reader.readEnum());
      msg.setDefMode(value);
      break;
    case 2:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setAmplitude(value);
      break;
    case 3:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setMean(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFreqVal(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartPhase(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndPhase(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCycles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.WF_Sine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.WF_Sine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.WF_Sine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_Sine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAmplitude();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getMean();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getFreqVal();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getStartPhase();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getEndPhase();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getCycles();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
};


/**
 * optional DefinitionMode def_mode = 1;
 * @return {!proto.common.DefinitionMode}
 */
proto.common.WF_Sine.prototype.getDefMode = function() {
  return /** @type {!proto.common.DefinitionMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.DefinitionMode} value
 * @return {!proto.common.WF_Sine} returns this
 */
proto.common.WF_Sine.prototype.setDefMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ValOrId amplitude = 2;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_Sine.prototype.getAmplitude = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 2));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_Sine} returns this
*/
proto.common.WF_Sine.prototype.setAmplitude = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_Sine} returns this
 */
proto.common.WF_Sine.prototype.clearAmplitude = function() {
  return this.setAmplitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_Sine.prototype.hasAmplitude = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ValOrId mean = 3;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_Sine.prototype.getMean = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 3));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_Sine} returns this
*/
proto.common.WF_Sine.prototype.setMean = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_Sine} returns this
 */
proto.common.WF_Sine.prototype.clearMean = function() {
  return this.setMean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_Sine.prototype.hasMean = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float freq_val = 8;
 * @return {number}
 */
proto.common.WF_Sine.prototype.getFreqVal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_Sine} returns this
 */
proto.common.WF_Sine.prototype.setFreqVal = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional uint32 start_phase = 9;
 * @return {number}
 */
proto.common.WF_Sine.prototype.getStartPhase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_Sine} returns this
 */
proto.common.WF_Sine.prototype.setStartPhase = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 end_phase = 10;
 * @return {number}
 */
proto.common.WF_Sine.prototype.getEndPhase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_Sine} returns this
 */
proto.common.WF_Sine.prototype.setEndPhase = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 cycles = 11;
 * @return {number}
 */
proto.common.WF_Sine.prototype.getCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_Sine} returns this
 */
proto.common.WF_Sine.prototype.setCycles = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.WF_DuoRamp.prototype.toObject = function(opt_includeInstance) {
  return proto.common.WF_DuoRamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.WF_DuoRamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_DuoRamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    defMode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dir: jspb.Message.getFieldWithDefault(msg, 2, 0),
    upRate: (f = msg.getUpRate()) && proto.common.ValOrId.toObject(includeInstance, f),
    downRate: (f = msg.getDownRate()) && proto.common.ValOrId.toObject(includeInstance, f),
    channelType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    measId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    upperEnd: (f = msg.getUpperEnd()) && proto.common.ValOrId.toObject(includeInstance, f),
    lowerEnd: (f = msg.getLowerEnd()) && proto.common.ValOrId.toObject(includeInstance, f),
    invEndCond: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    cycles: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.WF_DuoRamp}
 */
proto.common.WF_DuoRamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.WF_DuoRamp;
  return proto.common.WF_DuoRamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.WF_DuoRamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.WF_DuoRamp}
 */
proto.common.WF_DuoRamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.DefinitionMode} */ (reader.readEnum());
      msg.setDefMode(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Direction} */ (reader.readEnum());
      msg.setDir(value);
      break;
    case 3:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setUpRate(value);
      break;
    case 4:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setDownRate(value);
      break;
    case 9:
      var value = /** @type {!proto.common.EndChannelType} */ (reader.readEnum());
      msg.setChannelType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasId(value);
      break;
    case 11:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setUpperEnd(value);
      break;
    case 12:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setLowerEnd(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInvEndCond(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCycles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.WF_DuoRamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.WF_DuoRamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.WF_DuoRamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_DuoRamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDir();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUpRate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getDownRate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getChannelType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUpperEnd();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getLowerEnd();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getInvEndCond();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getCycles();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
};


/**
 * optional DefinitionMode def_mode = 1;
 * @return {!proto.common.DefinitionMode}
 */
proto.common.WF_DuoRamp.prototype.getDefMode = function() {
  return /** @type {!proto.common.DefinitionMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.DefinitionMode} value
 * @return {!proto.common.WF_DuoRamp} returns this
 */
proto.common.WF_DuoRamp.prototype.setDefMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Direction dir = 2;
 * @return {!proto.common.Direction}
 */
proto.common.WF_DuoRamp.prototype.getDir = function() {
  return /** @type {!proto.common.Direction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Direction} value
 * @return {!proto.common.WF_DuoRamp} returns this
 */
proto.common.WF_DuoRamp.prototype.setDir = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ValOrId up_rate = 3;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_DuoRamp.prototype.getUpRate = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 3));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_DuoRamp} returns this
*/
proto.common.WF_DuoRamp.prototype.setUpRate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_DuoRamp} returns this
 */
proto.common.WF_DuoRamp.prototype.clearUpRate = function() {
  return this.setUpRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_DuoRamp.prototype.hasUpRate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ValOrId down_rate = 4;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_DuoRamp.prototype.getDownRate = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 4));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_DuoRamp} returns this
*/
proto.common.WF_DuoRamp.prototype.setDownRate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_DuoRamp} returns this
 */
proto.common.WF_DuoRamp.prototype.clearDownRate = function() {
  return this.setDownRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_DuoRamp.prototype.hasDownRate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EndChannelType channel_type = 9;
 * @return {!proto.common.EndChannelType}
 */
proto.common.WF_DuoRamp.prototype.getChannelType = function() {
  return /** @type {!proto.common.EndChannelType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.common.EndChannelType} value
 * @return {!proto.common.WF_DuoRamp} returns this
 */
proto.common.WF_DuoRamp.prototype.setChannelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string meas_id = 10;
 * @return {string}
 */
proto.common.WF_DuoRamp.prototype.getMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.WF_DuoRamp} returns this
 */
proto.common.WF_DuoRamp.prototype.setMeasId = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.WF_DuoRamp} returns this
 */
proto.common.WF_DuoRamp.prototype.clearMeasId = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_DuoRamp.prototype.hasMeasId = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ValOrId upper_end = 11;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_DuoRamp.prototype.getUpperEnd = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 11));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_DuoRamp} returns this
*/
proto.common.WF_DuoRamp.prototype.setUpperEnd = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_DuoRamp} returns this
 */
proto.common.WF_DuoRamp.prototype.clearUpperEnd = function() {
  return this.setUpperEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_DuoRamp.prototype.hasUpperEnd = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ValOrId lower_end = 12;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_DuoRamp.prototype.getLowerEnd = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 12));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_DuoRamp} returns this
*/
proto.common.WF_DuoRamp.prototype.setLowerEnd = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_DuoRamp} returns this
 */
proto.common.WF_DuoRamp.prototype.clearLowerEnd = function() {
  return this.setLowerEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_DuoRamp.prototype.hasLowerEnd = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool inv_end_cond = 19;
 * @return {boolean}
 */
proto.common.WF_DuoRamp.prototype.getInvEndCond = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.WF_DuoRamp} returns this
 */
proto.common.WF_DuoRamp.prototype.setInvEndCond = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional uint32 cycles = 20;
 * @return {number}
 */
proto.common.WF_DuoRamp.prototype.getCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_DuoRamp} returns this
 */
proto.common.WF_DuoRamp.prototype.setCycles = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.WF_HaverSine.prototype.toObject = function(opt_includeInstance) {
  return proto.common.WF_HaverSine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.WF_HaverSine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_HaverSine.toObject = function(includeInstance, msg) {
  var f, obj = {
    defMode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dir: jspb.Message.getFieldWithDefault(msg, 2, 0),
    duration: (f = msg.getDuration()) && proto.common.ValOrId.toObject(includeInstance, f),
    amplitude: (f = msg.getAmplitude()) && proto.common.ValOrId.toObject(includeInstance, f),
    mean: (f = msg.getMean()) && proto.common.ValOrId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.WF_HaverSine}
 */
proto.common.WF_HaverSine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.WF_HaverSine;
  return proto.common.WF_HaverSine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.WF_HaverSine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.WF_HaverSine}
 */
proto.common.WF_HaverSine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.DefinitionMode} */ (reader.readEnum());
      msg.setDefMode(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Direction} */ (reader.readEnum());
      msg.setDir(value);
      break;
    case 3:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setDuration(value);
      break;
    case 4:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setAmplitude(value);
      break;
    case 5:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setMean(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.WF_HaverSine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.WF_HaverSine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.WF_HaverSine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_HaverSine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDir();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getAmplitude();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getMean();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
};


/**
 * optional DefinitionMode def_mode = 1;
 * @return {!proto.common.DefinitionMode}
 */
proto.common.WF_HaverSine.prototype.getDefMode = function() {
  return /** @type {!proto.common.DefinitionMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.DefinitionMode} value
 * @return {!proto.common.WF_HaverSine} returns this
 */
proto.common.WF_HaverSine.prototype.setDefMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Direction dir = 2;
 * @return {!proto.common.Direction}
 */
proto.common.WF_HaverSine.prototype.getDir = function() {
  return /** @type {!proto.common.Direction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Direction} value
 * @return {!proto.common.WF_HaverSine} returns this
 */
proto.common.WF_HaverSine.prototype.setDir = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ValOrId duration = 3;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_HaverSine.prototype.getDuration = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 3));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_HaverSine} returns this
*/
proto.common.WF_HaverSine.prototype.setDuration = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_HaverSine} returns this
 */
proto.common.WF_HaverSine.prototype.clearDuration = function() {
  return this.setDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_HaverSine.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ValOrId amplitude = 4;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_HaverSine.prototype.getAmplitude = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 4));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_HaverSine} returns this
*/
proto.common.WF_HaverSine.prototype.setAmplitude = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_HaverSine} returns this
 */
proto.common.WF_HaverSine.prototype.clearAmplitude = function() {
  return this.setAmplitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_HaverSine.prototype.hasAmplitude = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ValOrId mean = 5;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_HaverSine.prototype.getMean = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 5));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_HaverSine} returns this
*/
proto.common.WF_HaverSine.prototype.setMean = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_HaverSine} returns this
 */
proto.common.WF_HaverSine.prototype.clearMean = function() {
  return this.setMean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_HaverSine.prototype.hasMean = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.WF_DuoHaverSine.prototype.toObject = function(opt_includeInstance) {
  return proto.common.WF_DuoHaverSine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.WF_DuoHaverSine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_DuoHaverSine.toObject = function(includeInstance, msg) {
  var f, obj = {
    defMode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dir: jspb.Message.getFieldWithDefault(msg, 2, 0),
    durationUp: (f = msg.getDurationUp()) && proto.common.ValOrId.toObject(includeInstance, f),
    durationDown: (f = msg.getDurationDown()) && proto.common.ValOrId.toObject(includeInstance, f),
    amplitude: (f = msg.getAmplitude()) && proto.common.ValOrId.toObject(includeInstance, f),
    mean: (f = msg.getMean()) && proto.common.ValOrId.toObject(includeInstance, f),
    cycles: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.WF_DuoHaverSine}
 */
proto.common.WF_DuoHaverSine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.WF_DuoHaverSine;
  return proto.common.WF_DuoHaverSine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.WF_DuoHaverSine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.WF_DuoHaverSine}
 */
proto.common.WF_DuoHaverSine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.DefinitionMode} */ (reader.readEnum());
      msg.setDefMode(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Direction} */ (reader.readEnum());
      msg.setDir(value);
      break;
    case 3:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setDurationUp(value);
      break;
    case 4:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setDurationDown(value);
      break;
    case 5:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setAmplitude(value);
      break;
    case 6:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setMean(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCycles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.WF_DuoHaverSine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.WF_DuoHaverSine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.WF_DuoHaverSine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_DuoHaverSine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDir();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDurationUp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getDurationDown();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getAmplitude();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getMean();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getCycles();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
};


/**
 * optional DefinitionMode def_mode = 1;
 * @return {!proto.common.DefinitionMode}
 */
proto.common.WF_DuoHaverSine.prototype.getDefMode = function() {
  return /** @type {!proto.common.DefinitionMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.DefinitionMode} value
 * @return {!proto.common.WF_DuoHaverSine} returns this
 */
proto.common.WF_DuoHaverSine.prototype.setDefMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Direction dir = 2;
 * @return {!proto.common.Direction}
 */
proto.common.WF_DuoHaverSine.prototype.getDir = function() {
  return /** @type {!proto.common.Direction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Direction} value
 * @return {!proto.common.WF_DuoHaverSine} returns this
 */
proto.common.WF_DuoHaverSine.prototype.setDir = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ValOrId duration_up = 3;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_DuoHaverSine.prototype.getDurationUp = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 3));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_DuoHaverSine} returns this
*/
proto.common.WF_DuoHaverSine.prototype.setDurationUp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_DuoHaverSine} returns this
 */
proto.common.WF_DuoHaverSine.prototype.clearDurationUp = function() {
  return this.setDurationUp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_DuoHaverSine.prototype.hasDurationUp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ValOrId duration_down = 4;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_DuoHaverSine.prototype.getDurationDown = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 4));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_DuoHaverSine} returns this
*/
proto.common.WF_DuoHaverSine.prototype.setDurationDown = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_DuoHaverSine} returns this
 */
proto.common.WF_DuoHaverSine.prototype.clearDurationDown = function() {
  return this.setDurationDown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_DuoHaverSine.prototype.hasDurationDown = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ValOrId amplitude = 5;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_DuoHaverSine.prototype.getAmplitude = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 5));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_DuoHaverSine} returns this
*/
proto.common.WF_DuoHaverSine.prototype.setAmplitude = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_DuoHaverSine} returns this
 */
proto.common.WF_DuoHaverSine.prototype.clearAmplitude = function() {
  return this.setAmplitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_DuoHaverSine.prototype.hasAmplitude = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ValOrId mean = 6;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_DuoHaverSine.prototype.getMean = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 6));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_DuoHaverSine} returns this
*/
proto.common.WF_DuoHaverSine.prototype.setMean = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_DuoHaverSine} returns this
 */
proto.common.WF_DuoHaverSine.prototype.clearMean = function() {
  return this.setMean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_DuoHaverSine.prototype.hasMean = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 cycles = 15;
 * @return {number}
 */
proto.common.WF_DuoHaverSine.prototype.getCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_DuoHaverSine} returns this
 */
proto.common.WF_DuoHaverSine.prototype.setCycles = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.WF_TaperedSine.prototype.toObject = function(opt_includeInstance) {
  return proto.common.WF_TaperedSine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.WF_TaperedSine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_TaperedSine.toObject = function(includeInstance, msg) {
  var f, obj = {
    defMode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amplitude: (f = msg.getAmplitude()) && proto.common.ValOrId.toObject(includeInstance, f),
    mean: (f = msg.getMean()) && proto.common.ValOrId.toObject(includeInstance, f),
    freqVal: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    endFreqVal: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    cycles: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.WF_TaperedSine}
 */
proto.common.WF_TaperedSine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.WF_TaperedSine;
  return proto.common.WF_TaperedSine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.WF_TaperedSine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.WF_TaperedSine}
 */
proto.common.WF_TaperedSine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.DefinitionMode} */ (reader.readEnum());
      msg.setDefMode(value);
      break;
    case 3:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setAmplitude(value);
      break;
    case 4:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setMean(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFreqVal(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEndFreqVal(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCycles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.WF_TaperedSine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.WF_TaperedSine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.WF_TaperedSine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_TaperedSine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAmplitude();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getMean();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getFreqVal();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getEndFreqVal();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getCycles();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
};


/**
 * optional DefinitionMode def_mode = 1;
 * @return {!proto.common.DefinitionMode}
 */
proto.common.WF_TaperedSine.prototype.getDefMode = function() {
  return /** @type {!proto.common.DefinitionMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.DefinitionMode} value
 * @return {!proto.common.WF_TaperedSine} returns this
 */
proto.common.WF_TaperedSine.prototype.setDefMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ValOrId amplitude = 3;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_TaperedSine.prototype.getAmplitude = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 3));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_TaperedSine} returns this
*/
proto.common.WF_TaperedSine.prototype.setAmplitude = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_TaperedSine} returns this
 */
proto.common.WF_TaperedSine.prototype.clearAmplitude = function() {
  return this.setAmplitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_TaperedSine.prototype.hasAmplitude = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ValOrId mean = 4;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_TaperedSine.prototype.getMean = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 4));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_TaperedSine} returns this
*/
proto.common.WF_TaperedSine.prototype.setMean = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_TaperedSine} returns this
 */
proto.common.WF_TaperedSine.prototype.clearMean = function() {
  return this.setMean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_TaperedSine.prototype.hasMean = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float freq_val = 8;
 * @return {number}
 */
proto.common.WF_TaperedSine.prototype.getFreqVal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_TaperedSine} returns this
 */
proto.common.WF_TaperedSine.prototype.setFreqVal = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float end_freq_val = 9;
 * @return {number}
 */
proto.common.WF_TaperedSine.prototype.getEndFreqVal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_TaperedSine} returns this
 */
proto.common.WF_TaperedSine.prototype.setEndFreqVal = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional uint32 cycles = 10;
 * @return {number}
 */
proto.common.WF_TaperedSine.prototype.getCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_TaperedSine} returns this
 */
proto.common.WF_TaperedSine.prototype.setCycles = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.WF_SweepSine.prototype.toObject = function(opt_includeInstance) {
  return proto.common.WF_SweepSine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.WF_SweepSine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_SweepSine.toObject = function(includeInstance, msg) {
  var f, obj = {
    defMode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amplitude: (f = msg.getAmplitude()) && proto.common.ValOrId.toObject(includeInstance, f),
    mean: (f = msg.getMean()) && proto.common.ValOrId.toObject(includeInstance, f),
    startFreqVal: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    endFreqVal: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    phase: jspb.Message.getFieldWithDefault(msg, 10, 0),
    cycles: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.WF_SweepSine}
 */
proto.common.WF_SweepSine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.WF_SweepSine;
  return proto.common.WF_SweepSine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.WF_SweepSine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.WF_SweepSine}
 */
proto.common.WF_SweepSine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.DefinitionMode} */ (reader.readEnum());
      msg.setDefMode(value);
      break;
    case 2:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setAmplitude(value);
      break;
    case 3:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setMean(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStartFreqVal(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEndFreqVal(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPhase(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCycles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.WF_SweepSine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.WF_SweepSine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.WF_SweepSine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_SweepSine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAmplitude();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getMean();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getStartFreqVal();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getEndFreqVal();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getPhase();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getCycles();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
};


/**
 * optional DefinitionMode def_mode = 1;
 * @return {!proto.common.DefinitionMode}
 */
proto.common.WF_SweepSine.prototype.getDefMode = function() {
  return /** @type {!proto.common.DefinitionMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.DefinitionMode} value
 * @return {!proto.common.WF_SweepSine} returns this
 */
proto.common.WF_SweepSine.prototype.setDefMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ValOrId amplitude = 2;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_SweepSine.prototype.getAmplitude = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 2));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_SweepSine} returns this
*/
proto.common.WF_SweepSine.prototype.setAmplitude = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_SweepSine} returns this
 */
proto.common.WF_SweepSine.prototype.clearAmplitude = function() {
  return this.setAmplitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_SweepSine.prototype.hasAmplitude = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ValOrId mean = 3;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_SweepSine.prototype.getMean = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 3));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_SweepSine} returns this
*/
proto.common.WF_SweepSine.prototype.setMean = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_SweepSine} returns this
 */
proto.common.WF_SweepSine.prototype.clearMean = function() {
  return this.setMean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_SweepSine.prototype.hasMean = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float start_freq_val = 8;
 * @return {number}
 */
proto.common.WF_SweepSine.prototype.getStartFreqVal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_SweepSine} returns this
 */
proto.common.WF_SweepSine.prototype.setStartFreqVal = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float end_freq_val = 9;
 * @return {number}
 */
proto.common.WF_SweepSine.prototype.getEndFreqVal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_SweepSine} returns this
 */
proto.common.WF_SweepSine.prototype.setEndFreqVal = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional uint32 phase = 10;
 * @return {number}
 */
proto.common.WF_SweepSine.prototype.getPhase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_SweepSine} returns this
 */
proto.common.WF_SweepSine.prototype.setPhase = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 cycles = 11;
 * @return {number}
 */
proto.common.WF_SweepSine.prototype.getCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_SweepSine} returns this
 */
proto.common.WF_SweepSine.prototype.setCycles = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.WF_Squarewave.prototype.toObject = function(opt_includeInstance) {
  return proto.common.WF_Squarewave.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.WF_Squarewave} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_Squarewave.toObject = function(includeInstance, msg) {
  var f, obj = {
    defMode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amplitude: (f = msg.getAmplitude()) && proto.common.ValOrId.toObject(includeInstance, f),
    mean: (f = msg.getMean()) && proto.common.ValOrId.toObject(includeInstance, f),
    startDir: jspb.Message.getFieldWithDefault(msg, 8, 0),
    freqVal: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    cycles: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.WF_Squarewave}
 */
proto.common.WF_Squarewave.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.WF_Squarewave;
  return proto.common.WF_Squarewave.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.WF_Squarewave} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.WF_Squarewave}
 */
proto.common.WF_Squarewave.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.DefinitionMode} */ (reader.readEnum());
      msg.setDefMode(value);
      break;
    case 2:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setAmplitude(value);
      break;
    case 3:
      var value = new proto.common.ValOrId;
      reader.readMessage(value,proto.common.ValOrId.deserializeBinaryFromReader);
      msg.setMean(value);
      break;
    case 8:
      var value = /** @type {!proto.common.Direction} */ (reader.readEnum());
      msg.setStartDir(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFreqVal(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCycles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.WF_Squarewave.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.WF_Squarewave.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.WF_Squarewave} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.WF_Squarewave.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAmplitude();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getMean();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.ValOrId.serializeBinaryToWriter
    );
  }
  f = message.getStartDir();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getFreqVal();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getCycles();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
};


/**
 * optional DefinitionMode def_mode = 1;
 * @return {!proto.common.DefinitionMode}
 */
proto.common.WF_Squarewave.prototype.getDefMode = function() {
  return /** @type {!proto.common.DefinitionMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.DefinitionMode} value
 * @return {!proto.common.WF_Squarewave} returns this
 */
proto.common.WF_Squarewave.prototype.setDefMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ValOrId amplitude = 2;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_Squarewave.prototype.getAmplitude = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 2));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_Squarewave} returns this
*/
proto.common.WF_Squarewave.prototype.setAmplitude = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_Squarewave} returns this
 */
proto.common.WF_Squarewave.prototype.clearAmplitude = function() {
  return this.setAmplitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_Squarewave.prototype.hasAmplitude = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ValOrId mean = 3;
 * @return {?proto.common.ValOrId}
 */
proto.common.WF_Squarewave.prototype.getMean = function() {
  return /** @type{?proto.common.ValOrId} */ (
    jspb.Message.getWrapperField(this, proto.common.ValOrId, 3));
};


/**
 * @param {?proto.common.ValOrId|undefined} value
 * @return {!proto.common.WF_Squarewave} returns this
*/
proto.common.WF_Squarewave.prototype.setMean = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.WF_Squarewave} returns this
 */
proto.common.WF_Squarewave.prototype.clearMean = function() {
  return this.setMean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.WF_Squarewave.prototype.hasMean = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Direction start_dir = 8;
 * @return {!proto.common.Direction}
 */
proto.common.WF_Squarewave.prototype.getStartDir = function() {
  return /** @type {!proto.common.Direction} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.Direction} value
 * @return {!proto.common.WF_Squarewave} returns this
 */
proto.common.WF_Squarewave.prototype.setStartDir = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional float freq_val = 9;
 * @return {number}
 */
proto.common.WF_Squarewave.prototype.getFreqVal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_Squarewave} returns this
 */
proto.common.WF_Squarewave.prototype.setFreqVal = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional uint32 cycles = 10;
 * @return {number}
 */
proto.common.WF_Squarewave.prototype.getCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.WF_Squarewave} returns this
 */
proto.common.WF_Squarewave.prototype.setCycles = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AlgoPID.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AlgoPID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AlgoPID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AlgoPID.toObject = function(includeInstance, msg) {
  var f, obj = {
    pGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    iGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    dGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    asymmetricpid: (f = msg.getAsymmetricpid()) && proto.common.AsymmetricPid.toObject(includeInstance, f),
    adaptive: (f = msg.getAdaptive()) && proto.common.Adaptive.toObject(includeInstance, f),
    feedforward: (f = msg.getFeedforward()) && proto.common.FeedForwardDto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AlgoPID}
 */
proto.common.AlgoPID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AlgoPID;
  return proto.common.AlgoPID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AlgoPID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AlgoPID}
 */
proto.common.AlgoPID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPGain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setIGain(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDGain(value);
      break;
    case 5:
      var value = new proto.common.AsymmetricPid;
      reader.readMessage(value,proto.common.AsymmetricPid.deserializeBinaryFromReader);
      msg.setAsymmetricpid(value);
      break;
    case 6:
      var value = new proto.common.Adaptive;
      reader.readMessage(value,proto.common.Adaptive.deserializeBinaryFromReader);
      msg.setAdaptive(value);
      break;
    case 7:
      var value = new proto.common.FeedForwardDto;
      reader.readMessage(value,proto.common.FeedForwardDto.deserializeBinaryFromReader);
      msg.setFeedforward(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AlgoPID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AlgoPID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AlgoPID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AlgoPID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPGain();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getIGain();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDGain();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getAsymmetricpid();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.common.AsymmetricPid.serializeBinaryToWriter
    );
  }
  f = message.getAdaptive();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.common.Adaptive.serializeBinaryToWriter
    );
  }
  f = message.getFeedforward();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.common.FeedForwardDto.serializeBinaryToWriter
    );
  }
};


/**
 * optional float p_gain = 1;
 * @return {number}
 */
proto.common.AlgoPID.prototype.getPGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.AlgoPID} returns this
 */
proto.common.AlgoPID.prototype.setPGain = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float i_gain = 2;
 * @return {number}
 */
proto.common.AlgoPID.prototype.getIGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.AlgoPID} returns this
 */
proto.common.AlgoPID.prototype.setIGain = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float d_gain = 3;
 * @return {number}
 */
proto.common.AlgoPID.prototype.getDGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.AlgoPID} returns this
 */
proto.common.AlgoPID.prototype.setDGain = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional AsymmetricPid asymmetricPid = 5;
 * @return {?proto.common.AsymmetricPid}
 */
proto.common.AlgoPID.prototype.getAsymmetricpid = function() {
  return /** @type{?proto.common.AsymmetricPid} */ (
    jspb.Message.getWrapperField(this, proto.common.AsymmetricPid, 5));
};


/**
 * @param {?proto.common.AsymmetricPid|undefined} value
 * @return {!proto.common.AlgoPID} returns this
*/
proto.common.AlgoPID.prototype.setAsymmetricpid = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AlgoPID} returns this
 */
proto.common.AlgoPID.prototype.clearAsymmetricpid = function() {
  return this.setAsymmetricpid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AlgoPID.prototype.hasAsymmetricpid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Adaptive adaptive = 6;
 * @return {?proto.common.Adaptive}
 */
proto.common.AlgoPID.prototype.getAdaptive = function() {
  return /** @type{?proto.common.Adaptive} */ (
    jspb.Message.getWrapperField(this, proto.common.Adaptive, 6));
};


/**
 * @param {?proto.common.Adaptive|undefined} value
 * @return {!proto.common.AlgoPID} returns this
*/
proto.common.AlgoPID.prototype.setAdaptive = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AlgoPID} returns this
 */
proto.common.AlgoPID.prototype.clearAdaptive = function() {
  return this.setAdaptive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AlgoPID.prototype.hasAdaptive = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional FeedForwardDto feedforward = 7;
 * @return {?proto.common.FeedForwardDto}
 */
proto.common.AlgoPID.prototype.getFeedforward = function() {
  return /** @type{?proto.common.FeedForwardDto} */ (
    jspb.Message.getWrapperField(this, proto.common.FeedForwardDto, 7));
};


/**
 * @param {?proto.common.FeedForwardDto|undefined} value
 * @return {!proto.common.AlgoPID} returns this
*/
proto.common.AlgoPID.prototype.setFeedforward = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AlgoPID} returns this
 */
proto.common.AlgoPID.prototype.clearFeedforward = function() {
  return this.setFeedforward(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AlgoPID.prototype.hasFeedforward = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AlgoOpenLoop.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AlgoOpenLoop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AlgoOpenLoop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AlgoOpenLoop.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AlgoOpenLoop}
 */
proto.common.AlgoOpenLoop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AlgoOpenLoop;
  return proto.common.AlgoOpenLoop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AlgoOpenLoop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AlgoOpenLoop}
 */
proto.common.AlgoOpenLoop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AlgoOpenLoop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AlgoOpenLoop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AlgoOpenLoop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AlgoOpenLoop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.common.AlgoOpenLoop.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AlgoOpenLoop} returns this
 */
proto.common.AlgoOpenLoop.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.AlgoOpenLoop} returns this
 */
proto.common.AlgoOpenLoop.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AlgoOpenLoop.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Dither.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Dither.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Dither} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Dither.toObject = function(includeInstance, msg) {
  var f, obj = {
    amplitude: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequency: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Dither}
 */
proto.common.Dither.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Dither;
  return proto.common.Dither.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Dither} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Dither}
 */
proto.common.Dither.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAmplitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFrequency(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Dither.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Dither.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Dither} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Dither.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmplitude();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional uint32 amplitude = 1;
 * @return {number}
 */
proto.common.Dither.prototype.getAmplitude = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Dither} returns this
 */
proto.common.Dither.prototype.setAmplitude = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float frequency = 2;
 * @return {number}
 */
proto.common.Dither.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Dither} returns this
 */
proto.common.Dither.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool enable = 3;
 * @return {boolean}
 */
proto.common.Dither.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Dither} returns this
 */
proto.common.Dither.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AsymmetricPid.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AsymmetricPid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AsymmetricPid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AsymmetricPid.toObject = function(includeInstance, msg) {
  var f, obj = {
    positiveGainBias: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    negativeGainBias: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AsymmetricPid}
 */
proto.common.AsymmetricPid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AsymmetricPid;
  return proto.common.AsymmetricPid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AsymmetricPid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AsymmetricPid}
 */
proto.common.AsymmetricPid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPositiveGainBias(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setNegativeGainBias(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AsymmetricPid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AsymmetricPid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AsymmetricPid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AsymmetricPid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositiveGainBias();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getNegativeGainBias();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional float positive_gain_bias = 1;
 * @return {number}
 */
proto.common.AsymmetricPid.prototype.getPositiveGainBias = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.AsymmetricPid} returns this
 */
proto.common.AsymmetricPid.prototype.setPositiveGainBias = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float negative_gain_bias = 2;
 * @return {number}
 */
proto.common.AsymmetricPid.prototype.getNegativeGainBias = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.AsymmetricPid} returns this
 */
proto.common.AsymmetricPid.prototype.setNegativeGainBias = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool enable = 3;
 * @return {boolean}
 */
proto.common.AsymmetricPid.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.AsymmetricPid} returns this
 */
proto.common.AsymmetricPid.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.FeedForwardDto.prototype.toObject = function(opt_includeInstance) {
  return proto.common.FeedForwardDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.FeedForwardDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FeedForwardDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    velGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    accGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    jerkGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.FeedForwardDto}
 */
proto.common.FeedForwardDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.FeedForwardDto;
  return proto.common.FeedForwardDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.FeedForwardDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.FeedForwardDto}
 */
proto.common.FeedForwardDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVelGain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAccGain(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setJerkGain(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.FeedForwardDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.FeedForwardDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.FeedForwardDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FeedForwardDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVelGain();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getAccGain();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getJerkGain();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional float vel_gain = 1;
 * @return {number}
 */
proto.common.FeedForwardDto.prototype.getVelGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.FeedForwardDto} returns this
 */
proto.common.FeedForwardDto.prototype.setVelGain = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float acc_gain = 2;
 * @return {number}
 */
proto.common.FeedForwardDto.prototype.getAccGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.FeedForwardDto} returns this
 */
proto.common.FeedForwardDto.prototype.setAccGain = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float jerk_gain = 3;
 * @return {number}
 */
proto.common.FeedForwardDto.prototype.getJerkGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.FeedForwardDto} returns this
 */
proto.common.FeedForwardDto.prototype.setJerkGain = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool enable = 4;
 * @return {boolean}
 */
proto.common.FeedForwardDto.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.FeedForwardDto} returns this
 */
proto.common.FeedForwardDto.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Adaptive.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Adaptive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Adaptive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Adaptive.toObject = function(includeInstance, msg) {
  var f, obj = {
    ampPeriod: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ampTargetVal: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    ampErrorThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    ampStep: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    ampEn: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    meanTargetVal: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    meanStep: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    meanErrorThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    meanEn: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    measId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Adaptive}
 */
proto.common.Adaptive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Adaptive;
  return proto.common.Adaptive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Adaptive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Adaptive}
 */
proto.common.Adaptive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAmpPeriod(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmpTargetVal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmpErrorThreshold(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmpStep(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmpEn(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeanTargetVal(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeanStep(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeanErrorThreshold(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMeanEn(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Adaptive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Adaptive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Adaptive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Adaptive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmpPeriod();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAmpTargetVal();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getAmpErrorThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getAmpStep();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getAmpEn();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMeanTargetVal();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMeanStep();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getMeanErrorThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getMeanEn();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getMeasId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional uint32 amp_period = 1;
 * @return {number}
 */
proto.common.Adaptive.prototype.getAmpPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Adaptive} returns this
 */
proto.common.Adaptive.prototype.setAmpPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float amp_target_val = 2;
 * @return {number}
 */
proto.common.Adaptive.prototype.getAmpTargetVal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Adaptive} returns this
 */
proto.common.Adaptive.prototype.setAmpTargetVal = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float amp_error_threshold = 3;
 * @return {number}
 */
proto.common.Adaptive.prototype.getAmpErrorThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Adaptive} returns this
 */
proto.common.Adaptive.prototype.setAmpErrorThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float amp_step = 4;
 * @return {number}
 */
proto.common.Adaptive.prototype.getAmpStep = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Adaptive} returns this
 */
proto.common.Adaptive.prototype.setAmpStep = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool amp_en = 5;
 * @return {boolean}
 */
proto.common.Adaptive.prototype.getAmpEn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Adaptive} returns this
 */
proto.common.Adaptive.prototype.setAmpEn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional float mean_target_val = 6;
 * @return {number}
 */
proto.common.Adaptive.prototype.getMeanTargetVal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Adaptive} returns this
 */
proto.common.Adaptive.prototype.setMeanTargetVal = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float mean_step = 7;
 * @return {number}
 */
proto.common.Adaptive.prototype.getMeanStep = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Adaptive} returns this
 */
proto.common.Adaptive.prototype.setMeanStep = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float mean_error_threshold = 8;
 * @return {number}
 */
proto.common.Adaptive.prototype.getMeanErrorThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Adaptive} returns this
 */
proto.common.Adaptive.prototype.setMeanErrorThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional bool mean_en = 9;
 * @return {boolean}
 */
proto.common.Adaptive.prototype.getMeanEn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Adaptive} returns this
 */
proto.common.Adaptive.prototype.setMeanEn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string meas_id = 10;
 * @return {string}
 */
proto.common.Adaptive.prototype.getMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Adaptive} returns this
 */
proto.common.Adaptive.prototype.setMeasId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Calibration.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Calibration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Calibration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Calibration.toObject = function(includeInstance, msg) {
  var f, obj = {
    calibrationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    calibrationType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    linearCalibration: (f = msg.getLinearCalibration()) && proto.common.LinearCalibration.toObject(includeInstance, f),
    piecewiseCalibration: (f = msg.getPiecewiseCalibration()) && proto.common.PiecewiseCalibration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Calibration}
 */
proto.common.Calibration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Calibration;
  return proto.common.Calibration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Calibration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Calibration}
 */
proto.common.Calibration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalibrationId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CalibrationType} */ (reader.readEnum());
      msg.setCalibrationType(value);
      break;
    case 3:
      var value = new proto.common.LinearCalibration;
      reader.readMessage(value,proto.common.LinearCalibration.deserializeBinaryFromReader);
      msg.setLinearCalibration(value);
      break;
    case 4:
      var value = new proto.common.PiecewiseCalibration;
      reader.readMessage(value,proto.common.PiecewiseCalibration.deserializeBinaryFromReader);
      msg.setPiecewiseCalibration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Calibration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Calibration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Calibration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Calibration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalibrationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCalibrationType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLinearCalibration();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.LinearCalibration.serializeBinaryToWriter
    );
  }
  f = message.getPiecewiseCalibration();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.PiecewiseCalibration.serializeBinaryToWriter
    );
  }
};


/**
 * optional string calibration_id = 1;
 * @return {string}
 */
proto.common.Calibration.prototype.getCalibrationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Calibration} returns this
 */
proto.common.Calibration.prototype.setCalibrationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CalibrationType calibration_type = 2;
 * @return {!proto.common.CalibrationType}
 */
proto.common.Calibration.prototype.getCalibrationType = function() {
  return /** @type {!proto.common.CalibrationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CalibrationType} value
 * @return {!proto.common.Calibration} returns this
 */
proto.common.Calibration.prototype.setCalibrationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional LinearCalibration linear_calibration = 3;
 * @return {?proto.common.LinearCalibration}
 */
proto.common.Calibration.prototype.getLinearCalibration = function() {
  return /** @type{?proto.common.LinearCalibration} */ (
    jspb.Message.getWrapperField(this, proto.common.LinearCalibration, 3));
};


/**
 * @param {?proto.common.LinearCalibration|undefined} value
 * @return {!proto.common.Calibration} returns this
*/
proto.common.Calibration.prototype.setLinearCalibration = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Calibration} returns this
 */
proto.common.Calibration.prototype.clearLinearCalibration = function() {
  return this.setLinearCalibration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Calibration.prototype.hasLinearCalibration = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PiecewiseCalibration piecewise_calibration = 4;
 * @return {?proto.common.PiecewiseCalibration}
 */
proto.common.Calibration.prototype.getPiecewiseCalibration = function() {
  return /** @type{?proto.common.PiecewiseCalibration} */ (
    jspb.Message.getWrapperField(this, proto.common.PiecewiseCalibration, 4));
};


/**
 * @param {?proto.common.PiecewiseCalibration|undefined} value
 * @return {!proto.common.Calibration} returns this
*/
proto.common.Calibration.prototype.setPiecewiseCalibration = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Calibration} returns this
 */
proto.common.Calibration.prototype.clearPiecewiseCalibration = function() {
  return this.setPiecewiseCalibration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Calibration.prototype.hasPiecewiseCalibration = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.CalibrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.CalibrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.CalibrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CalibrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    calibration: (f = msg.getCalibration()) && proto.common.Calibration.toObject(includeInstance, f),
    tedsId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sensorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    shuntCalibration: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    zeroCalibration: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.CalibrationRequest}
 */
proto.common.CalibrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.CalibrationRequest;
  return proto.common.CalibrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.CalibrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.CalibrationRequest}
 */
proto.common.CalibrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Calibration;
      reader.readMessage(value,proto.common.Calibration.deserializeBinaryFromReader);
      msg.setCalibration(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTedsId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSensorId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShuntCalibration(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZeroCalibration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.CalibrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.CalibrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.CalibrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CalibrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalibration();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.Calibration.serializeBinaryToWriter
    );
  }
  f = message.getTedsId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSensorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShuntCalibration();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getZeroCalibration();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional Calibration calibration = 1;
 * @return {?proto.common.Calibration}
 */
proto.common.CalibrationRequest.prototype.getCalibration = function() {
  return /** @type{?proto.common.Calibration} */ (
    jspb.Message.getWrapperField(this, proto.common.Calibration, 1));
};


/**
 * @param {?proto.common.Calibration|undefined} value
 * @return {!proto.common.CalibrationRequest} returns this
*/
proto.common.CalibrationRequest.prototype.setCalibration = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.CalibrationRequest} returns this
 */
proto.common.CalibrationRequest.prototype.clearCalibration = function() {
  return this.setCalibration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.CalibrationRequest.prototype.hasCalibration = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string teds_id = 2;
 * @return {string}
 */
proto.common.CalibrationRequest.prototype.getTedsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.CalibrationRequest} returns this
 */
proto.common.CalibrationRequest.prototype.setTedsId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sensor_id = 3;
 * @return {string}
 */
proto.common.CalibrationRequest.prototype.getSensorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.CalibrationRequest} returns this
 */
proto.common.CalibrationRequest.prototype.setSensorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool shunt_calibration = 4;
 * @return {boolean}
 */
proto.common.CalibrationRequest.prototype.getShuntCalibration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.CalibrationRequest} returns this
 */
proto.common.CalibrationRequest.prototype.setShuntCalibration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool zero_calibration = 5;
 * @return {boolean}
 */
proto.common.CalibrationRequest.prototype.getZeroCalibration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.CalibrationRequest} returns this
 */
proto.common.CalibrationRequest.prototype.setZeroCalibration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.LinearCalibration.prototype.toObject = function(opt_includeInstance) {
  return proto.common.LinearCalibration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.LinearCalibration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.LinearCalibration.toObject = function(includeInstance, msg) {
  var f, obj = {
    slope: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    offset: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.LinearCalibration}
 */
proto.common.LinearCalibration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.LinearCalibration;
  return proto.common.LinearCalibration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.LinearCalibration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.LinearCalibration}
 */
proto.common.LinearCalibration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSlope(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.LinearCalibration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.LinearCalibration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.LinearCalibration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.LinearCalibration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlope();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional float slope = 2;
 * @return {number}
 */
proto.common.LinearCalibration.prototype.getSlope = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.LinearCalibration} returns this
 */
proto.common.LinearCalibration.prototype.setSlope = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float offset = 3;
 * @return {number}
 */
proto.common.LinearCalibration.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.LinearCalibration} returns this
 */
proto.common.LinearCalibration.prototype.setOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.PiecewiseCalibration.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.PiecewiseCalibration.prototype.toObject = function(opt_includeInstance) {
  return proto.common.PiecewiseCalibration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.PiecewiseCalibration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PiecewiseCalibration.toObject = function(includeInstance, msg) {
  var f, obj = {
    calibrationDataList: jspb.Message.toObjectList(msg.getCalibrationDataList(),
    proto.common.CalibrationTupleDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.PiecewiseCalibration}
 */
proto.common.PiecewiseCalibration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.PiecewiseCalibration;
  return proto.common.PiecewiseCalibration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.PiecewiseCalibration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.PiecewiseCalibration}
 */
proto.common.PiecewiseCalibration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.CalibrationTupleDto;
      reader.readMessage(value,proto.common.CalibrationTupleDto.deserializeBinaryFromReader);
      msg.addCalibrationData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.PiecewiseCalibration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.PiecewiseCalibration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.PiecewiseCalibration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PiecewiseCalibration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalibrationDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.CalibrationTupleDto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CalibrationTupleDto calibration_data = 1;
 * @return {!Array<!proto.common.CalibrationTupleDto>}
 */
proto.common.PiecewiseCalibration.prototype.getCalibrationDataList = function() {
  return /** @type{!Array<!proto.common.CalibrationTupleDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.CalibrationTupleDto, 1));
};


/**
 * @param {!Array<!proto.common.CalibrationTupleDto>} value
 * @return {!proto.common.PiecewiseCalibration} returns this
*/
proto.common.PiecewiseCalibration.prototype.setCalibrationDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.CalibrationTupleDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.CalibrationTupleDto}
 */
proto.common.PiecewiseCalibration.prototype.addCalibrationData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.CalibrationTupleDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.PiecewiseCalibration} returns this
 */
proto.common.PiecewiseCalibration.prototype.clearCalibrationDataList = function() {
  return this.setCalibrationDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.CalibrationTupleDto.prototype.toObject = function(opt_includeInstance) {
  return proto.common.CalibrationTupleDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.CalibrationTupleDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CalibrationTupleDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    usd: jspb.Message.getFieldWithDefault(msg, 1, 0),
    csd: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.CalibrationTupleDto}
 */
proto.common.CalibrationTupleDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.CalibrationTupleDto;
  return proto.common.CalibrationTupleDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.CalibrationTupleDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.CalibrationTupleDto}
 */
proto.common.CalibrationTupleDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCsd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.CalibrationTupleDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.CalibrationTupleDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.CalibrationTupleDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CalibrationTupleDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsd();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCsd();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional int32 usd = 1;
 * @return {number}
 */
proto.common.CalibrationTupleDto.prototype.getUsd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.CalibrationTupleDto} returns this
 */
proto.common.CalibrationTupleDto.prototype.setUsd = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float csd = 2;
 * @return {number}
 */
proto.common.CalibrationTupleDto.prototype.getCsd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.CalibrationTupleDto} returns this
 */
proto.common.CalibrationTupleDto.prototype.setCsd = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AxisInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AxisInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AxisInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AxisInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    axisIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    spType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fbMeasurement: jspb.Message.getFieldWithDefault(msg, 4, ""),
    spMeasurement: jspb.Message.getFieldWithDefault(msg, 5, ""),
    wfProps: (f = msg.getWfProps()) && proto.common.WFProps.toObject(includeInstance, f),
    algorithm: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pid: (f = msg.getPid()) && proto.common.AlgoPID.toObject(includeInstance, f),
    ol: (f = msg.getOl()) && proto.common.AlgoOpenLoop.toObject(includeInstance, f),
    dither: (f = msg.getDither()) && proto.common.Dither.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AxisInfo}
 */
proto.common.AxisInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AxisInfo;
  return proto.common.AxisInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AxisInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AxisInfo}
 */
proto.common.AxisInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAxisIndex(value);
      break;
    case 3:
      var value = /** @type {!proto.common.SetPointType} */ (reader.readEnum());
      msg.setSpType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFbMeasurement(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpMeasurement(value);
      break;
    case 6:
      var value = new proto.common.WFProps;
      reader.readMessage(value,proto.common.WFProps.deserializeBinaryFromReader);
      msg.setWfProps(value);
      break;
    case 7:
      var value = /** @type {!proto.common.AlgorithmType} */ (reader.readEnum());
      msg.setAlgorithm(value);
      break;
    case 50:
      var value = new proto.common.AlgoPID;
      reader.readMessage(value,proto.common.AlgoPID.deserializeBinaryFromReader);
      msg.setPid(value);
      break;
    case 51:
      var value = new proto.common.AlgoOpenLoop;
      reader.readMessage(value,proto.common.AlgoOpenLoop.deserializeBinaryFromReader);
      msg.setOl(value);
      break;
    case 8:
      var value = new proto.common.Dither;
      reader.readMessage(value,proto.common.Dither.deserializeBinaryFromReader);
      msg.setDither(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AxisInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AxisInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AxisInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AxisInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAxisId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAxisIndex();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSpType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFbMeasurement();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSpMeasurement();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWfProps();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.common.WFProps.serializeBinaryToWriter
    );
  }
  f = message.getAlgorithm();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPid();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.common.AlgoPID.serializeBinaryToWriter
    );
  }
  f = message.getOl();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.common.AlgoOpenLoop.serializeBinaryToWriter
    );
  }
  f = message.getDither();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.common.Dither.serializeBinaryToWriter
    );
  }
};


/**
 * optional string axis_id = 1;
 * @return {string}
 */
proto.common.AxisInfo.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AxisInfo} returns this
 */
proto.common.AxisInfo.prototype.setAxisId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 axis_index = 2;
 * @return {number}
 */
proto.common.AxisInfo.prototype.getAxisIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.AxisInfo} returns this
 */
proto.common.AxisInfo.prototype.setAxisIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional SetPointType sp_type = 3;
 * @return {!proto.common.SetPointType}
 */
proto.common.AxisInfo.prototype.getSpType = function() {
  return /** @type {!proto.common.SetPointType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.SetPointType} value
 * @return {!proto.common.AxisInfo} returns this
 */
proto.common.AxisInfo.prototype.setSpType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string fb_measurement = 4;
 * @return {string}
 */
proto.common.AxisInfo.prototype.getFbMeasurement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AxisInfo} returns this
 */
proto.common.AxisInfo.prototype.setFbMeasurement = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sp_measurement = 5;
 * @return {string}
 */
proto.common.AxisInfo.prototype.getSpMeasurement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AxisInfo} returns this
 */
proto.common.AxisInfo.prototype.setSpMeasurement = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional WFProps wf_props = 6;
 * @return {?proto.common.WFProps}
 */
proto.common.AxisInfo.prototype.getWfProps = function() {
  return /** @type{?proto.common.WFProps} */ (
    jspb.Message.getWrapperField(this, proto.common.WFProps, 6));
};


/**
 * @param {?proto.common.WFProps|undefined} value
 * @return {!proto.common.AxisInfo} returns this
*/
proto.common.AxisInfo.prototype.setWfProps = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AxisInfo} returns this
 */
proto.common.AxisInfo.prototype.clearWfProps = function() {
  return this.setWfProps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AxisInfo.prototype.hasWfProps = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AlgorithmType algorithm = 7;
 * @return {!proto.common.AlgorithmType}
 */
proto.common.AxisInfo.prototype.getAlgorithm = function() {
  return /** @type {!proto.common.AlgorithmType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.AlgorithmType} value
 * @return {!proto.common.AxisInfo} returns this
 */
proto.common.AxisInfo.prototype.setAlgorithm = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional AlgoPID pid = 50;
 * @return {?proto.common.AlgoPID}
 */
proto.common.AxisInfo.prototype.getPid = function() {
  return /** @type{?proto.common.AlgoPID} */ (
    jspb.Message.getWrapperField(this, proto.common.AlgoPID, 50));
};


/**
 * @param {?proto.common.AlgoPID|undefined} value
 * @return {!proto.common.AxisInfo} returns this
*/
proto.common.AxisInfo.prototype.setPid = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AxisInfo} returns this
 */
proto.common.AxisInfo.prototype.clearPid = function() {
  return this.setPid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AxisInfo.prototype.hasPid = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional AlgoOpenLoop ol = 51;
 * @return {?proto.common.AlgoOpenLoop}
 */
proto.common.AxisInfo.prototype.getOl = function() {
  return /** @type{?proto.common.AlgoOpenLoop} */ (
    jspb.Message.getWrapperField(this, proto.common.AlgoOpenLoop, 51));
};


/**
 * @param {?proto.common.AlgoOpenLoop|undefined} value
 * @return {!proto.common.AxisInfo} returns this
*/
proto.common.AxisInfo.prototype.setOl = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AxisInfo} returns this
 */
proto.common.AxisInfo.prototype.clearOl = function() {
  return this.setOl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AxisInfo.prototype.hasOl = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional Dither dither = 8;
 * @return {?proto.common.Dither}
 */
proto.common.AxisInfo.prototype.getDither = function() {
  return /** @type{?proto.common.Dither} */ (
    jspb.Message.getWrapperField(this, proto.common.Dither, 8));
};


/**
 * @param {?proto.common.Dither|undefined} value
 * @return {!proto.common.AxisInfo} returns this
*/
proto.common.AxisInfo.prototype.setDither = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AxisInfo} returns this
 */
proto.common.AxisInfo.prototype.clearDither = function() {
  return this.setDither(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AxisInfo.prototype.hasDither = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ControlInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ControlInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ControlInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ControlInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    axisIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fbMeasurement: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pid: (f = msg.getPid()) && proto.common.AlgoPID.toObject(includeInstance, f),
    dither: (f = msg.getDither()) && proto.common.Dither.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ControlInfo}
 */
proto.common.ControlInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ControlInfo;
  return proto.common.ControlInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ControlInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ControlInfo}
 */
proto.common.ControlInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAxisIndex(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFbMeasurement(value);
      break;
    case 4:
      var value = new proto.common.AlgoPID;
      reader.readMessage(value,proto.common.AlgoPID.deserializeBinaryFromReader);
      msg.setPid(value);
      break;
    case 5:
      var value = new proto.common.Dither;
      reader.readMessage(value,proto.common.Dither.deserializeBinaryFromReader);
      msg.setDither(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ControlInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ControlInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ControlInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ControlInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAxisId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAxisIndex();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFbMeasurement();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.AlgoPID.serializeBinaryToWriter
    );
  }
  f = message.getDither();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.common.Dither.serializeBinaryToWriter
    );
  }
};


/**
 * optional string axis_id = 1;
 * @return {string}
 */
proto.common.ControlInfo.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ControlInfo} returns this
 */
proto.common.ControlInfo.prototype.setAxisId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 axis_index = 2;
 * @return {number}
 */
proto.common.ControlInfo.prototype.getAxisIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.ControlInfo} returns this
 */
proto.common.ControlInfo.prototype.setAxisIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string fb_measurement = 3;
 * @return {string}
 */
proto.common.ControlInfo.prototype.getFbMeasurement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ControlInfo} returns this
 */
proto.common.ControlInfo.prototype.setFbMeasurement = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AlgoPID pid = 4;
 * @return {?proto.common.AlgoPID}
 */
proto.common.ControlInfo.prototype.getPid = function() {
  return /** @type{?proto.common.AlgoPID} */ (
    jspb.Message.getWrapperField(this, proto.common.AlgoPID, 4));
};


/**
 * @param {?proto.common.AlgoPID|undefined} value
 * @return {!proto.common.ControlInfo} returns this
*/
proto.common.ControlInfo.prototype.setPid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ControlInfo} returns this
 */
proto.common.ControlInfo.prototype.clearPid = function() {
  return this.setPid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ControlInfo.prototype.hasPid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Dither dither = 5;
 * @return {?proto.common.Dither}
 */
proto.common.ControlInfo.prototype.getDither = function() {
  return /** @type{?proto.common.Dither} */ (
    jspb.Message.getWrapperField(this, proto.common.Dither, 5));
};


/**
 * @param {?proto.common.Dither|undefined} value
 * @return {!proto.common.ControlInfo} returns this
*/
proto.common.ControlInfo.prototype.setDither = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ControlInfo} returns this
 */
proto.common.ControlInfo.prototype.clearDither = function() {
  return this.setDither(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ControlInfo.prototype.hasDither = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.UnitFormula.prototype.toObject = function(opt_includeInstance) {
  return proto.common.UnitFormula.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.UnitFormula} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.UnitFormula.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scale: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    offset: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.UnitFormula}
 */
proto.common.UnitFormula.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.UnitFormula;
  return proto.common.UnitFormula.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.UnitFormula} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.UnitFormula}
 */
proto.common.UnitFormula.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScale(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.UnitFormula.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.UnitFormula.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.UnitFormula} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.UnitFormula.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScale();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.UnitFormula.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UnitFormula} returns this
 */
proto.common.UnitFormula.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float scale = 2;
 * @return {number}
 */
proto.common.UnitFormula.prototype.getScale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.UnitFormula} returns this
 */
proto.common.UnitFormula.prototype.setScale = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float offset = 3;
 * @return {number}
 */
proto.common.UnitFormula.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.UnitFormula} returns this
 */
proto.common.UnitFormula.prototype.setOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.VariableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.VariableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.VariableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.VariableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    defaultValStr: jspb.Message.getFieldWithDefault(msg, 4, ""),
    defaultValNum: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    defaultValBool: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    measId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.VariableRequest}
 */
proto.common.VariableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.VariableRequest;
  return proto.common.VariableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.VariableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.VariableRequest}
 */
proto.common.VariableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.common.VariableType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultValStr(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDefaultValNum(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultValBool(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.VariableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.VariableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.VariableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.VariableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.VariableRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.VariableRequest} returns this
 */
proto.common.VariableRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.common.VariableRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.VariableRequest} returns this
 */
proto.common.VariableRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional VariableType type = 3;
 * @return {!proto.common.VariableType}
 */
proto.common.VariableRequest.prototype.getType = function() {
  return /** @type {!proto.common.VariableType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.VariableType} value
 * @return {!proto.common.VariableRequest} returns this
 */
proto.common.VariableRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string default_val_str = 4;
 * @return {string}
 */
proto.common.VariableRequest.prototype.getDefaultValStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.VariableRequest} returns this
 */
proto.common.VariableRequest.prototype.setDefaultValStr = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.VariableRequest} returns this
 */
proto.common.VariableRequest.prototype.clearDefaultValStr = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.VariableRequest.prototype.hasDefaultValStr = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float default_val_num = 5;
 * @return {number}
 */
proto.common.VariableRequest.prototype.getDefaultValNum = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.VariableRequest} returns this
 */
proto.common.VariableRequest.prototype.setDefaultValNum = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.VariableRequest} returns this
 */
proto.common.VariableRequest.prototype.clearDefaultValNum = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.VariableRequest.prototype.hasDefaultValNum = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool default_val_bool = 6;
 * @return {boolean}
 */
proto.common.VariableRequest.prototype.getDefaultValBool = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.VariableRequest} returns this
 */
proto.common.VariableRequest.prototype.setDefaultValBool = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.VariableRequest} returns this
 */
proto.common.VariableRequest.prototype.clearDefaultValBool = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.VariableRequest.prototype.hasDefaultValBool = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string meas_id = 7;
 * @return {string}
 */
proto.common.VariableRequest.prototype.getMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.VariableRequest} returns this
 */
proto.common.VariableRequest.prototype.setMeasId = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.VariableRequest} returns this
 */
proto.common.VariableRequest.prototype.clearMeasId = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.VariableRequest.prototype.hasMeasId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * @enum {number}
 */
proto.common.Direction = {
  UP: 0,
  DOWN: 1
};

/**
 * @enum {number}
 */
proto.common.RampDirection = {
  RAMP_UP: 0,
  RAMP_DOWN: 1,
  RAMP_AUTO: 2
};

/**
 * @enum {number}
 */
proto.common.StationChannelType = {
  STATION_CHANNEL_TYPE_DIGITAL_IO_5V: 0,
  STATION_CHANNEL_TYPE_DIGITAL_IO_24V: 1,
  STATION_CHANNEL_TYPE_DIGITAL_IN_24V: 2,
  STATION_CHANNEL_TYPE_RELAY: 3,
  STATION_CHANNEL_TYPE_ANALOG_IN_10KSPS: 4,
  STATION_CHANNEL_TYPE_ANALOG_IN_10V_24MA: 5,
  STATION_CHANNEL_TYPE_ANALOG_IN_60KSPS: 6,
  STATION_CHANNEL_TYPE_SIGNAL_CONDITIONING: 7,
  STATION_CHANNEL_TYPE_LVDT: 8,
  STATION_CHANNEL_TYPE_ANALOG_OUT_10V: 9,
  STATION_CHANNEL_TYPE_ANALOG_OUT_10V_24MA: 10,
  STATION_CHANNEL_TYPE_ANALOG_OUT_300MA: 11,
  STATION_CHANNEL_TYPE_DC_PWM_30V: 12,
  STATION_CHANNEL_TYPE_DC_PWM_50V: 13,
  STATION_CHANNEL_TYPE_STEPPER_DRIVER: 14,
  STATION_CHANNEL_TYPE_SINGLE_ENDED_ENCODER: 15,
  STATION_CHANNEL_TYPE_DIFFERENTIAL_ENCODER: 16,
  STATION_CHANNEL_TYPE_ANALOG_OUT_100MA: 20,
  STATION_CHANNEL_TYPE_DC_PWM_65V: 21
};

/**
 * @enum {number}
 */
proto.common.BridgeType = {
  BRIDGE_TYPE_FULL_BRIDGE: 0,
  BRIDGE_TYPE_HALF_BRIDGE: 1
};

/**
 * @enum {number}
 */
proto.common.ExcitationVoltage = {
  EXCITATION_VOLTAGE_2_5_V: 0,
  EXCITATION_VOLTAGE_5_V: 1,
  EXCITATION_VOLTAGE_10_V: 2,
  EXCITATION_VOLTAGE_EXTERNAL: 3
};

/**
 * @enum {number}
 */
proto.common.Gain = {
  GAIN_1_VV: 0,
  GAIN_2_VV: 1,
  GAIN_4_VV: 2,
  GAIN_8_VV: 3,
  GAIN_16_VV: 4,
  GAIN_32_VV: 5,
  GAIN_64_VV: 6,
  GAIN_128_VV: 7
};

/**
 * @enum {number}
 */
proto.common.PgaGain = {
  PGA_GAIN_0_125_VV: 0,
  PGA_GAIN_0_25_VV: 1,
  PGA_GAIN_0_5_VV: 2,
  PGA_GAIN_1_VV: 3,
  PGA_GAIN_2_VV: 4,
  PGA_GAIN_4_VV: 5,
  PGA_GAIN_8_VV: 6,
  PGA_GAIN_16_VV: 7,
  PGA_GAIN_32_VV: 8,
  PGA_GAIN_64_VV: 9,
  PGA_GAIN_128_VV: 10
};

/**
 * @enum {number}
 */
proto.common.SamplingRate = {
  SAMPLING_RATE_2_5: 0,
  SAMPLING_RATE_5: 1,
  SAMPLING_RATE_10: 2,
  SAMPLING_RATE_16_6: 3,
  SAMPLING_RATE_20: 4,
  SAMPLING_RATE_50: 5,
  SAMPLING_RATE_60: 6,
  SAMPLING_RATE_100: 7,
  SAMPLING_RATE_400: 8,
  SAMPLING_RATE_1200: 9,
  SAMPLING_RATE_2400: 10,
  SAMPLING_RATE_4800: 11,
  SAMPLING_RATE_7200: 12,
  SAMPLING_RATE_14400: 13,
  SAMPLING_RATE_19200: 14,
  SAMPLING_RATE_38400: 15
};

/**
 * @enum {number}
 */
proto.common.IODirection = {
  INPUT: 0,
  OUTPUT: 1
};

/**
 * @enum {number}
 */
proto.common.ActiveOrDefaultState = {
  ACTIVE_OR_DEFAULT_STATE_LOW_LEVEL: 0,
  ACTIVE_OR_DEFAULT_STATE_HIGH_LEVEL: 1
};

/**
 * @enum {number}
 */
proto.common.PullupResistor = {
  PULLUP_RESISTOR_OFF: 0,
  PULLUP_RESISTOR_ON: 1
};

/**
 * @enum {number}
 */
proto.common.AiAndServoDrvrType = {
  IO_AND_SERVO_DRVR_TYPE_VOLTAGE: 0,
  IO_AND_SERVO_DRVR_TYPE_CURRENT: 1,
  IO_AND_SERVO_DRVR_TYPE_SINGLE_ENDED: 2,
  IO_AND_SERVO_DRVR_TYPE_DIFFERENTIAL: 3
};

/**
 * @enum {number}
 */
proto.common.Range = {
  RANGE_0_5_V: 0,
  RANGE_0_10_V: 1,
  RANGE_PM_2_5_V: 2,
  RANGE_PM_5_V: 3,
  RANGE_PM_10_V: 4,
  RANGE_0_6_V: 5,
  RANGE_0_12_V: 6,
  RANGE_M_6_0_V: 7,
  RANGE_M_12_0_V: 8,
  RANGE_PM_3_V: 9,
  RANGE_PM_6_V: 10,
  RANGE_PM_12_V: 11,
  RANGE_PM_24_V: 12,
  RANGE_0_20_MA: 13,
  RANGE_0_24_MA: 14,
  RANGE_4_20_MA: 15,
  RANGE_PM_24_MA: 16,
  RANGE_0_3_MA: 17,
  RANGE_0_6_MA: 18,
  RANGE_0_12_MA: 19,
  RANGE_0_25_MA: 20,
  RANGE_0_50_MA: 21,
  RANGE_0_100_MA: 22,
  RANGE_0_200_MA: 23,
  RANGE_PM_50_MA: 24,
  RANGE_PM_100_MA: 25
};

/**
 * @enum {number}
 */
proto.common.PartNumbers = {
  PART_NUMBERS_DIO: 0,
  PART_NUMBERS_ADS1262: 1,
  PART_NUMBERS_DRV8701: 2,
  PART_NUMBERS_AD5752R: 3,
  PART_NUMBERS_ENCODER: 4,
  PART_NUMBERS_MAX1301: 5,
  PART_NUMBERS_AD5754R: 6,
  PART_NUMBERS_DRV8704: 7,
  PART_NUMBERS_DAC8775: 8,
  PART_NUMBERS_SUPER_PART: 9,
  PART_NUMBERS_AD5752RC: 10
};

/**
 * @enum {number}
 */
proto.common.UnitSystemType = {
  UNIT_SYSTEM_TYPE_NONE: 0,
  UNIT_SYSTEM_TYPE_METRIC: 1,
  UNIT_SYSTEM_TYPE_IMPERIAL: 2
};

/**
 * @enum {number}
 */
proto.common.DefinitionMode = {
  ABSOLUTE: 0,
  RELATIVE: 1
};

/**
 * @enum {number}
 */
proto.common.EndChannelType = {
  MEASUREMENT: 0,
  SET_POINT: 1,
  TIME: 2
};

/**
 * @enum {number}
 */
proto.common.AlgorithmType = {
  PID: 0,
  OPEN_LOOP: 1
};

/**
 * @enum {number}
 */
proto.common.SetPointType = {
  WAVEFORM: 0,
  MEASUREMENT: 1
};

/**
 * @enum {number}
 */
proto.common.CalibrationType = {
  PIECEWISE: 0,
  LINEAR: 1,
  LOOK_UP_TABLE: 2,
  POLYNOMIAL: 3
};

/**
 * @enum {number}
 */
proto.common.StationState = {
  IN_TEST: 0,
  IDLE: 1,
  ACTIVE: 2,
  SAFE: 3
};

/**
 * @enum {number}
 */
proto.common.VariableType = {
  VARIABLE_TYPE_NUMERIC: 0,
  VARIABLE_TYPE_STRING: 1,
  VARIABLE_TYPE_BOOLEAN: 2
};

/**
 * @enum {number}
 */
proto.common.VariablePredicate = {
  EQUALS: 0,
  NOT_EQUALS: 1
};

goog.object.extend(exports, proto.common);
