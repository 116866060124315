import { FC, useCallback, useEffect, useMemo } from 'react';
import { Button, DataTable, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataTableSelectionMultipleChangeEvent, DataTableValueArray } from 'primereact/datatable';
import {
	ReportSpecimenParams,
	TestReportSpecimenFormType,
	TestReportSpecimenEditFormType,
	TestReportSpecimenFormListType
} from '../../testReportConfiguration.types';
import { specimenReportConfigurationSchema, specimenReportListSchema } from '../../testReportConfiguration.schemas';
import { PrecisionType, ReportResultType, ReportResultTypeForm } from '../../testReportConfiguration.enums';
import SpecimenEditForm from './SpecimenEditForm';
import { useTest } from '../../../Tests/tests.hooks';
import {
	specimenParamsConverter,
	specimenReportListItemConverter,
	specimenTypeResponseReportItemConverter
} from '../../testReportConfiguration.converters';
import styles from './SpecimenReportConfigurationTable.module.scss';

export interface SpecimenReportConfigurationTableProps {
	values: ReportSpecimenParams;
	onSubmit: (data: ReportSpecimenParams) => void;
}

const editModalId = `${ReportResultType.SPECIMEN_RESULT}_EDIT`;

const SpecimenReportConfigurationTable: FC<SpecimenReportConfigurationTableProps> = ({ onSubmit, values }) => {
	const { testId } = useParams();
	const { testDto: test } = useTest(testId);
	const { t } = useTranslation('testConfiguration');
	const specimenForm = useForm<TestReportSpecimenFormListType>({
		resolver: yupResolver(specimenReportListSchema)
	});
	const { control, setValue, handleSubmit, watch, reset, getValues } = specimenForm;
	const { update } = useFieldArray({ control, name: 'specimenParamsResults' });
	const allSpecimenParams = useMemo<TestReportSpecimenFormType[]>(() => {
		if (!test?.specimenType) return [];
		return specimenTypeResponseReportItemConverter(test.specimenType);
	}, [test]);
	const specimenParams = watch('specimenParamsResults');

	const specimenEditForm = useForm<TestReportSpecimenEditFormType>({
		//@ts-ignore // TODO: fix this when implement report configuration
		resolver: yupResolver(specimenReportConfigurationSchema)
	});

	const { reset: resetSpecimen } = specimenEditForm;

	const handleSelection = (event: DataTableSelectionMultipleChangeEvent<DataTableValueArray>) => {
		const specimenParamsResults = getValues().specimenParamsResults;
		if (specimenParamsResults) {
			const newValues = generateTableData(specimenParamsResults, event.value as TestReportSpecimenFormType[]);
			setValue('specimenParamsResults', newValues);
		}
	};

	const handleOpenEditConfig = (rowData: TestReportSpecimenFormType) => {
		const index = allSpecimenParams.findIndex(({ name }) => name === rowData.name);
		resetSpecimen({ index, ...rowData });
		Modal.show(editModalId);
	};

	const handleCloseEditModal = useCallback(() => {
		Modal.hide(editModalId);
	}, []);

	const handleCloseModal = useCallback(() => {
		const specimenParamsResults = generateTableData(allSpecimenParams, specimenParamsConverter(values));
		reset({ specimenParamsResults });
		Modal.hide(ReportResultType.SPECIMEN_RESULT);
	}, [reset, values, allSpecimenParams]);

	const handleUpdateSpecimen = useCallback(
		(index: number, data: TestReportSpecimenFormType) => {
			update(index, data);
			handleCloseEditModal();
		},
		[update, handleCloseEditModal]
	);

	const handleUpdate = handleSubmit((data) => {
		const selectedValues = data.specimenParamsResults?.filter(({ isSelected }) => isSelected);
		if (selectedValues) {
			const value = specimenReportListItemConverter(selectedValues);
			onSubmit(value);
		}
	});

	const selectedRows = useMemo(() => {
		return specimenParams?.filter(({ isSelected }) => isSelected) || [];
	}, [specimenParams]);

	useEffect(() => {
		const newValues = generateTableData(allSpecimenParams, specimenParamsConverter(values));
		setValue('specimenParamsResults', newValues);
	}, [values, setValue, allSpecimenParams]);

	return (
		<>
			<Modal id={ReportResultType.SPECIMEN_RESULT} onClose={handleCloseModal} className={styles.modalCalculation}>
				<Modal.Header>{t('Select Specimen parameters to be Included in the Report')}</Modal.Header>
				<Modal.Content>
					<DataTable
						value={specimenParams}
						// loading={isLoading}
						// dataKey="id"
						showGridlines
						columnResizeMode="fit"
						selection={selectedRows}
						onSelectionChange={handleSelection}
						selectionMode="multiple"
					>
						<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
						<DataTable.Column header={t('Specimen Paratemer')} field="name" resizeable />
						<DataTable.Column
							header={t('Precision Type')}
							body={({ precisionType }) => PrecisionType[precisionType]}
							resizeable
						/>
						<DataTable.Column
							className={styles.lastCell}
							header=""
							body={(rowData) => {
								return (
									<Button
										type="button"
										icon="t-icon-edit"
										variant="text"
										onClick={() => {
											handleOpenEditConfig(rowData);
										}}
									/>
								);
							}}
						/>
					</DataTable>
				</Modal.Content>
				<Modal.Footer>
					<Button type="button" variant="text" color="success" onClick={handleCloseModal}>
						{t('Cancel')}
					</Button>
					<Button type="button" label={t('Save')} onClick={handleUpdate} />
				</Modal.Footer>
			</Modal>
			<Modal id={editModalId} onClose={handleCloseEditModal} className={styles.modalConfigure}>
				<Modal.Header>{t('Configure Specimen Parameters ')}</Modal.Header>
				<Modal.Content>
					<SpecimenEditForm form={specimenEditForm} onSubmit={handleUpdateSpecimen} />
				</Modal.Content>
				<Modal.Footer>
					<Button type="button" variant="text" color="success" onClick={handleCloseEditModal}>
						{t('Cancel')}
					</Button>
					<Button type="submit" label={t('Save')} form={ReportResultTypeForm.SPECIMEN_RESULT} />
				</Modal.Footer>
			</Modal>
		</>
	);
};

const generateTableData = (
	allSpecimenParams: TestReportSpecimenFormType[],
	selectedSpecimenTypes: TestReportSpecimenFormType[]
) => {
	return allSpecimenParams?.map((item) => {
		const selectedItem = selectedSpecimenTypes.find(({ name }) => name === item.name);
		if (selectedItem) {
			return {
				...selectedItem,
				isSelected: true
			};
		}

		return { ...item, isSelected: false };
	});
};

export default SpecimenReportConfigurationTable;
