import { FC, useEffect, useMemo } from 'react';
import { UseFormReturn, Controller } from 'react-hook-form';
import { Form, Dropdown, InputNumber, Switch, InputText, GroupInputFrame } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import DataChannel, { DataChannelType } from '../../../DataChannel';
import { GraphTypesOptions } from '../../testDashboardConfiguration.constants';
import { useQuantities } from '../../../Units';
import { useEntitiesAsList } from '../../../../hooks';
import { MeasurementResponseDto } from '../../../Measurements';
import { IAxisResponseDto } from '../../../Axes';
import { GraphFormType } from '../../../GraphWidget/graphWidget.types';
import { ScaleTypeOptions } from '../../../GraphWidget/graphWidget.constants';
import styles from './GraphForm.module.scss';

interface GraphFormProps {
	form: UseFormReturn<GraphFormType>;
	measurements: MeasurementResponseDto[];
	axes: IAxisResponseDto[];
	isAdvanced?: boolean;
	isType?: boolean;
}

const formId = 'graphForm';

const GraphForm: FC<GraphFormProps> = ({ form, isAdvanced = false, measurements, axes, isType }) => {
	const {
		register,
		control,
		formState: { errors },
		watch,
		setValue,
		trigger
	} = form;
	const { t } = useTranslation('dashboardConfiguration');
	const { quantities } = useQuantities('TIME');
	const timeUnitsOptions = useEntitiesAsList(quantities?.length ? quantities[0].units : []);
	const limitLines = watch('limitLines');
	const dcX = watch('dcX');
	const min = watch('min');
	const max = watch('max');
	const cmin = watch('cmin');
	const cmax = watch('cmax');
	const xOffset = watch('xOffset');
	const autoScaleX = watch('autoScaleX');
	const timeUnitId = watch('timeUnitId');

	const xOffsetUnit = useMemo(() => {
		switch (dcX[0]?.type) {
			case DataChannelType.TIME:
				return timeUnitsOptions.find((u) => u.value === timeUnitId)?.label;
			case DataChannelType.MEASUREMENT:
				const mes = measurements.find((m) => m.id === dcX[0]?.id);
				return mes?.unit.name;
			case DataChannelType.SET_POINT:
			case DataChannelType.COMP_SET_POINT:
				const axis = axes.find((a) => a.id === dcX[0]?.id);
				return axis?.actuator.calibrationResponseDto?.unit?.name;
			case DataChannelType.CYCLE:
				return 'cycles';
			default:
				return undefined;
		}
	}, [axes, dcX, measurements, timeUnitId, timeUnitsOptions]);

	useEffect(() => {
		if (cmin !== null && cmin !== undefined && cmax !== null && cmax !== undefined) {
			trigger('cmin');
			trigger('cmax');
		}
	}, [cmin, cmax, trigger]);

	useEffect(() => {
		if (min !== null && min !== undefined && max !== null && max !== undefined) {
			trigger('min');
			trigger('max');
		}
	}, [min, max, trigger]);

	useEffect(() => {
		if (!autoScaleX) {
			setValue('scaleType', null);
		}
	}, [autoScaleX, setValue]);

	useEffect(() => {
		if (!xOffset) {
			setValue('offset', null);
		}
	}, [autoScaleX, setValue, xOffset]);

	return (
		<Form className={styles.container} id={formId}>
			<input type="hidden" {...register('id')} />
			<input type="hidden" {...register('testId')} />
			{isAdvanced && (
				<div className={`${styles.section1} ${styles.section}`}>
					<Controller
						name="name"
						control={control}
						render={({ field }) => <InputText {...field} label={t('Name*')} error={errors.name?.message} />}
					/>
					<Controller
						name="graphType"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								label={t('Graph Type*')}
								options={GraphTypesOptions}
								error={errors.graphType?.message}
							/>
						)}
					/>
					<GroupInputFrame>
						<Controller
							name="rate"
							control={control}
							render={({ field }) => (
								<InputNumber {...field} label={t('Date rate*')} error={errors.rate?.message} data-testid="rate" />
							)}
						/>
						<InputText disabled value={'S/sec'} />
					</GroupInputFrame>

					<Controller
						name="timeUnitId"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								label={t('Time unit*')}
								options={timeUnitsOptions}
								error={errors.timeUnitId?.message}
							/>
						)}
					/>
				</div>
			)}
			{!isType && (
				<div className={`${styles.section2} ${styles.section}`}>
					<h3 className={styles.sectionTitle}>Y-axis Settings</h3>

					<div className={`${styles.sectionContent} ${styles.yaxisSettings}`}>
						<Controller
							name="dcY1"
							control={control}
							render={({ field }) => (
								<DataChannel
									{...field}
									isMultiply
									showClear
									label={t('Y-axis 1 Data channels*')}
									measurements={measurements}
									removeTime
									axes={axes}
									error={errors.dcY1?.message}
								/>
							)}
						/>
						<Controller
							name="dcY2"
							control={control}
							render={({ field }) => (
								<DataChannel
									{...field}
									isMultiply
									showClear
									label={t('Y-axis 2 Data channels')}
									removeTime
									measurements={measurements}
									axes={axes}
									error={errors.dcY2?.message}
								/>
							)}
						/>
						<Controller
							name="autoScaleY"
							control={control}
							render={({ field }) => (
								<Switch onChange={(e) => field.onChange(e.value)} label={t('Autoscale Y')} checked={!!field.value} />
							)}
						/>
						<Controller
							name="limitLines"
							control={control}
							render={({ field }) => (
								<Switch onChange={(e) => field.onChange(e.value)} label={t('Limit lines')} checked={!!field.value} />
							)}
						/>
						<GroupInputFrame>
							<Controller
								name="min"
								control={control}
								render={({ field }) => (
									<InputNumber
										{...field}
										label={t('Minimum*')}
										error={errors.min?.message}
										data-testid="min"
										disabled={!limitLines}
									/>
								)}
							/>
							<InputText disabled value={'SI'} />
						</GroupInputFrame>
						<GroupInputFrame>
							<Controller
								name="max"
								control={control}
								render={({ field }) => (
									<InputNumber
										{...field}
										label={t('Maximum*')}
										error={errors.max?.message}
										data-testid="max"
										disabled={!limitLines}
									/>
								)}
							/>
							<InputText disabled value={'SI'} />
						</GroupInputFrame>
						<GroupInputFrame>
							<Controller
								name="cmin"
								control={control}
								render={({ field }) => (
									<InputNumber
										{...field}
										label={t('Critical Minimum*')}
										error={errors.cmin?.message}
										data-testid="cmin"
										disabled={!limitLines}
									/>
								)}
							/>
							<InputText disabled value={'SI'} />
						</GroupInputFrame>
						<GroupInputFrame>
							<Controller
								name="cmax"
								control={control}
								render={({ field }) => (
									<InputNumber
										{...field}
										label={t('Critical Maximum*')}
										error={errors.cmax?.message}
										data-testid="cmax"
										disabled={!limitLines}
									/>
								)}
							/>
							<InputText disabled value={'SI'} />
						</GroupInputFrame>
					</div>
				</div>
			)}
			<div className={`${styles.section3} ${styles.section}`}>
				<h3 className={styles.sectionTitle}>X-axis Settings</h3>
				<div className={`${styles.sectionContent} ${styles.yaxisSettings}`}>
					{isType ? (
						<div />
					) : (
						<Controller
							name="dcX"
							control={control}
							render={({ field }) => (
								<DataChannel
									{...field}
									showClear
									label={t('Data channel*')}
									measurements={measurements}
									axes={axes}
									error={errors.dcY1?.message}
								/>
							)}
						/>
					)}
					<GroupInputFrame>
						<Controller
							name="timeWndSize"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Time window size')}
									error={errors.timeWndSize?.message}
									data-testid="timeWndSize"
									isInteger
								/>
							)}
						/>
						<InputText disabled value={'sec'} />
					</GroupInputFrame>

					<Controller
						name="autoScaleX"
						control={control}
						render={({ field }) => (
							<Switch onChange={(e) => field.onChange(e.value)} label={t('Autoscale X')} checked={!!field.value} />
						)}
					/>
					<Controller
						name="scaleType"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								label={t('X axis scale type*')}
								options={ScaleTypeOptions}
								error={errors.scaleType?.message}
								disabled={!autoScaleX}
							/>
						)}
					/>
					<Controller
						name="xOffset"
						control={control}
						render={({ field }) => (
							<Switch onChange={(e) => field.onChange(e.value)} label={t('X-axis offset')} checked={!!field.value} />
						)}
					/>
					<GroupInputFrame>
						<Controller
							name="offset"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('X axis offset value')}
									error={errors.offset?.message}
									data-testid="offset"
									disabled={!xOffset}
								/>
							)}
						/>
						<InputText disabled value={xOffsetUnit} />
					</GroupInputFrame>
				</div>
			</div>
		</Form>
	);
};

export default GraphForm;
