import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@tactun/ui';
import { DataTable, PageLayout } from '@tactun/ui';
import { WidgetsListItem } from '../../widgetsConfiguration.types';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { WidgetCrudActionTypes } from '../../widgetsConfiguration.enums';
import { useTableConfigs } from '../../../../hooks';

interface WidgetsConfigurationTableProps {
	onAction: (type: WidgetCrudActionTypes, data?: WidgetsListItem) => void;
	data?: WidgetsListItem[];
	isLoading: boolean;
	stationName: string;
}

const WidgetsConfigurationTable: FC<WidgetsConfigurationTableProps> = ({ data, isLoading, stationName, onAction }) => {
	const { t } = useTranslation('widgets');
	const transColumns = useMemo<ITableColumns[]>(() => {
		return [
			{ field: 'type', header: t('Widget type') },
			{ field: 'status', header: t('Status') }
		];
	}, [t]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<WidgetsListItem>(transColumns, data);
	const handleCreate = useCallback(() => {
		onAction(WidgetCrudActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={stationName}>
			<PageLayout.Header title={t('Widgets')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('Widget')} isCreateDisabled />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				value={data}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Widget name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(WidgetCrudActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default WidgetsConfigurationTable;
