export const enum CalculatorValueTypes {
	DIGIT = 1,
	DECIMAL_POINT,
	OPERATOR,
	OPEN_PARENTHESIS,
	CLOSE_PARENTHESIS,
	NEGATIVE_SIGN,
	FUNCTION,
	CONST,
	VARIABLE,
	NUMBER
}

export enum CalculatorDigitValues {
	'ZERO' = '0',
	'ONE' = '1',
	'TWO' = '2',
	'THREE' = '3',
	'FOUR' = '4',
	'FIVE' = '5',
	'SIX' = '6',
	'SEVEN' = '7',
	'EIGHT' = '8',
	'NINE' = '9'
}

export enum CalculatorOperatorValues {
	'PLUS' = '+',
	'MINUS' = '-',
	'DIVIDE' = '/',
	'MULTIPLY' = '*',
	'SQR' = '^2',
	'POWER' = '^'
}

export enum CalculatorFunctionValuesUserFriendly {
	'math::sqrt' = 'sqrt',
	'math::tan' = 'tan',
	'math::atan' = 'atan',
	'math::sin' = 'sin',
	'math::asin' = 'asin',
	'math::cos' = 'cos',
	'math::acos' = 'acos',
	'ABS' = 'abs',
	'math::log10' = 'log',
	'math::ln' = 'ln',
	'math::exp' = 'exp'
}

export enum CalculatorFunctionValues {
	'SQRT' = 'math::sqrt',
	'TAN' = 'math::tan',
	'ATAN' = 'math::atan',
	'SIN' = 'math::sin',
	'ASIN' = 'math::asin',
	'COS' = 'math::cos',
	'ACOS' = 'math::acos',
	'ABS' = 'abs',
	'LOG' = 'math::log10',
	'LN' = 'math::ln',
	'EXP' = 'math::exp'
}

export enum CalculatorConstValues {
	'PI' = 'pi',
	'E' = 'e'
}
