import {
	ConditionsAuxDeviceProperties,
	ConditionsConnectionProperties,
	ConditionsDigitalInputProperties,
	ConditionsObjectTypes,
	ConditionsStationProperties,
	ConditionsTestProperties,
	ConditionsUIButtonProperties
} from './conditions.enums';

export const stationConditions = {
	[ConditionsObjectTypes.DIGITAL_INPUT]: [ConditionsDigitalInputProperties.ON, ConditionsDigitalInputProperties.OFF],
	[ConditionsObjectTypes.STATION]: [
		ConditionsStationProperties.IN_TEST,
		ConditionsStationProperties.IDLE,
		ConditionsStationProperties.ACTIVE,
		ConditionsStationProperties.SAFE
	],
	[ConditionsObjectTypes.TEST]: [ConditionsTestProperties.IN_PROGRESS, ConditionsTestProperties.PAUSED],
	[ConditionsObjectTypes.CONNECTION]: [
		ConditionsConnectionProperties.CONNECTED,
		ConditionsConnectionProperties.DISCONNECTED
	],
	[ConditionsObjectTypes.AUX_DEVICE]: [ConditionsAuxDeviceProperties.ON, ConditionsAuxDeviceProperties.OFF],
	[ConditionsObjectTypes.MEASUREMENT]: []
};

export const buttonConditions = {
	...stationConditions,
	[ConditionsObjectTypes.UI_BUTTON]: [
		ConditionsUIButtonProperties.PRESSED,
		ConditionsUIButtonProperties.RELEASED,
		ConditionsUIButtonProperties.DISABLED
	]
};

export const testGlobalConditions = {
	[ConditionsObjectTypes.DIGITAL_INPUT]: [ConditionsDigitalInputProperties.ON, ConditionsDigitalInputProperties.OFF],
	[ConditionsObjectTypes.CONNECTION]: [
		ConditionsConnectionProperties.CONNECTED,
		ConditionsConnectionProperties.DISCONNECTED
	],
	[ConditionsObjectTypes.AUX_DEVICE]: [ConditionsAuxDeviceProperties.ON, ConditionsAuxDeviceProperties.OFF],
	[ConditionsObjectTypes.MEASUREMENT]: []
};

export const preTestConditions = {
	[ConditionsObjectTypes.DIGITAL_INPUT]: [ConditionsDigitalInputProperties.ON, ConditionsDigitalInputProperties.OFF],
	[ConditionsObjectTypes.AUX_DEVICE]: [ConditionsAuxDeviceProperties.ON, ConditionsAuxDeviceProperties.OFF],
	[ConditionsObjectTypes.MEASUREMENT]: []
};

export const postTestConditions = {
	[ConditionsObjectTypes.DIGITAL_INPUT]: [ConditionsDigitalInputProperties.ON, ConditionsDigitalInputProperties.OFF],
	[ConditionsObjectTypes.AUX_DEVICE]: [ConditionsAuxDeviceProperties.ON, ConditionsAuxDeviceProperties.OFF],
	[ConditionsObjectTypes.MEASUREMENT]: []
};

export const preStageConditions = {
	[ConditionsObjectTypes.DIGITAL_INPUT]: [ConditionsDigitalInputProperties.ON, ConditionsDigitalInputProperties.OFF],
	[ConditionsObjectTypes.AUX_DEVICE]: [ConditionsAuxDeviceProperties.ON, ConditionsAuxDeviceProperties.OFF],
	[ConditionsObjectTypes.MEASUREMENT]: []
};

export const postStageConditions = {
	[ConditionsObjectTypes.DIGITAL_INPUT]: [ConditionsDigitalInputProperties.ON, ConditionsDigitalInputProperties.OFF],
	[ConditionsObjectTypes.AUX_DEVICE]: [ConditionsAuxDeviceProperties.ON, ConditionsAuxDeviceProperties.OFF],
	[ConditionsObjectTypes.MEASUREMENT]: [],
	[ConditionsObjectTypes.CALCULATION]: []
};
