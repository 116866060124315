import { Form, InputText } from '@tactun/ui';
import React, { FC, useMemo } from 'react';
import { Controller, useController, UseFormReturn } from 'react-hook-form';
import { ActionOptions, RuleAction } from '../../actions.types';
import { useTranslation } from 'react-i18next';
import styles from './MessageBoxWithConfirmationActionForm.module.scss';
import Actions from '../Actions';
import { MeasurementResponseDto } from '../../../Measurements';
import { IStationChannelResponseDto } from '../../../StationChannels';
import { ControlChannelResponseDto } from '../../../ControlChannels';
import { VariableResponseDto } from '../../../Variables';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';
import { IAxisResponseDto } from '../../../Axes';
import { ActionObjectTypes } from '../../actions.enums';

export interface MessageBoxWithConfirmationActionFormProps {
	form: UseFormReturn<RuleAction>;
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	digitalChannels: IStationChannelResponseDto[];
	controlChannels: ControlChannelResponseDto[];
	variables: VariableResponseDto[];
	actionOptions: ActionOptions;
	axes: IAxisResponseDto[];
}

const MessageBoxWithConfirmationActionForm: FC<MessageBoxWithConfirmationActionFormProps> = ({
	form,
	measurements,
	digitalChannels,
	controlChannels,
	variables,
	axes,
	actionOptions
}) => {
	const { t } = useTranslation();
	const { control } = form;

	const { field: isParallel } = useController({ control, name: 'isParallel' });
	const { field: okActions } = useController({ control, name: 'msgOKActions' });
	const { field: cancelActions } = useController({ control, name: 'msgCancelActions' });
	const filteredOptions = useMemo(() => {
		const cloneActionOptions = { ...actionOptions };
		delete cloneActionOptions[ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION];
		return cloneActionOptions;
	}, [actionOptions]);

	return (
		<>
			<div />
			<Form.Row className={styles.messageBoxConfirmationMessage}>
				<Controller
					name="message"
					control={control}
					render={({ field }) => (
						<>
							<InputText textarea label={t('Message')} {...field} className={styles.messageTextArea} />
							<div className={styles.countDown}>1000</div>
						</>
					)}
				/>
			</Form.Row>
			<div />
			<Form.Row>
				<div className={styles.messageBoxConfirmationActions}>
					<Actions
						title={t('When “Ok” is pressed, then do the following actions:')}
						actions={okActions.value || []}
						updateActions={(e) => okActions.onChange(e)}
						//isParallel={isParallel.value || false}
						isParallel
						setIsParallel={(e) => isParallel.onChange(e)}
						measurements={measurements || []}
						digitalChannels={digitalChannels}
						controlChannels={controlChannels}
						dragType="if-actions-cards"
						variables={variables}
						axes={axes}
						actionOptions={filteredOptions}
						id="message-box-actions"
					/>
					<Actions
						title={t('When “Cancel” is pressed, then do the following actions:')}
						actions={cancelActions.value || []}
						updateActions={(e) => cancelActions.onChange(e)}
						//isParallel={isParallel.value || false}
						isParallel
						setIsParallel={(e) => isParallel.onChange(e)}
						measurements={measurements || []}
						digitalChannels={digitalChannels}
						controlChannels={controlChannels}
						dragType="if-actions-cards"
						variables={variables}
						axes={axes}
						actionOptions={filteredOptions}
						id="message-box-actions"
					/>
				</div>
			</Form.Row>
		</>
	);
};

export default MessageBoxWithConfirmationActionForm;
