export enum GroupActionTypes {
	CREATE_GROUP = 'CREATE_GROUP',
	REMOVE_FROM_GROUP = 'REMOVE_FROM_GROUP',
	ADD_TO_GROUP = 'ADD_TO_GROUP',
	RENAME_GROUP = 'RENAME_GROUP',
	BATCH_UPDATE_GROUPS = 'BATCH_UPDATE_GROUPS',
	DELETE_GROUP = 'DELETE_GROUP'
}

export enum GroupsTypes {
	UNIT = 'UNIT',
	SENSOR = 'SENSOR',
	AXIS = 'AXIS',
	STATION = 'STATION',
	STATION_CHANNEL = 'STATION_CHANNEL',
	MEASUREMENT = 'MEASUREMENT',
	ACTUATOR = 'ACTUATOR',
	CONTROL_CHANNEL = 'CONTROL_CHANNEL',
	ALGORITHM = 'ALGORITHM',
	WAVEFORM_RAMP = 'WAVEFORM_RAMP',
	VARIABLE = 'VARIABLE',
	SPECIMEN_TYPE = 'SPECIMEN_TYPE'
}
