export enum SensorActionTypes {
	EDIT = 'EDIT_SENSOR',
	CREATE = 'CREATE_SENSOR',
	DUPLICATE = 'DUPLICATE_SENSOR',
	DELETE = 'DELETE_SENSOR',
	OPEN_CALIBRATION = 'OPEN_CALIBRATION'
}

export enum SensorsTypes {
	DIFFERENTIAL_ENCODER = 0,
	SINGLE_ENDED_ENCODER = 1,
	LOAD_CELL = 2,
	LVDT = 5,
	GENERIC_SENSOR = 6
}

export enum CalibrationStatus {
	UNCALIBRATED = 'UNCALIBRATED',
	CALIBRATED = 'CALIBRATED',
	ERROR = 'ERROR'
}
