import { StationRuleResponseDto } from './../Rules/rules.types';
import { AxisInfo, DataLogParams, StartTestRequest, TestAxis, TestStage, TestStages } from '@tactun/grpc-client';
import { Converter } from '../../types';
import { ITestFullResponseDto, TestStageFullResponseDto } from '../Tests';
import { ControlChannelSetPointType } from '../ControlChannels';
import { waveformFormToGRPCRequestConverter, waveformResponseToFormConverter } from '../Waveforms';
import { rulesResponseToGRPCRequestConverter } from '../Rules';
import { testDataLoggingResponseDtoListToAxisLogGRPCConverter } from '../TestDataLogging/testDataLogging.converters';
import { testLoopResponseDtoListToLoopsGRPCConverter } from '../TestLoops/testLoops.converters';
import { measurementResponsesToMeasurementGRPCsConverter } from '../Measurements';
import { variableResponsesToVariableGRPCsConverter } from '../Variables';
import { specimenResponseDtoToSpecimenGRPCConverter } from '../Specimens/specimens.converters';
import {
	controlAlgorithmsFormTypeToAxisInfo,
	responseControlAlgorithmsFormConverter
} from '../ControlAlgorithms/controlAlgorithms.converters';
import { ActionDtoObjectTypes } from '../Actions';
import { CalculationTypes } from '../Calculations';
import { calculationActionsResponseToGRPCRequestConverter } from '../Calculations/calculation.converters';
import { ActionGroupDto } from '../TestActions/testActions.types';

export const stageToTestStageGrpcConverter: Converter<TestStageFullResponseDto, TestStage> = (resp) => {
	const stage = new TestStage();
	stage.setId(resp.id);
	stage.setName(resp.name);

	if (resp.mainStageRules?.length) {
		stage.setRulesList(rulesResponseToGRPCRequestConverter(resp.mainStageRules as unknown as StationRuleResponseDto[]));
	}

	// TODO change this when device implements all calculation types.
	const deviceImplementedCalculculations = resp.mainStageActions?.[0].actions.filter((conditionAction) => {
		const action = conditionAction.rulePositiveActions?.[0];
		return (
			action?.type === ActionDtoObjectTypes.CALCULATION &&
			action.calculation?.type !== undefined &&
			[
				CalculationTypes.MEASUREMENT_AT_PEAK_LOAD,
				CalculationTypes.MEAN,
				CalculationTypes.MAXIMUM,
				CalculationTypes.MINIMUM
			].includes(action.calculation?.type)
		);
	});

	if (deviceImplementedCalculculations?.length) {
		stage.setCalculationsList(calculationActionsResponseToGRPCRequestConverter(deviceImplementedCalculculations));
	}
	if (resp.preStageActions?.length) {
		const preStageActions = actionGroupDtoListToStationRuleResponseDtoList(resp.preStageActions);

		if (preStageActions.length > 0) {
			stage.setPreActionsList(rulesResponseToGRPCRequestConverter(preStageActions));
		}
	}
	if (resp.postStageActions?.length) {
		const postStageActions = actionGroupDtoListToStationRuleResponseDtoList(resp.postStageActions);

		if (postStageActions.length > 0) {
			stage.setPostActionsList(rulesResponseToGRPCRequestConverter(postStageActions));
		}
	}
	if (resp.logs?.length) {
		stage.setLogsList(testDataLoggingResponseDtoListToAxisLogGRPCConverter(resp.logs));
	}
	if (resp?.axesConfigs?.length) {
		stage.setAxesList(
			resp.axesConfigs.map((ac) => {
				const testAxis = new TestAxis();
				testAxis.setAxisId(ac.axis.id);
				testAxis.setComplStageOnAxisCompl(ac.stageCompleteFlag);

				const axisInfo = new AxisInfo();

				axisInfo.setAxisId(ac.axis.id);
				axisInfo.setAxisIndex(ac.axis.axisIndex);
				if (ac.cc.setPointMeasurement === undefined) {
					axisInfo.setSpType(ac.cc.setPointType as ControlChannelSetPointType);
				} else {
					axisInfo.setSpMeasurement(ac.cc.setPointMeasurement.id);
				}
				if (ac.cc.feedbackMeasurement) {
					axisInfo.setFbMeasurement(ac.cc.feedbackMeasurement.id);
				}

				axisInfo.setWfProps(waveformFormToGRPCRequestConverter(waveformResponseToFormConverter(ac.wf)));

				if (ac.useStationParams) {
					const controlAlgorithmsForm = responseControlAlgorithmsFormConverter(ac.cc.controlAlgorithm);
					controlAlgorithmsFormTypeToAxisInfo(controlAlgorithmsForm, axisInfo);
				} else if (ac.controlAlgorithm) {
					const controlAlgorithmsForm = responseControlAlgorithmsFormConverter(ac.controlAlgorithm);
					controlAlgorithmsFormTypeToAxisInfo(controlAlgorithmsForm, axisInfo);
				}

				testAxis.setAxes(axisInfo);

				return testAxis;
			})
		);
	}

	return stage;
};

export const testInfoToStartTestRequestConverter: Converter<ITestFullResponseDto, StartTestRequest> = (test) => {
	const startRequest = new StartTestRequest();

	startRequest.setId(test.id);
	startRequest.setName(test.name);
	startRequest.setStd(test.std);
	startRequest.setAxisIdsList(test.axes.map((axis) => axis.id));
	startRequest.setTimeUnitName(test.timeUnit.name);
	startRequest.setStationId(test.stationId);

	const logParams = new DataLogParams();
	logParams.setLogPath(test.std);
	logParams.setDsFullBh(test.dsFullBh);
	logParams.setDsType(test.dsType);
	startRequest.setLogParams(logParams);

	if (test.vars?.length) {
		startRequest.setVarsList(variableResponsesToVariableGRPCsConverter(test.vars));
	}

	if (test.rules?.length) {
		startRequest.setRulesList(rulesResponseToGRPCRequestConverter(test.rules as unknown as StationRuleResponseDto[]));
	}

	if (test.measurements?.length) {
		startRequest.setMeasurementsList(measurementResponsesToMeasurementGRPCsConverter(test.measurements));
	}

	if (test.specimen) {
		startRequest.setSpecimen(specimenResponseDtoToSpecimenGRPCConverter(test.specimen));
	}

	const testStages = new TestStages();

	// Add temp pre stage
	if (test.preStage) {
		const preStage = new TestStage();
		preStage.setId(test.preStage.id);

		if (test.preStage?.mainStageActions?.length) {
			const pretStageActions = actionGroupDtoListToStationRuleResponseDtoList(test.preStage?.mainStageActions);

			if (pretStageActions.length > 0) {
				preStage.setPreActionsList(rulesResponseToGRPCRequestConverter(pretStageActions));
			}
		}

		if (test.preStage?.logs?.length) {
			preStage.setLogsList(testDataLoggingResponseDtoListToAxisLogGRPCConverter(test.preStage?.logs));
		}

		testStages.addStages(preStage);
	}

	if (test.stages?.length) {
		test.stages.forEach((s) => testStages.addStages(stageToTestStageGrpcConverter(s)));

		const level1 = test.loops?.filter((l) => l.level === 1);
		const level2 = test.loops?.filter((l) => l.level === 2);

		if (level1?.length) {
			testStages.setLevel1LoopsList(testLoopResponseDtoListToLoopsGRPCConverter(level1));
		}
		if (level2?.length) {
			testStages.setLevel2LoopsList(testLoopResponseDtoListToLoopsGRPCConverter(level2));
		}

		startRequest.setSequences(testStages);
	}

	if (test.postStage) {
		const postStage = new TestStage();
		postStage.setId(test.postStage.id);

		if (test.postStage?.mainStageActions?.length) {
			const postStageActions = actionGroupDtoListToStationRuleResponseDtoList(test.postStage?.mainStageActions);

			if (postStageActions.length > 0) {
				postStage.setPostActionsList(rulesResponseToGRPCRequestConverter(postStageActions));
			}
		}

		if (test.postStage?.logs?.length) {
			postStage.setLogsList(testDataLoggingResponseDtoListToAxisLogGRPCConverter(test.postStage?.logs));
		}
		testStages.addStages(postStage);
	}

	return startRequest;
};

export const actionGroupDtoListToStationRuleResponseDtoList = (actions: ActionGroupDto[]): StationRuleResponseDto[] => {
	return actions.reduce<StationRuleResponseDto[]>((acc, current) => {
		acc.push(...(current.actions as StationRuleResponseDto[]));
		return acc;
	}, []);
};
