import React, { useCallback } from 'react';
import { Button, Modal, useModalData } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { testActionsTable, testActionsModalId } from '../../testActions.const';
import styles from './TestActionsModal.module.scss';
import { TestActionLocations } from '../../testActions.enums';
import { useIsMutating } from '@tanstack/react-query';

interface TestTestActionsModalProps {
	children: React.ReactNode;
}

const TestActionsModal: React.FC<TestTestActionsModalProps> = ({ children }) => {
	const { t } = useTranslation('testActions');

	const handleClose = useCallback(() => {
		Modal.hide(testActionsModalId);
	}, []);

	const modalData = useModalData(testActionsModalId);
	const enableCalculation =
		modalData?.location !== TestActionLocations.PRE_STAGE && modalData?.location !== TestActionLocations.POST_STAGE;
	const enableAction = modalData?.location !== TestActionLocations.STAGE;

	const isMutatingActions = useIsMutating({ mutationKey: ['test-stage-actions-update'] });

	return (
		<Modal id={testActionsModalId} className={styles.container} shouldCloseOnOverlayClick={false}>
			<Modal.Header>
				{enableAction && enableCalculation
					? t('Actions and Calculations')
					: enableAction
					? t('Actions')
					: t('Calculations')}
			</Modal.Header>
			<Modal.Content className={styles.content}>{children}</Modal.Content>
			<Modal.Footer>
				<Button onClick={handleClose} color="secondary" variant="contained">
					{t('Cancel')}
				</Button>
				<Button
					type="submit"
					color="secondary"
					variant="contained"
					form={testActionsTable}
					disabled={isMutatingActions > 0}
					loading={isMutatingActions > 0}
				>
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(TestActionsModal);
