import { Dropdown, Form } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { IListItem } from '../../../../types';
import styles from './SensorList.module.scss';

interface ISensorsListProps {
	sensors: IListItem[];
	sensorId: string | null;
	onSensorChange: (sensorId: string | null) => void;
}

const SensorList = ({ sensors, sensorId, onSensorChange }: ISensorsListProps) => {
	const { t } = useTranslation('calibrations');

	return (
		<Form className={styles.container}>
			<Dropdown
				options={sensors}
				label={t('Sensor*')}
				data-testid="sensorId"
				value={sensorId}
				onChange={(sensorId: any) => {
					onSensorChange(sensorId as string);
				}}
			/>
		</Form>
	);
};

export default SensorList;
