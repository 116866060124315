import React, { useCallback } from 'react';
import { InputText, RadioSelect, Switch } from '@tactun/ui';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WidgetControlAxisForm } from '../../widgetsConfiguration.types';
import Actions, { RuleAction } from '../../../Actions';
import { axisOnOffActions, controlAxisButtonActions } from '../../../Actions/actions.const';
import { useControlChannels } from '../../../ControlChannels';
import styles from './ButtonConfigForm.module.scss';
import { ButtonStates, LinkStates } from '../../widgetsConfiguration.enums';

interface ControlAxisFormProps {
	form: UseFormReturn<WidgetControlAxisForm>;
	index: number;
}

const ButtonConfigForm: React.FC<ControlAxisFormProps> = ({ form, index }) => {
	const { t } = useTranslation();
	const { control, watch, setValue } = form;
	const stationId = watch('stationId');
	const { controlChannels } = useControlChannels(stationId);
	const { fields: buttons } = useFieldArray({ name: `controlAxis.${index}.buttonConfigs`, control });

	const hasFirstStageEnableBit = !!watch(`controlAxis.${index}.firstStageEnableBit`);
	const hasSecondStageEnableBit = !!watch(`controlAxis.${index}.secondStageEnableBit`);

	const updateActions = useCallback(
		(actions: RuleAction[], buttonIndex: number, state: ButtonStates) => {
			if (state === ButtonStates.PRESSED) {
				setValue(`controlAxis.${index}.buttonConfigs.${buttonIndex}.linkedActionsForPressedState`, actions);
			} else {
				setValue(`controlAxis.${index}.buttonConfigs.${buttonIndex}.linkedActionsForReleasedState`, actions);
			}
		},
		[index, setValue]
	);

	return (
		<Accordion multiple className={styles.accordion}>
			<AccordionTab header={t('Axis "On / Off" Buttons Configuration')}>
				{hasFirstStageEnableBit && (
					<>
						<div className={styles.bitWrapper}>
							<Controller
								name={`controlAxis.${index}.firstStageEnableBit.stageEnableBit.enable`}
								control={control}
								render={({ field }) => (
									<Switch
										label={t('First stage enable bit')}
										inputId={field.name}
										onChange={(e) => field.onChange(e.value)}
										checked={!!field.value}
									/>
								)}
							/>
							<Controller
								name={`controlAxis.${index}.firstStageEnableBit.stageEnableBit.buttonNameForOnState`}
								control={control}
								render={({ field }) => (
									<InputText className={styles.axisInput} placeholder={t('Button name for "on" state')} {...field} />
								)}
							/>
							<Controller
								name={`controlAxis.${index}.firstStageEnableBit.stageEnableBit.buttonNameForOffState`}
								control={control}
								render={({ field }) => (
									<InputText className={styles.axisInput} placeholder={t('Button name for "off" state')} {...field} />
								)}
							/>
							<Controller
								name={`controlAxis.${index}.firstStageEnableBit.stageEnableBit.readOnly`}
								control={control}
								render={({ field }) => (
									<Switch
										label={t('Read-only')}
										inputId={field.name}
										onChange={(e) => field.onChange(e.value)}
										checked={!!field.value}
									/>
								)}
							/>
						</div>
					</>
				)}
				{hasSecondStageEnableBit && (
					<>
						<div className={styles.bitWrapper}>
							<Controller
								name={`controlAxis.${index}.secondStageEnableBit.stageEnableBit.enable`}
								control={control}
								render={({ field }) => (
									<Switch
										label={t('Second stage enable bit')}
										inputId={field.name}
										onChange={(e) => field.onChange(e.value)}
										checked={!!field.value}
									/>
								)}
							/>
							<Controller
								name={`controlAxis.${index}.secondStageEnableBit.stageEnableBit.buttonNameForOnState`}
								control={control}
								render={({ field }) => (
									<InputText className={styles.axisInput} placeholder={t('Button name for "on" state')} {...field} />
								)}
							/>
							<Controller
								name={`controlAxis.${index}.secondStageEnableBit.stageEnableBit.buttonNameForOffState`}
								control={control}
								render={({ field }) => (
									<InputText className={styles.axisInput} placeholder={t('Button name for "off" state')} {...field} />
								)}
							/>
							<Controller
								name={`controlAxis.${index}.secondStageEnableBit.stageEnableBit.readOnly`}
								control={control}
								render={({ field }) => (
									<Switch
										label={t('Read-only')}
										inputId={field.name}
										onChange={(e) => field.onChange(e.value)}
										checked={!!field.value}
									/>
								)}
							/>
						</div>
					</>
				)}
				{hasFirstStageEnableBit && (
					<>
						<Actions
							title={t('First stage actions for "On" state:')}
							actions={watch(`controlAxis.${index}.firstStageEnableBit.stageActionsForOnStage`) || []}
							updateActions={(actions) =>
								setValue(`controlAxis.${index}.firstStageEnableBit.stageActionsForOnStage`, actions)
							}
							isParallel
							measurements={[]}
							digitalChannels={[]}
							controlChannels={controlChannels || []}
							variables={[]}
							axes={[]}
							actionOptions={axisOnOffActions}
							dragType=""
						/>

						<Actions
							title={t('First stage actions for "Off" state:')}
							actions={watch(`controlAxis.${index}.firstStageEnableBit.stageActionsForOffStage`) || []}
							updateActions={(actions) =>
								setValue(`controlAxis.${index}.firstStageEnableBit.stageActionsForOffStage`, actions)
							}
							isParallel
							measurements={[]}
							digitalChannels={[]}
							controlChannels={controlChannels || []}
							variables={[]}
							axes={[]}
							actionOptions={axisOnOffActions}
							dragType=""
						/>
					</>
				)}
				{hasSecondStageEnableBit && (
					<>
						<Actions
							title={t('Second stage actions for "On" state:')}
							actions={watch(`controlAxis.${index}.secondStageEnableBit.stageActionsForOnStage`) || []}
							updateActions={(actions) =>
								setValue(`controlAxis.${index}.secondStageEnableBit.stageActionsForOnStage`, actions)
							}
							isParallel
							measurements={[]}
							digitalChannels={[]}
							controlChannels={controlChannels || []}
							variables={[]}
							axes={[]}
							actionOptions={axisOnOffActions}
							dragType=""
						/>

						<Actions
							title={t('Second stage actions for "Off" state:')}
							actions={watch(`controlAxis.${index}.secondStageEnableBit.stageActionsForOffStage`) || []}
							updateActions={(actions) =>
								setValue(`controlAxis.${index}.secondStageEnableBit.stageActionsForOffStage`, actions)
							}
							isParallel
							measurements={[]}
							digitalChannels={[]}
							controlChannels={controlChannels || []}
							variables={[]}
							axes={[]}
							actionOptions={axisOnOffActions}
							dragType=""
						/>
					</>
				)}
			</AccordionTab>
			{buttons.map((button, buttonIndex) => (
				<AccordionTab
					key={button.name}
					header={`${button.name} ${t('Configuration')}`}
					contentClassName={styles.accordionContent}
				>
					<div className={styles.wrapper}>
						<Controller
							name={`controlAxis.${index}.buttonConfigs.${buttonIndex}.enable`}
							control={control}
							render={({ field }) => (
								<Switch
									label={t('Enable')}
									inputId={field.name}
									onChange={(e) => field.onChange(e.value)}
									checked={!!field.value}
								/>
							)}
						/>
						<Actions
							title={t('Actions for "Pressed" state:')}
							actions={watch(`controlAxis.${index}.buttonConfigs.${buttonIndex}.linkedActionsForPressedState`) || []}
							updateActions={(actions) => updateActions(actions, buttonIndex, ButtonStates.PRESSED)}
							measurements={[]}
							isParallel
							digitalChannels={[]}
							controlChannels={controlChannels || []}
							variables={[]}
							axes={[]}
							actionOptions={controlAxisButtonActions}
							dragType=""
						/>
						<Actions
							title={t('Actions for "Released" state:')}
							actions={watch(`controlAxis.${index}.buttonConfigs.${buttonIndex}.linkedActionsForReleasedState`) || []}
							updateActions={(actions) => updateActions(actions, buttonIndex, ButtonStates.RELEASED)}
							measurements={[]}
							isParallel
							digitalChannels={[]}
							controlChannels={controlChannels || []}
							variables={[]}
							axes={[]}
							actionOptions={controlAxisButtonActions}
							dragType=""
						/>
					</div>
				</AccordionTab>
			))}

			{watch(`controlAxis.${index}.linkedUnlinkedButton`) && (
				<AccordionTab header={t('Linked / Unlinked Button Configuration')}>
					<Controller
						name={`controlAxis.${index}.linkedUnlinkedButton.enable`}
						control={control}
						render={({ field }) => (
							<Switch
								label={t('Enable')}
								inputId={field.name}
								onChange={(e) => field.onChange(e.value)}
								checked={!!field.value}
							/>
						)}
					/>
					<Controller
						name={`controlAxis.${index}.linkedUnlinkedButton.defaultState`}
						control={control}
						defaultValue={LinkStates.UNLINK}
						render={({ field }) => (
							<RadioSelect
								label={t('Default State')}
								options={[
									{ label: t('Link'), value: LinkStates.LINK },
									{
										label: t('Unlink'),
										value: LinkStates.UNLINK
									}
								]}
								{...field}
							/>
						)}
					/>
				</AccordionTab>
			)}
		</Accordion>
	);
};

export default ButtonConfigForm;
