import { useQuery } from '@tanstack/react-query';
import * as api from './buttonWidget.api';

export const useButtonWidgets = (stationId?: string) => {
	const { data, isLoading: isWidgetsLoading } = useQuery({
		queryKey: ['widgets', { stationId }],
		queryFn: () => api.getButtonWidgets(stationId as string),
		enabled: !!stationId
	});

	return {
		widgets: data,
		isWidgetsLoading
	};
};
