import axios from '../../httpCommon';
import {
	GraphRequestDto,
	GraphResponseDto,
	ReadoutRequestDto,
	ReadoutResponseDto
} from './testDashboardConfiguration.types';

export const getReadoutsForTest = async (testId?: string) => {
	const response = await axios.get(`/quasar/tests/${testId}/dashboard/readout`);

	return response.data as ReadoutResponseDto[];
};

export const getReadoutForTest = async (readoutId?: string) => {
	const response = await axios.get(`/quasar/tests/dashboard/readout/${readoutId}`);

	return response.data as ReadoutResponseDto;
};

export const createReadout = async (readout: ReadoutRequestDto) => {
	const response = await axios.post('/quasar/tests/dashboard/readout', readout);

	return response.data;
};

export const updateReadout = async (readout: ReadoutRequestDto, readoutId: string) => {
	const response = await axios.put(`/quasar/tests/dashboard/readout/${readoutId}`, readout);

	return response.data;
};

export const getGraphsForTest = async (testId?: string) => {
	const response = await axios.get(`/quasar/tests/${testId}/dashboard/graph`);

	return response.data as GraphResponseDto[];
};

export const getGraphForTest = async (graphId?: string) => {
	const response = await axios.get(`/quasar/tests/dashboard/graph/${graphId}`);

	return response.data as GraphResponseDto;
};

export const createGraph = async (graph: GraphRequestDto) => {
	const response = await axios.post('/quasar/tests/dashboard/graph', graph);

	return response.data;
};

export const updateGraph = async (graph: GraphRequestDto, graphId: string) => {
	const response = await axios.put(`/quasar/tests/dashboard/graph/${graphId}`, graph);

	return response.data;
};
