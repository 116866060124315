import { object, string, number, boolean, mixed, array } from 'yup';
import { emptyStringToNull } from '../../tools/yupHelpers';
import { PrecisionType, ReportFormat } from './testReportConfiguration.enums';

export const calculationReportConfigurationSchema = object({
	id: string().required(),
	// We cant changen name in calculation report configuration
	name: string().required(),
	isSelected: boolean().required(),
	includeSettings: boolean().required(),
	includeInStatistics: boolean().required(),
	precisionType: mixed<PrecisionType>(),
	precision: number()
		.transform(emptyStringToNull)
		.when(['precisionType'], {
			is: PrecisionType.SIGNIFICANT_DIGITS,
			then: (schema) => schema.min(1).required()
		})
		.when(['precisionType'], {
			is: PrecisionType.DECIMAL_PLACES,
			then: (schema) => schema.min(0).max(6).required()
		})
});

export const calculationReportEditConfigurationSchema = calculationReportConfigurationSchema.concat(
	object({
		index: number().required()
	})
);

export const calculationReportListSchema = object().shape({
	calculationResults: array().of(calculationReportConfigurationSchema)
});

export const specimenReportConfigurationSchema = object({
	// We cant changen name in calculation report configuration
	name: string().required(),
	precisionType: mixed<PrecisionType>(),
	isSelected: boolean().required(),
	precision: number()
		.transform(emptyStringToNull)
		.when(['precisionType'], {
			is: PrecisionType.SIGNIFICANT_DIGITS,
			then: (schema) => schema.min(1).required()
		})
		.when(['precisionType'], {
			is: PrecisionType.DECIMAL_PLACES,
			then: (schema) => schema.min(0).max(6).required()
		})
});

export const specimenReportEditConfigurationSchema = calculationReportConfigurationSchema.concat(
	object({
		index: number().required()
	})
);

export const specimenReportListSchema = object().shape({
	specimenParamsResults: array().of(specimenReportConfigurationSchema)
});

export const variableReportListSchema = object().shape({
	calculationResults: array().of(
		object({
			id: string().required(),
			name: string().required(),
			type: mixed<PrecisionType>()
		})
	)
});

export const testReportConfigurationSchema = object().shape({
	uuid: string(),
	additionalInformation: object({
		customerInformation: string(),
		includeBatch: boolean(),
		includeGraphData: boolean(),
		includeMaterial: boolean(),
		includeQrGeneration: boolean(),
		includeSpecimenImage: boolean(),
		includeSpecimenType: boolean(),
		includeTestDescription: boolean(),
		includeTestStandard: boolean()
	}),
	companyLogo: string(),
	fileOutput: object({
		outputFormat: mixed<ReportFormat>(),
		outputLocation: string().required()
	}),
	pipelineUuid: string(),
	pipelineName: string()
});
