import React from 'react';
import TestActionsModal from '../TestActionsModal';
import TestActionsContainer from '../../containers/TestActionsContainer';

interface ITestActionsProps {
	testId?: string;
}

const TestActions: React.FC<ITestActionsProps> = ({ testId }) => {
	return (
		<TestActionsModal>
			<TestActionsContainer testId={testId} />
		</TestActionsModal>
	);
};

export default React.memo(TestActions);
