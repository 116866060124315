import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { Icon } from '@tactun/ui';
import DataChannel, { DataChannelDropdownKeyValue, DataChannelType } from '../../../DataChannel';
import { useMeasurements } from '../../../Measurements';
import { useAxes } from '../../../Axes/axes.hooks';
import { useTest } from '../../../Tests/tests.hooks';
import { WidgetProps } from '../../../Dashboard/dashboard.types';
import { ReadoutMetadata, ReadoutSettings } from '../../readout.types';
import { useReadoutData } from '../../../Dashboard/dashboard.hooks';
import { dataChannelTreeToChannels } from '../../../Dashboard/dashboard.converters';
import WidgetHeader from '../../../Dashboard/components/Widget/WidgetHeader';
import WidgetContent from '../../../Dashboard/components/Widget/WidgetContent';
import TareToValueModal from '../TareToValueModal';
import { tareToValue } from '../../readout.grpc';
import { useStationId } from '../../../Stations';
import styles from './ReadoutWidget.module.scss';

const MIN_READOUT_CONTENT_HEIGHT = 108;
const heightClassSizes = [
	styles.heightXs,
	styles.heightS,
	styles.heightM,
	styles.heightL,
	styles.heightXl,
	styles.heightXxl
];

const ReadoutWidget: React.FC<WidgetProps> = ({ metadata, settings, updateSettings }) => {
	const { t } = useTranslation(['dashboard', 'common']);
	const { precision, max, rate, min, tare } = metadata as ReadoutMetadata;
	const { channel } = settings as ReadoutSettings;

	const stationId = useStationId();
	const { testId } = useParams();
	const { testDto } = useTest(testId);
	const { measurements = [] } = useMeasurements(stationId);
	const { axes = [] } = useAxes(stationId);

	const selectedChannel = useMemo(() => (channel ? [channel] : []), [channel]);
	const {
		unit = '',
		name,
		type
	} = useMemo(
		() => dataChannelTreeToChannels(selectedChannel, measurements, axes)[0] || {},
		[selectedChannel, measurements, axes]
	);
	const widgetName = useMemo(
		() => (name && type !== undefined ? `${name} ${t(`common:${DataChannelType[type]}`)}` : ''),
		[name, type, t]
	);

	const data = useReadoutData(channel);

	const updateChannel = useCallback(
		(channels: DataChannelDropdownKeyValue[]) => {
			const [channel] = channels;
			updateSettings({ ...metadata }, { channel });
		},
		[metadata, updateSettings]
	);

	const tareValue = useCallback(
		(val: number | null) => {
			if (channel) {
				tareToValue(channel.id, val);
			}
		},
		[channel]
	);

	const timeUnit = testDto?.timeUnit || { scale: 1, name: 'sec' };

	const value = data && !isNaN(Number(data.val)) ? Number(data.val).toFixed(precision) : '-';
	const maxValue = data && !isNaN(Number(data.max)) ? Number(data.max).toFixed(precision) : '-';
	const minValue = data && !isNaN(Number(data.min)) ? Number(data.min).toFixed(precision) : '-';
	const speed =
		data && !isNaN(Number(data.speed)) ? Number((data.speed * 1000) / timeUnit.scale).toFixed(precision) : '-';

	const speedUnit = `${unit || '-'}/${timeUnit.name}`;

	const allowTare = (metadata.isType || channel?.type === DataChannelType.MEASUREMENT) && tare;
	const [tareModal, setTareModal] = useState<boolean>(false);
	const customActions = useMemo(
		() =>
			allowTare
				? [
						{
							label: t('dashboard', 'Tare to value'),
							icon: 't-icon-tare-to-value',
							command: () => setTareModal(true)
						}
				  ]
				: [],
		[allowTare, t]
	);
	const onTareSave = useCallback(
		(value: number) => {
			tareValue(value);
			setTareModal(false);
		},
		[tareValue]
	);

	const additionalLines = (min || max ? 1 : 0) + (rate ? 1 : 0) + (allowTare ? 1 : 0);
	const onlyValue = !additionalLines;

	const valueContRef = useRef<HTMLDivElement>(null);
	const calculateFontSize = useCallback(() => {
		if (!valueContRef.current) return;
		const el = valueContRef.current;

		const charsCount = value.length + unit.length + 1;

		let fontSize = (el.clientWidth / charsCount) * 1.5;
		fontSize = fontSize >= el.clientHeight ? el.clientHeight * 0.5 : fontSize;

		return fontSize;
	}, [unit.length, value.length]);

	const elRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (!elRef.current) return;
		const el = elRef.current;

		const resizeObserver = new ResizeObserver((entries) => {
			// We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
			// https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
			window.requestAnimationFrame(() => {
				if (!Array.isArray(entries) || !entries.length) {
					return;
				}
				for (const entry of entries) {
					const heightStep = Math.round((entry.contentRect.height - MIN_READOUT_CONTENT_HEIGHT) / 48);

					entry.target.setAttribute('style', `font-size: ${calculateFontSize()}px`);

					const heightClass = heightClassSizes[heightStep] || heightClassSizes[heightClassSizes.length - 1];
					entry.target.classList.remove(...heightClassSizes);
					entry.target.classList.add(heightClass);
				}
			});
		});

		resizeObserver.observe(el);

		return () => resizeObserver.unobserve(el);
	}, [additionalLines, allowTare, calculateFontSize, max, min, rate]);

	return (
		<>
			<WidgetHeader customActions={customActions}>{widgetName}</WidgetHeader>
			<WidgetContent className={styles.readoutContainer} showFooter isType={metadata.isType}>
				<DataChannel
					value={selectedChannel}
					onChange={updateChannel}
					measurements={measurements}
					axes={axes}
					removeTime
					isMultiply={false}
					className={styles.channelSelect}
				/>
				<div className={styles.responsiveContainer} ref={elRef}>
					<div
						className={classNames(styles.readoutValue, {
							[styles.onlyValue]: onlyValue,
							[styles.w3lines]: additionalLines === 3,
							[styles.w2lines]: additionalLines === 2,
							[styles.w1lines]: additionalLines === 1
						})}
						ref={valueContRef}
					>
						<span className={styles.value}>{value}</span>
						<span className={styles.tooltip}>{value}</span>
						<span className={styles.unit}>{unit}</span>
					</div>
					{(min || max || rate) && (
						<div className={styles.smallValues}>
							{channel?.type !== DataChannelType.CYCLE && (max || min) && (
								<div className={styles.maxMinValues}>
									<div>
										{max && (
											<>
												<Icon size="1em" name="sorting-up" className={styles.minMaxLabel} />
												<span>{maxValue}</span> {unit}
												<span className={styles.tooltip}>{maxValue}</span>
											</>
										)}
									</div>
									<div>
										{min && (
											<>
												<Icon size="1em" name="sorting-down" className={styles.minMaxLabel} />
												<span>{minValue}</span> {unit}
												<span className={styles.tooltip}>{minValue}</span>
											</>
										)}
									</div>
								</div>
							)}
							{rate && (
								<div className={styles.rateValue}>
									<span className={styles.rateLabel}>{t('Rate')}: </span>
									<span>{speed}</span> {speedUnit}
									<span className={styles.tooltip}>{speed}</span>
								</div>
							)}
						</div>
					)}
					{allowTare && (
						<div className={styles.tareButtons}>
							<button className={styles.tareButton} onClick={() => tareValue(0)}>
								<Icon name="tare" size={18} />
								{t('dashboard:Tare')}
							</button>
							<button className={styles.tareButton} onClick={() => tareValue(null)}>
								<Icon name="untare" size={18} />
								{t('dashboard:Untare')}
							</button>
						</div>
					)}
				</div>
			</WidgetContent>
			<TareToValueModal onCancel={() => setTareModal(false)} onSave={onTareSave} isOpen={tareModal} />
		</>
	);
};

export default React.memo(ReadoutWidget);
