import { grpc } from '@improbable-eng/grpc-web';
import { Void, deviceInfoService } from '@tactun/grpc-client';
import { ChangeIpRequest } from '@tactun/grpc-client/src/grpc/device_utils_pb';
import { discoveryResultItemToDevice } from './connection.converters';
import { IDiscoveryResultItem, IDevice, IDeviceNetwork } from './connection.types';
import { UnaryOutput } from '@improbable-eng/grpc-web/dist/typings/unary';
import { ProtobufMessage } from '@improbable-eng/grpc-web/dist/typings/message';
import { tError, tInfo } from '../../tools/logger';

export const rebootDevice = async (): Promise<boolean> => {
	return new Promise((resolve) => {
		try {
			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

			grpc.unary(deviceInfoService.DeviceInfo.reboot, {
				request: new Void(),
				host: process.env.REACT_APP_DEVICE_URL as string,
				transport: myTransport,
				onEnd: () => {
					resolve(true);
				}
			});
		} catch {
			resolve(false);
		}
	});
};

export const enableDhcp = async (): Promise<boolean> => {
	return new Promise((resolve) => {
		try {
			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

			grpc.unary(deviceInfoService.DeviceInfo.enable_dhcp, {
				request: new Void(),
				host: process.env.REACT_APP_DEVICE_URL as string,
				transport: myTransport,
				onEnd: () => {
					resolve(true);
				}
			});
		} catch {
			resolve(false);
		}
	});
};

export const discover = async (): Promise<IDevice[]> => {
	try {
		if (window.__TAURI_INVOKE__) {
			await window.__TAURI_INVOKE__('start_broadcast', { duration: 500, timeout: 1000 });
			const response = await window.__TAURI_INVOKE__('collect_broadcast');

			const devices = (response.candidates as IDiscoveryResultItem[]).map((item) => discoveryResultItemToDevice(item));

			return devices;
		}
	} catch (e) {
		tError(e);
	}
	return [];
};

export const collectDiagnostics = async () => {
	try {
		if (window.__TAURI_INVOKE__) {
			await window.__TAURI_INVOKE__('collect_diagnostics', {});
		}
	} catch (e) {
		tError(e);
	}
	return [];
};

export const startTunnel = async (ip: string) => {
	try {
		if (window.__TAURI_INVOKE__) {
			const srcAddr = process.env.REACT_APP_DEVICE_URL?.split('://')[1];

			await window.__TAURI_INVOKE__('start_tunnel', {
				srcAddr: srcAddr,
				destAddr: `${ip}:8080`
			});

			return true;
		} else {
			// If we are not in Tauri, we expect that the tunnel is already open
			return true;
		}
	} catch (e: any) {
		tError(e);
	}
	return false;
};

export const stopTunnel = async () => {
	try {
		if (window.__TAURI_INVOKE__) {
			await window.__TAURI_INVOKE__('stop_tunnel');

			return true;
		} else {
			// If we are not in Tauri, we expect that the tunnel is already open
			return true;
		}
	} catch (e: any) {
		tError(e);
	}
	return false;
};

export const stopSync = async () => {
	try {
		if (window.__TAURI_INVOKE__) {
			await window.__TAURI_INVOKE__('stop_sync');
			tInfo('Stop sync with NATS');
		}
	} catch (e: any) {
		tError(e);
	}
};

export const checkTunnel = async () => {
	try {
		if (window.__TAURI_INVOKE__) {
			return await window.__TAURI_INVOKE__('has_connection', {});
		} else {
			// If we are not in Tauri, we expect that the tunnel is already open
			return true;
		}
	} catch (e: any) {
		tError(e);
	}
	return false;
};

export const changeIp = async (ip: string, mask: string, gateway: string): Promise<boolean> => {
	return new Promise((resolve) => {
		try {
			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });
			const request = new ChangeIpRequest();
			request.setIpAddr(ip);
			request.setMask(mask);
			request.setGateway(gateway);

			grpc.unary(deviceInfoService.DeviceInfo.change_ip, {
				request: request,
				host: process.env.REACT_APP_DEVICE_URL as string,
				transport: myTransport,
				onEnd: () => {
					resolve(true);
				}
			});
		} catch {
			resolve(false);
		}
	});
};

export const getNetworkInfo = async (): Promise<IDeviceNetwork | null> => {
	return new Promise((resolve) => {
		try {
			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

			grpc.unary(deviceInfoService.DeviceInfo.get_network_config, {
				request: new Void(),
				host: process.env.REACT_APP_DEVICE_URL as string,
				transport: myTransport,
				onEnd: (output: UnaryOutput<ProtobufMessage>) => {
					if (output.status === grpc.Code.OK) {
						const response = output.message?.toObject() as IDeviceNetwork;
						resolve(response);
					} else {
						resolve(null);
					}
				}
			});
		} catch {
			resolve(null);
		}
	});
};
