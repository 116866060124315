import axios, { AxiosInstance } from 'axios';
import Keycloak, { KeycloakInitOptions, KeycloakLoginOptions, KeycloakLogoutOptions } from 'keycloak-js';

export interface IUserData {
	username: string;
	firstName: string;
	lastName: string;
	email: string;
	roles?: IUserRoles;
}

export type IUserRoles = { [key: string]: string[] };

export class KeycloakAxios {
	kc: Keycloak;
	onAuthLogout: (() => void) | null = null;

	constructor(configUrl: string) {
		this.kc = new Keycloak(configUrl);

		this.kc.onAuthLogout = () => {
			if (this.onAuthLogout) {
				setTimeout(this.onAuthLogout, 0);
			}
		};

		this.kc.onAuthError = (err) => {
			// eslint-disable-next-line no-console
			console.error('[Keycloak]: Authentication error', err);
		};

		this.kc.onAuthRefreshError = () => {
			// eslint-disable-next-line no-console
			console.error('[Keycloak]: Authentication refresh error');
		};
	}

	init = async (options?: KeycloakInitOptions, axiosInstance?: AxiosInstance) => {
		try {
			await this.kc.init(options || {});
			this._interceptAxiosRequest(axiosInstance);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('[Keycloak]: Initialization failed', err);
		}
	};

	_interceptAxiosRequest = (axiosInstance?: AxiosInstance) => {
		const currentAxios = axiosInstance || axios;
		currentAxios.interceptors.request.use(async (config) => {
			const isTokenValid = await this.validateToken();

			if (isTokenValid && config?.headers) {
				config.headers.Authorization = `Bearer ${this.kc.token}`;
				config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json';
			}
			return config;
		});
	};

	_getUserRoles = () => {
		const { tokenParsed } = this.kc;
		let roles;
		if (tokenParsed?.resource_access) {
			const entries = Object.entries(tokenParsed.resource_access);
			roles = entries.reduce((newObj, r) => {
				const name = r[0];
				const data: Record<string, any> = { ...newObj };
				data[name] = r[1].roles;
				return data;
			}, {} as IUserRoles);
		}
		return roles;
	};

	getUserData = (): IUserData | null => {
		const { tokenParsed } = this.kc;

		return tokenParsed
			? ({
					username: tokenParsed.preferred_username,
					firstName: tokenParsed.given_name,
					lastName: tokenParsed.family_name,
					email: tokenParsed.email,
					roles: this._getUserRoles()
			  } as IUserData)
			: null;
	};

	validateToken = async () => {
		try {
			// If the token expires within 5 seconds (default value for updateToken, can pass eny value in seconds) the token is refreshed.
			await this.kc.updateToken(5);
			return true;
		} catch {
			return false;
		}
	};

	login = (options: KeycloakLoginOptions) => {
		this.kc.login(options);
	};

	logout = (options?: KeycloakLogoutOptions) => {
		this.kc.logout(options);
	};
}

export const initKeycloak = async (configUrl: string, options?: KeycloakInitOptions, axiosInstance?: AxiosInstance) => {
	const keycloak = new KeycloakAxios(configUrl);
	await keycloak.init(options, axiosInstance);
	return keycloak;
};
