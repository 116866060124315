import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as api from '../featuresets.api';
import Featureset from '../components/FeaturesetGeneral';

const AxesContainer: React.FC = () => {
	const navigate = useNavigate();
	const { featuresetId } = useParams();
	const isCreate = !featuresetId;

	const form = useForm<Record<string, string>>({
		mode: 'onBlur'
	});
	const { handleSubmit, reset } = form;

	const { data } = useQuery<Record<string, string>, Error>({
		queryKey: ['featureset', { featuresetId }],
		queryFn: async () => {
			const resp = await api.getFeatureset(featuresetId as string);
			return resp;
		},
		enabled: !!featuresetId
	});

	const handleBack = useCallback(() => {
		navigate('/featuresets');
	}, [navigate]);

	const handleSave = handleSubmit((data) => {
		if (featuresetId) {
			//Update
		} else {
			//Create
		}
	});

	useEffect(() => {
		if (featuresetId) {
			reset({ ...data }, { keepDirty: true, keepTouched: true });
		}
	}, [featuresetId, data, reset]);

	return <Featureset isCreate={isCreate} isLoading={false} form={form} onBack={handleBack} onSave={handleSave} />;
};

export default React.memo(AxesContainer);
