import React, { useCallback } from 'react';
import { Modal } from '@tactun/ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TestMeasurementsTable from '../components/TestMeasurementsTable';
import TestMeasurementsForm from '../components/TestMeasurementsForm';
import { TestMeasurementResponseDto, TestMeasurementFormType } from '../testMeasurements.types';
import { testMeasurementFormSchema } from '../testMeasurements.schemas';
import { useSensors } from '../../Sensors';
import { useQuantities } from '../../Units';
import { useTestVariablesEntities, VariablesTypes } from '../../Variables';
import { formRequestConverter, responseFormConverter } from '../testMeasurements.converters';
import { useEntityOptions } from '../../../hooks';
import { useTest } from '../../Tests/tests.hooks';
import { useTestMeasurements } from '../testMeasurements.hooks';

interface TestMeasurementsContainerProps {
	testId?: string;
}

const TestMeasurementsContainer: React.FC<TestMeasurementsContainerProps> = ({ testId }) => {
	const form = useForm<TestMeasurementFormType>({
		mode: 'onBlur',
		resolver: yupResolver(testMeasurementFormSchema)
	});
	const { handleSubmit, reset } = form;
	const { testDto } = useTest(testId);

	//  handel TestVariableCreateEditModal save
	const handleSuccessSave = useCallback(() => {
		reset({}, { keepDirty: false, keepTouched: false });
		Modal.hide('testMeasurementsFormId');
	}, [reset]);

	const { testMeasurements, saveTestMeasurement, isLoading } = useTestMeasurements(testId, handleSuccessSave);

	const handleEdit = useCallback(
		(measurement: TestMeasurementResponseDto) => {
			reset(responseFormConverter(measurement), { keepDirty: true, keepTouched: true });
			Modal.show('testMeasurementsFormId');
		},
		[reset]
	);

	const handleSave = handleSubmit((data) => {
		if (data.id) {
			saveTestMeasurement(formRequestConverter(data), data.id);
		}
	});

	const { sensors } = useSensors(testDto?.stationId, true);

	const { quantities } = useQuantities();

	const { testVariables = [] } = useTestVariablesEntities(testId, VariablesTypes.NUMERIC);
	const variablesList = useEntityOptions(testVariables);

	return (
		<>
			<TestMeasurementsTable measurements={testMeasurements} isLoading={isLoading} onEdit={handleEdit} />
			<TestMeasurementsForm
				form={form}
				onSave={handleSave}
				quantities={quantities || []}
				variables={variablesList}
				sensors={sensors || []}
			/>
		</>
	);
};

export default React.memo(TestMeasurementsContainer);
