import React, { useCallback, useMemo } from 'react';
import { useNavigate, useLocation, matchPath, useParams } from 'react-router';
import { normalizePath } from './helpers';

import { Handler, IStep, IWizardProps } from './types';
import WizardContext from './wizardContext';

const Wizard: React.FC<IWizardProps> = ({ children, steps }) => {
	const [isLoading, setIsLoading] = React.useState(false);
	const hasNextStep = React.useRef(true);
	const hasPreviousStep = React.useRef(false);
	const nextStepHandler = React.useRef<Handler>(() => true);
	const previousStepHandler = React.useRef<Handler>(() => true);
	const stepCount = React.Children.toArray(children).length;
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();

	const activeStep = useMemo(() => {
		return steps.findIndex((step) => {
			return !!matchPath(`${step.path}`, location.pathname);
		});
	}, [location.pathname, steps]);

	hasNextStep.current = activeStep < steps.length - 1;
	hasPreviousStep.current = activeStep > 0;

	const goToNextStep = useCallback(() => {
		navigate(normalizePath(steps[activeStep + 1].path, params));
	}, [activeStep, navigate, params, steps]);

	const goToPreviousStep = useCallback(() => {
		navigate(normalizePath(steps[activeStep - 1].path, params));
	}, [activeStep, navigate, params, steps]);

	const goToStep = useCallback(
		(step: IStep) => {
			nextStepHandler.current = null;
			previousStepHandler.current = null;
			navigate(normalizePath(step.path, params));
		},
		[navigate, params]
	);

	const beforeNextStep = React.useRef((handler: Handler) => {
		nextStepHandler.current = handler;
	});

	const beforePreviousStep = React.useRef((handler: Handler) => {
		previousStepHandler.current = handler;
	});

	const doNextStep = useCallback(async () => {
		if (nextStepHandler.current) {
			try {
				setIsLoading(true);
				const isNextStepAllowed = await nextStepHandler.current(steps[activeStep]);
				setIsLoading(false);
				if (isNextStepAllowed && hasNextStep.current) {
					nextStepHandler.current = null;
					previousStepHandler.current = null;
					goToNextStep();
				}
			} catch (error) {
				setIsLoading(false);
				throw error;
			}
		} else {
			goToNextStep();
		}
	}, [activeStep, goToNextStep, steps]);

	const doPreviousStep = useCallback(async () => {
		if (hasPreviousStep.current && previousStepHandler.current) {
			try {
				setIsLoading(true);
				const isPreviousStepAllowed = await previousStepHandler.current(steps[activeStep]);
				setIsLoading(false);
				if (isPreviousStepAllowed) {
					nextStepHandler.current = null;
					previousStepHandler.current = null;
					goToPreviousStep();
				}
			} catch (error) {
				setIsLoading(false);
				throw error;
			}
		} else {
			goToPreviousStep();
		}
	}, [activeStep, goToPreviousStep, steps]);

	const wizardValue = React.useMemo(() => {
		const stepsCopy = [...steps];
		steps.forEach((step, index) => {
			step.isActive = index === activeStep;
		});
		return {
			nextStep: doNextStep,
			previousStep: doPreviousStep,
			goToStep,
			beforeNextStep: beforeNextStep.current,
			beforePreviousStep: beforePreviousStep.current,
			steps: stepsCopy,
			isLoading,
			activeStep,
			stepCount,
			isFirstStep: !hasPreviousStep.current,
			isLastStep: !hasNextStep.current
		};
	}, [steps, doNextStep, doPreviousStep, goToStep, isLoading, activeStep, stepCount]);

	return <WizardContext.Provider value={wizardValue}>{children}</WizardContext.Provider>;
};

export default Wizard;
