import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { WizardLayout } from '../../../../components';

import styles from './ComponentsSettings.module.scss';

const ComponentsSettings: React.FC = () => {
	const { t } = useTranslation('common');
	return (
		<WizardLayout>
			<div className={styles.linksContainer}>
				<div className={styles.title}>{t('Components')}</div>
				<NavLink className={styles.link} to="/components-settings/units">
					{t('Units')}
				</NavLink>
				{/*<NavLink className={styles.link} to="/components-settings/languages">*/}
				{/*	{t('Languages')}*/}
				{/*</NavLink>*/}
				<NavLink className={styles.link} to="/components-settings/specimen-types">
					{t('Specimen Types')}
				</NavLink>
				<NavLink className={styles.link} to="/components-settings/dashboard-types">
					{t('Dashboard Types')}
				</NavLink>
			</div>
		</WizardLayout>
	);
};

export default React.memo(ComponentsSettings);
