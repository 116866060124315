import { useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { WidgetProps } from '../../Dashboard/dashboard.types';
import ControlTestWidget from '../components/ControlTest/ControlTestWidget';
import { TestOperateCommands, TestOperateStatus } from '../controlTest.enums';
import { useControlTest } from '../controlTest.grpc';

import { Modal } from '@tactun/ui';
import { specimenSelectionModalId } from '../../SpecimenSelectionModal';
import { toast } from 'react-toastify';

const ControlTestContainer: React.FC<WidgetProps> = ({ metadata }) => {
	const { testId } = useParams();
	const [searchParams] = useSearchParams();
	const specimenId = searchParams.get('specimenId');
	const { testState, operate, start } = useControlTest(testId, metadata.isType);

	const handlePlayStopClick = useCallback(() => {
		if (testId === undefined) {
			toast.error('Test is not selected');
			return;
		}

		if (testState === TestOperateStatus.STARTED || testState === TestOperateStatus.RESUMED) {
			operate(testId, TestOperateCommands.STOP);
		} else if (testState === TestOperateStatus.STOPPED) {
			// if specimen is not selected show selection modal else start test
			if (specimenId) {
				start(testId, specimenId);
			} else {
				Modal.show(specimenSelectionModalId, { isRunTest: true });
			}
		}
	}, [testState, testId, specimenId, operate, start]);

	const handlePauseResumeClick = useCallback(() => {
		if (testId === undefined) {
			toast.error('Test is not selected');
			return;
		}
		if (testState === TestOperateStatus.STARTED || testState === TestOperateStatus.RESUMED) {
			operate(testId, TestOperateCommands.PAUSE);
		} else if (testId && testState === TestOperateStatus.PAUSED) {
			operate(testId, TestOperateCommands.RESUME);
		}
	}, [operate, testState, testId]);

	const handleNextClick = useCallback(() => {}, []);
	const handleReportClick = useCallback(() => {}, []);

	return (
		<ControlTestWidget
			status={testState}
			onPlayStop={handlePlayStopClick}
			onPauseResume={handlePauseResumeClick}
			onReport={handleReportClick}
			onNext={handleNextClick}
			isType={metadata.isType}
		/>
	);
};
export default ControlTestContainer;
