import { useQuery } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSystems } from '../system.api';
import SystemsCrud from '../components/SystemsCrud';
import { SystemFormType } from '../system.types';
import { SystemsCrudActionTypes } from '../system.enums';

const SystemsCrudContainer: React.FC = () => {
	const navigate = useNavigate();

	const handleAction = useCallback(
		(type: SystemsCrudActionTypes, data?: SystemFormType) => {
			switch (type) {
				case SystemsCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case SystemsCrudActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case SystemsCrudActionTypes.OPEN_UNITS:
					navigate(`./${data?.id}/units`);
					break;
				case SystemsCrudActionTypes.OPEN_STATIONS:
					navigate(`./${data?.id}/stations`);
					break;
				case SystemsCrudActionTypes.OPEN_QUANTITIES:
					navigate(`./${data?.id}/quantities`);
					break;
			}
		},
		[navigate]
	);

	const { data, isLoading } = useQuery<SystemFormType[], Error>({ queryKey: ['systems'], queryFn: () => getSystems() });

	return <SystemsCrud onAction={handleAction} data={data} isLoading={isLoading} />;
};

export default React.memo(SystemsCrudContainer);
