import { DataChannelType } from './dataChannel.enums';
import { DataChannelDropdownKeyValue, DataChannelEntity } from './dataChannel.types';
import { MeasurementResponseDto } from '../Measurements';
import { IAxisResponseDto } from '../Axes';

export const dataChannelToDataChannelDto = (
	selected: DataChannelDropdownKeyValue[],
	measurements: MeasurementResponseDto[] = [],
	axes: IAxisResponseDto[] = []
): DataChannelEntity[] => {
	return selected
		.map((item) => {
			if (item.type === DataChannelType.MEASUREMENT) {
				const entity = measurements.find(({ id }) => item.id === id);

				return {
					id: entity?.id || '',
					type: DataChannelType.MEASUREMENT,
					entity
				};
			}

			if (item.type === DataChannelType.SET_POINT) {
				const entity = axes.find(({ id }) => item.id === id);

				return {
					id: entity?.id || '',
					type: DataChannelType.SET_POINT,
					entity
				};
			}

			if (item.type === DataChannelType.COMP_SET_POINT) {
				const entity = axes.find(({ id }) => item.id === id);

				return {
					id: entity?.id || '',
					type: DataChannelType.COMP_SET_POINT,
					entity
				};
			}

			if (item.type === DataChannelType.CYCLE) {
				const entity = axes.find(({ id }) => item.id === id);

				return {
					id: entity?.id || '',
					type: DataChannelType.CYCLE,
					entity
				};
			}

			return {
				id: 'TIME',
				type: DataChannelType.TIME
			};
		})
		.filter((item) => !!item?.id);
};
