import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCalibration, useIsSavingCalibration } from '../calibrations.hooks';
import { useSensor } from '../../Sensors';
import CalibrationPage from '../components/CalibrationPage';
import { useStationName } from '../../Stations';
import CalibrationContainer from './CalibrationContainer';

const CalibrationPageContainer: React.FC = () => {
	const navigate = useNavigate();
	const { stationId, sensorId } = useParams();
	const { stationName } = useStationName(stationId);
	const { sensor: currentSensor } = useSensor(sensorId);
	const { calibrationDto } = useCalibration(sensorId);
	const isEdit = useMemo(() => calibrationDto !== undefined, [calibrationDto]);

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/sensors`);
	}, [navigate, stationId]);

	const isLoading = useIsSavingCalibration(sensorId);

	return (
		<CalibrationPage
			isCreate={!isEdit}
			stationName={stationName}
			isLoading={isLoading}
			sensorName={currentSensor?.name || ''}
			onBack={handleBack}
		>
			<CalibrationContainer stationId={stationId} sensorId={sensorId} />
		</CalibrationPage>
	);
};

export default React.memo(CalibrationPageContainer);
