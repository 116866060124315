import { Button, Dropdown, Form, GroupInputFrame, Icon, InputNumber, InputText } from '@tactun/ui';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { calibrationFormId } from '../../../Calibrations';
import { OffsetCalibrationConfigurationFormType } from '../../offsetCalibration.types';
import { OffsetCalibrationStatus } from '../../offsetCalibration.enums';
import { IListItem } from '../../../../types';
import styles from './OffsetCalibrationDialog.module.scss';
import { ControlAlgorithmsBase } from '../../../ControlAlgorithms';
import PidAlgorithmForm from '../../../ControlAlgorithms/components/PidAlgorithmForm';
import AsymmetricPidAlgForm from '../../../ControlAlgorithms/components/AsymmetricPidAlgForm';
import { getIconNameByStatus } from '../../offsetCalibration.tools';

export interface IOffsetCalibrationDialogProps {
	title: string;
	isVisible: boolean;
	calibrationStatus: OffsetCalibrationStatus;
	controlChannels: IListItem[];
	offsetUnit?: string;
	onHide: () => void;
	onToggleCalibration: () => void;
}

const OffsetCalibrationDialog: React.FC<IOffsetCalibrationDialogProps> = ({
	title,
	isVisible,
	calibrationStatus,
	controlChannels,
	offsetUnit,
	onHide,
	onToggleCalibration
}) => {
	const { t } = useTranslation('offsetCalibration');
	const {
		control,
		register,
		watch,
		formState: { errors }
	} = useFormContext<OffsetCalibrationConfigurationFormType>();
	const baseAlgorithm = watch('controlAlgorithmForm.baseAlgorithm');
	const isRunning = calibrationStatus === OffsetCalibrationStatus.IN_PROGRESS;

	return (
		<Dialog header={title} visible={isVisible} onHide={onHide} className={styles.container} resizable={false}>
			<Form className={styles.form}>
				<input type="hidden" {...register('limit')} />
				<Controller
					name="controlChannelId"
					control={control}
					render={({ field }) => (
						<Dropdown
							{...field}
							options={controlChannels}
							label={t('Control*')}
							error={errors.controlChannelId?.message}
							data-testid="controlChannelId"
							disabled={isRunning}
						/>
					)}
				/>
				<div />
				<Form.Divider />
				{(baseAlgorithm === ControlAlgorithmsBase.PID || baseAlgorithm === ControlAlgorithmsBase.ASYMMETRIC_PID) && (
					<>
						{baseAlgorithm === ControlAlgorithmsBase.PID ? (
							<PidAlgorithmForm
								formPrefix="controlAlgorithmForm"
								errors={errors?.controlAlgorithmForm?.pid}
								disabled={isRunning}
							/>
						) : (
							<AsymmetricPidAlgForm
								formPrefix="controlAlgorithmForm"
								errors={errors?.controlAlgorithmForm?.asymmetricPid}
								disabled={isRunning}
							/>
						)}
						<Form.Divider className="formDivider" />
					</>
				)}
				<GroupInputFrame>
					<InputNumber
						data-testid="allowedVariationId"
						{...register('allowedVariation')}
						label={t('Allowed variation*')}
						error={errors?.allowedVariation?.message}
						disabled={isRunning}
					/>
					<InputText disabled value="%" />
				</GroupInputFrame>
				<GroupInputFrame>
					<InputNumber
						data-testid="delayId"
						{...register('delay')}
						label={t('Delay*')}
						error={errors?.delay?.message}
						disabled={isRunning}
						isInteger
					/>
					<InputText disabled value="ms" />
				</GroupInputFrame>
				<GroupInputFrame>
					<InputNumber
						data-testid="stepId"
						{...register('step')}
						label={t('Step*')}
						error={errors?.step?.message}
						disabled={isRunning}
					/>
					<InputText disabled value={offsetUnit} />
				</GroupInputFrame>
				<GroupInputFrame>
					<InputNumber
						data-testid="maximumOffsetId"
						{...register('maximumOffset')}
						label={t('Maximum offset*')}
						error={errors?.maximumOffset?.message}
						disabled={isRunning}
					/>
					<InputText disabled value={offsetUnit} />
				</GroupInputFrame>
			</Form>
			<div className={styles.calibrationFooter}>
				{calibrationStatus !== OffsetCalibrationStatus.NOT_STARTED && (
					<div className={styles.statusContainer}>
						<Icon
							name={getIconNameByStatus(calibrationStatus)}
							size={16}
							className={styles.statusIcon}
							isSpin={calibrationStatus === OffsetCalibrationStatus.IN_PROGRESS}
						/>
						<p className={styles.status}>{t(OffsetCalibrationStatus[calibrationStatus])}</p>
					</div>
				)}
				<Button className="btnCancel" color="success" variant="text" onClick={onHide}>
					{t('Cancel')}
				</Button>
				<Button
					variant="contained"
					color="secondary"
					form={calibrationFormId}
					label={isRunning ? t('Stop') : t('Start')}
					onClick={onToggleCalibration}
					className={styles.btnIcon}
					icon={t(isRunning ? 't-icon-control-test-stop' : 't-icon-control-test-play')}
				/>
			</div>
		</Dialog>
	);
};

export default OffsetCalibrationDialog;
