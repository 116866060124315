import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuantityResponseDto } from '.';
import { IListItemNumber } from '../../types';
import { UnitSystemTypes } from './units.enums';
import * as api from './units.api';
import { DropdownOptionString } from '@tactun/ui';

export const useQuantities = (type?: 'TIME' | 'USER', isDisabled: boolean = false) => {
	const {
		data: quantities,
		isLoading: isQuantitiesLoading,
		isFetched
	} = useQuery({
		queryKey: ['quantities', { type }],
		queryFn: () => api.getQuantities(type),
		enabled: !isDisabled
	});

	const queryClient = useQueryClient();

	const updateQuantities = () => {
		queryClient.invalidateQueries({ queryKey: ['units'] });
	};

	return {
		quantities,
		isQuantitiesLoading,
		isQuantitiesFetched: isFetched,
		updateQuantities
	};
};

export const useUnitsMap = (isDisabled: boolean = false) => {
	const { quantities } = useQuantities(undefined, isDisabled);

	return useMemo(() => {
		return (
			quantities?.reduce(
				(units, quantity) => ({
					...units,
					...quantity.units.reduce(
						(qUnits, unit) => ({
							...qUnits,
							[unit.id]: unit.name
						}),
						{}
					)
				}),
				{}
			) || {}
		);
	}, [quantities]) as Record<string, string>;
};

export const useUnit = (unitId: string) => {
	const { data: unitDto, isLoading: isUnitLoading } = useQuery<QuantityResponseDto>({
		queryKey: ['unit', { unitId }],
		// When we provide enable option - !!unitId, the unitId is guaranteed
		queryFn: () => api.getQuantity(unitId as string),
		enabled: !!unitId
	});

	const queryClient = useQueryClient();

	const updateUnit = () => {
		queryClient.invalidateQueries({ queryKey: ['units', { unitId }] });
	};

	return {
		unitDto,
		isUnitLoading,
		updateUnit
	};
};

export const useAllUnitSystemsOptions = () => {
	const { t } = useTranslation();
	const unitSystems: IListItemNumber[] = [];

	for (let key in UnitSystemTypes) {
		if (isNaN(Number(key))) {
			unitSystems.push({
				label: t(key),
				value: Number(UnitSystemTypes[key])
			});
		}
	}

	return unitSystems;
};

export const useUnitSystemsOptions = (withNone: boolean = true) => {
	const { t } = useTranslation();
	const { data: unitSystems, isLoading: isUnitSystemsLoading } = useQuery<IListItemNumber[], Error>({
		queryKey: ['getAvailableUnitSystemsTypes', { withNone }],
		queryFn: async () => {
			const unitSystemTypes = await api.getAvailableUnitSystemsTypes();

			return unitSystemTypes
				?.filter((type) => withNone || (!withNone && type !== UnitSystemTypes.NONE))
				.map((type: number) => ({ label: t(UnitSystemTypes[type]), value: type }));
		}
	});

	return { unitSystems: unitSystems || [], isUnitSystemsLoading };
};

export const useTimeQuantity = () => {
	const { data: timeQuantity, isLoading: isLoadingTimeQuantity } = useQuery({
		queryKey: ['quantities', 'time'],
		queryFn: () => api.getQuantities('TIME')
	});

	return {
		timeQuantity: timeQuantity && timeQuantity[0],
		isLoadingTimeQuantity
	};
};

export const useDefaultTimeUnitName = () => {
	const { timeQuantity } = useTimeQuantity();

	const defaultTimeUnitName = useMemo(() => {
		if (timeQuantity) {
			const defaultTimeUnit = timeQuantity.units.find((unit) => unit.isDefault);
			return defaultTimeUnit ? defaultTimeUnit.name : '';
		} else {
			return '';
		}
	}, [timeQuantity]);

	return defaultTimeUnitName;
};

export const useQuantityAsOptions = (quantities: QuantityResponseDto[]) => {
	return useMemo<DropdownOptionString[]>(() => {
		return quantities.map((quantity) => ({
			label: quantity.name,
			options: quantity.units.map((unit) => ({ label: unit.name, value: unit.id }))
		}));
	}, [quantities]);
};
