import { useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Wizard } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import * as paths from './station.const';
import StationsMainPageContainer from './containers/StationsMainPageContainer';
import StationContainer from './containers/StationContainer';
import StationWizardContainer from './containers/StationWizardContainer';
import StationChannelsTableContainer from '../StationChannels/containers/StationChannelsTableContainer';
import StationChannelContainer from '../StationChannels/containers/StationChannelContainer';
import CalculationsTableContainer from '../Calculations/containers/CalculationsTableContainer';
import CalculationContainer from '../Calculations/containers/CalculationContainer';
import SensorsTableContainer from '../Sensors/containers/SensorsTableContainer';
import SensorContainer from '../Sensors/containers/SensorContainer';
import ActuatorsTableContainer from '../Actuators/containers/ActuatorsTableContainer';
import ActuatorContainer from '../Actuators/containers/ActuatorContainer';
import ActuatorsCalibrationContainer from '../Actuators/containers/ActuatorsCalibrationContainer';
import AxesTableContainer from '../Axes/containers/AxesTableContainer';
import AxisContainer from '../Axes/containers/AxisContainer';
import VariablesTableContainer from '../Variables/containers/VariablesTableContainer';
import VariableContainer from '../Variables/containers/VariableContainer';
import MeasurementsTableContainer from '../Measurements/containers/MeasurementsTableContainer';
import MeasurementContainer from '../Measurements/containers/MeasurementContainer';
import CalibrationPageContainer from '../Calibrations/containers/CalibrationPageContainer';
import AuxiliaryDeviceContainer from '../AuxiliaryDevices/containers/AuxiliaryDeviceContainer';
import AuxiliaryDeviceTableContainer from '../AuxiliaryDevices/containers/AuxiliaryDevicesTableContainer';
import StationRulesTableContainer from '../StationRules/containers/StationRulesTableContainer';
import StationRuleContainer from '../StationRules/containers/StationRuleContainer';
import ControlChannelsTableContainer from '../ControlChannels/containers/ControlChannelsTableContainer';
import ControlChannelContainer from '../ControlChannels/containers/ControlChannelContainer';
import { CustomWidgetsTableContainer, CustomWidgetContainer } from '../CustomWidgets';
import WidgetsConfigurationTableContainer from '../WidgetsConfiguration/containers/WidgetsConfigurationTableContainer';
import WidgetsContainer from '../WidgetsConfiguration/containers/WidgetsContainer';
import Permissions from '../Permissions';

const useSteps = () => {
	const { t } = useTranslation('station');

	const steps = useMemo(() => {
		return [
			{ path: paths.generalInfoPath, name: t('General') },
			{ path: paths.stationChannelsPath, name: t('Channels') },
			{ path: paths.sensorsPath, name: t('Sensors') },
			{ path: paths.measurementsPath, name: t('Measurements') },
			{ path: paths.actuatorsPath, name: t('Actuators') },
			{ path: paths.axesPath, name: t('Axes') },
			{ path: paths.controlChannelsPath, name: t('Controls') },
			{ path: paths.calculationsPath, name: t('Calculations') },
			{ path: paths.variablesPath, name: t('Variables') },
			{ path: paths.auxiliaryDevicesPath, name: t('Auxiliary Devices') },
			{ path: paths.stationRulesPath, name: t('Rules') },
			{ path: paths.widgetsPath, name: t('Widgets') }
			// { path: paths.permissionsPath, name: t('Permissions') }
		];
	}, [t]);

	return steps;
};

const StationsRouts: React.FC = () => {
	const steps = useSteps();

	const createSteps = useMemo(
		() =>
			steps.map((step) => {
				return { ...step, path: step.path === paths.generalInfoPath ? paths.generalInfoCreatePath : step.path };
			}),
		[steps]
	);

	return (
		<Routes>
			<Route index element={<StationsMainPageContainer />} />
			<Route
				path="create"
				element={
					<Wizard steps={createSteps}>
						<StationWizardContainer />
					</Wizard>
				}
			>
				<Route index element={<StationContainer />} />
			</Route>
			<Route
				path=":stationId/*"
				element={
					<Wizard steps={steps}>
						<StationWizardContainer />
					</Wizard>
				}
			>
				<Route index element={<StationContainer />} />
				<Route path="station-channels" element={<StationChannelsTableContainer />} />
				<Route path="calculations" element={<CalculationsTableContainer />} />
				<Route path="sensors" element={<SensorsTableContainer />} />
				<Route path="control-channels" element={<ControlChannelsTableContainer />} />
				<Route path="actuators" element={<ActuatorsTableContainer />} />
				<Route path="axes" element={<AxesTableContainer />} />
				<Route path="variables" element={<VariablesTableContainer />} />
				<Route path="measurements" element={<MeasurementsTableContainer />} />
				<Route path="auxiliary-devices" element={<AuxiliaryDeviceTableContainer />} />
				<Route path="rules" element={<StationRulesTableContainer />} />
				<Route path="widgets" element={<CustomWidgetsTableContainer />} />
				<Route path="widgets-old" element={<WidgetsConfigurationTableContainer />} />
				<Route path="permissions" element={<Permissions />} />
			</Route>
			<Route path=":stationId/*">
				<Route path="station-channels/create" element={<StationChannelContainer />} />
				<Route path="station-channels/:channelId" element={<StationChannelContainer />} />
				<Route path="calculations/create" element={<CalculationContainer />} />
				<Route path="calculations/:calculationId" element={<CalculationContainer />} />
				<Route path="sensors/create" element={<SensorContainer />} />
				<Route path="sensors/:sensorId" element={<SensorContainer />} />
				<Route path="sensors/:sensorId/calibration">
					<Route index element={<CalibrationPageContainer />} />
					<Route path=":calibrationId" element={<CalibrationPageContainer />} />
				</Route>
				<Route path="control-channels/create" element={<ControlChannelContainer />} />
				<Route path="control-channels/:controlChannelId" element={<ControlChannelContainer />} />
				<Route path="actuators/create" element={<ActuatorContainer />} />
				<Route path="actuators/:actuatorId" element={<ActuatorContainer />} />
				<Route path="actuators/:actuatorId/calibration">
					<Route index element={<ActuatorsCalibrationContainer />} />
					<Route path=":calibrationId" element={<ActuatorsCalibrationContainer />} />
				</Route>
				<Route path="axes/create" element={<AxisContainer />} />
				<Route path="axes/:axisId" element={<AxisContainer />} />
				<Route path="variables/create" element={<VariableContainer />} />
				<Route path="variables/:variableId" element={<VariableContainer />} />
				<Route path="measurements/create" element={<MeasurementContainer />} />
				<Route path="measurements/:measurementId" element={<MeasurementContainer />} />
				<Route path="auxiliary-devices/create" element={<AuxiliaryDeviceContainer />} />
				<Route path="auxiliary-devices/:auxiliaryDeviceId" element={<AuxiliaryDeviceContainer />} />
				<Route path="rules/create" element={<StationRuleContainer />} />
				<Route path="rules/:stationRuleId" element={<StationRuleContainer />} />
				<Route path="widgets/create" element={<CustomWidgetContainer />} />
				<Route path="widgets/:widgetId" element={<CustomWidgetContainer />} />

				<Route path="widgets-old/create" element={<WidgetsContainer />} />
				<Route path="widgets-old/:widgetId" element={<WidgetsContainer />} />
			</Route>
		</Routes>
	);
};

export default StationsRouts;
