import { useEffect, useRef } from 'react';
import type { FC } from 'react';
import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { LineChartPoint } from './LineChart.types';
import styles from './LineChart.module.scss';

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip);

export interface LineChartProps {
	xLabel: string;
	yLabel: string;
	dataset: LineChartPoint[];
}

const LineChart: FC<LineChartProps> = ({ xLabel, yLabel, dataset }) => {
	const chart = useRef<ChartJS<'line', number[], number>>();

	useEffect(() => {
		if (chart.current) {
			chart.current.data.labels = dataset.map((point) => point.x);
			chart.current.data.datasets[0].data = dataset.map((point) => point.y);
			chart.current.update();
		}
	}, [chart, dataset]);

	return (
		<div className={styles.container}>
			<Line
				ref={chart}
				data={{
					labels: dataset.map((point) => point.x),
					datasets: [
						{
							data: dataset.map((point) => point.y),
							backgroundColor: '#84C7EC',
							borderColor: '#84C7EC'
						}
					]
				}}
				options={{
					plugins: {
						legend: {
							display: false
						}
					},
					datasets: {
						line: {
							backgroundColor: 'red',
							borderColor: 'red'
						}
					},
					scales: {
						x: {
							ticks: {
								color: '#737373'
							},
							title: {
								display: true,
								text: xLabel,
								color: '#363F46',
								font: {
									size: 12,
									weight: '700'
								}
							}
						},
						y: {
							title: {
								display: true,
								text: yLabel,
								color: '#363F46',
								font: {
									size: 12,
									weight: '700'
								}
							}
						}
					}
				}}
			/>
		</div>
	);
};

export default LineChart;
