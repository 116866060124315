import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { KebabMenu, IconButton } from '@tactun/ui';
import { Plot } from '../../graphWidget.types';
import { ChartAxisNames, GraphPlotActions } from '../../graphWidget.constants';
import styles from './PlotItem.module.scss';
import { Axis } from '../../graphWidget.enums';
import { DataChannelType } from '../../../DataChannel';

type PlotItemProps = {
	plot: Plot;
	onAction: (action: string, data: Plot) => void;
};

const PlotItem: FC<PlotItemProps> = ({ plot, onAction }) => {
	const { t } = useTranslation();
	const createMenuItems = useCallback(
		(data: Plot) => [
			{ label: 'Change color', command: () => onAction(GraphPlotActions.CHANGE_COLOR, data) },
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(GraphPlotActions.DELETE, data)
			}
		],
		[onAction, t]
	);

	const title = useMemo(() => {
		if (
			plot.channel.type === DataChannelType.CYCLE ||
			plot.channel.type === DataChannelType.SET_POINT ||
			plot.channel.type === DataChannelType.COMP_SET_POINT
		) {
			return `${plot.title} ${t(DataChannelType[plot.channel.type])}`;
		} else {
			return plot.title;
		}
	}, [plot.channel.type, plot.title, t]);

	return (
		<div key={plot.id} title={t(ChartAxisNames[plot.yAxisId as Axis])}>
			<div>
				<div style={{ backgroundColor: plot.color }} className={styles.plotColor}>
					{t(ChartAxisNames[plot.yAxisId as Axis][0])}
				</div>
				<div>{title}</div>
			</div>
			<div>
				<IconButton
					icon={plot.isVisible ? 'show' : 'hide'}
					onClick={() => {
						onAction(GraphPlotActions.TOGGLE_VISIBILITY, plot);
					}}
				></IconButton>
				<div className={styles.kebabButton}>
					<KebabMenu model={createMenuItems(plot)} />
				</div>
			</div>
		</div>
	);
};

export default PlotItem;
