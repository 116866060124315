import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { Dropdown, Icon, Modal } from '@tactun/ui';
import { intervalToDuration, formatDuration } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { TestProp } from '@tactun/grpc-client';
import { WidgetProps } from '../../../Dashboard/dashboard.types';
import { useTestState } from '../../../Dashboard/dashboard.hooks';
import { useTests } from '../../../Tests/tests.hooks';
import { testDtoToTestOption } from '../../../Dashboard/dashboard.converters';
import { useSpecimen } from '../../../Specimens';
import WidgetHeader from '../../../Dashboard/components/Widget/WidgetHeader';
import WidgetContent from '../../../Dashboard/components/Widget/WidgetContent';
import { specimenSelectionModalId } from '../../../SpecimenSelectionModal';
import styles from './TestInformation.module.scss';

const zeroPad = (num: number) => String(num).padStart(2, '0');

const TestInformation: FC<WidgetProps> = ({ width, metadata }) => {
	const { t } = useTranslation('dashboard');
	const { testId } = useParams();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const specimenId = searchParams.get('specimenId');
	const { specimen } = useSpecimen(specimenId || undefined);
	const [testStarted, setTestStarted] = useState<number | null>(null);
	const [currentTime, setCurrentTime] = useState<number>(Date.now());
	const [testEnded, setTestEnded] = useState<number | null>(null);
	const { testInfo, resetEvents } = useTestState(testId, metadata.isType);
	const initialState = !testStarted;
	const isTestEnded = testStarted && testEnded && testEnded > testStarted;
	const isTestRunning = testStarted && !testEnded;

	const runningTime = useMemo(() => {
		if (initialState || currentTime < testStarted) return '00:00:00';
		const duration = intervalToDuration({ start: testStarted, end: testEnded || currentTime });

		return formatDuration(duration, {
			format: ['hours', 'minutes', 'seconds'],
			zero: true,
			delimiter: ':',
			locale: {
				formatDistance: (_token, count) => zeroPad(count)
			}
		});
	}, [initialState, testStarted, testEnded, currentTime]);

	const { tests } = useTests(metadata.isType);
	const testOptions = useMemo(() => tests?.map((t) => testDtoToTestOption(t)) || [], [tests]);

	useEffect(() => {
		if (testInfo.state === TestProp.STARTED || testInfo.state === TestProp.RESUMED) {
			setTestStarted(Date.now());
			setTestEnded(null);
		}
		if (testInfo.state === TestProp.STOPPED) {
			setTestEnded(Date.now());
			resetEvents();
		}
	}, [resetEvents, testInfo.state]);

	useEffect(() => {
		if (isTestRunning) {
			const intervalId = setInterval(() => setCurrentTime(Date.now()), 1000);
			return () => clearInterval(intervalId);
		}
	}, [isTestRunning]);

	// when opening dashboard of already running test
	useEffect(() => {
		if (testInfo.specimenId && specimenId === null) {
			navigate(`/tests/${testInfo.testId}/dashboard?specimenId=${testInfo.specimenId}`);
		}
	}, [testInfo, specimenId, navigate]);

	const onTestChange = useCallback(
		(value: string | null) => {
			const test = tests?.find(({ id }) => id === value);

			if (test) {
				const specimenParams =
					test?.specimenTypeId !== specimen?.specimenType?.id ? '' : `?specimenId=${specimen.uuid}`;
				navigate(`/tests/${test.id}/dashboard${specimenParams}`);
			}
		},
		[specimen, tests, navigate]
	);

	const onSelectSpecimenClick = useCallback(() => {
		Modal.show(specimenSelectionModalId, { isRunTest: false });
	}, []);

	return (
		<>
			<WidgetHeader>{t('Test Information')}</WidgetHeader>
			<WidgetContent className={styles.widgetContent} isType={metadata.isType}>
				<div className={cx(styles.container, { [styles.sm]: width < 370 })}>
					<div className={styles.config}>
						<div>
							<Dropdown
								className={styles.dropdown}
								name="test"
								options={testOptions}
								onChange={onTestChange}
								value={testId}
								filter
							/>
						</div>
						<div>
							<div className={styles.specimen}>
								<p>{!specimenId ? t('Select Specimen') : specimen?.specimenId}</p>
								<Icon name="edit" size={18} onClick={onSelectSpecimenClick} />
							</div>
						</div>
					</div>
					<div className={styles.info}>
						<div>
							<div className={styles.testInfoValue}>{runningTime}</div>
							<div className={styles.testInfoLabel}>{t('Running time')}</div>
						</div>
						<div>
							{isTestEnded ? (
								<div className={styles.testEnded}>{t('Test is completed')}</div>
							) : (
								<>
									{/*<div className={`${styles.testInfoValue} ${styles.estimatedValue}`}>{estimatedTime}</div>*/}
									{/*<div className={styles.testInfoLabel}>{t('Estimated remaining time')}</div>*/}
									<div className={styles.testInfoLabel}></div>
								</>
							)}
						</div>
					</div>
				</div>
			</WidgetContent>
		</>
	);
};

export default memo(TestInformation);
