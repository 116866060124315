import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import RGL, { Layout, WidthProvider } from 'react-grid-layout';
import cx from 'classnames';
import { Button, usePreviewsValue } from '@tactun/ui';

import Widget from '../../../Dashboard/components/Widget/Widget';
import { WidgetTypes } from '../../../Dashboard/dashboard.enums';
import WidgetHeader from '../../../Dashboard/components/Widget/WidgetHeader';
import WidgetContent from '../../../Dashboard/components/Widget/WidgetContent';
import { ICustomWidgetForm } from '../../customWidget.types';
import { WidgetsLayoutTypes } from '../../customWidget.enums';
import ButtonWidgetSubframePreview from '../../../ButtonWidget/components/ButtonWidgetSubframePreview/ButtonWidgetSubframePreview';
import styles from './CustomWidgetPreview.module.scss';
import { FrameOrientation } from '../../../ButtonWidget/buttonWidget.enums';
import { useWidgetSizes } from '../../customWidget.hooks';
import { getMinSizes, getNewProportion } from '../../customWidget.tools';
import { sizeToSubframeSizes } from '../../customWidget.converters';
import classNames from 'classnames';

const ReactGridLayout = WidthProvider(RGL);

interface WidgetPreviewProps {}

const CustomWidgetPreview: React.FC<WidgetPreviewProps> = () => {
	const { t } = useTranslation('widgets');

	const { watch, setValue, control, getValues } = useFormContext<ICustomWidgetForm>();

	const name = useWatch({ name: 'name', control });
	const activeSubframe = useWatch({ name: 'activeSubframe', control });
	const activeButton = useWatch({ name: 'activeButton', control });
	const widgetW = useWatch({ name: 'width', control });
	const widgetH = useWatch({ name: 'height', control });
	const layoutType = useWatch({ name: 'layoutType', control });
	const subFrames = useWatch({ name: 'subFrames', control });
	const subFrameSize = useWatch({ name: 'subFrameSize', control });

	const ref = useRef<HTMLDivElement | null>(null);
	const {
		getValidContentHeight,
		getValidContentWidth,
		contentHeightToWidgetRow,
		contentWidthToWidgetCol,
		getContentWidth,
		getContentHeight
	} = useWidgetSizes(ref, {
		hasHeader: true,
		hasFooter: true
	});

	const minimums = useMemo(
		() => getMinSizes(subFrames, [subFrameSize?.[0] || 100, subFrameSize?.[1] || 100], layoutType),
		[subFrames, subFrameSize, layoutType]
	);
	const layoutList = useMemo(() => {
		return [
			{
				x: 12 - Math.floor(widgetW / 2),
				y: 0,
				w: widgetW,
				h: widgetH,
				minW: contentWidthToWidgetCol(minimums.minWidth),
				minH: contentHeightToWidgetRow(minimums.minHeight),
				i: '1'
			}
		];
	}, [widgetW, widgetH, contentWidthToWidgetCol, minimums.minWidth, minimums.minHeight, contentHeightToWidgetRow]);

	// Subframe resize
	const [width, setWidth] = useState(subFrameSize?.[0] || 100);
	const [height, setHeight] = useState(subFrameSize?.[1] || 100);
	useEffect(() => {
		setWidth(subFrameSize?.[0] || 100);
		setHeight(subFrameSize?.[1] || 100);
	}, [subFrameSize]);
	const subFrameSizes = useMemo(
		() => sizeToSubframeSizes(subFrameSize?.[0] || 100, subFrameSize?.[1] || 100, layoutType),
		[layoutType, subFrameSize]
	);

	const containerRef = useRef<HTMLDivElement>(null);

	const refSize = useRef({ minimums, width, height });
	refSize.current = { minimums, width, height };

	const handleVerticalMouseMove = useCallback((e: MouseEvent) => {
		e.preventDefault();
		if (containerRef.current) {
			const containerRect = containerRef.current.getBoundingClientRect();
			const containerHeight = containerRect.height;
			let offset = e.clientY - containerRect.top;
			offset = refSize.current.minimums.subFrameHeight
				? Math.max(refSize.current.minimums.subFrameHeight[0], offset)
				: offset;
			offset = refSize.current.minimums.subFrameHeight
				? Math.min(containerHeight - refSize.current.minimums.subFrameHeight[1], offset)
				: offset;
			const newHeight = Math.round((offset / containerHeight) * 100);
			setHeight(newHeight);
		}
	}, []);

	const handleHorizontalMouseMove = useCallback((e: MouseEvent) => {
		e.preventDefault();
		if (containerRef.current) {
			const containerRect = containerRef.current.getBoundingClientRect();
			const containerWidth = containerRect.width;
			let offset = e.clientX - containerRect.left;
			offset = refSize.current.minimums.subFrameWidth
				? Math.max(refSize.current.minimums.subFrameWidth[0], offset)
				: offset;
			offset = refSize.current.minimums.subFrameWidth
				? Math.min(containerWidth - refSize.current.minimums.subFrameWidth[1], offset)
				: offset;
			const newWidth = Math.round((offset / containerWidth) * 100);
			setWidth(newWidth);
		}
	}, []);

	const { width: widthPx, height: heightPx } = containerRef.current?.getBoundingClientRect() || { width: 0, height: 0 };

	const handleMouseUp = useCallback(
		(e: MouseEvent) => {
			e.preventDefault();
			const { width, height } = refSize.current;
			setValue('subFrameSize', [width, height]);
			document.removeEventListener('mousemove', handleVerticalMouseMove);
			document.removeEventListener('mousemove', handleHorizontalMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
		},
		[handleHorizontalMouseMove, handleVerticalMouseMove, setValue]
	);

	const handleVerticalMouseDown = useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault();
			document.addEventListener('mousemove', handleVerticalMouseMove);
			document.addEventListener('mouseup', handleMouseUp);
		},
		[handleVerticalMouseMove, handleMouseUp]
	);

	const handleHorizontalMouseDown = useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault();
			document.addEventListener('mousemove', handleHorizontalMouseMove);
			document.addEventListener('mouseup', handleMouseUp);
		},
		[handleHorizontalMouseMove, handleMouseUp]
	);

	const hasVerticalResize =
		layoutType === WidgetsLayoutTypes.DOUBLE_VERTICAL || layoutType === WidgetsLayoutTypes.QUADRO;
	const hasHorizontalResize =
		layoutType === WidgetsLayoutTypes.DOUBLE_HORIZONTAL || layoutType === WidgetsLayoutTypes.QUADRO;

	// TODO this is only for widget type button, we should move this to the button widget preview
	const subFrame = activeSubframe !== undefined ? subFrames?.[activeSubframe] : undefined;

	const hasActiveButton = activeButton !== undefined && activeButton !== null;

	const toggleOrientation = useCallback(() => {
		if (activeSubframe === undefined) return;
		setValue(
			`subFrames.${activeSubframe}.orientation`,
			subFrame?.orientation === FrameOrientation.HORIZONTAL ? FrameOrientation.VERTICAL : FrameOrientation.HORIZONTAL
		);
	}, [activeSubframe, setValue, subFrame?.orientation]);

	const numberOfButtons = subFrame?.numberOfButtons ? subFrame?.numberOfButtons + '' : '0';
	const deleteButton = useCallback(() => {
		if (activeSubframe === undefined) return;
		const newButtons = subFrame?.buttons?.filter((_, index) => index !== activeButton) || [];

		setValue('activeButton', null);
		setValue(`subFrames.${activeSubframe}.buttons`, newButtons);
		setValue(`subFrames.${activeSubframe}.numberOfButtons`, parseInt(numberOfButtons, 10) - 1);
	}, [subFrame?.buttons, setValue, activeSubframe, numberOfButtons, activeButton]);

	const duplicateButton = useCallback(() => {
		if (!subFrame?.buttons || activeSubframe === undefined || activeButton === undefined || activeButton === null)
			return;
		const button = { ...subFrame?.buttons?.[activeButton], id: undefined };
		const newButtons =
			[...subFrame.buttons.slice(0, activeButton), button, ...subFrame?.buttons.slice(activeButton)] || [];

		setValue(`subFrames.${activeSubframe}.buttons`, newButtons);
		setValue(`subFrames.${activeSubframe}.numberOfButtons`, parseInt(numberOfButtons, 10) + 1);
	}, [subFrame, activeSubframe, activeButton, setValue, numberOfButtons]);

	const updateSubFrameSize = useCallback(
		(index: number, { width, height }: { width?: number; height?: number }) => {
			// make subframe with index `index` to givven width, height
			const subFrameSize = getValues('subFrameSize');
			const newSubFrameSize = [...(subFrameSize || [100, 100])];
			const contentWidth = getContentWidth(watch('width'));
			const contentHeight = getContentHeight(watch('height'));
			// const { width: widgetWidth, height: widgetHeight } = containerRef.current.getBoundingClientRect();
			const [widthPrc = 100, heightPrc = 100] = newSubFrameSize;

			if (width) {
				const isLeftSubFrame = index === 0 || index === 2;
				const oldSubFrameWidth = ((isLeftSubFrame ? widthPrc : 100 - widthPrc) * contentWidth) / 100;
				const newContentWidth = contentWidth + width - oldSubFrameWidth;

				if (widthPrc !== 100) {
					const validContentWidth = getValidContentWidth(newContentWidth);
					const newWidthPrc = getNewProportion(
						isLeftSubFrame ? 100 - widthPrc : widthPrc,
						contentWidth,
						validContentWidth
					);
					newSubFrameSize[0] = isLeftSubFrame ? 100 - newWidthPrc : newWidthPrc;
				}

				const newColW = contentWidthToWidgetCol(newContentWidth);
				if (watch('width') !== newColW) {
					setValue('width', newColW);
				}
			}
			if (height) {
				const isTopSubFrame = index === 0 || (index === 1 && layoutType !== WidgetsLayoutTypes.DOUBLE_VERTICAL);
				const oldSubFrameHeight = ((isTopSubFrame ? heightPrc : 100 - heightPrc) * contentHeight) / 100;
				const newContentHeight = contentHeight + height - oldSubFrameHeight;

				if (heightPrc !== 100) {
					const validContentHeight = getValidContentHeight(newContentHeight);
					const newHeightPrc = getNewProportion(
						isTopSubFrame ? 100 - heightPrc : heightPrc,
						contentHeight,
						validContentHeight
					);
					newSubFrameSize[1] = isTopSubFrame ? 100 - newHeightPrc : newHeightPrc;
				}
				const newRowH = contentHeightToWidgetRow(newContentHeight);

				if (watch('height') !== newRowH) {
					setValue('height', newRowH);
				}
			}

			setValue('subFrameSize', newSubFrameSize);
		},
		[
			contentHeightToWidgetRow,
			contentWidthToWidgetCol,
			getContentHeight,
			getContentWidth,
			getValidContentHeight,
			getValidContentWidth,
			getValues,
			layoutType,
			setValue,
			watch
		]
	);

	// Update widget size on layout change
	const previousLayout = usePreviewsValue(layoutType);
	useEffect(() => {
		if (layoutType === previousLayout) return;
		const { width, height, subFrameSize } = getValues();
		const subFrameWidth = subFrameSize?.[0] || 100;
		const subFrameHeight = subFrameSize?.[1] || 100;

		const cols = layoutType === WidgetsLayoutTypes.SINGLE || layoutType === WidgetsLayoutTypes.DOUBLE_VERTICAL ? 1 : 2;
		const rows =
			layoutType === WidgetsLayoutTypes.SINGLE || layoutType === WidgetsLayoutTypes.DOUBLE_HORIZONTAL ? 1 : 2;
		const newSubFrameSize = [subFrameWidth, subFrameHeight];

		if (previousLayout === undefined && !width && !height) {
			setValue('width', contentWidthToWidgetCol(cols * 80));
			setValue('height', contentHeightToWidgetRow(rows * 80));
			newSubFrameSize[0] = cols === 1 ? 100 : 50;
			newSubFrameSize[1] = rows === 1 ? 100 : 50;
		} else if (previousLayout !== undefined) {
			const prevCols =
				previousLayout === WidgetsLayoutTypes.SINGLE || previousLayout === WidgetsLayoutTypes.DOUBLE_VERTICAL ? 1 : 2;
			const prevRows =
				previousLayout === WidgetsLayoutTypes.SINGLE || previousLayout === WidgetsLayoutTypes.DOUBLE_HORIZONTAL ? 1 : 2;

			if (prevCols !== cols) {
				const currentContentWidth = getContentWidth(width);
				const newWidgetWidth = contentWidthToWidgetCol(
					prevCols > cols ? (subFrameWidth * currentContentWidth) / 100 : currentContentWidth * 2
				);
				setValue('width', Math.min(newWidgetWidth, 24));
				if (cols > prevCols) newSubFrameSize[0] = 50;
			}
			if (prevRows !== rows) {
				const currentContentHeight = getContentHeight(height);
				const newWidgetHeight = contentHeightToWidgetRow(
					prevRows > rows ? (subFrameHeight * currentContentHeight) / 100 : currentContentHeight * 2
				);
				setValue('height', Math.min(newWidgetHeight, 24));
				if (rows > prevRows) newSubFrameSize[1] = 50;
			}
		}
		setValue('subFrameSize', newSubFrameSize);
	}, [
		previousLayout,
		layoutType,
		setValue,
		getValues,
		contentWidthToWidgetCol,
		contentHeightToWidgetRow,
		getContentWidth,
		getContentHeight
	]);

	const onLayoutChange = useCallback(
		(newLayout: Layout[]) => {
			const newLayoutItem = newLayout[0];
			if (newLayoutItem.w) setValue('width', newLayoutItem.w);
			if (newLayoutItem.h) setValue('height', newLayoutItem.h);
		},
		[setValue]
	);
	const gutter = useMemo(() => {
		return window.innerWidth >= 1920 ? 16 : 12;
	}, []);

	return (
		<div className={styles.previewContainer}>
			<div className={styles.title}>{t('Widget preview')}</div>
			<div className={styles.previewContent}>
				{activeSubframe !== undefined && (
					<div className={styles.actionBarContainer}>
						{!hasActiveButton && (
							<Button
								label={t('Direction')}
								variant="text"
								color="success"
								icon="t-icon-sorting-down"
								onClick={toggleOrientation}
								className={styles.btn}
								pt={{
									icon: {
										className:
											subFrame?.orientation === FrameOrientation.HORIZONTAL ? styles.btnIcon : styles.btnIconRotate
									}
								}}
							/>
						)}
						{hasActiveButton && (
							<>
								<Button
									label={t('Duplicate')}
									variant="text"
									color="success"
									icon="t-icon-duplicate"
									onClick={duplicateButton}
									disabled={numberOfButtons === '6'}
									className={styles.btn}
									pt={{
										icon: { className: styles.btnIcon }
									}}
								/>
								<Button
									label={t('Delete')}
									variant="text"
									color="success"
									icon="t-icon-delete"
									onClick={deleteButton}
									className={styles.btn}
									pt={{
										icon: { className: styles.btnIcon }
									}}
								/>
							</>
						)}
					</div>
				)}
				{layoutType !== undefined && (
					<div className={styles.hintText}>
						<span className={styles.boldText}>{t('Hint')}:</span>{' '}
						{t('Click on a subframe / button to configure. Drag a subframe / button to re-order.')}
					</div>
				)}
				<div className={styles.container} ref={ref}>
					<ReactGridLayout
						draggableHandle=".overlay-widget-header"
						className={classNames(styles.grid, {
							[styles.hideWidget]: layoutType === undefined
						})}
						cols={24}
						rowHeight={32}
						compactType="vertical"
						margin={[gutter, 16]}
						preventCollision={true}
						layout={layoutList}
						onLayoutChange={onLayoutChange}
					>
						<div key="1">
							{layoutType !== undefined && (
								<Widget
									id="1"
									widget={{
										...layoutList[0],
										metadata: {},
										noResize: false,
										type: WidgetTypes.BUTTON_WIDGET
									}}
									className={styles.widget}
								>
									<WidgetHeader disableRemove disableMinimize>
										{name}
									</WidgetHeader>
									<WidgetContent className={styles.widgetContainer} showFooter>
										<div
											className={cx(styles.subFramesContainer, {
												[styles.singleLayout]: layoutType === WidgetsLayoutTypes.SINGLE,
												[styles.doubleVertical]: layoutType === WidgetsLayoutTypes.DOUBLE_VERTICAL,
												[styles.doubleHorizontal]: layoutType === WidgetsLayoutTypes.DOUBLE_HORIZONTAL,
												[styles.quadro]: layoutType === WidgetsLayoutTypes.QUADRO
											})}
											ref={containerRef}
										>
											{hasVerticalResize && (
												<div
													className={styles.handlerVertical}
													style={{ top: `${height}%` }}
													onMouseDown={handleVerticalMouseDown}
												/>
											)}
											{hasHorizontalResize && (
												<div
													className={styles.handlerHorizontal}
													style={{ left: `${width}%` }}
													onMouseDown={handleHorizontalMouseDown}
												/>
											)}
											{subFrames?.map((subFrame, index) => (
												<div
													key={index}
													style={{
														height: hasVerticalResize
															? `${Math.abs(
																	(layoutType === WidgetsLayoutTypes.QUADRO ? (index < 2 ? 0 : 1) : index) * 100 -
																		height
															  )}%`
															: '100%',
														width: hasHorizontalResize ? `${Math.abs((index % 2) * 100 - width)}%` : '100%'
													}}
													onClick={() => {
														if (!subFrame.buttons?.length) {
															setValue('activeButton', null);
															setValue('activeSubframe', index);
														}
													}}
													className={cx(styles.subFrame, {
														[styles.subFrame1]: index === 0,
														[styles.subFrame2]: index === 1,
														[styles.subFrame3]: index === 2,
														[styles.subFrame4]: index === 3,
														[styles.horizontal]: subFrame.orientation === FrameOrientation.HORIZONTAL,
														[styles.vertical]: subFrame.orientation === FrameOrientation.VERTICAL,
														[styles.selected]: activeSubframe === index && !hasActiveButton,
														[styles.selectedWithButton]: activeSubframe === index && hasActiveButton
													})}
												>
													<ButtonWidgetSubframePreview
														width={subFrameSizes[index] ? (subFrameSizes[index][0] * widthPx) / 100 : 0}
														height={subFrameSizes[index] ? (subFrameSizes[index][1] * heightPx) / 100 : 0}
														blockWidth={
															layoutType === WidgetsLayoutTypes.QUADRO ||
															layoutType === WidgetsLayoutTypes.DOUBLE_VERTICAL
														}
														blockHeight={
															layoutType === WidgetsLayoutTypes.QUADRO ||
															layoutType === WidgetsLayoutTypes.DOUBLE_HORIZONTAL
														}
														updateSize={updateSubFrameSize}
														index={index}
														selected={activeSubframe === index}
														subFrame={subFrame}
													/>
												</div>
											))}
										</div>
									</WidgetContent>
								</Widget>
							)}
						</div>
					</ReactGridLayout>
				</div>
			</div>
		</div>
	);
};

export default CustomWidgetPreview;
