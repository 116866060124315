import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReadoutAddExisting from './ReadoutAddExisting';
import { readoutFormToWidgetSettings } from '../../readout.converters';
import { readoutFormSchema } from '../../readout.schemas';
import { WidgetSettingsStates } from '../../../Dashboard/dashboard.enums';
import AddWidgetSettings from '../../../Dashboard/components/AddWidgetSettings';
import { ReadoutForm } from '../../../TestDashboardConfiguration';
import { WidgetSettingsModalProps } from '../../../Dashboard/dashboard.types';
import { useTest } from '../../../Tests/tests.hooks';
import { readoutDefaultValues } from '../../readout.constants';
import styles from './ReadoutSettingsModal.module.scss';
import addReadoutImage from '../../assets/add-readout.svg';

export const ReadoutSettingsModal: FC<WidgetSettingsModalProps> = (props) => {
	const { onCancel, onSave, isType } = props;
	const { t } = useTranslation('dashboard');

	const { testId, stationId: stationIdParam } = useParams();
	const { testDto } = useTest(testId);
	const stationId = stationIdParam || testDto?.stationId;

	// const initialState = testId ? WidgetSettingsStates.INITIAL : WidgetSettingsStates.CREATE_NEW;
	const [state, setState] = useState<WidgetSettingsStates>(WidgetSettingsStates.CREATE_NEW);
	const form = useForm({
		mode: 'onBlur',
		defaultValues: { ...readoutDefaultValues, isType },
		resolver: yupResolver(readoutFormSchema)
	});

	const { handleSubmit } = form;

	const handleSave = useCallback(() => {
		handleSubmit((data) => {
			onSave(readoutFormToWidgetSettings(data), {
				channel: data.dataChannel[0]
			});
		})();
	}, [handleSubmit, onSave]);

	const onAddExisting = () => {
		setState(WidgetSettingsStates.ADD_EXISTING);
	};
	const onCreateNew = () => {
		setState(WidgetSettingsStates.CREATE_NEW);
	};

	return (
		<>
			<Modal.Header>
				{state === WidgetSettingsStates.INITIAL && t('Readout widget')}
				{state === WidgetSettingsStates.ADD_EXISTING && t('Add existing readout')}
				{state === WidgetSettingsStates.CREATE_NEW && t('Create new readout')}
			</Modal.Header>
			<Modal.Content>
				<div className={styles.contentWrapper}>
					{state === WidgetSettingsStates.INITIAL && (
						<AddWidgetSettings
							resource="Readout"
							onAddExisting={onAddExisting}
							onAddNew={onCreateNew}
							image={addReadoutImage}
						/>
					)}
					{state === WidgetSettingsStates.CREATE_NEW && (
						<ReadoutForm stationId={stationId} form={form} onSave={handleSave} isType={isType} />
					)}
					{state === WidgetSettingsStates.ADD_EXISTING && <ReadoutAddExisting form={form} />}
				</div>
			</Modal.Content>
			{state !== WidgetSettingsStates.INITIAL && (
				<Modal.Footer>
					{/*{initialState === WidgetSettingsStates.INITIAL ? (*/}
					{/*	<Button onClick={() => setState(WidgetSettingsStates.INITIAL)} color="success" variant="text">*/}
					{/*		{t('Back')}*/}
					{/*	</Button>*/}
					{/*) : (*/}
					<Button onClick={onCancel} color="success" variant="text">
						{t('Cancel')}
					</Button>
					{/*)}*/}
					<Button onClick={handleSave} color="secondary" variant="contained">
						{t('Save')}
					</Button>
				</Modal.Footer>
			)}
		</>
	);
};

export default ReadoutSettingsModal;
