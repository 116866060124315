import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IActuatorListItem } from '../actuators.types';
import ActuatorsTable from '../components/ActuatorsTable';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { ActuatorCrudActionTypes } from '../actuators.enums';
import { useStationName } from '../../Stations/stations.hooks';
import { responseListItemConverter } from '../actuators.converters';
import { useActuators } from '../actuators.hooks';
import { useTranslation } from 'react-i18next';

const ActuatorsTableContainer: FC = () => {
	const { t } = useTranslation('actuators');
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const { stationId } = useParams();
	const { stationName } = useStationName(stationId);
	const { actuators, isActuatorsLoading, deleteActuator } = useActuators(stationId);
	const actuatorsListItem = useMemo(
		() => actuators?.map((actuator) => responseListItemConverter(actuator)),
		[actuators]
	);

	const handleAction = useCallback(
		(type: ActuatorCrudActionTypes, data?: IActuatorListItem) => {
			switch (type) {
				case ActuatorCrudActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case ActuatorCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case ActuatorCrudActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case ActuatorCrudActionTypes.DELETE:
					setEntityForDelete(data as IEntity);
					break;
				case ActuatorCrudActionTypes.OPEN_CALIBRATION:
					navigate(`./${data?.id}/calibration/`);
					break;
			}
		},
		[navigate]
	);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleteError = useCallback(
		(status: string) => {
			switch (status) {
				case '1':
					toast.error(t('Actuator is not found.'));
					break;
				case '2':
					toast.error(t('The actuator is used in an Axes. Remove the actuator from the Axes to be able to delete it.'));
					break;
				default:
					toast.error(t('Actuator is not deleted. Unknown error.'));
			}
		},
		[t]
	);

	const handlePostDelete = useCallback(
		(id: string) => {
			deleteActuator(id);
			handleDeleteCancel();
		},
		[deleteActuator, handleDeleteCancel]
	);

	return (
		<>
			<ActuatorsTable
				onAction={handleAction}
				data={actuatorsListItem}
				isLoading={isActuatorsLoading}
				stationName={stationName}
			/>
			<EntityDeleter
				onError={handleDeleteError}
				onDeleted={handlePostDelete}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.ACTUATOR}
				entity={entityForDelete}
				checkUsage
			/>
		</>
	);
};

export default ActuatorsTableContainer;
