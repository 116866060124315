import React from 'react';
import cx from 'classnames';
import { ISubframeForm } from '../../../CustomWidgets';
import { FrameOrientation } from '../../buttonWidget.enums';

import styles from './CustomButtonGroup.module.scss';

interface CustomButtonGroupProps {
	subFrame: ISubframeForm;
	children: React.ReactNode;
}

const CustomButtonGroup: React.FC<CustomButtonGroupProps> = ({ subFrame, children }) => {
	return (
		<div
			className={cx(styles.subFrame, {
				[styles.vertical]: subFrame.orientation === FrameOrientation.VERTICAL,
				[styles.horizontal]: subFrame.orientation === FrameOrientation.HORIZONTAL
			})}
		>
			{children}
		</div>
	);
};

export default React.memo(CustomButtonGroup);
