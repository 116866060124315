import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePreviewsValue } from '@tactun/ui';
import { useOnce } from '../../../hooks';
import { useQuantities } from '../../Units/units.hooks';
import { useStationName } from '../../Stations/stations.hooks';
import { unitConvertor } from '../../../tools/unitConvertor';
import { actCalibrationFormRequestConverter, actCalibrationResponseFormConverter } from '../actuators.converters';
import { useActuator, useActuatorsCalibration } from '../actuators.hooks';
import { actuatorCalibrationFormSchema } from '../actuators.schemas';
import * as api from '../actuators.api';
import { IActuatorCalibrationForm, IActuatorCalibrationMeta, IActuatorCalibrationRequestDto } from '../actuators.types';
import ActuatorCalibrationForm from '../components/ActuatorCalibrationForm';
import { ActuatorCalibrationTypes } from '../actuators.enums';
import { ChannelType, Range, offsetFormatter } from '../../StationChannels';

const defaultValues: Partial<IActuatorCalibrationForm> = {
	type: ActuatorCalibrationTypes.PIECEWISE,
	unitId: undefined,
	actuatorId: undefined,
	calibrationData: []
};

const ActuatorsCalibrationContainer: React.FC = () => {
	const navigate = useNavigate();
	const { stationId, actuatorId } = useParams();
	const { stationName } = useStationName(stationId);
	const { actuatorDto } = useActuator(actuatorId);
	const { calibrationDto } = useActuatorsCalibration(actuatorId);

	const initTrigger = useOnce(!!calibrationDto);
	const form = useForm<IActuatorCalibrationForm>({
		defaultValues: { ...defaultValues, actuatorId },
		mode: 'onBlur',
		resolver: yupResolver(actuatorCalibrationFormSchema)
	});
	const { handleSubmit, reset, getValues, setValue } = form;
	const unitId = form.watch('unitId');
	const { quantities } = useQuantities('USER');

	const createMutation = useMutation({
		mutationFn: (calibration: IActuatorCalibrationRequestDto) => api.createCalibration(calibration),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { calibration: IActuatorCalibrationRequestDto; calibrationId: string }) =>
			api.updateCalibration(data.calibration, data.calibrationId),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/actuators`);
	}, [navigate, stationId]);

	const handleReset = useCallback(() => {
		if (calibrationDto) {
			reset(
				{ ...defaultValues, ...actCalibrationResponseFormConverter(calibrationDto), actuatorId },
				{
					keepDirty: true,
					keepTouched: true
				}
			);
		} else {
			reset({ ...defaultValues, actuatorId }, { keepDirty: true, keepTouched: true });
		}
	}, [calibrationDto, reset, actuatorId]);

	const handleSave = handleSubmit((data) => {
		const filteredData = actCalibrationFormRequestConverter(data);

		if (calibrationDto) {
			updateMutation.mutate({
				calibration: filteredData,
				calibrationId: calibrationDto.id
			});
		} else {
			createMutation.mutate(filteredData);
		}
	});

	// Handle Unit change
	const prevUnitId = usePreviewsValue(unitId);
	useEffect(() => {
		if (prevUnitId !== undefined && unitId !== prevUnitId) {
			const currentQuantity = quantities?.find((q) => q.units.some((u) => u.id === unitId));
			const currentUnit = currentQuantity?.units.find((u) => u.id === unitId);
			const previewsUnit = currentQuantity?.units.find((u) => u.id === prevUnitId);

			if (currentUnit && previewsUnit) {
				const currentFormData = getValues();
				const newData = currentFormData.calibrationData.map((d) => ({
					usd: d.usd,
					csd: unitConvertor(d.csd, previewsUnit, currentUnit)
				}));
				setValue('calibrationData', newData);
			}
		}
	}, [getValues, prevUnitId, quantities, setValue, unitId]);

	// Setup data for edit
	useEffect(() => {
		if (initTrigger) {
			handleReset();
		}
	}, [initTrigger, handleReset]);

	useEffect(() => {
		return () => {
			reset();
		};
	}, [reset]);

	const calibrationMeta = useMemo<null | IActuatorCalibrationMeta>(() => {
		if (actuatorDto?.stationChannel) {
			if (
				actuatorDto.stationChannel.type === ChannelType.ANALOG_OUT_10V ||
				actuatorDto.stationChannel.type === ChannelType.ANALOG_OUT_10V_24MA ||
				actuatorDto.stationChannel.type === ChannelType.ANALOG_OUT_300MA ||
				actuatorDto.stationChannel.type === ChannelType.ANALOG_OUT_100MA
			) {
				const rangeForParse = Range[actuatorDto.stationChannel.range];
				const { min, unit } = offsetFormatter(rangeForParse);
				return {
					unit: unit as 'V' | 'mA',
					lowerLimit: min,
					upperLimit: actuatorDto.stationChannel.limit
				};
			}
		}
		return null;
	}, [actuatorDto]);

	return (
		<>
			{actuatorDto ? (
				<ActuatorCalibrationForm
					isCreate={calibrationDto === undefined}
					isLoading={createMutation.isPending || updateMutation.isPending}
					form={form}
					actuatorCalibrationMeta={calibrationMeta}
					onBack={handleBack}
					onSave={handleSave}
					quantities={quantities || []}
					actuatorName={actuatorDto?.name}
					stationName={stationName}
				/>
			) : null}
		</>
	);
};

export default React.memo(ActuatorsCalibrationContainer);
