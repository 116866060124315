import React, { useMemo } from 'react';
import { Button as PrimButton, ButtonProps } from 'primereact/button';
import cx from 'classnames';
import styles from './Button.module.scss';

export interface IButton extends ButtonProps {
	variant?: 'contained' | 'outlined' | 'text';
	color?: 'success' | 'primary' | 'secondary' | 'danger';
	rounded?: boolean;
	icon?: string;
}

const Button: React.FC<IButton> = ({
	rounded = false,
	className,
	variant = 'contained',
	color = 'primary',
	icon,
	...rest
}) => {
	const varianClassName = useMemo(() => {
		switch (variant) {
			case 'text':
				return 'p-button-text';
			case 'outlined':
				return 'p-button-outlined';
			default:
				return null;
		}
	}, [variant]);

	const colorClassName = useMemo(() => {
		switch (color) {
			case 'primary':
				return 'p-button-primary';
			case 'success':
				return 'p-button-success';
			case 'secondary':
				return 'p-button-secondary';
			case 'danger':
				return 'p-button-danger';
			default:
				return 'p-button-primary';
		}
	}, [color]);

	return (
		<PrimButton
			{...rest}
			className={cx(styles.button, varianClassName, colorClassName, className, { 'p-button-rounded': rounded })}
			icon={icon ? cx({ pi: !!icon }, icon) : undefined}
		/>
	);
};
Button.displayName = 'Button';

export default React.memo(Button);
