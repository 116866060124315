import { KebabMenu } from '@tactun/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SpecimenTest } from '../../specimens.types';
import SpecimenStatusLabel from '../SpecimenStatusLabel';
import styles from './SpecimenTableRowContent.module.scss';

interface UnitTableRowContentProps {
	specimenTests: SpecimenTest[];
	createMenuItems: any;
}

const UnitTableRowContent: React.FC<UnitTableRowContentProps> = ({ specimenTests, createMenuItems }) => {
	const { t } = useTranslation('units');

	return (
		<div className={styles.bodyWrap}>
			{specimenTests.map((test) => (
				<div className={styles.row}>
					<div className={styles.cell}>
						<div className={styles.label}>{t('Specimen ID: ')}</div>
						<div className={styles.value}>{test.specimenTest.id}</div>
					</div>
					<div className={styles.cell}>
						<div className={styles.label}>{t('Specimen Status: ')}</div>
						<div className={styles.value}>
							<SpecimenStatusLabel status={test.specimenTest.specimenTestStatus} />
						</div>
					</div>
					<div className={styles.cell}>
						<div className={styles.label}>{t('Test Date: ')}</div>
						<div className={styles.value}>{test.createdAt}</div>
					</div>
					<div className={styles.cell}>
						<KebabMenu model={createMenuItems(test)} />
					</div>
				</div>
			))}
		</div>
	);
};

export default React.memo(UnitTableRowContent);
