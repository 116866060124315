import React, { forwardRef, Ref } from 'react';
import { Checkbox as PrimreactCheckbox, CheckboxProps as PrimreactCheckboxProps } from 'primereact/checkbox';
import styles from './Checkbox.module.scss';

export interface CheckboxProps extends PrimreactCheckboxProps {
	label?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = forwardRef(({ className, label, ...rest }, ref: Ref<HTMLInputElement>) => {
	return (
		<div className={className}>
			{label && <div className={styles.checkboxLabel}>{label}</div>}
			<PrimreactCheckbox className={styles.field} inputRef={ref} {...rest} />
		</div>
	);
});
Checkbox.displayName = 'Checkbox';

export default React.memo(Checkbox);
