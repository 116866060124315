export enum EntityTypes {
	STATION_CHANNEL = 'STATION_CHANNEL',
	STATION = 'STATION',
	TEST = 'TEST',
	ACTUATOR = 'ACTUATOR',
	AXES = 'AXES',
	WIDGETS = 'WIDGETS',
	CALCULATION = 'CALCULATION',
	MEASUREMENT = 'MEASUREMENT',
	CONTROL_CHANNEL = 'CONTROL_CHANNEL',
	SENSOR = 'SENSOR',
	VARIABLE = 'VARIABLE',
	QUANTITY = 'QUANTITY',
	SPECIMEN = 'SPECIMEN',
	SPECIMEN_TYPE = 'SPECIMEN_TYPE',
	UNIT = 'UNIT',
	AUXILIARY_DEVICE = 'AUXILIARY_DEVICE',
	TEST_RULE = 'TEST_RULE',
	RULE = 'RULE',
	READOUT = 'READOUT',
	GRAPH = 'GRAPH',
	TEST_VARIABLE = 'TEST_VARIABLE',
	TEST_DATA_LOGGING = 'TEST_DATA_LOGGING',
	TEST_ACTIONS = 'TEST_ACTIONS',
	TEST_LOOP = 'TEST_LOOP',
	CUSTOM_WIDGETS = 'CUSTOM_WIDGETS',
	PRODUCTS = 'PRODUCTS',
	CONTROLLERS = 'CONTROLLERS',
	DASHBOARD_TYPE = 'DASHBOARD_TYPE'
}
