import PageHeader, { IPageHeaderProps } from './PageHeader';
import PageFooter, { IPageFooterProps } from './PageFooter';
import PageFilters, { IPageFiltersProps } from './PageFilters';
import styles from './PageLayout.module.scss';

interface IPageLayoutProps {
	info?: React.ReactNode;
	children: React.ReactNode;
}

interface IPageLayoutSubComponents {
	Header: React.FC<IPageHeaderProps>;
	Footer: React.FC<IPageFooterProps>;
	Filters: React.FC<IPageFiltersProps>;
}

const PageLayout: React.FC<IPageLayoutProps> & IPageLayoutSubComponents = ({ children, info }) => {
	return (
		<div className={styles.container}>
			<div className={styles.info}>{info}</div>
			{children}
		</div>
	);
};

PageLayout.displayName = 'PageLayout';
PageLayout.Header = PageHeader;
PageLayout.Footer = PageFooter;
PageLayout.Filters = PageFilters;

export default PageLayout;
