import { Modal } from '@tactun/ui';
import { useCallback, memo } from 'react';
import { useRealTimeLineGraph } from '../../../components/RealTimeLineGraph';
import { Channel } from '../../Dashboard/dashboard.types';
import CreatePlotModal from '../components/CreatePlotModal';
import GraphColorPickerModal from '../components/GraphColorPickerModal';
import GraphSidebar from '../components/GraphSidebar';
import PlotItem from '../components/PlotItem';
import { GraphPlotActions } from '../graphWidget.constants';
import { GraphChannel, Plot } from '../graphWidget.types';

const plotModalId = 'plotModalId';
const colorPickerModalId = 'colorPickerModalId';

export interface ILegendContainerProps {
	graphId?: string;
	plots: Plot[];
	xChannel?: GraphChannel;
	onUpdate: (plots: Plot[]) => void;
}

const SidebarContainer = ({ plots, graphId, xChannel, onUpdate }: ILegendContainerProps) => {
	const { showSeries, hideSeries, removeSeries, configureSeries } = useRealTimeLineGraph(null, graphId);

	const handlePlotAction = useCallback(
		(actionType: string, current: Plot) => {
			switch (actionType) {
				case GraphPlotActions.DELETE:
					{
						const newPlots = plots.filter((plot) => plot.id !== current.id);
						removeSeries(graphId as string, current.id);
						onUpdate(newPlots);
					}
					break;
				case GraphPlotActions.TOGGLE_VISIBILITY:
					{
						const newPlots = plots.map((plot) => ({
							...plot,
							isVisible: plot.id === current.id && plot.yAxisId === current.yAxisId ? !plot.isVisible : plot.isVisible
						}));

						if (current.isVisible) {
							hideSeries(current.id);
						} else {
							showSeries(current.id);
						}
						onUpdate(newPlots);
					}
					break;
				case GraphPlotActions.CHANGE_COLOR:
					Modal.show(`${graphId}-${colorPickerModalId}`, current);
					break;
			}
		},
		[graphId, hideSeries, onUpdate, plots, removeSeries, showSeries]
	);

	const handleCreatePlot = useCallback(() => Modal.show(`${graphId}-${plotModalId}`), [graphId]);

	const handleSelectColor = useCallback(
		(plot: Plot, color: string) => {
			let updated: Plot | undefined;
			const newPlots = plots.map((p) => {
				if (p.id === plot.id && p.yAxisId === plot.yAxisId) {
					updated = { ...p, color };
					return updated;
				}
				return p;
			});
			onUpdate(newPlots);

			// update on graph
			if (updated) {
				configureSeries({ color: updated.color, isVisible: updated.isVisible, name: updated.title, id: updated.id });
			}
		},
		[configureSeries, onUpdate, plots]
	);

	const handleUpdatePlot = useCallback(
		(data: { xDataChannel: Channel[]; plots: Plot[] }) => {
			onUpdate(data.plots);
		},
		[onUpdate]
	);

	return (
		<GraphSidebar onCreatePlot={handleCreatePlot}>
			{plots.map((plot) => {
				return <PlotItem key={`${plot.id}_${plot.yAxisId}`} plot={plot} onAction={handlePlotAction} />;
			})}
			{graphId && (
				<CreatePlotModal
					modalId={`${graphId}-${plotModalId}`}
					xChannel={xChannel}
					plots={plots}
					onUpdatePlots={handleUpdatePlot}
				/>
			)}
			{graphId && (
				<GraphColorPickerModal onSelectColor={handleSelectColor} modalId={`${graphId}-${colorPickerModalId}`} />
			)}
		</GraphSidebar>
	);
};

export default memo(SidebarContainer);
