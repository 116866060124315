import { ChannelType } from '../../stationChannels.enums';
import { StationChannelsFieldsRelatedToType } from '../../stationChannels.types';

type StationChannelFieldMapping = {
	[Key in keyof Required<StationChannelsFieldsRelatedToType>]: ChannelType[];
};

export const stationChannelFieldTypeMapping: StationChannelFieldMapping = {
	direction: [ChannelType.DIGITAL_IO_5V, ChannelType.DIGITAL_IO_24V],
	activeState: [ChannelType.DIGITAL_IO_5V, ChannelType.DIGITAL_IO_24V, ChannelType.RELAY, ChannelType.DIGITAL_IN_24V],
	defaultState: [ChannelType.DIGITAL_IO_5V, ChannelType.DIGITAL_IO_24V, ChannelType.RELAY],
	pullUpResistor: [ChannelType.DIGITAL_IO_5V],
	specificType: [ChannelType.ANALOG_IN_10KSPS, ChannelType.ANALOG_IN_10V_24MA, ChannelType.ANALOG_OUT_10V_24MA],
	bridgeType: [ChannelType.SIGNAL_CONDITIONING],
	excitationVoltage: [ChannelType.SIGNAL_CONDITIONING],
	gain: [ChannelType.SIGNAL_CONDITIONING],
	pgaGain: [ChannelType.SIGNAL_CONDITIONING],
	samplingRate: [ChannelType.SIGNAL_CONDITIONING],
	range: [
		ChannelType.ANALOG_OUT_10V,
		ChannelType.ANALOG_OUT_10V_24MA,
		ChannelType.ANALOG_IN_10KSPS,
		ChannelType.ANALOG_IN_10V_24MA,
		ChannelType.ANALOG_IN_60KSPS,
		ChannelType.ANALOG_OUT_100MA
	],
	offset: [ChannelType.ANALOG_OUT_10V, ChannelType.ANALOG_OUT_10V_24MA, ChannelType.ANALOG_OUT_100MA],
	frequency: [ChannelType.DC_PWM_30V, ChannelType.DC_PWM_50V, ChannelType.DC_PWM_65V],
	limit: [ChannelType.ANALOG_OUT_10V, ChannelType.ANALOG_OUT_10V_24MA, ChannelType.ANALOG_OUT_100MA],
	excitationAmplitude: [ChannelType.LVDT],
	excitationFrequency: [ChannelType.LVDT],
	bouncingCancellation: [ChannelType.DIGITAL_IO_5V, ChannelType.DIGITAL_IO_24V]
};

export const isFieldVisible = (field: keyof StationChannelFieldMapping, type?: ChannelType): boolean => {
	if (type === undefined) {
		return false;
	} else {
		return !!stationChannelFieldTypeMapping[field].some((f) => {
			return f === type;
		});
	}
};
