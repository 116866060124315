import { useMemo, useRef } from 'react';
import { PageLayout, Form, InputText, Button, BottomBar, InfoBadge, InfoBadgeIcons } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import styles from './OrderHistory.module.scss';

interface AxesProps {
	isCreate: boolean;
	isLoading: boolean;
	form: UseFormReturn<Record<string, string>>;
	onBack: () => void;
	onSave: () => void;
}

const OrderHistory: React.FC<AxesProps> = ({ isCreate, isLoading, form, onBack, onSave }) => {
	const { t } = useTranslation('axes');
	const formId = useRef('axesForm');
	const { control } = form;

	const title = useMemo<string>(() => {
		return isCreate ? t('ABC Company Order History') : t('ABC Company Order History');
	}, [isCreate, t]);

	return (
		<>
			<PageLayout>
				<PageLayout.Header title={title} onBack={onBack} />
				<Form className={styles.container} onSubmit={onSave} id={formId.current}>
					<Controller
						name="CompanyName"
						control={control}
						render={({ field }) => <InputText label={t('Company name')} {...field} />}
					/>
					<Controller
						name="contactPerson"
						control={control}
						render={({ field }) => <InputText label={t('Contact person full name')} {...field} />}
					/>
					<Controller
						name="phoneNumber"
						control={control}
						render={({ field }) => <InputText label={t('Phone number')} {...field} />}
					/>
					<Controller
						name="email"
						control={control}
						render={({ field }) => <InputText label={t('Email')} {...field} />}
					/>
					<div className={styles.historyTable}>
						<div className={styles.historyLabel}>Order history</div>
						<div>
							<table>
								<thead>
									<tr>
										<td>Order ID</td>
										<td>Controller Serial Number</td>
										<td>Product</td>
										<td>Delivery Date</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Order_0002365987</td>
										<td>MA51MW1020007</td>
										<td>Single Axis static machine</td>
										<td>12/12/2022, 14:23:34</td>
									</tr>
									<tr>
										<td>Order_0002365123</td>
										<td>MA06MW1020006</td>
										<td>Dual Axis machine</td>
										<td>12/12/2022, 14:23:13</td>
									</tr>
									<tr>
										<td>Order_000236986</td>
										<td>MA06MW1020004</td>
										<td>Planar Biaxial machine</td>
										<td>12/12/2022, 14:23:32</td>
									</tr>
									<tr>
										<td>Order_0002367777</td>
										<td>MA01MW1028744</td>
										<td>Single Axis static machine</td>
										<td>12/12/2022, 14:23:50</td>
									</tr>
									<tr>
										<td>Order_0002365984</td>
										<td>MA01MW1020001</td>
										<td>Axial-Torsional machine</td>
										<td>12/12/2022, 14:23:15</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</Form>
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button data-testid="cancelBtn" label={t('Cancel')} variant="contained" color="success" onClick={onBack} />
				<Button
					data-testid="saveBtn"
					label={t('Save')}
					variant="contained"
					color="secondary"
					type="submit"
					form={formId.current}
				/>
			</BottomBar>
		</>
	);
};

export default OrderHistory;
