export enum TriggerType {
	TRG_DIGITAL_INPUT = 0,
	TRG_STATION,
	TRG_TEST,
	TRG_CONNECTION,
	TRG_AUX_DEVICE,
	TRG_SPECIMEN,
	TRG_MEASUREMENT,
	TRG_AXIS,
	TRG_STAGE,
	TRG_VARIABLE,
	TRG_OFFSET_CALIBRATION,
	TRG_MEM_STATUS
}

export enum DigitalInputState {
	ON = 0,
	OFF
}

export enum ActionStationState {
	STATION_IN_TEST = 0,
	STATION_IDLE,
	STATION_ACTIVE,
	STATION_SAFE
}

export enum OffsetCalibrationState {
	CALIBRATION_IN_PROGRESS = 0,
	CALIBRATION_COMPLETED,
	CALIBRATION_MAX_REACHED,
	CALIBRATION_STOPPED
}

export enum TestState {
	STARTED = 0,
	STOPPED,
	RESUMED,
	PAUSED
}
