import React from 'react';
import styles from './FullWidthFormWrapper.module.scss';

interface FullWidthFormWrapperProps {
	children: React.ReactNode;
}

const FullWidthFormWrapper: React.FC<FullWidthFormWrapperProps> = ({ children }) => {
	return <div className={styles.container}>{children}</div>;
};

export default FullWidthFormWrapper;
