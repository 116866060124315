import { useRef, useEffect } from 'react';

export type ObserverCb = (entries: ResizeObserverEntry[]) => void;

export const useResizeObserver = (cb: ObserverCb) => {
	const ref = useRef<HTMLElement>();

	useEffect(() => {
		if (!ref.current) return;
		const el = ref.current;

		const resizeObserver = new ResizeObserver((entries) => {
			// We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
			// https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
			window.requestAnimationFrame(() => {
				if (!Array.isArray(entries) || !entries.length) {
					return;
				}
				cb(entries);
			});
		});
		resizeObserver.observe(el);

		return () => resizeObserver.unobserve(el);
	}, [cb]);

	return ref;
};
