import { Converter } from '../../types';
import {
	ButtonDto,
	ButtonForm,
	ButtonRuleDto,
	ButtonRuleListItem,
	ButtonSubFrameDto,
	ButtonSubFrameForm,
	CustomButtonWidgetDto
} from './buttonWidget.types';
import { customWidgetDefaultButton } from './buttonWidget.const';
import { RuleConditionType, RuleStatus, RuleType, StationRuleRequestDto, StationRuleResponseDto } from '../Rules';
import { ButtonDefaultState, ButtonStatus } from './buttonWidget.enums';
import { TriggerDtoObjectTypes, TriggerUIButtonProperties, TriggerVariableProperties } from '../Triggers';
import { VariableResponseDto, VariablesTypes } from '../Variables';
import {
	ActionDtoObjectTypes,
	ActionRunType,
	ActionUIButtonProperties,
	UIButtonActionDto,
	VariableActionDto
} from '../Actions';
import {
	ConditionDtoObjectTypes,
	ConditionsUIButtonProperties,
	UIButtonConditionDto,
	VariableConditionDto
} from '../Conditions';
import { ControlChannelResponseDto } from '../ControlChannels';

export const buttonDtoToFormConverter = (input: ButtonDto): ButtonForm => ({
	id: input.uuid,
	name: input.name,
	tooltip: input.tooltip,
	defaultState: input.settings ? input.settings.state : customWidgetDefaultButton.defaultState,
	shape: input.settings ? input.settings.shape : customWidgetDefaultButton.shape,
	pressedText: input.settings?.pressedStateText,
	releasedText: input.settings?.releasedStateText,
	pressedColor: input.settings?.pressedStateBackgroundColor || customWidgetDefaultButton.pressedColor,
	releasedColor: input.settings?.releasedStateBackgroundColor || customWidgetDefaultButton.releasedColor,
	pressedTextColor: input.settings?.pressedStateTextColor || customWidgetDefaultButton.pressedButtonColor,
	releasedTextColor: input.settings?.releasedStateTextColor || customWidgetDefaultButton.releasedButtonColor,
	pressedIcon: input.settings?.pressedStateIcon,
	releasedIcon: input.settings?.releasedStateIcon,
	rules: input.rules?.map((rule) => buttonRuleDtoToStationRuleDto(rule)) || []
});

export const buttonFormToButtonStatus = (button: ButtonForm): ButtonStatus =>
	!!button.name && !!button.rules?.length && button.pressedColor && button.releasedColor
		? ButtonStatus.CONFIGURED
		: ButtonStatus.NOT_CONFIGURED;

export const buttonWidgetSubFrameDtoToFormConverter = (input: ButtonSubFrameDto): ButtonSubFrameForm => ({
	id: input.uuid,
	orientation: input.buttonOrientation,
	numberOfButtons: input.buttons.length || undefined,
	buttons: input.buttons.map((v) => buttonDtoToFormConverter(v)),
	width: input.width,
	height: input.height
});

export const stationRuleDtoToButtonRuleDto: Converter<StationRuleResponseDto, ButtonRuleDto> = (response) => ({
	uuid: response.uuid,
	name: response.name,
	type: response.ruleType,
	status: response.ruleStatus || RuleStatus.ENABLED,
	conditionType: response.ruleConditionType,
	conditionBondType: response.conditionBondType,
	conditions: response.conditions,
	positiveRunType: response.positiveRunType || ActionRunType.PARALLEL,
	negativeRunType: response.negativeRunType || ActionRunType.PARALLEL,
	positiveActions: response.rulePositiveActions,
	negativeActions: response.ruleNegativeActions,
	triggers: response.triggers || []
});

export const buttonRuleDtoToStationRuleDto: Converter<ButtonRuleDto, StationRuleResponseDto> = (input) => ({
	uuid: input.uuid || '',
	name: input.name,
	ruleType: input.type,
	ruleStatus: input.status || RuleStatus.ENABLED,
	ruleConditionType: input.conditionType,
	conditionBondType: input.conditionBondType,
	conditions: input.conditions,
	positiveRunType: input.positiveRunType,
	negativeRunType: input.negativeRunType,
	rulePositiveActions: input.positiveActions,
	ruleNegativeActions: input.negativeActions,
	triggers: input.triggers,
	ruleStationUuid: ''
});

export const buttonFormToButtonDtoConverter = (input: ButtonForm): ButtonDto => ({
	uuid: input.id,
	name: input.name || '',
	status: buttonFormToButtonStatus(input),
	tooltip: input.tooltip || '',
	settings: {
		state: input.defaultState,
		shape: input.shape,
		pressedStateText: input.pressedText || '',
		releasedStateText: input.releasedText || '',
		pressedStateBackgroundColor: input.pressedColor,
		releasedStateBackgroundColor: input.releasedColor,
		pressedStateTextColor: input?.pressedTextColor || '',
		releasedStateTextColor: input?.releasedTextColor || '',
		pressedStateIcon: input.pressedIcon || '',
		releasedStateIcon: input.releasedIcon || ''
	},
	rules: input.rules?.map((rule) => stationRuleDtoToButtonRuleDto(rule as StationRuleResponseDto)) || []
});

export const buttonWidgetSubFrameFormToButtonWidgetSubFrameDtoConverter = (
	input: ButtonSubFrameForm,
	size: { width: number; height: number }
): ButtonSubFrameDto => ({
	uuid: input.id,
	buttonOrientation: input.orientation,
	buttons: input.buttons?.map((v) => buttonFormToButtonDtoConverter(v)) || [],
	...size
});

// Rules
export const buttonToDisableStateVariableName = (id: string) => `BUTTON_${id}_DISABLE`;

export const buttonToPressStateVariableName = (id: string) => `BUTTON_${id}_PRESS`;

export const buttonUIConditionDtoToVariableConditionDto = (
	condition: UIButtonConditionDto,
	buttonId: string
): VariableConditionDto => ({
	type: ConditionDtoObjectTypes.VARIABLE,
	condition: condition.op,
	varType: VariablesTypes.NUMERIC,
	varUuid:
		condition.state === ConditionsUIButtonProperties.DISABLED
			? buttonToDisableStateVariableName(buttonId)
			: buttonToPressStateVariableName(buttonId),
	value: condition.state === ConditionsUIButtonProperties.DISABLED ? '1' : condition.state.toString()
});

export const buttonUIActionDtoToVariableActionDto = (
	action: UIButtonActionDto,
	buttonId: string
): VariableActionDto => ({
	type: ActionDtoObjectTypes.VARIABLE,
	varType: VariablesTypes.NUMERIC,
	varUuid:
		action.uiButtonProp === ActionUIButtonProperties.ENABLE || action.uiButtonProp === ActionUIButtonProperties.DISABLE
			? buttonToDisableStateVariableName(buttonId)
			: buttonToPressStateVariableName(buttonId),
	value:
		action.uiButtonProp === ActionUIButtonProperties.ENABLE || action.uiButtonProp === ActionUIButtonProperties.PRESS
			? '0'
			: '1'
});

export const buttonUIRuleDtoToVariableRule = (
	rule: StationRuleResponseDto,
	buttonId: string
): StationRuleResponseDto => ({
	...rule,
	rulePositiveActions: rule.rulePositiveActions?.map((action) =>
		action.type === ActionDtoObjectTypes.UI_BUTTON ? buttonUIActionDtoToVariableActionDto(action, buttonId) : action
	),
	ruleNegativeActions: rule.ruleNegativeActions?.map((action) =>
		action.type === ActionDtoObjectTypes.UI_BUTTON ? buttonUIActionDtoToVariableActionDto(action, buttonId) : action
	),
	conditions: rule.conditions?.map((condition) =>
		condition.type === ConditionDtoObjectTypes.UI_BUTTON
			? buttonUIConditionDtoToVariableConditionDto(condition, buttonId)
			: condition
	)
});

export const buttonWidgetRuleDtoToRuleDto: Converter<CustomButtonWidgetDto, StationRuleResponseDto[]> = (
	buttonWidget
) => {
	const rules: StationRuleResponseDto[] = [];

	// Create rules so we can get events for state changes
	const variables = buttonWidgetDtoToStateVariables(buttonWidget);
	variables.forEach(({ name }) => {
		rules.push({
			ruleStationUuid: '',
			uuid: '',
			name: name,
			ruleType: RuleType.STATION,
			ruleStatus: RuleStatus.ENABLED,
			ruleConditionType: RuleConditionType.UNCONDITIONAL,
			triggers: [
				{
					uuid: '1',
					type: TriggerDtoObjectTypes.VARIABLE,
					varUuid: name,
					varType: VariablesTypes.NUMERIC,
					condition: TriggerVariableProperties.EQUALS,
					value: '0'
				},
				{
					uuid: '2',
					type: TriggerDtoObjectTypes.VARIABLE,
					varUuid: name,
					varType: VariablesTypes.NUMERIC,
					condition: TriggerVariableProperties.EQUALS,
					value: '1'
				}
			]
		});
	});

	buttonWidget.subframes.forEach((subframe) => {
		subframe.buttons.forEach((button) => {
			button.rules
				.filter((r) => r.status !== RuleStatus.DISABLED)
				.forEach((rule) => {
					const triggers = rule.triggers?.filter((trigger) => trigger.type !== TriggerDtoObjectTypes.UI_BUTTON);
					if (triggers?.length) {
						rules.push(
							buttonUIRuleDtoToVariableRule(
								{
									...buttonRuleDtoToStationRuleDto(rule),
									triggers
								},
								button.uuid as string
							)
						);
					}
				});
		});
	});

	return rules;
};

export const buttonWidgetRulesDtoToEventActions = (
	rules: StationRuleResponseDto[],
	buttonId: string,
	controlChannels?: ControlChannelResponseDto[]
): Record<'press' | 'release', StationRuleResponseDto[]> => {
	const eventActions = {
		press: [] as StationRuleResponseDto[],
		release: [] as StationRuleResponseDto[]
	};

	rules
		.filter((r) => r.ruleStatus !== RuleStatus.DISABLED)
		.forEach((rule) => {
			rule.rulePositiveActions?.forEach((action) => {
				if (action.type === ActionDtoObjectTypes.CONTROL) {
					action.controlChannel = controlChannels?.find((channel) => channel.id === action.controlChannelUuid);
				}
			});
			rule.ruleNegativeActions?.forEach((action) => {
				if (action.type === ActionDtoObjectTypes.CONTROL) {
					action.controlChannel = controlChannels?.find((channel) => channel.id === action.controlChannelUuid);
				}
			});

			rule.triggers?.forEach((trigger) => {
				if (trigger.type === TriggerDtoObjectTypes.UI_BUTTON) {
					eventActions[trigger.buttonState === TriggerUIButtonProperties.PRESSED ? 'press' : 'release'].push(
						buttonUIRuleDtoToVariableRule(
							{
								...rule,
								triggers: [],
								rulePositiveActions: rule.rulePositiveActions?.length ? [...rule.rulePositiveActions] : [],
								ruleNegativeActions: rule.ruleNegativeActions?.length ? [...rule.ruleNegativeActions] : []
							},
							buttonId
						)
					);
				}
			});
		});

	return eventActions;
};

export const buttonWidgetDtoToStateVariables: Converter<CustomButtonWidgetDto, VariableResponseDto[]> = (
	buttonWidget
) => {
	const variables: VariableResponseDto[] = [];
	// TODO maybe we can imporive this by creating only those state variables that where used in the conditions and actions
	buttonWidget.subframes.forEach((subframe) => {
		subframe.buttons.forEach((button) => {
			variables.push({
				uuid: buttonToDisableStateVariableName(button.uuid as string),
				name: buttonToDisableStateVariableName(button.uuid as string),
				type: VariablesTypes.NUMERIC,
				defaultValue: '0'
			});
			variables.push({
				uuid: buttonToPressStateVariableName(button.uuid as string),
				name: buttonToPressStateVariableName(button.uuid as string),
				type: VariablesTypes.NUMERIC,
				defaultValue: (button.settings?.state !== undefined
					? button.settings.state
					: ButtonDefaultState.RELEASED
				).toString()
			});
		});
	});

	return variables;
};

export const buttonWidgetToWidgetMetadata = (buttonWidget: CustomButtonWidgetDto): Record<string, any> => {
	return {
		subFrames: buttonWidget.subframes.map((subframe) => buttonWidgetSubFrameDtoToFormConverter(subframe)),
		layoutType: buttonWidget.layoutTemplate,
		name: buttonWidget.name
	};
};

export const responseListItemConverter = (
	response: StationRuleResponseDto | StationRuleRequestDto
): ButtonRuleListItem => {
	return {
		//@ts-ignore
		id: response.id,
		name: response.name,
		ruleConditionType: response.ruleConditionType,
		status: response.ruleStatus || RuleStatus.ENABLED
	};
};
