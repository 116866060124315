import {
	ActionDto,
	ActionType,
	ActionTypeOffsetCalibration,
	ControlInfo,
	OffsetCalibrationCmd,
	OffsetCalibrationProp,
	Rule,
	RulesRequest,
	TriggerDto,
	TriggerType
} from '@tactun/grpc-client';
import { OffsetCalibrationConfigurationFormType } from './offsetCalibration.types';
import { IAxisResponseDto } from '../Axes';
import { controlAlgorithmsFormTypeToGrpcAlgoPid } from '../ControlAlgorithms/controlAlgorithms.converters';
import { ChannelType } from '../StationChannels';

export const offsetCalibrationConfigurationFormToGRPCRequest = (
	form: OffsetCalibrationConfigurationFormType,
	stationId: string,
	feedbackMeasurementId: string,
	axis: IAxisResponseDto,
	isStop: boolean = false
) => {
	const startRequest = new RulesRequest();
	startRequest.setStationId(stationId);
	const rule = new Rule();

	const trigger = new TriggerDto();
	trigger.setType(TriggerType.TRG_OFFSET_CALIBRATION);
	rule.addTriggers(trigger);

	const action = new ActionDto();
	action.setType(ActionType.ACTION_TYPE_OFFSET_CALIBRATION);

	const actionOc = new ActionTypeOffsetCalibration();
	actionOc.setCmd(isStop ? OffsetCalibrationCmd.CALIBRATION_STOP : OffsetCalibrationCmd.CALIBRATION_START);

	const controlInfo = new ControlInfo();
	controlInfo.setAxisId(axis.id);
	controlInfo.setAxisIndex(axis.axisIndex);
	controlInfo.setFbMeasurement(feedbackMeasurementId);

	if (form.controlAlgorithmForm !== undefined) {
		const pid = controlAlgorithmsFormTypeToGrpcAlgoPid(form.controlAlgorithmForm);
		if (pid) {
			controlInfo.setPid(pid);
		}
	}

	actionOc.setControlInfo(controlInfo);

	const ocProps = new OffsetCalibrationProp();
	ocProps.setAllowedVariation(form.allowedVariation);
	ocProps.setDelay(form.delay);

	let voltageToCurrentRatio = 1.0;
	if (axis.actuator?.stationChannel?.type === ChannelType.ANALOG_OUT_100MA) voltageToCurrentRatio = 100.0;

	ocProps.setStep(form.step * voltageToCurrentRatio);
	ocProps.setMaxOffset(form.maximumOffset * voltageToCurrentRatio);
	if (axis.actuator?.stationChannel?.offset !== undefined)
		ocProps.setInitialOffset(axis.actuator?.stationChannel?.offset * voltageToCurrentRatio);

	actionOc.setProp(ocProps);
	action.setActionOc(actionOc);
	rule.addPositiveActions(action);
	startRequest.addRules(rule);

	return startRequest;
};

export const getSetRequest = (
	stationId: string,
	axisId: string,
	axisIndex: number,
	offset: number,
	voltageToCurrentRatio: number = 1
) => {
	const stopRequest = new RulesRequest();
	stopRequest.setStationId(stationId);
	const rule = new Rule();

	const trigger = new TriggerDto();
	trigger.setType(TriggerType.TRG_OFFSET_CALIBRATION);
	rule.addTriggers(trigger);

	const action = new ActionDto();
	action.setType(ActionType.ACTION_TYPE_OFFSET_CALIBRATION);

	const actionOc = new ActionTypeOffsetCalibration();
	actionOc.setCmd(OffsetCalibrationCmd.CALIBRATION_SET);

	const controlInfo = new ControlInfo();
	controlInfo.setAxisId(axisId);
	controlInfo.setAxisIndex(axisIndex);

	actionOc.setControlInfo(controlInfo);

	const ocProps = new OffsetCalibrationProp();
	ocProps.setInitialOffset(offset * voltageToCurrentRatio);
	actionOc.setProp(ocProps);

	action.setActionOc(actionOc);
	rule.addPositiveActions(action);
	stopRequest.addRules(rule);

	return stopRequest;
};
