import { CalculationTypes, CalculationGroups } from './calculation.enums';

export const CALCULATION_TYPE_GROUPE = {
	[CalculationGroups.ADVANCED_CALCULATIONS]: [CalculationTypes.ENERGY],
	[CalculationGroups.STANDARD_CALCULATIONS]: [
		CalculationTypes.MEASUREMENT_AT_PEAK_LOAD,
		CalculationTypes.MAXIMUM_LOAD_OVER_WIDTH,
		CalculationTypes.MEASUREMENT_AT_BREAK,
		CalculationTypes.DATA_POINT
	],
	[CalculationGroups.MODULE_CALCULATIONS]: [
		CalculationTypes.MODULUS_OF_ELASTICITY,
		CalculationTypes.YIELD_OFFSET,
		CalculationTypes.MEASUREMENT_AT_YIELD
	],
	[CalculationGroups.STATISTICAL_CALCULATIONS]: [
		CalculationTypes.MAXIMUM,
		CalculationTypes.MINIMUM,
		CalculationTypes.MEAN
	]
};

export const STATISTICAL_TYPES_LIST = [CalculationTypes.MAXIMUM, CalculationTypes.MINIMUM, CalculationTypes.MEAN];
