import * as yup from 'yup';
import { alphaNumericRegex } from '../../constants/regexes';
import * as api from './station.api';
import { UnitSystemTypes } from '../Units';
import { emptyStringToNull } from '../../tools/yupHelpers';

export const stationSchema = yup
	.object({
		id: yup.string().optional(),
		name: yup
			.string()
			.required('Name is a required field')
			.max(30)
			.matches(alphaNumericRegex, 'Is not in correct format')
			.test('checkStationUniqueness', 'Name is used', function (value, form) {
				if (!value) return true;
				return api.checkName(value, form?.parent?.id);
			}),
		controllerModel: yup.string().required('Controller model is a required field'),
		type: yup.string().required('Type is a required field'),
		unitSystem: yup
			.number()
			.transform(emptyStringToNull)
			.oneOf([UnitSystemTypes.METRIC, UnitSystemTypes.IMPERIAL], 'Unit System is a required field')
			.required('Unit system is a required field'),
		specimenTypes: yup
			.array()
			.of(yup.string().required('Specimen Types is a required field'))
			.min(1, 'Must have at least 1 Specimen Type')
			.required('Specimen Types is a required field'),
		description: yup.string().optional().max(1000)
	})
	.required();
