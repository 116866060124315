import { object, string, mixed, array, bool } from 'yup';
import { WidgetsTypes } from './widgetsConfiguration.enums';
import { WidgetControlTestForm, WidgetForm } from './widgetsConfiguration.types';
import { alphaNumericRegex } from '../../constants/regexes';

export const widgetFormSchema = object<WidgetForm>({
	id: string().notRequired(),
	name: string().required().max(30).matches(alphaNumericRegex, 'Is not in correct format'),
	type: mixed<WidgetsTypes>().required(),
	stationId: string().required(),
	controlAxis: array()
		.of(
			object().shape({
				buttonConfigs: array().required(),
				axisUuid: string().required()
			})
		)
		.when('type', {
			is: (type: WidgetsTypes) => type === WidgetsTypes.CONTROL_AXIS,
			then: (schema) => schema.required()
		}),
	controlTest: object<WidgetControlTestForm>().when('type', {
		is: (type: WidgetsTypes) => type === WidgetsTypes.CONTROL_TEST,
		then: (schema) =>
			schema
				.shape({
					startStopButton: bool().required(),
					pauseResumeButton: bool().required(),
					jumpToNextStageButton: bool().required(),
					generateReportButton: bool().required()
				})
				.required(),
		otherwise: (schema) => schema.notRequired()
	})
}).required();
