import { useMemo } from 'react';
import { IListItem, INamedEntity, INamedEntityWithUUID } from '../types';

export const useEntitiesAsList = (entities?: INamedEntity[] | INamedEntityWithUUID[]): IListItem[] => {
	return useMemo<IListItem[]>(
		() =>
			entities?.map((e: INamedEntity | INamedEntityWithUUID) => {
				return {
					label: e.name,
					value: 'id' in e ? e.id : e.uuid
				} as IListItem;
			}) || [],
		[entities]
	);
};
