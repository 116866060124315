export enum TestLoopsActionTypes {
	CREATE = 'CREATE_LOOP',
	EDIT = 'EDIT_LOOP',
	DELETE = 'DELETE_LOOP'
}

export enum TestLoopRunForCalculationDto {
	ALL_ITERATIONS = 0,
	FIRST_STAGE,
	LAST_STAGE
}
