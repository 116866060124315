import React from 'react';
import TestAxesConfigurationModal from '../TestAxesConfigurationModal';
import TestAxesConfigurationContainer from '../../container/TestAxesConfigurationContainer';
import { TestAxesConfigurationFormType } from '../../testAxesConfiguration.type';

interface ITestAxesConfigurationProps {
	axisId?: string;
	stageId?: string;
	axisConfig?: TestAxesConfigurationFormType;
	testId: string;
}

const TestAxesConfiguration: React.FC<ITestAxesConfigurationProps> = ({ testId, axisConfig, axisId, stageId }) => {
	return (
		<TestAxesConfigurationModal isEdit={!!axisConfig}>
			<TestAxesConfigurationContainer testId={testId} axisConfig={axisConfig} axisId={axisId} stageId={stageId} />
		</TestAxesConfigurationModal>
	);
};

export default React.memo(TestAxesConfiguration);
