import { useEffect, useRef } from 'react';

export const usePrevious = (state: any) => {
	const previousState = useRef(state);

	useEffect(() => {
		previousState.current = state;
	}, [state]);

	return previousState.current;
};
