import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import System from '../components/System';
import { systemSchema } from '../system.schemas';
import { SystemFormType } from '../system.types';
import * as api from '../system.api';

const defaultValues = {
	id: undefined,
	name: '',
	controllerModel: '',
	copyrightInformation: '',
	version: '',
	description: ''
};

const SystemContainer: React.FC = () => {
	const { systemId } = useParams();
	const navigate = useNavigate();
	const form = useForm<SystemFormType>({
		defaultValues: defaultValues,
		mode: 'onBlur',
		resolver: yupResolver(systemSchema)
	});
	const { handleSubmit, reset } = form;
	const isCreate = !systemId;

	const { data: currentSystem } = useQuery<SystemFormType, Error>({
		queryKey: ['system', systemId],
		queryFn: () => api.getSystemById(systemId),
		enabled: !!systemId
	});

	const { data: devices } = useQuery<string[], Error>({ queryKey: ['systems'], queryFn: () => api.getDevices() });

	const createMutation = useMutation({
		mutationFn: (system: SystemFormType) => api.createSystem(system),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { system: SystemFormType; systemId: string }) => api.updateSystem(data.system, data.systemId),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const handleBack = useCallback(() => {
		navigate('/systems');
	}, [navigate]);

	const handleReset = useCallback(() => {
		if (currentSystem) {
			reset({ ...currentSystem }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultValues }, { keepDirty: true, keepTouched: true });
		}
	}, [currentSystem, reset]);

	const handleSave = handleSubmit((data) => {
		if (systemId) {
			data.id = undefined;
			updateMutation.mutate({ system: data as SystemFormType, systemId });
		} else {
			createMutation.mutate(data);
		}
	});

	//init current system for edit
	useEffect(() => {
		if (currentSystem) {
			handleReset();
		}
	}, [currentSystem, handleReset]);

	return (
		<System
			isCreate={isCreate}
			isLoading={createMutation.isPending || updateMutation.isPending}
			form={form}
			devices={devices}
			onBack={handleBack}
			onSave={handleSave}
		/>
	);
};

export default React.memo(SystemContainer);
