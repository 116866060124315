export enum TestDataLoggingActionTypes {
	EDIT = 'EDIT_DATA_LOGGING',
	DELETE = 'DELETE_DATA_LOGGING',
	CREATE = 'CREATE_DATA_LOGGING',
	DUPLICATE = 'DUPLICATE_DATA_LOGGING'
}

export enum DataLoggingTypes {
	TIMED,
	PERIODIC,
	PEAK_VALLEY
}

export enum ExtremumTypes {
	PEAKS = 0,
	VALLEYS,
	PEAKS_AND_VALLEYS
}
