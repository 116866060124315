import React, { LegacyRef, useCallback, useRef, useState } from 'react';
import cx from 'classnames';
import Icon from '@tactun/ui/src/components/Icon/Icon';

import { ButtonForm } from '../../buttonWidget.types';
import { ButtonShape } from '../../buttonWidget.enums';
import styles from './CustomButton.module.scss';
import { Tooltip } from 'primereact/tooltip';
import { useResizeObserver } from '../../../../hooks';

export interface CustomButtonProps {
	className?: string;
	width?: number;
	height?: number;
	tooltip?: string;
	button: ButtonForm;
	disabled?: boolean;
	pressed?: boolean;
	selected?: boolean;
	onClick?: () => void;
	onMouseDown?: () => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({
	className,
	width = 100,
	height = 100,
	tooltip,
	button,
	selected,
	onClick,
	pressed,
	disabled,
	onMouseDown
}) => {
	const handleClick = useCallback(() => {
		onClick?.();
	}, [onClick]);
	const el = useRef<HTMLButtonElement>(null);

	const releasedIcon = button.pressedIcon && !button.releasedIcon ? button.pressedIcon : button.releasedIcon;
	const releasedText = button.pressedText && !button.releasedText ? button.pressedText : button.releasedText;
	const bgColor = pressed ? button.pressedColor : button.releasedColor;
	const textColor = pressed ? button.pressedTextColor : button.releasedTextColor;

	const [fitHeight, setFitHeight] = useState(false);
	const [fontSize, setFontSize] = useState(36);

	const ref = useResizeObserver(
		useCallback((entries) => {
			const minSize = Math.min(entries[0].contentRect.width, entries[0].contentRect.height);
			setFitHeight(entries[0].contentRect.width >= entries[0].contentRect.height);
			setFontSize(minSize < 50 ? 24 : minSize < 80 ? 36 : 48);
		}, [])
	);

	return (
		<div
			className={cx(className, styles.container)}
			ref={ref as LegacyRef<HTMLDivElement>}
			style={{
				width: `${width}%`,
				height: `${height}%`
			}}
		>
			{!!tooltip && <Tooltip target={el} content={tooltip} />}
			<button
				ref={el}
				className={cx(styles.customButton, {
					[styles.round]: button.shape === ButtonShape.ROUND,
					[styles.fitHeight]: fitHeight,
					[styles.rectangular]: button.shape === ButtonShape.RECTANGULAR,
					[styles.selected]: selected,
					[styles.disabled]: disabled,
					[styles.empty]: !selected && !bgColor
				})}
				onClick={handleClick}
				onMouseDown={onMouseDown}
				disabled={disabled}
				style={{
					backgroundColor: bgColor || '#F1F1F1',
					color: textColor || '#FFF'
				}}
			>
				{pressed ? (
					<>
						{!!button.pressedIcon && (
							<Icon size={button.pressedText ? 24 : 36} name={button.pressedIcon} className={styles.buttonIcon} />
						)}
						{!!button.pressedText && <span className={styles.buttonText}>{button.pressedText}</span>}
					</>
				) : (
					<>
						{!!releasedIcon && (
							<Icon size={releasedText ? 24 : fontSize} name={releasedIcon} className={styles.buttonIcon} />
						)}
						{!!releasedText && <span className={styles.buttonText}>{releasedText}</span>}
					</>
				)}
			</button>
		</div>
	);
};

export default CustomButton;
