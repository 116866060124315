import axios, { AxiosError, AxiosInstance } from 'axios';
import { ErrorDto } from './types';

const axiosInstance: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	headers: {
		'Content-type': 'application/json'
	}
});

axiosInstance.interceptors.response.use(
	(response) => response,
	(error: AxiosError<ErrorDto | undefined>) => {
		const newError = { ...error };

		if (error?.response?.status === 400 || error?.response?.status === 404) {
			newError.message = error.response.data?.message || error.message;
		}
		return Promise.reject(newError);
	}
);

export default axiosInstance;
