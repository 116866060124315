import { FC, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, GroupInputFrame, InputText, Modal, Switch, InputNumber, Form } from '@tactun/ui';
import { GraphMainSettingsForm } from '../../graphWidget.types';
import { graphMainSettingsSchema } from '../../graphWidget.schemas';
import { ScaleTypeOptions } from '../../graphWidget.constants';

import styles from './GraphMainSettingsModal.module.scss';
import { DataChannelType } from '../../../DataChannel';

export interface IGraphMainSettingsModalProps {
	graphMainSettingsModalId: string;
}

export const GraphMainSettingsModal: FC<IGraphMainSettingsModalProps> = ({ graphMainSettingsModalId }) => {
	const { t } = useTranslation('dashboard');
	const {
		control,
		watch,
		formState: { errors },
		handleSubmit
	} = useForm<GraphMainSettingsForm>({
		mode: 'onBlur',
		//defaultValues: settings,
		resolver: yupResolver(graphMainSettingsSchema)
	});
	const xOffset = watch('xOffset');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const xChannel = { type: DataChannelType.TIME };
	//const { unitDto: timeUnit } = useUnit(settings.timeUnitId);

	const handleSettingClose = useCallback(() => {
		Modal.hide(graphMainSettingsModalId);
	}, [graphMainSettingsModalId]);

	const handleUpdateModalSettings = useCallback(() => {
		handleSubmit((data) => {
			//dispatch({ type: Actions.UPDATE_SETTINGS, payload: { ...settings, ...data } });
		})();
		Modal.hide(graphMainSettingsModalId);
	}, [graphMainSettingsModalId, handleSubmit]);

	const offsetUnit = useMemo(() => {
		const { type } = xChannel;
		if (type === DataChannelType.TIME) {
			return 'sec';
		}
		return 'cm';
	}, [xChannel]);

	return (
		<Modal id={graphMainSettingsModalId} onClose={handleSettingClose} className={styles.container}>
			<Modal.Header>{t('Graph Settings')}</Modal.Header>
			<Modal.Content>
				<Form className={styles.formContainer}>
					<Controller
						control={control}
						name="autoScaleX"
						render={({ field }) => (
							<Switch label="Auto scale X" onChange={(event) => field.onChange(event.value)} checked={!!field.value} />
						)}
					/>
					<Controller
						control={control}
						name="autoScaleY"
						render={({ field }) => (
							<Switch label="Auto scale Y" onChange={(event) => field.onChange(event.value)} checked={!!field.value} />
						)}
					/>
					<Controller
						control={control}
						name="scaleType"
						render={({ field }) => (
							<Dropdown
								{...field}
								label={t('X scale type*')}
								options={ScaleTypeOptions}
								error={errors.scaleType?.message}
							/>
						)}
					/>
					<div />
					<Controller
						control={control}
						name="xOffset"
						render={({ field }) => (
							<Switch label="X-axis offset" onChange={(event) => field.onChange(event.value)} checked={!!field.value} />
						)}
					/>
					<GroupInputFrame>
						<Controller
							control={control}
							name="offset"
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('X axis offset value')}
									error={errors.offset?.message}
									disabled={!xOffset}
								/>
							)}
						/>
						<InputText disabled value={offsetUnit} className={styles.timeWindowSizeUm} />
					</GroupInputFrame>
				</Form>
			</Modal.Content>
			<Modal.Footer>
				<Button onClick={handleSettingClose} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button onClick={handleUpdateModalSettings} color="secondary" variant="contained">
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default GraphMainSettingsModal;
