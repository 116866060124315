import React, { forwardRef, Ref, useId } from 'react';
import cx from 'classnames';
import styles from './ButtonSwitch.module.scss';
import Checkbox, { CheckboxProps } from '../Checkbox/Checkbox';

export interface ButtonSwitchProps extends CheckboxProps {
	className?: string;
	label: string;
}

const ButtonSwitch: React.FC<ButtonSwitchProps> = forwardRef(
	({ className, label, ...rest }, ref: Ref<HTMLInputElement>) => {
		const id = useId();

		return (
			<div className={cx(styles.buttonSwitchContainer, className)}>
				<Checkbox {...rest} type="checkbox" inputRef={ref} inputId={id} className={styles.input} />
				<label role="button" htmlFor={id} className={styles.label}>
					{label}
				</label>
			</div>
		);
	}
);
ButtonSwitch.displayName = 'ButtonSwitch';

export default React.memo(ButtonSwitch);
