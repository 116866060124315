import { Routes, Route } from 'react-router-dom';
import SystemsCrudContainer from './containers/SystemsCrudContainer';
import SystemContainer from './containers/SystemContainer';

const HardwareChannelsRouts: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<SystemsCrudContainer />} />
			<Route path="create" element={<SystemContainer />} />
			<Route path=":systemId" element={<SystemContainer />} />
		</Routes>
	);
};

export default HardwareChannelsRouts;
