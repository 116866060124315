import React, { forwardRef, LegacyRef, useId } from 'react';
import cx from 'classnames';
import { StatusLabelSizes, StatusLabelTypes } from './statusLabel.const';
import Icon from '../../Icon';
import styles from './StatusLabel.module.scss';
import { Tooltip } from 'primereact/tooltip';

export interface StatusLabelProps {
	className?: string;
	children: React.ReactNode;
	type?: StatusLabelTypes;
	size?: StatusLabelSizes;
	icon?: string;
	tooltip?: string;
	tooltipPosition?: 'mouse' | 'top' | 'bottom' | 'left' | 'right';
	isStaticLabel?: boolean;
}

const StatusLabel: React.FC<StatusLabelProps> = forwardRef(
	(
		{
			className,
			children,
			type = StatusLabelTypes.GRAY,
			size = StatusLabelSizes.STANDARD,
			icon,
			tooltip,
			tooltipPosition = 'bottom',
			isStaticLabel = false
		},
		ref: LegacyRef<any>
	) => {
		const id = useId().replace(/:/g, '');

		return (
			<>
				<div
					id={`status-label-${id}`}
					className={cx(styles.statusLabelContainer, [styles[type]], [styles[size]], className, {
						[styles.staticLabel]: isStaticLabel
					})}
					ref={ref}
				>
					{icon && (
						<Icon
							name={icon}
							className={cx(
								styles.statusLabelIcon,
								type === StatusLabelTypes.YELLOW || type === StatusLabelTypes.GRAY ? styles.blackIcon : undefined
							)}
							size={20}
						/>
					)}
					<span className={styles.content}>{children}</span>
				</div>
				{tooltip && <Tooltip target={`#status-label-${id}`} content={`${tooltip}`} position={tooltipPosition} />}
			</>
		);
	}
);
StatusLabel.displayName = 'StatusLabel';

export default React.memo(StatusLabel);
