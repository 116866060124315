import React, { forwardRef, ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';
import RGL, { Layout, WidthProvider } from 'react-grid-layout';

import styles from './OverlayWidget.module.scss';

const ReactGridLayout = WidthProvider(RGL);

interface WidgetContentProps {
	layout: Omit<Layout, 'i'>;
	children: ReactNode;
}

const OverlayWidget = forwardRef<HTMLDivElement, WidgetContentProps>((props, ref) => {
	const { layout, children } = props;
	const gutter = useMemo(() => {
		return window.innerWidth >= 1920 ? 16 : 12;
	}, []);

	const layoutList = useMemo(() => {
		return [{ ...layout, i: '1' }];
	}, [layout]);

	return createPortal(
		<div className={styles.container} ref={ref}>
			<ReactGridLayout
				draggableHandle=".overlay-widget-header"
				className={styles.grid}
				cols={24}
				rowHeight={32}
				verticalCompact={false}
				preventCollision={true}
				margin={[gutter, gutter]}
				layout={layoutList}
			>
				<div key="1">{children}</div>
			</ReactGridLayout>
		</div>,
		document.body
	);
});

export default React.memo(OverlayWidget);
