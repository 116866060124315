import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Materials } from './specimens.const';
import * as api from './specimens.api';
import { SpecimenListItem, SpecimenResponseDto } from './specimens.types';
import { SpecimenModes } from './specimens.enums';
import { responseListItemConverter } from './specimens.converters';
import { evalFormula, calculatorValueReplaceVariableValues } from '../Calculator';

export const useMaterials = () => {
	return { materials: Materials };
};

export const useSpecimens = (specimenMode: SpecimenModes) => {
	const { data: specimens, isLoading: isSpecimensLoading } = useQuery<SpecimenListItem[], Error>({
		queryKey: ['specimens', specimenMode],
		queryFn: async () => {
			const resp = await api.getSpecimens(specimenMode);

			return resp?.map((specimen) => responseListItemConverter(specimen)) ?? [];
		}
	});
	return { specimens, isSpecimensLoading };
};

export const useTypeSpecimens = (specimenType?: string) => {
	const queryClient = useQueryClient();

	const { data: specimens, isLoading: isSpecimensLoading } = useQuery<SpecimenListItem[], Error>({
		queryKey: ['specimens', { specimenType }],
		// When we provide enable option - !!stationId, the stationId is guaranteed
		queryFn: async () => {
			const resp = await api.getSpecimensByType(specimenType as string);

			return resp?.map((specimen) => responseListItemConverter(specimen)) ?? [];
		},
		staleTime: 1000,
		enabled: !!specimenType
	});

	const addNew = useCallback(
		(sp: SpecimenListItem) => {
			queryClient.setQueryData<SpecimenListItem[]>(['specimens', { specimenType }], (old) => {
				return [sp, ...(old ?? [])];
			});
		},
		[queryClient, specimenType]
	);

	return { specimens, isSpecimensLoading, addNew };
};

export const useSpecimen = (specimenUUID?: string) => {
	const { data: specimen, isFetching } = useQuery<SpecimenResponseDto>({
		queryKey: ['specimen', { specimenUUID }],
		// When we provide enable option - !!stationId, the stationId is guaranteed
		queryFn: () => api.getSpecimen(specimenUUID as string),
		enabled: !!specimenUUID
	});

	const queryClient = useQueryClient();

	const updateSpecimen = () => {
		queryClient.invalidateQueries({ queryKey: ['specimen', { specimenUUID }] });
	};

	return {
		specimen,
		isSpecimenLoading: isFetching,
		updateSpecimen
	};
};

export const useEvalSpecimenFormula = () => {
	const getAreaParams = useCallback(
		(specimen: SpecimenResponseDto, unitScale: number) =>
			Object.entries(specimen.specimenType.dimensions).reduce(
				(obj, [name, dimension]) => ({
					...obj,
					[name]: (dimension.value * dimension.unit.scale) / unitScale
				}),
				{}
			),
		[]
	);

	const evalSpecimenFormula = useCallback(
		async (specimen: SpecimenResponseDto) => {
			const areaParams = getAreaParams(specimen, specimen.specimenType.areaEquationUnit.scale || 1);
			const areaFormula = calculatorValueReplaceVariableValues(specimen.specimenType.areaEquation, areaParams);
			const areaValue = await evalFormula(areaFormula);

			const gaugeLengthParams = getAreaParams(specimen, specimen.specimenType.gaugeLengthEquationUnit.scale || 1);
			const gaugeLengthFormula = calculatorValueReplaceVariableValues(
				specimen.specimenType.gaugeLengthEquation,
				gaugeLengthParams
			);
			const gaugeLengthValue = await evalFormula(gaugeLengthFormula);

			return {
				...specimen,
				areaValue,
				gaugeLengthValue
			};
		},
		[getAreaParams]
	);

	return { evalSpecimenFormula };
};
