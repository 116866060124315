// Parse offset from backend
// Offset structure: _[format]_[data]_[unit]
// For example: _PM_3_V, _0_20_mA, _M_12_0_V etc
//
// Available formats
// _PM_[number]_[unit] - use one number as minimum and maximum value.
// _PM_3_V means range from -3 to 3
//
// _[number1]_[number2]_[unit] - range from positive number1 to number2.
// _1_3_V means range from 1 to 3
//
// _M_[number1]_[number2]_[unit] - from minimum negative number1 to maximum number2.
// _M_12_2_V - means range from -12 to 2

import { isNullOrUndefined } from '../../tools/common';
import { IListItemNumber } from '../../types';
import { ChannelType, Range } from './stationChannels.enums';

export const parseUnit = (raw: string) => {
	const data = raw.split('_');
	return data.pop();
};

export const getLimitUnitByRange = (range?: Range | null) => {
	if (isNullOrUndefined(range)) return '';

	return parseUnit(Range[range]);
};

export const getOffsetUnitByRange = (range?: Range | null) => {
	if (isNullOrUndefined(range)) return '';

	const limitUnit = parseUnit(Range[range]);

	return limitUnit === 'V' ? 'mV' : limitUnit;
};

export const isChannelCurrentAut = (type?: ChannelType | null) => {
	if (isNullOrUndefined(type)) return false;

	return type === ChannelType.ANALOG_OUT_100MA || type === ChannelType.ANALOG_OUT_300MA;
};

export const offsetFormatter = (raw: string) => {
	const [, format, ...data] = raw.split('_');
	const unit = data.pop() as string;

	if (!unit || !format || !data || data.length === 0) {
		throw new Error(`Wrong offset format: ${raw}`);
	}

	const limits = {
		min: 0,
		max: 0
	};

	if (isNaN(Number.parseInt(format))) {
		if (format === 'PM') {
			limits.min = -1 * Number.parseInt(data[0]);
			limits.max = Number.parseInt(data[0]);
		} else {
			limits.min = -1 * Number.parseInt(data[0]);
			limits.max = Number.parseInt(data[1]);
		}
	} else {
		limits.min = Number.parseInt(format);
		limits.max = Number.parseInt(data[0]);
	}

	return { ...limits, unit: unit as 'V' | 'mA' };
};

export const convertStringsToRangeList = (strs: string[]): IListItemNumber[] => {
	// @ts-ignore
	return strs.filter((str) => !!str).map((str) => ({ label: str, value: parseInt(Range[str]) }) as IListItemNumber);
};
