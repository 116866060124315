import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, KebabMenu } from '@tactun/ui';
import { DataTable, PageLayout } from '@tactun/ui';
import { CustomWidgetsListItem } from '../../customWidget.types';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { WidgetCrudActionTypes } from '../../customWidget.enums';
import { useTableConfigs } from '../../../../hooks';
import { useIsLocalDeployment } from '../../../../hooks/useIsLocalDeployment';

interface ICustomWidgetsTableProps {
	onAction: (type: WidgetCrudActionTypes, data?: CustomWidgetsListItem) => void;
	data?: CustomWidgetsListItem[];
	isLoading: boolean;
	stationName: string;
}

const CustomWidgetsTable: FC<ICustomWidgetsTableProps> = ({ data, isLoading, stationName, onAction }) => {
	const { t } = useTranslation('widgets');
	const isDisabled = useIsLocalDeployment();
	const formattedData = useMemo(
		() => data?.map((item) => ({ ...item, type: t(item.type), status: t(item.status) })),
		[data, t]
	);
	const transColumns = useMemo<ITableColumns<CustomWidgetsListItem>[]>(() => {
		return [
			{
				field: 'type',
				header: t('Widget type')
			},
			{
				field: 'status',
				header: t('Status')
			}
		];
	}, [t]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<CustomWidgetsListItem>(transColumns);
	const handleCreate = useCallback(() => {
		onAction(WidgetCrudActionTypes.CREATE);
	}, [onAction]);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(WidgetCrudActionTypes.DELETE, data),
				disabled: isDisabled
			}
		],
		[t, isDisabled, onAction]
	);

	return (
		<PageLayout info={stationName}>
			<PageLayout.Header title={t('Widgets')}>
				<CrudActions isCreateDisabled={isDisabled} onCreate={handleCreate} entityLabel={t('Widget')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				value={formattedData}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				resizableColumns
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Widget name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(WidgetCrudActionTypes.EDIT, rowData)}
							disabled={isDisabled}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="duplicate"
							fontSize="1.2rem"
							disabled
							title={t('Duplicate')}
							onClick={() => onAction(WidgetCrudActionTypes.DUPLICATE, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default CustomWidgetsTable;
