// package: teds
// file: teds.proto

var teds_pb = require("./teds_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var WebTeds = (function () {
  function WebTeds() {}
  WebTeds.serviceName = "teds.WebTeds";
  return WebTeds;
}());

WebTeds.ReadTeds = {
  methodName: "ReadTeds",
  service: WebTeds,
  requestStream: false,
  responseStream: false,
  requestType: teds_pb.TedsRequest,
  responseType: teds_pb.TedsResponse
};

exports.WebTeds = WebTeds;

function WebTedsClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

WebTedsClient.prototype.readTeds = function readTeds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebTeds.ReadTeds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.WebTedsClient = WebTedsClient;

