import { Routes, Route } from 'react-router-dom';
import ComponentsSettings from './components/ComponentsSettings';
import { UnitsTable, Unit } from '../Units';
import { SpecimenType, SpecimenTypesTable } from '../SpecimenTypes';
import DashboardTypeRouts, { DashboardTypesTable } from '../DashboardTypes';

const ComponentsRouts: React.FC = () => {
	return (
		<Routes>
			<Route path="*" element={<ComponentsSettings />}>
				<Route path="units" element={<UnitsTable />} />
				<Route path="specimen-types" element={<SpecimenTypesTable />} />
				<Route path="dashboard-types" element={<DashboardTypesTable />} />
			</Route>
			<Route path="units/*">
				<Route path="create" element={<Unit />} />
				<Route path=":quantityId" element={<Unit />} />
			</Route>
			<Route path="specimen-types/*">
				<Route path="create" element={<SpecimenType />} />
				<Route path=":specimenTypeId" element={<SpecimenType />} />
			</Route>
			<Route path="dashboard-types/*" element={<DashboardTypeRouts />} />

			{/* <Route path="dashboard-types/*">
				<Route path="create" element={<DashboardType />} />
				<Route path=":dashboardTypeId" element={<DashboardType />} />
			</Route> */}
		</Routes>
	);
};

export default ComponentsRouts;
