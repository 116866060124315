import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IStep, normalizePath, useWizard } from '@tactun/ui';
import Station from '../components/Station';
import { stationSchema } from '../station.schemas';
import { StationFormType, StationResponseDto, StationRequestDto } from '../station.types';
import * as api from '../station.api';
import * as paths from '../station.const';
import { stationsFormToRequest, stationsResponseToForm } from '../station.converters';
import { useUnitSystemsOptions } from '../../Units';
import { useStationTypes } from '../stations.hooks';
import { useSpecimenTypes } from '../../SpecimenTypes';
import { useEntitiesAsList } from '../../../hooks';

const defaultValues = {
	id: undefined,
	name: '',
	controllerModel: '',
	createdDate: undefined,
	type: '',
	description: '',
	unitSystem: undefined
};

const StationContainer: React.FC = () => {
	const { stationId } = useParams();
	const form = useForm<StationFormType>({
		defaultValues: defaultValues,
		mode: 'onBlur',
		resolver: yupResolver(stationSchema)
	});
	const {
		handleSubmit,
		reset,
		formState: { isDirty }
	} = form;
	const { beforeNextStep, activeStep, steps, goToStep } = useWizard();
	const isCreate = !stationId;

	const { data: currentStation } = useQuery<StationResponseDto, Error>({
		queryKey: ['station', stationId],
		queryFn: () => api.getStationById(stationId),
		enabled: !!stationId
	});
	const { stationTypes } = useStationTypes();

	const { data: devices } = useQuery<string[], Error>({
		queryKey: ['devices'],
		queryFn: () => api.getDevices()
	});

	const { specimenTypes } = useSpecimenTypes();
	const specimenTypesOptions = useEntitiesAsList(specimenTypes);

	const { unitSystems } = useUnitSystemsOptions(false);

	const createMutation = useMutation({
		mutationKey: ['saveStation'],
		mutationFn: (station: StationRequestDto) => api.createStation(station),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationKey: ['saveStation'],
		mutationFn: (data: { station: StationRequestDto; stationId: string }) =>
			api.updateStation(data.station, data.stationId),
		onError: (e: Error) => toast.error(e.message)
	});

	//init current station for edit
	useEffect(() => {
		if (currentStation) {
			reset({ ...stationsResponseToForm(currentStation) }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultValues }, { keepDirty: true, keepTouched: true });
		}
	}, [currentStation, reset]);

	// handle station save
	beforeNextStep((step: IStep) => {
		if (step.path === paths.generalInfoPath || step.path === paths.generalInfoCreatePath) {
			return new Promise<boolean>((resolve, reject) => {
				handleSubmit(
					async (data) => {
						const stationRequest = stationsFormToRequest(data);
						try {
							if (stationId) {
								if (isDirty) {
									await updateMutation.mutateAsync({ station: stationRequest, stationId });
								}
								resolve(true);
							} else {
								const newStation = await createMutation.mutateAsync(stationRequest);

								// Ugly hack: returning false to not go to next step inside of wizard, and going to next step manually
								// Hacked because next steps allowed only from path with stationId param
								let nextStep = steps[activeStep + 1];
								nextStep = { ...nextStep };
								nextStep.path = normalizePath(nextStep.path, { stationId: newStation.id });
								goToStep(nextStep);
								resolve(false);
							}
						} catch {
							reject(false);
						}
					},
					() => {
						// do not go to next step if validation is failed
						resolve(false);
					}
				)();
			});
		}
		return Promise.resolve(true);
	});

	const devicesList = useMemo(() => devices?.map((d) => ({ label: d, value: d })) || [], [devices]);

	return (
		<Station
			isCreate={isCreate}
			form={form}
			controllers={devicesList}
			types={stationTypes}
			unitSystemsOptions={unitSystems}
			specimenTypes={specimenTypesOptions}
		/>
	);
};

export default React.memo(StationContainer);
