export enum WidgetSettingsStates {
	INITIAL = 'initial',
	ADD_EXISTING = 'add_existing',
	CREATE_NEW = 'create_new'
}

export enum Modals {
	WIDGET_SETTINGS_MODAL = 'widget_settings_modal'
}

export enum DashboardTypes {
	STATION,
	PIPELINE
}

export enum WidgetTypes {
	GRAPH,
	RESULTS,
	TEST_INFORMATION,
	CONTROL_STAGE,
	STAGE_INFORMATION,
	EVENT_VIEWER,
	CONTROL_TEST,
	CONTROL_AXIS,
	SAFETY,
	READOUT,
	BUTTON_WIDGET,
	SENSOR_CALIBRATION = 13,
	OFFSET_CALIBRATION = 14
}

export enum EventTriggerTypes {
	'AUX' = 0,
	'MEASURE' = 1,
	'TEST_START' = 2,
	'TEST_END' = 3,
	'RESERVED' = 4
}

export enum EventTriggerObjectTypes {
	'E_STOP' = 0,
	'UP_LIMIT' = 1,
	'LO_LIMIT' = 2
}

export enum EventTriggerProps {
	'ON' = 0,
	'OFF' = 1
}
