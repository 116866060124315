import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DataTable, KebabMenu, StatusLabel, IconButton, StatusLabelTypes } from '@tactun/ui';
import { ITableColumns } from '../../../../types';
import styles from './ControllerSelectionModalContent.module.scss';
import { IDevice } from '../../connection.types';
import { MenuItem } from 'primereact/menuitem';

interface IControllerSelectionModalContentProps {
	onRefresh: () => void;
	onAddController: () => void;
	onRemoveController: (device: IDevice) => void;
	onToggleConnection: (device: IDevice) => void;
	onReboot: (ip: string) => void;
	onEdit: (device: IDevice) => void;
	onConfigureNetwork: (device: IDevice) => void;
	onSendLogs: (device: IDevice) => void;
	devices: IDevice[];
	manuallyAddedDevices: IDevice[];
	isLoading?: boolean;
}

const ControllerSelectionModalContent: React.FC<IControllerSelectionModalContentProps> = ({
	onRefresh,
	onAddController,
	onRemoveController,
	onToggleConnection,
	onReboot,
	onSendLogs,
	onEdit,
	onConfigureNetwork,
	isLoading,
	devices,
	manuallyAddedDevices
}) => {
	const { t } = useTranslation('connection');
	const transColumns = React.useMemo<ITableColumns[]>(() => {
		return [
			{ field: 'name', header: t('Controller Name') },
			{ field: 'model', header: t('Controller Model') },
			{ field: 'sn', header: t('Serial Number') },
			{ field: 'ip', header: t('IP Address') }
		];
	}, [t]);

	const createMenuItems = useCallback(
		(data: IDevice) => {
			const actions = [
				{
					label: t('Remove'),
					disabled: (data.isManuallyAdded && data.isConnected) || !data.isManuallyAdded,
					command: () => onRemoveController(data)
				},
				data.isManuallyAdded ? { label: t('Edit'), disabled: data.isConnected, command: () => onEdit(data) } : null,
				{
					label: t('Configure Network'),
					disabled: !data.isConnected,
					command: () => onConfigureNetwork(data)
				},
				{ label: t('Reboot'), disabled: !data.isConnected, command: () => onReboot(data.ip) },
				{ label: t('Send Controller Logs'), disabled: !data.isConnected, command: () => onSendLogs(data) }
			] as MenuItem[];

			return actions.filter((action) => action !== null) as MenuItem[];
		},
		[onEdit, onConfigureNetwork, onReboot, onRemoveController, onSendLogs, t]
	);

	const columnComponents = transColumns.map((col) => <DataTable.Column resizeable key={col.field} {...col} />);

	return (
		<div className={styles.container}>
			<p className={styles.title}>{t('Please choose a controller to connect')}</p>
			<DataTable value={devices} showGridlines columnResizeMode="fit" loading={isLoading} key="discoveredDevices">
				{columnComponents}
				<DataTable.Column
					header={t('Connectivity')}
					exportable
					body={(rowData) => (
						<StatusLabel type={rowData.isConnected ? StatusLabelTypes.SUCCESS : StatusLabelTypes.GRAY} isStaticLabel>
							{rowData.isConnected ? t('CONNECTED') : t('DISCONNECTED')}
						</StatusLabel>
					)}
				/>
				<DataTable.Column
					body={(rowData: IDevice) => (
						<IconButton
							icon={!rowData.isConnected ? t('connect') : t('disconnect')}
							fontSize="1.2rem"
							title={!rowData.isConnected ? t('connect') : t('disconnect')}
							onClick={() => onToggleConnection(rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
			</DataTable>
			<div className={styles.bottomBar}>
				<Button
					className={styles.iconRefresh}
					icon="t-icon-refresh"
					onClick={onRefresh}
					color="success"
					variant="outlined"
					disabled={window.__TAURI_INVOKE__ === undefined}
				>
					{t('Refresh')}
				</Button>
				<Button className={styles.iconManual} onClick={onAddController} color="secondary" variant="text">
					{t('Add controller manually')}
				</Button>
			</div>
			<DataTable
				value={manuallyAddedDevices}
				showGridlines
				columnResizeMode="fit"
				className={styles.secondTable}
				key="manuallyAddedDevices"
			>
				{columnComponents}
				<DataTable.Column
					header={t('Connectivity')}
					exportable
					body={(rowData) => (
						<StatusLabel type={rowData.isConnected ? StatusLabelTypes.SUCCESS : StatusLabelTypes.GRAY} isStaticLabel>
							{rowData.isConnected ? t('CONNECTED') : t('DISCONNECTED')}
						</StatusLabel>
					)}
				/>
				<DataTable.Column
					body={(rowData: IDevice) => (
						<IconButton
							icon={!rowData.isConnected ? t('connect') : t('disconnect')}
							fontSize="1.2rem"
							title={!rowData.isConnected ? t('connect') : t('disconnect')}
							onClick={() => onToggleConnection(rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
			</DataTable>
		</div>
	);
};

export default React.memo(ControllerSelectionModalContent);
