import React from 'react';
import cx from 'classnames';
import ButtonWidgetSubframe from '../components/ButtonWidgetSubframe';
import { ICustomWidgetForm, WidgetsLayoutTypes } from '../../CustomWidgets';
import styles from './ButtonWidget.module.scss';
import WidgetHeader from '../../Dashboard/components/Widget/WidgetHeader';
import WidgetContent from '../../Dashboard/components/Widget/WidgetContent';
import { WidgetProps } from '../../Dashboard/dashboard.types';

const ButtonWidget: React.FC<WidgetProps> = ({ metadata }) => {
	const { subFrames, layoutType, name } = metadata as ICustomWidgetForm;

	// TODO dashboard currently doesn't support dynamic min width/height of widgets
	// const { id, updateWidget, gridRef } = useContext(WidgetContext);
	// const { contentHeightToWidgetRow, contentWidthToWidgetCol } = useWidgetSizes(gridRef, {
	// 	hasHeader: true,
	// 	hasFooter: true
	// });
	//
	// useEffect(() => {
	// 	const firstSubFrame = subFrames?.[0] || { width: 100, height: 100 };
	// 	const minimums = getMinSizes(subFrames, [firstSubFrame.width, firstSubFrame.height], layoutType);
	//
	// 	updateWidget(id, {
	// 		minW: contentWidthToWidgetCol(minimums.minWidth),
	// 		minH: contentHeightToWidgetRow(minimums.minHeight)
	// 	});
	// }, [contentHeightToWidgetRow, contentWidthToWidgetCol, id, layoutType, subFrames, updateWidget]);

	return (
		<>
			<WidgetHeader disableMinimize>{name}</WidgetHeader>
			<WidgetContent showFooter>
				<div
					className={cx(styles.subFramesContainer, {
						[styles.singleLayout]: layoutType === WidgetsLayoutTypes.SINGLE,
						[styles.doubleVertical]: layoutType === WidgetsLayoutTypes.DOUBLE_VERTICAL,
						[styles.doubleHorizontal]: layoutType === WidgetsLayoutTypes.DOUBLE_HORIZONTAL,
						[styles.quadro]: layoutType === WidgetsLayoutTypes.QUADRO
					})}
				>
					{subFrames?.map((subFrame, index) => (
						<div
							key={index}
							style={{
								height: subFrame.height ? `${subFrame.height}%` : '100%',
								width: subFrame.width ? `${subFrame.width}%` : '100%'
							}}
							className={styles.subFrame}
						>
							<ButtonWidgetSubframe subFrame={subFrame} />
						</div>
					))}
				</div>
			</WidgetContent>
		</>
	);
};

export default React.memo(ButtonWidget);
