import { toast } from 'react-toastify';
import { t } from 'i18next';
import axios from '../../httpCommon';
import { isNotNullOrUndefined, toDataURL } from '../../tools/common';
import { getSpecimenType } from '../SpecimenTypes/specimenTypes.api';
import { getSpecimen, SpecimenStatusesDto } from '../Specimens';
import { getTestById } from '../Tests/tests.api';
import { getTestVariables } from '../Variables/variables.api';
import {
	IReportInfo,
	TestReportConfigurationRequest,
	TestReportConfigurationResponse
} from './testReportConfiguration.types';
import { PrecisionType, ReportFormat, ReportType, SpecimenStatus, VarType } from './testReportConfiguration.enums';
import { VariablesTypes } from '../Variables';
import { getUnitsByIds } from '../Units/units.api';
import { getTestExistingActions } from '../TestActions/testActions.api';
import { ActionDtoObjectTypes, CalculationActionDto } from '../Actions';

export const getTestReportConfig = async (testId?: string): Promise<TestReportConfigurationResponse> => {
	const response = await axios.get(`/cassiopeia/report-configuration/me/latest/${testId}`);

	return response.data.response;
};

export const updateTestReportConfig = async (params: TestReportConfigurationRequest) => {
	const response = await axios.put('/cassiopeia/report-configuration', params);

	return response.data.response;
};

export const createTestReportConfig = async (params: TestReportConfigurationRequest) => {
	const response = await axios.post('/cassiopeia/report-configuration', params);

	return response.data.response;
};

export const downloadReport = async (testId: string, specimenId: string, userName?: string) => {
	if (window.__TAURI_INVOKE__) {
		const [test, testReport, specimen, variables] = await Promise.allSettled([
			getTestById(testId),
			getTestReportConfig(testId),
			getSpecimen(specimenId),
			getTestVariables(testId)
		]);

		const additionalInformation = testReport.status === 'fulfilled' ? testReport.value.additionalInformation : null;
		const spParams = testReport.status === 'fulfilled' ? testReport.value.results.specimenParams : null;
		const testInfo = test.status === 'fulfilled' ? test.value : null;
		const specimenInfo = specimen.status === 'fulfilled' ? specimen.value : null;
		const testReportInfo = testReport.status === 'fulfilled' ? testReport.value : null;
		const fileOutput = testReportInfo?.fileOutput;
		const vars = variables.status === 'fulfilled' ? variables.value : null;

		const specimenType = specimenInfo?.specimenType?.id ? await getSpecimenType(specimenInfo?.specimenType?.id) : null;
		const specimenImage = await toDataURL(specimenType?.imageUrl);
		const companyLogo = await toDataURL(testReportInfo?.companyLogo);

		if (!additionalInformation || !testInfo || !specimenInfo || !fileOutput || !userName || !testInfo) {
			toast.error('Required information for report is missing');
			return;
		}

		const configuredVariables = testReportInfo?.results?.variables?.map((v) => v.id) || [];
		const specimenParams = spParams ? Object.keys(spParams) : [];

		const calcIdToUnitMap: Map<string, string> = new Map();
		(await getTestExistingActions(testId))
			.flatMap((it) => it.actions)
			.flatMap((it) => it.rulePositiveActions)
			.filter((it) => it?.type === ActionDtoObjectTypes.CALCULATION)
			.filter((it) => (it as CalculationActionDto).id !== undefined)
			.forEach((it) => {
				const calc = it as CalculationActionDto;
				calcIdToUnitMap.set(calc.id!!, calc.calculation?.unit!!);
			});

		const unitIdToUnitNameMap: Map<string, string> = new Map();
		(await getUnitsByIds(Array.from(calcIdToUnitMap.values()))).forEach((unit) => {
			unitIdToUnitNameMap.set(unit.id, unit.name);
		});

		const report: IReportInfo = {
			config: additionalInformation,
			additionalInformation: {
				std: testInfo.std,
				specimenType: testInfo.specimenType.name,
				specimenGroup: '',
				specimenMaterial: specimenInfo.specimenMaterial || '',
				specimenImage: specimenImage || ''
			},
			companyLogo: companyLogo || '',
			fileOutput: {
				outputFormat: ReportFormat[fileOutput.outputFormat] as ReportType,
				outputLocation: fileOutput.outputLocation
			},
			results: {
				calculations: testReportInfo.results.calculations.map((c) => ({
					id: c.id,
					includeInStatistics: c.includeInStatistics,
					includeSettings: c.includeSettings,
					name: c.name,
					precision: c.precision || 0,
					precisionType: c.precisionType || PrecisionType.DECIMAL_PLACES,
					unit: unitIdToUnitNameMap.get(calcIdToUnitMap.get(c.id) || '') || '',
					value: '' // this will be overridden by Tauri
				})),
				variables:
					vars !== null
						? vars
								.filter((v) => configuredVariables.some((id) => id === v.uuid))
								.map((v) => ({
									id: v.uuid,
									name: v.name,
									type: VariablesTypes[v.type] as VarType,
									value: v.defaultValue ?? ''
								}))
						: [],
				specimens: [
					{
						uuid: specimenInfo.uuid,
						specimenId: specimenInfo.specimenId,
						itemId: specimenInfo.items[0].uuid,
						material: specimenInfo.specimenMaterial || '',
						testId: specimenInfo.pipelineUuid,
						itemName: specimenInfo.batchId || specimenInfo.specimenId,
						status: SpecimenStatusesDto[specimenInfo.items[0].specimenTest.specimenTestStatus] as SpecimenStatus,
						params: Object.entries(specimenInfo.specimenType.dimensions || {})
							.filter(([key]) => specimenParams.some((p) => p === key))
							.reduce(
								(prev, [key, value]) => {
									return prev.set(key, {
										value: value.value.toPrecision(),
										unit: value.unit.name,
										precision: testReportInfo.results.specimenParams[key]?.precision || 0,
										precisionType:
											testReportInfo.results.specimenParams[key]?.precisionType || PrecisionType.DECIMAL_PLACES
									});
								},
								new Map<
									string,
									{
										value: String;
										unit: String;
										precision: number;
										precisionType: PrecisionType;
									}
								>()
							)
					}
				]
			},
			title: '',
			testName: testInfo.name,
			testId,
			specimenItemId: specimenInfo.items[0].uuid,
			operator: userName,
			date: t('common:dateTime', { date: new Date(specimenInfo.createdAt) }),
			genDate: t('common:dateTime', { date: new Date() }),
			uuid: testReportInfo.uuid,
			description: testInfo.description || ''
		};

		window.__TAURI_INVOKE__('generate_and_save_report', {
			report,
			path: './reports/render/render.html'
		});
	}
};
