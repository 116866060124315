// package: calculation
// file: calculation.proto

var calculation_pb = require("./calculation_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Calculation = (function () {
  function Calculation() {}
  Calculation.serviceName = "calculation.Calculation";
  return Calculation;
}());

Calculation.Calculate = {
  methodName: "Calculate",
  service: Calculation,
  requestStream: false,
  responseStream: false,
  requestType: calculation_pb.CalculationRequest,
  responseType: calculation_pb.CalculationResponse
};

exports.Calculation = Calculation;

function CalculationClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

CalculationClient.prototype.calculate = function calculate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Calculation.Calculate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.CalculationClient = CalculationClient;

