import {
	DashboardTypeFormType,
	IDashboardTypeListItem,
	IDashboardTypeRequest,
	IDashboardTypeResponse
} from './dashboardTypes.types';

export const dashboardTypeResponseToListItem = (input: IDashboardTypeResponse): IDashboardTypeListItem => {
	return {
		id: input.uuid,
		description: input.description,
		name: input.name,
		mode: input.mode
	};
};

export const dashboardTypeResponseToForm = (input: IDashboardTypeResponse): DashboardTypeFormType => {
	return {
		id: input.uuid,
		description: input.description,
		name: input.name,
		mode: input.mode,
		widgets: input.widgets
	};
};

export const dashboardTypeFormToRequest = (input: DashboardTypeFormType): IDashboardTypeRequest => {
	return {
		uuid: input.id,
		name: input.name,
		mode: input.mode,
		description: input.description,
		widgets: input.widgets ? input.widgets : []
	};
};
