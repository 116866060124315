import React from 'react';
import cx from 'classnames';
import Icon from '../../Icon';
import styles from './IconButton.module.scss';

export interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
	fontSize?: string;
	icon: string;
}

const IconButton: React.FC<IconButtonProps> = ({ className, icon, fontSize, onClick, ...rest }) => {
	return (
		<button {...rest} className={cx(styles.button, className, { [styles.disabled]: rest.disabled })} onClick={onClick}>
			<Icon
				name={icon}
				size={fontSize}
				className={cx({ [styles.disabledIcon]: rest.disabled })}
				color={styles['$heading-color-20']}
			/>
		</button>
	);
};
IconButton.displayName = 'IconButton';

export default React.memo(IconButton);
