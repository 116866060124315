import { FC } from 'react';
import styles from './AddWidgetSettings.module.scss';

interface AddWidgetSettingsProps {
	image: string;
	resource: string;
	onAddNew: () => void;
	onAddExisting: () => void;
}

export const AddWidgetSettings: FC<AddWidgetSettingsProps> = ({ image, onAddExisting, onAddNew, resource }) => {
	return (
		<div className={styles.container}>
			<div>
				<img src={image} alt={resource} />
			</div>
			<div className={styles.actions}>
				<button className={styles.button} onClick={onAddExisting}>
					Add
				</button>
				an existing readout or
				<button className={styles.button} onClick={onAddNew}>
					create
				</button>
				a new one
			</div>
		</div>
	);
};

export default AddWidgetSettings;
