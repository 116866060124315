export enum StationChannelCrudActionTypes {
	EDIT = 'EDIT_STATION_CHANNEL',
	CREATE = 'CREATE_STATION_CHANNEL',
	DUPLICATE = 'DUPLICATE_STATION_CHANNEL',
	DELETE = 'DELETE_STATION_CHANNEL'
}

export enum ChannelType {
	DIGITAL_IO_5V = 0,
	DIGITAL_IO_24V = 1,
	DIGITAL_IN_24V = 2,
	RELAY = 3,
	ANALOG_IN_10KSPS = 4,
	ANALOG_IN_10V_24MA = 5,
	ANALOG_IN_60KSPS = 6,
	SIGNAL_CONDITIONING = 7,
	LVDT = 8,
	ANALOG_OUT_10V = 9,
	ANALOG_OUT_10V_24MA = 10,
	ANALOG_OUT_300MA = 11,
	DC_PWM_30V = 12,
	DC_PWM_50V = 13,
	STEPPER_DRIVER = 14,
	SINGLE_ENDED_ENCODER = 15,
	DIFFERENTIAL_ENCODER = 16,
	ANALOG_OUT_100MA = 20,
	DC_PWM_65V = 21
}

export enum PullUpResistorState {
	OFF = 0,
	ON
}

export enum Direction {
	INPUT = 0,
	OUTPUT
}

export enum State {
	LOW_LEVEL = 0,
	HIGH_LEVEL
}

export enum Range {
	_0_5_V = 0,
	_0_10_V,
	_PM_2_5_V,
	_PM_5_V,
	_PM_10_V,
	_0_6_V,
	_0_12_V,
	_M_6_0_V,
	_M_12_0_V,
	_PM_3_V,
	_PM_6_V,
	_PM_12_V,
	_PM_24_V,
	_0_20_mA,
	_0_24_mA,
	_4_20_mA,
	_PM_24_mA,
	_0_3_mA,
	_0_6_mA,
	_0_12_mA,
	_0_25_mA,
	_0_50_mA,
	_0_100_mA,
	_0_200_mA,
	_PM_50_mA,
	_PM_100_mA
}

export enum SamplingRate {
	_2_5 = 0,
	_5,
	_10,
	_16_6,
	_20,
	_50,
	_60,
	_100,
	_400,
	_1200,
	_2400,
	_4800,
	_7200,
	_14400,
	_19200,
	_38400
}

export enum BridgeTypes {
	FULL_BRIDGE = 0,
	HALF_BRIDGE
}

export enum ExcitationVoltage {
	_2_5V = 0,
	_5V,
	_10V,
	EXTERNAL
}

export enum Gain {
	_1VV = 0,
	_2VV,
	_4VV,
	_8VV,
	_16VV,
	_32VV,
	_64VV,
	_128VV
}

export enum PGAGain {
	_0_125VV,
	_0_25VV,
	_0_5VV,
	_1VV,
	_2VV,
	_4VV,
	_8VV,
	_16VV,
	_32VV,
	_64VV,
	_128VV
}

// Use this for channel api
export enum SpecificType {
	VOLTAGE = 0,
	CURRENT,
	SINGLE_ENDED,
	DIFFERENTIAL
}

export enum AverageFilterPoints {
	_2 = 0,
	_4,
	_8,
	_16,
	_32,
	_64,
	_128
}

export enum PartNumbers {
	DIO = 0,
	ADS1262,
	DRV8701,
	AD5752R,
	ENCODER,
	MAX1301,
	AD5754R,
	DRV8704,
	SUPER_PART
}
