import { useMemo } from 'react';
import { PageLayout, Button, BottomBar, InfoBadge, InfoBadgeIcons } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { IListItem } from '../../../../types';
import { QuantityResponseDto } from '../../../Units';
import { SensorResponseDto } from '../../../Sensors/sensors.types';
import MeasurementForm from './MeasurementForm';

interface MeasurementProps {
	isCreate: boolean;
	isLoading: boolean;
	sensors: SensorResponseDto[];
	quantities: QuantityResponseDto[];
	variables: IListItem[];
	onBack: () => void;
	onSave: () => void;
	stationName: string;
}

const measurementFormId = 'measurementForm';
const Measurement: React.FC<MeasurementProps> = ({
	isCreate,
	isLoading,
	onBack,
	onSave,
	sensors,
	variables,
	quantities,
	stationName
}) => {
	const { t } = useTranslation('measurements');
	const title = useMemo<string>(() => {
		return isCreate ? t('Create Measurement') : t('Edit Measurement');
	}, [isCreate, t]);

	return (
		<>
			<PageLayout info={stationName}>
				<PageLayout.Header title={title} onBack={onBack} />
				<MeasurementForm
					formId={measurementFormId}
					onSave={onSave}
					sensors={sensors}
					variables={variables}
					quantities={quantities}
				/>
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button data-testid="cancelBtn" label={t('Cancel')} variant="contained" color="success" onClick={onBack} />
				<Button
					data-testid="saveBtn"
					label={t('Save')}
					variant="contained"
					color="secondary"
					type="submit"
					form={measurementFormId}
				/>
			</BottomBar>
		</>
	);
};

export default Measurement;
