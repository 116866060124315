import { FC, useCallback, useMemo } from 'react';
import { IconButton } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { useTableConfigs } from '../../../../hooks';
import { DashboardDisplayingMode, DashboardTypeCrudActionTypes } from '../../dashboardTypes.enums';
import { IDashboardTypeListItem } from '../../dashboardTypes.types';

interface IDashboardTypesTableProps {
	onAction: (type: DashboardTypeCrudActionTypes, data?: IDashboardTypeListItem) => void;
	data?: IDashboardTypeListItem[];
	isLoading: boolean;
}

const DashboardTypesTable: FC<IDashboardTypesTableProps> = ({ data, isLoading, onAction }) => {
	const { t } = useTranslation('dashboardTypes');

	const transColumns = useMemo<ITableColumns<IDashboardTypeListItem>[]>(() => {
		return [
			{
				field: 'mode',
				header: t('Display mode'),
				body: (rowData) => t(DashboardDisplayingMode[rowData.mode])
			},
			{
				field: 'description',
				header: t('Description')
			}
		];
	}, [t]);

	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<IDashboardTypeListItem>(transColumns, data);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Duplicate'),
				icon: 't-icon-duplicate',
				disabled: true,
				command: () => onAction(DashboardTypeCrudActionTypes.DUPLICATE, data)
			},
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(DashboardTypeCrudActionTypes.DELETE, data)
			}
		],
		[t, onAction]
	);

	const handleCreate = useCallback(() => {
		onAction(DashboardTypeCrudActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={t('Components')}>
			<PageLayout.Header title={t('Dashboard Types')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('dashboard type')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={data}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Dashboard type name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(DashboardTypeCrudActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default DashboardTypesTable;
