export const getCustomers = async (): Promise<Record<string, string>[]> => {
	return new Promise((resolve) =>
		resolve([
			{
				id: '1',
				companyName: 'ABC Company',
				creationDate: '12/04/2023, 13:04:56',
				ordersQuantity: '2',
				latestOrderDate: '12/04/2023, 13:04:56',
				status: ''
			},
			{
				id: '2',
				companyName: 'XYZ Company',
				creationDate: '12/04/2023, 05:04:51',
				ordersQuantity: '159',
				latestOrderDate: '12/04/2023, 05:04:51',
				status: ''
			},
			{
				id: '3',
				companyName: 'Company 1',
				creationDate: '12/06/2023, 13:04:56',
				ordersQuantity: '1',
				latestOrderDate: '12/06/2023, 13:04:56',
				status: ''
			},
			{
				id: '4',
				companyName: 'Company 2',
				creationDate: '12/04/2023, 11:04:24',
				ordersQuantity: '300',
				latestOrderDate: '12/06/2023, 13:04:56',
				status: ''
			}
		])
	);
};

export const getCustomer = async (id: string): Promise<Record<string, string>> => {
	return new Promise((resolve) =>
		resolve({
			id: '2',
			companyName: 'XYZ Company',
			creationDate: '12/04/2023, 13:04:56',
			ordersQuantity: '4',
			latestOrderDate: '12/06/2023, 13:04:56',
			status: ''
		})
	);
};
