import { Dropdown, useIsChanged } from '@tactun/ui';
import { FC, useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { RuleAction } from '../../actions.types';
import { useTranslation } from 'react-i18next';
import { ActionAxisProperties, AxisActionAdditionalChannels, Properties } from '../../actions.enums';
import { IDropdownOption } from '@tactun/ui/src/components/FormElements/Dropdown';
import { IAxisResponseDto } from '../../../Axes';

export interface AxisActionFormProps {
	form: UseFormReturn<RuleAction>;
	axes: IAxisResponseDto[];
}

const AxisActionForm: FC<AxisActionFormProps> = ({ form, axes }) => {
	const { t } = useTranslation();
	const {
		control,
		watch,
		setValue,
		formState: { errors, isDirty }
	} = form;

	const property = watch('property');
	const objectId = watch('objectId');
	const selectedAxis = useMemo(() => {
		if (objectId) {
			return axes.find((axis) => axis.id === objectId);
		}
	}, [objectId, axes]);

	const isAxisChanged = useIsChanged(objectId) && isDirty;
	useEffect(() => {
		if (isAxisChanged) {
			setValue('property', null);
			setValue('enableBitChannels', null);
		}
	}, [isAxisChanged, setValue]);

	const enableBitOptions = useMemo(() => {
		const options: IDropdownOption<string>[] = [];
		if (selectedAxis?.actuator.s1EnableBitChs) {
			selectedAxis?.actuator.s1EnableBitChs.forEach((bit) => {
				options.push({ value: bit.id, label: t(`First Stage "${bit.name}"`) });
			});
		}
		if (selectedAxis?.actuator.s2EnableBitChs) {
			selectedAxis?.actuator.s2EnableBitChs.forEach((bit) => {
				options.push({ value: bit.id, label: t(`Second Stage "${bit.name}"`) });
			});
		}
		if (property === ActionAxisProperties.DISABLE) {
			options.push({ value: AxisActionAdditionalChannels.WAVEFORM.toString(), label: t('Waveform') });
		}
		options.push({ value: AxisActionAdditionalChannels.OUTPUT.toString(), label: t('Output') });
		return options;
	}, [property, selectedAxis?.actuator, t]);

	return (
		<>
			{(property === Properties.ENABLE || property === Properties.DISABLE) && (
				<Controller
					control={control}
					name="enableBitChannels"
					render={({ field }) => (
						<Dropdown
							{...field}
							label={t('Object*')}
							options={enableBitOptions}
							isMulti
							error={errors.enableBitChannels?.message}
						/>
					)}
				/>
			)}
		</>
	);
};

export default AxisActionForm;
