import { toast } from 'react-toastify';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { grpc } from '@improbable-eng/grpc-web';
import { tedsService, systemService, TedsResponse } from '@tactun/grpc-client';
import { SensorResponseDto as Sensor } from '../Sensors/sensors.types';
import { SensorsTypes } from '../Sensors/sensors.enums';
import {
	sensorResponseToCalibrationSensorGRPCConverter,
	sensorResponseToTedsGRPCConverter
} from '../Sensors/sensors.converters.grpc';
import { UnaryOutput } from '@improbable-eng/grpc-web/dist/typings/unary';
import { ProtobufMessage } from '@improbable-eng/grpc-web/dist/typings/message';
import { tError } from '../../tools/logger';

export interface ISensorCalibrationError {
	code: string;
	message: string;
}

export interface ISensorCalibrationData {
	data: number;
}

export const useSensorCalibration = () => {
	const [isConnected, setIsConnected] = useState<boolean>(false);
	const [data, setData] = useState<number>(0);
	const [error, setError] = useState<ISensorCalibrationError>();
	const grpcRequestRef = useRef<grpc.Request>();

	const connect = useCallback(
		(
			sensor: Sensor,
			isShuntCalibration: boolean = false,
			isInversePolarity: boolean = false,
			configure: boolean = false
		) => {
			if (process.env.REACT_APP_DEVICE_URL) {
				const calibrationRequest = sensorResponseToCalibrationSensorGRPCConverter(
					{
						...sensor,
						inversePolarity: isInversePolarity,
						calibrations: undefined
					},
					configure
				);
				if (isShuntCalibration) {
					const calibrations = calibrationRequest.getSensorRequest();
					calibrations?.getCalibrations()?.setShuntCalibration(isShuntCalibration);
				}

				const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });
				let isFirstMessage = true;

				grpcRequestRef.current = grpc.invoke(systemService.WebSensorCalibration.SensorCalibration, {
					request: calibrationRequest,
					host: process.env.REACT_APP_DEVICE_URL,
					transport: myTransport,
					onEnd: (code: grpc.Code, message: string) => {
						setIsConnected(false);

						tError(`GRPC stream end code: "${code.toString()}" \nGRPC stream end message: "${message}" \n `);

						if (code !== grpc.Code.OK) {
							setError({ code: code.toString(), message });
						}
					},
					onMessage(res: grpc.ProtobufMessage) {
						if (isFirstMessage) {
							isFirstMessage = false;
							setIsConnected(true);
						}
						const data = res.toObject() as ISensorCalibrationData;
						setData(data.data);
					}
				});
			}
		},
		[]
	);

	const disConnect = useCallback(() => {
		try {
			if (isConnected) {
				grpcRequestRef.current?.close();
			}
		} catch {}
	}, [isConnected]);

	useEffect(() => {
		return () => disConnect();
	}, [disConnect]);

	const dataFlow = useMemo(() => {
		return { connect, disConnect, isConnected, data, error };
	}, [connect, data, disConnect, error, isConnected]);

	return dataFlow;
};

export const useTedsId = (sensor?: Sensor, getTedsId?: boolean) => {
	const [tedsId, setTedsId] = useState<string>();
	const grpcRequestRef = useRef<grpc.Request>();

	useEffect(() => {
		if (process.env.REACT_APP_DEVICE_URL && sensor && sensor.type === SensorsTypes.LOAD_CELL && getTedsId) {
			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

			const tersRequest = sensorResponseToTedsGRPCConverter(sensor);

			grpcRequestRef.current = grpc.unary(tedsService.WebTeds.ReadTeds, {
				request: tersRequest,
				host: process.env.REACT_APP_DEVICE_URL,
				transport: myTransport,
				onEnd: (output: UnaryOutput<ProtobufMessage>) => {
					if (output.status === grpc.Code.OK) {
						const res = output.message?.toObject() as TedsResponse.AsObject;
						setTedsId(res ? res.teds : undefined);
					} else {
						toast.error(`Teds ID retrieving error: ${output.statusMessage}`);
					}
				}
			});
		}
	}, [getTedsId, sensor]);

	return tedsId;
};
