import { useMemo } from 'react';
import { useEnumList } from '../../hooks';
import { IListItem } from '../../types';
import { ControlAlgorithmsAdditional, ControlAlgorithmsBase } from './controlAlgorithms.enums';

export const useControlAlgorithmTypes = (
	selectedAlgorithms: ControlAlgorithmsAdditional[] | undefined,
	selectedBaseAlgorithm?: ControlAlgorithmsBase
) => {
	const algorithmTypesList = useEnumList(ControlAlgorithmsAdditional, 'controlAlgorithm');

	const algorithmTypesListFiltered = useMemo<IListItem[]>(() => {
		if (selectedAlgorithms === undefined) return [];

		return algorithmTypesList.map((type) => {
			const current = { label: type.label, value: type.value, isDisabled: false };
			if (type.value === ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE) {
				current.isDisabled = selectedAlgorithms.includes(ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN);
			} else if (type.value === ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN) {
				current.isDisabled = selectedAlgorithms.includes(ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE);
			} else if (type.value === ControlAlgorithmsAdditional.FEED_FORWARD) {
				current.isDisabled = selectedBaseAlgorithm === ControlAlgorithmsBase.OPEN_LOOP;
			}
			return current;
		});
	}, [algorithmTypesList, selectedAlgorithms, selectedBaseAlgorithm]);

	return algorithmTypesListFiltered;
};
