import { FocusEvent, memo, useState, useCallback, ReactElement, CSSProperties } from 'react';
import cx from 'classnames';

import styles from './FieldFrame2.module.scss';

type Size = 'sm' | 'md' | 'lg' | 'auto';
type ErrorPosition = 'absolute' | 'relative';

type EventFocusCallback = (e: FocusEvent<HTMLElement>) => void;

export interface IFieldFrameProps {
	label?: React.ReactNode;
	fieldFrameClassName?: string;
	containerClassName?: string;
	className?: string;
	style?: CSSProperties;
	size?: Size;
	disabled?: boolean;
	errorPosition?: ErrorPosition;
	error?: string;
	leftIcon?: ReactElement;
	rightIcon?: ReactElement;
	dataTestId?: string;
	children: (props: {
		disabled: boolean;
		withLabel: boolean;
		className: string;
		isFocused: boolean;
		onFocus: EventFocusCallback;
		onBlur: EventFocusCallback;
	}) => ReactElement | ReactElement[];
	onFocus?: EventFocusCallback;
	onBlur?: EventFocusCallback;
	onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const FieldFrame2 = ({
	label,
	fieldFrameClassName,
	containerClassName,
	className,
	style,
	size,
	disabled,
	dataTestId,
	errorPosition,
	error,
	leftIcon,
	rightIcon,
	children,
	onFocus,
	onBlur,
	onMouseDown
}: IFieldFrameProps) => {
	const [isFocused, setIsFocused] = useState(false);

	const _onFocus = useCallback(
		(e: FocusEvent<HTMLElement>) => {
			setIsFocused(true);
			if (onFocus) {
				onFocus(e);
			}
		},
		[onFocus]
	);

	const _onBlur = useCallback(
		(e: FocusEvent<HTMLElement>) => {
			setIsFocused(false);
			if (onBlur) {
				onBlur(e);
			}
		},
		[onBlur]
	);

	return (
		<div className={cx(styles.container, className)} style={style} onMouseDown={onMouseDown}>
			{disabled && <div className={styles.disabledOverlay} />}
			{label && <span className={styles.fieldLabel}>{label}</span>}
			<div
				className={cx(styles.fieldFrame, styles[size as string], fieldFrameClassName, {
					[styles.hasError]: Boolean(error),
					[styles.disabled]: disabled,
					[styles.isFocused]: isFocused
				})}
			>
				<div className={cx(styles.fieldContainer, containerClassName)} data-testid={dataTestId}>
					{leftIcon && <span className={cx(styles.fieldIcon, styles[size as string], styles.left)}>{leftIcon}</span>}
					{children({
						disabled: Boolean(disabled),
						isFocused,
						withLabel: !!label,
						className: cx(styles.fieldControl, styles[size as string], {
							[styles.iconLeft]: leftIcon,
							[styles.iconRight]: rightIcon
						}),
						onFocus: _onFocus,
						onBlur: _onBlur
					})}
					{rightIcon && <span className={cx(styles.fieldIcon, styles[size as string], styles.right)}>{rightIcon}</span>}
				</div>
				{error && (
					<div className={cx(styles.fieldError, { [styles.relativeError]: errorPosition === 'relative' })}>{error}</div>
				)}
			</div>
		</div>
	);
};

FieldFrame2.defaultProps = {
	size: 'md',
	errorPosition: 'absolute'
};

export default Object.assign(memo(FieldFrame2), { displayName: 'FieldFrame2' });
