import React from 'react';
import { DataTable, EDataTableThemes } from '@tactun/ui';
import WidgetContent from '../../../Dashboard/components/Widget/WidgetContent';
import WidgetHeader from '../../../Dashboard/components/Widget/WidgetHeader';
import { SpecimenStatusesDto, SpecimenStatusLabel } from '../../../Specimens';
import styles from './Results.module.scss';

const data1 = [
	{ specimenId: 'Specimen 1', specimenStatus: SpecimenStatusesDto.TESTED, calc1: '56', calc2: '57' },
	{ specimenId: 'Specimen 2', specimenStatus: SpecimenStatusesDto.TEST_IN_PROGRESS, calc1: '78', calc2: '89' },
	{ specimenId: 'Specimen 3', specimenStatus: SpecimenStatusesDto.NOT_TESTED, calc1: '22', calc2: '' }
];

const ResultsTable: React.FC = () => {
	const { header, rows } = React.useMemo<{
		header: string[];
		rows: Record<string, string | SpecimenStatusesDto>[];
	}>(() => {
		const result: { header: string[]; rows: Record<string, string | SpecimenStatusesDto>[] } = {
			header: [],
			rows: [] as Record<string, SpecimenStatusesDto>[]
		};
		result.rows = data1;
		result.header = ['calc1', 'calc2'];
		return result;
	}, []);

	const columnComponents = React.useMemo(() => {
		return header.slice(0).map((key) => <DataTable.Column key={key} header={key} field={key} />);
	}, [header]);

	return (
		<>
			<WidgetHeader>Results Table</WidgetHeader>
			<WidgetContent>
				<DataTable className={styles.tableContainer} value={rows} dataKey="specimenId" theme={EDataTableThemes.WIDGET}>
					<DataTable.Column
						body={(row: Record<string, string | SpecimenStatusesDto>) => (
							<SpecimenStatusLabel status={row.specimenStatus as SpecimenStatusesDto} />
						)}
						header="Specimen ID"
					/>
					{columnComponents}
				</DataTable>
			</WidgetContent>
		</>
	);
};

export default React.memo(ResultsTable);
