import axios from '../../httpCommon';
import { IControllerResponseDto } from './controllers.types';

export const getControllers = async (): Promise<IControllerResponseDto[]> => {
	const response = await axios.get('/cassiopeia/controller', {
		params: { page: 0, size: 100 }
	});
	return response.data.response.data as IControllerResponseDto[];
};

export const getController = async (id: string): Promise<Record<string, string>> => {
	return new Promise((resolve) =>
		resolve({ id: '2', serialNumber: '2221GSJ344', model: 'Xyz', date: '12/06/2023, 13:04:56', status: 'AVAILABLE' })
	);
};
