import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useStation } from '../Stations';
import * as api from './stationChannels.api';
import {
	controllerChannelDtoToControllerChannelMetaConverter,
	responseListItemConverter
} from './stationChannels.converters';
import { ChannelType, Direction } from './stationChannels.enums';
import {
	IDevice,
	IStationChannelResponseDto,
	StationChannelListItem,
	ControllerChannelMeta
} from './stationChannels.types';
import { useTranslation } from 'react-i18next';

export const useStationChannels = (stationId?: string) => {
	const { data: stationChannelsDto } = useQuery<StationChannelListItem[], Error>({
		queryKey: ['stationChannels', stationId],
		queryFn: async () => {
			const resp = await api.getStationChannels(stationId);
			return resp.map((stationChannel) => responseListItemConverter(stationChannel));
		},
		enabled: !!stationId
	});

	return { stationChannelsDto };
};

export const useUnusedStationChannels = (stationId?: string, types?: ChannelType[], direction?: Direction) => {
	const { data = [] } = useQuery<IStationChannelResponseDto[], Error>({
		queryKey: ['unUsedStationChannels', { stationId, types }],
		queryFn: async () => {
			const resp = await api.getUnusedStationChannels(stationId as string, types as ChannelType[]);
			return resp;
		},
		enabled: !!stationId && !!types?.length
	});

	const stationChannels = useMemo<IStationChannelResponseDto[]>(
		() => (direction !== undefined ? data.filter((d) => d.direction === direction) : data),
		[data, direction]
	);

	return { stationChannels };
};

export const useStationChannel = (stationChannelId?: string) => {
	const { data: stationChannelDto } = useQuery<IStationChannelResponseDto, Error>({
		queryKey: ['stationChannel', stationChannelId],
		queryFn: () => api.getChannelById(stationChannelId),
		enabled: !!stationChannelId
	});

	return { stationChannelDto };
};

export const useDevice = (controllerModel?: string) => {
	const { data: device } = useQuery<IDevice, Error>({
		queryKey: ['device', controllerModel],
		queryFn: () => {
			return api.getDeviceByModel(controllerModel);
		},
		enabled: !!controllerModel
	});

	return {
		device
	};
};

export const useStationChannelMeta = (stationId?: string, stationChannelType?: ChannelType) => {
	const { stationDto } = useStation(stationId);
	const { t } = useTranslation('stationChannel');

	const { device } = useDevice(stationDto?.controllerModel);

	const stationChannelMeta = useMemo<ControllerChannelMeta | undefined>(() => {
		const controllerChannel = device?.controllerChannels?.find(
			(controlChannel) => controlChannel.stationChannelType === stationChannelType
		);

		if (controllerChannel) return controllerChannelDtoToControllerChannelMetaConverter(controllerChannel, t);
	}, [device?.controllerChannels, stationChannelType, t]);

	return stationChannelMeta;
};
