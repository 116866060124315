import React, { useCallback } from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { testDataLoggingModalId } from '../../testDataLogging.const';
import styles from './TestDataLoggingModal.module.scss';

interface ITestDataLoggingTableModalProps {
	children: React.ReactNode;
}

const TestDataLoggingModal: React.FC<ITestDataLoggingTableModalProps> = ({ children }) => {
	const { t } = useTranslation('dataLoggings');

	const handleClose = useCallback(() => {
		Modal.hide(testDataLoggingModalId);
	}, []);

	return (
		<Modal id={testDataLoggingModalId} className={styles.container} shouldCloseOnOverlayClick={false}>
			<Modal.Header>{t('Data Loggings')}</Modal.Header>
			<Modal.Content className={styles.content}>{children}</Modal.Content>
			<Modal.Footer>
				<Button onClick={handleClose} color="secondary" variant="contained">
					{t('Done')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(TestDataLoggingModal);
