import { DataTable, KebabMenu, Modal } from '@tactun/ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DimensionTypeActionTypes, DimensionFormType } from '../../specimenTypes.types';
import styles from './DimensionTable.module.scss';
import { ConfirmationModal } from '../../../../components';

interface DimensionTableProps {
	dimensions: DimensionFormType[];
	usedDimensions: string[];
	onAction: (type: DimensionTypeActionTypes, data?: DimensionFormType) => void;
	error?: string;
}

const paramConfirmationModalId = 'paramConfirmationModalId';

const DimensionTable: React.FC<DimensionTableProps> = ({ dimensions, usedDimensions, error, onAction }) => {
	const { t } = useTranslation('specimenTypes');

	const [deleteItem, setDeleteItem] = useState<DimensionFormType | null>(null);
	const createMenuItems = useCallback(
		(data: DimensionFormType) => [
			{
				label: 'Edit',
				command: () => onAction(DimensionTypeActionTypes.EDIT, data)
			},
			{
				label: 'Delete',
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => {
					setDeleteItem(usedDimensions.includes(data.name) ? null : data);
					return Modal.show(paramConfirmationModalId);
				}
			}
		],
		[onAction, setDeleteItem, usedDimensions]
	);

	const handleConfirm = useCallback(() => {
		if (deleteItem) onAction(DimensionTypeActionTypes.DELETE, deleteItem);
		setDeleteItem(null);
		ConfirmationModal.hide(paramConfirmationModalId);
	}, [deleteItem, onAction]);

	return (
		<>
			<div className={styles.container}>
				<DataTable value={dimensions} dataKey="id" showGridlines>
					<DataTable.Column field="name" header={t('Parameter Name')} />
					<DataTable.Column field="unitName" header={t('Parameter Unit')} />
					<DataTable.Column
						body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
						exportable={false}
						align="right"
						headerStyle={{ width: '1em' }}
					/>
				</DataTable>
				<span>{error}</span>
			</div>
			<ConfirmationModal
				title={t('Warning')}
				confirmBtnText={deleteItem ? t('Delete') : t('Done')}
				id={paramConfirmationModalId}
				onConfirm={handleConfirm}
				hideCancel={!deleteItem}
			>
				{deleteItem
					? t(`You are about to delete ${deleteItem.name} parameter. This action is irreversible. Are you sure?`)
					: t(
							'The parameter is used in the Gauge Length or Area equation. Remove the parameter from the equation to be able to delete it.'
					  )}
			</ConfirmationModal>
		</>
	);
};

export default DimensionTable;
