import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, InputText, Switch } from '@tactun/ui';
import { controllerConfigureNetworkFormId } from '../../connection.const';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ControllerConfigureNetworkFormType } from '../../connection.types';
import styles from './ControllerConfigureNetworkModalContent.module.scss';

interface IControllerConfigureNetworkModalContentProps {
	onSave: () => void;
	form: UseFormReturn<ControllerConfigureNetworkFormType>;
	disabled: boolean;
}

const ControllerConfigureNetworkModalContent: React.FC<IControllerConfigureNetworkModalContentProps> = ({
	onSave,
	form,
	disabled
}) => {
	const { t } = useTranslation('connection');
	const {
		watch,
		register,
		control,
		formState: { errors }
	} = form;
	const isDHCPEnabled = watch('isDHCPEnabled');

	return (
		<Form className={styles.container} id={controllerConfigureNetworkFormId} onSubmit={onSave}>
			<Controller
				name={'isDHCPEnabled'}
				control={control}
				render={({ field }) => (
					<Switch
						label={t('Enable DHCP')}
						inputId={field.name}
						onChange={(e) => field.onChange(e.value)}
						checked={!!field.value}
						disabled={disabled}
					/>
				)}
			/>
			<div />
			<InputText
				disabled={isDHCPEnabled || disabled}
				data-testid="ip"
				label={t('IP address')}
				{...register('ip')}
				error={errors.ip?.message}
			/>
			<InputText
				disabled={isDHCPEnabled || disabled}
				data-testid="gateway"
				label={t('Gateway')}
				{...register('gateway')}
				error={errors.gateway?.message}
			/>
			<InputText
				disabled={isDHCPEnabled || disabled}
				data-testid="mask"
				label={t('Subnet mask')}
				{...register('mask')}
				error={errors.mask?.message}
			/>
		</Form>
	);
};

export default ControllerConfigureNetworkModalContent;
