import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AddButton, Button, Dropdown, Form, GroupInputFrame, InputNumber, InputText, Modal } from '@tactun/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
	EqualConditions,
	InRangeConditions,
	ConditionLogic,
	ConditionOptions,
	ConditionsAuxDeviceProperties,
	conditionSchema,
	ConditionsConnectionProperties,
	ConditionsDigitalInputProperties,
	ConditionsObjectTypes,
	ConditionsTestProperties,
	RuleCondition,
	ConditionsStationProperties,
	Condition,
	ConditionsUIButtonProperties
} from '../../';
import ConditionCard from '../ConditionCard';
import { MeasurementResponseDto } from '../../../Measurements';
import { IStationChannelResponseDto } from '../../../StationChannels';
import { IAuxiliaryDeviceResponseDto } from '../../../AuxiliaryDevices';
import { convertNumberEnumToList } from '../../../../tools';
import { useEntityOptions, useNumberEnumList } from '../../../../hooks';
import { IListItemNumber } from '../../../../types';
import styles from './Conditions.module.scss';
import { CalculationResponseDto } from '../../../Calculations';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';

const defaultValues: RuleCondition = {
	objectId: null,
	objectType: null,
	condition: null,
	operation: null,
	property: null,
	lowerLimit: 0,
	upperLimit: 0,
	threshold: 0
};

export interface ConditionsProps {
	conditions: RuleCondition[];
	updateConditions: (newConditions: RuleCondition[]) => void;
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	calculations: CalculationResponseDto[];
	digitalChannels: IStationChannelResponseDto[];
	auxDevices: IAuxiliaryDeviceResponseDto[];
	conditionLogic: ConditionLogic;
	conditionOptions: ConditionOptions;
	setConditionLogic: (value: ConditionLogic) => void;
}

const Conditions: React.FC<ConditionsProps> = ({
	conditions,
	updateConditions,
	measurements,
	calculations,
	digitalChannels,
	auxDevices,
	conditionLogic,
	conditionOptions,
	setConditionLogic
}) => {
	const formId = useRef('condition-form');
	const { t } = useTranslation('rulesCondition');

	const form = useForm<RuleCondition>({
		defaultValues,
		mode: 'onBlur',
		//@ts-ignore
		resolver: yupResolver(conditionSchema)
	});

	const {
		control,
		watch,
		handleSubmit,
		reset,
		register,
		trigger,
		formState: { dirtyFields, errors }
	} = form;

	// Modal
	const [modalState, setModalState] = useState<{ isOpen: boolean; index?: number }>({
		isOpen: false
	});

	const openModal = useCallback(
		(index?: number) => {
			reset(index !== undefined ? conditions[index] : defaultValues);
			setModalState({ isOpen: true, index: index });
		},
		[reset, conditions]
	);

	const closeModal = useCallback(() => {
		reset(defaultValues);
		setModalState({ isOpen: false });
	}, [reset]);

	const onSubmit = handleSubmit((validCondition) => {
		const newConditions = [...conditions];
		if (modalState.index !== undefined) {
			newConditions.splice(modalState.index, 1, validCondition);
		} else {
			newConditions.push(validCondition);
		}
		updateConditions(newConditions);
		closeModal();
	});

	const lowerLimit = watch('lowerLimit');
	const upperLimit = watch('upperLimit');

	useEffect(() => {
		if (lowerLimit) {
			trigger('upperLimit');
		}
	}, [lowerLimit, trigger]);

	useEffect(() => {
		if (upperLimit) {
			trigger('lowerLimit');
		}
	}, [upperLimit, trigger]);

	const type = watch('objectType');
	useEffect(() => {
		if (type !== undefined && type !== null && dirtyFields.objectType) {
			form.reset({ ...defaultValues, objectType: type });
		}
	}, [form, type, dirtyFields]);

	const typeAllOptions = useMemo<IListItemNumber[]>(() => convertNumberEnumToList(ConditionsObjectTypes, t), [t]);
	const typeOptions = useMemo(() => {
		const numberFilters = Object.keys(conditionOptions).map((v) => Number(v));
		return typeAllOptions.filter(({ value }) => numberFilters.includes(value));
	}, [typeAllOptions, conditionOptions]);

	const digitalChannelsOptions = useEntityOptions(digitalChannels);
	const calculationsOptions = useEntityOptions(calculations);
	const measurementsOptions = useEntityOptions(measurements);
	const auxDevicesOptions = useEntityOptions(auxDevices);

	const objectOptions = useMemo(() => {
		switch (type) {
			case ConditionsObjectTypes.DIGITAL_INPUT:
				return digitalChannelsOptions;
			case ConditionsObjectTypes.CALCULATION:
				return calculationsOptions;
			case ConditionsObjectTypes.MEASUREMENT:
				return measurementsOptions;
			case ConditionsObjectTypes.AUX_DEVICE:
				return auxDevicesOptions;
			default:
				return [];
		}
	}, [auxDevicesOptions, calculationsOptions, digitalChannelsOptions, measurementsOptions, type]);

	const objectLabel = useMemo(() => {
		switch (type) {
			case ConditionsObjectTypes.DIGITAL_INPUT:
				return t('Channel*');
			case ConditionsObjectTypes.CALCULATION:
				return t('Calculation*');
			case ConditionsObjectTypes.MEASUREMENT:
				return t('Measurement*');
			case ConditionsObjectTypes.AUX_DEVICE:
				return t('Auxiliary device*');
			default:
				return undefined;
		}
	}, [t, type]);

	const operationOptions = useNumberEnumList(EqualConditions, 'rulesCondition');
	const inRangeConditions = useNumberEnumList(InRangeConditions, 'rulesCondition');
	const inRangeAndMoreLess = useNumberEnumList(Condition, 'rulesCondition');

	const conditionDropdownOptions = useMemo(() => {
		switch (type) {
			case ConditionsObjectTypes.MEASUREMENT:
				return inRangeAndMoreLess;
			case ConditionsObjectTypes.CALCULATION:
				return inRangeConditions;
		}
	}, [inRangeAndMoreLess, inRangeConditions, type]);

	const getDeleteHandler = useCallback(
		(index: number) => () => {
			const newConditions = [...conditions.slice(0, index), ...conditions.slice(index + 1)];
			updateConditions(newConditions);
		},
		[conditions, updateConditions]
	);

	const objectId = watch('objectId');

	const stationConditions = useNumberEnumList(ConditionsStationProperties, 'rulesCondition');
	const digitalInputProperties = useNumberEnumList(ConditionsDigitalInputProperties, 'rulesCondition');
	const testProperties = useNumberEnumList(ConditionsTestProperties, 'rulesCondition');
	const axuDeviceProperties = useNumberEnumList(ConditionsAuxDeviceProperties, 'rulesCondition');
	const connectionProperties = useNumberEnumList(ConditionsConnectionProperties, 'rulesCondition');
	const uiBottomProperties = useNumberEnumList(ConditionsUIButtonProperties, 'rulesCondition');

	const conditionPropertyOptions = useMemo<IListItemNumber[]>(() => {
		let allProperties: IListItemNumber[];
		switch (type) {
			case ConditionsObjectTypes.STATION:
				allProperties = stationConditions;
				break;
			case ConditionsObjectTypes.DIGITAL_INPUT:
				allProperties = digitalInputProperties;
				break;
			case ConditionsObjectTypes.TEST:
				allProperties = testProperties;
				break;
			case ConditionsObjectTypes.AUX_DEVICE:
				allProperties = axuDeviceProperties;
				break;
			case ConditionsObjectTypes.CONNECTION:
				allProperties = connectionProperties;
				break;
			case ConditionsObjectTypes.UI_BUTTON:
				allProperties = uiBottomProperties;
				break;
			default:
				allProperties = [];
		}

		return type !== null && type !== undefined
			? allProperties.filter(({ value }) => conditionOptions[type]?.includes(value))
			: [];
	}, [
		type,
		stationConditions,
		digitalInputProperties,
		testProperties,
		axuDeviceProperties,
		connectionProperties,
		uiBottomProperties,
		conditionOptions
	]);

	const limitsSuffix = useMemo(() => {
		const selectedMeasurement = measurements.find((measurement) => measurement.id === objectId);
		return selectedMeasurement?.unit.name || '';
	}, [measurements, objectId]);

	const conditionLogicLabel = conditionLogic === ConditionLogic.AND ? t('AND') : t('OR');

	const condition = watch('condition');
	const isInRange =
		conditionDropdownOptions && (condition === Condition.IN_RANGE || condition === Condition.OUT_OF_RANGE);
	const selectThreshold =
		type === ConditionsObjectTypes.MEASUREMENT &&
		(condition === Condition.MORE_THAN || condition === Condition.LESS_THAN);
	const selectOperator = type === ConditionsObjectTypes.STATION || type === ConditionsObjectTypes.UI_BUTTON;

	return (
		<>
			<div>
				<div className="conditions">
					{t('If') + ' '}
					<Dropdown
						name="conditionLogic"
						options={[
							{
								label: t('All'),
								value: ConditionLogic.AND
							},
							{
								label: t('Any'),
								value: ConditionLogic.OR
							}
						]}
						value={conditionLogic}
						data-testid="ruleAnyCondition"
						onChange={(val) => {
							if (val !== null) setConditionLogic(val);
						}}
					/>
					{' ' + t('of the following conditions are met:')}
				</div>
				{conditions.map((condition, index) => (
					<>
						<ConditionCard
							measurements={measurements}
							calculations={calculations}
							digitalChannels={digitalChannels}
							auxDevices={auxDevices}
							ruleCondition={condition}
							onEdit={() => openModal(index)}
							onDelete={getDeleteHandler(index)}
						/>
						<div className="conditionLogicLabel">{index < conditions.length - 1 ? conditionLogicLabel : ''}</div>
					</>
				))}
				<AddButton type="button" onClick={() => openModal()} />
			</div>
			<Modal id="rule-new-condition" isOpen={modalState.isOpen} onClose={closeModal} isLarge hidePrevModals>
				<Modal.Header>{t('Add a Condition')}</Modal.Header>
				<Modal.Content>
					<Form id={formId.current} onSubmit={onSubmit} className={styles.form}>
						<input type="hidden" {...register} />
						<Controller
							control={control}
							name="objectType"
							render={({ field }) => (
								<Dropdown
									{...field}
									label={t('Object type*')}
									options={typeOptions}
									data-testid="ruleOjectType"
									error={errors.objectType?.message}
								/>
							)}
						/>
						{!!objectLabel && (
							<Controller
								control={control}
								name="objectId"
								render={({ field }) => (
									<Dropdown
										{...field}
										data-testid="ruleOjectId"
										label={objectLabel}
										options={objectOptions}
										error={errors.objectId?.message}
									/>
								)}
							/>
						)}
						{selectOperator && (
							<Controller
								control={control}
								name="operation"
								render={({ field }) => (
									<Dropdown
										{...field}
										label={t('Condition*')}
										options={operationOptions}
										data-testid="ruleOperation"
										error={errors.operation?.message}
									/>
								)}
							/>
						)}
						{conditionDropdownOptions && (
							<Controller
								control={control}
								name="condition"
								render={({ field }) => (
									<Dropdown
										{...field}
										label={t('Condition*')}
										data-testid="ruleCondition"
										options={conditionDropdownOptions}
										error={errors.condition?.message}
									/>
								)}
							/>
						)}
						{isInRange && (
							<>
								<GroupInputFrame>
									<Controller
										control={control}
										name="lowerLimit"
										render={({ field }) => (
											<InputNumber
												data-testid="ruleCondLowerLimit"
												{...field}
												label={t('Lower Limit')}
												error={errors.lowerLimit?.message}
											/>
										)}
									/>
									<InputText data-testid="ruleCondLowerMeasure" disabled value={limitsSuffix} />
								</GroupInputFrame>
								<GroupInputFrame>
									<Controller
										control={control}
										name="upperLimit"
										render={({ field }) => (
											<InputNumber
												data-testid="ruleCondUpperLimit"
												{...field}
												error={errors.upperLimit?.message}
												label={t('Upper limit')}
											/>
										)}
									/>
									<InputText data-testid="ruleCondUpperLimitMeasure" disabled value={limitsSuffix} />
								</GroupInputFrame>
							</>
						)}
						{selectThreshold && (
							<GroupInputFrame>
								<Controller
									control={control}
									name="threshold"
									render={({ field }) => (
										<InputNumber
											data-testid="ruleCondThresold"
											{...field}
											label={t('Threshold')}
											error={errors.threshold?.message}
										/>
									)}
								/>
								<InputText data-testid="ruleCondThresoldMeasure" disabled value={limitsSuffix} />
							</GroupInputFrame>
						)}
						{!!conditionPropertyOptions.length && (
							<Controller
								control={control}
								name="property"
								render={({ field }) => (
									<Dropdown
										{...field}
										label={t('State*')}
										data-testid="ruleCondProperty"
										options={conditionPropertyOptions}
										error={errors.property?.message}
									/>
								)}
							/>
						)}
					</Form>
				</Modal.Content>
				<Modal.Footer>
					<Button color="success" type="button" onClick={closeModal}>
						{t('Cancel')}
					</Button>
					<Button color="secondary" type="submit" form={formId.current}>
						{t('Save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Conditions;
