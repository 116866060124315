import {
	ActionDto,
	ActionType,
	ActionTypeAxis,
	ActionTypeCalc,
	ActionTypeControl,
	ActionTypeControlDisable,
	ActionTypeDO,
	ActionTypeMeas,
	ActionTypeSpecimen,
	ActionTypeStage,
	ActionTypeStation,
	ActionTypeTest,
	ActionTypeVariable,
	AxisDisable,
	AxisEnable,
	AxisInfo,
	AxisPidReset,
	AxisTare,
	CalcActionDrawModulusLine,
	CalcActionRun,
	CalcActionType,
	ClientAppOpenMessageBox,
	ClientAppOpenMessageBoxConfirm,
	ClientAppReport,
	ClientAppRequestVarEntry,
	ControlActionType,
	ControlInfo,
	SetPointType,
	VariableRequest,
	VariableType
} from '@tactun/grpc-client';
import {
	WaveformDto,
	waveformFormToGRPCRequestConverter,
	waveformFormToRequestConverter,
	WaveformFormType,
	waveformResponseToFormConverter
} from '../Waveforms';
import {
	ActionAxisProperties,
	ActionCalculationProperties,
	ActionControlChannelProperties,
	ActionDigitalOutputProperties,
	ActionDtoObjectTypes,
	ActionMeasurementProperties,
	ActionObjectTypes,
	ActionSpecimenProperties,
	ActionStageProperties,
	ActionStationProperties,
	ActionTestProperties,
	ActionUIButtonProperties,
	AxisActionAdditionalChannels
} from './actions.enums';
import { RuleAction, RuleActionDto } from './actions.types';
import { getDefaultValuesByType } from '../Waveforms/waveforms.tools';
import { ControlAlgorithmsBase, ControlAlgorithmTypeExternal } from '../ControlAlgorithms';
import {
	controlAlgorithmsFormTypeToGrpcAlgoOpenLoop,
	controlAlgorithmsFormTypeToGrpcAlgoPid,
	controlAlgorithmsFormTypeToGrpcDither,
	formControlAlgorithmsExternalConverter,
	responseControlAlgorithmsFormConverter
} from '../ControlAlgorithms/controlAlgorithms.converters';
import { VariablesTypes } from '../Variables';
import { isNotNullOrUndefined } from '../../tools/common';

export const defaultValues = {
	objectId: null,
	objectType: null,
	property: null,
	frequency: 0,
	message: '',
	tareWeight: 0,
	variableId: null,
	numberOfPulses: 0,
	waveform: getDefaultValuesByType(),
	controlAlgorithmsForm: {
		isDitherEnabled: false,
		baseAlgorithm: ControlAlgorithmsBase.PID,
		additionalAlgorithms: []
	}
};

export const actionRequestConverter = (action: RuleAction): RuleActionDto => {
	switch (action.objectType as ActionObjectTypes) {
		case ActionObjectTypes.DIGITAL_OUTPUT:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.DIGITAL_OUTPUT,
				digitalOutChannelUuids: action.objectId as string[],
				digitalOutProp: action.property as ActionDigitalOutputProperties,
				digitalOutFrequency: action.frequency as number,
				digitalOutPulses: action.numberOfPulses as number
			};
		case ActionObjectTypes.CONTROL_CHANNEL:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.CONTROL,
				controlChannelUuid: action.objectId as string,
				controlChannelProp: action.property as ActionControlChannelProperties,
				ccAlgorithmProps:
					(action.property === ActionControlChannelProperties.OPERATE ||
						action.property === ActionControlChannelProperties.SET) &&
					action.controlAlgorithmsForm
						? formControlAlgorithmsExternalConverter(action.controlAlgorithmsForm)
						: undefined,
				ccWaveformProps:
					action.property === ActionControlChannelProperties.OPERATE
						? waveformFormToRequestConverter(action.waveform as WaveformFormType)
						: undefined
			};
		case ActionObjectTypes.SPECIMEN:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.SPECIMEN,
				specimenActionType: action.property as ActionSpecimenProperties,
				maxStress: 0, // TODO implement this after final back-end DTO (update grpc converter)
				upperLimit: 0,
				lowerLimit: 0,
				specimenStrainMeasUuid: '',
				specimenStressMeasUuid: ''
			};
		case ActionObjectTypes.CALCULATION:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.CALCULATION,
				action: action.property as ActionCalculationProperties,
				calculationUuid: action.objectId as string
			};
		case ActionObjectTypes.TEST:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.TEST,
				testProp: action.property as ActionTestProperties
			};
		case ActionObjectTypes.STAGE:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.STAGE,
				stageProp: action.property as ActionStageProperties
			};
		case ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.CLIENT_MSG_BOX_WITH_CONFIRMATION,
				msgBoxMessage: action.message as string,
				msgBoxOKActions: action.msgOKActions?.map(actionRequestConverter) || [],
				msgBoxCancelActions: action.msgCancelActions?.map(actionRequestConverter) || []
				// TODO isParallel are missing in back-end DTO (update grpc converter)
			};
		case ActionObjectTypes.MESSAGE_BOX:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.CLIENT_MSG_BOX,
				msgBoxMessage: action.message as string
			};
		case ActionObjectTypes.REQUEST_VARIABLE_ENTRY:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.CLIENT_REQUEST_VAR_ENTRY,
				// TODO there should be variable Uuid list instead of single uuid (update grpc converter)
				variableUuid: action.objectId as string
			};
		case ActionObjectTypes.REPORT:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.CLIENT_REPORT
			};
		case ActionObjectTypes.REQUEST_NOTE_ENTRY:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.CLIENT_REQUEST_NOTE_ENTRY
			};
		case ActionObjectTypes.MEASUREMENT:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.MEASUREMENT,
				measurementUuid: action.objectId as string,
				measurementProp: action.property as ActionMeasurementProperties,
				measurementVarUuid: action.variableId as string,
				measurementTareValue: action.tareWeight as number
			};
		case ActionObjectTypes.CAMERA:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.CAMERA
			};
		case ActionObjectTypes.AXIS: {
			const enableBitChannels: string[] = [];
			const additionalDoChannels: AxisActionAdditionalChannels[] = [];

			action.enableBitChannels?.forEach((channel) => {
				if (
					channel !== AxisActionAdditionalChannels.OUTPUT.toString() &&
					channel !== AxisActionAdditionalChannels.WAVEFORM.toString()
				) {
					enableBitChannels.push(channel);
				} else {
					additionalDoChannels.push(parseInt(channel));
				}
			});

			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.AXIS,
				axisProp: action.property as ActionAxisProperties,
				axisUuid: action.objectId as string,
				enableBitDoChannelUuids: enableBitChannels,
				additionalDoChannels: additionalDoChannels
			};
		}
		case ActionObjectTypes.STATION:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.STATION,
				stationProp: action.property as ActionStationProperties
			};
		case ActionObjectTypes.UI_BUTTON:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.UI_BUTTON,
				uiButtonProp: action.property as ActionUIButtonProperties
			};
		case ActionObjectTypes.VARIABLE:
			return {
				uuid: action.id,
				type: ActionDtoObjectTypes.VARIABLE,
				varUuid: action.objectId as string,
				varType:
					typeof action.variableValue === 'string'
						? VariablesTypes.STRING
						: typeof action.variableValue === 'boolean'
						? VariablesTypes.BOOLEAN
						: VariablesTypes.NUMERIC,
				value: action.variableValue?.toString() as string
			};
	}
};

export const actionResponseConverter = (response: RuleActionDto): RuleAction => {
	switch (response.type) {
		case ActionDtoObjectTypes.DIGITAL_OUTPUT:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.DIGITAL_OUTPUT,
				objectId: response.digitalOutChannelUuids,
				property: response.digitalOutProp,
				frequency: response.digitalOutFrequency,
				numberOfPulses: response.digitalOutPulses
			};
		case ActionDtoObjectTypes.CONTROL:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.CONTROL_CHANNEL,
				objectId: response.controlChannelUuid,
				property: response.controlChannelProp,
				controlAlgorithmsForm:
					(response.controlChannelProp === ActionControlChannelProperties.OPERATE ||
						response.controlChannelProp === ActionControlChannelProperties.SET) &&
					response.ccAlgorithmProps
						? responseControlAlgorithmsFormConverter(response.ccAlgorithmProps)
						: undefined,
				waveform:
					response.controlChannelProp === ActionControlChannelProperties.OPERATE && response.ccWaveformProps
						? waveformResponseToFormConverter(response.ccWaveformProps as WaveformDto)
						: undefined
			};
		case ActionDtoObjectTypes.SPECIMEN:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.SPECIMEN,
				property: response.specimenActionType
				// maxStress: 0,
				// upperLimit: 0,
				// lowerLimit: 0,
				// specimenStrainMeasUuid: '',
				// specimenStressMeasUuid: ''
			};
		case ActionDtoObjectTypes.CALCULATION:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.CALCULATION,
				property: response.action,
				objectId: response.calculationUuid,
				calculation: response.calculation
			};
		case ActionDtoObjectTypes.TEST:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.TEST,
				property: response.testProp
			};
		case ActionDtoObjectTypes.STAGE:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.STAGE,
				property: response.stageProp
			};
		case ActionDtoObjectTypes.CLIENT_MSG_BOX_WITH_CONFIRMATION:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION,
				message: response.msgBoxMessage,
				msgOKActions: response.msgBoxOKActions.map(actionResponseConverter),
				msgCancelActions: response.msgBoxCancelActions.map(actionResponseConverter)
			};
		case ActionDtoObjectTypes.CLIENT_MSG_BOX:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.MESSAGE_BOX,
				message: response.msgBoxMessage
			};
		case ActionDtoObjectTypes.CLIENT_REQUEST_VAR_ENTRY:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.REQUEST_VARIABLE_ENTRY,
				objectId: response.variableUuid
			};
		case ActionDtoObjectTypes.CLIENT_REPORT:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.REPORT
			};
		case ActionDtoObjectTypes.CLIENT_REQUEST_NOTE_ENTRY:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.REQUEST_NOTE_ENTRY
			};
		case ActionDtoObjectTypes.MEASUREMENT:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.MEASUREMENT,
				objectId: response.measurementUuid,
				property: response.measurementProp,
				variableId: response.measurementVarUuid,
				tareWeight: response.measurementTareValue
			};
		case ActionDtoObjectTypes.CAMERA:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.CAMERA
			};
		case ActionDtoObjectTypes.AXIS:
			const enableBitDoChannelUuids = response.enableBitDoChannelUuids || [];
			const additionalDoChannels = response.additionalDoChannels || [];
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.AXIS,
				property: response.axisProp,
				objectId: response.axisUuid,
				enableBitChannels: [...enableBitDoChannelUuids, ...additionalDoChannels.map(String)]
			};
		case ActionDtoObjectTypes.STATION:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.STATION,
				property: response.stationProp
			};
		case ActionDtoObjectTypes.UI_BUTTON:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.UI_BUTTON,
				property: response.uiButtonProp
			};
		case ActionDtoObjectTypes.VARIABLE:
			return {
				id: response.uuid,
				objectType: ActionObjectTypes.VARIABLE,
				objectId: response.varUuid,
				variableValue:
					response.varType === VariablesTypes.BOOLEAN
						? response.value === 'true'
						: response.varType === VariablesTypes.NUMERIC
						? Number(response.value)
						: response.value
			};
	}
};

export const actionResponseToGRPCRequestConverter = (action: RuleActionDto): ActionDto => {
	const actionDto = new ActionDto();
	actionDto.setId(action.uuid as string);

	switch (action.type) {
		case ActionDtoObjectTypes.DIGITAL_OUTPUT:
			actionDto.setType(ActionType.ACTION_TYPE_DIGITAL_OUTPUT);

			const actionTypeDio = new ActionTypeDO();
			actionTypeDio.setAction(action.digitalOutProp);
			actionTypeDio.setChannelIdList(action.digitalOutChannelUuids);

			if (action.digitalOutProp === ActionDigitalOutputProperties.PULSE_START) {
				actionTypeDio.setFreq(action.digitalOutFrequency);
				actionTypeDio.setPulses(action.digitalOutPulses);
			}
			actionDto.setActionDo(actionTypeDio);

			break;
		case ActionDtoObjectTypes.CONTROL:
			actionDto.setType(ActionType.ACTION_TYPE_CONTROL);

			const actionTypeControl = new ActionTypeControl();
			actionTypeControl.setCcId(action.controlChannelUuid);
			actionTypeControl.setActionType(action.controlChannelProp);

			if (action.controlChannelProp === ActionControlChannelProperties.DISABLE) {
				const actionTypeControlDisable = new ActionTypeControlDisable();
				// TODO: set axis id when backend will fixed, controlChannelID is used for now
				actionTypeControlDisable.setAxisId(action.controlChannel?.axis.id || action.controlChannelUuid);
				actionTypeControl.setActionType(ControlActionType.DISABLE);
				actionTypeControl.setDisable(actionTypeControlDisable);
			} else if (action.controlChannelProp === ActionControlChannelProperties.SET) {
				const controlInfo = new ControlInfo();

				if (action.controlChannel?.axis) {
					controlInfo.setAxisId(action.controlChannel.axis.id);
					controlInfo.setAxisIndex(action.controlChannel.axis.axisIndex);
					if (action.controlChannel.feedbackMeasurement)
						controlInfo.setFbMeasurement(action.controlChannel.feedbackMeasurement.id);
				}

				if (action.ccAlgorithmProps) {
					const controlAlgorithmsForm = responseControlAlgorithmsFormConverter(action.ccAlgorithmProps);

					const pid = controlAlgorithmsFormTypeToGrpcAlgoPid(controlAlgorithmsForm);
					if (pid) {
						controlInfo.setPid(pid);
					}

					const dither = controlAlgorithmsFormTypeToGrpcDither(controlAlgorithmsForm);
					if (dither) {
						controlInfo.setDither(dither);
					}
				}

				actionTypeControl.setActionType(ControlActionType.SET);
				actionTypeControl.setSet(controlInfo);
			} else {
				const axisInfo = new AxisInfo();
				if (action.controlChannel?.axis) {
					axisInfo.setAxisId(action.controlChannel.axis.id);
					axisInfo.setAxisIndex(action.controlChannel.axis.axisIndex);
					axisInfo.setSpType(SetPointType.WAVEFORM);
					if (action.controlChannel.feedbackMeasurement)
						axisInfo.setFbMeasurement(action.controlChannel.feedbackMeasurement.id);
					if (action.controlChannel.setPointMeasurement)
						axisInfo.setFbMeasurement(action.controlChannel.setPointMeasurement.id);
				}
				axisInfo.setWfProps(
					waveformFormToGRPCRequestConverter(waveformResponseToFormConverter(action.ccWaveformProps as WaveformDto))
				);

				if (action.ccAlgorithmProps) {
					const controlAlgorithmsForm = responseControlAlgorithmsFormConverter(action.ccAlgorithmProps);

					if (controlAlgorithmsForm.baseAlgorithm === ControlAlgorithmsBase.OPEN_LOOP) {
						axisInfo.setAlgorithm(ControlAlgorithmTypeExternal.OPEN_LOOP);
						const openLoop = controlAlgorithmsFormTypeToGrpcAlgoOpenLoop(controlAlgorithmsForm);
						if (openLoop) {
							axisInfo.setOl(openLoop);
						}
					} else {
						axisInfo.setAlgorithm(ControlAlgorithmTypeExternal.PID);
						const pid = controlAlgorithmsFormTypeToGrpcAlgoPid(controlAlgorithmsForm);
						if (pid) {
							axisInfo.setPid(pid);
						}
					}

					const dither = controlAlgorithmsFormTypeToGrpcDither(controlAlgorithmsForm);
					if (dither) {
						axisInfo.setDither(dither);
					}
				}

				if (action.controlChannelProp === ActionControlChannelProperties.OPERATE) {
					actionTypeControl.setActionType(ControlActionType.OPERATE);
					actionTypeControl.setOperate(axisInfo);
				} else {
					actionTypeControl.setActionType(ControlActionType.UPDATE);
					actionTypeControl.setUpdate(axisInfo);
				}
			}
			actionDto.setActionControl(actionTypeControl);

			break;
		case ActionDtoObjectTypes.SPECIMEN:
			actionDto.setType(ActionType.ACTION_TYPE_SPECIMEN);

			const actionSpecimen = new ActionTypeSpecimen();
			actionSpecimen.setStrainMeasId(action.specimenStrainMeasUuid);
			actionSpecimen.setStressMeasId(action.specimenStressMeasUuid);
			// actionSpecimen.setGLengthCompMethod();
			// actionSpecimen.setGLengthMeasId();
			// actionSpecimen.setLimitSpec();
			// actionSpecimen.setLimitSpecStrain();
			// actionSpecimen.setLimitSpecStress();
			actionDto.setActionSpecimen(actionSpecimen);

			break;
		case ActionDtoObjectTypes.CALCULATION:
			actionDto.setType(ActionType.ACTION_TYPE_CALCULATION);

			const actionCalc = new ActionTypeCalc();
			// actionCalc.setCalcId(action.calculationUuid);
			// TODO get calculation object here
			// actionCalc.setCalcName();
			if (action.action === ActionCalculationProperties.RUN) {
				const calcActionRun = new CalcActionRun();
				actionCalc.setActionType(CalcActionType.RUN);
				actionCalc.setRun(calcActionRun);
			}
			if (action.action === ActionCalculationProperties.DRAW_MODULES_LINE) {
				const calcActionDrawModulusLine = new CalcActionDrawModulusLine();
				actionCalc.setActionType(CalcActionType.DRAW_MOD_LINE);
				actionCalc.setDrawModLine(calcActionDrawModulusLine);
			}
			actionDto.setActionCalc(actionCalc);
			break;
		case ActionDtoObjectTypes.TEST:
			actionDto.setType(ActionType.ACTION_TYPE_TEST);

			const testProp = new ActionTypeTest();
			testProp.setTestProp(action.testProp);

			actionDto.setActionTest(testProp);
			break;
		case ActionDtoObjectTypes.STAGE:
			actionDto.setType(ActionType.ACTION_TYPE_STAGE);

			const actionStage = new ActionTypeStage();
			actionStage.setStageProp(action.stageProp);
			actionDto.setActionStage(actionStage);

			break;
		case ActionDtoObjectTypes.CLIENT_MSG_BOX_WITH_CONFIRMATION:
			actionDto.setType(ActionType.ACTION_TYPE_CLIENT_MSG_BOX_WITH_CONFIRMATION);

			const openMsgBoxConf = new ClientAppOpenMessageBoxConfirm();
			openMsgBoxConf.setMsg(action.msgBoxMessage);
			openMsgBoxConf.setOkBtn('');
			openMsgBoxConf.setCancelBtn('');
			// TODO proto has only one action avalable
			actionDto.setOpenMsgBoxConfirm(openMsgBoxConf);

			break;
		case ActionDtoObjectTypes.CLIENT_MSG_BOX:
			actionDto.setType(ActionType.ACTION_TYPE_CLIENT_MSG_BOX);

			const openMsgBox = new ClientAppOpenMessageBox();
			openMsgBox.setMsg(action.msgBoxMessage);
			actionDto.setOpenMsgBox(openMsgBox);

			break;
		case ActionDtoObjectTypes.CLIENT_REQUEST_VAR_ENTRY:
			actionDto.setType(ActionType.ACTION_TYPE_CLIENT_REQUEST_VAR_ENTRY);

			const requestVarEntry = new ClientAppRequestVarEntry();
			requestVarEntry.setVarIdsList([action.variableUuid]);
			actionDto.setRequestVarEntry(requestVarEntry);

			break;
		case ActionDtoObjectTypes.CLIENT_REPORT:
			actionDto.setType(ActionType.ACTION_TYPE_CLIENT_REPORT);

			const report = new ClientAppReport();
			actionDto.setReport(report);

			break;
		case ActionDtoObjectTypes.CLIENT_REQUEST_NOTE_ENTRY:
			// There is no proto and there is no PRD for this yet.
			break;
		case ActionDtoObjectTypes.MEASUREMENT:
			actionDto.setType(ActionType.ACTION_TYPE_MEASUREMENT);

			const actionTypeMeas = new ActionTypeMeas();
			actionTypeMeas.setMeasVarId(action.measurementVarUuid);
			actionTypeMeas.setMeasProp(action.measurementProp);
			actionTypeMeas.setMeasId(action.measurementUuid);
			actionTypeMeas.setTareVal(action.measurementTareValue);
			actionDto.setActionMeas(actionTypeMeas);

			break;
		case ActionDtoObjectTypes.CAMERA:
			// There is no proto and there is no PRD for this yet.
			break;
		case ActionDtoObjectTypes.AXIS:
			actionDto.setType(ActionType.ACTION_TYPE_AXIS);

			const actionAxis = new ActionTypeAxis();
			if (action.axisProp === ActionAxisProperties.ENABLE) {
				const axisEnable = new AxisEnable();
				if (isNotNullOrUndefined(action.enableBitDoChannelUuids))
					axisEnable.setEnableBitList(action.enableBitDoChannelUuids);
				if (action.additionalDoChannels?.some((channel) => channel === AxisActionAdditionalChannels.OUTPUT)) {
					axisEnable.setAxisId(action.axisUuid);
				}
				actionAxis.setAxisEnable(axisEnable);
			}
			if (action.axisProp === ActionAxisProperties.DISABLE) {
				const axisDisable = new AxisDisable();
				if (isNotNullOrUndefined(action.enableBitDoChannelUuids))
					axisDisable.setEnableBitsList(action.enableBitDoChannelUuids);

				action.additionalDoChannels?.forEach((channel) => {
					if (channel === AxisActionAdditionalChannels.OUTPUT) {
						axisDisable.setAxisId(action.axisUuid);
					}
					if (channel === AxisActionAdditionalChannels.WAVEFORM) {
						axisDisable.setWaveformAxisId(action.axisUuid);
					}
				});

				actionAxis.setAxisDisable(axisDisable);
			}
			if (action.axisProp === ActionAxisProperties.WF_TARE) {
				const axisTare = new AxisTare();

				axisTare.setAxisId(action.axisUuid);
				actionAxis.setAxisTare(axisTare);
			}

			if (action.axisProp === ActionAxisProperties.PID_RESET) {
				const pidReset = new AxisPidReset();

				pidReset.setAxisId(action.axisUuid);
				actionAxis.setPidReset(pidReset);
			}

			actionDto.setActionAxis(actionAxis);

			break;
		case ActionDtoObjectTypes.STATION:
			actionDto.setType(ActionType.ACTION_STATION);

			const actionStation = new ActionTypeStation();
			actionStation.setStationProp(action.stationProp);
			actionDto.setActionStation(actionStation);

			break;
		case ActionDtoObjectTypes.VARIABLE:
			actionDto.setType(ActionType.ACTION_TYPE_VARIABLE);

			const actionVariable = new ActionTypeVariable();
			const variable = new VariableRequest();
			variable.setId(action.varUuid);
			variable.setName(action.varUuid);
			if (action.varType === VariablesTypes.BOOLEAN) {
				variable.setType(VariableType.VARIABLE_TYPE_BOOLEAN);
				variable.setDefaultValBool(action.value === 'true');
			} else if (action.varType === VariablesTypes.NUMERIC) {
				variable.setType(VariableType.VARIABLE_TYPE_NUMERIC);
				variable.setDefaultValNum(Number(action.value));
			} else if (action.varType === VariablesTypes.STRING) {
				variable.setType(VariableType.VARIABLE_TYPE_STRING);
				variable.setDefaultValStr(action.value as string);
			}

			actionVariable.setVariable(variable);
			actionDto.setActionVariable(actionVariable);

			break;
	}

	return actionDto;
};
