import { useMemo } from 'react';
import { PageLayout, Form, InputText, Dropdown, Button, BottomBar, InfoBadge, InfoBadgeIcons } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import styles from './Controllers.module.scss';

interface ControllersProps {
	isCreate: boolean;
	isLoading: boolean;
	form: UseFormReturn<Record<string, string>>;
	onBack: () => void;
	onSave: () => void;
}

const Controllers: React.FC<ControllersProps> = ({ isCreate, isLoading, form, onBack, onSave }) => {
	const { t } = useTranslation('controllers');
	const {
		control,
		formState: { errors },
		register
	} = form;

	const title = useMemo<string>(() => {
		return isCreate ? t('Create Controller') : t('Edit Controller');
	}, [isCreate, t]);

	return (
		<>
			<PageLayout>
				<PageLayout.Header title={title} onBack={onBack} />
				<Form className={styles.container} onSubmit={onSave} id="controllerForm">
					<input type="hidden" {...register('stationId')} />
					<Controller
						name="name"
						control={control}
						render={({ field }) => (
							<InputText data-testid="nameId" label={t('Name*')} {...field} error={errors.name?.message} />
						)}
					/>
					<Controller
						name="type"
						control={control}
						render={({ field }) => <Dropdown {...field} data-testid="type" label={t('Type*')} filter />}
					/>
					<Controller
						name="actuatorId"
						control={control}
						render={({ field }) => <Dropdown {...field} label={t('Actuator*')} data-testid="actuatorId" filter />}
					/>
				</Form>
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button data-testid="cancelBtn" label={t('Cancel')} variant="contained" color="success" onClick={onBack} />
				<Button
					data-testid="saveBtn"
					label={t('Save')}
					variant="contained"
					color="secondary"
					type="submit"
					form="controllerForm"
				/>
			</BottomBar>
		</>
	);
};

export default Controllers;
