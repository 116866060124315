import { useCallback, useMemo } from 'react';
import { IListItem } from './../../types/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
	responseListItemConverter,
	VariableListItem,
	VariableRequestDto,
	VariableResponseDto,
	VariablesTypes
} from '.';
import * as api from './variables.api';

export const useVariables = (stationId?: string, type?: VariablesTypes) => {
	const { data, isLoading: isVariablesLoading } = useQuery({
		queryKey: ['variables', { stationId }],
		// When we provide enable option - !!variableId, the variableId is guaranteed
		queryFn: () => api.getStationVariables(stationId as string),
		enabled: !!stationId
	});

	const queryClient = useQueryClient();

	const updateVariables = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: ['variables', { stationId }] });
	}, [queryClient, stationId]);

	return useMemo(() => {
		return {
			variables: type ? data?.filter((d) => d.type === type) : data,
			isVariablesLoading,
			updateVariables
		};
	}, [isVariablesLoading, updateVariables, data, type]);
};

export const useVariablesList = (stationId: string | undefined, type?: VariablesTypes): IListItem[] => {
	const { variables } = useVariables(stationId);

	const varList = useMemo(
		() =>
			variables
				?.filter((vars) => type === undefined || vars.type === type)
				?.map((v) => ({ label: v.name, value: v.uuid })) || [],
		[variables, type]
	);

	return varList;
};

export const useVariable = (variableId?: string) => {
	const { data: variableDto, isLoading: isVariableLoading } = useQuery<VariableResponseDto>({
		queryKey: ['variable', { variableId }],
		// When we provide enable option - !!variableId, the variableId is guaranteed
		queryFn: () => api.getVariable(variableId as string),
		enabled: !!variableId
	});

	const queryClient = useQueryClient();

	const updateVariable = () => {
		queryClient.invalidateQueries({ queryKey: ['variables', { variableId }] });
	};

	return {
		variableDto,
		isVariableLoading,
		updateVariable
	};
};

export const useTestVariables = (testId?: string, onSaveSuccess?: () => void, type?: VariablesTypes) => {
	const queryClient = useQueryClient();

	const { data, isLoading } = useQuery<VariableListItem[], Error>({
		queryKey: ['test-variables', { testId }],
		queryFn: async () => {
			const resp = await api.getTestVariables(testId as string);
			return resp.map((axis) => responseListItemConverter(axis));
		},
		enabled: !!testId
	});

	const addNewItem = useCallback(
		(newVar: VariableListItem) => {
			queryClient.setQueryData<VariableListItem[]>(['test-variables', { testId }], (old) => {
				if (!old) return undefined;
				return [newVar, ...old];
			});
		},
		[queryClient, testId]
	);

	const updateItem = useCallback(
		(newVar: VariableListItem) => {
			queryClient.setQueryData<VariableListItem[]>(['test-variables', { testId }], (old) => {
				return old?.map((o) => (o.id === newVar.id ? newVar : o));
			});
		},
		[queryClient, testId]
	);

	const createMutation = useMutation({
		mutationFn: (data: { variable: VariableRequestDto; testId: string }) => {
			return api.createTestVariable(data.variable, data.testId);
		},
		onSuccess: (newVar) => {
			addNewItem(responseListItemConverter(newVar));
			if (onSaveSuccess) {
				onSaveSuccess();
			}
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { variable: VariableRequestDto; variableId: string }) => api.updateTestVariable(data.variable),

		onSuccess: (updatedVar) => {
			updateItem(responseListItemConverter(updatedVar));
			if (onSaveSuccess) {
				onSaveSuccess();
			}
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const saveTestVariable = useCallback(
		(data: VariableRequestDto, variableId?: string) => {
			if (!testId) {
				toast.error('Test id is missing for test variable.');
				return;
			}

			if (variableId) {
				updateMutation.mutate({
					variable: data,
					variableId
				});
			} else {
				createMutation.mutate({ variable: data, testId });
			}
		},
		[createMutation, testId, updateMutation]
	);

	const deleteTestVariable = useCallback(
		(id: string) => {
			queryClient.setQueryData<VariableListItem[]>(['test-variables', { testId }], (old) => {
				return old?.filter((hc) => hc.id !== id);
			});
		},
		[queryClient, testId]
	);

	return useMemo(
		() => ({
			isLoading,
			isCreating: createMutation.isPending,
			isUpdating: updateMutation.isPending,
			testVariables: type ? data?.filter((d) => d.type === VariablesTypes[type]) : data,
			saveTestVariable,
			deleteTestVariable
		}),
		[isLoading, createMutation.isPending, updateMutation.isPending, type, data, saveTestVariable, deleteTestVariable]
	);
};

export const useTestVariablesEntities = (testId?: string, type?: VariablesTypes) => {
	const { data, isLoading } = useQuery<VariableResponseDto[], Error>({
		queryKey: ['test-variables', { testId }],
		queryFn: async () => api.getTestVariables(testId as string),
		enabled: !!testId
	});

	return useMemo(
		() => ({
			isLoading,
			testVariables: type !== undefined ? data?.filter((d) => d.type === type) : data
		}),
		[isLoading, type, data]
	);
};

export const useTestVariable = (variableId?: string) => {
	const { data: testVariable, isLoading: isTestVariableLoading } = useQuery<VariableResponseDto>({
		queryKey: ['test-variable', { variableId }],
		queryFn: () => api.getTestVariable(variableId as string),
		enabled: !!variableId
	});

	return {
		testVariable,
		isTestVariableLoading
	};
};
