import { Converter } from '../../types';
import { SensorResponseDto } from './sensors.types';
import {
	CalibrationRequest,
	CalibrationSensorRequest,
	GenericSensorChannelType,
	SensorGeneric,
	SensorLoadCell,
	SensorLVDT,
	SensorRequest,
	SensorSeDiffEncoder,
	StationChannelAnalogIn10Ksps,
	StationChannelAnalogIn10V24MA,
	StationChannelAnalogIn60Ksps,
	StationChannelEncoder,
	StationChannelLVDT,
	StationChannelSignalConditioning,
	TedsRequest,
	UnitFormula
} from '@tactun/grpc-client';
import { calibrationResponseToCalibrationGRPCConverter, defaultPiecewiseCallibration } from '../Calibrations';
import { SensorsTypes } from './sensors.enums';
import {
	AverageFilterPoints,
	ChannelType,
	stationChannelResponseToHardwareChannelGRPCConverter
} from '../StationChannels';

export const sensorResponseToSensorGRPCConverter = (sensor: SensorResponseDto): SensorRequest => {
	const sensorRequest = new SensorRequest();

	sensorRequest.setId(sensor.id);
	sensorRequest.setName(sensor.name);
	sensorRequest.setType(sensor.type);
	sensorRequest.setInverseReading(false);
	if (sensor.serialNumber) sensorRequest.setSerialNumber(sensor.serialNumber);

	if (sensor.calibrations) {
		sensorRequest.setCalibrations(
			sensor.calibrations.length
				? calibrationResponseToCalibrationGRPCConverter(sensor.calibrations[0])
				: new CalibrationRequest()
		);
	} else {
		sensorRequest.setCalibrations(calibrationResponseToCalibrationGRPCConverter(defaultPiecewiseCallibration));
	}

	if (sensor.showRawData) sensorRequest.setShowRawData(sensor.showRawData);
	sensorRequest.setChannelId(sensor.stationChannel.id);
	sensorRequest.setHw(stationChannelResponseToHardwareChannelGRPCConverter(sensor.stationChannel));

	if (sensor.type === SensorsTypes.DIFFERENTIAL_ENCODER || sensor.type === SensorsTypes.SINGLE_ENDED_ENCODER) {
		const sensorEncoder = new SensorSeDiffEncoder();
		if (sensor.inversePolarity) sensorEncoder.setInversePolarity(sensor.inversePolarity);

		const stationChannelDiffEncoder = new StationChannelEncoder();
		sensorEncoder.setChannel(stationChannelDiffEncoder);

		sensorRequest.setEncoder(sensorEncoder);
	}
	if (sensor.type === SensorsTypes.LOAD_CELL) {
		const sensorLoadCell = new SensorLoadCell();
		if (sensor.inversePolarity) sensorLoadCell.setInversePolarity(sensor.inversePolarity);
		if (sensor.averagingFilter) sensorLoadCell.setAveragingFilter(sensor.averagingFilter);
		if (sensor.averagingFilterPoints) sensorLoadCell.setAveragingFilterPoints(sensor.averagingFilterPoints);

		const stationChannelSignalConditioning = new StationChannelSignalConditioning();
		if (sensor.stationChannel.bridgeType !== undefined)
			stationChannelSignalConditioning.setBridgeType(sensor.stationChannel.bridgeType);
		if (sensor.stationChannel.excitationVoltage !== undefined)
			stationChannelSignalConditioning.setExcitationVoltage(sensor.stationChannel.excitationVoltage);
		if (sensor.stationChannel.gain !== undefined) stationChannelSignalConditioning.setGain(sensor.stationChannel.gain);
		if (sensor.stationChannel.pgaGain !== undefined)
			stationChannelSignalConditioning.setPgaGain(sensor.stationChannel.pgaGain);
		stationChannelSignalConditioning.setSamplingRate(sensor.stationChannel.samplingRate);
		stationChannelSignalConditioning.setTedsIndex(sensor.stationChannel.channel.tedsChannel);
		stationChannelSignalConditioning.setTedsAddr(sensor.stationChannel.channel.tedsAddress);
		sensorLoadCell.setChannel(stationChannelSignalConditioning);

		sensorRequest.setLoadCell(sensorLoadCell);
	}
	if (sensor.type === SensorsTypes.GENERIC_SENSOR) {
		const sensorGeneric = new SensorGeneric();

		if (sensor.averagingFilter) sensorGeneric.setAveragingFilter(sensor.averagingFilter);
		if (sensor.averagingFilterPoints) sensorGeneric.setAveragingFilterPoints(sensor.averagingFilterPoints);

		if (sensor.stationChannel.type === ChannelType.ANALOG_IN_10KSPS) {
			sensorGeneric.setChanelType(GenericSensorChannelType.ANALOG_IN_10KSPS);

			const analogIn10Ksps = new StationChannelAnalogIn10Ksps();
			if (sensor.stationChannel.aiServoDriverType) analogIn10Ksps.setType(sensor.stationChannel.aiServoDriverType);
			analogIn10Ksps.setRange(sensor.stationChannel.range);
			sensorGeneric.setAnalogin10ksps(analogIn10Ksps);
		}

		if (sensor.stationChannel.type === ChannelType.ANALOG_IN_10V_24MA) {
			sensorGeneric.setChanelType(GenericSensorChannelType.ANALOG_IN_10V24MA);

			const analogIn10V24MA = new StationChannelAnalogIn10V24MA();
			if (sensor.stationChannel.limit) analogIn10V24MA.setLimit(sensor.stationChannel.limit);
			if (sensor.stationChannel.offset) analogIn10V24MA.setLimit(sensor.stationChannel.offset);
			analogIn10V24MA.setRange(sensor.stationChannel.range);
			sensorGeneric.setAnalogin10v24ma(analogIn10V24MA);
		}

		if (sensor.stationChannel.type === ChannelType.ANALOG_IN_60KSPS) {
			sensorGeneric.setChanelType(GenericSensorChannelType.ANALOG_IN_60KSPS);

			const analogIn60Ksps = new StationChannelAnalogIn60Ksps();
			analogIn60Ksps.setRange(sensor.stationChannel.range);
			sensorGeneric.setAnalogin60ksps(analogIn60Ksps);
		}

		sensorRequest.setGeneric(sensorGeneric);
	}

	if (sensor.type === SensorsTypes.LVDT) {
		const lvdtSensor = new SensorLVDT();
		lvdtSensor.setInversePolarity(sensor.inversePolarity !== undefined ? sensor.inversePolarity : false);
		lvdtSensor.setAveragingFilter(sensor.averagingFilter !== undefined ? sensor.averagingFilter : false);
		if (sensor.averagingFilter) {
			lvdtSensor.setAveragingFilterPoints(sensor.averagingFilterPoints as AverageFilterPoints);
		}
		if (sensor.stationChannel.type === ChannelType.LVDT) {
			const stationChannelLvdt = new StationChannelLVDT();

			if (sensor.stationChannel.excitationAmplitude !== undefined)
				stationChannelLvdt.setAmp(sensor.stationChannel.excitationAmplitude);
			if (sensor.stationChannel.excitationFrequency !== undefined)
				stationChannelLvdt.setFreq(sensor.stationChannel.excitationFrequency);
			if (sensor.stationChannel?.channel?.spiAddress !== undefined)
				stationChannelLvdt.setSpiAddress(sensor.stationChannel?.channel?.spiAddress);

			lvdtSensor.setChannel(stationChannelLvdt);
		}

		sensorRequest.setLvdt(lvdtSensor);
	}

	if (sensor.calibrations && sensor.unit) {
		const unitFormula = new UnitFormula();
		unitFormula.setName(sensor.unit.name);
		unitFormula.setScale(sensor.unit.scale);
		unitFormula.setOffset(sensor.unit.offset);

		sensorRequest.setUnit(unitFormula);
	}

	return sensorRequest;
};

export const sensorResponseToCalibrationSensorGRPCConverter = (
	sensor: SensorResponseDto,
	configure: boolean = false
): CalibrationSensorRequest => {
	const sensorRequest = sensorResponseToSensorGRPCConverter(sensor);

	const calibrationSensorRequest = new CalibrationSensorRequest();
	calibrationSensorRequest.setSensorRequest(sensorRequest);
	calibrationSensorRequest.setConfigure(configure);

	return calibrationSensorRequest;
};

export const sensorResponsesToSensorGRPCsConverter: Converter<SensorResponseDto[], Array<SensorRequest>> = (
	sensors
) => {
	return sensors.map((sensor) => sensorResponseToSensorGRPCConverter(sensor));
};

export const sensorResponseToTedsGRPCConverter: Converter<SensorResponseDto, TedsRequest> = (sensor) => {
	const tedsRequest = new TedsRequest();
	if (sensor.stationChannel.channel) {
		tedsRequest.setTedsAddress(sensor.stationChannel.channel.tedsAddress);
		tedsRequest.setTedsChannel(sensor.stationChannel.channel.tedsChannel);
	}

	return tedsRequest;
};
