import { Error as GrpcError, ErrorType, ErrorStruct, ErrorArgument } from '@tactun/grpc-client';
import { ValueOf } from 'type-fest';

export const grpcErrorToMessage = (error: GrpcError | null, statusMessage?: string) => {
	if (error === null) return statusMessage ? statusMessage : 'Unknown error';
	const messageArr = [];
	const errorStruct = error.getErrorStruct();
	const errorType = error.getErrorType();
	const errorArg = error.getErrorArgument();

	if (
		errorStruct === ErrorStruct.STSENSOR &&
		errorType === ErrorType.INVALIDARGUMENT &&
		errorArg === ErrorArgument.TEDSID
	) {
		return 'The TEDS ID of the connected sensor and the configured settings do not match. Please perform sensor calibration and reconfigure it for accurate results.';
	}

	if (errorStruct !== undefined) {
		messageArr.push(
			`Error Struct: '${Object.entries(ErrorStruct).find(
				([_, value]: [string, number]) => value === errorStruct
			)?.[0]}'`
		);
	}

	if (errorType !== undefined) {
		messageArr.push(
			`Error Type: '${Object.entries(ErrorType).find(([_, value]: [string, number]) => value === errorType)?.[0]}'`
		);
	}

	if (errorArg !== undefined) {
		messageArr.push(
			`Error Argument: '${Object.entries(ErrorArgument).find(
				([_, value]: [string, number]) => value === errorArg
			)?.[0]}'`
		);
	}

	const entityId = error.getErrorId();
	if (entityId) {
		messageArr.push(`Error Entity Id: '${entityId}'`);
	}

	return messageArr.join('; ');
};

export const getKeyByValue = <T extends {}>(struct: T, value: ValueOf<T> | undefined): keyof T | 'Unknown Error' => {
	const error = Object.entries(struct).find(([_, val]) => val === value)?.[0] as keyof T | undefined;

	return error ? error : 'Unknown Error';
};
