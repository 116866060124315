import React, { useCallback, useMemo } from 'react';
import { IStep, normalizePath, Stepper, StepStatuses, useWizard } from '@tactun/ui';
import { useNavigate, useParams } from 'react-router-dom';
import WizardLayout from '../../../components/WizardLayout';
import * as paths from '../station.const';
import ConfigureStationBottomBarContainer from '../../ConfigureStationButtomBar';
import { useStation } from '../stations.hooks';
import { useTranslation } from 'react-i18next';

const StationWizardContainer: React.FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('station');
	const { stationId } = useParams();
	const { steps, nextStep, previousStep, isLastStep, isFirstStep, isLoading, activeStep } = useWizard();
	const { stationDto } = useStation(stationId);

	const handleNext = useCallback(() => {
		nextStep();
	}, [nextStep]);

	const handlePrevious = useCallback(() => {
		previousStep();
	}, [previousStep]);

	const handleBack = useCallback(() => {
		navigate('/stations');
	}, [navigate]);

	const normalizedSteps = useMemo(() => {
		return steps.map((step) => ({ ...step, normalizedPath: normalizePath(step.path, { stationId }) }));
	}, [stationId, steps]);

	const getStepStatus = (step: IStep) => {
		if (!stationId && step.path === paths.generalInfoCreatePath) {
			return StepStatuses.IN_PROGRESS;
		}

		switch (step.path) {
			case paths.generalInfoPath:
				return StepStatuses.COMPLETED;
			case paths.stationChannelsPath:
				return stationDto?.stationChannels?.length ? StepStatuses.COMPLETED : StepStatuses.EMPTY;
			case paths.actuatorsPath:
				return stationDto?.actuators?.length ? StepStatuses.COMPLETED : StepStatuses.EMPTY;
			case paths.axesPath:
				return stationDto?.axes?.length ? StepStatuses.COMPLETED : StepStatuses.EMPTY;
			case paths.calculationsPath:
				return stationDto?.calculations?.length ? StepStatuses.COMPLETED : StepStatuses.EMPTY;
			case paths.controlChannelsPath:
				return stationDto?.controlChannels?.length ? StepStatuses.COMPLETED : StepStatuses.EMPTY;
			case paths.sensorsPath:
				return stationDto?.sensors?.length ? StepStatuses.COMPLETED : StepStatuses.EMPTY;
			case paths.variablesPath:
				return stationDto?.variables?.length ? StepStatuses.COMPLETED : StepStatuses.EMPTY;
			case paths.measurementsPath:
				return stationDto?.measurements?.length ? StepStatuses.COMPLETED : StepStatuses.EMPTY;
			case paths.auxiliaryDevicesPath:
				return stationDto?.auxiliaryDevices?.length ? StepStatuses.COMPLETED : StepStatuses.EMPTY;
			case paths.stationRulesPath:
				return stationDto?.stationRules?.length || stationDto?.testRules?.length
					? StepStatuses.COMPLETED
					: StepStatuses.EMPTY;
			case paths.widgetsPath:
				return stationDto?.widgets?.length ? StepStatuses.COMPLETED : StepStatuses.EMPTY;
			default:
				return StepStatuses.EMPTY;
		}
	};

	return (
		<WizardLayout
			onNext={handleNext}
			onPrevious={handlePrevious}
			onBack={handleBack}
			isSaving={isLoading}
			footerItems={<ConfigureStationBottomBarContainer stationId={stationId} />}
			isPreviousDisabled={isFirstStep || isLoading}
			isNextDisabled={isLoading}
			isLastStep={isLastStep}
			activeStep={steps[activeStep].path}
			backLabel={t('Back to Stations')}
		>
			<Stepper>
				{normalizedSteps?.map((step) => (
					<Stepper.Step
						label={step.name}
						path={step.normalizedPath}
						key={step.normalizedPath}
						disabled={!stationId}
						status={getStepStatus(step)}
					/>
				))}
			</Stepper>
		</WizardLayout>
	);
};

export default React.memo(StationWizardContainer);
