import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@tactun/ui';
import { PageLayout, DataTable, KebabMenu } from '@tactun/ui';
import { MeasurementResponseDto } from '../../measurements.types';
import { MeasurementCrudActionTypes } from '../../measurements.enums';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { useTableConfigs } from '../../../../hooks';

interface MeasurementTableProps {
	onAction: (type: MeasurementCrudActionTypes, data?: MeasurementResponseDto) => void;
	data?: MeasurementResponseDto[];
	isLoading: boolean;
	stationName: string;
}

const MeasurementsTable: FC<MeasurementTableProps> = ({ onAction, data, isLoading, stationName }) => {
	const { t } = useTranslation('measurements');

	const transColumns = useMemo<ITableColumns[]>(() => {
		return [{ field: 'unit.name', header: t('Measurement unit') }];
	}, [t]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<MeasurementResponseDto>(transColumns, data);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(MeasurementCrudActionTypes.DELETE, data)
			}
		],
		[onAction, t]
	);

	const handleCreate = useCallback(() => {
		onAction(MeasurementCrudActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={stationName}>
			<PageLayout.Header title={t('Measurements')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('Measurement')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={data}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Measurement name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(MeasurementCrudActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="duplicate"
							fontSize="1.2rem"
							title={t('Duplicate')}
							disabled
							onClick={() => onAction(MeasurementCrudActionTypes.DUPLICATE, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default MeasurementsTable;
