import { useMemo } from 'react';
import { IListItem, INamedEntity, INamedEntityWithUUID } from '../types';

export const useEntityOptions = (entities?: INamedEntity[] | INamedEntityWithUUID[]): IListItem[] => {
	return useMemo(
		() =>
			entities?.map((entity: INamedEntity | INamedEntityWithUUID) => ({
				label: entity.name as string,
				value: 'id' in entity ? entity.id : entity.uuid
			})) || [],
		[entities]
	);
};
