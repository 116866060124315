import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, useModalData } from '@tactun/ui';
import ControllerAddManuallyModal from '../components/ControllerAddManuallyModal';
import ControllerAddManuallyContent from '../components/ControllerAddManuallyContent';
import { controllerAddManuallySchema } from '../connection.schemas';
import { controllerAddManuallyModalId, addManuallyDefaultValues } from '../connection.const';
import { useManuallyAddedDevicesStore } from '../connection.store';
import { controllerAddManuallyFormTypeToDevice } from '../connection.converters';
import { uuid } from '../../../tools';

const ControllerAddManuallyContainer = () => {
	const { addDevice } = useManuallyAddedDevicesStore();
	const modalData = useModalData(controllerAddManuallyModalId);
	const form = useForm({
		defaultValues: addManuallyDefaultValues,
		mode: 'onBlur',
		resolver: yupResolver(controllerAddManuallySchema)
	});
	const { reset } = form;

	const handleSave = form.handleSubmit(async (data) => {
		const device = controllerAddManuallyFormTypeToDevice(data, uuid());
		addDevice(device);
		reset(addManuallyDefaultValues);
		Modal.hide(controllerAddManuallyModalId);
	});

	useEffect(() => {
		reset(addManuallyDefaultValues);
	}, [modalData, reset]);

	return (
		<ControllerAddManuallyModal>
			<ControllerAddManuallyContent onSave={handleSave} form={form} />
		</ControllerAddManuallyModal>
	);
};

export default ControllerAddManuallyContainer;
