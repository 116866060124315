import { LinkStates } from './widgetsConfiguration.enums';

export const defaultStageEnableBit = {
	stageEnableBit: {
		enable: true,
		buttonNameForOnState: 'On',
		buttonNameForOffState: 'Off',
		readOnly: false
	},
	stageActionsForOnStage: [],
	stageActionsForOffStage: []
};

export const defaultLinkButtonConfig = {
	enable: false,
	defaultState: LinkStates.UNLINK
};

export const defaultButtonConfigs = [
	{
		name: 'Home Button',
		enable: true,
		linkedActionsForPressedState: [],
		linkedActionsForReleasedState: [],
		unlinkedActionsForPressedState: [],
		unlinkedActionsForReleasedState: []
	},
	{
		name: 'Jog Up: High Speed Button',
		enable: true,
		linkedActionsForPressedState: [],
		linkedActionsForReleasedState: [],
		unlinkedActionsForPressedState: [],
		unlinkedActionsForReleasedState: []
	},
	{
		name: 'Jog Up: Low Speed Button',
		enable: true,
		linkedActionsForPressedState: [],
		linkedActionsForReleasedState: [],
		unlinkedActionsForPressedState: [],
		unlinkedActionsForReleasedState: []
	},
	{
		name: 'Jog Down: Low Speed" button',
		enable: true,
		linkedActionsForPressedState: [],
		linkedActionsForReleasedState: [],
		unlinkedActionsForPressedState: [],
		unlinkedActionsForReleasedState: []
	},
	{
		name: 'Jog Down: High Speed" button',
		enable: true,
		linkedActionsForPressedState: [],
		linkedActionsForReleasedState: [],
		unlinkedActionsForPressedState: [],
		unlinkedActionsForReleasedState: []
	}
];
