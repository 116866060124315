import { boolean, mixed, number, object, array, string } from 'yup';
import { emptyStringToNull } from '../../tools/yupHelpers';
import { ReadoutTypes } from './readout.enums';

export const readoutFormSchema = object({
	id: string().notRequired(),
	name: string().notRequired(),
	testId: string().notRequired(),
	stationId: string().notRequired(),
	precision: number().transform(emptyStringToNull).nullable().min(0).max(6),
	min: boolean(),
	max: boolean(),
	rate: boolean(),
	tare: boolean(),
	readoutType: mixed<ReadoutTypes>().required(),
	dataChannel: array()
		.required()
		.when('isType', {
			is: true,
			then: (schema) => schema.optional(),
			otherwise: (schema) => schema.min(1)
		}),
	isType: boolean().optional()
}).required();

export const selectExistingReadout = object({
	readoutId: string().required()
}).required();

export const tareSchema = object({
	tare: number().transform(emptyStringToNull).required('Value is a required field')
});
