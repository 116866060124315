import { FC, useMemo } from 'react';
import { omit } from 'lodash';
import { TestStageConfigs } from '../../tests.types';
import LineItem, { LineItemProps } from './LineItem';
import { uuid } from '../../../../tools';
import { TestLoopResponseDto } from '../../../TestLoops/testLoops.types';
import styles from './TestLoopLine.module.scss';

interface TestLoopLineProps {
	itemWidth: number;
	loopsList: TestLoopResponseDto[];
	stages: TestStageConfigs[];
}

interface LoopByLevel {
	key: string;
	items: LineItemProps[];
}

type LoopItem = LineItemProps & { level: number };

export const TestLoopLine: FC<TestLoopLineProps> = ({ itemWidth, loopsList, stages }) => {
	// Sorted array by level of loops, which index is the level
	const loopItemsByLevel = useMemo<LoopByLevel[]>(() => {
		const loopItems: LoopItem[] = loopsList.reduce<LoopItem[]>((res: LoopItem[], current) => {
			const startIndex = stages.findIndex(({ id }) => id === current.startStage?.id);
			const endIndex = stages.findIndex(({ id }) => id === current.endStage?.id);
			if (startIndex > -1 && endIndex > -1) {
				res.push({
					startPosition: itemWidth * startIndex,
					width: itemWidth * (endIndex - startIndex + 1),
					count: current.iter,
					// TODO temp solution, this must be fetched from backend
					level: current.level || 1,
					id: current.id
				});
			}

			return res;
		}, []);

		return loopItems.reduce<LoopByLevel[]>((res, current) => {
			const loopItem = omit(current, 'level');
			if (!res[current.level - 1]) {
				res[current.level - 1] = { key: uuid(), items: [loopItem] };
				return res;
			}
			res[current.level - 1].items.push(loopItem);
			return res;
		}, []);
	}, [loopsList, stages, itemWidth]);

	return (
		<>
			{loopItemsByLevel?.map(({ items, key }, i) => (
				<span
					className={styles.container}
					style={{ width: stages.length * itemWidth, top: i === 0 ? 58 : 20 }}
					key={key}
				>
					{items?.map((props) => <LineItem {...props} key={props.id} />)}
				</span>
			))}
		</>
	);
};

export default TestLoopLine;
