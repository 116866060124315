import {
	ConditionAuxDevice,
	ConditionCalc,
	ConditionConnection,
	ConditionDi,
	ConditionDto,
	ConditionMeasurement,
	ConditionStation,
	ConditionTest,
	ConditionType,
	ConditionVariable,
	MeasurementConditionType,
	MeasurementPropInRange,
	MeasurementPropLessThan,
	MeasurementPropMoreThan,
	MeasurementPropOutOfRange
} from '@tactun/grpc-client/src/grpc/rule_pb';
import { Converter } from '../../types';
import {
	Condition,
	ConditionDtoObjectTypes,
	ConditionsAuxDeviceProperties,
	ConditionsConnectionProperties,
	ConditionsDigitalInputProperties,
	ConditionsObjectTypes,
	ConditionsStationProperties,
	ConditionsTestProperties,
	ConditionsUIButtonProperties,
	EqualConditions,
	InRangeConditions,
	MoreLessConditions
} from './conditions.enums';
import { RuleCondition, RuleConditionDto } from './conditions.types';
import { VariableRequest, VariableType } from '@tactun/grpc-client';
import { VariablesTypes } from '../Variables';

export const conditionRequestConverter = (form: RuleCondition): RuleConditionDto => {
	switch (form.objectType as ConditionsObjectTypes) {
		case ConditionsObjectTypes.DIGITAL_INPUT:
			return {
				uuid: form.id,
				type: ConditionDtoObjectTypes.DIGITAL_INPUT,
				diProp: form.property as ConditionsDigitalInputProperties,
				diChannelUuid: form.objectId as string
			};
		case ConditionsObjectTypes.AUX_DEVICE:
			return {
				uuid: form.id,
				type: ConditionDtoObjectTypes.AUX_DEVICE,
				auxDeviceProp: form.property as ConditionsAuxDeviceProperties,
				auxDeviceUuid: form.objectId as string
			};
		case ConditionsObjectTypes.CALCULATION:
			return {
				uuid: form.id,
				type: ConditionDtoObjectTypes.CALCULATION,
				calculationUuid: form.objectId as string,
				condition: form.condition === Condition.IN_RANGE ? InRangeConditions.IN_RANGE : InRangeConditions.OUT_OF_RANGE,
				lowerLimit: form.lowerLimit as number,
				upperLimit: form.upperLimit as number
			};
		case ConditionsObjectTypes.MEASUREMENT:
			if (form.condition === Condition.IN_RANGE || form.condition === Condition.OUT_OF_RANGE) {
				return {
					uuid: form.id,
					type: ConditionDtoObjectTypes.MEASUREMENT_IN_OUT_RANGE,
					measurementUuid: form.objectId as string,
					condition:
						form.condition === Condition.IN_RANGE ? InRangeConditions.IN_RANGE : InRangeConditions.OUT_OF_RANGE,
					upperLimit: form.upperLimit as number,
					lowerLimit: form.lowerLimit as number
				};
			} else {
				return {
					uuid: form.id,
					type: ConditionDtoObjectTypes.MEASUREMENT_MORE_THAN_LESS_THAN,
					measurementUuid: form.objectId as string,
					condition:
						form.condition === Condition.MORE_THAN ? MoreLessConditions.MORE_THAN : MoreLessConditions.LESS_THAN,
					threshold: form.threshold as number
				};
			}
		case ConditionsObjectTypes.CONNECTION:
			return {
				uuid: form.id,
				type: ConditionDtoObjectTypes.CONNECTION,
				connectionState: form.property as ConditionsConnectionProperties
			};
		case ConditionsObjectTypes.TEST:
			return {
				uuid: form.id,
				type: ConditionDtoObjectTypes.TEST,
				testState: form.property as ConditionsTestProperties
			};
		case ConditionsObjectTypes.STATION:
			return {
				uuid: form.id,
				type: ConditionDtoObjectTypes.STATION,
				action: form.property as ConditionsStationProperties,
				op: form.operation as EqualConditions
			};
		case ConditionsObjectTypes.UI_BUTTON:
			return {
				uuid: form.id,
				type: ConditionDtoObjectTypes.UI_BUTTON,
				op: form.operation as EqualConditions,
				state: form.property as ConditionsUIButtonProperties
			};
		case ConditionsObjectTypes.VARIABLE:
			return {
				uuid: form.id,
				type: ConditionDtoObjectTypes.VARIABLE,
				condition: form.operation as EqualConditions,
				varUuid: form.objectId as string,
				varType:
					typeof form.variableValue === 'string'
						? VariablesTypes.STRING
						: typeof form.variableValue === 'boolean'
						? VariablesTypes.BOOLEAN
						: VariablesTypes.NUMERIC,
				value: form.variableValue?.toString() as string
			};
	}
};

export const conditionResponseConverter = (response: RuleConditionDto): RuleCondition => {
	switch (response.type) {
		case ConditionDtoObjectTypes.DIGITAL_INPUT:
			return {
				id: response.uuid,
				objectType: ConditionsObjectTypes.DIGITAL_INPUT,
				property: response.diProp,
				objectId: response.diChannelUuid
			};
		case ConditionDtoObjectTypes.AUX_DEVICE:
			return {
				id: response.uuid,
				objectType: ConditionsObjectTypes.AUX_DEVICE,
				property: response.auxDeviceProp,
				objectId: response.auxDeviceUuid
			};
		case ConditionDtoObjectTypes.CALCULATION:
			return {
				id: response.uuid,
				objectType: ConditionsObjectTypes.CALCULATION,
				objectId: response.calculationUuid,
				condition: response.condition === InRangeConditions.IN_RANGE ? Condition.IN_RANGE : Condition.OUT_OF_RANGE,
				lowerLimit: response.lowerLimit,
				upperLimit: response.upperLimit
			};
		case ConditionDtoObjectTypes.MEASUREMENT_IN_OUT_RANGE:
			return {
				id: response.uuid,
				objectType: ConditionsObjectTypes.MEASUREMENT,
				objectId: response.measurementUuid,
				condition: response.condition === InRangeConditions.IN_RANGE ? Condition.IN_RANGE : Condition.OUT_OF_RANGE,
				upperLimit: response.upperLimit,
				lowerLimit: response.lowerLimit
			};
		case ConditionDtoObjectTypes.MEASUREMENT_MORE_THAN_LESS_THAN:
			return {
				id: response.uuid,
				objectType: ConditionsObjectTypes.MEASUREMENT,
				objectId: response.measurementUuid,
				condition: response.condition === MoreLessConditions.MORE_THAN ? Condition.MORE_THAN : Condition.LESS_THAN,
				threshold: response.threshold
			};
		case ConditionDtoObjectTypes.CONNECTION:
			return {
				id: response.uuid,
				objectType: ConditionsObjectTypes.CONNECTION,
				property: response.connectionState
			};
		case ConditionDtoObjectTypes.TEST:
			return {
				id: response.uuid,
				objectType: ConditionsObjectTypes.TEST,
				property: response.testState
			};
		case ConditionDtoObjectTypes.STATION:
			return {
				id: response.uuid,
				objectType: ConditionsObjectTypes.STATION,
				property: response.action,
				operation: response.op
			};
		case ConditionDtoObjectTypes.UI_BUTTON:
			return {
				id: response.uuid,
				objectType: ConditionsObjectTypes.UI_BUTTON,
				operation: response.op,
				property: response.state
			};
		case ConditionDtoObjectTypes.VARIABLE:
			return {
				id: response.uuid,
				objectType: ConditionsObjectTypes.VARIABLE,
				objectId: response.varUuid,
				variableValue:
					response.varType === VariablesTypes.BOOLEAN
						? response.value === 'true'
						: response.varType === VariablesTypes.NUMERIC
						? Number(response.value)
						: response.value,
				operation: response.condition
			};
	}
};

export const conditionResponseToGRPCRequestConverter: Converter<RuleConditionDto, ConditionDto> = (condition) => {
	const conditionDto = new ConditionDto();
	conditionDto.setId(condition.uuid as string);

	switch (condition.type) {
		case ConditionDtoObjectTypes.DIGITAL_INPUT:
			conditionDto.setType(ConditionType.COND_DIGITAL_INPUT);

			const digitalInputCondition = new ConditionDi();
			digitalInputCondition.setDiProp(condition.diProp);
			digitalInputCondition.setChannelId(condition.diChannelUuid);
			conditionDto.setConditionDio(digitalInputCondition);

			break;
		case ConditionDtoObjectTypes.AUX_DEVICE:
			conditionDto.setType(ConditionType.COND_AUX_DEVICE);

			const auxDeviceCondition = new ConditionAuxDevice();
			auxDeviceCondition.setAuxDeviceId(condition.auxDeviceUuid);
			auxDeviceCondition.setState(condition.auxDeviceProp);
			conditionDto.setConditionAux(auxDeviceCondition);

			break;
		case ConditionDtoObjectTypes.CALCULATION:
			conditionDto.setType(ConditionType.COND_CALC);

			const calculationCondition = new ConditionCalc();
			calculationCondition.setCondition(condition.condition);
			calculationCondition.setLowerLimit(condition.lowerLimit);
			calculationCondition.setUpperLimit(condition.upperLimit);
			calculationCondition.setCalcId(condition.calculationUuid);
			conditionDto.setConditionCalc(calculationCondition);

			break;
		case ConditionDtoObjectTypes.MEASUREMENT_IN_OUT_RANGE:
		case ConditionDtoObjectTypes.MEASUREMENT_MORE_THAN_LESS_THAN:
			conditionDto.setType(ConditionType.COND_MEASUREMENT);

			const measurementCondition = new ConditionMeasurement();
			measurementCondition.setMeasId(condition.measurementUuid);

			if (condition.type === ConditionDtoObjectTypes.MEASUREMENT_IN_OUT_RANGE) {
				if (condition.condition === InRangeConditions.IN_RANGE) {
					const range = new MeasurementPropInRange();
					range.setLowerLimit(condition.lowerLimit);
					range.setUpperLimit(condition.upperLimit);
					measurementCondition.setType(MeasurementConditionType.COND_IN_RANGE);
					measurementCondition.setInRange(range);
				}
				if (condition.condition === InRangeConditions.OUT_OF_RANGE) {
					const range = new MeasurementPropOutOfRange();
					range.setLowerLimit(condition.lowerLimit);
					range.setUpperLimit(condition.upperLimit);
					measurementCondition.setType(MeasurementConditionType.COND_OUT_OF_RANGE);
					measurementCondition.setOutOfRange(range);
				}
			}
			if (condition.type === ConditionDtoObjectTypes.MEASUREMENT_MORE_THAN_LESS_THAN) {
				if (condition.condition === MoreLessConditions.LESS_THAN) {
					const threshold = new MeasurementPropLessThan();
					threshold.setLowerThreshold(condition.threshold);
					measurementCondition.setType(MeasurementConditionType.COND_LESS_THAN);
					measurementCondition.setLessThan(threshold);
				}
				if (condition.condition === MoreLessConditions.MORE_THAN) {
					const threshold = new MeasurementPropMoreThan();
					threshold.setUpperThreshold(condition.threshold);
					measurementCondition.setType(MeasurementConditionType.COND_MORE_THAN);
					measurementCondition.setMoreThan(threshold);
				}
			}
			conditionDto.setConditionMeas(measurementCondition);

			break;
		case ConditionDtoObjectTypes.CONNECTION:
			conditionDto.setType(ConditionType.COND_CONNECTION);

			const connectionCondition = new ConditionConnection();
			connectionCondition.setStProp(condition.connectionState);
			conditionDto.setConditionConn(connectionCondition);

			break;
		case ConditionDtoObjectTypes.TEST:
			conditionDto.setType(ConditionType.COND_TEST);

			const testCondition = new ConditionTest();
			testCondition.setTestProp(condition.testState);
			conditionDto.setConditionTest(testCondition);

			break;
		case ConditionDtoObjectTypes.STATION:
			conditionDto.setType(ConditionType.COND_STATION);

			const stationCondition = new ConditionStation();
			stationCondition.setOp(condition.op);
			stationCondition.setStationProp(condition.action);
			conditionDto.setConditionStation(stationCondition);

			break;
		case ConditionDtoObjectTypes.VARIABLE:
			conditionDto.setType(ConditionType.COND_VARIABLE);

			const variableCondtion = new ConditionVariable();
			const variable = new VariableRequest();
			variable.setId(condition.varUuid);
			variable.setName(condition.varUuid);
			if (condition.varType === VariablesTypes.BOOLEAN) {
				variable.setType(VariableType.VARIABLE_TYPE_BOOLEAN);
				variable.setDefaultValBool(condition.value === 'true');
			} else if (condition.varType === VariablesTypes.NUMERIC) {
				variable.setType(VariableType.VARIABLE_TYPE_NUMERIC);
				variable.setDefaultValNum(Number(condition.value));
			} else if (condition.varType === VariablesTypes.STRING) {
				variable.setType(VariableType.VARIABLE_TYPE_STRING);
				variable.setDefaultValStr(condition.value as string);
			}

			variableCondtion.setVariable(variable);
			variableCondtion.setPredicate(condition.condition);
			conditionDto.setConditionVariable(variableCondtion);

			break;
	}

	return conditionDto;
};
