import { ProductFormType } from './';
import { ProductPlatforms } from './products.enums';

export const productDefaultValues: ProductFormType = {
	name: '',
	model: '',
	stationId: '',
	version: '',
	platform: ProductPlatforms.WINDOWS
};

export const productFormId = 'productFormId';
export const unlinkConfirmModalId = 'unlinkConfirmModalId';
export const linkConfirmModalId = 'linkConfirmModalId';
export const connectDeviceConfirmModalId = 'connectDeviceConfirmModalId';
export const deleteConfirmModalId = 'deleteConfirmModalId';
