import React, { useCallback, FC } from 'react';
import ImageUploader from '../components/ImageUploader';
import { uploadImage } from '../imageUploader.api';
import { IImageUploadResponse } from '../imageUploader.types';

interface IImageUploaderContainerProps {
	className?: string;
	url?: string;
	isReadonly?: boolean;
	onChange?: (image: IImageUploadResponse | null) => void;
}

const ImageUploaderContainer: FC<IImageUploaderContainerProps> = ({
	className,
	url,
	onChange = () => {},
	isReadonly = false
}) => {
	const handleDelete = useCallback(() => onChange(null), [onChange]);

	const handleChange = useCallback(
		async (file: File) => {
			const formData = new FormData();
			formData.append('image', file);

			const response = await uploadImage(formData);
			onChange(response);
		},
		[onChange]
	);

	return (
		<ImageUploader
			onDelete={handleDelete}
			onChange={handleChange}
			url={url}
			className={className}
			isReadonly={isReadonly}
		/>
	);
};

export default React.memo(ImageUploaderContainer);
