import React, { FC } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { Icon } from '@tactun/ui';
import styles from './TestReportConfiguration.module.scss';

export interface GeneralFieldProps {
	label: string;
	value: string;
	description?: string;
}

export const GeneralField: FC<GeneralFieldProps> = ({ label, value, description }) => {
	return (
		<div className={styles.generalFieldCont}>
			<label>{label}: </label>
			<p>{value}</p>
			{description && (
				<>
					<Tooltip target={`#${label}`} className="xxxx" />
					<Icon id={label} name="question-circle" data-pr-tooltip={description || ''} />
				</>
			)}
		</div>
	);
};
