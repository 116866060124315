import React, { useCallback, useMemo } from 'react';
import { PageLayout } from '@tactun/ui';
import { IconButton } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { CrudActions, TableFilters } from '../../../../components';
import { IAuxiliaryDeviceListItem } from '../../auxiliaryDevice.types';
import { AuxiliaryDeviceTableActionTypes } from '../../auxiliaryDevice.enums';
import { ITableColumns } from '../../../../types';
import { useTableConfigs } from '../../../../hooks';
import { useIsLocalDeployment } from '../../../../hooks/useIsLocalDeployment';

interface IAuxiliaryDevicesTableProps {
	onAction: (type: AuxiliaryDeviceTableActionTypes, data?: IAuxiliaryDeviceListItem) => void;
	data?: IAuxiliaryDeviceListItem[];
	isLoading: boolean;
	stationName: string;
}

const AuxiliaryDevicesTable: React.FC<IAuxiliaryDevicesTableProps> = ({ data, stationName, isLoading, onAction }) => {
	const { t } = useTranslation('auxiliaryDevice');
	const isDisabled = useIsLocalDeployment();
	const formattedData = useMemo(
		() => data?.map((item) => ({ ...item, type: t(item.type), status: t(item.status) })),
		[data, t]
	);

	const transColumns = useMemo<ITableColumns<IAuxiliaryDeviceListItem>[]>(() => {
		return [
			{ field: 'type', header: t('Auxiliary device type') },
			{ field: 'status', header: t('Status') }
		];
	}, [t]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<IAuxiliaryDeviceListItem>(transColumns, formattedData);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Duplicate'),
				disabled: true,
				icon: 't-icon-duplicate',
				command: () => onAction(AuxiliaryDeviceTableActionTypes.DUPLICATE, data)
			},
			{
				label: t('Delete'),
				command: () => onAction(AuxiliaryDeviceTableActionTypes.DELETE, data),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				disabled: isDisabled
			}
		],
		[t, isDisabled, onAction]
	);

	const handleCreate = useCallback(() => {
		onAction(AuxiliaryDeviceTableActionTypes.CRETE);
	}, [onAction]);

	return (
		<PageLayout info={stationName}>
			<PageLayout.Header title={t('Auxiliary Devices')}>
				<CrudActions isCreateDisabled={isDisabled} onCreate={handleCreate} entityLabel={t('Auxiliary Device')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={formattedData}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Auxiliary device name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(AuxiliaryDeviceTableActionTypes.EDIT, rowData)}
							disabled={isDisabled}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default React.memo(AuxiliaryDevicesTable);
