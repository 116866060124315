import type { FC } from 'react';
import React, { useMemo } from 'react';
import styles from './TriggerCard.module.scss';
import { InputText, KebabMenu } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { RuleTrigger } from '../../triggers.types';
import {
	TriggerAuxDeviceProperties,
	TriggerAxisProperties,
	TriggerConnectionProperties,
	TriggerDigitalInputProperties,
	TriggerMeasurementProperties,
	TriggerObjectTypes,
	TriggerSpecimenProperties,
	TriggerStationProperties,
	TriggerTestProperties,
	TriggerUIButtonProperties
} from '../../triggers.enums';
import { IAuxiliaryDeviceResponseDto } from '../../../AuxiliaryDevices';
import { IStationChannelResponseDto } from '../../../StationChannels';
import { MeasurementResponseDto } from '../../../Measurements';
import { getEntityName } from '../../../../tools';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';

interface TriggerCardProps {
	trigger: RuleTrigger;
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	digitalChannels: IStationChannelResponseDto[];
	auxDevices: IAuxiliaryDeviceResponseDto[];
	onEdit: () => void;
	onDelete: () => void;
}

const TriggerCard: FC<TriggerCardProps> = ({
	trigger,
	digitalChannels,
	measurements,
	auxDevices,
	onEdit,
	onDelete
}) => {
	const { t } = useTranslation('ruleTrigger');

	const label = useMemo(() => {
		const { objectType, objectId } = trigger;

		if (objectType === undefined || objectType === null) {
			return '';
		}

		switch (objectType) {
			case TriggerObjectTypes.DIGITAL_INPUT:
				return t('Digital input: {{name}}', { name: getEntityName(digitalChannels, objectId) });

			case TriggerObjectTypes.MEASUREMENT:
				return t('Measurement: {{name}}', { name: getEntityName(measurements, objectId) });

			case TriggerObjectTypes.AUX_DEVICE:
				return t('Auxiliary device: {{name}}', { name: getEntityName(auxDevices, objectId) });

			default:
				return t(TriggerObjectTypes[objectType]);
		}
	}, [auxDevices, digitalChannels, measurements, t, trigger]);

	const value = useMemo(() => {
		const { property, objectType, objectId, upperLimit, threshold, lowerLimit } = trigger;
		if (property === undefined || property === null || objectType === undefined || objectType === null) return '';

		switch (objectType) {
			case TriggerObjectTypes.DIGITAL_INPUT:
				return t(TriggerDigitalInputProperties[property]);
			case TriggerObjectTypes.TEST:
				return t(TriggerTestProperties[property]);
			case TriggerObjectTypes.STATION:
				return t(TriggerStationProperties[property]);
			case TriggerObjectTypes.MEASUREMENT:
				const selectedMeasurement = measurements.find((measurement) => measurement.id === objectId);
				if (
					property === TriggerMeasurementProperties.OUT_OF_RANGE ||
					property === TriggerMeasurementProperties.IN_RANGE
				) {
					return `${t(TriggerMeasurementProperties[property])} ${lowerLimit} - ${upperLimit} ${selectedMeasurement?.unit
						.name}`;
				}
				if (
					property === TriggerMeasurementProperties.LESS_THAN ||
					property === TriggerMeasurementProperties.MORE_THAN
				) {
					return `${t(TriggerMeasurementProperties[property])} ${threshold} ${selectedMeasurement?.unit.name}`;
				}
				return t(TriggerMeasurementProperties[property]);
			case TriggerObjectTypes.AUX_DEVICE:
				return t(TriggerAuxDeviceProperties[property]);
			case TriggerObjectTypes.CONNECTION:
				return t(TriggerConnectionProperties[property]);
			case TriggerObjectTypes.SPECIMEN:
				return t(TriggerSpecimenProperties[property]);
			case TriggerObjectTypes.AXIS:
				return t(TriggerAxisProperties[property]);
			case TriggerObjectTypes.UI_BUTTON:
				return t(TriggerUIButtonProperties[property]);
			default:
				return '';
		}
	}, [measurements, t, trigger]);

	const actions = useMemo(() => {
		return [
			{ label: t('Edit'), command: () => onEdit() },
			{ label: t('Delete'), command: () => onDelete() }
		];
	}, [t, onEdit, onDelete]);
	return (
		<div className={styles.container}>
			<InputText data-testid="ruleTriggerLabel" className={styles.label} name={label} value={label} disabled />
			<InputText data-testid="ruleTriggerValue" className={styles.value} name={value} value={value} disabled />
			<div className="iconMore">
				<KebabMenu data-testid="ruleTriggerMore" model={actions} />
			</div>
		</div>
	);
};

export default TriggerCard;
