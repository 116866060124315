import React, { useCallback, useMemo } from 'react';
import { Layout } from 'react-grid-layout';
import { useParams, useNavigate } from 'react-router-dom';
import { IStep, useWizard } from '@tactun/ui';
import { LCProvider } from '../../../components/RealTimeLineGraph';
import WidgetContainer from '../../Dashboard/containers/WidgetContainer';
import AddWidgetSidebar from '../../Dashboard/components/AddWidgetSIdebar';
import Grid from '../../Dashboard/components/Grid';
import { useDashboardColumnWidth } from '../../Dashboard/dashboard.hooks';
import { widgetDefinitions } from '../../Dashboard/dashboard.widgetDefinitions';
import { widgetToGridLayoutItem } from '../../Dashboard/dashboard.converters';
import { useDashboardTemplateWidgets } from '../dashboardTypes.hooks';
import { dashboardPath, excludedWidgetTypes } from '../dashboardTypes.const';

const DashboardTypeDashboardContainer: React.FC = () => {
	const { dashboardTypeId } = useParams();
	const navigate = useNavigate();
	const { widgets, widgetsOrg, addWidget, updateWidget, updateWidgets, removeWidget } =
		useDashboardTemplateWidgets(dashboardTypeId);
	const layout = useMemo(() => widgets.map((widget) => widgetToGridLayoutItem(widget)), [widgets]);
	const { columnWidth, gridRef } = useDashboardColumnWidth();
	const { beforeNextStep } = useWizard();
	beforeNextStep((step: IStep) => {
		if (step.path === dashboardPath) {
			navigate('/components-settings/dashboard-types');
			return Promise.resolve(false);
		} else {
			return Promise.resolve(true);
		}
	});

	const onLayoutChange = useCallback(
		(newLayout: Layout[]) => {
			const newW = widgetsOrg.map((widget) => {
				const id = widget.metadata.id;
				const layoutItem = newLayout.find(({ i }) => i === id);

				return layoutItem
					? {
							...widget,
							x: layoutItem.x,
							y: layoutItem.y,
							w: layoutItem.w,
							h: widget?.minimized ? widget.h : layoutItem.h
					  }
					: widget;
			});

			updateWidgets(newW);
		},
		[updateWidgets, widgetsOrg]
	);

	const filteredWidgetTypes = useMemo(
		() => widgetDefinitions.filter((widgetType) => !excludedWidgetTypes.some((ewt) => ewt === widgetType.type)),
		[]
	);

	return (
		<>
			<LCProvider>
				<Grid ref={gridRef} layout={layout} onLayoutChange={onLayoutChange}>
					{widgetsOrg.map((widget) => (
						<div key={widget.metadata.id}>
							<WidgetContainer
								id={widget.metadata.id}
								columnWidth={columnWidth}
								updateWidget={updateWidget}
								removeWidget={removeWidget}
								addWidget={addWidget}
								widget={widget}
							/>
						</div>
					))}
				</Grid>
			</LCProvider>
			<AddWidgetSidebar
				isTestWidgets
				addWidget={addWidget}
				widgets={widgets}
				widgetTypes={filteredWidgetTypes}
				position="right"
				isType
			/>
		</>
	);
};

export default React.memo(DashboardTypeDashboardContainer);
