import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { format as formatDate, isDate } from 'date-fns';
import { enUS } from 'date-fns/locale';

import actionsEN from '../i18n/en-US/actions.json';
import actuatorsEN from '../i18n/en-US/actuators.json';
import authEN from '../i18n/en-US/auth.json';
import auxiliaryDeviceEN from '../i18n/en-US/auxiliaryDevice.json';
import axesEN from '../i18n/en-US/axes.json';
import calibrationsEN from '../i18n/en-US/calculation.json';
import calculationEN from '../i18n/en-US/calculation.json';
import commonEN from '../i18n/en-US/common.json';
import controlChannelEN from '../i18n/en-US/controlChannel.json';
import controlStageEN from '../i18n/en-US/controlStage.json';
import controlAlgorithmEN from '../i18n/en-US/controlAlgorithm.json';
import dashboardEN from '../i18n/en-US/dashboard.json';
import dashboardConfigurationEN from '../i18n/en-US/dashboardConfiguration.json';
import dataChannelEN from '../i18n/en-US/dataChannel.json';
import dataLoggingsEN from '../i18n/en-US/dataLoggings.json';
import graphEN from '../i18n/en-US/graph.json';
import groupsEN from '../i18n/en-US/groups.json';
import imageUploaderEN from '../i18n/en-US/imageUploader.json';
import measurementsEN from '../i18n/en-US/measurements.json';
import rulesEN from '../i18n/en-US/rules.json';
import rulesConditionEN from '../i18n/en-US/rulesCondition.json';
import ruleTriggerEN from '../i18n/en-US/ruleTrigger.json';
import sensorsEN from '../i18n/en-US/sensors.json';
import specimenEN from '../i18n/en-US/specimen.json';
import specimensEN from '../i18n/en-US/specimens.json';
import specimenTypesEN from '../i18n/en-US/specimenTypes.json';
import stationEN from '../i18n/en-US/station.json';
import stationChannelEN from '../i18n/en-US/stationChannel.json';
import stationRulesEN from '../i18n/en-US/stationRules.json';
import systemEN from '../i18n/en-US/system.json';
import testEN from '../i18n/en-US/test.json';
import testActionsEN from '../i18n/en-US/testActions.json';
import testAxesConfigurationEN from '../i18n/en-US/testAxesConfiguration.json';
import testConfigurationEN from '../i18n/en-US/testConfiguration.json';
import testLoopsEN from '../i18n/en-US/testLoops.json';
import testMeasurementsEN from '../i18n/en-US/testMeasurements.json';
import testSequencerRulesEN from '../i18n/en-US/testSequencerRules.json';
import testVariablesEN from '../i18n/en-US/testVariables.json';
import unitsEN from '../i18n/en-US/units.json';
import variablesEN from '../i18n/en-US/variables.json';
import waveformsEN from '../i18n/en-US/waveforms.json';
import widgetsEN from '../i18n/en-US/widgets.json';
import entityDeleterEn from '../i18n/en-US/entityDeleter.json';
import productsEN from '../i18n/en-US/products.json';
import offsetCalibrationEN from '../i18n/en-US/offsetCalibration.json';
import dashboardTypesEN from '../i18n/en-US/dashboardTypes.json';

const locales = { 'en-US': enUS };

const resources = {
	'en-US': {
		actions: actionsEN,
		common: commonEN,
		actuators: actuatorsEN,
		auth: authEN,
		auxiliaryDevice: auxiliaryDeviceEN,
		axes: axesEN,
		calibrations: calibrationsEN,
		calculation: calculationEN,
		controlChannel: controlChannelEN,
		controlAlgorithm: controlAlgorithmEN,
		controlStage: controlStageEN,
		dashboard: dashboardEN,
		dashboardConfiguration: dashboardConfigurationEN,
		dataChannel: dataChannelEN,
		graph: graphEN,
		ruleTrigger: ruleTriggerEN,
		imageUploader: imageUploaderEN,
		measurements: measurementsEN,
		rulesCondition: rulesConditionEN,
		testVariables: testVariablesEN,
		groups: groupsEN,
		specimen: specimenEN,
		specimenTypes: specimenTypesEN,
		stationRules: stationRulesEN,
		testAxesConfiguration: testAxesConfigurationEN,
		testConfiguration: testConfigurationEN,
		testMeasurements: testMeasurementsEN,
		units: unitsEN,
		variables: variablesEN,
		waveforms: waveformsEN,
		widgets: widgetsEN,
		sensors: sensorsEN,
		station: stationEN,
		stationChannel: stationChannelEN,
		system: systemEN,
		test: testEN,
		testSequencerRules: testSequencerRulesEN,
		dataLoggings: dataLoggingsEN,
		testLoops: testLoopsEN,
		testActions: testActionsEN,
		specimens: specimensEN,
		rules: rulesEN,
		entityDeleter: entityDeleterEn,
		products: productsEN,
		offsetCalibration: offsetCalibrationEN,
		dashboardTypes: dashboardTypesEN
	}
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
	name: 'defaultDetector',
	lookup() {
		return 'en-US' || undefined;
	}
});

const getVariationOfAOrAn = (value: string, capitalize: boolean) => {
	const letters = ['a', 'e', 'i', 'o', 'u', 'h'];
	const firstLetter = value.substring(0, 1).toLowerCase();
	let correctWordForm = '';

	if (letters.some((letter) => firstLetter === letter)) {
		correctWordForm = capitalize ? 'An' : 'an';
	} else {
		correctWordForm = capitalize ? 'A' : 'a';
	}

	return correctWordForm;
};

i18n
	.use(initReactI18next)
	.use(languageDetector)
	.init({
		keySeparator: false,
		fallbackLng: 'en-US',
		resources,
		defaultNS: 'common',
		debug: false,
		detection: {
			order: ['querystring', 'cookie', 'localStorage', 'defaultDetector', 'navigator'],
			lookupQuerystring: 'lng',
			lookupCookie: 'lng',
			caches: ['localStorage', 'cookie']
		},
		interpolation: {
			escapeValue: false,
			format: (value, format, lng) => {
				if (isDate(value) && lng && format) {
					//@ts-ignore
					const locale = locales[lng];

					return formatDate(value, format, { locale });
				}

				if (format === 'en-handle-an' || format === 'en-handle-an-capitalized') {
					return !lng || lng.startsWith('en') ? getVariationOfAOrAn(value, format.endsWith('-capitalized')) : '';
				}

				return value;
			}
		}
	});

export default i18n;
