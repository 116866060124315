import React, { useCallback } from 'react';
import WidgetComponent from '../components/Widget/Widget';
import { Widget, WidgetItem } from '../dashboard.types';
import { heightPxToRow, heightRowToPx, widthColToPx } from '../dashboard.converters';
import { widgetTypesComponentsMapping } from '../dashboard.const';

export interface WidgetContainerProps {
	id: string;
	columnWidth: number;
	updateWidget: (id: string, widget: Partial<Widget>) => void;
	removeWidget: (id: string) => void;
	addWidget: (widget: Widget) => void;
	widget: WidgetItem;
}

const WidgetContainer: React.FC<WidgetContainerProps> = (props) => {
	const { id, columnWidth, updateWidget, removeWidget, addWidget, widget } = props;

	const width = widthColToPx(widget.w, columnWidth);
	const height = heightRowToPx(widget.minimized ? 1 : widget.h);

	const updateHeight = useCallback(
		(newHeight: number) => {
			const newRowH = heightPxToRow(newHeight);

			if (widget.h !== newRowH && !widget.minimized) {
				updateWidget(id, {
					h: heightPxToRow(newHeight)
				});
			}
		},
		[id, updateWidget, widget.h, widget.minimized]
	);

	const updateSettings = useCallback(
		(metadata: Record<string, any>, settings?: Record<string, any>) => {
			updateWidget(id, { settings, metadata });
		},
		[id, updateWidget]
	);

	const WidgetTypeComponent = widgetTypesComponentsMapping[widget.type];

	return (
		<WidgetComponent
			id={id}
			widget={widget}
			updateWidget={updateWidget}
			removeWidget={removeWidget}
			addWidget={addWidget}
		>
			{WidgetTypeComponent && (
				<WidgetTypeComponent
					width={width}
					height={height}
					updateHeight={updateHeight}
					settings={widget.settings}
					metadata={widget.metadata}
					updateSettings={updateSettings}
				/>
			)}
		</WidgetComponent>
	);
};

export default React.memo(WidgetContainer);
