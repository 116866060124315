import { FC, useCallback, useState } from 'react';
import { Modal, useModalData } from '@tactun/ui';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import TestDataLoggingTable from '../components/TestDataLoggingTable';
import { useTestDataLoggings } from '../testDataLogging.hooks';
import { TestDataLoggingListItem } from '../testDataLogging.types';
import { TestDataLoggingActionTypes } from '../testDataLogging.enums';
import { createEditDataLoggingModalId, testDataLoggingModalId } from '../testDataLogging.const';
import TestDataLoggingCreateEditContainer from './TestDataLoggingCreateEditContainer';

interface TestDataLoggingContainerProps {
	testId?: string;
}

const TestDataLoggingTableContainer: FC<TestDataLoggingContainerProps> = ({ testId }) => {
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const modalData = useModalData(testDataLoggingModalId);

	const handleClose = useCallback(() => {
		Modal.hide(createEditDataLoggingModalId);
	}, []);

	const { testDataLoggings, isLoading, deleteDataLogging } = useTestDataLoggings(
		testId,
		modalData?.stageId,
		modalData?.stageType,
		handleClose
	);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			deleteDataLogging(id);
		},
		[deleteDataLogging]
	);
	const handleAction = useCallback(
		(type: TestDataLoggingActionTypes, data?: TestDataLoggingListItem) => {
			switch (type) {
				case TestDataLoggingActionTypes.CREATE:
					Modal.show(createEditDataLoggingModalId, { stageId: modalData?.stageId, stageType: modalData?.stageType });
					break;
				case TestDataLoggingActionTypes.EDIT:
					Modal.show(createEditDataLoggingModalId, {
						id: data?.id,
						stageId: modalData?.stageId,
						stageType: modalData?.stageType
					});
					break;
				case TestDataLoggingActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case TestDataLoggingActionTypes.DELETE:
					setEntityForDelete(data);
					break;
			}
		},
		[modalData?.stageId, modalData?.stageType]
	);

	return (
		<>
			<TestDataLoggingTable onAction={handleAction} data={testDataLoggings} isLoading={isLoading} />
			<TestDataLoggingCreateEditContainer testId={testId} onSaveSuccess={handleClose} onClose={handleClose} />
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.TEST_DATA_LOGGING}
				entity={entityForDelete}
			/>
		</>
	);
};

export default TestDataLoggingTableContainer;
