import * as yup from 'yup';
import { alphaNumericRegex } from '../../constants/regexes';
import * as api from './auxiliaryDevice.api';
import { AuxiliaryDeviceDirection, AuxiliaryDeviceType } from './auxiliaryDevice.enums';

export const auxiliaryDeviceSchema = yup
	.object({
		id: yup.string().optional(),
		stationId: yup.string().required('Station id is required'),
		name: yup
			.string()
			.required('Name is required')
			.max(30)
			.matches(alphaNumericRegex, 'Do not use special symbols')
			.test('checkStationChannelUniqueness', 'Name is used', function (value, form) {
				if (!value) return true;
				return api.checkName(value, form?.parent.stationId, form?.parent?.id);
			}),
		type: yup
			.mixed<AuxiliaryDeviceType>()
			.required('Type is required')
			.test((value) => Object.values(AuxiliaryDeviceType).includes(value as AuxiliaryDeviceType)),
		channelId: yup.string().required('Digital channel is required'),
		axisId: yup
			.string()
			.optional()
			.when(['type'], {
				is: AuxiliaryDeviceType.MECHANICAL_LIMITS,
				then: (schema) => schema.required('Axis is required')
			}),
		direction: yup
			.mixed()
			.optional()
			.when(['type'], {
				is: AuxiliaryDeviceType.MECHANICAL_LIMITS,
				then: (schema) => schema.oneOf(Object.values(AuxiliaryDeviceDirection)).required('Direction is required')
			})
	})
	.transform((value) => {
		const { id, ...rest } = value;
		return !id ? rest : value;
	});
