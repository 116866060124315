import axios from '../../httpCommon';
import { IStationChannelResponseDto } from '../StationChannels';
import { MeasurementRequestDto, MeasurementResponseDto } from './measurements.types';

export const getMeasurementsForStation = async (stationId: string) => {
	const response = await axios.get(`/quasar/measurements/station/${stationId}`);

	return response.data as MeasurementResponseDto[];
};

export const createMeasurement = async (measurement: MeasurementRequestDto) => {
	const response = await axios.post('/quasar/measurements', measurement);
	return response.data;
};

export const updateMeasurement = async (measurement: MeasurementRequestDto, measurementId: string) => {
	const response = await axios.put(`/quasar/measurements/${measurementId}`, measurement);
	return response.data;
};

export const getMeasurement = async (id: string) => {
	const response = await axios.get(`/quasar/measurements/${id}`);

	return response.data as MeasurementResponseDto;
};

export const checkName = async (name: string, parentId: string, id?: string): Promise<boolean> => {
	const response = await axios.post('/quasar/measurements/isNameUnique', { id, name, parentId });
	return response.data === true;
};

export const getAvailableStationChannels = async (stationId: string, type: string) => {
	const response = await axios.get(`/quasar/measurements/available-station-channels/${stationId}/${type}`);

	return response.data as IStationChannelResponseDto[];
};
