import { useEffect, useRef, useState } from 'react';

export const useIsLocalDeployment = () => {
	const isFirstRender = useRef(true);
	const [isLocalDeployment, setIsLocalDeployment] = useState(false);

	useEffect(() => {
		async function checkLocalDeployment() {
			if (window.__TAURI_INVOKE__) {
				const isLocal = await window.__TAURI_INVOKE__('is_local_deployment');
				setIsLocalDeployment(isLocal);
			}
		}

		if (isFirstRender.current) {
			isFirstRender.current = false;
			checkLocalDeployment();
		}
	}, []);

	return isLocalDeployment;
};
