import React from 'react';
import cx from 'classnames';
import { Icon, IconButton } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { StageAxisActionTypes } from '../../tests.enums';
import {
	WaveformDirection,
	WaveformHaversineType,
	WaveformRampDirection,
	WaveformRampType,
	WaveformType
} from '../../../Waveforms';
import { TestAxesConfigurationFormType } from '../../../TestAxesConfiguration/testAxesConfiguration.type';
import dwelConstImg from './assets/DWEL_CONST.svg';
import RampUpImg from './assets/RAMP_UP.svg';
import RampDownImg from './assets/RAMP_DOWN.svg';
import RampAutodetectImg from './assets/RAMP_AUTODETECT.svg';
import SineWaveImg from './assets/SINEWAVE.svg';
import DuotonicHaversineImg from './assets/DUOTONIC_HAVERSINE.svg';
import DuotonicRampImg from './assets/DUOTONIC_RAMP.svg';
import SimpleSweepSineImg from './assets/SIMPLE_SWEEP_SINE.svg';
import TaperedSineImg from './assets/TAPERED_SINE.svg';
import UpHaversineImg from './assets/UP_HAVERSINE.svg';
import DownHaversineImg from './assets/DOWN_HAVERSINE.svg';
import SquareWaveImg from './assets/SQUARE_WAVE.svg';
/*
import ZigzagImg from './assets/ZIGZAG.svg'; */
import styles from './StageAxis.module.scss';

interface StageAxisProps {
	axis?: TestAxesConfigurationFormType;
	onStageAxisAction: (type: StageAxisActionTypes, axis?: TestAxesConfigurationFormType) => void;
}

const getWaveformImg = (axis: TestAxesConfigurationFormType) => {
	switch (axis.wf.type) {
		case WaveformType.RAMP: {
			const wf = axis.wf as WaveformRampType;
			return wf.rampDirection === WaveformRampDirection.UP
				? RampUpImg
				: wf.rampDirection === WaveformRampDirection.DOWN
				? RampDownImg
				: RampAutodetectImg;
		}
		case WaveformType.DWELL:
		case WaveformType.CONSTANT:
			return dwelConstImg;
		case WaveformType.SINE:
			return SineWaveImg;
		case WaveformType.DUO_RAMP:
			return DuotonicRampImg;
		case WaveformType.DUO_HAVER_SINE:
			return DuotonicHaversineImg;
		case WaveformType.TAPERED_SINE:
			return TaperedSineImg;
		case WaveformType.HAVER_SINE: {
			const wf = axis.wf as WaveformHaversineType;
			return wf.direction === WaveformDirection.UP ? UpHaversineImg : DownHaversineImg;
		}
		case WaveformType.SWEEP_SINE:
			return SimpleSweepSineImg;
		case WaveformType.SQUAREWAVE:
			return SquareWaveImg;
	}
};

const StageAxis: React.FC<StageAxisProps> = ({ axis, onStageAxisAction }) => {
	const { t } = useTranslation('test');

	return (
		<div className={styles.axesBox} data-testid="axesBox">
			{axis?.axisId ? (
				<div className={styles.waveform}>
					<img
						src={getWaveformImg(axis)}
						alt={axis?.wf?.type ? WaveformType[axis.wf.type] : t('Waveform')}
						className={styles.waveformImg}
					/>
					<div className={styles.btns}>
						<IconButton
							className={styles.button}
							data-testid="stageEdit"
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onStageAxisAction(StageAxisActionTypes.EDIT_AXIS, axis)}
						/>
						<IconButton
							className={cx(styles.button, styles.delete)}
							data-testid="stageDelete"
							icon="delete"
							fontSize="1.2rem"
							title={t('Delete')}
							onClick={() => onStageAxisAction(StageAxisActionTypes.DELETE_AXIS, axis)}
						/>
					</div>
				</div>
			) : (
				<Icon
					name="plus"
					data-testid="emptyAdd"
					size="3rem"
					onClick={() => onStageAxisAction(StageAxisActionTypes.ADD_AXIS)}
				/>
			)}
		</div>
	);
};

export default React.memo(StageAxis);
