import * as yup from 'yup';
import { alphaNumericRegex } from '../../constants/regexes';
import { emptyStringToNull } from '../../tools/yupHelpers';
import { isFieldVisible } from './components/StationChannel/stationChannelFieldMapping';
import * as api from './stationChannels.api';
import {
	BridgeTypes,
	ChannelType,
	Direction,
	ExcitationVoltage,
	Range,
	Gain,
	SpecificType,
	State,
	PGAGain,
	SamplingRate
} from './stationChannels.enums';
import { offsetFormatter } from './stationChannels.tools';

export const stationChannelSchema = yup
	.object({
		id: yup.string().notRequired(),
		name: yup
			.string()
			.required()
			.max(30)
			.matches(alphaNumericRegex, 'Do not use special symbols')
			.test('checkStationChannelUniqueness', 'Name is used', function (value, form) {
				if (!value) return true;
				return api.checkName(value, form?.parent?.stationId, form?.parent?.id);
			}),
		type: yup.mixed<ChannelType>().required(),
		channelId: yup.string().required('Controller channel number is a required field'),
		stationId: yup.string().required(),
		specificType: yup.mixed<SpecificType>().when(['type'], {
			is: (type: ChannelType) => isFieldVisible('specificType', type),
			then: (schema) => schema.required('Type is a required field')
		}),

		range: yup
			.mixed<Range>()
			.nullable()
			.when(['type'], {
				is: (type: ChannelType) => isFieldVisible('range', type),
				then: (schema) => schema.required('Range is a required field')
			}),

		direction: yup.mixed<Direction>().when(['type'], {
			is: (type: ChannelType) => isFieldVisible('direction', type),
			then: (schema) => schema.required('Direction is a required field')
		}),

		activeState: yup.mixed<State>().when(['type'], {
			is: (type: ChannelType) => isFieldVisible('activeState', type),
			then: (schema) => schema.required('Active state is a required field')
		}),

		defaultState: yup.mixed<State>().when(['type', 'direction'], {
			is: (type: ChannelType, direction: Direction) => {
				if (type === ChannelType.DIGITAL_IO_5V || type === ChannelType.DIGITAL_IO_24V) {
					return isFieldVisible('defaultState', type) && direction === Direction.OUTPUT;
				} else {
					return isFieldVisible('defaultState', type);
				}
			},
			then: (schema) => schema.required('Default state is a required field')
		}),

		pullUpResistor: yup.boolean().when(['type', 'direction'], {
			is: (type: ChannelType, direction: Direction) =>
				isFieldVisible('pullUpResistor', type) && direction === Direction.INPUT,
			then: (schema) => schema.required()
		}),

		bridgeType: yup.mixed<BridgeTypes>().when(['type'], {
			is: (type: ChannelType) => isFieldVisible('bridgeType', type),
			then: (schema) => schema.required('Bridge type is a required field')
		}),

		excitationVoltage: yup.mixed<ExcitationVoltage>().when(['type'], {
			is: (type: ChannelType) => isFieldVisible('excitationVoltage', type),
			then: (schema) => schema.required('Excitation Voltage is a required field')
		}),

		gain: yup.mixed<Gain>().when(['type'], {
			is: (type: ChannelType) => isFieldVisible('gain', type),
			then: (schema) => schema.required('ADC Gain is a required field')
		}),

		pgaGain: yup.mixed<PGAGain>().when(['type'], {
			is: (type: ChannelType) => isFieldVisible('gain', type),
			then: (schema) => schema.required('PGA Gain is a required field')
		}),
		maxPWMFrequency: yup.number().transform(emptyStringToNull).nullable(),
		offset: yup
			.number()
			.transform(emptyStringToNull)
			.when(['type', 'channelId'], {
				is: (type: ChannelType, channelId: string) => {
					return isFieldVisible('offset', type) && channelId;
				},
				then: () => {
					return yup
						.number()
						.transform(emptyStringToNull)
						.test('offsetLimits', 'Going beyond the limit', (value, context) => {
							if (value === undefined) {
								return context.createError({
									message: 'Offset is required'
								});
							}

							const rangeForParse = Range[context.parent.range];

							if (!rangeForParse) {
								return true;
							}

							let { min, max, unit } = offsetFormatter(rangeForParse);
							min = unit === 'V' ? min * 1000 : min;
							max = unit === 'V' ? max * 1000 : max;

							if (value > max) {
								return context.createError({
									message: 'Maximum value is ' + max
								});
							} else if (value < min) {
								return context.createError({
									message: 'Minimum value is ' + min
								});
							} else {
								return true;
							}
						});
				}
			}),

		frequency: yup
			.number()
			.transform(emptyStringToNull)
			.moreThan(0, 'Minimum value is 1')
			.when(['type', 'channelId'], {
				is: (type: ChannelType, channelId: string) => {
					return isFieldVisible('frequency', type) && channelId;
				},
				then: (schema) => {
					return schema
						.transform(emptyStringToNull)
						.test('frequencyLimits', 'Going beyond the limit', (value, context) => {
							if (value === undefined) {
								return context.createError({
									message: 'Frequency is required'
								});
							}

							const max = context.parent.maxPWMFrequency;

							if (max && value > max) {
								return context.createError({
									message: 'Maximum value is ' + max
								});
							} else {
								return true;
							}
						});
				}
			}),
		samplingRate: yup.mixed<SamplingRate>().when(['type'], {
			is: (type: ChannelType) => {
				return isFieldVisible('samplingRate', type);
			},
			then: (schema) => {
				return schema.required('Sampling Rate is a required field');
			}
		}),
		excitationAmplitude: yup.number().when(['type'], {
			is: (type: ChannelType) => {
				return isFieldVisible('excitationAmplitude', type);
			},
			then: (schema) => {
				return schema
					.required('Excitation Amplitude is a required field')
					.min(0.1, 'Excitation Amplitude must be greater than or equal to 0.1')
					.max(5, 'Excitation Amplitude must be less than or equal to 5');
			}
		}),
		excitationFrequency: yup.number().when(['type'], {
			is: (type: ChannelType) => {
				return isFieldVisible('excitationFrequency', type);
			},
			then: (schema) => {
				return schema
					.required('Excitation Frequency is a required field')
					.min(0.1, 'Excitation Frequency must be greater than or equal to 0.1')
					.max(20, 'Excitation Frequency must be less than or equal to 20');
			}
		}),
		limit: yup
			.number()
			.transform(emptyStringToNull)
			.when(['type', 'channelId'], {
				is: (type: ChannelType, channelId: string) => {
					return isFieldVisible('limit', type) && channelId;
				},
				then: () => {
					return yup
						.number()
						.transform(emptyStringToNull)
						.positive('Only positive number is accepted')
						.test('servoLimits', 'Going beyond the limit', (value, context) => {
							if (value === undefined) {
								return context.createError({
									message: 'Limit is required'
								});
							}

							const rangeForParse = Range[context.parent.range];

							if (!rangeForParse) {
								return true;
							}

							let { max } = offsetFormatter(rangeForParse);

							if (value > max) {
								return context.createError({
									message: 'Maximum value is ' + max
								});
							} else {
								return true;
							}
						});
				}
			}),
		bouncingCancellation: yup.boolean().optional(),
		bouncingLatency: yup
			.number()
			.optional()
			.transform(emptyStringToNull)
			.when(['type', 'bouncingCancellation'], {
				is: (type: ChannelType, bouncingCancellation?: boolean) =>
					isFieldVisible('bouncingCancellation', type) && bouncingCancellation,
				then: (schema) =>
					schema
						.integer('Bouncing Latency must be an integer')
						.min(10, ({ min }) => `Minimum allowed number is ${min}`)
						.max(10000, ({ max }) => `Maximum allowed number is ${max}`)
						.required('Bouncing Latency is a required field'),
				otherwise: (schema) => schema.optional()
			})
	})
	.required();
