import { useContext, useEffect } from 'react';
import { IEntityWithGroups } from '../../types';
import { GroupContext } from './group.context';
import { IGroupsContext } from './group.types';

export const useGroup = (selected?: IEntityWithGroups[], onResetSelection?: () => void): IGroupsContext => {
	const context = useContext(GroupContext);
	const { _setSelectedItems, _setResetSelectionCallback } = context;

	useEffect(() => {
		if (selected) {
			_setSelectedItems?.(selected);
		}
	}, [_setSelectedItems, selected, onResetSelection]);

	useEffect(() => {
		if (onResetSelection) {
			_setResetSelectionCallback?.(onResetSelection);
		}
	}, [_setResetSelectionCallback, onResetSelection]);

	return context;
};
