import { Form, InputText, Dropdown, InputNumber, GroupInputFrame } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import ImageUploader from '../../../ImageUploader';
import { SpecimenForm as SpecimenFormType, SpecimensFormProps } from '../../specimens.types';
import { useSpecimenType } from '../../../SpecimenTypes';
import { specimenCreateEditFormId } from '../../specimens.const';
import DefaultImage from '../../../ImageUploader/assets/defaultImage.svg';
import styles from './SpecimenFormThin.module.scss';

interface SpecimenFormThinProps extends SpecimensFormProps {
	isForDashboard: boolean;
}

const SpecimenFormThin: React.FC<SpecimenFormThinProps> = ({
	onSave,
	specimenTypes,
	materials,
	tests,
	dimensions,
	isForDashboard
}) => {
	const { t } = useTranslation('specimens');
	const {
		control,
		register,
		watch,
		formState: { errors }
	} = useFormContext<SpecimenFormType>();

	const currentSpecimenTypeId = watch('specimenTypeUuid');
	const { specimenType } = useSpecimenType(currentSpecimenTypeId);

	return (
		<Form className={styles.container} onSubmit={onSave} id={specimenCreateEditFormId}>
			{<input type="hidden" {...register('uuid')} />}
			{<input type="hidden" {...register('mode')} />}
			<div className={styles.fieldsLayout}>
				<Controller
					name="specimenId"
					control={control}
					render={({ field }) => <InputText label={t('Specimen ID*')} {...field} error={errors.specimenId?.message} />}
				/>
				<Controller
					name="specimenTypeUuid"
					control={control}
					render={({ field }) => (
						<Dropdown
							options={specimenTypes}
							label={t('Specimen type*')}
							{...field}
							disabled={isForDashboard}
							error={errors.specimenTypeUuid?.message}
						/>
					)}
				/>
				<Controller
					name="specimenMaterial"
					control={control}
					render={({ field }) => (
						<Dropdown
							options={materials}
							label={t('Specimen material')}
							{...field}
							error={errors.specimenMaterial?.message}
							editable
							itemName={t('Material')}
						/>
					)}
				/>
				<Controller
					name="testId"
					control={control}
					render={({ field }) => (
						<Dropdown
							options={tests}
							label={t('Test')}
							disabled={isForDashboard}
							{...field}
							error={errors.testId?.message}
						/>
					)}
				/>
			</div>
			{specimenType && <ImageUploader isReadonly onChange={() => {}} url={specimenType.imageUrl || DefaultImage} />}
			<div className={styles.fieldsLayout}>
				{dimensions.map((row) =>
					row.map(({ name, field, unit }) => (
						<GroupInputFrame>
							<Controller
								key={name}
								// @ts-ignore
								name={field}
								control={control}
								render={({ field }) => (
									<InputNumber
										{...field}
										value={field.value as number}
										label={`${name}*`}
										error={errors?.dimensions?.[name]?.message}
										data-testid="typeOffset"
									/>
								)}
							/>
							<InputText disabled value={unit} />
						</GroupInputFrame>
					))
				)}
			</div>

			{/*<div className="separatorLine"></div>*/}
			{/*<Controller*/}
			{/*	name={'quantity'}*/}
			{/*	control={control}*/}
			{/*	render={({ field }) => (*/}
			{/*		<InputNumber*/}
			{/*			{...field}*/}
			{/*			label={t('Quantity*')}*/}
			{/*			error={errors?.quantity?.message}*/}
			{/*			data-testid="quantity"*/}
			{/*			isInteger*/}
			{/*		/>*/}
			{/*	)}*/}
			{/*/>*/}
			{/*{quantity > 1 && (*/}
			{/*	<Controller*/}
			{/*		name={'batchId'}*/}
			{/*		control={control}*/}
			{/*		render={({ field }) => <InputText label={t('Batch ID*')} {...field} error={errors.batchId?.message} />}*/}
			{/*	/>*/}
			{/*)}*/}
		</Form>
	);
};

export default SpecimenFormThin;
