import React, { forwardRef, useMemo } from 'react';
import { KebabMenu } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import StageInfoBar from '../StageInfoBar';
import StageAxis from '../StageAxis';
import { StageActionTypes, StageAxisActionTypes, StageConfigurationActionTypes, StageTypes } from '../../tests.enums';
import { TestStageConfigs } from '../../tests.types';
import { TestAxesConfigurationFormType } from '../../../TestAxesConfiguration/testAxesConfiguration.type';
import styles from './StageCard.module.scss';
import { TestActionLocations } from '../../../TestActions';

interface StageCardProps {
	stage?: TestStageConfigs;
	onStageAxisAction: (type: StageAxisActionTypes, stageId: string, axis?: TestAxesConfigurationFormType) => void;
	onStageAction: (actionType: StageActionTypes, data?: TestStageConfigs) => void;
	onConfigureStageAction: (actionType: StageConfigurationActionTypes, stageType: StageTypes, stageId?: string) => void;
	axesIds: string[];
}

const StageCard = forwardRef<HTMLDivElement, StageCardProps>(
	({ stage, axesIds, onStageAction, onConfigureStageAction, onStageAxisAction }, ref) => {
		const { t } = useTranslation('test');

		const stageCardItems = useMemo(() => {
			return [
				{
					label: t('Reset'),
					command: () => onStageAction(StageActionTypes.RESET_STAGE, stage)
				},
				{
					label: t('Delete'),
					command: () => onStageAction(StageActionTypes.DELETE_STAGE, stage)
				},
				{
					label: t('Duplicate'),
					disabled: true,
					command: () => onStageAction(StageActionTypes.DUPLICATE_STAGE, stage)
				}
			];
		}, [onStageAction, stage, t]);

		const handleConfigureClick = (actionType: StageConfigurationActionTypes, stageType: StageTypes) => {
			switch (actionType) {
				case StageConfigurationActionTypes.CONFIGURE_LOGGING:
					onConfigureStageAction(actionType, stageType, stage?.id);
					break;
				case StageConfigurationActionTypes.CONFIGURE_ACTION:
					onConfigureStageAction(actionType, stageType, stage?.id);
					break;
				case StageConfigurationActionTypes.CONFIGURE_RULES:
					onConfigureStageAction(actionType, stageType, stage?.id);
					break;
			}
		};

		return (
			<div ref={ref}>
				<div className={styles.container}>
					<div className={styles.header}></div>
					<div className={styles.body}>
						<div className={styles.stageLabel}>
							<div>{stage?.name}</div>
							<KebabMenu model={stageCardItems} />
						</div>
						<div className={styles.buttonsContainer}>
							<label className={styles.stageTypeHeader}>{t(StageTypes[StageTypes.PRE])}</label>
							<label className={styles.stageTypeHeader}>{t(StageTypes[StageTypes.MAIN])}</label>
							<label className={styles.stageTypeHeader}>{t(StageTypes[StageTypes.POST])}</label>
						</div>
						<div className={styles.stageWrapper}>
							<div className={styles.infoBarContainer}>
								<StageInfoBar
									location={TestActionLocations.PRE_STAGE}
									actionsCount={stage?.preStageActions?.length}
									onAddAction={() =>
										handleConfigureClick(StageConfigurationActionTypes.CONFIGURE_ACTION, StageTypes.PRE)
									}
								/>
								<StageInfoBar
									variant="horizontal"
									location={TestActionLocations.STAGE}
									rulesCount={stage?.mainStageRules?.length}
									actionsCount={stage?.mainStageActions?.length}
									logsCount={stage?.logs?.length}
									onAddAction={() =>
										handleConfigureClick(StageConfigurationActionTypes.CONFIGURE_ACTION, StageTypes.MAIN)
									}
									onAddRule={() => handleConfigureClick(StageConfigurationActionTypes.CONFIGURE_RULES, StageTypes.MAIN)}
									onAddLog={() =>
										handleConfigureClick(StageConfigurationActionTypes.CONFIGURE_LOGGING, StageTypes.MAIN)
									}
								/>
								<StageInfoBar
									location={TestActionLocations.POST_STAGE}
									actionsCount={stage?.postStageActions?.length}
									onAddAction={() =>
										handleConfigureClick(StageConfigurationActionTypes.CONFIGURE_ACTION, StageTypes.POST)
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.axisWrapper}>
					{stage &&
						axesIds.map((id) => (
							<StageAxis
								key={id}
								axis={stage?.axesConfigs?.find((axis) => axis.axisId === id)}
								onStageAxisAction={(type, axisConfig) => {
									onStageAxisAction(type, stage?.id, axisConfig || ({ axisId: id } as TestAxesConfigurationFormType));
								}}
							/>
						))}
				</div>
			</div>
		);
	}
);

export default React.memo(StageCard);
