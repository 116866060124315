import React, { useCallback, useEffect, useMemo } from 'react';
import { Layout } from 'react-grid-layout';
import { ModalContextProvider } from '@tactun/ui';
import { useParams } from 'react-router-dom';
import WidgetContainer from './WidgetContainer';
import { widgetToGridLayoutItem } from '../dashboard.converters';
import { useDashboardColumnWidth, useDashboardWidgets } from '../dashboard.hooks';
import Grid from '../components/Grid/Grid';
import AddWidgetSidebar from '../components/AddWidgetSIdebar/AddWidgetSidebar';
import { widgetDefinitions } from '../dashboard.widgetDefinitions';
import { useConnectionStore } from '../../Connection/connection.store';
import { updateDataFlowStream } from '../dashboard.grpc';
import { LCProvider } from '../../../components/RealTimeLineGraph';
import SpecimenSelectionModal from '../../SpecimenSelectionModal';

const DashboardContainer: React.FC = () => {
	const { testId } = useParams();
	const { widgets, addWidget, updateWidget, updateWidgets, removeWidget } = useDashboardWidgets();
	const { connectedDevice } = useConnectionStore();
	const layout = useMemo(() => widgets.map((widget) => widgetToGridLayoutItem(widget)), [widgets]);
	const { columnWidth, gridRef } = useDashboardColumnWidth();

	const onLayoutChange = useCallback(
		(newLayout: Layout[]) => {
			updateWidgets((widgets) => {
				return widgets.map((widget) => {
					const id = widget.metadata.id;
					const layoutItem = newLayout.find(({ i }) => i === id);

					return layoutItem
						? {
								...widget,
								x: layoutItem.x,
								y: layoutItem.y,
								w: layoutItem.w,
								h: widget?.minimized ? widget.h : layoutItem.h
						  }
						: widget;
				});
			});
		},
		[updateWidgets]
	);

	// Open data flow stream when connection is established
	useEffect(() => {
		if (connectedDevice !== null) {
			updateDataFlowStream();
		}
	}, [connectedDevice]);

	return (
		<>
			<LCProvider>
				<Grid ref={gridRef} layout={layout} onLayoutChange={onLayoutChange}>
					{widgets.map((widget) => (
						<div key={widget.metadata.id}>
							<WidgetContainer
								id={widget.metadata.id}
								columnWidth={columnWidth}
								updateWidget={updateWidget}
								removeWidget={removeWidget}
								addWidget={addWidget}
								widget={widget}
							/>
						</div>
					))}
				</Grid>
			</LCProvider>
			<AddWidgetSidebar
				isTestWidgets={testId !== undefined}
				addWidget={addWidget}
				widgets={widgets}
				widgetTypes={widgetDefinitions}
				position="right"
			/>
			<ModalContextProvider>
				<SpecimenSelectionModal />
			</ModalContextProvider>
		</>
	);
};

export default React.memo(DashboardContainer);
