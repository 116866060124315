import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, DataTable, KebabMenu, PageLayout } from '@tactun/ui';
import { Tag } from 'primereact/tag';
import { CrudActions, TableFilters } from '../../../../components';
import { ActuatorCalibrationStatus, ActuatorCrudActionTypes } from '../../actuators.enums';
import { IActuatorListItem } from '../../actuators.types';
import { ITableColumns } from '../../../../types';
import { useTableConfigs } from '../../../../hooks';
import { useIsLocalDeployment } from '../../../../hooks/useIsLocalDeployment';
import styles from './ActuatorsTable.module.scss';

interface IActuatorTableProps {
	onAction: (type: ActuatorCrudActionTypes, data?: IActuatorListItem) => void;
	data?: IActuatorListItem[];
	isLoading: boolean;
	stationName: string;
}

const ActuatorsTable: FC<IActuatorTableProps> = ({ data, isLoading, onAction, stationName }) => {
	const { t } = useTranslation(['actuators', 'common']);
	const isDisabled = useIsLocalDeployment();

	const formattedData = useMemo(
		() => data?.map((item) => ({ ...item, type: t(item.type, { ns: 'common' }) })),
		[data, t]
	);

	const transColumns = useMemo<ITableColumns<IActuatorListItem>[]>(() => {
		return [
			{
				field: 'type',
				header: t('Actuator type')
			},
			{ field: 'stationChannelName', header: t('Channel') },
			{
				field: 'calibrationStatus',
				header: t('Calibration status'),
				body: (sensor: IActuatorListItem) => (
					<Tag
						className={
							sensor.calibrationStatus === ActuatorCalibrationStatus.CALIBRATED
								? styles.calibrated
								: styles.uncalibrated
						}
						severity="success"
						value={t(sensor.calibrationStatus)}
						rounded
					/>
				)
			}
		];
	}, [t]);

	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<IActuatorListItem>(transColumns, formattedData);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Duplicate'),
				icon: 't-icon-duplicate',
				disabled: true,
				command: () => onAction(ActuatorCrudActionTypes.DUPLICATE, data)
			},
			{
				label: t('Delete'),
				command: () => onAction(ActuatorCrudActionTypes.DELETE, data),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				disabled: isDisabled
			}
		],
		[isDisabled, onAction, t]
	);

	const handleCreate = useCallback(() => {
		onAction(ActuatorCrudActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={stationName}>
			<PageLayout.Header title={t('Actuators')}>
				<CrudActions isCreateDisabled={isDisabled} onCreate={handleCreate} entityLabel={t('Actuator')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={formattedData}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Actuator name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(ActuatorCrudActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) =>
						rowData.calibrationStatus !== ActuatorCalibrationStatus.NOT_APPLICABLE ? (
							<IconButton
								icon="calibrate"
								fontSize="1.2rem"
								title={t('Calibrate')}
								onClick={() => onAction(ActuatorCrudActionTypes.OPEN_CALIBRATION, rowData)}
							/>
						) : null
					}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default ActuatorsTable;
