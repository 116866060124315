import { InputNumber, Dropdown } from '@tactun/ui';
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormulaSpecimenParams, FormulaVariablesTypes } from '../../measurements.enums';
import { DataInFormType, MeasurementFormType } from '../../measurements.types';
import { IListItem } from '../../../../types';
import { useNumberEnumList } from '../../../../hooks';

interface IMeasurementVariableProps {
	variables: IListItem[];
	variableType: FormulaVariablesTypes | null;
	control: Control<MeasurementFormType, any>;
	errors: Merge<FieldError, FieldErrorsImpl<DataInFormType>> | undefined;
	objName: 'dataIn1' | 'dataIn2';
	isForTest?: boolean;
}

const MeasurementVariable: React.FC<IMeasurementVariableProps> = ({
	variableType,
	control,
	errors,
	variables,
	objName,
	isForTest
}) => {
	const { t } = useTranslation('measurements');
	const specimenParams = useNumberEnumList(FormulaSpecimenParams);

	switch (variableType) {
		case FormulaVariablesTypes.NUMERIC:
			return (
				<Controller
					name={`${objName}.data`}
					control={control}
					render={({ field }) => (
						<InputNumber
							{...field}
							data-testid="dataIn1Variable"
							label={t('Numeric value*')}
							error={errors?.data?.message}
							disabled={isForTest}
						/>
					)}
				/>
			);
		case FormulaVariablesTypes.VARIABLE:
			return (
				<Controller
					name={`${objName}.variableId`}
					control={control}
					render={({ field }) => (
						<Dropdown
							{...field}
							options={variables}
							label={t('Variable*')}
							error={errors?.variableId?.message}
							data-testid="dataIn1Variable"
							disabled={isForTest}
						/>
					)}
				/>
			);
		case FormulaVariablesTypes.SPECIMEN_PARAMETERS:
			return (
				<Controller
					name={`${objName}.specimenParamType`}
					control={control}
					render={({ field }) => (
						<Dropdown
							{...field}
							options={specimenParams}
							label={t('Specimen parameters*')}
							data-testid="dataIn1Variable"
							error={errors?.specimenParamType?.message}
							disabled={isForTest}
						/>
					)}
				/>
			);
		default:
			return <div />;
	}
};

export default MeasurementVariable;
