import {
	ControllerAddManuallyFormType,
	ControllerEditFormType,
	IDevice,
	IDiscoveryResultItem
} from './connection.types';

export const discoveryResultItemToDevice = (item: IDiscoveryResultItem): IDevice => {
	return {
		id: item.id,
		name: item.name,
		model: item.controller_model,
		isDHCPEnabled: item.dhcp_enabled,
		ip: item.ipv4_addr,
		subnet: item.subnet,
		gateway: item.gateway,
		mac: item.mac_addr.join(':'),
		sn: item.board_sn,
		isConnected: false
	};
};

export const deviceToControllerEditForm = (item: IDevice): ControllerEditFormType => {
	return {
		id: item.id as string | undefined,
		name: item.name,
		model: item.model,
		isDHCPEnabled: item.isDHCPEnabled,
		ip: item.ip,
		mask: item.subnet,
		gateway: item.gateway,
		sn: item.sn,
		isManuallyAdded: item.isManuallyAdded
	};
};

export const controllerEditFormTypeToDevice = (item: ControllerEditFormType): IDevice => {
	return {
		id: item.id || 1,
		name: item.name,
		model: item.model,
		mac: '',
		sn: item.sn || '',
		ip: item.ip,
		subnet: item.mask,
		gateway: item.gateway,
		isConnected: false,
		isDHCPEnabled: item.isDHCPEnabled ? item.isDHCPEnabled : false,
		isManuallyAdded: item.isManuallyAdded
	};
};

export const controllerAddManuallyFormTypeToDevice = (item: ControllerAddManuallyFormType, id: string): IDevice => {
	return {
		id: id,
		name: item.name,
		model: item.model,
		mac: '',
		sn: item.sn || '',
		ip: item.ip,
		subnet: item.mask,
		gateway: item.gateway,
		isConnected: false,
		isDHCPEnabled: false,
		isManuallyAdded: true
	};
};
