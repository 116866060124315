import axios from '../../httpCommon';
import { QuantityRequestDto, QuantityResponseDto, UnitResponse } from './units.types';

export const getQuantities = async (type?: 'TIME' | 'USER') => {
	let params = {};
	if (type) {
		params = { type };
	}
	const response = await axios.get('/quasar/quantities', { params });
	return response.data as QuantityResponseDto[];
};

export const isQuantityNameUnique = async (name: string, id?: string): Promise<boolean> => {
	const response = await axios.post('/quasar/quantities/isNameUnique', { id, name });
	return response.data;
};

export const isUnitNameUnique = async (name: string, id?: string, parentId?: string): Promise<boolean> => {
	const response = await axios.post('/quasar/units/isNameUnique', { id, name, parentId });
	return response.data;
};

export const getUnitsByIds = async (ids?: string[]): Promise<UnitResponse[]> => {
	return (await axios.post('/quasar/units/getByIds', ids)).data;
};

export const createQuantity = async (unit: QuantityRequestDto) => {
	const response = await axios.post('/quasar/quantities', unit);
	return response.data;
};

export const updateQuantity = async (unit: QuantityRequestDto, quantityId: string) => {
	const response = await axios.put(`/quasar/quantities/${quantityId}`, unit);
	return response.data;
};

export const getQuantity = async (id: string) => {
	const response = await axios.get(`/quasar/quantities/${id}`);
	return response.data as QuantityResponseDto;
};

export const getTimeQuantity = async (): Promise<QuantityResponseDto[]> => {
	const response = await axios.get('/quasar/quantities?type=TIME');
	return response.data;
};

export const getAvailableUnitSystemsTypes = async (): Promise<number[]> => {
	const response = await axios.get('/quasar/quantities/availableUnitSystemTypes');

	return response.data;
};
