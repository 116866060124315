export enum GraphActions {
	GRAPH_PAUSE,
	GRAPH_RESUME,
	GRAPH_ACTIVATE_CURSOR,
	GRAPH_ACTIVATE_MOVE = 'move',
	GRAPH_SNAPSHOT = 'snapshot',
	GRAPH_ACTIVATE_ZOOM_X = 'zoom_x',
	GRAPH_ACTIVATE_ZOOM_Y = 'zoom_y',
	GRAPH_ACTIVATE_ZOOM_XY = 'zoom_xy',
	GRAPH_ACTIVATE_ZOOM_RESET = 'zoom_reset',
	GRAPH_CLEAR = 'clear',
	GRAPH_REFRESH = 'refresh',
	GRAPH_RESTART = 'restart'
}
