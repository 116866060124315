import { useMemo } from 'react';

export const useCurrentEntityInOptions = <T>(options: T[], entity?: T) => {
	const resultOptions = useMemo<T[]>(() => {
		if (entity !== undefined) {
			return [entity, ...options];
		} else {
			return options;
		}
	}, [entity, options]);

	return resultOptions;
};
