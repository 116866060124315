import React from 'react';
import { Button, Dropdown, Form, GroupInputFrame, InputNumber, InputText, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { TestDataLoggingFormType } from '../../testDataLogging.types';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useEntityOptions, useNumberEnumList } from '../../../../hooks';
import { DataLoggingTypes, ExtremumTypes } from '../../testDataLogging.enums';
import DataChannel from '../../../DataChannel';
import { IAxisResponseDto } from '../../../Axes';
import { createEditDataLoggingModalId } from '../../testDataLogging.const';
import { IListItem } from '../../../../types';
import styles from './TestDataLoggingCreateEditModal.module.scss';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';

interface ITestDataLoggingCreateEditModalProps {
	form: UseFormReturn<TestDataLoggingFormType>;
	isCreate: boolean;
	isLoading: boolean;
	name?: string;
	measurements: TestMeasurementResponseDto[];
	axes: IAxisResponseDto[];
	timeUnits: IListItem[];
	onSave: () => void;
	onClose: () => void;
	onAfterClose: () => void;
}

const testDataLoggingId = 'testDataLoggingId';
const TestDataLoggingCreateEditModal: React.FC<ITestDataLoggingCreateEditModalProps> = ({
	form,
	isCreate,
	isLoading,
	name,
	measurements,
	axes,
	timeUnits,
	onSave,
	onClose,
	onAfterClose
}) => {
	const { t } = useTranslation('dataLoggings');
	const {
		register,
		control,
		formState: { errors },
		watch
	} = form;
	const types = useNumberEnumList(DataLoggingTypes);
	const extremumTypeOptions = useNumberEnumList(ExtremumTypes, 'dataLoggings');
	const dataLoggingType = watch('dataLoggingType');

	const axesOptions = useEntityOptions(axes);

	return (
		<Modal
			id={createEditDataLoggingModalId}
			className={styles.container}
			shouldCloseOnOverlayClick={false}
			onClose={onClose}
			onAfterClose={onAfterClose}
		>
			<Modal.Header>{isCreate ? t('Create a Data Logging') : t('Edit "{{name}}" Data Logging', { name })}</Modal.Header>
			<Modal.Content>
				<Form className={styles.formContainer} onSubmit={onSave} id={testDataLoggingId}>
					<input type="hidden" {...register('id')} />
					<input type="hidden" {...register('stageId')} />
					<input type="hidden" {...register('testId')} />
					<input type="hidden" {...register('stageType')} />
					<Controller
						name="name"
						control={control}
						render={({ field }) => (
							<InputText
								data-testid="nameId"
								className={styles.input}
								label={t('Data logging name*')}
								{...field}
								error={errors.name?.message}
							/>
						)}
					/>
					<Controller
						name="dataChannels"
						control={control}
						render={({ field }) => (
							<DataChannel
								{...field}
								onChange={(data) => field.onChange(data)}
								value={field.value}
								measurements={measurements}
								axes={axes}
								removeTime
								isMultiply
								label={t('Data channel*')}
								data-testid="dataChannels"
								error={errors.dataChannels?.message}
								className={styles.input}
							/>
						)}
					/>
					<Controller
						name="dataLoggingType"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								className={styles.input}
								options={types}
								label={t('Data logging type*')}
								data-testid="dataLoggingTypeId"
								error={errors.dataLoggingType?.message}
							/>
						)}
					/>
					{(dataLoggingType === DataLoggingTypes.TIMED || dataLoggingType === DataLoggingTypes.PERIODIC) && (
						<GroupInputFrame>
							<Controller
								name="rate"
								control={control}
								render={({ field }) => (
									<InputNumber
										{...field}
										className={styles.input}
										label={t('Rate*')}
										error={errors.rate?.message}
										data-testid="rateId"
									/>
								)}
							/>
							<InputText disabled value={'S/sec'} />
						</GroupInputFrame>
					)}
					{dataLoggingType === DataLoggingTypes.PERIODIC && (
						<div className={styles.dataLoggingType}>
							<div>
								<GroupInputFrame>
									<Controller
										name="interval"
										control={control}
										render={({ field }) => (
											<InputNumber
												{...field}
												label={t('Interval*')}
												error={errors.interval?.message}
												data-testid="intervalId"
											/>
										)}
									/>
									<Controller
										name="intervalUnitId"
										control={control}
										render={({ field }) => (
											<Dropdown
												{...field}
												options={timeUnits}
												data-testid="intervalUnitId"
												error={errors.intervalUnitId?.message}
											/>
										)}
									/>
								</GroupInputFrame>
							</div>
							<div>
								<GroupInputFrame>
									<Controller
										name="dur"
										control={control}
										render={({ field }) => (
											<InputNumber
												{...field}
												label={t('Duration*')}
												error={errors.dur?.message}
												data-testid="durationId"
											/>
										)}
									/>
									<Controller
										name="durationUnitId"
										control={control}
										render={({ field }) => (
											<Dropdown
												{...field}
												options={timeUnits}
												data-testid="durationUnitId"
												error={errors.durationUnitId?.message}
											/>
										)}
									/>
								</GroupInputFrame>
							</div>
						</div>
					)}
					{dataLoggingType === DataLoggingTypes.PEAK_VALLEY && (
						<>
							<Controller
								name="extremumTypes"
								control={control}
								render={({ field }) => (
									<Dropdown
										{...field}
										className={styles.input}
										options={extremumTypeOptions}
										label={t('Extrema*')}
										data-testid="extremumTypes"
										error={errors.extremumTypes?.message}
									/>
								)}
							/>
							<Controller
								name="refAxisId"
								control={control}
								render={({ field }) => (
									<Dropdown
										{...field}
										className={styles.input}
										options={axesOptions}
										label={t('Reference Axis*')}
										data-testid="refAxisId"
										error={errors.refAxisId?.message}
									/>
								)}
							/>
						</>
					)}
				</Form>
			</Modal.Content>
			<Modal.Footer>
				<Button onClick={onClose} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button onClick={onSave} color="secondary" variant="contained" loading={isLoading}>
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(TestDataLoggingCreateEditModal);
