import React, { useId, useMemo } from 'react';
import { Icon } from '@tactun/ui';
import cx from 'classnames';
import { Tooltip } from 'primereact/tooltip';

import styles from './StageInfoBar.module.scss';

interface InfoIconProps {
	icon: string;
	className?: string;
	tooltip?: string;
	'data-testid'?: string;
	onClick?: () => void;
}

const InfoIcon: React.FC<InfoIconProps> = (props) => {
	const { icon, className, tooltip, onClick } = props;
	const id = useId();
	const fixedId = useMemo(() => id.replaceAll(':', ''), [id]);
	return (
		<>
			{tooltip && (
				<Tooltip target={`#${fixedId}`} autoHide={true}>
					{tooltip}
				</Tooltip>
			)}
			<Icon
				name={icon}
				onClick={onClick}
				id={fixedId}
				data-testid={props['data-testid']}
				className={cx(icon, styles.icon, className)}
			/>
		</>
	);
};

export default React.memo(InfoIcon);
