import { boolean, object, string } from 'yup';
import { ipRegex } from '../../constants/regexes';

export const controllerConfigureNetworkSchema = object({
	sn: string().optional(),
	ip: string().when(['isDHCPEnabled'], {
		is: true,
		then: (schema) => schema.required('IP is required field').matches(ipRegex, 'IP is not in correct format'),
		otherwise: (schema) => schema.optional()
	}),
	mask: string().when(['isDHCPEnabled'], {
		is: true,
		then: (schema) => schema.required('Mask is required field').matches(ipRegex, 'IP is not in correct format'),
		otherwise: (schema) => schema.optional()
	}),
	gateway: string().when(['isDHCPEnabled'], {
		is: true,
		then: (schema) => schema.required('Mask is required field').matches(ipRegex, 'IP is not in correct format'),
		otherwise: (schema) => schema.optional()
	}),
	isDHCPEnabled: boolean().optional(),
	isManuallyAdded: boolean().optional()
});

export const controllerEditSchema = object({
	id: string().optional(),
	name: string().required(),
	model: string().required(),
	sn: string().optional(),
	ip: string().required().matches(ipRegex, 'IP is not in correct format'),
	mask: string().required().matches(ipRegex, 'IP is not in correct format'),
	gateway: string().required().matches(ipRegex, 'IP is not in correct format'),
	isDHCPEnabled: boolean(),
	isManuallyAdded: boolean()
});

export const controllerAddManuallySchema = object({
	name: string().required(),
	model: string().required(),
	sn: string().optional(),
	ip: string().required().matches(ipRegex, 'IP is not in correct format'),
	mask: string().required().matches(ipRegex, 'IP is not in correct format'),
	gateway: string().required().matches(ipRegex, 'IP is not in correct format')
});
