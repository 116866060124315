import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import React, { useCallback } from 'react';
import styles from './Stepper.module.scss';
import { Icon } from '@tactun/ui';

export enum StepStatuses {
	EMPTY = 'EMPTY',
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETED = 'COMPLETED',
	WARNING = 'WARNING'
}

export interface IStepProps {
	label: React.ReactNode;
	path: string;
	status?: StepStatuses;
	disabled?: boolean;
}

export interface IStepperProps {
	children?: React.ReactNode;
}

export interface IStepperSubComponents {
	Step: React.FC<IStepProps>;
}

const StepComp: React.FC<IStepProps> = ({ label, path, disabled = false, status = StepStatuses.EMPTY }) => {
	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			if (disabled) {
				e.preventDefault();
			}
		},
		[disabled]
	);

	return (
		<NavLink
			to={path}
			end
			className={({ isActive }) => cx(styles.step, { [styles.activeStep]: isActive })}
			onClick={handleClick}
			style={{ pointerEvents: disabled ? 'none' : 'auto' }}
		>
			{/* <span
				className={cx(styles.icon, {
					[styles.full]: status === StepStatuses.COMPLETED,
					[styles.inProgress]: status === StepStatuses.IN_PROGRESS
				})}
			>
				{status === StepStatuses.COMPLETED && <i className="pi pi-check"></i>}
			</span> */}
			<span
				className={cx(styles.icon, {
					[styles.full]: status === StepStatuses.COMPLETED,
					[styles.inProgress]: status === StepStatuses.IN_PROGRESS
				})}
			>
				{status === StepStatuses.COMPLETED && <Icon name="Subtract-6" size={24}></Icon>}
			</span>
			<span className={styles.label}>{label}</span>
		</NavLink>
	);
};

const Stepper: React.FC<IStepperProps> & IStepperSubComponents = ({ children }) => {
	return <div className={styles.container}>{children}</div>;
};

Stepper.Step = React.memo(StepComp);

export default Stepper;
