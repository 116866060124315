import { Params } from 'react-router';

export const normalizePath = (path: string, params: Readonly<Params<string>>) => {
	let updatedPath = path;
	Object.entries(params).forEach(([param, val]) => {
		if (param && val) {
			updatedPath = updatedPath.replace(`:${param}`, val);
		}
	});

	return updatedPath;
};
