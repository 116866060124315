import { number, object, string, mixed, boolean, array } from 'yup';
import { emptyStringToNull } from '../../tools/yupHelpers';
import { CalibrationsTypes } from './calibrations.enums';

export const calibrationInputSchema = object({
	isRealTime: boolean().required(),
	uncalibratedData: number().nullable().transform(emptyStringToNull).required(),
	calibratedData: number().transform(emptyStringToNull).required('Calibrated data is a required field')
});

export const calibrationFormSchema = object({
	id: string().optional(),
	type: mixed<CalibrationsTypes>().required(),
	sensorId: string().required('Sensor is a required field'),
	data: array().when(['type'], {
		is: (type: CalibrationsTypes) => type === CalibrationsTypes.PIECEWISE,
		then: (schema) =>
			schema
				.min(2, 'At least 2 points are required for the Piecewise calibration')
				.required('At least 2 points are required for the Piecewise calibration')
	}),
	offset: number()
		.transform(emptyStringToNull)
		.when(['type'], {
			is: (type: CalibrationsTypes) => type === CalibrationsTypes.LINEAR,
			then: (schema) => schema.required('Offset is a required field')
		}),
	slope: number()
		.transform(emptyStringToNull)
		.when(['type'], {
			is: (type: CalibrationsTypes) => type === CalibrationsTypes.LINEAR,
			then: (schema) => schema.required('Sensitivity is a required field')
		}),
	tedsId: string().when(['type'], {
		is: (type: CalibrationsTypes) => type === CalibrationsTypes.PIECEWISE,
		then: (schema) => schema.max(30, 'Maximum allowed symbols are 30')
	}),
	isShuntCalibrationEnabled: boolean(),
	isManualTedsIdEnabled: boolean(),
	isInversePolarity: boolean().optional()
}).required();
