import React, { useCallback, useMemo, useState } from 'react';
import {
	PageLayout,
	Form,
	Dropdown,
	Button,
	BottomBar,
	InfoBadge,
	InfoBadgeIcons,
	DataTable,
	LineChart,
	LineChartPoint,
	DropdownOptionString,
	Modal
} from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller, useFieldArray, useForm } from 'react-hook-form';
import { IActuatorCalibrationForm, IActuatorCalibrationMeta, IActuatorCalibrationTuple } from '../../actuators.types';
import { QuantityResponseDto } from '../../../Units';
import ActuatorCalibrationInput from './ActuatorCalibrationInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { actuatorCalibrationInputSchema } from '../../actuators.schemas';
import styles from './ActuatorCalibrationForm.module.scss';

const defaultCalibrationInputValues: Partial<IActuatorCalibrationTuple> = {
	usd: undefined,
	csd: undefined
};

interface ActuatorCalibrationFormProps {
	isCreate: boolean;
	isLoading: boolean;
	form: UseFormReturn<IActuatorCalibrationForm>;
	actuatorCalibrationMeta: IActuatorCalibrationMeta | null;
	actuatorName?: string;
	onBack: () => void;
	onSave: () => void;
	quantities: QuantityResponseDto[];
	stationName: string;
}

const actuatorCalibrationFormId = 'actuatorCalibrationFormId';

const ActuatorCalibrationForm: React.FC<ActuatorCalibrationFormProps> = ({
	isCreate,
	stationName,
	quantities,
	isLoading,
	actuatorName,
	form,
	actuatorCalibrationMeta,
	onBack,
	onSave
}) => {
	const { t } = useTranslation('actuators');
	const [errorMessage, setErrorMessage] = useState('');
	const {
		control,
		formState: { errors },
		watch,
		trigger,
		setValue,
		register
	} = form;
	const { fields } = useFieldArray({ name: 'calibrationData', keyName: '', control });

	const inputForm = useForm<IActuatorCalibrationTuple>({
		defaultValues: {
			...defaultCalibrationInputValues,
			lowerLimit:
				actuatorCalibrationMeta?.lowerLimit !== undefined
					? actuatorCalibrationMeta?.lowerLimit
					: Number.MIN_SAFE_INTEGER,
			upperLimit:
				actuatorCalibrationMeta?.upperLimit !== undefined
					? actuatorCalibrationMeta?.upperLimit
					: Number.MAX_SAFE_INTEGER
		},
		mode: 'onBlur',
		resolver: yupResolver(actuatorCalibrationInputSchema)
	});

	const calibrationData = watch('calibrationData');

	const title = useMemo<string>(() => {
		return isCreate ? t(`Create ${actuatorName} Calibration`) : t(`Edit ${actuatorName} Calibration`);
	}, [isCreate, actuatorName, t]);

	const quantitiesOptions = useMemo<DropdownOptionString[]>(() => {
		return quantities.map((quantity) => ({
			label: quantity.name,
			options: quantity.units.map((unit) => ({ label: unit.name, value: unit.id }))
		}));
	}, [quantities]);

	const chartDataset = useMemo<LineChartPoint[]>(() => {
		const unsortedData = fields.map((field) => ({
			x: field.usd,
			y: field.csd
		}));

		const sortedData = unsortedData.sort((left, right) => (left.x < right.x ? -1 : 1));

		return sortedData;
	}, [fields]);

	const setError = useCallback(
		(error: 'is_equal' | 'not_monotonic') => {
			if (error === 'is_equal') {
				setErrorMessage(t('Error. One of the values already exists') || '');
			} else if (error === 'not_monotonic') {
				setErrorMessage(t('The entered data pair breaks the monotony of the existing pairs') || '');
			}
		},
		[t, setErrorMessage]
	);
	const toggleModal = () => {
		setErrorMessage('');
	};

	const onSubmitNewPoint = inputForm.handleSubmit((newPoint) => {
		addPoint(newPoint);
	});

	const resetInput = useCallback(() => {
		inputForm.reset(defaultCalibrationInputValues, { keepValues: true });
	}, [inputForm]);

	const addPoint = useCallback(
		(newPoint: IActuatorCalibrationTuple) => {
			const newPoints = [...calibrationData, newPoint];

			const uncalibratedData = new Set(newPoints.map(({ usd }) => usd));
			const calibratedData = new Set(newPoints.map(({ csd }) => csd));
			if (uncalibratedData.size !== newPoints.length || calibratedData.size !== newPoints.length) {
				return setError('is_equal');
			}

			newPoints.sort((a, b) => a.usd - b.usd);
			const isAscending = newPoints.every(({ csd }, i) => i === 0 || csd > newPoints[i - 1].csd);
			const isDescending = newPoints.every(({ csd }, i) => i === 0 || csd < newPoints[i - 1].csd);
			if (!isAscending && !isDescending) {
				return setError('not_monotonic');
			}

			setValue('calibrationData', newPoints);
			if (newPoints.length > 1) trigger('calibrationData');
			resetInput();
		},
		[calibrationData, setValue, setError, resetInput, trigger]
	);

	const deleteAll = useCallback(() => {
		setValue('calibrationData', []);
	}, [setValue]);

	return (
		<>
			<PageLayout info={stationName}>
				<PageLayout.Header title={title} onBack={onBack} />
				<Form className={styles.container} id={actuatorCalibrationFormId} onSubmit={onSave}>
					<input type="hidden" {...register('actuatorId')} />
					<input type="hidden" {...register('type')} />
					<Controller
						name="unitId"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={quantitiesOptions}
								label={t('Unit*')}
								error={errors.unitId?.message}
								data-testid="unit"
							/>
						)}
					/>
					<div />

					<ActuatorCalibrationInput
						onSubmit={onSubmitNewPoint}
						form={inputForm}
						controlValueUnit={actuatorCalibrationMeta?.unit}
					/>

					<div className="mainContainer">
						<div className={styles.tableContainer}>
							<DataTable dataKey="id" value={fields} showGridlines columnResizeMode="fit">
								<DataTable.Column field="usd" header={t('Control value')} />
								<DataTable.Column field="csd" header={t('Calibrated value')} />
								<DataTable.Column
									body={(_rowData, { rowIndex }) => (
										<Button
											icon="t-icon-delete"
											color="secondary"
											type="button"
											variant="text"
											onClick={() => {
												setValue('calibrationData', [...fields.slice(0, rowIndex), ...fields.slice(rowIndex + 1)]);
											}}
										/>
									)}
									exportable={false}
									align="right"
									headerStyle={{ width: '3em' }}
								/>
							</DataTable>
							{errors?.calibrationData && <span>{errors?.calibrationData?.message}</span>}
							<div className={styles.deleteAll} onClick={deleteAll}>
								{t('Delete All')}
							</div>
						</div>

						<div className={styles.calibrationChart}>
							<LineChart
								xLabel={t('Control value')}
								yLabel={t('Calibrated value')}
								dataset={chartDataset}
								data-testid="chart"
							/>
						</div>
					</div>
				</Form>
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button label={t('Cancel')} data-testid="cancelBtn" variant="contained" color="success" onClick={onBack} />
				<Button
					label={t('Save')}
					data-testid="saveBtn"
					variant="contained"
					color="secondary"
					type="submit"
					form={actuatorCalibrationFormId}
				/>
			</BottomBar>
			<Modal isOpen={!!errorMessage} onClose={toggleModal} id="my-modal">
				<Modal.Header>{t('Error')}</Modal.Header>
				<Modal.Content>{errorMessage}</Modal.Content>
				<Modal.Footer>
					<Button onClick={toggleModal} color="secondary" variant="contained">
						{t('Ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default React.memo(ActuatorCalibrationForm);
