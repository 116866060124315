import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Products from '../components/Products';
import { ProductFormType, productFormSchema, productResponseToForm, useProduct, useSaveProduct } from '../';
import {
	connectDeviceConfirmModalId,
	linkConfirmModalId,
	productDefaultValues,
	productFormId,
	unlinkConfirmModalId
} from '../products.constants';
import { useEntitiesAsList, useNumberEnumList } from '../../../hooks';
import CreateEditPageTemplate from '../../../components/CreateEditPageTemplate';
import { ProductPlatforms, ProductStatuses } from '../products.enums';
import { ConfirmationModal } from '../../../components';
import { useStationsByControllerModel } from '../../Stations';
import { useConnectionStore } from '../../Connection/connection.store';

const ProductsContainer: React.FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('products');
	const { productId, stationId } = useParams();
	const { productDto, isLoading } = useProduct(productId);
	const { connectedDevice } = useConnectionStore();
	const { stations } = useStationsByControllerModel(connectedDevice?.model);
	const form = useForm({
		defaultValues: {
			id: productId,
			...productDefaultValues,
			platform: ProductPlatforms.WINDOWS,
			stationId: stationId ? stationId : ''
		},
		mode: 'onBlur',
		resolver: yupResolver(productFormSchema)
	});
	const { handleSubmit, reset, setValue } = form;

	const handleSaveSuccess = useCallback(() => {
		navigate('/products');
	}, [navigate]);

	const { save, isLoading: isSaving } = useSaveProduct(productId, handleSaveSuccess);

	const platforms = useNumberEnumList(ProductPlatforms);
	const stationsOptions = useEntitiesAsList(stations);

	const title = useMemo<string>(() => {
		return !productId ? t('Create Product') : t('Edit Product');
	}, [productId, t]);

	const selectedStationId = useWatch({ name: 'stationId', control: form.control });
	const selectedStation = useMemo(
		() => stations?.find((s) => s.id === selectedStationId),
		[selectedStationId, stations]
	);

	useEffect(() => {
		if (productDto) {
			reset({ ...productResponseToForm(productDto) });
		} else {
			reset({ ...productDefaultValues, stationId: stationId ? stationId : '', platform: ProductPlatforms.WINDOWS });
		}
	}, [productId, productDto, reset, stationId]);

	useEffect(() => {
		if (connectedDevice?.model) {
			setValue('model', connectedDevice.model);
		}
		if (connectedDevice?.sn) {
			setValue('controllerSerialNumber', connectedDevice.sn);
		}
	}, [setValue, connectedDevice]);

	useEffect(() => {
		if (productDto?.readinessStatus === ProductStatuses.READY_FOR_DOWNLOAD) {
			ConfirmationModal.show(unlinkConfirmModalId);
		}
	}, [productDto, reset]);

	useEffect(() => {
		if (productId === undefined && connectedDevice === null) {
			ConfirmationModal.show(connectDeviceConfirmModalId);
		}
	}, [connectedDevice, productId]);

	const handleConnectConfirmation = useCallback(() => {
		ConfirmationModal.hide(unlinkConfirmModalId);
		navigate('/products');
	}, [navigate]);

	const handleSave = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			if (productId === undefined) {
				ConfirmationModal.show(linkConfirmModalId);
			} else {
				handleSubmit((data: ProductFormType) => save(data))();
			}
		},
		[handleSubmit, productId, save]
	);

	const handleSaveConfirmed = handleSubmit((data: ProductFormType) => save(data));

	return (
		<CreateEditPageTemplate
			isLoading={isLoading || isSaving}
			title={title}
			stationName=""
			formId={productFormId}
			onBack={handleSaveSuccess}
		>
			<Products
				form={form}
				platforms={platforms}
				stations={stationsOptions}
				formId={productFormId}
				onSave={handleSave}
				isStationChangeDisabled={productId !== undefined || !!stationId}
			/>
			<ConfirmationModal
				title={t('Warning')}
				confirmBtnText={t('Ok')}
				id={connectDeviceConfirmModalId}
				onConfirm={handleConnectConfirmation}
				handleClose={handleConnectConfirmation}
			>
				{t('Controller not detected. Please connect a controller.')}
			</ConfirmationModal>
			<ConfirmationModal
				title={t('Warning')}
				confirmBtnText={t('Ok')}
				id={linkConfirmModalId}
				onConfirm={handleSaveConfirmed}
				isLoading={isSaving}
			>
				{t(
					'The station {{stationName}} will be configured on the controller {{controllerModel}} and will be linked together exclusively.',
					{ stationName: selectedStation?.name, controllerModel: connectedDevice?.name }
				)}
			</ConfirmationModal>
		</CreateEditPageTemplate>
	);
};

export default React.memo(ProductsContainer);
