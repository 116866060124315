import { FC } from 'react';
import styles from './TestLoopLine.module.scss';

export interface LineItemProps {
	id: string;
	startPosition: number;
	width: number;
	count: number;
}

const LineItem: FC<LineItemProps> = ({ startPosition, width, count }) => {
	return (
		<div style={{ width, left: startPosition }} className={styles.item}>
			<div className={styles.itemCont} data-count={count} />
		</div>
	);
};

export default LineItem;
