import React from 'react';
import { memo } from 'react';
import styles from './GroupInputFrame.module.scss';

export interface IGroupInputFrameProps {
	children: React.ReactNode;
}

const GroupInputFrame: React.FC<IGroupInputFrameProps> = ({ children }) => {
	return <div className={styles.container}>{children}</div>;
};

export default Object.assign(memo(GroupInputFrame), { displayName: 'GroupInputFrame' });
