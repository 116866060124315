import { useMemo, useRef } from 'react';
import { PageLayout, Form, Switch, Dropdown } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import styles from './FeaturesetGeneral.module.scss';

interface AxesProps {
	isCreate: boolean;
	isLoading: boolean;
	form: UseFormReturn<Record<string, string>>;
	onBack: () => void;
	onSave: () => void;
}

const FeaturesetGeneral: React.FC<AxesProps> = ({ isCreate, form }) => {
	const { t } = useTranslation('axes');
	const formId = useRef('axesForm');
	const { control, register } = form;

	const title = useMemo<string>(() => {
		return isCreate ? t('Create a Feature-Set') : t('Edit a Feature-Set');
	}, [isCreate, t]);

	return (
		<>
			<PageLayout info={title}>
				<PageLayout.Header title={t('General')} />
				<Form className={styles.container} id={formId.current}>
					<input type="hidden" {...register('stationId')} />
					<div>Feature Set Name</div>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<div></div>
					<div>Users and Roles Management</div>
					<Controller
						name="type1"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Languages</div>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<div>Variable Types</div>
					<Controller
						name="type2"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
				</Form>
			</PageLayout>
		</>
	);
};

export default FeaturesetGeneral;
