import { FC } from 'react';
import { useParams } from 'react-router-dom';
import TestDashboardConfiguration from '../components/TestDashboardConfiguration';

const TestDashboardConfigurationContainer: FC = () => {
	const { testId } = useParams();
	return <TestDashboardConfiguration testId={testId as string} />;
};

export default TestDashboardConfigurationContainer;
