import * as yup from 'yup';
import * as api from './stationRules.api';
import { ruleBaseSchema, RuleType } from '../Rules';
import { alphaNumericRegex } from '../../constants/regexes';

export const stationRuleSchema = ruleBaseSchema.shape({
	name: yup
		.string()
		.required()
		.max(30)
		.matches(alphaNumericRegex, 'Do not use special symbols')
		.test('checkStationRuleUniqueness', 'Name is used', function (value, form) {
			if (!value || form?.parent?.type === undefined || form?.parent?.type === null) return true;
			return api.checkName(value, form?.parent.stationId, form.parent.type, form.parent.id);
		}),
	type: yup.mixed<RuleType>().required('Rule type is required'),
	stationId: yup.string().required('Id is required')
});
