import { FC, useCallback, useRef, useState } from 'react';
import { Button } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import styles from './ImageUploader.module.scss';
import DefaultImage from '../../assets/defaultImage.svg';
import DefaultImageActive from '../../assets/defaultImageActive.svg';

interface IImageUploaderProps {
	isMultiple?: boolean;
	isReadonly?: boolean;
	className?: string;
	url?: string;
	onDelete: () => void;
	onChange: (file: File) => void;
}

const ImageUploader: FC<IImageUploaderProps> = ({
	isMultiple = false,
	isReadonly = false,
	onDelete,
	className,
	url,
	onChange
}) => {
	const { t } = useTranslation('imageUploader');
	const [splashImage, setSplashImage] = useState<string>(DefaultImage);
	const [previewImg, setPreviewImg] = useState<string | undefined>();
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleChange = useCallback(() => {
		if (fileInputRef?.current?.files) {
			const url = URL.createObjectURL(fileInputRef.current.files[0]);
			onChange(fileInputRef.current.files[0]);
			setPreviewImg(url);
		}
	}, [onChange]);

	const handleChangeClick = useCallback(() => {
		if (fileInputRef?.current) {
			fileInputRef?.current.click();
		}
	}, []);

	const handleDelete = useCallback(() => {
		setPreviewImg(undefined);
		if (fileInputRef.current?.files) {
			fileInputRef.current.files = null;
		}
		onDelete();
	}, [onDelete]);

	const handleDragOver = useCallback(() => {
		setSplashImage(DefaultImageActive);
	}, [setSplashImage]);

	const handleDragEnd = useCallback(() => {
		setSplashImage(DefaultImage);
	}, [setSplashImage]);

	return (
		<div className={cx(styles.container, className)}>
			<label className="label">{t('Specimen type image')}</label>
			<div className={styles.box}>
				{(previewImg || url) && <img src={previewImg || url} alt="uploadedImg" className={styles.previewImg} />}
				<div className={cx(styles.fileDropArea, { [styles.hide]: !!(previewImg || url) })}>
					<input
						className={styles.fileDropInput}
						type="file"
						name="files[]"
						id="file"
						readOnly={isReadonly}
						onDragOver={handleDragOver}
						onDragLeave={handleDragEnd}
						onDropCapture={handleDragEnd}
						onChange={handleChange}
						value={undefined}
						ref={fileInputRef}
						multiple={isMultiple}
					/>
					<div className={styles.splash}>
						<img src={splashImage} alt="img" />
						{!isReadonly && (
							<label htmlFor="file" className="dragLabel">
								{t('Drag and drop an image here or ')} <span>{t('upload a file')}</span>
							</label>
						)}
					</div>
				</div>
			</div>
			{!isReadonly && (
				<div className={styles.buttonsCont}>
					<Button label={t('Change image')} variant="text" color="secondary" onClick={handleChangeClick} />
					<Button label={t('Delete')} variant="text" color="danger" onClick={handleDelete} />
				</div>
			)}
		</div>
	);
};

export default ImageUploader;
