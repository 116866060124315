import { useMutation, useMutationState, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { CalibrationRequestDto, CalibrationResponseDto } from '.';
import * as api from './calibrations.api';
import { toast } from 'react-toastify';

export type UseCalibrationsParams = {
	stationId?: string;
};

export const useCalibrations = ({ stationId }: UseCalibrationsParams) => {
	const { data: calibrations, isLoading: isCalibrationsLoading } = useQuery({
		queryKey: ['calibrations', { stationId }],
		// When we provide enable option - !!calibrationId, the calibrationId is guaranteed
		queryFn: () => api.getCalibrationsForStation(stationId as string),
		enabled: !!stationId
	});

	const queryClient = useQueryClient();

	const updateCalibrations = () => {
		queryClient.invalidateQueries({ queryKey: ['calibrations', { stationId }] });
	};

	return {
		calibrations,
		isCalibrationsLoading,
		updateCalibrations
	};
};

export const useCalibration = (sensorId?: string) => {
	const queryKey = useMemo(() => ['calibration', { sensorId }], [sensorId]);

	const { data: calibrationDto, isLoading: isCalibrationLoading } = useQuery<CalibrationResponseDto | null>({
		queryKey,
		// When we provide enable option - !!calibrationId, the calibrationId is guaranteed
		queryFn: () => api.getSensorCalibration(sensorId as string),
		enabled: !!sensorId
	});

	const queryClient = useQueryClient();

	const updateCalibration = useCallback(() => {
		queryClient.invalidateQueries({ queryKey });
	}, [queryClient, queryKey]);

	return {
		calibrationDto,
		isCalibrationLoading,
		updateCalibration
	};
};

export const useIsSavingCalibration = (sensorId?: string) => {
	const updateStatuses = useMutationState({
		filters: { mutationKey: ['updateCalibration', sensorId] },
		select: (mutation) => mutation.state.status === 'pending'
	});
	const createStatuses = useMutationState({
		filters: { mutationKey: ['createCalibration', sensorId] },
		select: (mutation) => mutation.state.status === 'pending'
	});

	return updateStatuses[updateStatuses.length - 1] || createStatuses[createStatuses.length - 1];
};

export const useSaveCalibration = (sensorId?: string, onSaveSuccess?: () => void) => {
	const createMutation = useMutation({
		mutationKey: ['createCalibration', sensorId],
		mutationFn: (calibration: CalibrationRequestDto) => api.createCalibration(calibration),
		onSuccess: () => {
			if (onSaveSuccess) onSaveSuccess();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationKey: ['updateCalibration', sensorId],
		mutationFn: (data: { calibration: CalibrationRequestDto; calibrationId: string }) =>
			api.updateCalibration(data.calibration, data.calibrationId),
		onSuccess: () => {
			if (onSaveSuccess) onSaveSuccess();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const save = useCallback(
		(data: CalibrationRequestDto, id?: string) => {
			if (id) {
				updateMutation.mutate({
					calibration: data,
					calibrationId: id
				});
			} else {
				createMutation.mutate(data);
			}
		},
		[createMutation, updateMutation]
	);

	return {
		save,
		isLoading: createMutation.isPending || updateMutation.isPending
	};
};
