import * as yup from 'yup';
import { ruleBaseSchema } from '../Rules/rules.schemas';
import { alphaNumericRegex } from '../../constants/regexes';
import { RuleListItem } from '../Rules';

export const testSequencerRuleSchema = ruleBaseSchema.shape({
	name: yup
		.string()
		.required()
		.max(30)
		.matches(alphaNumericRegex, 'Do not use special symbols')
		.test('checkTestRuleUniqueness', 'Name is used', function (value, form) {
			if (!value) return true;
			const { items = [] } = form.options.context || {};

			return !items.find((action: RuleListItem) => action.name === value && action.id !== form?.parent?.id);
		})
});
