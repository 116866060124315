import { Dropdown, Form, InputNumber, InputText, Switch } from '@tactun/ui';
import { FC, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TestReportCalculationFormType, TestReportCalculationEditFormType } from '../../testReportConfiguration.types';
import { PrecisionType, ReportResultTypeForm } from '../../testReportConfiguration.enums';
import { useNumberEnumList } from '../../../../hooks';
import styles from './CalculationReportConfigurationTable.module.scss';

interface CalculationEditFormProps {
	form: UseFormReturn<TestReportCalculationEditFormType>;
	onSubmit: (index: number, data: TestReportCalculationFormType) => void;
}

const CalculationEditForm: FC<CalculationEditFormProps> = ({ form, onSubmit }) => {
	const { t } = useTranslation('testConfiguration');
	const {
		register,
		control,
		watch,
		handleSubmit,
		formState: { errors }
	} = form;

	const precisionType = watch('precisionType');
	const precisionTypeOptions = useNumberEnumList(PrecisionType);

	const precisionNumberLabel = useMemo(() => {
		if (precisionType === PrecisionType.SIGNIFICANT_DIGITS) {
			return t('Significant digits');
		} else {
			return t('Decimal places');
		}
	}, [t, precisionType]);

	const onSave = handleSubmit((data) => {
		const { index, ...calculationConfig } = data;
		onSubmit(index, calculationConfig);
	});

	return (
		<Form id={ReportResultTypeForm.CALCULATION_RESULT} onSubmit={onSave} className={styles.container}>
			<div className={styles.calcultionName}>
				<InputText label={t('Calculation name')} {...register('name')} disabled />
			</div>
			<Controller
				name="precisionType"
				control={control}
				render={({ field }) => (
					<Dropdown
						panelClassName={styles.dropdown}
						{...field}
						label={t('Precision Type')}
						options={precisionTypeOptions}
					/>
				)}
			/>
			<Controller
				name="precision"
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={precisionNumberLabel}
						data-testid="nominalValue"
						error={errors.precision?.message}
					/>
				)}
			/>
			<Controller
				name="includeSettings"
				control={control}
				render={({ field }) => (
					<Switch label={t('Include settings')} checked={!!field.value} onChange={field.onChange} />
				)}
			/>
			<Controller
				name="includeInStatistics"
				control={control}
				render={({ field }) => (
					<Switch label={t('Include in statistics')} checked={!!field.value} onChange={field.onChange} />
				)}
			/>
		</Form>
	);
};

export default CalculationEditForm;
