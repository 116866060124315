import React, {
	forwardRef,
	useCallback,
	useState,
	useMemo,
	RefAttributes,
	ReactElement,
	MutableRefObject
} from 'react';
import cx from 'classnames';
import { IconButton, Modal } from '@tactun/ui';
import Select, {
	SelectInstance,
	Props,
	components,
	OptionProps,
	MultiValue,
	ValueContainerProps,
	GroupBase,
	ActionMeta,
	MenuProps
} from 'react-select';
import { Checkbox } from 'primereact/checkbox';
import styles from './Dropdown.module.scss';
import FieldFrame2, { IFieldFrameProps } from '../FieldFrame2';
import { Except } from 'type-fest';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { useTranslation } from 'react-i18next';
import CreateModal from '../../CreateModal';

export interface IDropdownOption<ValueType> {
	value?: ValueType;
	label?: string;
	options?: Omit<IDropdownOption<ValueType>, 'options'>[];
}

export type DropdownOptionString = IDropdownOption<string>;
export type DropdownOptionNumber = IDropdownOption<number>;

export interface DropdownProps<ValueType, IsMulti extends boolean>
	extends Omit<
			Props<IDropdownOption<ValueType>, IsMulti>,
			| 'ariaLiveMessages'
			| 'formatOptionLabel'
			| 'styles'
			| 'filterOption'
			| 'onBlur'
			| 'onFocus'
			| 'onChange'
			| 'options'
			| 'value'
		>,
		Except<IFieldFrameProps, 'children' | 'onMouseDown' | 'onBlur'> {
	onBlur?: () => void;
	options?: (IDropdownOption<ValueType> | ValueType)[];
	OptionComponent?: React.FC<OptionProps<IDropdownOption<ValueType>>>;
	panelClassName?: string;
	itemName?: string;
	filter?: boolean;
	disabled?: boolean;
	editable?: boolean;
	onChange?: IsMulti extends true ? (value: ValueType[]) => void : (value: ValueType | null) => void;
	value?: IsMulti extends true ? ValueType[] | null : ValueType | null;
	isMulti?: IsMulti;
	'data-testid'?: string;
}

function MultiValueOption<T>({ children, ...props }: OptionProps<IDropdownOption<T>>) {
	return (
		<components.Option {...props}>
			<Checkbox checked={props.isSelected} />
			<span className={styles.multiSelectOption}>{children}</span>
		</components.Option>
	);
}

const NoOptionsMessage = () => <div className={styles.noOptionMessage} />;
const NoOptionsCreatableMessage = () => <div />;

const ClosedMultiValueContainer = () => null;

function ClosedValueContainer<T>({ children, ...props }: ValueContainerProps<IDropdownOption<T>>) {
	return (
		<components.ValueContainer {...props}>
			<div className={styles.valuesClosed}>
				{props
					.getValue()
					.map((o) => (typeof o === 'object' ? o?.label : o))
					.join(', ')}
			</div>
			{children}
		</components.ValueContainer>
	);
}

const createItemModalId = 'creatableModal';

function CreatableMenu<T>({ children, ...props }: MenuProps<IDropdownOption<T>>) {
	const { t } = useTranslation('common');
	const onClick = () => {
		Modal.show(createItemModalId);
	};
	// @ts-ignore
	const itemName = props.selectProps['data-itemName'] || '';

	return (
		<components.Menu {...props}>
			{children}

			<div className={styles.iconPlusCont} onClick={onClick} data-testid="createNewItem">
				<IconButton icon="plus" fontSize="1.2rem" className={styles.iconPlus} />
				<span className={styles.labelPlus}>
					{t('Create')} {itemName}
				</span>
			</div>
		</components.Menu>
	);
}

type Dropdown = <T, IsMulti extends boolean = false>(
	props: DropdownProps<T, IsMulti> & RefAttributes<SelectInstance<T, IsMulti>>
) => ReactElement;

function DropdownInner<T, IsMulti extends boolean = false>(
	props: DropdownProps<T, IsMulti>,
	ref:
		| ((instance: SelectInstance<T, IsMulti> | null) => void)
		| MutableRefObject<SelectInstance<T, IsMulti> | null>
		| null
) {
	const { panelClassName, OptionComponent, editable, itemName, options, onChange, filter, value, isMulti, ...rest } =
		props;
	const [isOpen, setIsOpen] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	const innerRef = React.useRef<SelectInstance<IDropdownOption<T>, true>>(null);

	// Create new option
	const [createdOptions, setCreatedOptions] = useState<IDropdownOption<T>[]>([]);

	const objectOptions = useMemo(() => {
		return [
			...createdOptions,
			...(options?.map(
				(o) =>
					(typeof o === 'object'
						? o
						: {
								label: String(o),
								value: o
						  }) as IDropdownOption<T>
			) || [])
		];
	}, [options, createdOptions]);

	const { onBlur } = rest;
	const handleAddItem = useCallback(
		(name: string) => {
			if (!objectOptions?.find((o) => o.value === name)) {
				setCreatedOptions([{ label: name, value: name as T }]);
			}
			(onChange as (v: T | null) => void)?.(name as T);
			onBlur?.();
			Modal.hide(createItemModalId);
		},
		[objectOptions, onChange, onBlur]
	);

	const valuesMap = useMemo(() => {
		const map: Record<string, any> = {};
		objectOptions?.forEach(({ options, ...opt }) => {
			if (options) {
				options.forEach((opt2) => {
					map[String(opt2.value)] = opt2;
				});
			} else {
				map[String(opt.value)] = opt;
			}
		});
		return map;
	}, [objectOptions]);

	const selectValue = useMemo(() => {
		const arrayValue = !isMulti ? [value].filter((v) => v !== undefined && v !== null && v !== '') : (value as T[]);

		return (
			arrayValue?.map((val: any) => {
				return (
					valuesMap[String(val)] || {
						value: val,
						label: String(val)
					}
				);
			}) || []
		);
	}, [isMulti, valuesMap, value]);

	const classNames = useCallback(
		(className: string) => ({
			control: () => cx(styles.dropdownCustom, className),
			groupHeading: () => styles.groupLabel,
			group: () => styles.group,
			menuPortal: () => cx(panelClassName, styles.menuPortal),
			menu: () => styles.dropdownOptionList,
			multiValue: () => styles.multiValue,
			multiValueLabel: () => styles.multiValueLabel,
			multiValueRemove: () => styles.multiValueRemove,
			valueContainer: () => (isOpen ? '' : styles.valueContainer),
			input: () => (isOpen ? '' : styles.inputClosed),
			option: (optionState: { isSelected: boolean; isDisabled: boolean }) =>
				cx(styles.dropdownOptionItem, {
					[styles.dropdownOptionItemSelected]: optionState.isSelected,
					[styles.dropdownOptionItemDisabled]: optionState.isDisabled
				})
		}),
		[isOpen, panelClassName]
	);

	const componentsConfigs:
		| Partial<SelectComponents<IDropdownOption<T>, boolean, GroupBase<IDropdownOption<T>>>>
		| undefined = useMemo(
		() => ({
			Menu: editable ? CreatableMenu : components.Menu,
			Option: OptionComponent || (isMulti ? MultiValueOption<T> : components.Option),
			NoOptionsMessage: editable ? NoOptionsCreatableMessage : NoOptionsMessage,
			ValueContainer: isOpen ? components.ValueContainer : ClosedValueContainer<T>,
			MultiValueContainer: isOpen ? components.MultiValueContainer : ClosedMultiValueContainer
		}),
		[OptionComponent, editable, isMulti, isOpen]
	);

	const handleOnChange = useCallback(
		(o: MultiValue<IDropdownOption<T>>, action: ActionMeta<IDropdownOption<T>>) => {
			if (action.action === 'clear') {
				setSearchValue('');
				return false;
			}
			if (onChange) {
				if (!isMulti) {
					if (action.action === 'select-option' || action.action === 'create-option') {
						(onChange as (v: T | null) => void)(action.option?.value === undefined ? null : action.option.value);
					} else if (action.action !== 'deselect-option') {
						(onChange as (v: T | null) => void)(null);
					}
				} else {
					(onChange as (v: T[]) => void)(o?.map((i) => i.value as T) || []);
				}
			}
		},
		[isMulti, onChange]
	);

	return (
		<>
			<FieldFrame2
				{...rest}
				className={cx(styles.fieldFrame, rest.className, { [styles.dropdownOpen]: isOpen })}
				dataTestId={rest?.['data-testid']}
			>
				{({ className, onBlur, disabled }) => {
					return (
						<Select<IDropdownOption<T>, true>
							{...rest}
							unstyled
							isMulti={true}
							ref={innerRef}
							placeholder={rest.placeholder || ''}
							isDisabled={disabled}
							onBlur={onBlur}
							options={objectOptions}
							menuPlacement="auto"
							hideSelectedOptions={false}
							menuPortalTarget={document.body}
							onMenuOpen={() => setIsOpen(true)}
							onMenuClose={() => setIsOpen(false)}
							closeMenuOnSelect={!isMulti}
							blurInputOnSelect={!isMulti}
							className={styles.dropdownContainer}
							isSearchable={filter}
							inputValue={searchValue}
							isClearable={!!searchValue}
							onInputChange={(value) => setSearchValue(value)}
							formatGroupLabel={({ label }) => label}
							getOptionValue={(o) => o.value as string}
							classNames={classNames(className)}
							components={componentsConfigs}
							value={selectValue}
							onChange={handleOnChange}
							data-itemName={itemName}
						/>
					);
				}}
			</FieldFrame2>
			{editable && <CreateModal modalId={createItemModalId} label={itemName} onSave={handleAddItem} />}
		</>
	);
}

const Dropdown = forwardRef(DropdownInner) as Dropdown;
export default Dropdown;
