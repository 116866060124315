import React, { useCallback, useState } from 'react';
import { Modal } from '@tactun/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SpecimenStatus } from '@tactun/grpc-client';
import { useKeycloakContext } from '@tactun/keycloak-axios-provider';
import SpecimenTable from '../components/SpecimensTable';
import { SpecimenListItem } from '../specimens.types';
import { SpecimenModes, SpecimenTableActionTypes } from '../specimens.enums';
import { useSpecimens } from '../specimens.hooks';
import { deleteLogs, useSpecimenLog } from '../specimens.grpc';
import { SelectTestModal, TestModals } from '../../Tests';
import { useConnectionStore } from '../../Connection/connection.store';
import EntityDeleter, { EntityTypes } from '../../EntityDeleter';
import { downloadReport } from '../../TestReportConfiguration/testReportConfiguration.api';

const SpecimenTableContainer: React.FC = () => {
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<SpecimenListItem | undefined>();
	const [currentSpecimenId, setCurrentSpecimenId] = useState('');
	const { mode } = useParams();
	const queryClient = useQueryClient();
	const { downloadLogs } = useSpecimenLog();
	const { connectedDevice } = useConnectionStore();
	const { specimens, isSpecimensLoading } = useSpecimens(mode as SpecimenModes);
	const user = useKeycloakContext()?.getUserData();

	const handleRunTest = useCallback(
		(testId: string, specimenId: string) => navigate(`/tests/${testId}/dashboard?specimenId=${specimenId}`),
		[navigate]
	);

	const handleAction = useCallback(
		(type: SpecimenTableActionTypes, data?: SpecimenListItem) => {
			switch (type) {
				case SpecimenTableActionTypes.EDIT:
					navigate(`./edit/${data?.id}`);
					break;
				case SpecimenTableActionTypes.RUN_SPECIMEN_TEST:
					if (!data?.test && data?.id) {
						Modal.show(TestModals.SELECT_TEST_MODAL);
						return setCurrentSpecimenId(data.id);
					}
					if (data) {
						handleRunTest(data?.test, data?.id);
					}
					break;
				case SpecimenTableActionTypes.CREATE:
					navigate('./create');
					break;
				case SpecimenTableActionTypes.PLAYBACK:
					navigate('/tests/a2822ac9-45e4-42af-beeb-be3887a21a62/dashboard');
					break;
				case SpecimenTableActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case SpecimenTableActionTypes.DELETE:
					setEntityForDelete(data as SpecimenListItem);
					break;
				case SpecimenTableActionTypes.TEST_LOGS:
					if (!connectedDevice?.ip) {
						toast.warning('Please connect to the device before downloading logs.');
						return;
					}
					if (data?.pipelineUuid && data.children[0]?.uuid) {
						downloadLogs(data.pipelineUuid, data.children[0]?.uuid, connectedDevice?.ip);
					}
					break;
				case SpecimenTableActionTypes.TEST_REPORT:
					if (!connectedDevice?.ip) {
						toast.warning('Please connect to the device before downloading report.');
						return;
					}
					if (!window.__TAURI_INVOKE__) {
						toast.warning('Report available only in desktop app.');
						return;
					}
					if (data?.pipelineUuid && data.children[0]?.uuid) {
						downloadReport(data.pipelineUuid, data.id, user?.username);
					}
					break;
			}
		},
		[navigate, connectedDevice?.ip, handleRunTest, downloadLogs, user?.username]
	);

	const handleDeleteCancel = useCallback(() => setEntityForDelete(undefined), []);

	const handlePostDelete = useCallback(
		(id: string) => {
			if (entityForDelete?.children?.[0].specimenTest.specimenTestStatus !== SpecimenStatus.NOT_TESTED) {
				deleteLogs(`log-${entityForDelete?.children[0]?.uuid}`);
			}
			queryClient.setQueryData<SpecimenListItem[]>(['specimens', mode], (old) => old?.filter((st) => st.id !== id));
			setEntityForDelete(undefined);
		},
		[queryClient, mode, entityForDelete]
	);

	const handleCloseSelectTest = useCallback(() => {
		setCurrentSpecimenId('');
		Modal.hide(TestModals.SELECT_TEST_MODAL);
	}, []);

	const handleSelectTest = useCallback(
		(testId: string) => handleRunTest(testId, currentSpecimenId),
		[currentSpecimenId, handleRunTest]
	);

	return (
		<>
			<SpecimenTable
				specimenMode={mode as SpecimenModes}
				onAction={handleAction}
				data={specimens}
				isLoading={isSpecimensLoading}
			/>
			<EntityDeleter
				onDeleted={handlePostDelete}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.SPECIMEN}
				entity={entityForDelete}
			/>
			<SelectTestModal handleClose={handleCloseSelectTest} onConfirm={handleSelectTest} />
		</>
	);
};

export default React.memo(SpecimenTableContainer);
