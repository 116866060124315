import axios from '../../httpCommon';
import { IStationChannelResponseDto } from '../StationChannels';
import { ActuatorsTypes } from './actuators.enums';
import {
	IActuatorCalibrationRequestDto,
	IActuatorCalibrationResponseDto,
	IActuatorRequestDto,
	IActuatorResponseDto
} from './actuators.types';

export const getActuatorsForStation = async (stationId?: string, unUsedOnly: boolean = false) => {
	const response = await axios.get(`/quasar/actuators/station/${stationId}?unUsedOnly=${unUsedOnly}`);

	return response.data as IActuatorResponseDto[];
};

export const createActuator = async (actuator: IActuatorRequestDto) => {
	const response = await axios.post('/quasar/actuators', actuator);
	return response.data;
};

export const updateActuator = async (actuator: IActuatorRequestDto, actuatorId: string) => {
	const response = await axios.put(`/quasar/actuators/${actuatorId}`, actuator);
	return response.data;
};

export const getActuator = async (id: string) => {
	const response = await axios.get(`/quasar/actuators/${id}`);

	return response.data as IActuatorResponseDto;
};

export const getAvailableStationChannels = async (stationId: string, type: ActuatorsTypes) => {
	const response = await axios.get(`/quasar/actuators/available-station-channels/${stationId}/${type}`);

	return response.data as IStationChannelResponseDto[];
};

export const getActuatorsManufacturers = async () => {
	const response = await axios.get('/quasar/actuators/manufacturers');

	return response.data as string[];
};

export const checkName = async (name: string, stationId?: string, id?: string): Promise<boolean> => {
	const response = await axios.post('/quasar/actuators/isNameUnique', { id, parentId: stationId, name });
	return response.data === true;
};

export const getCalibration = async (id: string) => {
	const response = await axios.get(`/quasar/actuator-calibrations/${id}`);

	return response.data as IActuatorCalibrationResponseDto;
};

export const createCalibration = async (calibration: IActuatorCalibrationRequestDto) => {
	const response = await axios.post('/quasar/actuator-calibrations', calibration);
	return response.data;
};

export const updateCalibration = async (calibration: IActuatorCalibrationRequestDto, calibrationId: string) => {
	const response = await axios.put(`/quasar/actuator-calibrations/${calibrationId}`, calibration);
	return response.data;
};

export const getActuatorCalibration = async (actuatorId: string) => {
	const response = await axios.get(`/quasar/actuator-calibrations/actuator/${actuatorId}`);

	return response.data as IActuatorCalibrationResponseDto;
};
