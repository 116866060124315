import React, { useMemo } from 'react';
import { Menubar } from 'primereact/menubar';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './HeaderNavigation.module.scss';

export interface IHeaderNavigationProps {
	children: any;
	className?: string;
}

const menuItemTemplate = (
	{ target, label }: { target: string; label: string },
	{ className }: { className: string }
) => {
	return (
		<Link to={target} className={className}>
			{label}
		</Link>
	);
};

// the component used for receiving props and converting them to "Primereact" "MenuModel" object
// that kind of architecture is defined to make possible migration to our own components in the future and provide the ability to use components in a declarative manner
const HeaderNavigation: React.FC<IHeaderNavigationProps> = ({ children, className }) => {
	// Traverse children components to create "MenuModel" "Primereact"  object
	const model = useMemo(() => {
		return React.Children.map(children, (child) => {
			const { label, children: subChildren, target } = child?.props;

			const menuItems = React.Children.map(subChildren, (subChild) => {
				const { label, target } = subChild?.props;
				return {
					label,
					target,
					template: target ? menuItemTemplate : null
				};
			});

			return {
				label: label,
				icon: children,
				target: target,
				template: target ? menuItemTemplate : null,
				items: menuItems
			};
		});
	}, [children]);

	return <Menubar model={model} className={cx(styles.container, className)} data-testid="menuNavigation" />;
};

export default React.memo(HeaderNavigation);
