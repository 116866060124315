import { Button } from '@tactun/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigureDevice, useConfiguredStation } from '../stations.hooks';
import { Tooltip } from 'primereact/tooltip';

interface IStationConfigureBtnContainerProps {
	stationId: string;
	isConnectedModel: boolean;
	isConnected: boolean;
}

const StationConfigureBtnContainer = ({
	stationId,
	isConnectedModel,
	isConnected
}: IStationConfigureBtnContainerProps) => {
	const { t } = useTranslation(['station']);
	const configuredInfo = useConfiguredStation(stationId);
	const { configure, isLoading } = useConfigureDevice();
	const isConfigured = configuredInfo?.stationId === stationId;

	return (
		<>
			<Button
				icon={isConfigured ? 't-icon-configuration_applied' : 't-icon-configure'}
				data-testid="applyConfigureBtn"
				variant="text"
				color="secondary"
				disabled={!isConnectedModel || isLoading}
				onClick={() => configure(stationId)}
				loading={isLoading}
				id={`configure-btn-${stationId}`}
			/>
			{isConnected && (
				<Tooltip
					target={`#configure-btn-${stationId}`}
					content={
						isConfigured
							? t('Configuration applied')
							: !isConnectedModel
							? t('Incompatible station')
							: t('Configure station')
					}
					position="bottom"
					showOnDisabled
				/>
			)}
		</>
	);
};

export default React.memo(StationConfigureBtnContainer);
