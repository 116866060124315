import {
	ActuatorRequest,
	ActuatorTypeDcPwm,
	ActuatorTypeServoElectric,
	Calibration,
	CalibrationTupleDto,
	DcPwmChannelType,
	PiecewiseCalibration,
	ServoChannelType,
	StationChannelAnalogOut100MA,
	StationChannelAnalogOut10V,
	StationChannelAnalogOut10V24MA,
	StationChannelDcPwm30V,
	StationChannelDcPwm50V,
	StationChannelDcPwm65V
} from '@tactun/grpc-client';
import { Converter } from '../../types';
import { ChannelType, stationChannelResponseToHardwareChannelGRPCConverter } from '../StationChannels';
import { ActuatorCalibrationTypes, ActuatorsTypes } from './actuators.enums';
import {
	IActuatorResponseDto,
	ActuatorFormType,
	IActuatorRequestDto,
	IActuatorListItem,
	IActuatorCalibrationResponseDto,
	IActuatorCalibrationForm,
	IActuatorCalibrationRequestDto,
	IActuatorCalibrationTuple
} from './actuators.types';

export const responseListItemConverter: Converter<IActuatorResponseDto, IActuatorListItem> = (response) => {
	return {
		id: response.id,
		name: response.name,
		type: ActuatorsTypes[response.type],
		stationChannelName: response.stationChannel?.name ?? '',
		calibrationStatus: response.calibrationStatus
	};
};

export const responseFormConverter = (input: IActuatorResponseDto): ActuatorFormType => {
	return {
		id: input.id,
		name: input.name,
		type: input.type,
		manufacturer: input.manufacturer,
		stationId: input.stationId,
		stationChannelId: input.stationChannel?.id ?? '',
		serialNumber: input.serialNumber,
		s1EnableBitChIds: input.s1EnableBitChs?.map((eb) => eb.id) || null,
		s2EnableBitChIds: input.s2EnableBitChs?.map((eb) => eb.id) || null
	};
};

export const formRequestConverter = (form: ActuatorFormType): IActuatorRequestDto => {
	return {
		name: form.name,
		type: form.type,
		manufacturer: form.manufacturer ? form.manufacturer : undefined,
		stationId: form.stationId,
		stationChannelId: form.stationChannelId,
		serialNumber: form.serialNumber,
		s1EnableBitChIds: form.s1EnableBitChIds ? form.s1EnableBitChIds : undefined,
		s2EnableBitChIds: form.s2EnableBitChIds ? form.s2EnableBitChIds : undefined
	};
};

export const actCalibrationResponseFormConverter: Converter<
	IActuatorCalibrationResponseDto,
	IActuatorCalibrationForm
> = (response) => {
	return {
		type: response.type,
		unitId: response.unit.id,
		actuatorId: '',
		calibrationData: response.calibrationData
	};
};

export const actCalibrationFormRequestConverter: Converter<IActuatorCalibrationForm, IActuatorCalibrationRequestDto> = (
	form
) => {
	return {
		type: form.type,
		unitId: form.unitId,
		actuatorId: form.actuatorId,
		calibrationData: form.calibrationData
	};
};

export const calibrationDataToCalibrationTupleGRPCConverter: Converter<
	IActuatorCalibrationTuple[],
	CalibrationTupleDto[]
> = (calibrationTuples) => {
	return calibrationTuples.map((calibration) => {
		const calibrationTupleRequest = new CalibrationTupleDto();
		calibrationTupleRequest.setUsd(calibration.usd);
		calibrationTupleRequest.setCsd(calibration.csd);

		return calibrationTupleRequest;
	});
};

export const calibrationResponseToCalibrationGRPCConverter: Converter<IActuatorCalibrationResponseDto, Calibration> = (
	calibrationResp
) => {
	const calibration = new Calibration();

	if (calibrationResp.id) {
		calibration.setCalibrationId(calibrationResp.id);
	}
	if (calibrationResp.type) {
		calibration.setCalibrationType(calibrationResp.type);
	}

	if (calibrationResp.type === ActuatorCalibrationTypes.PIECEWISE) {
		const piecwiseCalibration = new PiecewiseCalibration();

		if (calibrationResp.calibrationData) {
			const cData = calibrationDataToCalibrationTupleGRPCConverter(calibrationResp.calibrationData);
			piecwiseCalibration.setCalibrationDataList(cData);
		}

		calibration.setPiecewiseCalibration(piecwiseCalibration);
	}

	return calibration;
};

export const actuatorResponseToActuatorGRPCConverter: Converter<IActuatorResponseDto, ActuatorRequest> = (actuator) => {
	const actuatorDto = new ActuatorRequest();
	actuatorDto.setId(actuator.id);
	actuatorDto.setName(actuator.name);
	actuatorDto.setType(actuator.type);

	if (actuator.stationChannel?.id) actuatorDto.setChannelId(actuator.stationChannel?.id);
	if (actuator.manufacturer) actuatorDto.setManufacturer(actuator.manufacturer);
	if (actuator.serialNumber) actuatorDto.setSerialNumber(actuator.serialNumber);

	if (actuator.type === ActuatorsTypes.DC_PWM) {
		const dcPwm = new ActuatorTypeDcPwm();
		dcPwm.setChannelType(
			actuator.stationChannel?.type === ChannelType.DC_PWM_30V ? DcPwmChannelType.DCPWM_30V : DcPwmChannelType.DCPWM_50V
		);

		if (actuator.stationChannel?.type === ChannelType.DC_PWM_30V) {
			const dcPwm30V = new StationChannelDcPwm30V();
			if (actuator.stationChannel?.frequency) dcPwm30V.setPwmFreq(actuator.stationChannel?.frequency);
			dcPwm.setDcpwm30(dcPwm30V);
		}

		if (actuator.stationChannel?.type === ChannelType.DC_PWM_50V) {
			const dcPwm50V = new StationChannelDcPwm50V();
			if (actuator.stationChannel?.frequency) dcPwm50V.setPwmFreq(actuator.stationChannel?.frequency);
			dcPwm.setDcpwm50(dcPwm50V);
		}

		if (actuator.stationChannel?.type === ChannelType.DC_PWM_65V) {
			const dcPwm65V = new StationChannelDcPwm65V();
			if (actuator.stationChannel?.frequency) dcPwm65V.setPwmFreq(actuator.stationChannel?.frequency);
			dcPwm.setDcpwm65(dcPwm65V);
		}

		actuatorDto.setDcpwm(dcPwm);
	}

	if (actuator.type === ActuatorsTypes.SERVO_ELECTRIC) {
		const servoElectric = new ActuatorTypeServoElectric();
		if (actuator.stationChannel?.type === ChannelType.ANALOG_OUT_10V) {
			const analogOut10V = new StationChannelAnalogOut10V();
			servoElectric.setChannelType(ServoChannelType.ANALOG_OUT_10V);

			analogOut10V.setRange(actuator.stationChannel.range);
			analogOut10V.setOffset(actuator.stationChannel.offset);
			analogOut10V.setLimit(actuator.stationChannel.limit);

			servoElectric.setAnalogout10v(analogOut10V);
		}

		if (actuator.stationChannel?.type === ChannelType.ANALOG_OUT_100MA) {
			const analogOut100ma = new StationChannelAnalogOut100MA();
			servoElectric.setChannelType(ServoChannelType.ANALOG_OUT_100MA);

			analogOut100ma.setRange(actuator.stationChannel.range);
			analogOut100ma.setOffset(actuator.stationChannel.offset);
			analogOut100ma.setLimit(actuator.stationChannel.limit);

			servoElectric.setAnalogout100ma(analogOut100ma);
		}

		if (actuator.stationChannel?.type === ChannelType.ANALOG_OUT_10V_24MA) {
			const analogOut10V24MA = new StationChannelAnalogOut10V24MA();
			servoElectric.setChannelType(ServoChannelType.ANALOG_OUT_10V_24MA);

			if (actuator.stationChannel.aiServoDriverType)
				analogOut10V24MA.setType(actuator.stationChannel.aiServoDriverType);
			analogOut10V24MA.setRange(actuator.stationChannel.range);
			analogOut10V24MA.setOffset(actuator.stationChannel.offset);
			analogOut10V24MA.setLimit(actuator.stationChannel.limit);

			servoElectric.setAnalogout10v24ma(analogOut10V24MA);
		}

		if (actuator.calibrationResponseDto) {
			actuatorDto.setCalibration(calibrationResponseToCalibrationGRPCConverter(actuator.calibrationResponseDto));
		}

		actuatorDto.setServoElectric(servoElectric);
	}

	/* 	actuatorDto.setStepper(value?: ActuatorTypeStepper);
	actuatorDto.setServoHydraulic(value?: ActuatorTypeServoHydraulic);
	actuatorDto.setPiezo(value?: ActuatorTypePiezoDriver); */

	if (actuator.stationChannel)
		actuatorDto.setHw(stationChannelResponseToHardwareChannelGRPCConverter(actuator.stationChannel));
	if (actuator.s1EnableBitChs?.length) actuatorDto.setEbit1IdList(actuator.s1EnableBitChs.map((eb) => eb.id));
	if (actuator.s2EnableBitChs?.length) actuatorDto.setEbit2IdList(actuator.s2EnableBitChs.map((eb) => eb.id));

	return actuatorDto;
};

export const actuatorsResponsesToActuatorsGRPCsConverter: Converter<IActuatorResponseDto[], Array<ActuatorRequest>> = (
	actuators
) => {
	return actuators.map((actuator) => actuatorResponseToActuatorGRPCConverter(actuator));
};
