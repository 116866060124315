import { useMemo } from 'react';
import { useAxes } from '../Axes/axes.hooks';
import { DataChannelType } from '../DataChannel';
import { useMeasurements } from '../Measurements';
import { UnitResponse, useQuantities } from '../Units';
import { dtoToStateConverter } from './graphWidget.converters';
import { Axis } from './graphWidget.enums';
import { generateAxisTitle, generatePlotTitle } from './graphWidget.tools';
import { GraphChannelsDto, GraphMetaDataDto, GraphState } from './graphWidget.types';

export const useGraphSettings = (
	channels: GraphChannelsDto,
	metadata: GraphMetaDataDto,
	stationId?: string
): GraphState | null => {
	const { measurements = [], isMeasurementsFetched } = useMeasurements(stationId);
	const { axes = [], isAxesFetched } = useAxes(stationId);
	const { quantities = [], isQuantitiesFetched } = useQuantities();

	const timeUnit = useMemo(
		() =>
			quantities.reduce<UnitResponse | undefined>((res, { units }) => {
				if (!res) {
					res = units.find(({ id }) => id === metadata.settings.timeUnitId);
				}
				return res;
			}, undefined),
		[metadata.settings.timeUnitId, quantities]
	);

	const graphState = useMemo(() => {
		if (!metadata || !channels || !timeUnit || !isMeasurementsFetched || !isAxesFetched || !isQuantitiesFetched)
			return null;

		const state = dtoToStateConverter({ meta: metadata, settings: channels });

		if (state.xChannel === undefined) return null;

		const leftChannels = state.plots.filter((plot) => plot.yAxisId === Axis.LEFT_Y) || [];
		const leftChannel =
			leftChannels?.find((plot) => plot.channel.type === DataChannelType.MEASUREMENT) || leftChannels[0];
		state.leftAxisTitle = generateAxisTitle(leftChannel?.channel, measurements, quantities, timeUnit?.name);

		const rightChannels = state.plots.filter((plot) => plot.yAxisId === Axis.RIGHT_Y) || [];
		const rightChannel =
			rightChannels?.find((plot) => plot.channel.type === DataChannelType.MEASUREMENT) || rightChannels[0];
		if (rightChannel?.channel !== undefined) {
			state.rightAxisTitle = generateAxisTitle(rightChannel?.channel, measurements, quantities, timeUnit?.name);
		}

		state.xChannel.title = generateAxisTitle(state.xChannel, measurements, quantities, timeUnit?.name);

		state.plots.forEach((plot) => {
			plot.title = generatePlotTitle(plot.channel, measurements, axes, timeUnit?.name);
		});

		return state;
	}, [
		axes,
		channels,
		isAxesFetched,
		isMeasurementsFetched,
		isQuantitiesFetched,
		measurements,
		metadata,
		quantities,
		timeUnit
	]);

	return graphState;
};
