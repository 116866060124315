import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnBodyOptions } from 'primereact/column';

export const useFormatDateColumn = () => {
	const { t } = useTranslation('common');

	return useCallback(
		(rowData: Record<string, any>, { field }: ColumnBodyOptions) => {
			const data = rowData[field];
			return data ? t('dateTime', { date: new Date(data) }) : undefined;
		},
		[t]
	);
};
