export enum DashboardTypeCrudActionTypes {
	EDIT = 'EDIT_DASHBOARD_TYPE',
	CREATE = 'CREATE_DASHBOARD_TYPE',
	DUPLICATE = 'DUPLICATE_DASHBOARD_TYPE',
	DELETE = 'DELETE_DASHBOARD_TYPE'
}

export enum DashboardDisplayingMode {
	FLOATING = 0,
	FIXED
}
