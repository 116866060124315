import { Channel, WidgetItem, WidgetItemLocal } from './dashboard.types';
import { Layout } from 'react-grid-layout';
import { AxisType } from '@tactun/grpc-client';
import { GraphDataChannel, GraphResponseDto } from '../TestDashboardConfiguration/testDashboardConfiguration.types';
import { DataChannelDropdownKeyValue, dataChannelToDataChannelDto, DataChannelType } from '../DataChannel';
import { MeasurementResponseDto } from '../Measurements';
import { IAxisResponseDto } from '../Axes';
import { Converter } from '../../types';

import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem';
import { TestListDto } from '../Tests/tests.types';
import { SpecimenListItem } from '../Specimens/specimens.types';
import { uuid } from '../../tools';
import { GraphDto, Plot } from '../GraphWidget/graphWidget.types';
import { getPLotColorGenerator } from '../GraphWidget/graphWidget.tools';
import { dataChannelsToPlotsConverter, plotsToPlotsMetaConverter } from '../GraphWidget/graphWidget.converters';

export const widgetToGridLayoutItem = ({
	x,
	y,
	w,
	h,
	minH = 0,
	minW = 0,
	maxH = Infinity,
	maxW = Infinity,
	metadata
}: WidgetItem & WidgetItemLocal): Layout => ({
	i: metadata.id,
	x,
	y,
	w,
	h,
	minH,
	minW,
	maxH,
	maxW
});

export const ROW_HEIGHT = 32;
export const widthColToPx = (w: number, columnWidth: number) => {
	const gutter = window.innerWidth >= 1920 ? 16 : 12;

	return Math.round(w * columnWidth + (w - 1) * gutter);
};

export const widthPxToCol = (w: number, columnWidth: number) => {
	const gutter = window.innerWidth >= 1920 ? 16 : 12;

	return Math.ceil((w + gutter) / (columnWidth + gutter));
};

export const heightRowToPx = (h: number) => {
	return Math.round(h * ROW_HEIGHT + (h - 1) * 16);
};

export const heightPxToRow = (h: number) => {
	return Math.ceil((h + 16) / (ROW_HEIGHT + 16));
};

export const dataChannelTreeToChannels = (
	dataChannels?: DataChannelDropdownKeyValue[],
	measurements: MeasurementResponseDto[] = [],
	axes: IAxisResponseDto[] = []
): Channel[] => {
	const channels = dataChannels ? dataChannelToDataChannelDto(dataChannels, measurements, axes) : [];
	return channels.map((channel) => {
		if (channel.type === DataChannelType.MEASUREMENT) {
			return {
				id: channel.id,
				type: DataChannelType.MEASUREMENT,
				quantity: (channel.entity as MeasurementResponseDto).unit.quantity || '',
				unit: (channel.entity as MeasurementResponseDto).unit.name || '',
				name: channel.entity?.name || ''
			};
		}

		if (channel.type === DataChannelType.SET_POINT) {
			return {
				id: channel.id,
				type: DataChannelType.SET_POINT,
				name: channel.entity?.name
			};
		}

		if (channel.type === DataChannelType.COMP_SET_POINT) {
			return {
				id: channel.id,
				type: DataChannelType.COMP_SET_POINT,
				name: channel.entity?.name
			};
		}

		if (channel.type === DataChannelType.CYCLE) {
			return {
				id: channel.id,
				type: DataChannelType.CYCLE,
				name: channel.entity?.name
			};
		}

		return {
			id: `${DataChannelType.TIME}`,
			type: DataChannelType.TIME
		};
	});
};

export const dataChannelResponseGraphAxisConverter: Converter<GraphDataChannel, Channel[]> = (input) => {
	const result: Channel[] = [];
	if (input.time) {
		result.push({ id: 'TIME', type: DataChannelType.TIME });
	}
	input.measurements?.forEach((measurement) => {
		result.push({
			id: measurement.id,
			type: DataChannelType.MEASUREMENT
		});
	});

	input.cycleAxes?.forEach((cycle) => {
		result.push({ id: cycle.id, type: DataChannelType.CYCLE });
	});

	input.spAxes?.forEach((setPoint) => {
		result.push({ id: setPoint.id, type: DataChannelType.SET_POINT });
	});

	input.compSpAxes?.forEach((setPoint) => {
		result.push({ id: setPoint.id, type: DataChannelType.COMP_SET_POINT });
	});

	return result;
};

export const graphResponseDtoToSettingsConverter: Converter<GraphResponseDto, GraphDto> = (
	input: GraphResponseDto
): any => {
	const generatePlotColor = getPLotColorGenerator();
	const plots: Plot[] = dataChannelsToPlotsConverter({
		leftChannels: dataChannelResponseGraphAxisConverter(input.dcY1),
		rightChannels: dataChannelResponseGraphAxisConverter(input.dcY2)
	}).map((plot) => ({ ...plot, color: generatePlotColor() }));

	return {
		settings: {
			xChannel: dataChannelResponseGraphAxisConverter(input.dcX)[0],
			yChannels: plots.map((plot) => plot.channel)
		},
		meta: {
			id: input.id || uuid(),
			name: input.name,
			settings: {
				limitLines: input.limitLines,
				autoScaleX: input.autoscaleX,
				autoScaleY: input.autoscaleY,
				// @ts-ignore
				scaleType: input.scaleType as ScaleTypes,
				dataRate: input.rate,
				timeWindowSize: input.timeWndSize,
				xOffset: input.xoffset,
				offset: input.xoffsetValue,
				min: input.min,
				max: input.max,
				cmin: input.cmin,
				cmax: input.cmax,
				timeUnitId: input.timeUnit?.id || ''
			},
			plotsMetaData: plotsToPlotsMetaConverter(plots)
		}
	};
};

export const testAxesToDropdownOptions: Converter<IAxisResponseDto[], SelectItemOptionsType> = (axes) => {
	return axes.map((axis) => ({
		value: axis.id,
		label: axis.name,
		type: AxisType.AXIS_TYPE_AXIAL
	}));
};

export const testDtoToTestOption: Converter<TestListDto, SelectItem> = (test) => {
	return {
		label: test.name,
		value: test.id
	};
};

export const specimenDtoToTestOption: Converter<SpecimenListItem, SelectItem> = (specimen) => {
	return {
		label: specimen.name,
		value: specimen.id
	};
};
