import { IListItemNumber } from '../../types';
import { ReadoutTypes } from './readout.enums';

export const ReadoutTypeOptions: IListItemNumber[] = [{ label: 'Standard', value: ReadoutTypes.STANDARD }];

export const readoutDefaultValues = {
	name: '',
	readoutType: ReadoutTypes.STANDARD,
	dataChannel: [],
	precision: 1,
	min: false,
	max: false,
	rate: false,
	tare: false
};
