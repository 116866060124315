import { FC, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@tactun/ui';
import { PageLayout, DataTable } from '@tactun/ui';
import { KebabMenu } from '@tactun/ui';
import { DataTableExpandedRows } from 'primereact/datatable';
import { QuantityResponseDto, UnitResponse } from '../../units.types';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { UnitCrudActionTypes } from '../../units.enums';
import UnitTableRowContent from '../UnitTableRowContent';
import { useTableConfigs } from '../../../../hooks';

interface UnitTableProps {
	onAction: (type: UnitCrudActionTypes, data?: QuantityResponseDto) => void;
	data?: QuantityResponseDto[];
	isLoading: boolean;
}

const UnitsTable: FC<UnitTableProps> = ({ data, isLoading, onAction }) => {
	const { t } = useTranslation('units');
	const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows>([]);

	const transColumns = useMemo<ITableColumns[]>(() => {
		return [{ field: 'name', header: t('Quantity name') }];
	}, [t]);
	const { filter, selected, columnComponents, handleFilterChange, handleCancel } = useTableConfigs<QuantityResponseDto>(
		transColumns,
		data
	);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Duplicate'),
				icon: 't-icon-duplicate',
				disabled: true,
				command: () => onAction(UnitCrudActionTypes.DUPLICATE, data)
			},
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(UnitCrudActionTypes.DELETE, data)
			}
		],
		[t, onAction]
	);

	const handleCreate = useCallback(() => {
		onAction(UnitCrudActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={t('Components')}>
			<PageLayout.Header title={t('Units')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('quantity')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={data}
				loading={isLoading}
				className="unitsTable"
				key="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				expandedRows={expandedRows}
				onRowToggle={(e) => setExpandedRows(e.data)}
				rowExpansionTemplate={({ units }: { units: UnitResponse[] }) => <UnitTableRowContent units={units} />}
			>
				<DataTable.Column exportable={false} expander align="right" headerStyle={{ width: '3em' }} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(UnitCrudActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					expander
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default UnitsTable;
