import axios from '../../httpCommon';
import { TestStageAxisConfigResponseDto } from '../Tests';
import { TestStageAxisConfigRequestDto } from './testAxesConfiguration.type';

export const createStageAxis = async (
	stage: TestStageAxisConfigRequestDto,
	stageId: string
): Promise<TestStageAxisConfigResponseDto> => {
	const response = await axios.post(`/quasar/tests/stages/${stageId}/axis`, stage);

	return response.data;
};

export const updateStageAxis = async (
	axis: TestStageAxisConfigRequestDto,
	axisId: string
): Promise<TestStageAxisConfigResponseDto> => {
	const response = await axios.put(`/quasar/tests/stages/axis/${axisId}`, axis);

	return response.data;
};
