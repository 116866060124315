import React, { memo } from 'react';
import { OptionProps } from 'react-select';
import cx from 'classnames';
import styles from './IconPicker.module.scss';
import { IDropdownOption } from '../Dropdown';
import Icon from '../../Icon';

const IconPickerOption: React.FC<OptionProps<IDropdownOption<string>>> = (props) => {
	return (
		<div
			className={cx(styles.iconItem, {
				[styles.selected]: props.isSelected
			})}
			{...props.innerProps}
		>
			<div className={styles.iconContainer}>
				<Icon name={props.data.value as string} />
			</div>
		</div>
	);
};

export default memo(IconPickerOption);
