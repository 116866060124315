import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, InputText } from '@tactun/ui';
import { UseFormReturn } from 'react-hook-form';
import { controllerAddManuallyFormId } from '../../connection.const';
import { ControllerAddManuallyFormType } from '../../connection.types';
import styles from './ControllerAddManuallyContent.module.scss';

interface IControllerAddManuallyContentProps {
	onSave: () => void;
	form: UseFormReturn<ControllerAddManuallyFormType>;
}

const ControllerAddManuallyContent: React.FC<IControllerAddManuallyContentProps> = ({ onSave, form }) => {
	const { t } = useTranslation('connection');
	const {
		register,
		formState: { errors }
	} = form;

	return (
		<Form className={styles.container} id={controllerAddManuallyFormId} onSubmit={onSave}>
			<InputText data-testid="nameId" label={t('Controller name')} {...register('name')} error={errors.name?.message} />
			<InputText
				data-testid="modelId"
				label={t('Controller model')}
				{...register('model')}
				error={errors.model?.message}
			/>
			<InputText
				data-testid="snId"
				label={t('Controller serial number')}
				{...register('sn')}
				error={errors.sn?.message}
			/>
			<InputText data-testid="ip" label={t('IP address')} {...register('ip')} error={errors.ip?.message} />
			<InputText data-testid="gateway" label={t('Gateway')} {...register('gateway')} error={errors.gateway?.message} />
			<InputText data-testid="mask" label={t('Subnet mask')} {...register('mask')} error={errors.mask?.message} />
		</Form>
	);
};

export default ControllerAddManuallyContent;
