import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@tactun/ui';
import { PageLayout } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { CrudActions, TableFilters } from '../../../../components';
import { INamedEntity, ITableColumns } from '../../../../types';
import { FeaturesetCrudActionTypes } from '../../featuresets.enums';
import { useTableConfigs } from '../../../../hooks';

interface FeaturesetsTableProps {
	onAction: (type: FeaturesetCrudActionTypes, data?: Record<string, string>) => void;
	data?: Record<string, string>[];
	isLoading: boolean;
}

const FeaturesetsTable: FC<FeaturesetsTableProps> = ({ data, isLoading, onAction }) => {
	const { t } = useTranslation('axes');

	const transColumns = useMemo<ITableColumns[]>(() => {
		return [
			{ field: 'name', header: t('Feature set Name') },
			{ field: 'date', header: t('Creation Date') },
			{ field: 'group', header: t('Group') }
		];
	}, [t]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<INamedEntity>(transColumns);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(FeaturesetCrudActionTypes.DELETE, data)
			}
		],
		[t, onAction]
	);

	const handleCreate = useCallback(() => {
		onAction(FeaturesetCrudActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={t('Systems and Stations')}>
			<PageLayout.Header title={t('Feature Sets')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('feature set')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters
					onFilterChange={handleFilterChange}
					onCancel={handleCancel}
					selected={selected as unknown as INamedEntity[]}
				/>
			</PageLayout.Filters>
			<DataTable
				value={data}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(FeaturesetCrudActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="duplicate"
							fontSize="1.2rem"
							title={t('Duplicate')}
							disabled
							onClick={() => onAction(FeaturesetCrudActionTypes.DUPLICATE, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default FeaturesetsTable;
