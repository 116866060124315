import React, { LegacyRef, useCallback } from 'react';
import cx from 'classnames';
import { ObserverCb, useResizeObserver } from '../../../../hooks';
import styles from './GraphContent.module.scss';

export interface IGraphContentProps {
	children: React.ReactNode;
	sidebar: React.ReactNode;
	toolbar: React.ReactNode;
	minimizedView: boolean;
}

const GraphContent: React.FC<IGraphContentProps> = ({ children, sidebar, toolbar, minimizedView }) => {
	const observerCb = useCallback<ObserverCb>((entries) => {
		//dispatch({ type: Actions.SET_MINIMIZED_VIEW_STATE, payload: entries[0].target.clientHeight < heightBreakpoint });
	}, []);

	const observableRef = useResizeObserver(observerCb);

	return (
		<div
			ref={observableRef as LegacyRef<HTMLDivElement>}
			className={cx(styles.container, { [styles.miniView]: minimizedView })}
		>
			<div className={styles.graphCont}>
				{toolbar}
				<div className={styles.graphContainer}>{children}</div>
			</div>
			{sidebar}
		</div>
	);
};

export default React.memo(GraphContent);
