import { RadioButtonProps } from 'primereact/radiobutton';
import React, { FC, forwardRef, Ref } from 'react';
import { IListItem, IListItemNumber } from '../../../../../../web/src/types';
import RadioButton from '../RadioButton';
import styles from './RadioSelect.module.scss';
import cn from 'classnames';

export interface RadioSelectProps {
	label?: string;
	value: string | number;
	disabled?: boolean;
	onChange: RadioButtonProps['onChange'];
	options: IListItem[] | IListItemNumber[];
	className?: string;
}

const RadioSelect: FC<RadioSelectProps> = forwardRef(
	({ value, onChange, options, label, className, disabled }, ref: Ref<HTMLDivElement>) => {
		return (
			<div ref={ref} className={cn(styles.container, className)}>
				<label className={styles.label}>{label}</label>
				{options.map((option) => (
					<RadioButton
						key={option.value}
						label={option.label}
						value={option.value}
						checked={option.value === value}
						onChange={onChange}
						disabled={disabled}
					/>
				))}
			</div>
		);
	}
);

export default RadioSelect;
