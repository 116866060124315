import React, { useCallback, useMemo } from 'react';
import { DataTable, KebabMenu, PageLayout } from '@tactun/ui';
import { IconButton } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { TestListItem } from '../../tests.types';
import { TestsTableActionTypes } from '../../tests.enums';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { useTableConfigs } from '../../../../hooks';
import { GroupTableCell } from '../../../Group';

interface ITestsProps {
	onAction: (type: TestsTableActionTypes, data?: TestListItem) => void;
	data?: TestListItem[];
	isLoading: boolean;
}

const TestsTable: React.FC<ITestsProps> = ({ data, isLoading, onAction }) => {
	const { t } = useTranslation('test');
	const dataConverted = useMemo<TestListItem[] | undefined>(
		() => data?.map((d) => ({ ...d, createdDate: t('common:dateTime', { date: new Date(d.createdDate) }) })),
		[data, t]
	);

	const transColumns = useMemo<ITableColumns<TestListItem>[]>(() => {
		const columns = [
			{ field: 'standard', header: t('Test standard') },
			{ field: 'stationName', header: t('Station name') },
			{ field: 'specimenType', header: t('Specimen type') },
			{ field: 'createdDate', header: t('Creation date') },
			{
				field: 'status',
				header: t('Status'),
				body: (data: TestListItem) => t(data.status)
			}
		] as ITableColumns<TestListItem>[];

		if (data?.some((d) => d.groups && d.groups.length > 0)) {
			columns.push({
				field: 'group',
				header: t('Group'),
				sortable: false,
				body: (data: TestListItem) => <GroupTableCell groups={data.groups} />
			});
		}
		return columns;
	}, [data, t]);

	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<TestListItem>(transColumns, dataConverted);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Duplicate'),
				icon: 't-icon-duplicate',
				disabled: true,
				command: () => onAction(TestsTableActionTypes.DUPLICATE, data)
			},
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(TestsTableActionTypes.DELETE, data)
			}
		],
		[onAction, t]
	);

	const handleCreate = useCallback(() => {
		onAction(TestsTableActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={t('Test')}>
			<PageLayout.Header title={t('Tests')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('Test')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={dataConverted}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
				data-testid="testsListTable"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Test name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(TestsTableActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="dahsboard-small"
							fontSize="1.2rem"
							title={t('Dashboard')}
							onClick={() => onAction(TestsTableActionTypes.RUN_TEST, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default React.memo(TestsTable);
