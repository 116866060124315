import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { TestMeasurementResponseDto, TestMeasurementRequestDto } from '.';
import * as api from './testMeasurements.api';

export const useTestMeasurements = (testId?: string, onSaveSuccess?: () => void) => {
	const queryClient = useQueryClient();

	const { data, isLoading } = useQuery<TestMeasurementResponseDto[], Error>({
		queryKey: ['test-measurements', { testId }],
		queryFn: () => api.getTestMeasurements(testId as string),
		enabled: !!testId
	});

	const updateItem = useCallback(
		(newMeasurement: TestMeasurementResponseDto) => {
			queryClient.setQueryData<TestMeasurementResponseDto[]>(['test-measurements', { testId }], (old) => {
				return old?.map((o) => (o.id === newMeasurement.id ? newMeasurement : o));
			});
		},
		[queryClient, testId]
	);

	const updateMutation = useMutation({
		mutationFn: async (data: { measurement: TestMeasurementRequestDto; measurementId: string }) => {
			const { id } = await api.updateTestMeasurement(data.measurement, data.measurementId);
			return await api.getTestMeasurement(id);
		},

		onSuccess: (updatedMeasurement) => {
			updateItem(updatedMeasurement);
			if (onSaveSuccess) {
				onSaveSuccess();
			}
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const saveTestMeasurement = useCallback(
		(data: TestMeasurementRequestDto, measurementId: string) => {
			if (!testId) {
				toast.error('Test id is missing for test measurement.');
				return;
			}

			updateMutation.mutate({
				measurement: data,
				measurementId
			});
		},
		[testId, updateMutation]
	);

	return useMemo(
		() => ({
			isLoading,
			isUpdating: updateMutation.isPending,
			testMeasurements: data,
			saveTestMeasurement
		}),
		[isLoading, updateMutation.isPending, data, saveTestMeasurement]
	);
};
