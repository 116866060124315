import './configs/wdyr.config';
import ReactDOM from 'react-dom/client';
import { App } from './app';

import './configs/i18next.config';
import './configs/primereact.config';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
