import { Routes, Route } from 'react-router-dom';
import ProductsContainer from './containers/ProductsContainer';
import ProductsTableContainer from './containers/ProductsTableContainer';

const ProductsRouts: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<ProductsTableContainer />} />
			<Route path="create" element={<ProductsContainer />} />
			<Route path="create/:stationId" element={<ProductsContainer />} />
			<Route path="edit/:productId" element={<ProductsContainer />} />
		</Routes>
	);
};

export default ProductsRouts;
