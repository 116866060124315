import { FC, useEffect } from 'react';
import { Dropdown, GroupInputFrame, InputNumber, InputText } from '@tactun/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEntitiesAsList, useNumberEnumList } from '../../../hooks';
import { ControlChannelResponseDto } from '../../ControlChannels';
import { useDefaultTimeUnitName } from '../../Units/units.hooks';
import { VariableResponseDto } from '../../Variables';
import { WaveformDefinitionMode, WaveformDirection, WaveformValueType } from '../waveforms.enums';
import { createControllerName, useControllerName } from '../waveforms.hooks';
import { WaveformDuoHaversineFormErrors } from '../waveforms.types';
import { getFieldByFormPrefix } from '../waveforms.tools';

export interface DuotonicHaversineFormViewProps {
	variables: VariableResponseDto[];
	controlChannel: ControlChannelResponseDto;
	formPrefix?: string;
	errors?: WaveformDuoHaversineFormErrors;
	isRunning?: boolean;
	isControlStage?: boolean;
}

const DuotonicHaversineFormView: FC<DuotonicHaversineFormViewProps> = ({
	variables,
	controlChannel,
	formPrefix,
	errors,
	isRunning,
	isControlStage = false
}) => {
	const { t } = useTranslation('waveforms');
	const {
		control,
		watch,
		setValue,
		formState: { dirtyFields }
	} = useFormContext();
	const defaultTimeUnitName = useDefaultTimeUnitName();

	const difinitionModeOptions = useNumberEnumList(WaveformDefinitionMode);
	const directionOptions = useNumberEnumList(WaveformDirection);
	const typeOptions = useNumberEnumList(WaveformValueType, 'waveforms');
	const variablesOptions = useEntitiesAsList(variables);

	const upDurationTypeCN = useControllerName('upDurationType', formPrefix);
	const downDurationTypeCN = useControllerName('downDurationType', formPrefix);
	const amplitudeTypeCN = useControllerName('amplitudeType', formPrefix);
	const meanTypeCN = useControllerName('meanType', formPrefix);
	const upDurationType = watch(upDurationTypeCN);
	const downDurationType = watch(downDurationTypeCN);
	const amplitudeType = watch(amplitudeTypeCN);
	const meanType = watch(meanTypeCN);

	const feedbackOrAxisUnit = controlChannel.feedbackMeasurement?.unit.name
		? controlChannel.feedbackMeasurement?.unit.name
		: controlChannel.axis.actuator.calibrationResponseDto?.unit?.name;

	const upDurationTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.upDurationType;
	useEffect(() => {
		if (upDurationTypeDirtyField) {
			setValue(createControllerName('upDurationValue', formPrefix), null);
			setValue(createControllerName('upDurationVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, upDurationTypeDirtyField]);

	const downDurationTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.downDurationType;
	useEffect(() => {
		if (downDurationTypeDirtyField) {
			setValue(createControllerName('downDurationValue', formPrefix), null);
			setValue(createControllerName('downDurationVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, downDurationTypeDirtyField]);

	const amplitudeTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.amplitudeType;
	useEffect(() => {
		if (amplitudeTypeDirtyField) {
			setValue(createControllerName('amplitudeValue', formPrefix), null);
			setValue(createControllerName('amplitudeVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, amplitudeTypeDirtyField]);

	const meanTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.meanType;
	useEffect(() => {
		if (meanTypeDirtyField) {
			setValue(createControllerName('meanValue', formPrefix), null);
			setValue(createControllerName('meanVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, meanTypeDirtyField]);

	return (
		<>
			<div />
			<Controller
				name={createControllerName('defMode', formPrefix)}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Definition mode*')}
						options={difinitionModeOptions}
						error={errors?.defMode?.message}
						data-testid="defMode"
						{...field}
						disabled={isRunning}
					/>
				)}
			/>
			<Controller
				name={createControllerName('direction', formPrefix)}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Starting direction*')}
						options={directionOptions}
						error={errors?.direction?.message}
						data-testid="startingDirection"
						{...field}
						disabled={isRunning}
					/>
				)}
			/>
			<Controller
				name={upDurationTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Up duration*')}
						options={typeOptions}
						data-testid="upDurationTypeCN"
						error={errors?.upDurationType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{upDurationType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('upDurationVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								label={t('Up duration variable*')}
								options={variablesOptions}
								data-testid="upDurationVariable"
								error={errors?.upDurationVariableId?.message}
								{...field}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('upDurationValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber
								data-testid="upDurationValue"
								{...field}
								label={t('Up duration constant value*')}
								error={errors?.upDurationValue?.message}
							/>
						)}
					/>
				)}
				<InputText data-testid="defaultTimeUnitName" disabled value={defaultTimeUnitName} />
			</GroupInputFrame>
			<Controller
				name={downDurationTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Down duration*')}
						options={typeOptions}
						data-testid="downDurationTypeCN"
						error={errors?.downDurationType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{downDurationType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('downDurationVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								label={t('Down duration variable*')}
								options={variablesOptions}
								data-testid="downDurationVariableId"
								error={errors?.downDurationVariableId?.message}
								{...field}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('downDurationValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber
								data-testid="downDurationValue"
								{...field}
								label={t('Down duration constant value*')}
								error={errors?.downDurationValue?.message}
							/>
						)}
					/>
				)}
				<InputText data-testid="defaultTimeUnitName" disabled value={defaultTimeUnitName} />
			</GroupInputFrame>
			<Controller
				name={amplitudeTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Amplitude*')}
						options={typeOptions}
						data-testid="amplitudeTypeCN"
						error={errors?.amplitudeType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{amplitudeType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('amplitudeVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								label={t('Amplitude variable*')}
								options={variablesOptions}
								data-testid="amplitudeVariableId"
								error={errors?.amplitudeVariableId?.message}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('amplitudeValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								label={t('Amplitude constant value*')}
								data-testid="amplitudeValue"
								error={errors?.amplitudeValue?.message}
							/>
						)}
					/>
				)}
				<InputText data-testid="feedbackOrAxisUnit" disabled value={feedbackOrAxisUnit} />
			</GroupInputFrame>
			<Controller
				name={meanTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Mean*')}
						options={typeOptions}
						data-testid="meanTypeCN"
						error={errors?.meanType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{meanType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('meanVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								data-testid="meanVariableId"
								{...field}
								label={t('Mean variable*')}
								options={variablesOptions}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('meanValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber
								data-testid="meanValue"
								{...field}
								label={t('Mean constant value*')}
								error={errors?.meanValue?.message}
							/>
						)}
					/>
				)}
				<InputText data-testid="feedbackOrAxisUnit" disabled value={feedbackOrAxisUnit} />
			</GroupInputFrame>
			<Controller
				name={createControllerName('numberOfCycles', formPrefix)}
				control={control}
				render={({ field }) => (
					<InputNumber
						data-testid="numberOfCycles"
						{...field}
						label={t('No of cycles*')}
						error={errors?.numberOfCycles?.message}
						isInteger
					/>
				)}
			/>
		</>
	);
};

export default DuotonicHaversineFormView;
