import { useMemo } from 'react';
import { hasRoles } from '../../utils/security';
import useUser from '../../hooks/useUser';
import { IUserRoles } from '../../keycloakAxios';

export interface IPrivateAccessProps {
	children: React.ReactElement;
	roles?: IUserRoles;
}

const PrivateAccess: React.FC<IPrivateAccessProps> = ({ roles, children }) => {
	const user = useUser();
	const isAuthorized = useMemo(() => {
		return user && hasRoles(roles, user.roles);
	}, [roles, user]);

	return isAuthorized ? children : null;
};

PrivateAccess.displayName = 'PrivateAccess';

export default PrivateAccess;
