import { Dropdown, GroupInputFrame, InputNumber, InputText, useIsChanged } from '@tactun/ui';
import React, { FC, useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TriggerMeasurementProperties } from '../../triggers.enums';
import { MeasurementResponseDto } from '../../../Measurements';
import { RuleTrigger } from '../../triggers.types';
import { IAxisResponseDto } from '../../../Axes';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';

export interface MeasurementFormProps {
	form: UseFormReturn<RuleTrigger>;
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	testAxes: IAxisResponseDto[];
}

const MeasurementTriggerForm: FC<MeasurementFormProps> = ({ form, testAxes, measurements }) => {
	const { t } = useTranslation();
	const {
		trigger,
		control,
		watch,
		setValue,
		formState: { errors, isDirty }
	} = form;

	const objectId = watch('objectId');
	const property = watch('property');
	const currentMeasurement = useMemo(() => {
		if (objectId) {
			return measurements.find((measurement) => measurement.id === objectId) || undefined;
		}
		return undefined;
	}, [measurements, objectId]);

	const limitsSuffix = useMemo(() => {
		return currentMeasurement?.unit.name || '';
	}, [currentMeasurement]);

	const axesOptions = useMemo(() => {
		return testAxes.map(({ id, name }) => ({ value: id, label: name }));
	}, [testAxes]);

	const lowerLimit = watch('lowerLimit');
	const upperLimit = watch('upperLimit');

	useEffect(() => {
		if (lowerLimit !== null && lowerLimit !== undefined) {
			trigger('upperLimit');
		}
	}, [lowerLimit, trigger]);

	useEffect(() => {
		if (upperLimit !== null && upperLimit !== undefined) {
			trigger('upperLimit');
		}
	}, [upperLimit, trigger]);

	const thresholdProperty =
		property === TriggerMeasurementProperties.LESS_THAN || property === TriggerMeasurementProperties.MORE_THAN;
	const isRangeProperty =
		property === TriggerMeasurementProperties.IN_RANGE || property === TriggerMeasurementProperties.OUT_OF_RANGE;
	const showCycles =
		property === TriggerMeasurementProperties.AMPLITUDE_CHANGE || property === TriggerMeasurementProperties.MEAN_CHANGE;

	const isObjectChanged = useIsChanged(objectId);
	useEffect(() => {
		if (isObjectChanged && isDirty && currentMeasurement) {
			setValue('lowerLimit', !isNaN(currentMeasurement.lowerLimit) ? currentMeasurement.lowerLimit : 0);
			setValue('upperLimit', !isNaN(currentMeasurement.upperLimit) ? currentMeasurement.upperLimit : 0);
		}
	}, [isObjectChanged, currentMeasurement, setValue, isDirty]);

	return (
		<>
			{isRangeProperty && (
				<GroupInputFrame>
					<Controller
						name="upperLimit"
						control={control}
						render={({ field }) => (
							<InputNumber
								data-testid="ruleUpperLimit"
								{...field}
								label={t('Upper Limit*')}
								error={errors.upperLimit?.message}
							/>
						)}
					/>
					<InputText data-testid="ruleUpperLimitMeasure" disabled value={limitsSuffix} />
				</GroupInputFrame>
			)}

			{isRangeProperty && (
				<GroupInputFrame>
					<Controller
						name="lowerLimit"
						control={control}
						render={({ field }) => (
							<InputNumber
								data-testid="ruleLowerLimit"
								{...field}
								label={t('Lower Limit*')}
								error={errors.lowerLimit?.message}
							/>
						)}
					/>
					<InputText data-testid="ruleLowerLimitMeasure" disabled value={limitsSuffix} />
				</GroupInputFrame>
			)}

			{thresholdProperty && (
				<GroupInputFrame>
					<Controller
						name="threshold"
						control={control}
						render={({ field }) => (
							<InputNumber
								data-testid="ruleThreshold"
								{...field}
								label={t('Threshold*')}
								error={errors.threshold?.message}
							/>
						)}
					/>
					<InputText data-testid="ruleThresholdMeasure" disabled value={limitsSuffix} />
				</GroupInputFrame>
			)}

			{property === TriggerMeasurementProperties.AMPLITUDE_CHANGE && (
				<GroupInputFrame>
					<Controller
						name="amplitudeChange"
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								label={t('Amplitude change*')}
								data-testid="ruleAmplitudeChange"
								error={errors.amplitudeChange?.message}
							/>
						)}
					/>
					<InputText data-testid="ruleAmplitudeChangeMeasure" disabled value="%" />
				</GroupInputFrame>
			)}

			{property === TriggerMeasurementProperties.MEAN_CHANGE && (
				<GroupInputFrame>
					<Controller
						name="maxMeanShift"
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								label={t('Maximum Mean Shift*')}
								data-testid="ruleMeanShift"
								error={errors.maxMeanShift?.message}
							/>
						)}
					/>
					<InputText data-testid="ruleMeanShiftMeasure" disabled value={limitsSuffix} />
				</GroupInputFrame>
			)}

			{showCycles && (
				<>
					<Controller
						name="delayCycles"
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								min={0}
								isInteger
								label={t('Delay cycles*')}
								data-testid="ruleDelayCycles"
								error={errors.delayCycles?.message}
							/>
						)}
					/>
					<Controller
						name="changeCycles"
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								min={0}
								isInteger
								data-testid="ruleChangeCycles"
								label={t('Change cycles*')}
								error={errors.changeCycles?.message}
							/>
						)}
					/>
					<Controller
						name="referenceCycles"
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								min={0}
								isInteger
								data-testid="ruleRefCycles"
								label={t('Reference cycles*')}
								error={errors.referenceCycles?.message}
							/>
						)}
					/>
					<Controller
						name="referenceAxis"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={axesOptions}
								data-testid="ruleRefAxis"
								label={t('Reference Axis*')}
								error={errors.referenceAxis?.message}
							/>
						)}
					/>
				</>
			)}
		</>
	);
};

export default MeasurementTriggerForm;
