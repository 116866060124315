import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IStep, normalizePath, useWizard } from '@tactun/ui';
import TestInformation from '../components/TestInformation';
import { testSchema } from '../tests.schemas';
import { TestInformationFormType, TestInformationRequestDto, TestInformationResponseDto } from '../tests.types';
import * as api from '../tests.api';
import { formRequestConverter, responseFormConverter } from '../tests.converters';
import { UnitSystemTypes, useQuantities, useUnitSystemsOptions } from '../../Units';
import { useEntitiesAsList } from '../../../hooks';
import { useStation, useStations } from '../../Stations';
import { useAxes } from '../../Axes/axes.hooks';
import { useSpecimenTypes, useSpecimenType } from '../../SpecimenTypes';
import { useDataStorageTypeOptions, useStorageFullBehaviorTypeOptions, useTestStandards } from '../tests.hooks';
import { DataStorageTypes, StorageFullBehaviorTypes } from '../tests.enums';
import { generalInfoCreatePath, generalInfoPath } from '../tests.const';

const defaultValues = {
	id: undefined,
	name: '',
	standard: '',
	station: '',
	axis: [],
	specimenType: '',
	unitSystem: UnitSystemTypes.METRIC,
	timeUnit: '',
	dataStorage: DataStorageTypes.SD_CARD,
	storageFullBehavior: StorageFullBehaviorTypes.CONTINUE_TEST,
	description: ''
};

const TestInformationContainer: React.FC = () => {
	const { testId } = useParams();
	const form = useForm<TestInformationFormType>({
		defaultValues: defaultValues,
		mode: 'onBlur',
		resolver: yupResolver(testSchema)
	});
	const {
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { isDirty, dirtyFields }
	} = form;
	const { beforeNextStep, activeStep, steps, goToStep } = useWizard();
	const isCreate = !testId;

	const { data: currentTest } = useQuery<TestInformationResponseDto, Error>({
		queryKey: ['test', testId],
		queryFn: () => api.getTestById(testId),
		enabled: !!testId
	});

	const createMutation = useMutation({
		mutationKey: ['saveTest'],
		mutationFn: (test: TestInformationRequestDto) => api.createTest(test),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationKey: ['saveTest'],
		mutationFn: (data: { test: TestInformationRequestDto; testId: string }) => api.updateTest(data.test, data.testId),

		onError: (e: Error) => toast.error(e.message)
	});

	const { quantities } = useQuantities('TIME');
	const timeUnitsOptions = useEntitiesAsList(quantities?.length ? quantities[0].units : []);

	const { stations } = useStations();
	const stationsOptions = useEntitiesAsList(stations);

	const stationId = watch('station');
	const { axes } = useAxes(stationId);
	const axesOptions = useEntitiesAsList(axes);

	const { stationDto: selectedStation } = useStation(stationId);

	const { specimenTypes } = useSpecimenTypes();
	const specimensForStation = useMemo(() => {
		return specimenTypes?.filter((st) => selectedStation?.specimenTypes?.some((st2) => st2.id === st.id)) || [];
	}, [specimenTypes, selectedStation]);
	const specimenTypesOptions = useEntitiesAsList(specimensForStation);

	const { unitSystems } = useUnitSystemsOptions(false);
	const { dataStorageTypes } = useDataStorageTypeOptions();
	const { storageFullBehaviorTypes } = useStorageFullBehaviorTypeOptions();

	const { testStandards } = useTestStandards();

	const specimenId = watch('specimenType');
	const { specimenType } = useSpecimenType(specimenId);

	//init current test for edit
	useEffect(() => {
		if (currentTest) {
			reset({ ...responseFormConverter(currentTest) }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultValues }, { keepDirty: true, keepTouched: true });
		}
	}, [currentTest, reset]);

	useEffect(() => {
		setValue('imageId', specimenType?.imageId);
	}, [setValue, specimenType]);

	useEffect(() => {
		if (dirtyFields.station) {
			setValue('axis', []);
		}
	}, [stationId, dirtyFields, setValue]);

	// handle test save
	beforeNextStep((step: IStep) => {
		if (step.path === generalInfoPath || step.path === generalInfoCreatePath) {
			return new Promise<boolean>((resolve, reject) => {
				handleSubmit(
					async (data) => {
						const testRequest = formRequestConverter(data);
						try {
							if (testId) {
								if (isDirty) {
									await updateMutation.mutateAsync({ test: testRequest, testId });
								}
								resolve(true);
							} else {
								const newTest = await createMutation.mutateAsync(testRequest);

								let nextStep = steps[activeStep + 1];
								nextStep = { ...nextStep };
								nextStep.path = normalizePath(nextStep.path, { testId: newTest.id });
								goToStep(nextStep);
								resolve(false);
							}
						} catch {
							reject(false);
						}
					},
					() => {
						// do not go to next step if validation is failed
						resolve(false);
					}
				)();
			});
		}
		return Promise.resolve(true);
	});

	return (
		<TestInformation
			isCreate={isCreate}
			form={form}
			timeUnits={timeUnitsOptions}
			stations={stationsOptions}
			axis={axesOptions}
			specimenTypes={specimenTypesOptions}
			unitSystems={unitSystems}
			dataStorageTypes={dataStorageTypes}
			storageFullBehaviorTypes={storageFullBehaviorTypes}
			testStandards={testStandards}
			specimenImageUrl={specimenType?.imageUrl}
		/>
	);
};

export default React.memo(TestInformationContainer);
