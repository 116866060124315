import { IconButton } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import styles from './ConnectionBadge.module.scss';

export interface IConnectionBadgeProps {
	isConnected?: boolean;
	onToggleConnection: () => void;
}

const ConnectionBadge = ({ isConnected, onToggleConnection }: IConnectionBadgeProps) => {
	const { t } = useTranslation('connection');
	const iconName = isConnected ? 'connection-connected' : 'connection-disconnected';
	const title = isConnected ? t('Connected') : t('Disconnected');

	return (
		<div className={styles.container}>
			<IconButton icon={iconName} fontSize="32px" title={title} onClick={onToggleConnection} />
		</div>
	);
};

export default ConnectionBadge;
