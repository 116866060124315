import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@tactun/ui';
import * as api from '../../testDashboardConfiguration.api';
import { ReadoutRequestDto, ReadoutResponseDto } from '../../testDashboardConfiguration.types';
import styles from './CreateReadoutModal.module.scss';
import { readoutFormRequestConverter, readoutResponseFormConverter } from '../../testDashboardConfiguration.converter';
import { deleteEmptyFields } from '../../../../tools';
import { useReadout } from '../../testDashboardConfiguration.hooks';
import ReadoutForm from '../ReadoutForm';
import { Modals } from '../../testDashboardConfiguration.enums';
import { readoutDefaultValues } from '../../../ReadoutWidget/readout.constants';
import { ReadoutFormType } from '../../../ReadoutWidget/readout.types';
import { readoutFormSchema } from '../../../ReadoutWidget/readout.schemas';

interface CreateReadoutModalProps {
	readoutId?: string;
	testId: string;
	stationId: string;
	onSaveSuccess: (readout: ReadoutResponseDto) => void;
	onSaveError: (e: Error) => void;
	handleClose: () => void;
}

const CreateReadoutModal: React.FC<CreateReadoutModalProps> = ({
	readoutId,
	onSaveSuccess,
	stationId,
	handleClose,
	onSaveError,
	testId
}) => {
	const { t } = useTranslation('dashboardConfiguration');
	const { readout } = useReadout<ReadoutFormType>(readoutId, readoutResponseFormConverter);

	const isEdit = !!readoutId;
	const createMutation = useMutation({
		mutationKey: ['saveReadout'],
		mutationFn: (readout: ReadoutRequestDto) => api.createReadout(readout),
		onSuccess: onSaveSuccess,
		onError: onSaveError
	});

	const updateMutation = useMutation({
		mutationKey: ['saveReadout'],
		mutationFn: ({ readout, readoutId }: { readout: ReadoutRequestDto; readoutId: string }) =>
			api.updateReadout(readout, readoutId),

		onSuccess: onSaveSuccess,
		onError: onSaveError
	});
	const form = useForm({
		mode: 'onBlur',
		defaultValues: { ...readoutDefaultValues, testId },
		resolver: yupResolver(readoutFormSchema)
	});
	const { handleSubmit, reset } = form;

	// handel readout add modal close
	const onClose = useCallback(() => {
		handleClose();
	}, [handleClose]);

	const handleSave = useCallback(() => {
		handleSubmit((data) => {
			const filteredData = deleteEmptyFields<ReadoutRequestDto>(readoutFormRequestConverter(data));
			if (readoutId) {
				updateMutation.mutate({ readout: filteredData, readoutId });
			} else {
				createMutation.mutate(filteredData);
			}
		})();
	}, [handleSubmit, readoutId, updateMutation, createMutation]);

	const handleCleanup = useCallback(() => {
		reset({ ...readoutDefaultValues, testId });
	}, [reset, testId]);

	useEffect(() => {
		if (readout) {
			reset(readout);
		}
	}, [reset, readout]);

	return (
		<Modal id={Modals.CREATE_READOUT_MODAL} className={styles.modal} onClose={handleClose} onAfterClose={handleCleanup}>
			<Modal.Header>{isEdit ? t('Edit a Readout') : t('Create new Readout')}</Modal.Header>
			<Modal.Content>
				{stationId && <ReadoutForm stationId={stationId} form={form} isEdit={isEdit} onSave={onClose} />}
			</Modal.Content>
			<Modal.Footer>
				<Button onClick={onClose} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button onClick={handleSave} color="secondary" variant="contained">
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(CreateReadoutModal);
