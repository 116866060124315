import React, { ReactNode } from 'react';
import { PageLayout, Button, BottomBar, InfoBadge, InfoBadgeIcons } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import styles from './CreateEditPageTemplate.module.scss';

interface CreateEditPageTemplateProps {
	isLoading: boolean;
	title: string;
	stationName: string;
	formId: string;
	children: ReactNode;
	onBack: () => void;
}

const CreateEditPageTemplate: React.FC<CreateEditPageTemplateProps> = ({
	isLoading,
	title,
	stationName,
	formId,
	children,
	onBack
}) => {
	const { t } = useTranslation('common');

	return (
		<>
			<PageLayout info={stationName}>
				<PageLayout.Header title={title} onBack={onBack} />
				<div className={styles.container}>{children}</div>
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button label={t('Cancel')} data-testid="cancelBtn" variant="text" color="success" onClick={onBack} />
				<Button
					label={t('Save')}
					data-testid="saveBtn"
					variant="contained"
					color="secondary"
					type="submit"
					form={formId}
					disabled={isLoading}
				/>
			</BottomBar>
		</>
	);
};

export default React.memo(CreateEditPageTemplate);
