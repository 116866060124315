export enum Actions {
	INIT = 'INIT',
	CHANGE_TOOL = 'CHANGE_TOOL',
	CHANGE_COLOR = 'CHANGE_COLOR',
	CLEAR_PLOTS = 'CLEAR_PLOTS',
	DELETE_PLOT = 'DELETE_PLOT',
	TOGGLE_PLOT_VISIBILITY = 'TOGGLE_PLOT_VISIBILITY',
	SET_MINIMIZED_VIEW_STATE = 'SET_MINIMIZED_VIEW_STATE',
	TOGGLE_GRAPH_STATE = 'TOGGLE_GRAPH_STATE',
	UPDATE_SETTINGS = 'UPDATE_SETTINGS',
	ADD_PLOT = 'ADD_PLOT'
}

export enum GraphTools {
	PAUSE,
	RESUME,
	CURSOR,
	MOVE,
	SNAPSHOT,
	ZOOM_X,
	ZOOM_Y,
	ZOOM_XY,
	ZOOM_RESET,
	CLEAR,
	REFRESH
}

export enum ScaleTypes {
	LOGARITHMIC = 'logarithmic',
	LINEAR = 'linear'
}

export enum Axis {
	X = 'x',
	LEFT_Y = 'left_y_axis',
	RIGHT_Y = 'right_y_axis'
}

export enum Modals {
	CREATE_PLOT_MODAL = 'CREATE_PLOT_MODAL',
	COLOR_PICKER_MODAL = 'COLOR_PICKER_MODAL',
	SNAPSHOT_MODAL = 'SNAPSHOT_MODAL'
}
