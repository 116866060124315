import axios from '../../httpCommon';
import { CustomWidgetDto } from './customWidget.types';

export const getStationWidgets = async (stationId?: string): Promise<CustomWidgetDto[]> => {
	const { data } = await axios.get(`/cassiopeia/button-widget/stations/${stationId}`);

	return data.response.data as CustomWidgetDto[];
};

export const getWidget = async (id: string): Promise<CustomWidgetDto> => {
	const { data } = await axios.get(`/cassiopeia/button-widget/${id}`);

	return data.response as CustomWidgetDto;
};

export const createWidget = async (widget: CustomWidgetDto): Promise<CustomWidgetDto> => {
	const response = await axios.post('/cassiopeia/button-widget', widget);

	return response.data as CustomWidgetDto;
};

export const updateWidget = async (widget: CustomWidgetDto): Promise<CustomWidgetDto> => {
	const response = await axios.put('/cassiopeia/button-widget', widget);

	return response.data as CustomWidgetDto;
};

export const checkName = async (name: string, stationUuid?: string, id?: string): Promise<boolean> => {
	const response = await axios.get('/cassiopeia/button-widget/isNameUnique', {
		params: {
			id,
			stationUuid,
			name
		}
	});
	return response.data.response.exists !== true;
};
