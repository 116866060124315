import { ReadoutFormType, ReadoutMetadata } from './readout.types';
import { TareRequest } from '@tactun/grpc-client';

export const readoutFormToWidgetSettings = (form: ReadoutFormType): ReadoutMetadata => {
	return {
		name: form.name ?? '',
		precision: form.precision ?? 0,
		max: form.max ?? false,
		min: form.min ?? false,
		rate: form.rate ?? false,
		tare: form.tare ?? false
	};
};

export const tareToTareGRPCConverter = (id: string, value: number | null) => {
	const tareRequest = new TareRequest();
	if (value !== null) {
		tareRequest.setValue(value);
	}
	tareRequest.setId(id);

	return tareRequest;
};
