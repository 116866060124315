export const getUsers = async (): Promise<Record<string, string>[]> => {
	return new Promise((resolve) =>
		resolve([
			{
				id: '1',
				username: 'John Smith',
				role: 'Operator',
				addedDate: '12/04/2022, 13:04:56',
				lastAccessedDate: '12/04/2023, 13:04:56',
				status: 'DISABLED'
			},
			{
				id: '2',
				username: 'George White',
				role: 'Test Engineer',
				addedDate: '12/06/2022, 13:04:56',
				lastAccessedDate: '12/04/2023, 13:04:56',
				status: 'ACTIVE'
			},
			{
				id: '3',
				username: 'Jane Smith',
				role: 'Test Engineer',
				addedDate: '29/05/2021, 13:04:56',
				lastAccessedDate: '12/04/2023, 13:04:56',
				status: 'ACTIVE'
			},
			{
				id: '4',
				username: 'Tom Granger',
				role: 'Administrator',
				addedDate: '12/06/2022, 13:04:56',
				lastAccessedDate: '12/04/2023, 13:04:56',
				status: 'ACTIVE'
			},
			{
				id: '5',
				username: 'Helen Swift',
				role: 'Operator',
				addedDate: '11/06/2023, 13:04:56',
				lastAccessedDate: '12/04/2023, 13:04:56',
				status: 'ACTIVE'
			},
			{
				id: '6',
				username: 'David Orwell',
				role: 'Test Engineer',
				addedDate: '10/06/2021, 13:04:56',
				lastAccessedDate: '12/04/2023, 13:04:56',
				status: 'ACTIVE'
			},
			{
				id: '7',
				username: 'Mary Simpson',
				role: 'Test Engineer',
				addedDate: '04/06/2021, 13:04:56',
				lastAccessedDate: '12/04/2023, 13:04:56',
				status: 'ACTIVE'
			}
		])
	);
};

export const getUser = async (id: string): Promise<Record<string, string>> => {
	return new Promise((resolve) =>
		resolve({
			id: '2',
			username: 'George White',
			role: 'Test Engineer',
			addedDate: '12/06/2023, 13:04:56',
			lastAccessedDate: '12/04/2023, 13:04:56',
			status: 'ACTIVE'
		})
	);
};
