export enum FrameOrientation {
	VERTICAL,
	HORIZONTAL
}

export enum ButtonShape {
	RECTANGULAR,
	ROUND
}

export enum ButtonDefaultState {
	PRESSED,
	RELEASED
}

export enum ButtonStatus {
	CONFIGURED,
	NOT_CONFIGURED
}
