import * as yup from 'yup';
import { alphaNumericRegex } from '../../constants/regexes';
import { emptyStringToNull } from '../../tools/yupHelpers';
import * as api from './calculation.api';
import { STATISTICAL_TYPES_LIST } from './calculation.const';
import {
	CalculationChannelTypes,
	CalculationTypes,
	LimitDefinitions,
	LimitTypes,
	ReferencePointTypes
} from './calculation.enums';

export const calculationSchema = yup
	.object({
		id: yup.string(),
		name: yup
			.string()
			.required('Name is a required field')
			.max(30)
			.matches(alphaNumericRegex, 'Is not in correct format')
			.test('checkCalculationUniqueness', 'Name is used', function (value, form) {
				if (!value) return true;
				return api.checkName(value, form?.parent?.stationId || form?.parent?.testId, form?.parent?.id);
			}),
		type: yup.mixed<CalculationTypes>().required(),
		stationId: yup.string().required(),
		testId: yup.string(),
		successFailCriteria: yup.boolean(),
		limitDefinition: yup.mixed<LimitDefinitions>().notRequired(),
		limitUnit: yup.string(),
		measurementId1: yup.string().when(['type'], {
			is: (type: CalculationTypes) => type !== CalculationTypes.DATA_POINT,
			then: (schema) => schema.required('Required field'),
			otherwise: (schema) => schema.notRequired()
		}),
		measurementOrAxesId1: yup.string().when(['type'], {
			is: (type: CalculationTypes) => type === CalculationTypes.DATA_POINT,
			then: (schema) => schema.required('Data Channel is a required field')
		}),
		refPoint: yup.mixed<ReferencePointTypes>().when(['type'], {
			is: (type: CalculationTypes) => type === CalculationTypes.DATA_POINT,
			then: (schema) => schema.required('Reference Point is a required field')
		}),
		measurementId2: yup.string().when(['type'], {
			is: (type: CalculationTypes) => {
				return (
					type === CalculationTypes.ENERGY ||
					type === CalculationTypes.MEASUREMENT_AT_PEAK_LOAD ||
					type === CalculationTypes.YIELD_OFFSET ||
					type === CalculationTypes.MEASUREMENT_AT_YIELD ||
					type === CalculationTypes.MODULUS_OF_ELASTICITY
				);
			},
			then: (schema) => schema.required('Required field')
		}),
		measurementId3: yup.string().when(['type'], {
			is: (type: CalculationTypes) => type === CalculationTypes.MEASUREMENT_AT_YIELD,
			then: (schema) => schema.required('Required field')
		}),
		measurementOrAxesId2: yup.string().when(['type', 'refPoint', 'ch2Type'], {
			is: (type: CalculationTypes, refPoint: ReferencePointTypes, ch2Type: CalculationChannelTypes) => {
				return (
					(type === CalculationTypes.DATA_POINT && refPoint === ReferencePointTypes.REFERENCE_CHANNEL) ||
					(ch2Type === CalculationChannelTypes.MEASUREMENT && !STATISTICAL_TYPES_LIST.some((st) => st === type))
				);
			},
			then: (schema) => schema.required('Reference channel is a required field')
		}),
		refValue: yup
			.number()
			.transform(emptyStringToNull)
			.when(['type', 'refPoint'], {
				is: (type: CalculationTypes, refPoint: ReferencePointTypes) =>
					type === CalculationTypes.DATA_POINT && refPoint === ReferencePointTypes.REFERENCE_CHANNEL,
				then: (schema) => schema.required('Reference value is a required field'),
				otherwise: (schema) => schema.nullable()
			}),
		upperLimit: yup
			.number()
			.transform(emptyStringToNull)
			.when(['limitDefinition'], {
				is: (type: LimitDefinitions) => type === LimitDefinitions.ABSOLUTE,
				then: (schema) => schema.required(),
				otherwise: (schema) => schema.nullable()
			})
			.test('upperLimitMoreThen', function (value, form) {
				const lowerLimit = form.parent.lowerLimit;
				if (typeof value === 'number' && typeof lowerLimit === 'number' && value <= lowerLimit) {
					return this.createError({ message: `Upper limit must be greater than lower limit ${lowerLimit}` });
				}
				return true;
			}),
		lowerLimit: yup
			.number()
			.transform(emptyStringToNull)
			.when(['limitDefinition'], {
				is: (type: LimitDefinitions) => type === LimitDefinitions.ABSOLUTE,
				then: (schema) => schema.required(),
				otherwise: (schema) => schema.nullable()
			})
			.test('lowerLimitLowerThen', function (value, form) {
				const upperLimit = form.parent.upperLimit;
				if (typeof value === 'number' && typeof upperLimit === 'number' && value >= upperLimit) {
					return this.createError({ message: `Lower limit must be less than ${upperLimit}` });
				}
				return true;
			}),
		nominalValue: yup
			.number()
			.transform(emptyStringToNull)
			.when(['limitDefinition'], {
				is: (type: LimitDefinitions) => type === LimitDefinitions.NOMINAL,
				then: (schema) => schema.required('Nominal value is a required field'),
				otherwise: (schema) => schema.nullable()
			}),
		variation: yup
			.number()
			.transform(emptyStringToNull)
			.when(['limitDefinition'], {
				is: (type: LimitDefinitions) => type === LimitDefinitions.NOMINAL,
				then: (schema) => schema.required('Variation is a required field'),
				otherwise: (schema) => schema.nullable()
			}),
		unit: yup.string().when(['type', 'ch1Type'], {
			is: (type: CalculationTypes, ch1Type: CalculationChannelTypes) =>
				(type !== CalculationTypes.DATA_POINT && ch1Type === CalculationChannelTypes.MEASUREMENT) ||
				type === CalculationTypes.MODULUS_OF_ELASTICITY ||
				type === CalculationTypes.MEASUREMENT_AT_YIELD ||
				type === CalculationTypes.ENERGY ||
				type === CalculationTypes.YIELD_OFFSET ||
				type === CalculationTypes.MINIMUM ||
				type === CalculationTypes.MEAN ||
				type === CalculationTypes.MAXIMUM,
			then: (schema) => schema.required('Unit is a required field')
		}),
		unit2: yup.string().when(['type'], {
			is: (type: CalculationTypes) => type === CalculationTypes.MEASUREMENT_AT_YIELD,
			then: (schema) => schema.required('Stress Measurementn Unit is a required field'),
			otherwise: (schema) => schema.nullable()
		}),
		statCalcRange: yup
			.number()
			.transform(emptyStringToNull)
			.when(['type'], {
				is: (type: CalculationTypes) =>
					type === CalculationTypes.MAXIMUM || type === CalculationTypes.MINIMUM || type === CalculationTypes.MEAN,
				then: (schema) => schema.required('Range is a required field'),
				otherwise: (schema) => schema.nullable()
			}),
		limitType: yup
			.number()
			.transform(emptyStringToNull)
			.when(['type'], {
				is: (type: CalculationTypes) =>
					type === CalculationTypes.MODULUS_OF_ELASTICITY ||
					type === CalculationTypes.YIELD_OFFSET ||
					type === CalculationTypes.MEASUREMENT_AT_YIELD,
				then: (schema) => schema.required('Limit specification is a required field'),
				otherwise: (schema) => schema.nullable()
			}),
		yieldUpperLimit: yup
			.number()
			.transform(emptyStringToNull)
			.when(['type', 'limitType'], {
				is: (type: CalculationTypes, limitType: LimitTypes) =>
					(type === CalculationTypes.YIELD_OFFSET ||
						type === CalculationTypes.MODULUS_OF_ELASTICITY ||
						type === CalculationTypes.MEASUREMENT_AT_YIELD) &&
					limitType !== LimitTypes.AUTO,
				then: (schema) => schema.required('Upper limit is a required field'),
				otherwise: (schema) => schema.nullable()
			})
			.test('yieldUpperLimitMoreThen', function (value, form) {
				const yieldLowerLimit = form.parent.yieldLowerLimit;
				if (typeof value === 'number' && typeof yieldLowerLimit === 'number' && value <= yieldLowerLimit) {
					return this.createError({ message: `Upper limit must be greater than lower limit ${yieldLowerLimit}` });
				}
				return true;
			}),
		yieldLowerLimit: yup
			.number()
			.transform(emptyStringToNull)
			.when(['type', 'limitType'], {
				is: (type: CalculationTypes, limitType: LimitTypes) =>
					(type === CalculationTypes.YIELD_OFFSET ||
						type === CalculationTypes.MODULUS_OF_ELASTICITY ||
						type === CalculationTypes.MEASUREMENT_AT_YIELD) &&
					limitType !== LimitTypes.AUTO,
				then: (schema) => schema.required('Lower limit is a required field'),
				otherwise: (schema) => schema.nullable()
			})
			.test('yieldLowerLimitLessThen', function (value, form) {
				const yieldUpperLimit = form.parent.yieldUpperLimit;
				if (typeof value === 'number' && typeof yieldUpperLimit === 'number' && value >= yieldUpperLimit) {
					return this.createError({ message: `Lower limit must be less than ${yieldUpperLimit}` });
				}
				return true;
			}),
		typeOffset: yup
			.number()
			.transform(emptyStringToNull)
			.when(['type'], {
				is: (type: CalculationTypes) =>
					type === CalculationTypes.YIELD_OFFSET || type === CalculationTypes.MEASUREMENT_AT_YIELD,
				then: (schema) => schema.required('Offset is a required field'),
				otherwise: (schema) => schema.nullable()
			}),
		ch1Type: yup.mixed<CalculationChannelTypes>().notRequired(),
		ch2Type: yup.mixed<CalculationChannelTypes>().notRequired(),
		refUnit: yup.string(),
		startVal: yup.number(),
		startValUnit: yup.string(),
		endVal: yup.number(),
		endValUnit: yup.string()
	})
	.required();
