import { useMemo } from 'react';
import cx from 'classnames';
import { PrimeIcons } from 'primereact/api';
import styles from './InfoBadge.module.scss';

export enum InfoBadgeIcons {
	loading = 'loading',
	checkmark = 'checkmark'
}

export interface IInfoBadgeProps {
	children?: React.ReactNode;
	icon?: InfoBadgeIcons;
}

const InfoBadge: React.FC<IInfoBadgeProps> = ({ children, icon }) => {
	const iconClass = useMemo(() => {
		switch (icon) {
			case InfoBadgeIcons.checkmark:
				return cx(PrimeIcons.CHECK, styles.icon, styles.whiteIcon);
			case InfoBadgeIcons.loading:
				return cx(PrimeIcons.SPINNER, 'pi-spin', styles.icon);
		}
	}, [icon]);

	return (
		<span className={styles.container}>
			{icon && (
				<span className={cx(styles.iconWrap, { [styles.greenBack]: icon === InfoBadgeIcons.checkmark })}>
					<i className={iconClass} />
				</span>
			)}
			{children}
		</span>
	);
};

export default InfoBadge;
