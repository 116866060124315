import { Converter } from '../../types';
import { WidgetsLayoutTypes, WidgetsStatuses, WidgetsTypes } from './customWidget.enums';
import { CustomWidgetDto, CustomWidgetsListItem, ICustomWidgetForm } from './customWidget.types';
import {
	buttonWidgetDtoToStateVariables,
	buttonWidgetRuleDtoToRuleDto,
	buttonWidgetSubFrameDtoToFormConverter,
	buttonWidgetSubFrameFormToButtonWidgetSubFrameDtoConverter
} from '../ButtonWidget/buttonWidget.converters';
import { VariableResponseDto } from '../Variables';
import { StationRuleResponseDto } from '../Rules';
import { ButtonStatus } from '../ButtonWidget/buttonWidget.enums';

export const responseListItemConverter: Converter<CustomWidgetDto, CustomWidgetsListItem> = (response) => {
	return {
		id: response.uuid,
		name: response.name,
		type: WidgetsTypes[response.type],
		status: WidgetsStatuses[response.status]
	} as CustomWidgetsListItem;
};

export const sizeToSubframeSizes = (width: number, height: number, layoutType: WidgetsLayoutTypes) => {
	switch (layoutType) {
		case WidgetsLayoutTypes.SINGLE:
			return [[100, 100]];
		case WidgetsLayoutTypes.DOUBLE_VERTICAL:
			return [
				[100, height],
				[100, 100 - height]
			];
		case WidgetsLayoutTypes.DOUBLE_HORIZONTAL:
			return [
				[width, 100],
				[100 - width, 100]
			];
		case WidgetsLayoutTypes.QUADRO:
			return [
				[width, height],
				[100 - width, height],
				[width, 100 - height],
				[100 - width, 100 - height]
			];
	}
};

export const responseFormConverter: Converter<CustomWidgetDto, ICustomWidgetForm> = (input) => {
	switch (input.type) {
		case WidgetsTypes.BUTTON_WIDGET:
			return {
				id: input.uuid,
				name: input.name,
				width: input.width,
				height: input.height,
				stationId: input.stationUuid,
				type: input.type,
				layoutType: input.layoutTemplate,
				subFrameSize: [input.subframes?.[0]?.width || 100, input.subframes?.[0]?.height || 100],
				subFrames: input.subframes.map((v) => buttonWidgetSubFrameDtoToFormConverter(v))
			};
	}
};

export const formRequestConverter: Converter<ICustomWidgetForm, CustomWidgetDto> = (input) => {
	switch (input.type) {
		case WidgetsTypes.BUTTON_WIDGET: {
			const [subFrameWidth = 100, subFrameHeight = 100] = input.subFrameSize || [];
			const subframeSizes = sizeToSubframeSizes(subFrameWidth, subFrameHeight, input.layoutType);
			const subFrames =
				input.subFrames?.map((v, i) => {
					const [width, height] = subframeSizes[i];
					return buttonWidgetSubFrameFormToButtonWidgetSubFrameDtoConverter(v, { width, height });
				}) || [];
			const configured = subFrames.every(
				(subFrame) =>
					!!subFrame.buttons?.length && subFrame.buttons.every((button) => button.status === ButtonStatus.CONFIGURED)
			);

			return {
				uuid: input.id,
				name: input.name,
				width: input.width,
				height: input.height,
				stationUuid: input.stationId,
				type: input.type,
				status: configured ? WidgetsStatuses.CONFIGURED : WidgetsStatuses.NOT_CONFIGURED,
				layoutTemplate: input.layoutType,
				subframes: subFrames
			};
		}
	}
};

export const customWidgetDtoToStateVariables: Converter<CustomWidgetDto, VariableResponseDto[]> = (widget) => {
	switch (widget.type) {
		case WidgetsTypes.BUTTON_WIDGET:
			return buttonWidgetDtoToStateVariables(widget);
	}
};

export const customWidgetRuleDtoToRuleDto: Converter<CustomWidgetDto, StationRuleResponseDto[]> = (widget) => {
	switch (widget.type) {
		case WidgetsTypes.BUTTON_WIDGET:
			return buttonWidgetRuleDtoToRuleDto(widget);
	}
};
