import React from 'react';
import { Button } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import styles from './CrudActions.module.scss';

export interface ICrudActionsProps {
	entityLabel?: string;
	isCreateDisabled?: boolean;
	onCreate?: () => void;
}

const CrudActions: React.FC<ICrudActionsProps> = ({ onCreate, entityLabel, isCreateDisabled }) => {
	const { t } = useTranslation();
	return (
		<>
			{onCreate && (
				<Button
					data-testid="createBtn"
					variant="contained"
					color="primary"
					icon={'t-icon-plus'}
					className={styles.addBtn}
					label={entityLabel ? t('create_typed', { type: entityLabel }) : t('Create')}
					onClick={onCreate}
					disabled={isCreateDisabled}
				/>
			)}
		</>
	);
};
export default React.memo(CrudActions);
