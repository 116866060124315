export const initIntervalManager = (interval: number) => {
	let timeoutId: NodeJS.Timeout | undefined;
	let _callback: (() => void) | undefined;

	const restart = () => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		if (_callback) {
			timeoutId = setTimeout(_callback, interval);
		}
	};

	const clear = () => {
		if (timeoutId) {
			clearTimeout(timeoutId);
			timeoutId = undefined;
			_callback = undefined;
		}
	};

	const start = (callback: () => void) => {
		_callback = callback;
		restart();
	};

	return {
		start,
		restart,
		clear
	};
};
