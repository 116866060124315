// source: rule.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var error_pb = require('./error_pb.js');
goog.object.extend(proto, error_pb);
goog.exportSymbol('proto.rule.ActionDto', null, global);
goog.exportSymbol('proto.rule.ActionMeasurementProp', null, global);
goog.exportSymbol('proto.rule.ActionStageProp', null, global);
goog.exportSymbol('proto.rule.ActionStationState', null, global);
goog.exportSymbol('proto.rule.ActionTestProp', null, global);
goog.exportSymbol('proto.rule.ActionType', null, global);
goog.exportSymbol('proto.rule.ActionTypeAnalogOutput', null, global);
goog.exportSymbol('proto.rule.ActionTypeAnalogOutput.AoActionCase', null, global);
goog.exportSymbol('proto.rule.ActionTypeAxis', null, global);
goog.exportSymbol('proto.rule.ActionTypeAxis.AxisActionTypeCase', null, global);
goog.exportSymbol('proto.rule.ActionTypeCalc', null, global);
goog.exportSymbol('proto.rule.ActionTypeControl', null, global);
goog.exportSymbol('proto.rule.ActionTypeControlDisable', null, global);
goog.exportSymbol('proto.rule.ActionTypeDO', null, global);
goog.exportSymbol('proto.rule.ActionTypeMeas', null, global);
goog.exportSymbol('proto.rule.ActionTypeOffsetCalibration', null, global);
goog.exportSymbol('proto.rule.ActionTypeSpecimen', null, global);
goog.exportSymbol('proto.rule.ActionTypeStage', null, global);
goog.exportSymbol('proto.rule.ActionTypeStation', null, global);
goog.exportSymbol('proto.rule.ActionTypeTest', null, global);
goog.exportSymbol('proto.rule.ActionTypeVariable', null, global);
goog.exportSymbol('proto.rule.AnalogOutputActionType', null, global);
goog.exportSymbol('proto.rule.AnalogOutputConstant', null, global);
goog.exportSymbol('proto.rule.AnalogOutputMeasurement', null, global);
goog.exportSymbol('proto.rule.AuxDeviceProp', null, global);
goog.exportSymbol('proto.rule.AuxDeviceStateProp', null, global);
goog.exportSymbol('proto.rule.AxisActionProp', null, global);
goog.exportSymbol('proto.rule.AxisDisable', null, global);
goog.exportSymbol('proto.rule.AxisEnable', null, global);
goog.exportSymbol('proto.rule.AxisLink', null, global);
goog.exportSymbol('proto.rule.AxisPidReset', null, global);
goog.exportSymbol('proto.rule.AxisTare', null, global);
goog.exportSymbol('proto.rule.AxisUnlink', null, global);
goog.exportSymbol('proto.rule.BondType', null, global);
goog.exportSymbol('proto.rule.CalcActionDrawModulusLine', null, global);
goog.exportSymbol('proto.rule.CalcActionRun', null, global);
goog.exportSymbol('proto.rule.CalcActionType', null, global);
goog.exportSymbol('proto.rule.ClientAppActionLimited', null, global);
goog.exportSymbol('proto.rule.ClientAppActionLimitedType', null, global);
goog.exportSymbol('proto.rule.ClientAppOpenMessageBox', null, global);
goog.exportSymbol('proto.rule.ClientAppOpenMessageBoxConfirm', null, global);
goog.exportSymbol('proto.rule.ClientAppReport', null, global);
goog.exportSymbol('proto.rule.ClientAppRequestVarEntry', null, global);
goog.exportSymbol('proto.rule.ConditionAuxDevice', null, global);
goog.exportSymbol('proto.rule.ConditionCalc', null, global);
goog.exportSymbol('proto.rule.ConditionCalcProp', null, global);
goog.exportSymbol('proto.rule.ConditionConnection', null, global);
goog.exportSymbol('proto.rule.ConditionDi', null, global);
goog.exportSymbol('proto.rule.ConditionDto', null, global);
goog.exportSymbol('proto.rule.ConditionMeasurement', null, global);
goog.exportSymbol('proto.rule.ConditionStation', null, global);
goog.exportSymbol('proto.rule.ConditionStationOp', null, global);
goog.exportSymbol('proto.rule.ConditionStationState', null, global);
goog.exportSymbol('proto.rule.ConditionTest', null, global);
goog.exportSymbol('proto.rule.ConditionTestProp', null, global);
goog.exportSymbol('proto.rule.ConditionType', null, global);
goog.exportSymbol('proto.rule.ConditionVariable', null, global);
goog.exportSymbol('proto.rule.ConnectionProp', null, global);
goog.exportSymbol('proto.rule.ControlActionType', null, global);
goog.exportSymbol('proto.rule.DigitalInputProp', null, global);
goog.exportSymbol('proto.rule.DigitalOutputActionProp', null, global);
goog.exportSymbol('proto.rule.GaugeLengthCompMethods', null, global);
goog.exportSymbol('proto.rule.LimitSpec', null, global);
goog.exportSymbol('proto.rule.LimitSpecStrain', null, global);
goog.exportSymbol('proto.rule.LimitSpecStress', null, global);
goog.exportSymbol('proto.rule.LimitSpecType', null, global);
goog.exportSymbol('proto.rule.MeasurementConditionType', null, global);
goog.exportSymbol('proto.rule.MeasurementPropAmpChange', null, global);
goog.exportSymbol('proto.rule.MeasurementPropInRange', null, global);
goog.exportSymbol('proto.rule.MeasurementPropLessThan', null, global);
goog.exportSymbol('proto.rule.MeasurementPropMeanChange', null, global);
goog.exportSymbol('proto.rule.MeasurementPropMoreThan', null, global);
goog.exportSymbol('proto.rule.MeasurementPropOutOfRange', null, global);
goog.exportSymbol('proto.rule.MeasurementTriggerType', null, global);
goog.exportSymbol('proto.rule.OffsetCalibrationCmd', null, global);
goog.exportSymbol('proto.rule.OffsetCalibrationProp', null, global);
goog.exportSymbol('proto.rule.OffsetCalibrationState', null, global);
goog.exportSymbol('proto.rule.Rule', null, global);
goog.exportSymbol('proto.rule.RuleType', null, global);
goog.exportSymbol('proto.rule.RulesRequest', null, global);
goog.exportSymbol('proto.rule.RunType', null, global);
goog.exportSymbol('proto.rule.SpecimenTriggerType', null, global);
goog.exportSymbol('proto.rule.StageProp', null, global);
goog.exportSymbol('proto.rule.TestProp', null, global);
goog.exportSymbol('proto.rule.TriggerAuxDevice', null, global);
goog.exportSymbol('proto.rule.TriggerAxis', null, global);
goog.exportSymbol('proto.rule.TriggerAxisProp', null, global);
goog.exportSymbol('proto.rule.TriggerConnection', null, global);
goog.exportSymbol('proto.rule.TriggerDigitalInput', null, global);
goog.exportSymbol('proto.rule.TriggerDto', null, global);
goog.exportSymbol('proto.rule.TriggerMeasurement', null, global);
goog.exportSymbol('proto.rule.TriggerMemStatus', null, global);
goog.exportSymbol('proto.rule.TriggerOffsetCalibration', null, global);
goog.exportSymbol('proto.rule.TriggerSpecimen', null, global);
goog.exportSymbol('proto.rule.TriggerSpecimenBreak', null, global);
goog.exportSymbol('proto.rule.TriggerSpecimenYield', null, global);
goog.exportSymbol('proto.rule.TriggerStage', null, global);
goog.exportSymbol('proto.rule.TriggerStation', null, global);
goog.exportSymbol('proto.rule.TriggerTest', null, global);
goog.exportSymbol('proto.rule.TriggerType', null, global);
goog.exportSymbol('proto.rule.TriggerUltimateStrength', null, global);
goog.exportSymbol('proto.rule.TriggerVariable', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.RulesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rule.RulesRequest.repeatedFields_, null);
};
goog.inherits(proto.rule.RulesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.RulesRequest.displayName = 'proto.rule.RulesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.Rule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rule.Rule.repeatedFields_, null);
};
goog.inherits(proto.rule.Rule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.Rule.displayName = 'proto.rule.Rule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerDto.displayName = 'proto.rule.TriggerDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerDigitalInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerDigitalInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerDigitalInput.displayName = 'proto.rule.TriggerDigitalInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerStation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerStation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerStation.displayName = 'proto.rule.TriggerStation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerTest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rule.TriggerTest.repeatedFields_, null);
};
goog.inherits(proto.rule.TriggerTest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerTest.displayName = 'proto.rule.TriggerTest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerConnection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerConnection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerConnection.displayName = 'proto.rule.TriggerConnection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerMeasurement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerMeasurement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerMeasurement.displayName = 'proto.rule.TriggerMeasurement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerAuxDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerAuxDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerAuxDevice.displayName = 'proto.rule.TriggerAuxDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerSpecimen = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerSpecimen, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerSpecimen.displayName = 'proto.rule.TriggerSpecimen';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerSpecimenBreak = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerSpecimenBreak, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerSpecimenBreak.displayName = 'proto.rule.TriggerSpecimenBreak';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerSpecimenYield = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerSpecimenYield, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerSpecimenYield.displayName = 'proto.rule.TriggerSpecimenYield';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerUltimateStrength = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerUltimateStrength, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerUltimateStrength.displayName = 'proto.rule.TriggerUltimateStrength';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerAxis = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerAxis, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerAxis.displayName = 'proto.rule.TriggerAxis';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerStage.displayName = 'proto.rule.TriggerStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerVariable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerVariable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerVariable.displayName = 'proto.rule.TriggerVariable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerOffsetCalibration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerOffsetCalibration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerOffsetCalibration.displayName = 'proto.rule.TriggerOffsetCalibration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.TriggerMemStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.TriggerMemStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.TriggerMemStatus.displayName = 'proto.rule.TriggerMemStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.MeasurementPropMoreThan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.MeasurementPropMoreThan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.MeasurementPropMoreThan.displayName = 'proto.rule.MeasurementPropMoreThan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.MeasurementPropLessThan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.MeasurementPropLessThan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.MeasurementPropLessThan.displayName = 'proto.rule.MeasurementPropLessThan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.MeasurementPropAmpChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.MeasurementPropAmpChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.MeasurementPropAmpChange.displayName = 'proto.rule.MeasurementPropAmpChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.MeasurementPropMeanChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.MeasurementPropMeanChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.MeasurementPropMeanChange.displayName = 'proto.rule.MeasurementPropMeanChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.MeasurementPropInRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.MeasurementPropInRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.MeasurementPropInRange.displayName = 'proto.rule.MeasurementPropInRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.MeasurementPropOutOfRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.MeasurementPropOutOfRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.MeasurementPropOutOfRange.displayName = 'proto.rule.MeasurementPropOutOfRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ConditionDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ConditionDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ConditionDto.displayName = 'proto.rule.ConditionDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ConditionDi = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ConditionDi, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ConditionDi.displayName = 'proto.rule.ConditionDi';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ConditionStation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ConditionStation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ConditionStation.displayName = 'proto.rule.ConditionStation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ConditionConnection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ConditionConnection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ConditionConnection.displayName = 'proto.rule.ConditionConnection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ConditionTest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ConditionTest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ConditionTest.displayName = 'proto.rule.ConditionTest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ConditionMeasurement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ConditionMeasurement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ConditionMeasurement.displayName = 'proto.rule.ConditionMeasurement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ConditionAuxDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ConditionAuxDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ConditionAuxDevice.displayName = 'proto.rule.ConditionAuxDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ConditionCalc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ConditionCalc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ConditionCalc.displayName = 'proto.rule.ConditionCalc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ConditionVariable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ConditionVariable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ConditionVariable.displayName = 'proto.rule.ConditionVariable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ActionDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionDto.displayName = 'proto.rule.ActionDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionTypeDO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rule.ActionTypeDO.repeatedFields_, null);
};
goog.inherits(proto.rule.ActionTypeDO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionTypeDO.displayName = 'proto.rule.ActionTypeDO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionTypeAnalogOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.rule.ActionTypeAnalogOutput.oneofGroups_);
};
goog.inherits(proto.rule.ActionTypeAnalogOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionTypeAnalogOutput.displayName = 'proto.rule.ActionTypeAnalogOutput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.AnalogOutputMeasurement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.AnalogOutputMeasurement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.AnalogOutputMeasurement.displayName = 'proto.rule.AnalogOutputMeasurement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.AnalogOutputConstant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.AnalogOutputConstant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.AnalogOutputConstant.displayName = 'proto.rule.AnalogOutputConstant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionTypeControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ActionTypeControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionTypeControl.displayName = 'proto.rule.ActionTypeControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionTypeCalc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ActionTypeCalc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionTypeCalc.displayName = 'proto.rule.ActionTypeCalc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.CalcActionRun = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.CalcActionRun, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.CalcActionRun.displayName = 'proto.rule.CalcActionRun';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.CalcActionDrawModulusLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.CalcActionDrawModulusLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.CalcActionDrawModulusLine.displayName = 'proto.rule.CalcActionDrawModulusLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionTypeTest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ActionTypeTest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionTypeTest.displayName = 'proto.rule.ActionTypeTest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionTypeControlDisable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ActionTypeControlDisable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionTypeControlDisable.displayName = 'proto.rule.ActionTypeControlDisable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionTypeStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ActionTypeStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionTypeStage.displayName = 'proto.rule.ActionTypeStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionTypeSpecimen = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ActionTypeSpecimen, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionTypeSpecimen.displayName = 'proto.rule.ActionTypeSpecimen';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ClientAppOpenMessageBox = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ClientAppOpenMessageBox, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ClientAppOpenMessageBox.displayName = 'proto.rule.ClientAppOpenMessageBox';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ClientAppOpenMessageBoxConfirm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rule.ClientAppOpenMessageBoxConfirm.repeatedFields_, null);
};
goog.inherits(proto.rule.ClientAppOpenMessageBoxConfirm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ClientAppOpenMessageBoxConfirm.displayName = 'proto.rule.ClientAppOpenMessageBoxConfirm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ClientAppActionLimited = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ClientAppActionLimited, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ClientAppActionLimited.displayName = 'proto.rule.ClientAppActionLimited';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ClientAppRequestVarEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rule.ClientAppRequestVarEntry.repeatedFields_, null);
};
goog.inherits(proto.rule.ClientAppRequestVarEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ClientAppRequestVarEntry.displayName = 'proto.rule.ClientAppRequestVarEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ClientAppReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ClientAppReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ClientAppReport.displayName = 'proto.rule.ClientAppReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.LimitSpecStress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.LimitSpecStress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.LimitSpecStress.displayName = 'proto.rule.LimitSpecStress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.LimitSpecStrain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.LimitSpecStrain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.LimitSpecStrain.displayName = 'proto.rule.LimitSpecStrain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionTypeMeas = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ActionTypeMeas, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionTypeMeas.displayName = 'proto.rule.ActionTypeMeas';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionTypeAxis = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.rule.ActionTypeAxis.oneofGroups_);
};
goog.inherits(proto.rule.ActionTypeAxis, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionTypeAxis.displayName = 'proto.rule.ActionTypeAxis';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.AxisEnable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rule.AxisEnable.repeatedFields_, null);
};
goog.inherits(proto.rule.AxisEnable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.AxisEnable.displayName = 'proto.rule.AxisEnable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.AxisDisable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rule.AxisDisable.repeatedFields_, null);
};
goog.inherits(proto.rule.AxisDisable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.AxisDisable.displayName = 'proto.rule.AxisDisable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.AxisLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.AxisLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.AxisLink.displayName = 'proto.rule.AxisLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.AxisUnlink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.AxisUnlink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.AxisUnlink.displayName = 'proto.rule.AxisUnlink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.AxisTare = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.AxisTare, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.AxisTare.displayName = 'proto.rule.AxisTare';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.AxisPidReset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.AxisPidReset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.AxisPidReset.displayName = 'proto.rule.AxisPidReset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionTypeStation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ActionTypeStation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionTypeStation.displayName = 'proto.rule.ActionTypeStation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionTypeVariable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ActionTypeVariable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionTypeVariable.displayName = 'proto.rule.ActionTypeVariable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.ActionTypeOffsetCalibration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.ActionTypeOffsetCalibration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.ActionTypeOffsetCalibration.displayName = 'proto.rule.ActionTypeOffsetCalibration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rule.OffsetCalibrationProp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rule.OffsetCalibrationProp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rule.OffsetCalibrationProp.displayName = 'proto.rule.OffsetCalibrationProp';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rule.RulesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.RulesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.RulesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.RulesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.RulesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    proto.rule.Rule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.RulesRequest}
 */
proto.rule.RulesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.RulesRequest;
  return proto.rule.RulesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.RulesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.RulesRequest}
 */
proto.rule.RulesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationId(value);
      break;
    case 2:
      var value = new proto.rule.Rule;
      reader.readMessage(value,proto.rule.Rule.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.RulesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.RulesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.RulesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.RulesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.rule.Rule.serializeBinaryToWriter
    );
  }
};


/**
 * optional string station_id = 1;
 * @return {string}
 */
proto.rule.RulesRequest.prototype.getStationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.RulesRequest} returns this
 */
proto.rule.RulesRequest.prototype.setStationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Rule rules = 2;
 * @return {!Array<!proto.rule.Rule>}
 */
proto.rule.RulesRequest.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.rule.Rule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rule.Rule, 2));
};


/**
 * @param {!Array<!proto.rule.Rule>} value
 * @return {!proto.rule.RulesRequest} returns this
*/
proto.rule.RulesRequest.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.rule.Rule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rule.Rule}
 */
proto.rule.RulesRequest.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.rule.Rule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rule.RulesRequest} returns this
 */
proto.rule.RulesRequest.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rule.Rule.repeatedFields_ = [7,9,11,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.Rule.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.Rule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.Rule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.Rule.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    stationId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    triggerBondType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    triggersList: jspb.Message.toObjectList(msg.getTriggersList(),
    proto.rule.TriggerDto.toObject, includeInstance),
    conditionBondType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    conditionsList: jspb.Message.toObjectList(msg.getConditionsList(),
    proto.rule.ConditionDto.toObject, includeInstance),
    positiveRunType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    positiveActionsList: jspb.Message.toObjectList(msg.getPositiveActionsList(),
    proto.rule.ActionDto.toObject, includeInstance),
    negativeRunType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    negativeActionsList: jspb.Message.toObjectList(msg.getNegativeActionsList(),
    proto.rule.ActionDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.Rule}
 */
proto.rule.Rule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.Rule;
  return proto.rule.Rule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.Rule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.Rule}
 */
proto.rule.Rule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.rule.RuleType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationId(value);
      break;
    case 6:
      var value = /** @type {!proto.rule.BondType} */ (reader.readEnum());
      msg.setTriggerBondType(value);
      break;
    case 7:
      var value = new proto.rule.TriggerDto;
      reader.readMessage(value,proto.rule.TriggerDto.deserializeBinaryFromReader);
      msg.addTriggers(value);
      break;
    case 8:
      var value = /** @type {!proto.rule.BondType} */ (reader.readEnum());
      msg.setConditionBondType(value);
      break;
    case 9:
      var value = new proto.rule.ConditionDto;
      reader.readMessage(value,proto.rule.ConditionDto.deserializeBinaryFromReader);
      msg.addConditions(value);
      break;
    case 10:
      var value = /** @type {!proto.rule.RunType} */ (reader.readEnum());
      msg.setPositiveRunType(value);
      break;
    case 11:
      var value = new proto.rule.ActionDto;
      reader.readMessage(value,proto.rule.ActionDto.deserializeBinaryFromReader);
      msg.addPositiveActions(value);
      break;
    case 12:
      var value = /** @type {!proto.rule.RunType} */ (reader.readEnum());
      msg.setNegativeRunType(value);
      break;
    case 13:
      var value = new proto.rule.ActionDto;
      reader.readMessage(value,proto.rule.ActionDto.deserializeBinaryFromReader);
      msg.addNegativeActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.Rule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.Rule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.Rule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.Rule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStationId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTriggerBondType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTriggersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.rule.TriggerDto.serializeBinaryToWriter
    );
  }
  f = message.getConditionBondType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.rule.ConditionDto.serializeBinaryToWriter
    );
  }
  f = message.getPositiveRunType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getPositiveActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.rule.ActionDto.serializeBinaryToWriter
    );
  }
  f = message.getNegativeRunType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getNegativeActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.rule.ActionDto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rule.Rule.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.Rule} returns this
 */
proto.rule.Rule.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.rule.Rule.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.Rule} returns this
 */
proto.rule.Rule.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RuleType type = 3;
 * @return {!proto.rule.RuleType}
 */
proto.rule.Rule.prototype.getType = function() {
  return /** @type {!proto.rule.RuleType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.rule.RuleType} value
 * @return {!proto.rule.Rule} returns this
 */
proto.rule.Rule.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string station_id = 4;
 * @return {string}
 */
proto.rule.Rule.prototype.getStationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.Rule} returns this
 */
proto.rule.Rule.prototype.setStationId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional BondType trigger_bond_type = 6;
 * @return {!proto.rule.BondType}
 */
proto.rule.Rule.prototype.getTriggerBondType = function() {
  return /** @type {!proto.rule.BondType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.rule.BondType} value
 * @return {!proto.rule.Rule} returns this
 */
proto.rule.Rule.prototype.setTriggerBondType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated TriggerDto triggers = 7;
 * @return {!Array<!proto.rule.TriggerDto>}
 */
proto.rule.Rule.prototype.getTriggersList = function() {
  return /** @type{!Array<!proto.rule.TriggerDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rule.TriggerDto, 7));
};


/**
 * @param {!Array<!proto.rule.TriggerDto>} value
 * @return {!proto.rule.Rule} returns this
*/
proto.rule.Rule.prototype.setTriggersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.rule.TriggerDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rule.TriggerDto}
 */
proto.rule.Rule.prototype.addTriggers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.rule.TriggerDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rule.Rule} returns this
 */
proto.rule.Rule.prototype.clearTriggersList = function() {
  return this.setTriggersList([]);
};


/**
 * optional BondType condition_bond_type = 8;
 * @return {!proto.rule.BondType}
 */
proto.rule.Rule.prototype.getConditionBondType = function() {
  return /** @type {!proto.rule.BondType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.rule.BondType} value
 * @return {!proto.rule.Rule} returns this
 */
proto.rule.Rule.prototype.setConditionBondType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated ConditionDto conditions = 9;
 * @return {!Array<!proto.rule.ConditionDto>}
 */
proto.rule.Rule.prototype.getConditionsList = function() {
  return /** @type{!Array<!proto.rule.ConditionDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rule.ConditionDto, 9));
};


/**
 * @param {!Array<!proto.rule.ConditionDto>} value
 * @return {!proto.rule.Rule} returns this
*/
proto.rule.Rule.prototype.setConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.rule.ConditionDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rule.ConditionDto}
 */
proto.rule.Rule.prototype.addConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.rule.ConditionDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rule.Rule} returns this
 */
proto.rule.Rule.prototype.clearConditionsList = function() {
  return this.setConditionsList([]);
};


/**
 * optional RunType positive_run_type = 10;
 * @return {!proto.rule.RunType}
 */
proto.rule.Rule.prototype.getPositiveRunType = function() {
  return /** @type {!proto.rule.RunType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.rule.RunType} value
 * @return {!proto.rule.Rule} returns this
 */
proto.rule.Rule.prototype.setPositiveRunType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * repeated ActionDto positive_actions = 11;
 * @return {!Array<!proto.rule.ActionDto>}
 */
proto.rule.Rule.prototype.getPositiveActionsList = function() {
  return /** @type{!Array<!proto.rule.ActionDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rule.ActionDto, 11));
};


/**
 * @param {!Array<!proto.rule.ActionDto>} value
 * @return {!proto.rule.Rule} returns this
*/
proto.rule.Rule.prototype.setPositiveActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.rule.ActionDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rule.ActionDto}
 */
proto.rule.Rule.prototype.addPositiveActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.rule.ActionDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rule.Rule} returns this
 */
proto.rule.Rule.prototype.clearPositiveActionsList = function() {
  return this.setPositiveActionsList([]);
};


/**
 * optional RunType negative_run_type = 12;
 * @return {!proto.rule.RunType}
 */
proto.rule.Rule.prototype.getNegativeRunType = function() {
  return /** @type {!proto.rule.RunType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.rule.RunType} value
 * @return {!proto.rule.Rule} returns this
 */
proto.rule.Rule.prototype.setNegativeRunType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * repeated ActionDto negative_actions = 13;
 * @return {!Array<!proto.rule.ActionDto>}
 */
proto.rule.Rule.prototype.getNegativeActionsList = function() {
  return /** @type{!Array<!proto.rule.ActionDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rule.ActionDto, 13));
};


/**
 * @param {!Array<!proto.rule.ActionDto>} value
 * @return {!proto.rule.Rule} returns this
*/
proto.rule.Rule.prototype.setNegativeActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.rule.ActionDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rule.ActionDto}
 */
proto.rule.Rule.prototype.addNegativeActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.rule.ActionDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rule.Rule} returns this
 */
proto.rule.Rule.prototype.clearNegativeActionsList = function() {
  return this.setNegativeActionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerDto.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    triggerDi: (f = msg.getTriggerDi()) && proto.rule.TriggerDigitalInput.toObject(includeInstance, f),
    triggerStation: (f = msg.getTriggerStation()) && proto.rule.TriggerStation.toObject(includeInstance, f),
    triggerTest: (f = msg.getTriggerTest()) && proto.rule.TriggerTest.toObject(includeInstance, f),
    triggerConn: (f = msg.getTriggerConn()) && proto.rule.TriggerConnection.toObject(includeInstance, f),
    triggerMeas: (f = msg.getTriggerMeas()) && proto.rule.TriggerMeasurement.toObject(includeInstance, f),
    triggerAux: (f = msg.getTriggerAux()) && proto.rule.TriggerAuxDevice.toObject(includeInstance, f),
    triggerSpecimen: (f = msg.getTriggerSpecimen()) && proto.rule.TriggerSpecimen.toObject(includeInstance, f),
    triggerAxis: (f = msg.getTriggerAxis()) && proto.rule.TriggerAxis.toObject(includeInstance, f),
    triggerStage: (f = msg.getTriggerStage()) && proto.rule.TriggerStage.toObject(includeInstance, f),
    triggerVariable: (f = msg.getTriggerVariable()) && proto.rule.TriggerVariable.toObject(includeInstance, f),
    triggerOc: (f = msg.getTriggerOc()) && proto.rule.TriggerOffsetCalibration.toObject(includeInstance, f),
    triggerMs: (f = msg.getTriggerMs()) && proto.rule.TriggerMemStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerDto}
 */
proto.rule.TriggerDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerDto;
  return proto.rule.TriggerDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerDto}
 */
proto.rule.TriggerDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.TriggerType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 50:
      var value = new proto.rule.TriggerDigitalInput;
      reader.readMessage(value,proto.rule.TriggerDigitalInput.deserializeBinaryFromReader);
      msg.setTriggerDi(value);
      break;
    case 51:
      var value = new proto.rule.TriggerStation;
      reader.readMessage(value,proto.rule.TriggerStation.deserializeBinaryFromReader);
      msg.setTriggerStation(value);
      break;
    case 52:
      var value = new proto.rule.TriggerTest;
      reader.readMessage(value,proto.rule.TriggerTest.deserializeBinaryFromReader);
      msg.setTriggerTest(value);
      break;
    case 53:
      var value = new proto.rule.TriggerConnection;
      reader.readMessage(value,proto.rule.TriggerConnection.deserializeBinaryFromReader);
      msg.setTriggerConn(value);
      break;
    case 54:
      var value = new proto.rule.TriggerMeasurement;
      reader.readMessage(value,proto.rule.TriggerMeasurement.deserializeBinaryFromReader);
      msg.setTriggerMeas(value);
      break;
    case 55:
      var value = new proto.rule.TriggerAuxDevice;
      reader.readMessage(value,proto.rule.TriggerAuxDevice.deserializeBinaryFromReader);
      msg.setTriggerAux(value);
      break;
    case 56:
      var value = new proto.rule.TriggerSpecimen;
      reader.readMessage(value,proto.rule.TriggerSpecimen.deserializeBinaryFromReader);
      msg.setTriggerSpecimen(value);
      break;
    case 57:
      var value = new proto.rule.TriggerAxis;
      reader.readMessage(value,proto.rule.TriggerAxis.deserializeBinaryFromReader);
      msg.setTriggerAxis(value);
      break;
    case 58:
      var value = new proto.rule.TriggerStage;
      reader.readMessage(value,proto.rule.TriggerStage.deserializeBinaryFromReader);
      msg.setTriggerStage(value);
      break;
    case 59:
      var value = new proto.rule.TriggerVariable;
      reader.readMessage(value,proto.rule.TriggerVariable.deserializeBinaryFromReader);
      msg.setTriggerVariable(value);
      break;
    case 60:
      var value = new proto.rule.TriggerOffsetCalibration;
      reader.readMessage(value,proto.rule.TriggerOffsetCalibration.deserializeBinaryFromReader);
      msg.setTriggerOc(value);
      break;
    case 61:
      var value = new proto.rule.TriggerMemStatus;
      reader.readMessage(value,proto.rule.TriggerMemStatus.deserializeBinaryFromReader);
      msg.setTriggerMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTriggerDi();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.rule.TriggerDigitalInput.serializeBinaryToWriter
    );
  }
  f = message.getTriggerStation();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.rule.TriggerStation.serializeBinaryToWriter
    );
  }
  f = message.getTriggerTest();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.rule.TriggerTest.serializeBinaryToWriter
    );
  }
  f = message.getTriggerConn();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.rule.TriggerConnection.serializeBinaryToWriter
    );
  }
  f = message.getTriggerMeas();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.rule.TriggerMeasurement.serializeBinaryToWriter
    );
  }
  f = message.getTriggerAux();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      proto.rule.TriggerAuxDevice.serializeBinaryToWriter
    );
  }
  f = message.getTriggerSpecimen();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      proto.rule.TriggerSpecimen.serializeBinaryToWriter
    );
  }
  f = message.getTriggerAxis();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      proto.rule.TriggerAxis.serializeBinaryToWriter
    );
  }
  f = message.getTriggerStage();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      proto.rule.TriggerStage.serializeBinaryToWriter
    );
  }
  f = message.getTriggerVariable();
  if (f != null) {
    writer.writeMessage(
      59,
      f,
      proto.rule.TriggerVariable.serializeBinaryToWriter
    );
  }
  f = message.getTriggerOc();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      proto.rule.TriggerOffsetCalibration.serializeBinaryToWriter
    );
  }
  f = message.getTriggerMs();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      proto.rule.TriggerMemStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rule.TriggerDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TriggerType type = 2;
 * @return {!proto.rule.TriggerType}
 */
proto.rule.TriggerDto.prototype.getType = function() {
  return /** @type {!proto.rule.TriggerType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.TriggerType} value
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional TriggerDigitalInput trigger_di = 50;
 * @return {?proto.rule.TriggerDigitalInput}
 */
proto.rule.TriggerDto.prototype.getTriggerDi = function() {
  return /** @type{?proto.rule.TriggerDigitalInput} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerDigitalInput, 50));
};


/**
 * @param {?proto.rule.TriggerDigitalInput|undefined} value
 * @return {!proto.rule.TriggerDto} returns this
*/
proto.rule.TriggerDto.prototype.setTriggerDi = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.clearTriggerDi = function() {
  return this.setTriggerDi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerDto.prototype.hasTriggerDi = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional TriggerStation trigger_station = 51;
 * @return {?proto.rule.TriggerStation}
 */
proto.rule.TriggerDto.prototype.getTriggerStation = function() {
  return /** @type{?proto.rule.TriggerStation} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerStation, 51));
};


/**
 * @param {?proto.rule.TriggerStation|undefined} value
 * @return {!proto.rule.TriggerDto} returns this
*/
proto.rule.TriggerDto.prototype.setTriggerStation = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.clearTriggerStation = function() {
  return this.setTriggerStation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerDto.prototype.hasTriggerStation = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional TriggerTest trigger_test = 52;
 * @return {?proto.rule.TriggerTest}
 */
proto.rule.TriggerDto.prototype.getTriggerTest = function() {
  return /** @type{?proto.rule.TriggerTest} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerTest, 52));
};


/**
 * @param {?proto.rule.TriggerTest|undefined} value
 * @return {!proto.rule.TriggerDto} returns this
*/
proto.rule.TriggerDto.prototype.setTriggerTest = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.clearTriggerTest = function() {
  return this.setTriggerTest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerDto.prototype.hasTriggerTest = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional TriggerConnection trigger_conn = 53;
 * @return {?proto.rule.TriggerConnection}
 */
proto.rule.TriggerDto.prototype.getTriggerConn = function() {
  return /** @type{?proto.rule.TriggerConnection} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerConnection, 53));
};


/**
 * @param {?proto.rule.TriggerConnection|undefined} value
 * @return {!proto.rule.TriggerDto} returns this
*/
proto.rule.TriggerDto.prototype.setTriggerConn = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.clearTriggerConn = function() {
  return this.setTriggerConn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerDto.prototype.hasTriggerConn = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional TriggerMeasurement trigger_meas = 54;
 * @return {?proto.rule.TriggerMeasurement}
 */
proto.rule.TriggerDto.prototype.getTriggerMeas = function() {
  return /** @type{?proto.rule.TriggerMeasurement} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerMeasurement, 54));
};


/**
 * @param {?proto.rule.TriggerMeasurement|undefined} value
 * @return {!proto.rule.TriggerDto} returns this
*/
proto.rule.TriggerDto.prototype.setTriggerMeas = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.clearTriggerMeas = function() {
  return this.setTriggerMeas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerDto.prototype.hasTriggerMeas = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional TriggerAuxDevice trigger_aux = 55;
 * @return {?proto.rule.TriggerAuxDevice}
 */
proto.rule.TriggerDto.prototype.getTriggerAux = function() {
  return /** @type{?proto.rule.TriggerAuxDevice} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerAuxDevice, 55));
};


/**
 * @param {?proto.rule.TriggerAuxDevice|undefined} value
 * @return {!proto.rule.TriggerDto} returns this
*/
proto.rule.TriggerDto.prototype.setTriggerAux = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.clearTriggerAux = function() {
  return this.setTriggerAux(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerDto.prototype.hasTriggerAux = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional TriggerSpecimen trigger_specimen = 56;
 * @return {?proto.rule.TriggerSpecimen}
 */
proto.rule.TriggerDto.prototype.getTriggerSpecimen = function() {
  return /** @type{?proto.rule.TriggerSpecimen} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerSpecimen, 56));
};


/**
 * @param {?proto.rule.TriggerSpecimen|undefined} value
 * @return {!proto.rule.TriggerDto} returns this
*/
proto.rule.TriggerDto.prototype.setTriggerSpecimen = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.clearTriggerSpecimen = function() {
  return this.setTriggerSpecimen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerDto.prototype.hasTriggerSpecimen = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional TriggerAxis trigger_axis = 57;
 * @return {?proto.rule.TriggerAxis}
 */
proto.rule.TriggerDto.prototype.getTriggerAxis = function() {
  return /** @type{?proto.rule.TriggerAxis} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerAxis, 57));
};


/**
 * @param {?proto.rule.TriggerAxis|undefined} value
 * @return {!proto.rule.TriggerDto} returns this
*/
proto.rule.TriggerDto.prototype.setTriggerAxis = function(value) {
  return jspb.Message.setWrapperField(this, 57, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.clearTriggerAxis = function() {
  return this.setTriggerAxis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerDto.prototype.hasTriggerAxis = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional TriggerStage trigger_stage = 58;
 * @return {?proto.rule.TriggerStage}
 */
proto.rule.TriggerDto.prototype.getTriggerStage = function() {
  return /** @type{?proto.rule.TriggerStage} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerStage, 58));
};


/**
 * @param {?proto.rule.TriggerStage|undefined} value
 * @return {!proto.rule.TriggerDto} returns this
*/
proto.rule.TriggerDto.prototype.setTriggerStage = function(value) {
  return jspb.Message.setWrapperField(this, 58, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.clearTriggerStage = function() {
  return this.setTriggerStage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerDto.prototype.hasTriggerStage = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional TriggerVariable trigger_variable = 59;
 * @return {?proto.rule.TriggerVariable}
 */
proto.rule.TriggerDto.prototype.getTriggerVariable = function() {
  return /** @type{?proto.rule.TriggerVariable} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerVariable, 59));
};


/**
 * @param {?proto.rule.TriggerVariable|undefined} value
 * @return {!proto.rule.TriggerDto} returns this
*/
proto.rule.TriggerDto.prototype.setTriggerVariable = function(value) {
  return jspb.Message.setWrapperField(this, 59, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.clearTriggerVariable = function() {
  return this.setTriggerVariable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerDto.prototype.hasTriggerVariable = function() {
  return jspb.Message.getField(this, 59) != null;
};


/**
 * optional TriggerOffsetCalibration trigger_oc = 60;
 * @return {?proto.rule.TriggerOffsetCalibration}
 */
proto.rule.TriggerDto.prototype.getTriggerOc = function() {
  return /** @type{?proto.rule.TriggerOffsetCalibration} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerOffsetCalibration, 60));
};


/**
 * @param {?proto.rule.TriggerOffsetCalibration|undefined} value
 * @return {!proto.rule.TriggerDto} returns this
*/
proto.rule.TriggerDto.prototype.setTriggerOc = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.clearTriggerOc = function() {
  return this.setTriggerOc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerDto.prototype.hasTriggerOc = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional TriggerMemStatus trigger_ms = 61;
 * @return {?proto.rule.TriggerMemStatus}
 */
proto.rule.TriggerDto.prototype.getTriggerMs = function() {
  return /** @type{?proto.rule.TriggerMemStatus} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerMemStatus, 61));
};


/**
 * @param {?proto.rule.TriggerMemStatus|undefined} value
 * @return {!proto.rule.TriggerDto} returns this
*/
proto.rule.TriggerDto.prototype.setTriggerMs = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerDto} returns this
 */
proto.rule.TriggerDto.prototype.clearTriggerMs = function() {
  return this.setTriggerMs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerDto.prototype.hasTriggerMs = function() {
  return jspb.Message.getField(this, 61) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerDigitalInput.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerDigitalInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerDigitalInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerDigitalInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    diProp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerDigitalInput}
 */
proto.rule.TriggerDigitalInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerDigitalInput;
  return proto.rule.TriggerDigitalInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerDigitalInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerDigitalInput}
 */
proto.rule.TriggerDigitalInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.DigitalInputProp} */ (reader.readEnum());
      msg.setDiProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerDigitalInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerDigitalInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerDigitalInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerDigitalInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDiProp();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.rule.TriggerDigitalInput.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.TriggerDigitalInput} returns this
 */
proto.rule.TriggerDigitalInput.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DigitalInputProp di_prop = 2;
 * @return {!proto.rule.DigitalInputProp}
 */
proto.rule.TriggerDigitalInput.prototype.getDiProp = function() {
  return /** @type {!proto.rule.DigitalInputProp} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.DigitalInputProp} value
 * @return {!proto.rule.TriggerDigitalInput} returns this
 */
proto.rule.TriggerDigitalInput.prototype.setDiProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerStation.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerStation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerStation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerStation.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationProp: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerStation}
 */
proto.rule.TriggerStation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerStation;
  return proto.rule.TriggerStation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerStation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerStation}
 */
proto.rule.TriggerStation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {!proto.rule.ActionStationState} */ (reader.readEnum());
      msg.setStationProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerStation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerStation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerStation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerStation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationProp();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional ActionStationState station_prop = 5;
 * @return {!proto.rule.ActionStationState}
 */
proto.rule.TriggerStation.prototype.getStationProp = function() {
  return /** @type {!proto.rule.ActionStationState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.rule.ActionStationState} value
 * @return {!proto.rule.TriggerStation} returns this
 */
proto.rule.TriggerStation.prototype.setStationProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rule.TriggerTest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerTest.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerTest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerTest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerTest.toObject = function(includeInstance, msg) {
  var f, obj = {
    testProp: jspb.Message.getFieldWithDefault(msg, 6, 0),
    variablesList: jspb.Message.toObjectList(msg.getVariablesList(),
    common_pb.VariableRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerTest}
 */
proto.rule.TriggerTest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerTest;
  return proto.rule.TriggerTest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerTest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerTest}
 */
proto.rule.TriggerTest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {!proto.rule.TestProp} */ (reader.readEnum());
      msg.setTestProp(value);
      break;
    case 7:
      var value = new common_pb.VariableRequest;
      reader.readMessage(value,common_pb.VariableRequest.deserializeBinaryFromReader);
      msg.addVariables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerTest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerTest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerTest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerTest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTestProp();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      common_pb.VariableRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional TestProp test_prop = 6;
 * @return {!proto.rule.TestProp}
 */
proto.rule.TriggerTest.prototype.getTestProp = function() {
  return /** @type {!proto.rule.TestProp} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.rule.TestProp} value
 * @return {!proto.rule.TriggerTest} returns this
 */
proto.rule.TriggerTest.prototype.setTestProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated common.VariableRequest variables = 7;
 * @return {!Array<!proto.common.VariableRequest>}
 */
proto.rule.TriggerTest.prototype.getVariablesList = function() {
  return /** @type{!Array<!proto.common.VariableRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.VariableRequest, 7));
};


/**
 * @param {!Array<!proto.common.VariableRequest>} value
 * @return {!proto.rule.TriggerTest} returns this
*/
proto.rule.TriggerTest.prototype.setVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.VariableRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.VariableRequest}
 */
proto.rule.TriggerTest.prototype.addVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.VariableRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rule.TriggerTest} returns this
 */
proto.rule.TriggerTest.prototype.clearVariablesList = function() {
  return this.setVariablesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerConnection.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerConnection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerConnection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerConnection.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeoutSec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    connProp: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerConnection}
 */
proto.rule.TriggerConnection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerConnection;
  return proto.rule.TriggerConnection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerConnection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerConnection}
 */
proto.rule.TriggerConnection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimeoutSec(value);
      break;
    case 7:
      var value = /** @type {!proto.rule.ConnectionProp} */ (reader.readEnum());
      msg.setConnProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerConnection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerConnection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerConnection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerConnection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeoutSec();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getConnProp();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional uint32 timeout_sec = 1;
 * @return {number}
 */
proto.rule.TriggerConnection.prototype.getTimeoutSec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rule.TriggerConnection} returns this
 */
proto.rule.TriggerConnection.prototype.setTimeoutSec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ConnectionProp conn_prop = 7;
 * @return {!proto.rule.ConnectionProp}
 */
proto.rule.TriggerConnection.prototype.getConnProp = function() {
  return /** @type {!proto.rule.ConnectionProp} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.rule.ConnectionProp} value
 * @return {!proto.rule.TriggerConnection} returns this
 */
proto.rule.TriggerConnection.prototype.setConnProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerMeasurement.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerMeasurement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerMeasurement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerMeasurement.toObject = function(includeInstance, msg) {
  var f, obj = {
    measId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    moreThan: (f = msg.getMoreThan()) && proto.rule.MeasurementPropMoreThan.toObject(includeInstance, f),
    lessThan: (f = msg.getLessThan()) && proto.rule.MeasurementPropLessThan.toObject(includeInstance, f),
    ampChange: (f = msg.getAmpChange()) && proto.rule.MeasurementPropAmpChange.toObject(includeInstance, f),
    meanChange: (f = msg.getMeanChange()) && proto.rule.MeasurementPropMeanChange.toObject(includeInstance, f),
    inRange: (f = msg.getInRange()) && proto.rule.MeasurementPropInRange.toObject(includeInstance, f),
    outOfRange: (f = msg.getOutOfRange()) && proto.rule.MeasurementPropOutOfRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerMeasurement}
 */
proto.rule.TriggerMeasurement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerMeasurement;
  return proto.rule.TriggerMeasurement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerMeasurement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerMeasurement}
 */
proto.rule.TriggerMeasurement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasId(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.MeasurementTriggerType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 50:
      var value = new proto.rule.MeasurementPropMoreThan;
      reader.readMessage(value,proto.rule.MeasurementPropMoreThan.deserializeBinaryFromReader);
      msg.setMoreThan(value);
      break;
    case 51:
      var value = new proto.rule.MeasurementPropLessThan;
      reader.readMessage(value,proto.rule.MeasurementPropLessThan.deserializeBinaryFromReader);
      msg.setLessThan(value);
      break;
    case 52:
      var value = new proto.rule.MeasurementPropAmpChange;
      reader.readMessage(value,proto.rule.MeasurementPropAmpChange.deserializeBinaryFromReader);
      msg.setAmpChange(value);
      break;
    case 53:
      var value = new proto.rule.MeasurementPropMeanChange;
      reader.readMessage(value,proto.rule.MeasurementPropMeanChange.deserializeBinaryFromReader);
      msg.setMeanChange(value);
      break;
    case 54:
      var value = new proto.rule.MeasurementPropInRange;
      reader.readMessage(value,proto.rule.MeasurementPropInRange.deserializeBinaryFromReader);
      msg.setInRange(value);
      break;
    case 55:
      var value = new proto.rule.MeasurementPropOutOfRange;
      reader.readMessage(value,proto.rule.MeasurementPropOutOfRange.deserializeBinaryFromReader);
      msg.setOutOfRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerMeasurement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerMeasurement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerMeasurement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerMeasurement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeasId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMoreThan();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.rule.MeasurementPropMoreThan.serializeBinaryToWriter
    );
  }
  f = message.getLessThan();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.rule.MeasurementPropLessThan.serializeBinaryToWriter
    );
  }
  f = message.getAmpChange();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.rule.MeasurementPropAmpChange.serializeBinaryToWriter
    );
  }
  f = message.getMeanChange();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.rule.MeasurementPropMeanChange.serializeBinaryToWriter
    );
  }
  f = message.getInRange();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.rule.MeasurementPropInRange.serializeBinaryToWriter
    );
  }
  f = message.getOutOfRange();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      proto.rule.MeasurementPropOutOfRange.serializeBinaryToWriter
    );
  }
};


/**
 * optional string meas_id = 1;
 * @return {string}
 */
proto.rule.TriggerMeasurement.prototype.getMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.TriggerMeasurement} returns this
 */
proto.rule.TriggerMeasurement.prototype.setMeasId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MeasurementTriggerType type = 2;
 * @return {!proto.rule.MeasurementTriggerType}
 */
proto.rule.TriggerMeasurement.prototype.getType = function() {
  return /** @type {!proto.rule.MeasurementTriggerType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.MeasurementTriggerType} value
 * @return {!proto.rule.TriggerMeasurement} returns this
 */
proto.rule.TriggerMeasurement.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional MeasurementPropMoreThan more_than = 50;
 * @return {?proto.rule.MeasurementPropMoreThan}
 */
proto.rule.TriggerMeasurement.prototype.getMoreThan = function() {
  return /** @type{?proto.rule.MeasurementPropMoreThan} */ (
    jspb.Message.getWrapperField(this, proto.rule.MeasurementPropMoreThan, 50));
};


/**
 * @param {?proto.rule.MeasurementPropMoreThan|undefined} value
 * @return {!proto.rule.TriggerMeasurement} returns this
*/
proto.rule.TriggerMeasurement.prototype.setMoreThan = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerMeasurement} returns this
 */
proto.rule.TriggerMeasurement.prototype.clearMoreThan = function() {
  return this.setMoreThan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerMeasurement.prototype.hasMoreThan = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional MeasurementPropLessThan less_than = 51;
 * @return {?proto.rule.MeasurementPropLessThan}
 */
proto.rule.TriggerMeasurement.prototype.getLessThan = function() {
  return /** @type{?proto.rule.MeasurementPropLessThan} */ (
    jspb.Message.getWrapperField(this, proto.rule.MeasurementPropLessThan, 51));
};


/**
 * @param {?proto.rule.MeasurementPropLessThan|undefined} value
 * @return {!proto.rule.TriggerMeasurement} returns this
*/
proto.rule.TriggerMeasurement.prototype.setLessThan = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerMeasurement} returns this
 */
proto.rule.TriggerMeasurement.prototype.clearLessThan = function() {
  return this.setLessThan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerMeasurement.prototype.hasLessThan = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional MeasurementPropAmpChange amp_change = 52;
 * @return {?proto.rule.MeasurementPropAmpChange}
 */
proto.rule.TriggerMeasurement.prototype.getAmpChange = function() {
  return /** @type{?proto.rule.MeasurementPropAmpChange} */ (
    jspb.Message.getWrapperField(this, proto.rule.MeasurementPropAmpChange, 52));
};


/**
 * @param {?proto.rule.MeasurementPropAmpChange|undefined} value
 * @return {!proto.rule.TriggerMeasurement} returns this
*/
proto.rule.TriggerMeasurement.prototype.setAmpChange = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerMeasurement} returns this
 */
proto.rule.TriggerMeasurement.prototype.clearAmpChange = function() {
  return this.setAmpChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerMeasurement.prototype.hasAmpChange = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional MeasurementPropMeanChange mean_change = 53;
 * @return {?proto.rule.MeasurementPropMeanChange}
 */
proto.rule.TriggerMeasurement.prototype.getMeanChange = function() {
  return /** @type{?proto.rule.MeasurementPropMeanChange} */ (
    jspb.Message.getWrapperField(this, proto.rule.MeasurementPropMeanChange, 53));
};


/**
 * @param {?proto.rule.MeasurementPropMeanChange|undefined} value
 * @return {!proto.rule.TriggerMeasurement} returns this
*/
proto.rule.TriggerMeasurement.prototype.setMeanChange = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerMeasurement} returns this
 */
proto.rule.TriggerMeasurement.prototype.clearMeanChange = function() {
  return this.setMeanChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerMeasurement.prototype.hasMeanChange = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional MeasurementPropInRange in_range = 54;
 * @return {?proto.rule.MeasurementPropInRange}
 */
proto.rule.TriggerMeasurement.prototype.getInRange = function() {
  return /** @type{?proto.rule.MeasurementPropInRange} */ (
    jspb.Message.getWrapperField(this, proto.rule.MeasurementPropInRange, 54));
};


/**
 * @param {?proto.rule.MeasurementPropInRange|undefined} value
 * @return {!proto.rule.TriggerMeasurement} returns this
*/
proto.rule.TriggerMeasurement.prototype.setInRange = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerMeasurement} returns this
 */
proto.rule.TriggerMeasurement.prototype.clearInRange = function() {
  return this.setInRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerMeasurement.prototype.hasInRange = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional MeasurementPropOutOfRange out_of_range = 55;
 * @return {?proto.rule.MeasurementPropOutOfRange}
 */
proto.rule.TriggerMeasurement.prototype.getOutOfRange = function() {
  return /** @type{?proto.rule.MeasurementPropOutOfRange} */ (
    jspb.Message.getWrapperField(this, proto.rule.MeasurementPropOutOfRange, 55));
};


/**
 * @param {?proto.rule.MeasurementPropOutOfRange|undefined} value
 * @return {!proto.rule.TriggerMeasurement} returns this
*/
proto.rule.TriggerMeasurement.prototype.setOutOfRange = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerMeasurement} returns this
 */
proto.rule.TriggerMeasurement.prototype.clearOutOfRange = function() {
  return this.setOutOfRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerMeasurement.prototype.hasOutOfRange = function() {
  return jspb.Message.getField(this, 55) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerAuxDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerAuxDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerAuxDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerAuxDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    auxDevId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    auxDevProp: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerAuxDevice}
 */
proto.rule.TriggerAuxDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerAuxDevice;
  return proto.rule.TriggerAuxDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerAuxDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerAuxDevice}
 */
proto.rule.TriggerAuxDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuxDevId(value);
      break;
    case 11:
      var value = /** @type {!proto.rule.AuxDeviceProp} */ (reader.readEnum());
      msg.setAuxDevProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerAuxDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerAuxDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerAuxDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerAuxDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuxDevId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAuxDevProp();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string aux_dev_id = 10;
 * @return {string}
 */
proto.rule.TriggerAuxDevice.prototype.getAuxDevId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.TriggerAuxDevice} returns this
 */
proto.rule.TriggerAuxDevice.prototype.setAuxDevId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional AuxDeviceProp aux_dev_prop = 11;
 * @return {!proto.rule.AuxDeviceProp}
 */
proto.rule.TriggerAuxDevice.prototype.getAuxDevProp = function() {
  return /** @type {!proto.rule.AuxDeviceProp} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.rule.AuxDeviceProp} value
 * @return {!proto.rule.TriggerAuxDevice} returns this
 */
proto.rule.TriggerAuxDevice.prototype.setAuxDevProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerSpecimen.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerSpecimen.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerSpecimen} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerSpecimen.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pb_break: (f = msg.getBreak()) && proto.rule.TriggerSpecimenBreak.toObject(includeInstance, f),
    yield: (f = msg.getYield()) && proto.rule.TriggerSpecimenYield.toObject(includeInstance, f),
    ultimateStr: (f = msg.getUltimateStr()) && proto.rule.TriggerUltimateStrength.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerSpecimen}
 */
proto.rule.TriggerSpecimen.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerSpecimen;
  return proto.rule.TriggerSpecimen.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerSpecimen} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerSpecimen}
 */
proto.rule.TriggerSpecimen.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rule.SpecimenTriggerType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 50:
      var value = new proto.rule.TriggerSpecimenBreak;
      reader.readMessage(value,proto.rule.TriggerSpecimenBreak.deserializeBinaryFromReader);
      msg.setBreak(value);
      break;
    case 51:
      var value = new proto.rule.TriggerSpecimenYield;
      reader.readMessage(value,proto.rule.TriggerSpecimenYield.deserializeBinaryFromReader);
      msg.setYield(value);
      break;
    case 52:
      var value = new proto.rule.TriggerUltimateStrength;
      reader.readMessage(value,proto.rule.TriggerUltimateStrength.deserializeBinaryFromReader);
      msg.setUltimateStr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerSpecimen.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerSpecimen.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerSpecimen} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerSpecimen.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBreak();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.rule.TriggerSpecimenBreak.serializeBinaryToWriter
    );
  }
  f = message.getYield();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.rule.TriggerSpecimenYield.serializeBinaryToWriter
    );
  }
  f = message.getUltimateStr();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.rule.TriggerUltimateStrength.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpecimenTriggerType type = 1;
 * @return {!proto.rule.SpecimenTriggerType}
 */
proto.rule.TriggerSpecimen.prototype.getType = function() {
  return /** @type {!proto.rule.SpecimenTriggerType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rule.SpecimenTriggerType} value
 * @return {!proto.rule.TriggerSpecimen} returns this
 */
proto.rule.TriggerSpecimen.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional TriggerSpecimenBreak break = 50;
 * @return {?proto.rule.TriggerSpecimenBreak}
 */
proto.rule.TriggerSpecimen.prototype.getBreak = function() {
  return /** @type{?proto.rule.TriggerSpecimenBreak} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerSpecimenBreak, 50));
};


/**
 * @param {?proto.rule.TriggerSpecimenBreak|undefined} value
 * @return {!proto.rule.TriggerSpecimen} returns this
*/
proto.rule.TriggerSpecimen.prototype.setBreak = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerSpecimen} returns this
 */
proto.rule.TriggerSpecimen.prototype.clearBreak = function() {
  return this.setBreak(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerSpecimen.prototype.hasBreak = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional TriggerSpecimenYield yield = 51;
 * @return {?proto.rule.TriggerSpecimenYield}
 */
proto.rule.TriggerSpecimen.prototype.getYield = function() {
  return /** @type{?proto.rule.TriggerSpecimenYield} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerSpecimenYield, 51));
};


/**
 * @param {?proto.rule.TriggerSpecimenYield|undefined} value
 * @return {!proto.rule.TriggerSpecimen} returns this
*/
proto.rule.TriggerSpecimen.prototype.setYield = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerSpecimen} returns this
 */
proto.rule.TriggerSpecimen.prototype.clearYield = function() {
  return this.setYield(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerSpecimen.prototype.hasYield = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional TriggerUltimateStrength ultimate_str = 52;
 * @return {?proto.rule.TriggerUltimateStrength}
 */
proto.rule.TriggerSpecimen.prototype.getUltimateStr = function() {
  return /** @type{?proto.rule.TriggerUltimateStrength} */ (
    jspb.Message.getWrapperField(this, proto.rule.TriggerUltimateStrength, 52));
};


/**
 * @param {?proto.rule.TriggerUltimateStrength|undefined} value
 * @return {!proto.rule.TriggerSpecimen} returns this
*/
proto.rule.TriggerSpecimen.prototype.setUltimateStr = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerSpecimen} returns this
 */
proto.rule.TriggerSpecimen.prototype.clearUltimateStr = function() {
  return this.setUltimateStr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerSpecimen.prototype.hasUltimateStr = function() {
  return jspb.Message.getField(this, 52) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerSpecimenBreak.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerSpecimenBreak.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerSpecimenBreak} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerSpecimenBreak.toObject = function(includeInstance, msg) {
  var f, obj = {
    measId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    drop: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerSpecimenBreak}
 */
proto.rule.TriggerSpecimenBreak.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerSpecimenBreak;
  return proto.rule.TriggerSpecimenBreak.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerSpecimenBreak} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerSpecimenBreak}
 */
proto.rule.TriggerSpecimenBreak.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThreshold(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDrop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerSpecimenBreak.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerSpecimenBreak.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerSpecimenBreak} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerSpecimenBreak.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeasId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDrop();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string meas_id = 1;
 * @return {string}
 */
proto.rule.TriggerSpecimenBreak.prototype.getMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.TriggerSpecimenBreak} returns this
 */
proto.rule.TriggerSpecimenBreak.prototype.setMeasId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float threshold = 2;
 * @return {number}
 */
proto.rule.TriggerSpecimenBreak.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.TriggerSpecimenBreak} returns this
 */
proto.rule.TriggerSpecimenBreak.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float drop = 3;
 * @return {number}
 */
proto.rule.TriggerSpecimenBreak.prototype.getDrop = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.TriggerSpecimenBreak} returns this
 */
proto.rule.TriggerSpecimenBreak.prototype.setDrop = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerSpecimenYield.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerSpecimenYield.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerSpecimenYield} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerSpecimenYield.toObject = function(includeInstance, msg) {
  var f, obj = {
    stressMeasId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    strainMeasId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    thresholdMeasId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerSpecimenYield}
 */
proto.rule.TriggerSpecimenYield.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerSpecimenYield;
  return proto.rule.TriggerSpecimenYield.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerSpecimenYield} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerSpecimenYield}
 */
proto.rule.TriggerSpecimenYield.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStressMeasId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrainMeasId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setThresholdMeasId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerSpecimenYield.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerSpecimenYield.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerSpecimenYield} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerSpecimenYield.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStressMeasId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStrainMeasId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getThresholdMeasId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional string stress_meas_id = 1;
 * @return {string}
 */
proto.rule.TriggerSpecimenYield.prototype.getStressMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.TriggerSpecimenYield} returns this
 */
proto.rule.TriggerSpecimenYield.prototype.setStressMeasId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string strain_meas_id = 2;
 * @return {string}
 */
proto.rule.TriggerSpecimenYield.prototype.getStrainMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.TriggerSpecimenYield} returns this
 */
proto.rule.TriggerSpecimenYield.prototype.setStrainMeasId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string threshold_meas_id = 3;
 * @return {string}
 */
proto.rule.TriggerSpecimenYield.prototype.getThresholdMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.TriggerSpecimenYield} returns this
 */
proto.rule.TriggerSpecimenYield.prototype.setThresholdMeasId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float threshold = 4;
 * @return {number}
 */
proto.rule.TriggerSpecimenYield.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.TriggerSpecimenYield} returns this
 */
proto.rule.TriggerSpecimenYield.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerUltimateStrength.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerUltimateStrength.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerUltimateStrength} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerUltimateStrength.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerUltimateStrength}
 */
proto.rule.TriggerUltimateStrength.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerUltimateStrength;
  return proto.rule.TriggerUltimateStrength.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerUltimateStrength} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerUltimateStrength}
 */
proto.rule.TriggerUltimateStrength.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerUltimateStrength.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerUltimateStrength.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerUltimateStrength} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerUltimateStrength.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.rule.TriggerUltimateStrength.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.TriggerUltimateStrength} returns this
 */
proto.rule.TriggerUltimateStrength.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.rule.TriggerUltimateStrength} returns this
 */
proto.rule.TriggerUltimateStrength.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerUltimateStrength.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerAxis.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerAxis.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerAxis} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerAxis.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    axisProp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerAxis}
 */
proto.rule.TriggerAxis.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerAxis;
  return proto.rule.TriggerAxis.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerAxis} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerAxis}
 */
proto.rule.TriggerAxis.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.TriggerAxisProp} */ (reader.readEnum());
      msg.setAxisProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerAxis.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerAxis.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerAxis} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerAxis.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAxisId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAxisProp();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string axis_id = 1;
 * @return {string}
 */
proto.rule.TriggerAxis.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.TriggerAxis} returns this
 */
proto.rule.TriggerAxis.prototype.setAxisId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TriggerAxisProp axis_prop = 2;
 * @return {!proto.rule.TriggerAxisProp}
 */
proto.rule.TriggerAxis.prototype.getAxisProp = function() {
  return /** @type {!proto.rule.TriggerAxisProp} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.TriggerAxisProp} value
 * @return {!proto.rule.TriggerAxis} returns this
 */
proto.rule.TriggerAxis.prototype.setAxisProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerStage.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sequence: jspb.Message.getFieldWithDefault(msg, 2, 0),
    loopCurr: jspb.Message.getFieldWithDefault(msg, 3, 0),
    loopAll: jspb.Message.getFieldWithDefault(msg, 4, 0),
    stageProp: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerStage}
 */
proto.rule.TriggerStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerStage;
  return proto.rule.TriggerStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerStage}
 */
proto.rule.TriggerStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSequence(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoopCurr(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoopAll(value);
      break;
    case 6:
      var value = /** @type {!proto.rule.StageProp} */ (reader.readEnum());
      msg.setStageProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLoopCurr();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getLoopAll();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getStageProp();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rule.TriggerStage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.TriggerStage} returns this
 */
proto.rule.TriggerStage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 sequence = 2;
 * @return {number}
 */
proto.rule.TriggerStage.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rule.TriggerStage} returns this
 */
proto.rule.TriggerStage.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 loop_curr = 3;
 * @return {number}
 */
proto.rule.TriggerStage.prototype.getLoopCurr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rule.TriggerStage} returns this
 */
proto.rule.TriggerStage.prototype.setLoopCurr = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 loop_all = 4;
 * @return {number}
 */
proto.rule.TriggerStage.prototype.getLoopAll = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.rule.TriggerStage} returns this
 */
proto.rule.TriggerStage.prototype.setLoopAll = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional StageProp stage_prop = 6;
 * @return {!proto.rule.StageProp}
 */
proto.rule.TriggerStage.prototype.getStageProp = function() {
  return /** @type {!proto.rule.StageProp} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.rule.StageProp} value
 * @return {!proto.rule.TriggerStage} returns this
 */
proto.rule.TriggerStage.prototype.setStageProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerVariable.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerVariable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerVariable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerVariable.toObject = function(includeInstance, msg) {
  var f, obj = {
    predicate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    variable: (f = msg.getVariable()) && common_pb.VariableRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerVariable}
 */
proto.rule.TriggerVariable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerVariable;
  return proto.rule.TriggerVariable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerVariable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerVariable}
 */
proto.rule.TriggerVariable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.VariablePredicate} */ (reader.readEnum());
      msg.setPredicate(value);
      break;
    case 2:
      var value = new common_pb.VariableRequest;
      reader.readMessage(value,common_pb.VariableRequest.deserializeBinaryFromReader);
      msg.setVariable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerVariable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerVariable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerVariable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerVariable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPredicate();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVariable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.VariableRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.VariablePredicate predicate = 1;
 * @return {!proto.common.VariablePredicate}
 */
proto.rule.TriggerVariable.prototype.getPredicate = function() {
  return /** @type {!proto.common.VariablePredicate} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.VariablePredicate} value
 * @return {!proto.rule.TriggerVariable} returns this
 */
proto.rule.TriggerVariable.prototype.setPredicate = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional common.VariableRequest variable = 2;
 * @return {?proto.common.VariableRequest}
 */
proto.rule.TriggerVariable.prototype.getVariable = function() {
  return /** @type{?proto.common.VariableRequest} */ (
    jspb.Message.getWrapperField(this, common_pb.VariableRequest, 2));
};


/**
 * @param {?proto.common.VariableRequest|undefined} value
 * @return {!proto.rule.TriggerVariable} returns this
*/
proto.rule.TriggerVariable.prototype.setVariable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.TriggerVariable} returns this
 */
proto.rule.TriggerVariable.prototype.clearVariable = function() {
  return this.setVariable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.TriggerVariable.prototype.hasVariable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerOffsetCalibration.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerOffsetCalibration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerOffsetCalibration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerOffsetCalibration.toObject = function(includeInstance, msg) {
  var f, obj = {
    offset: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerOffsetCalibration}
 */
proto.rule.TriggerOffsetCalibration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerOffsetCalibration;
  return proto.rule.TriggerOffsetCalibration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerOffsetCalibration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerOffsetCalibration}
 */
proto.rule.TriggerOffsetCalibration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOffset(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.OffsetCalibrationState} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerOffsetCalibration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerOffsetCalibration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerOffsetCalibration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerOffsetCalibration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional float offset = 1;
 * @return {number}
 */
proto.rule.TriggerOffsetCalibration.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.TriggerOffsetCalibration} returns this
 */
proto.rule.TriggerOffsetCalibration.prototype.setOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional OffsetCalibrationState state = 2;
 * @return {!proto.rule.OffsetCalibrationState}
 */
proto.rule.TriggerOffsetCalibration.prototype.getState = function() {
  return /** @type {!proto.rule.OffsetCalibrationState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.OffsetCalibrationState} value
 * @return {!proto.rule.TriggerOffsetCalibration} returns this
 */
proto.rule.TriggerOffsetCalibration.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.TriggerMemStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.TriggerMemStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.TriggerMemStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerMemStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    storagePath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.TriggerMemStatus}
 */
proto.rule.TriggerMemStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.TriggerMemStatus;
  return proto.rule.TriggerMemStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.TriggerMemStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.TriggerMemStatus}
 */
proto.rule.TriggerMemStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoragePath(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.TriggerMemStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.TriggerMemStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.TriggerMemStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.TriggerMemStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoragePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string storage_path = 1;
 * @return {string}
 */
proto.rule.TriggerMemStatus.prototype.getStoragePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.TriggerMemStatus} returns this
 */
proto.rule.TriggerMemStatus.prototype.setStoragePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float threshold = 2;
 * @return {number}
 */
proto.rule.TriggerMemStatus.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.TriggerMemStatus} returns this
 */
proto.rule.TriggerMemStatus.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.MeasurementPropMoreThan.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.MeasurementPropMoreThan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.MeasurementPropMoreThan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.MeasurementPropMoreThan.toObject = function(includeInstance, msg) {
  var f, obj = {
    upperThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.MeasurementPropMoreThan}
 */
proto.rule.MeasurementPropMoreThan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.MeasurementPropMoreThan;
  return proto.rule.MeasurementPropMoreThan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.MeasurementPropMoreThan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.MeasurementPropMoreThan}
 */
proto.rule.MeasurementPropMoreThan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.MeasurementPropMoreThan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.MeasurementPropMoreThan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.MeasurementPropMoreThan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.MeasurementPropMoreThan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpperThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float upper_threshold = 1;
 * @return {number}
 */
proto.rule.MeasurementPropMoreThan.prototype.getUpperThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropMoreThan} returns this
 */
proto.rule.MeasurementPropMoreThan.prototype.setUpperThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.MeasurementPropLessThan.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.MeasurementPropLessThan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.MeasurementPropLessThan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.MeasurementPropLessThan.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowerThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.MeasurementPropLessThan}
 */
proto.rule.MeasurementPropLessThan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.MeasurementPropLessThan;
  return proto.rule.MeasurementPropLessThan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.MeasurementPropLessThan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.MeasurementPropLessThan}
 */
proto.rule.MeasurementPropLessThan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.MeasurementPropLessThan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.MeasurementPropLessThan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.MeasurementPropLessThan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.MeasurementPropLessThan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowerThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float lower_threshold = 1;
 * @return {number}
 */
proto.rule.MeasurementPropLessThan.prototype.getLowerThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropLessThan} returns this
 */
proto.rule.MeasurementPropLessThan.prototype.setLowerThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.MeasurementPropAmpChange.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.MeasurementPropAmpChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.MeasurementPropAmpChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.MeasurementPropAmpChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ampChange: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    delayCycles: jspb.Message.getFieldWithDefault(msg, 3, 0),
    changeCycles: jspb.Message.getFieldWithDefault(msg, 4, 0),
    refCycles: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.MeasurementPropAmpChange}
 */
proto.rule.MeasurementPropAmpChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.MeasurementPropAmpChange;
  return proto.rule.MeasurementPropAmpChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.MeasurementPropAmpChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.MeasurementPropAmpChange}
 */
proto.rule.MeasurementPropAmpChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmpChange(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDelayCycles(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChangeCycles(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRefCycles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.MeasurementPropAmpChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.MeasurementPropAmpChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.MeasurementPropAmpChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.MeasurementPropAmpChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAxisId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmpChange();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDelayCycles();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getChangeCycles();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRefCycles();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string axis_id = 1;
 * @return {string}
 */
proto.rule.MeasurementPropAmpChange.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.MeasurementPropAmpChange} returns this
 */
proto.rule.MeasurementPropAmpChange.prototype.setAxisId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float amp_change = 2;
 * @return {number}
 */
proto.rule.MeasurementPropAmpChange.prototype.getAmpChange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropAmpChange} returns this
 */
proto.rule.MeasurementPropAmpChange.prototype.setAmpChange = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 delay_cycles = 3;
 * @return {number}
 */
proto.rule.MeasurementPropAmpChange.prototype.getDelayCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropAmpChange} returns this
 */
proto.rule.MeasurementPropAmpChange.prototype.setDelayCycles = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 change_cycles = 4;
 * @return {number}
 */
proto.rule.MeasurementPropAmpChange.prototype.getChangeCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropAmpChange} returns this
 */
proto.rule.MeasurementPropAmpChange.prototype.setChangeCycles = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 ref_cycles = 5;
 * @return {number}
 */
proto.rule.MeasurementPropAmpChange.prototype.getRefCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropAmpChange} returns this
 */
proto.rule.MeasurementPropAmpChange.prototype.setRefCycles = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.MeasurementPropMeanChange.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.MeasurementPropMeanChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.MeasurementPropMeanChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.MeasurementPropMeanChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    maxMeanShift: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    delayCycles: jspb.Message.getFieldWithDefault(msg, 3, 0),
    changeCycles: jspb.Message.getFieldWithDefault(msg, 4, 0),
    refCycles: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.MeasurementPropMeanChange}
 */
proto.rule.MeasurementPropMeanChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.MeasurementPropMeanChange;
  return proto.rule.MeasurementPropMeanChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.MeasurementPropMeanChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.MeasurementPropMeanChange}
 */
proto.rule.MeasurementPropMeanChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxMeanShift(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDelayCycles(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChangeCycles(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRefCycles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.MeasurementPropMeanChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.MeasurementPropMeanChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.MeasurementPropMeanChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.MeasurementPropMeanChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAxisId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMaxMeanShift();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDelayCycles();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getChangeCycles();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRefCycles();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string axis_id = 1;
 * @return {string}
 */
proto.rule.MeasurementPropMeanChange.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.MeasurementPropMeanChange} returns this
 */
proto.rule.MeasurementPropMeanChange.prototype.setAxisId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float max_mean_shift = 2;
 * @return {number}
 */
proto.rule.MeasurementPropMeanChange.prototype.getMaxMeanShift = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropMeanChange} returns this
 */
proto.rule.MeasurementPropMeanChange.prototype.setMaxMeanShift = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 delay_cycles = 3;
 * @return {number}
 */
proto.rule.MeasurementPropMeanChange.prototype.getDelayCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropMeanChange} returns this
 */
proto.rule.MeasurementPropMeanChange.prototype.setDelayCycles = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 change_cycles = 4;
 * @return {number}
 */
proto.rule.MeasurementPropMeanChange.prototype.getChangeCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropMeanChange} returns this
 */
proto.rule.MeasurementPropMeanChange.prototype.setChangeCycles = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 ref_cycles = 5;
 * @return {number}
 */
proto.rule.MeasurementPropMeanChange.prototype.getRefCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropMeanChange} returns this
 */
proto.rule.MeasurementPropMeanChange.prototype.setRefCycles = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.MeasurementPropInRange.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.MeasurementPropInRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.MeasurementPropInRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.MeasurementPropInRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    upperLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lowerLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.MeasurementPropInRange}
 */
proto.rule.MeasurementPropInRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.MeasurementPropInRange;
  return proto.rule.MeasurementPropInRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.MeasurementPropInRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.MeasurementPropInRange}
 */
proto.rule.MeasurementPropInRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.MeasurementPropInRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.MeasurementPropInRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.MeasurementPropInRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.MeasurementPropInRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpperLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLowerLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float upper_limit = 1;
 * @return {number}
 */
proto.rule.MeasurementPropInRange.prototype.getUpperLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropInRange} returns this
 */
proto.rule.MeasurementPropInRange.prototype.setUpperLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float lower_limit = 2;
 * @return {number}
 */
proto.rule.MeasurementPropInRange.prototype.getLowerLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropInRange} returns this
 */
proto.rule.MeasurementPropInRange.prototype.setLowerLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.MeasurementPropOutOfRange.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.MeasurementPropOutOfRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.MeasurementPropOutOfRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.MeasurementPropOutOfRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    upperLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lowerLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.MeasurementPropOutOfRange}
 */
proto.rule.MeasurementPropOutOfRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.MeasurementPropOutOfRange;
  return proto.rule.MeasurementPropOutOfRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.MeasurementPropOutOfRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.MeasurementPropOutOfRange}
 */
proto.rule.MeasurementPropOutOfRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.MeasurementPropOutOfRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.MeasurementPropOutOfRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.MeasurementPropOutOfRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.MeasurementPropOutOfRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpperLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLowerLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float upper_limit = 1;
 * @return {number}
 */
proto.rule.MeasurementPropOutOfRange.prototype.getUpperLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropOutOfRange} returns this
 */
proto.rule.MeasurementPropOutOfRange.prototype.setUpperLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float lower_limit = 2;
 * @return {number}
 */
proto.rule.MeasurementPropOutOfRange.prototype.getLowerLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.MeasurementPropOutOfRange} returns this
 */
proto.rule.MeasurementPropOutOfRange.prototype.setLowerLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ConditionDto.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ConditionDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ConditionDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    conditionDio: (f = msg.getConditionDio()) && proto.rule.ConditionDi.toObject(includeInstance, f),
    conditionAux: (f = msg.getConditionAux()) && proto.rule.ConditionAuxDevice.toObject(includeInstance, f),
    conditionCalc: (f = msg.getConditionCalc()) && proto.rule.ConditionCalc.toObject(includeInstance, f),
    conditionMeas: (f = msg.getConditionMeas()) && proto.rule.ConditionMeasurement.toObject(includeInstance, f),
    conditionTest: (f = msg.getConditionTest()) && proto.rule.ConditionTest.toObject(includeInstance, f),
    conditionConn: (f = msg.getConditionConn()) && proto.rule.ConditionConnection.toObject(includeInstance, f),
    conditionStation: (f = msg.getConditionStation()) && proto.rule.ConditionStation.toObject(includeInstance, f),
    conditionVariable: (f = msg.getConditionVariable()) && proto.rule.ConditionVariable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ConditionDto}
 */
proto.rule.ConditionDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ConditionDto;
  return proto.rule.ConditionDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ConditionDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ConditionDto}
 */
proto.rule.ConditionDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.ConditionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 50:
      var value = new proto.rule.ConditionDi;
      reader.readMessage(value,proto.rule.ConditionDi.deserializeBinaryFromReader);
      msg.setConditionDio(value);
      break;
    case 51:
      var value = new proto.rule.ConditionAuxDevice;
      reader.readMessage(value,proto.rule.ConditionAuxDevice.deserializeBinaryFromReader);
      msg.setConditionAux(value);
      break;
    case 52:
      var value = new proto.rule.ConditionCalc;
      reader.readMessage(value,proto.rule.ConditionCalc.deserializeBinaryFromReader);
      msg.setConditionCalc(value);
      break;
    case 53:
      var value = new proto.rule.ConditionMeasurement;
      reader.readMessage(value,proto.rule.ConditionMeasurement.deserializeBinaryFromReader);
      msg.setConditionMeas(value);
      break;
    case 54:
      var value = new proto.rule.ConditionTest;
      reader.readMessage(value,proto.rule.ConditionTest.deserializeBinaryFromReader);
      msg.setConditionTest(value);
      break;
    case 55:
      var value = new proto.rule.ConditionConnection;
      reader.readMessage(value,proto.rule.ConditionConnection.deserializeBinaryFromReader);
      msg.setConditionConn(value);
      break;
    case 56:
      var value = new proto.rule.ConditionStation;
      reader.readMessage(value,proto.rule.ConditionStation.deserializeBinaryFromReader);
      msg.setConditionStation(value);
      break;
    case 57:
      var value = new proto.rule.ConditionVariable;
      reader.readMessage(value,proto.rule.ConditionVariable.deserializeBinaryFromReader);
      msg.setConditionVariable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ConditionDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ConditionDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ConditionDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getConditionDio();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.rule.ConditionDi.serializeBinaryToWriter
    );
  }
  f = message.getConditionAux();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.rule.ConditionAuxDevice.serializeBinaryToWriter
    );
  }
  f = message.getConditionCalc();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.rule.ConditionCalc.serializeBinaryToWriter
    );
  }
  f = message.getConditionMeas();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.rule.ConditionMeasurement.serializeBinaryToWriter
    );
  }
  f = message.getConditionTest();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.rule.ConditionTest.serializeBinaryToWriter
    );
  }
  f = message.getConditionConn();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      proto.rule.ConditionConnection.serializeBinaryToWriter
    );
  }
  f = message.getConditionStation();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      proto.rule.ConditionStation.serializeBinaryToWriter
    );
  }
  f = message.getConditionVariable();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      proto.rule.ConditionVariable.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rule.ConditionDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ConditionDto} returns this
 */
proto.rule.ConditionDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ConditionType type = 2;
 * @return {!proto.rule.ConditionType}
 */
proto.rule.ConditionDto.prototype.getType = function() {
  return /** @type {!proto.rule.ConditionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.ConditionType} value
 * @return {!proto.rule.ConditionDto} returns this
 */
proto.rule.ConditionDto.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ConditionDi condition_dio = 50;
 * @return {?proto.rule.ConditionDi}
 */
proto.rule.ConditionDto.prototype.getConditionDio = function() {
  return /** @type{?proto.rule.ConditionDi} */ (
    jspb.Message.getWrapperField(this, proto.rule.ConditionDi, 50));
};


/**
 * @param {?proto.rule.ConditionDi|undefined} value
 * @return {!proto.rule.ConditionDto} returns this
*/
proto.rule.ConditionDto.prototype.setConditionDio = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ConditionDto} returns this
 */
proto.rule.ConditionDto.prototype.clearConditionDio = function() {
  return this.setConditionDio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ConditionDto.prototype.hasConditionDio = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional ConditionAuxDevice condition_aux = 51;
 * @return {?proto.rule.ConditionAuxDevice}
 */
proto.rule.ConditionDto.prototype.getConditionAux = function() {
  return /** @type{?proto.rule.ConditionAuxDevice} */ (
    jspb.Message.getWrapperField(this, proto.rule.ConditionAuxDevice, 51));
};


/**
 * @param {?proto.rule.ConditionAuxDevice|undefined} value
 * @return {!proto.rule.ConditionDto} returns this
*/
proto.rule.ConditionDto.prototype.setConditionAux = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ConditionDto} returns this
 */
proto.rule.ConditionDto.prototype.clearConditionAux = function() {
  return this.setConditionAux(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ConditionDto.prototype.hasConditionAux = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional ConditionCalc condition_calc = 52;
 * @return {?proto.rule.ConditionCalc}
 */
proto.rule.ConditionDto.prototype.getConditionCalc = function() {
  return /** @type{?proto.rule.ConditionCalc} */ (
    jspb.Message.getWrapperField(this, proto.rule.ConditionCalc, 52));
};


/**
 * @param {?proto.rule.ConditionCalc|undefined} value
 * @return {!proto.rule.ConditionDto} returns this
*/
proto.rule.ConditionDto.prototype.setConditionCalc = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ConditionDto} returns this
 */
proto.rule.ConditionDto.prototype.clearConditionCalc = function() {
  return this.setConditionCalc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ConditionDto.prototype.hasConditionCalc = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional ConditionMeasurement condition_meas = 53;
 * @return {?proto.rule.ConditionMeasurement}
 */
proto.rule.ConditionDto.prototype.getConditionMeas = function() {
  return /** @type{?proto.rule.ConditionMeasurement} */ (
    jspb.Message.getWrapperField(this, proto.rule.ConditionMeasurement, 53));
};


/**
 * @param {?proto.rule.ConditionMeasurement|undefined} value
 * @return {!proto.rule.ConditionDto} returns this
*/
proto.rule.ConditionDto.prototype.setConditionMeas = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ConditionDto} returns this
 */
proto.rule.ConditionDto.prototype.clearConditionMeas = function() {
  return this.setConditionMeas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ConditionDto.prototype.hasConditionMeas = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional ConditionTest condition_test = 54;
 * @return {?proto.rule.ConditionTest}
 */
proto.rule.ConditionDto.prototype.getConditionTest = function() {
  return /** @type{?proto.rule.ConditionTest} */ (
    jspb.Message.getWrapperField(this, proto.rule.ConditionTest, 54));
};


/**
 * @param {?proto.rule.ConditionTest|undefined} value
 * @return {!proto.rule.ConditionDto} returns this
*/
proto.rule.ConditionDto.prototype.setConditionTest = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ConditionDto} returns this
 */
proto.rule.ConditionDto.prototype.clearConditionTest = function() {
  return this.setConditionTest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ConditionDto.prototype.hasConditionTest = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional ConditionConnection condition_conn = 55;
 * @return {?proto.rule.ConditionConnection}
 */
proto.rule.ConditionDto.prototype.getConditionConn = function() {
  return /** @type{?proto.rule.ConditionConnection} */ (
    jspb.Message.getWrapperField(this, proto.rule.ConditionConnection, 55));
};


/**
 * @param {?proto.rule.ConditionConnection|undefined} value
 * @return {!proto.rule.ConditionDto} returns this
*/
proto.rule.ConditionDto.prototype.setConditionConn = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ConditionDto} returns this
 */
proto.rule.ConditionDto.prototype.clearConditionConn = function() {
  return this.setConditionConn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ConditionDto.prototype.hasConditionConn = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional ConditionStation condition_station = 56;
 * @return {?proto.rule.ConditionStation}
 */
proto.rule.ConditionDto.prototype.getConditionStation = function() {
  return /** @type{?proto.rule.ConditionStation} */ (
    jspb.Message.getWrapperField(this, proto.rule.ConditionStation, 56));
};


/**
 * @param {?proto.rule.ConditionStation|undefined} value
 * @return {!proto.rule.ConditionDto} returns this
*/
proto.rule.ConditionDto.prototype.setConditionStation = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ConditionDto} returns this
 */
proto.rule.ConditionDto.prototype.clearConditionStation = function() {
  return this.setConditionStation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ConditionDto.prototype.hasConditionStation = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional ConditionVariable condition_variable = 57;
 * @return {?proto.rule.ConditionVariable}
 */
proto.rule.ConditionDto.prototype.getConditionVariable = function() {
  return /** @type{?proto.rule.ConditionVariable} */ (
    jspb.Message.getWrapperField(this, proto.rule.ConditionVariable, 57));
};


/**
 * @param {?proto.rule.ConditionVariable|undefined} value
 * @return {!proto.rule.ConditionDto} returns this
*/
proto.rule.ConditionDto.prototype.setConditionVariable = function(value) {
  return jspb.Message.setWrapperField(this, 57, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ConditionDto} returns this
 */
proto.rule.ConditionDto.prototype.clearConditionVariable = function() {
  return this.setConditionVariable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ConditionDto.prototype.hasConditionVariable = function() {
  return jspb.Message.getField(this, 57) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ConditionDi.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ConditionDi.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ConditionDi} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionDi.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    diProp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ConditionDi}
 */
proto.rule.ConditionDi.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ConditionDi;
  return proto.rule.ConditionDi.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ConditionDi} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ConditionDi}
 */
proto.rule.ConditionDi.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.DigitalInputProp} */ (reader.readEnum());
      msg.setDiProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ConditionDi.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ConditionDi.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ConditionDi} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionDi.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDiProp();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.rule.ConditionDi.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ConditionDi} returns this
 */
proto.rule.ConditionDi.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DigitalInputProp di_prop = 2;
 * @return {!proto.rule.DigitalInputProp}
 */
proto.rule.ConditionDi.prototype.getDiProp = function() {
  return /** @type {!proto.rule.DigitalInputProp} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.DigitalInputProp} value
 * @return {!proto.rule.ConditionDi} returns this
 */
proto.rule.ConditionDi.prototype.setDiProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ConditionStation.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ConditionStation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ConditionStation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionStation.toObject = function(includeInstance, msg) {
  var f, obj = {
    op: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stationProp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ConditionStation}
 */
proto.rule.ConditionStation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ConditionStation;
  return proto.rule.ConditionStation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ConditionStation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ConditionStation}
 */
proto.rule.ConditionStation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rule.ConditionStationOp} */ (reader.readEnum());
      msg.setOp(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.ConditionStationState} */ (reader.readEnum());
      msg.setStationProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ConditionStation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ConditionStation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ConditionStation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionStation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOp();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStationProp();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional ConditionStationOp op = 1;
 * @return {!proto.rule.ConditionStationOp}
 */
proto.rule.ConditionStation.prototype.getOp = function() {
  return /** @type {!proto.rule.ConditionStationOp} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rule.ConditionStationOp} value
 * @return {!proto.rule.ConditionStation} returns this
 */
proto.rule.ConditionStation.prototype.setOp = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ConditionStationState station_prop = 2;
 * @return {!proto.rule.ConditionStationState}
 */
proto.rule.ConditionStation.prototype.getStationProp = function() {
  return /** @type {!proto.rule.ConditionStationState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.ConditionStationState} value
 * @return {!proto.rule.ConditionStation} returns this
 */
proto.rule.ConditionStation.prototype.setStationProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ConditionConnection.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ConditionConnection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ConditionConnection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionConnection.toObject = function(includeInstance, msg) {
  var f, obj = {
    stProp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ConditionConnection}
 */
proto.rule.ConditionConnection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ConditionConnection;
  return proto.rule.ConditionConnection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ConditionConnection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ConditionConnection}
 */
proto.rule.ConditionConnection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rule.ConnectionProp} */ (reader.readEnum());
      msg.setStProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ConditionConnection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ConditionConnection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ConditionConnection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionConnection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStProp();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ConnectionProp st_prop = 1;
 * @return {!proto.rule.ConnectionProp}
 */
proto.rule.ConditionConnection.prototype.getStProp = function() {
  return /** @type {!proto.rule.ConnectionProp} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rule.ConnectionProp} value
 * @return {!proto.rule.ConditionConnection} returns this
 */
proto.rule.ConditionConnection.prototype.setStProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ConditionTest.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ConditionTest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ConditionTest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionTest.toObject = function(includeInstance, msg) {
  var f, obj = {
    testProp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ConditionTest}
 */
proto.rule.ConditionTest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ConditionTest;
  return proto.rule.ConditionTest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ConditionTest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ConditionTest}
 */
proto.rule.ConditionTest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rule.ConditionTestProp} */ (reader.readEnum());
      msg.setTestProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ConditionTest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ConditionTest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ConditionTest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionTest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTestProp();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ConditionTestProp test_prop = 1;
 * @return {!proto.rule.ConditionTestProp}
 */
proto.rule.ConditionTest.prototype.getTestProp = function() {
  return /** @type {!proto.rule.ConditionTestProp} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rule.ConditionTestProp} value
 * @return {!proto.rule.ConditionTest} returns this
 */
proto.rule.ConditionTest.prototype.setTestProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ConditionMeasurement.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ConditionMeasurement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ConditionMeasurement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionMeasurement.toObject = function(includeInstance, msg) {
  var f, obj = {
    measId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    moreThan: (f = msg.getMoreThan()) && proto.rule.MeasurementPropMoreThan.toObject(includeInstance, f),
    lessThan: (f = msg.getLessThan()) && proto.rule.MeasurementPropLessThan.toObject(includeInstance, f),
    inRange: (f = msg.getInRange()) && proto.rule.MeasurementPropInRange.toObject(includeInstance, f),
    outOfRange: (f = msg.getOutOfRange()) && proto.rule.MeasurementPropOutOfRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ConditionMeasurement}
 */
proto.rule.ConditionMeasurement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ConditionMeasurement;
  return proto.rule.ConditionMeasurement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ConditionMeasurement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ConditionMeasurement}
 */
proto.rule.ConditionMeasurement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasId(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.MeasurementConditionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 50:
      var value = new proto.rule.MeasurementPropMoreThan;
      reader.readMessage(value,proto.rule.MeasurementPropMoreThan.deserializeBinaryFromReader);
      msg.setMoreThan(value);
      break;
    case 51:
      var value = new proto.rule.MeasurementPropLessThan;
      reader.readMessage(value,proto.rule.MeasurementPropLessThan.deserializeBinaryFromReader);
      msg.setLessThan(value);
      break;
    case 52:
      var value = new proto.rule.MeasurementPropInRange;
      reader.readMessage(value,proto.rule.MeasurementPropInRange.deserializeBinaryFromReader);
      msg.setInRange(value);
      break;
    case 53:
      var value = new proto.rule.MeasurementPropOutOfRange;
      reader.readMessage(value,proto.rule.MeasurementPropOutOfRange.deserializeBinaryFromReader);
      msg.setOutOfRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ConditionMeasurement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ConditionMeasurement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ConditionMeasurement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionMeasurement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeasId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMoreThan();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.rule.MeasurementPropMoreThan.serializeBinaryToWriter
    );
  }
  f = message.getLessThan();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.rule.MeasurementPropLessThan.serializeBinaryToWriter
    );
  }
  f = message.getInRange();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.rule.MeasurementPropInRange.serializeBinaryToWriter
    );
  }
  f = message.getOutOfRange();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.rule.MeasurementPropOutOfRange.serializeBinaryToWriter
    );
  }
};


/**
 * optional string meas_id = 1;
 * @return {string}
 */
proto.rule.ConditionMeasurement.prototype.getMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ConditionMeasurement} returns this
 */
proto.rule.ConditionMeasurement.prototype.setMeasId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MeasurementConditionType type = 2;
 * @return {!proto.rule.MeasurementConditionType}
 */
proto.rule.ConditionMeasurement.prototype.getType = function() {
  return /** @type {!proto.rule.MeasurementConditionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.MeasurementConditionType} value
 * @return {!proto.rule.ConditionMeasurement} returns this
 */
proto.rule.ConditionMeasurement.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional MeasurementPropMoreThan more_than = 50;
 * @return {?proto.rule.MeasurementPropMoreThan}
 */
proto.rule.ConditionMeasurement.prototype.getMoreThan = function() {
  return /** @type{?proto.rule.MeasurementPropMoreThan} */ (
    jspb.Message.getWrapperField(this, proto.rule.MeasurementPropMoreThan, 50));
};


/**
 * @param {?proto.rule.MeasurementPropMoreThan|undefined} value
 * @return {!proto.rule.ConditionMeasurement} returns this
*/
proto.rule.ConditionMeasurement.prototype.setMoreThan = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ConditionMeasurement} returns this
 */
proto.rule.ConditionMeasurement.prototype.clearMoreThan = function() {
  return this.setMoreThan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ConditionMeasurement.prototype.hasMoreThan = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional MeasurementPropLessThan less_than = 51;
 * @return {?proto.rule.MeasurementPropLessThan}
 */
proto.rule.ConditionMeasurement.prototype.getLessThan = function() {
  return /** @type{?proto.rule.MeasurementPropLessThan} */ (
    jspb.Message.getWrapperField(this, proto.rule.MeasurementPropLessThan, 51));
};


/**
 * @param {?proto.rule.MeasurementPropLessThan|undefined} value
 * @return {!proto.rule.ConditionMeasurement} returns this
*/
proto.rule.ConditionMeasurement.prototype.setLessThan = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ConditionMeasurement} returns this
 */
proto.rule.ConditionMeasurement.prototype.clearLessThan = function() {
  return this.setLessThan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ConditionMeasurement.prototype.hasLessThan = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional MeasurementPropInRange in_range = 52;
 * @return {?proto.rule.MeasurementPropInRange}
 */
proto.rule.ConditionMeasurement.prototype.getInRange = function() {
  return /** @type{?proto.rule.MeasurementPropInRange} */ (
    jspb.Message.getWrapperField(this, proto.rule.MeasurementPropInRange, 52));
};


/**
 * @param {?proto.rule.MeasurementPropInRange|undefined} value
 * @return {!proto.rule.ConditionMeasurement} returns this
*/
proto.rule.ConditionMeasurement.prototype.setInRange = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ConditionMeasurement} returns this
 */
proto.rule.ConditionMeasurement.prototype.clearInRange = function() {
  return this.setInRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ConditionMeasurement.prototype.hasInRange = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional MeasurementPropOutOfRange out_of_range = 53;
 * @return {?proto.rule.MeasurementPropOutOfRange}
 */
proto.rule.ConditionMeasurement.prototype.getOutOfRange = function() {
  return /** @type{?proto.rule.MeasurementPropOutOfRange} */ (
    jspb.Message.getWrapperField(this, proto.rule.MeasurementPropOutOfRange, 53));
};


/**
 * @param {?proto.rule.MeasurementPropOutOfRange|undefined} value
 * @return {!proto.rule.ConditionMeasurement} returns this
*/
proto.rule.ConditionMeasurement.prototype.setOutOfRange = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ConditionMeasurement} returns this
 */
proto.rule.ConditionMeasurement.prototype.clearOutOfRange = function() {
  return this.setOutOfRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ConditionMeasurement.prototype.hasOutOfRange = function() {
  return jspb.Message.getField(this, 53) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ConditionAuxDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ConditionAuxDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ConditionAuxDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionAuxDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    auxDeviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ConditionAuxDevice}
 */
proto.rule.ConditionAuxDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ConditionAuxDevice;
  return proto.rule.ConditionAuxDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ConditionAuxDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ConditionAuxDevice}
 */
proto.rule.ConditionAuxDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuxDeviceId(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.AuxDeviceStateProp} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ConditionAuxDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ConditionAuxDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ConditionAuxDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionAuxDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuxDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string aux_device_id = 1;
 * @return {string}
 */
proto.rule.ConditionAuxDevice.prototype.getAuxDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ConditionAuxDevice} returns this
 */
proto.rule.ConditionAuxDevice.prototype.setAuxDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AuxDeviceStateProp state = 2;
 * @return {!proto.rule.AuxDeviceStateProp}
 */
proto.rule.ConditionAuxDevice.prototype.getState = function() {
  return /** @type {!proto.rule.AuxDeviceStateProp} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.AuxDeviceStateProp} value
 * @return {!proto.rule.ConditionAuxDevice} returns this
 */
proto.rule.ConditionAuxDevice.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ConditionCalc.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ConditionCalc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ConditionCalc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionCalc.toObject = function(includeInstance, msg) {
  var f, obj = {
    calcId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    condition: jspb.Message.getFieldWithDefault(msg, 2, 0),
    upperLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    lowerLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ConditionCalc}
 */
proto.rule.ConditionCalc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ConditionCalc;
  return proto.rule.ConditionCalc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ConditionCalc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ConditionCalc}
 */
proto.rule.ConditionCalc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalcId(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.ConditionCalcProp} */ (reader.readEnum());
      msg.setCondition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperLimit(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ConditionCalc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ConditionCalc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ConditionCalc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionCalc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalcId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCondition();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUpperLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getLowerLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional string calc_id = 1;
 * @return {string}
 */
proto.rule.ConditionCalc.prototype.getCalcId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ConditionCalc} returns this
 */
proto.rule.ConditionCalc.prototype.setCalcId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ConditionCalcProp condition = 2;
 * @return {!proto.rule.ConditionCalcProp}
 */
proto.rule.ConditionCalc.prototype.getCondition = function() {
  return /** @type {!proto.rule.ConditionCalcProp} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.ConditionCalcProp} value
 * @return {!proto.rule.ConditionCalc} returns this
 */
proto.rule.ConditionCalc.prototype.setCondition = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional float upper_limit = 3;
 * @return {number}
 */
proto.rule.ConditionCalc.prototype.getUpperLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.ConditionCalc} returns this
 */
proto.rule.ConditionCalc.prototype.setUpperLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float lower_limit = 4;
 * @return {number}
 */
proto.rule.ConditionCalc.prototype.getLowerLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.ConditionCalc} returns this
 */
proto.rule.ConditionCalc.prototype.setLowerLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ConditionVariable.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ConditionVariable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ConditionVariable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionVariable.toObject = function(includeInstance, msg) {
  var f, obj = {
    predicate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    variable: (f = msg.getVariable()) && common_pb.VariableRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ConditionVariable}
 */
proto.rule.ConditionVariable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ConditionVariable;
  return proto.rule.ConditionVariable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ConditionVariable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ConditionVariable}
 */
proto.rule.ConditionVariable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.VariablePredicate} */ (reader.readEnum());
      msg.setPredicate(value);
      break;
    case 2:
      var value = new common_pb.VariableRequest;
      reader.readMessage(value,common_pb.VariableRequest.deserializeBinaryFromReader);
      msg.setVariable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ConditionVariable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ConditionVariable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ConditionVariable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ConditionVariable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPredicate();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVariable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.VariableRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.VariablePredicate predicate = 1;
 * @return {!proto.common.VariablePredicate}
 */
proto.rule.ConditionVariable.prototype.getPredicate = function() {
  return /** @type {!proto.common.VariablePredicate} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.VariablePredicate} value
 * @return {!proto.rule.ConditionVariable} returns this
 */
proto.rule.ConditionVariable.prototype.setPredicate = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional common.VariableRequest variable = 2;
 * @return {?proto.common.VariableRequest}
 */
proto.rule.ConditionVariable.prototype.getVariable = function() {
  return /** @type{?proto.common.VariableRequest} */ (
    jspb.Message.getWrapperField(this, common_pb.VariableRequest, 2));
};


/**
 * @param {?proto.common.VariableRequest|undefined} value
 * @return {!proto.rule.ConditionVariable} returns this
*/
proto.rule.ConditionVariable.prototype.setVariable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ConditionVariable} returns this
 */
proto.rule.ConditionVariable.prototype.clearVariable = function() {
  return this.setVariable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ConditionVariable.prototype.hasVariable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionDto.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actionDo: (f = msg.getActionDo()) && proto.rule.ActionTypeDO.toObject(includeInstance, f),
    actionStation: (f = msg.getActionStation()) && proto.rule.ActionTypeStation.toObject(includeInstance, f),
    actionControl: (f = msg.getActionControl()) && proto.rule.ActionTypeControl.toObject(includeInstance, f),
    actionTest: (f = msg.getActionTest()) && proto.rule.ActionTypeTest.toObject(includeInstance, f),
    openMsgBox: (f = msg.getOpenMsgBox()) && proto.rule.ClientAppOpenMessageBox.toObject(includeInstance, f),
    openMsgBoxConfirm: (f = msg.getOpenMsgBoxConfirm()) && proto.rule.ClientAppOpenMessageBoxConfirm.toObject(includeInstance, f),
    requestVarEntry: (f = msg.getRequestVarEntry()) && proto.rule.ClientAppRequestVarEntry.toObject(includeInstance, f),
    report: (f = msg.getReport()) && proto.rule.ClientAppReport.toObject(includeInstance, f),
    actionSpecimen: (f = msg.getActionSpecimen()) && proto.rule.ActionTypeSpecimen.toObject(includeInstance, f),
    actionCalc: (f = msg.getActionCalc()) && proto.rule.ActionTypeCalc.toObject(includeInstance, f),
    actionStage: (f = msg.getActionStage()) && proto.rule.ActionTypeStage.toObject(includeInstance, f),
    actionMeas: (f = msg.getActionMeas()) && proto.rule.ActionTypeMeas.toObject(includeInstance, f),
    actionAxis: (f = msg.getActionAxis()) && proto.rule.ActionTypeAxis.toObject(includeInstance, f),
    actionVariable: (f = msg.getActionVariable()) && proto.rule.ActionTypeVariable.toObject(includeInstance, f),
    actionAnalogOutput: (f = msg.getActionAnalogOutput()) && proto.rule.ActionTypeAnalogOutput.toObject(includeInstance, f),
    actionOc: (f = msg.getActionOc()) && proto.rule.ActionTypeOffsetCalibration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionDto}
 */
proto.rule.ActionDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionDto;
  return proto.rule.ActionDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionDto}
 */
proto.rule.ActionDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.ActionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 50:
      var value = new proto.rule.ActionTypeDO;
      reader.readMessage(value,proto.rule.ActionTypeDO.deserializeBinaryFromReader);
      msg.setActionDo(value);
      break;
    case 51:
      var value = new proto.rule.ActionTypeStation;
      reader.readMessage(value,proto.rule.ActionTypeStation.deserializeBinaryFromReader);
      msg.setActionStation(value);
      break;
    case 52:
      var value = new proto.rule.ActionTypeControl;
      reader.readMessage(value,proto.rule.ActionTypeControl.deserializeBinaryFromReader);
      msg.setActionControl(value);
      break;
    case 53:
      var value = new proto.rule.ActionTypeTest;
      reader.readMessage(value,proto.rule.ActionTypeTest.deserializeBinaryFromReader);
      msg.setActionTest(value);
      break;
    case 54:
      var value = new proto.rule.ClientAppOpenMessageBox;
      reader.readMessage(value,proto.rule.ClientAppOpenMessageBox.deserializeBinaryFromReader);
      msg.setOpenMsgBox(value);
      break;
    case 55:
      var value = new proto.rule.ClientAppOpenMessageBoxConfirm;
      reader.readMessage(value,proto.rule.ClientAppOpenMessageBoxConfirm.deserializeBinaryFromReader);
      msg.setOpenMsgBoxConfirm(value);
      break;
    case 56:
      var value = new proto.rule.ClientAppRequestVarEntry;
      reader.readMessage(value,proto.rule.ClientAppRequestVarEntry.deserializeBinaryFromReader);
      msg.setRequestVarEntry(value);
      break;
    case 57:
      var value = new proto.rule.ClientAppReport;
      reader.readMessage(value,proto.rule.ClientAppReport.deserializeBinaryFromReader);
      msg.setReport(value);
      break;
    case 58:
      var value = new proto.rule.ActionTypeSpecimen;
      reader.readMessage(value,proto.rule.ActionTypeSpecimen.deserializeBinaryFromReader);
      msg.setActionSpecimen(value);
      break;
    case 59:
      var value = new proto.rule.ActionTypeCalc;
      reader.readMessage(value,proto.rule.ActionTypeCalc.deserializeBinaryFromReader);
      msg.setActionCalc(value);
      break;
    case 60:
      var value = new proto.rule.ActionTypeStage;
      reader.readMessage(value,proto.rule.ActionTypeStage.deserializeBinaryFromReader);
      msg.setActionStage(value);
      break;
    case 61:
      var value = new proto.rule.ActionTypeMeas;
      reader.readMessage(value,proto.rule.ActionTypeMeas.deserializeBinaryFromReader);
      msg.setActionMeas(value);
      break;
    case 62:
      var value = new proto.rule.ActionTypeAxis;
      reader.readMessage(value,proto.rule.ActionTypeAxis.deserializeBinaryFromReader);
      msg.setActionAxis(value);
      break;
    case 63:
      var value = new proto.rule.ActionTypeVariable;
      reader.readMessage(value,proto.rule.ActionTypeVariable.deserializeBinaryFromReader);
      msg.setActionVariable(value);
      break;
    case 64:
      var value = new proto.rule.ActionTypeAnalogOutput;
      reader.readMessage(value,proto.rule.ActionTypeAnalogOutput.deserializeBinaryFromReader);
      msg.setActionAnalogOutput(value);
      break;
    case 65:
      var value = new proto.rule.ActionTypeOffsetCalibration;
      reader.readMessage(value,proto.rule.ActionTypeOffsetCalibration.deserializeBinaryFromReader);
      msg.setActionOc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getActionDo();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.rule.ActionTypeDO.serializeBinaryToWriter
    );
  }
  f = message.getActionStation();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.rule.ActionTypeStation.serializeBinaryToWriter
    );
  }
  f = message.getActionControl();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.rule.ActionTypeControl.serializeBinaryToWriter
    );
  }
  f = message.getActionTest();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.rule.ActionTypeTest.serializeBinaryToWriter
    );
  }
  f = message.getOpenMsgBox();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.rule.ClientAppOpenMessageBox.serializeBinaryToWriter
    );
  }
  f = message.getOpenMsgBoxConfirm();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      proto.rule.ClientAppOpenMessageBoxConfirm.serializeBinaryToWriter
    );
  }
  f = message.getRequestVarEntry();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      proto.rule.ClientAppRequestVarEntry.serializeBinaryToWriter
    );
  }
  f = message.getReport();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      proto.rule.ClientAppReport.serializeBinaryToWriter
    );
  }
  f = message.getActionSpecimen();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      proto.rule.ActionTypeSpecimen.serializeBinaryToWriter
    );
  }
  f = message.getActionCalc();
  if (f != null) {
    writer.writeMessage(
      59,
      f,
      proto.rule.ActionTypeCalc.serializeBinaryToWriter
    );
  }
  f = message.getActionStage();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      proto.rule.ActionTypeStage.serializeBinaryToWriter
    );
  }
  f = message.getActionMeas();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      proto.rule.ActionTypeMeas.serializeBinaryToWriter
    );
  }
  f = message.getActionAxis();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      proto.rule.ActionTypeAxis.serializeBinaryToWriter
    );
  }
  f = message.getActionVariable();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      proto.rule.ActionTypeVariable.serializeBinaryToWriter
    );
  }
  f = message.getActionAnalogOutput();
  if (f != null) {
    writer.writeMessage(
      64,
      f,
      proto.rule.ActionTypeAnalogOutput.serializeBinaryToWriter
    );
  }
  f = message.getActionOc();
  if (f != null) {
    writer.writeMessage(
      65,
      f,
      proto.rule.ActionTypeOffsetCalibration.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rule.ActionDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ActionType type = 2;
 * @return {!proto.rule.ActionType}
 */
proto.rule.ActionDto.prototype.getType = function() {
  return /** @type {!proto.rule.ActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.ActionType} value
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ActionTypeDO action_do = 50;
 * @return {?proto.rule.ActionTypeDO}
 */
proto.rule.ActionDto.prototype.getActionDo = function() {
  return /** @type{?proto.rule.ActionTypeDO} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeDO, 50));
};


/**
 * @param {?proto.rule.ActionTypeDO|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setActionDo = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearActionDo = function() {
  return this.setActionDo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasActionDo = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional ActionTypeStation action_station = 51;
 * @return {?proto.rule.ActionTypeStation}
 */
proto.rule.ActionDto.prototype.getActionStation = function() {
  return /** @type{?proto.rule.ActionTypeStation} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeStation, 51));
};


/**
 * @param {?proto.rule.ActionTypeStation|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setActionStation = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearActionStation = function() {
  return this.setActionStation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasActionStation = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional ActionTypeControl action_control = 52;
 * @return {?proto.rule.ActionTypeControl}
 */
proto.rule.ActionDto.prototype.getActionControl = function() {
  return /** @type{?proto.rule.ActionTypeControl} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeControl, 52));
};


/**
 * @param {?proto.rule.ActionTypeControl|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setActionControl = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearActionControl = function() {
  return this.setActionControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasActionControl = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional ActionTypeTest action_test = 53;
 * @return {?proto.rule.ActionTypeTest}
 */
proto.rule.ActionDto.prototype.getActionTest = function() {
  return /** @type{?proto.rule.ActionTypeTest} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeTest, 53));
};


/**
 * @param {?proto.rule.ActionTypeTest|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setActionTest = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearActionTest = function() {
  return this.setActionTest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasActionTest = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional ClientAppOpenMessageBox open_msg_box = 54;
 * @return {?proto.rule.ClientAppOpenMessageBox}
 */
proto.rule.ActionDto.prototype.getOpenMsgBox = function() {
  return /** @type{?proto.rule.ClientAppOpenMessageBox} */ (
    jspb.Message.getWrapperField(this, proto.rule.ClientAppOpenMessageBox, 54));
};


/**
 * @param {?proto.rule.ClientAppOpenMessageBox|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setOpenMsgBox = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearOpenMsgBox = function() {
  return this.setOpenMsgBox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasOpenMsgBox = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional ClientAppOpenMessageBoxConfirm open_msg_box_confirm = 55;
 * @return {?proto.rule.ClientAppOpenMessageBoxConfirm}
 */
proto.rule.ActionDto.prototype.getOpenMsgBoxConfirm = function() {
  return /** @type{?proto.rule.ClientAppOpenMessageBoxConfirm} */ (
    jspb.Message.getWrapperField(this, proto.rule.ClientAppOpenMessageBoxConfirm, 55));
};


/**
 * @param {?proto.rule.ClientAppOpenMessageBoxConfirm|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setOpenMsgBoxConfirm = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearOpenMsgBoxConfirm = function() {
  return this.setOpenMsgBoxConfirm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasOpenMsgBoxConfirm = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional ClientAppRequestVarEntry request_var_entry = 56;
 * @return {?proto.rule.ClientAppRequestVarEntry}
 */
proto.rule.ActionDto.prototype.getRequestVarEntry = function() {
  return /** @type{?proto.rule.ClientAppRequestVarEntry} */ (
    jspb.Message.getWrapperField(this, proto.rule.ClientAppRequestVarEntry, 56));
};


/**
 * @param {?proto.rule.ClientAppRequestVarEntry|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setRequestVarEntry = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearRequestVarEntry = function() {
  return this.setRequestVarEntry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasRequestVarEntry = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional ClientAppReport report = 57;
 * @return {?proto.rule.ClientAppReport}
 */
proto.rule.ActionDto.prototype.getReport = function() {
  return /** @type{?proto.rule.ClientAppReport} */ (
    jspb.Message.getWrapperField(this, proto.rule.ClientAppReport, 57));
};


/**
 * @param {?proto.rule.ClientAppReport|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setReport = function(value) {
  return jspb.Message.setWrapperField(this, 57, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearReport = function() {
  return this.setReport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasReport = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional ActionTypeSpecimen action_specimen = 58;
 * @return {?proto.rule.ActionTypeSpecimen}
 */
proto.rule.ActionDto.prototype.getActionSpecimen = function() {
  return /** @type{?proto.rule.ActionTypeSpecimen} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeSpecimen, 58));
};


/**
 * @param {?proto.rule.ActionTypeSpecimen|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setActionSpecimen = function(value) {
  return jspb.Message.setWrapperField(this, 58, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearActionSpecimen = function() {
  return this.setActionSpecimen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasActionSpecimen = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional ActionTypeCalc action_calc = 59;
 * @return {?proto.rule.ActionTypeCalc}
 */
proto.rule.ActionDto.prototype.getActionCalc = function() {
  return /** @type{?proto.rule.ActionTypeCalc} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeCalc, 59));
};


/**
 * @param {?proto.rule.ActionTypeCalc|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setActionCalc = function(value) {
  return jspb.Message.setWrapperField(this, 59, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearActionCalc = function() {
  return this.setActionCalc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasActionCalc = function() {
  return jspb.Message.getField(this, 59) != null;
};


/**
 * optional ActionTypeStage action_stage = 60;
 * @return {?proto.rule.ActionTypeStage}
 */
proto.rule.ActionDto.prototype.getActionStage = function() {
  return /** @type{?proto.rule.ActionTypeStage} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeStage, 60));
};


/**
 * @param {?proto.rule.ActionTypeStage|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setActionStage = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearActionStage = function() {
  return this.setActionStage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasActionStage = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional ActionTypeMeas action_meas = 61;
 * @return {?proto.rule.ActionTypeMeas}
 */
proto.rule.ActionDto.prototype.getActionMeas = function() {
  return /** @type{?proto.rule.ActionTypeMeas} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeMeas, 61));
};


/**
 * @param {?proto.rule.ActionTypeMeas|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setActionMeas = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearActionMeas = function() {
  return this.setActionMeas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasActionMeas = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional ActionTypeAxis action_axis = 62;
 * @return {?proto.rule.ActionTypeAxis}
 */
proto.rule.ActionDto.prototype.getActionAxis = function() {
  return /** @type{?proto.rule.ActionTypeAxis} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeAxis, 62));
};


/**
 * @param {?proto.rule.ActionTypeAxis|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setActionAxis = function(value) {
  return jspb.Message.setWrapperField(this, 62, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearActionAxis = function() {
  return this.setActionAxis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasActionAxis = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional ActionTypeVariable action_variable = 63;
 * @return {?proto.rule.ActionTypeVariable}
 */
proto.rule.ActionDto.prototype.getActionVariable = function() {
  return /** @type{?proto.rule.ActionTypeVariable} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeVariable, 63));
};


/**
 * @param {?proto.rule.ActionTypeVariable|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setActionVariable = function(value) {
  return jspb.Message.setWrapperField(this, 63, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearActionVariable = function() {
  return this.setActionVariable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasActionVariable = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional ActionTypeAnalogOutput action_analog_output = 64;
 * @return {?proto.rule.ActionTypeAnalogOutput}
 */
proto.rule.ActionDto.prototype.getActionAnalogOutput = function() {
  return /** @type{?proto.rule.ActionTypeAnalogOutput} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeAnalogOutput, 64));
};


/**
 * @param {?proto.rule.ActionTypeAnalogOutput|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setActionAnalogOutput = function(value) {
  return jspb.Message.setWrapperField(this, 64, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearActionAnalogOutput = function() {
  return this.setActionAnalogOutput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasActionAnalogOutput = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional ActionTypeOffsetCalibration action_oc = 65;
 * @return {?proto.rule.ActionTypeOffsetCalibration}
 */
proto.rule.ActionDto.prototype.getActionOc = function() {
  return /** @type{?proto.rule.ActionTypeOffsetCalibration} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeOffsetCalibration, 65));
};


/**
 * @param {?proto.rule.ActionTypeOffsetCalibration|undefined} value
 * @return {!proto.rule.ActionDto} returns this
*/
proto.rule.ActionDto.prototype.setActionOc = function(value) {
  return jspb.Message.setWrapperField(this, 65, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionDto} returns this
 */
proto.rule.ActionDto.prototype.clearActionOc = function() {
  return this.setActionOc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionDto.prototype.hasActionOc = function() {
  return jspb.Message.getField(this, 65) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rule.ActionTypeDO.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionTypeDO.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionTypeDO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionTypeDO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeDO.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    action: jspb.Message.getFieldWithDefault(msg, 2, 0),
    freq: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    pulses: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionTypeDO}
 */
proto.rule.ActionTypeDO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionTypeDO;
  return proto.rule.ActionTypeDO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionTypeDO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionTypeDO}
 */
proto.rule.ActionTypeDO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addChannelId(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.DigitalOutputActionProp} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFreq(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPulses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionTypeDO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionTypeDO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionTypeDO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeDO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFreq();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getPulses();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * repeated string channel_id = 1;
 * @return {!Array<string>}
 */
proto.rule.ActionTypeDO.prototype.getChannelIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rule.ActionTypeDO} returns this
 */
proto.rule.ActionTypeDO.prototype.setChannelIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rule.ActionTypeDO} returns this
 */
proto.rule.ActionTypeDO.prototype.addChannelId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rule.ActionTypeDO} returns this
 */
proto.rule.ActionTypeDO.prototype.clearChannelIdList = function() {
  return this.setChannelIdList([]);
};


/**
 * optional DigitalOutputActionProp action = 2;
 * @return {!proto.rule.DigitalOutputActionProp}
 */
proto.rule.ActionTypeDO.prototype.getAction = function() {
  return /** @type {!proto.rule.DigitalOutputActionProp} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.DigitalOutputActionProp} value
 * @return {!proto.rule.ActionTypeDO} returns this
 */
proto.rule.ActionTypeDO.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional float freq = 3;
 * @return {number}
 */
proto.rule.ActionTypeDO.prototype.getFreq = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.ActionTypeDO} returns this
 */
proto.rule.ActionTypeDO.prototype.setFreq = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional uint32 pulses = 4;
 * @return {number}
 */
proto.rule.ActionTypeDO.prototype.getPulses = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.rule.ActionTypeDO} returns this
 */
proto.rule.ActionTypeDO.prototype.setPulses = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.rule.ActionTypeAnalogOutput.oneofGroups_ = [[4,5]];

/**
 * @enum {number}
 */
proto.rule.ActionTypeAnalogOutput.AoActionCase = {
  AO_ACTION_NOT_SET: 0,
  AO_MEASUREMENT: 4,
  AO_CONSTANT: 5
};

/**
 * @return {proto.rule.ActionTypeAnalogOutput.AoActionCase}
 */
proto.rule.ActionTypeAnalogOutput.prototype.getAoActionCase = function() {
  return /** @type {proto.rule.ActionTypeAnalogOutput.AoActionCase} */(jspb.Message.computeOneofCase(this, proto.rule.ActionTypeAnalogOutput.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionTypeAnalogOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionTypeAnalogOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionTypeAnalogOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeAnalogOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    scId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    calibration: (f = msg.getCalibration()) && common_pb.Calibration.toObject(includeInstance, f),
    actionType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    aoMeasurement: (f = msg.getAoMeasurement()) && proto.rule.AnalogOutputMeasurement.toObject(includeInstance, f),
    aoConstant: (f = msg.getAoConstant()) && proto.rule.AnalogOutputConstant.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionTypeAnalogOutput}
 */
proto.rule.ActionTypeAnalogOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionTypeAnalogOutput;
  return proto.rule.ActionTypeAnalogOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionTypeAnalogOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionTypeAnalogOutput}
 */
proto.rule.ActionTypeAnalogOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScId(value);
      break;
    case 2:
      var value = new common_pb.Calibration;
      reader.readMessage(value,common_pb.Calibration.deserializeBinaryFromReader);
      msg.setCalibration(value);
      break;
    case 3:
      var value = /** @type {!proto.rule.AnalogOutputActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    case 4:
      var value = new proto.rule.AnalogOutputMeasurement;
      reader.readMessage(value,proto.rule.AnalogOutputMeasurement.deserializeBinaryFromReader);
      msg.setAoMeasurement(value);
      break;
    case 5:
      var value = new proto.rule.AnalogOutputConstant;
      reader.readMessage(value,proto.rule.AnalogOutputConstant.deserializeBinaryFromReader);
      msg.setAoConstant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionTypeAnalogOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionTypeAnalogOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionTypeAnalogOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeAnalogOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCalibration();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.Calibration.serializeBinaryToWriter
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAoMeasurement();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.rule.AnalogOutputMeasurement.serializeBinaryToWriter
    );
  }
  f = message.getAoConstant();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.rule.AnalogOutputConstant.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sc_id = 1;
 * @return {string}
 */
proto.rule.ActionTypeAnalogOutput.prototype.getScId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ActionTypeAnalogOutput} returns this
 */
proto.rule.ActionTypeAnalogOutput.prototype.setScId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Calibration calibration = 2;
 * @return {?proto.common.Calibration}
 */
proto.rule.ActionTypeAnalogOutput.prototype.getCalibration = function() {
  return /** @type{?proto.common.Calibration} */ (
    jspb.Message.getWrapperField(this, common_pb.Calibration, 2));
};


/**
 * @param {?proto.common.Calibration|undefined} value
 * @return {!proto.rule.ActionTypeAnalogOutput} returns this
*/
proto.rule.ActionTypeAnalogOutput.prototype.setCalibration = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeAnalogOutput} returns this
 */
proto.rule.ActionTypeAnalogOutput.prototype.clearCalibration = function() {
  return this.setCalibration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeAnalogOutput.prototype.hasCalibration = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AnalogOutputActionType action_type = 3;
 * @return {!proto.rule.AnalogOutputActionType}
 */
proto.rule.ActionTypeAnalogOutput.prototype.getActionType = function() {
  return /** @type {!proto.rule.AnalogOutputActionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.rule.AnalogOutputActionType} value
 * @return {!proto.rule.ActionTypeAnalogOutput} returns this
 */
proto.rule.ActionTypeAnalogOutput.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional AnalogOutputMeasurement ao_measurement = 4;
 * @return {?proto.rule.AnalogOutputMeasurement}
 */
proto.rule.ActionTypeAnalogOutput.prototype.getAoMeasurement = function() {
  return /** @type{?proto.rule.AnalogOutputMeasurement} */ (
    jspb.Message.getWrapperField(this, proto.rule.AnalogOutputMeasurement, 4));
};


/**
 * @param {?proto.rule.AnalogOutputMeasurement|undefined} value
 * @return {!proto.rule.ActionTypeAnalogOutput} returns this
*/
proto.rule.ActionTypeAnalogOutput.prototype.setAoMeasurement = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.rule.ActionTypeAnalogOutput.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeAnalogOutput} returns this
 */
proto.rule.ActionTypeAnalogOutput.prototype.clearAoMeasurement = function() {
  return this.setAoMeasurement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeAnalogOutput.prototype.hasAoMeasurement = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AnalogOutputConstant ao_constant = 5;
 * @return {?proto.rule.AnalogOutputConstant}
 */
proto.rule.ActionTypeAnalogOutput.prototype.getAoConstant = function() {
  return /** @type{?proto.rule.AnalogOutputConstant} */ (
    jspb.Message.getWrapperField(this, proto.rule.AnalogOutputConstant, 5));
};


/**
 * @param {?proto.rule.AnalogOutputConstant|undefined} value
 * @return {!proto.rule.ActionTypeAnalogOutput} returns this
*/
proto.rule.ActionTypeAnalogOutput.prototype.setAoConstant = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.rule.ActionTypeAnalogOutput.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeAnalogOutput} returns this
 */
proto.rule.ActionTypeAnalogOutput.prototype.clearAoConstant = function() {
  return this.setAoConstant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeAnalogOutput.prototype.hasAoConstant = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.AnalogOutputMeasurement.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.AnalogOutputMeasurement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.AnalogOutputMeasurement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AnalogOutputMeasurement.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.AnalogOutputMeasurement}
 */
proto.rule.AnalogOutputMeasurement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.AnalogOutputMeasurement;
  return proto.rule.AnalogOutputMeasurement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.AnalogOutputMeasurement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.AnalogOutputMeasurement}
 */
proto.rule.AnalogOutputMeasurement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.AnalogOutputMeasurement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.AnalogOutputMeasurement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.AnalogOutputMeasurement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AnalogOutputMeasurement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rule.AnalogOutputMeasurement.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.AnalogOutputMeasurement} returns this
 */
proto.rule.AnalogOutputMeasurement.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.AnalogOutputConstant.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.AnalogOutputConstant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.AnalogOutputConstant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AnalogOutputConstant.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.AnalogOutputConstant}
 */
proto.rule.AnalogOutputConstant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.AnalogOutputConstant;
  return proto.rule.AnalogOutputConstant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.AnalogOutputConstant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.AnalogOutputConstant}
 */
proto.rule.AnalogOutputConstant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.AnalogOutputConstant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.AnalogOutputConstant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.AnalogOutputConstant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AnalogOutputConstant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float value = 1;
 * @return {number}
 */
proto.rule.AnalogOutputConstant.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.AnalogOutputConstant} returns this
 */
proto.rule.AnalogOutputConstant.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionTypeControl.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionTypeControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionTypeControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeControl.toObject = function(includeInstance, msg) {
  var f, obj = {
    ccId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operate: (f = msg.getOperate()) && common_pb.AxisInfo.toObject(includeInstance, f),
    disable: (f = msg.getDisable()) && proto.rule.ActionTypeControlDisable.toObject(includeInstance, f),
    update: (f = msg.getUpdate()) && common_pb.AxisInfo.toObject(includeInstance, f),
    set: (f = msg.getSet()) && common_pb.ControlInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionTypeControl}
 */
proto.rule.ActionTypeControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionTypeControl;
  return proto.rule.ActionTypeControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionTypeControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionTypeControl}
 */
proto.rule.ActionTypeControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCcId(value);
      break;
    case 2:
      var value = /** @type {!proto.rule.ControlActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    case 50:
      var value = new common_pb.AxisInfo;
      reader.readMessage(value,common_pb.AxisInfo.deserializeBinaryFromReader);
      msg.setOperate(value);
      break;
    case 51:
      var value = new proto.rule.ActionTypeControlDisable;
      reader.readMessage(value,proto.rule.ActionTypeControlDisable.deserializeBinaryFromReader);
      msg.setDisable(value);
      break;
    case 52:
      var value = new common_pb.AxisInfo;
      reader.readMessage(value,common_pb.AxisInfo.deserializeBinaryFromReader);
      msg.setUpdate(value);
      break;
    case 53:
      var value = new common_pb.ControlInfo;
      reader.readMessage(value,common_pb.ControlInfo.deserializeBinaryFromReader);
      msg.setSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionTypeControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionTypeControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionTypeControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCcId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOperate();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      common_pb.AxisInfo.serializeBinaryToWriter
    );
  }
  f = message.getDisable();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.rule.ActionTypeControlDisable.serializeBinaryToWriter
    );
  }
  f = message.getUpdate();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      common_pb.AxisInfo.serializeBinaryToWriter
    );
  }
  f = message.getSet();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      common_pb.ControlInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string cc_id = 1;
 * @return {string}
 */
proto.rule.ActionTypeControl.prototype.getCcId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ActionTypeControl} returns this
 */
proto.rule.ActionTypeControl.prototype.setCcId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ControlActionType action_type = 2;
 * @return {!proto.rule.ControlActionType}
 */
proto.rule.ActionTypeControl.prototype.getActionType = function() {
  return /** @type {!proto.rule.ControlActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rule.ControlActionType} value
 * @return {!proto.rule.ActionTypeControl} returns this
 */
proto.rule.ActionTypeControl.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional common.AxisInfo operate = 50;
 * @return {?proto.common.AxisInfo}
 */
proto.rule.ActionTypeControl.prototype.getOperate = function() {
  return /** @type{?proto.common.AxisInfo} */ (
    jspb.Message.getWrapperField(this, common_pb.AxisInfo, 50));
};


/**
 * @param {?proto.common.AxisInfo|undefined} value
 * @return {!proto.rule.ActionTypeControl} returns this
*/
proto.rule.ActionTypeControl.prototype.setOperate = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeControl} returns this
 */
proto.rule.ActionTypeControl.prototype.clearOperate = function() {
  return this.setOperate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeControl.prototype.hasOperate = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional ActionTypeControlDisable disable = 51;
 * @return {?proto.rule.ActionTypeControlDisable}
 */
proto.rule.ActionTypeControl.prototype.getDisable = function() {
  return /** @type{?proto.rule.ActionTypeControlDisable} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeControlDisable, 51));
};


/**
 * @param {?proto.rule.ActionTypeControlDisable|undefined} value
 * @return {!proto.rule.ActionTypeControl} returns this
*/
proto.rule.ActionTypeControl.prototype.setDisable = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeControl} returns this
 */
proto.rule.ActionTypeControl.prototype.clearDisable = function() {
  return this.setDisable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeControl.prototype.hasDisable = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional common.AxisInfo update = 52;
 * @return {?proto.common.AxisInfo}
 */
proto.rule.ActionTypeControl.prototype.getUpdate = function() {
  return /** @type{?proto.common.AxisInfo} */ (
    jspb.Message.getWrapperField(this, common_pb.AxisInfo, 52));
};


/**
 * @param {?proto.common.AxisInfo|undefined} value
 * @return {!proto.rule.ActionTypeControl} returns this
*/
proto.rule.ActionTypeControl.prototype.setUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeControl} returns this
 */
proto.rule.ActionTypeControl.prototype.clearUpdate = function() {
  return this.setUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeControl.prototype.hasUpdate = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional common.ControlInfo set = 53;
 * @return {?proto.common.ControlInfo}
 */
proto.rule.ActionTypeControl.prototype.getSet = function() {
  return /** @type{?proto.common.ControlInfo} */ (
    jspb.Message.getWrapperField(this, common_pb.ControlInfo, 53));
};


/**
 * @param {?proto.common.ControlInfo|undefined} value
 * @return {!proto.rule.ActionTypeControl} returns this
*/
proto.rule.ActionTypeControl.prototype.setSet = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeControl} returns this
 */
proto.rule.ActionTypeControl.prototype.clearSet = function() {
  return this.setSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeControl.prototype.hasSet = function() {
  return jspb.Message.getField(this, 53) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionTypeCalc.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionTypeCalc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionTypeCalc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeCalc.toObject = function(includeInstance, msg) {
  var f, obj = {
    calcName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    calcId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actionType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    run: (f = msg.getRun()) && proto.rule.CalcActionRun.toObject(includeInstance, f),
    drawModLine: (f = msg.getDrawModLine()) && proto.rule.CalcActionDrawModulusLine.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionTypeCalc}
 */
proto.rule.ActionTypeCalc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionTypeCalc;
  return proto.rule.ActionTypeCalc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionTypeCalc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionTypeCalc}
 */
proto.rule.ActionTypeCalc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalcName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalcId(value);
      break;
    case 3:
      var value = /** @type {!proto.rule.CalcActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    case 50:
      var value = new proto.rule.CalcActionRun;
      reader.readMessage(value,proto.rule.CalcActionRun.deserializeBinaryFromReader);
      msg.setRun(value);
      break;
    case 51:
      var value = new proto.rule.CalcActionDrawModulusLine;
      reader.readMessage(value,proto.rule.CalcActionDrawModulusLine.deserializeBinaryFromReader);
      msg.setDrawModLine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionTypeCalc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionTypeCalc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionTypeCalc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeCalc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalcName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCalcId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRun();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.rule.CalcActionRun.serializeBinaryToWriter
    );
  }
  f = message.getDrawModLine();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.rule.CalcActionDrawModulusLine.serializeBinaryToWriter
    );
  }
};


/**
 * optional string calc_name = 1;
 * @return {string}
 */
proto.rule.ActionTypeCalc.prototype.getCalcName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ActionTypeCalc} returns this
 */
proto.rule.ActionTypeCalc.prototype.setCalcName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string calc_id = 2;
 * @return {string}
 */
proto.rule.ActionTypeCalc.prototype.getCalcId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ActionTypeCalc} returns this
 */
proto.rule.ActionTypeCalc.prototype.setCalcId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CalcActionType action_type = 3;
 * @return {!proto.rule.CalcActionType}
 */
proto.rule.ActionTypeCalc.prototype.getActionType = function() {
  return /** @type {!proto.rule.CalcActionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.rule.CalcActionType} value
 * @return {!proto.rule.ActionTypeCalc} returns this
 */
proto.rule.ActionTypeCalc.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional CalcActionRun run = 50;
 * @return {?proto.rule.CalcActionRun}
 */
proto.rule.ActionTypeCalc.prototype.getRun = function() {
  return /** @type{?proto.rule.CalcActionRun} */ (
    jspb.Message.getWrapperField(this, proto.rule.CalcActionRun, 50));
};


/**
 * @param {?proto.rule.CalcActionRun|undefined} value
 * @return {!proto.rule.ActionTypeCalc} returns this
*/
proto.rule.ActionTypeCalc.prototype.setRun = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeCalc} returns this
 */
proto.rule.ActionTypeCalc.prototype.clearRun = function() {
  return this.setRun(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeCalc.prototype.hasRun = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional CalcActionDrawModulusLine draw_mod_line = 51;
 * @return {?proto.rule.CalcActionDrawModulusLine}
 */
proto.rule.ActionTypeCalc.prototype.getDrawModLine = function() {
  return /** @type{?proto.rule.CalcActionDrawModulusLine} */ (
    jspb.Message.getWrapperField(this, proto.rule.CalcActionDrawModulusLine, 51));
};


/**
 * @param {?proto.rule.CalcActionDrawModulusLine|undefined} value
 * @return {!proto.rule.ActionTypeCalc} returns this
*/
proto.rule.ActionTypeCalc.prototype.setDrawModLine = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeCalc} returns this
 */
proto.rule.ActionTypeCalc.prototype.clearDrawModLine = function() {
  return this.setDrawModLine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeCalc.prototype.hasDrawModLine = function() {
  return jspb.Message.getField(this, 51) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.CalcActionRun.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.CalcActionRun.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.CalcActionRun} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.CalcActionRun.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.CalcActionRun}
 */
proto.rule.CalcActionRun.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.CalcActionRun;
  return proto.rule.CalcActionRun.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.CalcActionRun} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.CalcActionRun}
 */
proto.rule.CalcActionRun.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.CalcActionRun.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.CalcActionRun.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.CalcActionRun} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.CalcActionRun.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.rule.CalcActionRun.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.CalcActionRun} returns this
 */
proto.rule.CalcActionRun.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.rule.CalcActionRun} returns this
 */
proto.rule.CalcActionRun.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.CalcActionRun.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.CalcActionDrawModulusLine.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.CalcActionDrawModulusLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.CalcActionDrawModulusLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.CalcActionDrawModulusLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.CalcActionDrawModulusLine}
 */
proto.rule.CalcActionDrawModulusLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.CalcActionDrawModulusLine;
  return proto.rule.CalcActionDrawModulusLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.CalcActionDrawModulusLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.CalcActionDrawModulusLine}
 */
proto.rule.CalcActionDrawModulusLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.CalcActionDrawModulusLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.CalcActionDrawModulusLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.CalcActionDrawModulusLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.CalcActionDrawModulusLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.rule.CalcActionDrawModulusLine.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.CalcActionDrawModulusLine} returns this
 */
proto.rule.CalcActionDrawModulusLine.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.rule.CalcActionDrawModulusLine} returns this
 */
proto.rule.CalcActionDrawModulusLine.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.CalcActionDrawModulusLine.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionTypeTest.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionTypeTest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionTypeTest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeTest.toObject = function(includeInstance, msg) {
  var f, obj = {
    testProp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionTypeTest}
 */
proto.rule.ActionTypeTest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionTypeTest;
  return proto.rule.ActionTypeTest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionTypeTest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionTypeTest}
 */
proto.rule.ActionTypeTest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rule.ActionTestProp} */ (reader.readEnum());
      msg.setTestProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionTypeTest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionTypeTest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionTypeTest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeTest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTestProp();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ActionTestProp test_prop = 1;
 * @return {!proto.rule.ActionTestProp}
 */
proto.rule.ActionTypeTest.prototype.getTestProp = function() {
  return /** @type {!proto.rule.ActionTestProp} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rule.ActionTestProp} value
 * @return {!proto.rule.ActionTypeTest} returns this
 */
proto.rule.ActionTypeTest.prototype.setTestProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionTypeControlDisable.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionTypeControlDisable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionTypeControlDisable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeControlDisable.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionTypeControlDisable}
 */
proto.rule.ActionTypeControlDisable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionTypeControlDisable;
  return proto.rule.ActionTypeControlDisable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionTypeControlDisable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionTypeControlDisable}
 */
proto.rule.ActionTypeControlDisable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionTypeControlDisable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionTypeControlDisable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionTypeControlDisable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeControlDisable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAxisId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string axis_id = 1;
 * @return {string}
 */
proto.rule.ActionTypeControlDisable.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ActionTypeControlDisable} returns this
 */
proto.rule.ActionTypeControlDisable.prototype.setAxisId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionTypeStage.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionTypeStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionTypeStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    stageProp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionTypeStage}
 */
proto.rule.ActionTypeStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionTypeStage;
  return proto.rule.ActionTypeStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionTypeStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionTypeStage}
 */
proto.rule.ActionTypeStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rule.ActionStageProp} */ (reader.readEnum());
      msg.setStageProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionTypeStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionTypeStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionTypeStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStageProp();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ActionStageProp stage_prop = 1;
 * @return {!proto.rule.ActionStageProp}
 */
proto.rule.ActionTypeStage.prototype.getStageProp = function() {
  return /** @type {!proto.rule.ActionStageProp} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rule.ActionStageProp} value
 * @return {!proto.rule.ActionTypeStage} returns this
 */
proto.rule.ActionTypeStage.prototype.setStageProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionTypeSpecimen.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionTypeSpecimen.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionTypeSpecimen} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeSpecimen.toObject = function(includeInstance, msg) {
  var f, obj = {
    stressMeasId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    strainMeasId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    limitSpec: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gLengthCompMethod: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gLengthMeasId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 6, 0),
    limitSpecStress: (f = msg.getLimitSpecStress()) && proto.rule.LimitSpecStress.toObject(includeInstance, f),
    limitSpecStrain: (f = msg.getLimitSpecStrain()) && proto.rule.LimitSpecStrain.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionTypeSpecimen}
 */
proto.rule.ActionTypeSpecimen.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionTypeSpecimen;
  return proto.rule.ActionTypeSpecimen.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionTypeSpecimen} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionTypeSpecimen}
 */
proto.rule.ActionTypeSpecimen.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStressMeasId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrainMeasId(value);
      break;
    case 3:
      var value = /** @type {!proto.rule.LimitSpec} */ (reader.readEnum());
      msg.setLimitSpec(value);
      break;
    case 4:
      var value = /** @type {!proto.rule.GaugeLengthCompMethods} */ (reader.readEnum());
      msg.setGLengthCompMethod(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGLengthMeasId(value);
      break;
    case 6:
      var value = /** @type {!proto.rule.LimitSpecType} */ (reader.readEnum());
      msg.setLimit(value);
      break;
    case 50:
      var value = new proto.rule.LimitSpecStress;
      reader.readMessage(value,proto.rule.LimitSpecStress.deserializeBinaryFromReader);
      msg.setLimitSpecStress(value);
      break;
    case 51:
      var value = new proto.rule.LimitSpecStrain;
      reader.readMessage(value,proto.rule.LimitSpecStrain.deserializeBinaryFromReader);
      msg.setLimitSpecStrain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionTypeSpecimen.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionTypeSpecimen.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionTypeSpecimen} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeSpecimen.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStressMeasId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStrainMeasId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLimitSpec();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGLengthCompMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGLengthMeasId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLimitSpecStress();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.rule.LimitSpecStress.serializeBinaryToWriter
    );
  }
  f = message.getLimitSpecStrain();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.rule.LimitSpecStrain.serializeBinaryToWriter
    );
  }
};


/**
 * optional string stress_meas_id = 1;
 * @return {string}
 */
proto.rule.ActionTypeSpecimen.prototype.getStressMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ActionTypeSpecimen} returns this
 */
proto.rule.ActionTypeSpecimen.prototype.setStressMeasId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string strain_meas_id = 2;
 * @return {string}
 */
proto.rule.ActionTypeSpecimen.prototype.getStrainMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ActionTypeSpecimen} returns this
 */
proto.rule.ActionTypeSpecimen.prototype.setStrainMeasId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LimitSpec limit_spec = 3;
 * @return {!proto.rule.LimitSpec}
 */
proto.rule.ActionTypeSpecimen.prototype.getLimitSpec = function() {
  return /** @type {!proto.rule.LimitSpec} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.rule.LimitSpec} value
 * @return {!proto.rule.ActionTypeSpecimen} returns this
 */
proto.rule.ActionTypeSpecimen.prototype.setLimitSpec = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional GaugeLengthCompMethods g_length_comp_method = 4;
 * @return {!proto.rule.GaugeLengthCompMethods}
 */
proto.rule.ActionTypeSpecimen.prototype.getGLengthCompMethod = function() {
  return /** @type {!proto.rule.GaugeLengthCompMethods} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.rule.GaugeLengthCompMethods} value
 * @return {!proto.rule.ActionTypeSpecimen} returns this
 */
proto.rule.ActionTypeSpecimen.prototype.setGLengthCompMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string g_length_meas_id = 5;
 * @return {string}
 */
proto.rule.ActionTypeSpecimen.prototype.getGLengthMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ActionTypeSpecimen} returns this
 */
proto.rule.ActionTypeSpecimen.prototype.setGLengthMeasId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional LimitSpecType limit = 6;
 * @return {!proto.rule.LimitSpecType}
 */
proto.rule.ActionTypeSpecimen.prototype.getLimit = function() {
  return /** @type {!proto.rule.LimitSpecType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.rule.LimitSpecType} value
 * @return {!proto.rule.ActionTypeSpecimen} returns this
 */
proto.rule.ActionTypeSpecimen.prototype.setLimit = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional LimitSpecStress limit_spec_stress = 50;
 * @return {?proto.rule.LimitSpecStress}
 */
proto.rule.ActionTypeSpecimen.prototype.getLimitSpecStress = function() {
  return /** @type{?proto.rule.LimitSpecStress} */ (
    jspb.Message.getWrapperField(this, proto.rule.LimitSpecStress, 50));
};


/**
 * @param {?proto.rule.LimitSpecStress|undefined} value
 * @return {!proto.rule.ActionTypeSpecimen} returns this
*/
proto.rule.ActionTypeSpecimen.prototype.setLimitSpecStress = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeSpecimen} returns this
 */
proto.rule.ActionTypeSpecimen.prototype.clearLimitSpecStress = function() {
  return this.setLimitSpecStress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeSpecimen.prototype.hasLimitSpecStress = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional LimitSpecStrain limit_spec_strain = 51;
 * @return {?proto.rule.LimitSpecStrain}
 */
proto.rule.ActionTypeSpecimen.prototype.getLimitSpecStrain = function() {
  return /** @type{?proto.rule.LimitSpecStrain} */ (
    jspb.Message.getWrapperField(this, proto.rule.LimitSpecStrain, 51));
};


/**
 * @param {?proto.rule.LimitSpecStrain|undefined} value
 * @return {!proto.rule.ActionTypeSpecimen} returns this
*/
proto.rule.ActionTypeSpecimen.prototype.setLimitSpecStrain = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeSpecimen} returns this
 */
proto.rule.ActionTypeSpecimen.prototype.clearLimitSpecStrain = function() {
  return this.setLimitSpecStrain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeSpecimen.prototype.hasLimitSpecStrain = function() {
  return jspb.Message.getField(this, 51) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ClientAppOpenMessageBox.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ClientAppOpenMessageBox.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ClientAppOpenMessageBox} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ClientAppOpenMessageBox.toObject = function(includeInstance, msg) {
  var f, obj = {
    msg: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ClientAppOpenMessageBox}
 */
proto.rule.ClientAppOpenMessageBox.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ClientAppOpenMessageBox;
  return proto.rule.ClientAppOpenMessageBox.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ClientAppOpenMessageBox} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ClientAppOpenMessageBox}
 */
proto.rule.ClientAppOpenMessageBox.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ClientAppOpenMessageBox.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ClientAppOpenMessageBox.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ClientAppOpenMessageBox} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ClientAppOpenMessageBox.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string msg = 1;
 * @return {string}
 */
proto.rule.ClientAppOpenMessageBox.prototype.getMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ClientAppOpenMessageBox} returns this
 */
proto.rule.ClientAppOpenMessageBox.prototype.setMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rule.ClientAppOpenMessageBoxConfirm.repeatedFields_ = [4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ClientAppOpenMessageBoxConfirm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ClientAppOpenMessageBoxConfirm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ClientAppOpenMessageBoxConfirm.toObject = function(includeInstance, msg) {
  var f, obj = {
    msg: jspb.Message.getFieldWithDefault(msg, 1, ""),
    okBtn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    runOkActionsParallel: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    okActionsList: jspb.Message.toObjectList(msg.getOkActionsList(),
    proto.rule.ClientAppActionLimited.toObject, includeInstance),
    cancelBtn: jspb.Message.getFieldWithDefault(msg, 5, ""),
    runCancelActionsParallel: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    cancelActionsList: jspb.Message.toObjectList(msg.getCancelActionsList(),
    proto.rule.ClientAppActionLimited.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ClientAppOpenMessageBoxConfirm}
 */
proto.rule.ClientAppOpenMessageBoxConfirm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ClientAppOpenMessageBoxConfirm;
  return proto.rule.ClientAppOpenMessageBoxConfirm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ClientAppOpenMessageBoxConfirm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ClientAppOpenMessageBoxConfirm}
 */
proto.rule.ClientAppOpenMessageBoxConfirm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsg(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOkBtn(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunOkActionsParallel(value);
      break;
    case 4:
      var value = new proto.rule.ClientAppActionLimited;
      reader.readMessage(value,proto.rule.ClientAppActionLimited.deserializeBinaryFromReader);
      msg.addOkActions(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCancelBtn(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunCancelActionsParallel(value);
      break;
    case 7:
      var value = new proto.rule.ClientAppActionLimited;
      reader.readMessage(value,proto.rule.ClientAppActionLimited.deserializeBinaryFromReader);
      msg.addCancelActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ClientAppOpenMessageBoxConfirm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ClientAppOpenMessageBoxConfirm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ClientAppOpenMessageBoxConfirm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOkBtn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRunOkActionsParallel();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOkActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.rule.ClientAppActionLimited.serializeBinaryToWriter
    );
  }
  f = message.getCancelBtn();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRunCancelActionsParallel();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCancelActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.rule.ClientAppActionLimited.serializeBinaryToWriter
    );
  }
};


/**
 * optional string msg = 1;
 * @return {string}
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.getMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ClientAppOpenMessageBoxConfirm} returns this
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.setMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ok_btn = 2;
 * @return {string}
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.getOkBtn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ClientAppOpenMessageBoxConfirm} returns this
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.setOkBtn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool run_ok_actions_parallel = 3;
 * @return {boolean}
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.getRunOkActionsParallel = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.rule.ClientAppOpenMessageBoxConfirm} returns this
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.setRunOkActionsParallel = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated ClientAppActionLimited ok_actions = 4;
 * @return {!Array<!proto.rule.ClientAppActionLimited>}
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.getOkActionsList = function() {
  return /** @type{!Array<!proto.rule.ClientAppActionLimited>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rule.ClientAppActionLimited, 4));
};


/**
 * @param {!Array<!proto.rule.ClientAppActionLimited>} value
 * @return {!proto.rule.ClientAppOpenMessageBoxConfirm} returns this
*/
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.setOkActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.rule.ClientAppActionLimited=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rule.ClientAppActionLimited}
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.addOkActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.rule.ClientAppActionLimited, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rule.ClientAppOpenMessageBoxConfirm} returns this
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.clearOkActionsList = function() {
  return this.setOkActionsList([]);
};


/**
 * optional string cancel_btn = 5;
 * @return {string}
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.getCancelBtn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ClientAppOpenMessageBoxConfirm} returns this
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.setCancelBtn = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool run_cancel_actions_parallel = 6;
 * @return {boolean}
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.getRunCancelActionsParallel = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.rule.ClientAppOpenMessageBoxConfirm} returns this
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.setRunCancelActionsParallel = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated ClientAppActionLimited cancel_actions = 7;
 * @return {!Array<!proto.rule.ClientAppActionLimited>}
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.getCancelActionsList = function() {
  return /** @type{!Array<!proto.rule.ClientAppActionLimited>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rule.ClientAppActionLimited, 7));
};


/**
 * @param {!Array<!proto.rule.ClientAppActionLimited>} value
 * @return {!proto.rule.ClientAppOpenMessageBoxConfirm} returns this
*/
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.setCancelActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.rule.ClientAppActionLimited=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rule.ClientAppActionLimited}
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.addCancelActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.rule.ClientAppActionLimited, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rule.ClientAppOpenMessageBoxConfirm} returns this
 */
proto.rule.ClientAppOpenMessageBoxConfirm.prototype.clearCancelActionsList = function() {
  return this.setCancelActionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ClientAppActionLimited.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ClientAppActionLimited.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ClientAppActionLimited} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ClientAppActionLimited.toObject = function(includeInstance, msg) {
  var f, obj = {
    okAction: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actionDo: (f = msg.getActionDo()) && proto.rule.ActionTypeDO.toObject(includeInstance, f),
    actionStation: (f = msg.getActionStation()) && proto.rule.ActionTypeStation.toObject(includeInstance, f),
    actionControl: (f = msg.getActionControl()) && proto.rule.ActionTypeControl.toObject(includeInstance, f),
    actionTest: (f = msg.getActionTest()) && proto.rule.ActionTypeTest.toObject(includeInstance, f),
    openMsgBox: (f = msg.getOpenMsgBox()) && proto.rule.ClientAppOpenMessageBox.toObject(includeInstance, f),
    requestVarEntry: (f = msg.getRequestVarEntry()) && proto.rule.ClientAppRequestVarEntry.toObject(includeInstance, f),
    report: (f = msg.getReport()) && proto.rule.ClientAppReport.toObject(includeInstance, f),
    actionSpecimen: (f = msg.getActionSpecimen()) && proto.rule.ActionTypeSpecimen.toObject(includeInstance, f),
    actionCalc: (f = msg.getActionCalc()) && proto.rule.ActionTypeCalc.toObject(includeInstance, f),
    actionStage: (f = msg.getActionStage()) && proto.rule.ActionTypeStage.toObject(includeInstance, f),
    actionMeas: (f = msg.getActionMeas()) && proto.rule.ActionTypeMeas.toObject(includeInstance, f),
    actionAxis: (f = msg.getActionAxis()) && proto.rule.ActionTypeAxis.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ClientAppActionLimited}
 */
proto.rule.ClientAppActionLimited.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ClientAppActionLimited;
  return proto.rule.ClientAppActionLimited.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ClientAppActionLimited} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ClientAppActionLimited}
 */
proto.rule.ClientAppActionLimited.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {!proto.rule.ClientAppActionLimitedType} */ (reader.readEnum());
      msg.setOkAction(value);
      break;
    case 50:
      var value = new proto.rule.ActionTypeDO;
      reader.readMessage(value,proto.rule.ActionTypeDO.deserializeBinaryFromReader);
      msg.setActionDo(value);
      break;
    case 51:
      var value = new proto.rule.ActionTypeStation;
      reader.readMessage(value,proto.rule.ActionTypeStation.deserializeBinaryFromReader);
      msg.setActionStation(value);
      break;
    case 52:
      var value = new proto.rule.ActionTypeControl;
      reader.readMessage(value,proto.rule.ActionTypeControl.deserializeBinaryFromReader);
      msg.setActionControl(value);
      break;
    case 53:
      var value = new proto.rule.ActionTypeTest;
      reader.readMessage(value,proto.rule.ActionTypeTest.deserializeBinaryFromReader);
      msg.setActionTest(value);
      break;
    case 54:
      var value = new proto.rule.ClientAppOpenMessageBox;
      reader.readMessage(value,proto.rule.ClientAppOpenMessageBox.deserializeBinaryFromReader);
      msg.setOpenMsgBox(value);
      break;
    case 56:
      var value = new proto.rule.ClientAppRequestVarEntry;
      reader.readMessage(value,proto.rule.ClientAppRequestVarEntry.deserializeBinaryFromReader);
      msg.setRequestVarEntry(value);
      break;
    case 57:
      var value = new proto.rule.ClientAppReport;
      reader.readMessage(value,proto.rule.ClientAppReport.deserializeBinaryFromReader);
      msg.setReport(value);
      break;
    case 58:
      var value = new proto.rule.ActionTypeSpecimen;
      reader.readMessage(value,proto.rule.ActionTypeSpecimen.deserializeBinaryFromReader);
      msg.setActionSpecimen(value);
      break;
    case 59:
      var value = new proto.rule.ActionTypeCalc;
      reader.readMessage(value,proto.rule.ActionTypeCalc.deserializeBinaryFromReader);
      msg.setActionCalc(value);
      break;
    case 60:
      var value = new proto.rule.ActionTypeStage;
      reader.readMessage(value,proto.rule.ActionTypeStage.deserializeBinaryFromReader);
      msg.setActionStage(value);
      break;
    case 61:
      var value = new proto.rule.ActionTypeMeas;
      reader.readMessage(value,proto.rule.ActionTypeMeas.deserializeBinaryFromReader);
      msg.setActionMeas(value);
      break;
    case 62:
      var value = new proto.rule.ActionTypeAxis;
      reader.readMessage(value,proto.rule.ActionTypeAxis.deserializeBinaryFromReader);
      msg.setActionAxis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ClientAppActionLimited.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ClientAppActionLimited.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ClientAppActionLimited} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ClientAppActionLimited.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOkAction();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getActionDo();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.rule.ActionTypeDO.serializeBinaryToWriter
    );
  }
  f = message.getActionStation();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.rule.ActionTypeStation.serializeBinaryToWriter
    );
  }
  f = message.getActionControl();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.rule.ActionTypeControl.serializeBinaryToWriter
    );
  }
  f = message.getActionTest();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.rule.ActionTypeTest.serializeBinaryToWriter
    );
  }
  f = message.getOpenMsgBox();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.rule.ClientAppOpenMessageBox.serializeBinaryToWriter
    );
  }
  f = message.getRequestVarEntry();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      proto.rule.ClientAppRequestVarEntry.serializeBinaryToWriter
    );
  }
  f = message.getReport();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      proto.rule.ClientAppReport.serializeBinaryToWriter
    );
  }
  f = message.getActionSpecimen();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      proto.rule.ActionTypeSpecimen.serializeBinaryToWriter
    );
  }
  f = message.getActionCalc();
  if (f != null) {
    writer.writeMessage(
      59,
      f,
      proto.rule.ActionTypeCalc.serializeBinaryToWriter
    );
  }
  f = message.getActionStage();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      proto.rule.ActionTypeStage.serializeBinaryToWriter
    );
  }
  f = message.getActionMeas();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      proto.rule.ActionTypeMeas.serializeBinaryToWriter
    );
  }
  f = message.getActionAxis();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      proto.rule.ActionTypeAxis.serializeBinaryToWriter
    );
  }
};


/**
 * optional ClientAppActionLimitedType ok_action = 3;
 * @return {!proto.rule.ClientAppActionLimitedType}
 */
proto.rule.ClientAppActionLimited.prototype.getOkAction = function() {
  return /** @type {!proto.rule.ClientAppActionLimitedType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.rule.ClientAppActionLimitedType} value
 * @return {!proto.rule.ClientAppActionLimited} returns this
 */
proto.rule.ClientAppActionLimited.prototype.setOkAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ActionTypeDO action_do = 50;
 * @return {?proto.rule.ActionTypeDO}
 */
proto.rule.ClientAppActionLimited.prototype.getActionDo = function() {
  return /** @type{?proto.rule.ActionTypeDO} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeDO, 50));
};


/**
 * @param {?proto.rule.ActionTypeDO|undefined} value
 * @return {!proto.rule.ClientAppActionLimited} returns this
*/
proto.rule.ClientAppActionLimited.prototype.setActionDo = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ClientAppActionLimited} returns this
 */
proto.rule.ClientAppActionLimited.prototype.clearActionDo = function() {
  return this.setActionDo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ClientAppActionLimited.prototype.hasActionDo = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional ActionTypeStation action_station = 51;
 * @return {?proto.rule.ActionTypeStation}
 */
proto.rule.ClientAppActionLimited.prototype.getActionStation = function() {
  return /** @type{?proto.rule.ActionTypeStation} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeStation, 51));
};


/**
 * @param {?proto.rule.ActionTypeStation|undefined} value
 * @return {!proto.rule.ClientAppActionLimited} returns this
*/
proto.rule.ClientAppActionLimited.prototype.setActionStation = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ClientAppActionLimited} returns this
 */
proto.rule.ClientAppActionLimited.prototype.clearActionStation = function() {
  return this.setActionStation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ClientAppActionLimited.prototype.hasActionStation = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional ActionTypeControl action_control = 52;
 * @return {?proto.rule.ActionTypeControl}
 */
proto.rule.ClientAppActionLimited.prototype.getActionControl = function() {
  return /** @type{?proto.rule.ActionTypeControl} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeControl, 52));
};


/**
 * @param {?proto.rule.ActionTypeControl|undefined} value
 * @return {!proto.rule.ClientAppActionLimited} returns this
*/
proto.rule.ClientAppActionLimited.prototype.setActionControl = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ClientAppActionLimited} returns this
 */
proto.rule.ClientAppActionLimited.prototype.clearActionControl = function() {
  return this.setActionControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ClientAppActionLimited.prototype.hasActionControl = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional ActionTypeTest action_test = 53;
 * @return {?proto.rule.ActionTypeTest}
 */
proto.rule.ClientAppActionLimited.prototype.getActionTest = function() {
  return /** @type{?proto.rule.ActionTypeTest} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeTest, 53));
};


/**
 * @param {?proto.rule.ActionTypeTest|undefined} value
 * @return {!proto.rule.ClientAppActionLimited} returns this
*/
proto.rule.ClientAppActionLimited.prototype.setActionTest = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ClientAppActionLimited} returns this
 */
proto.rule.ClientAppActionLimited.prototype.clearActionTest = function() {
  return this.setActionTest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ClientAppActionLimited.prototype.hasActionTest = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional ClientAppOpenMessageBox open_msg_box = 54;
 * @return {?proto.rule.ClientAppOpenMessageBox}
 */
proto.rule.ClientAppActionLimited.prototype.getOpenMsgBox = function() {
  return /** @type{?proto.rule.ClientAppOpenMessageBox} */ (
    jspb.Message.getWrapperField(this, proto.rule.ClientAppOpenMessageBox, 54));
};


/**
 * @param {?proto.rule.ClientAppOpenMessageBox|undefined} value
 * @return {!proto.rule.ClientAppActionLimited} returns this
*/
proto.rule.ClientAppActionLimited.prototype.setOpenMsgBox = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ClientAppActionLimited} returns this
 */
proto.rule.ClientAppActionLimited.prototype.clearOpenMsgBox = function() {
  return this.setOpenMsgBox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ClientAppActionLimited.prototype.hasOpenMsgBox = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional ClientAppRequestVarEntry request_var_entry = 56;
 * @return {?proto.rule.ClientAppRequestVarEntry}
 */
proto.rule.ClientAppActionLimited.prototype.getRequestVarEntry = function() {
  return /** @type{?proto.rule.ClientAppRequestVarEntry} */ (
    jspb.Message.getWrapperField(this, proto.rule.ClientAppRequestVarEntry, 56));
};


/**
 * @param {?proto.rule.ClientAppRequestVarEntry|undefined} value
 * @return {!proto.rule.ClientAppActionLimited} returns this
*/
proto.rule.ClientAppActionLimited.prototype.setRequestVarEntry = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ClientAppActionLimited} returns this
 */
proto.rule.ClientAppActionLimited.prototype.clearRequestVarEntry = function() {
  return this.setRequestVarEntry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ClientAppActionLimited.prototype.hasRequestVarEntry = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional ClientAppReport report = 57;
 * @return {?proto.rule.ClientAppReport}
 */
proto.rule.ClientAppActionLimited.prototype.getReport = function() {
  return /** @type{?proto.rule.ClientAppReport} */ (
    jspb.Message.getWrapperField(this, proto.rule.ClientAppReport, 57));
};


/**
 * @param {?proto.rule.ClientAppReport|undefined} value
 * @return {!proto.rule.ClientAppActionLimited} returns this
*/
proto.rule.ClientAppActionLimited.prototype.setReport = function(value) {
  return jspb.Message.setWrapperField(this, 57, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ClientAppActionLimited} returns this
 */
proto.rule.ClientAppActionLimited.prototype.clearReport = function() {
  return this.setReport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ClientAppActionLimited.prototype.hasReport = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional ActionTypeSpecimen action_specimen = 58;
 * @return {?proto.rule.ActionTypeSpecimen}
 */
proto.rule.ClientAppActionLimited.prototype.getActionSpecimen = function() {
  return /** @type{?proto.rule.ActionTypeSpecimen} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeSpecimen, 58));
};


/**
 * @param {?proto.rule.ActionTypeSpecimen|undefined} value
 * @return {!proto.rule.ClientAppActionLimited} returns this
*/
proto.rule.ClientAppActionLimited.prototype.setActionSpecimen = function(value) {
  return jspb.Message.setWrapperField(this, 58, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ClientAppActionLimited} returns this
 */
proto.rule.ClientAppActionLimited.prototype.clearActionSpecimen = function() {
  return this.setActionSpecimen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ClientAppActionLimited.prototype.hasActionSpecimen = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional ActionTypeCalc action_calc = 59;
 * @return {?proto.rule.ActionTypeCalc}
 */
proto.rule.ClientAppActionLimited.prototype.getActionCalc = function() {
  return /** @type{?proto.rule.ActionTypeCalc} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeCalc, 59));
};


/**
 * @param {?proto.rule.ActionTypeCalc|undefined} value
 * @return {!proto.rule.ClientAppActionLimited} returns this
*/
proto.rule.ClientAppActionLimited.prototype.setActionCalc = function(value) {
  return jspb.Message.setWrapperField(this, 59, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ClientAppActionLimited} returns this
 */
proto.rule.ClientAppActionLimited.prototype.clearActionCalc = function() {
  return this.setActionCalc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ClientAppActionLimited.prototype.hasActionCalc = function() {
  return jspb.Message.getField(this, 59) != null;
};


/**
 * optional ActionTypeStage action_stage = 60;
 * @return {?proto.rule.ActionTypeStage}
 */
proto.rule.ClientAppActionLimited.prototype.getActionStage = function() {
  return /** @type{?proto.rule.ActionTypeStage} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeStage, 60));
};


/**
 * @param {?proto.rule.ActionTypeStage|undefined} value
 * @return {!proto.rule.ClientAppActionLimited} returns this
*/
proto.rule.ClientAppActionLimited.prototype.setActionStage = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ClientAppActionLimited} returns this
 */
proto.rule.ClientAppActionLimited.prototype.clearActionStage = function() {
  return this.setActionStage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ClientAppActionLimited.prototype.hasActionStage = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional ActionTypeMeas action_meas = 61;
 * @return {?proto.rule.ActionTypeMeas}
 */
proto.rule.ClientAppActionLimited.prototype.getActionMeas = function() {
  return /** @type{?proto.rule.ActionTypeMeas} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeMeas, 61));
};


/**
 * @param {?proto.rule.ActionTypeMeas|undefined} value
 * @return {!proto.rule.ClientAppActionLimited} returns this
*/
proto.rule.ClientAppActionLimited.prototype.setActionMeas = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ClientAppActionLimited} returns this
 */
proto.rule.ClientAppActionLimited.prototype.clearActionMeas = function() {
  return this.setActionMeas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ClientAppActionLimited.prototype.hasActionMeas = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional ActionTypeAxis action_axis = 62;
 * @return {?proto.rule.ActionTypeAxis}
 */
proto.rule.ClientAppActionLimited.prototype.getActionAxis = function() {
  return /** @type{?proto.rule.ActionTypeAxis} */ (
    jspb.Message.getWrapperField(this, proto.rule.ActionTypeAxis, 62));
};


/**
 * @param {?proto.rule.ActionTypeAxis|undefined} value
 * @return {!proto.rule.ClientAppActionLimited} returns this
*/
proto.rule.ClientAppActionLimited.prototype.setActionAxis = function(value) {
  return jspb.Message.setWrapperField(this, 62, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ClientAppActionLimited} returns this
 */
proto.rule.ClientAppActionLimited.prototype.clearActionAxis = function() {
  return this.setActionAxis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ClientAppActionLimited.prototype.hasActionAxis = function() {
  return jspb.Message.getField(this, 62) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rule.ClientAppRequestVarEntry.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ClientAppRequestVarEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ClientAppRequestVarEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ClientAppRequestVarEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ClientAppRequestVarEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    varIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ClientAppRequestVarEntry}
 */
proto.rule.ClientAppRequestVarEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ClientAppRequestVarEntry;
  return proto.rule.ClientAppRequestVarEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ClientAppRequestVarEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ClientAppRequestVarEntry}
 */
proto.rule.ClientAppRequestVarEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addVarIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ClientAppRequestVarEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ClientAppRequestVarEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ClientAppRequestVarEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ClientAppRequestVarEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVarIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string var_ids = 1;
 * @return {!Array<string>}
 */
proto.rule.ClientAppRequestVarEntry.prototype.getVarIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rule.ClientAppRequestVarEntry} returns this
 */
proto.rule.ClientAppRequestVarEntry.prototype.setVarIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rule.ClientAppRequestVarEntry} returns this
 */
proto.rule.ClientAppRequestVarEntry.prototype.addVarIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rule.ClientAppRequestVarEntry} returns this
 */
proto.rule.ClientAppRequestVarEntry.prototype.clearVarIdsList = function() {
  return this.setVarIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ClientAppReport.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ClientAppReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ClientAppReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ClientAppReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ClientAppReport}
 */
proto.rule.ClientAppReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ClientAppReport;
  return proto.rule.ClientAppReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ClientAppReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ClientAppReport}
 */
proto.rule.ClientAppReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ClientAppReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ClientAppReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ClientAppReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ClientAppReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.rule.ClientAppReport.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ClientAppReport} returns this
 */
proto.rule.ClientAppReport.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.rule.ClientAppReport} returns this
 */
proto.rule.ClientAppReport.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ClientAppReport.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.LimitSpecStress.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.LimitSpecStress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.LimitSpecStress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.LimitSpecStress.toObject = function(includeInstance, msg) {
  var f, obj = {
    upperLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lowerLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.LimitSpecStress}
 */
proto.rule.LimitSpecStress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.LimitSpecStress;
  return proto.rule.LimitSpecStress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.LimitSpecStress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.LimitSpecStress}
 */
proto.rule.LimitSpecStress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.LimitSpecStress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.LimitSpecStress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.LimitSpecStress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.LimitSpecStress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpperLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLowerLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float upper_limit = 1;
 * @return {number}
 */
proto.rule.LimitSpecStress.prototype.getUpperLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.LimitSpecStress} returns this
 */
proto.rule.LimitSpecStress.prototype.setUpperLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float lower_limit = 2;
 * @return {number}
 */
proto.rule.LimitSpecStress.prototype.getLowerLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.LimitSpecStress} returns this
 */
proto.rule.LimitSpecStress.prototype.setLowerLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.LimitSpecStrain.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.LimitSpecStrain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.LimitSpecStrain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.LimitSpecStrain.toObject = function(includeInstance, msg) {
  var f, obj = {
    upperLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lowerLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.LimitSpecStrain}
 */
proto.rule.LimitSpecStrain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.LimitSpecStrain;
  return proto.rule.LimitSpecStrain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.LimitSpecStrain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.LimitSpecStrain}
 */
proto.rule.LimitSpecStrain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.LimitSpecStrain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.LimitSpecStrain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.LimitSpecStrain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.LimitSpecStrain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpperLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLowerLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float upper_limit = 1;
 * @return {number}
 */
proto.rule.LimitSpecStrain.prototype.getUpperLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.LimitSpecStrain} returns this
 */
proto.rule.LimitSpecStrain.prototype.setUpperLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float lower_limit = 2;
 * @return {number}
 */
proto.rule.LimitSpecStrain.prototype.getLowerLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.LimitSpecStrain} returns this
 */
proto.rule.LimitSpecStrain.prototype.setLowerLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionTypeMeas.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionTypeMeas.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionTypeMeas} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeMeas.toObject = function(includeInstance, msg) {
  var f, obj = {
    measId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tareVal: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    measProp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    measVarId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionTypeMeas}
 */
proto.rule.ActionTypeMeas.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionTypeMeas;
  return proto.rule.ActionTypeMeas.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionTypeMeas} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionTypeMeas}
 */
proto.rule.ActionTypeMeas.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTareVal(value);
      break;
    case 3:
      var value = /** @type {!proto.rule.ActionMeasurementProp} */ (reader.readEnum());
      msg.setMeasProp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasVarId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionTypeMeas.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionTypeMeas.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionTypeMeas} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeMeas.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeasId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTareVal();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMeasProp();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMeasVarId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string meas_id = 1;
 * @return {string}
 */
proto.rule.ActionTypeMeas.prototype.getMeasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ActionTypeMeas} returns this
 */
proto.rule.ActionTypeMeas.prototype.setMeasId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float tare_val = 2;
 * @return {number}
 */
proto.rule.ActionTypeMeas.prototype.getTareVal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.ActionTypeMeas} returns this
 */
proto.rule.ActionTypeMeas.prototype.setTareVal = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional ActionMeasurementProp meas_prop = 3;
 * @return {!proto.rule.ActionMeasurementProp}
 */
proto.rule.ActionTypeMeas.prototype.getMeasProp = function() {
  return /** @type {!proto.rule.ActionMeasurementProp} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.rule.ActionMeasurementProp} value
 * @return {!proto.rule.ActionTypeMeas} returns this
 */
proto.rule.ActionTypeMeas.prototype.setMeasProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string meas_var_id = 4;
 * @return {string}
 */
proto.rule.ActionTypeMeas.prototype.getMeasVarId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.ActionTypeMeas} returns this
 */
proto.rule.ActionTypeMeas.prototype.setMeasVarId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.rule.ActionTypeAxis.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.rule.ActionTypeAxis.AxisActionTypeCase = {
  AXIS_ACTION_TYPE_NOT_SET: 0,
  AXIS_ENABLE: 1,
  AXIS_DISABLE: 2,
  AXIS_LINK: 3,
  AXIS_UNLINK: 4,
  AXIS_TARE: 5,
  PID_RESET: 6
};

/**
 * @return {proto.rule.ActionTypeAxis.AxisActionTypeCase}
 */
proto.rule.ActionTypeAxis.prototype.getAxisActionTypeCase = function() {
  return /** @type {proto.rule.ActionTypeAxis.AxisActionTypeCase} */(jspb.Message.computeOneofCase(this, proto.rule.ActionTypeAxis.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionTypeAxis.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionTypeAxis.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionTypeAxis} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeAxis.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisEnable: (f = msg.getAxisEnable()) && proto.rule.AxisEnable.toObject(includeInstance, f),
    axisDisable: (f = msg.getAxisDisable()) && proto.rule.AxisDisable.toObject(includeInstance, f),
    axisLink: (f = msg.getAxisLink()) && proto.rule.AxisLink.toObject(includeInstance, f),
    axisUnlink: (f = msg.getAxisUnlink()) && proto.rule.AxisUnlink.toObject(includeInstance, f),
    axisTare: (f = msg.getAxisTare()) && proto.rule.AxisTare.toObject(includeInstance, f),
    pidReset: (f = msg.getPidReset()) && proto.rule.AxisPidReset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionTypeAxis}
 */
proto.rule.ActionTypeAxis.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionTypeAxis;
  return proto.rule.ActionTypeAxis.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionTypeAxis} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionTypeAxis}
 */
proto.rule.ActionTypeAxis.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rule.AxisEnable;
      reader.readMessage(value,proto.rule.AxisEnable.deserializeBinaryFromReader);
      msg.setAxisEnable(value);
      break;
    case 2:
      var value = new proto.rule.AxisDisable;
      reader.readMessage(value,proto.rule.AxisDisable.deserializeBinaryFromReader);
      msg.setAxisDisable(value);
      break;
    case 3:
      var value = new proto.rule.AxisLink;
      reader.readMessage(value,proto.rule.AxisLink.deserializeBinaryFromReader);
      msg.setAxisLink(value);
      break;
    case 4:
      var value = new proto.rule.AxisUnlink;
      reader.readMessage(value,proto.rule.AxisUnlink.deserializeBinaryFromReader);
      msg.setAxisUnlink(value);
      break;
    case 5:
      var value = new proto.rule.AxisTare;
      reader.readMessage(value,proto.rule.AxisTare.deserializeBinaryFromReader);
      msg.setAxisTare(value);
      break;
    case 6:
      var value = new proto.rule.AxisPidReset;
      reader.readMessage(value,proto.rule.AxisPidReset.deserializeBinaryFromReader);
      msg.setPidReset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionTypeAxis.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionTypeAxis.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionTypeAxis} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeAxis.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAxisEnable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rule.AxisEnable.serializeBinaryToWriter
    );
  }
  f = message.getAxisDisable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rule.AxisDisable.serializeBinaryToWriter
    );
  }
  f = message.getAxisLink();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.rule.AxisLink.serializeBinaryToWriter
    );
  }
  f = message.getAxisUnlink();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.rule.AxisUnlink.serializeBinaryToWriter
    );
  }
  f = message.getAxisTare();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.rule.AxisTare.serializeBinaryToWriter
    );
  }
  f = message.getPidReset();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.rule.AxisPidReset.serializeBinaryToWriter
    );
  }
};


/**
 * optional AxisEnable axis_enable = 1;
 * @return {?proto.rule.AxisEnable}
 */
proto.rule.ActionTypeAxis.prototype.getAxisEnable = function() {
  return /** @type{?proto.rule.AxisEnable} */ (
    jspb.Message.getWrapperField(this, proto.rule.AxisEnable, 1));
};


/**
 * @param {?proto.rule.AxisEnable|undefined} value
 * @return {!proto.rule.ActionTypeAxis} returns this
*/
proto.rule.ActionTypeAxis.prototype.setAxisEnable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.rule.ActionTypeAxis.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeAxis} returns this
 */
proto.rule.ActionTypeAxis.prototype.clearAxisEnable = function() {
  return this.setAxisEnable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeAxis.prototype.hasAxisEnable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AxisDisable axis_disable = 2;
 * @return {?proto.rule.AxisDisable}
 */
proto.rule.ActionTypeAxis.prototype.getAxisDisable = function() {
  return /** @type{?proto.rule.AxisDisable} */ (
    jspb.Message.getWrapperField(this, proto.rule.AxisDisable, 2));
};


/**
 * @param {?proto.rule.AxisDisable|undefined} value
 * @return {!proto.rule.ActionTypeAxis} returns this
*/
proto.rule.ActionTypeAxis.prototype.setAxisDisable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.rule.ActionTypeAxis.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeAxis} returns this
 */
proto.rule.ActionTypeAxis.prototype.clearAxisDisable = function() {
  return this.setAxisDisable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeAxis.prototype.hasAxisDisable = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AxisLink axis_link = 3;
 * @return {?proto.rule.AxisLink}
 */
proto.rule.ActionTypeAxis.prototype.getAxisLink = function() {
  return /** @type{?proto.rule.AxisLink} */ (
    jspb.Message.getWrapperField(this, proto.rule.AxisLink, 3));
};


/**
 * @param {?proto.rule.AxisLink|undefined} value
 * @return {!proto.rule.ActionTypeAxis} returns this
*/
proto.rule.ActionTypeAxis.prototype.setAxisLink = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.rule.ActionTypeAxis.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeAxis} returns this
 */
proto.rule.ActionTypeAxis.prototype.clearAxisLink = function() {
  return this.setAxisLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeAxis.prototype.hasAxisLink = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AxisUnlink axis_unlink = 4;
 * @return {?proto.rule.AxisUnlink}
 */
proto.rule.ActionTypeAxis.prototype.getAxisUnlink = function() {
  return /** @type{?proto.rule.AxisUnlink} */ (
    jspb.Message.getWrapperField(this, proto.rule.AxisUnlink, 4));
};


/**
 * @param {?proto.rule.AxisUnlink|undefined} value
 * @return {!proto.rule.ActionTypeAxis} returns this
*/
proto.rule.ActionTypeAxis.prototype.setAxisUnlink = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.rule.ActionTypeAxis.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeAxis} returns this
 */
proto.rule.ActionTypeAxis.prototype.clearAxisUnlink = function() {
  return this.setAxisUnlink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeAxis.prototype.hasAxisUnlink = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AxisTare axis_tare = 5;
 * @return {?proto.rule.AxisTare}
 */
proto.rule.ActionTypeAxis.prototype.getAxisTare = function() {
  return /** @type{?proto.rule.AxisTare} */ (
    jspb.Message.getWrapperField(this, proto.rule.AxisTare, 5));
};


/**
 * @param {?proto.rule.AxisTare|undefined} value
 * @return {!proto.rule.ActionTypeAxis} returns this
*/
proto.rule.ActionTypeAxis.prototype.setAxisTare = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.rule.ActionTypeAxis.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeAxis} returns this
 */
proto.rule.ActionTypeAxis.prototype.clearAxisTare = function() {
  return this.setAxisTare(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeAxis.prototype.hasAxisTare = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AxisPidReset pid_reset = 6;
 * @return {?proto.rule.AxisPidReset}
 */
proto.rule.ActionTypeAxis.prototype.getPidReset = function() {
  return /** @type{?proto.rule.AxisPidReset} */ (
    jspb.Message.getWrapperField(this, proto.rule.AxisPidReset, 6));
};


/**
 * @param {?proto.rule.AxisPidReset|undefined} value
 * @return {!proto.rule.ActionTypeAxis} returns this
*/
proto.rule.ActionTypeAxis.prototype.setPidReset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.rule.ActionTypeAxis.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeAxis} returns this
 */
proto.rule.ActionTypeAxis.prototype.clearPidReset = function() {
  return this.setPidReset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeAxis.prototype.hasPidReset = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rule.AxisEnable.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.AxisEnable.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.AxisEnable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.AxisEnable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AxisEnable.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enableBitList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.AxisEnable}
 */
proto.rule.AxisEnable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.AxisEnable;
  return proto.rule.AxisEnable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.AxisEnable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.AxisEnable}
 */
proto.rule.AxisEnable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addEnableBit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.AxisEnable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.AxisEnable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.AxisEnable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AxisEnable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnableBitList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string axis_id = 1;
 * @return {string}
 */
proto.rule.AxisEnable.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.AxisEnable} returns this
 */
proto.rule.AxisEnable.prototype.setAxisId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.rule.AxisEnable} returns this
 */
proto.rule.AxisEnable.prototype.clearAxisId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.AxisEnable.prototype.hasAxisId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string enable_bit = 2;
 * @return {!Array<string>}
 */
proto.rule.AxisEnable.prototype.getEnableBitList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rule.AxisEnable} returns this
 */
proto.rule.AxisEnable.prototype.setEnableBitList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rule.AxisEnable} returns this
 */
proto.rule.AxisEnable.prototype.addEnableBit = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rule.AxisEnable} returns this
 */
proto.rule.AxisEnable.prototype.clearEnableBitList = function() {
  return this.setEnableBitList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rule.AxisDisable.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.AxisDisable.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.AxisDisable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.AxisDisable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AxisDisable.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enableBitsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    waveformAxisId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.AxisDisable}
 */
proto.rule.AxisDisable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.AxisDisable;
  return proto.rule.AxisDisable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.AxisDisable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.AxisDisable}
 */
proto.rule.AxisDisable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addEnableBits(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWaveformAxisId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.AxisDisable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.AxisDisable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.AxisDisable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AxisDisable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnableBitsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string axis_id = 1;
 * @return {string}
 */
proto.rule.AxisDisable.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.AxisDisable} returns this
 */
proto.rule.AxisDisable.prototype.setAxisId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.rule.AxisDisable} returns this
 */
proto.rule.AxisDisable.prototype.clearAxisId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.AxisDisable.prototype.hasAxisId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string enable_bits = 2;
 * @return {!Array<string>}
 */
proto.rule.AxisDisable.prototype.getEnableBitsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rule.AxisDisable} returns this
 */
proto.rule.AxisDisable.prototype.setEnableBitsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rule.AxisDisable} returns this
 */
proto.rule.AxisDisable.prototype.addEnableBits = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rule.AxisDisable} returns this
 */
proto.rule.AxisDisable.prototype.clearEnableBitsList = function() {
  return this.setEnableBitsList([]);
};


/**
 * optional string waveform_axis_id = 3;
 * @return {string}
 */
proto.rule.AxisDisable.prototype.getWaveformAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.AxisDisable} returns this
 */
proto.rule.AxisDisable.prototype.setWaveformAxisId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.rule.AxisDisable} returns this
 */
proto.rule.AxisDisable.prototype.clearWaveformAxisId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.AxisDisable.prototype.hasWaveformAxisId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.AxisLink.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.AxisLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.AxisLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AxisLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    todo: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.AxisLink}
 */
proto.rule.AxisLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.AxisLink;
  return proto.rule.AxisLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.AxisLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.AxisLink}
 */
proto.rule.AxisLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTodo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.AxisLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.AxisLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.AxisLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AxisLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTodo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string todo = 1;
 * @return {string}
 */
proto.rule.AxisLink.prototype.getTodo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.AxisLink} returns this
 */
proto.rule.AxisLink.prototype.setTodo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.AxisUnlink.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.AxisUnlink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.AxisUnlink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AxisUnlink.toObject = function(includeInstance, msg) {
  var f, obj = {
    todo: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.AxisUnlink}
 */
proto.rule.AxisUnlink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.AxisUnlink;
  return proto.rule.AxisUnlink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.AxisUnlink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.AxisUnlink}
 */
proto.rule.AxisUnlink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTodo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.AxisUnlink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.AxisUnlink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.AxisUnlink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AxisUnlink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTodo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string todo = 1;
 * @return {string}
 */
proto.rule.AxisUnlink.prototype.getTodo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.AxisUnlink} returns this
 */
proto.rule.AxisUnlink.prototype.setTodo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.AxisTare.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.AxisTare.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.AxisTare} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AxisTare.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.AxisTare}
 */
proto.rule.AxisTare.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.AxisTare;
  return proto.rule.AxisTare.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.AxisTare} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.AxisTare}
 */
proto.rule.AxisTare.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.AxisTare.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.AxisTare.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.AxisTare} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AxisTare.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAxisId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string axis_id = 1;
 * @return {string}
 */
proto.rule.AxisTare.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.AxisTare} returns this
 */
proto.rule.AxisTare.prototype.setAxisId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.AxisPidReset.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.AxisPidReset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.AxisPidReset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AxisPidReset.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.AxisPidReset}
 */
proto.rule.AxisPidReset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.AxisPidReset;
  return proto.rule.AxisPidReset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.AxisPidReset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.AxisPidReset}
 */
proto.rule.AxisPidReset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.AxisPidReset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.AxisPidReset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.AxisPidReset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.AxisPidReset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAxisId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string axis_id = 1;
 * @return {string}
 */
proto.rule.AxisPidReset.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rule.AxisPidReset} returns this
 */
proto.rule.AxisPidReset.prototype.setAxisId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionTypeStation.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionTypeStation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionTypeStation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeStation.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationProp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionTypeStation}
 */
proto.rule.ActionTypeStation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionTypeStation;
  return proto.rule.ActionTypeStation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionTypeStation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionTypeStation}
 */
proto.rule.ActionTypeStation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rule.ActionStationState} */ (reader.readEnum());
      msg.setStationProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionTypeStation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionTypeStation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionTypeStation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeStation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationProp();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ActionStationState station_prop = 1;
 * @return {!proto.rule.ActionStationState}
 */
proto.rule.ActionTypeStation.prototype.getStationProp = function() {
  return /** @type {!proto.rule.ActionStationState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rule.ActionStationState} value
 * @return {!proto.rule.ActionTypeStation} returns this
 */
proto.rule.ActionTypeStation.prototype.setStationProp = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionTypeVariable.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionTypeVariable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionTypeVariable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeVariable.toObject = function(includeInstance, msg) {
  var f, obj = {
    variable: (f = msg.getVariable()) && common_pb.VariableRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionTypeVariable}
 */
proto.rule.ActionTypeVariable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionTypeVariable;
  return proto.rule.ActionTypeVariable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionTypeVariable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionTypeVariable}
 */
proto.rule.ActionTypeVariable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.VariableRequest;
      reader.readMessage(value,common_pb.VariableRequest.deserializeBinaryFromReader);
      msg.setVariable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionTypeVariable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionTypeVariable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionTypeVariable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeVariable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.VariableRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.VariableRequest variable = 1;
 * @return {?proto.common.VariableRequest}
 */
proto.rule.ActionTypeVariable.prototype.getVariable = function() {
  return /** @type{?proto.common.VariableRequest} */ (
    jspb.Message.getWrapperField(this, common_pb.VariableRequest, 1));
};


/**
 * @param {?proto.common.VariableRequest|undefined} value
 * @return {!proto.rule.ActionTypeVariable} returns this
*/
proto.rule.ActionTypeVariable.prototype.setVariable = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeVariable} returns this
 */
proto.rule.ActionTypeVariable.prototype.clearVariable = function() {
  return this.setVariable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeVariable.prototype.hasVariable = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.ActionTypeOffsetCalibration.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.ActionTypeOffsetCalibration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.ActionTypeOffsetCalibration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeOffsetCalibration.toObject = function(includeInstance, msg) {
  var f, obj = {
    cmd: jspb.Message.getFieldWithDefault(msg, 1, 0),
    controlInfo: (f = msg.getControlInfo()) && common_pb.ControlInfo.toObject(includeInstance, f),
    prop: (f = msg.getProp()) && proto.rule.OffsetCalibrationProp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.ActionTypeOffsetCalibration}
 */
proto.rule.ActionTypeOffsetCalibration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.ActionTypeOffsetCalibration;
  return proto.rule.ActionTypeOffsetCalibration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.ActionTypeOffsetCalibration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.ActionTypeOffsetCalibration}
 */
proto.rule.ActionTypeOffsetCalibration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rule.OffsetCalibrationCmd} */ (reader.readEnum());
      msg.setCmd(value);
      break;
    case 2:
      var value = new common_pb.ControlInfo;
      reader.readMessage(value,common_pb.ControlInfo.deserializeBinaryFromReader);
      msg.setControlInfo(value);
      break;
    case 3:
      var value = new proto.rule.OffsetCalibrationProp;
      reader.readMessage(value,proto.rule.OffsetCalibrationProp.deserializeBinaryFromReader);
      msg.setProp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.ActionTypeOffsetCalibration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.ActionTypeOffsetCalibration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.ActionTypeOffsetCalibration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.ActionTypeOffsetCalibration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCmd();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getControlInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ControlInfo.serializeBinaryToWriter
    );
  }
  f = message.getProp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.rule.OffsetCalibrationProp.serializeBinaryToWriter
    );
  }
};


/**
 * optional OffsetCalibrationCmd cmd = 1;
 * @return {!proto.rule.OffsetCalibrationCmd}
 */
proto.rule.ActionTypeOffsetCalibration.prototype.getCmd = function() {
  return /** @type {!proto.rule.OffsetCalibrationCmd} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rule.OffsetCalibrationCmd} value
 * @return {!proto.rule.ActionTypeOffsetCalibration} returns this
 */
proto.rule.ActionTypeOffsetCalibration.prototype.setCmd = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional common.ControlInfo control_info = 2;
 * @return {?proto.common.ControlInfo}
 */
proto.rule.ActionTypeOffsetCalibration.prototype.getControlInfo = function() {
  return /** @type{?proto.common.ControlInfo} */ (
    jspb.Message.getWrapperField(this, common_pb.ControlInfo, 2));
};


/**
 * @param {?proto.common.ControlInfo|undefined} value
 * @return {!proto.rule.ActionTypeOffsetCalibration} returns this
*/
proto.rule.ActionTypeOffsetCalibration.prototype.setControlInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeOffsetCalibration} returns this
 */
proto.rule.ActionTypeOffsetCalibration.prototype.clearControlInfo = function() {
  return this.setControlInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeOffsetCalibration.prototype.hasControlInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OffsetCalibrationProp prop = 3;
 * @return {?proto.rule.OffsetCalibrationProp}
 */
proto.rule.ActionTypeOffsetCalibration.prototype.getProp = function() {
  return /** @type{?proto.rule.OffsetCalibrationProp} */ (
    jspb.Message.getWrapperField(this, proto.rule.OffsetCalibrationProp, 3));
};


/**
 * @param {?proto.rule.OffsetCalibrationProp|undefined} value
 * @return {!proto.rule.ActionTypeOffsetCalibration} returns this
*/
proto.rule.ActionTypeOffsetCalibration.prototype.setProp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rule.ActionTypeOffsetCalibration} returns this
 */
proto.rule.ActionTypeOffsetCalibration.prototype.clearProp = function() {
  return this.setProp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rule.ActionTypeOffsetCalibration.prototype.hasProp = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rule.OffsetCalibrationProp.prototype.toObject = function(opt_includeInstance) {
  return proto.rule.OffsetCalibrationProp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rule.OffsetCalibrationProp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.OffsetCalibrationProp.toObject = function(includeInstance, msg) {
  var f, obj = {
    allowedVariation: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    step: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    maxOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    delay: jspb.Message.getFieldWithDefault(msg, 4, 0),
    initialOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rule.OffsetCalibrationProp}
 */
proto.rule.OffsetCalibrationProp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rule.OffsetCalibrationProp;
  return proto.rule.OffsetCalibrationProp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rule.OffsetCalibrationProp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rule.OffsetCalibrationProp}
 */
proto.rule.OffsetCalibrationProp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllowedVariation(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStep(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxOffset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelay(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInitialOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rule.OffsetCalibrationProp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rule.OffsetCalibrationProp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rule.OffsetCalibrationProp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rule.OffsetCalibrationProp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllowedVariation();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getStep();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMaxOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDelay();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getInitialOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional float allowed_variation = 1;
 * @return {number}
 */
proto.rule.OffsetCalibrationProp.prototype.getAllowedVariation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.OffsetCalibrationProp} returns this
 */
proto.rule.OffsetCalibrationProp.prototype.setAllowedVariation = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float step = 2;
 * @return {number}
 */
proto.rule.OffsetCalibrationProp.prototype.getStep = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.OffsetCalibrationProp} returns this
 */
proto.rule.OffsetCalibrationProp.prototype.setStep = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float max_offset = 3;
 * @return {number}
 */
proto.rule.OffsetCalibrationProp.prototype.getMaxOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.OffsetCalibrationProp} returns this
 */
proto.rule.OffsetCalibrationProp.prototype.setMaxOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional uint32 delay = 4;
 * @return {number}
 */
proto.rule.OffsetCalibrationProp.prototype.getDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.rule.OffsetCalibrationProp} returns this
 */
proto.rule.OffsetCalibrationProp.prototype.setDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float initial_offset = 5;
 * @return {number}
 */
proto.rule.OffsetCalibrationProp.prototype.getInitialOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rule.OffsetCalibrationProp} returns this
 */
proto.rule.OffsetCalibrationProp.prototype.setInitialOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.rule.RuleType = {
  CONDITIONAL: 0,
  UNCONDITIONAL: 1
};

/**
 * @enum {number}
 */
proto.rule.BondType = {
  AND: 0,
  OR: 1
};

/**
 * @enum {number}
 */
proto.rule.RunType = {
  LINEAR: 0,
  PARALLEL: 1
};

/**
 * @enum {number}
 */
proto.rule.MeasurementTriggerType = {
  TRG_MORE_THAN: 0,
  TRG_LESS_THAN: 1,
  TRG_AMPLITUDE_CHANGE: 2,
  TRG_MEAN_CHANGE: 3,
  TRG_IN_RANGE: 4,
  TRG_OUT_OF_RANGE: 5
};

/**
 * @enum {number}
 */
proto.rule.SpecimenTriggerType = {
  SPECIMEN_BREAK: 0,
  SPECIMEN_YIELD: 1,
  SPECIMEN_ULTIMATE_STRENGTH: 2
};

/**
 * @enum {number}
 */
proto.rule.TriggerAxisProp = {
  COMPLETED: 0
};

/**
 * @enum {number}
 */
proto.rule.OffsetCalibrationState = {
  CALIBRATION_IN_PROGRESS: 0,
  CALIBRATION_COMPLETED: 1,
  CALIBRATION_MAX_REACHED: 2,
  CALIBRATION_STOPPED: 3
};

/**
 * @enum {number}
 */
proto.rule.TriggerType = {
  TRG_DIGITAL_INPUT: 0,
  TRG_STATION: 1,
  TRG_TEST: 2,
  TRG_CONNECTION: 3,
  TRG_AUX_DEVICE: 4,
  TRG_SPECIMEN: 5,
  TRG_MEASUREMENT: 6,
  TRG_AXIS: 7,
  TRG_STAGE: 8,
  TRG_VARIABLE: 9,
  TRG_OFFSET_CALIBRATION: 10,
  TRG_MEM_STATUS: 11
};

/**
 * @enum {number}
 */
proto.rule.DigitalInputProp = {
  ON: 0,
  OFF: 1
};

/**
 * @enum {number}
 */
proto.rule.ActionStationState = {
  STATION_IN_TEST: 0,
  STATION_IDLE: 1,
  STATION_ACTIVE: 2,
  STATION_SAFE: 3
};

/**
 * @enum {number}
 */
proto.rule.TestProp = {
  STARTED: 0,
  STOPPED: 1,
  RESUMED: 2,
  PAUSED: 3
};

/**
 * @enum {number}
 */
proto.rule.StageProp = {
  STAGE_STARTED: 0,
  STAGE_FINISHED: 1
};

/**
 * @enum {number}
 */
proto.rule.ConnectionProp = {
  CONNECTED: 0,
  DISCONNECTED: 1
};

/**
 * @enum {number}
 */
proto.rule.AuxDeviceProp = {
  PROP_ON: 0,
  PROP_OFF: 1
};

/**
 * @enum {number}
 */
proto.rule.ConditionType = {
  COND_DIGITAL_INPUT: 0,
  COND_AUX_DEVICE: 1,
  COND_CALC: 2,
  COND_MEASUREMENT: 3,
  COND_TEST: 4,
  COND_CONNECTION: 5,
  COND_STATION: 6,
  COND_VARIABLE: 7
};

/**
 * @enum {number}
 */
proto.rule.ConditionStationOp = {
  EQUALS: 0,
  NOT_EQUALS: 1
};

/**
 * @enum {number}
 */
proto.rule.MeasurementConditionType = {
  COND_MORE_THAN: 0,
  COND_LESS_THAN: 1,
  COND_IN_RANGE: 2,
  COND_OUT_OF_RANGE: 3
};

/**
 * @enum {number}
 */
proto.rule.AuxDeviceStateProp = {
  AUX_DEV_STATE_ON: 0,
  AUX_DEV_STATE_OFF: 1
};

/**
 * @enum {number}
 */
proto.rule.ConditionStationState = {
  CONDITION_STATION_IN_TEST: 0,
  CONDITION_STATION_IDLE: 1,
  CONDITION_STATION_ACTIVE: 2,
  CONDITION_STATION_SAFE: 3
};

/**
 * @enum {number}
 */
proto.rule.ConditionTestProp = {
  CONDITION_TEST_PAUSED: 0,
  CONDITION_TEST_IN_PROGRESS: 1
};

/**
 * @enum {number}
 */
proto.rule.ConditionCalcProp = {
  CONDITION_CALC_IN_RANGE: 0,
  CONDITION_CALC_OUT_OF_RANGE: 1
};

/**
 * @enum {number}
 */
proto.rule.ActionType = {
  ACTION_TYPE_DIGITAL_OUTPUT: 0,
  ACTION_STATION: 1,
  ACTION_TYPE_CONTROL: 2,
  ACTION_TYPE_TEST: 3,
  ACTION_TYPE_CLIENT_MSG_BOX: 4,
  ACTION_TYPE_CLIENT_MSG_BOX_WITH_CONFIRMATION: 5,
  ACTION_TYPE_CLIENT_REQUEST_VAR_ENTRY: 6,
  ACTION_TYPE_CLIENT_REPORT: 7,
  ACTION_TYPE_SPECIMEN: 8,
  ACTION_TYPE_CALCULATION: 9,
  ACTION_TYPE_STAGE: 10,
  ACTION_TYPE_MEASUREMENT: 11,
  ACTION_TYPE_AXIS: 12,
  ACTION_TYPE_VARIABLE: 13,
  ACTION_TYPE_ANALOG_OUTPUT: 14,
  ACTION_TYPE_OFFSET_CALIBRATION: 15
};

/**
 * @enum {number}
 */
proto.rule.AnalogOutputActionType = {
  START: 0,
  STOP: 1
};

/**
 * @enum {number}
 */
proto.rule.ControlActionType = {
  OPERATE: 0,
  DISABLE: 1,
  UPDATE: 2,
  SET: 3
};

/**
 * @enum {number}
 */
proto.rule.CalcActionType = {
  RUN: 0,
  DRAW_MOD_LINE: 1
};

/**
 * @enum {number}
 */
proto.rule.ActionStageProp = {
  STOP: 0,
  ACTION_TYPE_STAGE_JUMP: 1
};

/**
 * @enum {number}
 */
proto.rule.LimitSpecType = {
  STRESS: 0,
  STRAIN: 1
};

/**
 * @enum {number}
 */
proto.rule.ClientAppActionLimitedType = {
  MSGBOX_ACTION_TYPE_DIGITAL_OUTPUT: 0,
  MSGBOX_ACTION_STATION: 1,
  MSGBOX_ACTION_TYPE_CONTROL: 2,
  MSGBOX_ACTION_TYPE_TEST: 3,
  MSGBOX_ACTION_TYPE_CLIENT_MSG_BOX: 4,
  MSGBOX_ACTION_TYPE_CLIENT_REQUEST_VAR_ENTRY: 6,
  MSGBOX_ACTION_TYPE_CLIENT_REPORT: 7,
  MSGBOX_ACTION_TYPE_SPECIMEN: 8,
  MSGBOX_ACTION_TYPE_CALCULATION: 9,
  MSGBOX_ACTION_TYPE_STAGE: 10,
  MSGBOX_ACTION_TYPE_MEASUREMENT: 11,
  MSGBOX_ACTION_TYPE_AXIS: 12
};

/**
 * @enum {number}
 */
proto.rule.LimitSpec = {
  LIMIT_SPEC_STRESS_POINTS: 0,
  LIMIT_SPEC_STRAIN_POINTS: 1,
  LIMIT_SPEC_MAX_STRESS: 2,
  LIMIT_SPEC_AUTO: 3
};

/**
 * @enum {number}
 */
proto.rule.GaugeLengthCompMethods = {
  GL_NONE: 0,
  GL_TENSILE: 1,
  GL_COMPRESSIVE: 2
};

/**
 * @enum {number}
 */
proto.rule.ActionTestProp = {
  TEST_ACTION_START: 0,
  TEST_ACTION_STOP: 1,
  TEST_ACTION_PAUSE: 2,
  TEST_ACTION_RESUME: 3
};

/**
 * @enum {number}
 */
proto.rule.DigitalOutputActionProp = {
  PULSE_START: 0,
  PULSE_END: 1,
  LOW: 2,
  HIGH: 3
};

/**
 * @enum {number}
 */
proto.rule.AxisActionProp = {
  AXIS_OPERATE: 0,
  AXIS_DISABLE: 1,
  AXIS_LINK: 2,
  AXIS_UNLINK: 3
};

/**
 * @enum {number}
 */
proto.rule.ActionMeasurementProp = {
  TARE: 0,
  UNTARE: 1,
  GET_VALUE: 2
};

/**
 * @enum {number}
 */
proto.rule.OffsetCalibrationCmd = {
  CALIBRATION_START: 0,
  CALIBRATION_STOP: 1,
  CALIBRATION_SET: 2
};

goog.object.extend(exports, proto.rule);
