import React from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { createEditButtonRuleModalId } from '../../buttonWidget.const';
import styles from './ButtonRuleCreateEditModal.module.scss';

interface ButtonRuleCreateEditModalProps {
	isCreate: boolean;
	name?: string;
	children: React.ReactNode;
	formId: string;
	onClose: () => void;
	onAfterClose?: () => void;
}

const ButtonRuleCreateEditModal: React.FC<ButtonRuleCreateEditModalProps> = ({
	isCreate,
	name,
	children,
	formId,
	onClose,
	onAfterClose
}) => {
	const { t } = useTranslation('testSequencerRules');

	return (
		<Modal
			id={createEditButtonRuleModalId}
			className={styles.container}
			shouldCloseOnOverlayClick={false}
			onClose={onClose}
			onAfterClose={onAfterClose}
		>
			<Modal.Header>{isCreate ? t('Create a Rule') : t('Edit "{{name}}" Rule', { name })}</Modal.Header>
			<Modal.Content className={styles.innerCont}>{children}</Modal.Content>
			<Modal.Footer>
				<Button onClick={onClose} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button color="secondary" variant="contained" type="submit" form={formId}>
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(ButtonRuleCreateEditModal);
