import * as yup from 'yup';
import { testLoopFormValidationRequestConverter } from './testLoops.converters';
import { TestLoopValidationRequestDto } from './testLoops.types';
import * as api from './testLoops.api';
import { emptyStringToNull } from '../../tools/yupHelpers';

export const testLoopSchema = yup.object().shape({
	id: yup.string().optional(),
	testId: yup.string().required(),
	startStageId: yup
		.string()
		.required('Start stage is a required param')
		.test('validateLoopStage1', async function (value, form) {
			if (!value || !form.parent.endStageId) return true;
			const loopValidationRequest: TestLoopValidationRequestDto = testLoopFormValidationRequestConverter(form.parent);
			const { result, message } = await api.validateTestLoop(loopValidationRequest, form.parent.testId);
			if (!result) {
				return this.createError({ message });
			}

			return true;
		}),
	endStageId: yup
		.string()
		.required('End stage is a required param')
		.test('validateLoopStage2', async function (value, form) {
			if (!value || !form.parent.startStageId) return true;
			const loopValidationRequest: TestLoopValidationRequestDto = testLoopFormValidationRequestConverter(form.parent);
			const { result, message } = await api.validateTestLoop(loopValidationRequest, form.parent.testId);
			if (!result) {
				return this.createError({ message });
			}

			return true;
		}),
	runCalcFor: yup.number().transform(emptyStringToNull).required('Run calculation for is a requiered field'),
	iter: yup.number().transform(emptyStringToNull).min(2).max(4294967296).required('Iteration count is a required param')
});
