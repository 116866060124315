import { Button } from '@tactun/ui';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { calibrationFormId } from '../../../Calibrations';
import styles from './CalibrationWidgetDialog.module.scss';

export interface ICalibrationWidgetDialogProps {
	children: React.ReactNode;
	onHide: () => void;
	title: string;
}

const CalibrationWidgetDialog: React.FC<ICalibrationWidgetDialogProps> = ({ title, children, onHide }) => {
	const { t } = useTranslation('calibrations');

	return (
		<Dialog header={title} visible={true} style={{ width: '924px' }} onHide={onHide} className={styles.container}>
			{children}
			<div className={styles.calibrationFooter}>
				<Button className="btnCancel" onClick={onHide} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button type="submit" form={calibrationFormId} color="secondary" variant="contained">
					{t('Save')}
				</Button>
			</div>
		</Dialog>
	);
};

export default CalibrationWidgetDialog;
