import { Channel } from '../Dashboard/dashboard.types';
import { uuid } from '../../tools';
import { Converter } from '../../types';
import { GraphTools, ScaleTypes, Axis } from './graphWidget.enums';
import { generatePlotId, getPLotColorGenerator } from './graphWidget.tools';
import {
	GraphState,
	GraphChannels,
	Plot,
	GraphDto,
	PlotSavedState,
	ChartAxisLabels,
	GraphFormType
} from './graphWidget.types';

export const dtoToStateConverter = ({ meta, settings }: GraphDto): GraphState => {
	return {
		id: meta.id,
		name: meta.name,
		activeTool: GraphTools.MOVE,
		minimizedView: false,
		settings: meta.settings,
		xChannel: settings.xChannel,
		yChannels: settings.yChannels,
		leftAxisTitle: '',
		rightAxisTitle: undefined,
		plots: meta.plotsMetaData.map((plotsMeta, index) => ({
			id: generatePlotId(settings.yChannels[index]),
			isVisible: plotsMeta.isVisible,
			title: '',
			yAxisId: plotsMeta.yAxisId,
			channel: settings.yChannels[index],
			color: plotsMeta.color,
			data: []
		}))
	};
};

export const stateToDtoConverter: Converter<GraphState, GraphDto> = (state) => {
	return {
		meta: {
			id: state.id,
			name: state.name,
			settings: state.settings,
			plotsMetaData: plotsToPlotsMetaConverter(state.plots)
		},
		settings: {
			xChannel: state.xChannel ? { id: state.xChannel.id, type: state.xChannel.type } : undefined,
			yChannels: state.plots.map((plot) => plot.channel)
		}
	};
};

export const plotToDatasetsConverter = (plot: Plot) => {
	return {
		yAxisID: plot.yAxisId,
		borderColor: plot.color,
		pointRadius: 0,
		backgroundColor: 'transparent',
		data: [],
		id: plot.id
	};
};

export const plotsToChannelsConverter = (xChannel: Channel, plots: Plot[]): GraphChannels => {
	return {
		xAxisChannel: { ...xChannel, id: `${xChannel?.id}_${xChannel?.type}` },
		channels: plots.map((plot: Plot) => ({ ...plot.channel, id: plot.channel?.id }))
	};
};

export const plotsToPlotsMetaConverter = (plots: Plot[]): PlotSavedState[] => {
	return plots.map((plot) => ({
		color: plot.color,
		yAxisId: plot.yAxisId,
		isVisible: plot.isVisible
	}));
};

// TODO Temp solution we should get all the data from backend then
export const graphFormToStateConverter: Converter<GraphFormType, GraphDto> = (input) => {
	const generatePlotColor = getPLotColorGenerator();
	const plots: Plot[] = dataChannelsToPlotsConverter({
		leftChannels: input.dcY1,
		rightChannels: input.dcY2
	}).map((plot) => ({ ...plot, color: generatePlotColor() }));

	return {
		settings: {
			xChannel: input.dcX.length ? { id: input.dcX[0].id, type: input.dcX[0].type } : undefined,
			yChannels: plots.map((plot) => plot.channel)
		},
		meta: {
			id: input.id || uuid(),
			name: input.name,
			settings: {
				limitLines: input.limitLines ?? false,
				autoScaleX: input.autoScaleX,
				autoScaleY: input.autoScaleY,
				// @ts-ignore
				scaleType: input.scaleType as ScaleTypes,
				dataRate: input.rate,
				timeWindowSize: input.timeWndSize,
				xOffset: input.xOffset,
				offset: input.offset,
				min: input.min ?? null,
				max: input.max ?? null,
				cmin: input.cmin ?? null,
				timeUnitId: input.timeUnitId,
				cmax: input.cmax ?? null
			},
			plotsMetaData: plotsToPlotsMetaConverter(plots)
		}
	};
};

export const dataChannelsToPlotsConverter: Converter<
	{
		leftChannels?: Channel[];
		rightChannels?: Channel[];
	},
	Plot[]
> = ({ leftChannels = [], rightChannels = [] }) => {
	const leftPlots: Plot[] = leftChannels.map((channel) => {
		return {
			id: generatePlotId(channel),
			channel,
			yAxisId: Axis.LEFT_Y,
			color: '',
			data: [],
			isVisible: true,
			title: ''
		};
	});
	const rightPlots: Plot[] = rightChannels.map((channel) => {
		return {
			id: `${channel.id}_${channel.type}`,
			channel,
			yAxisId: Axis.RIGHT_Y,
			color: '',
			data: [],
			isVisible: true,
			title: ''
		};
	});
	return [...leftPlots, ...rightPlots];
};

export const plotsToAxisLabelsConverter: Converter<{ plots: Plot[]; xDataChannel: any }, ChartAxisLabels> = (input) => {
	const leftPlot = input.plots.find(({ yAxisId }) => yAxisId === Axis.LEFT_Y);
	const rightPlot = input.plots.find(({ yAxisId }) => yAxisId === Axis.RIGHT_Y);

	return {
		[Axis.X]: input.xDataChannel?.title || '',
		[Axis.LEFT_Y]: leftPlot?.title || '',
		[Axis.RIGHT_Y]: rightPlot?.title || ''
	};
};
