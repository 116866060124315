import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Button } from '@tactun/ui';
import DataChannel from '../../../DataChannel';
import { SimplifiedMeasurement, useMeasurements } from '../../../Measurements';
import { useAxes } from '../../../Axes/axes.hooks';
import { plotSchema } from '../../graphWidget.schemas';
import { GraphChannel, Plot } from '../../graphWidget.types';
import { Axis } from '../../graphWidget.enums';
import { dataChannelTreeToChannels } from '../../../Dashboard/dashboard.converters';
import { dataChannelsToPlotsConverter } from '../../graphWidget.converters';
import { Channel } from '../../../Dashboard/dashboard.types';
import { getPLotColorGenerator } from '../../graphWidget.tools';
import { useStationId } from '../../../Stations';
import styles from './CreatePlotModal.module.scss';

interface CreatePlotModalProps {
	modalId: string;
	plots: Plot[];
	xChannel?: GraphChannel;
	onUpdatePlots: (data: { xDataChannel: Channel[]; plots: Plot[] }) => void;
}

const CreatePlotModal: FC<CreatePlotModalProps> = ({ modalId, xChannel, plots, onUpdatePlots }) => {
	const stationId = useStationId();
	const { t } = useTranslation();
	const { measurements = [] } = useMeasurements(stationId);
	const { axes = [] } = useAxes(stationId);

	const simplifiedMeasurements = useMemo<Record<string, SimplifiedMeasurement>>(() => {
		return measurements.reduce<Record<string, SimplifiedMeasurement>>((result, { id, unit }) => {
			result[id] = {
				id,
				quantityId: unit?.quantity,
				unitId: unit?.id
			};
			return result;
		}, {});
	}, [measurements]);

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors }
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(plotSchema),
		defaultValues: { dcX: [], dcY1: [], dcY2: [] }
	});

	const handleClose = useCallback(() => {
		Modal.hide(modalId);
	}, [modalId]);

	const handleConfirm = handleSubmit((data) => {
		const y1DataChannels = dataChannelTreeToChannels(data.dcY1, measurements, axes);
		const y2DataChannels = dataChannelTreeToChannels(data.dcY2, measurements, axes);
		let plotList: Plot[] = dataChannelsToPlotsConverter({
			leftChannels: y1DataChannels,
			rightChannels: y2DataChannels
		});

		const plotColorMap = plots.map((plot) => ({ [plot.id]: plot.color }));
		const colorGenerator = getPLotColorGenerator(Array.from(Object.values(plotColorMap.values())));
		plotList = plotList.map((plot) => ({
			...plot,
			//@ts-ignore
			color: plotColorMap[plot.id] ? plotColorMap[plot.id] : colorGenerator()
		}));

		onUpdatePlots({
			xDataChannel: dataChannelTreeToChannels(data.dcX, measurements, axes),
			plots: plotList
		});
		handleClose();
	});

	// Set simplifiedMeasurements for validations
	useEffect(() => {
		if (simplifiedMeasurements) {
			setValue('simplifiedMeasurements', simplifiedMeasurements);
		}
	}, [setValue, simplifiedMeasurements]);

	//Set selected Y axes values
	useEffect(() => {
		const plotChannels = plots.map((p) => ({ id: p.channel.id, type: p.channel.type, yAxisId: p.yAxisId }));

		const defaultLeftY = plotChannels.filter((p) => p.yAxisId === Axis.LEFT_Y);
		const defaultRightY = plotChannels.filter((p) => p.yAxisId === Axis.RIGHT_Y);

		setValue('dcY1', defaultLeftY);

		setValue('dcY2', defaultRightY);
	}, [plots, setValue]);

	// Set selected X axis value
	useEffect(() => {
		if (xChannel) {
			setValue('dcX', [xChannel]);
		}
	}, [setValue, xChannel]);

	return (
		<Modal id={modalId} onClose={handleClose}>
			<Modal.Header>{t('Create Plot')}</Modal.Header>
			<Modal.Content className={styles.createPlot}>
				<Controller
					name="dcX"
					control={control}
					render={({ field }) => (
						<DataChannel
							{...field}
							label={t('X-axis data channel*')}
							className={styles.input}
							measurements={measurements}
							axes={axes}
							error={errors.dcX?.message}
						/>
					)}
				/>
				<Controller
					name="dcY1"
					control={control}
					render={({ field }) => (
						<DataChannel
							{...field}
							label={t('Left Y-axis data channel*')}
							showClear
							isMultiply
							measurements={measurements}
							removeTime
							className={styles.input}
							axes={axes}
							error={errors.dcY1?.message}
						/>
					)}
				/>
				<Controller
					name="dcY2"
					control={control}
					render={({ field }) => (
						<DataChannel
							{...field}
							label={t('Right Y-axis data channel')}
							showClear
							isMultiply
							removeTime
							measurements={measurements}
							className={styles.input}
							axes={axes}
							error={errors.dcY2?.message}
						/>
					)}
				/>
			</Modal.Content>
			<Modal.Footer>
				<Button className="btnCancel" onClick={handleClose} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button onClick={handleConfirm} color="secondary" variant="contained">
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default CreatePlotModal;
