import axios from '../../httpCommon';
import { TestRuleResponseDto, TestRuleRequestDto, RuleType } from '../Rules';

const url = '/cassiopeia/rules';

export const getStationTestRulesByType = async (stationId: string, type: RuleType) => {
	const response = await axios.get(`${url}/search/findAllByRuleStationUuidAndRuleType`, {
		params: {
			ruleStationUuid: stationId,
			ruleType: RuleType[type]
		}
	});

	const rules = response.data?._embedded.rules as TestRuleResponseDto[];

	return rules;
};

export const getTestRulesByLocation = async (testId: string, ruleType: RuleType) => {
	const response = await axios.get(`${url}/search/findAllByRuleTestUuidAndRuleType`, {
		params: {
			ruleTestUuid: testId,
			ruleType: RuleType[ruleType]
		}
	});

	return response.data?._embedded.rules as TestRuleResponseDto[];
};

export const getStageRules = async (stageId: string) => {
	const response = await axios.get(`${url}/search/findAllByRuleStageUuid`, {
		params: {
			ruleStageUuid: stageId
		}
	});

	return response.data?._embedded.rules as TestRuleResponseDto[];
};

export const getTestSequencerRule = async (id: string) => {
	const response = await axios.get(`${url}/${id}`);

	return response.data as TestRuleResponseDto;
};

export const createTestSequencerRule = async (dataLogConfig: TestRuleRequestDto) => {
	const response = await axios.post(`${url}`, dataLogConfig);
	return response.data as TestRuleResponseDto;
};

export const updateTestSequencerRule = async (dataLogConfig: TestRuleRequestDto, id: string) => {
	const response = await axios.put(`${url}/${id}`, dataLogConfig);
	return response.data as TestRuleResponseDto;
};
