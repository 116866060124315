import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@tactun/ui';
import { ITableColumns } from '../../../../types';
import styles from './ButtonWidgetTable.module.scss';
import { CustomButtonWidgetDto } from '../../buttonWidget.types';
import { formatDate } from '../../../../tools';
import { DataTableSelectionSingleChangeEvent, DataTableValueArray } from 'primereact/datatable';

interface IButtonWidgetTableProps {
	widgets: CustomButtonWidgetDto[];
	setSelectedWidget: (id: string) => void;
	selectedWidget?: string;
	isLoading?: boolean;
}

const ButtonWidgetTable: React.FC<IButtonWidgetTableProps> = ({
	isLoading,
	widgets,
	setSelectedWidget,
	selectedWidget
}) => {
	const { t } = useTranslation('widgets');

	const data = useMemo(() => {
		return widgets.map(
			(widget) => ({
				id: widget.uuid,
				name: widget.name,
				createdDate: widget.createdAt ? formatDate(new Date(widget.createdAt)) : ''
			}),
			[]
		);
	}, [widgets]);

	const transColumns = React.useMemo<ITableColumns[]>(() => {
		return [
			{ field: 'name', header: t('Button Widget Name') },
			{
				field: 'createdDate',
				header: t('Creation Date')
			}
		];
	}, [t]);

	const columnComponents = transColumns.map((col) => <DataTable.Column resizeable key={col.field} {...col} />);

	const selected = useMemo(() => {
		return selectedWidget ? data?.find((d) => d.id === selectedWidget) : undefined;
	}, [data, selectedWidget]);
	const handleWidgetSelection = useCallback(
		(event: DataTableSelectionSingleChangeEvent<DataTableValueArray>) => {
			if (event.value) {
				setSelectedWidget(event.value.id);
			}
		},
		[setSelectedWidget]
	);

	return (
		<div className={styles.container}>
			<p className={styles.title}>{t('Please select a button widget to add')}</p>
			<DataTable
				value={data}
				showGridlines
				columnResizeMode="fit"
				loading={isLoading}
				selection={selected}
				onSelectionChange={handleWidgetSelection}
				selectionMode="single"
			>
				<DataTable.Column selectionMode="single" headerStyle={{ width: '3em' }} />
				{columnComponents}
			</DataTable>
		</div>
	);
};

export default React.memo(ButtonWidgetTable);
