import { ButtonDefaultState, ButtonShape, FrameOrientation } from './buttonWidget.enums';

export const createEditButtonRuleModalId = 'createEditButtonRuleModalId';
export const createEditButtonRuleFormId = 'createEditButtonRuleFormId';
export const buttonWidgetSelectionModalId = 'buttonWidgetSelectionModalId';

export const customWidgetDefaultButton = {
	name: '',
	defaultState: ButtonDefaultState.RELEASED,
	shape: ButtonShape.RECTANGULAR,
	releasedColor: '',
	pressedColor: '',
	releasedButtonColor: '',
	pressedButtonColor: '',
	releasedText: '',
	pressedText: ''
};

export const customWidgetDefaultFrame = {
	orientation: FrameOrientation.VERTICAL,
	numberOfButtons: 1,
	buttons: [{ ...customWidgetDefaultButton }]
};
