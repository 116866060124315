import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { FC } from 'react';
import cx from 'classnames';
import update from 'immutability-helper';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { AddButton, Button, Dropdown, Form, Modal, Checkbox } from '@tactun/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { actionSchema } from '../../actions.schemas';
import { ActionOptions, RuleAction } from '../../actions.types';
import ActionCard from '../ActionCard';
import DigitalOutputActionForm from '../DigitalOutputActionForm';
import MeasurementActionForm from '../MeasurementActionForm';
import MessageBoxActionForm from '../MessageBoxActionForm';
import { MeasurementResponseDto } from '../../../Measurements';
import { ControlChannelResponseDto } from '../../../ControlChannels/controlChannel.types';
import { IListItemNumber } from '../../../../types';
import { convertNumberEnumToList } from '../../../../tools';
import {
	ActionAxisProperties,
	ActionCalculationProperties,
	ActionControlChannelProperties,
	ActionDigitalOutputProperties,
	ActionMeasurementProperties,
	ActionObjectTypes,
	ActionStageProperties,
	ActionStationProperties,
	ActionTestProperties,
	ActionUIButtonProperties,
	Properties
} from '../../actions.enums';
import { VariableResponseDto } from '../../../Variables';
import { useEntityOptions } from '../../../../hooks';
import WaveformFormView from '../../../Waveforms';
import { defaultValues } from '../../actions.converters';
import { WaveformFormErrors } from '../../../Waveforms';
import { consumeEntity } from '../../../../tools';
import { IStationChannelResponseDto } from '../../../StationChannels';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';
import { IAxisResponseDto } from '../../../Axes';
import AxisActionForm from '../AxisActionForm';
import MessageBoxWithConfirmationActionForm from '../MessageBoxWithConfirmationActionForm';
import { CalculationResponseDto } from '../../../Calculations';
import ControlAlgorithmsContainer, {
	ControlAlgorithmsAdditional,
	responseControlAlgorithmsFormConverter
} from '../../../ControlAlgorithms';
import { analogOutChannels, currentBasedAnalogOutChannels } from '../../../StationChannels/stationChannels.const';
import { useAxis } from '../../../Axes/axes.hooks';
import styles from './Actions.module.scss';

export interface ActionsProps {
	title: string;
	actions: RuleAction[];
	maxLimit?: number;
	updateActions: (newActions: RuleAction[]) => void;
	isParallel?: boolean;
	setIsParallel?: (value: boolean) => void;
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	digitalChannels: IStationChannelResponseDto[];
	controlChannels: ControlChannelResponseDto[];
	calculations?: CalculationResponseDto[];
	variables: VariableResponseDto[];
	actionOptions: ActionOptions;
	axes: IAxisResponseDto[];
	dragType: string;
	isError?: boolean;
	id?: string;
}

const Actions: FC<ActionsProps> = ({
	title,
	actions,
	maxLimit,
	updateActions,
	isParallel,
	setIsParallel,
	measurements,
	calculations,
	digitalChannels,
	controlChannels,
	variables,
	actionOptions,
	axes,
	isError = false,
	dragType,
	id = 'station-rule-new-action'
}) => {
	const formId = useRef(id);
	const { t } = useTranslation('actions');
	const { stationId, testId } = useParams();
	const form = useForm<RuleAction>({
		defaultValues,
		mode: 'onBlur',
		resolver: yupResolver(actionSchema)
	});

	const {
		control,
		watch,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, dirtyFields, touchedFields },
		register
	} = form;

	const objectType = watch('objectType');
	const objectId = watch('objectId');
	const property = watch('property');
	const controlChannel = useMemo(() => {
		if (objectType !== ActionObjectTypes.CONTROL_CHANNEL) return null;
		return controlChannels?.find((cc) => cc.id === objectId);
	}, [objectType, controlChannels, objectId]);

	// Modal
	const [modalState, setModalState] = useState<{ isOpen: boolean; index?: number }>({
		isOpen: false
	});

	const openModal = useCallback(
		(index?: number) => {
			reset(index !== undefined ? actions[index] : defaultValues);
			setModalState({ isOpen: true, index: index });
		},
		[reset, actions]
	);

	const closeModal = useCallback(() => {
		reset(defaultValues);
		setModalState({ isOpen: false });
	}, [reset]);

	const onSubmit = handleSubmit((validAction) => {
		const newActions = [...actions];
		if (modalState.index !== undefined) {
			newActions.splice(modalState.index, 1, validAction);
		} else {
			newActions.push(validAction);
		}
		updateActions(newActions);
		closeModal();
	});

	const isNeedFilterControlChannels = useMemo(
		() =>
			isParallel &&
			objectType === ActionObjectTypes.CONTROL_CHANNEL &&
			(property === ActionControlChannelProperties.OPERATE || property === ActionControlChannelProperties.SET),
		[isParallel, objectType, property]
	);

	const availableControlChannels = useMemo(() => {
		if (isNeedFilterControlChannels) {
			const alreadyOperatedControlChannelsIds: string[] = actions
				.filter((action, i) => {
					const isControlChannel = action.objectType === ActionObjectTypes.CONTROL_CHANNEL;
					const isOperate =
						action.property === ActionControlChannelProperties.OPERATE ||
						action.property === ActionControlChannelProperties.SET;

					return isControlChannel && isOperate && action.objectId && i !== modalState.index;
				})
				.map(({ objectId }) => objectId as string);

			return controlChannels.filter((channel) => !alreadyOperatedControlChannelsIds.includes(channel.id));
		} else {
			return controlChannels;
		}
	}, [actions, controlChannels, modalState, isNeedFilterControlChannels]);

	const typeAllOptions = useMemo<IListItemNumber[]>(() => convertNumberEnumToList(ActionObjectTypes, t), [t]);
	const typeOptions = useMemo(() => {
		const numberFilters = Object.keys(actionOptions).map((v) => Number(v));
		return typeAllOptions.filter(({ value }) => numberFilters.includes(value));
	}, [typeAllOptions, actionOptions]);

	const properyLabel = useMemo<string>(() => {
		switch (objectType) {
			case ActionObjectTypes.STATION:
				return t('Set State*');
			default:
				return t('Action*');
		}
	}, [objectType, t]);

	const propertyOptions = useMemo<IListItemNumber[] | undefined>(() => {
		let allProperties: IListItemNumber[] | undefined;
		switch (objectType) {
			case ActionObjectTypes.STATION:
				allProperties = convertNumberEnumToList(ActionStationProperties, t);
				break;
			case ActionObjectTypes.DIGITAL_OUTPUT:
				allProperties = convertNumberEnumToList(ActionDigitalOutputProperties, t);
				break;
			case ActionObjectTypes.TEST:
				allProperties = convertNumberEnumToList(ActionTestProperties, t);
				break;
			case ActionObjectTypes.CONTROL_CHANNEL:
				allProperties = convertNumberEnumToList(ActionControlChannelProperties, t);
				break;
			case ActionObjectTypes.MEASUREMENT:
				allProperties = convertNumberEnumToList(ActionMeasurementProperties, t);
				break;
			case ActionObjectTypes.CALCULATION:
				allProperties = convertNumberEnumToList(ActionCalculationProperties, t);
				break;
			case ActionObjectTypes.AXIS:
				allProperties = convertNumberEnumToList(ActionAxisProperties, t);
				break;
			case ActionObjectTypes.STAGE:
				allProperties = convertNumberEnumToList(ActionStageProperties, t);
				break;
			case ActionObjectTypes.UI_BUTTON:
				allProperties = convertNumberEnumToList(ActionUIButtonProperties, t);
				break;
			default:
				allProperties = undefined;
		}

		return allProperties && objectType !== null && objectType !== undefined
			? allProperties.filter(({ value }) => actionOptions[objectType as ActionObjectTypes]?.includes(value))
			: undefined;
	}, [objectType, t, actionOptions]);

	const objectIdLabel = useMemo<string>(() => {
		switch (objectType) {
			case ActionObjectTypes.DIGITAL_OUTPUT:
				return t('Channel*');
			case ActionObjectTypes.CONTROL_CHANNEL:
				return t('Control*');
			case ActionObjectTypes.MEASUREMENT:
				return t('Measurement*');
			case ActionObjectTypes.AXIS:
				return t('Axis*');
			default:
				return '';
		}
	}, [objectType, t]);

	const variablesOptions = useEntityOptions(variables);

	const getDeleteHandler = useCallback(
		(index: number) => () => {
			const newActions = [...actions.slice(0, index), ...actions.slice(index + 1)];
			updateActions(newActions);
		},
		[actions, updateActions]
	);

	const digitalChannelOptions = useEntityOptions(digitalChannels);
	const measurementOptions = useEntityOptions(measurements);
	const controlChannelOptions = useEntityOptions(availableControlChannels);
	const axisOptions = useEntityOptions(axes);

	const objectIdOptions = useMemo(() => {
		switch (objectType) {
			case ActionObjectTypes.DIGITAL_OUTPUT:
				return digitalChannelOptions;
			case ActionObjectTypes.CONTROL_CHANNEL:
				return controlChannelOptions;
			case ActionObjectTypes.MEASUREMENT:
				return measurementOptions;
			case ActionObjectTypes.AXIS:
				return axisOptions;
			default:
				return [];
		}
	}, [objectType, digitalChannelOptions, controlChannelOptions, measurementOptions, axisOptions]);

	const getLabel = useCallback(
		(action: RuleAction) => {
			if (action.objectType === undefined || action.objectType === null) {
				return '';
			}

			switch (action.objectType) {
				case ActionObjectTypes.DIGITAL_OUTPUT:
					const selectedChannelId = action.objectId || '';
					const selectedChannel = digitalChannels.find((channel) => channel.id === selectedChannelId);
					return t('Digital output: {{name}}', { name: selectedChannel?.name || '' });

				case ActionObjectTypes.TEST:
					return t('Test');

				case ActionObjectTypes.CONTROL_CHANNEL:
					const selectedControlChannelId = action.objectId || '';
					const selectedControlChannel = controlChannels.find((channel) => channel.id === selectedControlChannelId);
					return t('Control: {{name}}', { name: selectedControlChannel?.name || '' });

				case ActionObjectTypes.MEASUREMENT:
					const measurementlId = action.objectId || '';
					const meas = measurements.find((measurement) => measurement.id === measurementlId);
					return t('Measurement: {{name}}', { name: meas?.name || '' });

				case ActionObjectTypes.CALCULATION:
					const calculationId = action.objectId || '';
					const calc = calculations?.find((calc) => calc.uuid === calculationId);
					return t('Measurement: {{name}}', { name: calc?.name || '' });

				case ActionObjectTypes.MESSAGE_BOX:
					return t('Message box');

				case ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION:
					return t('Message box with confirmation');

				case ActionObjectTypes.STATION:
					return t('Station State');

				case ActionObjectTypes.STAGE:
					return t('Stage');

				case ActionObjectTypes.AXIS:
					return t('Axis');

				case ActionObjectTypes.UI_BUTTON:
					return t('UI Button');

				default:
					return '';
			}
		},
		[digitalChannels, t, controlChannels, measurements, calculations]
	);

	const getValueLabel = useCallback(
		(action: RuleAction) => {
			const isPropertyMissing = action.property === undefined || action.property === null;

			if (isPropertyMissing && action.objectType === ActionObjectTypes.MESSAGE_BOX) {
				return action.message || '';
			} else if (isPropertyMissing && action.objectType === ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION) {
				return action.message || '';
			} else if (action.property === undefined || action.property === null) {
				return '';
			} else {
				switch (action.objectType) {
					case ActionObjectTypes.DIGITAL_OUTPUT:
						return isPropertyMissing ? '' : t(ActionDigitalOutputProperties[action.property]);
					case ActionObjectTypes.TEST:
						return t(ActionTestProperties[action.property]);
					case ActionObjectTypes.MEASUREMENT:
						return t(ActionMeasurementProperties[action.property]);
					case ActionObjectTypes.CONTROL_CHANNEL:
						return t(ActionControlChannelProperties[action.property]);
					case ActionObjectTypes.STATION:
						return t(ActionStationProperties[action.property]);
					case ActionObjectTypes.STAGE:
						return t(ActionStageProperties[action.property]);
					case ActionObjectTypes.AXIS:
						return t(ActionAxisProperties[action.property]);
					case ActionObjectTypes.UI_BUTTON:
						return t(ActionUIButtonProperties[action.property]);
					default:
						return '';
				}
			}
		},
		[t]
	);

	const moveCard = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const newActions = update(actions, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, actions[dragIndex] as RuleAction]
				]
			});

			updateActions(newActions);
		},
		[actions, updateActions]
	);

	const measurementUnit = useMemo(() => {
		if (objectType === ActionObjectTypes.MEASUREMENT && objectId) {
			let measurementUnit = '';
			consumeEntity(measurements, objectId as string).onSuccess((measurement) => {
				measurementUnit = measurement.unit.name;
			});
			return measurementUnit;
		} else {
			return '';
		}
	}, [measurements, objectId, objectType]);

	useEffect(() => {
		if (objectType !== undefined && objectType !== null && dirtyFields.objectType) {
			reset({ ...defaultValues, objectType });
		}
	}, [dirtyFields.objectType, objectType, reset]);

	const onFormSubmit = useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.stopPropagation();
			onSubmit(e);
		},
		[onSubmit]
	);

	//Set Control Algorithms default value after channel selection
	useEffect(() => {
		if (
			objectType === ActionObjectTypes.CONTROL_CHANNEL &&
			(property === Properties.OPERATE || property === Properties.SET)
		) {
			if (touchedFields.objectId && objectId && controlChannel?.controlAlgorithm) {
				const controlAlgorithmsForm = responseControlAlgorithmsFormConverter(controlChannel.controlAlgorithm);
				setValue('controlAlgorithmsForm', controlAlgorithmsForm);
				// Do not remove this line, it is needed to update the form state. react-hook-form does not update the nested field when the value is array
				setValue('controlAlgorithmsForm.additionalAlgorithms', controlAlgorithmsForm.additionalAlgorithms);
			}
		}
	}, [controlChannel?.controlAlgorithm, objectId, objectType, property, setValue, touchedFields.objectId]);

	const axisId = useMemo(() => {
		return objectType === ActionObjectTypes.CONTROL_CHANNEL &&
			(property === Properties.OPERATE || property === Properties.SET) &&
			objectId
			? controlChannel?.axis?.id
			: undefined;
	}, [controlChannel?.axis?.id, objectId, objectType, property]);
	const { axisDto } = useAxis(axisId);

	const isAxisBasedOnAnalogOut = useMemo(() => {
		const type = axisDto?.actuator?.stationChannel?.type;
		return type !== undefined && analogOutChannels.includes(type);
	}, [axisDto]);

	const additionalAlgorithms = useWatch({ control, name: 'controlAlgorithmsForm.additionalAlgorithms' });
	const isAdaptive =
		additionalAlgorithms?.includes(ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE) ||
		additionalAlgorithms?.includes(ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN);

	return (
		<>
			<div>
				<div className="actionContainer">
					<div className="rulesLabel">{title}</div>
					{setIsParallel && (
						<div className="checkboxContainer">
							<Checkbox
								label={t('Run in parallel')}
								disabled
								checked={!!isParallel}
								data-testid="ruleRunParallel"
								onChange={() => {
									//setIsParallel(!isParallel);
								}}
							/>
						</div>
					)}
				</div>

				{actions.map((action, index) => (
					<ActionCard
						label={getLabel(action)}
						property={getValueLabel(action)}
						onEdit={() => openModal(index)}
						onDelete={getDeleteHandler(index)}
						key={index}
						id={index}
						index={index}
						moveCard={moveCard}
						dragType={dragType}
					/>
				))}

				{(!maxLimit || maxLimit > actions.length) && (
					<AddButton type="button" onClick={() => openModal()} isError={isError} />
				)}
			</div>
			<Modal id={id} isOpen={modalState.isOpen} onClose={closeModal} isLarge hidePrevModals>
				<Modal.Header>{t('Add an Action')}</Modal.Header>
				<Modal.Content>
					<FormProvider {...form}>
						<Form
							id={formId.current}
							onSubmit={onFormSubmit}
							className={cx(styles.form, { [styles.messageAction]: objectType === ActionObjectTypes.MESSAGE_BOX })}
						>
							<input type="hidden" {...register} />
							<Controller
								control={control}
								name="objectType"
								render={({ field }) => (
									<Dropdown
										{...field}
										label={t('Object type*')}
										options={typeOptions}
										className={styles.objectTypeDropdown}
										data-testid="ruleActionObjectType"
										error={errors.objectType?.message as string}
									/>
								)}
							/>

							{objectIdLabel && (
								<Controller
									name="objectId"
									control={control}
									render={({ field }) => (
										<Dropdown
											label={objectIdLabel}
											{...field}
											options={objectIdOptions}
											isMulti={objectType === ActionObjectTypes.DIGITAL_OUTPUT}
											data-testid="ruleActionObjectId"
											error={errors.objectId?.message as string}
										/>
									)}
								/>
							)}

							{propertyOptions !== undefined && (
								<Controller
									control={control}
									name="property"
									render={({ field }) => (
										<Dropdown
											{...field}
											label={properyLabel}
											options={propertyOptions}
											error={errors.property?.message as string}
											data-testid="ruleActionProperty"
											disabled={objectType === undefined}
										/>
									)}
								/>
							)}

							{objectType === ActionObjectTypes.DIGITAL_OUTPUT && <DigitalOutputActionForm form={form} />}
							{objectType === ActionObjectTypes.MEASUREMENT && (
								<MeasurementActionForm form={form} variablesOptions={variablesOptions} unit={measurementUnit} />
							)}
							{objectType === ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION && (
								<MessageBoxWithConfirmationActionForm
									form={form}
									measurements={measurements}
									digitalChannels={digitalChannels}
									controlChannels={controlChannels}
									variables={variables}
									axes={axes}
									actionOptions={actionOptions}
								/>
							)}
							{objectType === ActionObjectTypes.MESSAGE_BOX && <MessageBoxActionForm form={form} />}
							{objectType === ActionObjectTypes.CONTROL_CHANNEL &&
								(property === Properties.OPERATE || property === Properties.SET) &&
								objectId && (
									<>
										<ControlAlgorithmsContainer
											formPrefix="controlAlgorithmsForm"
											stationId={stationId}
											testId={testId}
											ccId={objectId as string}
											isControlWithCurrent={currentBasedAnalogOutChannels.some(
												(t) => t === controlChannel?.axis.actuator.stationChannel?.type
											)}
											isAxisBasedOnAnalogOut={isAxisBasedOnAnalogOut}
											isOpenLoopAvailable={isAxisBasedOnAnalogOut}
											errors={errors.controlAlgorithmsForm}
										/>
										{property === Properties.OPERATE && (
											<>
												<Form.Divider />
												<WaveformFormView
													stationId={stationId}
													testId={testId}
													formPrefix="waveform"
													controlChannelId={objectId as string}
													errors={errors.waveform as WaveformFormErrors}
													isAdaptive={isAdaptive}
												/>
											</>
										)}
									</>
								)}
							{objectType === ActionObjectTypes.AXIS && <AxisActionForm form={form} axes={axes} />}
						</Form>
					</FormProvider>
				</Modal.Content>
				<Modal.Footer>
					<Button color="success" type="button" onClick={closeModal}>
						{t('Cancel')}
					</Button>
					<Button color="secondary" type="submit" form={formId.current}>
						{t('Save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Actions;
