import { useState, useCallback, useMemo } from 'react';
import { Menubar } from 'primereact/menubar';
import { GraphTools } from '../../graphWidget.enums';
import { MenuItem } from 'primereact/menuitem';
import styles from './Toolbar.module.scss';

export interface IToolbarProps {
	onAction: (graph: GraphTools, previews?: GraphTools) => void;
}

const actionsWithSelection = [
	GraphTools.CURSOR,
	GraphTools.MOVE,
	GraphTools.ZOOM_RESET,
	GraphTools.ZOOM_XY,
	GraphTools.ZOOM_X,
	GraphTools.ZOOM_Y
];

const Toolbar = ({ onAction }: IToolbarProps) => {
	const [active, setActive] = useState<GraphTools>(GraphTools.CURSOR);
	const [pause, setPause] = useState<boolean>(false);

	const handleAction = useCallback(
		(toolName: GraphTools) => {
			switch (toolName) {
				case GraphTools.PAUSE:
					setPause(true);
					onAction(toolName);
					break;
				case GraphTools.RESUME:
					setPause(false);
					onAction(toolName);
					break;
				default:
					setActive((old) => {
						if (actionsWithSelection.some((a) => a === toolName)) {
							onAction(toolName, old);
							return toolName;
						} else {
							onAction(toolName);
							return old;
						}
					});
					break;
			}
		},
		[onAction]
	);

	const items: MenuItem[] = useMemo(() => {
		return [
			{
				command: () => handleAction(pause ? GraphTools.RESUME : GraphTools.PAUSE),
				icon: pause ? 't-icon-run-test' : 't-icon-pause'
			},
			{
				command: () => handleAction(GraphTools.REFRESH),
				icon: 't-icon-refresh'
			},
			{
				command: () => handleAction(GraphTools.CURSOR),
				icon: 't-icon-crosshair',
				className: active === GraphTools.CURSOR ? styles.activeTool : ''
			},
			{
				command: () => handleAction(GraphTools.MOVE),
				icon: 't-icon-move',
				className: active === GraphTools.MOVE ? styles.activeTool : ''
			},
			{
				command: () => handleAction(GraphTools.ZOOM_XY),
				icon: 't-icon-zoom-in',
				className: active === GraphTools.ZOOM_XY ? styles.activeTool : ''
			},
			{
				command: () => handleAction(GraphTools.ZOOM_RESET),
				icon: 't-icon-zoom-out',
				className: active === GraphTools.ZOOM_RESET ? styles.activeTool : ''
			},
			{
				command: () => handleAction(GraphTools.ZOOM_X),
				icon: 't-icon-zoom-x',
				className: active === GraphTools.ZOOM_X ? styles.activeTool : ''
			},
			{
				command: () => handleAction(GraphTools.ZOOM_Y),
				icon: 't-icon-zoom-y',
				className: active === GraphTools.ZOOM_Y ? styles.activeTool : ''
			},
			{
				command: () => handleAction(GraphTools.CLEAR),
				icon: 't-icon-clear'
			},
			{
				command: () => handleAction(GraphTools.SNAPSHOT),
				icon: 't-icon-screenshot'
			}
		];
	}, [active, handleAction, pause]);

	return (
		<div className={styles.container} data-element="toolbar">
			<Menubar model={items} />
		</div>
	);
};

export default Toolbar;
