export enum ControllerCrudActionTypes {
	EDIT = 'EDIT_CONTROLLER',
	CREATE = 'CREATE_CONTROLLER',
	DUPLICATE = 'DUPLICATE_CONTROLLER',
	DELETE = 'DELETE_CONTROLLER'
}

export enum ControllerStatuses {
	AVAILABLE,
	LINKED
}

export enum PurchaseStatuses {
	AVAILABLE,
	SOLD
}
