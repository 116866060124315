import React, { FC, useCallback } from 'react';
import ButtonWidgetTable from '../components/ButtonWidgetTable';
import { useButtonWidgets } from '../buttonWidget.hooks';
import { WidgetSettingsModalProps } from '../../Dashboard/dashboard.types';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTest } from '../../Tests/tests.hooks';
import { buttonWidgetToWidgetMetadata } from '../buttonWidget.converters';

const ButtonWidgetTableContainer: FC<WidgetSettingsModalProps> = (props) => {
	const { onSave, onCancel } = props;
	const { testId, stationId: stationIdParam } = useParams();
	const { testDto } = useTest(testId);
	const stationId = stationIdParam || testDto?.stationId;
	const { widgets, isWidgetsLoading } = useButtonWidgets(stationId);
	const { t } = useTranslation('widgets');

	const [selectedWidget, setSelectedWidget] = React.useState<string>();

	const onAdd = useCallback(() => {
		const widget = widgets?.find((w) => w.uuid === selectedWidget);
		if (widget) {
			onSave(buttonWidgetToWidgetMetadata(widget), null, { w: Math.round(widget.width / 3), h: widget.height });
		}
	}, [onSave, selectedWidget, widgets]);

	return (
		<>
			<Modal.Header>{t('Add Button Widget')}</Modal.Header>
			<Modal.Content>
				<ButtonWidgetTable
					isLoading={isWidgetsLoading}
					widgets={widgets || []}
					setSelectedWidget={setSelectedWidget}
					selectedWidget={selectedWidget}
				/>
			</Modal.Content>
			<Modal.Footer>
				<Button className="btnCancel" onClick={onCancel} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button onClick={onAdd} color="secondary" variant="contained" disabled={!selectedWidget}>
					{t('Add')}
				</Button>
			</Modal.Footer>
		</>
	);
};

export default ButtonWidgetTableContainer;
