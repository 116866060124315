import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import DashboardTypesTable from '../components/DashboardTypesTable';
import { DashboardTypeCrudActionTypes } from '../dashboardTypes.enums';
import { IDashboardTypeListItem } from '../dashboardTypes.types';
import { useDashboardTypes } from '../dashboardTypes.hooks';
import { dashboardTypeResponseToListItem } from '../dashboardTypes.converters';

const DashboardTypesTableContainer: FC = () => {
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const { dashboardTypes, isDashboardTypesLoading, deleteDashboardType } = useDashboardTypes();
	const listItems = useMemo(() => dashboardTypes?.map(dashboardTypeResponseToListItem), [dashboardTypes]);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			setEntityForDelete(undefined);
			deleteDashboardType(id);
		},
		[deleteDashboardType]
	);

	const handleAction = useCallback(
		(type: DashboardTypeCrudActionTypes, data?: IDashboardTypeListItem) => {
			switch (type) {
				case DashboardTypeCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case DashboardTypeCrudActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case DashboardTypeCrudActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case DashboardTypeCrudActionTypes.DELETE:
					setEntityForDelete(data as IDashboardTypeListItem);
					break;
			}
		},
		[navigate]
	);

	return (
		<>
			<DashboardTypesTable onAction={handleAction} data={listItems} isLoading={isDashboardTypesLoading} />
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.DASHBOARD_TYPE}
				entity={entityForDelete}
			/>
		</>
	);
};

export default DashboardTypesTableContainer;
