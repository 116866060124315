import { useMemo, useRef } from 'react';
import { PageLayout, Form, Switch, Dropdown } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import styles from './Specimens.module.scss';

interface AxesProps {
	isCreate?: boolean;
	form: UseFormReturn<Record<string, string>>;
}

const Specimens: React.FC<AxesProps> = ({ isCreate, form }) => {
	const { t } = useTranslation('axes');
	const formId = useRef('axesForm');
	const { control, register } = form;

	const title = useMemo<string>(() => {
		return isCreate ? t('Create a Feature-Set') : t('Edit a Feature-Set');
	}, [isCreate, t]);

	return (
		<>
			<PageLayout info={title}>
				<PageLayout.Header title={t('Specimens')} />
				<Form className={styles.container} id={formId.current}>
					<input type="hidden" {...register('stationId')} />
					<div>Specimens Creation</div>
					<Controller
						name="type1"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Specimen Batches Creation</div>
					<Controller
						name="type2"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Specimen Types</div>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<div></div>
					<div>Dogbone</div>
					<Controller
						name="type3"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>CTOD Beam</div>
					<Controller
						name="type4"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>3 pt. Bend</div>
					<Controller
						name="type5"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Flat</div>
					<Controller
						name="type6"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Tube</div>
					<Controller
						name="type7"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Foam</div>
					<Controller
						name="type8"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Fastener</div>
					<Controller
						name="type9"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Hex</div>
					<Controller
						name="type10"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Solidshaft-torsion</div>
					<Controller
						name="type11"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Cut tube</div>
					<Controller
						name="type12"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
				</Form>
			</PageLayout>
		</>
	);
};

export default Specimens;
