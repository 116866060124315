import * as yup from 'yup';
import { emptyStringToNull } from '../../tools/yupHelpers';
import {
	Condition,
	ConditionsAuxDeviceProperties,
	ConditionsConnectionProperties,
	ConditionsDigitalInputProperties,
	ConditionsObjectTypes,
	ConditionsStationProperties,
	ConditionsTestProperties,
	EqualConditions,
	InRangeConditions
} from './conditions.enums';
import { RuleCondition } from './conditions.types';

export const baseSchema = yup.object({
	objectType: yup.mixed<ConditionsObjectTypes>().required('Object type is a required field')
});

export const digitalInputSchema = yup.object({
	objectId: yup.string().required('Channel is required field'),
	property: yup.mixed<ConditionsDigitalInputProperties>().required('State is required field')
});

export const auxDeviceSchema = yup.object({
	objectId: yup.string().required('Auxiliary device is required field'),
	property: yup.mixed<ConditionsAuxDeviceProperties>().required('State is required field')
});

export const calculationSchema = yup.object({
	objectId: yup.string().required('This is required field'),
	condition: yup.mixed<InRangeConditions>().required('This is required field'),
	lowerLimit: yup.number().transform(emptyStringToNull),
	upperLimit: yup
		.number()
		.transform(emptyStringToNull)
		.min(yup.ref('lowerLimit') || 0 + 1)
});

export const measurementSchema = yup.object({
	objectId: yup.string().required('Measurement is required field'),
	condition: yup.mixed<Condition>().required('Condition is required field'),
	lowerLimit: yup
		.number()
		.transform(emptyStringToNull)
		.when('condition', ([condition], schema) => {
			return condition === Condition.IN_RANGE || condition === Condition.OUT_OF_RANGE
				? schema.required('Lower limit is required field')
				: schema;
		}),
	upperLimit: yup
		.number()
		.transform(emptyStringToNull)
		.when(['condition', 'lowerLimit'], ([condition, lowerLimit], schema) => {
			return condition === Condition.IN_RANGE || condition === Condition.OUT_OF_RANGE
				? schema.min(lowerLimit).required('Upper limit is required field')
				: schema;
		}),
	threshold: yup
		.number()
		.transform(emptyStringToNull)
		.when('condition', ([condition], schema) => {
			return condition === Condition.LESS_THAN || condition === Condition.MORE_THAN
				? schema.required('Threshold is required field')
				: schema;
		})
});

export const connectionSchema = yup.object({
	property: yup.mixed<ConditionsConnectionProperties>().required('State is required field')
});

export const testSchema = yup.object({
	property: yup.mixed<ConditionsTestProperties>().required('State is required field')
});

export const stationSchema = yup.object({
	property: yup.mixed<ConditionsStationProperties>().required('State is required field'),
	operation: yup.mixed<EqualConditions>().required('Condition is required field')
});

export const conditionSchema = yup.lazy((data: RuleCondition) => {
	switch (data.objectType) {
		case ConditionsObjectTypes.DIGITAL_INPUT:
			return baseSchema.concat(digitalInputSchema);
		case ConditionsObjectTypes.AUX_DEVICE:
			return baseSchema.concat(auxDeviceSchema);
		case ConditionsObjectTypes.CALCULATION:
			return baseSchema.concat(calculationSchema);
		case ConditionsObjectTypes.MEASUREMENT:
			return baseSchema.concat(measurementSchema);
		case ConditionsObjectTypes.CONNECTION:
			return baseSchema.concat(connectionSchema);
		case ConditionsObjectTypes.TEST:
			return baseSchema.concat(testSchema);
		case ConditionsObjectTypes.STATION:
			return baseSchema.concat(stationSchema);
		default:
			return baseSchema;
	}
});
