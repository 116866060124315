import { object, string, array, mixed, number } from 'yup';
import { DataChannelType } from '../DataChannel';
import { StageTypes } from '../Tests/tests.enums';
import { DataLoggingTypes, ExtremumTypes } from './testDataLogging.enums';
import { compareTimeWithUnitScale } from './testDataLogging.tools';
import { checkName } from './testDataLogging.api';
import { alphaNumericRegex } from '../../constants/regexes';
import { emptyStringToNull } from '../../tools/yupHelpers';

export const testDataLoggingSchema = object({
	id: string().notRequired(),
	name: string()
		.required('Name is a required field')
		.max(30)
		.matches(alphaNumericRegex, 'Do not use special symbols')
		.test('checkTestDataLoggingUniqueness', 'Name is used', function (value, form) {
			if (!value) return true;
			return checkName(value, form?.parent?.testId, form?.parent?.id);
		}),
	stageId: string().required(),
	testId: string().required(),
	dataChannels: array()
		.of(
			object().shape({
				type: mixed<DataChannelType>().required(),
				id: string().required()
			})
		)
		.min(1, 'Data channel is a required field')
		.required(),
	dataLoggingType: mixed<DataLoggingTypes>().required(),
	stageType: mixed<StageTypes>().required(),
	rate: number()
		.transform(emptyStringToNull)
		.notRequired()
		.nullable()
		.when(['dataLoggingType'], {
			is: (dataLoggingType: DataLoggingTypes) =>
				dataLoggingType === DataLoggingTypes.TIMED || dataLoggingType === DataLoggingTypes.PERIODIC,
			then: (schema) => schema.moreThan(0).max(10000).nullable().required('Rate is a required field')
		}),
	interval: number()
		.transform(emptyStringToNull)
		.notRequired()
		.nullable()
		.when(['dataLoggingType'], {
			is: DataLoggingTypes.PERIODIC,
			then: (schema) =>
				schema
					.moreThan(0)
					.required('Interval is a required field')
					.test('intervalMoreThenDuration', 'Interval should be longer than Duration', function (value, form) {
						if (value && form?.parent?.intervalUnitId && form?.parent?.dur && form?.parent?.durationUnitId) {
							const intervalUnitScale = form?.parent?.timeUnits?.find(
								(unit: any) => unit.id === form?.parent?.intervalUnitId
							)?.scale;
							const durUnitScale = form?.parent?.timeUnits?.find(
								(unit: any) => unit.id === form?.parent?.durationUnitId
							)?.scale;
							return compareTimeWithUnitScale([value, intervalUnitScale], [form?.parent?.dur, durUnitScale]);
						}

						return true;
					})
		}),
	intervalUnitId: string()
		.optional()
		.when(['dataLoggingType'], {
			is: DataLoggingTypes.PERIODIC,
			then: (schema) => schema.required('Unit is required')
		}),
	dur: number()
		.transform(emptyStringToNull)
		.notRequired()
		.nullable()
		.when(['dataLoggingType'], {
			is: DataLoggingTypes.PERIODIC,
			then: (schema) =>
				schema
					.moreThan(0)
					.required('Duration is a required field')
					.test('durationLessThenInterval', 'Duration should be less then Interval', function (value, form) {
						if (value && form?.parent?.intervalUnitId && form?.parent?.dur && form?.parent?.durationUnitId) {
							const intervalUnitScale = form?.parent?.timeUnits?.find(
								(unit: any) => unit.id === form?.parent?.intervalUnitId
							)?.scale;
							const durUnitScale = form?.parent?.timeUnits?.find(
								(unit: any) => unit.id === form?.parent?.durationUnitId
							)?.scale;

							return !compareTimeWithUnitScale([value, durUnitScale], [form?.parent?.interval, intervalUnitScale]);
						}

						return true;
					})
		}),
	durationUnitId: string()
		.optional()
		.when(['dataLoggingType'], {
			is: DataLoggingTypes.PERIODIC,
			then: (schema) => schema.required('Unit is required')
		}),
	timeUnits: array()
		.of(
			object()
				.shape({
					id: string().required(),
					name: string().required(),
					offset: number().transform(emptyStringToNull).required(),
					scale: number().transform(emptyStringToNull).required()
				})
				.nullable()
				.notRequired()
		)
		.nullable()
		.notRequired(),
	extremumTypes: mixed<ExtremumTypes>().when(['dataLoggingType'], {
		is: DataLoggingTypes.PEAK_VALLEY,
		then: (schema) => schema.required('Extremums is required'),
		otherwise: (schema) => schema.notRequired()
	}),
	refAxisId: string().when(['dataLoggingType'], {
		is: DataLoggingTypes.PEAK_VALLEY,
		then: (schema) => schema.required('Reference Axis is required'),
		otherwise: (schema) => schema.notRequired()
	})
});
