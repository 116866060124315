import { FC } from 'react';
import { InputNumber } from '@tactun/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { constructFieldName } from '../../controlAlgorithms.tools';
import styles from './FeedForwardAlgForm.module.scss';
import { FeedForwardAlgFormErrors } from '../../controlAlgorithms.types';

interface FeedForwardAlgFormProps {
	formPrefix: string;
	disabled?: boolean;
	errors?: FeedForwardAlgFormErrors;
}

const FeedForwardAlgForm: FC<FeedForwardAlgFormProps> = ({ formPrefix, errors, disabled = false }) => {
	const { t } = useTranslation('controlAlgorithm');

	const { control } = useFormContext();

	return (
		<>
			<div className={styles.formLabel}>{t('Feed Forward')}</div>
			<Controller
				name={constructFieldName('velocityFeedForward', `${formPrefix}.feedForward`)}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Velocity Feed Forward*')}
						error={errors?.velocityFeedForward?.message as string}
						data-testid="velocityFeedForward"
						disabled={disabled}
					/>
				)}
			/>
			<Controller
				name={constructFieldName('accelerationFeedForward', `${formPrefix}.feedForward`)}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Acceleration Feed Forward*')}
						error={errors?.accelerationFeedForward?.message as string}
						data-testid="accelerationFeedForward"
						disabled={disabled}
					/>
				)}
			/>
			<Controller
				name={constructFieldName('jerkFeedForward', `${formPrefix}.feedForward`)}
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={t('Jerk Feed Forward*')}
						error={errors?.jerkFeedForward?.message as string}
						data-testid="jerkFeedForward"
						disabled={disabled}
					/>
				)}
			/>
			<div></div>
		</>
	);
};

export default FeedForwardAlgForm;
