// package: calibration
// file: calibration.proto

var calibration_pb = require("./calibration_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var WebCalibrationUsedChannels = (function () {
  function WebCalibrationUsedChannels() {}
  WebCalibrationUsedChannels.serviceName = "calibration.WebCalibrationUsedChannels";
  return WebCalibrationUsedChannels;
}());

WebCalibrationUsedChannels.SensorCalibration = {
  methodName: "SensorCalibration",
  service: WebCalibrationUsedChannels,
  requestStream: false,
  responseStream: true,
  requestType: calibration_pb.CalibrationUsedChannelsRequest,
  responseType: calibration_pb.CalibrationUsedChannelsResponse
};

exports.WebCalibrationUsedChannels = WebCalibrationUsedChannels;

function WebCalibrationUsedChannelsClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

WebCalibrationUsedChannelsClient.prototype.sensorCalibration = function sensorCalibration(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(WebCalibrationUsedChannels.SensorCalibration, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.WebCalibrationUsedChannelsClient = WebCalibrationUsedChannelsClient;

var WebCalibrationSave = (function () {
  function WebCalibrationSave() {}
  WebCalibrationSave.serviceName = "calibration.WebCalibrationSave";
  return WebCalibrationSave;
}());

WebCalibrationSave.SensorCalibration = {
  methodName: "SensorCalibration",
  service: WebCalibrationSave,
  requestStream: false,
  responseStream: false,
  requestType: calibration_pb.CalibrationWidgetRequest,
  responseType: calibration_pb.CalibrationWidgetResponse
};

exports.WebCalibrationSave = WebCalibrationSave;

function WebCalibrationSaveClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

WebCalibrationSaveClient.prototype.sensorCalibration = function sensorCalibration(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebCalibrationSave.SensorCalibration, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.WebCalibrationSaveClient = WebCalibrationSaveClient;

