import { Form, InputText, Dropdown, Button } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { ProductFormType } from '../../products.types';
import { IListItem, IListItemNumber } from '../../../../types';
import styles from './Products.module.scss';

const classes = 'flexRow flexFull flexStation';
interface IProductsProps {
	form: UseFormReturn<ProductFormType>;
	platforms: IListItemNumber[];
	stations: IListItem[];
	formId: string;
	isStationChangeDisabled?: boolean;
	onSave: (event: React.FormEvent<HTMLFormElement>) => void;
}

const Products: React.FC<IProductsProps> = ({ form, platforms, stations, formId, isStationChangeDisabled, onSave }) => {
	const { t } = useTranslation('products');
	const {
		control,
		formState: { errors },
		register
	} = form;

	return (
		<Form className={styles.container} onSubmit={onSave} id={formId}>
			<input type="hidden" {...register('id')} />
			<input type="hidden" {...register('controllerSerialNumber')} />
			<InputText data-testid="nameId" label={t('Product name*')} {...register('name')} error={errors.name?.message} />
			<div />
			<InputText
				data-testid="modelId"
				label={t('Controller model*')}
				{...register('model')}
				disabled
				error={errors.model?.message}
			/>
			<div className={classes}>
				<Controller
					name="stationId"
					control={control}
					render={({ field }) => (
						<Dropdown
							{...field}
							data-testid="stationId"
							label={t('Station name*')}
							filter
							options={stations}
							disabled={isStationChangeDisabled}
							error={errors.stationId?.message}
						/>
					)}
				/>
				<Button
					type="button"
					icon="t-icon-edit"
					className={styles.editData}
					data-testid="editData"
					disabled={isStationChangeDisabled}
				/>
			</div>
			<InputText
				data-testid="versionId"
				label={t('Product version*')}
				{...register('version')}
				error={errors.version?.message}
			/>
			<Controller
				name="platform"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						data-testid="platformId"
						label={t('Application platform*')}
						filter
						options={platforms}
						error={errors.platform?.message}
						disabled
					/>
				)}
			/>
		</Form>
	);
};

export default Products;
