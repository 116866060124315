import { object, string, mixed, number, boolean } from 'yup';
import { FormulaSpecimenParams, FormulaTypes, FormulaVariablesTypes } from './measurements.enums';
import * as api from './measurements.api';
import { alphaNumericRegex } from '../../constants/regexes';
import { emptyStringToNull } from '../../tools/yupHelpers';

export const variableSchema = object({
	formulaVariableType: mixed<FormulaVariablesTypes>().nullable().required('Data type is a required field'),
	data: number()
		.transform(emptyStringToNull)
		.nullable()
		.when(['formulaVariableType'], {
			is: (formulaVariableType: FormulaVariablesTypes) => formulaVariableType === FormulaVariablesTypes.NUMERIC,
			then: (schema) => schema.required('Numeric value is a required field'),
			otherwise: (schema) => schema.notRequired()
		}),
	variableId: string()
		.nullable()
		.when(['formulaVariableType'], {
			is: (formulaVariableType: FormulaVariablesTypes) => formulaVariableType === FormulaVariablesTypes.VARIABLE,
			then: (schema) => schema.required('Variable is a required field'),
			otherwise: (schema) => schema.notRequired()
		}),
	specimenParamType: mixed<FormulaSpecimenParams>()
		.nullable()
		.when(['formulaVariableType'], {
			is: (formulaVariableType: FormulaVariablesTypes) =>
				formulaVariableType === FormulaVariablesTypes.SPECIMEN_PARAMETERS,
			then: (schema) => schema.required('Specimen parameters is a required field'),
			otherwise: (schema) => schema.notRequired()
		})
});

export const measurementFormSchemaBase = object({
	id: string().nullable(),
	name: string()
		.required()
		.max(30)
		.matches(alphaNumericRegex, 'Is not in correct format')
		.test('checkMeasurementUniqueness', 'Name is used', function (value, form) {
			if (!value) return true;
			return api.checkName(value, form?.parent?.stationId || form?.parent?.testId, form?.parent?.id);
		}),
	sensorId: string().required('Sensor is a required field'),
	isApplyFormula: boolean().required(),
	isInverseReading: boolean().required(),
	formulaType: mixed<FormulaTypes>()
		.nullable()
		.when('isApplyFormula', {
			is: true,
			then: (schema) => schema.required('This is a required field'),
			otherwise: (schema) => schema.notRequired()
		}),
	unitId: string().required('Measurement unit is a required field'),
	dataIn1: variableSchema.nullable().when('formulaType', {
		is: (formulaType: FormulaTypes) => formulaType === FormulaTypes.LINEAR || formulaType === FormulaTypes.DIVIDE,
		then: (schema) => schema.required('this is a required field'),
		otherwise: (schema) => schema.notRequired()
	}),
	dataIn2: variableSchema.nullable().when('formulaType', {
		is: (formulaType: FormulaTypes) => formulaType === FormulaTypes.LINEAR,
		then: (schema) => schema.required('this is a required field'),
		otherwise: (schema) => schema.notRequired()
	}),
	upperLimit: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('isLimitEnabled', {
			is: true,
			then: (schema) =>
				schema
					.required('Upper limit is a required field')
					.test('upperLimitValidation', 'Upper and Lower limits cannot be equal', (value, form) => {
						if (!form?.parent?.lowerLimit && form?.parent?.lowerLimit !== 0) return true;

						if (form?.parent?.lowerLimit === value) {
							return form.createError({
								message: 'Upper and Lower limits cannot be equal'
							});
						}
						if (form?.parent?.lowerLimit > value) {
							return form.createError({
								message: 'Upper limit should be grater than Lower limit'
							});
						}

						return true;
					}),
			otherwise: (schema) => schema.notRequired()
		}),
	lowerLimit: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('isLimitEnabled', {
			is: true,
			then: (schema) =>
				schema
					.required('Lower limit is a required field')
					.test('lowerLimitValidation', 'Upper and Lower limits cannot be equal', (value, form) => {
						if (!form?.parent?.upperLimit && form?.parent?.upperLimit !== 0) return true;

						if (form?.parent?.upperLimit === value) {
							return form.createError({
								message: 'Upper and Lower limits cannot be equal'
							});
						}
						if (form?.parent?.upperLimit < value) {
							return form.createError({
								message: 'Lower limit should be less than Upper limit'
							});
						}
						return true;
					}),
			otherwise: (schema) => schema.notRequired()
		}),
	isLimitEnabled: boolean().required()
});

export const stationMeasurementFormSchema = measurementFormSchemaBase.concat(
	object({ stationId: string().required() })
);
