import React from 'react';
import Keycloak, { KeycloakLoginOptions, KeycloakLogoutOptions } from 'keycloak-js';
import { IUserData } from './keycloakAxios';

export interface IKeycloakContext {
	getUserData: () => IUserData | null;
	keycloak: Keycloak;
	login: (options?: KeycloakLoginOptions) => void;
	logout: (options?: KeycloakLogoutOptions) => void;
}

export default React.createContext<IKeycloakContext | null>(null);
