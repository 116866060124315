import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ControlChannel from '../components/ControlChannel';
import { controlChannelSchema } from '../controlChannel.schemas';
import { ControlChannelForm, ControlChannelRequestDto } from '../controlChannel.types';
import * as api from '../controlChannel.api';
import { useStationName } from '../../Stations';
import { ControlChannelSetPointType } from '../controlChannel.enums';
import { responseFormConverter, formRequestConverter } from '../controlChannel.converters';
import { deleteEmptyFields } from '../../../tools';
import { useMeasurements } from '../../Measurements/measurements.hooks';
import { useAxes } from '../../Axes/axes.hooks';
import { useControlChannel } from '../controlChannel.hooks';
import { useDefaultTimeUnitName } from '../../Units';
import { ControlAlgorithmsBase } from '../../ControlAlgorithms';
import { analogOutChannels } from '../../StationChannels/stationChannels.const';

const defaultValues: ControlChannelForm = {
	id: undefined,
	isRateLimitEnabled: false,
	stationId: '',
	name: '',
	axisId: '',
	feedbackId: '',
	setPoint: String(ControlChannelSetPointType.WAVEFORM),
	rateLimit: undefined,
	controlAlgorithmsForm: {
		isDitherEnabled: false,
		baseAlgorithm: ControlAlgorithmsBase.PID,
		additionalAlgorithms: []
	}
};

const ControlChannelContainer: React.FC = () => {
	const { controlChannelId, stationId } = useParams();
	const navigate = useNavigate();
	const form = useForm<ControlChannelForm>({
		defaultValues: { ...defaultValues, stationId },
		mode: 'onBlur',
		resolver: yupResolver(controlChannelSchema)
	});
	const queryClient = useQueryClient();
	const { handleSubmit, reset, watch, setValue } = form;
	const isCreate = !controlChannelId;

	const { controlChannel } = useControlChannel(controlChannelId);
	const currentChannel = useMemo(() => controlChannel && responseFormConverter(controlChannel), [controlChannel]);
	const defaultTimeUnitName = useDefaultTimeUnitName();

	const { axes } = useAxes(stationId);
	const { measurements } = useMeasurements(stationId);

	const createMutation = useMutation({
		mutationFn: (controlChannel: ControlChannelRequestDto) => api.createControlChannel(controlChannel),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['stations_full', { stationId }] });
			handleBack();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { controlChannel: ControlChannelRequestDto; controlChannelId: string }) =>
			api.updateControlChannel(data.controlChannel, data.controlChannelId),

		onSuccess: (data) => {
			queryClient.setQueryData(['controlChannel', { id: controlChannelId }], data);
			queryClient.invalidateQueries({ queryKey: ['stations_full', { stationId }] });
			handleBack();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/control-channels`);
	}, [navigate, stationId]);

	const handleReset = useCallback(() => {
		if (currentChannel) {
			reset({ ...currentChannel, stationId }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultValues, stationId }, { keepDirty: true, keepTouched: true });
		}
	}, [currentChannel, reset, stationId]);

	const handleSave = handleSubmit((data) => {
		const filteredData = deleteEmptyFields<ControlChannelRequestDto>(formRequestConverter(data));
		if (controlChannelId) {
			updateMutation.mutate({ controlChannel: filteredData, controlChannelId: controlChannelId });
		} else {
			createMutation.mutate(filteredData);
		}
	});

	//init current system for edit
	useEffect(() => {
		if (currentChannel) {
			handleReset();
		}
	}, [currentChannel, handleReset]);

	const { stationName } = useStationName(stationId);

	const axisId = watch('axisId');
	const setPoint = watch('setPoint');
	const feedbackId = watch('feedbackId');
	const isAxisBasedOnAnalogOut = useMemo(() => {
		const selectedAxis = axes?.find((axis) => axis.id === axisId);
		const type = selectedAxis?.actuator?.stationChannel?.type;
		return type !== undefined && analogOutChannels.includes(type);
	}, [axisId, axes]);

	const isOpenLoopAvailable = useMemo(
		() => !(setPoint !== String(ControlChannelSetPointType.WAVEFORM) || feedbackId) && isAxisBasedOnAnalogOut,
		[feedbackId, isAxisBasedOnAnalogOut, setPoint]
	);

	useEffect(() => {
		setValue('isAxisBasedOnAnalogOut', isAxisBasedOnAnalogOut);
	}, [isAxisBasedOnAnalogOut, setValue]);

	return (
		<ControlChannel
			stationName={stationName}
			isCreate={isCreate}
			isLoading={createMutation.isPending || updateMutation.isPending}
			form={form}
			axes={axes}
			measurements={measurements}
			stationId={stationId}
			defaultTimeUnitName={defaultTimeUnitName}
			onBack={handleBack}
			onSave={handleSave}
			isOpenLoopAvailable={isOpenLoopAvailable}
			isAxisBasedOnAnalogOut={isAxisBasedOnAnalogOut}
		/>
	);
};

export default React.memo(ControlChannelContainer);
