import React from 'react';
import TestSequencerRulesModal from '../TestSequencerRulesModal';
import TestSequencerRulesContainer from '../../containers/TestSequencerRulesContainer';

interface ITestSequencerRulesProps {
	testId?: string;
}

const TestSequencerRules: React.FC<ITestSequencerRulesProps> = ({ testId }) => {
	return (
		<TestSequencerRulesModal>
			<TestSequencerRulesContainer testId={testId} />
		</TestSequencerRulesModal>
	);
};

export default React.memo(TestSequencerRules);
