import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import CreateReadoutModal from '../components/CrearteReadoutModal/CreateReadoutModal';
import ReadoutTable from '../components/ReadoutTable';
import { readoutListItemConverter } from '../testDashboardConfiguration.converter';
import { DashboardConfigurationActions, Modals } from '../testDashboardConfiguration.enums';
import { useReadouts } from '../testDashboardConfiguration.hooks';
import { ReadoutListItem } from '../testDashboardConfiguration.types';
import { useTest } from '../../Tests/tests.hooks';
import { Modal } from '@tactun/ui';

const ReadoutTableContainer: FC = () => {
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const [entityForUpdate, setEntityForUpdate] = useState('');
	const { testId } = useParams();
	const { testDto } = useTest(testId);
	const { updateReadouts, readouts, isReadoutsLoading } = useReadouts<ReadoutListItem>(
		testId,
		readoutListItemConverter
	);

	const onSaveSuccess = () => {
		handleCloseReadoutModal();
		updateReadouts();
	};

	const handleDeleted = () => updateReadouts();
	const handleDeleteCancel = () => setEntityForDelete(undefined);

	const handleAction = (action: DashboardConfigurationActions, data?: ReadoutListItem) => {
		switch (action) {
			case DashboardConfigurationActions.CREATE: {
				Modal.show(Modals.CREATE_READOUT_MODAL);
				break;
			}
			case DashboardConfigurationActions.EDIT: {
				if (data) {
					setEntityForUpdate(data.id);
					Modal.show(Modals.CREATE_READOUT_MODAL);
				}
				break;
			}
			case DashboardConfigurationActions.DELETE: {
				setEntityForDelete(data as IEntity);
				break;
			}
		}
	};

	const handleCloseReadoutModal = () => {
		Modal.hide(Modals.CREATE_READOUT_MODAL);
		setEntityForUpdate('');
	};

	const onSaveError = (error: Error) => toast.error(error.message);

	return (
		<>
			<ReadoutTable onAction={handleAction} isLoading={isReadoutsLoading} data={readouts} />
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.READOUT}
				entity={entityForDelete}
			/>
			{testId && testDto && (
				<CreateReadoutModal
					onSaveSuccess={onSaveSuccess}
					onSaveError={onSaveError}
					testId={testId}
					stationId={testDto.stationId}
					readoutId={entityForUpdate}
					handleClose={handleCloseReadoutModal}
				/>
			)}
		</>
	);
};

export default ReadoutTableContainer;
