import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
	Form,
	Dropdown,
	Button,
	InputNumber,
	InputText,
	DataTable,
	LineChart,
	LineChartPoint,
	Modal,
	Switch,
	GroupInputFrame,
	useIsChanged
} from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller, useFieldArray, useForm } from 'react-hook-form';
import { CalibrationFormType, CalibrationInputState, PiecewiceCalibrationPair } from '../../calibrations.types';
import { CalibrationsTypes } from '../../calibrations.enums';
import CalibrationInput from './CalibrationInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { calibrationInputSchema } from '../../calibrations.schemas';
import { IListItemNumber } from '../../../../types';
import { SensorResponseDto } from '../../../Sensors/sensors.types';
import { useTedsId } from '../../calibrations.grpc';
import styles from './CalibrationForm.module.scss';
import { typesWithInversePolarity } from '../../calibrations.const';

const defaultCalibrationInputValues: Partial<CalibrationInputState> = {
	isRealTime: true,
	uncalibratedData: undefined,
	calibratedData: undefined
};

interface CalibrationFormProps {
	types: IListItemNumber[];
	sensor?: SensorResponseDto;
	form: UseFormReturn<CalibrationFormType>;
	onSave: () => void;
	onCalibrationModeChange: (isRealTime: boolean) => void;
	calibrationData: number;
	isSignalConditioning: boolean;
	isInWidget: boolean;
}

const CalibrationForm: React.FC<CalibrationFormProps> = ({
	types,
	sensor,
	form,
	onSave,
	onCalibrationModeChange,
	calibrationData,
	isSignalConditioning,
	isInWidget
}) => {
	const { t } = useTranslation('calibrations');
	const formId = useRef('calibrationForm');

	const {
		control,
		formState: { errors },
		watch,
		trigger,
		setValue,
		register
	} = form;

	const { fields } = useFieldArray({ name: 'data', keyName: '', control });

	const inputForm = useForm<CalibrationInputState>({
		defaultValues: defaultCalibrationInputValues,
		mode: 'onBlur',
		resolver: yupResolver(calibrationInputSchema)
	});

	const isManualTedsIdEnabled = watch('isManualTedsIdEnabled');
	const tedsId = useTedsId(sensor, isManualTedsIdEnabled === false);
	const type = watch('type');
	const data = watch('data');

	useEffect(() => {
		if (!isManualTedsIdEnabled) {
			setValue('tedsId', tedsId || '');
		}
	}, [isManualTedsIdEnabled, setValue, tedsId]);

	const isRealTime = inputForm.watch('isRealTime');
	const isRealTimeChanged = useIsChanged(isRealTime);
	const isTypeChanged = useIsChanged(type);
	useEffect(() => {
		if (isRealTimeChanged || isTypeChanged) {
			onCalibrationModeChange(isRealTime && type === CalibrationsTypes.PIECEWISE);
		}
	}, [isRealTime, isRealTimeChanged, isTypeChanged, onCalibrationModeChange, type]);

	useEffect(() => {
		if (!isRealTime) {
			setValue('isShuntCalibrationEnabled', false, { shouldValidate: false, shouldDirty: false, shouldTouch: false });
		}
	}, [isRealTime, setValue]);

	const chartDataset = useMemo<LineChartPoint[]>(() => {
		const unsortedData = fields.map((field) => ({
			x: field.uncalibratedData,
			y: field.calibratedData
		}));

		const sortedData = unsortedData.sort((left, right) => (left.x < right.x ? -1 : 1));

		return sortedData;
	}, [fields]);

	const [errorMessage, setErrorMessage] = useState('');

	const setError = useCallback(
		(error: 'is_equal' | 'not_monotonic') => {
			if (error === 'is_equal') {
				setErrorMessage(t('Error. One of the values already exists') || '');
			} else if (error === 'not_monotonic') {
				setErrorMessage(t('The entered data pair breaks the monotony of the existing pairs') || '');
			}
		},
		[t, setErrorMessage]
	);
	const toggleModal = () => {
		setErrorMessage('');
	};

	const onSubmitNewPoint = inputForm.handleSubmit((newPoint) => {
		addPoint(newPoint as PiecewiceCalibrationPair);
	});

	const resetInput = useCallback(() => {
		inputForm.reset(
			{
				isRealTime: inputForm.getValues('isRealTime'),
				uncalibratedData: undefined,
				calibratedData: undefined
			},
			{ keepValues: true }
		);
	}, [inputForm]);

	const addPoint = useCallback(
		(newPoint: PiecewiceCalibrationPair) => {
			const oldData = data ? data : [];
			const newPoints = [...oldData, newPoint];

			const uncalibratedData = new Set(newPoints.map(({ uncalibratedData }) => uncalibratedData));
			const calibratedData = new Set(newPoints.map(({ calibratedData }) => calibratedData));
			if (uncalibratedData.size !== newPoints.length || calibratedData.size !== newPoints.length) {
				return setError('is_equal');
			}

			newPoints.sort((a, b) => a.uncalibratedData - b.uncalibratedData);
			const isAscending = newPoints.every(
				({ calibratedData }, i) => i === 0 || calibratedData > newPoints[i - 1].calibratedData
			);
			const isDescending = newPoints.every(
				({ calibratedData }, i) => i === 0 || calibratedData < newPoints[i - 1].calibratedData
			);
			if (!isAscending && !isDescending) {
				return setError('not_monotonic');
			}

			setValue('data', newPoints);
			if (newPoints.length > 1) trigger('data');
			resetInput();
		},
		[data, setValue, setError, resetInput, trigger]
	);

	const deleteAll = useCallback(() => {
		setValue('data', []);
	}, [setValue]);

	return (
		<>
			<Form className={styles.container} id={formId.current} onSubmit={onSave}>
				<input type="hidden" {...register('sensorId')} />
				<input type="hidden" {...register('id')} />
				<Controller
					name="type"
					control={control}
					render={({ field }) => (
						<Dropdown
							{...field}
							options={types}
							label={t('Type*')}
							error={errors.type?.message}
							data-testid="type"
							disabled={sensor === undefined}
						/>
					)}
				/>
				{typesWithInversePolarity.some((t) => t === sensor?.type) ? (
					<Controller
						name="isInversePolarity"
						control={control}
						render={({ field }) => (
							<Switch
								label={t('Inverse Polarity')}
								inputId={field.name}
								data-testid="isInversePolarity"
								onChange={(e) => field.onChange(e.value)}
								className={styles.switchCustomForm}
								checked={!!field.value}
							/>
						)}
					/>
				) : (
					<div />
				)}
				{type === CalibrationsTypes.LINEAR && (
					<>
						<Controller
							name="slope"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Sensitivity*')}
									// Condition "type === CalibrationsTypes.LINEAR" guarantees the field
									// @ts-ignore
									error={errors.slope?.message}
									data-testid="slope"
								/>
							)}
						/>
						<Controller
							name="offset"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Offset*')}
									// Condition "type === CalibrationsTypes.LINEAR" guarantees the field
									// @ts-ignore
									error={errors.offset?.message}
									data-testid="offset"
								/>
							)}
						/>
						{isSignalConditioning && (
							<div className={styles.flexRow}>
								<div className="flexLabel">{t('TEDS ID manual input')}</div>
								<GroupInputFrame>
									<Controller
										name="isManualTedsIdEnabled"
										control={control}
										render={({ field }) => (
											<Switch
												className={styles.switchCustom}
												onChange={(event) => field.onChange(event.value)}
												data-testid="tedsIdSwitch"
												checked={!!field.value}
											/>
										)}
									/>
									<Controller
										name="tedsId"
										control={control}
										render={({ field }) => (
											<InputText
												data-testid="tedsId"
												{...field}
												error={errors.tedsId?.message}
												disabled={!isManualTedsIdEnabled}
											/>
										)}
									/>
								</GroupInputFrame>
							</div>
						)}
					</>
				)}

				{type === CalibrationsTypes.PIECEWISE && (
					<CalibrationInput onSubmit={onSubmitNewPoint} calibrationData={calibrationData} form={inputForm} />
				)}

				{type === CalibrationsTypes.PIECEWISE && (
					<div className={isInWidget ? styles.mainContainerModal : styles.mainContainer}>
						<div className={styles.tableContainer}>
							<DataTable dataKey="id" value={fields} showGridlines columnResizeMode="fit">
								<DataTable.Column field="uncalibratedData" header={t('Uncalibrated sensor data')} />
								<DataTable.Column field="calibratedData" header={t('Calibrated sensor data')} />
								<DataTable.Column
									body={(rowData, { rowIndex }) => (
										<Button
											icon="t-icon-delete"
											color="secondary"
											type="button"
											variant="text"
											onClick={() => {
												setValue('data', [...fields.slice(0, rowIndex), ...fields.slice(rowIndex + 1)]);
											}}
										/>
									)}
									exportable={false}
									align="right"
									headerStyle={{ width: '3em' }}
								/>
							</DataTable>
							{errors?.data && <span>{errors?.data?.message}</span>}
							<div className={styles.deleteAll} onClick={deleteAll}>
								Delete All
							</div>
							{type === CalibrationsTypes.PIECEWISE && (
								<div className={styles.container}>
									{isSignalConditioning ? (
										<>
											<div className={styles.flexRow}>
												<div className="flexLabel">{t('Shunt calibration')}</div>
												<Controller
													name="isShuntCalibrationEnabled"
													control={control}
													render={({ field }) => (
														<Switch
															className="switchLeft"
															onChange={(event) => field.onChange(event.value)}
															checked={!!field.value}
															disabled={!isRealTime}
															data-testid="shuntCalibration"
														/>
													)}
												/>
											</div>
											<div className={styles.flexRow}>
												<div className="flexLabel">{t('TEDS ID manual input')}</div>
												<GroupInputFrame>
													<Controller
														name="isManualTedsIdEnabled"
														control={control}
														render={({ field }) => (
															<Switch
																className={styles.switchCustom}
																onChange={(event) => field.onChange(event.value)}
																data-testid="tedsIdSwitch"
																checked={!!field.value}
															/>
														)}
													/>
													<Controller
														name="tedsId"
														control={control}
														render={({ field }) => (
															<InputText
																data-testid="tedsId"
																{...field}
																error={errors.tedsId?.message}
																disabled={!isManualTedsIdEnabled}
															/>
														)}
													/>
												</GroupInputFrame>
											</div>
										</>
									) : (
										<div />
									)}
								</div>
							)}
						</div>
						<div className={styles.calibrationChart}>
							<LineChart
								xLabel={t('Uncalibrated Data')}
								yLabel={t('Calibrated Data')}
								dataset={chartDataset}
								data-testid="chart"
							/>
						</div>
					</div>
				)}
			</Form>
			<Modal isOpen={!!errorMessage} onClose={toggleModal} id="my-modal">
				<Modal.Header>{t('Error')}</Modal.Header>
				<Modal.Content>{errorMessage}</Modal.Content>
				<Modal.Footer>
					<Button onClick={toggleModal} color="secondary" variant="contained">
						{t('Ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default React.memo(CalibrationForm);
