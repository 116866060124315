import { useEffect, useMemo } from 'react';
import {
	PageLayout,
	Form,
	InputText,
	InputNumber,
	Dropdown,
	Button,
	BottomBar,
	InfoBadge,
	InfoBadgeIcons,
	Switch,
	DropdownOptionString,
	GroupInputFrame
} from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller, FormProvider } from 'react-hook-form';
import { ControlChannelForm } from '../../controlChannel.types';
import styles from './ControlChannel.module.scss';
import { IListItem } from '../../../../types';
import { ControlChannelSetPointType } from '../../controlChannel.enums';
import { IAxisResponseDto } from '../../../Axes';
import { MeasurementResponseDto } from '../../../Measurements';
import ControlAlgorithmsContainer from '../../../ControlAlgorithms';
import { ControlAlgorithmsBase } from '../../../ControlAlgorithms';
import { currentBasedAnalogOutChannels } from '../../../StationChannels/stationChannels.const';

interface IControlChannelProps {
	stationId?: string;
	isCreate: boolean;
	isLoading: boolean;
	form: UseFormReturn<ControlChannelForm>;
	defaultTimeUnitName: string;
	axes?: IAxisResponseDto[];
	measurements?: MeasurementResponseDto[];
	onBack: () => void;
	onSave: () => void;
	stationName: string;
	isAxisBasedOnAnalogOut: boolean;
	isOpenLoopAvailable: boolean;
}

const ControlChannel: React.FC<IControlChannelProps> = ({
	isCreate,
	isLoading,
	stationId,
	form,
	defaultTimeUnitName,
	axes = [],
	measurements = [],
	onBack,
	onSave,
	stationName,
	isAxisBasedOnAnalogOut,
	isOpenLoopAvailable
}) => {
	const { t } = useTranslation('controlChannel');
	const {
		control,
		formState: { errors },
		register,
		watch,
		setValue
	} = form;

	const isRateLimitEnabled = watch('isRateLimitEnabled');
	const setPoint = watch('setPoint');
	const feedbackId = watch('feedbackId');
	const id = watch('id');
	const axisId = watch('axisId');
	const controlAlg = watch('controlAlgorithmsForm.baseAlgorithm');
	const title = useMemo<string>(() => {
		return isCreate ? t('Create Control') : t('Edit Control');
	}, [isCreate, t]);
	const axisList = useMemo<IListItem[]>(() => axes.map((axis) => ({ label: axis.name, value: axis.id })), [axes]);
	const measurementsList = useMemo<IListItem[]>(
		() =>
			measurements.map((measurement) => ({
				label: measurement.name,
				value: measurement.id
			})),
		[measurements]
	);
	const feedbackList = useMemo<IListItem[]>(
		() => measurementsList.filter(({ value }) => value !== setPoint),
		[measurementsList, setPoint]
	);

	// Reset rateLimit section values
	useEffect(() => {
		setValue('isRateLimitEnabled', setPoint === String(ControlChannelSetPointType.WAVEFORM));
	}, [setPoint, setValue]);

	const setPointList = useMemo<DropdownOptionString[]>(() => {
		const values = [
			{
				label: t('Waveform'),
				options: [{ label: t('Waveform'), value: String(ControlChannelSetPointType.WAVEFORM) }]
			}
		];

		if (controlAlg !== ControlAlgorithmsBase.OPEN_LOOP) {
			values.push({
				label: t('Measurements'),
				options: measurementsList.filter(({ value }) => value !== feedbackId)
			});
		}

		return values;
	}, [controlAlg, feedbackId, measurementsList, t]);

	const rateLimitUnit = useMemo<string | undefined>(() => {
		if (isRateLimitEnabled) {
			const feedbackUnit = measurements.find((m) => m.id === feedbackId)?.unit.name;

			return feedbackUnit ? ` ${feedbackUnit}/${defaultTimeUnitName}` : undefined;
		}
	}, [feedbackId, isRateLimitEnabled, measurements, defaultTimeUnitName]);

	const selectedAxis = useMemo<IAxisResponseDto | undefined>(
		() => axes.find((axis) => axis.id === axisId),
		[axes, axisId]
	);

	return (
		<>
			<PageLayout info={stationName}>
				<PageLayout.Header title={title} onBack={onBack} />

				<FormProvider {...form}>
					<Form className={styles.container} onSubmit={onSave} id="controlChannelForm">
						<input type="hidden" {...register('id')} />
						<input type="hidden" {...register('stationId')} />
						<Controller
							name="name"
							control={control}
							render={({ field }) => (
								<InputText data-testid="nameId" label={t('Control Name*')} {...field} error={errors.name?.message} />
							)}
						/>
						<Controller
							name="axisId"
							control={control}
							render={({ field }) => (
								<Dropdown
									data-testid="axisId"
									{...field}
									label={t('Output*')}
									options={axisList}
									error={errors.axisId?.message}
									filter
								/>
							)}
						/>
						<Controller
							name="setPoint"
							control={control}
							render={({ field }) => (
								<Dropdown
									label={t('Setpoint*')}
									{...field}
									options={setPointList}
									error={errors.setPoint?.message}
									data-testid="setPoint"
									filter
								/>
							)}
						/>
						{setPoint === String(ControlChannelSetPointType.WAVEFORM) && (
							<div className={styles.rateContainer}>
								<Controller
									name="isRateLimitEnabled"
									control={control}
									render={({ field }) => (
										<Switch
											data-testid="isRateLimitEnabled"
											label={t('Rate Limit')}
											className={styles.switchCustomForm}
											checked={!!field.value}
											onChange={(e) => field.onChange(e)}
										/>
									)}
								/>
								{isRateLimitEnabled && (
									<GroupInputFrame>
										<Controller
											name="rateLimit"
											control={control}
											render={({ field }) => (
												<InputNumber {...field} error={errors.rateLimit?.message} data-testid="rateLimit" />
											)}
										/>
										<InputText disabled value={rateLimitUnit} />
									</GroupInputFrame>
								)}
							</div>
						)}
						{controlAlg !== ControlAlgorithmsBase.OPEN_LOOP && (
							<Controller
								name="feedbackId"
								control={control}
								render={({ field }) => (
									<Dropdown
										label={t('Feedback*')}
										{...field}
										options={feedbackList}
										error={errors.feedbackId?.message}
										data-testid="feedbackId"
										filter
									/>
								)}
							/>
						)}
						{setPoint === String(ControlChannelSetPointType.WAVEFORM) &&
							controlAlg !== ControlAlgorithmsBase.OPEN_LOOP && <div />}
						<ControlAlgorithmsContainer
							formPrefix="controlAlgorithmsForm"
							stationId={stationId}
							ccId={id}
							isControlWithCurrent={currentBasedAnalogOutChannels.some(
								(t) => t === selectedAxis?.actuator.stationChannel?.type
							)}
							isAxisBasedOnAnalogOut={isAxisBasedOnAnalogOut}
							isOpenLoopAvailable={isOpenLoopAvailable}
							errors={errors.controlAlgorithmsForm}
						/>
					</Form>
				</FormProvider>
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button data-testid="cancelBtn" label={t('Cancel')} variant="contained" color="success" onClick={onBack} />
				<Button
					data-testid="saveBtn"
					label={t('Save')}
					variant="contained"
					color="secondary"
					type="submit"
					form="controlChannelForm"
				/>
			</BottomBar>
		</>
	);
};

export default ControlChannel;
