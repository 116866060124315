import { FC, useEffect } from 'react';
import { UseFormReturn, Controller } from 'react-hook-form';
import { Form, Dropdown, InputNumber, Checkbox } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import DataChannel, { DataChannelType } from '../../../DataChannel';
import { useMeasurements } from '../../../Measurements';
import { useAxes } from '../../../Axes/axes.hooks';
import { ReadoutTypeOptions } from '../../../ReadoutWidget/readout.constants';
import { ReadoutFormType } from '../../../ReadoutWidget/readout.types';
import styles from './ReadoutForm.module.scss';

interface ReadoutFormProps {
	form: UseFormReturn<ReadoutFormType>;
	isEdit?: boolean;
	stationId?: string;
	onSave: () => void;
	isType?: boolean;
}

const formId = 'readoutForm';

const ReadoutForm: FC<ReadoutFormProps> = ({ form, isEdit = false, onSave, stationId, isType }) => {
	const {
		register,
		control,
		watch,
		setValue,
		formState: { errors }
	} = form;
	const { t } = useTranslation('dashboardConfiguration');
	const { measurements = [] } = useMeasurements(stationId);
	const { axes = [] } = useAxes(stationId);
	const dataChannel = watch('dataChannel');

	useEffect(() => {
		if (dataChannel?.[0]?.type === DataChannelType.CYCLE) {
			setValue('min', false);
			setValue('max', false);
		}
	}, [dataChannel, setValue]);

	return (
		<Form className={styles.container} onSubmit={onSave} id={formId}>
			{isEdit && <input type="hidden" {...register('id')} />}
			<input type="hidden" {...register('testId')} />
			<input type="hidden" {...register('stationId')} />
			<div className={styles.section1}>
				<Controller
					name="readoutType"
					control={control}
					render={({ field }) => (
						<Dropdown
							{...field}
							label={t('Readout Type*')}
							options={ReadoutTypeOptions}
							error={errors.readoutType?.message}
						/>
					)}
				/>
				{!isType && (
					<Controller
						name="dataChannel"
						control={control}
						render={({ field }) => (
							<DataChannel
								{...field}
								label={t('Data channel*')}
								measurements={measurements}
								axes={axes}
								error={errors.dataChannel?.message}
								removeTime
							/>
						)}
					/>
				)}
				<Controller
					name="precision"
					control={control}
					render={({ field }) => (
						<InputNumber
							{...field}
							label={t('Precision Digits')}
							error={errors.precision?.message}
							data-testid="precisionDigits"
							isInteger
						/>
					)}
				/>
			</div>
			<div className={styles.section2}>
				<h3 className={styles.sectionTitle}>{t('Display readout values')}</h3>
				<div className={styles.checkboxes}>
					<div className={styles.checkboxWrapper}>
						<Controller
							name="min"
							control={control}
							render={({ field }) => (
								<Checkbox
									className={styles.checkbox}
									checked={!!field.value}
									{...field}
									disabled={dataChannel?.[0]?.type === DataChannelType.CYCLE}
								/>
							)}
						/>
						<span className={styles.checkboxLabel}>{t('Minimum value')}</span>
					</div>
					<div className={styles.checkboxWrapper}>
						<Controller
							name="rate"
							control={control}
							render={({ field }) => <Checkbox className={styles.checkbox} checked={!!field.value} {...field} />}
						/>
						<span className={styles.checkboxLabel}>{t('Rate')}</span>
					</div>
					<div className={styles.checkboxWrapper}>
						<Controller
							name="max"
							control={control}
							render={({ field }) => (
								<Checkbox
									className={styles.checkbox}
									checked={!!field.value}
									{...field}
									disabled={dataChannel?.[0]?.type === DataChannelType.CYCLE}
								/>
							)}
						/>
						<span className={styles.checkboxLabel}>{t('Maximum value')}</span>
					</div>
					<div className={styles.checkboxWrapper}>
						<Controller
							name="tare"
							control={control}
							render={({ field }) => <Checkbox className={styles.checkbox} checked={!!field.value} {...field} />}
						/>
						<span className={styles.checkboxLabel}>{t('Tare and Untare')}</span>
					</div>
				</div>
			</div>
		</Form>
	);
};

export default ReadoutForm;
