import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { StationRuleResponseDto } from '../Rules';
import * as api from './stationRules.api';

export const useStationRule = (stationRuleId?: string) => {
	const { data: stationRuleDto } = useQuery({
		queryKey: ['stationRule', stationRuleId],
		queryFn: () => api.getStationRule(stationRuleId || ''),
		enabled: !!stationRuleId
	});

	const queryClient = useQueryClient();

	const pullStationRule = () => {
		queryClient.invalidateQueries({ queryKey: ['stationRule', stationRuleId] });
	};

	return {
		stationRuleDto,
		pullStationRule
	};
};

export const useStationStationRules = (stationId?: string) => {
	const stationStationRulesQueryKey = useMemo(() => [{ stationId }], [stationId]);

	const { data: stationStationRulesResponse, isLoading: isStationStationRulesLoading } = useQuery({
		queryKey: stationStationRulesQueryKey,
		queryFn: () => api.getStationRules(stationId as string),
		enabled: !!stationId
	});

	const queryClient = useQueryClient();

	const pullStationStationRule = () => {
		queryClient.invalidateQueries({ queryKey: stationStationRulesQueryKey });
	};

	const updateRules = (newRules: StationRuleResponseDto[]) => {
		queryClient.setQueryData(stationStationRulesQueryKey, newRules);
		queryClient.invalidateQueries({ queryKey: ['stations_full', { stationId }] });
	};

	const updateRuleStatus = (updatedRule: StationRuleResponseDto) => {
		const newRules =
			stationStationRulesResponse?.map((rule) => (rule.uuid === updatedRule.uuid ? updatedRule : rule)) || [];
		updateRules(newRules);
	};

	return {
		stationStationRulesResponse,
		isStationStationRulesLoading,
		pullStationStationRule,
		stationStationRulesQueryKey,
		updateRules,
		updateRuleStatus
	};
};
