import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DashboardTypeFormType, IDashboardTypeResponse } from '../dashboardTypes.types';
import { dashboardTypeFormSchema } from '../dashboardTypes.schemas';
import DashboardTypeForm from '../components/DashboardTypeForm';
import {
	dashboardGeneralInfoFormId,
	dashboardTypeDefaultValues,
	generalInfoCreatePath,
	generalInfoPath
} from '../dashboardTypes.const';
import { useEnumList } from '../../../hooks';
import { DashboardDisplayingMode } from '../dashboardTypes.enums';
import { useDashboardType, useSaveDashboardType } from '../dashboardTypes.hooks';
import { dashboardTypeResponseToForm } from '../dashboardTypes.converters';
import { IStep, PageLayout, normalizePath, useWizard } from '@tactun/ui';
import { tInfo } from '../../../tools/logger';

const DashboardTypeContainer: React.FC = () => {
	const { t } = useTranslation('dashboardTypes');
	const { dashboardTypeId } = useParams();
	const modes = useEnumList(DashboardDisplayingMode, 'dashboardTypes');
	const form = useForm<DashboardTypeFormType>({
		defaultValues: { ...dashboardTypeDefaultValues },
		mode: 'onBlur',
		resolver: yupResolver(dashboardTypeFormSchema)
	});
	const { beforeNextStep, activeStep, steps, goToStep } = useWizard();

	const handleSaveSuccess = useCallback(
		(dashboardType: IDashboardTypeResponse) => {
			let nextStep = steps[activeStep + 1];
			nextStep = { ...nextStep };
			nextStep.path = normalizePath(nextStep.path, { dashboardTypeId: dashboardType.uuid });
			goToStep(nextStep);
		},
		[activeStep, goToStep, steps]
	);

	const { dashboardType: currentDashboardType } = useDashboardType(dashboardTypeId);
	const { save } = useSaveDashboardType(dashboardTypeId, handleSaveSuccess);

	const handleReset = useCallback(() => {
		if (currentDashboardType) {
			form.reset({ ...dashboardTypeResponseToForm(currentDashboardType) }, { keepDirty: true, keepTouched: true });
		} else {
			form.reset({ ...dashboardTypeDefaultValues }, { keepDirty: true, keepTouched: true });
		}
	}, [currentDashboardType, form]);

	// Setup data for edit
	useEffect(() => {
		if (currentDashboardType) {
			handleReset();
		}
	}, [currentDashboardType, handleReset]);

	beforeNextStep((step: IStep) => {
		if (step.path === generalInfoPath || step.path === generalInfoCreatePath) {
			return new Promise<boolean>((resolve, reject) => {
				form.handleSubmit(
					async (data) => {
						save(data);
						resolve(false);
					},
					() => {
						tInfo('Dashboard type validation failed');
						resolve(false);
					}
				)();
			});
		}
		return Promise.resolve(true);
	});

	return (
		<PageLayout info={t('Create a Dashboard type')}>
			<PageLayout.Header title={t('Dashboard Types')} />
			<DashboardTypeForm form={form} formId={dashboardGeneralInfoFormId} modes={modes} />
		</PageLayout>
	);
};

export default React.memo(DashboardTypeContainer);
