import { Loop } from '@tactun/grpc-client';
import { Converter } from '../../types';
import { TestLoopRunForCalculationDto } from './testLoops.enums';
import {
	TestLoopFormType,
	TestLoopListItem,
	TestLoopRequestDto,
	TestLoopResponseDto,
	TestLoopValidationRequestDto
} from './testLoops.types';

export const testLoopFormRequestConverter: Converter<TestLoopFormType, TestLoopRequestDto> = (input) => {
	return {
		startStageId: input.startStageId,
		endStageId: input.endStageId,
		iter: input.iter,
		runCalcFor: input.runCalcFor,
		testId: input.testId
	};
};

export const testLoopFormValidationRequestConverter: Converter<TestLoopFormType, TestLoopValidationRequestDto> = (
	input
) => {
	return {
		id: input?.id || undefined,
		startStageId: input.startStageId,
		endStageId: input.endStageId
	};
};

export const testLoopResponseFormConverter: Converter<TestLoopResponseDto, TestLoopFormType> = (input) => {
	return {
		id: input.id,
		testId: input.testId,
		startStageId: input.startStage.id,
		endStageId: input.endStage.id,
		iter: input.iter,
		runCalcFor: input.runCalcFor as TestLoopRunForCalculationDto
	};
};

export const testLoopResponseListItemConverter: Converter<TestLoopResponseDto, TestLoopListItem> = (input) => {
	return {
		id: input.id,
		loopStart: input.startStage.name,
		loopEnd: input.endStage.name,
		numberOfIterations: input.iter,
		runCalculationFor: TestLoopRunForCalculationDto[input.runCalcFor]
	};
};

export const testLoopResponseDtoToLoopGRPCConverter: Converter<TestLoopResponseDto, Loop> = (resp) => {
	const loop = new Loop();
	loop.setStartIdx(resp.startStage.seq);
	loop.setEndIdx(resp.endStage.seq);
	loop.setRpt(resp.iter);

	return loop;
};

export const testLoopResponseDtoListToLoopsGRPCConverter: Converter<TestLoopResponseDto[], Loop[]> = (resp) => {
	return resp.map((r) => testLoopResponseDtoToLoopGRPCConverter(r));
};
