import React from 'react';
import { EAxisActions } from '../../controlAxis.enums';
import styles from './AxisActionButton.module.scss';

interface IActionButtonProps {
	action: EAxisActions;
	handleActionStart: (e: React.MouseEvent<HTMLButtonElement>) => void;
	icon: string;
	disabled: boolean;
}

const AxisActionButton: React.FC<IActionButtonProps> = ({ action, handleActionStart, icon, disabled }) => {
	return (
		<button
			className={`${styles.actionButton} ${icon}`}
			onMouseDown={handleActionStart}
			data-action={action}
			disabled={disabled}
		/>
	);
};

export default React.memo(AxisActionButton);
