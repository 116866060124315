export interface IHeaderMenuItemProps {
	target: string;
	label: string;
}

// the component used for receiving props and converting them to "Primereact" "Menu Model" object
// that kind of architecture is defined to make possible migration to our own components in the future and provide the ability to use components in a declarative manner
const HeaderMenuItem: React.FC<IHeaderMenuItemProps> = () => null;

export default HeaderMenuItem;
