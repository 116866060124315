import { ChartXY, PointLineAreaSeries } from '@arction/lcjs';
import { create } from 'zustand';
import { RealTimeLineGraphState } from './realTimeLineGraph.types';

export const useCharts = create<RealTimeLineGraphState>((set, get) => ({
	graphs: new Map<string, ChartXY>(),
	series: new Map<string, Map<string, PointLineAreaSeries>>(),
	pausedGraphs: new Set<string>(),
	initGraph: (graphId: string, graph: ChartXY) => {
		set((state) => {
			const graphCopy = new Map(state.graphs);
			graphCopy.set(graphId, graph);

			const seriesCopy = new Map(state.series);
			seriesCopy.set(graphId, new Map<string, PointLineAreaSeries>());

			return { graphs: graphCopy, series: seriesCopy };
		});
	},
	deleteGraph: (chartId: string) => {
		set((state) => {
			const seriesCopy = new Map(state.series);
			seriesCopy.get(chartId)?.forEach((series) => series.dispose());
			seriesCopy.delete(chartId);

			const graphsCopy = new Map(state.graphs);
			graphsCopy.delete(chartId);

			return { graphs: graphsCopy, series: seriesCopy };
		});
	},
	addSeries: (graphId: string, seriesId: string, series: PointLineAreaSeries) => {
		set((state) => {
			const graph = state.graphs.get(graphId);

			if (!graph) throw Error('Chart not initialized');

			const seriesCopy = new Map(state.series);
			seriesCopy.get(graphId)?.set(seriesId, series);
			return { series: seriesCopy };
		});
	},
	removeSeries: (graphId: string, seriesId: string) => {
		set((state) => {
			const chart = state.graphs.get(graphId);

			if (!chart) throw Error('Chart not initialized');

			const seriesCopy = new Map(state.series);
			seriesCopy.get(graphId)?.get(seriesId)?.dispose();
			seriesCopy.get(graphId)?.delete(seriesId);

			return { series: seriesCopy };
		});
	},
	getSerries: (graphId: string, id: string) => get().series.get(graphId)?.get(id),
	getAllSerries: (graphId: string) => {
		const series = get().series.get(graphId);
		return series ? Array.from(series.values()) : undefined;
	},
	getAllSerriesWithIds: (graphId: string) => {
		return get().series.get(graphId);
	},
	pauseGraph: (graphId: string) => {
		set((state) => {
			const newPaused = new Set(state.pausedGraphs);
			newPaused.add(graphId);

			return { pausedGraphs: newPaused };
		});
	},
	resumeGraph: (graphId: string) => {
		set((state) => {
			const newPaused = new Set(state.pausedGraphs);
			newPaused.delete(graphId);

			return { pausedGraphs: newPaused };
		});
	},
	isPaused: (graphId: string) => get().pausedGraphs.has(graphId)
}));
