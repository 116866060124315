import { FC, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Form, Dropdown } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useReadouts } from '../../../TestDashboardConfiguration/testDashboardConfiguration.hooks';
import { readoutResponseFormConverter } from '../../../TestDashboardConfiguration/testDashboardConfiguration.converter';
import { ReadoutFormType } from '../../readout.types';
import styles from './ReadoutSettingsModal.module.scss';

interface ReadoutFormProps {
	form: UseFormReturn<ReadoutFormType>;
}

const formId = 'readoutForm';

const ReadoutAddExisting: FC<ReadoutFormProps> = ({ form }) => {
	const [selectedReadout, setSelectedReadout] = useState('');
	const { testId } = useParams();
	const { readouts } = useReadouts<ReadoutFormType>(testId, readoutResponseFormConverter);
	const { reset } = form;
	const options = useMemo(
		() =>
			readouts.map(({ id, name }, i) => ({
				value: id as string,
				label: name as string
			})),
		[readouts]
	);

	const { t } = useTranslation('dashboard');

	const handleDropdownChange = (value: string | null) => {
		reset(readouts.find(({ id }) => id === value));
		if (value) {
			setSelectedReadout(value);
		}
	};

	return (
		<>
			<Form className={styles.container} id={formId}>
				<Dropdown
					name="readout"
					value={selectedReadout}
					onChange={handleDropdownChange}
					label={t('Existing Readouts *')}
					options={options}
				/>
			</Form>
		</>
	);
};

export default ReadoutAddExisting;
