import React, { useCallback, useMemo } from 'react';
import { IStep, normalizePath, Stepper, StepStatuses, useWizard } from '@tactun/ui';
import { useNavigate, useParams } from 'react-router-dom';
import WizardLayout from '../../../components/WizardLayout';
import * as paths from '../tests.const';
import { useTestStages } from '../tests.hooks';
import { useTestReportConfig } from '../../TestReportConfiguration/testReportConfiguration.hooks';
import { useTranslation } from 'react-i18next';

const TestWizardContainer: React.FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('test');
	const { testId } = useParams();
	const { steps, nextStep, previousStep, activeStep, isLastStep, isFirstStep, isLoading } = useWizard();
	const { reportConfig } = useTestReportConfig(testId);
	const { stages } = useTestStages(testId);

	const handleNext = useCallback(() => {
		nextStep();
	}, [nextStep]);

	const handlePrevious = useCallback(() => {
		previousStep();
	}, [previousStep]);

	const handleBack = useCallback(() => {
		navigate('/tests');
	}, [navigate]);

	const normalizedSteps = useMemo(() => {
		return steps.map((step) => ({ ...step, normalizedPath: normalizePath(step.path, { testId }) }));
	}, [testId, steps]);

	const getStepStatus = (step: IStep) => {
		if (!testId) {
			return step.path === paths.generalInfoPath ? StepStatuses.IN_PROGRESS : StepStatuses.EMPTY;
		}

		switch (step.path) {
			case paths.generalInfoPath:
				return StepStatuses.COMPLETED;
			case paths.configurationPath: {
				if (stages?.some((stage) => stage.axesConfigs.length > 0)) {
					return StepStatuses.COMPLETED;
				} else {
					return steps[activeStep].path === paths.configurationPath ? StepStatuses.IN_PROGRESS : StepStatuses.EMPTY;
				}
			}
			case paths.reportConfigurationPath: {
				if (!!reportConfig) {
					return StepStatuses.COMPLETED;
				} else {
					return steps[activeStep].path === paths.reportConfigurationPath
						? StepStatuses.IN_PROGRESS
						: StepStatuses.EMPTY;
				}
			}
			default:
				return StepStatuses.EMPTY;
		}
	};

	return (
		<WizardLayout
			onNext={handleNext}
			onPrevious={handlePrevious}
			onBack={handleBack}
			isSaving={isLoading}
			isPreviousDisabled={isFirstStep || isLoading}
			isNextDisabled={isLoading}
			isLastStep={isLastStep}
			backLabel={t('Back to Tests')}
		>
			<Stepper>
				{normalizedSteps?.map((step, index) => (
					<Stepper.Step
						label={step.name}
						path={step.normalizedPath}
						key={step.normalizedPath}
						disabled={!testId}
						status={getStepStatus(step)}
					/>
				))}
			</Stepper>
		</WizardLayout>
	);
};

export default React.memo(TestWizardContainer);
