import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuxiliaryDevice from '../components/AuxiliaryDeviceForm';
import { auxiliaryDeviceSchema } from '../auxiliaryDevice.schemas';
import { defaultValues, responseFormConverter } from '../auxiliaryDevice.converters';
import { useStationName } from '../../Stations/stations.hooks';
import {
	useAuxiliaryDevice,
	useAuxiliaryDeviceAxes,
	useAuxiliaryDeviceDirections,
	useAuxiliaryDeviceTypes,
	useSaveAuxiliaryDevice
} from '../auxiliaryDevice.hooks';
import { IListItemNumber } from '../../../types';
import { useDigitalInputChannels } from '../../Sensors/sensors.hooks';
import { AuxiliaryDeviceDirection, AuxiliaryDeviceType } from '../auxiliaryDevice.enums';
import { useCurrentEntityInOptions } from '../../../tools';
import CreateEditPageTemplate from '../../../components/CreateEditPageTemplate';
import { useEntitiesAsList } from '../../../hooks';

const auxiliaryDeviceFormId = 'auxiliaryDeviceFormId';

const AuxiliaryDeviceContainer: React.FC = () => {
	const { t } = useTranslation('auxiliaryDevice');
	const navigate = useNavigate();
	const { stationId, auxiliaryDeviceId } = useParams();
	const { stationName } = useStationName(stationId);

	const form = useForm({
		defaultValues: { ...defaultValues, stationId, id: auxiliaryDeviceId },
		mode: 'onBlur',
		resolver: yupResolver(auxiliaryDeviceSchema)
	});
	const { handleSubmit, watch, reset } = form;
	const axisId = watch('axisId');

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/auxiliary-devices`);
	}, [navigate, stationId]);

	const { auxiliaryDeviceDto } = useAuxiliaryDevice(auxiliaryDeviceId);
	const { save, isLoading } = useSaveAuxiliaryDevice(auxiliaryDeviceId, handleBack);

	const handleReset = useCallback(() => {
		if (auxiliaryDeviceDto) {
			reset({ ...responseFormConverter(auxiliaryDeviceDto), stationId }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultValues, stationId }, { keepDirty: true, keepTouched: true });
		}
	}, [auxiliaryDeviceDto, reset, stationId]);

	const handleSave = handleSubmit((data) => save(data));

	//init current channel for edit
	useEffect(() => {
		if (auxiliaryDeviceDto) {
			handleReset();
		}
	}, [auxiliaryDeviceDto, handleReset]);

	const devicesTypes = useAuxiliaryDeviceTypes(stationId);
	const devicesTypesOptions = useMemo<IListItemNumber[]>(() => {
		if (auxiliaryDeviceDto?.type === AuxiliaryDeviceType.PHYSICAL_E_STOP) {
			return [
				...(devicesTypes?.map((type) => ({
					label: t(AuxiliaryDeviceType[type]),
					value: type
				})) || []),
				{
					label: t(AuxiliaryDeviceType[AuxiliaryDeviceType.PHYSICAL_E_STOP]),
					value: auxiliaryDeviceDto.type
				}
			];
		} else {
			return (
				devicesTypes?.map((type) => ({
					label: t(AuxiliaryDeviceType[type]),
					value: type
				})) || []
			);
		}
	}, [auxiliaryDeviceDto?.type, devicesTypes, t]);

	const devicesDirections = useAuxiliaryDeviceDirections(stationId, axisId);
	const directions = useCurrentEntityInOptions(devicesDirections || [], auxiliaryDeviceDto?.direction);
	const devicesDirectionsOptions = useMemo<IListItemNumber[]>(
		() =>
			directions.map((direction) => ({
				label: t(AuxiliaryDeviceDirection[direction]),
				value: direction
			})) || [],
		[directions, t]
	);

	const axes = useAuxiliaryDeviceAxes(stationId, auxiliaryDeviceId);
	const axisOptions = useEntitiesAsList(axes);

	const { digitalInputChannels } = useDigitalInputChannels(stationId);
	const digitalChannelOptions = useEntitiesAsList(digitalInputChannels);

	const title = useMemo<string>(() => {
		return !auxiliaryDeviceId ? t('Create Auxiliary Device') : t('Edit Auxiliary Device');
	}, [auxiliaryDeviceId, t]);

	return (
		<CreateEditPageTemplate
			isLoading={isLoading}
			title={title}
			stationName={stationName}
			formId={auxiliaryDeviceFormId}
			onBack={handleBack}
		>
			<AuxiliaryDevice
				formId={auxiliaryDeviceFormId}
				stationChannelOptions={digitalChannelOptions}
				axisOptions={axisOptions}
				directionOptions={devicesDirectionsOptions}
				typesOptions={devicesTypesOptions}
				form={form}
				onSave={handleSave}
			/>
		</CreateEditPageTemplate>
	);
};

export default React.memo(AuxiliaryDeviceContainer);
