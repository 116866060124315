import { Modal } from '@tactun/ui';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '../../../components';
import { INamedEntity } from '../../../types';
import { createGroup, deleteGroup, getGroupsByType, ungroupByItemIds, updateGroup } from '../group.api';
import { GroupContext } from '../group.context';
import { GroupActionTypes, GroupsTypes } from '../group.enums';
import { GroupsRequestDto, GroupsResponseDto, GroupsFormType, IMenuItemGroup, UngroupRequestDto } from '../group.types';
import GroupCreateEditModal from '../GroupCreateEditModal';

export interface GroupContextProviderProps {
	children: React.ReactNode;
	type: GroupsTypes;
	onAction?: (action: GroupActionTypes, group?: INamedEntity) => void;
}

const groupCreateEditModalId = 'groupCreateEditModal';
const confirmationModalId = 'confirmationModalId';

const GroupContextProvider: React.FC<GroupContextProviderProps> = ({ children, type, onAction }) => {
	const { t } = useTranslation('groups');
	const [selected, setSelected] = useState<INamedEntity[]>([]);
	const [selectedGroup, setSelectedGroup] = useState<INamedEntity>();
	const [groupForEdit, setGroupForEdit] = useState<GroupsFormType>({ name: '', type } as GroupsFormType);
	const resetSelectionRef = useRef<() => void>();
	const queryClient = useQueryClient();

	const { data: groups = [] } = useQuery<GroupsResponseDto[], Error>({
		queryKey: ['groups', { type }],
		queryFn: () => getGroupsByType(type),
		enabled: !!type
	});

	const createMutation = useMutation({
		mutationFn: (group: GroupsRequestDto) => createGroup(group),
		onSuccess: (group) => {
			queryClient.invalidateQueries({ queryKey: ['groups', { type }] });

			if (onAction) {
				onAction(GroupActionTypes.CREATE_GROUP, group);
			}

			Modal.hide(groupCreateEditModalId);
			setGroupForEdit({ name: '', type } as GroupsFormType);
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const renameMutation = useMutation({
		mutationFn: (data: { group: GroupsRequestDto; id: string }) => updateGroup(data.group, data.id),
		onSuccess: (group) => {
			if (onAction) {
				onAction(GroupActionTypes.RENAME_GROUP, group);
			}
			queryClient.invalidateQueries({ queryKey: ['groups', { type }] });

			Modal.hide(groupCreateEditModalId);
			setGroupForEdit({ name: '', type } as GroupsFormType);
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { group: GroupsRequestDto; id: string }) => updateGroup(data.group, data.id),
		onError: (e: Error) => toast.error(e.message)
	});

	const removeMutation = useMutation({
		mutationFn: (data: UngroupRequestDto) => ungroupByItemIds(data),
		onSuccess: () => {
			if (onAction) {
				onAction(GroupActionTypes.REMOVE_FROM_GROUP, selectedGroup);
			}
			resetSelectionRef.current?.();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const deleteMutation = useMutation({
		mutationFn: (id: string) => deleteGroup(id),
		onSuccess: (group) => {
			if (onAction) {
				onAction(GroupActionTypes.DELETE_GROUP, selectedGroup);
			}
			setSelectedGroup(undefined);

			queryClient.invalidateQueries({ queryKey: ['groups', { type }] });
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const selectGroup = useCallback((group?: INamedEntity) => {
		setSelectedGroup(group);
		resetSelectionRef.current?.();
	}, []);

	const rename = useCallback(() => {
		const group = groups.find((g) => g.id === selectedGroup?.id);
		setGroupForEdit(group as GroupsFormType);

		Modal.show(groupCreateEditModalId);
	}, [groups, selectedGroup?.id]);

	const ungroup = useCallback(() => {
		ConfirmationModal.show(confirmationModalId);
	}, []);

	const remove = useCallback(
		(ids: string[]) => {
			const currentGroup = groups.find((g) => g.id === selectedGroup?.id);
			if (currentGroup) {
				if (currentGroup?.itemIds.length === ids.length) {
					ungroup();
				} else {
					removeMutation.mutateAsync({
						id: currentGroup.id,
						type: currentGroup.type,
						itemIds: ids
					} as UngroupRequestDto);
				}
			}
		},
		[groups, selectedGroup?.id, ungroup, removeMutation]
	);

	const create = useCallback(() => {
		setGroupForEdit({ name: '', type, itemIds: selected?.map((item) => item.id) } as GroupsFormType);

		Modal.show(groupCreateEditModalId);
	}, [selected, type]);

	const update = useCallback(
		(addedGroupIds: string[], removedGroupIds: string[]) => {
			const groupsToAdd = groups
				.filter((g) => addedGroupIds.includes(g.id))
				.map((g) => ({ ...g, itemIds: [...g.itemIds, ...selected.map((item) => item.id)] }));

			const groupsToRemove = groups
				.filter((g) => removedGroupIds.includes(g.id))
				.map((g) => ({ ...g, itemIds: g.itemIds.filter((id) => !selected.some((s) => s.id === id)) }));

			const groupsToUpdate = [...groupsToAdd, ...groupsToRemove];

			Promise.allSettled(
				groupsToUpdate.map((group) =>
					updateMutation.mutateAsync({
						group: { name: group.name, type: group.type, itemIds: group.itemIds } as GroupsRequestDto,
						id: group.id
					})
				)
			).then(() => {
				resetSelectionRef.current?.();
				queryClient.invalidateQueries({ queryKey: ['groups', { type }] });

				if (onAction) {
					onAction(GroupActionTypes.RENAME_GROUP);
				}
			});
		},
		[groups, onAction, queryClient, selected, type, updateMutation]
	);

	const onSaveGroup = useCallback(
		(data: GroupsFormType) => {
			const groupRequest = { name: data.name, type: data.type, itemIds: data.itemIds } as GroupsRequestDto;
			if (data.id) {
				renameMutation.mutateAsync({
					group: groupRequest,
					id: data.id
				});
			} else {
				createMutation.mutateAsync(groupRequest);
			}
		},
		[createMutation, renameMutation]
	);

	const handleUngroupConfirm = useCallback(() => {
		if (selectedGroup) {
			deleteMutation.mutateAsync(selectedGroup?.id);
		}
		ConfirmationModal.hide(confirmationModalId);
	}, [deleteMutation, selectedGroup]);

	const handleCloseUngroupConfirmationModal = useCallback(() => {
		ConfirmationModal.hide(confirmationModalId);
	}, []);

	const groupMenuItems = useMemo(() => {
		const filteredGroups = groups.filter((g) => selected?.every((e) => g.itemIds.includes(e.id)));

		return groups.map((gr) => ({ ...gr, checked: filteredGroups.some((g) => g.id === gr.id) }) as IMenuItemGroup);
	}, [groups, selected]);

	const state = useMemo(
		() => ({
			selectedGroup,
			groups,
			groupMenuItems,
			selectGroup,
			_setSelectedItems: (items?: INamedEntity[]) => setSelected(items ? items : []),
			_setResetSelectionCallback: (callback: () => void) => {
				resetSelectionRef.current = callback;
			},
			rename,
			ungroup,
			remove,
			create,
			update
		}),
		[selectedGroup, groups, groupMenuItems, selectGroup, rename, ungroup, remove, create, update]
	);

	return (
		<GroupContext.Provider value={state}>
			{children}
			<GroupCreateEditModal modalId={groupCreateEditModalId} onSave={onSaveGroup} group={groupForEdit} />
			<ConfirmationModal
				title={t('Warning')}
				id={confirmationModalId}
				onConfirm={handleUngroupConfirm}
				handleClose={handleCloseUngroupConfirmationModal}
			>
				{t(
					'Are you sure you want to ungroup these items? It will result in deleting the group. This action is irreversible.'
				)}
			</ConfirmationModal>
		</GroupContext.Provider>
	);
};

export default GroupContextProvider;
