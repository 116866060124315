import { Column as PrimColumn, ColumnProps } from 'primereact/column';
import cx from 'classnames';
import styles from './Column.module.scss';

export interface IColumnProps extends ColumnProps {
	className?: string;
}

const Column: React.FC<IColumnProps> = ({ className, ...rest }) => {
	return <PrimColumn className={cx(styles.container, className)} {...rest} />;
};

export default Column;
