import { FC, useEffect } from 'react';
import { Dropdown, GroupInputFrame, InputNumber, InputText } from '@tactun/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEntitiesAsList, useNumberEnumList } from '../../../hooks';
import { ControlChannelResponseDto } from '../../ControlChannels';
import { VariableResponseDto } from '../../Variables';
import { WaveformDefinitionMode, WaveformValueType } from '../waveforms.enums';
import { createControllerName, useControllerName } from '../waveforms.hooks';
import { WaveformSweepSineFormErrors } from '../waveforms.types';
import { getFieldByFormPrefix } from '../waveforms.tools';

export interface SweepSineFormViewProps {
	variables: VariableResponseDto[];
	controlChannel: ControlChannelResponseDto;
	formPrefix?: string;
	errors?: WaveformSweepSineFormErrors;
	isRunning?: boolean;
	isControlStage?: boolean;
}

const SweepSineFormView: FC<SweepSineFormViewProps> = ({
	variables,
	controlChannel,
	formPrefix,
	errors,
	isRunning,
	isControlStage = false
}) => {
	const { t } = useTranslation('waveforms');
	const {
		control,
		watch,
		register,
		formState: { dirtyFields },
		setValue
	} = useFormContext();

	const difinitionModeOptions = useNumberEnumList(WaveformDefinitionMode);
	const typeOptions = useNumberEnumList(WaveformValueType);
	const variablesOptions = useEntitiesAsList(variables);

	const amplitudeValueCN = createControllerName('amplitudeValue', formPrefix);
	const amplitudeTypeCN = useControllerName('amplitudeType', formPrefix);
	const meanValueCN = createControllerName('meanValue', formPrefix);
	const startFrequencyCN = createControllerName('startFrequency', formPrefix);
	const endFrequencyCN = createControllerName('endFrequency', formPrefix);
	const phaseCN = createControllerName('phase', formPrefix);
	const numberOfCyclesCN = createControllerName('numberOfCycles', formPrefix);
	const meanTypeCN = useControllerName('meanType', formPrefix);
	const amplitudeType = watch(amplitudeTypeCN);
	const meanType = watch(meanTypeCN);

	const feedbackOrAxisUnit = controlChannel.feedbackMeasurement?.unit.name
		? controlChannel.feedbackMeasurement?.unit.name
		: controlChannel.axis.actuator.calibrationResponseDto?.unit?.name;

	const amplitudeTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.amplitudeType;
	useEffect(() => {
		if (amplitudeTypeDirtyField) {
			setValue(createControllerName('amplitudeValue', formPrefix), null);
			setValue(createControllerName('amplitudeVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, amplitudeTypeDirtyField]);

	const meanTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.meanType;
	useEffect(() => {
		if (meanTypeDirtyField) {
			setValue(createControllerName('meanValue', formPrefix), null);
			setValue(createControllerName('meanVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, meanTypeDirtyField]);

	return (
		<>
			<Controller
				name={createControllerName('defMode', formPrefix)}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Definition mode*')}
						options={difinitionModeOptions}
						data-testid="defMode"
						error={errors?.defMode?.message}
						{...field}
						disabled={isRunning}
					/>
				)}
			/>
			<Controller
				name={amplitudeTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Amplitude*')}
						options={typeOptions}
						data-testid="amplitudeTypeCN"
						error={errors?.amplitudeType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{amplitudeType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('amplitudeVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								label={t('Amplitude variable*')}
								data-testid="amplitudeVariableId"
								options={variablesOptions}
								error={errors?.amplitudeVariableId?.message}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<InputNumber
						data-testid="amplitudeValue"
						{...register(amplitudeValueCN, { deps: [startFrequencyCN, endFrequencyCN] })}
						label={t('Amplitude constant value*')}
						error={errors?.amplitudeValue?.message}
					/>
				)}
				<InputText data-testid="feedbackOrAxisUnit" disabled value={feedbackOrAxisUnit} />
			</GroupInputFrame>
			<Controller
				name={meanTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Mean*')}
						options={typeOptions}
						data-testid="meanTypeCN"
						error={errors?.meanType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{meanType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('meanVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								data-testid="meanVariableId"
								{...field}
								label={t('Mean variable*')}
								options={variablesOptions}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<InputNumber
						data-testid="meanValue"
						{...register(meanValueCN)}
						label={t('Mean constant value*')}
						error={errors?.meanValue?.message}
					/>
				)}
				<InputText data-testid="feedbackOrAxisUnit" disabled value={feedbackOrAxisUnit} />
			</GroupInputFrame>
			<GroupInputFrame>
				<InputNumber
					data-testid="startFrequency"
					{...register(startFrequencyCN, { deps: [amplitudeValueCN] })}
					label={t('Start Frequency*')}
					disabled={isRunning}
					error={errors?.startFrequency?.message}
				/>
				<InputText data-testid="Hz1" disabled value="Hz" />
			</GroupInputFrame>
			<GroupInputFrame>
				<InputNumber
					data-testid="endFrequency"
					{...register(endFrequencyCN, { deps: [amplitudeValueCN] })}
					label={t('End Frequency*')}
					disabled={isRunning}
					error={errors?.endFrequency?.message}
				/>
				<InputText data-testid="Hz2" disabled value="Hz" />
			</GroupInputFrame>
			<GroupInputFrame>
				<InputNumber
					data-testid="phase"
					{...register(phaseCN)}
					label={t('Phase')}
					error={errors?.phase?.message}
					disabled={isRunning}
					isInteger
				/>
				<InputText data-testid="deg" disabled value="deg" />
			</GroupInputFrame>
			<InputNumber
				data-testid="numberOfCycles"
				{...register(numberOfCyclesCN)}
				label={t('No of cycles*')}
				error={errors?.numberOfCycles?.message}
				disabled={isRunning}
				isInteger
			/>
		</>
	);
};

export default SweepSineFormView;
