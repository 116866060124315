import { KeycloakInitOptions } from 'keycloak-js';
import { useEffect, useState } from 'react';
import { AxiosInstance } from 'axios';
import { initKeycloak, KeycloakAxios } from '../keycloakAxios';
import { isServer } from '../utils/other';

export interface KeycloakConfig {
	configUrl: string;
	options?: KeycloakInitOptions;
	axiosInstance?: AxiosInstance;
}

export const useInitKeycloak = ({ configUrl, options, axiosInstance }: KeycloakConfig) => {
	const [keycloak, setKeycloak] = useState<KeycloakAxios>();
	useEffect(() => {
		let isSubscribed = true;
		(async function init() {
			// eslint-disable-next-line no-underscore-dangle
			let _keycloak;
			try {
				if (!isServer()) {
					_keycloak = await initKeycloak(configUrl, options, axiosInstance);
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('[Keycloak]: Failed to initialize keycloak', err);
			}
			if (isSubscribed) {
				setKeycloak(_keycloak);
			}
		})();

		return () => {
			isSubscribed = false;
		};
		// Inititialization should happen only on mount component
		// eslint-disable-next-line
	}, []);

	return keycloak;
};
