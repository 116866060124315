import { FC, memo, useState } from 'react';
import { Modal, Button, Dropdown } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { TestModals } from '../../tests.enums';
import { useTests } from '../../tests.hooks';
import { useEntitiesAsList } from '../../../../hooks';
import styles from './SelectTestModal.module.scss';

interface SelectTestModalProps {
	handleClose: () => void;
	onConfirm: (testId: string) => void;
}

const SelectTestModal: FC<SelectTestModalProps> = ({ handleClose, onConfirm }) => {
	const { t } = useTranslation('test');
	const { tests = [] } = useTests();
	const testOptions = useEntitiesAsList(tests);
	const [selectedTest, setSelectedTest] = useState<string | null>(null);

	const handleConfirm = () => {
		if (selectedTest) {
			onConfirm(selectedTest);
		}
	};

	return (
		<Modal id={TestModals.SELECT_TEST_MODAL} onClose={handleClose}>
			<Modal.Header>{t('Select Test')}</Modal.Header>
			<Modal.Content>
				<div className={styles.container}>
					<div className={styles.label}>{t('Please select the test to run.')}</div>
					<Dropdown
						options={testOptions}
						name="test"
						className={styles.input}
						label={t('Test name')}
						value={selectedTest}
						onChange={setSelectedTest}
					/>
				</div>
			</Modal.Content>
			<Modal.Footer>
				<Button className="btnCancel" onClick={handleClose} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button onClick={handleConfirm} color="secondary" variant="contained">
					{t('Run test')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default memo(SelectTestModal);
