import React, { useEffect, useMemo } from 'react';
import { Dropdown, InputNumber, DropdownOptionString, GroupInputFrame, InputText } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { CalculationFormType } from '../../calculation.types';
import { IListItemNumber } from '../../../../types';
import { MeasurementResponseDto } from '../../../Measurements';
import { CalculationChannelTypes, CalculationTypes, ReferencePointTypes } from '../../calculation.enums';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';

interface DataPointCalculationProps {
	measurements?: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	measurementsAndAxes: DropdownOptionString[];
	referencePointTypes: IListItemNumber[];
	form: UseFormReturn<CalculationFormType>;
}

const DataPointCalculation: React.FC<DataPointCalculationProps> = ({
	measurements,
	measurementsAndAxes,
	referencePointTypes,
	form
}) => {
	const { t } = useTranslation('calculation');
	const {
		control,
		formState: { errors },
		watch,
		setValue
	} = form;
	const type = watch('type');
	const measurementOrAxesId1 = watch('measurementOrAxesId1');
	const measurementOrAxesId2 = watch('measurementOrAxesId2');
	const refPoint = watch('refPoint');

	const refValueUnit = useMemo(() => {
		if (type !== CalculationTypes.DATA_POINT || refPoint !== ReferencePointTypes.REFERENCE_CHANNEL) {
			return undefined;
		}

		return measurements?.find((m) => m.id === measurementOrAxesId2)?.unit;
	}, [measurementOrAxesId2, measurements, refPoint, type]);
	const refValueUnitName = refValueUnit?.name || '';

	useEffect(() => {
		setValue('refUnit', refValueUnit?.id);
	}, [refValueUnit, setValue]);

	// Reset type specific values after unmount values
	useEffect(() => {
		return () => {
			setValue('measurementOrAxesId1', undefined);
			setValue('refPoint', undefined);
			setValue('measurementOrAxesId2', undefined);
			setValue('refValue', undefined);
		};
	}, [setValue]);

	useEffect(() => {
		if (type === CalculationTypes.DATA_POINT) {
			const isMeasurement = !!measurements?.find((m) => m.id === measurementOrAxesId1)
				? CalculationChannelTypes.MEASUREMENT
				: CalculationChannelTypes.CYCLE;
			setValue('ch1Type', isMeasurement);
		}
	}, [measurementOrAxesId1, measurements, setValue, type]);

	useEffect(() => {
		if (type === CalculationTypes.DATA_POINT) {
			const isMeasurement = !!measurements?.find((m) => m.id === measurementOrAxesId2)
				? CalculationChannelTypes.MEASUREMENT
				: CalculationChannelTypes.CYCLE;
			setValue('ch2Type', isMeasurement);
		}
	}, [measurementOrAxesId2, measurements, setValue, type]);

	return (
		<>
			<Controller
				name="measurementOrAxesId1"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						options={measurementsAndAxes}
						label={t('Data Channel*')}
						error={errors.measurementOrAxesId1?.message}
						data-testid="measurementOrAxesId1"
						filter
					/>
				)}
			/>
			<Controller
				name="refPoint"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						options={referencePointTypes}
						label={t('Reference Point*')}
						error={errors.refPoint?.message}
						data-testid="refPoint"
						filter
					/>
				)}
			/>
			{refPoint === ReferencePointTypes.REFERENCE_CHANNEL && (
				<>
					<Controller
						name="measurementOrAxesId2"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={measurementsAndAxes}
								label={t('Reference Channel*')}
								error={errors.measurementOrAxesId2?.message}
								data-testid="measurementOrAxesId2"
								filter
							/>
						)}
					/>
					<GroupInputFrame>
						<Controller
							name="refValue"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Reference Value*')}
									error={errors.refValue?.message}
									data-testid="refValue"
								/>
							)}
						/>
						<InputText disabled value={refValueUnitName} />
					</GroupInputFrame>
				</>
			)}
		</>
	);
};

export default DataPointCalculation;
