import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@tactun/ui';
import { PageLayout } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { CrudActions, TableFilters } from '../../../../components';
import { INamedEntity, ITableColumns } from '../../../../types';
import { UserCrudActionTypes } from '../../users.enums';
import { useTableConfigs } from '../../../../hooks';

interface UsersTableProps {
	onAction: (type: UserCrudActionTypes, data?: Record<string, string>) => void;
	data?: Record<string, string>[];
	isLoading: boolean;
}

const UsersTable: FC<UsersTableProps> = ({ data, isLoading, onAction }) => {
	const { t } = useTranslation('axes');

	const transColumns = useMemo<ITableColumns[]>(() => {
		return [
			{ field: 'username', header: t('Username') },
			{ field: 'role', header: t('Role') },
			{ field: 'addedDate', header: t('Added Date') },
			{ field: 'lastAccessedDate', header: t('Last Accessed Date') },
			{ field: 'status', header: t('Status') }
		];
	}, [t]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<INamedEntity>(transColumns);

	const createMenuItems = useCallback(
		(data: any) => [{ label: t('Activate'), command: () => onAction(UserCrudActionTypes.DUPLICATE, data) }],
		[t, onAction]
	);

	const handleCreate = useCallback(() => {
		onAction(UserCrudActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={t('My Account')}>
			<PageLayout.Header title={t('User Management')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('user')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters
					onFilterChange={handleFilterChange}
					onCancel={handleCancel}
					selected={selected as unknown as INamedEntity[]}
				/>
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={data}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(UserCrudActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default UsersTable;
