import { useTranslation } from 'react-i18next';
import { Icon } from '@tactun/ui';
import { OffsetCalibrationStatus } from '../../offsetCalibration.enums';
import styles from './OffsetCalibrationFooter.module.scss';
import React from 'react';
import { getIconNameByStatus } from '../../offsetCalibration.tools';

interface OffsetCalibrationFooterProps {
	status: OffsetCalibrationStatus;
	isSavingOffset: boolean;
}

const OffsetCalibrationFooter = ({ status, isSavingOffset }: OffsetCalibrationFooterProps) => {
	const { t } = useTranslation('offsetCalibration');

	let iconName = getIconNameByStatus(status);
	if (isSavingOffset) {
		iconName = 'loading-1';
	}

	return (
		(status !== OffsetCalibrationStatus.NOT_STARTED || isSavingOffset) && (
			<div className={styles.container}>
				<p className={styles.statusLabel}>{t('Calibration status:')}</p>
				<Icon
					name={iconName}
					size={16}
					className={styles.statusIcon}
					isSpin={status === OffsetCalibrationStatus.IN_PROGRESS || isSavingOffset}
				/>
				<p className={styles.status}>{isSavingOffset ? t('Saving...') : t(OffsetCalibrationStatus[status])}</p>
			</div>
		)
	);
};

export default React.memo(OffsetCalibrationFooter);
