import React, { useCallback, useEffect, useState } from 'react';
import { Modal, useIsChanged } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { endpointMapping, labelMapping } from '../helpers';
import { EntityTypes } from '../entityDeleter.enums';
import { IDeleteResponse, IEntity, IEntityCheckResult } from '../entityDeleter.types';
import * as api from '../entityDeleter.api';

export interface IEntityDeleterContainerProps {
	entity?: IEntity;
	entityType: EntityTypes;
	checkUsage?: boolean;
	onCanceled: () => void;
	onDeleted: (id: string) => void;
	onError?: (status: string) => void;
}

const EntityDeleterContainer: React.FC<IEntityDeleterContainerProps> = ({
	entity,
	entityType,
	checkUsage = false,
	onDeleted,
	onCanceled,
	onError
}) => {
	const { t } = useTranslation('entityDeleter');
	const isChanged = useIsChanged(entity);
	const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
	const [usedMeta, setUsedMeta] = useState<Record<string, string>>({});
	const modalId = `${entityType}-delete-confirm`;
	const infoModalId = `${entityType}-delete-Info`;

	const checkDeleteMutation = useMutation({
		mutationFn: (id: string) => api.checkIsInUse(endpointMapping[entityType], id),
		onSuccess: (data) => {
			let isUsed = false;
			if (data.entityNameAndIds) {
				const response = data as IEntityCheckResult;

				isUsed = Object.entries(response.entityNameAndIds).reduce((prev, curr) => {
					return prev || curr[1].length > 0;
				}, false);

				if (isUsed) {
					const usedByEntityTypes = Object.keys(response.entityNameAndIds)
						.map((name) => t(name))
						.join(', ');
					const usedByEntities = Object.values(response.entityNameAndIds)
						.reduce((prev, curr) => {
							return prev.concat(curr);
						}, [])
						.join(', ');
					setUsedMeta({ usedByEntityTypes, usedByEntities });

					setIsInfoModalOpen(true);
				} else {
					ConfirmationModal.show(modalId);
				}
			} else {
				ConfirmationModal.hide(modalId);
			}
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const deleteMutation = useMutation({
		mutationFn: (id: string) => api.deleteEntity(endpointMapping[entityType], id),
		onSuccess: (_, id) => {
			onDeleted(id);
		},
		onError: (e: any) => {
			const response = e?.response?.data ? (e.response.data as IDeleteResponse) : null;
			if (response !== null && onError) {
				onError(response.status);
			} else {
				toast.error(e.message);
			}
		}
	});

	useEffect(() => {
		if (isChanged && entity) {
			if (checkUsage) {
				checkDeleteMutation.mutate(entity.id);
			} else {
				ConfirmationModal.show(modalId);
			}
		}
	}, [checkUsage, checkDeleteMutation, entity, isChanged, entityType, modalId]);

	const handleConfirm = useCallback(() => {
		if (entity) deleteMutation.mutate(entity.id);

		ConfirmationModal.hide(modalId);
	}, [deleteMutation, entity, modalId]);

	const handleCloseInfoModal = useCallback(() => {
		setIsInfoModalOpen(false);
		onCanceled();
	}, [onCanceled]);

	const handleCloseConfirmationModal = useCallback(() => {
		ConfirmationModal.hide(modalId);
		onCanceled();
	}, [onCanceled, modalId]);

	return (
		<>
			<ConfirmationModal
				title={t('Warning')}
				id={modalId}
				onConfirm={handleConfirm}
				handleClose={handleCloseConfirmationModal}
			>
				{t('You are about to delete "{{name}}" {{entityLabel}}. This action is irreversible.', {
					name: entity?.name,
					entityLabel: labelMapping[entityType]
				})}
			</ConfirmationModal>
			<Modal id={infoModalId} isOpen={isInfoModalOpen} onClose={handleCloseInfoModal}>
				<Modal.Header>{t('Warning')}</Modal.Header>
				<Modal.Content>
					{t(
						`The {{entityLabel}} is used in the following {{usedByEntityTypes}} and cannot be deleted: ({{usedByEntities}}). 
                        Remove the {{entityLabel}} from the {{usedByEntityTypes}} listed to be able to delete it`,
						{
							entityLabel: labelMapping[entityType],
							usedByEntities: usedMeta.usedByEntities,
							usedByEntityTypes: usedMeta.usedByEntityTypes
						}
					)}
				</Modal.Content>
			</Modal>
		</>
	);
};

export default React.memo(EntityDeleterContainer);
