import React, { useEffect } from 'react';
import { Dropdown, Form, InputText } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { IListItemNumber } from '../../../../types';
import { TRuleForm } from '../../';
import styles from './RuleForm.module.scss';

interface RuleFormProps {
	form: UseFormReturn<TRuleForm>;
	typesOptions?: IListItemNumber[];
	disableChange?: boolean;
	isAction?: boolean;
	formId: string;
	onSave: () => void;
}

const RuleForm: React.FC<RuleFormProps> = ({ form, typesOptions, isAction, disableChange, formId, onSave }) => {
	const { t } = useTranslation('stationRules');
	const {
		control,
		watch,
		trigger,
		formState: { errors },
		register
	} = form;

	const type = useWatch({ control, name: 'type' });
	useEffect(() => {
		if (watch('name')) {
			trigger('name');
		}
	}, [watch, type, trigger]);

	return (
		<Form className={styles.container} onSubmit={onSave} id={formId}>
			<input type="hidden" {...register('stationId')} />
			<input type="hidden" {...register('testId')} />
			<input type="hidden" {...register('id')} />

			<Controller
				name="name"
				control={control}
				render={({ field }) => (
					<InputText
						data-testid="ruleName"
						label={isAction ? t('Action name*') : t('Rule name*')}
						{...field}
						error={errors.name?.message}
					/>
				)}
			/>
			{typesOptions && (
				<Controller
					name="type"
					control={control}
					render={({ field }) => (
						<Dropdown
							{...field}
							options={typesOptions}
							disabled={disableChange}
							label={t('Rule type*')}
							error={errors.type?.message}
							data-testid="type"
							filter
						/>
					)}
				/>
			)}
		</Form>
	);
};

export default React.memo(RuleForm);
