import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as api from '../users.api';
import Axis from '../components/Users';

const AxesContainer: React.FC = () => {
	const navigate = useNavigate();
	const { userId } = useParams();
	const isCreate = !userId;

	const form = useForm<Record<string, string>>({
		mode: 'onBlur'
	});
	const { handleSubmit, reset } = form;

	const { data } = useQuery<Record<string, string>, Error>({
		queryKey: ['user', { userId }],
		queryFn: async () => await api.getUser(userId as string),
		enabled: !!userId
	});

	const handleBack = useCallback(() => {
		navigate('/users');
	}, [navigate]);

	const handleSave = handleSubmit((data) => {
		if (userId) {
			//Update
		} else {
			//Create
		}
	});

	useEffect(() => {
		if (userId) {
			reset({ ...data }, { keepDirty: true, keepTouched: true });
		}
	}, [userId, data, reset]);

	return <Axis isCreate={isCreate} isLoading={false} form={form} onBack={handleBack} onSave={handleSave} />;
};

export default React.memo(AxesContainer);
