import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import cx from 'classnames';
import accountImg from './account.png';
import styles from './AccountMenu.module.scss';

export interface IInfoBadgeProps {
	items: MenuItem[];
	className?: string;
	menuClassName?: string;
	img?: string;
}

const AccountMenu: React.FC<IInfoBadgeProps> = ({ items, img, className, menuClassName }) => {
	const { t } = useTranslation();
	const menuRef = useRef<Menu>(null);

	const handleClick = useCallback((event: React.SyntheticEvent<HTMLSpanElement>) => menuRef.current?.toggle(event), []);

	return (
		<>
			<Menu
				model={items}
				popup
				ref={menuRef}
				id="myAccountMenu"
				data-testid="myAccountMenu"
				className={cx(styles.menuContainer, menuClassName)}
			/>
			<span data-testid="myAccount" className={cx(styles.container, className)} onClick={handleClick}>
				<img src={img ?? accountImg} alt="Account Img" />
				<span>{t('My Account')}</span>
			</span>
		</>
	);
};

export default React.memo(AccountMenu);
