import { FC, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ISpecimenTypeResponse } from '../specimenTypes.types';
import * as api from '../specimenTypes.api';
import UnitsTable from '../components/SpecimenTypesTable';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { SpecimenTypeCrudActionTypes } from '../specimenTypes.enums';

const UnitsTableContainer: FC = () => {
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const queryClient = useQueryClient();

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			queryClient.setQueryData<ISpecimenTypeResponse[]>(['specimenTypes'], (old) => {
				if (!old) return undefined;
				return old.filter((hc) => hc.id !== id);
			});
		},
		[queryClient]
	);

	const { data, isLoading } = useQuery<ISpecimenTypeResponse[], Error>({
		queryKey: ['specimenTypes'],
		queryFn: () => api.getSpecimenTypes()
	});

	const handleAction = useCallback(
		(type: SpecimenTypeCrudActionTypes, data?: ISpecimenTypeResponse) => {
			switch (type) {
				case SpecimenTypeCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case SpecimenTypeCrudActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case SpecimenTypeCrudActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case SpecimenTypeCrudActionTypes.DELETE:
					setEntityForDelete(data as ISpecimenTypeResponse);
					break;
			}
		},
		[navigate]
	);

	return (
		<>
			<UnitsTable onAction={handleAction} data={data} isLoading={isLoading} />
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.SPECIMEN_TYPE}
				entity={entityForDelete}
				checkUsage
			/>
		</>
	);
};

export default UnitsTableContainer;
