export enum DashboardConfigurationActions {
	CREATE = 'CREATE_DASHBOARD_CONFIGURATION',
	DUPLICATE = 'DUPLICATE',
	EDIT = 'EDIT_DASHBOARD_CONFIGURATION',
	DELETE = 'DELETE_DASHBOARD_CONFIGURATION'
}

export enum GraphTypes {
	XY = 0
}

export enum GraphScaleTypes {
	LINEAR = 0,
	LOGARITHMIC = 1
}

export enum Modals {
	CREATE_GRAPH_MODAL = 'create_graph_modal',
	CREATE_READOUT_MODAL = 'create_readout_modal'
}
