export enum CalibrationsTypes {
	PIECEWISE = 0,
	LINEAR
}

export enum CalibrationCrudActionTypes {
	EDIT = 'EDIT_ACTUATOR',
	CREATE = 'CREATE_ACTUATOR',
	DUPLICATE = 'DUPLICATE_ACTUATOR',
	DELETE = 'DELETE_ACTUATOR'
}
