import React, { useCallback, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CalculationRequestDto, CalculationResponseDto } from '../calculation.types';
import * as api from '../calculation.api';
import { useStationName } from '../../Stations/stations.hooks';
import CreateEditPageTemplate from '../../../components/CreateEditPageTemplate';
import CalculationFormContainer from './CalculationFormContainer';

const calculationFormId = 'calculationFormId';

const CalculationContainer: React.FC = () => {
	const { t } = useTranslation('calculation');
	const { stationId, calculationId } = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const isCreate = !calculationId;

	const { data: currentCalc } = useQuery<CalculationResponseDto, Error>({
		queryKey: ['calculation', { id: calculationId }],
		queryFn: () => api.getCalculationById(calculationId),
		enabled: !!calculationId
	});

	const createMutation = useMutation({
		mutationFn: (system: CalculationRequestDto) => api.createCalculation(system),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { calculation: CalculationRequestDto; calculationId: string }) =>
			api.updateCalculation(data.calculation, data.calculationId),

		onSuccess: (data) => {
			queryClient.setQueryData(['calculation', { id: calculationId }], data);
			handleBack();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/calculations`);
	}, [navigate, stationId]);

	const handleSave = useCallback(
		(calculation: CalculationRequestDto, calculationId?: string) => {
			if (calculationId) {
				updateMutation.mutate({ calculation, calculationId });
			} else {
				createMutation.mutate(calculation);
			}
		},
		[createMutation, updateMutation]
	);

	const { stationName } = useStationName(stationId);
	const title = useMemo<string>(() => {
		return isCreate ? t('Create Calculation') : t('Edit Calculation');
	}, [isCreate, t]);

	return (
		<CreateEditPageTemplate
			isLoading={createMutation.isPending || updateMutation.isPending}
			title={title}
			stationName={stationName}
			formId={calculationFormId}
			onBack={handleBack}
		>
			<CalculationFormContainer
				onSave={handleSave}
				calculation={currentCalc}
				stationId={stationId}
				formId={calculationFormId}
			/>
		</CreateEditPageTemplate>
	);
};

export default React.memo(CalculationContainer);
