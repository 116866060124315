import React, { FC, useCallback, useMemo } from 'react';
import { TreeDropdown } from '@tactun/ui';
import { TreeSelectChangeEvent, TreeSelectProps, TreeSelectSelectionKeysType } from 'primereact/treeselect';
import { useTranslation } from 'react-i18next';
import { TreeNode } from 'primereact/treenode';
import { DataChannelType } from './dataChannel.enums';
import { DataChannelDropdownKeyValue } from './dataChannel.types';
import classNames from 'classnames';
import { MeasurementResponseDto } from '../Measurements';
import { IAxisResponseDto } from '../Axes';
import { dataChannelToUniqKey, uniqKeyToDataChannel } from '../../tools/dataChannelHelper';
import styles from './DataChannel.module.scss';
import { TestMeasurementResponseDto } from '../TestMeasurements';
import { TreeDropdownProps } from '@tactun/ui/src/components/FormElements/TreeDropdown/TreeDropdown';

export interface DataChannelProps extends Omit<TreeDropdownProps, 'onChange' | 'value'> {
	className?: string;
	name?: string;
	label?: string;
	isMultiply?: boolean;
	value: DataChannelDropdownKeyValue[];
	onChange: (value: DataChannelDropdownKeyValue[]) => void;
	removeTime?: boolean;
	removeSetPoint?: boolean;
	removeCompSetPoint?: boolean;
	removeCycle?: boolean;
	removeMeasurement?: boolean;
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	axes: IAxisResponseDto[];
	error?: string;
}

const parentIds = [
	String(DataChannelType.TIME),
	String(DataChannelType.MEASUREMENT),
	String(DataChannelType.CYCLE),
	String(DataChannelType.SET_POINT),
	String(DataChannelType.COMP_SET_POINT)
];

const DataChannel: FC<DataChannelProps> = ({
	value,
	onChange,
	removeTime,
	removeSetPoint,
	removeCompSetPoint,
	removeCycle,
	removeMeasurement,
	className,
	measurements,
	axes,
	isMultiply = false,
	name = 'dataChannel',
	label,
	error,
	...rest
}) => {
	const { t } = useTranslation('dataChannel');

	const hasTime = useMemo(() => !!value.find(({ type }) => type === DataChannelType.TIME), [value]);
	const hasMeasurement = useMemo(() => !!value.find(({ type }) => type === DataChannelType.MEASUREMENT), [value]);
	const hasCycles = useMemo(() => !!value.find(({ type }) => type === DataChannelType.CYCLE), [value]);
	const hasSetPoints = useMemo(() => !!value.find(({ type }) => type === DataChannelType.SET_POINT), [value]);
	const hasCompSetPoints = useMemo(() => !!value.find(({ type }) => type === DataChannelType.COMP_SET_POINT), [value]);

	const selectedMeasurementUnitId = useMemo(() => {
		const selectedMeasurement = value.find(({ type }) => type === DataChannelType.MEASUREMENT);
		if (selectedMeasurement) {
			const existMeasurement = measurements.find(({ id }) => selectedMeasurement.id === id);
			return existMeasurement ? existMeasurement.unit.id : false;
		}
		return false;
	}, [measurements, value]);

	const options = useMemo<TreeNode[]>(() => {
		const newOptions = [];

		if (!removeMeasurement) {
			newOptions.push({
				label: t('Measurements'),
				selectable: false,
				key: dataChannelToUniqKey({ type: DataChannelType.MEASUREMENT, id: String(DataChannelType.MEASUREMENT) }),
				children: measurements.map(({ id, name, unit }) => ({
					key: dataChannelToUniqKey({ type: DataChannelType.MEASUREMENT, id }),
					label: name,
					selectable: true,
					className: classNames({
						disabled:
							isMultiply &&
							(hasCycles || hasTime || (selectedMeasurementUnitId && selectedMeasurementUnitId === unit.id))
					})
				}))
			});
		}
		if (!removeTime) {
			newOptions.push({
				label: t('Time'),
				selectable: true,
				className: classNames(styles.leftOption, {
					disabled: isMultiply && (hasCycles || hasMeasurement || hasSetPoints || hasCompSetPoints)
				}),
				key: dataChannelToUniqKey({
					type: DataChannelType.TIME,
					id: String(DataChannelType.TIME)
				})
			});
		}
		if (!removeSetPoint) {
			newOptions.push({
				label: t('Set Point'),
				selectable: false,
				key: dataChannelToUniqKey({ type: DataChannelType.SET_POINT, id: String(DataChannelType.SET_POINT) }),
				children: axes.map(({ id, name }) => ({
					key: dataChannelToUniqKey({ type: DataChannelType.SET_POINT, id }),
					label: name,
					selectable: true,
					className: classNames({
						disabled: isMultiply && (hasCycles || hasTime)
					})
				}))
			});
		}
		if (!removeCompSetPoint) {
			newOptions.push({
				label: t('Compensated Set Point'),
				selectable: false,
				key: dataChannelToUniqKey({ type: DataChannelType.COMP_SET_POINT, id: String(DataChannelType.COMP_SET_POINT) }),
				children: axes.map(({ id, name }) => ({
					key: dataChannelToUniqKey({ type: DataChannelType.COMP_SET_POINT, id }),
					label: name,
					selectable: true,
					className: classNames({
						disabled: isMultiply && (hasCycles || hasTime)
					})
				}))
			});
		}
		if (!removeCycle) {
			newOptions.push({
				label: t('Cycle'),
				key: dataChannelToUniqKey({ type: DataChannelType.CYCLE, id: String(DataChannelType.CYCLE) }),
				selectable: false,
				children: axes.map(({ id, name }) => ({
					key: dataChannelToUniqKey({ type: DataChannelType.CYCLE, id }),
					label: name,
					selectable: true,
					className: classNames({
						disabled: isMultiply && (hasMeasurement || hasTime || hasSetPoints || hasCompSetPoints)
					})
				}))
			});
		}

		return newOptions;
	}, [
		removeMeasurement,
		removeTime,
		removeSetPoint,
		removeCompSetPoint,
		removeCycle,
		t,
		measurements,
		isMultiply,
		hasCycles,
		hasTime,
		selectedMeasurementUnitId,
		hasMeasurement,
		hasSetPoints,
		hasCompSetPoints,
		axes
	]);

	const onChangeHandler = useCallback(
		(data: TreeSelectChangeEvent) => {
			let newValue: DataChannelDropdownKeyValue[];
			if (data.value === undefined) {
				newValue = [];
			} else {
				if (isMultiply) {
					newValue = Object.entries(data.value as TreeSelectSelectionKeysType)
						.map(([key]) => uniqKeyToDataChannel(key) as DataChannelDropdownKeyValue)
						.filter(
							(keyData) => keyData.type === DataChannelType.TIME || (keyData.id && !parentIds.includes(keyData.id))
						);
				} else {
					newValue = [uniqKeyToDataChannel(data.value as string) as DataChannelDropdownKeyValue];
				}
			}

			onChange(newValue);
		},
		[isMultiply, onChange]
	);

	const treeValue = useMemo(() => {
		return isMultiply
			? value
					.map((item) => dataChannelToUniqKey(item))
					.reduce(
						(acc: TreeSelectSelectionKeysType, key) => ({
							...acc,
							[key]: { checked: !key.includes('undefined') }
						}),
						{}
					)
			: value.length
			? dataChannelToUniqKey(value[0])
			: undefined;
	}, [isMultiply, value]);

	const valueTemplate = useCallback(
		(selectedNodes: TreeNode | TreeNode[], props: TreeSelectProps) => {
			if (Array.isArray(selectedNodes)) {
				const labels = selectedNodes.map((node) => {
					const data = uniqKeyToDataChannel(node.key as string);

					return data?.type === DataChannelType.CYCLE ||
						data?.type === DataChannelType.SET_POINT ||
						data?.type === DataChannelType.COMP_SET_POINT
						? `${node?.label} ${t(DataChannelType[data.type])} `
						: node?.label;
				});
				return labels.join(', ');
			} else {
				return selectedNodes.label;
			}
		},
		[t]
	);

	return (
		<TreeDropdown
			name={name}
			className={className}
			value={treeValue}
			multiple={isMultiply}
			valueTemplate={valueTemplate}
			options={options}
			onChange={onChangeHandler}
			label={label}
			selectionMode={isMultiply ? 'checkbox' : 'single'}
			error={error}
			{...rest}
		/>
	);
};

export default DataChannel;
