import React, { useMemo } from 'react';
import classNames from 'classnames';

import styles from './Widget.module.scss';
import { WidgetItem } from '../../dashboard.types';
import WidgetContext from './widgetContext';

interface WidgetContentProps {
	id: string;
	className?: string;
	widget: WidgetItem;
	updateWidget?: (id: string, widget: Partial<WidgetItem>) => void;
	removeWidget?: (id: string) => void;
	addWidget?: (widget: WidgetItem) => void;
	children: React.ReactNode;
}

const Widget: React.FC<WidgetContentProps> = (props) => {
	const { id, className, widget, updateWidget, removeWidget, addWidget, children } = props;

	const state = useMemo(() => {
		const widgetUpdated = {
			...widget,
			...(widget.minimized ? { noResize: true, h: 1 } : {})
		};
		return {
			id,
			widget: widgetUpdated,
			updateWidget: updateWidget || (() => {}),
			removeWidget: removeWidget || (() => {}),
			addWidget: addWidget || (() => {})
		};
	}, [widget, id, updateWidget, removeWidget, addWidget]);

	return (
		<WidgetContext.Provider value={state}>
			<div
				className={classNames(styles.widgetContainer, className, {
					[styles.disableResize]: widget.noResize || widget.minimized,
					[styles.minimized]: widget.minimized
				})}
			>
				{children}
			</div>
		</WidgetContext.Provider>
	);
};

export default React.memo(Widget);
