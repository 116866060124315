import { object, string, mixed, array, number } from 'yup';
import { WidgetsLayoutTypes, WidgetsTypes } from './customWidget.enums';
import { alphaNumericRegex } from '../../constants/regexes';
import { buttonWidgetSubframeFormSchema } from '../ButtonWidget/buttonWidget.schemas';
import * as api from './customWidget.api';

export const widgetFormSchema = object({
	id: string(),
	width: number().required(),
	height: number().required(),
	activeSubframe: number(),
	activeButton: number().nullable(),
	stationId: string().required(),
	name: string()
		.required('Widget name is a required field')
		.max(30)
		.matches(alphaNumericRegex, 'Is not in correct format')
		.test('checkAxisUniqueness', 'Name is used', function (value, form) {
			if (!value || !!form?.parent?.id) return true;
			return api.checkName(value, form?.parent.stationId, form?.parent?.id);
		}),
	type: mixed<WidgetsTypes>().required('Widget type is a required field'),
	layoutType: mixed<WidgetsLayoutTypes>().required('Widget layout is a required field'),
	subFrameSize: array().of(number()),
	subFrames: array().of(buttonWidgetSubframeFormSchema).min(1).required()
}).required();
