export enum SpecimenTableActionTypes {
	EDIT = 'EDIT_SPECIMEN',
	PLAYBACK = 'PLAYBACK',
	RUN_SPECIMEN_TEST = 'RUN_SPECIMEN_TEST',
	CREATE = 'CREATE_SPECIMEN',
	DUPLICATE = 'DUPLICATE_SPECIMEN',
	TEST_REPORT = 'TEST_REPORT',
	TEST_LOGS = 'TEST_LOGS',
	DELETE = 'DELETE_SPECIMEN'
}

export enum SpecimenModes {
	SINGLE = 'singles',
	BATCH = 'batches'
}

export enum SpecimenStatusesDto {
	NOT_TESTED = 0,
	TEST_IN_PROGRESS,
	BROKEN,
	TESTED
}

export enum SpecimenCreateErrors {
	'Specimen ID already exists',
	'Specimen batch ID already exists'
}

export enum SpecimenEditErrors {
	'Specimen not found for UUID',
	'Removing more items than possible',
	'Specimen ID already exists',
	'Specimen batch ID already exists',
	'Specimen ID can not be changed',
	'Specimen material can not be changed',
	'Specimen pipeline can not be changed',
	'Specimen batch ID can not be changed',
	'Specimen type can not be changed'
}
