export const debounce = (callback: (...args: any) => void, ms: number) => {
	let timeout: NodeJS.Timeout | undefined;

	const cancel = () => {
		if (timeout) {
			clearTimeout(timeout);
		}
	};

	const debounced = (...args: any) => {
		cancel();
		timeout = setTimeout(() => callback(...args), ms);
	};

	debounced.cancel = cancel;
	return debounced;
};

export const isServer = () => typeof window === 'undefined';
