import { useMemo, useRef } from 'react';
import { PageLayout, Form, InputText, Dropdown, Button, BottomBar, InfoBadge, InfoBadgeIcons } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import styles from './Orders.module.scss';

interface AxesProps {
	isCreate: boolean;
	isLoading: boolean;
	form: UseFormReturn<Record<string, string>>;
	onBack: () => void;
	onSave: () => void;
}

const Orders: React.FC<AxesProps> = ({ isCreate, isLoading, form, onBack, onSave }) => {
	const { t } = useTranslation('axes');
	const formId = useRef('axesForm');
	const { control, register } = form;

	const title = useMemo<string>(() => {
		return isCreate ? t('Create an Order') : t('Edit Order');
	}, [isCreate, t]);

	return (
		<>
			<PageLayout>
				<PageLayout.Header title={title} onBack={onBack} />
				<Form className={styles.container} onSubmit={onSave} id={formId.current}>
					<input type="hidden" {...register('stationId')} />
					<Controller
						name="orderID"
						control={control}
						render={({ field }) => <InputText disabled label={t('Order ID*')} {...field} value="0000037" />}
					/>
					<Controller
						name="customerName"
						control={control}
						render={({ field }) => <Dropdown {...field} label={t('Customer name*')} filter />}
					/>
					<Controller
						name="controllerModel"
						control={control}
						render={({ field }) => <Dropdown {...field} label={t('Controller model(*)')} filter />}
					/>
					<Controller
						name="controllerSerialNumber"
						control={control}
						render={({ field }) => <Dropdown {...field} label={t('Controller serial number(*)')} filter />}
					/>
					<Controller
						name="system"
						control={control}
						render={({ field }) => <Dropdown {...field} label={t('System(*)')} filter />}
					/>
					<Controller
						name="test"
						control={control}
						render={({ field }) => <Dropdown {...field} label={t('Test(*)')} filter />}
					/>
					<Controller
						name="username"
						control={control}
						render={({ field }) => <InputText label={t('Username*')} {...field} />}
					/>
					<Controller
						name="password"
						control={control}
						render={({ field }) => (
							<InputText
								//type="password"
								//TODO: TEXT add password
								className={styles.passwordEye}
								label={t('Password*')}
								{...field}
							/>
						)}
					/>
					<Controller
						name="notes"
						control={control}
						render={({ field }) => (
							<>
								<InputText {...field} textarea label={t('Notes')} />
								<div className={styles.countDown}>1000</div>
							</>
						)}
					/>
				</Form>
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button data-testid="cancelBtn" label={t('Cancel')} variant="contained" color="success" onClick={onBack} />
				<Button
					data-testid="saveBtn"
					label={t('Save')}
					variant="contained"
					color="secondary"
					type="submit"
					form={formId.current}
				/>
			</BottomBar>
		</>
	);
};

export default Orders;
