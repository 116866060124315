import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Measurement from '../components/Measurement';
import * as api from '../measurements.api';
import { formRequestConverter, responseFormConverter } from '../measurements.converters';
import { MeasurementRequestDto } from '../measurements.types';
import { stationMeasurementFormSchema } from '../measurements.schemas';
import { useMeasurement } from '../measurements.hooks';
import { useOnce } from '../../../hooks';
import { useQuantities } from '../../Units/units.hooks';
import { useSensors } from '../../Sensors/sensors.hooks';
import { useStationName } from '../../Stations/stations.hooks';
import { useVariablesList, VariablesTypes } from '../../Variables';

const defaultValues = {
	name: '',
	isApplyFormula: false,
	sensorId: '',
	isInverseReading: false,
	isLimitEnabled: false,
	stationId: '',
	unitId: '',
	formulaType: null,
	dataIn1: null,
	dataIn2: null,
	upperLimit: null,
	lowerLimit: null
};

const MeasurementContainer: React.FC = () => {
	const navigate = useNavigate();
	const { stationId, measurementId } = useParams();
	const queryClient = useQueryClient();
	const isCreate = !measurementId;
	const form = useForm({
		defaultValues: { ...defaultValues, stationId },
		mode: 'onBlur',
		reValidateMode: 'onChange',
		resolver: yupResolver(stationMeasurementFormSchema)
	});
	const { handleSubmit, reset } = form;
	const { stationName } = useStationName(stationId);
	const { sensors } = useSensors(stationId);
	const { measurementDto: currentMeasurementDto } = useMeasurement(measurementId);
	const { quantities } = useQuantities();
	const variables = useVariablesList(stationId, VariablesTypes.NUMERIC);
	const initTrigger = useOnce(!!currentMeasurementDto);

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/measurements`);
	}, [navigate, stationId]);

	const handleReset = useCallback(() => {
		if (currentMeasurementDto) {
			reset({ ...responseFormConverter(currentMeasurementDto), stationId }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultValues, stationId }, { keepDirty: true, keepTouched: true });
		}
	}, [currentMeasurementDto, reset, stationId]);

	const handleSave = handleSubmit((data) => {
		const filteredData = formRequestConverter(data);

		if (measurementId) {
			updateMutation.mutate({
				measurement: filteredData,
				measurementId
			});
		} else {
			createMutation.mutate(filteredData);
		}
	});

	const createMutation = useMutation({
		mutationFn: (measurement: MeasurementRequestDto) => api.createMeasurement(measurement),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { measurement: MeasurementRequestDto; measurementId: string }) =>
			api.updateMeasurement(data.measurement, data.measurementId),
		onSuccess: (data) => {
			queryClient.setQueryData(['measurement', { id: measurementId }], data);
			handleBack();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	useEffect(() => {
		if (initTrigger) {
			handleReset();
		}
	}, [initTrigger, handleReset]);

	return (
		<FormProvider {...form}>
			<Measurement
				isCreate={isCreate}
				isLoading={createMutation.isPending || updateMutation.isPending}
				onBack={handleBack}
				onSave={handleSave}
				sensors={sensors || []}
				variables={variables}
				quantities={quantities || []}
				stationName={stationName}
			/>
		</FormProvider>
	);
};

export default React.memo(MeasurementContainer);
