import React, { useCallback, useMemo } from 'react';
import { PageLayout, StatusLabel, StatusLabelTypes, StatusLabelSizes } from '@tactun/ui';
import { IconButton } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { CrudActions, TableFilters } from '../../../../components';
import { StationRulesTableActionTypes } from '../../stationRules.enums';
import { IColumnProps } from '@tactun/ui/src/components/DataTable/Column';
import { RuleListItem, RuleStatus } from '../../../Rules';
import { useTableConfigs } from '../../../../hooks';
import { useIsLocalDeployment } from '../../../../hooks/useIsLocalDeployment';

interface StationRulesTableProps {
	onAction: (type: StationRulesTableActionTypes, data?: RuleListItem) => void;
	data?: RuleListItem[];
	isLoading: boolean;
	stationName: string;
}

const StationRulesTable: React.FC<StationRulesTableProps> = ({ data, stationName, isLoading, onAction }) => {
	const { t } = useTranslation(['stationRules', 'actions', 'ruleTrigger']);
	const isDisabled = useIsLocalDeployment();

	const formattedData = useMemo(
		() =>
			data?.map((item) => ({
				...item,
				triggerType: item.triggerTypes.map((triggerType) => t(triggerType, { ns: 'ruleTrigger' })).join(', '),
				statusText: item.status === RuleStatus.ENABLED ? t('Active') : t('Disabled')
			})),
		[data, t]
	);

	const transColumns = useMemo<IColumnProps[]>(() => {
		const typeColumn = {
			field: 'type',
			header: t('Rule type'),
			body: (data: RuleListItem) => {
				return `${t(data.type)}${data.location ? ` (${t(data.location, { ns: 'actions' })})` : ''}`;
			}
		};

		return [
			typeColumn,
			{
				field: 'triggerType',
				header: t('Rule trigger')
			},
			{
				field: 'statusText',
				header: t('Status'),
				body: (data: RuleListItem) => {
					const statusLabelState =
						data.status === RuleStatus.ENABLED
							? { type: StatusLabelTypes.SUCCESS, text: t('Active') }
							: { type: StatusLabelTypes.GRAY, text: t('Disabled') };
					return (
						<StatusLabel type={statusLabelState.type} size={StatusLabelSizes.SMALL}>
							{t(statusLabelState.text)}
						</StatusLabel>
					);
				}
			}
		];
	}, [t]);

	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<RuleListItem>(transColumns, formattedData);

	const createMenuItems = useCallback(
		(data: any) => {
			const actions = [
				{
					label: t('Duplicate'),
					disabled: true,
					icon: 't-icon-duplicate',
					command: () => onAction(StationRulesTableActionTypes.DUPLICATE, data)
				},
				{
					label: t('Delete'),
					icon: 't-icon-delete',
					className: 'red-menu-item',
					command: () => onAction(StationRulesTableActionTypes.DELETE, data),
					disabled: isDisabled
				}
			];
			return actions;
		},
		[t, isDisabled, onAction]
	);

	const handleCreate = useCallback(() => {
		onAction(StationRulesTableActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={stationName}>
			<PageLayout.Header title={t('Rules')}>
				<CrudActions isCreateDisabled={isDisabled} onCreate={handleCreate} entityLabel={t('Rule')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={formattedData}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Rule name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(StationRulesTableActionTypes.EDIT, rowData)}
							disabled={isDisabled}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="toggle_on"
							fontSize="1.2rem"
							title={rowData.status === RuleStatus.ENABLED ? t('Disable') : t('Enable')}
							onClick={() => onAction(StationRulesTableActionTypes.UPDATE_STATUS, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default React.memo(StationRulesTable);
