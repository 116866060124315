import React, { forwardRef, useMemo } from 'react';
import RGL, { Layout, WidthProvider } from 'react-grid-layout';

import styles from './Grid.module.scss';

const ReactGridLayout = WidthProvider(RGL);

interface WidgetContentProps {
	layout: Layout[];
	onLayoutChange: (layout: Layout[]) => void;
	children: React.ReactNode;
}

const Grid = forwardRef<HTMLDivElement, WidgetContentProps>((props, ref) => {
	const { layout, onLayoutChange, children } = props;
	const gutter = useMemo(() => {
		return window.innerWidth >= 1920 ? 16 : 12;
	}, []);

	return (
		<div className={styles.container} ref={ref}>
			<ReactGridLayout
				draggableHandle=".widget-header"
				className={styles.grid}
				cols={24}
				rowHeight={32}
				margin={[gutter, 16]}
				compactType="vertical"
				layout={layout}
				onLayoutChange={onLayoutChange}
			>
				{children}
			</ReactGridLayout>
		</div>
	);
});

export default React.memo(Grid);
