import React, { forwardRef, Ref } from 'react';
import cx from 'classnames';
import { InputSwitch, InputSwitchProps } from 'primereact/inputswitch';
import styles from './Switch.module.scss';

export interface SwitchProps extends InputSwitchProps {
	label?: React.ReactNode;
}

const Switch: React.FC<SwitchProps> = forwardRef(
	({ className, label, id, name, ...rest }, ref: Ref<HTMLInputElement>) => {
		return (
			<div className={cx(styles.switchContainer, className)}>
				{label && (
					<label className={styles.switchLabel} htmlFor={id || name}>
						{label}
					</label>
				)}
				<InputSwitch className={styles.field} inputRef={ref} id={id || name} name={name} {...rest} />
			</div>
		);
	}
);
Switch.displayName = 'Switch';

export default React.memo(Switch);
