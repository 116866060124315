import { ColorPicker, Modal, useModalData } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { Plot } from '../../graphWidget.types';
import styles from './GraphColorPickerModal.module.scss';

interface IGraphColorPickerModalProps {
	onSelectColor: (plot: Plot, color: string) => void;
	modalId: string;
}

const GraphColorPickerModal = ({ onSelectColor, modalId }: IGraphColorPickerModalProps) => {
	const { t } = useTranslation('');
	const plot = useModalData(modalId) || {};

	return (
		<Modal id={modalId} onClose={() => Modal.hide(modalId)}>
			<Modal.Header>{t('Change Color')}</Modal.Header>
			<Modal.Content>
				<div className={styles.colorPickerContainer}>
					{plot && <ColorPicker onChange={(color) => onSelectColor(plot, color)} initialColor={plot.color} />}
				</div>
			</Modal.Content>
		</Modal>
	);
};

export default GraphColorPickerModal;
