import { useEffect, useState } from 'react';
import { subscribeToEvents } from '../Dashboard/dashboard.grpc';
import { eventsGrpcToEventList } from './eventViewer.converters';
import { IEvent } from './eventViewer.types';
import { TriggerType } from '@tactun/grpc-client';

export const useEvents = (isDisabled: boolean = false) => {
	const [events, setEvents] = useState<IEvent[]>([]);

	useEffect(() => {
		let subscription: { unsubscribe(): void };

		if (!isDisabled) {
			subscription = subscribeToEvents(null, (newEvents) => {
				const filteredEvents = newEvents.filter(
					({ ruleName, trigger }) => !ruleName.startsWith('BUTTON_') || trigger?.type !== TriggerType.TRG_VARIABLE
				);
				setEvents((prevEvents) => [...prevEvents, ...eventsGrpcToEventList(filteredEvents)]);
			});
		}

		return () => {
			if (subscription) {
				subscription.unsubscribe();
			}
		};
	}, [isDisabled]);

	return events;
};
