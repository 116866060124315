import { Button } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { ControlStageActionTypes } from '../../controlStage.enums';
import styles from './ControlStage.module.scss';

interface ControlStageProps {
	isTestingMode: boolean;
	isRunning: boolean;
	children: React.ReactNode;
	onAction: (actionType: ControlStageActionTypes) => void;
}

const ControlStage: React.FC<ControlStageProps> = ({ isTestingMode, children, onAction, isRunning }) => {
	const { t } = useTranslation('controlStage');
	return (
		<div className={styles.container}>
			{children}
			<div className={styles.btnCont}>
				{!isTestingMode && (
					<>
						<Button
							variant="contained"
							color="success"
							label={t('Save Control')}
							onClick={() => onAction(ControlStageActionTypes.SAVE_CONTROL_CHANNEL)}
						/>
						<Button
							className={styles.btnIcon}
							variant="contained"
							color="secondary"
							label={t(isRunning ? 'Stop' : 'Start')}
							onClick={() =>
								onAction(isRunning ? ControlStageActionTypes.STOP_STATION : ControlStageActionTypes.START_STATION)
							}
							icon={t(isRunning ? 't-icon-control-test-pause' : 't-icon-control-test-play')}
						/>
					</>
				)}
				{isTestingMode && (
					<>
						<Button
							variant="contained"
							color="success"
							disabled={true}
							label={t('Save to test')}
							onClick={() => onAction(ControlStageActionTypes.SAVE_TEST)}
						/>
						<Button
							className={styles.btnIcon}
							variant="contained"
							color="secondary"
							disabled={true}
							label={isRunning ? t('Stop stage') : t('Start stage')}
							onClick={() =>
								onAction(isRunning ? ControlStageActionTypes.START_STAGE : ControlStageActionTypes.START_STAGE)
							}
							icon={t(isRunning ? 't-icon-control-test-pause' : 't-icon-control-test-play')}
						/>
						<Button
							className={styles.btnIcon}
							variant="contained"
							color="secondary"
							disabled={true}
							label={isRunning ? t('Stop test') : t('Start test')}
							onClick={() =>
								onAction(isRunning ? ControlStageActionTypes.STOP_STAGE : ControlStageActionTypes.START_TEST)
							}
							icon={t(isRunning ? 't-icon-control-test-pause' : 't-icon-control-test-play')}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default ControlStage;
