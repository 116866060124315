import React, { useCallback, useMemo, useRef, forwardRef, useImperativeHandle } from 'react';
import { PageLayout, DataTable, KebabMenu, IconButton, StatusLabelTypes, StatusLabel } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { StationListItem } from '../../station.types';
import { StationCrudActionTypes } from '../../station.enums';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { useTableConfigs } from '../../../../hooks';
import { GroupTableCell } from '../../../Group';
import StationConfigureBtnContainer from '../../containers/StationConfigureBtnContainer';
import { DataTableValueArray, DataTable as PrimeDataTable } from 'primereact/datatable';
interface IStationsTableProps {
	onAction: (type: StationCrudActionTypes, data?: StationListItem) => void;
	data: StationListItem[];
	connectedStationId?: string;
	connectedModel?: string;
	isLoading: boolean;
}

const StationsTable = forwardRef<{ scrollTo(index: number): void } | null, IStationsTableProps>(
	({ data, isLoading, connectedModel, connectedStationId, onAction }, ref) => {
		const { t } = useTranslation(['station', 'common']);
		const tableRef = useRef<PrimeDataTable<DataTableValueArray> | null>(null);

		useImperativeHandle(ref, () => ({
			scrollTo(index) {
				const vScroll = tableRef.current?.getVirtualScroller();
				vScroll?.scrollToIndex(index);
			}
		}));

		const transColumns = useMemo<ITableColumns<StationListItem>[]>(() => {
			const columns = [
				{ field: 'controllerModel', header: t('Controller model') },
				{ field: 'type', header: t('Station type') },
				{ field: 'createdDate', header: t('Creation date') },
				{
					field: 'status',
					header: t('Status'),
					body: (station: StationListItem) =>
						station.isLinked ? (
							<StatusLabel type={StatusLabelTypes.YELLOW} isStaticLabel>
								{t('LINKED')}
							</StatusLabel>
						) : undefined
				}
			] as ITableColumns<StationListItem>[];

			if (data.some((d) => d.groups && d.groups.length > 0)) {
				columns.push({
					field: 'group',
					header: t('Group'),
					sortable: false,
					body: (data: StationListItem) => <GroupTableCell groups={data.groups} />
				});
			}

			return columns;
		}, [data, t]);

		const {
			filter,
			selected,
			columnComponents,
			dataFilteredByGroups,
			handleSelectionChange,
			handleFilterChange,
			handleCancel
		} = useTableConfigs<StationListItem>(transColumns, data);

		const createMenuItems = useCallback(
			(data: StationListItem) => [
				{
					label: t('Duplicate'),
					icon: 't-icon-duplicate',
					command: () => onAction(StationCrudActionTypes.DUPLICATE, data)
				},
				{
					label: t('Dashboard'),
					icon: 't-icon-dahsboard-small',
					disabled: connectedStationId !== data.id,
					command: () => onAction(StationCrudActionTypes.RUN_STATION, data)
				},
				{
					label: t('Create Product'),
					icon: 't-icon-create-product',
					disabled: connectedStationId !== data.id || data.isLinked,
					command: () => onAction(StationCrudActionTypes.CREATE_PRODUCT, data)
				},
				{
					label: t('Delete'),
					command: () => onAction(StationCrudActionTypes.DELETE, data),
					icon: 't-icon-delete',
					className: 'red-menu-item',
					disabled: data.isLinked
				}
			],
			[connectedStationId, onAction, t]
		);

		const handleCreate = useCallback(() => {
			onAction(StationCrudActionTypes.CREATE);
		}, [onAction]);

		const transData = useMemo(() => {
			return dataFilteredByGroups?.map((d) => ({
				...d,
				createdDate: t('common:dateTime', { date: new Date(d.createdDate) })
			}));
		}, [dataFilteredByGroups, t]);

		return (
			<PageLayout info={t('Systems and Stations')}>
				<PageLayout.Header title={t('Stations')}>
					<CrudActions onCreate={handleCreate} entityLabel={t('Station')} />
				</PageLayout.Header>
				<PageLayout.Filters>
					<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
				</PageLayout.Filters>
				<DataTable
					ref={tableRef}
					visibleRowsCount={8}
					value={transData}
					loading={isLoading}
					dataKey="id"
					globalFilter={filter}
					showGridlines
					columnResizeMode="fit"
					selection={selected}
					onSelectionChange={handleSelectionChange}
					selectionMode="multiple"
					data-testid="stationsListTable"
					virtualScrollerOptions={{ itemSize: 58 }}
				>
					<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
					<DataTable.Column sortable resizeable field="name" header={t('Station name')} />
					{columnComponents}
					<DataTable.Column
						body={(rowData) => (
							<IconButton
								icon="edit"
								fontSize="1.2rem"
								title={t('Edit')}
								onClick={() => onAction(StationCrudActionTypes.EDIT, rowData)}
								disabled={rowData.isLinked}
							/>
						)}
						exportable={false}
						align="right"
						headerStyle={{ width: '3em' }}
					/>
					<DataTable.Column
						body={(rowData) => (
							<StationConfigureBtnContainer
								stationId={rowData.id}
								isConnectedModel={connectedModel === rowData.controllerModel}
								isConnected={connectedModel !== undefined}
							/>
						)}
						exportable={false}
						align="right"
						headerStyle={{ width: '3em' }}
					/>
					<DataTable.Column
						body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
						exportable={false}
						align="right"
						headerStyle={{ width: '1em' }}
					/>
				</DataTable>
			</PageLayout>
		);
	}
);

export default React.memo(StationsTable);
