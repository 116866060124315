import axios from '../../httpCommon';
import { CalculationResponseDto } from '../Calculations';
import { TestRuleResponseDto } from '../Rules';
import { StageTypes } from '../Tests/tests.enums';
import { ActionGroupDto } from './testActions.types';
import { defaultActionGroup } from './testActions.const';

export const checkName = async (name: string, parentId: string, id?: string): Promise<boolean> => {
	const response = await axios.post('/cassiopeia/action/isNameUnique', { id, name, parentId });
	return response.data === true;
};

export const getStageActions = async (
	testId: string,
	stageId: string,
	stageType: StageTypes
): Promise<ActionGroupDto> => {
	const response = await axios.get('/cassiopeia/action/search/findAllByStageUuid', {
		params: { uuid: stageId }
	});
	const allActions = response.data._embedded.action as ActionGroupDto[];

	return (
		allActions.find((action) => action.location === stageType) ||
		({
			...defaultActionGroup,
			location: stageType,
			testUuid: testId,
			stageUuid: stageId
		} as ActionGroupDto)
	);
};

export const getTestExistingActions = async (testId: string): Promise<ActionGroupDto[]> => {
	const response = await axios.get('/cassiopeia/action/search/findAllByTestUuid', {
		params: { uuid: testId }
	});

	return response.data._embedded.action as ActionGroupDto[];
};

export const getTestAction = async (id: string) => {
	const response = await axios.get(`/cassiopeia/action/${id}`);

	return response.data as TestRuleResponseDto;
};

export const getTestCalculation = async (id: string) => {
	const response = await axios.get(`/cassiopeia/calc/${id}`);

	return response.data as CalculationResponseDto;
};

export const updateTestAction = async (dataLogConfig: ActionGroupDto) => {
	const response = await axios.post('/cassiopeia/action', dataLogConfig);
	return response.data as ActionGroupDto;
};
