import React, { FC, useEffect, useMemo } from 'react';
import { Dropdown, GroupInputFrame, InputNumber, InputText, Switch, TreeDropdown } from '@tactun/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEntitiesAsList, useNumberEnumList } from '../../../hooks';
import { ControlChannelResponseDto } from '../../ControlChannels';
import { MeasurementResponseDto } from '../../Measurements';
import { useDefaultTimeUnitName } from '../../Units/units.hooks';
import { VariableResponseDto } from '../../Variables';
import {
	WaveformDefinitionMode,
	WaveformDirection,
	WaveformEndChannelType,
	WaveformValueType
} from '../waveforms.enums';
import {
	createControllerName,
	useControllerName,
	useEndChannel,
	useEndChannelMeta,
	useIsInvertEndConditionDisabled
} from '../waveforms.hooks';
import { WaveformDuoRampFormErrors } from '../waveforms.types';
import { getFieldByFormPrefix } from '../waveforms.tools';
import { TestMeasurementResponseDto } from '../../TestMeasurements';

export interface RampWaveformFormViewProps {
	variables: VariableResponseDto[];
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	controlChannel: ControlChannelResponseDto;
	formPrefix?: string;
	errors?: WaveformDuoRampFormErrors;
	isRunning?: boolean;
	isControlStage?: boolean;
}

const RampWaveformFormView: FC<RampWaveformFormViewProps> = ({
	variables,
	measurements,
	controlChannel,
	formPrefix,
	errors,
	isRunning,
	isControlStage = false
}) => {
	const { t } = useTranslation('waveforms');
	const {
		control,
		watch,
		formState: { dirtyFields },
		setValue
	} = useFormContext();

	const endChannelTypes = useEndChannel(measurements);
	const defaultTimeUnitName = useDefaultTimeUnitName();
	const difinitionModeOptions = useNumberEnumList(WaveformDefinitionMode);
	const directionOptions = useNumberEnumList(WaveformDirection);
	const rateTypeOptions = useNumberEnumList(WaveformValueType, 'waveforms');
	const waveformValTypeOptions = useNumberEnumList(WaveformValueType, 'waveforms');
	const variablesOptions = useEntitiesAsList(variables);

	// CN - means Controller name
	const upEndChannelValueTypeCN = useControllerName('upEndChannelValueType', formPrefix);
	const endChannelCN = useControllerName('endChannel', formPrefix);
	const lowerEndChannelValueTypeCN = useControllerName('lowerEndChannelValueType', formPrefix);
	const upRateTypeCN = useControllerName('upRateType', formPrefix);
	const downRateTypeCN = useControllerName('downRateType', formPrefix);
	const isInvertEndConditionCN = createControllerName('isInvertEndCondition', formPrefix);

	const endChannel = watch(endChannelCN);
	const upEndChannelValueType = watch(upEndChannelValueTypeCN);
	const lowerEndChannelValueType = watch(lowerEndChannelValueTypeCN);
	const upRateType = watch(upRateTypeCN);
	const downRateType = watch(downRateTypeCN);

	const duoRampEndChannelTypeOptions = useMemo(
		() => endChannelTypes.filter((ch) => ch.key !== WaveformEndChannelType.TIME),
		[endChannelTypes]
	);

	const feedbackOrAxisUnit = controlChannel.feedbackMeasurement?.unit.name
		? controlChannel.feedbackMeasurement?.unit.name
		: controlChannel.axis.actuator.calibrationResponseDto?.unit?.name;

	const rateUnit = useMemo(() => {
		return `${feedbackOrAxisUnit}/${defaultTimeUnitName}`;
	}, [feedbackOrAxisUnit, defaultTimeUnitName]);

	const { endChannelUnit } = useEndChannelMeta(endChannel, defaultTimeUnitName, feedbackOrAxisUnit || '', measurements);

	const upRateTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.upRateType;
	useEffect(() => {
		if (upRateTypeDirtyField) {
			setValue(createControllerName('upRateValue', formPrefix), null);
			setValue(createControllerName('upRateVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, upRateTypeDirtyField]);

	const downRateTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.downRateType;
	useEffect(() => {
		if (downRateTypeDirtyField) {
			setValue(createControllerName('downRateValue', formPrefix), null);
			setValue(createControllerName('downRateVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, downRateTypeDirtyField]);

	const upEndChannelValueTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.upEndChannelValueType;
	useEffect(() => {
		if (upEndChannelValueTypeDirtyField) {
			setValue(createControllerName('upEndChannelValue', formPrefix), null);
			setValue(createControllerName('upEndChannelVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, upEndChannelValueTypeDirtyField]);

	const lowerEndChannelValueTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.lowerEndChannelValueType;
	useEffect(() => {
		if (lowerEndChannelValueTypeDirtyField) {
			setValue(createControllerName('lowerEndChannelValue', formPrefix), null);
			setValue(createControllerName('lowerEndChannelVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, lowerEndChannelValueTypeDirtyField]);

	const isInvertEndConditionDisabled = useIsInvertEndConditionDisabled(
		endChannel,
		measurements,
		controlChannel.feedbackMeasurement?.sensor?.id
	);

	useEffect(() => {
		if (isInvertEndConditionDisabled) {
			setValue(isInvertEndConditionCN, false);
		}
	}, [isInvertEndConditionCN, isInvertEndConditionDisabled, setValue]);

	return (
		<>
			<div />
			<Controller
				name={createControllerName('defMode', formPrefix)}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Definition mode*')}
						options={difinitionModeOptions}
						error={errors?.defMode?.message}
						data-testid="defMode"
						{...field}
						disabled={isRunning}
					/>
				)}
			/>
			<Controller
				name={createControllerName('direction', formPrefix)}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Starting Direction*')}
						options={directionOptions}
						error={errors?.direction?.message}
						data-testid="direction"
						{...field}
						disabled={isRunning}
					/>
				)}
			/>
			<Controller
				name={upRateTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Up Rate*')}
						options={rateTypeOptions}
						data-testid="upRateTypeCN"
						error={errors?.upRateType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{upRateType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('upRateVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								label={t('Up Rate variable*')}
								options={variablesOptions}
								error={errors?.upRateVariableId?.message}
								data-testid="upRateVariableId"
								{...field}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('upRateValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								label={t('Up Rate constant value*')}
								error={errors?.upRateValue?.message}
								data-testid="upRateValue"
							/>
						)}
					/>
				)}
				<InputText data-testid="rateUnit" disabled value={rateUnit} />
			</GroupInputFrame>
			<Controller
				name={downRateTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Down Rate*')}
						options={rateTypeOptions}
						data-testid="downRateTypeCN"
						error={errors?.downRateType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{downRateType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('downRateVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								label={t('Down Rate variable*')}
								options={variablesOptions}
								data-testid="downRateVariableId"
								error={errors?.downRateVariableId?.message}
								{...field}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('downRateValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								label={t('Down Rate constant value*')}
								data-testid="downRateValue"
								error={errors?.downRateValue?.message}
							/>
						)}
					/>
				)}
				<InputText data-testid="rateUnit" disabled value={rateUnit} />
			</GroupInputFrame>
			<Controller
				name={endChannelCN}
				control={control}
				render={({ field }) => (
					<TreeDropdown
						{...field}
						options={duoRampEndChannelTypeOptions}
						data-testid="upEndChannelCN"
						label={t('End channel*')}
						disabled={isRunning}
						error={errors?.endChannel?.message}
					/>
				)}
			/>
			<div />
			<Controller
				name={upEndChannelValueTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Upper end value*')}
						options={waveformValTypeOptions}
						data-testid="upEndChannelValueTypeCN"
						error={errors?.upEndChannelValueType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{upEndChannelValueType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('upEndChannelVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								label={t('Upper end variable*')}
								options={variablesOptions}
								data-testid="upEndChannelVariableId"
								error={errors?.upEndChannelVariableId?.message}
								{...field}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('upEndChannelValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								label={t('Upper end value*')}
								error={errors?.upEndChannelValue?.message}
								data-testid="upEndChannelValue"
							/>
						)}
					/>
				)}
				<InputText data-testid="endChannelUnit" disabled value={endChannelUnit} />
			</GroupInputFrame>
			<Controller
				name={lowerEndChannelValueTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Lower end value*')}
						options={waveformValTypeOptions}
						data-testid="lowerEndChannelValueTypeCN"
						error={errors?.lowerEndChannelValueType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{lowerEndChannelValueType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('lowerEndChannelVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								label={t('Lower end variable*')}
								options={variablesOptions}
								data-testid="lowerEndChannelVariableId"
								error={errors?.lowerEndChannelVariableId?.message}
								{...field}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('lowerEndChannelValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								label={t('Lower end value*')}
								data-testid="lowerEndChannelValue"
								error={errors?.lowerEndChannelValue?.message}
							/>
						)}
					/>
				)}
				<InputText data-testid="lowerEndChannelUnit" disabled value={endChannelUnit} />
			</GroupInputFrame>
			<Controller
				name={createControllerName('numberOfCycles', formPrefix)}
				control={control}
				render={({ field }) => (
					<InputNumber
						data-testid="numberOfCycles"
						{...field}
						label={t('No of cycles*')}
						error={errors?.numberOfCycles?.message}
						isInteger
					/>
				)}
			/>
			<Controller
				name={isInvertEndConditionCN}
				control={control}
				render={({ field }) => (
					<Switch
						label={t('Invert End Condition')}
						inputId={field.name}
						onChange={(e) => field.onChange(e.value)}
						data-testid="isInvertEndCondition"
						checked={!!field.value}
						disabled={isRunning || isInvertEndConditionDisabled}
					/>
				)}
			/>
		</>
	);
};

export default RampWaveformFormView;
