import { useEffect, useRef, useCallback } from 'react';
import { debounce, isServer } from '../utils/other';

const EVENTS = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchmove'];

const useUserActivity = (handler: () => void, ms: number) => {
	const handlerRef = useRef<{
		(...args: any): void;
		cancel: () => void;
	}>();

	const callback = useCallback(() => handlerRef.current?.(), []);

	useEffect(() => {
		if (handlerRef.current && handlerRef.current.cancel) {
			handlerRef.current.cancel();
		}
		handlerRef.current = debounce(handler, ms);
	}, [handler, ms]);

	useEffect(() => {
		EVENTS.forEach((event) => {
			if (!isServer()) {
				document.addEventListener(event, callback, true);
			}
		});

		return () => {
			EVENTS.forEach((event) => {
				if (!isServer()) {
					document.removeEventListener(event, callback);
				}
			});
		};
	}, [callback]);
};

export default useUserActivity;
