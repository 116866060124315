import React, { FormEvent, useCallback } from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { specimenCreateEditFormId } from '../../../Specimens/specimens.const';
import { specimenCreateModalId } from '../../specimenSelection.const';
import styles from './SpecimenFormModal.module.scss';
import SpecimenFormContainer from '../../../Specimens/containers/SpecimenFormContainer';

export interface SpecimenCreateModalProps {
	onSave: (event: FormEvent<HTMLFormElement>) => void;
}

const SpecimenFormModal: React.FC<SpecimenCreateModalProps> = ({ onSave }) => {
	const { t } = useTranslation('specimens');

	const handleClose = useCallback(() => Modal.hide(specimenCreateModalId), []);

	return (
		<>
			<Modal id={specimenCreateModalId} onClose={handleClose} isFitContent>
				<Modal.Header>{t('Create a Specimen')}</Modal.Header>
				<Modal.Content className={styles.container}>
					<SpecimenFormContainer onSave={onSave} isForDashboard />
				</Modal.Content>
				<Modal.Footer>
					<Button className="btnCancel" onClick={handleClose} color="success" variant="text">
						{t('Cancel')}
					</Button>
					<Button type="submit" color="secondary" variant="contained" form={specimenCreateEditFormId}>
						{t('Save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default React.memo(SpecimenFormModal);
