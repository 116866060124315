import { useQuery } from '@tanstack/react-query';
import { ControlChannelResponseDto } from './controlChannel.types';
import * as api from './controlChannel.api';

export const useControlChannel = (controlChannelId?: string) => {
	const { data: controlChannel } = useQuery<ControlChannelResponseDto, Error>({
		queryKey: ['controlChannel', { controlChannelId }],
		queryFn: async () => {
			const response = await api.getControlChannelById(controlChannelId);
			return response;
		},
		enabled: !!controlChannelId
	});

	return {
		controlChannel
	};
};

export const useAxisControlChannels = (axisId?: string) => {
	const { data: controlChannels } = useQuery<ControlChannelResponseDto[], Error>({
		queryKey: ['controlChannels', { axisId }],
		queryFn: async () => {
			const response = await api.getControlChannelsByAxisId(axisId);
			return response;
		},
		enabled: !!axisId
	});

	return {
		controlChannels
	};
};

export const useControlChannels = (stationId?: string) => {
	const { data, isLoading } = useQuery<ControlChannelResponseDto[], Error>({
		queryKey: ['controlChannels', stationId],
		queryFn: async () => {
			return await api.getControlChannels(stationId);
		},
		enabled: !!stationId
	});

	return {
		controlChannels: data,
		isControlChannelsLoading: isLoading
	};
};
