import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEntitiesAsList } from '../../../hooks';
import { useSensors } from '../../Sensors';
import CalibrationContainer from '../../Calibrations/containers/CalibrationContainer';
import WidgetContext from '../../Dashboard/components/Widget/widgetContext';
import WidgetHeader from '../../Dashboard/components/Widget/WidgetHeader';
import { WidgetProps } from '../../Dashboard/dashboard.types';
import CalibrationWidgetDialog from '../components/CalibrationWidgetDialog/CalibrationWidgetDialog';
import SensorList from '../components/SensorList';
import { useSaveCalibration } from '../sensorCalibrationWidget.hooks';
import { toast } from 'react-toastify';
import { CalibrationRequestDto } from '../../Calibrations';
import { useStationId } from '../../Stations';

const SensorCalibrationWidgetContainer: React.FC<WidgetProps> = () => {
	const { t } = useTranslation('calibrations');
	const { id, removeWidget } = useContext(WidgetContext);
	const stationId = useStationId();
	const { sensors } = useSensors(stationId);
	const sensorsList = useEntitiesAsList(sensors);
	const [sensorId, setSensorId] = useState<string | null>(null);
	/* const { usedSensors, getUsedSensorsError } = useGetUsedSensors(stationId); */
	const { saveCalibration, saveError } = useSaveCalibration();

	const handleHide = useCallback(() => {
		removeWidget(id);
	}, [id, removeWidget]);

	const handleSensorChange = useCallback((sensorId: string | null) => {
		setSensorId(sensorId);
	}, []);

	const handleSave = useCallback(
		(calibration: CalibrationRequestDto, calibrationId?: string) => {
			if (stationId) {
				saveCalibration(calibration, stationId, calibrationId);
			}
		},
		[saveCalibration, stationId]
	);

	/* const filteredSensorsList = useMemo(() => {
		return sensorsList.map((sensor) =>
			usedSensors.some((sensorId) => sensorId === sensor.value) ? { ...sensor, isDisabled: true } : sensor
		);
	}, [sensorsList, usedSensors]); */

	useEffect(() => {
		/* if (getUsedSensorsError) {
			toast.error(`Error code-${getUsedSensorsError?.code}; Message-${getUsedSensorsError?.message}`);
		} */
		if (saveError) {
			toast.error(`Error code-${saveError?.code}; Message-${saveError?.message}`);
		}
	}, [saveError]);

	return (
		<>
			<WidgetHeader disableMinimize>{t('Sensor Calibration')}</WidgetHeader>
			<CalibrationWidgetDialog onHide={handleHide} title={t('Sensor Calibration')}>
				<SensorList sensors={sensorsList} sensorId={sensorId} onSensorChange={handleSensorChange} />
				<CalibrationContainer
					sensorId={sensorId !== null ? sensorId : undefined}
					stationId={stationId}
					isInWidget
					onSave={handleSave}
				/>
			</CalibrationWidgetDialog>
		</>
	);
};

export default SensorCalibrationWidgetContainer;
