import { Converter } from '../../types';
import { QuantityFormType, QuantityRequestDto, QuantityResponseDto, UnitRequest } from './units.types';

export const responseFormConverter: Converter<QuantityResponseDto, QuantityFormType> = (response) => {
	return {
		id: response.id,
		name: response.name,
		units: response.units.map((unit) => ({
			id: unit.id,
			name: unit.name,
			offset: unit.offset,
			scale: unit.scale,
			systemType: unit.systemType,
			isDefault: unit.isDefault,
			parentId: response.id
		}))
	};
};

export const formRequestConverter: Converter<QuantityFormType, QuantityRequestDto> = (form) => {
	return {
		name: form.name,
		units: form.units.map(
			(unit) =>
				({
					id: unit.id,
					name: unit.name,
					offset: unit.offset,
					scale: unit.scale,
					systemType: unit.systemType,
					isDefault: unit.isDefault
				}) as UnitRequest
		)
	};
};
