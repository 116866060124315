import { Converter } from '../../types';
import { CalculationTypes, LimitDefinitions } from './calculation.enums';
import {
	CalculationFormType,
	CalculationListItem,
	CalculationRequestDto,
	CalculationResponseDto,
	DataPointCalculationDto,
	EnergyCalculationDto,
	MaxLoadOverWidthCalculationDto,
	MeasurementAtBreakCalculationDto,
	MeasurementAtPeakLoadCalculationDto,
	MeasurementAtYieldPointCalculationDto,
	ModulusOfElasticityCalculationDto,
	StatisticalMaxCalculationDto,
	StatisticalMeanCalculationDto,
	StatisticalMinCalculationDto,
	YieldOffsetCalculationDto
} from './calculation.types';
import { RuleRequestDtoBase, RuleResponseDtoBase } from '../Rules';
import {
	PeakLoadCalculation,
	StageCalculations,
	StageCalculationType,
	StaticCalculation,
	StaticCalculationType
} from '@tactun/grpc-client';
import { CalculationActionDto } from '../Actions';

export const responsesListItemConverter: Converter<
	CalculationResponseDto & {
		unitName?: string;
	},
	CalculationListItem
> = (response) => {
	return {
		id: response.uuid,
		name: response.name,
		type: CalculationTypes[response.calculation.type],
		unit: response.unitName
	};
};

export const responseFormConverter: Converter<CalculationResponseDto, CalculationFormType> = (resp) => {
	const form = {
		id: resp.uuid,
		name: resp.name,
		stationId: resp.stationUuid,
		type: resp.calculation.type,
		successFailCriteria: resp.calculation.successCheck,
		nominalValue: resp.calculation.nominalValue as number,
		variation: resp.calculation.variation as number,
		upperLimit: resp.calculation.absoluteUpperLimit,
		lowerLimit: resp.calculation.absoluteLowerLimit,
		limitDefinition:
			resp.calculation.nominalAbsoluteValue === true
				? LimitDefinitions.NOMINAL
				: resp.calculation.nominalAbsoluteValue === false
				? LimitDefinitions.ABSOLUTE
				: undefined
	};
	switch (resp.calculation.type) {
		case CalculationTypes.MEASUREMENT_AT_PEAK_LOAD:
			return {
				...form,
				measurementId1: resp.calculation.measurement,
				measurementId2: resp.calculation.loadMeasurement,
				unit: resp.calculation.unit
			};

		case CalculationTypes.MODULUS_OF_ELASTICITY:
			return {
				...form,
				measurementId1: resp.calculation.stressMeasurement,
				measurementId2: resp.calculation.strainMeasurement,
				unit: resp.calculation.unit,
				limitType: resp.calculation.limitSpec,
				yieldUpperLimit: resp.calculation.upperLimit,
				yieldLowerLimit: resp.calculation.lowerLimit,
				limitUnit: resp.calculation.limitUnit
			};

		case CalculationTypes.YIELD_OFFSET:
			return {
				...form,
				measurementId1: resp.calculation.stressMeasurement,
				measurementId2: resp.calculation.strainMeasurement,
				unit: resp.calculation.unit,
				typeOffset: resp.calculation.offset,
				limitType: resp.calculation.limitSpec,
				yieldUpperLimit: resp.calculation.upperLimit,
				yieldLowerLimit: resp.calculation.lowerLimit,
				limitUnit: resp.calculation.limitUnit
			};

		case CalculationTypes.MEASUREMENT_AT_YIELD:
			return {
				...form,
				unit: resp.calculation.unit,
				unit2: resp.calculation.yield.unit,
				measurementId1: resp.calculation.measurement,
				measurementId2: resp.calculation.yield.stressMeasurement,
				measurementId3: resp.calculation.yield.strainMeasurement,
				limitType: resp.calculation.yield.limitSpec,
				typeOffset: resp.calculation.yield.offset,
				yieldUpperLimit: resp.calculation.yield.upperLimit,
				yieldLowerLimit: resp.calculation.yield.lowerLimit,
				limitUnit: resp.calculation.yield.limitUnit
			};

		case CalculationTypes.ENERGY:
			return {
				...form,
				measurementId1: resp.calculation.xaxisMeasurement,
				measurementId2: resp.calculation.yaxisMeasurement,
				unit: resp.calculation.unit
			};

		case CalculationTypes.MEASUREMENT_AT_BREAK:
			return {
				...form,
				measurementId1: resp.calculation.measurement,
				unit: resp.calculation.unit
			};

		case CalculationTypes.MAXIMUM_LOAD_OVER_WIDTH:
			return {
				...form,
				measurementId1: resp.calculation.loadMeasurement,
				unit: resp.calculation.unit
			};

		case CalculationTypes.DATA_POINT:
			return {
				...form,
				unit: resp.calculation.unit,
				measurementOrAxesId1: resp.calculation.dataChannel,
				ch1Type: resp.calculation.channelType,
				refPoint: resp.calculation.refPoint,
				measurementOrAxesId2: resp.calculation.refMeasurement,
				ch2Type: resp.calculation.refMeasurementType,
				refValue: resp.calculation.refValue,
				refUnit: resp.calculation.refUnit
			};

		case CalculationTypes.MAXIMUM:
			return {
				...form,
				measurementId1: resp.calculation.measurement,
				unit: resp.calculation.unit,
				statCalcRange: resp.calculation.range,
				measurementOrAxesId1: resp.calculation.startChannel,
				ch1Type: resp.calculation.startChannelType,
				startVal: resp.calculation.startValue,
				startValUnit: resp.calculation.startValueUnit,
				measurementOrAxesId2: resp.calculation.endChannel,
				ch2Type: resp.calculation.endChannelType,
				endVal: resp.calculation.endsValue,
				endValUnit: resp.calculation.endValueUnit
			};

		case CalculationTypes.MINIMUM:
			return {
				...form,
				measurementId1: resp.calculation.measurement,
				unit: resp.calculation.unit,
				statCalcRange: resp.calculation.range,
				measurementOrAxesId1: resp.calculation.startChannel,
				ch1Type: resp.calculation.startChannelType,
				startVal: resp.calculation.startValue,
				startValUnit: resp.calculation.startValueUnit,
				measurementOrAxesId2: resp.calculation.endChannel,
				ch2Type: resp.calculation.endChannelType,
				endVal: resp.calculation.endsValue,
				endValUnit: resp.calculation.endValueUnit
			};

		case CalculationTypes.MEAN:
			return {
				...form,
				measurementId1: resp.calculation.measurement,
				unit: resp.calculation.unit,
				statCalcRange: resp.calculation.range,
				measurementOrAxesId1: resp.calculation.startChannel,
				ch1Type: resp.calculation.startChannelType,
				startVal: resp.calculation.startValue,
				startValUnit: resp.calculation.startValueUnit,
				measurementOrAxesId2: resp.calculation.endChannel,
				ch2Type: resp.calculation.endChannelType,
				endVal: resp.calculation.endsValue,
				endValUnit: resp.calculation.endValueUnit
			};
	}
};

export const formRequestConverter: Converter<CalculationFormType, CalculationRequestDto> = (input) => {
	const request = {
		name: input.name,
		stationUuid: input.stationId
	};
	const calculation = {
		type: input.type,
		successCheck: input.successFailCriteria,
		nominalValue: input.nominalValue as number,
		variation: input.variation as number,
		absoluteUpperLimit: input.upperLimit,
		absoluteLowerLimit: input.lowerLimit,
		nominalAbsoluteValue:
			input.limitDefinition === LimitDefinitions.NOMINAL
				? true
				: input.limitDefinition === LimitDefinitions.ABSOLUTE
				? false
				: undefined
	};

	switch (input.type) {
		case CalculationTypes.MEASUREMENT_AT_PEAK_LOAD:
			return {
				...request,
				calculation: {
					...calculation,
					measurement: input.measurementId1,
					loadMeasurement: input.measurementId2,
					unit: input.unit
				} as MeasurementAtPeakLoadCalculationDto
			};

		case CalculationTypes.MODULUS_OF_ELASTICITY:
			return {
				...request,
				calculation: {
					...calculation,
					stressMeasurement: input.measurementId1,
					strainMeasurement: input.measurementId2,
					unit: input.unit,
					limitSpec: input.limitType,
					upperLimit: input.yieldUpperLimit,
					lowerLimit: input.yieldLowerLimit,
					limitUnit: input.limitUnit
				} as ModulusOfElasticityCalculationDto
			};

		case CalculationTypes.YIELD_OFFSET:
			return {
				...request,
				calculation: {
					...calculation,
					stressMeasurement: input.measurementId1,
					strainMeasurement: input.measurementId2,
					unit: input.unit,
					offset: input.typeOffset,
					limitSpec: input.limitType,
					upperLimit: input.yieldUpperLimit,
					lowerLimit: input.yieldLowerLimit,
					limitUnit: input.limitUnit
				} as YieldOffsetCalculationDto
			};

		case CalculationTypes.MEASUREMENT_AT_YIELD:
			return {
				...request,
				calculation: {
					...calculation,
					measurement: input.measurementId1,
					unit: input.unit,
					yield: {
						unit: input.unit2,
						stressMeasurement: input.measurementId2,
						strainMeasurement: input.measurementId3,
						limitSpec: input.limitType,
						offset: input.typeOffset,
						upperLimit: input.yieldUpperLimit,
						lowerLimit: input.yieldLowerLimit,
						limitUnit: input.limitUnit
					}
				} as MeasurementAtYieldPointCalculationDto
			};

		case CalculationTypes.ENERGY:
			return {
				...request,
				calculation: {
					...calculation,
					xaxisMeasurement: input.measurementId1,
					yaxisMeasurement: input.measurementId2,
					unit: input.unit
				} as EnergyCalculationDto
			};

		case CalculationTypes.MEASUREMENT_AT_BREAK:
			return {
				...request,
				calculation: {
					...calculation,
					measurement: input.measurementId1,
					unit: input.unit
				} as MeasurementAtBreakCalculationDto
			};

		case CalculationTypes.MAXIMUM_LOAD_OVER_WIDTH:
			return {
				...request,
				calculation: {
					...calculation,
					loadMeasurement: input.measurementId1,
					unit: input.unit
				} as MaxLoadOverWidthCalculationDto
			};

		case CalculationTypes.DATA_POINT:
			return {
				...request,
				calculation: {
					...calculation,
					unit: input.unit,
					dataChannel: input.measurementOrAxesId1,
					channelType: input.ch1Type,
					refPoint: input.refPoint,
					refMeasurement: input.measurementOrAxesId2,
					refMeasurementType: input.ch2Type,
					refValue: input.refValue,
					refUnit: input.refUnit
				} as DataPointCalculationDto
			};

		case CalculationTypes.MAXIMUM:
			return {
				...request,
				calculation: {
					...calculation,
					measurement: input.measurementId1,
					unit: input.unit,
					range: input.statCalcRange,
					startChannel: input.measurementOrAxesId1,
					startChannelType: input.ch1Type,
					startValue: input.startVal,
					startValueUnit: input.startValUnit,
					endChannel: input.measurementOrAxesId2,
					endChannelType: input.ch2Type,
					endsValue: input.endVal,
					endValueUnit: input.endValUnit
				} as StatisticalMaxCalculationDto
			};

		case CalculationTypes.MINIMUM:
			return {
				...request,
				calculation: {
					...calculation,
					measurement: input.measurementId1,
					unit: input.unit,
					range: input.statCalcRange,
					startChannel: input.measurementOrAxesId1,
					startChannelType: input.ch1Type,
					startValue: input.startVal,
					startValueUnit: input.startValUnit,
					endChannel: input.measurementOrAxesId2,
					endChannelType: input.ch2Type,
					endsValue: input.endVal,
					endValueUnit: input.endValUnit
				} as StatisticalMinCalculationDto
			};

		case CalculationTypes.MEAN:
			return {
				...request,
				calculation: {
					...calculation,
					measurement: input.measurementId1,
					unit: input.unit,
					range: input.statCalcRange,
					startChannel: input.measurementOrAxesId1,
					startChannelType: input.ch1Type,
					startValue: input.startVal,
					startValueUnit: input.startValUnit,
					endChannel: input.measurementOrAxesId2,
					endChannelType: input.ch2Type,
					endsValue: input.endVal,
					endValueUnit: input.endValUnit
				} as StatisticalMeanCalculationDto
			};
	}
};

export const calculationActionResponseToGRPCRequestConverter: Converter<
	RuleResponseDtoBase | RuleRequestDtoBase,
	StageCalculations
> = (calculationAction) => {
	const stageCalculation = new StageCalculations();

	const action = calculationAction.rulePositiveActions?.[0] as CalculationActionDto;
	const calculation = action.calculation;
	if (action.id) stageCalculation.setId(action.id);
	stageCalculation.setName(calculationAction.name);

	if (calculation) {
		if (calculation?.unit) {
			stageCalculation.setUnit(calculation?.unit);
		}

		if (calculation.type === CalculationTypes.MEASUREMENT_AT_PEAK_LOAD) {
			stageCalculation.setStageCalcType(StageCalculationType.PEAK_LOAD);

			const peakLoadCalc = new PeakLoadCalculation();

			peakLoadCalc.setRefChannelId(calculation.loadMeasurement);
			peakLoadCalc.setMeasChannelId(calculation.measurement);

			stageCalculation.setPeakLoadCalc(peakLoadCalc);
		}

		if (
			calculation.type === CalculationTypes.MEAN ||
			calculation.type === CalculationTypes.MAXIMUM ||
			calculation.type === CalculationTypes.MINIMUM
		) {
			stageCalculation.setStageCalcType(StageCalculationType.STATIC);

			const staticCalculation = new StaticCalculation();

			staticCalculation.setRange(calculation.range);
			staticCalculation.setMeasChannelId(calculation.measurement);

			if (calculation.type === CalculationTypes.MEAN) {
				staticCalculation.setCalcType(StaticCalculationType.MEAN);
			} else if (calculation.type === CalculationTypes.MAXIMUM) {
				staticCalculation.setCalcType(StaticCalculationType.MAX);
			} else {
				staticCalculation.setCalcType(StaticCalculationType.MIN);
			}

			stageCalculation.setStaticCalc(staticCalculation);
		}
	}

	return stageCalculation;
};

export const calculationActionsResponseToGRPCRequestConverter: Converter<
	(RuleResponseDtoBase | RuleRequestDtoBase)[],
	Array<StageCalculations>
> = (calculationActions) => {
	return calculationActions.map((calculationAction) =>
		calculationActionResponseToGRPCRequestConverter(calculationAction)
	);
};
