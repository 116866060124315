import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { usePreviewsValue } from '@tactun/ui';
import classNames from 'classnames';

import { ICustomWidgetForm, ISubframeForm } from '../../../CustomWidgets/customWidget.types';
import CustomButton from '../CustomButton';
import DragAndDrop from '../DragAndDrop';
import CustomButtonGroup from '../CustomButtonGroup';
import { ButtonStatus, FrameOrientation } from '../../buttonWidget.enums';
import styles from './ButtonWidgetSubframePreview.module.scss';
import { WidgetsLayoutTypes } from '../../../CustomWidgets';
import { buttonFormToButtonStatus } from '../../buttonWidget.converters';

interface IActionButtonProps {
	subFrame: ISubframeForm;
	width: number;
	height: number;
	blockWidth?: boolean;
	blockHeight?: boolean;
	updateSize: (index: number, { width, height }: { width?: number; height?: number }) => void;
	index: number;
	selected: boolean;
}

const MIN_BUTTON_HEIGHT = 48;
const MIN_BUTTON_WIDTH = 48;

const ButtonWidgetSubframePreview: React.FC<IActionButtonProps> = (props) => {
	const { subFrame, width, height, blockWidth, blockHeight, updateSize, index, selected } = props;
	const { numberOfButtons, orientation } = subFrame;
	const { setValue, control, getValues } = useFormContext<ICustomWidgetForm>();

	const layoutType = useWatch({ name: 'layoutType', control });

	const onMove = useCallback(
		(from: number, to: number) => {
			const current = getValues(`subFrames.${index}.buttons`);
			const newButtons = current ? [...current] : [];
			const [removed] = newButtons.splice(from, 1);
			newButtons.splice(to, 0, removed);

			setValue(`subFrames.${index}.buttons`, newButtons);
			setValue('activeButton', to);
		},
		[getValues, index, setValue]
	);

	const selectedButton = useWatch({ name: 'activeButton', control });
	const onButtonClick = (buttonIndex: number) => {
		setValue('activeSubframe', index);
		setValue('activeButton', buttonIndex);
	};

	// single button size in %
	const buttonSize = useMemo(
		() => ({
			width:
				orientation === FrameOrientation.HORIZONTAL && numberOfButtons
					? Math.floor(10 * (100 / numberOfButtons)) / 10
					: 100,
			height:
				orientation === FrameOrientation.VERTICAL && numberOfButtons
					? Math.floor(10 * (100 / numberOfButtons)) / 10
					: 100
		}),
		[numberOfButtons, orientation]
	);

	// Update subFrame size on button count change
	const previousNumberOfButtons = usePreviewsValue(numberOfButtons);
	useEffect(() => {
		if (!numberOfButtons || previousNumberOfButtons === numberOfButtons || numberOfButtons < 1 || !height) return;

		if (orientation === FrameOrientation.HORIZONTAL) {
			const newWidth =
				blockWidth || !previousNumberOfButtons || previousNumberOfButtons < 1
					? MIN_BUTTON_WIDTH * numberOfButtons
					: (width / previousNumberOfButtons) * numberOfButtons;
			if (!blockWidth || newWidth > width) updateSize(index, { width: newWidth });
		} else {
			const newHeight =
				blockHeight || !previousNumberOfButtons || previousNumberOfButtons < 1
					? MIN_BUTTON_HEIGHT * numberOfButtons
					: (height / previousNumberOfButtons) * numberOfButtons;

			if (!blockHeight || newHeight > height) updateSize(index, { height: newHeight });
		}
	}, [
		previousNumberOfButtons,
		numberOfButtons,
		orientation,
		height,
		updateSize,
		blockHeight,
		blockWidth,
		width,
		index
	]);

	const buttonCompletePlace = useMemo(() => {
		if (index === 0) return subFrame.orientation === FrameOrientation.HORIZONTAL ? styles.top : styles.left;
		if (index === 2) return subFrame.orientation === FrameOrientation.HORIZONTAL ? styles.bottom : styles.left;
		if (index === 3) return subFrame.orientation === FrameOrientation.HORIZONTAL ? styles.bottom : styles.right;
		if (layoutType === WidgetsLayoutTypes.DOUBLE_VERTICAL)
			return subFrame.orientation === FrameOrientation.HORIZONTAL ? styles.bottom : styles.left;
		return subFrame.orientation === FrameOrientation.HORIZONTAL ? styles.top : styles.right;
	}, [layoutType, index, subFrame.orientation]);

	// Update subFrame size on orientation change
	const previousOrientation = usePreviewsValue(orientation);
	useEffect(() => {
		if (previousOrientation === undefined || !numberOfButtons || previousOrientation === orientation) return;

		if (orientation === FrameOrientation.HORIZONTAL) {
			// const newWidth = blockWidth ? MIN_BUTTON_WIDTH * numberOfButtons : width * numberOfButtons;
			// const newHeight = blockHeight ? MIN_BUTTON_HEIGHT : height / numberOfButtons;
			// updateSize(index, {
			// 	width: !blockWidth || newWidth > width ? newWidth : width,
			// 	height: !blockHeight || newHeight > height ? newHeight : height
			// });
			const newWidth = MIN_BUTTON_WIDTH * numberOfButtons;
			if (newWidth > width) updateSize(index, { width: newWidth });
		} else {
			// const newWidth = blockWidth ? MIN_BUTTON_WIDTH : width / numberOfButtons;
			// const newHeight = blockHeight ? MIN_BUTTON_HEIGHT * numberOfButtons : height * numberOfButtons;
			// updateSize(index, {
			// 	width: !blockWidth || newWidth > width ? newWidth : width,
			// 	height: !blockHeight || newHeight > height ? newHeight : height
			// });
			const newHeight = MIN_BUTTON_HEIGHT * numberOfButtons;
			if (newHeight > height) updateSize(index, { height: newHeight });
		}
	}, [blockWidth, blockHeight, width, height, numberOfButtons, orientation, previousOrientation, updateSize, index]);

	return (
		<CustomButtonGroup subFrame={subFrame}>
			{subFrame.buttons?.map((button, buttonIndex) => (
				<DragAndDrop
					key={buttonIndex}
					index={buttonIndex}
					group={`subframe-${index}`}
					move={onMove}
					style={{ width: `${buttonSize.width}%`, height: `${buttonSize.height}%` }}
				>
					<CustomButton
						key={buttonIndex}
						button={button}
						selected={selected && selectedButton === buttonIndex}
						onClick={() => onButtonClick(buttonIndex)}
						className={classNames(buttonCompletePlace, {
							[styles.buttonSuccessIcon]: buttonFormToButtonStatus(button) === ButtonStatus.CONFIGURED
						})}
					/>
				</DragAndDrop>
			))}
		</CustomButtonGroup>
	);
};

export default React.memo(ButtonWidgetSubframePreview);
