import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { StageTypes } from '../Tests/tests.enums';
import { TestRuleResponseDto, RuleResponseDtoBase, RuleRequestDtoBase } from '../Rules';
import {
	getStageActions,
	updateTestAction,
	getTestAction,
	getTestExistingActions,
	getTestCalculation
} from './testActions.api';
import { ActionGroupDto } from './testActions.types';
import { CalculationResponseDto } from '../Calculations';

export const useCurrentStageActions = (
	testId?: string,
	stageId?: string,
	stageType?: StageTypes,
	onSaveSuccess?: () => void
) => {
	const queryClient = useQueryClient();

	const { data: actionGroup, isLoading } = useQuery<ActionGroupDto>({
		queryKey: ['test-stage-actions', { stageId, stageType }],
		queryFn: () => getStageActions(testId as string, stageId as string, stageType as StageTypes),
		enabled: !!testId && !!stageId && stageType !== undefined
	});

	const updateMutation = useMutation({
		mutationFn: (actionGroup: ActionGroupDto) => {
			return updateTestAction(actionGroup);
		},
		mutationKey: ['test-stage-actions-update'],
		onSuccess: (updated) => {
			queryClient.invalidateQueries({ queryKey: ['test-stages', { testId }] });
			if (onSaveSuccess) onSaveSuccess();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const saveActions = useCallback(() => {
		if (actionGroup) {
			updateMutation.mutate(actionGroup);
		}
	}, [actionGroup, updateMutation]);

	const saveAction = useCallback(
		(data: RuleRequestDtoBase, index?: number, isCopy?: boolean) => {
			queryClient.setQueryData<ActionGroupDto>(['test-stage-actions', { stageId, stageType }], (old) => {
				if (!old) return undefined;
				if (index !== undefined) {
					return {
						...old,
						actions: old.actions.map((item, i) => (index === i ? data : item))
					};
				} else {
					return {
						...old,
						actions: [...old.actions, data]
					};
				}
			});
		},
		[queryClient, stageId, stageType]
	);

	const updateInParallel = useCallback(
		(inParallel: boolean) => {
			queryClient.setQueryData<ActionGroupDto>(['test-stage-actions', { stageId, stageType }], (old) => {
				return old
					? {
							...old,
							runInParallel: inParallel
					  }
					: old;
			});
		},
		[queryClient, stageId, stageType]
	);

	const deleteAction = useCallback(
		(index: number) => {
			queryClient.setQueryData<ActionGroupDto>(['test-stage-actions', { stageId, stageType }], (old) => {
				if (!old) return;
				const actions = [...old.actions];
				actions.splice(index, 1);
				return {
					...old,
					actions
				};
			});
		},
		[queryClient, stageId, stageType]
	);

	const moveAction = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			queryClient.setQueryData<ActionGroupDto>(['test-stage-actions', { stageId, stageType }], (old) => {
				if (!old) return;
				const oldActions = old.actions;

				const actions = [...oldActions.slice(0, dragIndex), ...oldActions.slice(dragIndex + 1)];
				actions.splice(hoverIndex, 0, oldActions[dragIndex]);
				return {
					...old,
					actions
				};
			});
		},
		[queryClient, stageId, stageType]
	);

	return useMemo(
		() => ({
			isLoading,
			inParallel: true, //actionGroup?.runInParallel,
			updateInParallel: updateInParallel,
			isUpdating: updateMutation.isPending,
			actions: actionGroup?.actions || [],
			saveAction,
			moveAction,
			saveActions,
			deleteAction
		}),
		[
			actionGroup?.actions,
			deleteAction,
			isLoading,
			moveAction,
			saveAction,
			saveActions,
			updateInParallel,
			updateMutation.isPending
		]
	);
};

export const useTestExistingActions = (testId?: string, stageId?: string, stageType?: StageTypes) => {
	const { data: testActions, isLoading: isTestActionsLoading } = useQuery<(RuleResponseDtoBase | RuleRequestDtoBase)[]>(
		{
			queryKey: ['test-stage-actions', { testId }],
			queryFn: async () => {
				const actionGroups = await getTestExistingActions(testId as string);

				const actions = actionGroups
					.filter((actionGroup) => actionGroup.stageUuid !== stageId || actionGroup.location !== stageType)
					.reduce((arr: (RuleResponseDtoBase | RuleRequestDtoBase)[], { actions }) => [...arr, ...actions], []);
				return actions;
			},
			enabled: !!testId
		}
	);

	return {
		testActions: testActions || [],
		isTestActionsLoading
	};
};

export const useAction = (actionId?: string, isAction: boolean = true) => {
	const { data: action, isLoading: isActionLoading } = useQuery<TestRuleResponseDto>({
		queryKey: ['test-stage-actions', { actionId }],
		queryFn: () => getTestAction(actionId as string),
		enabled: !!actionId && isAction
	});

	const { data: calculation, isLoading: isCalculationLoading } = useQuery<CalculationResponseDto>({
		queryKey: ['test-stage-actions', { actionId }],
		queryFn: () => getTestCalculation(actionId as string),
		enabled: !!actionId && !isAction
	});

	return {
		action: action || calculation,
		isActionLoading: isActionLoading || isCalculationLoading
	};
};
