export enum RuleConditionType {
	CONDITIONAL = 0,
	UNCONDITIONAL
}

export enum RuleStatus {
	ENABLED = 0,
	DISABLED
}

export enum RuleType {
	STATION = 0,
	TEST,
	STAGE
}
