import {
	formRequestConverter as measurementFormRequestConverter,
	responseFormConverter as measurementResponseFormConverter
} from '../Measurements';
import {
	TestMeasurementRequestDto,
	TestMeasurementResponseDto,
	TestMeasurementFormType
} from './testMeasurements.types';

export const formRequestConverter = (form: TestMeasurementFormType): TestMeasurementRequestDto => {
	const { stationId, ...measurementDto } = measurementFormRequestConverter({
		...form,
		stationId: ''
	});

	return {
		...measurementDto,
		testId: form.testId
	};
};

export const responseFormConverter = (input: TestMeasurementResponseDto): TestMeasurementFormType => {
	const { stationId, ...measurementForm } = measurementResponseFormConverter({
		...input,
		stationId: ''
	});

	return {
		...measurementForm,
		testId: input.testId
	};
};
