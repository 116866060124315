import { SensorsTypes } from '../../sensors.enums';
import { SensorFormRelated } from '../../sensors.types';

export type SensorFieldMapping = {
	[Key in keyof SensorFormRelated]?: SensorsTypes[];
};

export const sensorFieldMapping: SensorFieldMapping = {
	isAveragingFilter: [SensorsTypes.LOAD_CELL, SensorsTypes.GENERIC_SENSOR, SensorsTypes.LVDT],
	averagingFilterPoint: [SensorsTypes.LOAD_CELL, SensorsTypes.GENERIC_SENSOR, SensorsTypes.LVDT]
};

export const isFieldVisible = (field: keyof Required<SensorFieldMapping>, type: SensorsTypes | undefined): boolean => {
	if (type === undefined) return false;

	return !!sensorFieldMapping[field]?.some((f) => f === type);
};
