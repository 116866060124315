import { DashboardRequestDto } from './dashboard.types';
import axios from '../../httpCommon';

export const getDashboard = async (id: string) => {
	const response = await axios.get('/cassiopeia/dashboard/me/latest', {
		params: {
			originUuid: id
		}
	});
	return response.data.response;
};

export const createDashboard = async (dashboard: DashboardRequestDto) => {
	const response = await axios.post('/cassiopeia/dashboard', dashboard);
	return response.data.response;
};

export const updateDashboard = async (dashboard: Partial<DashboardRequestDto>) => {
	const response = await axios.put('/cassiopeia/dashboard', {
		uuid: dashboard.uuid,
		widgets: dashboard.widgets
	});
	return response.data.response;
};
