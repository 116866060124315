import React, { useEffect, useMemo } from 'react';
import {
	Form,
	InputText,
	Dropdown,
	InputNumber,
	RadioButton,
	useIsChanged,
	DropdownOptionNumber,
	DropdownOptionString,
	GroupInputFrame
} from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { CalculationFormType } from '../../calculation.types';
import { IListItemNumber } from '../../../../types';
import { MeasurementResponseDto } from '../../../Measurements';
import { QuantityResponseDto } from '../../../Units';
import { useEntitiesAsList, useNumberEnumList } from '../../../../hooks';
import styles from './Calculation.module.scss';
import { useMeasurementAndAxesList, useMeasurementLabelsByType, useUnitOfLimits } from '../../calculation.hooks';
import { CalculationTypes, LimitDefinitions, LimitTypes } from '../../calculation.enums';
import { STATISTICAL_TYPES_LIST } from '../../calculation.const';
import StatisticalCalculation from '../StatisticalCalculation';
import DataPointCalculation from '../DataPointCalculation';
import { IAxisResponseDto } from '../../../Axes';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';

interface ICalculationProps {
	formId: string;
	form: UseFormReturn<CalculationFormType>;
	calculationTypes: DropdownOptionNumber[];
	measurements?: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	axes: IAxisResponseDto[];
	quantities: QuantityResponseDto[];
	referencePointTypes: IListItemNumber[];
	statisticalCalcRanges: IListItemNumber[];
	onSave: () => void;
}

const typesWithYieldLimits = [
	CalculationTypes.MODULUS_OF_ELASTICITY,
	CalculationTypes.YIELD_OFFSET,
	CalculationTypes.MEASUREMENT_AT_YIELD
];

const typesWithNotFilteredUnits = [
	CalculationTypes.MAXIMUM_LOAD_OVER_WIDTH,
	CalculationTypes.MODULUS_OF_ELASTICITY,
	CalculationTypes.ENERGY
];

const Calculation: React.FC<ICalculationProps> = ({
	calculationTypes,
	measurements,
	quantities,
	axes,
	referencePointTypes,
	statisticalCalcRanges,
	form,
	formId,
	onSave
}) => {
	const { t } = useTranslation('calculation');
	const {
		control,
		formState: { errors, isDirty, dirtyFields },
		register,
		watch,
		setValue
	} = form;

	const type = watch('type');
	const successFailCriteria = watch('successFailCriteria');
	const limitDefinition = watch('limitDefinition');
	const limitType = watch('limitType');
	const measurementId1 = watch('measurementId1');
	const measurementId2 = watch('measurementId2');
	const measurementId3 = watch('measurementId3');
	const measurementOrAxesId1 = watch('measurementOrAxesId1');
	const unit = watch('unit');
	const isSuccessFailCriteriaChanged = useIsChanged(successFailCriteria);
	const isLimitDefinitionChanged = useIsChanged(limitDefinition);
	const isStatistical = useMemo(() => STATISTICAL_TYPES_LIST.some((st) => st === type), [type]);

	// Reset successFailCriteria section values
	useEffect(() => {
		if (isSuccessFailCriteriaChanged && isDirty) {
			setValue('limitDefinition', successFailCriteria ? LimitDefinitions.NOMINAL : undefined);
			setValue('nominalValue', undefined);
			setValue('variation', undefined);
			setValue('upperLimit', undefined);
			setValue('lowerLimit', undefined);
		}
		if (isLimitDefinitionChanged && isDirty) {
			setValue('nominalValue', undefined);
			setValue('variation', undefined);
			setValue('upperLimit', undefined);
			setValue('lowerLimit', undefined);
		}
	}, [isDirty, isLimitDefinitionChanged, isSuccessFailCriteriaChanged, setValue, successFailCriteria]);

	const measurementsList = useEntitiesAsList(measurements);
	const [measurementLabel1, measurementLabel2, measurementLabel3] = useMeasurementLabelsByType(type);
	const unitOfLimits = useUnitOfLimits(measurements, measurementId1, measurementId2, measurementId3, limitType, type);
	const unitName = unitOfLimits?.name ? ` ${unitOfLimits?.name}` : '';

	useEffect(() => {
		if (unitOfLimits) {
			setValue('limitUnit', unitOfLimits.id);
		}
	}, [setValue, unitOfLimits]);

	const selectedUnitName = useMemo<string>(() => {
		let unitName = '';
		quantities.forEach((q) =>
			q.units.forEach((u) => {
				if (u.id === unit) {
					unitName = ` ${u.name}`;
				}
			})
		);
		return unitName;
	}, [quantities, unit]);

	const limitTypes = useNumberEnumList(LimitTypes);

	const quantitiesOptions = useMemo<DropdownOptionString[]>(() => {
		const quantityId =
			!typesWithNotFilteredUnits.includes(type) && measurementId1 !== undefined
				? measurements?.find((me) => me.id === measurementId1)?.unit?.quantity
				: undefined;
		return quantities
			.filter((qu) => !quantityId || qu.id === quantityId)
			.map((quantity) => ({
				label: quantity.name,
				value: quantity.id,
				options: quantity.units.map((unit) => ({ label: unit.name, value: unit.id }))
			}));
	}, [measurementId1, measurements, quantities, type]);

	const quantitiesOptions2 = useMemo<DropdownOptionString[]>(() => {
		const quantityId =
			!typesWithNotFilteredUnits.includes(type) && measurementId2 !== undefined
				? measurements?.find((me) => me.id === measurementId2)?.unit?.quantity
				: undefined;
		return quantities
			.filter((qu) => !quantityId || qu.id === quantityId)
			.map((quantity) => ({
				label: quantity.name,
				value: quantity.id,
				options: quantity.units.map((unit) => ({ label: unit.name, value: unit.id }))
			}));
	}, [measurementId2, measurements, quantities, type]);

	const dataChannelUnit = useMemo(() => {
		if (type !== CalculationTypes.DATA_POINT) {
			return '';
		}

		return measurements?.find((m) => m.id === measurementOrAxesId1)?.unit.id || '';
	}, [measurementOrAxesId1, measurements, type]);

	const measurementsAndAxes = useMeasurementAndAxesList(
		measurements,
		axes,
		type === CalculationTypes.DATA_POINT || isStatistical
	);

	useEffect(() => {
		if (measurementId1 !== undefined && !typesWithNotFilteredUnits.includes(type) && dirtyFields.measurementId1) {
			const unitId = measurements?.find((me) => me.id === measurementId1)?.unit?.id;

			if (unitId) setValue('unit', unitId);
		}
	}, [dirtyFields, measurementId1, measurements, setValue, type]);

	useEffect(() => {
		if (dataChannelUnit && dirtyFields.measurementOrAxesId1) {
			setValue('unit', dataChannelUnit);
		}
	}, [dataChannelUnit, dirtyFields, setValue]);

	return (
		<Form className={styles.container} onSubmit={onSave} id={formId}>
			<input type="hidden" {...register('id')} />
			<input type="hidden" {...register('stationId')} />
			<Controller
				name="name"
				control={control}
				render={({ field }) => (
					<InputText data-testid="nameId" label={t('Calculation name*')} {...field} error={errors.name?.message} />
				)}
			/>
			<Controller
				name="type"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						options={calculationTypes}
						label={t('Calculation type*')}
						error={errors.type?.message}
						data-testid="type"
						filter
					/>
				)}
			/>
			{type !== CalculationTypes.DATA_POINT && (
				<Controller
					name="measurementId1"
					control={control}
					render={({ field }) => (
						<Dropdown
							{...field}
							options={measurementsList}
							label={measurementLabel1}
							error={errors.measurementId1?.message}
							data-testid="measurementId1"
							filter
						/>
					)}
				/>
			)}
			{type !== CalculationTypes.DATA_POINT &&
				type !== CalculationTypes.MAXIMUM_LOAD_OVER_WIDTH &&
				type !== CalculationTypes.MEASUREMENT_AT_BREAK &&
				type !== CalculationTypes.MEASUREMENT_AT_YIELD &&
				!isStatistical && (
					<Controller
						name="measurementId2"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={measurementsList}
								label={measurementLabel2}
								error={errors.measurementId2?.message}
								data-testid="measurementId2"
								filter
							/>
						)}
					/>
				)}
			{type === CalculationTypes.MEASUREMENT_AT_YIELD && (
				<Controller
					name="unit"
					control={control}
					render={({ field }) => (
						<Dropdown
							data-testid="unit"
							{...field}
							options={quantitiesOptions}
							label={t('Measurement Unit*')}
							error={errors.unit?.message}
							filter
						/>
					)}
				/>
			)}
			{type !== undefined && (
				<>
					{type === CalculationTypes.MEASUREMENT_AT_YIELD && (
						<>
							<Controller
								name="measurementId2"
								control={control}
								render={({ field }) => (
									<Dropdown
										{...field}
										options={measurementsList}
										label={measurementLabel2}
										error={errors.measurementId2?.message}
										data-testid="measurementId2"
										filter
									/>
								)}
							/>
							<Controller
								name="unit2"
								control={control}
								render={({ field }) => (
									<Dropdown
										data-testid="unit2"
										{...field}
										options={quantitiesOptions2}
										label={t('Stress Measurement Unit*')}
										error={errors.unit2?.message}
										filter
									/>
								)}
							/>
							<Controller
								name="measurementId3"
								control={control}
								render={({ field }) => (
									<Dropdown
										{...field}
										options={measurementsList}
										label={measurementLabel3}
										error={errors.measurementId3?.message}
										data-testid="measurementId3"
										filter
									/>
								)}
							/>
						</>
					)}
					{isStatistical && (
						<StatisticalCalculation
							measurementsAndAxes={measurementsAndAxes}
							measurements={measurements}
							statisticalCalcRanges={statisticalCalcRanges}
							quantities={quantitiesOptions}
							form={form}
						/>
					)}
					{type === CalculationTypes.DATA_POINT && (
						<DataPointCalculation
							measurementsAndAxes={measurementsAndAxes}
							measurements={measurements}
							referencePointTypes={referencePointTypes}
							form={form}
						/>
					)}
					{(type !== CalculationTypes.DATA_POINT || dataChannelUnit) &&
						!isStatistical &&
						type !== CalculationTypes.MEASUREMENT_AT_YIELD && (
							<Controller
								name="unit"
								control={control}
								render={({ field }) => (
									<Dropdown
										data-testid="unit"
										{...field}
										options={quantitiesOptions}
										label={t('Unit*')}
										error={errors.unit?.message}
										filter
									/>
								)}
							/>
						)}
					{typesWithYieldLimits.some((t) => t === type) && (
						<Controller
							name="limitType"
							control={control}
							render={({ field }) => (
								<Dropdown
									{...field}
									options={limitTypes}
									label={t('Limit specification*')}
									error={errors.limitType?.message}
									data-testid="limitType"
									filter
								/>
							)}
						/>
					)}
					{(type === CalculationTypes.YIELD_OFFSET || type === CalculationTypes.MEASUREMENT_AT_YIELD) && (
						<GroupInputFrame>
							<Controller
								name="typeOffset"
								control={control}
								render={({ field }) => (
									<InputNumber
										{...field}
										label={t('Offset*')}
										error={errors.typeOffset?.message}
										data-testid="typeOffset"
									/>
								)}
							/>
							<InputText disabled value={'%'} />
						</GroupInputFrame>
					)}

					{typesWithYieldLimits.some((t) => t === type) && limitType !== LimitTypes.AUTO && (
						<>
							<GroupInputFrame>
								<Controller
									name="yieldUpperLimit"
									control={control}
									render={({ field }) => (
										<InputNumber
											{...field}
											label={t('Upper limit*')}
											error={errors.yieldUpperLimit?.message}
											data-testid="yieldUpperLimit"
										/>
									)}
								/>
								<InputText disabled value={unitName} />
							</GroupInputFrame>
							<GroupInputFrame>
								<Controller
									name="yieldLowerLimit"
									control={control}
									render={({ field }) => (
										<InputNumber
											{...field}
											label={t('Lower limit*')}
											error={errors.yieldLowerLimit?.message}
											data-testid="yieldLowerLimit"
										/>
									)}
								/>
								<InputText disabled value={unitName} />
							</GroupInputFrame>
						</>
					)}
					{/*<Form.Divider className="FormDivider" />*/}

					{/*<Controller*/}
					{/*	name="successFailCriteria"*/}
					{/*	control={control}*/}
					{/*	render={({ field }) => (*/}
					{/*		<Switch*/}
					{/*			label={t('Success-Fail criteria')}*/}
					{/*			checked={!!field.value}*/}
					{/*			onChange={(e) => field.onChange(e)}*/}
					{/*			data-testid="successFailCriteria"*/}
					{/*			className="FormDivider"*/}
					{/*		/>*/}
					{/*	)}*/}
					{/*/>*/}

					{successFailCriteria && (
						<div className={styles.limitDefContainer}>
							<div>
								<div className={styles.limitDefLabel}>{t('Limit definition')}</div>
								<div className={styles.criteriaContainer}>
									<Controller
										name="limitDefinition"
										control={control}
										render={({ field }) => (
											<>
												<RadioButton
													label={t('Nominal value')}
													data-testid="limitDefinition"
													{...field}
													value={LimitDefinitions.NOMINAL}
													checked={field.value === LimitDefinitions.NOMINAL}
												/>
											</>
										)}
									/>
									<Controller
										name="limitDefinition"
										control={control}
										render={({ field }) => (
											<>
												<RadioButton
													label={t('Absolute value')}
													data-testid="limitDefinition"
													{...field}
													value={LimitDefinitions.ABSOLUTE}
													checked={field.value === LimitDefinitions.ABSOLUTE}
												/>
											</>
										)}
									/>
								</div>
							</div>
							<div>
								{limitDefinition === LimitDefinitions.NOMINAL && (
									<>
										<GroupInputFrame>
											<Controller
												name="nominalValue"
												control={control}
												render={({ field }) => (
													<InputNumber
														{...field}
														label={t('Nominal value*')}
														error={errors.nominalValue?.message}
														data-testid="nominalValue"
													/>
												)}
											/>
											<InputText disabled value={selectedUnitName} />
										</GroupInputFrame>
										<div />

										<GroupInputFrame>
											<Controller
												name="variation"
												control={control}
												render={({ field }) => (
													<InputNumber
														{...field}
														label={t('Variation*')}
														error={errors.variation?.message}
														data-testid="variation"
													/>
												)}
											/>
											<InputText disabled value={'%'} />
										</GroupInputFrame>
									</>
								)}
								{limitDefinition === LimitDefinitions.ABSOLUTE && (
									<>
										<GroupInputFrame>
											<Controller
												name="upperLimit"
												control={control}
												render={({ field }) => (
													<InputNumber
														{...field}
														label={t('Upper limit*')}
														error={errors.upperLimit?.message}
														data-testid="upperLimit"
													/>
												)}
											/>
											<InputText disabled value={selectedUnitName} />
										</GroupInputFrame>
										<div />
										<GroupInputFrame>
											<Controller
												name="lowerLimit"
												control={control}
												render={({ field }) => (
													<InputNumber
														{...field}
														label={t('Lower limit*')}
														error={errors.lowerLimit?.message}
														data-testid="lowerLimit"
													/>
												)}
											/>
											<InputText disabled value={selectedUnitName} />
										</GroupInputFrame>
									</>
								)}
							</div>
						</div>
					)}
				</>
			)}
		</Form>
	);
};

export default React.memo(Calculation);
