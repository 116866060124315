import { FC } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { createBrowserRouter, RouterProvider, createRoutesFromElements } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { KeycloakProvider, PrivateRoute } from '@tactun/keycloak-axios-provider';
//import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import SystemsRouts from './modules/Systems';
import StationsRouts from './modules/Stations';
import TestsRouts from './modules/Tests';
import SpecimensRouts from './modules/Specimens';
import ComponentsSettings from './modules/ComponentsSettings';
import Controllers from './modules/Controllers';
import Products from './modules/Products';
import Users from './modules/Users';
import Dashboard from './modules/Dashboard';
import Layout from './modules/Layout';
import Orders from './modules/Orders';
import Customers from './modules/Customers';
import FeatureSets from './modules/FeatureSets';
import axios from './httpCommon';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { useIsLocalDeployment } from './hooks/useIsLocalDeployment';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000,
			refetchOnWindowFocus: false
		}
	}
});

const getRouter = (isLocalDeployment: boolean) => {
	return createBrowserRouter(
		createRoutesFromElements([
			<Route element={<Layout />}>
				<Route path="*" element={<PrivateRoute />}>
					<Route path="stations/:stationId/dashboard" element={<Dashboard />} />
					<Route path="stations/*" element={<StationsRouts />} />
					<Route path="tests/:testId/dashboard" element={<Dashboard />} />
					<Route path="tests/*" element={<TestsRouts />} />
					<Route path="specimen-list/*" element={<SpecimensRouts />} />
					<Route path="systems/*" element={<SystemsRouts />} />
					{!isLocalDeployment && (
						<>
							(<Route path="components-settings/*" element={<ComponentsSettings />} />
							<Route path="controllers/*" element={<Controllers />} />
							<Route path="products/*" element={<Products />} />
							<Route path="users/*" element={<Users />} />
							<Route path="orders/*" element={<Orders />} />
							<Route path="customers/*" element={<Customers />} />
							<Route path="featuresets/*" element={<FeatureSets />} />)
						</>
					)}
					<Route path="*" element={<Navigate to="/stations" replace />} />
				</Route>
			</Route>
		])
	);
};

const keycloakProviderConfigs = { checkLoginIframe: false };

export const App: FC = () => {
	const isLocalDeployment = useIsLocalDeployment();

	return (
		<KeycloakProvider
			configUrl="/keycloak.json"
			options={keycloakProviderConfigs}
			axiosInstance={axios}
			trackUserActivity
			sessionIdleMilliseconds={60 * 60 * 24 * 30 * 1000}
		>
			<DndProvider backend={HTML5Backend}>
				<QueryClientProvider client={queryClient}>
					<RouterProvider router={getRouter(isLocalDeployment)} />
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
					{/* <ReactQueryDevtools initialIsOpen={false} /> */}
				</QueryClientProvider>
			</DndProvider>
		</KeycloakProvider>
	);
};
