export enum TestOperateCommands {
	STOP = 0,
	PAUSE = 1,
	RESUME = 2
}

export enum TestOperateStatus {
	STARTED = 0,
	STOPPED = 1,
	RESUMED = 2,
	PAUSED = 3
}
