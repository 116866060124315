import { TFunction } from 'i18next';
import { IListItemNumber, IListItem, NumberEnum } from './../types';

export const convertNumberEnumToList = <T>(en: NumberEnum | T, translationFunc?: TFunction): IListItemNumber[] =>
	//@ts-ignore
	Object.entries(en)
		.filter(([key]) => isNaN(Number(key)))
		.map(([key, value]) => {
			return { label: translationFunc ? translationFunc(key) : key, value } as IListItemNumber;
		});

export const convertEnumToList = <T>(en: NumberEnum | T, translationFunc?: TFunction): IListItem[] =>
	//@ts-ignore
	Object.entries(en)
		.filter(([key]) => isNaN(Number(key)))
		.map(([key, value]) => {
			return { label: translationFunc ? translationFunc(key) : key, value } as IListItem;
		});

export const getEnumKeyByValue = (value: number, _enum: { [key: number | string]: number | string }): string => {
	const entries = Object.entries(_enum);
	for (let i = 0; i < entries.length; i++) {
		if (entries[i][1] === value) {
			return entries[i][0];
		}
	}

	return '';
};
