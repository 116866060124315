import styles from './PageFooter.module.scss';

export interface IPageFooterProps {
	left?: React.ReactNode;
	children: React.ReactNode;
}

const PageFooter: React.FC<IPageFooterProps> = ({ left, children }) => {
	return (
		<div className={styles.container}>
			<div className={styles.left}>{left}</div>
			<div className={styles.right}>{children}</div>
		</div>
	);
};

export default PageFooter;
