import { Switch } from '@tactun/ui';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Controller, UseFormReturn } from 'react-hook-form';
import { WidgetControlTestForm } from '../../widgetsConfiguration.types';
import styles from './ControlTestForm.module.scss';
import { useTranslation } from 'react-i18next';

interface ControlTestFormProps {
	form: UseFormReturn<WidgetControlTestForm>;
}

const ControlTestForm: React.FC<ControlTestFormProps> = ({ form: { control } }) => {
	const { t } = useTranslation('widgets');

	return (
		<Accordion multiple className={styles.accordion}>
			<AccordionTab header={t('Start / Stop Button Configuration')} contentClassName={styles.accordionContent}>
				<Controller
					name="controlTest.startStopButton"
					control={control}
					render={({ field }) => (
						<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
					)}
				/>
			</AccordionTab>

			<AccordionTab header={t('Pause / Resume Button Configuration')} contentClassName={styles.accordionContent}>
				<Controller
					name="controlTest.pauseResumeButton"
					control={control}
					render={({ field }) => (
						<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
					)}
				/>
			</AccordionTab>

			<AccordionTab header={t('Jump to Next Stage Button Configuration')} contentClassName={styles.accordionContent}>
				<Controller
					name="controlTest.jumpToNextStageButton"
					control={control}
					render={({ field }) => (
						<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
					)}
				/>
			</AccordionTab>

			<AccordionTab header={t('Generate Report Button Configuration')} contentClassName={styles.accordionContent}>
				<Controller
					name="controlTest.generateReportButton"
					control={control}
					render={({ field }) => (
						<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
					)}
				/>
			</AccordionTab>
		</Accordion>
	);
};

export default ControlTestForm;
