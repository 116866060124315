export type ConsumeEntityOnSuccess<T> = (entity: T) => void;
export type ConsumeEntityOnError<T> = (entities: T[], id: string) => void;
export type ConsumeEntity<T> = (
	entities: T[],
	id: string,
	onSuccess: ConsumeEntityOnSuccess<T>,
	onError: ConsumeEntityOnError<T>
) => void;

export const consumeEntity = <T extends { id: string }>(entities: T[], id: string) => {
	const entity = entities.find((e) => e.id === id);

	return {
		onSuccess(handler: ConsumeEntityOnSuccess<T>) {
			if (entity) {
				handler(entity);
			}

			return this;
		},
		onError(handler: ConsumeEntityOnError<T>) {
			if (!entity) {
				handler(entities, id);
			}

			return this;
		}
	};
};
