import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'react-grid-layout';
import { DataTable, EDataTableThemes } from '@tactun/ui';
import classNames from 'classnames';
import { Badge } from 'primereact/badge';
import { WidgetProps } from '../../../Dashboard/dashboard.types';
import { useEStop, useLimits } from '../../../Dashboard/dashboard.hooks';
import WidgetContext from '../../../Dashboard/components/Widget/widgetContext';
import { useAxes } from '../../../Axes/axes.hooks';
import WidgetHeader from '../../../Dashboard/components/Widget/WidgetHeader';
import WidgetContent from '../../../Dashboard/components/Widget/WidgetContent';
import OverlayWidget from '../../../Dashboard/components/OverlayWidget';
import { AuxiliaryDeviceDirection, useStationAuxiliaryDevices } from '../../../AuxiliaryDevices';
import { useStationId } from '../../../Stations';
import styles from './Safety.module.scss';

const SafetyWidget: React.FC<WidgetProps> = (props) => {
	const { width, height } = props;
	const { t } = useTranslation('dashboard');
	const { widget } = useContext(WidgetContext);
	const stationId = useStationId();
	const { stationAuxiliaryDevicesResponse: auxiliaryDevices } = useStationAuxiliaryDevices(stationId);
	const { status: stopStatus } = useEStop(auxiliaryDevices);
	const { limits } = useLimits(auxiliaryDevices);
	const [limitsPopupPosition, setLimitsPopupPosition] = useState<Omit<Layout, 'i'> | null>();
	const onLimitsClick = useCallback(() => {
		const widgetRight = (widget?.x || 0) + (widget?.w || 0);
		const widgetBottom = (widget?.y || 0) + (widget?.h || 0);
		setLimitsPopupPosition((prevState) =>
			prevState
				? null
				: {
						x: widgetRight + 1,
						y: widgetBottom + 1,
						w: 6,
						h: 5
				  }
		);
	}, [widget]);

	const vertical = useMemo(() => height > width, [width, height]);

	const { axes } = useAxes(stationId);
	const limitsTable = useMemo(() => {
		if (auxiliaryDevices?.length) {
			// TODO: get axes channel by auxDevId
			return limits.map((limit) => {
				const auxDevice = auxiliaryDevices?.find((a) => a.id === limit.auxDevId);
				return {
					name: axes?.find((a) => a.id === limit.auxDevId)?.name || '',
					status: auxDevice?.direction === AuxiliaryDeviceDirection.UP ? 'Upper Limit' : 'Lower Limit'
				};
			});
		}
		return [];
	}, [limits, auxiliaryDevices, axes]);

	return (
		<>
			<WidgetHeader disableMinimize>{t('Safety')}</WidgetHeader>
			<WidgetContent className={styles.container}>
				<div className={vertical ? styles.verticalButtons : styles.horizontalButtons}>
					<span
						className={classNames(styles.eStop, styles.safetyItem, {
							[styles.disabled]: !stopStatus
						})}
					/>
					<button
						className={classNames(styles.warning, styles.safetyItem, {
							[styles.disabled]: limits.length
						})}
						onClick={onLimitsClick}
						disabled={!limits.length}
					>
						{!!limits.length && <Badge value={limits.length} className={styles.badgeWarning} severity="danger"></Badge>}
					</button>
				</div>
				{limitsPopupPosition && (
					<OverlayWidget layout={limitsPopupPosition}>
						<div className={classNames('overlay-widget-header', styles.limitsHeader)}>
							<div className={styles.limitsHeaderTitle}>{t('Limits')}</div>
							<span
								className={classNames(styles.closeButton, 'pi pi-times')}
								onClick={() => setLimitsPopupPosition(null)}
							/>
						</div>
						<div className={styles.limitsContent}>
							<DataTable value={limitsTable} dataKey="id" theme={EDataTableThemes.WIDGET}>
								<DataTable.Column header={t('Object name')} field="name" />
								<DataTable.Column header={t('Status')} field="status" />
							</DataTable>
						</div>
					</OverlayWidget>
				)}
			</WidgetContent>
		</>
	);
};

export default React.memo(SafetyWidget);
