import { object, string, mixed, boolean } from 'yup';
import { alphaNumericRegex } from '../../constants/regexes';
import { AverageFilterPoints } from '../StationChannels';
import { isFieldVisible } from './components/SensorForm/sensorFieldMapping';
import * as api from './sensors.api';

import { SensorsTypes } from './sensors.enums';

export const sensorFormSchema = object({
	id: string().notRequired(),
	name: string()
		.required()
		.max(30)
		.matches(alphaNumericRegex, 'Is not in correct format')
		.test('checkSensorNameUniqueness', 'Name is used', function (value, form) {
			if (!value) return true;
			return api.checkName(value, form?.parent?.id, form?.parent.stationId);
		}),
	type: mixed<SensorsTypes>().required(),
	stationId: string().required(),
	manufacturer: string().max(30).matches(alphaNumericRegex, 'Is not in correct format').notRequired(),
	serialNumber: string().max(100).matches(alphaNumericRegex, 'Is not in correct format'),
	unitId: string().required('Unit is a required field'),
	stationChannelId: string().required('Cannel is a required field'),
	isInversePolarity: boolean().optional(),
	isShowRawData: boolean().notRequired(),
	isAveragingFilter: boolean().notRequired(),
	averagingFilterPoint: mixed<AverageFilterPoints>().when(['type', 'isAveragingFilter'], {
		is: (type: SensorsTypes, isAveragingFilter: boolean) =>
			isFieldVisible('averagingFilterPoint', type) && isAveragingFilter,
		then: (schema) => schema.required('Averaging filter points is a required field')
	})
}).required();
