export enum WaveformType {
	//JOG = 0,
	RAMP = 0,
	DWELL,
	CONSTANT,
	SINE,
	DUO_RAMP,
	HAVER_SINE,
	DUO_HAVER_SINE,
	TAPERED_SINE,
	SWEEP_SINE,
	SQUAREWAVE
}

export enum WaveformDefinitionMode {
	ABSOLUTE = 0,
	RELATIVE
}

export enum WaveformDirection {
	UP = 0,
	DOWN
}

export enum WaveformRampDirection {
	UP = 0,
	DOWN,
	AUTODETECT
}

export enum WaveformEndChannelType {
	MEASUREMENT = 0,
	SET_POINT,
	TIME
}

export enum WaveformValueType {
	VALUE = 0,
	VARIABLE
}
