import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { grpc } from '@improbable-eng/grpc-web';
import {
	ruleService,
	ErrorType,
	Error as GrpcError,
	RulesRequest,
	TriggerType,
	OffsetCalibrationState
} from '@tactun/grpc-client';
import { UnaryOutput } from '@improbable-eng/grpc-web/dist/typings/unary';
import { OffsetCalibrationStatus } from './offsetCalibration.enums';
import { grpcErrorToMessage } from '../../tools/grpcHelper';
import { subscribeToEvents } from '../Dashboard/dashboard.grpc';
import { tError, tInfo } from '../../tools/logger';

export const useOffsetCalibration = (isDisabled: boolean = false) => {
	const [status, setStatus] = useState<OffsetCalibrationStatus>(OffsetCalibrationStatus.NOT_STARTED);
	const [offset, setOffset] = useState<number>();
	const grpcRequestRef = useRef<grpc.Request>();

	const start = useCallback((request: RulesRequest) => {
		if (process.env.REACT_APP_DEVICE_URL) {
			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

			grpcRequestRef.current = grpc.unary(ruleService.WebRules.Apply, {
				request: request,
				host: process.env.REACT_APP_DEVICE_URL,
				transport: myTransport,
				onEnd: (output: UnaryOutput<GrpcError>) => {
					if (output.message?.getErrorType() === ErrorType.OK) {
						tInfo('Offset calibration start command sent');
					} else {
						setStatus(OffsetCalibrationStatus.FAILED);
						toast.error('Failed to start offset calibration');

						const message = grpcErrorToMessage(output.message, output.statusMessage);
						tError(message);
					}
				}
			});
		}
	}, []);

	const stop = useCallback((request: RulesRequest) => {
		if (process.env.REACT_APP_DEVICE_URL) {
			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

			grpcRequestRef.current = grpc.unary(ruleService.WebRules.Apply, {
				request: request,
				host: process.env.REACT_APP_DEVICE_URL,
				transport: myTransport,
				onEnd: (output: UnaryOutput<GrpcError>) => {
					if (output.status === grpc.Code.OK) {
						tInfo('Offset calibration stop command sent');
					} else {
						setStatus(OffsetCalibrationStatus.FAILED);
						toast.error('Failed to stop offset calibration');

						const message = grpcErrorToMessage(output.message, output.statusMessage);
						tError(message);
					}
				}
			});
		}
	}, []);

	const setOffsetValue = useCallback((request: RulesRequest) => {
		if (process.env.REACT_APP_DEVICE_URL) {
			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

			grpcRequestRef.current = grpc.unary(ruleService.WebRules.Apply, {
				request: request,
				host: process.env.REACT_APP_DEVICE_URL,
				transport: myTransport,
				onEnd: (output: UnaryOutput<GrpcError>) => {
					if (output.status === grpc.Code.OK) {
						tInfo('Offset calibration set command sent');
					} else {
						setStatus(OffsetCalibrationStatus.FAILED);
						toast.error('Failed to set offset');

						const message = grpcErrorToMessage(output.message, output.statusMessage);
						tError(message);
					}
				}
			});
		}
	}, []);

	useEffect(() => {
		let subscription: { unsubscribe(): void };

		if (!isDisabled) {
			subscription = subscribeToEvents([TriggerType.TRG_OFFSET_CALIBRATION], (newEvents) => {
				newEvents.forEach(({ trigger }) => {
					if (trigger?.triggerOc?.state !== undefined) {
						switch (trigger.triggerOc.state) {
							case OffsetCalibrationState.CALIBRATION_IN_PROGRESS:
								setStatus(OffsetCalibrationStatus.IN_PROGRESS);
								break;
							case OffsetCalibrationState.CALIBRATION_COMPLETED:
								setStatus(OffsetCalibrationStatus.SUCCESS);
								setOffset(trigger.triggerOc.offset);
								break;
							case OffsetCalibrationState.CALIBRATION_MAX_REACHED:
								setStatus(OffsetCalibrationStatus.FAILED);
								setOffset(trigger.triggerOc.offset);
								break;
							case OffsetCalibrationState.CALIBRATION_STOPPED:
								setStatus(OffsetCalibrationStatus.NOT_STARTED);
								break;
						}
					}
				});
			});
		}

		return () => {
			if (subscription) {
				subscription.unsubscribe();
			}
		};
	}, [isDisabled]);

	return useMemo(() => {
		return { start, stop, setOffset: setOffsetValue, status, offset };
	}, [start, stop, setOffsetValue, status, offset]);
};
