import { Form, InputText, Dropdown } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { DashboardTypeFormType } from '../../dashboardTypes.types';
import { IListItem } from '../../../../types';
import styles from './DashboardTypeForm.module.scss';

interface DashboardTypeFormProps {
	formId: string;
	form: UseFormReturn<DashboardTypeFormType>;
	modes: IListItem[];
}

const DashboardTypeForm: React.FC<DashboardTypeFormProps> = ({ formId, modes, form }) => {
	const { t } = useTranslation('dashboardTypes');
	const {
		control,
		formState: { errors },
		register
	} = form;

	return (
		<Form className={styles.container} id={formId}>
			<input type="hidden" {...register('id')} />
			<InputText data-testid="nameId" label={t('Name*')} {...register('name')} error={errors.name?.message} />
			<Controller
				name="mode"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						data-testid="mode"
						options={modes}
						label={t('Display mode*')}
						error={errors.mode?.message}
						filter
					/>
				)}
			/>
			<Controller
				name="description"
				control={control}
				render={({ field }) => (
					<InputText
						{...field}
						textarea
						label={t('Description')}
						error={errors.description?.message}
						maxLength={1000}
						data-testid="description"
						className={styles.description}
					/>
				)}
			/>
		</Form>
	);
};

export default DashboardTypeForm;
