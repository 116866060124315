import { useEffect } from 'react';
import { useTauriStore } from './useTauriStore';
import { useKeycloakContext } from '@tactun/keycloak-axios-provider';
import { useConnectionStore } from '../modules/Connection/connection.store';

//Save info for Tauri App
export const useSyncDeviceInfoWithTauri = () => {
	const { connectedDevice } = useConnectionStore();
	const { set, save } = useTauriStore('./tactun.json');
	const keycloakContext = useKeycloakContext();

	useEffect(() => {
		if (connectedDevice) {
			set('device_ip', connectedDevice.ip);
			set('server_url', process.env.REACT_APP_API_BASE_URL);
		}
	}, [connectedDevice, save, set]);

	useEffect(() => {
		if (keycloakContext?.keycloak?.token) {
			set('access_token', keycloakContext?.keycloak?.token);
			save();
		}
	}, [keycloakContext?.keycloak?.token, save, set]);
};
