import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, Modal, useModalData } from '@tactun/ui';
import { useSearchParams, useParams } from 'react-router-dom';
import {
	useTypeSpecimens,
	SpecimenCreateErrors,
	SpecimenStatusesDto,
	SpecimenListItem,
	SpecimenRequestDto,
	specimenSchema,
	formRequestConverter,
	responseListItemConverter,
	createSpecimen,
	SpecimenFormType
} from '../../Specimens';
import { SpecimenFormModal } from '../components/SpecimenFormModal';
import SpecimenSelectionModalContent from '../components/SpecimenSelectionModalContent';
import { deleteEmptyFields } from '../../../tools';
import { specimenCreateModalId, specimenSelectionModalId } from '../specimenSelection.const';
import { useTest } from '../../Tests/tests.hooks';
import { useControlTest } from '../../ControlTestWidget';

const SpecimenSelectionModalContainer: React.FC = () => {
	const { t } = useTranslation('specimens');
	const { testId } = useParams();
	const { testDto } = useTest(testId);
	const [, setSearchParams] = useSearchParams();
	const { start } = useControlTest();
	const [selectedSpecimen, setSelectedSpecimen] = useState<SpecimenListItem>();
	const { specimens, isSpecimensLoading, addNew } = useTypeSpecimens(testDto?.specimenType?.id);
	const modalData = useModalData(specimenSelectionModalId);
	const isRunTest = modalData?.isRunTest;

	const notTestedSpecimens = useMemo(
		() => [
			...(specimens?.filter(
				(sp) =>
					sp.children[0]?.specimenTest.specimenTestStatus === SpecimenStatusesDto.NOT_TESTED &&
					(sp.pipelineUuid === testId || !sp.pipelineUuid)
			) || [])
		],
		[specimens, testId]
	);

	const form = useForm({
		mode: 'onBlur',
		resolver: yupResolver(specimenSchema),
		defaultValues: { quantity: 1, testId, specimenTypeUuid: testDto?.specimenType?.id }
	});
	const { handleSubmit, setValue } = form;

	const createMutation = useMutation({
		mutationFn: (specimen: SpecimenRequestDto) => createSpecimen(specimen),
		onSuccess: (req) => {
			const specimenItem = responseListItemConverter(req.response);
			addNew(specimenItem);
			setSelectedSpecimen(specimenItem);

			Modal.hide(specimenCreateModalId);
		},
		onError: (e: Error & { response: any }) => {
			toast.error(
				SpecimenCreateErrors[e?.response?.data?.error] ? t(SpecimenCreateErrors[e?.response?.data?.error]) : e.message
			);
		}
	});

	useEffect(() => {
		if (testDto?.specimenType?.id) {
			//@ts-ignore
			setValue<string>('specimenTypeUuid', testDto?.specimenType?.id);
		}
		if (testId) {
			//@ts-ignore
			setValue('testId', testId);
		}
	}, [setValue, testDto?.specimenType?.id, testId]);

	const handleCloseRootModal = useCallback(() => Modal.hide(specimenSelectionModalId), []);

	const handleSpecimenSelect = useCallback((specimen: SpecimenListItem) => setSelectedSpecimen(specimen), []);

	const handleCreate = useCallback(() => Modal.show(specimenCreateModalId), []);

	const handleCreateNewSpecimen = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			handleSubmit((data: SpecimenFormType) => {
				const filteredData = deleteEmptyFields<SpecimenRequestDto>(formRequestConverter(data));

				createMutation.mutate(filteredData);
			})();
		},
		[createMutation, handleSubmit]
	);

	const handleSaveOrRunTest = useCallback(() => {
		if (selectedSpecimen) {
			setSearchParams({ specimenId: selectedSpecimen.id });
		}
		if (isRunTest && testId && selectedSpecimen?.id) {
			start(testId, selectedSpecimen.id);
		}
		Modal.hide(specimenSelectionModalId);
	}, [isRunTest, selectedSpecimen, setSearchParams, start, testId]);

	return (
		<>
			<SpecimenSelectionModalContent
				specimens={notTestedSpecimens}
				isLoading={isSpecimensLoading}
				selected={selectedSpecimen}
				onSelect={handleSpecimenSelect}
				onCreate={handleCreate}
			/>
			<FormProvider {...form}>
				<SpecimenFormModal onSave={handleCreateNewSpecimen} />
			</FormProvider>
			<Modal.Footer>
				<Button className="btnCancel" onClick={handleCloseRootModal} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button onClick={handleSaveOrRunTest} color="secondary" variant="contained" disabled={!selectedSpecimen}>
					{isRunTest ? t('Run test') : t('Select')}
				</Button>
			</Modal.Footer>
		</>
	);
};

export default React.memo(SpecimenSelectionModalContainer);
