import { AxisRequest } from '@tactun/grpc-client';
import { Converter } from '../../types';
import { AxesTypes } from './axes.enums';
import { IAxisResponseDto, AxisFormType, IAxisRequestDto, IAxis, IAxisListItem } from './axes.types';

export const responseListItemConverter: Converter<IAxisResponseDto, IAxisListItem> = (response) => {
	return {
		id: response.id,
		name: response.name,
		type: AxesTypes[response.type],
		actuatorName: response.actuator?.name
	};
};

export const responseFormConverter: Converter<IAxisResponseDto, AxisFormType> = (input) => {
	return {
		id: input.id,
		name: input.name,
		type: input.type,
		stationId: input.stationId,
		actuatorId: input.actuator.id
	};
};

export const entityFormConverter: Converter<IAxis, AxisFormType> = (input) => ({
	name: input.name,
	type: input.type,
	stationId: input.stationId,
	actuatorId: input.actuator.id
});

export const formRequestConverter: Converter<AxisFormType, IAxisRequestDto> = (input) => {
	return {
		name: input.name,
		type: input.type,
		stationId: input.stationId,
		actuatorId: input.actuatorId
	};
};

export const axisResponseToAxisGRPCConverter: Converter<IAxisResponseDto, AxisRequest> = (axis) => {
	const axisDto = new AxisRequest();
	axisDto.setId(axis.id);
	axisDto.setName(axis.name);
	axisDto.setType(axis.type);
	axisDto.setActuatorId(axis.actuator.id);
	axisDto.setSetpointIndex(axis.spIndex);
	axisDto.setCompSetpointIndex(axis.cspIndex);
	axisDto.setCycleIndex(axis.cycleIndex);
	axisDto.setStageIndex(axis.stageIndex);
	axisDto.setCompleteIndex(axis.completeIndex);
	axisDto.setSharedmemAddr(axis.shmemAddress);
	axisDto.setMeasurementIdIndex(axis.measIndex);

	return axisDto;
};

export const axesResponsesToAxesGRPCsConverter: Converter<IAxisResponseDto[], Array<AxisRequest>> = (axes) => {
	return axes.map((axis) => axisResponseToAxisGRPCConverter(axis));
};
