export enum MeasurementCrudActionTypes {
	EDIT = 'EDIT_CALCULATION',
	CREATE = 'CREATE_CALCULATION',
	DELETE = 'DELETE_CALCULATION',
	DUPLICATE = 'DUPLICATE_CALCULATION'
}

export enum FormulaTypes {
	DIVIDE = 0,
	LINEAR
}

export enum FormulaVariablesTypes {
	NUMERIC = 0,
	VARIABLE,
	SPECIMEN_PARAMETERS
}

export enum FormulaSpecimenParams {
	AREA = 0,
	GAUGE_LENGTH
}
