import React, { FC, useEffect, useMemo } from 'react';
import { Dropdown, GroupInputFrame, InputNumber, InputText, Switch, TreeDropdown } from '@tactun/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEntitiesAsList, useNumberEnumList } from '../../../hooks';
import { ControlChannelResponseDto } from '../../ControlChannels';
import { MeasurementResponseDto } from '../../Measurements';
import { useDefaultTimeUnitName } from '../../Units/units.hooks';
import { VariableResponseDto } from '../../Variables';
import {
	WaveformDefinitionMode,
	WaveformEndChannelType,
	WaveformRampDirection,
	WaveformValueType
} from '../waveforms.enums';
import {
	createControllerName,
	useControllerName,
	useEndChannel,
	useEndChannelMeta,
	useIsInvertEndConditionDisabled
} from '../waveforms.hooks';
import { WaveformRampFormErrors } from '../waveforms.types';
import { getFieldByFormPrefix } from '../waveforms.tools';
import { TestMeasurementResponseDto } from '../../TestMeasurements';

export interface RampWaveformFormViewProps {
	variables: VariableResponseDto[];
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	controlChannel: ControlChannelResponseDto;
	formPrefix?: string;
	errors?: WaveformRampFormErrors;
	isRunning?: boolean;
	isControlStage?: boolean;
}

const RampWaveformFormView: FC<RampWaveformFormViewProps> = ({
	variables,
	measurements,
	controlChannel,
	formPrefix,
	errors,
	isRunning,
	isControlStage = false
}) => {
	const { t } = useTranslation('waveforms');
	const {
		control,
		watch,
		setValue,
		formState: { dirtyFields }
	} = useFormContext();

	const defaultTimeUnitName = useDefaultTimeUnitName();

	const difinitionModeOptions = useNumberEnumList(WaveformDefinitionMode);
	const directionOptions = useNumberEnumList(WaveformRampDirection);
	const waveformValTypeOptions = useNumberEnumList(WaveformValueType, 'waveforms');
	const variablesOptions = useEntitiesAsList(variables);

	// CN - means Controller name
	const rateTypeCN = useControllerName('rateType', formPrefix);
	const defModeCN = useControllerName('defMode', formPrefix);
	const endChannelValueTypeCN = useControllerName('endChannelValueType', formPrefix);
	const endChannelCN = useControllerName('endChannel', formPrefix);
	const rampDirectionCN = useControllerName('rampDirection', formPrefix);
	const directionRelCN = useControllerName('directionRel', formPrefix);
	const isInvertEndConditionCN = createControllerName('isInvertEndCondition', formPrefix);

	const rateType = watch(rateTypeCN);
	const defMode = watch(defModeCN);
	const endChannel = watch(endChannelCN);
	const endChannelValueType = watch(endChannelValueTypeCN);
	const rampDirection = watch(rampDirectionCN);

	const endChannelTypes = useEndChannel(
		measurements,
		rampDirection === WaveformRampDirection.AUTODETECT || defMode === WaveformDefinitionMode.RELATIVE
	);
	const directionOptionsFiltered = useMemo(() => {
		if (endChannel === WaveformEndChannelType.TIME || defMode === WaveformDefinitionMode.RELATIVE) {
			return directionOptions.filter((option) => option.value !== WaveformRampDirection.AUTODETECT);
		} else {
			return directionOptions;
		}
	}, [defMode, directionOptions, endChannel]);

	useEffect(() => {
		if (defMode === WaveformDefinitionMode.RELATIVE && rampDirection === WaveformRampDirection.AUTODETECT) {
			setValue(rampDirectionCN, null);
		} else if (defMode === WaveformDefinitionMode.RELATIVE && watch(endChannelCN) === WaveformEndChannelType.TIME) {
			setValue(endChannelCN, null);
		}
	}, [defMode, endChannelCN, rampDirection, rampDirectionCN, setValue, watch]);

	const typeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.rateType;
	useEffect(() => {
		if (typeDirtyField) {
			setValue(createControllerName('rateValue', formPrefix), null);
			setValue(createControllerName('rateVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, typeDirtyField]);

	useEffect(() => {
		if (rampDirection === WaveformRampDirection.AUTODETECT && endChannel === WaveformEndChannelType.SET_POINT) {
			setValue(createControllerName('directionRel', formPrefix), true);
		} else if (rampDirection === WaveformRampDirection.AUTODETECT && endChannel === WaveformEndChannelType.TIME) {
			setValue(createControllerName('directionRel', formPrefix), false);
		}
	}, [endChannel, formPrefix, rampDirection, setValue]);

	const endChannelValueTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.endChannelValueType;
	useEffect(() => {
		if (endChannelValueTypeDirtyField) {
			setValue(createControllerName('endChannelValue', formPrefix), null);
			setValue(createControllerName('endChannelVariableId', formPrefix), null);
		}
	}, [formPrefix, setValue, endChannelValueTypeDirtyField]);

	const feedbackOrAxisUnit = controlChannel.feedbackMeasurement?.unit.name
		? controlChannel.feedbackMeasurement?.unit.name
		: controlChannel.axis.actuator.calibrationResponseDto?.unit?.name;

	const rateUnit = useMemo(() => {
		if (defaultTimeUnitName) {
			return `${feedbackOrAxisUnit}/${defaultTimeUnitName}`;
		} else {
			return '';
		}
	}, [feedbackOrAxisUnit, defaultTimeUnitName]);

	const { endChannelUnit } = useEndChannelMeta(endChannel, defaultTimeUnitName, feedbackOrAxisUnit || '', measurements);

	const isInvertEndConditionDisabled = useIsInvertEndConditionDisabled(
		endChannel,
		measurements,
		controlChannel.feedbackMeasurement?.sensor?.id
	);

	useEffect(() => {
		if (isInvertEndConditionDisabled) {
			setValue(isInvertEndConditionCN, false);
		}
	}, [isInvertEndConditionCN, isInvertEndConditionDisabled, setValue]);

	return (
		<>
			<div />
			<Controller
				name={defModeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Definition mode*')}
						options={difinitionModeOptions}
						error={errors?.defMode?.message}
						data-testid="defMode"
						{...field}
						disabled={isRunning}
					/>
				)}
			/>
			<Controller
				name={rampDirectionCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Movement Direction*')}
						options={directionOptionsFiltered}
						data-testid="rampDirection"
						error={errors?.rampDirection?.message}
						{...field}
						disabled={isRunning}
					/>
				)}
			/>
			{rampDirection === WaveformRampDirection.AUTODETECT && (
				<>
					<Controller
						name={directionRelCN}
						control={control}
						render={({ field }) => (
							<Switch
								label={t('Direct relationship')}
								data-testid="directionRel"
								{...field}
								onChange={(e) => field.onChange(e.value)}
								checked={!!field.value}
								disabled={
									isRunning ||
									(rampDirection === WaveformRampDirection.AUTODETECT &&
										(endChannel === WaveformEndChannelType.SET_POINT || endChannel === WaveformEndChannelType.TIME))
								}
							/>
						)}
					/>
					<div />
				</>
			)}
			<Controller
				name={rateTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Rate*')}
						options={waveformValTypeOptions}
						data-testid="rateTypeCN"
						error={errors?.rateType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{rateType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('rateVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								label={t('Rate variable*')}
								options={variablesOptions}
								data-testid="rateVariableId"
								error={errors?.rateVariableId?.message}
								{...field}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('rateValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber
								data-testid="rateValue"
								{...field}
								label={t('Rate constant value*')}
								error={errors?.rateValue?.message}
							/>
						)}
					/>
				)}
				<InputText data-testid="rateUnit" disabled value={rateUnit} />
			</GroupInputFrame>
			<Controller
				name={endChannelCN}
				control={control}
				render={({ field }) => (
					<TreeDropdown
						{...field}
						options={endChannelTypes}
						data-testid="endChannelCN"
						label={t('End channel*')}
						disabled={isRunning}
						error={errors?.endChannel?.message}
					/>
				)}
			/>
			<div></div>
			<Controller
				name={endChannelValueTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Value type*')}
						options={waveformValTypeOptions}
						data-testid="endChannelValueTypeCN"
						error={errors?.endChannelValueType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{endChannelValueType === WaveformValueType.VARIABLE ? (
					<Controller
						name={createControllerName('endChannelVariableId', formPrefix)}
						control={control}
						render={({ field }) => (
							<Dropdown
								label={t('Variable*')}
								options={variablesOptions}
								data-testid="endChannelVariableId"
								error={errors?.endChannelVariableId?.message}
								{...field}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<Controller
						name={createControllerName('endChannelValue', formPrefix)}
						control={control}
						render={({ field }) => (
							<InputNumber
								data-testid="endChannelValue"
								{...field}
								label={t('Value*')}
								error={errors?.endChannelValue?.message}
							/>
						)}
					/>
				)}
				<InputText data-testid="endChannelUnit" disabled value={endChannelUnit} />
			</GroupInputFrame>

			<Controller
				name={isInvertEndConditionCN}
				control={control}
				render={({ field }) => (
					<Switch
						label={t('Invert End Condition')}
						inputId={field.name}
						data-testid="isInvertEndCondition"
						onChange={(e) => field.onChange(e.value)}
						checked={!!field.value}
						disabled={isRunning || isInvertEndConditionDisabled}
					/>
				)}
			/>
		</>
	);
};

export default RampWaveformFormView;
