import { FormulaType, MeasurementRequest, UnitFormula } from '@tactun/grpc-client';
import { Converter } from '../../types';
import {
	DataInFormType,
	DataInRequestDto,
	DataInResponseDto,
	MeasurementFormType,
	MeasurementRequestDto,
	MeasurementResponseDto
} from './measurements.types';
import { FormulaTypes } from './measurements.enums';

const dataInResponseFormConverter: Converter<DataInResponseDto, DataInFormType> = (response) => {
	return {
		formulaVariableType: response.type,
		data: response.data !== undefined ? response.data : null,
		variableId: response.variable?.uuid !== undefined ? response.variable.uuid : null,
		specimenParamType: response.specimenParamType !== undefined ? response.specimenParamType : null
	};
};

const dataInFormRequestConverter = (response: DataInFormType): DataInRequestDto => {
	return {
		type: response.formulaVariableType,
		data: response.data !== null ? response.data : undefined,
		variableId: response.variableId !== null ? response.variableId : undefined,
		specimenParamType: response.specimenParamType !== null ? response.specimenParamType : undefined
	};
};

export const responseFormConverter: Converter<MeasurementResponseDto, MeasurementFormType> = (response) => {
	return {
		id: response.id,
		name: response.name,
		stationId: response.stationId,
		sensorId: response.sensor.id,
		unitId: response.unit.id,
		isInverseReading: response.inverseReading,
		isApplyFormula: response.applyFormula,
		formulaType: response.formulaType !== undefined ? response.formulaType : null,
		dataIn1: response.dataIn1?.type !== undefined ? dataInResponseFormConverter(response.dataIn1) : null,
		dataIn2: response.dataIn2?.type !== undefined ? dataInResponseFormConverter(response.dataIn2) : null,
		isLimitEnabled: !!response.upperLimit || !!response.lowerLimit,
		upperLimit: response.upperLimit,
		lowerLimit: response.lowerLimit
	};
};

export const formRequestConverter = (form: MeasurementFormType): MeasurementRequestDto => {
	return {
		name: form.name,
		stationId: form.stationId,
		sensorId: form.sensorId,
		unitId: form.unitId,
		inverseReading: form.isInverseReading,
		applyFormula: form.isApplyFormula,
		formulaType: form.formulaType !== null ? form.formulaType : undefined,
		dataIn1: form.dataIn1 ? dataInFormRequestConverter(form.dataIn1) : undefined,
		dataIn2: form.dataIn2 ? dataInFormRequestConverter(form.dataIn2) : undefined,
		upperLimit: form.upperLimit !== null ? form.upperLimit : undefined,
		lowerLimit: form.lowerLimit !== null ? form.lowerLimit : undefined
	};
};

export const measurementResponseToMeasurementGRPCConverter: Converter<MeasurementResponseDto, MeasurementRequest> = (
	measurement
) => {
	const measurementReq = new MeasurementRequest();

	measurementReq.setLimitsEnabled(!!measurement.upperLimit || !!measurement.lowerLimit);
	measurementReq.setLowerLimit(measurement.lowerLimit);
	measurementReq.setUpperLimit(measurement.upperLimit);

	measurementReq.setId(measurement.id);
	measurementReq.setName(measurement.name);
	measurementReq.setUnitName(measurement.unit.name);
	measurementReq.setSensorId(measurement.sensor.id);
	measurementReq.setInverseReading(measurement.inverseReading);
	measurementReq.setApplyFormula(measurement.applyFormula);
	if (measurement.id) {
		measurementReq.setParentId(measurement.id);
	}
	if (measurement.formulaType === FormulaTypes.DIVIDE) {
		measurementReq.setFormulaType(FormulaType.FORMULA_TYPE_X_DIV_K);
	}
	if (measurement.formulaType === FormulaTypes.LINEAR) {
		measurementReq.setFormulaType(FormulaType.FORMULA_TYPE_KX_PLUS_B);
	}
	if (measurement.dataIn1) {
		if (measurement.dataIn1.type !== undefined) measurementReq.setDataIn1ValueType(measurement.dataIn1.type);
		if (measurement.dataIn1.data !== undefined) measurementReq.setDataIn1(measurement.dataIn1.data);
		if (measurement.dataIn1.variable !== undefined)
			measurementReq.setDataIn1VariableId(measurement.dataIn1.variable.uuid);
		if (measurement.dataIn1.specimenParamType !== undefined)
			measurementReq.setDataIn1SpecimenParamType(measurement.dataIn1.specimenParamType);
	}
	if (measurement.dataIn2) {
		if (measurement.dataIn2.type !== undefined) measurementReq.setDataIn2ValueType(measurement.dataIn2.type);
		if (measurement.dataIn2.data !== undefined) measurementReq.setDataIn2(measurement.dataIn2.data);
		if (measurement.dataIn2.variable !== undefined)
			measurementReq.setDataIn2VariableId(measurement.dataIn2.variable.uuid);
		if (measurement.dataIn2.specimenParamType !== undefined)
			measurementReq.setDataIn2SpecimenParamType(measurement.dataIn2.specimenParamType);
	}
	if (measurement.unit) {
		const unitFormula = new UnitFormula();
		unitFormula.setName(measurement.unit.name);
		unitFormula.setScale(measurement.unit.scale);
		unitFormula.setOffset(measurement.unit.offset);

		measurementReq.setUnit(unitFormula);
	}

	return measurementReq;
};

export const measurementResponsesToMeasurementGRPCsConverter: Converter<
	MeasurementResponseDto[],
	Array<MeasurementRequest>
> = (measurements) => {
	return measurements.map((measurement) => measurementResponseToMeasurementGRPCConverter(measurement));
};
