import { useMemo } from 'react';
import { PageLayout, Form, InputText, Dropdown } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { TestInformationFormType } from '../../tests.types';
import { IListItem, IListItemNumber } from '../../../../types';
import styles from './TestInformation.module.scss';
import DefaultImage from '../../../ImageUploader/assets/defaultImage.svg';
import { useTest } from '../../tests.hooks';

interface TestProps {
	isCreate: boolean;
	form: UseFormReturn<TestInformationFormType>;
	specimenImageUrl?: string;
	timeUnits: IListItem[];
	stations: IListItem[];
	axis: IListItem[];
	specimenTypes: IListItem[];
	unitSystems: IListItemNumber[];
	dataStorageTypes: IListItemNumber[];
	storageFullBehaviorTypes: IListItemNumber[];
	testStandards: string[];
}

const formId = 'testForm';
const Test: React.FC<TestProps> = ({
	form,
	specimenImageUrl,
	timeUnits,
	stations,
	axis,
	specimenTypes,
	unitSystems,
	dataStorageTypes,
	storageFullBehaviorTypes,
	testStandards
}) => {
	const { t } = useTranslation('test');
	const {
		control,
		formState: { errors },
		register
	} = form;
	const { testId } = useParams();
	const { testDto } = useTest(testId);

	const title = useMemo<string>(() => {
		// return isCreate ? t('Create Test') : t('Edit Test');
		return t('General');
	}, [t]);

	return (
		<PageLayout info={testId ? testDto?.name : t('New Test')}>
			<PageLayout.Header title={title} />
			<div className={styles.pageContainer}>
				<Form className={styles.container} id={formId}>
					<input type="hidden" {...register('id')} />
					<input type="hidden" {...register('imageId')} />
					<Controller
						name="name"
						control={control}
						render={({ field }) => (
							<InputText data-testid="testName" label={t('Test name*')} {...field} error={errors.name?.message} />
						)}
					/>
					<Controller
						name="standard"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								data-testid="testStandard"
								options={testStandards}
								label={t('Standard*')}
								error={errors.standard?.message}
								editable
								itemName={t('Standard')}
							/>
						)}
					/>
					<Controller
						name="station"
						control={control}
						render={({ field }) => (
							<Dropdown
								data-testid="testStation"
								{...field}
								options={stations}
								label={t('Station*')}
								error={errors.station?.message}
							/>
						)}
					/>
					<Controller
						name="axis"
						control={control}
						render={({ field }) => (
							<Dropdown
								isMulti
								{...field}
								data-testid="testAxis"
								value={axis?.length > 0 ? field.value : []}
								options={axis}
								label={t('Axis*')}
								error={errors.axis?.message}
								filter
							/>
						)}
					/>
					<Controller
						name="specimenType"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={specimenTypes}
								label={t('Specimen type*')}
								data-testid="specimenType"
								error={errors.specimenType?.message}
							/>
						)}
					/>
					<Controller
						name="unitSystem"
						control={control}
						render={({ field }) => (
							<Dropdown
								data-testid="unitSystem"
								{...field}
								options={unitSystems}
								label={t('Unit system*')}
								error={errors.unitSystem?.message}
							/>
						)}
					/>
					<Controller
						name="timeUnit"
						control={control}
						render={({ field }) => (
							<Dropdown
								data-testid="timeUnit"
								{...field}
								options={timeUnits}
								label={t('Time unit*')}
								error={errors.timeUnit?.message}
							/>
						)}
					/>
					<Controller
						name="dataStorage"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								data-testid="dataStorage"
								options={dataStorageTypes}
								label={t('Data storage (Memory)*')}
								error={errors.dataStorage?.message}
							/>
						)}
					/>
					<Controller
						name="storageFullBehavior"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={storageFullBehaviorTypes}
								label={t('Storage full behavior*')}
								data-testid="storageFullBehavior"
								error={errors.storageFullBehavior?.message}
							/>
						)}
					/>
					<div />
					<div className={styles.FormDivider}>
						<Controller
							name="description"
							control={control}
							render={({ field }) => (
								<InputText
									{...field}
									textarea
									label={t('Test description')}
									error={errors.description?.message}
									maxLength={1000}
									data-testid="testDescription"
								/>
							)}
						/>
					</div>
				</Form>
				<div className={styles.specimenImageContainer}>
					<div className={styles.fieldLabel}>{t('Specimen image')}</div>
					<div className={styles.specimen}>
						<img data-testid="specimenImage" src={specimenImageUrl || DefaultImage} alt="specimen" />
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default Test;
