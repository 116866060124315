import { object, string, mixed, number } from 'yup';
import { VariablesTypes } from './variables.enums';
import * as api from './variables.api';
import { alphaNumericRegex } from '../../constants/regexes';
import { emptyStringToNull } from '../../tools/yupHelpers';

export const variableFormSchema = object()
	.shape(
		{
			id: string().notRequired(),
			name: string()
				.required('Name is a required field')
				.max(30)
				.matches(alphaNumericRegex, 'Is not in correct format')
				.test('checkVariableUniqueness', 'Name is used', function (value, form) {
					if (!value) return true;
					if (form?.parent?.testId) {
						return api.checkTesVarName(value, form?.parent?.testId, form?.parent?.id);
					} else {
						return api.checkName(value, form?.parent?.stationId, form?.parent?.id);
					}
				}),
			type: mixed<VariablesTypes>().required('Type is a required field'),
			defaultValue: mixed<number | string>()
				.optional()
				.when(['type'], {
					is: VariablesTypes.STRING,
					then: () => string().required('Default value is a required field'),
					otherwise: () => number().transform(emptyStringToNull).required('Default value is a required field')
				}),
			testId: string()
				.nullable()
				.when('stationId', {
					is: undefined,
					then: (schema) => schema.required(),
					otherwise: (schema) => schema.notRequired()
				}),
			stationId: string()
				.nullable()
				.when('testId', {
					is: undefined,
					then: (schema) => schema.required(),
					otherwise: (schema) => schema.notRequired()
				})
		},
		[['testId', 'stationId']]
	)
	.required();
