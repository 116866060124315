import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsChanged } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { sensorFormSchema } from '../sensors.schemas';
import SensorForm from '../components/SensorForm';
import { useAvailableStationChannels, useSaveSensor, useSensor, useSensorsManufacturers } from '../sensors.hooks';
import { useOnce } from '../../../hooks';
import { SENSOR_CHANNELS_MAP } from '../sensors.const';
import { useStationChannel, useStationChannelMeta } from '../../StationChannels/stationChannels.hooks';
import { useStationName } from '../../Stations/stations.hooks';
import { responseFormConverter } from '../sensors.converters';
import { IListItem } from '../../../types';
import { useQuantities, useQuantityAsOptions } from '../../Units';
import CreateEditPageTemplate from '../../../components/CreateEditPageTemplate';
import { SensorFormType } from '../sensors.types';
import { CalibrationsTypes } from '../../Calibrations';
import { unitConvertor } from '../../../tools/unitConvertor';
import { useSaveCalibration } from '../../Calibrations/calibrations.hooks';
import * as convertor from '../../Calibrations/calibrations.converters';

const defaultValues = {
	name: '',
	type: undefined,
	stationChannelId: '',
	stationId: '',
	manufacturer: '',
	serialNumber: '',
	isInversePolarity: false,
	isShowRawData: false,
	isAveragingFilter: false,
	averagingFilterPoint: undefined,
	id: ''
};

const sensorFormId = 'sensorFormId';

const SensorContainer: React.FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('sensors');
	const { stationId, sensorId } = useParams();
	const { stationName } = useStationName(stationId);
	const { quantities } = useQuantities('USER');
	const isCreate = !sensorId;
	const form = useForm({
		defaultValues: { ...defaultValues, stationId },
		mode: 'onBlur',
		resolver: yupResolver(sensorFormSchema)
	});
	const {
		watch,
		handleSubmit,
		reset,
		setValue,
		formState: { isDirty }
	} = form;
	const type = watch('type');
	const stationChannelId = watch('stationChannelId');
	const isTypeChanged = useIsChanged(type);
	const { stationChannelDto } = useStationChannel(stationChannelId);
	const stationChannelMeta = useStationChannelMeta(stationId, stationChannelDto?.type);
	const { sensor: currentSensor } = useSensor(sensorId);
	const { stationChannels } = useAvailableStationChannels(stationId);
	const manufacturers = useSensorsManufacturers();
	const quantitiesOptions = useQuantityAsOptions(quantities ?? []);
	const { isLoading: isLoadingCalibration, save: saveCalibration } = useSaveCalibration(sensorId);

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/sensors`);
	}, [navigate, stationId]);
	const { isLoading, save } = useSaveSensor(sensorId, handleBack);

	const handleReset = useCallback(() => {
		if (currentSensor) {
			const sensorRequestData = responseFormConverter(currentSensor);

			reset({ ...sensorRequestData, stationId, id: currentSensor.id }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultValues, stationId }, { keepDirty: true, keepTouched: true });
		}
	}, [currentSensor, reset, stationId]);

	const handleSave = handleSubmit((sensorForm: SensorFormType) => {
		if (currentSensor?.unit.id !== sensorForm.unitId) {
			const currentQuantity = quantities?.find((q) => q.units.some((u) => u.id === sensorForm.unitId));
			const currentUnit = currentQuantity?.units.find((u) => u.id === sensorForm.unitId);
			const previewsUnit = currentQuantity?.units.find((u) => u.id === currentSensor?.unit.id);

			if (currentUnit && previewsUnit) {
				const calibration = currentSensor?.calibrations ? currentSensor?.calibrations[0] : null;
				if (calibration) {
					if (calibration.type === CalibrationsTypes.LINEAR) {
						if (calibration.offset) {
							calibration.offset = unitConvertor(calibration.offset, previewsUnit, currentUnit);
						}
						if (calibration.slopeOrScaleFactor) {
							calibration.slopeOrScaleFactor = unitConvertor(calibration.slopeOrScaleFactor, previewsUnit, currentUnit);
						}
					} else {
						calibration.calibrationData = calibration.calibrationData?.map((d) => ({
							...d,
							csd: unitConvertor(d.csd, previewsUnit, currentUnit)
						}));
					}
					saveCalibration(
						convertor.formRequestConverter(convertor.responseFormConverter(calibration, currentSensor?.id)),
						calibration.id
					);
				}
			}
		}
		save(sensorForm);
	});

	const stationChannelsByType = useMemo(() => {
		const channels = stationChannels?.filter((channel) => SENSOR_CHANNELS_MAP[type]?.includes(channel.type));

		if (currentSensor?.stationChannel && SENSOR_CHANNELS_MAP[type]?.includes(currentSensor?.stationChannel.type)) {
			channels?.push(currentSensor.stationChannel);
		}

		return channels;
	}, [stationChannels, type, currentSensor]);

	const manufacturerOptions = useMemo<IListItem[]>(
		() =>
			manufacturers.map((manufacturer) => ({
				label: manufacturer,
				value: manufacturer
			})),
		[manufacturers]
	);

	const initTrigger = useOnce(!!currentSensor);
	// Setup data for edit
	useEffect(() => {
		if (initTrigger) {
			handleReset();
		}
	}, [initTrigger, handleReset]);

	useEffect(() => {
		if (isTypeChanged && isDirty) {
			setValue('stationChannelId', '');
		}
	}, [isDirty, isTypeChanged, setValue]);

	return (
		<CreateEditPageTemplate
			isLoading={isLoading || isLoadingCalibration}
			title={isCreate ? t('Create Sensor') : t('Edit Sensor')}
			stationName={stationName}
			formId={sensorFormId}
			onBack={handleBack}
		>
			<SensorForm
				form={form}
				formId={sensorFormId}
				onSave={handleSave}
				stationChannels={stationChannelsByType || []}
				stationChannelMeta={stationChannelMeta}
				quantitiesOptions={quantitiesOptions}
				manufacturerOptions={manufacturerOptions}
			/>
		</CreateEditPageTemplate>
	);
};

export default React.memo(SensorContainer);
