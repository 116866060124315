import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, InputText, Modal, Icon } from '@tactun/ui';
import { useTranslation } from 'react-i18next';

import CalculatorButton from '../components/CalculatorButton';
import {
	CalculatorDigitValues,
	CalculatorFunctionValues,
	CalculatorOperatorValues,
	CalculatorConstValues,
	CalculatorValueTypes
} from '../calculator.enums';

import styles from './Calculator.module.scss';
import { useCalculator } from '../calculator.hooks';

export interface CalculatorProps {
	isOpen: boolean;
	initialValue: string;
	onCancel: () => void;
	onSave: (v: string) => void;
	variables: string[];
}

const Calculator: React.FC<CalculatorProps> = ({ isOpen, initialValue, onCancel, onSave, variables }) => {
	const { t } = useTranslation('specimens');

	const { add, remove, reset, isValid, allowedOperations, toggleLastTermNegativeSign, value, valueUserFriendly } =
		useCalculator(initialValue);

	// Reset calculator
	useEffect(() => {
		if (isOpen) {
			reset(initialValue);
		}
	}, [initialValue, isOpen, reset]);

	// Variables
	const [selectedVariable, setSelectedVariable] = useState<string | null>(null);
	const addVariable = useCallback(() => {
		if (!selectedVariable) return;
		add({ type: CalculatorValueTypes.VARIABLE, value: selectedVariable });
		setSelectedVariable(null);
	}, [add, selectedVariable]);

	// Button click handle
	const onButtonClick = useCallback(
		(type?: CalculatorValueTypes, value?: string) => {
			if (value && typeof type === 'number') add({ type, value });
		},
		[add]
	);

	// Modal cancel / save
	const close = useCallback(() => {
		if (onCancel) {
			onCancel();
		} else {
			Modal.hide('calculator');
		}
	}, [onCancel]);

	const handleConfirm = useCallback(() => {
		onSave(value);
	}, [onSave, value]);

	return (
		<>
			<Modal id="calculator" isOpen={isOpen} onClose={onCancel} isFitContent>
				<Modal.Header>{t('Calculator')}</Modal.Header>
				<Modal.Content>
					<InputText textarea name="" className={styles.input} value={valueUserFriendly} readOnly />
					<div className={styles.variable}>
						<Dropdown name="variable" value={selectedVariable} onChange={setSelectedVariable} options={variables} />
						<Button
							variant="contained"
							color="primary"
							type="button"
							onClick={addVariable}
							disabled={!selectedVariable || !allowedOperations[CalculatorValueTypes.VARIABLE]}
						>
							{t('Add')}
						</Button>
					</div>
					<div className={styles.buttons}>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.FUNCTION]}
							type={CalculatorValueTypes.FUNCTION}
							value={CalculatorFunctionValues.SQRT}
						>
							<Icon name="calc-root" size={16}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.OPEN_PARENTHESIS]}
							type={CalculatorValueTypes.OPEN_PARENTHESIS}
							value="("
						>
							<Icon name="calc-parenthesis-open"></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.CLOSE_PARENTHESIS]}
							type={CalculatorValueTypes.CLOSE_PARENTHESIS}
							value=")"
						>
							<Icon name="calc-parenthesis-close"></Icon>
						</CalculatorButton>
						<CalculatorButton blueText onClick={() => reset()}>
							<Icon name="calc-C" size={16}></Icon>
						</CalculatorButton>
						<CalculatorButton blueText onClick={remove}>
							<Icon name="calc-back"></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.DIGIT]}
							type={CalculatorValueTypes.DIGIT}
							value={CalculatorDigitValues.SEVEN}
							whiteBack
						>
							<Icon name="calc-seven" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.DIGIT]}
							type={CalculatorValueTypes.DIGIT}
							value={CalculatorDigitValues.EIGHT}
							whiteBack
						>
							<Icon name="calc-eight" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.DIGIT]}
							type={CalculatorValueTypes.DIGIT}
							value={CalculatorDigitValues.NINE}
							whiteBack
						>
							<Icon name="calc-nine" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.OPERATOR]}
							type={CalculatorValueTypes.OPERATOR}
							value={CalculatorOperatorValues.DIVIDE}
							className={styles.calcOperation}
						>
							<Icon name="calc-divide" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.OPERATOR]}
							type={CalculatorValueTypes.OPERATOR}
							value={CalculatorOperatorValues.SQR}
						>
							<Icon name="calc-x-square" size={18}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.OPERATOR]}
							type={CalculatorValueTypes.OPERATOR}
							value={CalculatorOperatorValues.POWER}
						>
							<Icon name="calc-x-y" size={18}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.FUNCTION]}
							type={CalculatorValueTypes.FUNCTION}
							value={CalculatorFunctionValues.EXP}
						>
							<Icon name="calc-e-x" size={18}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.FUNCTION]}
							type={CalculatorValueTypes.FUNCTION}
							value={CalculatorFunctionValues.TAN}
						>
							<Icon name="calc-tan" size={27}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.FUNCTION]}
							type={CalculatorValueTypes.FUNCTION}
							value={CalculatorFunctionValues.ATAN}
						>
							<Icon name="calc-tan-minus-one" size={34}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.DIGIT]}
							type={CalculatorValueTypes.DIGIT}
							value={CalculatorDigitValues.FOUR}
							whiteBack
						>
							<Icon name="calc-four" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.DIGIT]}
							type={CalculatorValueTypes.DIGIT}
							value={CalculatorDigitValues.FIVE}
							whiteBack
						>
							<Icon name="calc-five" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.DIGIT]}
							type={CalculatorValueTypes.DIGIT}
							value={CalculatorDigitValues.SIX}
							whiteBack
						>
							<Icon name="calc-six" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.OPERATOR]}
							type={CalculatorValueTypes.OPERATOR}
							value={CalculatorOperatorValues.MULTIPLY}
							className={styles.calcOperation}
						>
							<Icon name="zoom-multiply" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.FUNCTION]}
							type={CalculatorValueTypes.FUNCTION}
							value={CalculatorFunctionValues.ABS}
						>
							<Icon name="calc-abs" size={30}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.FUNCTION]}
							type={CalculatorValueTypes.FUNCTION}
							value={CalculatorFunctionValues.SIN}
						>
							<Icon name="calc-sin" size={23}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.FUNCTION]}
							type={CalculatorValueTypes.FUNCTION}
							value={CalculatorFunctionValues.ASIN}
						>
							<Icon name="calc-sin-minus-one" size={34}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.FUNCTION]}
							type={CalculatorValueTypes.FUNCTION}
							value={CalculatorFunctionValues.COS}
						>
							<Icon name="calc-cos" size={30}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.FUNCTION]}
							type={CalculatorValueTypes.FUNCTION}
							value={CalculatorFunctionValues.ACOS}
						>
							<Icon name="calc-cos-minus-one" size={37}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.DIGIT]}
							type={CalculatorValueTypes.DIGIT}
							value={CalculatorDigitValues.ONE}
							whiteBack
						>
							<Icon name="calc-one" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.DIGIT]}
							type={CalculatorValueTypes.DIGIT}
							value={CalculatorDigitValues.TWO}
							whiteBack
						>
							<Icon name="calc-two" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.DIGIT]}
							type={CalculatorValueTypes.DIGIT}
							value={CalculatorDigitValues.THREE}
							whiteBack
						>
							<Icon name="calc-three" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.OPERATOR]}
							type={CalculatorValueTypes.OPERATOR}
							value={CalculatorOperatorValues.MINUS}
							className={styles.calcOperation}
						>
							<Icon name="minus" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.CONST]}
							type={CalculatorValueTypes.CONST}
							value={CalculatorConstValues.PI}
						>
							<Icon name="calc-pi" size={12}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.CONST]}
							type={CalculatorValueTypes.CONST}
							value={CalculatorConstValues.E}
						>
							<Icon name="calc-e" size={12}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.FUNCTION]}
							type={CalculatorValueTypes.FUNCTION}
							value={CalculatorFunctionValues.LOG}
						>
							<Icon name="calc-log" size={18}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.FUNCTION]}
							type={CalculatorValueTypes.FUNCTION}
							value={CalculatorFunctionValues.LN}
						>
							<Icon name="calc-ln" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={toggleLastTermNegativeSign}
							disabled={!allowedOperations[CalculatorValueTypes.NEGATIVE_SIGN]}
							value="+/-"
						>
							<Icon name="calc-plus-minus"></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.DIGIT]}
							type={CalculatorValueTypes.DIGIT}
							value={CalculatorDigitValues.ZERO}
							doubleWidth
							whiteBack
						>
							<Icon name="calc-zero" size={14}></Icon>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.DECIMAL_POINT]}
							type={CalculatorValueTypes.DECIMAL_POINT}
							value="."
							whiteBack
						>
							<span className={styles.calcDot}></span>
						</CalculatorButton>
						<CalculatorButton
							onClick={onButtonClick}
							disabled={!allowedOperations[CalculatorValueTypes.OPERATOR]}
							type={CalculatorValueTypes.OPERATOR}
							value={CalculatorOperatorValues.PLUS}
							className={styles.calcOperation}
						>
							<Icon name="plus" size={14}></Icon>
						</CalculatorButton>
					</div>
				</Modal.Content>
				<Modal.Footer>
					<Button className="btnCancel" onClick={close} color="success" variant="text">
						{t('Cancel')}
					</Button>
					<Button disabled={!isValid} onClick={handleConfirm} color="secondary" variant="contained">
						{t('Save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default React.memo(Calculator);
