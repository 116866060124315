import { BottomBar, Button, InfoBadge, InfoBadgeIcons } from '@tactun/ui';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import styles from './WizardLayout.module.scss';

export interface IWizardLayout {
	onNext?: () => void;
	onPrevious?: () => void;
	onBack?: () => void;
	footerItems?: React.ReactNode;
	isNextDisabled?: boolean;
	isPreviousDisabled?: boolean;
	isSaving?: boolean;
	backLabel?: string;
	children: React.ReactNode;
	isLastStep?: boolean;
	activeStep?: string;
}

const WizardLayout: React.FC<IWizardLayout> = ({
	onNext,
	onPrevious,
	onBack,
	footerItems,
	isNextDisabled,
	isPreviousDisabled,
	isSaving,
	backLabel,
	children,
	isLastStep
}) => {
	const { t } = useTranslation();

	const [isMouseOverSidebar, setIsMouseOverSidebar] = useState(false);
	const [isSidebarOpen, setIsSidebarOpen] = useState(true);
	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
		setIsMouseOverSidebar(!isSidebarOpen);
	};

	const onMouseEnter = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (!(e.target as HTMLDivElement).getAttribute('data-prevent-hover')) {
			setIsMouseOverSidebar(true);
		}
	}, []);

	const onMouseLeave = useCallback(() => {
		setIsMouseOverSidebar(false);
	}, []);

	return (
		<div
			className={cx(styles.container, {
				[styles.sidebarClosed]: !isSidebarOpen
			})}
		>
			<div
				className={cx(styles.sidebar, {
					[styles.visible]: isSidebarOpen || isMouseOverSidebar
				})}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{onBack && (
					<Button
						label={backLabel ? backLabel : t('Back')}
						className={styles.backButton}
						variant="text"
						color="primary"
						icon="t-icon-next"
						data-testid="backID"
						onClick={onBack}
					/>
				)}
				<div className={styles.steps}>{children}</div>
				{/* <span className={styles.sidebarButton} data-prevent-hover="true" onClick={() => toggleSidebar()}>
					<span className={styles.icon}></span>
				</span> */}

				<Button
					rounded
					color="success"
					data-prevent-hover="true"
					icon="t-icon-next"
					data-testid="toggleSidebar"
					className={cx(styles.sidebarButton)}
					onClick={() => toggleSidebar()}
				/>
			</div>
			<div className={styles.content}>
				<Outlet />
			</div>
			{onPrevious && onNext && (
				<BottomBar>
					{isSaving && (
						<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
							{t('Saving...')}
						</InfoBadge>
					)}
					{footerItems}
					<Button
						label={t('Previous')}
						data-testid="cancelBtn"
						variant="contained"
						color="success"
						onClick={onPrevious}
						disabled={isPreviousDisabled}
					/>
					<Button
						label={t(isLastStep ? 'Complete' : 'Next')}
						data-testid="saveBtn"
						variant="contained"
						color="secondary"
						onClick={onNext}
						disabled={isNextDisabled}
					/>
					{/* TODO: Ugly hack: done for demo */}
					{/*{activeStep === '/stations/:stationId/permissions' && (*/}
					{/*	<Button*/}
					{/*		label={t('Save')}*/}
					{/*		data-testid="saveBtn"*/}
					{/*		variant="contained"*/}
					{/*		color="secondary"*/}
					{/*		onClick={onNext}*/}
					{/*		disabled={isNextDisabled}*/}
					{/*	/>*/}
					{/*)}*/}
				</BottomBar>
			)}
		</div>
	);
};
export default WizardLayout;
