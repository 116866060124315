import cx from 'classnames';
import styles from './PageFilters.module.scss';

export interface IPageFiltersProps {
	children: React.ReactNode;
	className?: string;
}

const PageFilters: React.FC<IPageFiltersProps> = ({ children, className }) => {
	return <div className={cx(styles.container, className)}>{children}</div>;
};
PageFilters.displayName = 'PageFilters';

export default PageFilters;
