import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { formRequestConverter } from '.';
import { ChannelType, Direction } from '../StationChannels';
import * as api from './sensors.api';
import { toast } from 'react-toastify';
import { deleteEmptyFields } from '../../tools';
import { SensorFormType, SensorRequestDto, SensorResponseDto } from './sensors.types';

export const useSensors = (stationId?: string, onlyCalibrated: boolean = false) => {
	const { data: sensors, isLoading: isSensorsLoading } = useQuery({
		queryKey: ['sensors', { stationId }],
		// When we provide enable option - !!sensorId, the sensorId is guaranteed
		queryFn: async () => {
			const sensors = await api.getSensorsForStation(stationId as string);
			return onlyCalibrated ? sensors.filter((sensor) => sensor.calibrations?.length) : sensors;
		},
		enabled: !!stationId
	});

	const queryClient = useQueryClient();

	const updateSensors = () => {
		queryClient.invalidateQueries({ queryKey: ['sensors', { stationId }] });
	};

	return {
		sensors,
		isSensorsLoading,
		updateSensors
	};
};

export const useSensor = (sensorId?: string) => {
	const { data: sensor, isLoading: isSensorLoading } = useQuery<SensorResponseDto>({
		queryKey: ['sensor', { sensorId }],
		// When we provide enable option - !!sensorId, the sensorId is guaranteed
		queryFn: () => api.getSensor(sensorId as string),
		enabled: !!sensorId
	});

	const queryClient = useQueryClient();

	const updateSensor = () => {
		queryClient.invalidateQueries({ queryKey: ['sensors', { sensorId }] });
	};

	return {
		sensor,
		isSensorLoading,
		updateSensor
	};
};

export const useAvailableStationChannels = (stationId?: string) => {
	const queryKey = useMemo(() => ['availableStationChannels', stationId], [stationId]);
	const { data: stationChannels } = useQuery({
		queryKey,
		queryFn: () => api.getAvailableStationChannels(stationId as string),
		enabled: !!stationId
	});

	const queryClient = useQueryClient();

	const updateStationChannels = useCallback(() => {
		queryClient.invalidateQueries({ queryKey });
	}, [queryClient, queryKey]);

	return {
		stationChannels,
		updateStationChannels
	};
};

export const useDigitalInputChannels = (stationId?: string) => {
	const { stationChannels } = useAvailableStationChannels(stationId);

	const digitalInputChannels = useMemo(
		() =>
			stationChannels?.filter((channel) => {
				const isDigitalIn = channel.type === ChannelType.DIGITAL_IN_24V;
				const isCorrectDigitalIo =
					(channel.type === ChannelType.DIGITAL_IO_5V || channel.type === ChannelType.DIGITAL_IO_24V) &&
					channel.direction === Direction.INPUT;
				return isDigitalIn || isCorrectDigitalIo;
			}) || [],
		[stationChannels]
	);

	return { digitalInputChannels };
};

export const useDigitalOutputChannels = (stationId?: string) => {
	const { stationChannels } = useAvailableStationChannels(stationId);

	const digitalOutputChannels = useMemo(
		() =>
			stationChannels?.filter((channel) => {
				const isCorrectDigitalIo =
					(channel.type === ChannelType.DIGITAL_IO_5V || channel.type === ChannelType.DIGITAL_IO_24V) &&
					channel.direction === Direction.OUTPUT;
				return isCorrectDigitalIo;
			}) || [],
		[stationChannels]
	);

	return { digitalOutputChannels };
};

export const useSensorsManufacturers = () => {
	const { data } = useQuery({
		queryKey: ['sensorsManufacturers'],
		queryFn: () => api.getSensorsManufacturers()
	});

	return data || [];
};

export const useSaveSensor = (sensorId?: string, onSaveSuccess?: () => void) => {
	const queryClient = useQueryClient();

	const createMutation = useMutation({
		mutationFn: (sensor: SensorRequestDto) => api.createSensor(sensor),
		onSuccess: (sensor) => {
			queryClient.invalidateQueries({ queryKey: ['stations_full', { stationId: sensor.stationId }] });
			if (onSaveSuccess) onSaveSuccess();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { sensor: SensorRequestDto; sensorId: string }) => api.updateSensor(data.sensor, data.sensorId),
		onSuccess: (sensor) => {
			queryClient.invalidateQueries({ queryKey: ['stations_full', { stationId: sensor.stationId }] });
			if (onSaveSuccess) onSaveSuccess();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const save = useCallback(
		(data: SensorFormType) => {
			const filteredData = deleteEmptyFields<SensorRequestDto>(formRequestConverter(data));
			if (sensorId) {
				updateMutation.mutate({
					sensor: filteredData,
					sensorId
				});
			} else {
				createMutation.mutate(filteredData);
			}
		},
		[sensorId, createMutation, updateMutation]
	);

	return {
		save,
		isLoading: createMutation.isPending || updateMutation.isPending
	};
};
