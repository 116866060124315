export enum CalculationsTableActionTypes {
	EDIT = 'EDIT_CALCULATION',
	CREATE = 'CREATE_CALCULATION',
	DELETE = 'DELETE_CALCULATION',
	DUPLICATE = 'DUPLICATE_CALCULATION'
}

export enum CalculationGroups {
	STANDARD_CALCULATIONS = 'Standard Calculations',
	MODULE_CALCULATIONS = 'Modulus Calculations',
	ADVANCED_CALCULATIONS = 'Advanced Calculations',
	STATISTICAL_CALCULATIONS = 'Statistical Calculations'
}

export enum CalculationChannelTypes {
	MEASUREMENT = 0,
	CYCLE
}

export enum CalculationTypes {
	MEASUREMENT_AT_PEAK_LOAD = 0,
	MODULUS_OF_ELASTICITY,
	YIELD_OFFSET,
	MEASUREMENT_AT_YIELD,
	ENERGY,
	MEASUREMENT_AT_BREAK,
	MAXIMUM_LOAD_OVER_WIDTH,
	DATA_POINT,
	MAXIMUM,
	MINIMUM,
	MEAN
}

export enum StatisticalCalcRanges {
	WHOLE = 0,
	CUSTOM
}

export enum ReferencePointTypes {
	START = 0,
	END,
	REFERENCE_CHANNEL
}

export enum PrecisionTypes {
	PRECISION_DIGITS = 'PRECISION_DIGITS',
	SIGNIFICANT_DIGITS = 'SIGNIFICANT_DIGITS'
}

export enum LimitTypes {
	STRESS_POINT = 0,
	STRAIN_POINT,
	MAX_STRESS,
	AUTO
}

export enum LimitDefinitions {
	NOMINAL = 'NOMINAL',
	ABSOLUTE = 'ABSOLUTE'
}
