import { FC, useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { WidgetsListItem, WidgetResponseDto } from '../widgetsConfiguration.types';
import WidgetsConfigurationTable from '../components/WidgetsConfigurationTable';
import { WidgetCrudActionTypes } from '../widgetsConfiguration.enums';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { useStationName } from '../../Stations/stations.hooks';
import { responseListItemConverter } from '../widgetsConfiguration.converters';
import { useWidgets } from '../widgetsConfiguration.hooks';

const WidgetsConfigurationTableContainer: FC = () => {
	const navigate = useNavigate();
	const { stationId } = useParams();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const queryClient = useQueryClient();
	const { widgets, isWidgetsLoading } = useWidgets(stationId);
	const widgetList = widgets?.map((widget) => responseListItemConverter(widget));
	const { stationName } = useStationName(stationId);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			queryClient.setQueryData<WidgetResponseDto[]>(['widgets', stationId], (old) => {
				if (!old) return undefined;
				return old.filter((hc) => hc.uuid !== id);
			});
		},
		[queryClient, stationId]
	);

	const handleAction = useCallback(
		(type: WidgetCrudActionTypes, data?: WidgetsListItem) => {
			switch (type) {
				case WidgetCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case WidgetCrudActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
			}
		},
		[navigate]
	);

	return (
		<>
			<WidgetsConfigurationTable
				onAction={handleAction}
				data={widgetList}
				isLoading={isWidgetsLoading}
				stationName={stationName}
			/>
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.WIDGETS}
				entity={entityForDelete}
			/>
		</>
	);
};

export default WidgetsConfigurationTableContainer;
