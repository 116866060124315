import { useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Wizard } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import * as paths from './tests.const';
import TestsTableContainer from './containers/TestTableContainer';
import TestContainer from './containers/TestInformationContainer';
import TestConfigurationContainer from './containers/TestConfigurationContainer';
import TestWizardContainer from './containers/TestWizardContainer';
import { TestDashboardConfigurationContainer } from '../TestDashboardConfiguration';
import TestReportConfiguration from '../TestReportConfiguration';

const steps = [
	{ path: paths.generalInfoPath, name: 'General' },
	{ path: paths.configurationPath, name: 'Configuration' },
	{ path: paths.reportConfigurationPath, name: 'Report' }
	// { path: paths.dashboardConfiguration, name: 'Dashboard Configuration' }
];

const TestsRouts: React.FC = () => {
	const { t } = useTranslation('test');

	const transSteps = useMemo(() => steps.map((step) => ({ ...step, name: t(step.name) })), [t]);
	const createSteps = useMemo(
		() =>
			transSteps.map((step) => {
				return { ...step, path: step.path === paths.generalInfoPath ? '/tests/create' : step.path };
			}),
		[transSteps]
	);

	return (
		<Routes>
			<Route index element={<TestsTableContainer />} />
			<Route
				path="create"
				element={
					<Wizard steps={createSteps}>
						<TestWizardContainer />
					</Wizard>
				}
			>
				<Route index element={<TestContainer />} />
			</Route>
			<Route
				path=":testId/*"
				element={
					<Wizard steps={transSteps}>
						<TestWizardContainer />
					</Wizard>
				}
			>
				<Route index element={<TestContainer />} />
				<Route path="configuration" element={<TestConfigurationContainer />} />
				<Route path="report-configuration" element={<TestReportConfiguration />} />
				<Route path="dashboard-configuration" element={<TestDashboardConfigurationContainer />} />
			</Route>
		</Routes>
	);
};

export default TestsRouts;
