import { useCallback, useRef, useState, DragEvent, useMemo } from 'react';
import { uploadImage } from './imageUploader.api';
import { IImageUploadResponse } from './imageUploader.types';

export const useImageUploader = <DraggableElement>(
	DefaultImage: string,
	DefaultImageActive: string,
	onChange: (image: IImageUploadResponse | null) => void,
	initialImage?: string
) => {
	const [splashImage, setSplashImage] = useState<string>(DefaultImage);
	const [previewImg, setPreviewImg] = useState<string | undefined>(initialImage);

	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleImageUpload = useCallback(
		async (file: File) => {
			const formData = new FormData();
			formData.append('image', file);

			const response = await uploadImage(formData);
			onChange(response);
		},
		[onChange]
	);

	const handleChange = useCallback(() => {
		if (fileInputRef?.current?.files?.length) {
			const url = URL.createObjectURL(fileInputRef.current.files[0]);
			handleImageUpload(fileInputRef.current.files[0]);
			setPreviewImg(url);
		}
	}, [handleImageUpload]);

	const handleChangeClick = useCallback(() => {
		if (fileInputRef?.current) {
			fileInputRef?.current.click();
		}
	}, []);

	const handleDelete = useCallback(() => {
		setPreviewImg(undefined);
		if (fileInputRef.current?.files) {
			fileInputRef.current.files = null;
			fileInputRef.current.value = '';
		}
		onChange(null);
	}, [onChange]);

	const handleDragOver = useCallback(
		(e: DragEvent<DraggableElement>) => {
			e.preventDefault();
			setSplashImage(DefaultImageActive);
		},
		[setSplashImage, DefaultImageActive]
	);

	const handleDragEnd = useCallback(
		(e: DragEvent<DraggableElement>) => {
			e.preventDefault();
			if (fileInputRef.current) {
				fileInputRef.current.files = e.dataTransfer.files;
				handleChange();
			}
			setSplashImage(DefaultImage);
		},
		[DefaultImage, setSplashImage, handleChange]
	);

	const result = useMemo(
		() => ({
			inputProps: {
				onChange: handleChange,
				type: 'file',
				style: { display: 'none' }
			},
			draggableElementProps: {
				onDragOver: handleDragOver,
				onDragLeave: handleDragEnd,
				onDropCapture: handleDragEnd
			},
			ref: fileInputRef,
			displayedImage: initialImage || previewImg || splashImage,
			onDelete: handleDelete,
			onChangeClick: handleChangeClick
		}),
		[
			handleChange,
			handleChangeClick,
			handleDelete,
			handleDragOver,
			handleDragEnd,
			previewImg,
			splashImage,
			initialImage
		]
	);

	return result;
};
