import { PageLayout, RadioButton } from '@tactun/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Permissions.module.scss';

enum Permission {
	READ_ONLY = 0,
	EDIT,
	HIDE
}

const Permissions: React.FC = () => {
	const { t } = useTranslation();
	const [unitSystemPermission, setUnitSystemPermission] = useState<Permission>(Permission.EDIT);
	const [timeUnitPermission, setTimeUnitPermission] = useState<Permission>(Permission.EDIT);
	const [stationChannelsInUsePermission, setStationChannelsInUsePermission] = useState<Permission>(
		Permission.READ_ONLY
	);
	const [stationChannelsPermission, setStationChannelsPermission] = useState<Permission>(Permission.EDIT);
	const [sensorsPermission, setSensorsPermission] = useState<Permission>(Permission.EDIT);
	const [sensorCalibrationPermission, setSensorCalibrationPermission] = useState<Permission>(Permission.EDIT);
	const [diffEncoderPermission, setDiffEncoderPermission] = useState<Permission>(Permission.EDIT);
	const [singleEncoderPermission, setSingleEncoderPermission] = useState<Permission>(Permission.EDIT);
	const [averagingFilterPermission, setAveragingFilterPermission] = useState<Permission>(Permission.EDIT);
	const [inversePolarityPermission, setInversePolarityPermission] = useState<Permission>(Permission.EDIT);
	const [inverseReadingPermission, setInverseReadingPermission] = useState<Permission>(Permission.EDIT);
	const [controlChannelsPermission, setControlChannelsPermission] = useState<Permission>(Permission.EDIT);
	const [calculationsPermission, setCalculationsPermission] = useState<Permission>(Permission.EDIT);
	const [auxiliarlyDevicesPermission, setAuxiliarlyDevicesPermission] = useState<Permission>(Permission.EDIT);
	const [variablesPermission, setVariablesPermission] = useState<Permission>(Permission.EDIT);
	const [stationRulesPermission, setStationRulesPermission] = useState<Permission>(Permission.EDIT);
	const [testRulesPermission, setTestRulesPermission] = useState<Permission>(Permission.EDIT);
	const [widgetsPermission, setWidgetsPermission] = useState<Permission>(Permission.EDIT);

	return (
		<PageLayout>
			<PageLayout.Header title={t('Permissions')} />

			{/* <div className={styles.container}>
				<RadioButton label="Permission 1" />
			</div> */}
			<div className={styles.container}>
				<div className={styles.tablePermissions}>
					<table>
						<thead>
							<tr>
								<td>Actions</td>
								<td>Read-only</td>
								<td>Edit</td>
								<td>Hide</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colSpan={4} className={styles.permissionTitle}>
									General
								</td>
							</tr>
							<tr>
								<td>Unit system</td>
								<td>
									<RadioButton
										onChange={() => setUnitSystemPermission(Permission.READ_ONLY)}
										checked={unitSystemPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setUnitSystemPermission(Permission.EDIT)}
										checked={unitSystemPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setUnitSystemPermission(Permission.HIDE)}
										checked={unitSystemPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td>Time unit</td>
								<td>
									<RadioButton
										onChange={() => setTimeUnitPermission(Permission.READ_ONLY)}
										checked={timeUnitPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setTimeUnitPermission(Permission.EDIT)}
										checked={timeUnitPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setTimeUnitPermission(Permission.HIDE)}
										checked={timeUnitPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan={4} className={styles.permissionTitle}>
									Channels
								</td>
							</tr>
							<tr>
								<td>Channels in use</td>
								<td>
									<RadioButton
										onChange={() => setStationChannelsInUsePermission(Permission.READ_ONLY)}
										checked={stationChannelsInUsePermission === Permission.READ_ONLY}
									/>
								</td>
								<td></td>
								<td>
									<RadioButton
										onChange={() => setStationChannelsInUsePermission(Permission.HIDE)}
										checked={stationChannelsInUsePermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td>Channels</td>
								<td>
									<RadioButton
										onChange={() => setStationChannelsPermission(Permission.READ_ONLY)}
										checked={stationChannelsPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setStationChannelsPermission(Permission.EDIT)}
										checked={stationChannelsPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setStationChannelsPermission(Permission.HIDE)}
										checked={stationChannelsPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan={4} className={styles.permissionTitle}>
									Sensors
								</td>
							</tr>
							<tr>
								<td>Sensors</td>
								<td>
									<RadioButton
										onChange={() => setSensorsPermission(Permission.READ_ONLY)}
										checked={sensorsPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setSensorsPermission(Permission.EDIT)}
										checked={sensorsPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setSensorsPermission(Permission.HIDE)}
										checked={sensorsPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td>Sensor calibration</td>
								<td>
									<RadioButton
										onChange={() => setSensorCalibrationPermission(Permission.READ_ONLY)}
										checked={sensorCalibrationPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setSensorCalibrationPermission(Permission.EDIT)}
										checked={sensorCalibrationPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setSensorCalibrationPermission(Permission.HIDE)}
										checked={sensorCalibrationPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td>Differential Encoder: Inverse polarity</td>
								<td>
									<RadioButton
										onChange={() => setDiffEncoderPermission(Permission.READ_ONLY)}
										checked={diffEncoderPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setDiffEncoderPermission(Permission.EDIT)}
										checked={diffEncoderPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setDiffEncoderPermission(Permission.HIDE)}
										checked={diffEncoderPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td>Single-ended Encoder: Inverse polarity</td>
								<td>
									<RadioButton
										onChange={() => setSingleEncoderPermission(Permission.READ_ONLY)}
										checked={singleEncoderPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setSingleEncoderPermission(Permission.EDIT)}
										checked={singleEncoderPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setSingleEncoderPermission(Permission.HIDE)}
										checked={singleEncoderPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td>Wheatstone bridge: Averaging filter</td>
								<td>
									<RadioButton
										onChange={() => setAveragingFilterPermission(Permission.READ_ONLY)}
										checked={averagingFilterPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setAveragingFilterPermission(Permission.EDIT)}
										checked={averagingFilterPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setAveragingFilterPermission(Permission.HIDE)}
										checked={averagingFilterPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td>Wheatstone bridge: Inverse polarity</td>
								<td>
									<RadioButton
										onChange={() => setInversePolarityPermission(Permission.READ_ONLY)}
										checked={inversePolarityPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setInversePolarityPermission(Permission.EDIT)}
										checked={inversePolarityPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setInversePolarityPermission(Permission.HIDE)}
										checked={inversePolarityPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan={4} className={styles.permissionTitle}>
									Measurements
								</td>
							</tr>
							<tr>
								<td>Inverse reading</td>
								<td>
									<RadioButton
										onChange={() => setInverseReadingPermission(Permission.READ_ONLY)}
										checked={inverseReadingPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setInverseReadingPermission(Permission.EDIT)}
										checked={inverseReadingPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setInverseReadingPermission(Permission.HIDE)}
										checked={inverseReadingPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td>Controls</td>
								<td>
									<RadioButton
										onChange={() => setControlChannelsPermission(Permission.READ_ONLY)}
										checked={controlChannelsPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setControlChannelsPermission(Permission.EDIT)}
										checked={controlChannelsPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setControlChannelsPermission(Permission.HIDE)}
										checked={controlChannelsPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan={4} className={styles.permissionTitle}>
									Actuators
								</td>
							</tr>
							<tr>
								<td colSpan={4} className={styles.permissionMessage}>
									You are not allowed to change the permissions of this component. Please contact the software provider.
								</td>
							</tr>
							<tr>
								<td colSpan={4} className={styles.permissionTitle}>
									Axis
								</td>
							</tr>
							<tr>
								<td colSpan={4} className={styles.permissionMessage}>
									You are not allowed to change the permissions of this component. Please contact the software provider.
								</td>
							</tr>
							<tr>
								<td colSpan={4} className={styles.permissionTitle}>
									Calculations
								</td>
							</tr>
							<tr>
								<td>Calculations</td>
								<td>
									<RadioButton
										onChange={() => setCalculationsPermission(Permission.READ_ONLY)}
										checked={calculationsPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setCalculationsPermission(Permission.EDIT)}
										checked={calculationsPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setCalculationsPermission(Permission.HIDE)}
										checked={calculationsPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan={4} className={styles.permissionTitle}>
									Auxiliarly Devices
								</td>
							</tr>
							<tr>
								<td>Auxiliarly Devices</td>
								<td>
									<RadioButton
										onChange={() => setAuxiliarlyDevicesPermission(Permission.READ_ONLY)}
										checked={auxiliarlyDevicesPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setAuxiliarlyDevicesPermission(Permission.EDIT)}
										checked={auxiliarlyDevicesPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setAuxiliarlyDevicesPermission(Permission.HIDE)}
										checked={auxiliarlyDevicesPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan={4} className={styles.permissionTitle}>
									Variables
								</td>
							</tr>
							<tr>
								<td>Variables</td>
								<td>
									<RadioButton
										onChange={() => setVariablesPermission(Permission.READ_ONLY)}
										checked={variablesPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setVariablesPermission(Permission.EDIT)}
										checked={variablesPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setVariablesPermission(Permission.HIDE)}
										checked={variablesPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan={4} className={styles.permissionTitle}>
									Station Rules
								</td>
							</tr>
							<tr>
								<td>Station Rules</td>
								<td>
									<RadioButton
										onChange={() => setStationRulesPermission(Permission.READ_ONLY)}
										checked={stationRulesPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setStationRulesPermission(Permission.EDIT)}
										checked={stationRulesPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setStationRulesPermission(Permission.HIDE)}
										checked={stationRulesPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan={4} className={styles.permissionTitle}>
									Test Rules
								</td>
							</tr>
							<tr>
								<td>Test Rules</td>
								<td>
									<RadioButton
										onChange={() => setTestRulesPermission(Permission.READ_ONLY)}
										checked={testRulesPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setTestRulesPermission(Permission.EDIT)}
										checked={testRulesPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setTestRulesPermission(Permission.HIDE)}
										checked={testRulesPermission === Permission.HIDE}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan={4} className={styles.permissionTitle}>
									Widgets
								</td>
							</tr>
							<tr>
								<td>Widgets</td>
								<td>
									<RadioButton
										onChange={() => setWidgetsPermission(Permission.READ_ONLY)}
										checked={widgetsPermission === Permission.READ_ONLY}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setWidgetsPermission(Permission.EDIT)}
										checked={widgetsPermission === Permission.EDIT}
									/>
								</td>
								<td>
									<RadioButton
										onChange={() => setWidgetsPermission(Permission.HIDE)}
										checked={widgetsPermission === Permission.HIDE}
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</PageLayout>
	);
};

export default Permissions;
