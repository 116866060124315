import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import findKey from 'lodash/findKey';
import * as api from '../specimenTypes.api';
import {
	DimensionFormType,
	SpecimenFormType,
	ISpecimenTypeRequest,
	ISpecimenTypeResponse
} from '../specimenTypes.types';
import { specimenTypeSchema, dimensionSchema } from '../specimenTypes.schemas';
import SpecimenType from '../components/SpecimenType';
import { useOnce } from '../../../hooks';
import { deleteEmptyFields } from '../../../tools';
import { formRequestConverter, responseFormConverter } from '../specimenTypes.converters';
import { INamedEntity } from '../../../types';
import { useQuantities } from '../../Units';

const defaultValues: SpecimenFormType = {
	name: '',
	gaugeLengthEquation: '',
	gaugeLengthEquationUnitId: '',
	areaEquation: '',
	areaEquationUnitId: ''
};

const SpecimenTypeContainer: React.FC = () => {
	const navigate = useNavigate();
	const { specimenTypeId } = useParams();

	const isCreate = !specimenTypeId;

	const form = useForm<SpecimenFormType>({
		//defaultValues: { ...defaultValues },
		mode: 'onBlur',
		resolver: yupResolver(specimenTypeSchema)
	});
	const { fields, append, remove, update } = useFieldArray({
		name: 'dimensions',
		control: form.control
	});
	const dimensionForm = useForm<DimensionFormType>({
		//defaultValues: { ...defaultValues },
		mode: 'onChange',
		resolver: yupResolver(dimensionSchema)
	});

	const { handleSubmit, reset } = form;

	const { data: currentSpecimenType } = useQuery<ISpecimenTypeResponse>({
		queryKey: ['specimenType', { unitId: specimenTypeId }],
		queryFn: () => api.getSpecimenType(specimenTypeId as string),
		enabled: !!specimenTypeId
	});

	const unitsResp = useQuery<INamedEntity[]>({
		queryKey: ['units'],
		queryFn: () => api.getUnits()
	});

	const { quantities } = useQuantities('USER');

	const handleBack = useCallback(() => {
		navigate('/components-settings/specimen-types');
	}, [navigate]);

	const createMutation = useMutation({
		mutationFn: (unit: ISpecimenTypeRequest) => api.createSpecimenType(unit),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { specimenType: ISpecimenTypeRequest; specimenTypeId: string }) =>
			api.updateSpecimenType(data.specimenType, data.specimenTypeId),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const handleReset = useCallback(() => {
		if (currentSpecimenType) {
			reset({ ...responseFormConverter(currentSpecimenType) }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultValues }, { keepDirty: true, keepTouched: true });
		}
	}, [currentSpecimenType, reset]);

	const handleSave = handleSubmit((data) => {
		const filteredData = deleteEmptyFields<ISpecimenTypeRequest>(formRequestConverter(data));

		if (specimenTypeId) {
			updateMutation.mutate({
				specimenType: filteredData,
				specimenTypeId: specimenTypeId
			});
		} else {
			createMutation.mutate(filteredData);
		}
	});

	const initTrigger = useOnce(!!currentSpecimenType);

	// Setup data for edit
	useEffect(() => {
		if (initTrigger) {
			handleReset();
		}
	}, [initTrigger, handleReset]);

	const updateDimensionUnitName = useCallback(
		(dimension: DimensionFormType) => {
			const unit = unitsResp.data?.find((unit) => unit.id === dimension.unit);
			return { ...dimension, unitName: unit?.name || '' };
		},
		[unitsResp.data]
	);

	const handleAddDimension = useCallback(
		(dimension: DimensionFormType) => {
			const newDimension = updateDimensionUnitName(dimension);

			append(newDimension);
		},
		[append, updateDimensionUnitName]
	);

	const [changedDimensionNames, setChangedDimensionNames] = useState<Record<string, string>>({});
	const handleEditDimension = useCallback(
		(dimension: DimensionFormType) => {
			const current = fields.find((f) => f.id === dimension.id);

			if (current) {
				const newDimension = updateDimensionUnitName(dimension);
				update(fields.indexOf(current), newDimension);
				const latestName = findKey(changedDimensionNames, (v) => v === current.name) || current.name;
				setChangedDimensionNames((prevState) => ({ ...prevState, [latestName]: dimension.name }));
			}
		},
		[changedDimensionNames, fields, update, updateDimensionUnitName]
	);

	const handleDeleteDimension = useCallback(
		(dimension: DimensionFormType) => {
			const index = fields.findIndex((field) => field.id === dimension.id);
			remove(index);
		},
		[fields, remove]
	);

	return (
		<SpecimenType
			isCreate={isCreate}
			isLoading={createMutation.isPending || updateMutation.isPending}
			form={form}
			dimensionForm={dimensionForm}
			dimensions={fields}
			changedDimensionNames={changedDimensionNames}
			quantities={quantities || []}
			onAddDimension={handleAddDimension}
			onEditDimension={handleEditDimension}
			onDeleteDimension={handleDeleteDimension}
			onBack={handleBack}
			onSave={handleSave}
		/>
	);
};

export default React.memo(SpecimenTypeContainer);
