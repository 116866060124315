import { FC, useCallback, useMemo, useState } from 'react';
import { Modal, useModalData } from '@tactun/ui';
import TestSequencerRulesTableModal from '../components/TestSequencerRulesTable';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { TestSequencerRulesActionTypes } from '../testSequencerRules.enums';
import { createEditTestSequencerRulesModalId, testSequencerRulesModalId } from '../testSequencerRules.const';
import { useAllTestRulesOfTest, useTestSequencerRules } from '../testSequencerRules.hooks';
import TestSequencerRulesCreateEditContainer from './TestSequencerRulesCreateEditContainer';
import { RuleListItem, RuleStatus, TestRuleResponseDto, ruleTableConverter } from '../../Rules';
import { useTest } from '../../Tests/tests.hooks';
import { updateRuleStatus } from '../../StationRules/stationRules.api';
import { StageTypes } from '../../Tests';

interface ITestSequencerRulesContainerProps {
	testId?: string;
}

const TestSequencerRulesContainer: FC<ITestSequencerRulesContainerProps> = ({ testId }) => {
	const { testDto } = useTest(testId);
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const modalData = useModalData(testSequencerRulesModalId);
	const { testRules } = useAllTestRulesOfTest(testId, testDto?.stationId, modalData?.stageType);
	const existingRules = useMemo(
		() => (testRules ? testRules?.map((dto) => ruleTableConverter(dto)) : ([] as RuleListItem[])),
		[testRules]
	);
	// handel TestDataLoggingCreateEditModal close
	const handleClose = useCallback(() => {
		Modal.hide(createEditTestSequencerRulesModalId);
	}, []);

	const { sequencerRules, isLoading, deleteRule, updateItem } = useTestSequencerRules(
		testId,
		modalData?.stageId,
		modalData?.stageType,
		handleClose
	);

	const currentStageRules = useMemo(() => {
		return sequencerRules?.map((rule) => ruleTableConverter(rule));
	}, [sequencerRules]);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			deleteRule(id);
		},
		[deleteRule]
	);

	const handleAction = useCallback(
		(type: TestSequencerRulesActionTypes, data?: RuleListItem) => {
			switch (type) {
				case TestSequencerRulesActionTypes.CREATE:
					Modal.show(createEditTestSequencerRulesModalId, {
						stageId: modalData?.stageId,
						stageType: modalData?.stageType
					});
					break;
				case TestSequencerRulesActionTypes.EDIT:
					Modal.show(createEditTestSequencerRulesModalId, {
						id: data?.id,
						stageId: modalData?.stageId,
						stageType: modalData?.stageType
					});
					break;
				case TestSequencerRulesActionTypes.CREATE_FROM:
					Modal.show(createEditTestSequencerRulesModalId, {
						id: data?.id,
						stageId: modalData?.stageId,
						stageType: modalData?.stageType,
						isCopy: true
					});
					break;
				case TestSequencerRulesActionTypes.UPDATE_STATUS:
					if (data?.id !== undefined) {
						const newStatus = data.status === RuleStatus.ENABLED ? RuleStatus.DISABLED : RuleStatus.ENABLED;

						updateRuleStatus(data.id, newStatus).then((rule) => {
							updateItem(rule as TestRuleResponseDto);
						});
					}
					break;
				case TestSequencerRulesActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case TestSequencerRulesActionTypes.DELETE:
					setEntityForDelete(data);
					break;
			}
		},
		[modalData?.stageId, modalData?.stageType, updateItem]
	);

	return (
		<>
			<TestSequencerRulesTableModal
				onAction={handleAction}
				isGlobal={modalData?.stageType === StageTypes.GLOBAL}
				currentStageRules={currentStageRules}
				existingRules={existingRules}
				isLoadingCurrentStageRules={isLoading}
				isLoadingExistingRules={false}
			/>
			<TestSequencerRulesCreateEditContainer
				testId={testId}
				onSaveSuccess={handleClose}
				onClose={handleClose}
				existingRules={existingRules}
			/>
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.TEST_RULE}
				entity={entityForDelete}
			/>
		</>
	);
};

export default TestSequencerRulesContainer;
