import { Adaptive, AlgoOpenLoop, AlgoPID, AsymmetricPid, AxisInfo, Dither, FeedForwardDto } from '@tactun/grpc-client';
import {
	ControlAlgorithmsAdditional,
	ControlAlgorithmsBase,
	ControlAlgorithmTypeExternal
} from './controlAlgorithms.enums';
import { ControlAlgorithmFormType, IControlAlgorithmExternal } from './controlAlgorithms.types';
import { tInfo } from '../../tools/logger';

export const responseControlAlgorithmsFormConverter = (input: IControlAlgorithmExternal): ControlAlgorithmFormType => {
	const controlAlgorithm = {
		baseAlgorithm:
			input.type === ControlAlgorithmTypeExternal.OPEN_LOOP
				? ControlAlgorithmsBase.OPEN_LOOP
				: input.asymmetric?.enabled
				? ControlAlgorithmsBase.ASYMMETRIC_PID
				: ControlAlgorithmsBase.PID,
		isDitherEnabled: input.dither?.enabled || false,
		additionalAlgorithms: []
	} as ControlAlgorithmFormType;

	if (input.type === ControlAlgorithmTypeExternal.PID) {
		if (input.asymmetric) {
			controlAlgorithm.asymmetricPid = {
				pGainForward: input.asymmetric.positiveGainBias as number,
				pGainReverse: input.asymmetric.negativeGainBias as number,
				integralGain: input.integralGain as number,
				derivativeGain: input.derivativeGain as number
			};
		} else {
			controlAlgorithm.pid = {
				kp: input.proportionalGain as number,
				ki: input.integralGain as number,
				kd: input.derivativeGain as number
			};
		}

		if (input.adaptive) {
			const adaptive = input.adaptive;
			if (controlAlgorithm.additionalAlgorithms !== undefined) {
				controlAlgorithm.additionalAlgorithms.push(
					adaptive?.meanEnable
						? ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN
						: ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE
				);
			}

			controlAlgorithm.adaptiveAmplitude = {
				fbId: adaptive.measUuid,
				adjustmentPeriod: adaptive.amplitudePeriod as number,
				step: adaptive.amplitudeStep as number,
				targetValue: adaptive.amplitudeTargetVal as number,
				allowedError: adaptive.amplitudeErrorThreshold as number,
				meanStep: adaptive.meanStep === null ? undefined : adaptive.meanStep,
				meanTargetVal: adaptive.meanTargetVal === null ? undefined : adaptive.meanTargetVal,
				meanAllowedError: adaptive.meanErrorThreshold === null ? undefined : adaptive.meanErrorThreshold
			};
		}
	}

	if (input.dither) {
		controlAlgorithm.dither = {
			amplitude: input.dither.amplitude,
			amplitudeRaw: input.dither.amplitudeRaw,
			frequency: input.dither.frequencyVal
		};
	}

	if (input.feedForward) {
		controlAlgorithm.additionalAlgorithms.push(ControlAlgorithmsAdditional.FEED_FORWARD);

		controlAlgorithm.feedForward = {
			velocityFeedForward: input.feedForward.velocityGain,
			accelerationFeedForward: input.feedForward.accelerationGain,
			jerkFeedForward: input.feedForward.jerkGain
		};
	}

	return controlAlgorithm;
};

export const formControlAlgorithmsExternalConverter = (form: ControlAlgorithmFormType): IControlAlgorithmExternal => {
	const controlAlgorithm = {
		type:
			form.baseAlgorithm === ControlAlgorithmsBase.OPEN_LOOP
				? ControlAlgorithmTypeExternal.OPEN_LOOP
				: ControlAlgorithmTypeExternal.PID
	} as IControlAlgorithmExternal;

	if (form.dither?.amplitude && form.dither?.frequency) {
		controlAlgorithm.dither = {
			amplitude: form.dither.amplitude,
			amplitudeRaw: form.dither.amplitudeRaw,
			frequencyVal: form.dither.frequency,
			enabled: true
		};
	}

	if (form.additionalAlgorithms.includes(ControlAlgorithmsAdditional.FEED_FORWARD)) {
		controlAlgorithm.feedForward = {
			accelerationGain: form.feedForward?.accelerationFeedForward || 0,
			jerkGain: form.feedForward?.jerkFeedForward || 0,
			velocityGain: form.feedForward?.velocityFeedForward || 0,
			enabled: true
		};
	}
	if (form.pid) {
		controlAlgorithm.proportionalGain = form.pid.kp;
		controlAlgorithm.integralGain = form.pid.ki;
		controlAlgorithm.derivativeGain = form.pid.kd;
	}

	if (form.asymmetricPid && form.baseAlgorithm === ControlAlgorithmsBase.ASYMMETRIC_PID) {
		controlAlgorithm.asymmetric = {
			positiveGainBias: form.asymmetricPid.pGainForward,
			negativeGainBias: form.asymmetricPid.pGainReverse,
			enabled: true
		};
		controlAlgorithm.proportionalGain = 1;
		controlAlgorithm.integralGain = form.asymmetricPid.integralGain;
		controlAlgorithm.derivativeGain = form.asymmetricPid.derivativeGain;
	}

	if (
		form.adaptiveAmplitude &&
		form.additionalAlgorithms?.some(
			(alg) =>
				alg === ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN ||
				alg === ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE
		)
	) {
		const isWithMean =
			form.additionalAlgorithms?.some((alg) => alg === ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN) || false;

		controlAlgorithm.adaptive = {
			amplitudeEnable: true,
			amplitudePeriod: form.adaptiveAmplitude.adjustmentPeriod,
			amplitudeStep: form.adaptiveAmplitude.step,
			amplitudeTargetVal: form.adaptiveAmplitude.targetValue,
			amplitudeErrorThreshold: form.adaptiveAmplitude.allowedError,

			meanEnable: isWithMean,
			meanStep: isWithMean ? form.adaptiveAmplitude.meanStep : undefined,
			meanTargetVal: isWithMean ? form.adaptiveAmplitude.meanTargetVal : undefined,
			meanErrorThreshold: isWithMean ? form.adaptiveAmplitude.meanAllowedError : undefined,

			measUuid: form.adaptiveAmplitude.fbId
		};
	}

	return controlAlgorithm;
};

/* export const controlAlgorithmExternalToPidAlgForm = (
	input: IControlAlgorithmExternal
): PidAlgFormType | AsymmetricPIDAlgFormType => {}; */

export const controlAlgorithmsFormTypeToGrpcAlgoPid = (form: ControlAlgorithmFormType): AlgoPID | null => {
	if (form.baseAlgorithm !== ControlAlgorithmsBase.OPEN_LOOP) {
		const pid = new AlgoPID();
		pid.setPGain(form.pid?.kp || 1);
		pid.setIGain(form.pid?.ki || 0);
		pid.setDGain(form.pid?.kd || 0);

		if (form.baseAlgorithm === ControlAlgorithmsBase.ASYMMETRIC_PID && form.asymmetricPid !== undefined) {
			const asymmetric = new AsymmetricPid();
			asymmetric.setEnable(true);
			pid.setPGain(1);
			pid.setIGain(form.asymmetricPid?.integralGain || 0);
			pid.setDGain(form.asymmetricPid?.derivativeGain || 0);
			asymmetric.setNegativeGainBias(form.asymmetricPid?.pGainReverse || 0);
			asymmetric.setPositiveGainBias(form.asymmetricPid?.pGainForward || 0);

			pid.setAsymmetricpid(asymmetric);
		}

		if (form.feedForward !== undefined) {
			const feedForward = controlAlgorithmsFormTypeToGrpcFeedForward(form);
			if (feedForward) {
				pid.setFeedforward(feedForward);
			}
		}

		if (
			form.additionalAlgorithms?.some(
				(alg) =>
					alg === ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE ||
					alg === ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN
			) &&
			form.adaptiveAmplitude
		) {
			const isWithMean =
				form.additionalAlgorithms?.some((alg) => alg === ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN) || false;

			const adaptive = new Adaptive();
			adaptive.setAmpPeriod(form.adaptiveAmplitude.adjustmentPeriod);
			adaptive.setAmpTargetVal(form.adaptiveAmplitude.targetValue);
			adaptive.setAmpErrorThreshold(form.adaptiveAmplitude.allowedError);
			adaptive.setAmpStep(form.adaptiveAmplitude.step);
			adaptive.setAmpEn(true);
			adaptive.setMeasId(form.adaptiveAmplitude.fbId);

			if (isWithMean) {
				if (form.adaptiveAmplitude.meanTargetVal !== undefined)
					adaptive.setMeanTargetVal(form.adaptiveAmplitude.meanTargetVal);
				if (form.adaptiveAmplitude.meanStep !== undefined) adaptive.setMeanStep(form.adaptiveAmplitude.meanStep);
				if (form.adaptiveAmplitude.meanAllowedError !== undefined)
					adaptive.setMeanErrorThreshold(form.adaptiveAmplitude.meanAllowedError);
				adaptive.setMeanEn(true);
			}

			pid.setAdaptive(adaptive);
		}

		return pid;
	} else {
		return null;
	}
};

export const controlAlgorithmsFormTypeToGrpcAlgoOpenLoop = (form: ControlAlgorithmFormType): AlgoOpenLoop | null => {
	if (form.baseAlgorithm === ControlAlgorithmsBase.OPEN_LOOP) {
		const openLoop = new AlgoOpenLoop();

		return openLoop;
	}
	return null;
};

export const controlAlgorithmsFormTypeToGrpcDither = (form: ControlAlgorithmFormType): Dither | null => {
	if (form.dither !== undefined && form.dither !== null && form.isDitherEnabled) {
		const dither = new Dither();
		if (form.dither.amplitudeRaw) {
			dither.setAmplitude(form.dither.amplitudeRaw);
		} else {
			tInfo('######Dither amplitudeRaw is undefined########');
		}
		dither.setFrequency(form.dither.frequency);
		dither.setEnable(form.isDitherEnabled);

		return dither;
	}
	return null;
};

export const controlAlgorithmsFormTypeToGrpcFeedForward = (form: ControlAlgorithmFormType): FeedForwardDto | null => {
	if (
		form.feedForward !== undefined &&
		form.feedForward !== null &&
		form.additionalAlgorithms.includes(ControlAlgorithmsAdditional.FEED_FORWARD)
	) {
		const feedForward = new FeedForwardDto();

		feedForward.setVelGain(form.feedForward.velocityFeedForward);
		feedForward.setAccGain(form.feedForward.accelerationFeedForward);
		feedForward.setJerkGain(form.feedForward.jerkFeedForward);
		feedForward.setEnable(true);

		return feedForward;
	}
	return null;
};

export const controlAlgorithmsFormTypeToAxisInfo = (
	form: ControlAlgorithmFormType,
	destinationObj: AxisInfo
): AxisInfo => {
	const feedForward = controlAlgorithmsFormTypeToGrpcFeedForward(form);

	if (form.baseAlgorithm === ControlAlgorithmsBase.OPEN_LOOP) {
		destinationObj.setAlgorithm(ControlAlgorithmTypeExternal.OPEN_LOOP);
		const openLoop = controlAlgorithmsFormTypeToGrpcAlgoOpenLoop(form);
		if (openLoop) {
			destinationObj.setOl(openLoop);
		}
	} else {
		destinationObj.setAlgorithm(ControlAlgorithmTypeExternal.PID);
		const pid = controlAlgorithmsFormTypeToGrpcAlgoPid(form);

		if (pid) {
			if (feedForward) pid.setFeedforward(feedForward);
			destinationObj.setPid(pid);
		}
	}

	const dither = controlAlgorithmsFormTypeToGrpcDither(form);
	if (dither) {
		destinationObj.setDither(dither);
	}

	return destinationObj;
};
