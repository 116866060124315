import { Dropdown, Form, InputNumber, InputText } from '@tactun/ui';
import { FC, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TestReportSpecimenFormType, TestReportSpecimenEditFormType } from '../../testReportConfiguration.types';
import { PrecisionType, ReportResultTypeForm } from '../../testReportConfiguration.enums';
import { useNumberEnumList } from '../../../../hooks';
import styles from './SpecimenReportConfigurationTable.module.scss';

interface SpecimenEditFormProps {
	form: UseFormReturn<TestReportSpecimenEditFormType>;
	onSubmit: (index: number, data: TestReportSpecimenFormType) => void;
}

const SpecimenEditForm: FC<SpecimenEditFormProps> = ({ form, onSubmit }) => {
	const { t } = useTranslation('testConfiguration');
	const {
		register,
		control,
		watch,
		handleSubmit,
		formState: { errors }
	} = form;

	const precisionType = watch('precisionType');
	const precisionTypeOptions = useNumberEnumList(PrecisionType);

	const precisionNumberLabel = useMemo(() => {
		if (precisionType === PrecisionType.SIGNIFICANT_DIGITS) {
			return t('Significant digits');
		} else {
			return t('Decimal places');
		}
	}, [t, precisionType]);

	const onSave = handleSubmit((data) => {
		const { index, ...specimenListItem } = data;
		onSubmit(index, specimenListItem);
	});

	return (
		<Form id={ReportResultTypeForm.SPECIMEN_RESULT} onSubmit={onSave} className={styles.container}>
			<InputText label={t('Specimen parameter')} {...register('name')} disabled />
			<Controller
				name="precisionType"
				control={control}
				render={({ field }) => (
					<Dropdown
						panelClassName={styles.dropdown}
						{...field}
						label={t('Precision Type')}
						options={precisionTypeOptions}
					/>
				)}
			/>
			<Controller
				name="precision"
				control={control}
				render={({ field }) => (
					<InputNumber
						{...field}
						label={precisionNumberLabel}
						data-testid="nominalValue"
						error={errors.precision?.message}
					/>
				)}
			/>
		</Form>
	);
};

export default SpecimenEditForm;
