import React, { useContext, useEffect, useMemo } from 'react';
import { Outlet, Navigate, Route, Routes } from 'react-router-dom';

import Context, { IKeycloakContext } from '../../context';
import { hasRoles } from '../../utils/security';
import useUser from '../../hooks/useUser';
import { IUserRoles } from '../../keycloakAxios';
import Loader from '../Loader';

const useAuth = (context: IKeycloakContext | null) => {
	useEffect(() => {
		if (context && !context?.keycloak?.authenticated) {
			context.login({});
		}
	}, [context]);
};

export interface IPrivateRouteProps {
	roles?: IUserRoles;
	accessDeniedUrl?: string;
	accessDeniedComponent?: React.ReactNode;
	loader?: React.ReactNode;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({
	roles,
	accessDeniedUrl = '/',
	accessDeniedComponent,
	loader
}) => {
	const context = useContext(Context);
	useAuth(context);
	const user = useUser();
	const isAuthorized = useMemo(() => user && hasRoles(roles, user.roles), [roles, user]);
	if (!user) {
		return (
			<Routes>
				<Route path="*" element={loader ? loader : <Loader />} />
			</Routes>
		);
	}

	if (!isAuthorized) {
		return accessDeniedComponent ? (
			<Routes>
				<Route path="*" element={accessDeniedComponent} />
			</Routes>
		) : (
			<Navigate to={accessDeniedUrl} />
		);
	}

	return <Outlet />;
};

PrivateRoute.displayName = 'PrivateRoute';

export default PrivateRoute;
