import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as api from '../variables.api';
import { VariableFormType, VariableRequestDto } from '../variables.types';
import { variableFormSchema } from '../variables.schemas';
import { useVariable } from '../variables.hooks';
import { useOnce } from '../../../hooks';
import { deleteEmptyFields } from '../../../tools';
import { formRequestConverter, responseFormConverter } from '../variables.converters';
import { useStationName } from '../../Stations/stations.hooks';
import { useTranslation } from 'react-i18next';
import { BottomBar, Button, InfoBadge, InfoBadgeIcons, PageLayout } from '@tactun/ui';
import VariableForm from '../components/VariableForm';

const defaultValues = {
	name: '',
	type: undefined,
	defaultValue: ''
};

const VariableContainer: React.FC = () => {
	const navigate = useNavigate();
	const { variableId, stationId } = useParams();
	const { t } = useTranslation();

	const form = useForm<VariableFormType>({
		defaultValues: { ...defaultValues, stationId },
		mode: 'onBlur',
		resolver: yupResolver(variableFormSchema)
	});

	const { handleSubmit, reset } = form;

	const { variableDto: currentVariableDto } = useVariable(variableId);

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/variables`);
	}, [navigate, stationId]);

	const createMutation = useMutation({
		mutationFn: (variable: VariableRequestDto) => api.createVariable(variable),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { variable: VariableRequestDto; variableId: string }) => api.updateVariable(data.variable),

		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const handleReset = useCallback(() => {
		if (currentVariableDto) {
			reset({ ...responseFormConverter(currentVariableDto) }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultValues, stationId }, { keepDirty: true, keepTouched: true });
		}
	}, [currentVariableDto, reset, stationId]);

	const handleSave = handleSubmit((data) => {
		const filteredData = deleteEmptyFields<VariableRequestDto>(formRequestConverter(data));

		if (variableId) {
			updateMutation.mutate({
				variable: filteredData,
				variableId
			});
		} else {
			createMutation.mutate(filteredData);
		}
	});

	const initTrigger = useOnce(!!currentVariableDto);

	// Setup data for edit
	useEffect(() => {
		if (initTrigger) {
			handleReset();
		}
	}, [initTrigger, handleReset]);

	const { stationName } = useStationName(stationId);

	return (
		<>
			<PageLayout info={stationName}>
				<PageLayout.Header title={!variableId ? t('Create a Variable') : t('Edit a Variable')} onBack={handleBack} />
				<VariableForm form={form} />
			</PageLayout>
			<BottomBar>
				{(createMutation.isPending || updateMutation.isPending) && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button data-testid="cancelBtn" label={t('Cancel')} variant="contained" color="success" onClick={handleBack} />
				<Button data-testid="saveBtn" label={t('Save')} variant="contained" color="secondary" onClick={handleSave} />
			</BottomBar>
		</>
	);
};

export default React.memo(VariableContainer);
