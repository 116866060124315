import { ControlAlgorithmsBase } from '../ControlAlgorithms';
import { getDefaultValuesByType } from '../Waveforms';

export const testAxesConfigurationModalId = 'testAxesConfigurationFormId';

export const axesConfigurationFormDefaultValues = {
	ccId: '',
	axisId: '',
	stageId: '',
	useStationParams: false,
	controlAlgorithmsForm: {
		isDitherEnabled: false,
		baseAlgorithm: ControlAlgorithmsBase.PID,
		additionalAlgorithms: []
	},
	isFinishStageTrigger: false,
	wf: getDefaultValuesByType()
};
