import { TFunction } from 'i18next';
import { Converter } from '../../types';
import { DataChannelDropdownKeyValue, DataChannelType } from '../DataChannel';
import { ReadoutTypeOptions } from '../ReadoutWidget/readout.constants';
import { ReadoutFormType } from '../ReadoutWidget/readout.types';
import { GraphTypesOptions } from './testDashboardConfiguration.constants';
import { GraphTypes } from './testDashboardConfiguration.enums';
import {
	DatachannelRequestDto,
	GraphDataChannel,
	GraphListItem,
	GraphRequestDto,
	GraphResponseDto,
	ReadoutListItem,
	ReadoutRequestDto,
	ReadoutResponseDto
} from './testDashboardConfiguration.types';
import { GraphFormType } from '../GraphWidget/graphWidget.types';
import { ScaleTypeOptions } from '../GraphWidget/graphWidget.constants';

export const readoutResponseDtoToDataChannel = (form: ReadoutResponseDto) => {
	if (form.measurement) {
		return { id: form.measurement.id, type: DataChannelType.MEASUREMENT };
	}
	if (form.spAxis) {
		return { id: form.spAxis.id, type: DataChannelType.SET_POINT };
	}
	if (form.compSpAxis) {
		return { id: form.compSpAxis.id, type: DataChannelType.COMP_SET_POINT };
	}
	if (form.cycleAxis) {
		return { id: form.cycleAxis.id, type: DataChannelType.CYCLE };
	}
};

export const readoutResponseDtoToDataChannelName = (input: ReadoutResponseDto, t: TFunction): string => {
	if (input.measurement) {
		return `${input.measurement.name} ${t(DataChannelType[DataChannelType.MEASUREMENT])}`;
	}
	if (input.spAxis) {
		return `${input.spAxis.name} ${t(DataChannelType[DataChannelType.SET_POINT])}`;
	}
	if (input.compSpAxis) {
		return `${input.compSpAxis.name} ${t(DataChannelType[DataChannelType.COMP_SET_POINT])}`;
	}
	if (input.cycleAxis) {
		return `${input.cycleAxis.name} ${t(DataChannelType[DataChannelType.CYCLE])}`;
	}

	return '';
};

export const readoutResponseFormConverter = (input: ReadoutResponseDto, t: TFunction): ReadoutFormType => {
	const dataChannel = readoutResponseDtoToDataChannel(input);
	return {
		id: input.id,
		name: readoutResponseDtoToDataChannelName(input, t),
		dataChannel: dataChannel !== undefined ? [dataChannel] : [],
		max: input.max,
		min: input.min,
		precision: input.precision,
		rate: input.rate,
		readoutType: input.readoutType,
		tare: input.tare,
		stationId: input.stationId,
		testId: input.testId
	};
};

export const readoutFormRequestConverter = (input: ReadoutFormType): ReadoutRequestDto => {
	const dataChannelDetection = {
		cycleAxisId: '',
		measurementId: '',
		spAxisId: '',
		compSpAxisId: ''
	};

	if (input.dataChannel) {
		switch (input.dataChannel[0].type) {
			case DataChannelType.MEASUREMENT:
				dataChannelDetection.measurementId = input.dataChannel[0].id;
				break;
			case DataChannelType.CYCLE:
				dataChannelDetection.cycleAxisId = input.dataChannel[0].id;
				break;
			case DataChannelType.SET_POINT:
				dataChannelDetection.spAxisId = input.dataChannel[0].id;
				break;
			case DataChannelType.COMP_SET_POINT:
				dataChannelDetection.compSpAxisId = input.dataChannel[0].id;
				break;
		}
	}

	return {
		id: input.id || undefined,
		name: input.name || '',
		testId: input.testId || undefined,
		readoutType: input.readoutType,
		max: input.max || false,
		min: input.min || false,
		rate: input.rate || false,
		tare: input.tare || false,
		precision: input.precision || 0,
		...dataChannelDetection
	};
};

export const readoutListItemConverter = (input: ReadoutResponseDto, t: TFunction): ReadoutListItem => {
	return {
		id: input.id,
		readoutType: ReadoutTypeOptions[input.readoutType]?.label || '',
		dataChannel: readoutResponseDtoToDataChannelName(input, t)
	};
};

export const dataChannelResponseConverter: Converter<GraphDataChannel, DataChannelDropdownKeyValue[]> = (input) => {
	const result: DataChannelDropdownKeyValue[] = [];

	if (input.time) {
		result.push({ id: `${DataChannelType.TIME}`, type: DataChannelType.TIME });
	}
	input.measurements?.forEach((measurement) => {
		result.push({ id: measurement.id, type: DataChannelType.MEASUREMENT });
	});
	input.cycleAxes?.forEach((cycle) => {
		result.push({ id: cycle.id, type: DataChannelType.CYCLE });
	});
	input.spAxes?.forEach((setPoint) => {
		result.push({ id: setPoint.id, type: DataChannelType.SET_POINT });
	});
	input.compSpAxes?.forEach((compSpAxes) => {
		result.push({ id: compSpAxes.id, type: DataChannelType.COMP_SET_POINT });
	});

	return result;
};

export const graphResponseFormConverter: Converter<GraphResponseDto, GraphFormType> = (input) => {
	return {
		id: input.id,
		name: input.name,
		autoScaleX: input.autoscaleX,
		autoScaleY: input.autoscaleY,
		cmax: input.cmax,
		cmin: input.cmin,
		min: input.min,
		max: input.max,
		dcX: dataChannelResponseConverter(input.dcX),
		dcY1: dataChannelResponseConverter(input.dcY1),
		dcY2: dataChannelResponseConverter(input.dcY2),
		graphType: GraphTypes.XY,
		limitLines: input.limitLines,
		rate: input.rate,
		testId: input.testId,
		timeUnitId: input.timeUnit.id,
		timeWndSize: input.timeWndSize,
		xOffset: input.xoffset,
		offset: input.xoffsetValue,
		scaleType: ScaleTypeOptions[input.xscaleType].value,
		simplifiedMeasurements: {}
	};
};

export const dataChannelRequestConverter = (
	input: DataChannelDropdownKeyValue[] | undefined = []
): DatachannelRequestDto => {
	return input.reduce(
		(res, current) => {
			if (current.id === 'undefined') return res;
			switch (current.type) {
				case DataChannelType.TIME:
					res.time = true;
					break;
				case DataChannelType.MEASUREMENT:
					if (Array.isArray(res.measurementIds)) res.measurementIds.push(current.id);
					break;
				case DataChannelType.CYCLE:
					if (Array.isArray(res.cycleAxisIds)) res.cycleAxisIds.push(current.id);
					break;
				case DataChannelType.SET_POINT:
					if (Array.isArray(res.spAxisIds)) res.spAxisIds.push(current.id);
					break;
				case DataChannelType.COMP_SET_POINT:
					if (Array.isArray(res.compSpAxisIds)) res.compSpAxisIds.push(current.id);
					break;
			}
			return res;
		},
		{
			time: false,
			measurementIds: [],
			cycleAxisIds: [],
			spAxisIds: [],
			compSpAxisIds: []
		} as DatachannelRequestDto
	);
};

export const graphFormRequestConverter: Converter<GraphFormType, GraphRequestDto> = (input) => {
	return {
		id: input.id,
		name: input.name,
		testId: input.testId ?? '',
		graphType: input.graphType ?? GraphTypes.XY,
		autoscaleX: input.autoScaleX,
		autoscaleY: input.autoScaleY,
		cmin: input.cmin ?? null,
		cmax: input.cmax ?? null,
		min: input.min ?? null,
		max: input.max ?? null,
		time: true,
		dcX: dataChannelRequestConverter(input.dcX),
		dcY1: dataChannelRequestConverter(input.dcY1),
		dcY2: dataChannelRequestConverter(input.dcY2),
		limitLines: input.limitLines ?? false,
		rate: input.rate,
		timeUnitId: input.timeUnitId,
		timeWndSize: input.timeWndSize,
		xoffset: input.xOffset,
		xoffsetValue: input.offset,
		xscaleType: ScaleTypeOptions.findIndex(({ value }) => value === input.scaleType)
	};
};

export const graphListItemConverter: Converter<GraphResponseDto, GraphListItem> = (input) => {
	return {
		id: input.id,
		name: input.name,
		graphType: GraphTypesOptions[input.graphType]?.label || '',
		dateRate: input.rate
	};
};
