import { array, boolean, lazy, mixed, number, object, string } from 'yup';
import { controlAlgBaseSchema } from '../ControlAlgorithms/controlAlgorithms.schemas';
import { waveformSchema } from '../Waveforms';

import {
	ActionAxisProperties,
	ActionCalculationProperties,
	ActionControlChannelProperties,
	ActionDigitalOutputProperties,
	ActionMeasurementProperties,
	ActionObjectTypes,
	ActionStageProperties,
	ActionStationProperties,
	ActionTestProperties
} from './actions.enums';
import { RuleAction } from './actions.types';

export const digitalOutputSchemaBuilder = (action: RuleAction) => {
	const baseSchema = object({
		objectId: array()
			.of(string().required('Channel is a required field'))
			.min(1, 'Channel is a required field')
			.required('Channel is a required field'),
		property: mixed<ActionDigitalOutputProperties>().required('Action is a required field')
	});

	if (action.property === ActionDigitalOutputProperties.PULSE_START) {
		return baseSchema.concat(
			object({
				frequency: number().required('Frequency is a required field').min(0).max(10),
				numberOfPulses: number().required('Number of Pulses is a required field').min(0).max(4294967295)
			})
		);
	} else {
		return baseSchema;
	}
};

export const controlChannelSchemaBuilder = (action: RuleAction) => {
	const baseSchema = object({
		objectId: string().required('Control is a required field'),
		property: mixed<ActionControlChannelProperties>().required('Action is a required field')
	});

	if (action.property === ActionControlChannelProperties.OPERATE) {
		return baseSchema.concat(object({ controlAlgorithmsForm: controlAlgBaseSchema.required() })).concat(
			object({
				waveform: lazy((value) => waveformSchema.resolve({ value: value?.type }).required())
			})
		);
	} else if (action.property === ActionControlChannelProperties.SET) {
		return baseSchema.concat(object({ controlAlgorithmsForm: controlAlgBaseSchema.required() }));
	} else {
		return baseSchema;
	}
};

export const specimenSchema = object({
	property: mixed<ActionTestProperties>().required()
	// TODO missing properties
});

export const calculationSchema = object({
	objectId: string().required('Calculation is a required field'),
	property: mixed<ActionCalculationProperties>().required()
});

export const testSchema = object({
	property: mixed<ActionTestProperties>().required('Action is a required field')
});

export const stageSchema = object({
	property: mixed<ActionStageProperties>().required()
});

export const messageBoxConfirmSchema = object({
	message: string().max(1000).required(),
	isParallel: boolean(),
	msgOKActions: array(),
	msgCancelActions: array()
	// TODO missing props
});

export const messageBoxSchema = object({
	message: string().max(1000).required('Message is required')
});

export const measurementSchemaBuilder = (action: RuleAction) => {
	const baseSchema = object({
		property: mixed<ActionMeasurementProperties>().required('Action is a required field'),
		objectId: string().required('Measurement is a required field')
	});
	switch (action.property) {
		case ActionMeasurementProperties.TARE: {
			return baseSchema.concat(object({ tareWeight: number().required('Tare Weight is a required field') }));
		}
		case ActionMeasurementProperties.GET_MEASUREMENT_VALUE: {
			return baseSchema.concat(object({ variableId: string().required('Variable is a required field') }));
		}
		default:
			return baseSchema;
	}
};

export const axisSchema = object({
	property: mixed<ActionAxisProperties>().required('Action is a required field'),
	objectId: string().required('Axis is a required field'),
	enableBitChannels: array()
		.notRequired()
		.when(['property'], {
			is: (prop: ActionAxisProperties) => prop === ActionAxisProperties.ENABLE || prop === ActionAxisProperties.DISABLE,
			then: (schema) => schema.of(string()).min(1, 'Object is a required field').required('Object is a required field')
		})
});

export const stationSchema = object({
	property: mixed<ActionStationProperties>().required('Set State is a required field')
});

export const actionSchema = lazy((action: RuleAction) => {
	const baseSchema = object<RuleAction>({
		objectType: number().required('Object type is a required field')
	});

	switch (action.objectType) {
		case ActionObjectTypes.DIGITAL_OUTPUT:
			return baseSchema.concat(digitalOutputSchemaBuilder(action));

		case ActionObjectTypes.CONTROL_CHANNEL:
			return baseSchema.concat(controlChannelSchemaBuilder(action));

		case ActionObjectTypes.SPECIMEN:
			return baseSchema.concat(specimenSchema);

		case ActionObjectTypes.CALCULATION:
			return baseSchema.concat(calculationSchema);

		case ActionObjectTypes.TEST:
			return baseSchema.concat(testSchema);

		case ActionObjectTypes.STAGE:
			return baseSchema.concat(stageSchema);

		case ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION:
			return baseSchema.concat(messageBoxConfirmSchema);

		case ActionObjectTypes.MESSAGE_BOX:
			return baseSchema.concat(messageBoxSchema);
		case ActionObjectTypes.REQUEST_VARIABLE_ENTRY:
		case ActionObjectTypes.REPORT:
		case ActionObjectTypes.REQUEST_NOTE_ENTRY:
			return baseSchema;

		case ActionObjectTypes.MEASUREMENT:
			return baseSchema.concat(measurementSchemaBuilder(action));
		case ActionObjectTypes.CAMERA:
			return baseSchema;
		case ActionObjectTypes.AXIS:
			return baseSchema.concat(axisSchema);
		case ActionObjectTypes.STATION:
			return baseSchema.concat(stationSchema);
		default:
			return baseSchema;
	}
});
