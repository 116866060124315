import { object, string, array, boolean, number, mixed, ref } from 'yup';
import { emptyStringToNull } from '../../tools/yupHelpers';
import { DataChannelDropdownKeyValue } from '../DataChannel';
import { dataChannelDropdownKeyValueScheme } from '../DataChannel/dataChannel.schemas';
import { SimplifiedMeasurement } from '../Measurements';
import { GraphTypes } from '../TestDashboardConfiguration/testDashboardConfiguration.enums';
import { dataPointsMaxCount, DateRate } from './graphWidget.constants';
import { ScaleTypes } from './graphWidget.enums';
import { Plot } from './graphWidget.types';
import { validateGraphChannelInput } from './graphWidget.tools';

const dcY1Schema = array()
	.of(dataChannelDropdownKeyValueScheme)
	.test('y1ChannelRequired', 'Please select a data channel', (value, context) => {
		if (context?.parent?.dcY2?.length > 0) return true;
		return !!(value?.length && value?.length > 0);
	})
	.test('y1Unique', 'Data channel must be unique', (value, form) => {
		return !value?.some((channel1) =>
			form.parent.dcY2.some(
				(channel2: DataChannelDropdownKeyValue) => channel1.id === channel2.id && channel1.type === channel2.type
			)
		);
	})
	.test('y1Validation', function (value, form) {
		const channels = value?.filter(({ id }) => id !== 'undefined') as DataChannelDropdownKeyValue[];

		const { errorMessage, isValid } = validateGraphChannelInput(channels, form.parent.simplifiedMeasurements);

		if (!isValid) {
			return this.createError({ message: errorMessage });
		}

		return true;
	});

const dcY2Schema = array()
	.of(dataChannelDropdownKeyValueScheme)
	.test('y2ChannelRequired', 'Please select a data channel', (value, context) => {
		const { dcY1, dcY2 } = context.parent;
		if (dcY1?.length > 0 || (!dcY1?.length && !dcY2?.length)) return true;
		return !!(value?.length && value?.length > 0);
	})
	.test('y2Unique', 'Data channel must be unique', (value, form) => {
		return !value?.some((channel1) =>
			form.parent.dcY1.some(
				(channel2: DataChannelDropdownKeyValue) => channel1.id === channel2.id && channel1.type === channel2.type
			)
		);
	})
	.test('y2Validation', function (value, form) {
		const channels = value?.filter(({ id }) => id !== 'undefined') as DataChannelDropdownKeyValue[];

		const { errorMessage, isValid } = validateGraphChannelInput(channels, form.parent.simplifiedMeasurements);

		if (!isValid) {
			return this.createError({ message: errorMessage });
		}

		return true;
	});

export const plotSchema = object().shape({
	dcX: array().of(dataChannelDropdownKeyValueScheme).min(1, 'Please select a channel').required(),
	dcY1: dcY1Schema.required(),
	dcY2: dcY2Schema.required(),
	plots: array().of(object<Plot>()),
	simplifiedMeasurements: object<Record<'string', SimplifiedMeasurement>>()
});

export const graphMainSettingsSchema = object({
	autoScaleX: boolean().required(),
	autoScaleY: boolean().required(),
	scaleType: mixed<ScaleTypes>()
		.nullable()
		.when('autoScaleX', {
			is: true,
			then: (schema) => schema.required()
		}),
	xOffset: boolean().required(),
	offset: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('xOffset', {
			is: true,
			then: (schema) => schema.required()
		})
});

export const graphSettingsSchema = graphMainSettingsSchema.concat(
	object({
		id: string().notRequired(),
		name: string().required(),
		graphType: mixed<GraphTypes>().default(GraphTypes.XY).notRequired(),
		rate: number()
			.min(DateRate.min, `Minimum value of date rate is ${DateRate.min}`)
			.max(DateRate.max, `Maximum value of date rate is ${DateRate.max}`)
			.required(),
		timeUnitId: string().required('Time unit is a required field'),
		dcX: array()
			.of(dataChannelDropdownKeyValueScheme)
			.required()
			.when('isType', {
				is: true,
				then: (schema) => schema.optional(),
				otherwise: (schema) => schema.min(1, 'Please select a channel')
			}),
		dcY1: array()
			.required()
			.when('isType', {
				is: true,
				then: (schema) => schema.optional(),
				otherwise: () => dcY1Schema.required()
			}),
		dcY2: array()
			.required()
			.when('isType', {
				is: true,
				then: (schema) => schema.optional(),
				otherwise: () => dcY2Schema.required()
			}),
		limitLines: boolean(),
		min: number()
			.transform(emptyStringToNull)
			.nullable()
			.when('limitLines', {
				is: true,
				then: (schema) =>
					schema.required('Minimum is a required field').when('max', {
						is: (max?: number | null) => max !== null && max !== undefined,
						then: (schema) => schema.lessThan(ref('max'), ({ less }) => `The value must be less than ${less}`)
					})
			}),
		max: number()
			.transform(emptyStringToNull)
			.nullable()
			.when('limitLines', {
				is: true,
				then: (schema) =>
					schema.required('Maximum is a required field').when('min', {
						is: (min?: number | null) => min !== null && min !== undefined,
						then: (schema) => schema.moreThan(ref('min'), ({ more }) => `The value must be greater than ${more}`)
					})
			}),

		timeWndSize: number()
			.transform(emptyStringToNull)
			.min(1, ({ min }) => `Minimum value of time window size is ${min}`)
			.test('timeWndSizeMax', function (value, form) {
				if (!value || !form.parent.rate) return true;
				const max = dataPointsMaxCount / form.parent.rate / 10;

				if (value > max) {
					return this.createError({
						message: `Time window size is ≤ ${Math.floor(max)} given the current sampling rate`
					});
				}

				return true;
			})
			.required('Time window size is a required field'),
		testId: string().notRequired(),
		cmin: number()
			.transform(emptyStringToNull)
			.nullable()
			.when('limitLines', {
				is: true,
				then: (schema) =>
					schema.required('Critical Minimum is a required field').when('cmax', {
						is: (cmax?: number | null) => cmax !== null && cmax !== undefined,
						then: (schema) => schema.lessThan(ref('cmax'), ({ less }) => `The value must be less than ${less}`)
					})
			}),
		cmax: number()
			.transform(emptyStringToNull)
			.nullable()
			.when('limitLines', {
				is: true,
				then: (schema) =>
					schema.required('Critical Maximum is a required field').when('cmin', {
						is: (cmin?: number | null) => cmin !== null && cmin !== undefined,
						then: (schema) => schema.moreThan(ref('cmin'), ({ more }) => `The value must be greater than ${more}`)
					})
			}),
		simplifiedMeasurements: object<Record<'string', SimplifiedMeasurement>>(),
		isType: boolean().optional()
	})
);
