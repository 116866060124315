import { useMemo, useRef } from 'react';
import { PageLayout, Form, InputText, Dropdown, Button, BottomBar, InfoBadge, InfoBadgeIcons } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import styles from './Customers.module.scss';

interface AxesProps {
	isCreate: boolean;
	isLoading: boolean;
	form: UseFormReturn<Record<string, string>>;
	onBack: () => void;
	onSave: () => void;
}

const Customers: React.FC<AxesProps> = ({ isCreate, isLoading, form, onBack, onSave }) => {
	const { t } = useTranslation('axes');
	const formId = useRef('axesForm');
	const { control } = form;

	const title = useMemo<string>(() => {
		return isCreate ? t('Create Customer') : t('Edit Customer');
	}, [isCreate, t]);

	return (
		<>
			<PageLayout>
				<PageLayout.Header title={title} onBack={onBack} />
				<Form className={styles.container} onSubmit={onSave} id={formId.current}>
					<div className={styles.buttonsCont}>
						<div className="companyLogo">
							<div>Company logo</div>
							<div className={styles.customerLogo}></div>
						</div>
						<Button label={t('Change image')} variant="text" color="secondary" />
						<Button label={t('Delete')} variant="text" color="danger" />
					</div>
					<div></div>
					<Controller
						name="CompanyName"
						control={control}
						render={({ field }) => <InputText label={t('Company name*')} {...field} />}
					/>
					<Controller
						name="contactPerson"
						control={control}
						render={({ field }) => <InputText label={t('Contact person full name*')} {...field} />}
					/>
					<Controller
						name="phoneNumber"
						control={control}
						render={({ field }) => <InputText label={t('Phone number')} {...field} />}
					/>
					<Controller
						name="email"
						control={control}
						render={({ field }) => <InputText label={t('Email')} {...field} />}
					/>
					<Controller
						name="website"
						control={control}
						render={({ field }) => <InputText label={t('Website link')} {...field} />}
					/>
					<Controller
						name="country"
						control={control}
						render={({ field }) => <Dropdown {...field} label={t('Country')} filter />}
					/>
					<Controller
						name="state"
						control={control}
						render={({ field }) => <Dropdown {...field} label={t('State')} filter />}
					/>
					<Controller
						name="region"
						control={control}
						render={({ field }) => <Dropdown {...field} label={t('Region')} filter />}
					/>
					<Controller
						name="city"
						control={control}
						render={({ field }) => <Dropdown {...field} label={t('City')} filter />}
					/>
					<Controller
						name="postalIndex"
						control={control}
						render={({ field }) => <InputText label={t('Postal index')} {...field} />}
					/>
					<Controller
						name="street"
						control={control}
						render={({ field }) => <InputText label={t('Street')} {...field} />}
					/>
					<div></div>
					<Controller
						name="description"
						control={control}
						render={({ field }) => (
							<>
								<InputText {...field} textarea label={t('Description')} />
								<div className={styles.countDown}>1000</div>
							</>
						)}
					/>
				</Form>
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button data-testid="cancelBtn" label={t('Cancel')} variant="contained" color="success" onClick={onBack} />
				<Button
					data-testid="saveBtn"
					label={t('Save')}
					variant="contained"
					color="secondary"
					type="submit"
					form={formId.current}
				/>
			</BottomBar>
		</>
	);
};

export default Customers;
