import React from 'react';
import { DataTable, EDataTableThemes } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import WidgetContent from '../../../Dashboard/components/Widget/WidgetContent';
import WidgetHeader from '../../../Dashboard/components/Widget/WidgetHeader';
import { IEvent } from '../../eventViewer.types';
import styles from './EventViewerWidget.module.scss';

export interface EventViewerWidgetProps {
	events: IEvent[];
	isType?: boolean;
}

const EventViewerWidget: React.FC<EventViewerWidgetProps> = ({ events, isType }) => {
	const { t } = useTranslation(['dashboard', 'common']);

	return (
		<>
			<WidgetHeader>{t('Event viewer')}</WidgetHeader>
			<WidgetContent className={styles.widgetContainer} isType={isType}>
				<DataTable
					className={styles.tableContainer}
					value={events}
					dataKey="id"
					theme={EDataTableThemes.WIDGET}
					virtualScrollerOptions={{ itemSize: 33 }}
				>
					<DataTable.Column
						header="Date"
						body={({ timestamp }) => timestamp && t('common:dateTime', { date: new Date(timestamp) })}
					/>
					<DataTable.Column header="Rule name" field="ruleName" />
					<DataTable.Column header="Trigger object type" field={'triggerType'} />
					<DataTable.Column header="Trigger object" field="trigger" />
					<DataTable.Column header="Trigger object property" field="triggerProp" />
				</DataTable>
			</WidgetContent>
		</>
	);
};

export default EventViewerWidget;
