// package: control_stage
// file: control_stage.proto

var control_stage_pb = require("./control_stage_pb");
var error_pb = require("./error_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var WebControlStage = (function () {
  function WebControlStage() {}
  WebControlStage.serviceName = "control_stage.WebControlStage";
  return WebControlStage;
}());

WebControlStage.StartStage = {
  methodName: "StartStage",
  service: WebControlStage,
  requestStream: false,
  responseStream: false,
  requestType: control_stage_pb.ControlStageRequest,
  responseType: error_pb.Error
};

WebControlStage.UpdateStageConfig = {
  methodName: "UpdateStageConfig",
  service: WebControlStage,
  requestStream: false,
  responseStream: false,
  requestType: control_stage_pb.UpdateConfigRequest,
  responseType: error_pb.Error
};

WebControlStage.StopStage = {
  methodName: "StopStage",
  service: WebControlStage,
  requestStream: false,
  responseStream: false,
  requestType: control_stage_pb.StopControlStage,
  responseType: error_pb.Error
};

exports.WebControlStage = WebControlStage;

function WebControlStageClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

WebControlStageClient.prototype.startStage = function startStage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebControlStage.StartStage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebControlStageClient.prototype.updateStageConfig = function updateStageConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebControlStage.UpdateStageConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebControlStageClient.prototype.stopStage = function stopStage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(WebControlStage.StopStage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.WebControlStageClient = WebControlStageClient;

