import { Button } from '../../../';
import styles from './PageHeader.module.scss';

export interface IPageHeaderProps {
	title: React.ReactNode;
	onBack?: () => void;
	children?: React.ReactNode;
}

const PageHeader: React.FC<IPageHeaderProps> = ({ children, title, onBack }) => {
	return (
		<div className={styles.container}>
			<div className={styles.left}>
				{onBack && (
					<Button
						onClick={onBack}
						variant="outlined"
						color="success"
						className={styles.btnPadding}
						icon="t-icon-arrow-chevron-down"
					/>
				)}
				<p className={styles.title}>{title}</p>
			</div>
			<div className={styles.right}>{children}</div>
		</div>
	);
};

export default PageHeader;
