import {
	HardwareChannel,
	StationChannelDigitalIO24V,
	StationChannelDigitalIO5V,
	StationChannelRequest
} from '@tactun/grpc-client';
import { Converter, IListItemNumber, INamedEntity } from '../../types';
import {
	PullUpResistorState,
	ChannelType,
	State,
	Direction,
	AverageFilterPoints,
	BridgeTypes,
	ExcitationVoltage,
	Gain,
	SamplingRate,
	SpecificType,
	Range,
	PGAGain
} from './stationChannels.enums';
import {
	IStationChannelResponseDto,
	StationChannelFormType,
	StationChannelRequestDto,
	StationChannelListItem,
	ControllerChannelDto,
	ControllerChannelMeta
} from './stationChannels.types';
import { TFunction } from 'i18next';
import { rangeToUserFriendlyMap } from './stationChannels.const';

export const responseListItemConverter: Converter<IStationChannelResponseDto, StationChannelListItem> = (response) => {
	return {
		id: response.id,
		name: response.name,
		type: ChannelType[response.type],
		channelName: `Channel ${response.channel.seq}`,
		seq: response.channel.seq,
		groups: (response?.groups || []) as INamedEntity[]
	};
};

export const responseFormConverter = (response: IStationChannelResponseDto): StationChannelFormType => {
	const { channel, pullUpResistor, aiServoDriverType, ...formResponse } = response;

	return {
		...formResponse,
		samplingRate: formResponse.samplingRate,
		channelId: channel.id,
		pullUpResistor: pullUpResistor === PullUpResistorState.ON,
		specificType: aiServoDriverType,
		bouncingCancellation: response.bouncingLatency !== undefined,
		bouncingLatency: response.bouncingLatency
	};
};

export const formRequestConverter = (form: StationChannelFormType): StationChannelRequestDto => {
	const baseRequest = {
		name: form.name,
		type: form.type,
		channelId: form.channelId,
		stationId: form.stationId
	};

	switch (form.type) {
		case ChannelType.DIFFERENTIAL_ENCODER:
		case ChannelType.SINGLE_ENDED_ENCODER:
			return baseRequest;
		case ChannelType.SIGNAL_CONDITIONING:
			return {
				...baseRequest,
				bridgeType: form.bridgeType,
				excitationVoltage: form.excitationVoltage,
				gain: form.gain,
				pgaGain: form.pgaGain,
				samplingRate: form.samplingRate
			};
		case ChannelType.ANALOG_IN_10KSPS:
		case ChannelType.ANALOG_IN_10V_24MA:
			return {
				...baseRequest,
				aiServoDriverType: form.specificType,
				range: form.range as Range
			};
		case ChannelType.ANALOG_IN_60KSPS:
			return {
				...baseRequest,
				range: form.range as Range
			};
		case ChannelType.DIGITAL_IO_5V:
			return {
				...baseRequest,
				direction: form.direction,
				activeState: form.activeState,
				defaultState: form.defaultState,
				pullUpResistor: form.pullUpResistor ? PullUpResistorState.ON : PullUpResistorState.OFF,
				bouncingLatency: form.bouncingCancellation ? form.bouncingLatency : undefined
			};
		case ChannelType.DIGITAL_IO_24V:
			return {
				...baseRequest,
				direction: form.direction,
				activeState: form.activeState,
				defaultState: form.defaultState,
				bouncingLatency: form.bouncingCancellation ? form.bouncingLatency : undefined
			};
		case ChannelType.RELAY:
			return {
				...baseRequest,
				activeState: form.activeState,
				defaultState: form.defaultState
			};
		case ChannelType.DIGITAL_IN_24V: {
			return {
				...baseRequest,
				activeState: form.activeState
			};
		}
		case ChannelType.ANALOG_OUT_10V:
		case ChannelType.ANALOG_OUT_100MA:
			return {
				...baseRequest,
				range: form.range as Range,
				offset: form.offset,
				limit: form.limit
			};
		case ChannelType.ANALOG_OUT_10V_24MA:
			return {
				...baseRequest,
				range: form.range as Range,
				aiServoDriverType: form.specificType,
				offset: form.offset,
				limit: form.limit
			};
		case ChannelType.DC_PWM_30V:
			return {
				...baseRequest,
				frequency: form.frequency
			};
		case ChannelType.DC_PWM_50V:
			return {
				...baseRequest,
				frequency: form.frequency
			};
		case ChannelType.DC_PWM_65V:
			return {
				...baseRequest,
				frequency: form.frequency
			};
		case ChannelType.LVDT:
			return {
				...baseRequest,
				excitationAmplitude: form.excitationAmplitude,
				excitationFrequency: form.excitationFrequency
			};
		default:
			throw new Error('Form request converter error: unknown channel type ');
	}
};

const convertArrayToList = <T>(
	en: T,
	arr?: number[],
	translationFunction?: (v: string) => string
): IListItemNumber[] => {
	if (!arr?.length) return [];
	//@ts-ignore
	return Object.entries(en)
		.filter(([key, value]) => isNaN(Number(key)) && arr.some((v) => v === value))
		.map(([key, value]) => {
			return { label: translationFunction ? translationFunction(key) : key, value } as IListItemNumber;
		});
};

const numberToUserFriendly = (str: string): string => {
	if (!str) return '';

	str = str[0] === '_' ? str.substring(1) : str;
	str = str.replaceAll('_', '.');

	if (str.endsWith('VV')) return str.replaceAll('VV', ' (V/V)');

	if (str.endsWith('V')) return str.replaceAll('V', ' V');

	return str;
};

const rangeToUserFriendly = (v: string) => rangeToUserFriendlyMap[v] || '';

export const controllerChannelDtoToControllerChannelMetaConverter = (
	response: ControllerChannelDto,
	translationFunction: TFunction
): ControllerChannelMeta => {
	const excitationVoltagesToUserFriendly = (v: string) => {
		return v === 'EXTERNAL' ? translationFunction('EXTERNAL') : numberToUserFriendly(v);
	};

	return {
		stationChannelType: response.stationChannelType,
		quantity: response.quantity,
		maxPWMFrequency: response.maxPWMFrequency,
		activeStates: convertArrayToList<typeof State>(State, response.activeStates, translationFunction),
		defaultStates: convertArrayToList<typeof State>(State, response.defaultStates, translationFunction),
		directions: convertArrayToList<typeof Direction>(Direction, response.directions, translationFunction),
		pullupResistor: response.pullupResistor,
		averagingFilterPoints: convertArrayToList<typeof AverageFilterPoints>(
			AverageFilterPoints,
			response.averagingFilterPoints,
			numberToUserFriendly
		),
		bridgeTypes: convertArrayToList<typeof BridgeTypes>(BridgeTypes, response.bridgeTypes, translationFunction),
		excitationVoltages: convertArrayToList<typeof ExcitationVoltage>(
			ExcitationVoltage,
			response.excitationVoltages,
			excitationVoltagesToUserFriendly
		),
		gains: convertArrayToList<typeof Gain>(Gain, response.gains, numberToUserFriendly),
		pgaGains: convertArrayToList<typeof PGAGain>(PGAGain, response.pgaGains, numberToUserFriendly),
		samplingRates: convertArrayToList<typeof SamplingRate>(SamplingRate, response.samplingRates, numberToUserFriendly),
		types: convertArrayToList<typeof SpecificType>(SpecificType, response.types, translationFunction),
		singleEndedRanges: convertArrayToList<typeof Range>(Range, response.singleEndedRanges, rangeToUserFriendly),
		differentialRanges: convertArrayToList<typeof Range>(Range, response.differentialRanges, rangeToUserFriendly),
		voltageRanges: convertArrayToList<typeof Range>(Range, response.voltageRanges, rangeToUserFriendly),
		ranges: convertArrayToList<typeof Range>(Range, response.ranges, rangeToUserFriendly),
		currentRanges: convertArrayToList<typeof Range>(Range, response.currentRanges, rangeToUserFriendly)
	};
};

export const stationChannelResponseToHardwareChannelGRPCConverter: Converter<
	IStationChannelResponseDto,
	HardwareChannel
> = (channel) => {
	const stationChannelDto = new HardwareChannel();
	stationChannelDto.setPartNumber(channel.partNumber);
	stationChannelDto.setBoardIndex(channel.channel.boardIndex);
	stationChannelDto.setComponentIndex(channel.channel.componentIndex);
	stationChannelDto.setChannelIndex(channel.channel.channelIndex);
	stationChannelDto.setDmaIndex(channel.channel.dmaIndex);
	stationChannelDto.setDmaChannelIndex(channel.channel.dmaDIIndex);
	stationChannelDto.setSharedMemAddr(channel.channel.shmemAddress);
	stationChannelDto.setSensorIndex(channel.channel.sensorIndex);

	return stationChannelDto;
};

export const stationChannelResponseToStationChannelGRPCConverter: Converter<
	IStationChannelResponseDto,
	StationChannelRequest
> = (channel) => {
	const stationChannelDto = new StationChannelRequest();

	stationChannelDto.setId(channel.id);
	stationChannelDto.setName(channel.name);
	stationChannelDto.setHw(stationChannelResponseToHardwareChannelGRPCConverter(channel));

	if (channel.type === ChannelType.DIGITAL_IO_5V) {
		const digitalIO5V = new StationChannelDigitalIO5V();
		digitalIO5V.setDirection(channel.direction);
		digitalIO5V.setActiveState(channel.activeState);
		digitalIO5V.setDefaultState(channel.defaultState);
		digitalIO5V.setPullUpResistor(channel.pullUpResistor);
		if (channel.bouncingLatency !== undefined) digitalIO5V.setBouncingLatency(channel.bouncingLatency);
		stationChannelDto.setDio5v(digitalIO5V);
	}

	if (channel.type === ChannelType.DIGITAL_IO_24V) {
		const digitalIO24V = new StationChannelDigitalIO24V();
		digitalIO24V.setDirection(channel.direction);
		digitalIO24V.setActiveState(channel.activeState);
		digitalIO24V.setDefaultState(channel.defaultState);
		if (channel.bouncingLatency !== undefined) digitalIO24V.setBouncingLatency(channel.bouncingLatency);
		stationChannelDto.setDio24v(digitalIO24V);
	}

	return stationChannelDto;
};

export const stationChannelResponsesToStationChannelsGRPCConverter: Converter<
	IStationChannelResponseDto[],
	Array<StationChannelRequest>
> = (channels) => {
	const requiredChannels = channels.filter(
		(channel) =>
			channel.type === ChannelType.DIGITAL_IO_5V ||
			channel.type === ChannelType.DIGITAL_IO_24V ||
			channel.type === ChannelType.RELAY ||
			channel.type === ChannelType.DIGITAL_IN_24V
	);
	return requiredChannels.map((ch) => stationChannelResponseToStationChannelGRPCConverter(ch));
};
