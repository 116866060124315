export enum AxesTypes {
	AXIAL = 0,
	TORSIONAL = 1
}

export enum AxesCrudActionTypes {
	EDIT = 'EDIT_AXES',
	CREATE = 'CREATE_AXES',
	DUPLICATE = 'DUPLICATE_AXES',
	DELETE = 'DELETE_AXES'
}
