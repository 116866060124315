import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sidebar } from 'primereact/sidebar';
import { Tooltip } from 'primereact/tooltip';
import classNames from 'classnames';
import { Button, Modal } from '@tactun/ui';

import { Widget, WidgetItem, WidgetSettingsModalProps, WidgetTypeDefinition } from '../../dashboard.types';
import { Modals, WidgetTypes } from '../../dashboard.enums';
import { uuid } from '../../../../tools';

import styles from './AddWidgetSidebar.module.scss';

interface AddWidgetSidebarProps {
	widgets: WidgetItem[];
	addWidget: (widget: Omit<Widget, 'x' | 'y'>) => Widget | undefined | void;
	widgetTypes: WidgetTypeDefinition[];
	position: 'right' | 'left';
	isTestWidgets?: boolean;
	isType?: boolean;
}

const hideWidgetTypes = [WidgetTypes.SAFETY, WidgetTypes.RESULTS, WidgetTypes.STAGE_INFORMATION];

const AddWidgetSidebar: React.FC<AddWidgetSidebarProps> = ({
	widgets,
	addWidget,
	widgetTypes,
	position,
	isTestWidgets = false,
	isType
}) => {
	const [visible, setVisible] = useState(false);

	const visibleWidgetTypes = useMemo(() => {
		return widgetTypes.filter((widgetType) => !hideWidgetTypes.includes(widgetType.type));
	}, [widgetTypes]);

	const { t } = useTranslation('dashboard');

	const enabledWidgetTypes = useMemo(() => {
		return visibleWidgetTypes.filter((widgetType) =>
			isTestWidgets ? widgetType.enableInTest : widgetType.enableInStation
		);
	}, [visibleWidgetTypes, isTestWidgets]);

	const existingWidgetTypes = useMemo(() => {
		const widgetTypeCounts: Record<WidgetTypes, number> = {} as Record<WidgetTypes, number>;
		widgets.forEach(({ type }) => {
			widgetTypeCounts[type] = (widgetTypeCounts[type] || 0) + 1;
		});
		return widgetTypeCounts;
	}, [widgets]);

	const addNewWidget = useCallback(
		(widgetType: WidgetTypeDefinition, metadata: any = {}, settings?: any, widgetProps?: any) => {
			addWidget({
				type: widgetType.type,
				w: widgetType.initialSize.w,
				h: widgetType.initialSize.h,
				...(widgetProps || {}),
				metadata: { ...metadata, id: metadata.id || uuid() },
				settings
			});

			/* // Scroll to center of the widget that was added
			if (newWidget) {
				const widgetCenter = newWidget.y + newWidget.h / 2;
				window.scrollTo({
					top: heightRowToPx(widgetCenter) + 56 - window.innerHeight / 2,
					behavior: 'smooth'
				});
			} */
		},
		[addWidget]
	);

	const [settingsModal, setSettingsModal] = useState<{
		Component: React.FC<WidgetSettingsModalProps>;
		widgetType: WidgetTypeDefinition;
	} | null>();
	const onModalCancel = useCallback(() => {
		setSettingsModal(null);
	}, []);
	const onModalSave = useCallback(
		(metadata: any, settings: any, widgetProps?: any) => {
			if (settingsModal) {
				addNewWidget(settingsModal.widgetType, metadata, settings, widgetProps);
			}
			setSettingsModal(null);
		},
		[addNewWidget, settingsModal]
	);
	const SettingsComponent = settingsModal ? settingsModal.Component : null;

	const onWidgetClick = useCallback(
		(widgetType: WidgetTypeDefinition) => {
			if (widgetType.maxPerDashboard <= existingWidgetTypes[widgetType.type]) {
				return false;
			}

			if (widgetType.settingsModal) {
				setSettingsModal({ Component: widgetType.settingsModal, widgetType: widgetType });
			} else {
				addNewWidget(widgetType);
			}

			setVisible(false);
		},
		[addNewWidget, existingWidgetTypes]
	);

	const graphMaxTooltip = t(
		'This widget can`t be added to the dashboard as the maximum number of graphs (10) is exceeded'
	);
	const otherMaxTooltip = t('This widget is already added to the dashboard');

	useEffect(() => {
		if (SettingsComponent) {
			Modal.show(Modals.WIDGET_SETTINGS_MODAL);
		} else {
			Modal.hide(Modals.WIDGET_SETTINGS_MODAL);
		}
	}, [SettingsComponent]);

	return (
		<div>
			<div className={styles.sidebarPlaceholder} />
			<Button
				rounded
				color="success"
				icon="t-icon-next"
				className={classNames(styles.sidebarButton, {
					[styles.sidebarButtonRight]: position === 'right',
					[styles.sidebarButtonLeft]: position === 'left'
				})}
				onClick={() => setVisible(true)}
			/>
			<Sidebar
				className={styles.sidebar}
				showCloseIcon={false}
				modal={false}
				visible={visible}
				onHide={() => setVisible(false)}
				icons={<h2>{t('Widget Sidebar')}</h2>}
				position={position}
			>
				<div>
					<Tooltip target="[data-disabled=true]" className={styles.widgetToolTip} />
					<div className={styles.widgetTypeList}>
						{enabledWidgetTypes.map((widgetType) => (
							<div
								key={widgetType.type}
								data-disabled={widgetType.maxPerDashboard <= existingWidgetTypes[widgetType.type]}
								data-pr-tooltip={widgetType.type === WidgetTypes.GRAPH ? graphMaxTooltip : otherMaxTooltip}
								data-pr-position="top"
								className={styles.widgetType}
								onClick={() => onWidgetClick(widgetType)}
							>
								<div className={styles.widgetTypeIcon}>
									<img src={widgetType.icon} alt={widgetType.title} />
								</div>
								<div className={styles.widgetTypeTitle}>
									<h3>{widgetType.title}</h3>
									<p>{widgetType.description}</p>
								</div>
								<div className={styles.widgetTypeSign}>
									<span className="pi pi-plus"></span>
								</div>
							</div>
						))}
					</div>
				</div>
				<Button
					rounded
					color="success"
					icon="t-icon-next"
					className={classNames(styles.sidebarCloseButton, {
						[styles.sidebarCloseButtonRight]: position === 'right',
						[styles.sidebarCloseButtonLeft]: position === 'left'
					})}
					onClick={() => setVisible(false)}
				/>
			</Sidebar>
			<Modal id={Modals.WIDGET_SETTINGS_MODAL} isLarge onClose={onModalCancel}>
				{SettingsComponent && <SettingsComponent onCancel={onModalCancel} onSave={onModalSave} isType={isType} />}
			</Modal>
		</div>
	);
};

export default React.memo(AddWidgetSidebar);
