import axios from '../../httpCommon';
import { INamedEntity } from '../../types';
import { ISpecimenTypeRequest, ISpecimenTypeResponse } from './specimenTypes.types';

export const getSpecimenTypes = async () => {
	const response = await axios.get('/quasar/specimen-types');

	return response.data as ISpecimenTypeResponse[];
};

export const createSpecimenType = async (unit: ISpecimenTypeRequest) => {
	const response = await axios.post('/quasar/specimen-types', unit);
	return response.data;
};

export const updateSpecimenType = async (unit: ISpecimenTypeRequest, unitId: string) => {
	const response = await axios.put(`/quasar/specimen-types/${unitId}`, unit);
	return response.data;
};

export const getSpecimenType = async (id: string) => {
	const response = await axios.get(`/quasar/specimen-types/${id}`);

	return response.data as ISpecimenTypeResponse;
};

export const getUnits = async () => {
	const response = await axios.get('/quasar/units');
	return response.data as INamedEntity[];
};

export const checkName = async (name: string, id?: string): Promise<boolean> => {
	const response = await axios.post('/quasar/specimen-types/isNameUnique', { id, name });
	return response.data === true;
};
