import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, DataTable, KebabMenu } from '@tactun/ui';
import { DataTableSelectionSingleChangeEvent, DataTableValueArray } from 'primereact/datatable';
import { VariableListItem } from '../../variables.types';
import { VariableTableActionTypes } from '../../variables.enums';
import { INamedEntity } from '../../../../types';

interface VariablesTableProps {
	onAction: (type: VariableTableActionTypes, data?: VariableListItem) => void;
	data?: VariableListItem[];
	columns: JSX.Element[];
	isLoading: boolean;
	filter?: string;
	hasSelection?: boolean;
	selected?: INamedEntity[];
	onSelect?: (data: DataTableSelectionSingleChangeEvent<DataTableValueArray>) => void;
}

const VariablesTable: FC<VariablesTableProps> = ({
	data,
	isLoading,
	columns,
	filter,
	selected,
	onAction,
	onSelect,
	hasSelection = false
}) => {
	const { t } = useTranslation('variables');

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(VariableTableActionTypes.DELETE, data)
			}
		],
		[onAction, t]
	);

	const handleVariableSelection = useCallback(
		(event: DataTableSelectionSingleChangeEvent<DataTableValueArray>) => {
			if (onSelect) {
				onSelect(event);
			}
		},
		[onSelect]
	);

	return (
		<DataTable
			visibleRowsCount={8}
			value={data}
			loading={isLoading}
			dataKey="id"
			globalFilter={filter}
			showGridlines
			columnResizeMode="fit"
			selection={selected}
			onSelectionChange={handleVariableSelection}
		>
			{hasSelection && <DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />}
			<DataTable.Column resizeable field="name" header={t('Variables name')} />
			{columns}
			<DataTable.Column
				body={(rowData) => (
					<IconButton
						icon="edit"
						fontSize="1.2rem"
						title={t('Edit')}
						onClick={() => onAction(VariableTableActionTypes.EDIT, rowData)}
					/>
				)}
				exportable={false}
				align="right"
				headerStyle={{ width: '3em' }}
			/>
			<DataTable.Column
				body={(rowData) => (
					<IconButton
						icon="duplicate"
						fontSize="1.2rem"
						title={t('Duplicate')}
						disabled
						onClick={() => onAction(VariableTableActionTypes.DUPLICATE, rowData)}
					/>
				)}
				exportable={false}
				align="right"
				headerStyle={{ width: '3em' }}
			/>
			<DataTable.Column
				body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
				exportable={false}
				align="right"
				headerStyle={{ width: '1em' }}
			/>
		</DataTable>
	);
};

export default React.memo(VariablesTable);
