import React, { useCallback, useEffect } from 'react';
import { Button, Form, InputText, Modal, Dropdown, DropdownOptionString } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { dimensionSchema } from '../../specimenTypes.schemas';
import { DimensionFormType } from '../../specimenTypes.types';
import styles from './EditDimensionModal.module.scss';

interface EditDimensionModalProps {
	dimension?: DimensionFormType;
	quantitiesOptions: DropdownOptionString[];
	onSave: (dimension: DimensionFormType) => void;
}

const modalId = 'editDimensionModal';
const formId = 'editDimensionForm';

const EditDimensionModal: React.FC<EditDimensionModalProps> = ({ dimension, quantitiesOptions, onSave }) => {
	const { t } = useTranslation('specimenTypes');
	const {
		register,
		control,
		formState: { errors },
		handleSubmit,
		reset
	} = useForm<DimensionFormType>({
		mode: 'onBlur',
		defaultValues: {
			id: null,
			unit: '',
			name: ''
		},
		resolver: yupResolver(dimensionSchema)
	});

	const handleSave = useCallback(() => {
		handleSubmit((data) => {
			onSave(data);
			Modal.hide(modalId);
		})();
	}, [handleSubmit, onSave]);

	useEffect(() => {
		if (dimension !== undefined) {
			reset(dimension);
		}
	}, [reset, dimension]);

	return (
		<Modal id={modalId}>
			<Modal.Header>{t('Edit a Dimension')}</Modal.Header>
			<Modal.Content>
				<Form className={styles.container} onSubmit={handleSave} id={formId}>
					<input type="hidden" {...register('id')} />
					<Controller
						name="name"
						control={control}
						render={({ field }) => (
							<InputText
								label={t('Parameter name*')}
								{...field}
								error={errors.name?.message}
								className={styles.inputWidth}
							/>
						)}
					/>
					<Controller
						name="unit"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={quantitiesOptions}
								label={t('Parameter unit*')}
								error={errors.unit?.message}
								filter
								className={styles.inputWidth}
							/>
						)}
					/>
				</Form>
			</Modal.Content>
			<Modal.Footer>
				<Button onClick={() => Modal.hide(modalId)} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button onClick={handleSave} color="secondary" variant="contained">
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(EditDimensionModal);
