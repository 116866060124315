import React, { forwardRef, useRef } from 'react';
// @ts-ignore
import { GithubPicker } from 'react-color';
import { OverlayPanel } from 'primereact/overlaypanel';
import styles from './ColorPicker2.module.scss';
import FieldFrame2, { IFieldFrameProps } from '../FieldFrame2';

const colors = [
	'#FFFFFF',
	'#F9F9F9',
	'#E3E3E3',
	'#1E2326',
	'#363F46',
	'#5E656B',
	'#829F23',
	'#A2C72C',
	'#B9D561',
	'#4990B8',
	'#5BB4E6',
	'#84C7EC',
	'#C04B3E',
	'#F05E4E',
	'#F4867A',
	'#FFD858',
	'#737373',
	'#969696'
];

interface IColorPicker2Props extends Omit<IFieldFrameProps, 'children'> {
	value?: string;
	onChange: (newColor: string) => void;
}

const ColorPicker2 = forwardRef(({ onChange, value, ...rest }: IColorPicker2Props, ref: any) => {
	const pickerRef = useRef<any>();
	const handleChange = ({ hex }: any) => {
		onChange(hex as string);
		pickerRef.current?.hide();
	};

	return (
		<>
			<FieldFrame2
				{...rest}
				fieldFrameClassName={styles.colorFieldFrame}
				containerClassName={styles.fieldFrameContainer}
			>
				{({ className, onBlur, disabled }) => {
					return (
						<div
							onClick={(e) => pickerRef.current?.toggle(e)}
							className={styles.colorField}
							style={{ backgroundColor: value || 'transparent' }}
						></div>
					);
				}}
			</FieldFrame2>
			<OverlayPanel ref={pickerRef} className={styles.overlay}>
				<GithubPicker onChange={handleChange} color={value} colors={colors} triangle="hide" />
			</OverlayPanel>
		</>
	);
});

export default ColorPicker2;
