import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { WidgetProps } from '../../Dashboard/dashboard.types';
import WidgetHeader from '../../Dashboard/components/Widget/WidgetHeader';
import WidgetContent from '../../Dashboard/components/Widget/WidgetContent';
import OffsetCalibrationFooter from '../components/OffsetCalibrationFooter';
import OffsetCalibrationContent from '../components/OffsetCalibrationContent';
import OffsetCalibrationDialogContainer from './OffsetCalibrationDialogContainer';
import { useOffsetCalibration } from '../offsetCalibration.hook';
import { offsetCalibrationSchema } from '../offsetCalibration.schemas';
import { OffsetCalibrationConfigurationFormType } from '../offsetCalibration.types';
import { useAxes, useTestAxis } from '../../Axes/axes.hooks';
import { useEntitiesAsList } from '../../../hooks';
import { getSetRequest, offsetCalibrationConfigurationFormToGRPCRequest } from '../offsetCalibration.converters';
import { ControlChannelResponseDto } from '../../ControlChannels';
import { OffsetCalibrationStatus } from '../offsetCalibration.enums';
import { updateStationChannel } from '../../StationChannels/stationChannels.api';
import {
	ChannelType,
	IStationChannelResponseDto,
	StationChannelRequestDto,
	formRequestConverter,
	getOffsetUnitByRange,
	isChannelCurrentAut,
	responseFormConverter
} from '../../StationChannels';
import { isNotNullOrUndefined } from '../../../tools/common';
import { useMutation } from '@tanstack/react-query';
import { ActuatorsTypes } from '../../Actuators';
import { useStationId } from '../../Stations';

const OffsetCalibrationContainer: React.FC<WidgetProps> = ({ metadata }) => {
	const { t } = useTranslation('offsetCalibration');
	const [isDialogVisible, setIsDialogVisible] = useState(false);
	const { start, stop, setOffset, status, offset } = useOffsetCalibration(metadata.isType);
	const { stationId, testId } = useParams();
	const testStationId = useStationId();
	const { axes: stationAxes } = useAxes(stationId);
	const { axes: testAxes } = useTestAxis(testId);
	const axes = useMemo(
		() => (testAxes || stationAxes || []).filter((axis) => axis.actuator.type === ActuatorsTypes.SERVO_ELECTRIC),
		[stationAxes, testAxes]
	);
	const axesOption = useEntitiesAsList(axes);

	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(offsetCalibrationSchema)
	});
	const { handleSubmit, reset, setValue, trigger } = form;

	const axisId = form.watch('axisId');
	const selectedAxis = useMemo(() => axes.find((axis) => axis.id === axisId), [axes, axisId]);

	const updateMutation = useMutation({
		mutationFn: (data: { stationChannel: StationChannelRequestDto; channelId: string }) =>
			updateStationChannel(data.stationChannel, data.channelId),
		onSuccess: (stationChannel: IStationChannelResponseDto) => {
			if (testStationId && selectedAxis) {
				let voltageToCurrentRatio = 1.0;
				if (stationChannel?.type === ChannelType.ANALOG_OUT_100MA) voltageToCurrentRatio = 100.0;

				setOffset(
					getSetRequest(
						testStationId,
						selectedAxis?.id,
						selectedAxis?.axisIndex,
						stationChannel.offset,
						voltageToCurrentRatio
					)
				);
			}
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const handleCalibrate = useCallback(() => {
		if (axisId) {
			setIsDialogVisible(true);
		} else {
			trigger('axisId');
		}
	}, [axisId, trigger]);

	const handleSave = useCallback(() => {
		handleSubmit((data) => {
			const stationChannel = selectedAxis?.actuator?.stationChannel;
			if (stationChannel && isNotNullOrUndefined(data.offset)) {
				const stChannelRequest = formRequestConverter(responseFormConverter(stationChannel));
				stChannelRequest.offset = data.offset;
				updateMutation.mutate({ stationChannel: stChannelRequest, channelId: stationChannel.id });
			} else {
				toast.error(t('Station Channel of selected axis is missing.'));
			}
		})();
	}, [handleSubmit, selectedAxis?.actuator?.stationChannel, t, updateMutation]);

	const handleRunningToggle = useCallback(
		(data: OffsetCalibrationConfigurationFormType, channel: ControlChannelResponseDto, isStop: boolean) => {
			if (testStationId === undefined) {
				toast.error(t('Station Id is missing.'));
				return;
			}

			const axis = axes.find((axis) => axis.id === axisId);
			if (axis && channel.feedbackMeasurement?.id) {
				const request = offsetCalibrationConfigurationFormToGRPCRequest(
					data,
					testStationId,
					channel.feedbackMeasurement?.id,
					axis,
					isStop
				);

				if (isStop) stop(request);
				else start(request);
			} else {
				toast.error(t('Cannot start calibration. Missing axis or feedback measurement.'));
			}
		},
		[axes, axisId, start, stop, t, testStationId]
	);

	const handleDialogVisibilityChange = useCallback((isDialogVisible: boolean) => {
		setIsDialogVisible(isDialogVisible);
	}, []);

	useEffect(() => {
		return () => {
			reset();
		};
	}, [reset]);

	// Set offset value after calibration
	useEffect(() => {
		if (status === OffsetCalibrationStatus.SUCCESS && offset !== undefined) {
			setValue('offset', offset);
		}
	}, [offset, setValue, status]);

	// Set default offset from Channel after axis selection
	useEffect(() => {
		if (selectedAxis?.actuator?.stationChannel?.offset !== undefined) {
			setValue('offset', selectedAxis?.actuator?.stationChannel?.offset);
			trigger('offset');
		}
	}, [setValue, selectedAxis, trigger]);

	const offsetUnit = useMemo(
		() => getOffsetUnitByRange(selectedAxis?.actuator?.stationChannel?.range),
		[selectedAxis?.actuator?.stationChannel?.range]
	);

	const limit = useMemo(() => {
		if (selectedAxis?.actuator?.stationChannel?.limit === undefined) return undefined;

		return isChannelCurrentAut(selectedAxis?.actuator?.stationChannel.type)
			? selectedAxis?.actuator?.stationChannel?.limit
			: selectedAxis?.actuator?.stationChannel?.limit * 1000;
	}, [selectedAxis?.actuator?.stationChannel]);

	return (
		<>
			<OffsetCalibrationDialogContainer
				limit={limit}
				offsetUnit={offsetUnit}
				axisId={axisId}
				calibrationStatus={status}
				onRunningChange={handleRunningToggle}
				isDialogVisible={isDialogVisible}
				onDialogVisibilityChange={handleDialogVisibilityChange}
			/>
			<WidgetHeader>{t('Offset calibration')}</WidgetHeader>
			<WidgetContent
				footer={<OffsetCalibrationFooter status={status} isSavingOffset={updateMutation.isPending} />}
				isType={metadata.isType}
			>
				<OffsetCalibrationContent
					form={form}
					axes={axesOption}
					calibrationStatus={status}
					offsetUnit={offsetUnit}
					onCalibrate={handleCalibrate}
					onSave={handleSave}
				/>
			</WidgetContent>
		</>
	);
};
export default OffsetCalibrationContainer;
