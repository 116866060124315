import * as yup from 'yup';
import { UnitResponse, UnitFormType } from '../Units';
import { dimensionSchema, specimenTypeSchema } from './specimenTypes.schemas';

export enum DimensionTypeActionTypes {
	DELETE = 'DELETE_DIMENSION',
	EDIT = 'EDIT_DIMENSION'
}

export interface ISpecimenTypeRequest {
	name: string;
	dimensions: Record<string, any>;
	gaugeLengthEquation?: string;
	gaugeLengthEquationUnitId?: string;
	areaEquation?: string;
	areaEquationUnitId?: string;
	imageId?: string;
}

export interface ISpecimenTypeResponse {
	id: string;
	name: string;
	dimensions: Record<string, UnitResponse>;
	gaugeLengthEquation: string;
	gaugeLengthEquationUnit: UnitFormType;
	areaEquation: string;
	areaEquationUnit: UnitFormType;
	imageUrl: string;
	imageId: string;
}
export type DimensionFormType = yup.InferType<typeof dimensionSchema>;

export type SpecimenFormType = yup.InferType<typeof specimenTypeSchema>;
