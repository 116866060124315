import React, { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ControlChannelsTable from '../components/ControlChannelsTable';
import { ControlChannelListItem } from '../controlChannel.types';
import { ControlChannelsTableActionTypes } from '../controlChannel.enums';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { useStationName } from '../../Stations/stations.hooks';
import { responsesListConverter } from '../controlChannel.converters';
import { useControlChannels } from '../controlChannel.hooks';

const ControlChannelsTableContainer: React.FC = () => {
	const navigate = useNavigate();
	const { stationId } = useParams();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const queryClient = useQueryClient();
	const { stationName } = useStationName(stationId);

	const handleAction = useCallback(
		(type: ControlChannelsTableActionTypes, data?: ControlChannelListItem) => {
			switch (type) {
				case ControlChannelsTableActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case ControlChannelsTableActionTypes.CREATE:
					navigate('./create');
					break;
				case ControlChannelsTableActionTypes.DELETE:
					setEntityForDelete(data as IEntity);
					break;
				case ControlChannelsTableActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
			}
		},
		[navigate]
	);

	const { controlChannels, isControlChannelsLoading } = useControlChannels(stationId);
	const data = useMemo(() => controlChannels && responsesListConverter(controlChannels), [controlChannels]);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handlePostDelete = useCallback(
		(id: string) => {
			queryClient.setQueryData<ControlChannelListItem[]>(
				['controlChannels', stationId],
				(old) => old?.filter((channel) => channel.id !== id)
			);
		},
		[queryClient, stationId]
	);

	return (
		<>
			<ControlChannelsTable
				stationName={stationName}
				onAction={handleAction}
				data={data}
				isLoading={isControlChannelsLoading}
			/>
			<EntityDeleter
				onDeleted={handlePostDelete}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.CONTROL_CHANNEL}
				entity={entityForDelete}
				checkUsage
			/>
		</>
	);
};

export default React.memo(ControlChannelsTableContainer);
