import { useQuery, useQueryClient } from '@tanstack/react-query';
import { MeasurementResponseDto } from './measurements.types';
import { FormulaTypes } from './measurements.enums';
import * as api from './measurements.api';

export const useMeasurements = (stationId?: string) => {
	const {
		data: measurements,
		isLoading: isMeasurementsLoading,
		isFetched
	} = useQuery({
		queryKey: ['measurements', { stationId }],
		// When we provide enable option - !!measurementId, the measurementId is guaranteed
		queryFn: () => api.getMeasurementsForStation(stationId as string),
		enabled: !!stationId
	});

	const queryClient = useQueryClient();

	const updateMeasurements = () => {
		queryClient.invalidateQueries({ queryKey: ['measurements', { stationId }] });
	};

	return {
		measurements,
		isMeasurementsLoading,
		isMeasurementsFetched: isFetched,
		updateMeasurements
	};
};

export const useMeasurement = (measurementId?: string) => {
	const { data: measurementDto, isLoading: isMeasurementLoading } = useQuery<MeasurementResponseDto>({
		queryKey: ['measurement', { id: measurementId }],
		// When we provide enable option - !!measurementId, the measurementId is guaranteed
		queryFn: () => api.getMeasurement(measurementId as string),
		enabled: !!measurementId
	});

	const queryClient = useQueryClient();

	const updateMeasurement = () => {
		queryClient.invalidateQueries({ queryKey: ['measurements', { measurementId }] });
	};

	return {
		measurementDto,
		isMeasurementLoading,
		updateMeasurement
	};
};

export const useFormulaTypes = () => {
	return Object.values(FormulaTypes);
};
