import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	PageLayout,
	Form,
	InputText,
	Button,
	BottomBar,
	InfoBadge,
	InfoBadgeIcons,
	Dropdown,
	DropdownOptionString,
	Modal
} from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller, FieldValues } from 'react-hook-form';
import { SpecimenFormType, DimensionFormType, DimensionTypeActionTypes } from '../../specimenTypes.types';
import DimensionTable from '../DimensionTable';
import ImageUploader, { IImageUploadResponse } from '../../../ImageUploader';
import { QuantityResponseDto } from '../../../Units';
import EditDimensionModal from '../EditDimensionModal';
import { Calculator, calculatorValueToUserFriendly } from '../../../Calculator';
import styles from './SpecimenType.module.scss';
import {
	calculatorStringValueToList,
	calculatorValueReplaceVariableNames
} from '../../../Calculator/calculator.converters';
import { CalculatorValueTypes } from '../../../Calculator/calculator.enums';

interface ISpecimenTypeProps {
	isCreate: boolean;
	isLoading: boolean;
	form: UseFormReturn<SpecimenFormType>;
	dimensionForm: UseFormReturn<DimensionFormType>;
	dimensions: FieldValues;
	quantities: QuantityResponseDto[];
	changedDimensionNames: Record<string, string>;
	onAddDimension: (dimension: DimensionFormType) => void;
	onEditDimension: (dimension: DimensionFormType) => void;
	onDeleteDimension: (dimension: DimensionFormType) => void;
	onBack: () => void;
	onSave: () => void;
}

const formId = 'specimenTypeForm';
const SpecimenType: React.FC<ISpecimenTypeProps> = ({
	isCreate,
	isLoading,
	form,
	dimensionForm,
	dimensions,
	changedDimensionNames,
	quantities,
	onBack,
	onSave,
	onAddDimension,
	onEditDimension,
	onDeleteDimension
}) => {
	const { t } = useTranslation('specimenTypes');
	const [dimensionForEdit, setDimensionForEdit] = useState<DimensionFormType>();
	const {
		control,
		formState: { errors },
		register,
		setValue,
		trigger,
		watch
	} = form;
	const {
		control: dimensionControl,
		formState: { errors: dimensionErrors },
		handleSubmit,
		setValue: setDimensionValue,
		reset
	} = dimensionForm;
	const title = useMemo<string>(() => {
		return isCreate ? t('Create a Specimen type') : t('Edit a Specimen type');
	}, [isCreate, t]);

	const dimensionsList = useMemo(() => dimensions.map((d: DimensionFormType) => ({ ...d })), [dimensions]);

	const gaugeLengthEquation = watch('gaugeLengthEquation');
	const areaEquation = watch('areaEquation');
	const usedDimensions = useMemo(() => {
		const usedInGauge = calculatorStringValueToList(gaugeLengthEquation).filter(
			(v) => v.type === CalculatorValueTypes.VARIABLE
		);
		const usedInAreaEquation = calculatorStringValueToList(areaEquation).filter(
			(v) => v.type === CalculatorValueTypes.VARIABLE
		);

		return [...usedInGauge, ...usedInAreaEquation]
			.filter((value, index, array) => array.indexOf(value) === index)
			.map((v) => v.value);
	}, [areaEquation, gaugeLengthEquation]);

	useEffect(() => {
		setDimensionValue(
			'dimensions',
			dimensionsList.map((d: DimensionFormType) => d.name)
		);
	}, [setDimensionValue, dimensionsList]);

	const handleImageChange = useCallback(
		(image: IImageUploadResponse | null) => {
			setValue('imageId', image?.id);
			setValue('imageUrl', image?.url);
		},
		[setValue]
	);

	const quantitiesOptions = useMemo<DropdownOptionString[]>(() => {
		return quantities.map((quantity) => ({
			label: quantity.name,
			options: quantity.units.map((unit) => ({ label: unit.name, value: unit.id }))
		}));
	}, [quantities]);

	const handleAction = useCallback(
		(type: DimensionTypeActionTypes, data?: DimensionFormType) => {
			switch (type) {
				case DimensionTypeActionTypes.EDIT:
					setDimensionForEdit(data);
					Modal.show('editDimensionModal');
					break;
				case DimensionTypeActionTypes.DELETE:
					if (data) onDeleteDimension(data);
					break;
			}
		},
		[onDeleteDimension]
	);
	const imageUrl = watch('imageUrl');

	// Calculator
	const [calculatorField, setCalculatorField] = useState<'gaugeLengthEquation' | 'areaEquation' | null>(null);
	const calculatorInitialValue = useMemo(
		() => (calculatorField ? watch(calculatorField) : ''),
		[calculatorField, watch]
	);
	const calculatorVariables = useMemo(() => {
		return dimensions.map((param: DimensionFormType) => param.name as string);
	}, [dimensions]);
	const handleCalcClose = useCallback(() => setCalculatorField(null), []);
	const handleCalcSave = useCallback(
		(value: string) => {
			if (calculatorField) {
				setValue(calculatorField, value);
				trigger(calculatorField);
				setCalculatorField(null);
			}
		},
		[calculatorField, setValue, trigger]
	);
	useEffect(() => {
		const newAreaEquation = calculatorValueReplaceVariableNames(watch('areaEquation'), changedDimensionNames);
		const newGaugeLengthEquation = calculatorValueReplaceVariableNames(
			watch('gaugeLengthEquation'),
			changedDimensionNames
		);
		setValue('areaEquation', newAreaEquation);
		setValue('gaugeLengthEquation', newGaugeLengthEquation);
	}, [changedDimensionNames, setValue, watch]);

	return (
		<>
			<PageLayout info={t('Specimen Types')}>
				<PageLayout.Header title={title} onBack={onBack} />
				<div className={styles.container}>
					<Form className={styles.left} onSubmit={onSave} id={formId}>
						<input type="hidden" {...register('id')} />
						<input type="hidden" {...register('imageId')} />
						<input type="hidden" {...register('imageUrl')} />
						<input type="hidden" {...register('dimensions')} />
						<Form.Row>
							<Controller
								name="name"
								control={control}
								render={({ field }) => <InputText label={t('Name*')} {...field} error={errors.name?.message} />}
							/>
						</Form.Row>
						<Form.Row className="cellsThree">
							<Controller
								name="name"
								control={dimensionControl}
								render={({ field }) => (
									<InputText
										label={t('Parameter name*')}
										{...field}
										id="parameterName"
										error={dimensionErrors.name?.message || errors.dimensions?.message}
									/>
								)}
							/>
							<Controller
								name="unit"
								control={dimensionControl}
								render={({ field }) => (
									<Dropdown
										{...field}
										options={quantitiesOptions}
										label={t('Parameter unit*')}
										error={dimensionErrors.unit?.message}
										filter
									/>
								)}
							/>
							<div>
								<Button
									variant="contained"
									color="primary"
									icon="t-icon-add"
									type="button"
									className="fieldAdd"
									onClick={() =>
										handleSubmit((data) => {
											onAddDimension(data);
											reset({ name: '' });
										})()
									}
								/>
							</div>
						</Form.Row>
						<div className="cellsOne">
							<DimensionTable
								usedDimensions={usedDimensions}
								dimensions={dimensionsList}
								onAction={handleAction}
								error={errors.dimensions?.message}
							/>
						</div>
						<Form.Row>
							<div className={styles.calculationFormRow}>
								<Controller
									name="gaugeLengthEquation"
									control={control}
									render={({ field: { onBlur, ...field } }) => (
										<InputText
											label={t('Gauge length equation*')}
											readOnly
											{...field}
											value={calculatorValueToUserFriendly(field.value)}
											className={styles.lengthInput}
											error={errors.gaugeLengthEquation?.message}
										/>
									)}
								/>
								<Controller
									name="gaugeLengthEquationUnitId"
									control={control}
									render={({ field }) => (
										<Dropdown
											{...field}
											options={quantitiesOptions}
											filter
											className={styles.lengthDropdown}
											error={errors.gaugeLengthEquationUnitId?.message}
										/>
									)}
								/>
								<Button
									variant="contained"
									color="secondary"
									className="lengthButton"
									icon="t-icon-calc-plus-minus"
									type="button"
									onClick={() => setCalculatorField('gaugeLengthEquation')}
								/>
							</div>
							<div className={styles.calculationFormRow}>
								<Controller
									name="areaEquation"
									control={control}
									render={({ field: { onBlur, ...field } }) => (
										<InputText
											readOnly
											label={t('Area equation*')}
											className={styles.lengthInput}
											{...field}
											value={calculatorValueToUserFriendly(field.value)}
											error={errors.areaEquation?.message}
										/>
									)}
								/>
								<Controller
									name="areaEquationUnitId"
									control={control}
									render={({ field }) => (
										<Dropdown
											{...field}
											options={quantitiesOptions}
											filter
											error={errors.areaEquationUnitId?.message}
											className={styles.lengthDropdown}
										/>
									)}
								/>
								<Button
									variant="contained"
									color="secondary"
									className="lengthButton"
									icon="t-icon-calc-plus-minus"
									type="button"
									onClick={() => setCalculatorField('areaEquation')}
								/>
							</div>
						</Form.Row>
					</Form>
					<ImageUploader onChange={handleImageChange} url={imageUrl} />
				</div>
			</PageLayout>
			<Calculator
				isOpen={!!calculatorField}
				initialValue={calculatorInitialValue}
				onCancel={handleCalcClose}
				onSave={handleCalcSave}
				variables={calculatorVariables}
			/>
			<EditDimensionModal dimension={dimensionForEdit} quantitiesOptions={quantitiesOptions} onSave={onEditDimension} />
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button label={t('Cancel')} data-testid="cancelBtn" variant="contained" color="success" onClick={onBack} />
				<Button
					label={t('Save')}
					data-testid="saveBtn"
					variant="contained"
					color="secondary"
					type="submit"
					form={formId}
				/>
			</BottomBar>
		</>
	);
};

export default SpecimenType;
