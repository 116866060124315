export const isNullOrUndefined = <T>(value: T): value is T => {
	return value === null || value === undefined;
};

export const isNotNullOrUndefined = <T>(value: T | undefined | null): value is T => {
	return !isNullOrUndefined(value);
};

export const downloadURL = (url: string, fileName: string) => {
	let a = document.createElement('a');
	a.href = url;
	a.download = fileName;
	a.style.display = 'none';
	document.body.appendChild(a);
	a.click();
	a.remove();
};

export const toDataURL = (url?: string): Promise<string | null> => {
	if (!url) return Promise.resolve(null);

	return fetch(url)
		.then((response) => response.blob())
		.then(
			(blob) =>
				new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onloadend = () => {
						resolve(reader.result as string);
					};
					reader.onerror = (e) => {
						reject(e);
					};
					reader.readAsDataURL(blob);
				})
		);
};
