import { Routes, Route } from 'react-router-dom';
import CustomersContainer from './containers/CustomersContainer';
import CustomersTableContainer from './containers/CustomersTableContainer';
import OrderHistoryContainer from './containers/OrderHistoryContainer';

const CustomersRouts: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<CustomersTableContainer />} />
			<Route path="create" element={<CustomersContainer />} />
			<Route path="edit/:customerId" element={<CustomersContainer />} />
			<Route path="order-history" element={<OrderHistoryContainer />} />
		</Routes>
	);
};

export default CustomersRouts;
