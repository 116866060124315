import cx from 'classnames';
import FormRow, { IFormRowProps } from './FormRow';
import FormDivider, { IFormDividerProps } from './FormDivider';

import styles from './Form.module.scss';

interface IPageLayoutProps {
	id?: string;
	children?: React.ReactNode;
	className?: string;
	onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
}

interface IPageLayoutSubComponents {
	Row: React.FC<IFormRowProps>;
	Divider: React.FC<IFormDividerProps>;
}

const Form: React.FC<IPageLayoutProps> & IPageLayoutSubComponents = ({ children, className, onSubmit, id }) => {
	return (
		<form className={cx(styles.formContainer, className)} onSubmit={onSubmit} id={id} autoComplete="off">
			{children}
		</form>
	);
};

Form.displayName = 'Form';
Form.Row = FormRow;
Form.Divider = FormDivider;

export default Form;
