import { array, lazy, mixed, number, object, string } from 'yup';
import { controlAlgBaseSchema } from '../ControlAlgorithms/controlAlgorithms.schemas';
import { ControlChannelSetPointType } from '../ControlChannels';
import { waveformSchema } from '../Waveforms';

export const axisConfigurationSchema = object({
	axisId: string().required(),
	axisName: string().required(),
	axisIndex: number().required(),
	controlChannelId: string().required('Control is a required field'),
	controlChannelName: string().required(),
	stationId: string().required(),
	setPointType: mixed<ControlChannelSetPointType>().required(),
	setPointMeasurementId: string().optional(),
	feedbackMeasurementId: string().optional(),
	rateLimit: number().optional(),
	waveform: lazy((value) => waveformSchema.resolve({ value: value?.type }).required()),
	controlAlgorithmsForm: controlAlgBaseSchema.optional()
});

export const controlStageSchema = object({
	testId: string().optional(),
	stationId: string().optional(),
	axesConfigs: array().of(axisConfigurationSchema.required()).required()
});
