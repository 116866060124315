import axios from '../../httpCommon';
import {
	TestInformationResponseDto,
	TestInformationRequestDto,
	TestListDto,
	TestStageResponseDto,
	TestStageRequestDto,
	TestStageAxisConfigResponseDto,
	ITestFullResponseDto
} from './tests.types';

export const getTestById = async (id?: string): Promise<TestInformationResponseDto> => {
	const response = await axios.get(`/quasar/tests/${id}`);

	return response.data;
};

export const getFullTestById = async (id: string): Promise<ITestFullResponseDto> => {
	const response = await axios.get(`/quasar/tests/${id}/full`);

	return response.data;
};

export const getTest = async (id?: string): Promise<TestInformationResponseDto> => {
	const response = await axios.get(`/quasar/tests/${id}`);

	return response.data;
};

export const checkName = async (name: string, id?: string): Promise<boolean> => {
	const response = await axios.post('/quasar/tests/isNameUnique', { id, name });
	return response.data === true;
};

export const getTests = async (): Promise<TestListDto[]> => {
	const response = await axios.get('/quasar/tests');

	return response.data;
};

export const createTest = async (system: TestInformationRequestDto): Promise<TestInformationResponseDto> => {
	const response = await axios.post('/quasar/tests', system);

	return response.data;
};

export const updateTest = async (
	system: TestInformationRequestDto,
	id: string
): Promise<TestInformationResponseDto> => {
	const response = await axios.put(`/quasar/tests/${id}`, system);

	return response.data;
};

export const getDevices = async (): Promise<string[]> => {
	const response = await axios.get('/quasar/devices');

	return response.data;
};

export const getTestStandards = async (): Promise<string[]> => {
	const response = await axios.get('/quasar/tests/stds');

	return response.data;
};

export const getTestStages = async (testId?: string): Promise<TestStageResponseDto[]> => {
	const response = await axios.get(`/quasar/tests/${testId}/stages`);

	return response.data as TestStageResponseDto[];
};

export const getTestTypes = async (): Promise<string[]> => {
	// TODO: Should be used endpoint
	const response = new Promise<string[]>((resolve) => {
		setTimeout(() => {
			resolve([
				'SINGLE_AXIS_STATIC_MACHINE',
				'SINGLE_AXIS_DYNAMIC_MACHINE',
				'PLANAR_BIAXIAL_MACHINE',
				'AXIAL_TORSIONAL_MACHINE',
				'MULTI_AXIS_MACHINE'
			]);
		}, 200);
	});

	return response;
};

export const createStage = async (stage: TestStageRequestDto): Promise<TestStageResponseDto> => {
	const response = await axios.post(`/quasar/tests/${stage.testId}/stages`, stage);

	return response.data;
};

export const updateStage = async (stage: TestStageRequestDto): Promise<TestStageResponseDto> => {
	const response = await axios.put(`/quasar/tests/${stage.testId}/stages`, stage);

	return response.data;
};

export const deleteStage = async (stageId: string): Promise<TestStageResponseDto> => {
	const response = await axios.delete(`/quasar/tests/stages/${stageId}`);

	return response.data;
};

export const deleteStageAxis = async (axisId: string): Promise<TestStageAxisConfigResponseDto> => {
	const response = await axios.delete(`/quasar/tests/stages/axis/${axisId}`);

	return response.data;
};
