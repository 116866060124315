import axios from '../../httpCommon';
import { SystemFormType } from './system.types';

const normalizeResponseData = (response: Record<string, any>): SystemFormType => {
	return {
		id: response.id,
		name: response.name,
		controllerModel: response.controllerModel,
		copyrightInformation: response.copyrightInformation,
		version: response.version,
		description: response.description
	} as SystemFormType;
};

export const getSystemById = async (id?: string): Promise<SystemFormType> => {
	const response = await axios.get(`/quasar/systems/${id}`);

	return normalizeResponseData(response.data);
};

export const getSystems = async (): Promise<SystemFormType[]> => {
	const response = await axios.get('/quasar/systems');

	return response.data.map((d: Record<string, any>) => normalizeResponseData(d));
};

export const createSystem = async (system: SystemFormType): Promise<SystemFormType> => {
	const response = await axios.post('/quasar/systems', system);

	return response.data;
};

export const updateSystem = async (system: SystemFormType, id: string): Promise<SystemFormType> => {
	const response = await axios.put(`/quasar/systems/${id}`, system);

	return response.data;
};

export const getDevices = async (): Promise<string[]> => {
	const response = await axios.get('/quasar/devices');

	return response.data;
};
