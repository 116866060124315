import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Modal } from '@tactun/ui';
import { useAxisControlChannels } from '../../ControlChannels';
import { useEntitiesAsList } from '../../../hooks';
import TestAxesConfigurationForm from '../components/TestAxesConfigurationForm';
import { axesConfigurationFormDefaultValues, testAxesConfigurationModalId } from '../testAxesConfiguration.const';
import { TestAxesConfigurationFormType, TestStageAxisConfigRequestDto } from '../testAxesConfiguration.type';
import { testAxesConfigurationFormToRequestConverter } from '../testAxesConfiguration.converters';
import { createStageAxis, updateStageAxis } from '../testAxesConfiguration.api';
import { testAxesConfigurationSchema } from '../testAxesConfiguration.schemas';
import { responseControlAlgorithmsFormConverter } from '../../ControlAlgorithms';
import { useAxis } from '../../Axes/axes.hooks';

interface TestAxesConfigurationContainerProps {
	axisId?: string;
	stageId?: string;
	axisConfig?: TestAxesConfigurationFormType;
	testId: string;
}

const TestAxesConfigurationContainer: React.FC<TestAxesConfigurationContainerProps> = ({
	axisId,
	stageId,
	testId,
	axisConfig
}) => {
	const { controlChannels } = useAxisControlChannels(axisId);
	const queryClient = useQueryClient();
	const controlChannelsOptions = useEntitiesAsList(controlChannels);
	const form = useForm({
		mode: 'onBlur',
		defaultValues: axesConfigurationFormDefaultValues,
		// @ts-ignore
		resolver: yupResolver(testAxesConfigurationSchema)
	});
	const {
		watch,
		reset,
		setValue,
		formState: { touchedFields }
	} = form;
	const ccId = watch('ccId');
	const controlChannel = useMemo(() => controlChannels?.find((cc) => cc.id === ccId), [ccId, controlChannels]);
	const { axisDto } = useAxis(axisId);
	const useStationParams = watch('useStationParams');

	const updateStageAxisConfig = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: ['test-stages', { testId }] });
	}, [queryClient, testId]);

	const createMutation = useMutation({
		mutationFn: (data: { axisConfig: TestStageAxisConfigRequestDto; stageId: string }) =>
			createStageAxis(data.axisConfig, data.stageId),
		onSuccess: () => {
			updateStageAxisConfig();
			Modal.hide(testAxesConfigurationModalId);
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { axisConfig: TestStageAxisConfigRequestDto; axisId: string }) =>
			updateStageAxis(data.axisConfig, data.axisId),
		onSuccess: () => {
			updateStageAxisConfig();
			Modal.hide(testAxesConfigurationModalId);
		},
		onError: (e: Error) => toast.error(e.message)
	});

	useEffect(() => {
		reset(
			// @ts-ignore
			{
				...axisConfig,
				axisId,
				stageId
			},
			{ keepDirty: false, keepTouched: false }
		);
	}, [axisId, stageId, reset, axisConfig]);

	useEffect(() => {
		return () => {
			reset(axesConfigurationFormDefaultValues);
		};
	}, [reset]);

	useEffect(() => {
		if (touchedFields.ccId && ccId && controlChannel?.controlAlgorithm) {
			const controlAlgorithmsForm = responseControlAlgorithmsFormConverter(controlChannel.controlAlgorithm);

			setValue('controlAlgorithmsForm', {
				...controlAlgorithmsForm,
				additionalAlgorithms: []
			});
			// Do not remove this line, it is needed to update the form state. react-hook-form does not update the nested field when the value is array
			setValue('controlAlgorithmsForm.additionalAlgorithms', controlAlgorithmsForm.additionalAlgorithms as never[]);
		}
	}, [ccId, controlChannel, touchedFields.ccId, setValue]);

	useEffect(() => {
		if (useStationParams && controlChannel) {
			const ca = responseControlAlgorithmsFormConverter(controlChannel.controlAlgorithm);

			setValue('controlAlgorithmsForm.baseAlgorithm', ca.baseAlgorithm);
			// @ts-ignore
			setValue('controlAlgorithmsForm.pid', ca.pid);
			// @ts-ignore
			setValue('controlAlgorithmsForm.asymmetricPid', ca.asymmetricPid);
		}
	}, [controlChannel, setValue, useStationParams]);

	const handleSave = useCallback(
		(data: TestAxesConfigurationFormType) => {
			const request = testAxesConfigurationFormToRequestConverter(data);
			if (data.id) {
				updateMutation.mutate({ axisConfig: request, axisId: data.id });
			} else {
				if (stageId) {
					createMutation.mutate({ axisConfig: request, stageId });
				}
			}
		},
		[createMutation, stageId, updateMutation]
	);

	return (
		<FormProvider {...form}>
			<TestAxesConfigurationForm
				controlChannels={controlChannelsOptions}
				controlChannel={controlChannel}
				onSave={handleSave}
				testId={testId}
				axisDto={axisDto}
			/>
		</FormProvider>
	);
};

export default TestAxesConfigurationContainer;
