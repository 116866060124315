import { Button, Modal } from '@tactun/ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface IConfirmationModalProps {
	title: string;
	children: React.ReactNode;
	id: string;
	hideCancel?: boolean;
	className?: string;
	confirmBtnText?: string;
	isLoading?: boolean;
	onConfirm: () => void;
	handleClose?: () => void;
}

interface IConfirmationModalSub {
	show: (modalId: string) => void;
	hide: (modalId: string) => void;
}

const ConfirmationModal: React.FC<IConfirmationModalProps> & IConfirmationModalSub = ({
	className,
	children,
	id,
	title,
	confirmBtnText,
	isLoading,
	onConfirm,
	hideCancel,
	handleClose
}) => {
	const { t } = useTranslation();

	const close = useCallback(() => {
		if (handleClose) {
			handleClose();
		} else {
			Modal.hide(id);
		}
	}, [handleClose, id]);

	const handleConfirm = useCallback(() => {
		onConfirm();
	}, [onConfirm]);

	return (
		<>
			<Modal id={id} onClose={handleClose}>
				<Modal.Header>{title}</Modal.Header>
				<Modal.Content className={className}>{children}</Modal.Content>
				<Modal.Footer>
					{!hideCancel && (
						<Button className="btnCancel" onClick={handleClose || close} color="success" variant="text">
							{t('Cancel')}
						</Button>
					)}
					<Button
						onClick={handleConfirm}
						color="secondary"
						variant="contained"
						disabled={isLoading}
						loading={isLoading}
					>
						{confirmBtnText ?? t('Delete')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

ConfirmationModal.show = (modalId: string) => {
	Modal.show(modalId);
};
ConfirmationModal.hide = (modalId: string) => {
	Modal.hide(modalId);
};

export default ConfirmationModal;
