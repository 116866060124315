// source: test.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var system_pb = require('./system_pb.js');
goog.object.extend(proto, system_pb);
var rule_pb = require('./rule_pb.js');
goog.object.extend(proto, rule_pb);
var specimen_pb = require('./specimen_pb.js');
goog.object.extend(proto, specimen_pb);
var data_flow_pb = require('./data_flow_pb.js');
goog.object.extend(proto, data_flow_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.test.DataLogParams', null, global);
goog.exportSymbol('proto.test.DataStorageFullBehaviorType', null, global);
goog.exportSymbol('proto.test.DataStorageType', null, global);
goog.exportSymbol('proto.test.Loop', null, global);
goog.exportSymbol('proto.test.OperateRequest', null, global);
goog.exportSymbol('proto.test.OperationCmd', null, global);
goog.exportSymbol('proto.test.PeakLoadCalculation', null, global);
goog.exportSymbol('proto.test.StageCalculationType', null, global);
goog.exportSymbol('proto.test.StageCalculations', null, global);
goog.exportSymbol('proto.test.StartTestRequest', null, global);
goog.exportSymbol('proto.test.StaticCalculation', null, global);
goog.exportSymbol('proto.test.StaticCalculationRange', null, global);
goog.exportSymbol('proto.test.StaticCalculationType', null, global);
goog.exportSymbol('proto.test.TestAxis', null, global);
goog.exportSymbol('proto.test.TestResponse', null, global);
goog.exportSymbol('proto.test.TestStage', null, global);
goog.exportSymbol('proto.test.TestStages', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.test.StartTestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.test.StartTestRequest.repeatedFields_, null);
};
goog.inherits(proto.test.StartTestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.test.StartTestRequest.displayName = 'proto.test.StartTestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.test.TestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.test.TestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.test.TestResponse.displayName = 'proto.test.TestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.test.DataLogParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.test.DataLogParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.test.DataLogParams.displayName = 'proto.test.DataLogParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.test.OperateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.test.OperateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.test.OperateRequest.displayName = 'proto.test.OperateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.test.TestStages = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.test.TestStages.repeatedFields_, null);
};
goog.inherits(proto.test.TestStages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.test.TestStages.displayName = 'proto.test.TestStages';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.test.TestStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.test.TestStage.repeatedFields_, null);
};
goog.inherits(proto.test.TestStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.test.TestStage.displayName = 'proto.test.TestStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.test.StageCalculations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.test.StageCalculations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.test.StageCalculations.displayName = 'proto.test.StageCalculations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.test.StaticCalculation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.test.StaticCalculation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.test.StaticCalculation.displayName = 'proto.test.StaticCalculation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.test.PeakLoadCalculation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.test.PeakLoadCalculation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.test.PeakLoadCalculation.displayName = 'proto.test.PeakLoadCalculation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.test.TestAxis = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.test.TestAxis, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.test.TestAxis.displayName = 'proto.test.TestAxis';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.test.Loop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.test.Loop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.test.Loop.displayName = 'proto.test.Loop';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.test.StartTestRequest.repeatedFields_ = [4,9,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.test.StartTestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.test.StartTestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.test.StartTestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.StartTestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    std: jspb.Message.getFieldWithDefault(msg, 3, ""),
    axisIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    timeUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    logParams: (f = msg.getLogParams()) && proto.test.DataLogParams.toObject(includeInstance, f),
    stationId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sequences: (f = msg.getSequences()) && proto.test.TestStages.toObject(includeInstance, f),
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    rule_pb.Rule.toObject, includeInstance),
    varsList: jspb.Message.toObjectList(msg.getVarsList(),
    common_pb.VariableRequest.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    system_pb.MeasurementRequest.toObject, includeInstance),
    specimen: (f = msg.getSpecimen()) && specimen_pb.Specimen.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.test.StartTestRequest}
 */
proto.test.StartTestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.test.StartTestRequest;
  return proto.test.StartTestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.test.StartTestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.test.StartTestRequest}
 */
proto.test.StartTestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAxisIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeUnitName(value);
      break;
    case 6:
      var value = new proto.test.DataLogParams;
      reader.readMessage(value,proto.test.DataLogParams.deserializeBinaryFromReader);
      msg.setLogParams(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationId(value);
      break;
    case 8:
      var value = new proto.test.TestStages;
      reader.readMessage(value,proto.test.TestStages.deserializeBinaryFromReader);
      msg.setSequences(value);
      break;
    case 9:
      var value = new rule_pb.Rule;
      reader.readMessage(value,rule_pb.Rule.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    case 10:
      var value = new common_pb.VariableRequest;
      reader.readMessage(value,common_pb.VariableRequest.deserializeBinaryFromReader);
      msg.addVars(value);
      break;
    case 11:
      var value = new system_pb.MeasurementRequest;
      reader.readMessage(value,system_pb.MeasurementRequest.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 12:
      var value = new specimen_pb.Specimen;
      reader.readMessage(value,specimen_pb.Specimen.deserializeBinaryFromReader);
      msg.setSpecimen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.test.StartTestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.test.StartTestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.test.StartTestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.StartTestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAxisIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getTimeUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLogParams();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.test.DataLogParams.serializeBinaryToWriter
    );
  }
  f = message.getStationId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSequences();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.test.TestStages.serializeBinaryToWriter
    );
  }
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      rule_pb.Rule.serializeBinaryToWriter
    );
  }
  f = message.getVarsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      common_pb.VariableRequest.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      system_pb.MeasurementRequest.serializeBinaryToWriter
    );
  }
  f = message.getSpecimen();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      specimen_pb.Specimen.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.test.StartTestRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.test.StartTestRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string std = 3;
 * @return {string}
 */
proto.test.StartTestRequest.prototype.getStd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.setStd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string axis_ids = 4;
 * @return {!Array<string>}
 */
proto.test.StartTestRequest.prototype.getAxisIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.setAxisIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.addAxisIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.clearAxisIdsList = function() {
  return this.setAxisIdsList([]);
};


/**
 * optional string time_unit_name = 5;
 * @return {string}
 */
proto.test.StartTestRequest.prototype.getTimeUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.setTimeUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional DataLogParams log_params = 6;
 * @return {?proto.test.DataLogParams}
 */
proto.test.StartTestRequest.prototype.getLogParams = function() {
  return /** @type{?proto.test.DataLogParams} */ (
    jspb.Message.getWrapperField(this, proto.test.DataLogParams, 6));
};


/**
 * @param {?proto.test.DataLogParams|undefined} value
 * @return {!proto.test.StartTestRequest} returns this
*/
proto.test.StartTestRequest.prototype.setLogParams = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.clearLogParams = function() {
  return this.setLogParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.test.StartTestRequest.prototype.hasLogParams = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string station_id = 7;
 * @return {string}
 */
proto.test.StartTestRequest.prototype.getStationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.setStationId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional TestStages sequences = 8;
 * @return {?proto.test.TestStages}
 */
proto.test.StartTestRequest.prototype.getSequences = function() {
  return /** @type{?proto.test.TestStages} */ (
    jspb.Message.getWrapperField(this, proto.test.TestStages, 8));
};


/**
 * @param {?proto.test.TestStages|undefined} value
 * @return {!proto.test.StartTestRequest} returns this
*/
proto.test.StartTestRequest.prototype.setSequences = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.clearSequences = function() {
  return this.setSequences(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.test.StartTestRequest.prototype.hasSequences = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated rule.Rule rules = 9;
 * @return {!Array<!proto.rule.Rule>}
 */
proto.test.StartTestRequest.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.rule.Rule>} */ (
    jspb.Message.getRepeatedWrapperField(this, rule_pb.Rule, 9));
};


/**
 * @param {!Array<!proto.rule.Rule>} value
 * @return {!proto.test.StartTestRequest} returns this
*/
proto.test.StartTestRequest.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.rule.Rule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rule.Rule}
 */
proto.test.StartTestRequest.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.rule.Rule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};


/**
 * repeated common.VariableRequest vars = 10;
 * @return {!Array<!proto.common.VariableRequest>}
 */
proto.test.StartTestRequest.prototype.getVarsList = function() {
  return /** @type{!Array<!proto.common.VariableRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.VariableRequest, 10));
};


/**
 * @param {!Array<!proto.common.VariableRequest>} value
 * @return {!proto.test.StartTestRequest} returns this
*/
proto.test.StartTestRequest.prototype.setVarsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.common.VariableRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.VariableRequest}
 */
proto.test.StartTestRequest.prototype.addVars = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.common.VariableRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.clearVarsList = function() {
  return this.setVarsList([]);
};


/**
 * repeated system.MeasurementRequest measurements = 11;
 * @return {!Array<!proto.system.MeasurementRequest>}
 */
proto.test.StartTestRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.system.MeasurementRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, system_pb.MeasurementRequest, 11));
};


/**
 * @param {!Array<!proto.system.MeasurementRequest>} value
 * @return {!proto.test.StartTestRequest} returns this
*/
proto.test.StartTestRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.system.MeasurementRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.system.MeasurementRequest}
 */
proto.test.StartTestRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.system.MeasurementRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * optional specimen.Specimen specimen = 12;
 * @return {?proto.specimen.Specimen}
 */
proto.test.StartTestRequest.prototype.getSpecimen = function() {
  return /** @type{?proto.specimen.Specimen} */ (
    jspb.Message.getWrapperField(this, specimen_pb.Specimen, 12));
};


/**
 * @param {?proto.specimen.Specimen|undefined} value
 * @return {!proto.test.StartTestRequest} returns this
*/
proto.test.StartTestRequest.prototype.setSpecimen = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.test.StartTestRequest} returns this
 */
proto.test.StartTestRequest.prototype.clearSpecimen = function() {
  return this.setSpecimen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.test.StartTestRequest.prototype.hasSpecimen = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.test.TestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.test.TestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.test.TestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.TestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.test.TestResponse}
 */
proto.test.TestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.test.TestResponse;
  return proto.test.TestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.test.TestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.test.TestResponse}
 */
proto.test.TestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.test.TestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.test.TestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.test.TestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.TestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.test.DataLogParams.prototype.toObject = function(opt_includeInstance) {
  return proto.test.DataLogParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.test.DataLogParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.DataLogParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    dsType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dsFullBh: jspb.Message.getFieldWithDefault(msg, 2, 0),
    logPath: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.test.DataLogParams}
 */
proto.test.DataLogParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.test.DataLogParams;
  return proto.test.DataLogParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.test.DataLogParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.test.DataLogParams}
 */
proto.test.DataLogParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.test.DataStorageType} */ (reader.readEnum());
      msg.setDsType(value);
      break;
    case 2:
      var value = /** @type {!proto.test.DataStorageFullBehaviorType} */ (reader.readEnum());
      msg.setDsFullBh(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.test.DataLogParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.test.DataLogParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.test.DataLogParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.DataLogParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDsType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDsFullBh();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLogPath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional DataStorageType ds_type = 1;
 * @return {!proto.test.DataStorageType}
 */
proto.test.DataLogParams.prototype.getDsType = function() {
  return /** @type {!proto.test.DataStorageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.test.DataStorageType} value
 * @return {!proto.test.DataLogParams} returns this
 */
proto.test.DataLogParams.prototype.setDsType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional DataStorageFullBehaviorType ds_full_bh = 2;
 * @return {!proto.test.DataStorageFullBehaviorType}
 */
proto.test.DataLogParams.prototype.getDsFullBh = function() {
  return /** @type {!proto.test.DataStorageFullBehaviorType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.test.DataStorageFullBehaviorType} value
 * @return {!proto.test.DataLogParams} returns this
 */
proto.test.DataLogParams.prototype.setDsFullBh = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string log_path = 3;
 * @return {string}
 */
proto.test.DataLogParams.prototype.getLogPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.DataLogParams} returns this
 */
proto.test.DataLogParams.prototype.setLogPath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.test.OperateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.test.OperateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.test.OperateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.OperateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cmd: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.test.OperateRequest}
 */
proto.test.OperateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.test.OperateRequest;
  return proto.test.OperateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.test.OperateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.test.OperateRequest}
 */
proto.test.OperateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.test.OperationCmd} */ (reader.readEnum());
      msg.setCmd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.test.OperateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.test.OperateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.test.OperateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.OperateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCmd();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.test.OperateRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.OperateRequest} returns this
 */
proto.test.OperateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OperationCmd cmd = 2;
 * @return {!proto.test.OperationCmd}
 */
proto.test.OperateRequest.prototype.getCmd = function() {
  return /** @type {!proto.test.OperationCmd} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.test.OperationCmd} value
 * @return {!proto.test.OperateRequest} returns this
 */
proto.test.OperateRequest.prototype.setCmd = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.test.TestStages.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.test.TestStages.prototype.toObject = function(opt_includeInstance) {
  return proto.test.TestStages.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.test.TestStages} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.TestStages.toObject = function(includeInstance, msg) {
  var f, obj = {
    stagesList: jspb.Message.toObjectList(msg.getStagesList(),
    proto.test.TestStage.toObject, includeInstance),
    level1LoopsList: jspb.Message.toObjectList(msg.getLevel1LoopsList(),
    proto.test.Loop.toObject, includeInstance),
    level2LoopsList: jspb.Message.toObjectList(msg.getLevel2LoopsList(),
    proto.test.Loop.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.test.TestStages}
 */
proto.test.TestStages.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.test.TestStages;
  return proto.test.TestStages.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.test.TestStages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.test.TestStages}
 */
proto.test.TestStages.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.test.TestStage;
      reader.readMessage(value,proto.test.TestStage.deserializeBinaryFromReader);
      msg.addStages(value);
      break;
    case 2:
      var value = new proto.test.Loop;
      reader.readMessage(value,proto.test.Loop.deserializeBinaryFromReader);
      msg.addLevel1Loops(value);
      break;
    case 3:
      var value = new proto.test.Loop;
      reader.readMessage(value,proto.test.Loop.deserializeBinaryFromReader);
      msg.addLevel2Loops(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.test.TestStages.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.test.TestStages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.test.TestStages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.TestStages.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.test.TestStage.serializeBinaryToWriter
    );
  }
  f = message.getLevel1LoopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.test.Loop.serializeBinaryToWriter
    );
  }
  f = message.getLevel2LoopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.test.Loop.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TestStage stages = 1;
 * @return {!Array<!proto.test.TestStage>}
 */
proto.test.TestStages.prototype.getStagesList = function() {
  return /** @type{!Array<!proto.test.TestStage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.test.TestStage, 1));
};


/**
 * @param {!Array<!proto.test.TestStage>} value
 * @return {!proto.test.TestStages} returns this
*/
proto.test.TestStages.prototype.setStagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.test.TestStage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.test.TestStage}
 */
proto.test.TestStages.prototype.addStages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.test.TestStage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.test.TestStages} returns this
 */
proto.test.TestStages.prototype.clearStagesList = function() {
  return this.setStagesList([]);
};


/**
 * repeated Loop level1_loops = 2;
 * @return {!Array<!proto.test.Loop>}
 */
proto.test.TestStages.prototype.getLevel1LoopsList = function() {
  return /** @type{!Array<!proto.test.Loop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.test.Loop, 2));
};


/**
 * @param {!Array<!proto.test.Loop>} value
 * @return {!proto.test.TestStages} returns this
*/
proto.test.TestStages.prototype.setLevel1LoopsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.test.Loop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.test.Loop}
 */
proto.test.TestStages.prototype.addLevel1Loops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.test.Loop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.test.TestStages} returns this
 */
proto.test.TestStages.prototype.clearLevel1LoopsList = function() {
  return this.setLevel1LoopsList([]);
};


/**
 * repeated Loop level2_loops = 3;
 * @return {!Array<!proto.test.Loop>}
 */
proto.test.TestStages.prototype.getLevel2LoopsList = function() {
  return /** @type{!Array<!proto.test.Loop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.test.Loop, 3));
};


/**
 * @param {!Array<!proto.test.Loop>} value
 * @return {!proto.test.TestStages} returns this
*/
proto.test.TestStages.prototype.setLevel2LoopsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.test.Loop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.test.Loop}
 */
proto.test.TestStages.prototype.addLevel2Loops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.test.Loop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.test.TestStages} returns this
 */
proto.test.TestStages.prototype.clearLevel2LoopsList = function() {
  return this.setLevel2LoopsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.test.TestStage.repeatedFields_ = [3,4,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.test.TestStage.prototype.toObject = function(opt_includeInstance) {
  return proto.test.TestStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.test.TestStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.TestStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    axesList: jspb.Message.toObjectList(msg.getAxesList(),
    proto.test.TestAxis.toObject, includeInstance),
    logsList: jspb.Message.toObjectList(msg.getLogsList(),
    data_flow_pb.AxisLog.toObject, includeInstance),
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    rule_pb.Rule.toObject, includeInstance),
    preActionsList: jspb.Message.toObjectList(msg.getPreActionsList(),
    rule_pb.Rule.toObject, includeInstance),
    postActionsList: jspb.Message.toObjectList(msg.getPostActionsList(),
    rule_pb.Rule.toObject, includeInstance),
    calculationsList: jspb.Message.toObjectList(msg.getCalculationsList(),
    proto.test.StageCalculations.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.test.TestStage}
 */
proto.test.TestStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.test.TestStage;
  return proto.test.TestStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.test.TestStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.test.TestStage}
 */
proto.test.TestStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.test.TestAxis;
      reader.readMessage(value,proto.test.TestAxis.deserializeBinaryFromReader);
      msg.addAxes(value);
      break;
    case 4:
      var value = new data_flow_pb.AxisLog;
      reader.readMessage(value,data_flow_pb.AxisLog.deserializeBinaryFromReader);
      msg.addLogs(value);
      break;
    case 5:
      var value = new rule_pb.Rule;
      reader.readMessage(value,rule_pb.Rule.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    case 6:
      var value = new rule_pb.Rule;
      reader.readMessage(value,rule_pb.Rule.deserializeBinaryFromReader);
      msg.addPreActions(value);
      break;
    case 7:
      var value = new rule_pb.Rule;
      reader.readMessage(value,rule_pb.Rule.deserializeBinaryFromReader);
      msg.addPostActions(value);
      break;
    case 8:
      var value = new proto.test.StageCalculations;
      reader.readMessage(value,proto.test.StageCalculations.deserializeBinaryFromReader);
      msg.addCalculations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.test.TestStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.test.TestStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.test.TestStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.TestStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAxesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.test.TestAxis.serializeBinaryToWriter
    );
  }
  f = message.getLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      data_flow_pb.AxisLog.serializeBinaryToWriter
    );
  }
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      rule_pb.Rule.serializeBinaryToWriter
    );
  }
  f = message.getPreActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      rule_pb.Rule.serializeBinaryToWriter
    );
  }
  f = message.getPostActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      rule_pb.Rule.serializeBinaryToWriter
    );
  }
  f = message.getCalculationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.test.StageCalculations.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.test.TestStage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.TestStage} returns this
 */
proto.test.TestStage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.test.TestStage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.TestStage} returns this
 */
proto.test.TestStage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated TestAxis axes = 3;
 * @return {!Array<!proto.test.TestAxis>}
 */
proto.test.TestStage.prototype.getAxesList = function() {
  return /** @type{!Array<!proto.test.TestAxis>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.test.TestAxis, 3));
};


/**
 * @param {!Array<!proto.test.TestAxis>} value
 * @return {!proto.test.TestStage} returns this
*/
proto.test.TestStage.prototype.setAxesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.test.TestAxis=} opt_value
 * @param {number=} opt_index
 * @return {!proto.test.TestAxis}
 */
proto.test.TestStage.prototype.addAxes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.test.TestAxis, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.test.TestStage} returns this
 */
proto.test.TestStage.prototype.clearAxesList = function() {
  return this.setAxesList([]);
};


/**
 * repeated dataflow.AxisLog logs = 4;
 * @return {!Array<!proto.dataflow.AxisLog>}
 */
proto.test.TestStage.prototype.getLogsList = function() {
  return /** @type{!Array<!proto.dataflow.AxisLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, data_flow_pb.AxisLog, 4));
};


/**
 * @param {!Array<!proto.dataflow.AxisLog>} value
 * @return {!proto.test.TestStage} returns this
*/
proto.test.TestStage.prototype.setLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dataflow.AxisLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataflow.AxisLog}
 */
proto.test.TestStage.prototype.addLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dataflow.AxisLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.test.TestStage} returns this
 */
proto.test.TestStage.prototype.clearLogsList = function() {
  return this.setLogsList([]);
};


/**
 * repeated rule.Rule rules = 5;
 * @return {!Array<!proto.rule.Rule>}
 */
proto.test.TestStage.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.rule.Rule>} */ (
    jspb.Message.getRepeatedWrapperField(this, rule_pb.Rule, 5));
};


/**
 * @param {!Array<!proto.rule.Rule>} value
 * @return {!proto.test.TestStage} returns this
*/
proto.test.TestStage.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.rule.Rule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rule.Rule}
 */
proto.test.TestStage.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.rule.Rule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.test.TestStage} returns this
 */
proto.test.TestStage.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};


/**
 * repeated rule.Rule pre_actions = 6;
 * @return {!Array<!proto.rule.Rule>}
 */
proto.test.TestStage.prototype.getPreActionsList = function() {
  return /** @type{!Array<!proto.rule.Rule>} */ (
    jspb.Message.getRepeatedWrapperField(this, rule_pb.Rule, 6));
};


/**
 * @param {!Array<!proto.rule.Rule>} value
 * @return {!proto.test.TestStage} returns this
*/
proto.test.TestStage.prototype.setPreActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.rule.Rule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rule.Rule}
 */
proto.test.TestStage.prototype.addPreActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.rule.Rule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.test.TestStage} returns this
 */
proto.test.TestStage.prototype.clearPreActionsList = function() {
  return this.setPreActionsList([]);
};


/**
 * repeated rule.Rule post_actions = 7;
 * @return {!Array<!proto.rule.Rule>}
 */
proto.test.TestStage.prototype.getPostActionsList = function() {
  return /** @type{!Array<!proto.rule.Rule>} */ (
    jspb.Message.getRepeatedWrapperField(this, rule_pb.Rule, 7));
};


/**
 * @param {!Array<!proto.rule.Rule>} value
 * @return {!proto.test.TestStage} returns this
*/
proto.test.TestStage.prototype.setPostActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.rule.Rule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rule.Rule}
 */
proto.test.TestStage.prototype.addPostActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.rule.Rule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.test.TestStage} returns this
 */
proto.test.TestStage.prototype.clearPostActionsList = function() {
  return this.setPostActionsList([]);
};


/**
 * repeated StageCalculations calculations = 8;
 * @return {!Array<!proto.test.StageCalculations>}
 */
proto.test.TestStage.prototype.getCalculationsList = function() {
  return /** @type{!Array<!proto.test.StageCalculations>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.test.StageCalculations, 8));
};


/**
 * @param {!Array<!proto.test.StageCalculations>} value
 * @return {!proto.test.TestStage} returns this
*/
proto.test.TestStage.prototype.setCalculationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.test.StageCalculations=} opt_value
 * @param {number=} opt_index
 * @return {!proto.test.StageCalculations}
 */
proto.test.TestStage.prototype.addCalculations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.test.StageCalculations, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.test.TestStage} returns this
 */
proto.test.TestStage.prototype.clearCalculationsList = function() {
  return this.setCalculationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.test.StageCalculations.prototype.toObject = function(opt_includeInstance) {
  return proto.test.StageCalculations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.test.StageCalculations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.StageCalculations.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stageCalcType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 3, ""),
    staticCalc: (f = msg.getStaticCalc()) && proto.test.StaticCalculation.toObject(includeInstance, f),
    peakLoadCalc: (f = msg.getPeakLoadCalc()) && proto.test.PeakLoadCalculation.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.test.StageCalculations}
 */
proto.test.StageCalculations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.test.StageCalculations;
  return proto.test.StageCalculations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.test.StageCalculations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.test.StageCalculations}
 */
proto.test.StageCalculations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.test.StageCalculationType} */ (reader.readEnum());
      msg.setStageCalcType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 4:
      var value = new proto.test.StaticCalculation;
      reader.readMessage(value,proto.test.StaticCalculation.deserializeBinaryFromReader);
      msg.setStaticCalc(value);
      break;
    case 5:
      var value = new proto.test.PeakLoadCalculation;
      reader.readMessage(value,proto.test.PeakLoadCalculation.deserializeBinaryFromReader);
      msg.setPeakLoadCalc(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.test.StageCalculations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.test.StageCalculations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.test.StageCalculations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.StageCalculations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStageCalcType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStaticCalc();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.test.StaticCalculation.serializeBinaryToWriter
    );
  }
  f = message.getPeakLoadCalc();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.test.PeakLoadCalculation.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.test.StageCalculations.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.StageCalculations} returns this
 */
proto.test.StageCalculations.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StageCalculationType stage_calc_type = 2;
 * @return {!proto.test.StageCalculationType}
 */
proto.test.StageCalculations.prototype.getStageCalcType = function() {
  return /** @type {!proto.test.StageCalculationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.test.StageCalculationType} value
 * @return {!proto.test.StageCalculations} returns this
 */
proto.test.StageCalculations.prototype.setStageCalcType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string unit = 3;
 * @return {string}
 */
proto.test.StageCalculations.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.StageCalculations} returns this
 */
proto.test.StageCalculations.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional StaticCalculation static_calc = 4;
 * @return {?proto.test.StaticCalculation}
 */
proto.test.StageCalculations.prototype.getStaticCalc = function() {
  return /** @type{?proto.test.StaticCalculation} */ (
    jspb.Message.getWrapperField(this, proto.test.StaticCalculation, 4));
};


/**
 * @param {?proto.test.StaticCalculation|undefined} value
 * @return {!proto.test.StageCalculations} returns this
*/
proto.test.StageCalculations.prototype.setStaticCalc = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.test.StageCalculations} returns this
 */
proto.test.StageCalculations.prototype.clearStaticCalc = function() {
  return this.setStaticCalc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.test.StageCalculations.prototype.hasStaticCalc = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PeakLoadCalculation peak_load_calc = 5;
 * @return {?proto.test.PeakLoadCalculation}
 */
proto.test.StageCalculations.prototype.getPeakLoadCalc = function() {
  return /** @type{?proto.test.PeakLoadCalculation} */ (
    jspb.Message.getWrapperField(this, proto.test.PeakLoadCalculation, 5));
};


/**
 * @param {?proto.test.PeakLoadCalculation|undefined} value
 * @return {!proto.test.StageCalculations} returns this
*/
proto.test.StageCalculations.prototype.setPeakLoadCalc = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.test.StageCalculations} returns this
 */
proto.test.StageCalculations.prototype.clearPeakLoadCalc = function() {
  return this.setPeakLoadCalc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.test.StageCalculations.prototype.hasPeakLoadCalc = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string id = 6;
 * @return {string}
 */
proto.test.StageCalculations.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.StageCalculations} returns this
 */
proto.test.StageCalculations.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.test.StaticCalculation.prototype.toObject = function(opt_includeInstance) {
  return proto.test.StaticCalculation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.test.StaticCalculation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.StaticCalculation.toObject = function(includeInstance, msg) {
  var f, obj = {
    calcType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    measChannelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    range: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.test.StaticCalculation}
 */
proto.test.StaticCalculation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.test.StaticCalculation;
  return proto.test.StaticCalculation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.test.StaticCalculation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.test.StaticCalculation}
 */
proto.test.StaticCalculation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.test.StaticCalculationType} */ (reader.readEnum());
      msg.setCalcType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasChannelId(value);
      break;
    case 3:
      var value = /** @type {!proto.test.StaticCalculationRange} */ (reader.readEnum());
      msg.setRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.test.StaticCalculation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.test.StaticCalculation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.test.StaticCalculation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.StaticCalculation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalcType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMeasChannelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional StaticCalculationType calc_type = 1;
 * @return {!proto.test.StaticCalculationType}
 */
proto.test.StaticCalculation.prototype.getCalcType = function() {
  return /** @type {!proto.test.StaticCalculationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.test.StaticCalculationType} value
 * @return {!proto.test.StaticCalculation} returns this
 */
proto.test.StaticCalculation.prototype.setCalcType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string meas_channel_id = 2;
 * @return {string}
 */
proto.test.StaticCalculation.prototype.getMeasChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.StaticCalculation} returns this
 */
proto.test.StaticCalculation.prototype.setMeasChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional StaticCalculationRange range = 3;
 * @return {!proto.test.StaticCalculationRange}
 */
proto.test.StaticCalculation.prototype.getRange = function() {
  return /** @type {!proto.test.StaticCalculationRange} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.test.StaticCalculationRange} value
 * @return {!proto.test.StaticCalculation} returns this
 */
proto.test.StaticCalculation.prototype.setRange = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.test.PeakLoadCalculation.prototype.toObject = function(opt_includeInstance) {
  return proto.test.PeakLoadCalculation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.test.PeakLoadCalculation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.PeakLoadCalculation.toObject = function(includeInstance, msg) {
  var f, obj = {
    measChannelId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    refChannelId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.test.PeakLoadCalculation}
 */
proto.test.PeakLoadCalculation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.test.PeakLoadCalculation;
  return proto.test.PeakLoadCalculation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.test.PeakLoadCalculation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.test.PeakLoadCalculation}
 */
proto.test.PeakLoadCalculation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasChannelId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefChannelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.test.PeakLoadCalculation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.test.PeakLoadCalculation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.test.PeakLoadCalculation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.PeakLoadCalculation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeasChannelId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRefChannelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string meas_channel_id = 3;
 * @return {string}
 */
proto.test.PeakLoadCalculation.prototype.getMeasChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.PeakLoadCalculation} returns this
 */
proto.test.PeakLoadCalculation.prototype.setMeasChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ref_channel_id = 4;
 * @return {string}
 */
proto.test.PeakLoadCalculation.prototype.getRefChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.PeakLoadCalculation} returns this
 */
proto.test.PeakLoadCalculation.prototype.setRefChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.test.TestAxis.prototype.toObject = function(opt_includeInstance) {
  return proto.test.TestAxis.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.test.TestAxis} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.TestAxis.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    axes: (f = msg.getAxes()) && common_pb.AxisInfo.toObject(includeInstance, f),
    complStageOnAxisCompl: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.test.TestAxis}
 */
proto.test.TestAxis.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.test.TestAxis;
  return proto.test.TestAxis.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.test.TestAxis} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.test.TestAxis}
 */
proto.test.TestAxis.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    case 2:
      var value = new common_pb.AxisInfo;
      reader.readMessage(value,common_pb.AxisInfo.deserializeBinaryFromReader);
      msg.setAxes(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setComplStageOnAxisCompl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.test.TestAxis.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.test.TestAxis.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.test.TestAxis} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.TestAxis.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAxisId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAxes();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.AxisInfo.serializeBinaryToWriter
    );
  }
  f = message.getComplStageOnAxisCompl();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string axis_id = 1;
 * @return {string}
 */
proto.test.TestAxis.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.test.TestAxis} returns this
 */
proto.test.TestAxis.prototype.setAxisId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.AxisInfo axes = 2;
 * @return {?proto.common.AxisInfo}
 */
proto.test.TestAxis.prototype.getAxes = function() {
  return /** @type{?proto.common.AxisInfo} */ (
    jspb.Message.getWrapperField(this, common_pb.AxisInfo, 2));
};


/**
 * @param {?proto.common.AxisInfo|undefined} value
 * @return {!proto.test.TestAxis} returns this
*/
proto.test.TestAxis.prototype.setAxes = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.test.TestAxis} returns this
 */
proto.test.TestAxis.prototype.clearAxes = function() {
  return this.setAxes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.test.TestAxis.prototype.hasAxes = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool compl_stage_on_axis_compl = 3;
 * @return {boolean}
 */
proto.test.TestAxis.prototype.getComplStageOnAxisCompl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.test.TestAxis} returns this
 */
proto.test.TestAxis.prototype.setComplStageOnAxisCompl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.test.Loop.prototype.toObject = function(opt_includeInstance) {
  return proto.test.Loop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.test.Loop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.Loop.toObject = function(includeInstance, msg) {
  var f, obj = {
    startIdx: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endIdx: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rpt: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.test.Loop}
 */
proto.test.Loop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.test.Loop;
  return proto.test.Loop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.test.Loop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.test.Loop}
 */
proto.test.Loop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartIdx(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndIdx(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRpt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.test.Loop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.test.Loop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.test.Loop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.test.Loop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartIdx();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEndIdx();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRpt();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 start_idx = 1;
 * @return {number}
 */
proto.test.Loop.prototype.getStartIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.test.Loop} returns this
 */
proto.test.Loop.prototype.setStartIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 end_idx = 2;
 * @return {number}
 */
proto.test.Loop.prototype.getEndIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.test.Loop} returns this
 */
proto.test.Loop.prototype.setEndIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 rpt = 3;
 * @return {number}
 */
proto.test.Loop.prototype.getRpt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.test.Loop} returns this
 */
proto.test.Loop.prototype.setRpt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.test.StageCalculationType = {
  STATIC: 0,
  PEAK_LOAD: 1
};

/**
 * @enum {number}
 */
proto.test.StaticCalculationType = {
  MIN: 0,
  MAX: 1,
  MEAN: 2
};

/**
 * @enum {number}
 */
proto.test.StaticCalculationRange = {
  WHOLE: 0,
  CUSTOM: 1
};

/**
 * @enum {number}
 */
proto.test.DataStorageType = {
  DATA_STORAGE_SD_CARD: 0,
  DATA_STORAGE_USB: 1,
  UNKNOWN: 2
};

/**
 * @enum {number}
 */
proto.test.DataStorageFullBehaviorType = {
  DATA_STORAGE_FULL_CONTINUE: 0,
  DATA_STORAGE_FULL_PAUSE: 1,
  DATA_STORAGE_FULL_OVERWRITE: 2,
  DATA_STORAGE_FULL_TERMINATE: 3
};

/**
 * @enum {number}
 */
proto.test.OperationCmd = {
  TEST_OP_STOP: 0,
  TEST_OP_PAUSE: 1,
  TEST_OP_RESUME: 2
};

goog.object.extend(exports, proto.test);
