import * as yup from 'yup';
import { alphaNumericRegex } from '../../constants/regexes';

export const groupSchema = yup
	.object({
		name: yup.string().required().max(30).matches(alphaNumericRegex, 'Is not in correct format'),
		type: yup.string().required(),
		id: yup.string(),
		itemIds: yup.array().of(yup.string().required())
	})
	.required();
