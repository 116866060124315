import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as api from '../controllers.api';
import Controllers from '../components/Controllers';

const ControllersContainer: React.FC = () => {
	const navigate = useNavigate();
	const { controllerId } = useParams();
	const isCreate = !controllerId;

	const form = useForm<Record<string, string>>({
		mode: 'onBlur'
	});
	const { handleSubmit, reset } = form;

	const { data } = useQuery<Record<string, string>, Error>({
		queryKey: ['controller', { controllerId }],
		queryFn: async () => {
			const resp = await api.getController(controllerId as string);
			return resp;
		},
		enabled: !!controllerId
	});

	const handleBack = useCallback(() => {
		navigate('/controllers');
	}, [navigate]);

	const handleSave = handleSubmit((data) => {
		if (controllerId) {
			//Update
		} else {
			//Create
		}
	});

	useEffect(() => {
		if (controllerId) {
			reset({ ...data }, { keepDirty: true, keepTouched: true });
		}
	}, [controllerId, data, reset]);

	return <Controllers isCreate={isCreate} isLoading={false} form={form} onBack={handleBack} onSave={handleSave} />;
};

export default React.memo(ControllersContainer);
