import React, { useState } from 'react';
import cx from 'classnames';
import styles from './GraphSidebar.module.scss';

interface IGraphSidebarProps {
	children: React.ReactNode;
	onCreatePlot: () => void;
}

const GraphSidebar = ({ children, onCreatePlot }: IGraphSidebarProps) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(true);

	return (
		<>
			<div onClick={() => setIsSidebarOpen((prev) => !prev)} className={`${styles.toggleTab} ${styles.openButton}`} />
			<div
				className={cx(styles.container, { [styles.sidebarClosed]: !isSidebarOpen })}
				data-sidebar-state={isSidebarOpen}
				data-element="sidebar"
			>
				<div
					onClick={() => setIsSidebarOpen((prev) => !prev)}
					className={`${styles.toggleTab} ${styles.closeButton}`}
				/>
				<div className={styles.sidebarClosed} />
				<div className={styles.legendLabel}>Legend</div>
				<div className={styles.legendPanelContainer}>
					<div className={styles.legendPanelContent}>{children}</div>
					<div className={styles.createPlot}>
						<div className={styles.iconPlus}></div>
						<button onClick={onCreatePlot}>Create new plot</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default React.memo(GraphSidebar);
