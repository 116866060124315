import { useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
	buildProduct,
	getDownloadUrl,
	getProduct,
	getProducts,
	IProductDownloadUrl,
	IProductRequestDto,
	IProductResponseDto,
	productDtoToListItems,
	productFormToRequest,
	ProductFormType,
	updateProduct
} from './';
import { linkDevice } from './products.grpc';
import { LinkResult, LinkResultMap } from '@tactun/grpc-client';
import { getKeyByValue } from '../../tools/grpcHelper';
import { useKeycloakContext } from '@tactun/keycloak-axios-provider';
import { downloadURL } from '../../tools/common';

export const useProduct = (productId?: string) => {
	const { data: productDto, isLoading } = useQuery<IProductResponseDto, Error>({
		queryKey: ['products', { productId }],
		queryFn: async () => await getProduct(productId as string),
		enabled: !!productId
	});

	const queryClient = useQueryClient();

	const updateAxis = () => {
		queryClient.invalidateQueries({ queryKey: ['products', { productId }] });
	};

	return {
		productDto,
		isLoading,
		updateAxis
	};
};

export const useProducts = () => {
	const { data: products = [], isLoading } = useQuery({
		queryKey: ['products'],
		queryFn: () => getProducts()
	});

	const queryClient = useQueryClient();

	const updateProduct = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: ['products'] });
	}, [queryClient]);

	const deleteProduct = useCallback(
		(id: string) => {
			queryClient.setQueryData<IProductResponseDto[]>(['products'], (old) => {
				if (!old) return undefined;
				return old?.filter((st) => st.uuid !== id);
			});
		},
		[queryClient]
	);

	return {
		products: products.map((p) => productDtoToListItems(p)),
		isLoading,
		updateProduct,
		deleteProduct
	};
};

export const useSaveProduct = (productId?: string, onSaveFinished?: (isSuccess: boolean) => void) => {
	const [isLinking, setIsLinking] = useState(false);
	const keycloakContext = useKeycloakContext();

	const updateMutation = useMutation({
		mutationFn: (product: IProductRequestDto) => updateProduct(product),
		onSuccess: () => {
			if (onSaveFinished) onSaveFinished(true);
		},
		onError: (e: Error) => {
			if (onSaveFinished) onSaveFinished(false);
			toast.error(e.message);
		}
	});

	const save = useCallback(
		async (product: ProductFormType) => {
			const productRequest = productFormToRequest(product);
			if (productId) {
				updateMutation.mutate(productRequest);
			} else {
				setIsLinking(true);
				const linkResult = await linkDevice(
					product,
					process.env.REACT_APP_API_BASE_URL as string,
					keycloakContext?.keycloak?.token as string
				);
				setIsLinking(false);
				if (linkResult.status === LinkResult.LINK_SUCCESS) {
					if (onSaveFinished) onSaveFinished(true);
				} else {
					if (onSaveFinished) onSaveFinished(false);
					toast.error(`${getKeyByValue<LinkResultMap>(LinkResult, linkResult.status)} ${linkResult.error}`);
				}
			}
		},
		[keycloakContext?.keycloak?.token, onSaveFinished, productId, updateMutation]
	);

	return {
		save,
		isLoading: updateMutation.isPending || isLinking
	};
};

export const useBuildProduct = () => {
	const queryClient = useQueryClient();

	const buildMutation = useMutation({
		mutationFn: (productId: string) => buildProduct(productId),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['products'] });
		},
		onError: (e: Error) => {
			toast.error(e.message);
		}
	});

	const build = useCallback(
		(productId: string) => {
			buildMutation.mutate(productId);
		},
		[buildMutation]
	);

	return {
		build,
		isLoading: buildMutation.isPending
	};
};

export const useDownloadProduct = () => {
	const getUrlMutation = useMutation({
		mutationFn: (productId: string) => getDownloadUrl(productId),
		onSuccess: (productInfo: IProductDownloadUrl, productId) => {
			downloadURL(productInfo.downloadUrl, `Tactun_${productId}.exe`);
		},
		onError: (e: Error) => {
			toast.error(e.message);
		}
	});

	const download = useCallback(
		(productId: string) => {
			getUrlMutation.mutate(productId);
		},
		[getUrlMutation]
	);

	return {
		download,
		isDownloading: getUrlMutation.isPending
	};
};
