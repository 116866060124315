import React, { FC, useCallback, useMemo } from 'react';
import { IconButton } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { SensorListItem } from '../../sensors.types';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { CalibrationStatus, SensorActionTypes } from '../../sensors.enums';
import { useTableConfigs } from '../../../../hooks';
import { Tag } from 'primereact/tag';
import { useIsLocalDeployment } from '../../../../hooks/useIsLocalDeployment';
import styles from './SensorsTable.module.scss';

interface ISensorsTableProps {
	onAction: (type: SensorActionTypes, data?: SensorListItem) => void;
	data?: SensorListItem[];
	isLoading: boolean;
	stationName: string;
}

const SensorsTable: FC<ISensorsTableProps> = ({ data, isLoading, stationName, onAction }) => {
	const { t } = useTranslation(['sensors', 'common']);
	const isDisabled = useIsLocalDeployment();

	const formattedData = useMemo(
		() => data?.map((item) => ({ ...item, type: t(item.type, { ns: 'common' }) })),
		[data, t]
	);

	const transColumns = useMemo<ITableColumns<SensorListItem>[]>(() => {
		return [
			{
				field: 'type',
				header: t('Sensor type')
			},
			{ field: 'stationChannelName', header: t('Channel') },
			{
				field: 'calibrationStatus',
				header: t('Calibration status'),
				body: (sensor: SensorListItem) => (
					<Tag
						className={
							sensor.calibrationStatus === CalibrationStatus.CALIBRATED ? styles.calibrated : styles.uncalibrated
						}
						severity="success"
						value={sensor.calibrationStatus === CalibrationStatus.CALIBRATED ? t('Calibrated') : t('Uncalibrated')}
						rounded
					/>
				)
			}
		];
	}, [t]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<SensorListItem>(transColumns, formattedData);

	const createMenuItems = useCallback(
		(data: SensorListItem) => [
			{
				label: t('Duplicate'),
				disabled: true,
				icon: 't-icon-duplicate',
				command: () => onAction(SensorActionTypes.DUPLICATE, data)
			},
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(SensorActionTypes.DELETE, data),
				disabled: isDisabled
			}
		],
		[t, isDisabled, onAction]
	);

	const handleCreate = useCallback(() => {
		onAction(SensorActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={stationName}>
			<PageLayout.Header title={t('Sensors')}>
				<CrudActions isCreateDisabled={isDisabled} onCreate={handleCreate} entityLabel={t('Sensor')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={formattedData}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Sensor name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(SensorActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="calibrate"
							fontSize="1.2rem"
							title={t('Calibrate')}
							onClick={() => onAction(SensorActionTypes.OPEN_CALIBRATION, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default React.memo(SensorsTable);
