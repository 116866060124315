import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from '@tactun/ui';
import Unit from '../components/Unit';
import EditUnitModal from '../components/EditUnitModal';
import * as api from '../units.api';
import { useOnce } from '../../../hooks';
import { deleteEmptyFields } from '../../../tools';
import { UnitSystemTypes } from '../units.enums';
import { quantityFormSchema, unitFormSchema } from '../units.schemas';
import { formRequestConverter, responseFormConverter } from '../units.converters';
import { QuantityFormType, QuantityRequestDto, UnitFormType, QuantityResponseDto } from '../units.types';
import { useAllUnitSystemsOptions } from '../units.hooks';
import EntityCheckBeforeDeleteContainer from '../../EntityDeleter/containers/EntityCheckBeforeDeleteContainer';
import { EntityTypes, IEntity } from '../../EntityDeleter';

const defaultQuantity = {
	name: '',
	units: []
};

const defaultUnit = {
	name: '',
	scale: 0,
	offset: 0,
	systemType: UnitSystemTypes.METRIC,
	isDefault: false
};

const UnitContainer: React.FC = () => {
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<UnitFormType | undefined>();
	const { quantityId } = useParams();
	const [unitForEdit, setUnitForEdit] = useState<UnitFormType>();

	const isCreate = !quantityId;

	const form = useForm<QuantityFormType>({
		defaultValues: { ...defaultQuantity },
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(quantityFormSchema)
	});
	const unitForm = useForm<UnitFormType>({
		defaultValues: { ...defaultUnit, parentId: quantityId },
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(unitFormSchema)
	});
	const { fields, append, update, remove } = useFieldArray({ name: 'units', control: form.control, keyName: 'tmpId' });

	const { handleSubmit, reset } = form;
	const { setError: setUnitError, handleSubmit: handleUnitSubmit, reset: resetUnit } = unitForm;

	const { data: currentUnitDto } = useQuery<QuantityResponseDto>({
		queryKey: ['quantity', { unitId: quantityId }],
		// When we provide enable option - !!unitId, the unitId is guaranteed
		queryFn: () => api.getQuantity(quantityId as string),
		enabled: !!quantityId
	});

	const handleBack = useCallback(() => {
		navigate('/components-settings/units');
	}, [navigate]);

	const createMutation = useMutation({
		mutationFn: (quantity: QuantityRequestDto) => api.createQuantity(quantity),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { quantity: QuantityRequestDto; unitId: string }) =>
			api.updateQuantity(data.quantity, data.unitId),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const handleReset = useCallback(() => {
		if (currentUnitDto) {
			reset({ ...responseFormConverter(currentUnitDto) }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultQuantity }, { keepDirty: true, keepTouched: true });
		}
	}, [currentUnitDto, reset]);

	const handleSave = handleSubmit((data) => {
		const filteredData = deleteEmptyFields<QuantityRequestDto>(formRequestConverter(data));

		if (quantityId) {
			updateMutation.mutate({
				quantity: filteredData,
				unitId: quantityId
			});
		} else {
			createMutation.mutate(filteredData);
		}
	});

	const initTrigger = useOnce(!!currentUnitDto);

	// Setup data for edit
	useEffect(() => {
		if (initTrigger) {
			handleReset();
		}
	}, [initTrigger, handleReset]);

	const handleAddUnit = useCallback(() => {
		handleUnitSubmit((unit) => {
			const isNameUnique = !quantityId ? !fields.some((f) => f.name === unit.name) : true;
			if (isNameUnique) {
				const allUnitsWithSpecificsType = fields.filter(
					(existing) => existing.systemType === unit.systemType && existing.isDefault
				);

				unit.isDefault = allUnitsWithSpecificsType.length === 0;

				append(unit);

				resetUnit({ ...defaultUnit, systemType: unit.systemType });
			} else {
				setUnitError('name', { message: 'Unit name should be unique' });
			}
		})();
	}, [append, fields, handleUnitSubmit, quantityId, resetUnit, setUnitError]);

	const makeFirstDefault = useCallback(
		(type: UnitSystemTypes, excludeId?: string) => {
			const current = fields.find((f) => f.systemType === type && f.tmpId !== excludeId);
			if (current) {
				current.isDefault = true;
				update(fields.indexOf(current), current);
			}
		},
		[fields, update]
	);

	const makeCurrentDefaultNotDefault = useCallback(
		(type: UnitSystemTypes) => {
			const currentDefault = fields.find((f) => f.systemType === type && f.isDefault);
			if (currentDefault) {
				currentDefault.isDefault = false;
				update(fields.indexOf(currentDefault), currentDefault);
			}
		},
		[fields, update]
	);

	const handleDeleteUnit = useCallback((unit: UnitFormType) => {
		setEntityForDelete(unit);
	}, []);

	const handleInitEditUnit = useCallback((unit: UnitFormType) => {
		setUnitForEdit(unit);
		Modal.show('editUnitModal');
	}, []);

	const handleSaveEditedUnit = useCallback(
		(unit: UnitFormType) => {
			const current = fields.find((f) => f.tmpId === unit.tmpId);
			//Make First unit with the same systemType default
			if (current?.isDefault && !unit.isDefault) {
				makeFirstDefault(unit.systemType, current.tmpId);
			}
			//Make default not default
			if (!current?.isDefault && unit.isDefault) {
				makeCurrentDefaultNotDefault(unit.systemType);
			}

			// Manage default, if default system type changed
			if (current?.systemType !== unit.systemType && current?.isDefault) {
				makeFirstDefault(current.systemType, current.tmpId);
				if (unit.isDefault) {
					makeCurrentDefaultNotDefault(unit.systemType);
				}
			}

			if (current) {
				update(fields.indexOf(current), unit);
			}
		},
		[fields, makeCurrentDefaultNotDefault, makeFirstDefault, update]
	);

	const handleDefaultChange = useCallback(
		(unit: UnitFormType) => {
			// make current default not default
			makeCurrentDefaultNotDefault(unit.systemType);
			// make selected default
			const current = fields.find((f) => f.tmpId === unit.tmpId);
			if (current) {
				current.isDefault = true;
				update(fields.indexOf(current), current);
			}
		},
		[fields, makeCurrentDefaultNotDefault, update]
	);

	const handleDeleteCanceled = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleteChecked = useCallback(
		(canBeDeleted: boolean) => {
			if (canBeDeleted && entityForDelete) {
				if (entityForDelete.isDefault) {
					makeFirstDefault(entityForDelete.systemType, entityForDelete.tmpId);
				}
				const index = fields.findIndex((f) => f.tmpId === entityForDelete.tmpId);
				remove(index);
			} else {
				setEntityForDelete(undefined);
			}
		},
		[entityForDelete, fields, makeFirstDefault, remove]
	);

	const units = useMemo(() => fields.map((u: UnitFormType) => ({ ...u })), [fields]);

	const unitSystems = useAllUnitSystemsOptions();

	return (
		<>
			<Unit
				isCreate={isCreate}
				isLoading={createMutation.isPending || updateMutation.isPending}
				isSystemQuantity={currentUnitDto?.name === 'Time'}
				form={form}
				unitForm={unitForm}
				units={fields}
				systemTypes={unitSystems}
				onAddUnit={handleAddUnit}
				onDeleteUnit={handleDeleteUnit}
				onEditUnit={handleInitEditUnit}
				onDefaultChange={handleDefaultChange}
				onBack={handleBack}
				onSave={handleSave}
			/>
			<EditUnitModal unit={unitForEdit} units={units} onSave={handleSaveEditedUnit} systemTypes={unitSystems} />
			<EntityCheckBeforeDeleteContainer
				entity={entityForDelete as IEntity}
				entityType={EntityTypes.UNIT}
				checkUsage
				onCanceled={handleDeleteCanceled}
				onChecked={handleDeleteChecked}
			/>
		</>
	);
};

export default React.memo(UnitContainer);
