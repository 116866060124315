export enum ControlAlgorithmTypeExternal {
	PID = 0,
	OPEN_LOOP
}

export enum ControlAlgorithmsBase {
	OPEN_LOOP = 'OPEN_LOOP',
	PID = 'PID',
	ASYMMETRIC_PID = 'ASYMMETRIC_PID'
}

export enum ControlAlgorithmsAdditional {
	ADAPTIVE_AMPLITUDE = 'ADAPTIVE_AMPLITUDE',
	ADAPTIVE_AMPLITUDE_MEAN = 'ADAPTIVE_AMPLITUDE_MEAN',
	FEED_FORWARD = 'FEED_FORWARD'
}
