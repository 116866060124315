import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { formRequestConverter, AxisFormType, IAxisRequestDto, IAxisResponseDto } from '.';
import { deleteEmptyFields } from '../../tools';
import * as api from './axes.api';

export const useAxes = (stationId?: string) => {
	const {
		data: axes,
		isLoading: isAxesLoading,
		isFetched
	} = useQuery({
		queryKey: ['axes', { stationId }],
		// When we provide enable option - !!stationId, the stationId is guaranteed
		queryFn: () => api.getAxesForStation(stationId as string),
		enabled: !!stationId
	});

	const queryClient = useQueryClient();

	const updateAxes = () => {
		queryClient.invalidateQueries({ queryKey: ['axes', { stationId }] });
	};

	const deleteAxis = useCallback(
		(id: string) => {
			queryClient.setQueryData<{ id: string }[]>(['axes', { stationId }], (old) => {
				if (!old) return undefined;
				return old?.filter((st) => st.id !== id);
			});
		},
		[queryClient, stationId]
	);

	return {
		axes,
		isAxesLoading,
		isAxesFetched: isFetched,
		updateAxes,
		deleteAxis
	};
};

export const useAxis = (axisId?: string) => {
	const { data: axisDto, isLoading: isAxisLoading } = useQuery<IAxisResponseDto>({
		queryKey: ['axis', axisId],
		// When we provide enable option - !!axisId, the axisId is guaranteed
		queryFn: () => api.getAxis(axisId as string),
		enabled: !!axisId
	});

	const queryClient = useQueryClient();

	const updateAxis = () => {
		queryClient.invalidateQueries({ queryKey: ['axes', { axisId }] });
	};

	return {
		axisDto,
		isAxisLoading,
		updateAxis
	};
};

export const useTestAxis = (testId?: string) => {
	const { data: axes, isLoading: isAxesLoading } = useQuery({
		queryKey: ['axis', { testId }],
		queryFn: () => api.getAxesForTest(testId as string),
		enabled: !!testId
	});

	return {
		axes,
		isAxesLoading
	};
};

export const useSaveAxis = (axisId?: string, onSaveSuccess?: () => void) => {
	const createMutation = useMutation({
		mutationFn: (axis: IAxisRequestDto) => api.createAxis(axis),
		onSuccess: () => {
			if (onSaveSuccess) onSaveSuccess();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { axis: IAxisRequestDto; axisId: string }) => api.updateAxis(data.axis, data.axisId),

		onSuccess: () => {
			if (onSaveSuccess) onSaveSuccess();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const save = useCallback(
		(data: AxisFormType) => {
			const filteredData = deleteEmptyFields<IAxisRequestDto>(formRequestConverter(data));
			if (axisId) {
				updateMutation.mutate({
					axis: filteredData,
					axisId
				});
			} else {
				createMutation.mutate(filteredData);
			}
		},
		[axisId, createMutation, updateMutation]
	);

	return {
		save,
		isLoading: createMutation.isPending || updateMutation.isPending
	};
};
