export enum WidgetsTypes {
	CONTROL_TEST,
	CONTROL_AXIS
}

export enum WidgetsStatuses {
	CONFIGURED = 0,
	NOT_CONFIGURED = 1
}

export enum WidgetCrudActionTypes {
	EDIT = 'EDIT_WIDGET',
	CREATE = 'CREATE_WIDGET',
	DUPLICATE = 'DUPLICATE_WIDGET',
	DELETE = 'DELETE_WIDGET'
}

export enum LinkStates {
	LINK,
	UNLINK
}

export enum ButtonStates {
	PRESSED,
	RELEASED
}
