import React, { useMemo, forwardRef } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable as PrimDataTable, DataTableProps, DataTableValueArray } from 'primereact/datatable';
import cx from 'classnames';
import Column, { IColumnProps } from './Column';
import styles from './DataTable.module.scss';

export enum EDataTableThemes {
	WIDGET = 'widget'
}

export type TactunDataTableProps = {
	className?: string;
	theme?: EDataTableThemes;
	visibleRowsCount?: number;
	children: React.ReactNode;
} & DataTableProps<DataTableValueArray>;

export interface IDataTableSubComponents {
	Column: React.FC<IColumnProps>;
}

const headerHeight = 47;
const rowHeight = 57;
const padding = 7;

//TODO: fix types
//@ts-ignore
const DataTable: React.ForwardRefExoticComponent<
	TactunDataTableProps & React.RefAttributes<PrimDataTable<DataTableValueArray>>
> &
	IDataTableSubComponents = forwardRef<PrimDataTable<DataTableValueArray>, TactunDataTableProps>(
	({ children, globalFilter, className, visibleRowsCount, theme, ...rest }, ref) => {
		const filters = useMemo(
			() => ({
				global: { value: globalFilter, matchMode: FilterMatchMode.CONTAINS }
			}),
			[globalFilter]
		);

		const scrollConfig = useMemo(() => {
			if (visibleRowsCount === undefined) return {};
			const height = headerHeight + visibleRowsCount * rowHeight + padding;

			return {
				scrollable: true,
				scrollHeight: `${height}px`
			};
		}, [visibleRowsCount]);

		return (
			<PrimDataTable
				ref={ref}
				filters={filters}
				className={cx(styles.container, className, theme && styles[theme], { DataTableBlank: !rest.value })}
				{...rest}
				{...scrollConfig}
			>
				{children}
			</PrimDataTable>
		);
	}
);

DataTable.displayName = 'DataTable';
DataTable.Column = Column;

export default DataTable;
