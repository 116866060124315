import { AuxDeviceRequest } from '@tactun/grpc-client';
import { Converter } from '../../types';
import { AuxiliaryDeviceStatus, AuxiliaryDeviceType } from './auxiliaryDevice.enums';
import { auxiliaryDeviceSchema } from './auxiliaryDevice.schemas';
import {
	AuxiliaryDeviceFormType,
	IAuxiliaryDeviceListItem,
	IAuxiliaryDeviceResponseDto
} from './auxiliaryDevice.types';

export const defaultValues = {
	name: '',
	stationId: '',
	id: '',
	type: undefined,
	axisId: undefined,
	channelId: '',
	direction: undefined
};

export const responseListItemConverter: Converter<IAuxiliaryDeviceResponseDto, IAuxiliaryDeviceListItem> = (
	response
) => {
	return {
		id: response.id,
		name: response.name,
		type: AuxiliaryDeviceType[response.type],
		status: AuxiliaryDeviceStatus[response.status]
	};
};

export const responseFormConverter: Converter<IAuxiliaryDeviceResponseDto, AuxiliaryDeviceFormType> = (response) => {
	const { channel, axis, direction, status, ...baseResponse } = response;

	if (response.type === AuxiliaryDeviceType.MECHANICAL_LIMITS) {
		return {
			...baseResponse,
			direction,
			channelId: channel.id,
			axisId: axis?.id || ''
		};
	} else {
		return {
			...baseResponse,
			channelId: channel.id
		};
	}
};

export const formRequestConverter = (form: AuxiliaryDeviceFormType) => auxiliaryDeviceSchema.cast(form);

export const auxiliaryDeviceToGRPCConverter: Converter<IAuxiliaryDeviceResponseDto, AuxDeviceRequest> = (
	auxiliaryDevice
) => {
	const auxDeviceDto = new AuxDeviceRequest();
	auxDeviceDto.setId(auxiliaryDevice.id);
	auxDeviceDto.setName(auxiliaryDevice.name);
	auxDeviceDto.setType(auxiliaryDevice.type);
	if (auxiliaryDevice.axis?.id) {
		auxDeviceDto.setAxisId(auxiliaryDevice.axis.id);
	}
	auxDeviceDto.setDirection(auxiliaryDevice.direction);
	auxDeviceDto.setChannelId(auxiliaryDevice.channel.id);

	return auxDeviceDto;
};

export const auxiliaryDeviceResponsesToGRPCsConverter: Converter<
	IAuxiliaryDeviceResponseDto[],
	Array<AuxDeviceRequest>
> = (auxiliaryDevices) => {
	return auxiliaryDevices.map((variable) => auxiliaryDeviceToGRPCConverter(variable));
};
