import * as yup from 'yup';
// import * as api from './testActions.api';
import { ruleBaseSchema } from '../Rules/rules.schemas';
import { alphaNumericRegex } from '../../constants/regexes';
import { RuleRequestDtoBase } from '../Rules';
import { calculationSchema } from '../Calculations/calculation.schemas';

export const testActionsSchema = ruleBaseSchema.shape({
	name: yup
		.string()
		.required()
		.max(30)
		.matches(alphaNumericRegex, 'Do not use special symbols')
		.test('checkActionNameUniqueness', 'Name is used', function (value, form) {
			if (!value) return true;
			const { items = [], editingIndex } = form.options.context || {};

			return !items.find((action: RuleRequestDtoBase, i: number) => action.name === value && i !== editingIndex);
		}),
	triggers: yup.array().notRequired(),
	positiveActions: yup.array().min(1)
});

export const testCalculationSchema = calculationSchema.shape({
	name: yup
		.string()
		.required('Name is a required field')
		.max(30)
		.matches(alphaNumericRegex, 'Is not in correct format')
		.test('checkActionNameUniqueness', 'Name is used', function (value, form) {
			if (!value) return true;
			const { items = [], editingIndex } = form.options.context || {};

			return !items.find((action: RuleRequestDtoBase, i: number) => action.name === value && i !== editingIndex);
		}),
	stationId: yup.string().optional()
});
