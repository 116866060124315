import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import HeaderNavigation, { IHeaderNavigationProps } from './HeaderNavigation';
import HeaderMenu, { IHeaderMenuProps } from './HeaderMenu';
import HeaderMenuItem, { IHeaderMenuItemProps } from './HeaderMenuItem';
import HeaderLeftNavigation, { IHeaderLeftNavigationProps } from './HeaderLeft';
import tactunLogo from './logo.png';
import styles from './Header.module.scss';

export interface IHeaderProps {
	children: React.ReactNode;
	logoClickPath: string;
	logo?: string;
	className?: string;
}

export interface IHeaderSubComponents {
	Navigation: React.FC<IHeaderNavigationProps>;
	Menu: React.FC<IHeaderMenuProps>;
	MenuItem: React.FC<IHeaderMenuItemProps>;
	LeftNavigation: React.FC<IHeaderLeftNavigationProps>;
}

const Header: React.FC<IHeaderProps> & IHeaderSubComponents = ({ children, className, logoClickPath, logo }) => {
	return (
		<div className={cx(styles.container, className)}>
			<Link to={logoClickPath}>
				<img src={logo ?? tactunLogo} alt="logo" className={styles.logo} data-testid="logoImg" />
			</Link>
			{children}
		</div>
	);
};

Header.Navigation = HeaderNavigation;
Header.Menu = HeaderMenu;
Header.MenuItem = HeaderMenuItem;
Header.LeftNavigation = HeaderLeftNavigation;
Header.displayName = 'Header';

export default Header;
