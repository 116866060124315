export enum ConditionsObjectTypes {
	DIGITAL_INPUT = 0,
	STATION,
	TEST,
	CONNECTION,
	MEASUREMENT,
	AUX_DEVICE,
	CALCULATION,
	UI_BUTTON,
	VARIABLE
}

export enum ConditionDtoObjectTypes {
	DIGITAL_INPUT,
	AUX_DEVICE,
	CALCULATION,
	MEASUREMENT_IN_OUT_RANGE,
	MEASUREMENT_MORE_THAN_LESS_THAN,
	CONNECTION,
	TEST,
	STATION,
	UI_BUTTON,
	VARIABLE
}

export enum Condition {
	IN_RANGE = 0,
	OUT_OF_RANGE,
	MORE_THAN,
	LESS_THAN
}

export enum EqualConditions {
	EQUALS = 0,
	DOES_NOT_EQUAL
}

export enum InRangeConditions {
	IN_RANGE = 0,
	OUT_OF_RANGE
}

export enum MoreLessConditions {
	MORE_THAN = 0,
	LESS_THAN
}

export enum ConditionsDigitalInputProperties {
	ON = 0,
	OFF
}

export enum ConditionsStationProperties {
	IN_TEST = 0,
	IDLE,
	ACTIVE,
	SAFE
}

export enum ConditionsTestProperties {
	PAUSED = 0,
	IN_PROGRESS
}

export enum ConditionsConnectionProperties {
	CONNECTED = 0,
	DISCONNECTED
}

export enum ConditionsAuxDeviceProperties {
	ON = 0,
	OFF
}

export enum ConditionsUIButtonProperties {
	PRESSED = 0,
	RELEASED,
	DISABLED
}

export enum ConditionLogic {
	AND = 0,
	OR
}
