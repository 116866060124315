import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Button, DataTable, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataTableSelectionMultipleChangeEvent, DataTableValueArray } from 'primereact/datatable';
import {
	calculationReportEditConfigurationSchema,
	calculationReportListSchema
} from '../../testReportConfiguration.schemas';
import {
	TestReportCalculationFormType,
	TestReportCalculationEditFormType,
	TestReportCalculationFormListType
} from '../../testReportConfiguration.types';
import { PrecisionType, ReportResultType, ReportResultTypeForm } from '../../testReportConfiguration.enums';
import CalculationEditForm from './CalculationEditForm';
import { useTestReportCalculations } from '../../testReportConfiguration.hooks';
import styles from './CalculationReportConfigurationTable.module.scss';

const booleanToWord = (value: boolean): 'Yes' | 'No' => (value ? 'Yes' : 'No');

export interface CalculationReportConfigurationTableProps {
	values: TestReportCalculationFormType[];
	onSubmit: (data: TestReportCalculationFormType[]) => void;
}

const editModalId = `${ReportResultType.CALCULATION_RESULT}_EDIT`;

const CalculationReportConfigurationTable: FC<CalculationReportConfigurationTableProps> = ({ values, onSubmit }) => {
	const { testId } = useParams();
	const { testReportCalculations, isLoading } = useTestReportCalculations(testId);
	const { t } = useTranslation('testConfiguration');
	const calculationForm = useForm<TestReportCalculationFormListType>({
		resolver: yupResolver(calculationReportListSchema)
	});
	const { control, setValue, handleSubmit, watch, reset, getValues } = calculationForm;
	const { update } = useFieldArray({ control, name: 'calculationResults' });
	const calculations = watch('calculationResults');

	const calculationEditForm = useForm<TestReportCalculationEditFormType>({
		resolver: yupResolver(calculationReportEditConfigurationSchema)
	});

	const { reset: resetCalculation } = calculationEditForm;

	const handleSelection = (event: DataTableSelectionMultipleChangeEvent<DataTableValueArray>) => {
		const calculationResults = getValues().calculationResults;
		if (calculationResults) {
			const newValues = generateTableData(calculationResults, event.value as TestReportCalculationFormType[]);
			setValue('calculationResults', newValues);
		}
	};

	const handleOpenEditConfig = (rowData: TestReportCalculationFormType) => {
		const index = testReportCalculations.findIndex(({ id }) => id === rowData.id);
		resetCalculation({ index, ...rowData });
		Modal.show(editModalId);
	};

	const handleCloseEditModal = useCallback(() => {
		Modal.hide(editModalId);
	}, []);

	const handleCloseModal = useCallback(() => {
		const calculationResults = generateTableData(testReportCalculations, values);
		reset({ calculationResults });
		Modal.hide(ReportResultType.CALCULATION_RESULT);
	}, [reset, values, testReportCalculations]);

	const handleUpdateCalculation = useCallback(
		(index: number, data: TestReportCalculationFormType) => {
			update(index, data);
			handleCloseEditModal();
		},
		[update, handleCloseEditModal]
	);

	const handleUpdate = handleSubmit((data) => {
		const selectedValues = data.calculationResults?.filter(({ isSelected }) => isSelected);
		if (selectedValues) {
			onSubmit(selectedValues);
		}
	});

	const selectedRows = useMemo(() => {
		return calculations?.filter(({ isSelected }) => isSelected) || [];
	}, [calculations]);

	useEffect(() => {
		const newValues = generateTableData(testReportCalculations, values);
		setValue('calculationResults', newValues);
	}, [values, setValue, testReportCalculations]);

	return (
		<>
			<Modal id={ReportResultType.CALCULATION_RESULT} onClose={handleCloseModal} className={styles.modalCalculation}>
				<Modal.Header>{t('Select Calculations to be Included in the Report')}</Modal.Header>
				<Modal.Content>
					<DataTable
						value={calculations}
						loading={isLoading}
						dataKey="id"
						showGridlines
						columnResizeMode="fit"
						selection={selectedRows}
						onSelectionChange={handleSelection}
						selectionMode="multiple"
					>
						<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
						<DataTable.Column header={t('Calculation Name')} field="name" resizeable />
						<DataTable.Column
							header={t('Precision Type')}
							body={({ precisionType }) => PrecisionType[precisionType]}
							resizeable
						/>
						<DataTable.Column
							header={t('Include Settings')}
							body={(rowData) => t(booleanToWord(rowData.includeSettings))}
							resizeable
						/>
						<DataTable.Column
							header={t('Include in Statistics')}
							body={(rowData) => t(booleanToWord(rowData.includeInStatistics))}
							resizeable
						/>
						<DataTable.Column
							header=""
							className={styles.lastCell}
							body={(rowData) => {
								return (
									<Button
										type="button"
										icon="t-icon-edit"
										variant="text"
										onClick={() => {
											handleOpenEditConfig(rowData);
										}}
									/>
								);
							}}
						/>
					</DataTable>
				</Modal.Content>
				<Modal.Footer>
					<Button type="button" variant="text" color="success" onClick={handleCloseModal}>
						{t('Cancel')}
					</Button>
					<Button type="button" label={t('Save')} onClick={handleUpdate} />
				</Modal.Footer>
			</Modal>
			<Modal id={editModalId} onClose={handleCloseEditModal} className={styles.modalConfigure}>
				<Modal.Header>{t('Configure Calculation ')}</Modal.Header>
				<Modal.Content>
					<CalculationEditForm form={calculationEditForm} onSubmit={handleUpdateCalculation} />
				</Modal.Content>
				<Modal.Footer>
					<Button type="button" variant="text" color="success" onClick={handleCloseEditModal}>
						{t('Cancel')}
					</Button>
					<Button type="submit" label={t('Save')} form={ReportResultTypeForm.CALCULATION_RESULT} />
				</Modal.Footer>
			</Modal>
		</>
	);
};

const generateTableData = (
	allCalculations: TestReportCalculationFormType[],
	selectedCalculations: TestReportCalculationFormType[]
) => {
	return allCalculations?.map((item) => {
		const selectedItem = selectedCalculations.find(({ id }) => id === item.id);
		if (selectedItem) {
			return {
				...selectedItem,
				isSelected: true
			};
		}

		return { ...item, isSelected: false };
	});
};

export default CalculationReportConfigurationTable;
