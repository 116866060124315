import React, { useCallback, useMemo } from 'react';
import { PageLayout } from '@tactun/ui';
import { IconButton } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { CalculationListItem } from '../../calculation.types';
import { CalculationsTableActionTypes } from '../../calculation.enums';
import { CrudActions, TableFilters } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { useTableConfigs } from '../../../../hooks';

interface ICalculationsTableProps {
	onAction: (type: CalculationsTableActionTypes, data?: CalculationListItem) => void;
	data?: CalculationListItem[];
	isLoading: boolean;
	stationName: string;
}

const CalculationsTable: React.FC<ICalculationsTableProps> = ({ data, isLoading, onAction, stationName }) => {
	const { t } = useTranslation('calculation');

	const formattedData = useMemo(() => data?.map((item) => ({ ...item, type: t(item.type) })), [data, t]);

	const transColumns = useMemo<ITableColumns<CalculationListItem>[]>(() => {
		return [
			{ field: 'type', header: t('Calculation type') },
			{ field: 'unit', header: t('Unit') }
		];
	}, [t]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<CalculationListItem>(transColumns, formattedData);

	const createMenuItems = useCallback(
		(data: any) => [
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(CalculationsTableActionTypes.DELETE, data)
			}
		],
		[onAction, t]
	);

	const handleCreate = useCallback(() => {
		onAction(CalculationsTableActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={stationName}>
			<PageLayout.Header title={t('Calculations')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('Calculation')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>

			<DataTable
				visibleRowsCount={8}
				value={formattedData}
				loading={isLoading}
				globalFilter={filter}
				dataKey="id"
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('Calculation name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(CalculationsTableActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="duplicate"
							fontSize="1.2rem"
							title={t('Duplicate')}
							disabled
							onClick={() => onAction(CalculationsTableActionTypes.DUPLICATE, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default React.memo(CalculationsTable);
