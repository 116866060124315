import { useMemo } from 'react';
import { PageLayout, Form, InputText, Dropdown } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { StationFormType } from '../../station.types';
import { IListItem, IListItemNumber } from '../../../../types';
import styles from './Station.module.scss';
import { useIsLocalDeployment } from '../../../../hooks/useIsLocalDeployment';

interface IStationProps {
	isCreate: boolean;
	form: UseFormReturn<StationFormType>;
	controllers: IListItem[];
	types: string[];
	unitSystemsOptions: IListItemNumber[];
	specimenTypes: IListItem[];
}

const Station: React.FC<IStationProps> = ({
	isCreate,
	form,
	controllers,
	types,
	unitSystemsOptions,
	specimenTypes
}) => {
	const { t } = useTranslation('station');
	const {
		control,
		formState: { errors },
		register,
		watch
	} = form;

	const name = watch('name');
	const id = watch('id');

	const title = useMemo<string>(() => {
		return isCreate ? t('Create a Station') : t('Edit "{{name}}" Station', { name });
	}, [isCreate, name, t]);

	const isDisabled = useIsLocalDeployment();

	return (
		<PageLayout info={title}>
			<PageLayout.Header title={t('General')} />
			<Form className={styles.container} id="stationForm">
				<input type="hidden" {...register('id')} />
				<Controller
					name="name"
					control={control}
					render={({ field }) => (
						<InputText
							data-testid="nameId"
							label={t('Station name*')}
							{...field}
							error={errors.name?.message}
							disabled={isDisabled}
						/>
					)}
				/>
				<Controller
					name="type"
					control={control}
					render={({ field }) => (
						<Dropdown
							{...field}
							data-testid="type"
							options={types}
							label={t('Station type*')}
							error={errors.type?.message}
							editable
							itemName={t('Station type')}
							disabled={isDisabled}
						/>
					)}
				/>
				<Controller
					name="controllerModel"
					control={control}
					render={({ field }) => (
						<Dropdown
							{...field}
							options={controllers}
							label={t('Controller model*')}
							data-testid="controllerModel"
							error={errors.controllerModel?.message}
							filter
							disabled={!!id || isDisabled}
						/>
					)}
				/>
				<Controller
					name="unitSystem"
					control={control}
					render={({ field }) => (
						<Dropdown
							{...field}
							label={t('Unit system*')}
							error={errors.unitSystem?.message}
							data-testid="unitSystem"
							options={unitSystemsOptions}
							disabled={isDisabled}
						/>
					)}
				/>
				<Controller
					name="specimenTypes"
					control={control}
					render={({ field }) => (
						<Dropdown
							isMulti
							{...field}
							label={t('Specimen Types*')}
							error={errors.specimenTypes?.message}
							data-testid="specimenTypes"
							options={specimenTypes}
							disabled={isDisabled}
						/>
					)}
				/>
				<div className={styles.FormDivider}>
					<Controller
						name="description"
						control={control}
						render={({ field }) => (
							<InputText
								{...field}
								textarea
								data-testid="description"
								label={t('Description')}
								error={errors.description?.message}
								maxLength={1000}
								disabled={isDisabled}
							/>
						)}
					/>
				</div>
			</Form>
		</PageLayout>
	);
};

export default Station;
