import * as yup from 'yup';
import { emptyStringToNull } from '../../tools/yupHelpers';
import {
	TriggerAuxDeviceProperties,
	TriggerConnectionProperties,
	TriggerDigitalInputProperties,
	TriggerMeasurementProperties,
	TriggerObjectTypes,
	TriggerSpecimenProperties,
	TriggerStationProperties,
	TriggerTestProperties
} from './triggers.enums';
import { RuleTrigger } from './triggers.types';

export const triggerBaseSchema = yup.object<RuleTrigger>({
	objectType: yup.mixed<TriggerObjectTypes>().required('Object type is a required field'),
	property: yup.number().required('State is a required field')
});

export const digitalInputTriggerSchema = yup.object({
	objectId: yup.string().required('Digital input is a required field'),
	property: yup.mixed<TriggerDigitalInputProperties>().required('State is a required field')
});

export const testTriggerSchema = yup.object({
	property: yup.mixed<TriggerTestProperties>().required('State is a required field')
});

export const connectionTriggerSchema = yup.object({
	property: yup.mixed<TriggerConnectionProperties>().required('State is a required field')
});

export const measurementTriggerSchema = (data: RuleTrigger) => {
	switch (data.property) {
		case TriggerMeasurementProperties.OUT_OF_RANGE:
		case TriggerMeasurementProperties.IN_RANGE:
			return triggerBaseSchema.concat(
				yup.object({
					objectId: yup.string().required('This is a required field'),
					lowerLimit: yup.number().transform(emptyStringToNull),
					upperLimit: yup
						.number()
						.transform(emptyStringToNull)
						.min(yup.ref('lowerLimit') || 0 + 1)
				})
			);
		case TriggerMeasurementProperties.AMPLITUDE_CHANGE:
			return triggerBaseSchema.concat(
				yup.object({
					objectId: yup.string().required('This is a required field'),
					amplitudeChange: yup
						.number()
						.transform(emptyStringToNull)
						.min(0)
						.required('Amplitude change is a required field'),
					delayCycles: yup
						.number()
						.transform(emptyStringToNull)
						.integer()
						.min(0)
						.required('Delay cycles is a required field'),
					changeCycles: yup
						.number()
						.transform(emptyStringToNull)
						.integer()
						.min(0)
						.required('Change cycles is a required field'),
					referenceCycles: yup
						.number()
						.transform(emptyStringToNull)
						.integer()
						.min(0)
						.required('Reference cycles is a required field'),
					referenceAxis: yup.string().transform(emptyStringToNull).required('Reference Axis is a required field')
				})
			);
		case TriggerMeasurementProperties.MEAN_CHANGE:
			return triggerBaseSchema.concat(
				yup.object({
					objectId: yup.string().required('This is a required field'),
					maxMeanShift: yup.number().transform(emptyStringToNull).required('This is a required field'),
					delayCycles: yup.number().transform(emptyStringToNull).integer().min(0).required('This is a required field'),
					changeCycles: yup.number().transform(emptyStringToNull).integer().min(0).required('This is a required field'),
					referenceCycles: yup
						.number()
						.transform(emptyStringToNull)
						.integer()
						.min(0)
						.required('This is a required field'),
					referenceAxis: yup.string().required('This is a required field')
				})
			);
		case TriggerMeasurementProperties.LESS_THAN:
		case TriggerMeasurementProperties.MORE_THAN:
			return triggerBaseSchema.concat(
				yup.object({
					objectId: yup.string().required('This is a required field'),
					threshold: yup.number().required('Threshold is a required field')
				})
			);
		default:
			return triggerBaseSchema;
	}
};

export const specimenTriggerSchema = (data: RuleTrigger) => {
	switch (data.property) {
		case TriggerSpecimenProperties.BREAK:
			return triggerBaseSchema.concat(
				yup.object({
					objectId: yup.string().required('This is a required field'),
					threshold: yup.number().transform(emptyStringToNull).required('Threshold is a required field'),
					dropPercentage: yup
						.number()
						.transform(emptyStringToNull)
						.integer()
						.min(0)
						.max(100)
						.required('This is a required field')
				})
			);
		case TriggerSpecimenProperties.YIELD:
			return triggerBaseSchema.concat(
				yup.object({
					threshold: yup.number().transform(emptyStringToNull).required('Threshold is a required field'),
					stressMeasurementId: yup.string().required('Required field'),
					strainMeasurementId: yup.string().required('Required field'),
					thresholdMeasurementId: yup.string().required('Required field')
				})
			);
		default:
			return triggerBaseSchema;
	}
};

export const auxDeviceTriggerSchema = yup.object({
	objectId: yup.string().required('Auxiliary device is a required field'),
	property: yup.mixed<TriggerAuxDeviceProperties>().required('State is a required field')
});

export const stationTriggerSchema = yup.object({
	property: yup.mixed<TriggerStationProperties>().required('State is a required field')
});

export const axisTriggerSchema = yup.object({
	objectId: yup.string().required('Axis is a required field')
});

export const triggerSchema = yup.lazy((data: RuleTrigger) => {
	switch (data.objectType) {
		case TriggerObjectTypes.DIGITAL_INPUT:
			return triggerBaseSchema.concat(digitalInputTriggerSchema);
		case TriggerObjectTypes.TEST:
			return triggerBaseSchema.concat(testTriggerSchema);
		case TriggerObjectTypes.CONNECTION:
			return triggerBaseSchema.concat(connectionTriggerSchema);
		case TriggerObjectTypes.MEASUREMENT:
			return measurementTriggerSchema(data);
		case TriggerObjectTypes.STATION:
			return triggerBaseSchema.concat(stationTriggerSchema);
		case TriggerObjectTypes.AUX_DEVICE:
			return triggerBaseSchema.concat(auxDeviceTriggerSchema);
		case TriggerObjectTypes.SPECIMEN:
			return specimenTriggerSchema(data);
		case TriggerObjectTypes.AXIS:
			return triggerBaseSchema.concat(axisTriggerSchema);
		case TriggerObjectTypes.UI_BUTTON:
			return triggerBaseSchema;
		default:
			return triggerBaseSchema;
	}
});
