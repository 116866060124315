import { EntityTypes } from '../entityDeleter.enums';

const serviceUrlQuasar: string = 'quasar/';
const serviceUrlCassiopeia: string = 'cassiopeia/';

export const labelMapping: Record<string, string> = {
	[EntityTypes.STATION_CHANNEL]: 'channel',
	[EntityTypes.STATION]: 'station',
	[EntityTypes.ACTUATOR]: 'actuator',
	[EntityTypes.AXES]: 'axes',
	[EntityTypes.CALCULATION]: 'calculation',
	[EntityTypes.CONTROL_CHANNEL]: 'control',
	[EntityTypes.SENSOR]: 'sensor',
	[EntityTypes.VARIABLE]: 'variable',
	[EntityTypes.TEST_VARIABLE]: 'variable',
	[EntityTypes.TEST_LOOP]: 'test loop',
	[EntityTypes.QUANTITY]: 'quantity',
	[EntityTypes.UNIT]: 'unit',
	[EntityTypes.SPECIMEN_TYPE]: 'specimen types',
	[EntityTypes.SPECIMEN]: 'specimen',
	[EntityTypes.MEASUREMENT]: 'measurement',
	[EntityTypes.AUXILIARY_DEVICE]: 'auxiliary device',
	[EntityTypes.RULE]: 'rule',
	[EntityTypes.TEST_RULE]: 'test rule',
	[EntityTypes.TEST]: 'test',
	[EntityTypes.WIDGETS]: 'Widgets',
	[EntityTypes.TEST_DATA_LOGGING]: 'data logging',
	[EntityTypes.TEST_ACTIONS]: 'test actions',
	[EntityTypes.CONTROLLERS]: 'controller',
	[EntityTypes.PRODUCTS]: 'product',
	[EntityTypes.DASHBOARD_TYPE]: 'dashboard type'
};
export const endpointMapping: Record<string, string> = {
	[EntityTypes.STATION_CHANNEL]: serviceUrlQuasar + 'station-channels',
	[EntityTypes.READOUT]: serviceUrlQuasar + 'tests/dashboard/readout',
	[EntityTypes.GRAPH]: serviceUrlQuasar + 'tests/dashboard/graph',
	[EntityTypes.STATION]: serviceUrlQuasar + 'stations',
	[EntityTypes.ACTUATOR]: serviceUrlQuasar + 'actuators',
	[EntityTypes.AXES]: serviceUrlQuasar + 'axis',
	[EntityTypes.CALCULATION]: serviceUrlCassiopeia + 'calc',
	[EntityTypes.CONTROL_CHANNEL]: serviceUrlQuasar + 'control-channels',
	[EntityTypes.SENSOR]: serviceUrlQuasar + 'sensors',
	[EntityTypes.VARIABLE]: serviceUrlCassiopeia + 'station-variable',
	[EntityTypes.TEST_VARIABLE]: serviceUrlCassiopeia + 'test-variable',
	[EntityTypes.TEST_LOOP]: serviceUrlQuasar + 'tests/loops',
	[EntityTypes.QUANTITY]: serviceUrlQuasar + 'quantities',
	[EntityTypes.UNIT]: serviceUrlQuasar + 'units',
	[EntityTypes.SPECIMEN_TYPE]: serviceUrlQuasar + 'specimen-types',
	[EntityTypes.SPECIMEN]: serviceUrlCassiopeia + 'specimen',
	[EntityTypes.MEASUREMENT]: serviceUrlQuasar + 'measurements',
	[EntityTypes.AUXILIARY_DEVICE]: serviceUrlQuasar + 'auxiliary-devices',
	[EntityTypes.RULE]: serviceUrlCassiopeia + 'rules',
	[EntityTypes.TEST_RULE]: serviceUrlCassiopeia + 'rules',
	[EntityTypes.TEST]: serviceUrlQuasar + 'tests',
	[EntityTypes.WIDGETS]: serviceUrlQuasar + 'widgets',
	[EntityTypes.TEST_DATA_LOGGING]: serviceUrlQuasar + 'tests/stages/logs',
	[EntityTypes.TEST_ACTIONS]: serviceUrlQuasar + 'tests/actions',
	[EntityTypes.CUSTOM_WIDGETS]: serviceUrlCassiopeia + 'button-widget',
	[EntityTypes.CONTROLLERS]: serviceUrlCassiopeia + 'controller',
	[EntityTypes.PRODUCTS]: serviceUrlCassiopeia + 'product/me',
	[EntityTypes.DASHBOARD_TYPE]: serviceUrlCassiopeia + 'dashboard-type'
};
