import { useCallback, useState, useMemo, useEffect } from 'react';
import { DataTable } from '@tactun/ui';
import {
	DataTableSelectionMultipleChangeEvent,
	DataTableSelectionSingleChangeEvent,
	DataTableValueArray
} from 'primereact/datatable';
import { IColumnProps } from '@tactun/ui/src/components/DataTable/Column';
import { IEntityWithGroups, INamedEntity, ITableColumns } from '../types';
import { useGroup } from '../modules/Group';

export function useTableConfigs<T extends INamedEntity | IEntityWithGroups>(
	columns: ITableColumns[] | IColumnProps[],
	data?: T[]
) {
	const [selected, setSelected] = useState<T[]>([]);
	const [filter, setFilter] = useState<string>('');
	const { selectedGroup } = useGroup(
		selected as IEntityWithGroups[],
		useCallback(() => setSelected([]), [])
	);

	const handleSelectionChange = useCallback(
		(
			event:
				| DataTableSelectionSingleChangeEvent<DataTableValueArray>
				| DataTableSelectionMultipleChangeEvent<DataTableValueArray>
		) => setSelected(event.value as T[]),
		[]
	);

	const handleFilterChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setFilter(e.target.value);
	}, []);

	const columnComponents = useMemo(
		() => columns.map((col) => <DataTable.Column sortable resizeable key={col.field} {...col} />),
		[columns]
	);

	const handleCancel = useCallback(() => {
		setSelected([]);
	}, []);

	const dataFilteredByGroups = useMemo(() => {
		return selectedGroup
			? data?.filter((d: IEntityWithGroups) => d?.groups?.some((gr) => gr.id === selectedGroup?.id))
			: (data as T[]);
	}, [data, selectedGroup]);

	useEffect(() => {
		const validSelected = selected.filter((item) => !item.id || data?.find((it) => it.id === item.id));

		if (validSelected.length !== selected.length) {
			setSelected(validSelected);
		}
	}, [data, selected]);

	return {
		filter,
		groupFilter: selectedGroup?.id,
		selected,
		columnComponents,
		dataFilteredByGroups,
		handleSelectionChange,
		handleFilterChange,
		handleCancel
	};
}
