import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CalculationListItem, CalculationTypes } from '../Calculations';
import * as api from './testReportConfiguration.api';
import { calculationItemReportConfigConverter } from './testReportConfiguration.converters';
import { TestReportCalculationFormType } from './testReportConfiguration.types';
import { getTestExistingActions } from '../TestActions/testActions.api';
import { ActionDtoObjectTypes, CalculationActionDto } from '../Actions';

export const useTestReportConfig = (testId?: string) => {
	const { data, isLoading } = useQuery({
		queryKey: ['test-report-config', { testId }],
		queryFn: async () => api.getTestReportConfig(testId),
		retry: false,
		enabled: !!testId
	});

	return {
		isLoading,
		reportConfig: data
	};
};

interface ShitAction extends CalculationActionDto {
	name: string;
}

export const useTestReportCalculations = (testId?: string) => {
	const { data, isLoading } = useQuery<CalculationListItem[], Error>({
		queryKey: ['calculations', { testId }],
		queryFn: async () => {
			const resp = await getTestExistingActions(testId as string);

			// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
			// Ugly hack to get the calculations from the actions, until endpoint will be ready
			try {
				const calcs = resp.reduce((acc: ShitAction[], curr) => {
					const calculations =
						curr.actions.reduce((acc1: ShitAction[], curr1) => {
							if (curr1.rulePositiveActions) {
								return [...acc1, ...curr1.rulePositiveActions.map((a) => ({ ...a, name: curr1.name }) as ShitAction)];
							}
							return acc1;
						}, []) || [];

					return [...acc, ...calculations];
				}, []);

				return calcs
					.filter((c) => c.type === ActionDtoObjectTypes.CALCULATION && c.calculation)
					.map((action) => {
						const name = action.name;
						const calc = action as CalculationActionDto;

						return {
							id: calc.id,
							name: name,
							type: calc.calculation?.type ? CalculationTypes[calc.calculation?.type] : '',
							unit: calc.calculation?.unit || ''
						} as CalculationListItem;
					});
			} catch {
				return [];
			}
			// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
		},
		enabled: !!testId
	});

	const testReportCalculations = useMemo<TestReportCalculationFormType[]>(() => {
		return (
			data?.map((item) => calculationItemReportConfigConverter(item)).map((cr) => ({ ...cr, isSelected: false })) || []
		);
	}, [data]);

	return { testReportCalculations, isLoading };
};
