import cx from 'classnames';
import styles from './FormRow.module.scss';

export interface IFormRowProps {
	children?: React.ReactNode;
	className?: string;
}

const FormRow: React.FC<IFormRowProps> = ({ children, className }) => {
	return <div className={cx(styles.rowContainer, className)}>{children}</div>;
};

FormRow.displayName = 'FormRow';
export default FormRow;
