import { BondType, Rule, RulesRequest } from '@tactun/grpc-client/src/grpc/rule_pb';
import { uniq } from 'lodash';
import { Converter } from '../../types';
import { actionRequestConverter, actionResponseConverter, actionResponseToGRPCRequestConverter } from '../Actions';
import { conditionResponseToGRPCRequestConverter } from '../Conditions';
import {
	TriggerDtoObjectTypes,
	triggerFormRequestConverter,
	triggerResponseConverter,
	triggerResponseToGRPCRequestConverter
} from '../Triggers';
import {
	RuleConditionType,
	RuleListItem,
	RuleRequestConverterParams,
	RuleRequestDtoBase,
	RuleStatus,
	RuleType,
	StationRuleRequestDto,
	StationRuleResponseDto,
	TestRuleRequestDto,
	TestRuleResponseDto,
	TRuleForm
} from './';

export const defaultValues: Partial<TRuleForm> = {
	id: undefined,
	name: '',
	type: undefined,
	isTestRule: false,
	triggers: [],
	positiveActions: [],
	negativeActions: []
};

export const ruleTableConverter: Converter<StationRuleResponseDto | TestRuleResponseDto, RuleListItem> = (response) => {
	return {
		id: response.uuid,
		name: response.name,
		type: RuleType[response.ruleType],
		ruleConditionType: RuleConditionType[response.ruleConditionType],
		triggerTypes: uniq(response.triggers?.map((trigger) => TriggerDtoObjectTypes[trigger.type])) || [],
		createdInStation: !!(response as StationRuleResponseDto).ruleStationUuid,
		status: response.ruleStatus
	};
};

export const responseFormConverter: Converter<StationRuleResponseDto | TestRuleResponseDto, TRuleForm> = (
	response
) => ({
	name: response.name,
	stationId: 'ruleStationUuid' in response ? response.ruleStationUuid : undefined,
	testId: 'ruleTestUuid' in response ? response.ruleTestUuid : undefined,
	type: response.ruleType,
	id: response.uuid,
	triggers: response.triggers?.map((trigger) => triggerResponseConverter(trigger)) || [],
	positiveActions: response.rulePositiveActions?.map((action) => actionResponseConverter(action)) || [],
	negativeActions: response.ruleNegativeActions?.map((action) => actionResponseConverter(action)) || []
});

export const ruleRequestConverter = ({
	form,
	conditions,
	positiveRunType,
	negativeRunType,
	conditionBondType
}: RuleRequestConverterParams): StationRuleRequestDto | TestRuleRequestDto => {
	const baseRuleRequest = {
		name: form.name,
		ruleType: form.type,
		ruleConditionType: RuleConditionType.CONDITIONAL,
		ruleStationUuid: form.stationId,
		triggers: form.triggers?.map((trigger) => triggerFormRequestConverter(trigger)),
		ruleNegativeActions: form.negativeActions.map((action) => actionRequestConverter(action)),
		rulePositiveActions: form.positiveActions.map((action) => actionRequestConverter(action)),
		conditions,
		positiveRunType,
		negativeRunType,
		conditionBondType
	} as RuleRequestDtoBase;

	if (form.testId) {
		return {
			...baseRuleRequest,
			ruleTestUuid: form.testId,
			ruleStageUuid: undefined,
			ruleStationUuid: undefined
		} as TestRuleRequestDto;
	}

	if (form.stationId && !form.testId) {
		return { ...baseRuleRequest, ruleStationUuid: form.stationId } as StationRuleRequestDto;
	}

	throw new Error('Invalid rule request. Rule must be either station or test rule.');
};

export const ruleResponseToGRPCRequestConverter: Converter<StationRuleResponseDto, Rule> = (ruleDto) => {
	const rule = new Rule();

	rule.setId(ruleDto.uuid);
	rule.setName(ruleDto.name);
	rule.setType(ruleDto.conditions?.length ? RuleConditionType.CONDITIONAL : RuleConditionType.UNCONDITIONAL);
	rule.setStationId(ruleDto.ruleStationUuid);

	if (ruleDto.conditionBondType !== undefined && ruleDto.conditions?.length) {
		rule.setConditionBondType(ruleDto.conditionBondType);
	} else {
		rule.setConditionBondType(BondType.AND);
	}

	if (ruleDto.conditions !== undefined)
		rule.setConditionsList(ruleDto.conditions.map((condition) => conditionResponseToGRPCRequestConverter(condition)));

	if (ruleDto.triggers !== undefined)
		rule.setTriggersList(ruleDto.triggers.map((trigger) => triggerResponseToGRPCRequestConverter(trigger)));

	if (ruleDto.positiveRunType !== undefined) rule.setPositiveRunType(ruleDto.positiveRunType);
	if (ruleDto.rulePositiveActions !== undefined)
		rule.setPositiveActionsList(
			ruleDto.rulePositiveActions.map((action) => actionResponseToGRPCRequestConverter(action))
		);

	if (ruleDto.negativeRunType !== undefined) rule.setNegativeRunType(ruleDto.negativeRunType);
	if (ruleDto.ruleNegativeActions !== undefined) {
		rule.setNegativeActionsList(
			ruleDto.ruleNegativeActions.map((action) => actionResponseToGRPCRequestConverter(action))
		);
	}

	return rule;
};

export const rulesResponseToGRPCRequestConverter: Converter<StationRuleResponseDto[], Array<Rule>> = (rules) => {
	return rules
		.filter((r) => r.ruleStatus !== RuleStatus.DISABLED)
		.map((rule) => ruleResponseToGRPCRequestConverter(rule));
};

export const rulesToRuleApplyConverter = (rules: StationRuleResponseDto[], stationId: string) => {
	const rulesRequest = new RulesRequest();

	rulesRequest.setStationId(stationId);
	rules.forEach((rule) => {
		rulesRequest.addRules(ruleResponseToGRPCRequestConverter(rule));
	});

	return rulesRequest;
};
