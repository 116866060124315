import { UpdateConfigTypeMap } from '@tactun/grpc-client';

export const updateConfigTypesMap: Record<string, UpdateConfigTypeMap[keyof UpdateConfigTypeMap]> = {
	kp: 0,
	kd: 0,
	ki: 0,
	derivativeGain: 0,
	integralGain: 0,
	pGainForward: 1,
	pGainReverse: 2,
	amplitudeDither: 3,
	frequencyDither: 4,
	rateValue: 5,
	upRateValue: 6,
	downRateValue: 7,
	endChannelValue: 8,
	value: 8,
	upEndChannelValue: 9,
	lowerEndChannelValue: 10,
	amplitudeValue: 11,
	meanValue: 12,
	frequency: 13,
	durationValue: 14,
	upDurationValue: 15,
	downDurationValue: 16,
	endingPhase: 17,
	numberOfCycles: 18
};
