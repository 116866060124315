import React from 'react';

export const ModalContext = React.createContext<{
	data: Map<string, any>;
	updateModalData: (id: string, data: any) => void;
	removeModalData: (id: string) => void;
}>({
	data: new Map(),
	updateModalData: () => {},
	removeModalData: () => {}
});
