import {
	ActionAxisProperties,
	ActionControlChannelProperties,
	ActionDigitalOutputProperties,
	ActionMeasurementProperties,
	ActionObjectTypes,
	ActionSpecimenProperties,
	ActionStationProperties,
	ActionTestProperties,
	ActionUIButtonProperties
} from './actions.enums';

export const stationActions = {
	[ActionObjectTypes.STATION]: [
		ActionStationProperties.IN_TEST,
		ActionStationProperties.IDLE,
		ActionStationProperties.SAFE,
		ActionStationProperties.ACTIVE
	],
	[ActionObjectTypes.DIGITAL_OUTPUT]: [ActionDigitalOutputProperties.LOW, ActionDigitalOutputProperties.HIGH],
	[ActionObjectTypes.CONTROL_CHANNEL]: [ActionControlChannelProperties.OPERATE, ActionControlChannelProperties.SET],
	[ActionObjectTypes.TEST]: [
		ActionTestProperties.PAUSE,
		ActionTestProperties.RESUME,
		ActionTestProperties.START,
		ActionTestProperties.STOP
	],
	[ActionObjectTypes.MESSAGE_BOX]: [],
	[ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION]: [],
	[ActionObjectTypes.MEASUREMENT]: [ActionMeasurementProperties.GET_MEASUREMENT_VALUE],
	[ActionObjectTypes.AXIS]: [
		ActionAxisProperties.ENABLE,
		ActionAxisProperties.DISABLE,
		ActionAxisProperties.WF_TARE,
		ActionAxisProperties.PID_RESET
	]
};

export const buttonActions = {
	...stationActions,
	[ActionObjectTypes.UI_BUTTON]: [
		ActionUIButtonProperties.ENABLE,
		ActionUIButtonProperties.DISABLE,
		ActionUIButtonProperties.PRESS,
		ActionUIButtonProperties.RELEASE
	]
};

export const testStageActions = {
	[ActionObjectTypes.DIGITAL_OUTPUT]: [ActionDigitalOutputProperties.LOW, ActionDigitalOutputProperties.HIGH],
	[ActionObjectTypes.TEST]: [ActionTestProperties.STOP, ActionTestProperties.PAUSE],
	[ActionObjectTypes.MESSAGE_BOX]: [],
	[ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION]: [],
	[ActionObjectTypes.MEASUREMENT]: [ActionMeasurementProperties.GET_MEASUREMENT_VALUE]
};

export const testGlobalActions = {
	[ActionObjectTypes.DIGITAL_OUTPUT]: [ActionDigitalOutputProperties.LOW, ActionDigitalOutputProperties.HIGH],
	[ActionObjectTypes.TEST]: [ActionTestProperties.STOP, ActionTestProperties.PAUSE],
	[ActionObjectTypes.MESSAGE_BOX]: [],
	[ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION]: [],
	[ActionObjectTypes.MEASUREMENT]: [ActionMeasurementProperties.GET_MEASUREMENT_VALUE]
};

export const preTestActions = {
	[ActionObjectTypes.DIGITAL_OUTPUT]: [ActionDigitalOutputProperties.LOW, ActionDigitalOutputProperties.HIGH],
	[ActionObjectTypes.CONTROL_CHANNEL]: [ActionControlChannelProperties.OPERATE, ActionControlChannelProperties.SET],
	[ActionObjectTypes.TEST]: [ActionTestProperties.PAUSE],
	[ActionObjectTypes.MESSAGE_BOX]: [],
	[ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION]: [],
	[ActionObjectTypes.MEASUREMENT]: [
		ActionMeasurementProperties.TARE,
		ActionMeasurementProperties.UNTARE,
		ActionMeasurementProperties.GET_MEASUREMENT_VALUE
	]
};

export const postTestActions = {
	[ActionObjectTypes.DIGITAL_OUTPUT]: [ActionDigitalOutputProperties.LOW, ActionDigitalOutputProperties.HIGH],
	[ActionObjectTypes.CONTROL_CHANNEL]: [ActionControlChannelProperties.OPERATE, ActionControlChannelProperties.SET],
	[ActionObjectTypes.MESSAGE_BOX]: [],
	[ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION]: [],
	[ActionObjectTypes.MEASUREMENT]: [
		ActionMeasurementProperties.UNTARE,
		ActionMeasurementProperties.GET_MEASUREMENT_VALUE
	],
	[ActionObjectTypes.SPECIMEN]: [ActionSpecimenProperties.SLACK_CORRECTION]
};

export const preStageActions = {
	[ActionObjectTypes.DIGITAL_OUTPUT]: [
		ActionDigitalOutputProperties.PULSE_START,
		ActionDigitalOutputProperties.PULSE_STOP,
		ActionDigitalOutputProperties.LOW,
		ActionDigitalOutputProperties.HIGH
	],
	[ActionObjectTypes.TEST]: [ActionTestProperties.PAUSE],
	[ActionObjectTypes.MESSAGE_BOX]: [],
	[ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION]: [],
	[ActionObjectTypes.MEASUREMENT]: [
		ActionMeasurementProperties.TARE,
		ActionMeasurementProperties.UNTARE,
		ActionMeasurementProperties.GET_MEASUREMENT_VALUE
	]
};

export const postStageActions = {
	[ActionObjectTypes.DIGITAL_OUTPUT]: [
		ActionDigitalOutputProperties.PULSE_START,
		ActionDigitalOutputProperties.PULSE_STOP,
		ActionDigitalOutputProperties.LOW,
		ActionDigitalOutputProperties.HIGH
	],
	[ActionObjectTypes.TEST]: [ActionTestProperties.PAUSE],
	[ActionObjectTypes.MESSAGE_BOX]: [],
	[ActionObjectTypes.MESSAGE_BOX_WITH_CONFIRMATION]: [],
	[ActionObjectTypes.MEASUREMENT]: [
		ActionMeasurementProperties.TARE,
		ActionMeasurementProperties.UNTARE,
		ActionMeasurementProperties.GET_MEASUREMENT_VALUE
	]
};

export const axisOnOffActions = {
	[ActionObjectTypes.AXIS]: [ActionAxisProperties.ENABLE, ActionAxisProperties.DISABLE]
};

export const controlAxisButtonActions = {
	[ActionObjectTypes.CONTROL_CHANNEL]: [ActionControlChannelProperties.OPERATE, ActionControlChannelProperties.SET],
	[ActionObjectTypes.AXIS]: [
		ActionAxisProperties.ENABLE,
		ActionAxisProperties.DISABLE,
		ActionAxisProperties.WF_TARE,
		ActionAxisProperties.PID_RESET
	]
};
