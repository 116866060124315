import React, { useCallback } from 'react';
import { Button, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { testSequencerRulesModalId } from '../../testSequencerRules.const';
import styles from './TestSequencerRulesModal.module.scss';

interface ITestSequencerRulesModalProps {
	children: React.ReactNode;
}

const TestSequencerRulesModal: React.FC<ITestSequencerRulesModalProps> = ({ children }) => {
	const { t } = useTranslation('testSequencerRules');

	const handleClose = useCallback(() => {
		Modal.hide(testSequencerRulesModalId);
	}, []);

	return (
		<Modal id={testSequencerRulesModalId} className={styles.container} shouldCloseOnOverlayClick={false}>
			<Modal.Header>{t('Rules')}</Modal.Header>
			<Modal.Content className={styles.content}>{children}</Modal.Content>
			<Modal.Footer>
				<Button onClick={handleClose} color="secondary" variant="contained">
					{t('Done')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(TestSequencerRulesModal);
