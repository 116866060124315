import { Dropdown } from '@tactun/ui';
import { FC, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useControlChannel } from '../../ControlChannels';
import { useMeasurements } from '../../Measurements';
import { useTestVariablesEntities, useVariables, VariablesTypes } from '../../Variables';
import SinewaveWaveformFormView from '../components/SinewaveWaveformFormView';
import ConstantWaveformFormView from '../components/ConstantWaveformFormView';
import DwellWaveformFormView from '../components/DwellWaveformFormView';
import RampWaveformFormView from '../components/RampWaveformFormView';
import DuotonicRampFormView from '../components/DuotonicRampFormView';
import HaversineFormView from '../components/HaversineFormView';
import DuotonicHaversineFormView from '../components/DuotonicHaversineFormView';
import TaperedSineFormView from '../components/TaperedSineFormView';
import SquarewaveFormView from '../components/SquarewaveFormView';
import SweepSineFormView from '../components/SweepSineFormView';
import { useNumberEnumList } from '../../../hooks';
import { WaveformType } from '../waveforms.enums';
import { useControllerName } from '../waveforms.hooks';
import {
	WaveformConstantFormErrors,
	WaveformDuoHaversineFormErrors,
	WaveformDuoRampFormErrors,
	WaveformDwellFormErrors,
	WaveformFormErrors,
	WaveformHaversineFormErrors,
	WaveformRampFormErrors,
	WaveformSinewaveFormErrors,
	WaveformSquareSineFormErrors,
	WaveformSweepSineFormErrors,
	WaveformTaperedSineFormErrors
} from '../waveforms.types';
import { getDefaultValuesByType, getFieldByFormPrefix } from '../waveforms.tools';
import { useTestMeasurements } from '../../TestMeasurements/testMeasurements.hooks';

export interface WaveformContainerProps {
	stationId?: string;
	testId?: string;
	controlChannelId: string;
	formPrefix: string;
	isRunning?: boolean;
	isAdaptive: boolean;
	isControlStage?: boolean;
	errors?: WaveformFormErrors;
}

const adaptiveWaveformTypes = [WaveformType.SINE, WaveformType.SWEEP_SINE];
const excludedWfTypes = [WaveformType.TAPERED_SINE, WaveformType.SWEEP_SINE];

const WaveformContainer: FC<WaveformContainerProps> = ({
	stationId,
	testId,
	controlChannelId,
	formPrefix,
	errors,
	isAdaptive = false,
	isRunning,
	isControlStage = false
}) => {
	const { t } = useTranslation('waveforms');

	const { variables } = useVariables(stationId, VariablesTypes.NUMERIC);
	const { testVariables } = useTestVariablesEntities(testId, VariablesTypes.NUMERIC);
	const testOrStationVars = useMemo(
		() => (testId ? testVariables : variables) || [],
		[testId, testVariables, variables]
	);
	const { measurements } = useMeasurements(stationId);
	const { testMeasurements } = useTestMeasurements(testId);
	const { controlChannel } = useControlChannel(controlChannelId);

	const {
		control,
		watch,
		setValue,
		reset,
		getValues,
		register,
		formState: { dirtyFields }
	} = useFormContext();
	const typeOptions = useNumberEnumList(WaveformType);
	const typeCN = useControllerName('type', formPrefix);
	const type = watch(typeCN);

	const rateLimitCN = useControllerName('rateLimit', formPrefix);
	useEffect(() => {
		if (controlChannel) {
			setValue(rateLimitCN, controlChannel.rateLimit || null);
		}
	}, [controlChannel, rateLimitCN, setValue]);

	const controlChannelFeedbackUnit = useMemo(() => {
		return controlChannel?.feedbackMeasurement?.unit.name
			? controlChannel.feedbackMeasurement?.unit.name
			: controlChannel?.axis.actuator.calibrationResponseDto?.unit?.name || '';
	}, [
		controlChannel?.axis.actuator.calibrationResponseDto?.unit?.name,
		controlChannel?.feedbackMeasurement?.unit.name
	]);

	const typeOptionsFiltered = useMemo(() => {
		const types = isAdaptive
			? typeOptions.filter((option) => adaptiveWaveformTypes.includes(option.value))
			: typeOptions;

		return types.map((option) => {
			if (excludedWfTypes.includes(option.value)) {
				return {
					...option,
					isDisabled: true
				};
			}
			return option;
		});
	}, [typeOptions, isAdaptive]);

	useEffect(() => {
		if (type !== undefined && type !== null && getFieldByFormPrefix(dirtyFields, formPrefix)?.type) {
			const values = getValues();
			const newValues = {
				...values
			};

			const wfConfigs = {
				...getDefaultValuesByType(type),
				rateLimit: controlChannel?.rateLimit || null,
				type: type
			};

			if (formPrefix === undefined) {
				newValues.waveform = wfConfigs;
			} else {
				const wf = getFieldByFormPrefix(newValues, formPrefix);
				if (wf) {
					for (const key in wfConfigs) {
						wf[key] = wfConfigs[key as keyof typeof wfConfigs];
					}
				}
			}

			reset(newValues);
		}
	}, [formPrefix, getValues, dirtyFields, reset, type, controlChannel]);

	// Reset waveform type adaptive but waveform is not compatible
	useEffect(() => {
		if (isAdaptive && !adaptiveWaveformTypes.includes(type)) {
			setValue(typeCN, null);
		}
	}, [isAdaptive, setValue, type, typeCN]);

	return (
		<>
			<input type="hidden" {...register(rateLimitCN)} />
			<Controller
				name={typeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						options={typeOptionsFiltered}
						label={t('Setpoint*')}
						error={errors?.type?.message}
						data-testid="waveFormType"
						disabled={isRunning}
					/>
				)}
			/>
			{controlChannel && type === WaveformType.RAMP && (
				<RampWaveformFormView
					controlChannel={controlChannel}
					measurements={measurements || testMeasurements || []}
					variables={testOrStationVars}
					formPrefix={formPrefix}
					errors={errors !== undefined ? (errors as WaveformRampFormErrors) : undefined}
					isRunning={isRunning}
					isControlStage={isControlStage}
				/>
			)}
			{type === WaveformType.DWELL && (
				<DwellWaveformFormView
					variables={testOrStationVars}
					formPrefix={formPrefix}
					errors={errors !== undefined ? (errors as WaveformDwellFormErrors) : undefined}
					isRunning={isRunning}
					isControlStage={isControlStage}
				/>
			)}
			{type === WaveformType.SINE && (
				<SinewaveWaveformFormView
					variables={testOrStationVars}
					errors={errors !== undefined ? (errors as WaveformSinewaveFormErrors) : undefined}
					feedbackUnit={controlChannelFeedbackUnit}
					formPrefix={formPrefix}
					isRunning={isRunning}
					isControlStage={isControlStage}
				/>
			)}
			{controlChannel && type === WaveformType.CONSTANT && (
				<ConstantWaveformFormView
					variables={testOrStationVars}
					errors={errors !== undefined ? (errors as WaveformConstantFormErrors) : undefined}
					controlChannel={controlChannel}
					formPrefix={formPrefix}
					isRunning={isRunning}
					isControlStage={isControlStage}
				/>
			)}
			{controlChannel && type === WaveformType.DUO_RAMP && (
				<DuotonicRampFormView
					controlChannel={controlChannel}
					measurements={measurements || testMeasurements || []}
					variables={testOrStationVars}
					formPrefix={formPrefix}
					errors={errors !== undefined ? (errors as WaveformDuoRampFormErrors) : undefined}
					isRunning={isRunning}
					isControlStage={isControlStage}
				/>
			)}
			{controlChannel && type === WaveformType.HAVER_SINE && (
				<HaversineFormView
					controlChannel={controlChannel}
					variables={testOrStationVars}
					formPrefix={formPrefix}
					errors={errors !== undefined ? (errors as WaveformHaversineFormErrors) : undefined}
					isRunning={isRunning}
					isControlStage={isControlStage}
				/>
			)}
			{controlChannel && type === WaveformType.DUO_HAVER_SINE && (
				<DuotonicHaversineFormView
					controlChannel={controlChannel}
					variables={testOrStationVars}
					formPrefix={formPrefix}
					errors={errors !== undefined ? (errors as WaveformDuoHaversineFormErrors) : undefined}
					isRunning={isRunning}
					isControlStage={isControlStage}
				/>
			)}
			{controlChannel && type === WaveformType.TAPERED_SINE && (
				<TaperedSineFormView
					controlChannel={controlChannel}
					variables={testOrStationVars}
					formPrefix={formPrefix}
					errors={errors !== undefined ? (errors as WaveformTaperedSineFormErrors) : undefined}
					isRunning={isRunning}
					isControlStage={isControlStage}
				/>
			)}
			{controlChannel && type === WaveformType.SQUAREWAVE && (
				<SquarewaveFormView
					controlChannel={controlChannel}
					variables={testOrStationVars}
					formPrefix={formPrefix}
					errors={errors !== undefined ? (errors as WaveformSquareSineFormErrors) : undefined}
					isRunning={isRunning}
					isControlStage={isControlStage}
				/>
			)}
			{controlChannel && type === WaveformType.SWEEP_SINE && (
				<SweepSineFormView
					controlChannel={controlChannel}
					variables={testOrStationVars}
					formPrefix={formPrefix}
					errors={errors !== undefined ? (errors as WaveformSweepSineFormErrors) : undefined}
					isRunning={isRunning}
					isControlStage={isControlStage}
				/>
			)}
		</>
	);
};

export default WaveformContainer;
