import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { CrudActions } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { DashboardConfigurationActions } from '../../testDashboardConfiguration.enums';
import styles from './GraphTable.module.scss';
import { GraphListItem } from '../../testDashboardConfiguration.types';

interface IGraphTableProps {
	onAction: (type: DashboardConfigurationActions, data?: GraphListItem) => void;
	data?: GraphListItem[];
	isLoading: boolean;
}

const GraphTable: React.FC<IGraphTableProps> = ({ data, isLoading, onAction }) => {
	const { t } = useTranslation('dashboardConfiguration');

	const transColumns = useMemo<ITableColumns[]>(() => {
		return [
			{ field: 'name', header: t('Graph Name') },
			{ field: 'graphType', header: t('Graph Type') },
			{ field: 'dateRate', header: t('Date Rate') }
		];
	}, [t]);

	const createMenuItems = useCallback(
		(data: GraphListItem) => [
			{
				label: t('Duplicate'),
				disabled: true,
				icon: 't-icon-duplicate',
				command: () => onAction(DashboardConfigurationActions.DUPLICATE, data)
			},
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(DashboardConfigurationActions.DELETE, data)
			}
		],
		[onAction, t]
	);

	const handleCreate = useCallback(() => {
		onAction(DashboardConfigurationActions.CREATE);
	}, [onAction]);

	const columnComponents = transColumns.map((col) => <DataTable.Column sortable resizeable key={col.field} {...col} />);

	return (
		<>
			<DataTable
				className={styles.dashboardTable}
				value={data}
				loading={isLoading}
				dataKey="id"
				showGridlines
				columnResizeMode="fit"
			>
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(DashboardConfigurationActions.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
			<div className={styles.tableFooter}>
				<CrudActions onCreate={handleCreate} entityLabel={t('Graph')} />
			</div>
		</>
	);
};

export default React.memo(GraphTable);
