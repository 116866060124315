import {
	ControlStageRequest,
	StopControlStage,
	UpdateConfigRequest
} from '@tactun/grpc-client/src/grpc/control_stage_pb';
import { AxisInfo } from '@tactun/grpc-client/src/grpc/common_pb';
import { Converter } from '../../types';
import { ControlChannelResponseDto, ControlChannelRequestDto, ControlChannelSetPointType } from '../ControlChannels';
import { AxisConfigurationFormType, ControlStageFormType } from './controlStage.types';
import { waveformFormToGRPCRequestConverter, waveformResponseToFormConverter } from '../Waveforms';
import {
	controlAlgorithmsFormTypeToAxisInfo,
	formControlAlgorithmsExternalConverter
} from '../ControlAlgorithms/controlAlgorithms.converters';
import { responseControlAlgorithmsFormConverter } from '../ControlAlgorithms';
import { updateConfigTypesMap } from './controlStage.const';
import { TestStageAxisConfigResponseDto } from '../Tests';

export const controlStageFormToControlStageStartConverter: Converter<ControlStageFormType, ControlStageRequest> = (
	controlStage
) => {
	const startRequest = new ControlStageRequest();
	startRequest.setStationId(controlStage.stationId as string);

	const axes = controlStage.axesConfigs.map((ac) => {
		const axis = new AxisInfo();
		axis.setAxisId(ac.axisId);
		axis.setAxisIndex(ac.axisIndex);
		axis.setSpType(ac.setPointType);
		if (ac.feedbackMeasurementId) axis.setFbMeasurement(ac.feedbackMeasurementId);
		if (ac.setPointMeasurementId) axis.setSpMeasurement(ac.setPointMeasurementId);

		axis.setWfProps(waveformFormToGRPCRequestConverter(ac.waveform));

		if (ac.controlAlgorithmsForm) controlAlgorithmsFormTypeToAxisInfo(ac.controlAlgorithmsForm, axis);

		return axis;
	});
	startRequest.setAxesList(axes);

	return startRequest;
};

export const controlStageFormToControlStageStopConverter: Converter<ControlStageFormType, StopControlStage> = (
	controlStage
) => {
	const stopRequest = new StopControlStage();
	const axes = controlStage.axesConfigs.map((ac) => ac.axisIndex);
	stopRequest.setAxisIndexList(axes);

	return stopRequest;
};

export const axesConfigToControlStageUpdateConverter = (
	axisConfig: AxisConfigurationFormType,
	changedKey: string,
	stationId: string
) => {
	const updateRequest = new UpdateConfigRequest();

	updateRequest.setStationId(stationId);
	updateRequest.setType(updateConfigTypesMap[changedKey] || 0);

	const axis = new AxisInfo();
	axis.setAxisId(axisConfig.axisId);
	axis.setAxisIndex(axisConfig.axisIndex);
	axis.setSpType(axisConfig.setPointType);
	if (axisConfig.feedbackMeasurementId) axis.setFbMeasurement(axisConfig.feedbackMeasurementId);
	if (axisConfig.setPointMeasurementId) {
		axis.setSpMeasurement(axisConfig.setPointMeasurementId);
	}

	axis.setWfProps(waveformFormToGRPCRequestConverter(axisConfig.waveform));

	if (axisConfig.controlAlgorithmsForm) controlAlgorithmsFormTypeToAxisInfo(axisConfig.controlAlgorithmsForm, axis);

	updateRequest.setAxis(axis);

	return updateRequest;
};

export const controlChannelToAxisConfigurationForm = (
	controlChannel: ControlChannelResponseDto,
	axisConfiguration: AxisConfigurationFormType,
	replaceControlAlgWithChannelValues: boolean
): AxisConfigurationFormType => {
	const axisConf = axisConfiguration || ({} as AxisConfigurationFormType);

	axisConf.controlChannelName = controlChannel.name;
	axisConf.stationId = controlChannel.stationId;
	axisConf.setPointType = controlChannel.setPointType as ControlChannelSetPointType;
	axisConf.setPointMeasurementId = controlChannel.setPointMeasurement?.id;
	axisConf.feedbackMeasurementId = controlChannel.feedbackMeasurement?.id;
	axisConf.rateLimit = controlChannel.rateLimit;

	// if the axis has its own algorithm configuration do not overwrite with channel configs
	if (controlChannel.controlAlgorithm && replaceControlAlgWithChannelValues) {
		axisConf.controlAlgorithmsForm = responseControlAlgorithmsFormConverter(controlChannel.controlAlgorithm);
	}

	return axisConf;
};

export const testStageAxisToAxisConfigurationForm: Converter<
	TestStageAxisConfigResponseDto,
	AxisConfigurationFormType
> = (axisConf) => {
	const controlAlgorithmsForm = responseControlAlgorithmsFormConverter(axisConf.controlAlgorithm);
	if (axisConf.useStationParams) {
		const stationControlAlg = responseControlAlgorithmsFormConverter(axisConf.cc.controlAlgorithm);
		controlAlgorithmsForm.baseAlgorithm = stationControlAlg.baseAlgorithm;
		controlAlgorithmsForm.pid = stationControlAlg.pid;
		controlAlgorithmsForm.asymmetricPid = stationControlAlg.asymmetricPid;
	}
	return {
		axisId: axisConf.axis.id,
		axisName: axisConf.axis.name,
		axisIndex: axisConf.axis.axisIndex,
		controlChannelId: axisConf.cc.id,
		controlChannelName: axisConf.cc.name,
		rateLimit: axisConf.cc.rateLimit,
		stationId: axisConf.cc.stationId,
		setPointType: ControlChannelSetPointType.WAVEFORM,
		setPointMeasurementId: axisConf.cc.setPointMeasurement?.id,
		feedbackMeasurementId: axisConf.cc.feedbackMeasurement?.id,
		waveform: waveformResponseToFormConverter(axisConf.wf),
		controlAlgorithmsForm
	};
};

export const axisConfigurationFormToControlChannelRequest = (
	axisConf: AxisConfigurationFormType
): ControlChannelRequestDto => {
	return {
		name: axisConf.controlChannelName,
		axisId: axisConf.axisId,
		stationId: axisConf.stationId,
		setPointType: axisConf.setPointType,
		setPointMeasurementId: axisConf.setPointMeasurementId,
		feedbackMeasurementId: axisConf.feedbackMeasurementId,
		rateLimit: axisConf.rateLimit,
		controlAlgorithm: axisConf.controlAlgorithmsForm
			? formControlAlgorithmsExternalConverter(axisConf.controlAlgorithmsForm)
			: undefined
	};
};
