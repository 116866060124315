import { useMemo, useRef } from 'react';
import { PageLayout, Form, InputText, Dropdown, Button, BottomBar, InfoBadge, InfoBadgeIcons } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { WidgetControlAxisForm, WidgetControlTestForm, WidgetForm } from '../../widgetsConfiguration.types';
import { IListItemNumber } from '../../../../types';
import ControlTestForm from '../ControlTestForm';
import styles from './WidgetConfiguration.module.scss';
import { WidgetsTypes } from '../../widgetsConfiguration.enums';
import ControlAxisForm from '../ControlAxisForm';

interface WidgetConfigurationProps {
	isCreate: boolean;
	isLoading: boolean;
	form: UseFormReturn<WidgetForm>;
	onBack: () => void;
	onSave: () => void;
	types: IListItemNumber[];
	stationName: string;
}

const WidgetConfiguration: React.FC<WidgetConfigurationProps> = ({
	isCreate,
	types,
	isLoading,
	form,
	onBack,
	onSave,
	stationName
}) => {
	const { t } = useTranslation('widgets');
	const formId = useRef('widgetsForm');
	const {
		control,
		formState: { errors },
		register,
		watch
	} = form;
	const type = watch('type');

	const title = useMemo<string>(() => {
		return isCreate ? t('Create Widget') : t('Edit Widget');
	}, [isCreate, t]);

	return (
		<>
			<PageLayout info={stationName}>
				<PageLayout.Header title={title} onBack={onBack} />
				<Form className={styles.container} onSubmit={onSave} id={formId.current}>
					<input type="hidden" {...register('stationId')} />
					<Controller
						name="name"
						control={control}
						render={({ field }) => (
							<InputText data-testid="nameId" label={t('Name*')} {...field} error={errors.name?.message} disabled />
						)}
					/>
					<Controller
						name="type"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								data-testid="type"
								options={types}
								label={t('Type*')}
								error={errors.type?.message}
								filter
								disabled
							/>
						)}
					/>
					<div className={styles.accordionContainer}>
						{type === WidgetsTypes.CONTROL_TEST && (
							<ControlTestForm form={form as UseFormReturn<WidgetControlTestForm>} />
						)}
						{type === WidgetsTypes.CONTROL_AXIS && (
							<ControlAxisForm form={form as UseFormReturn<WidgetControlAxisForm>} />
						)}
					</div>
				</Form>
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button data-testid="cancelBtn" label={t('Cancel')} variant="contained" color="success" onClick={onBack} />
				<Button
					data-testid="saveBtn"
					label={t('Save')}
					variant="contained"
					color="secondary"
					type="submit"
					form={formId.current}
				/>
			</BottomBar>
		</>
	);
};

export default WidgetConfiguration;
