export enum ProductCrudActions {
	EDIT = 'EDIT_PRODUCT',
	CREATE = 'CREATE_PRODUCT',
	DUPLICATE = 'DUPLICATE_PRODUCT',
	DELETE = 'DELETE_PRODUCT',
	BUILD = 'BUILD_PRODUCT',
	DOWNLOAD = 'DOWNLOAD_PRODUCT'
}

export enum ProductStatuses {
	PENDING,
	BUILD_IN_PROGRESS,
	READY_FOR_DOWNLOAD
}

export enum ProductPlatforms {
	WINDOWS = 0,
	LINUX,
	MACOS
}
