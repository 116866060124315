import React from 'react';
import { useTranslation } from 'react-i18next';
import { UnitResponse } from '../../units.types';
import { UnitSystemTypes } from '../../units.enums';
import styles from './UnitTableRowContent.module.scss';

interface UnitTableRowContentProps {
	units: UnitResponse[];
}

const UnitTableRowContent: React.FC<UnitTableRowContentProps> = ({ units }) => {
	const { t } = useTranslation('units');

	return (
		<div className={styles.bodyWrap}>
			{units.map((unit) => (
				<div className={styles.row}>
					<div className={styles.cell}>
						<div className={styles.label}>{t('Unit: ')}</div>
						<div className={styles.value}>{unit.name}</div>
					</div>
					<div className={styles.cell}>
						<div className={styles.label}>{t('Scale: ')}</div>
						<div className={styles.value}>{unit.scale}</div>
					</div>
					<div className={styles.cell}>
						<div className={styles.label}>{t('Unit System: ')}</div>
						<div className={styles.value}>{t(UnitSystemTypes[unit.systemType])}</div>
					</div>
					<div className={styles.cell}>
						<div className={styles.label}>{t('Default: ')}</div>
						<div className={styles.value}>{unit.isDefault ? t('Yes') : t('No')}</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default React.memo(UnitTableRowContent);
