import { useCallback, useMemo, useState } from 'react';
import { ModalContext } from '../modal.context';

export interface ModalContextProviderProps {
	children: React.ReactNode;
}

const ModalContextProvider: React.FC<ModalContextProviderProps> = ({ children }) => {
	const [modalData, setModalData] = useState(new Map());

	const removeModalData = useCallback((id: string) => {
		setModalData((data) => {
			const newData = new Map(data);
			newData.delete(id);
			return newData;
		});
	}, []);

	const updateModalData = useCallback((id: string, data: any) => {
		setModalData((current) => {
			const newData = new Map(current);
			newData.set(id, data);
			return newData;
		});
	}, []);

	const state = useMemo(
		() => ({
			data: modalData,
			updateModalData,
			removeModalData
		}),
		[modalData, removeModalData, updateModalData]
	);

	return <ModalContext.Provider value={state}>{children}</ModalContext.Provider>;
};

export default ModalContextProvider;
