import { InputNumber } from '@tactun/ui';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { RuleAction } from '../../actions.types';
import { useTranslation } from 'react-i18next';
import { Properties } from '../../actions.enums';

export interface DigitalOutputActionFormProps {
	form: UseFormReturn<RuleAction>;
}

const DigitalOutputActionForm: FC<DigitalOutputActionFormProps> = ({ form }) => {
	const { t } = useTranslation();
	const {
		control,
		watch,
		formState: { errors }
	} = form;

	const property = watch('property');

	return (
		<>
			{property === Properties.PULSE_START && (
				<>
					<Controller
						name="frequency"
						control={control}
						render={({ field }) => (
							<InputNumber {...field} label={t('Frequency*')} isInteger error={errors.frequency?.message} />
						)}
					/>
					<Controller
						name="numberOfPulses"
						control={control}
						render={({ field }) => (
							<InputNumber {...field} label={t('Number of Pulses*')} error={errors.numberOfPulses?.message} />
						)}
					/>
				</>
			)}
		</>
	);
};

export default DigitalOutputActionForm;
