import React, { useEffect, useMemo } from 'react';
import {
	PageLayout,
	Form,
	InputText,
	InputNumber,
	Dropdown,
	Button,
	Switch,
	BottomBar,
	InfoBadge,
	InfoBadgeIcons,
	useIsChanged
} from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { StationChannelFormType, ControllerChannelMeta } from '../../stationChannels.types';
import { IListItem, IListItemNumber } from '../../../../types';
import { isFieldVisible } from './stationChannelFieldMapping';
import { ChannelType, Direction, SpecificType } from '../../stationChannels.enums';
import { getLimitUnitByRange, getOffsetUnitByRange, offsetFormatter } from '../../stationChannels.tools';
import { Range } from '../../stationChannels.enums';
import styles from './StationChannel.module.scss';

interface IStationChannelProps {
	isCreate: boolean;
	isLoading: boolean;
	isStationChannelInUse?: boolean;
	form: UseFormReturn<StationChannelFormType>;
	channels?: IListItem[];
	channelData?: ControllerChannelMeta[];
	onBack: () => void;
	onSave: () => void;
	stationName: string;
}

const StationChannel: React.FC<IStationChannelProps> = ({
	channels = [],
	channelData = [],
	isLoading,
	isCreate,
	form,
	onBack,
	onSave,
	isStationChannelInUse,
	stationName
}) => {
	const { t } = useTranslation('stationChannel');
	const {
		control,
		formState: { errors, isDirty },
		watch,
		register,
		trigger,
		setValue,
		reset
	} = form;
	const type = watch('type', undefined);
	const direction = watch('direction');
	const channelId = watch('channelId');
	const specificType = watch('specificType');
	const range = watch('range');
	const frequency = watch('frequency');
	const offset = watch('offset');
	const limit = watch('limit');
	const bouncingCancellation = watch('bouncingCancellation');

	const isFrequencyChanged = useIsChanged(frequency);
	useEffect(() => {
		if (isFrequencyChanged) {
			trigger('frequency');
		}
	}, [isFrequencyChanged, trigger]);

	const title = useMemo<string>(() => {
		return isCreate ? t('Create Channel') : t('Edit Channel');
	}, [isCreate, t]);

	const channelTypes = useMemo<IListItemNumber[]>(
		() =>
			channelData?.map((ct) => ({
				label: t(ChannelType[ct.stationChannelType]),
				value: ct.stationChannelType
			})) || [],

		[t, channelData]
	);

	const channelMeta = useMemo<ControllerChannelMeta | undefined>(() => {
		return channelData?.find((ct) => ct.stationChannelType === type);
	}, [channelData, type]);

	const limitUnit = useMemo(() => getLimitUnitByRange(range), [range]);
	const offsetUnit = useMemo(() => getOffsetUnitByRange(range), [range]);

	const isRangeVisible = useMemo(() => isFieldVisible('range', type) && channelId, [type, channelId]);

	const isSpecificTypeChanged = useIsChanged(specificType);
	useEffect(() => {
		if (isRangeVisible && isSpecificTypeChanged && isDirty) {
			setValue('offset', 0);
			setValue('limit', 0);
			setValue('range', null);
		}
	}, [isSpecificTypeChanged, isRangeVisible, isDirty, setValue, reset]);

	const rangeOptions = useMemo(() => {
		if (isRangeVisible && channelMeta && specificType !== undefined) {
			switch (specificType) {
				case SpecificType.CURRENT:
					return channelMeta.currentRanges;
				case SpecificType.DIFFERENTIAL:
					return channelMeta.differentialRanges;
				case SpecificType.SINGLE_ENDED:
					return channelMeta.singleEndedRanges;
				case SpecificType.VOLTAGE:
					return channelMeta.voltageRanges;
				default:
					return channelMeta.ranges;
			}
		} else if (type === ChannelType.ANALOG_OUT_100MA) {
			return channelMeta?.currentRanges ?? [];
		} else {
			return channelMeta?.ranges ?? [];
		}
	}, [isRangeVisible, channelMeta, specificType, type]);

	const rangeLabel = useMemo(() => {
		if (isRangeVisible) {
			switch (specificType) {
				case SpecificType.CURRENT:
					return t('Current Range*');
				case SpecificType.DIFFERENTIAL:
					return t('Differential Range*');
				case SpecificType.SINGLE_ENDED:
					return t('Single-Ended Range*');
				case SpecificType.VOLTAGE:
					return t('Voltage Range*');
				default:
					return t('Range*');
			}
		}
	}, [isRangeVisible, specificType, t]);

	const isOffsetVisible = useMemo(
		() => isFieldVisible('offset', type) && channelId && range !== undefined && range !== null,
		[type, channelId, range]
	);
	const isDirectionVisible = useMemo(() => isFieldVisible('direction', type) && channelId, [type, channelId]);
	const isActiveVisible = useMemo(() => isFieldVisible('activeState', type) && channelId, [type, channelId]);
	const isFrequencyVisible = useMemo(() => isFieldVisible('frequency', type) && channelId, [type, channelId]);
	const isLimitVisible = useMemo(
		() => isFieldVisible('limit', type) && channelId && range !== undefined && range !== null,
		[type, channelId, range]
	);
	const isPullUpResistorVisible = useMemo(
		() => isFieldVisible('pullUpResistor', type) && channelId && direction === Direction.INPUT,
		[type, channelId, direction]
	);
	const isBouncingCancellationVisible = useMemo(
		() => isFieldVisible('bouncingCancellation', type) && channelId && direction === Direction.INPUT,
		[channelId, direction, type]
	);
	const isBouncingLatencyVisible = useMemo(() => bouncingCancellation, [bouncingCancellation]);
	const isDefaultStateVisible = useMemo(() => {
		const isVisibleByType = isFieldVisible('defaultState', type) && channelId;

		if (type === ChannelType.DIGITAL_IO_5V || type === ChannelType.DIGITAL_IO_24V) {
			return isVisibleByType && direction === Direction.OUTPUT;
		} else {
			return isVisibleByType;
		}
	}, [type, direction, channelId]);
	const isBridgeTypeVisible = useMemo(() => isFieldVisible('bridgeType', type) && channelId, [type, channelId]);
	const isExcitationVoltageVisible = useMemo(
		() => isFieldVisible('excitationVoltage', type) && channelId,
		[type, channelId]
	);
	const isGainVisible = useMemo(() => isFieldVisible('gain', type) && channelId, [type, channelId]);
	const isPgaGainVisible = useMemo(() => isFieldVisible('pgaGain', type) && channelId, [type, channelId]);
	const isSamplingRateVisible = useMemo(() => isFieldVisible('samplingRate', type) && channelId, [type, channelId]);
	const isSpecificTypeVisible = useMemo(() => isFieldVisible('specificType', type) && channelId, [type, channelId]);
	const isExcitationAmplitudeVisible = useMemo(
		() => isFieldVisible('excitationAmplitude', type) && channelId,
		[type, channelId]
	);
	const isExcitationFrequencyVisible = useMemo(
		() => isFieldVisible('excitationFrequency', type) && channelId,
		[type, channelId]
	);

	const isRangeChanged = useIsChanged(range);
	useEffect(() => {
		if (isOffsetVisible) {
			if (isRangeChanged || offset || offset === 0) {
				trigger('offset');
			}
		}
	}, [isOffsetVisible, isRangeChanged, offset, trigger]);

	useEffect(() => {
		setValue('maxPWMFrequency', channelMeta?.maxPWMFrequency);
	}, [channelMeta?.maxPWMFrequency, setValue]);

	useEffect(() => {
		if (!bouncingCancellation) {
			setValue('bouncingLatency', undefined);
		}
	}, [bouncingCancellation, setValue]);

	useEffect(() => {
		if (isLimitVisible && range !== undefined && range !== null && isRangeChanged && isDirty) {
			const { max } = offsetFormatter(Range[range]);

			let limitCurrent = max !== undefined && limit !== undefined && (limit > max || limit === 0) ? max : limit;

			setValue('limit', limitCurrent);
		}
	}, [isDirty, isLimitVisible, isRangeChanged, limit, range, setValue]);

	return (
		<>
			<PageLayout info={stationName}>
				<PageLayout.Header title={title} onBack={onBack} />
				<Form className={styles.container} onSubmit={onSave} id="stationChannelForm">
					<input type="hidden" {...register('id')} />
					<input type="hidden" {...register('stationId')} />
					<input type="hidden" {...register('maxPWMFrequency')} />
					<Controller
						name="name"
						control={control}
						render={({ field }) => (
							<InputText data-testid="nameId" label={t('Channel name*')} {...field} error={errors.name?.message} />
						)}
					/>
					<Controller
						name="type"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={channelTypes}
								disabled={isStationChannelInUse}
								label={t('Channel type*')}
								error={errors.type?.message}
								data-testid="type"
								filter
							/>
						)}
					/>
					<Controller
						name="channelId"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={channels}
								label={t('Controller channel number*')}
								data-testid="channelId"
								error={errors.channelId?.message}
								filter
							/>
						)}
					/>
					{isDirectionVisible && (
						<Controller
							name="direction"
							control={control}
							render={({ field }) => (
								<Dropdown
									label={t('Direction*')}
									{...field}
									options={channelMeta?.directions}
									disabled={isStationChannelInUse}
									error={errors.direction?.message}
									data-testid="direction"
									filter
								/>
							)}
						/>
					)}
					{isBridgeTypeVisible && (
						<Controller
							name="bridgeType"
							control={control}
							render={({ field }) => (
								<Dropdown
									{...field}
									options={channelMeta?.bridgeTypes}
									label={t('Bridge type*')}
									data-testid="bridgeType"
									error={errors.bridgeType?.message}
								/>
							)}
						/>
					)}
					{isExcitationVoltageVisible && (
						<Controller
							name="excitationVoltage"
							control={control}
							render={({ field }) => (
								<Dropdown
									{...field}
									options={channelMeta?.excitationVoltages}
									label={t('Excitation voltage*')}
									data-testid="excitationVoltage"
									error={errors.excitationVoltage?.message}
								/>
							)}
						/>
					)}
					{isGainVisible && (
						<Controller
							name="gain"
							control={control}
							render={({ field }) => (
								<Dropdown
									{...field}
									data-testid="gain"
									options={channelMeta?.gains}
									label={t('ADC Gain*')}
									error={errors.gain?.message}
								/>
							)}
						/>
					)}
					{isPgaGainVisible && (
						<Controller
							name="pgaGain"
							control={control}
							render={({ field }) => (
								<Dropdown
									{...field}
									data-testid="pgaGain"
									options={channelMeta?.pgaGains}
									label={t('PGA Gain*')}
									error={errors.pgaGain?.message}
								/>
							)}
						/>
					)}
					{isSamplingRateVisible && (
						<Controller
							name="samplingRate"
							control={control}
							render={({ field }) => (
								<Dropdown
									label={t('Sampling rate*, S/s')}
									{...field}
									options={channelMeta?.samplingRates}
									error={errors.samplingRate?.message}
									data-testid="samplingRate"
									filter
								/>
							)}
						/>
					)}
					{/* Only one range per specific type. So we can place all ranges in one form row. */}

					{isSpecificTypeVisible && (
						<Controller
							name="specificType"
							control={control}
							render={({ field }) => (
								<Dropdown
									label={t('Type*')}
									{...field}
									error={errors.specificType?.message}
									options={channelMeta?.types}
									data-testid="specificType"
									filter
								/>
							)}
						/>
					)}
					{isRangeVisible && (
						<Controller
							name="range"
							control={control}
							render={({ field }) => (
								<Dropdown
									label={rangeLabel}
									data-testid="range"
									{...field}
									options={rangeOptions}
									error={errors.range?.message}
									filter
								/>
							)}
						/>
					)}

					{isFrequencyVisible && (
						<Controller
							name="frequency"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('PWM Frequency, kHz')}
									data-testid="frequency"
									error={errors.frequency?.message}
								/>
							)}
						/>
					)}
					{isActiveVisible && (
						<Controller
							name="activeState"
							control={control}
							render={({ field }) => (
								<Dropdown
									label={t('Active State*')}
									{...field}
									options={channelMeta?.activeStates}
									error={errors.activeState?.message}
									data-testid="activeState"
									filter
								/>
							)}
						/>
					)}
					{isOffsetVisible && (
						<Controller
							name="offset"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Offset*, ' + offsetUnit)}
									data-testid="offset"
									error={errors.offset?.message}
								/>
							)}
						/>
					)}
					{isExcitationAmplitudeVisible && (
						<Controller
							name="excitationAmplitude"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Excitation Amplitude*, V')}
									data-testid="excitationAmplitude"
									error={errors.excitationAmplitude?.message}
								/>
							)}
						/>
					)}
					{isExcitationFrequencyVisible && (
						<Controller
							name="excitationFrequency"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Excitation Frequency*, kHz')}
									data-testid="excitationFrequency"
									error={errors.excitationFrequency?.message}
								/>
							)}
						/>
					)}
					{isLimitVisible && (
						<Controller
							name="limit"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Output Limit*, ' + limitUnit)}
									error={errors.limit?.message}
									data-testid="limit"
								/>
							)}
						/>
					)}
					{isDefaultStateVisible && (
						<Controller
							name="defaultState"
							control={control}
							render={({ field }) => (
								<Dropdown
									label={t('Default State*')}
									{...field}
									options={channelMeta?.defaultStates}
									error={errors.defaultState?.message}
									data-testid="defaultState"
									filter
								/>
							)}
						/>
					)}
					{isPullUpResistorVisible && (
						<Controller
							name="pullUpResistor"
							control={control}
							render={({ field }) => (
								<Switch
									inputId={field.name}
									onChange={(e) => field.onChange(e.value)}
									checked={!!field.value}
									data-testid="pullUpResistor"
									className={styles.switchCustomForm}
									label={t('Pullup resistor')}
								/>
							)}
						/>
					)}
					{isBouncingCancellationVisible && (
						<>
							<Controller
								name="bouncingCancellation"
								control={control}
								render={({ field }) => (
									<Switch
										inputId={field.name}
										onChange={(e) => field.onChange(e.value)}
										checked={!!field.value}
										data-testid="bouncingCancellation"
										className={styles.switchCustomForm}
										label={t('Bouncing Cancellation')}
									/>
								)}
							/>
							{isBouncingLatencyVisible && (
								<Controller
									name="bouncingLatency"
									control={control}
									render={({ field }) => (
										<InputNumber
											{...field}
											label={t('Bouncing Latency*, nsec')}
											data-testid="bouncingLatency"
											error={errors.bouncingLatency?.message}
										/>
									)}
								/>
							)}
						</>
					)}
				</Form>
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button data-testid="cancelBtn" label={t('Cancel')} variant="contained" color="success" onClick={onBack} />
				<Button
					data-testid="saveBtn"
					label={t('Save')}
					variant="contained"
					color="secondary"
					type="submit"
					form="stationChannelForm"
				/>
			</BottomBar>
		</>
	);
};

export default React.memo(StationChannel);
