import {
	TriggerAuxDeviceProperties,
	TriggerAxisProperties,
	TriggerConnectionProperties,
	TriggerDigitalInputProperties,
	TriggerMeasurementProperties,
	TriggerObjectTypes,
	TriggerSpecimenProperties,
	TriggerStationProperties,
	TriggerTestProperties,
	TriggerUIButtonProperties
} from './triggers.enums';

export const stationTriggers = {
	[TriggerObjectTypes.DIGITAL_INPUT]: [TriggerDigitalInputProperties.ON, TriggerDigitalInputProperties.OFF],
	[TriggerObjectTypes.STATION]: [
		TriggerStationProperties.IN_TEST,
		TriggerStationProperties.IDLE,
		TriggerStationProperties.ACTIVE,
		TriggerStationProperties.SAFE
	],
	[TriggerObjectTypes.TEST]: [
		TriggerTestProperties.STARTED,
		TriggerTestProperties.PAUSED,
		TriggerTestProperties.RESUMED,
		TriggerTestProperties.STOPPED
	],
	[TriggerObjectTypes.CONNECTION]: [TriggerConnectionProperties.CONNECTED, TriggerConnectionProperties.DISCONNECTED],
	[TriggerObjectTypes.AUX_DEVICE]: [TriggerAuxDeviceProperties.ON, TriggerAuxDeviceProperties.OFF],
	[TriggerObjectTypes.MEASUREMENT]: [
		TriggerMeasurementProperties.MORE_THAN,
		TriggerMeasurementProperties.LESS_THAN,
		TriggerMeasurementProperties.IN_RANGE,
		TriggerMeasurementProperties.OUT_OF_RANGE
	],
	[TriggerObjectTypes.AXIS]: [TriggerAxisProperties.COMPLETED]
};

export const buttonTriggers = {
	...stationTriggers,
	[TriggerObjectTypes.UI_BUTTON]: [TriggerUIButtonProperties.PRESSED, TriggerUIButtonProperties.RELEASED]
};

export const testStageTriggers = {
	[TriggerObjectTypes.DIGITAL_INPUT]: [TriggerDigitalInputProperties.ON, TriggerDigitalInputProperties.OFF],
	[TriggerObjectTypes.AUX_DEVICE]: [TriggerAuxDeviceProperties.ON, TriggerAuxDeviceProperties.OFF],
	[TriggerObjectTypes.SPECIMEN]: [TriggerSpecimenProperties.BREAK, TriggerSpecimenProperties.YIELD],
	[TriggerObjectTypes.MEASUREMENT]: [
		TriggerMeasurementProperties.MORE_THAN,
		TriggerMeasurementProperties.LESS_THAN,
		TriggerMeasurementProperties.OUT_OF_RANGE,
		TriggerMeasurementProperties.IN_RANGE,
		TriggerMeasurementProperties.MEAN_CHANGE,
		TriggerMeasurementProperties.AMPLITUDE_CHANGE
	]
};

export const testGlobalTriggers = {
	[TriggerObjectTypes.DIGITAL_INPUT]: [TriggerDigitalInputProperties.ON, TriggerDigitalInputProperties.OFF],
	[TriggerObjectTypes.CONNECTION]: [TriggerConnectionProperties.CONNECTED, TriggerConnectionProperties.DISCONNECTED],
	[TriggerObjectTypes.AUX_DEVICE]: [TriggerAuxDeviceProperties.ON, TriggerAuxDeviceProperties.OFF],
	[TriggerObjectTypes.SPECIMEN]: [TriggerSpecimenProperties.BREAK, TriggerSpecimenProperties.YIELD],
	[TriggerObjectTypes.MEASUREMENT]: [
		TriggerMeasurementProperties.MORE_THAN,
		TriggerMeasurementProperties.LESS_THAN,
		TriggerMeasurementProperties.OUT_OF_RANGE,
		TriggerMeasurementProperties.IN_RANGE
	]
};
