import { FC, HTMLAttributes, ReactNode } from 'react';
import cx from 'classnames';

import styles from './ModalContent.module.scss';

export interface IModalContentProps extends HTMLAttributes<HTMLElement> {
	children: ReactNode;
}

const ModalContent: FC<IModalContentProps> = ({ className, children, ...props }) => {
	return (
		<div className={cx(styles.modalContent, className)} {...props}>
			{children}
		</div>
	);
};

ModalContent.displayName = 'ModalContent';

export default ModalContent;
