import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AddButton, Button, Dropdown, Form, Modal } from '@tactun/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { RuleTrigger, TriggerAxisProperties, TriggerOptions, triggerSchema, TriggerUIButtonProperties } from '../../';
import {
	TriggerAuxDeviceProperties,
	TriggerConnectionProperties,
	TriggerDigitalInputProperties,
	TriggerMeasurementProperties,
	TriggerObjectTypes,
	TriggerSpecimenProperties,
	TriggerStationProperties,
	TriggerTestProperties
} from '../../triggers.enums';
import TriggerCard from '../TriggerCard';
import { MeasurementResponseDto } from '../../../Measurements';
import { IStationChannelResponseDto } from '../../../StationChannels';
import { IAuxiliaryDeviceResponseDto } from '../../../AuxiliaryDevices';
import { useEntityOptions, useNumberEnumList } from '../../../../hooks';
import { IListItemNumber } from '../../../../types';
import styles from './Triggers.module.scss';
import MeasurementTriggerForm from '../MeasurementTriggerForm';
import { IAxisResponseDto } from '../../../Axes';
import { convertNumberEnumToList } from '../../../../tools';
import SpecimenTriggerForm from '../SpecimenTriggerForm';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';

const defaultValues: RuleTrigger = {
	objectId: null,
	objectType: null,
	property: null,
	lowerLimit: 0,
	upperLimit: 0
};

export interface TriggersProps {
	triggers?: RuleTrigger[];
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	digitalChannels: IStationChannelResponseDto[];
	auxDevices: IAuxiliaryDeviceResponseDto[];
	axes: IAxisResponseDto[];
	updateTriggers: (newTriggers: RuleTrigger[]) => void;
	triggerOptions: TriggerOptions;
	isError?: boolean;
}

const modalId = 'rule-trigger-form-modal';
const formId = 'rule-trigger-form';

const Triggers: FC<TriggersProps> = ({
	triggers = [],
	updateTriggers,
	measurements,
	digitalChannels,
	auxDevices,
	axes,
	triggerOptions,
	isError = false
}) => {
	const { t } = useTranslation('ruleTrigger');

	const form = useForm<RuleTrigger>({
		defaultValues,
		resolver: yupResolver(triggerSchema)
	});

	const {
		control,
		watch,
		handleSubmit,
		reset,
		register,
		setValue,
		formState: { dirtyFields, errors }
	} = form;

	const objectType = watch('objectType');

	// Modal
	const [modalState, setModalState] = useState<{ isOpen: boolean; index?: number }>({
		isOpen: false
	});

	const openModal = useCallback(
		(index?: number) => {
			reset(index !== undefined ? triggers[index] : defaultValues);
			setModalState({ isOpen: true, index: index });
		},
		[reset, triggers]
	);

	const closeModal = useCallback(() => {
		reset(defaultValues);
		setModalState({ isOpen: false });
	}, [reset]);

	const onSubmit = handleSubmit((validTrigger) => {
		const newTriggers = [...triggers];
		if (modalState.index !== undefined) {
			newTriggers.splice(modalState.index, 1, validTrigger);
		} else {
			newTriggers.push(validTrigger);
		}
		updateTriggers(newTriggers);
		closeModal();
	});

	const deleteTrigger = useCallback(
		(index: number) => {
			const newTriggers = [...triggers];
			newTriggers.splice(index, 1);
			updateTriggers(newTriggers);
		},
		[triggers, updateTriggers]
	);

	useEffect(() => {
		if (objectType !== null && dirtyFields.objectType) {
			reset({ ...defaultValues, objectType });
		}
	}, [dirtyFields.objectType, objectType, reset, setValue]);

	const typeAllOptions = useNumberEnumList(TriggerObjectTypes, 'ruleTrigger');
	const typeOptions = useMemo(() => {
		const numberFilters = Object.keys(triggerOptions).map((v) => Number(v));
		return typeAllOptions.filter(({ value }) => numberFilters.includes(value));
	}, [typeAllOptions, triggerOptions]);

	const objectIdLabel = useMemo<string>(() => {
		switch (objectType) {
			case TriggerObjectTypes.AXIS:
				return t('Axis*');
			case TriggerObjectTypes.AUX_DEVICE:
				return t('Auxiliary device*');
			case TriggerObjectTypes.DIGITAL_INPUT:
				return t('Digital input*');
			case TriggerObjectTypes.MEASUREMENT:
				return t('Measurement*');
			default:
				return '';
		}
	}, [objectType, t]);

	const axisOptions = useEntityOptions(axes);
	const digitalChannelsOptions = useEntityOptions(digitalChannels);
	const measurementsOptions = useEntityOptions(measurements);
	const auxDevicesOptions = useEntityOptions(auxDevices);

	const objectIdOptions = useMemo(() => {
		if (!objectIdLabel) {
			return [];
		}

		switch (objectType) {
			case TriggerObjectTypes.AXIS:
				return axisOptions;
			case TriggerObjectTypes.DIGITAL_INPUT:
				return digitalChannelsOptions;
			case TriggerObjectTypes.MEASUREMENT:
				return measurementsOptions;
			case TriggerObjectTypes.AUX_DEVICE:
				return auxDevicesOptions;
			default:
				throw new Error('This type does not have objects');
		}
	}, [objectIdLabel, objectType, axisOptions, digitalChannelsOptions, measurementsOptions, auxDevicesOptions]);

	const propertyOptions = useMemo<IListItemNumber[]>(() => {
		let allProperties: IListItemNumber[];
		switch (objectType) {
			case TriggerObjectTypes.DIGITAL_INPUT:
				allProperties = convertNumberEnumToList(TriggerDigitalInputProperties, t);
				break;
			case TriggerObjectTypes.TEST:
				allProperties = convertNumberEnumToList(TriggerTestProperties, t);
				break;
			case TriggerObjectTypes.AUX_DEVICE:
				allProperties = convertNumberEnumToList(TriggerAuxDeviceProperties, t);
				break;
			case TriggerObjectTypes.CONNECTION:
				allProperties = convertNumberEnumToList(TriggerConnectionProperties, t);
				break;
			case TriggerObjectTypes.MEASUREMENT:
				allProperties = convertNumberEnumToList(TriggerMeasurementProperties, t);
				break;
			case TriggerObjectTypes.STATION:
				allProperties = convertNumberEnumToList(TriggerStationProperties, t);
				break;
			case TriggerObjectTypes.SPECIMEN:
				allProperties = convertNumberEnumToList(TriggerSpecimenProperties, t);
				break;
			case TriggerObjectTypes.AXIS:
				allProperties = convertNumberEnumToList(TriggerAxisProperties, t);
				break;
			case TriggerObjectTypes.UI_BUTTON:
				allProperties = convertNumberEnumToList(TriggerUIButtonProperties, t);
				break;
			default:
				allProperties = [];
		}

		return objectType !== null && objectType !== undefined
			? allProperties.filter(({ value }) => triggerOptions[objectType]?.includes(value))
			: [];
	}, [objectType, triggerOptions, t]);

	const triggersNode = useMemo(() => {
		return triggers.map((trigger, index) => (
			<>
				<TriggerCard
					trigger={trigger}
					measurements={measurements}
					auxDevices={auxDevices}
					digitalChannels={digitalChannels}
					onEdit={() => {
						openModal(index);
					}}
					onDelete={() => deleteTrigger(index)}
					key={index}
				/>
				<div data-testid="ruleTriggerIf" className={styles.triggerLabel}>
					{index < triggers.length - 1 ? t('Or') : ''}
				</div>
			</>
		));
	}, [auxDevices, deleteTrigger, digitalChannels, measurements, openModal, t, triggers]);

	return (
		<>
			<div className="rulesLabel">{t('Rule trigger when*:')}</div>
			<div>
				{triggersNode}
				<AddButton data-testid="ruleTriggerOpenModal" type="button" onClick={() => openModal()} isError={isError} />
			</div>
			<Modal id={modalId} isOpen={modalState.isOpen} onClose={closeModal} isLarge hidePrevModals>
				<Modal.Header>{t('Add a Trigger')}</Modal.Header>
				<Modal.Content>
					<Form id={formId} onSubmit={onSubmit} className={styles.form}>
						<input type="hidden" {...register} />
						<Controller
							control={control}
							name="objectType"
							render={({ field }) => (
								<Dropdown
									{...field}
									label={t('Object type*')}
									options={typeOptions}
									data-testid="ruleObjectType"
									error={errors.objectType?.message}
								/>
							)}
						/>
						{objectIdLabel && (
							<Controller
								control={control}
								name="objectId"
								render={({ field }) => (
									<Dropdown
										{...field}
										label={objectIdLabel}
										options={objectIdOptions}
										error={errors.objectId?.message}
										data-testid="ruleObjectIdLabel"
									/>
								)}
							/>
						)}
						{objectType !== null && (
							<Controller
								control={control}
								name="property"
								render={({ field }) => (
									<Dropdown
										data-testid="ruleState"
										{...field}
										label={t('State*')}
										options={propertyOptions}
										error={errors.property?.message}
									/>
								)}
							/>
						)}

						{objectType === TriggerObjectTypes.MEASUREMENT && (
							<MeasurementTriggerForm measurements={measurements} form={form} testAxes={axes} />
						)}
						{objectType === TriggerObjectTypes.SPECIMEN && (
							<SpecimenTriggerForm measurements={measurements} form={form} />
						)}
					</Form>
				</Modal.Content>
				<Modal.Footer>
					<Button color="success" type="button" onClick={closeModal}>
						{t('Cancel')}
					</Button>
					<Button color="secondary" type="submit" form={formId}>
						{t('Save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Triggers;
