import { useNavigate, useParams } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { useTestReportConfig } from '../testReportConfiguration.hooks';
import { TestReportConfigurationForm, TestReportConfigurationRequest } from '../testReportConfiguration.types';
import { formRequestConverter, responseFormConverter } from '../testReportConfiguration.converters';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { testReportConfigurationSchema } from '../testReportConfiguration.schemas';
import { IStep, useWizard } from '@tactun/ui';
import { reportConfigurationPath } from '../../Tests/tests.const';
import { ReportFormat } from '../testReportConfiguration.enums';
import { useTest } from '../../Tests/tests.hooks';
import TestReportConfiguration from '../components/TestReportConfiguration';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { createTestReportConfig, updateTestReportConfig } from '../testReportConfiguration.api';

const defaultValues: Partial<TestReportConfigurationForm> = {
	additionalInformation: {
		includeTestStandard: false,
		includeTestDescription: false,
		includeSpecimenImage: false,
		includeSpecimenType: false,
		includeMaterial: false,
		includeBatch: false,
		includeGraphData: false,
		includeQrGeneration: false,
		customerInformation: ''
	},
	fileOutput: {
		outputFormat: ReportFormat.PDF,
		outputLocation: ''
	},
	companyLogo: '',
	companyLogoId: '',
	calculationResults: [],
	specimenParamsResults: {},
	variableResults: []
};

const TestReportConfigurationContainer: FC = () => {
	const { testId } = useParams();
	const { testDto } = useTest(testId);
	const navigate = useNavigate();
	const { reportConfig, isLoading } = useTestReportConfig(testId);

	const form = useForm<TestReportConfigurationForm>({
		defaultValues: defaultValues,
		mode: 'onSubmit',
		//@ts-ignore // TODO: fix this when implement report configuration
		resolver: yupResolver(testReportConfigurationSchema)
	});
	const { handleSubmit, reset } = form;

	const saveMutation = useMutation({
		mutationFn: (data: TestReportConfigurationRequest) => {
			return reportConfig ? updateTestReportConfig(data) : createTestReportConfig(data);
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const { beforeNextStep } = useWizard();
	beforeNextStep((step: IStep) => {
		if (step.path === reportConfigurationPath) {
			return new Promise<boolean>((resolve) => {
				handleSubmit(
					async (data) => {
						try {
							const params = formRequestConverter(data);
							await saveMutation.mutateAsync(params);
							resolve(true);
							navigate('/tests');
						} catch (error) {
							resolve(false);
						}
					},
					() => {
						resolve(false);
					}
				)();
			});
		}
		return Promise.resolve(true);
	});

	useEffect(() => {
		if (!isLoading) {
			reset(
				reportConfig
					? responseFormConverter(reportConfig)
					: {
							...defaultValues,
							pipelineUuid: testId
					  }
			);
		}
	}, [isLoading, reportConfig, reset, testId]);

	return !isLoading && testDto ? <TestReportConfiguration test={testDto} form={form} /> : null;
};

export default TestReportConfigurationContainer;
