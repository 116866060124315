import React, { useMemo } from 'react';
import { Button } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { ITableColumns } from '../../../../types';
import { useTableConfigs } from '../../../../hooks';
import { VariableListItem, VariablesTable, VariableTableActionTypes } from '../../../Variables';
import styles from './TestVariablesTable.module.scss';

interface ITestVariablesTableProps {
	onAction: (type: VariableTableActionTypes, data?: VariableListItem) => void;
	data?: VariableListItem[];
	isLoading: boolean;
}

const TestVariablesTable: React.FC<ITestVariablesTableProps> = ({ onAction, data, isLoading }) => {
	const { t } = useTranslation('testVariables');
	const { t: common } = useTranslation('common');

	const transColumns = useMemo<ITableColumns<VariableListItem>[]>(() => {
		return [
			{
				field: 'type',
				header: t('Variable type'),
				body: (data: VariableListItem) => {
					return common(data.type);
				}
			},
			{ field: 'defaultValue', header: t('Default value') }
		];
	}, [t, common]);
	const { columnComponents } = useTableConfigs<VariableListItem>(transColumns, data);

	return (
		<>
			<div className={styles.title}>{t('Variables of the current test')}</div>
			<VariablesTable columns={columnComponents} data={data} isLoading={isLoading} onAction={onAction} />
			<Button
				variant="contained"
				color="primary"
				icon="t-icon-add"
				label={t('Create variable')}
				onClick={() => onAction(VariableTableActionTypes.CREATE)}
				className={styles.createBtn}
			/>
		</>
	);
};

export default React.memo(TestVariablesTable);
