import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useController, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';
import { useTranslation } from 'react-i18next';
import { Form } from '@tactun/ui';
import RuleForm from '../components/RuleForm';
import FullWidthFormWrapper from '../components/FullWidthFormWrapper';
import styles from './RuleCreateEditContainer.module.scss';
import Conditions, {
	ConditionLogic,
	ConditionOptions,
	conditionRequestConverter,
	conditionResponseConverter,
	RuleCondition
} from '../../Conditions';
import Triggers, {
	buttonTriggers,
	RuleTrigger,
	stationTriggers,
	testGlobalTriggers,
	testStageTriggers,
	TriggerOptions
} from '../../Triggers';
import Actions, { ActionOptions, ActionRunType } from '../../Actions';
import { useDigitalInputChannels, useDigitalOutputChannels } from '../../Sensors';
import { useMeasurements } from '../../Measurements';
import { useStationAuxiliaryDevices } from '../../AuxiliaryDevices';
import { VariablesTypes, useTestVariablesEntities, useVariables } from '../../Variables';
import { useCalculations } from '../../Calculations';
import { useControlChannels } from '../../ControlChannels';
import { useAxes, useTestAxis } from '../../Axes/axes.hooks';
import {
	defaultValues,
	responseFormConverter,
	ruleBaseSchema,
	ruleRequestConverter,
	RuleType,
	StationRuleRequestDto,
	StationRuleResponseDto,
	TestRuleRequestDto,
	TestRuleResponseDto
} from '../';
import { useNumberEnumList } from '../../../hooks';
import {
	buttonActions,
	postStageActions,
	postTestActions,
	preStageActions,
	preTestActions,
	stationActions,
	testGlobalActions,
	testStageActions
} from '../../Actions/actions.const';
import {
	buttonConditions,
	postStageConditions,
	postTestConditions,
	preStageConditions,
	preTestConditions,
	stationConditions,
	testGlobalConditions
} from '../../Conditions/conditions.const';
import { useTestMeasurements } from '../../TestMeasurements/testMeasurements.hooks';
import { TestActionLocations } from '../../TestActions';

export interface RuleCreateEditContainerProps {
	isInTest?: boolean;
	isButtonRule?: boolean;
	isStationRule?: boolean;
	stageId?: string;
	ruleType?: RuleType;
	actionLocation?: TestActionLocations;
	testId?: string;
	stationId?: string;
	formId: string;
	ruleId?: string;
	rule?: StationRuleResponseDto | TestRuleResponseDto;
	ruleSchema?: AnyObjectSchema;
	formContext?: any;
	onSave: (request: StationRuleRequestDto | TestRuleRequestDto) => void;
}

const RuleCreateEditContainer: React.FC<RuleCreateEditContainerProps> = ({
	isInTest = false,
	isButtonRule = false,
	isStationRule = false,
	stageId,
	ruleType,
	actionLocation,
	testId,
	stationId,
	formId,
	ruleId,
	rule,
	ruleSchema,
	formContext,
	onSave
}) => {
	const { t } = useTranslation('rules');

	const form = useForm({
		defaultValues: {
			...defaultValues,
			stationId,
			testId,
			id: ruleId,
			type: isInTest ? RuleType.TEST : null
		},
		mode: 'onBlur',
		context: formContext,
		resolver: ruleSchema ? yupResolver(ruleSchema) : yupResolver(ruleBaseSchema)
	});
	const {
		handleSubmit,
		reset,
		watch,
		trigger,
		control,
		setValue,
		formState: { errors }
	} = form;

	const isAction = actionLocation !== undefined;
	const [conditions, setConditions] = useState<RuleCondition[]>([]);
	const [isPositiveActionsParallel, setIsPositiveActionsParallel] = useState<boolean>(true);
	const [isNegativeActionsParallel, setIsNegativeActionsParallel] = useState<boolean>(true);
	const [conditionLogic, setConditionLogic] = useState<ConditionLogic>(ConditionLogic.OR);
	const typesOptions = useNumberEnumList(RuleType);
	const { digitalInputChannels } = useDigitalInputChannels(stationId);
	const { digitalOutputChannels } = useDigitalOutputChannels(stationId);
	const { calculations } = useCalculations(stationId);
	const { measurements: stationMeasurements } = useMeasurements(stationId);
	const { testMeasurements } = useTestMeasurements(testId);
	const { stationAuxiliaryDevicesResponse } = useStationAuxiliaryDevices(stationId);
	const { controlChannels } = useControlChannels(stationId);
	const { axes: stationAxes } = useAxes(stationId);
	const { axes: testAxes } = useTestAxis(testId);
	const measurements = isAction ? testMeasurements : stationMeasurements;
	const axes = isAction ? testAxes : stationAxes;
	const { variables } = useVariables(stationId, VariablesTypes.NUMERIC);
	const { testVariables } = useTestVariablesEntities(testId, VariablesTypes.NUMERIC);
	const testOrStationVars = useMemo(
		() => (testId ? testVariables : variables) || [],
		[testId, testVariables, variables]
	);

	const formRuleType = useWatch({ control, name: 'type' });
	const isTestRule = formRuleType !== RuleType.STATION;

	const handleSave = handleSubmit((data) => {
		const payload = ruleRequestConverter({
			form: data,
			conditions: conditions.map((condition) => conditionRequestConverter(condition)),
			negativeRunType: isNegativeActionsParallel ? ActionRunType.PARALLEL : ActionRunType.LINEAR,
			positiveRunType: isPositiveActionsParallel ? ActionRunType.PARALLEL : ActionRunType.LINEAR,
			conditionBondType: conditionLogic
		});
		onSave(payload);
	});

	const {
		field: { value: positiveActions, onChange: setPositiveActions }
	} = useController({ name: 'positiveActions', control });
	const {
		field: { value: negativeActions, onChange: setNegativeActions }
	} = useController({ name: 'negativeActions', control });

	const handleReset = useCallback(() => {
		if (rule) {
			reset(
				{ ...responseFormConverter(rule), stationId, testId, stageId, id: ruleId },
				{ keepDirty: true, keepTouched: true }
			);

			const newConditions = rule.conditions?.map((condition) => conditionResponseConverter(condition));
			setConditions(newConditions || []);
			setConditionLogic(rule.conditionBondType || ConditionLogic.AND);

			//setIsPositiveActionsParallel(rule.positiveRunType === ActionRunType.PARALLEL);
			//setIsNegativeActionsParallel(rule.negativeRunType === ActionRunType.PARALLEL);
		} else {
			reset(
				{ ...defaultValues, stationId, testId, stageId, id: ruleId, type: ruleType },
				{ keepDirty: true, keepTouched: true }
			);
		}
	}, [rule, reset, stationId, testId, stageId, ruleId, ruleType]);

	//init current channel for edit
	useEffect(() => {
		handleReset();
	}, [rule, handleReset]);

	const triggers = watch('triggers');
	const setTriggers = useCallback(
		(newTriggers: RuleTrigger[]) => {
			setValue('triggers', newTriggers);
			trigger('triggers');
		},
		[setValue, trigger]
	);

	const triggerOptions: TriggerOptions = useMemo(() => {
		if (isButtonRule) return buttonTriggers;
		if (!isTestRule) return stationTriggers;
		if (formRuleType === RuleType.TEST) return testGlobalTriggers;
		if (formRuleType === RuleType.STAGE) return testStageTriggers;
		return {};
	}, [isButtonRule, isTestRule, formRuleType]);

	const actionOptions: ActionOptions = useMemo(() => {
		if (isButtonRule) return buttonActions;
		if (!isTestRule && !isAction) return stationActions;
		if (actionLocation === TestActionLocations.PRE_STAGE) return preStageActions;
		if (actionLocation === TestActionLocations.POST_STAGE) return postStageActions;
		if (actionLocation === TestActionLocations.PRE_TEST) return preTestActions;
		if (actionLocation === TestActionLocations.POST_TEST) return postTestActions;
		if (formRuleType === RuleType.TEST) return testGlobalActions;
		if (formRuleType === RuleType.STAGE) return testStageActions;
		return {};
	}, [actionLocation, isAction, isButtonRule, isTestRule, formRuleType]);

	const conditionOptions: ConditionOptions = useMemo(() => {
		if (isButtonRule) return buttonConditions;
		if (!isTestRule && !isAction) return stationConditions;
		if (actionLocation === TestActionLocations.PRE_STAGE) return preStageConditions;
		if (actionLocation === TestActionLocations.POST_STAGE) return postStageConditions;
		if (actionLocation === TestActionLocations.PRE_TEST) return preTestConditions;
		if (actionLocation === TestActionLocations.POST_TEST) return postTestConditions;
		if (formRuleType === RuleType.TEST) return testGlobalConditions;
		return {};
	}, [actionLocation, isAction, isButtonRule, isTestRule, formRuleType]);

	const noRuleType = formRuleType === null || formRuleType === undefined;
	const hideForm = isStationRule && noRuleType;

	return (
		<>
			<RuleForm
				formId={formId}
				form={form}
				onSave={handleSave}
				typesOptions={isStationRule ? typesOptions : undefined}
				disableChange={triggers.length > 0 || conditions.length > 0 || positiveActions.length > 0}
				isAction={isAction}
			/>
			<div className={styles.formHeader}></div>
			{!hideForm && (
				<>
					<Form.Divider />
					{!isAction && (
						<>
							<FullWidthFormWrapper>
								<Triggers
									triggers={triggers}
									updateTriggers={setTriggers}
									measurements={measurements || []}
									digitalChannels={digitalInputChannels}
									auxDevices={stationAuxiliaryDevicesResponse || []}
									axes={axes || []}
									triggerOptions={triggerOptions}
									isError={!!errors.triggers?.message}
								/>
							</FullWidthFormWrapper>
							<Form.Divider />
						</>
					)}
					<FullWidthFormWrapper>
						<Conditions
							conditions={conditions}
							updateConditions={setConditions}
							measurements={measurements || []}
							calculations={calculations || []}
							digitalChannels={digitalInputChannels}
							auxDevices={stationAuxiliaryDevicesResponse || []}
							conditionLogic={conditionLogic}
							conditionOptions={conditionOptions}
							setConditionLogic={setConditionLogic}
						/>
					</FullWidthFormWrapper>
					<Form.Divider />
					<FullWidthFormWrapper>
						<Actions
							actions={positiveActions}
							title={t('Then do the following action:')}
							maxLimit={isAction ? 1 : undefined}
							updateActions={setPositiveActions}
							isParallel={isPositiveActionsParallel}
							setIsParallel={!isAction ? setIsPositiveActionsParallel : undefined}
							measurements={measurements || []}
							digitalChannels={digitalOutputChannels}
							controlChannels={controlChannels || []}
							axes={axes || []}
							dragType="if-actions-cards"
							actionOptions={actionOptions}
							variables={testOrStationVars}
							isError={!!errors.positiveActions?.message}
						/>
					</FullWidthFormWrapper>
					<FullWidthFormWrapper>
						<Actions
							variables={testOrStationVars}
							actions={negativeActions}
							title={t('If conditions not met, then do the following action:')}
							maxLimit={isAction ? 1 : undefined}
							updateActions={setNegativeActions}
							isParallel={isNegativeActionsParallel}
							setIsParallel={!isAction ? setIsNegativeActionsParallel : undefined}
							measurements={measurements || []}
							digitalChannels={digitalOutputChannels}
							controlChannels={controlChannels || []}
							actionOptions={actionOptions}
							axes={axes || []}
							dragType="else-actions-cards"
						/>
					</FullWidthFormWrapper>
				</>
			)}
		</>
	);
};

export default React.memo(RuleCreateEditContainer);
