import { DataChannelDropdownKeyValue } from '../modules/DataChannel';
import { Converter } from '../types';

export const dataChannelToUniqKey: Converter<Partial<DataChannelDropdownKeyValue>, string> = (item) => {
	return `${item.type}###-###${item.id}`;
};

export const uniqKeyToDataChannel: Converter<string, DataChannelDropdownKeyValue> = (str) => {
	const [type, id] = str.split('###-###');

	return { id, type: parseInt(type) };
};
