import { useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Wizard } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as paths from './featuresets.const';
import FeaturesetsTableContainer from './containers/FeaturesetsTableContainer';
import FeaturesetsWizardContainer from './containers/FeaturesetsWizardContainer';
import FeaturesetsContainer from './containers/FeaturesetsContainer';
import StationConfiguration from './components/StationConfiguration';
import TestConfiguration from './components/TestConfiguration';
import TestAnalysis from './components/TestAnalysis';
import Triggers from './components/Triggers';
import Actions from './components/Actions';
import Specimens from './components/Specimens';
import Calculations from './components/Calculations';
import Widgets from './components/Widgets';

const useSteps = () => {
	const { t } = useTranslation('station');
	const steps = useMemo(() => {
		return [
			{ path: paths.generalInfoPath, name: t('General') },
			{ path: paths.stationConfigurationPath, name: t('Station Configuration') },
			{ path: paths.testConfigurationPath, name: t('Test Configuration') },
			{ path: paths.testAnalysisPath, name: t('Test Analysis') },
			{ path: paths.triggersPath, name: t('Triggers') },
			{ path: paths.actionsPath, name: t('Actions') },
			{ path: paths.specimensPath, name: t('Specimens') },
			{ path: paths.calculationsPath, name: t('Calculations') },
			{ path: paths.widgetsPath, name: t('Widgets') }
		];
	}, [t]);

	return steps;
};

const StationsRouts: React.FC = () => {
	const steps = useSteps();
	const form = useForm();

	const createSteps = useMemo(
		() =>
			steps.map((step) => {
				return { ...step, path: step.path === paths.generalInfoPath ? paths.generalInfoCreatePath : step.path };
			}),
		[steps]
	);

	return (
		<Routes>
			<Route index element={<FeaturesetsTableContainer />} />
			<Route
				path="create"
				element={
					<Wizard steps={createSteps}>
						<FeaturesetsWizardContainer />
					</Wizard>
				}
			>
				<Route index element={<FeaturesetsContainer />} />
			</Route>
			<Route
				path=":featuresetId/*"
				element={
					<Wizard steps={steps}>
						<FeaturesetsWizardContainer />
					</Wizard>
				}
			>
				<Route index element={<FeaturesetsContainer />} />
				<Route path="station-configuration" element={<StationConfiguration form={form} />} />
				<Route path="test-configuration" element={<TestConfiguration form={form} />} />
				<Route path="test-analysis" element={<TestAnalysis form={form} />} />
				<Route path="triggers" element={<Triggers form={form} />} />
				<Route path="actions" element={<Actions form={form} />} />
				<Route path="specimens" element={<Specimens form={form} />} />
				<Route path="calculations" element={<Calculations form={form} />} />
				<Route path="widgets" element={<Widgets form={form} />} />
			</Route>
			<Route path=":featuresetId/*">
				<Route path="station-configuration/create" element={<StationConfiguration isCreate form={form} />} />
				<Route path="test-configuration/create" element={<TestConfiguration isCreate form={form} />} />
				<Route path="test-analysis/create" element={<TestAnalysis isCreate form={form} />} />
				<Route path="triggers/create" element={<Triggers isCreate form={form} />} />
				<Route path="actions/create" element={<Actions isCreate form={form} />} />
				<Route path="specimens/create" element={<Specimens isCreate form={form} />} />
				<Route path="calculations/create" element={<Calculations isCreate form={form} />} />
				<Route path="widgets/create" element={<Widgets isCreate form={form} />} />
			</Route>
		</Routes>
	);
};

export default StationsRouts;
