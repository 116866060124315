import cx from 'classnames';
import styles from './FormDivider.module.scss';

export interface IFormDividerProps {
	className?: string;
}

const FormDivider: React.FC<IFormDividerProps> = ({ className }) => {
	return <div className={cx(styles.dividerContainer, className)} />;
};

FormDivider.displayName = 'FormDivider';
export default FormDivider;
