import { Event, TriggerDto } from '@tactun/grpc-client';
import { uuid } from '../../tools';
import {
	DigitalInputState,
	TriggerType,
	ActionStationState,
	TestState,
	OffsetCalibrationState
} from './eventViewer.enums';
import { IEvent } from './eventViewer.types';

// Todo: fix and show user friendly text's
export const triggerPropByType = (triggerType: TriggerType, trigger: TriggerDto.AsObject): string => {
	switch (triggerType) {
		case TriggerType.TRG_DIGITAL_INPUT:
			return (
				`Di Trigger ${trigger.triggerDi?.channelId} ${
					trigger.triggerDi?.diProp ? DigitalInputState[trigger.triggerDi?.diProp] : ''
				}` || ''
			);
		case TriggerType.TRG_STATION:
			return (
				`Station Trigger - ${
					trigger.triggerStation?.stationProp ? ActionStationState[trigger.triggerStation?.stationProp] : ''
				}` || ''
			);
		case TriggerType.TRG_TEST:
			return `Test Trigger - ${trigger.triggerTest?.testProp ? TestState[trigger.triggerTest?.testProp] : ''}` || '';
		case TriggerType.TRG_CONNECTION:
			return (
				`Test Connection - ${trigger.triggerConn?.connProp} Timeout Sec - ${trigger.triggerConn?.timeoutSec}` || ''
			);
		case TriggerType.TRG_AUX_DEVICE:
			return `Test Aux - ${trigger.triggerAux?.auxDevId}  ${trigger.triggerAux?.auxDevProp}` || '';
		case TriggerType.TRG_SPECIMEN:
			return (
				`Test Specimen - ${trigger.triggerSpecimen?.type} ${trigger.triggerSpecimen?.ultimateStr} ${trigger.triggerSpecimen?.yield} ${trigger.triggerSpecimen?.pb_break}` ||
				''
			);
		case TriggerType.TRG_MEASUREMENT:
			return `Test Measurement - ${trigger.triggerMeas?.measId} ${trigger.triggerMeas?.type}` || '';
		case TriggerType.TRG_AXIS:
			return `Test Axis - ${trigger.triggerAxis?.axisId} ${trigger.triggerAxis?.axisProp}` || '';
		case TriggerType.TRG_STAGE:
			return (
				`Test Stage - ${trigger.triggerStage?.id} Sequence - ${trigger.triggerStage?.sequence} All loop- ${trigger.triggerStage?.loopAll} Curr Lup -${trigger.triggerStage?.loopCurr}` ||
				''
			);
		case TriggerType.TRG_OFFSET_CALIBRATION:
			return (
				`${trigger.triggerOc?.state ? OffsetCalibrationState[trigger.triggerOc?.state] : ''} Offset - ${trigger
					.triggerOc?.offset}` || ''
			);
		default:
			return '';
	}
};

export const eventGrpcToEventListItem = (event: Event.AsObject): IEvent => {
	return {
		id: uuid(),
		timestamp: new Date().getTime(),
		ruleName: event.ruleName,
		//trigger: event.trigger?.type,
		triggerType: event.trigger?.type ? TriggerType[event.trigger?.type] : undefined,
		triggerProp:
			event.trigger?.type && event.trigger ? triggerPropByType(event.trigger?.type, event.trigger) : undefined
	};
};

export const eventsGrpcToEventList = (events: Event.AsObject[]): IEvent[] =>
	events.map((event) => eventGrpcToEventListItem(event));
