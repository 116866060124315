import * as yup from 'yup';
import { alphaNumericRegex } from '../../constants/regexes';

export const systemSchema = yup
	.object({
		id: yup.string().optional(),
		copyrightInformation: yup.string().optional(),
		version: yup.string().optional(),
		description: yup.string().optional(),
		name: yup.string().required().matches(alphaNumericRegex, 'Is not in correct format'),
		controllerModel: yup.string().required()
	})
	.required();
