import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { ButtonSwitch, Dropdown } from '@tactun/ui';
import { AxisType } from '@tactun/grpc-client';
import { useParams } from 'react-router-dom';
import { WidgetProps } from '../../../Dashboard/dashboard.types';
import { useAxes, useTestAxis } from '../../../Axes/axes.hooks';
import { testAxesToDropdownOptions } from '../../../Dashboard/dashboard.converters';
import { useIsTestRunning } from '../../../Dashboard/dashboard.hooks';
import { useControlChannels } from '../../../ControlChannels';
import { WaveformDirection } from '../../../Waveforms';
import WidgetHeader from '../../../Dashboard/components/Widget/WidgetHeader';
import WidgetContent from '../../../Dashboard/components/Widget/WidgetContent';
import AxisActionButton from '../AxisActionButton/AxisActionButton';
import { ControlChannelNames, EAxisActions } from '../../controlAxis.enums';

import styles from './ControlAxis.module.scss';
import { useControlAxis } from '../../controlAxis.grpc';
import { useStationId } from '../../../Stations';

const isHomeState = true;

const ControlAxisWidget: React.FC<WidgetProps> = () => {
	const { t } = useTranslation('dashboard');
	const { testId } = useParams();
	const stationId = useStationId();

	// Axes
	const { axes: testAxes } = useTestAxis(testId);
	const { axes: stationAxes } = useAxes(stationId);
	const axes = testAxes || stationAxes;
	const axisOptions = useMemo(() => (axes ? testAxesToDropdownOptions(axes) : []), [axes]);
	const [currentAxisValue, setCurrentAxisValue] = React.useState(null);
	useEffect(() => {
		if (axisOptions.length) {
			setCurrentAxisValue(axisOptions[0].value);
		}
	}, [axisOptions]);
	const currentAxis = React.useMemo(() => {
		return axes?.find(({ id }) => id === currentAxisValue);
	}, [axes, currentAxisValue]);

	const actuatorBits: Record<string, string>[] = [];
	const { control } = useForm({
		defaultValues: {
			label1: false
		}
	});

	const { controlChannels } = useControlChannels(stationId);
	const { upChannel, downChannel, upFastChannel, downFastChannel } = useMemo(
		() => ({
			upChannel: controlChannels?.find((ch) => ch.name === ControlChannelNames.UP),
			downChannel: controlChannels?.find((ch) => ch.name === ControlChannelNames.DOWN),
			upFastChannel: controlChannels?.find((ch) => ch.name === ControlChannelNames.UP_FAST),
			downFastChannel: controlChannels?.find((ch) => ch.name === ControlChannelNames.DOWN_FAST)
		}),
		[controlChannels]
	);

	const { stop, start } = useControlAxis();

	const handleActionStart = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			const action: EAxisActions = e.currentTarget.getAttribute('data-action') as EAxisActions;

			if (action === EAxisActions.UP && upChannel && currentAxis) {
				start(currentAxis, upChannel, WaveformDirection.UP, 2);
			} else if (action === EAxisActions.UP_SPEED && upFastChannel && currentAxis) {
				start(currentAxis, upFastChannel, WaveformDirection.UP, 4);
			} else if (action === EAxisActions.DOWN && downChannel && currentAxis) {
				start(currentAxis, downChannel, WaveformDirection.DOWN, 2);
			} else if (action === EAxisActions.DOWN_SPEED && downFastChannel && currentAxis) {
				start(currentAxis, downFastChannel, WaveformDirection.DOWN, 4);
			}
		},
		[upChannel, currentAxis, upFastChannel, downChannel, downFastChannel, start]
	);

	useEffect(() => {
		const onMouseUp = () => {
			if (currentAxis) {
				stop(currentAxis);
			}
		};

		document.documentElement.addEventListener('mouseup', onMouseUp);
		return () => document.documentElement.removeEventListener('mouseup', onMouseUp);
	}, [currentAxis, stop]);

	const isAxialAxis = currentAxis ? currentAxis?.type === AxisType.AXIS_TYPE_AXIAL : true;

	const isTestRunning = useIsTestRunning();

	return (
		<>
			<WidgetHeader disableMinimize>{t('Control axis')}</WidgetHeader>
			<WidgetContent className={styles.widgetContainer} showFooter>
				<div className={styles.container}>
					<div className={styles.inner}>
						{axes && axes.length > 1 && (
							<div className={styles.header}>
								<Dropdown
									onChange={setCurrentAxisValue}
									options={axisOptions}
									value={currentAxisValue}
									name="Axis"
									disabled={isTestRunning}
									className={styles.dropdown}
								/>
							</div>
						)}
						{!!actuatorBits.length && (
							<div className={styles.switchContainer}>
								{actuatorBits.map((bit) => (
									<div className={styles.label}>
										<Controller
											name="label1"
											control={control}
											render={({ field: { value, ...field } }) => {
												return <ButtonSwitch {...field} checked={value} className={styles.label} label={bit.name} />;
											}}
										/>
									</div>
								))}
							</div>
						)}
						<div className={styles.buttons}>
							<AxisActionButton
								action={EAxisActions.HOME}
								icon={isHomeState ? styles.iconHome : styles.iconHomeStop}
								handleActionStart={handleActionStart}
								disabled={isTestRunning}
							/>
							<AxisActionButton
								action={EAxisActions.UP_SPEED}
								icon={isAxialAxis ? styles.iconUpSpeed : styles.iconCircleRightSpeed}
								handleActionStart={handleActionStart}
								disabled={isTestRunning}
							/>
							<AxisActionButton
								action={EAxisActions.UP}
								icon={isAxialAxis ? styles.iconUp : styles.iconCircleRight}
								handleActionStart={handleActionStart}
								disabled={isTestRunning}
							/>
							<AxisActionButton
								action={EAxisActions.DOWN}
								icon={isAxialAxis ? styles.iconDown : styles.iconCircleLeft}
								handleActionStart={handleActionStart}
								disabled={isTestRunning}
							/>
							<AxisActionButton
								action={EAxisActions.DOWN_SPEED}
								icon={isAxialAxis ? styles.iconDownSpeed : styles.iconCircleLeftSpeed}
								handleActionStart={handleActionStart}
								disabled={isTestRunning}
							/>
						</div>
						{axes && axes.length > 1 && <button className={styles.linkButton}>Link</button>}
					</div>
				</div>
			</WidgetContent>
		</>
	);
};

export default React.memo(ControlAxisWidget);
