import React from 'react';
import TestMeasurementsModal from '../TestMeasurementsModal';
import TestMeasurementsContainer from '../../containers/TestMeasurementsContainer';

interface ITestMeasurementsProps {
	testId?: string;
}

const TestMeasurements: React.FC<ITestMeasurementsProps> = ({ testId }) => {
	return (
		<TestMeasurementsModal>
			<TestMeasurementsContainer testId={testId} />
		</TestMeasurementsModal>
	);
};

export default React.memo(TestMeasurements);
