export const makeTwoColumnsMatrix = <T>(input: T[]): T[][] => {
	return input.reduce((res: T[][], current: T, currentIndex) => {
		if (currentIndex % 2 === 0) {
			res.push([current]);
			return res;
		}

		res[res.length - 1].push(current);
		return res;
	}, []);
};
