import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, useModalData } from '@tactun/ui';
import { toast } from 'react-toastify';
import ControllerConfigureNetworkModal from '../components/ControllerConfigureNetworkModal';
import ControllerConfigureNetworkModalContent from '../components/ControllerConfigureNetworkModalContent';
import { controllerConfigureNetworkSchema } from '../connection.schemas';
import { controllerConfigureNetworkModalId, configureNetworkDefaultValues } from '../connection.const';
import { useChangeIp, useNetworkInfo } from '../connection.hooks';
import { useConnectionStore, useManuallyAddedDevicesStore } from '../connection.store';
import { enableDhcp, rebootDevice } from '../connection.api';

const ControllerConfigureNetworkContainer = () => {
	const modalData = useModalData(controllerConfigureNetworkModalId);
	const { changeDeviceIp } = useChangeIp();
	const { updateDevice } = useManuallyAddedDevicesStore();
	const { connectedDevice } = useConnectionStore();
	const networkInfo = useNetworkInfo(connectedDevice !== null && !!modalData);
	const form = useForm({
		defaultValues: configureNetworkDefaultValues,
		mode: 'onBlur',
		resolver: yupResolver(controllerConfigureNetworkSchema)
	});
	const {
		reset,
		formState: { dirtyFields }
	} = form;

	const handleSaveEdit = form.handleSubmit(async (data) => {
		let isUpdated = false;
		if (dirtyFields.isDHCPEnabled && data.isDHCPEnabled) {
			isUpdated = await enableDhcp();
		} else if (dirtyFields.ip || dirtyFields.mask || dirtyFields.gateway) {
			isUpdated = await changeDeviceIp(data.ip as string, data.mask as string, data.gateway as string);
		}

		if (isUpdated) {
			await rebootDevice();
			toast.info('Network configurations changed successfully. Please wait for the device to reconnect.');
		}

		if (data.isManuallyAdded && connectedDevice) {
			updateDevice({ ...connectedDevice, ...data });
			//Update connected device if it's the same device
			/* if (updatedDevice.ip === connectedDevice?.ip) {
				setConnectedDevice({ ...connectedDevice, ...updatedDevice });
			} */
		}

		Modal.hide(controllerConfigureNetworkModalId);
	});

	useEffect(() => {
		reset(
			connectedDevice && networkInfo && modalData?.device
				? {
						...networkInfo,
						ip: networkInfo.ipAddr,
						sn: connectedDevice.sn,
						isDHCPEnabled: networkInfo.dhcpEnabled,
						isManuallyAdded: connectedDevice.isManuallyAdded
				  }
				: configureNetworkDefaultValues
		);
	}, [connectedDevice, modalData?.device, networkInfo, reset]);

	return (
		<ControllerConfigureNetworkModal>
			<ControllerConfigureNetworkModalContent onSave={handleSaveEdit} form={form} disabled={networkInfo === null} />
		</ControllerConfigureNetworkModal>
	);
};

export default ControllerConfigureNetworkContainer;
