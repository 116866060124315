import { grpc } from '@improbable-eng/grpc-web';
import { tareService } from '@tactun/grpc-client';
import {} from '@tactun/grpc-client/src/grpc/tare_pb_service';
import { tareToTareGRPCConverter } from './readout.converters';
import { tError } from '../../tools/logger';

export const tareToValue = (id: string, value: number | null) => {
	if (process.env.REACT_APP_DEVICE_URL) {
		const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

		grpc.unary(tareService.Tare.Tare, {
			request: tareToTareGRPCConverter(id, value),
			host: process.env.REACT_APP_DEVICE_URL,
			transport: myTransport,
			onEnd: (output: grpc.UnaryOutput<grpc.ProtobufMessage>) => {
				if (output.status !== grpc.Code.OK) {
					tError(
						`GRPC stream end code: "${output.status.toString()}" \nGRPC stream end message: "${output.message}" \n `
					);
				}
			}
		});
	}
};
