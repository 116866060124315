import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import {
	createTestSequencerRule,
	getStageRules,
	getStationTestRulesByType,
	getTestRulesByLocation,
	getTestSequencerRule,
	updateTestSequencerRule
} from './testSequencerRules.api';
import { StageTypes } from '../Tests/tests.enums';
import { RuleType, StationRuleResponseDto, TestRuleRequestDto, TestRuleResponseDto } from '../Rules';

export const useAllTestRulesOfTest = (testId?: string, stationId?: string, stageType?: StageTypes) => {
	const ruleType = stageType === StageTypes.GLOBAL ? RuleType.TEST : RuleType.STAGE;

	const { data: stationTestRules, isLoading: isStationTestRulesLoading } = useQuery({
		queryKey: ['stationTestRules', { stationId, ruleType }],
		queryFn: () => getStationTestRulesByType(stationId as string, ruleType),
		enabled: !!stationId
	});

	const { data: currentTestRules, isLoading: isCurrentTestRulesLoading } = useQuery({
		queryKey: ['testRules', { testId, ruleType }],
		queryFn: () => getTestRulesByLocation(testId as string, ruleType),
		enabled: !!testId
	});

	const queryClient = useQueryClient();

	const pullTestRules = () => {
		queryClient.invalidateQueries({ queryKey: ['testRules', { testId, ruleType }] });
	};

	const updateTestRules = (newRules: StationRuleResponseDto[]) => {
		queryClient.setQueryData(['testRules'], newRules);
	};

	const testRules = useMemo(() => {
		if (
			testId &&
			stationId &&
			!isStationTestRulesLoading &&
			!isCurrentTestRulesLoading &&
			currentTestRules &&
			stationTestRules
		) {
			return [...currentTestRules, ...stationTestRules];
		}
	}, [testId, stationId, isStationTestRulesLoading, isCurrentTestRulesLoading, currentTestRules, stationTestRules]);

	return {
		testRules,
		isLoading: isStationTestRulesLoading || isCurrentTestRulesLoading,
		pullTestRules,
		updateTestRules
	};
};

export const useTestSequencerRules = (
	testId?: string,
	stageId?: string,
	stageType?: StageTypes,
	onSaveSuccess?: () => void
) => {
	const queryClient = useQueryClient();
	const location = stageType === StageTypes.GLOBAL ? RuleType.TEST : RuleType.STAGE;

	const updateRulesInTest = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: ['test-stages', { testId }] });
	}, [queryClient, testId]);

	const { data, isLoading } = useQuery({
		queryKey: ['stageRules', { stageId, testId }],
		queryFn: () =>
			stageType === StageTypes.GLOBAL
				? getTestRulesByLocation(testId as string, RuleType.TEST)
				: getStageRules(stageId as string),

		enabled: (stageType === StageTypes.GLOBAL && !!testId) || (stageType !== StageTypes.GLOBAL && !!stageId)
	});

	const addNewItem = useCallback(
		(newVar: TestRuleResponseDto) => {
			queryClient.setQueryData<TestRuleResponseDto[]>(['stageRules', { stageId, testId }], (old) => {
				if (!old) return undefined;
				return [newVar, ...old];
			});
		},
		[queryClient, stageId, testId]
	);

	const updateItem = useCallback(
		(newVar: TestRuleResponseDto) => {
			queryClient.setQueryData<TestRuleResponseDto[]>(['stageRules', { stageId, testId }], (old) => {
				return old?.map((o) => (o.uuid === newVar.uuid ? newVar : o));
			});
		},
		[queryClient, stageId, testId]
	);

	const createMutation = useMutation({
		mutationFn: (data: TestRuleRequestDto) => {
			return createTestSequencerRule(data);
		},

		onSuccess: (newVar) => {
			addNewItem(newVar);
			updateRulesInTest();
			if (onSaveSuccess) {
				onSaveSuccess();
			}
			queryClient.invalidateQueries({ queryKey: ['testRules', { testId, location }] });
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { rule: TestRuleRequestDto; ruleId: string }) => updateTestSequencerRule(data.rule, data.ruleId),

		onSuccess: (updatedVar) => {
			updateItem(updatedVar);
			if (onSaveSuccess) {
				onSaveSuccess();
			}
			queryClient.invalidateQueries({ queryKey: ['testRules', { testId, location }] });
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const saveRule = useCallback(
		(data: TestRuleRequestDto, ruleId?: string, isCopy?: boolean) => {
			const fullData = {
				...data,
				ruleStageUuid: stageId
			};
			if (ruleId && !isCopy) {
				updateMutation.mutate({
					rule: fullData,
					ruleId
				});
			} else {
				createMutation.mutate(fullData);
			}
		},
		[createMutation, stageId, updateMutation]
	);

	const deleteRule = useCallback(
		(id: string) => {
			queryClient.setQueryData<TestRuleResponseDto[]>(['stageRules', { stageId, testId }], (old) => {
				return old?.filter((hc) => hc.uuid !== id);
			});
			queryClient.invalidateQueries({ queryKey: ['testRules', { testId, location }] });

			updateRulesInTest();
		},
		[location, queryClient, stageId, testId, updateRulesInTest]
	);

	return useMemo(
		() => ({
			isLoading,
			isCreating: createMutation.isPending,
			isUpdating: updateMutation.isPending,
			sequencerRules: data || [],
			saveRule,
			deleteRule,
			updateItem
		}),
		[createMutation.isPending, data, deleteRule, isLoading, saveRule, updateItem, updateMutation.isPending]
	);
};

export const useTestSequencerRule = (ruleId?: string) => {
	const { data: testSequencerRule, isLoading: isTestSequencerRuleLoading } = useQuery<TestRuleResponseDto>({
		queryKey: ['test-sequencer-rule', { ruleId }],
		queryFn: () => getTestSequencerRule(ruleId as string),
		enabled: !!ruleId
	});

	return {
		testSequencerRule,
		isTestSequencerRuleLoading
	};
};
