import React, { useCallback, useMemo } from 'react';
import { PageLayout } from '@tactun/ui';
import { IconButton } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { SystemFormType } from '../../system.types';
import { SystemsCrudActionTypes } from '../../system.enums';
import { CrudActions, TableFilters } from '../../../../components';
import { INamedEntity, ITableColumns } from '../../../../types';
import { useTableConfigs } from '../../../../hooks';

interface ISystemsCrudProps {
	onAction: (type: SystemsCrudActionTypes, data?: SystemFormType) => void;
	data?: SystemFormType[];
	isLoading: boolean;
}

const SystemsCrud: React.FC<ISystemsCrudProps> = ({ data, isLoading, onAction }) => {
	const { t } = useTranslation('system');

	const transColumns = useMemo<ITableColumns[]>(() => {
		return [
			{ field: 'controllerModel', header: t('Controller Model') },
			{ field: 'description', header: t('Description') }
		];
	}, [t]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<INamedEntity>(transColumns);

	const createMenuItems = useCallback(
		(data: any) => [
			{ label: t('Units'), command: () => onAction(SystemsCrudActionTypes.OPEN_UNITS, data) },
			{ label: t('Quantities'), command: () => onAction(SystemsCrudActionTypes.OPEN_QUANTITIES, data) },
			{ label: t('Stations'), command: () => onAction(SystemsCrudActionTypes.OPEN_STATIONS, data) },
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(SystemsCrudActionTypes.DELETE, data)
			}
		],
		[onAction, t]
	);

	const handleCreate = useCallback(() => {
		onAction(SystemsCrudActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={t('Systems and Stations')}>
			<PageLayout.Header title={t('Systems')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('System')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters
					onFilterChange={handleFilterChange}
					onCancel={handleCancel}
					selected={selected as unknown as INamedEntity[]}
				/>
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={data}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column sortable resizeable field="name" header={t('System name')} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(SystemsCrudActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default React.memo(SystemsCrud);
