import { Routes, Route } from 'react-router-dom';
import ControllersContainer from './containers/ControllersContainer';
import ControllersTableContainer from './containers/ControllersTableContainer';

const ControllersRouts: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<ControllersTableContainer />} />
			<Route path="create" element={<ControllersContainer />} />
			<Route path="edit/:controllerId" element={<ControllersContainer />} />
		</Routes>
	);
};

export default ControllersRouts;
