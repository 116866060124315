import { FC, useCallback, useMemo } from 'react';
import { useModalData } from '@tactun/ui';
import TestActionsCreateEditModal from '../components/TestActionsCreateEditModal';
import RuleCreateEdit, {
	RuleRequestDtoBase,
	RuleResponseDtoBase,
	RuleType,
	StationRuleRequestDto,
	TestRuleRequestDto,
	TestRuleResponseDto
} from '../../Rules';
import { createEditTestActionsModalId } from '../testActions.const';
import { testActionsSchema, testCalculationSchema } from '../testActions.schemas';
import { CalculationCreateEdit, CalculationRequestDto, CalculationResponseDto } from '../../Calculations';
import { useTest } from '../../Tests/tests.hooks';
import { calculationToTestAction } from '../testActions.converters';
import { ActionCalculationProperties, ActionDtoObjectTypes, CalculationActionDto } from '../../Actions';

interface ITestActionsCreateEditContainerProps {
	testId?: string;
	onClose: () => void;
	onSave: (data: RuleRequestDtoBase, index: number) => void;
	actions: (RuleRequestDtoBase | RuleResponseDtoBase)[];
}

const testActionsFormId = 'testActionsFormId';

const TestActionsCreateEditContainer: FC<ITestActionsCreateEditContainerProps> = ({
	testId,
	onClose,
	onSave,
	actions
}) => {
	const data = useModalData(createEditTestActionsModalId);
	const { testDto } = useTest(testId);
	const action = data?.action;

	const handleSaveAction = useCallback(
		(action: TestRuleRequestDto | StationRuleRequestDto) => {
			onSave(action as RuleRequestDtoBase, data?.index);
		},
		[data?.index, onSave]
	);

	const handleSaveCalculation = useCallback(
		(calc: CalculationRequestDto) => {
			onSave(calculationToTestAction(calc), data?.index);
		},
		[data?.index, onSave]
	);

	const actionCopy = useMemo(() => {
		const copy = { ...action } as TestRuleResponseDto;
		if (data?.isCopy) {
			copy.name = `${copy.name}_copy`;
		}
		return action ? copy : undefined;
	}, [action, data?.isCopy]);

	const isCalculation = action
		? action?.rulePositiveActions[0].type === ActionDtoObjectTypes.CALCULATION &&
		  action?.rulePositiveActions[0].action === ActionCalculationProperties.RUN
		: !data?.isAction;

	const calculationCopy = useMemo(() => {
		if (actionCopy && isCalculation) {
			return {
				uuid: actionCopy.uuid,
				name: actionCopy.name,
				stationUuid: actionCopy.ruleStageUuid,
				calculation: (actionCopy.rulePositiveActions?.[0] as CalculationActionDto).calculation
			} as CalculationResponseDto;
		}
		return undefined;
	}, [actionCopy, isCalculation]);

	const isCalculationVisible = !!(isCalculation && testId);
	const isActionVisible = !!(!isCalculation && testId);

	const formContext = useMemo(() => {
		return {
			items: actions,
			editingIndex: data?.index
		};
	}, [actions, data?.index]);

	return (
		<TestActionsCreateEditModal
			formId={testActionsFormId}
			isCreate={!data?.action}
			name={data?.action?.name}
			isLoading={false}
			onClose={onClose}
			isCalculation={isCalculation}
		>
			{isActionVisible && (
				<RuleCreateEdit
					stageId={data?.stageId}
					actionLocation={data?.actionLocation}
					testId={testId}
					stationId={testDto?.stationId}
					formId={testActionsFormId}
					ruleType={RuleType.STAGE}
					rule={actionCopy as TestRuleResponseDto}
					ruleId={data?.isCopy ? undefined : data?.id}
					ruleSchema={testActionsSchema}
					formContext={formContext}
					onSave={handleSaveAction}
				/>
			)}
			{isCalculationVisible && (
				<CalculationCreateEdit
					testId={testId}
					formId={testActionsFormId}
					calculation={calculationCopy}
					formSchema={testCalculationSchema}
					formContext={formContext}
					onSave={handleSaveCalculation}
				/>
			)}
		</TestActionsCreateEditModal>
	);
};

export default TestActionsCreateEditContainer;
