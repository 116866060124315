import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetContent from '../../../Dashboard/components/Widget/WidgetContent';
import WidgetHeader from '../../../Dashboard/components/Widget/WidgetHeader';
import { TestOperateStatus } from '../../controlTest.enums';
import ControlButton from '../ControlButton';
import styles from './ControlTest.module.scss';

interface ControlTestWidgetProps {
	status: TestOperateStatus;
	onPlayStop: () => void;
	onPauseResume: () => void;
	onNext: () => void;
	onReport: () => void;
	isType?: boolean;
}

const ControlTestWidget: React.FC<ControlTestWidgetProps> = ({
	status,
	onPlayStop,
	onPauseResume,
	isType
	// onNext,
	// onReport
}) => {
	const { t } = useTranslation('dashboard');

	return (
		<>
			<WidgetHeader disableMinimize>{t('Control test')}</WidgetHeader>
			<WidgetContent className={styles.widgetContainer} showFooter isType={isType}>
				<div className={styles.container}>
					<div className={styles.inner}>
						<ControlButton
							icon={status === TestOperateStatus.STOPPED ? styles.iconPlay : styles.iconStop}
							onClick={onPlayStop}
							disabled={status === TestOperateStatus.PAUSED}
						/>
						<ControlButton
							icon={status === TestOperateStatus.PAUSED ? styles.iconResume : styles.iconPause}
							onClick={onPauseResume}
							disabled={status === TestOperateStatus.STOPPED}
						/>
						{/*<ControlButton*/}
						{/*	icon={styles.iconNext}*/}
						{/*	onClick={onNext}*/}
						{/*	disabled={status === TestOperateStatus.STOP || playback}*/}
						{/*/>*/}
						{/*<ControlButton icon={styles.iconReport} onClick={onReport} />*/}
					</div>
				</div>
			</WidgetContent>
		</>
	);
};

export default React.memo(ControlTestWidget);
