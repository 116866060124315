import { useMemo, FC, useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalContextProvider } from '@tactun/ui';
import SidebarContainer from './SidebarContainer';
import GraphContent from '../components/GraphContent';
import GraphMainSettingsModal from '../components/GraphMainSettingsModal';
import { GraphActions, GraphInfo, useRealTimeLineGraph } from '../../../components/RealTimeLineGraph';
import { DataChannelType } from '../../DataChannel';
import { useGraphSettings } from '../graphWidget.hooks';
import { generatePlotId } from '../graphWidget.tools';
import { GraphChannelsDto, GraphMetaDataDto, Plot } from '../graphWidget.types';
import { subscribeToGraph } from '../../Dashboard/dashboard.grpc';
import { WidgetProps } from '../../Dashboard/dashboard.types';
import WidgetContent from '../../Dashboard/components/Widget/WidgetContent';
import WidgetHeader from '../../Dashboard/components/Widget/WidgetHeader';
import { stateToDtoConverter } from '../graphWidget.converters';
import { Axis, GraphTools } from '../graphWidget.enums';
import Toolbar from '../components/Toolbar';
import { useStationId } from '../../Stations';

const GraphContainer: FC<WidgetProps> = ({ settings, metadata, updateSettings }) => {
	const { t } = useTranslation('dashboard');
	const stationId = useStationId();
	const timeRef = useRef<number>(0);
	const isInitialConfigurationApplied = useRef(false);
	const [contRef, setContRef] = useState<HTMLDivElement | null>(null);
	const graphState = useGraphSettings(settings as GraphChannelsDto, metadata as GraphMetaDataDto, stationId);
	const { updateSeries, addSamples, configureChart, chart, applyAction, isPaused } = useRealTimeLineGraph(
		contRef,
		graphState?.id
	);
	const graphInfo = useMemo<GraphInfo | undefined>(() => {
		if (graphState) {
			return {
				xAxisTitle: graphState.xChannel?.title,
				leftYAxisTitle: graphState.leftAxisTitle,
				leftYAxisType: 'number',
				rightYAxisTitle: graphState.rightAxisTitle,
				rightYAxisType: graphState.rightAxisTitle !== undefined ? 'number' : undefined,
				xAxisType: graphState.xChannel?.type === DataChannelType.TIME ? 'time' : 'number',
				timeWindowSize: graphState.settings.timeWindowSize
			};
		}
	}, [graphState]);

	// Init Data Channels
	useEffect(() => {
		if (graphState && chart && graphInfo && !metadata.isType) {
			if (!isInitialConfigurationApplied.current) {
				configureChart(graphInfo);
				isInitialConfigurationApplied.current = true;
			}

			const series = graphState.plots.map((plot) => ({
				id: plot.id,
				name: plot.title,
				color: plot.color,
				isVisible: plot.isVisible,
				rightYAxisTitle: plot.yAxisId === Axis.RIGHT_Y ? graphState.rightAxisTitle : undefined
			}));

			updateSeries(series);
		}
	}, [updateSeries, chart, configureChart, graphInfo, graphState, metadata.isType]);

	// configure chart after graphInfo change
	useEffect(() => {
		if (graphInfo && !metadata.isType && isInitialConfigurationApplied.current) {
			configureChart(graphInfo);
		}
	}, [configureChart, graphInfo, metadata.isType]);

	// Subscribe to Stream
	useEffect(() => {
		let stream: { unsubscribe: () => void };
		if (graphState && chart && graphInfo && isInitialConfigurationApplied.current && !metadata.isType) {
			const channels = graphState.xChannel ? [graphState.xChannel, ...graphState.yChannels] : [...graphState.yChannels];

			stream = subscribeToGraph(graphState.id, channels, Math.round(1000 / graphState.settings.dataRate), (chunk) => {
				if (isPaused(graphState.id)) return;
				const xAxisValues = chunk.find((channelData) => channelData.key?.channelId === graphState.xChannel?.id)
					?.valList;

				if (xAxisValues?.length) {
					const xMax = xAxisValues[xAxisValues?.length - 1];
					// reset graf data if stream time reseated on the device
					if (xMax < timeRef.current) {
						applyAction(GraphActions.GRAPH_RESTART, graphInfo);
					}
					timeRef.current = xMax;

					chunk
						.filter((channelData) => channelData.key?.channelId !== graphState.xChannel?.id)
						.forEach((channelData) => {
							if (channelData.key?.channelId !== undefined) {
								addSamples(
									generatePlotId({
										id: channelData.key.channelId,
										type: channelData.key.type
									}),
									{ xValues: xAxisValues, yValues: channelData.valList }
								);
							}
						});
				}
			});
		}
		return () => {
			stream?.unsubscribe?.();
		};
	}, [addSamples, chart, applyAction, graphState, isPaused, graphInfo, metadata.isType]);

	const graphMainSettingsModalId = useMemo(
		() => (graphState?.id ? `graph-modal-${graphState?.id}` : null),
		[graphState?.id]
	);

	const graphMenuItems = useMemo(
		() => [
			{
				label: t('Settings'),
				icon: 't-icon-settings',
				command: () => graphMainSettingsModalId && Modal.show(graphMainSettingsModalId)
			}
		],
		[graphMainSettingsModalId, t]
	);

	const handleUpdatePlots = useCallback(
		(plots: Plot[]) => {
			if (graphState) {
				const currentDto = stateToDtoConverter({ ...graphState, plots });
				updateSettings(currentDto.meta, currentDto.settings);
			}
		},
		[graphState, updateSettings]
	);

	const handleToolbarAction = useCallback(
		(tool: GraphTools) => {
			switch (tool) {
				case GraphTools.PAUSE:
					applyAction(GraphActions.GRAPH_PAUSE);
					break;
				case GraphTools.RESUME:
					applyAction(GraphActions.GRAPH_RESUME);
					break;
				case GraphTools.REFRESH:
					applyAction(GraphActions.GRAPH_REFRESH);
					break;
				case GraphTools.CURSOR:
					applyAction(GraphActions.GRAPH_ACTIVATE_CURSOR);
					break;
				case GraphTools.MOVE:
					applyAction(GraphActions.GRAPH_ACTIVATE_MOVE);
					break;
				case GraphTools.ZOOM_RESET:
					applyAction(GraphActions.GRAPH_ACTIVATE_ZOOM_RESET);
					break;
				case GraphTools.ZOOM_XY:
					applyAction(GraphActions.GRAPH_ACTIVATE_ZOOM_XY);
					break;
				case GraphTools.ZOOM_X:
					applyAction(GraphActions.GRAPH_ACTIVATE_ZOOM_X);
					break;
				case GraphTools.ZOOM_Y:
					applyAction(GraphActions.GRAPH_ACTIVATE_ZOOM_Y);
					break;
				case GraphTools.CLEAR:
					if (graphState) {
						const currentDto = stateToDtoConverter({ ...graphState, plots: [] });
						updateSettings(currentDto.meta, currentDto.settings);
						applyAction(GraphActions.GRAPH_CLEAR);
					}
					break;
				case GraphTools.SNAPSHOT:
					applyAction(GraphActions.GRAPH_SNAPSHOT);
					break;
			}
		},
		[applyAction, graphState, updateSettings]
	);

	return (
		<>
			<WidgetHeader customActions={graphMenuItems}>{graphState?.name}</WidgetHeader>
			<WidgetContent isType={metadata.isType}>
				<ModalContextProvider>
					<GraphContent
						minimizedView={false}
						sidebar={
							<SidebarContainer
								plots={graphState?.plots ?? []}
								onUpdate={handleUpdatePlots}
								graphId={graphState?.id}
								xChannel={settings?.xChannel}
							/>
						}
						toolbar={<Toolbar onAction={handleToolbarAction} />}
					>
						<div ref={(newRef) => setContRef(newRef)} />
					</GraphContent>
				</ModalContextProvider>
			</WidgetContent>
			{graphMainSettingsModalId && <GraphMainSettingsModal graphMainSettingsModalId={graphMainSettingsModalId} />}
		</>
	);
};

export default GraphContainer;
