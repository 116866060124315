// source: data_flow.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var rule_pb = require('./rule_pb.js');
goog.object.extend(proto, rule_pb);
goog.exportSymbol('proto.dataflow.AxisCtrl', null, global);
goog.exportSymbol('proto.dataflow.AxisCycle', null, global);
goog.exportSymbol('proto.dataflow.AxisLog', null, global);
goog.exportSymbol('proto.dataflow.ChannelType', null, global);
goog.exportSymbol('proto.dataflow.ConfigStatus', null, global);
goog.exportSymbol('proto.dataflow.DataFlowRequestBkp', null, global);
goog.exportSymbol('proto.dataflow.DataFlowResponseBkp', null, global);
goog.exportSymbol('proto.dataflow.DigitalState', null, global);
goog.exportSymbol('proto.dataflow.DioKey', null, global);
goog.exportSymbol('proto.dataflow.EntryKey', null, global);
goog.exportSymbol('proto.dataflow.Event', null, global);
goog.exportSymbol('proto.dataflow.ExtremumTypes', null, global);
goog.exportSymbol('proto.dataflow.Flow', null, global);
goog.exportSymbol('proto.dataflow.FlowConfig', null, global);
goog.exportSymbol('proto.dataflow.Graph', null, global);
goog.exportSymbol('proto.dataflow.LogType', null, global);
goog.exportSymbol('proto.dataflow.LoggingPeriodic', null, global);
goog.exportSymbol('proto.dataflow.LoggingPnV', null, global);
goog.exportSymbol('proto.dataflow.LoggingTimed', null, global);
goog.exportSymbol('proto.dataflow.Readout', null, global);
goog.exportSymbol('proto.dataflow.Stage', null, global);
goog.exportSymbol('proto.dataflow.TriggerObject', null, global);
goog.exportSymbol('proto.dataflow.TriggerProp', null, global);
goog.exportSymbol('proto.dataflow.TypedId', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.EntryKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.EntryKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.EntryKey.displayName = 'proto.dataflow.EntryKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.DioKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.DioKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.DioKey.displayName = 'proto.dataflow.DioKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.AxisCtrl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.AxisCtrl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.AxisCtrl.displayName = 'proto.dataflow.AxisCtrl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.Graph = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataflow.Graph.repeatedFields_, null);
};
goog.inherits(proto.dataflow.Graph, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.Graph.displayName = 'proto.dataflow.Graph';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.Readout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.Readout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.Readout.displayName = 'proto.dataflow.Readout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.DigitalState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.DigitalState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.DigitalState.displayName = 'proto.dataflow.DigitalState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.Event.displayName = 'proto.dataflow.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.FlowConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataflow.FlowConfig.repeatedFields_, null);
};
goog.inherits(proto.dataflow.FlowConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.FlowConfig.displayName = 'proto.dataflow.FlowConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.ConfigStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.ConfigStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.ConfigStatus.displayName = 'proto.dataflow.ConfigStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.Stage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataflow.Stage.repeatedFields_, null);
};
goog.inherits(proto.dataflow.Stage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.Stage.displayName = 'proto.dataflow.Stage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.AxisCycle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.AxisCycle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.AxisCycle.displayName = 'proto.dataflow.AxisCycle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.Flow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataflow.Flow.repeatedFields_, null);
};
goog.inherits(proto.dataflow.Flow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.Flow.displayName = 'proto.dataflow.Flow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.DataFlowResponseBkp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.DataFlowResponseBkp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.DataFlowResponseBkp.displayName = 'proto.dataflow.DataFlowResponseBkp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.DataFlowRequestBkp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.DataFlowRequestBkp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.DataFlowRequestBkp.displayName = 'proto.dataflow.DataFlowRequestBkp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.TypedId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.TypedId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.TypedId.displayName = 'proto.dataflow.TypedId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.AxisLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataflow.AxisLog.repeatedFields_, null);
};
goog.inherits(proto.dataflow.AxisLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.AxisLog.displayName = 'proto.dataflow.AxisLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.LoggingTimed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.LoggingTimed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.LoggingTimed.displayName = 'proto.dataflow.LoggingTimed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.LoggingPeriodic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.LoggingPeriodic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.LoggingPeriodic.displayName = 'proto.dataflow.LoggingPeriodic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataflow.LoggingPnV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataflow.LoggingPnV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataflow.LoggingPnV.displayName = 'proto.dataflow.LoggingPnV';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.EntryKey.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.EntryKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.EntryKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.EntryKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    channelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    widgetId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.EntryKey}
 */
proto.dataflow.EntryKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.EntryKey;
  return proto.dataflow.EntryKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.EntryKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.EntryKey}
 */
proto.dataflow.EntryKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dataflow.ChannelType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.EntryKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.EntryKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.EntryKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.EntryKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional ChannelType type = 1;
 * @return {!proto.dataflow.ChannelType}
 */
proto.dataflow.EntryKey.prototype.getType = function() {
  return /** @type {!proto.dataflow.ChannelType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dataflow.ChannelType} value
 * @return {!proto.dataflow.EntryKey} returns this
 */
proto.dataflow.EntryKey.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string channel_id = 2;
 * @return {string}
 */
proto.dataflow.EntryKey.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.EntryKey} returns this
 */
proto.dataflow.EntryKey.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string widget_id = 3;
 * @return {string}
 */
proto.dataflow.EntryKey.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.EntryKey} returns this
 */
proto.dataflow.EntryKey.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.DioKey.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.DioKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.DioKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.DioKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    widgetId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.DioKey}
 */
proto.dataflow.DioKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.DioKey;
  return proto.dataflow.DioKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.DioKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.DioKey}
 */
proto.dataflow.DioKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.DioKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.DioKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.DioKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.DioKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string channel_id = 2;
 * @return {string}
 */
proto.dataflow.DioKey.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.DioKey} returns this
 */
proto.dataflow.DioKey.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string widget_id = 3;
 * @return {string}
 */
proto.dataflow.DioKey.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.DioKey} returns this
 */
proto.dataflow.DioKey.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.AxisCtrl.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.AxisCtrl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.AxisCtrl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.AxisCtrl.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    controlId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    widgetId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.AxisCtrl}
 */
proto.dataflow.AxisCtrl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.AxisCtrl;
  return proto.dataflow.AxisCtrl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.AxisCtrl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.AxisCtrl}
 */
proto.dataflow.AxisCtrl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.AxisCtrl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.AxisCtrl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.AxisCtrl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.AxisCtrl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAxisId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string axis_id = 1;
 * @return {string}
 */
proto.dataflow.AxisCtrl.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.AxisCtrl} returns this
 */
proto.dataflow.AxisCtrl.prototype.setAxisId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string control_id = 2;
 * @return {string}
 */
proto.dataflow.AxisCtrl.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.AxisCtrl} returns this
 */
proto.dataflow.AxisCtrl.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string widget_id = 3;
 * @return {string}
 */
proto.dataflow.AxisCtrl.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.AxisCtrl} returns this
 */
proto.dataflow.AxisCtrl.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataflow.Graph.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.Graph.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.Graph.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.Graph} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.Graph.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: (f = msg.getKey()) && proto.dataflow.EntryKey.toObject(includeInstance, f),
    valList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.Graph}
 */
proto.dataflow.Graph.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.Graph;
  return proto.dataflow.Graph.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.Graph} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.Graph}
 */
proto.dataflow.Graph.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataflow.EntryKey;
      reader.readMessage(value,proto.dataflow.EntryKey.deserializeBinaryFromReader);
      msg.setKey(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addVal(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.Graph.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.Graph.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.Graph} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.Graph.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataflow.EntryKey.serializeBinaryToWriter
    );
  }
  f = message.getValList();
  if (f.length > 0) {
    writer.writePackedFloat(
      2,
      f
    );
  }
};


/**
 * optional EntryKey key = 1;
 * @return {?proto.dataflow.EntryKey}
 */
proto.dataflow.Graph.prototype.getKey = function() {
  return /** @type{?proto.dataflow.EntryKey} */ (
    jspb.Message.getWrapperField(this, proto.dataflow.EntryKey, 1));
};


/**
 * @param {?proto.dataflow.EntryKey|undefined} value
 * @return {!proto.dataflow.Graph} returns this
*/
proto.dataflow.Graph.prototype.setKey = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataflow.Graph} returns this
 */
proto.dataflow.Graph.prototype.clearKey = function() {
  return this.setKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataflow.Graph.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated float val = 2;
 * @return {!Array<number>}
 */
proto.dataflow.Graph.prototype.getValList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.dataflow.Graph} returns this
 */
proto.dataflow.Graph.prototype.setValList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.dataflow.Graph} returns this
 */
proto.dataflow.Graph.prototype.addVal = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataflow.Graph} returns this
 */
proto.dataflow.Graph.prototype.clearValList = function() {
  return this.setValList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.Readout.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.Readout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.Readout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.Readout.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: (f = msg.getKey()) && proto.dataflow.EntryKey.toObject(includeInstance, f),
    val: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    max: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    min: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    speed: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.Readout}
 */
proto.dataflow.Readout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.Readout;
  return proto.dataflow.Readout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.Readout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.Readout}
 */
proto.dataflow.Readout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataflow.EntryKey;
      reader.readMessage(value,proto.dataflow.EntryKey.deserializeBinaryFromReader);
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMax(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMin(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.Readout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.Readout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.Readout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.Readout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataflow.EntryKey.serializeBinaryToWriter
    );
  }
  f = message.getVal();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMax();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getMin();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional EntryKey key = 1;
 * @return {?proto.dataflow.EntryKey}
 */
proto.dataflow.Readout.prototype.getKey = function() {
  return /** @type{?proto.dataflow.EntryKey} */ (
    jspb.Message.getWrapperField(this, proto.dataflow.EntryKey, 1));
};


/**
 * @param {?proto.dataflow.EntryKey|undefined} value
 * @return {!proto.dataflow.Readout} returns this
*/
proto.dataflow.Readout.prototype.setKey = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataflow.Readout} returns this
 */
proto.dataflow.Readout.prototype.clearKey = function() {
  return this.setKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataflow.Readout.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float val = 2;
 * @return {number}
 */
proto.dataflow.Readout.prototype.getVal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.Readout} returns this
 */
proto.dataflow.Readout.prototype.setVal = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float max = 3;
 * @return {number}
 */
proto.dataflow.Readout.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.Readout} returns this
 */
proto.dataflow.Readout.prototype.setMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float min = 4;
 * @return {number}
 */
proto.dataflow.Readout.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.Readout} returns this
 */
proto.dataflow.Readout.prototype.setMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float speed = 5;
 * @return {number}
 */
proto.dataflow.Readout.prototype.getSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.Readout} returns this
 */
proto.dataflow.Readout.prototype.setSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.DigitalState.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.DigitalState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.DigitalState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.DigitalState.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: (f = msg.getKey()) && proto.dataflow.DioKey.toObject(includeInstance, f),
    val: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.DigitalState}
 */
proto.dataflow.DigitalState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.DigitalState;
  return proto.dataflow.DigitalState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.DigitalState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.DigitalState}
 */
proto.dataflow.DigitalState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataflow.DioKey;
      reader.readMessage(value,proto.dataflow.DioKey.deserializeBinaryFromReader);
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.DigitalState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.DigitalState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.DigitalState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.DigitalState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataflow.DioKey.serializeBinaryToWriter
    );
  }
  f = message.getVal();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional DioKey key = 1;
 * @return {?proto.dataflow.DioKey}
 */
proto.dataflow.DigitalState.prototype.getKey = function() {
  return /** @type{?proto.dataflow.DioKey} */ (
    jspb.Message.getWrapperField(this, proto.dataflow.DioKey, 1));
};


/**
 * @param {?proto.dataflow.DioKey|undefined} value
 * @return {!proto.dataflow.DigitalState} returns this
*/
proto.dataflow.DigitalState.prototype.setKey = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataflow.DigitalState} returns this
 */
proto.dataflow.DigitalState.prototype.clearKey = function() {
  return this.setKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataflow.DigitalState.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool val = 2;
 * @return {boolean}
 */
proto.dataflow.DigitalState.prototype.getVal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataflow.DigitalState} returns this
 */
proto.dataflow.DigitalState.prototype.setVal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ruleName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    trigger: (f = msg.getTrigger()) && rule_pb.TriggerDto.toObject(includeInstance, f),
    timePoint: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.Event}
 */
proto.dataflow.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.Event;
  return proto.dataflow.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.Event}
 */
proto.dataflow.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRuleName(value);
      break;
    case 3:
      var value = new rule_pb.TriggerDto;
      reader.readMessage(value,rule_pb.TriggerDto.deserializeBinaryFromReader);
      msg.setTrigger(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimePoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getRuleName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTrigger();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      rule_pb.TriggerDto.serializeBinaryToWriter
    );
  }
  f = message.getTimePoint();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional uint64 timestamp = 1;
 * @return {number}
 */
proto.dataflow.Event.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.Event} returns this
 */
proto.dataflow.Event.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string rule_name = 2;
 * @return {string}
 */
proto.dataflow.Event.prototype.getRuleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.Event} returns this
 */
proto.dataflow.Event.prototype.setRuleName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional rule.TriggerDto trigger = 3;
 * @return {?proto.rule.TriggerDto}
 */
proto.dataflow.Event.prototype.getTrigger = function() {
  return /** @type{?proto.rule.TriggerDto} */ (
    jspb.Message.getWrapperField(this, rule_pb.TriggerDto, 3));
};


/**
 * @param {?proto.rule.TriggerDto|undefined} value
 * @return {!proto.dataflow.Event} returns this
*/
proto.dataflow.Event.prototype.setTrigger = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataflow.Event} returns this
 */
proto.dataflow.Event.prototype.clearTrigger = function() {
  return this.setTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataflow.Event.prototype.hasTrigger = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 time_point = 4;
 * @return {number}
 */
proto.dataflow.Event.prototype.getTimePoint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.Event} returns this
 */
proto.dataflow.Event.prototype.setTimePoint = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataflow.FlowConfig.repeatedFields_ = [2,3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.FlowConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.FlowConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.FlowConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.FlowConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    period: jspb.Message.getFieldWithDefault(msg, 1, 0),
    readoutsList: jspb.Message.toObjectList(msg.getReadoutsList(),
    proto.dataflow.EntryKey.toObject, includeInstance),
    graphsList: jspb.Message.toObjectList(msg.getGraphsList(),
    proto.dataflow.EntryKey.toObject, includeInstance),
    enableEvents: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    diosList: jspb.Message.toObjectList(msg.getDiosList(),
    proto.dataflow.DioKey.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.FlowConfig}
 */
proto.dataflow.FlowConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.FlowConfig;
  return proto.dataflow.FlowConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.FlowConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.FlowConfig}
 */
proto.dataflow.FlowConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPeriod(value);
      break;
    case 2:
      var value = new proto.dataflow.EntryKey;
      reader.readMessage(value,proto.dataflow.EntryKey.deserializeBinaryFromReader);
      msg.addReadouts(value);
      break;
    case 3:
      var value = new proto.dataflow.EntryKey;
      reader.readMessage(value,proto.dataflow.EntryKey.deserializeBinaryFromReader);
      msg.addGraphs(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableEvents(value);
      break;
    case 5:
      var value = new proto.dataflow.DioKey;
      reader.readMessage(value,proto.dataflow.DioKey.deserializeBinaryFromReader);
      msg.addDios(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.FlowConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.FlowConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.FlowConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.FlowConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeriod();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getReadoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.dataflow.EntryKey.serializeBinaryToWriter
    );
  }
  f = message.getGraphsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.dataflow.EntryKey.serializeBinaryToWriter
    );
  }
  f = message.getEnableEvents();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDiosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.dataflow.DioKey.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 period = 1;
 * @return {number}
 */
proto.dataflow.FlowConfig.prototype.getPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.FlowConfig} returns this
 */
proto.dataflow.FlowConfig.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated EntryKey readouts = 2;
 * @return {!Array<!proto.dataflow.EntryKey>}
 */
proto.dataflow.FlowConfig.prototype.getReadoutsList = function() {
  return /** @type{!Array<!proto.dataflow.EntryKey>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataflow.EntryKey, 2));
};


/**
 * @param {!Array<!proto.dataflow.EntryKey>} value
 * @return {!proto.dataflow.FlowConfig} returns this
*/
proto.dataflow.FlowConfig.prototype.setReadoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dataflow.EntryKey=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataflow.EntryKey}
 */
proto.dataflow.FlowConfig.prototype.addReadouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dataflow.EntryKey, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataflow.FlowConfig} returns this
 */
proto.dataflow.FlowConfig.prototype.clearReadoutsList = function() {
  return this.setReadoutsList([]);
};


/**
 * repeated EntryKey graphs = 3;
 * @return {!Array<!proto.dataflow.EntryKey>}
 */
proto.dataflow.FlowConfig.prototype.getGraphsList = function() {
  return /** @type{!Array<!proto.dataflow.EntryKey>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataflow.EntryKey, 3));
};


/**
 * @param {!Array<!proto.dataflow.EntryKey>} value
 * @return {!proto.dataflow.FlowConfig} returns this
*/
proto.dataflow.FlowConfig.prototype.setGraphsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dataflow.EntryKey=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataflow.EntryKey}
 */
proto.dataflow.FlowConfig.prototype.addGraphs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dataflow.EntryKey, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataflow.FlowConfig} returns this
 */
proto.dataflow.FlowConfig.prototype.clearGraphsList = function() {
  return this.setGraphsList([]);
};


/**
 * optional bool enable_events = 4;
 * @return {boolean}
 */
proto.dataflow.FlowConfig.prototype.getEnableEvents = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataflow.FlowConfig} returns this
 */
proto.dataflow.FlowConfig.prototype.setEnableEvents = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated DioKey dios = 5;
 * @return {!Array<!proto.dataflow.DioKey>}
 */
proto.dataflow.FlowConfig.prototype.getDiosList = function() {
  return /** @type{!Array<!proto.dataflow.DioKey>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataflow.DioKey, 5));
};


/**
 * @param {!Array<!proto.dataflow.DioKey>} value
 * @return {!proto.dataflow.FlowConfig} returns this
*/
proto.dataflow.FlowConfig.prototype.setDiosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.dataflow.DioKey=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataflow.DioKey}
 */
proto.dataflow.FlowConfig.prototype.addDios = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.dataflow.DioKey, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataflow.FlowConfig} returns this
 */
proto.dataflow.FlowConfig.prototype.clearDiosList = function() {
  return this.setDiosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.ConfigStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.ConfigStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.ConfigStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.ConfigStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    ignore: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.ConfigStatus}
 */
proto.dataflow.ConfigStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.ConfigStatus;
  return proto.dataflow.ConfigStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.ConfigStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.ConfigStatus}
 */
proto.dataflow.ConfigStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.ConfigStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.ConfigStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.ConfigStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.ConfigStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ignore = 1;
 * @return {string}
 */
proto.dataflow.ConfigStatus.prototype.getIgnore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.ConfigStatus} returns this
 */
proto.dataflow.ConfigStatus.prototype.setIgnore = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.dataflow.ConfigStatus} returns this
 */
proto.dataflow.ConfigStatus.prototype.clearIgnore = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataflow.ConfigStatus.prototype.hasIgnore = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataflow.Stage.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.Stage.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.Stage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.Stage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.Stage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sequence: jspb.Message.getFieldWithDefault(msg, 2, 0),
    loopCurr: jspb.Message.getFieldWithDefault(msg, 3, 0),
    loopAll: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cyclesList: jspb.Message.toObjectList(msg.getCyclesList(),
    proto.dataflow.AxisCycle.toObject, includeInstance),
    logsList: jspb.Message.toObjectList(msg.getLogsList(),
    proto.dataflow.AxisLog.toObject, includeInstance),
    paused: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.Stage}
 */
proto.dataflow.Stage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.Stage;
  return proto.dataflow.Stage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.Stage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.Stage}
 */
proto.dataflow.Stage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSequence(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLoopCurr(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLoopAll(value);
      break;
    case 7:
      var value = new proto.dataflow.AxisCycle;
      reader.readMessage(value,proto.dataflow.AxisCycle.deserializeBinaryFromReader);
      msg.addCycles(value);
      break;
    case 8:
      var value = new proto.dataflow.AxisLog;
      reader.readMessage(value,proto.dataflow.AxisLog.deserializeBinaryFromReader);
      msg.addLogs(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaused(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.Stage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.Stage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.Stage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.Stage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getLoopCurr();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getLoopAll();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getCyclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.dataflow.AxisCycle.serializeBinaryToWriter
    );
  }
  f = message.getLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.dataflow.AxisLog.serializeBinaryToWriter
    );
  }
  f = message.getPaused();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dataflow.Stage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.Stage} returns this
 */
proto.dataflow.Stage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 sequence = 2;
 * @return {number}
 */
proto.dataflow.Stage.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.Stage} returns this
 */
proto.dataflow.Stage.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 loop_curr = 3;
 * @return {number}
 */
proto.dataflow.Stage.prototype.getLoopCurr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.Stage} returns this
 */
proto.dataflow.Stage.prototype.setLoopCurr = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 loop_all = 4;
 * @return {number}
 */
proto.dataflow.Stage.prototype.getLoopAll = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.Stage} returns this
 */
proto.dataflow.Stage.prototype.setLoopAll = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated AxisCycle cycles = 7;
 * @return {!Array<!proto.dataflow.AxisCycle>}
 */
proto.dataflow.Stage.prototype.getCyclesList = function() {
  return /** @type{!Array<!proto.dataflow.AxisCycle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataflow.AxisCycle, 7));
};


/**
 * @param {!Array<!proto.dataflow.AxisCycle>} value
 * @return {!proto.dataflow.Stage} returns this
*/
proto.dataflow.Stage.prototype.setCyclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.dataflow.AxisCycle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataflow.AxisCycle}
 */
proto.dataflow.Stage.prototype.addCycles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.dataflow.AxisCycle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataflow.Stage} returns this
 */
proto.dataflow.Stage.prototype.clearCyclesList = function() {
  return this.setCyclesList([]);
};


/**
 * repeated AxisLog logs = 8;
 * @return {!Array<!proto.dataflow.AxisLog>}
 */
proto.dataflow.Stage.prototype.getLogsList = function() {
  return /** @type{!Array<!proto.dataflow.AxisLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataflow.AxisLog, 8));
};


/**
 * @param {!Array<!proto.dataflow.AxisLog>} value
 * @return {!proto.dataflow.Stage} returns this
*/
proto.dataflow.Stage.prototype.setLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.dataflow.AxisLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataflow.AxisLog}
 */
proto.dataflow.Stage.prototype.addLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.dataflow.AxisLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataflow.Stage} returns this
 */
proto.dataflow.Stage.prototype.clearLogsList = function() {
  return this.setLogsList([]);
};


/**
 * optional bool paused = 9;
 * @return {boolean}
 */
proto.dataflow.Stage.prototype.getPaused = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataflow.Stage} returns this
 */
proto.dataflow.Stage.prototype.setPaused = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.AxisCycle.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.AxisCycle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.AxisCycle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.AxisCycle.toObject = function(includeInstance, msg) {
  var f, obj = {
    axisId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    current: jspb.Message.getFieldWithDefault(msg, 2, 0),
    all: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.AxisCycle}
 */
proto.dataflow.AxisCycle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.AxisCycle;
  return proto.dataflow.AxisCycle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.AxisCycle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.AxisCycle}
 */
proto.dataflow.AxisCycle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAxisId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAll(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.AxisCycle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.AxisCycle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.AxisCycle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.AxisCycle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAxisId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrent();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAll();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string axis_id = 1;
 * @return {string}
 */
proto.dataflow.AxisCycle.prototype.getAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.AxisCycle} returns this
 */
proto.dataflow.AxisCycle.prototype.setAxisId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 current = 2;
 * @return {number}
 */
proto.dataflow.AxisCycle.prototype.getCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.AxisCycle} returns this
 */
proto.dataflow.AxisCycle.prototype.setCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 all = 3;
 * @return {number}
 */
proto.dataflow.AxisCycle.prototype.getAll = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.AxisCycle} returns this
 */
proto.dataflow.AxisCycle.prototype.setAll = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataflow.Flow.repeatedFields_ = [1,2,3,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.Flow.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.Flow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.Flow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.Flow.toObject = function(includeInstance, msg) {
  var f, obj = {
    readoutsList: jspb.Message.toObjectList(msg.getReadoutsList(),
    proto.dataflow.Readout.toObject, includeInstance),
    graphsList: jspb.Message.toObjectList(msg.getGraphsList(),
    proto.dataflow.Graph.toObject, includeInstance),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.dataflow.Event.toObject, includeInstance),
    stage: (f = msg.getStage()) && proto.dataflow.Stage.toObject(includeInstance, f),
    diosList: jspb.Message.toObjectList(msg.getDiosList(),
    proto.dataflow.DigitalState.toObject, includeInstance),
    axisCtrlList: jspb.Message.toObjectList(msg.getAxisCtrlList(),
    proto.dataflow.AxisCtrl.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.Flow}
 */
proto.dataflow.Flow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.Flow;
  return proto.dataflow.Flow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.Flow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.Flow}
 */
proto.dataflow.Flow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataflow.Readout;
      reader.readMessage(value,proto.dataflow.Readout.deserializeBinaryFromReader);
      msg.addReadouts(value);
      break;
    case 2:
      var value = new proto.dataflow.Graph;
      reader.readMessage(value,proto.dataflow.Graph.deserializeBinaryFromReader);
      msg.addGraphs(value);
      break;
    case 3:
      var value = new proto.dataflow.Event;
      reader.readMessage(value,proto.dataflow.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 4:
      var value = new proto.dataflow.Stage;
      reader.readMessage(value,proto.dataflow.Stage.deserializeBinaryFromReader);
      msg.setStage(value);
      break;
    case 5:
      var value = new proto.dataflow.DigitalState;
      reader.readMessage(value,proto.dataflow.DigitalState.deserializeBinaryFromReader);
      msg.addDios(value);
      break;
    case 6:
      var value = new proto.dataflow.AxisCtrl;
      reader.readMessage(value,proto.dataflow.AxisCtrl.deserializeBinaryFromReader);
      msg.addAxisCtrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.Flow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.Flow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.Flow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.Flow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReadoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dataflow.Readout.serializeBinaryToWriter
    );
  }
  f = message.getGraphsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.dataflow.Graph.serializeBinaryToWriter
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.dataflow.Event.serializeBinaryToWriter
    );
  }
  f = message.getStage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dataflow.Stage.serializeBinaryToWriter
    );
  }
  f = message.getDiosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.dataflow.DigitalState.serializeBinaryToWriter
    );
  }
  f = message.getAxisCtrlList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.dataflow.AxisCtrl.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Readout readouts = 1;
 * @return {!Array<!proto.dataflow.Readout>}
 */
proto.dataflow.Flow.prototype.getReadoutsList = function() {
  return /** @type{!Array<!proto.dataflow.Readout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataflow.Readout, 1));
};


/**
 * @param {!Array<!proto.dataflow.Readout>} value
 * @return {!proto.dataflow.Flow} returns this
*/
proto.dataflow.Flow.prototype.setReadoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dataflow.Readout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataflow.Readout}
 */
proto.dataflow.Flow.prototype.addReadouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dataflow.Readout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataflow.Flow} returns this
 */
proto.dataflow.Flow.prototype.clearReadoutsList = function() {
  return this.setReadoutsList([]);
};


/**
 * repeated Graph graphs = 2;
 * @return {!Array<!proto.dataflow.Graph>}
 */
proto.dataflow.Flow.prototype.getGraphsList = function() {
  return /** @type{!Array<!proto.dataflow.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataflow.Graph, 2));
};


/**
 * @param {!Array<!proto.dataflow.Graph>} value
 * @return {!proto.dataflow.Flow} returns this
*/
proto.dataflow.Flow.prototype.setGraphsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dataflow.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataflow.Graph}
 */
proto.dataflow.Flow.prototype.addGraphs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dataflow.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataflow.Flow} returns this
 */
proto.dataflow.Flow.prototype.clearGraphsList = function() {
  return this.setGraphsList([]);
};


/**
 * repeated Event events = 3;
 * @return {!Array<!proto.dataflow.Event>}
 */
proto.dataflow.Flow.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.dataflow.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataflow.Event, 3));
};


/**
 * @param {!Array<!proto.dataflow.Event>} value
 * @return {!proto.dataflow.Flow} returns this
*/
proto.dataflow.Flow.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dataflow.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataflow.Event}
 */
proto.dataflow.Flow.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dataflow.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataflow.Flow} returns this
 */
proto.dataflow.Flow.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional Stage stage = 4;
 * @return {?proto.dataflow.Stage}
 */
proto.dataflow.Flow.prototype.getStage = function() {
  return /** @type{?proto.dataflow.Stage} */ (
    jspb.Message.getWrapperField(this, proto.dataflow.Stage, 4));
};


/**
 * @param {?proto.dataflow.Stage|undefined} value
 * @return {!proto.dataflow.Flow} returns this
*/
proto.dataflow.Flow.prototype.setStage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataflow.Flow} returns this
 */
proto.dataflow.Flow.prototype.clearStage = function() {
  return this.setStage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataflow.Flow.prototype.hasStage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated DigitalState dios = 5;
 * @return {!Array<!proto.dataflow.DigitalState>}
 */
proto.dataflow.Flow.prototype.getDiosList = function() {
  return /** @type{!Array<!proto.dataflow.DigitalState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataflow.DigitalState, 5));
};


/**
 * @param {!Array<!proto.dataflow.DigitalState>} value
 * @return {!proto.dataflow.Flow} returns this
*/
proto.dataflow.Flow.prototype.setDiosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.dataflow.DigitalState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataflow.DigitalState}
 */
proto.dataflow.Flow.prototype.addDios = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.dataflow.DigitalState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataflow.Flow} returns this
 */
proto.dataflow.Flow.prototype.clearDiosList = function() {
  return this.setDiosList([]);
};


/**
 * repeated AxisCtrl axis_ctrl = 6;
 * @return {!Array<!proto.dataflow.AxisCtrl>}
 */
proto.dataflow.Flow.prototype.getAxisCtrlList = function() {
  return /** @type{!Array<!proto.dataflow.AxisCtrl>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataflow.AxisCtrl, 6));
};


/**
 * @param {!Array<!proto.dataflow.AxisCtrl>} value
 * @return {!proto.dataflow.Flow} returns this
*/
proto.dataflow.Flow.prototype.setAxisCtrlList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.dataflow.AxisCtrl=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataflow.AxisCtrl}
 */
proto.dataflow.Flow.prototype.addAxisCtrl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.dataflow.AxisCtrl, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataflow.Flow} returns this
 */
proto.dataflow.Flow.prototype.clearAxisCtrlList = function() {
  return this.setAxisCtrlList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.DataFlowResponseBkp.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.DataFlowResponseBkp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.DataFlowResponseBkp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.DataFlowResponseBkp.toObject = function(includeInstance, msg) {
  var f, obj = {
    first: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    second: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.DataFlowResponseBkp}
 */
proto.dataflow.DataFlowResponseBkp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.DataFlowResponseBkp;
  return proto.dataflow.DataFlowResponseBkp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.DataFlowResponseBkp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.DataFlowResponseBkp}
 */
proto.dataflow.DataFlowResponseBkp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFirst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSecond(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.DataFlowResponseBkp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.DataFlowResponseBkp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.DataFlowResponseBkp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.DataFlowResponseBkp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirst();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getSecond();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float first = 1;
 * @return {number}
 */
proto.dataflow.DataFlowResponseBkp.prototype.getFirst = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.DataFlowResponseBkp} returns this
 */
proto.dataflow.DataFlowResponseBkp.prototype.setFirst = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float second = 2;
 * @return {number}
 */
proto.dataflow.DataFlowResponseBkp.prototype.getSecond = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.DataFlowResponseBkp} returns this
 */
proto.dataflow.DataFlowResponseBkp.prototype.setSecond = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.DataFlowRequestBkp.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.DataFlowRequestBkp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.DataFlowRequestBkp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.DataFlowRequestBkp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.DataFlowRequestBkp}
 */
proto.dataflow.DataFlowRequestBkp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.DataFlowRequestBkp;
  return proto.dataflow.DataFlowRequestBkp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.DataFlowRequestBkp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.DataFlowRequestBkp}
 */
proto.dataflow.DataFlowRequestBkp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.DataFlowRequestBkp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.DataFlowRequestBkp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.DataFlowRequestBkp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.DataFlowRequestBkp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dataflow.DataFlowRequestBkp.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.DataFlowRequestBkp} returns this
 */
proto.dataflow.DataFlowRequestBkp.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.TypedId.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.TypedId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.TypedId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.TypedId.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    channelId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.TypedId}
 */
proto.dataflow.TypedId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.TypedId;
  return proto.dataflow.TypedId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.TypedId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.TypedId}
 */
proto.dataflow.TypedId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dataflow.ChannelType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.TypedId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.TypedId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.TypedId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.TypedId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChannelType type = 1;
 * @return {!proto.dataflow.ChannelType}
 */
proto.dataflow.TypedId.prototype.getType = function() {
  return /** @type {!proto.dataflow.ChannelType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dataflow.ChannelType} value
 * @return {!proto.dataflow.TypedId} returns this
 */
proto.dataflow.TypedId.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string channel_id = 2;
 * @return {string}
 */
proto.dataflow.TypedId.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.TypedId} returns this
 */
proto.dataflow.TypedId.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataflow.AxisLog.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.AxisLog.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.AxisLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.AxisLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.AxisLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    typedidList: jspb.Message.toObjectList(msg.getTypedidList(),
    proto.dataflow.TypedId.toObject, includeInstance),
    rtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timed: (f = msg.getTimed()) && proto.dataflow.LoggingTimed.toObject(includeInstance, f),
    periodic: (f = msg.getPeriodic()) && proto.dataflow.LoggingPeriodic.toObject(includeInstance, f),
    pnv: (f = msg.getPnv()) && proto.dataflow.LoggingPnV.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.AxisLog}
 */
proto.dataflow.AxisLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.AxisLog;
  return proto.dataflow.AxisLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.AxisLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.AxisLog}
 */
proto.dataflow.AxisLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataflow.TypedId;
      reader.readMessage(value,proto.dataflow.TypedId.deserializeBinaryFromReader);
      msg.addTypedid(value);
      break;
    case 2:
      var value = /** @type {!proto.dataflow.LogType} */ (reader.readEnum());
      msg.setRtype(value);
      break;
    case 50:
      var value = new proto.dataflow.LoggingTimed;
      reader.readMessage(value,proto.dataflow.LoggingTimed.deserializeBinaryFromReader);
      msg.setTimed(value);
      break;
    case 51:
      var value = new proto.dataflow.LoggingPeriodic;
      reader.readMessage(value,proto.dataflow.LoggingPeriodic.deserializeBinaryFromReader);
      msg.setPeriodic(value);
      break;
    case 52:
      var value = new proto.dataflow.LoggingPnV;
      reader.readMessage(value,proto.dataflow.LoggingPnV.deserializeBinaryFromReader);
      msg.setPnv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.AxisLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.AxisLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.AxisLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.AxisLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypedidList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dataflow.TypedId.serializeBinaryToWriter
    );
  }
  f = message.getRtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTimed();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.dataflow.LoggingTimed.serializeBinaryToWriter
    );
  }
  f = message.getPeriodic();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.dataflow.LoggingPeriodic.serializeBinaryToWriter
    );
  }
  f = message.getPnv();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.dataflow.LoggingPnV.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TypedId typedId = 1;
 * @return {!Array<!proto.dataflow.TypedId>}
 */
proto.dataflow.AxisLog.prototype.getTypedidList = function() {
  return /** @type{!Array<!proto.dataflow.TypedId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataflow.TypedId, 1));
};


/**
 * @param {!Array<!proto.dataflow.TypedId>} value
 * @return {!proto.dataflow.AxisLog} returns this
*/
proto.dataflow.AxisLog.prototype.setTypedidList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dataflow.TypedId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataflow.TypedId}
 */
proto.dataflow.AxisLog.prototype.addTypedid = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dataflow.TypedId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataflow.AxisLog} returns this
 */
proto.dataflow.AxisLog.prototype.clearTypedidList = function() {
  return this.setTypedidList([]);
};


/**
 * optional LogType rtype = 2;
 * @return {!proto.dataflow.LogType}
 */
proto.dataflow.AxisLog.prototype.getRtype = function() {
  return /** @type {!proto.dataflow.LogType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.dataflow.LogType} value
 * @return {!proto.dataflow.AxisLog} returns this
 */
proto.dataflow.AxisLog.prototype.setRtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional LoggingTimed timed = 50;
 * @return {?proto.dataflow.LoggingTimed}
 */
proto.dataflow.AxisLog.prototype.getTimed = function() {
  return /** @type{?proto.dataflow.LoggingTimed} */ (
    jspb.Message.getWrapperField(this, proto.dataflow.LoggingTimed, 50));
};


/**
 * @param {?proto.dataflow.LoggingTimed|undefined} value
 * @return {!proto.dataflow.AxisLog} returns this
*/
proto.dataflow.AxisLog.prototype.setTimed = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataflow.AxisLog} returns this
 */
proto.dataflow.AxisLog.prototype.clearTimed = function() {
  return this.setTimed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataflow.AxisLog.prototype.hasTimed = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional LoggingPeriodic periodic = 51;
 * @return {?proto.dataflow.LoggingPeriodic}
 */
proto.dataflow.AxisLog.prototype.getPeriodic = function() {
  return /** @type{?proto.dataflow.LoggingPeriodic} */ (
    jspb.Message.getWrapperField(this, proto.dataflow.LoggingPeriodic, 51));
};


/**
 * @param {?proto.dataflow.LoggingPeriodic|undefined} value
 * @return {!proto.dataflow.AxisLog} returns this
*/
proto.dataflow.AxisLog.prototype.setPeriodic = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataflow.AxisLog} returns this
 */
proto.dataflow.AxisLog.prototype.clearPeriodic = function() {
  return this.setPeriodic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataflow.AxisLog.prototype.hasPeriodic = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional LoggingPnV pnv = 52;
 * @return {?proto.dataflow.LoggingPnV}
 */
proto.dataflow.AxisLog.prototype.getPnv = function() {
  return /** @type{?proto.dataflow.LoggingPnV} */ (
    jspb.Message.getWrapperField(this, proto.dataflow.LoggingPnV, 52));
};


/**
 * @param {?proto.dataflow.LoggingPnV|undefined} value
 * @return {!proto.dataflow.AxisLog} returns this
*/
proto.dataflow.AxisLog.prototype.setPnv = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataflow.AxisLog} returns this
 */
proto.dataflow.AxisLog.prototype.clearPnv = function() {
  return this.setPnv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataflow.AxisLog.prototype.hasPnv = function() {
  return jspb.Message.getField(this, 52) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.LoggingTimed.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.LoggingTimed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.LoggingTimed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.LoggingTimed.toObject = function(includeInstance, msg) {
  var f, obj = {
    rate: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.LoggingTimed}
 */
proto.dataflow.LoggingTimed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.LoggingTimed;
  return proto.dataflow.LoggingTimed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.LoggingTimed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.LoggingTimed}
 */
proto.dataflow.LoggingTimed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.LoggingTimed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.LoggingTimed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.LoggingTimed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.LoggingTimed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRate();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float rate = 1;
 * @return {number}
 */
proto.dataflow.LoggingTimed.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.LoggingTimed} returns this
 */
proto.dataflow.LoggingTimed.prototype.setRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.LoggingPeriodic.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.LoggingPeriodic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.LoggingPeriodic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.LoggingPeriodic.toObject = function(includeInstance, msg) {
  var f, obj = {
    interval: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    rate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.LoggingPeriodic}
 */
proto.dataflow.LoggingPeriodic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.LoggingPeriodic;
  return proto.dataflow.LoggingPeriodic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.LoggingPeriodic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.LoggingPeriodic}
 */
proto.dataflow.LoggingPeriodic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInterval(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.LoggingPeriodic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.LoggingPeriodic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.LoggingPeriodic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.LoggingPeriodic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInterval();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getRate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional float interval = 1;
 * @return {number}
 */
proto.dataflow.LoggingPeriodic.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.LoggingPeriodic} returns this
 */
proto.dataflow.LoggingPeriodic.prototype.setInterval = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float duration = 2;
 * @return {number}
 */
proto.dataflow.LoggingPeriodic.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.LoggingPeriodic} returns this
 */
proto.dataflow.LoggingPeriodic.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float rate = 3;
 * @return {number}
 */
proto.dataflow.LoggingPeriodic.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dataflow.LoggingPeriodic} returns this
 */
proto.dataflow.LoggingPeriodic.prototype.setRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataflow.LoggingPnV.prototype.toObject = function(opt_includeInstance) {
  return proto.dataflow.LoggingPnV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataflow.LoggingPnV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.LoggingPnV.toObject = function(includeInstance, msg) {
  var f, obj = {
    extremum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    refAxisId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataflow.LoggingPnV}
 */
proto.dataflow.LoggingPnV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataflow.LoggingPnV;
  return proto.dataflow.LoggingPnV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataflow.LoggingPnV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataflow.LoggingPnV}
 */
proto.dataflow.LoggingPnV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dataflow.ExtremumTypes} */ (reader.readEnum());
      msg.setExtremum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefAxisId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataflow.LoggingPnV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataflow.LoggingPnV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataflow.LoggingPnV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataflow.LoggingPnV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtremum();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRefAxisId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ExtremumTypes extremum = 1;
 * @return {!proto.dataflow.ExtremumTypes}
 */
proto.dataflow.LoggingPnV.prototype.getExtremum = function() {
  return /** @type {!proto.dataflow.ExtremumTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dataflow.ExtremumTypes} value
 * @return {!proto.dataflow.LoggingPnV} returns this
 */
proto.dataflow.LoggingPnV.prototype.setExtremum = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string ref_axis_id = 2;
 * @return {string}
 */
proto.dataflow.LoggingPnV.prototype.getRefAxisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataflow.LoggingPnV} returns this
 */
proto.dataflow.LoggingPnV.prototype.setRefAxisId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.dataflow.ChannelType = {
  SENSOR: 0,
  MEASUREMENT: 1,
  SET_POINT: 2,
  CYCLE: 3,
  TIME: 4,
  COMP_SET_POINT: 5
};

/**
 * @enum {number}
 */
proto.dataflow.TriggerObject = {
  E_STOP: 0,
  UP_LIMIT: 1,
  LO_LIMIT: 2
};

/**
 * @enum {number}
 */
proto.dataflow.TriggerProp = {
  ON: 0,
  OFF: 1
};

/**
 * @enum {number}
 */
proto.dataflow.LogType = {
  LOG_TIMED: 0,
  LOG_PERIODIC: 1,
  LOG_PEAK_AND_VALLEY: 2
};

/**
 * @enum {number}
 */
proto.dataflow.ExtremumTypes = {
  EXTREMUM_PEAKS: 0,
  EXTREMUM_VALLEYS: 1,
  EXTREMUM_PEAKS_VALLEYS: 2
};

goog.object.extend(exports, proto.dataflow);
