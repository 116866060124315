import React from 'react';
import { ISubframeForm } from '../../../CustomWidgets';
import CustomButtonGroup from '../CustomButtonGroup';
import CustomButtonContainer from '../CustomButtonContainer';
import { FrameOrientation } from '../../buttonWidget.enums';

interface IActionButtonProps {
	subFrame: ISubframeForm;
}

const ButtonWidgetSubframe: React.FC<IActionButtonProps> = ({ subFrame }) => {
	return (
		<CustomButtonGroup subFrame={subFrame}>
			{subFrame.buttons?.map((button, buttonIndex) => (
				<CustomButtonContainer
					key={buttonIndex}
					button={button}
					width={
						subFrame.orientation === FrameOrientation.HORIZONTAL && subFrame.numberOfButtons
							? Math.floor(100 / subFrame.numberOfButtons)
							: 100
					}
					height={
						subFrame.orientation === FrameOrientation.VERTICAL && subFrame.numberOfButtons
							? Math.floor(100 / subFrame.numberOfButtons)
							: 100
					}
				/>
			))}
		</CustomButtonGroup>
	);
};

export default React.memo(ButtonWidgetSubframe);
