import * as yup from 'yup';
import { alphaNumericRegex } from '../../constants/regexes';
import { UnitSystemTypes } from '../Units';
import * as api from './tests.api';
import { DataStorageTypes, StorageFullBehaviorTypes } from './tests.enums';

export const testSchema = yup
	.object({
		id: yup.string().optional(),
		name: yup
			.string()
			.required('Name is a required field')
			.max(30)
			.matches(alphaNumericRegex, 'Is not in correct format')
			.test('checkTestUniqueness', 'Name is used', function (value, form) {
				if (!value) return true;
				return api.checkName(value, form?.parent?.id);
			}),
		standard: yup.string().required('Standard is a required field'),
		station: yup.string().required('Station is a required field'),
		unitSystem: yup.mixed<UnitSystemTypes>().required('Unit system is a required field'),
		timeUnit: yup.string().required('Time unit is a required field'),
		axis: yup
			.array()
			.of(yup.string().required())
			.min(1, 'Must have at least 1 Axis')
			.required('Axis is a required field'),
		specimenType: yup.string().required('Specimen type is a required field'),
		dataStorage: yup.mixed<DataStorageTypes>().required('Data storage is a required field'),
		storageFullBehavior: yup.mixed<StorageFullBehaviorTypes>().required('Storage full behaviour is a required field'),
		description: yup.string().optional().max(1000),
		imageId: yup.string().optional()
	})
	.required();
