export enum ControlChannelNames {
	UP = 'UP',
	UP_FAST = 'UP_FAST',
	DOWN = 'DOWN',
	DOWN_FAST = 'DOWN_FAST'
}

export enum EAxisActions {
	HOME = 'home',
	UP_SPEED = 'up_speed',
	UP = 'up',
	DOWN_SPEED = 'down_speed',
	DOWN = 'down'
}
