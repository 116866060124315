import * as React from 'react';
import { IWizardValues } from './types';
import WizardContext from './wizardContext';

export const useWizard = () => {
	const context = React.useContext(WizardContext);

	if (!context) {
		throw Error('Wrap your step with `Wizard`');
	} else {
		return context as IWizardValues;
	}
};
