export enum ActionObjectTypes {
	DIGITAL_OUTPUT = 0,
	CONTROL_CHANNEL,
	SPECIMEN,
	CALCULATION,
	TEST,
	STAGE,
	MESSAGE_BOX_WITH_CONFIRMATION,
	MESSAGE_BOX,
	REQUEST_VARIABLE_ENTRY,
	REPORT,
	REQUEST_NOTE_ENTRY,
	MEASUREMENT,
	CAMERA,
	AXIS,
	STATION,
	UI_BUTTON,
	VARIABLE
}

export enum ActionDtoObjectTypes {
	DIGITAL_OUTPUT = 0,
	CONTROL,
	SPECIMEN,
	CALCULATION,
	TEST,
	STAGE,
	CLIENT_MSG_BOX_WITH_CONFIRMATION,
	CLIENT_MSG_BOX,
	CLIENT_REQUEST_VAR_ENTRY,
	CLIENT_REPORT,
	CLIENT_REQUEST_NOTE_ENTRY,
	MEASUREMENT,
	CAMERA,
	AXIS,
	STATION,
	UI_BUTTON,
	VARIABLE
}

export enum ActionStationProperties {
	IN_TEST = 0,
	IDLE,
	ACTIVE,
	SAFE
}

export enum ActionDigitalOutputProperties {
	PULSE_START = 0,
	PULSE_STOP,
	LOW,
	HIGH
}

export enum ActionControlChannelProperties {
	OPERATE = 0,
	DISABLE,
	UPDATE,
	SET
}

export enum ActionTestProperties {
	START = 0,
	STOP,
	PAUSE,
	RESUME
}

export enum ActionUIButtonProperties {
	PRESS = 0,
	RELEASE,
	ENABLE,
	DISABLE
}

export enum ActionCalculationProperties {
	RUN = 0,
	DRAW_MODULES_LINE
}

export enum ActionMeasurementProperties {
	TARE = 0,
	UNTARE,
	GET_MEASUREMENT_VALUE
}

export enum ActionAxisProperties {
	ENABLE = 0,
	DISABLE,
	WF_TARE,
	PID_RESET
}

export enum ActionStageProperties {
	STOP = 0,
	JUMP_NEXT_STAGE
}

export enum AxisActionAdditionalChannels {
	WAVEFORM = 0,
	OUTPUT
}

export enum ActionSpecimenProperties {
	SLACK_CORRECTION = 0,
	STRESS_POINTS,
	STRAIN_POINTS,
	MAX_STRESS
}

export type PropertiesType =
	| ActionStationProperties
	| ActionDigitalOutputProperties
	| ActionControlChannelProperties
	| ActionTestProperties
	| ActionCalculationProperties
	| ActionMeasurementProperties
	| ActionAxisProperties;

export const Properties = {
	...ActionStationProperties,
	...ActionDigitalOutputProperties,
	...ActionControlChannelProperties,
	...ActionTestProperties,
	...ActionCalculationProperties,
	...ActionMeasurementProperties,
	...ActionAxisProperties
};

export enum ActionRunType {
	LINEAR = 0,
	PARALLEL
}
