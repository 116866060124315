import React, { FC, useEffect } from 'react';
import { Button, Form, InputNumber, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { tareSchema } from '../../readout.schemas';
import { TareForm } from '../../readout.types';

interface TareToValueModalProps {
	onCancel: () => void;
	onSave: (value: number) => void;
	isOpen: boolean;
}

const tareFormId = 'tareFormId';

export const TareToValueModal: FC<TareToValueModalProps> = (props) => {
	const { onCancel, onSave, isOpen } = props;
	const { t } = useTranslation('dashboard');

	const {
		control,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm<TareForm>({
		resolver: yupResolver(tareSchema)
	});

	useEffect(() => {
		if (isOpen) reset({ tare: 0 });
	}, [isOpen, reset]);

	const onSubmit = handleSubmit((data) => onSave(data.tare));

	return (
		<Modal id="tareValue" isOpen={isOpen} onClose={onCancel}>
			<Modal.Header>{t('Tare to value')}</Modal.Header>
			<Modal.Content>
				<Form onSubmit={onSubmit} id={tareFormId}>
					<Controller
						name="tare"
						control={control}
						render={({ field }) => <InputNumber {...field} label={t('Value')} error={errors.tare?.message} />}
					/>
				</Form>
			</Modal.Content>
			<Modal.Footer>
				<Button onClick={onCancel} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button form={tareFormId} color="secondary" variant="contained">
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default TareToValueModal;
