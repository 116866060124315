import { grpc } from '@improbable-eng/grpc-web';
import { ProtobufMessage } from '@improbable-eng/grpc-web/dist/typings/message';
import { UnaryOutput } from '@improbable-eng/grpc-web/dist/typings/unary';
import { OperateRequest, testService } from '@tactun/grpc-client';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTestState } from '../Dashboard/dashboard.hooks';
import { ITestFullResponseDto } from '../Tests';
import { getFullTestById } from '../Tests/tests.api';
import { getSpecimen } from '../Specimens/specimens.api';
import { testInfoToStartTestRequestConverter } from './controlTest.converters';
import { TestOperateCommands, TestOperateStatus } from './controlTest.enums';
import { ControlTestError } from './controlTest.types';
import { useEvalSpecimenFormula } from '../Specimens';
import { useUnitsMap } from '../Units';
import { cloneDeep } from 'lodash';
import { ActionDtoObjectTypes } from '../Actions';
import { tError } from '../../tools/logger';

export const useControlTest = (testId?: string, isDisabled: boolean = false) => {
	const [testCache, setTestCache] = useState<ITestFullResponseDto>();
	const [error, setError] = useState<ControlTestError>();
	const grpcRequestRef = useRef<grpc.Request>();
	const { testInfo } = useTestState(testId, isDisabled);
	const { evalSpecimenFormula } = useEvalSpecimenFormula();

	const units = useUnitsMap(isDisabled);
	const start = useCallback(
		async (testId: string, specimenId: string) => {
			if (process.env.REACT_APP_DEVICE_URL && testInfo.state === TestOperateStatus.STOPPED) {
				let test = testCache;
				if (!test) {
					test = await getFullTestById(testId);
				}
				const specimen = await getSpecimen(specimenId);
				const evaluatedSpecimen = await evalSpecimenFormula(specimen);

				/**
				 * TODO START revert this after demo, Sorry Armen :) Artur asked for this
				 */
				const clonedTest = cloneDeep(test);
				clonedTest.stages?.forEach(
					(stage) =>
						stage.mainStageActions?.forEach(
							(stageAction) =>
								stageAction.actions?.forEach((action) => {
									if (
										action?.rulePositiveActions?.[0].type === ActionDtoObjectTypes.CALCULATION &&
										action.rulePositiveActions[0].calculation
									) {
										action.rulePositiveActions[0].calculation.unit =
											units[action?.rulePositiveActions?.[0].calculation.unit];
									}
								})
						)
				);
				/**
				 * TODO END
				 */

				const startRequest = testInfoToStartTestRequestConverter({
					...clonedTest,
					specimen: evaluatedSpecimen
				});

				const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

				grpcRequestRef.current = grpc.unary(testService.Test.Start, {
					request: startRequest,
					host: process.env.REACT_APP_DEVICE_URL,
					transport: myTransport,
					onEnd: (output: UnaryOutput<ProtobufMessage>) => {
						if (output.status === grpc.Code.OK) {
							setTestCache(test);
						} else {
							setTestCache(undefined);
							tError(
								`GRPC stream end code: "${output.status.toString()}" \nGRPC stream end message: "${output.message}" \n `
							);

							setError({ code: output.status.toString(), message: output.statusMessage ?? '' });
						}
					}
				});
			}
		},
		[evalSpecimenFormula, testCache, testInfo.state, units]
	);

	const operate = useCallback(
		(testId: string, command: TestOperateCommands) => {
			if (process.env.REACT_APP_DEVICE_URL && testInfo.state !== TestOperateStatus.STOPPED && testId) {
				const operateRequest = new OperateRequest();
				operateRequest.setId(testId);
				operateRequest.setCmd(command);

				const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

				grpcRequestRef.current = grpc.unary(testService.Test.Operate, {
					request: operateRequest,
					host: process.env.REACT_APP_DEVICE_URL,
					transport: myTransport,
					onEnd: (output: UnaryOutput<ProtobufMessage>) => {
						if (output.status === grpc.Code.OK) {
							if (command === TestOperateCommands.STOP) {
								setTestCache(undefined);
							}
						} else {
							tError(
								`GRPC stream end code: "${output.status.toString()}" \nGRPC stream end message: "${output.message}" \n `
							);

							setError({ code: output.status.toString(), message: output.statusMessage ?? '' });
						}
					}
				});
			}
		},
		[testInfo.state]
	);

	return useMemo(() => {
		return { start, operate, testState: testInfo.state, error };
	}, [start, operate, testInfo, error]);
};
