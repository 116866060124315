import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import * as api from './auxiliaryDevice.api';
import { formRequestConverter } from './auxiliaryDevice.converters';
import { AuxiliaryDeviceFormType } from './auxiliaryDevice.types';

export const useAuxiliaryDevice = (auxiliaryDeviceId?: string) => {
	const auxiliaryDeviceQueryKey = useMemo(() => ['auxiliaryDevice', auxiliaryDeviceId], [auxiliaryDeviceId]);

	const { data: auxiliaryDeviceDto } = useQuery({
		queryKey: auxiliaryDeviceQueryKey,
		queryFn: () => api.getAuxiliaryDevice(auxiliaryDeviceId),
		enabled: !!auxiliaryDeviceId
	});

	const queryClient = useQueryClient();

	const pullAuxiliaryDevice = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: auxiliaryDeviceQueryKey });
	}, [queryClient, auxiliaryDeviceQueryKey]);

	return {
		auxiliaryDeviceDto,
		pullAuxiliaryDevice
	};
};

export const useStationAuxiliaryDevices = (stationId?: string) => {
	const { data: stationAuxiliaryDevicesResponse, isLoading: isStationAuxiliaryDevicesLoading } = useQuery({
		queryKey: ['stationAuxiliaryDevice', { stationId }],
		queryFn: () => api.getStationAuxiliaryDevices(stationId),
		enabled: !!stationId
	});

	const queryClient = useQueryClient();

	const pullStationAuxiliaryDevice = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: ['stationAuxiliaryDevice', { stationId }] });
	}, [queryClient, stationId]);

	const deleteAuxiliaryDevice = useCallback(
		(id: string) => {
			queryClient.setQueryData<{ id: string }[]>(['stationAuxiliaryDevice', { stationId }], (old) => {
				if (!old) return undefined;
				return old?.filter((st) => st.id !== id);
			});
		},
		[queryClient, stationId]
	);

	return {
		stationAuxiliaryDevicesResponse,
		isStationAuxiliaryDevicesLoading,
		pullStationAuxiliaryDevice,
		deleteAuxiliaryDevice
	};
};

export const useAuxiliaryDeviceTypes = (stationId?: string) => {
	const stationAuxiliaryDeviceTypesQueryKey = ['stationAuxiliaryDevicesTypes'];

	const { data } = useQuery({
		queryKey: stationAuxiliaryDeviceTypesQueryKey,
		queryFn: () => api.getTypes({ stationId }),
		enabled: !!stationId
	});

	return data;
};

export const useAuxiliaryDeviceDirections = (stationId?: string, axisId?: string) => {
	const stationAuxiliaryDeviceDirectionsQueryKey = ['stationAuxiliaryDevicesDirections', stationId];

	const { data } = useQuery({
		queryKey: stationAuxiliaryDeviceDirectionsQueryKey,
		queryFn: () => api.getDirections({ stationId, axisId }),
		enabled: !!stationId && !!axisId
	});

	return data;
};

export const useAuxiliaryDeviceAxes = (stationId?: string, auxiliaryDeviceId?: string) => {
	const { data } = useQuery({
		queryKey: ['stationAuxiliaryDevicesAxes', stationId],
		queryFn: () =>
			api.getAxes({
				stationId,
				auxiliaryDeviceId
			}),
		enabled: !!stationId
	});

	return data;
};

export const useSaveAuxiliaryDevice = (auxiliaryDeviceId?: string, onSaveSuccess?: () => void) => {
	const createMutation = useMutation({
		mutationFn: (auxiliaryDevice: any) => api.createAuxiliaryDevice(auxiliaryDevice),
		onSuccess: () => {
			if (onSaveSuccess) onSaveSuccess();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { auxiliaryDevice: any; id: string }) =>
			api.updateAuxiliaryDevice(data.auxiliaryDevice, data.id),

		onSuccess: () => {
			if (onSaveSuccess) onSaveSuccess();
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const save = useCallback(
		(data: AuxiliaryDeviceFormType) => {
			const filteredData = formRequestConverter(data);
			if (auxiliaryDeviceId) {
				updateMutation.mutate({
					auxiliaryDevice: filteredData,
					id: auxiliaryDeviceId
				});
			} else {
				createMutation.mutate(filteredData);
			}
		},
		[auxiliaryDeviceId, createMutation, updateMutation]
	);

	return {
		save,
		isLoading: createMutation.isPending || updateMutation.isPending
	};
};
