import { OffsetCalibrationStatus } from './offsetCalibration.enums';

export const getIconNameByStatus = (status: OffsetCalibrationStatus) => {
	switch (status) {
		case OffsetCalibrationStatus.NOT_STARTED:
			return 'dropdown-remove';
		case OffsetCalibrationStatus.SUCCESS:
			return 'success';
		case OffsetCalibrationStatus.FAILED:
			return 'warning1';
		case OffsetCalibrationStatus.IN_PROGRESS:
			return 'loading-1';
	}
};
