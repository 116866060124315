import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@tactun/ui';
import { PageLayout } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { CrudActions, TableFilters } from '../../../../components';
import { INamedEntity, ITableColumns } from '../../../../types';
import { CustomerCrudActionTypes } from '../../customers.enums';
import { useTableConfigs } from '../../../../hooks';

interface CustomersTableProps {
	onAction: (type: CustomerCrudActionTypes, data?: Record<string, string>) => void;
	data?: Record<string, string>[];
	isLoading: boolean;
}

const CustomersTable: FC<CustomersTableProps> = ({ data, isLoading, onAction }) => {
	const { t } = useTranslation('axes');

	const transColumns = useMemo<ITableColumns[]>(() => {
		return [
			{ field: 'companyName', header: t('Company Name') },
			{ field: 'creationDate', header: t('Creation Date') },
			{ field: 'ordersQuantity', header: t('Orders Quantity') },
			{ field: 'latestOrderDate', header: t('Latest Order Date') },
			{ field: 'groups', header: t('Groups') }
		];
	}, [t]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<INamedEntity>(transColumns);

	const createMenuItems = useCallback(
		(data: any) => [
			{ label: t('View'), command: () => onAction(CustomerCrudActionTypes.DUPLICATE, data) },

			{ label: t('Order history'), command: () => onAction(CustomerCrudActionTypes.OPEN_ORDER_HISTORY, data) },
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(CustomerCrudActionTypes.DELETE, data)
			}
		],
		[t, onAction]
	);

	const handleCreate = useCallback(() => {
		onAction(CustomerCrudActionTypes.CREATE);
	}, [onAction]);

	return (
		<PageLayout info={t('Customers and Orders')}>
			<PageLayout.Header title={t('Customers')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('customer')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters
					onFilterChange={handleFilterChange}
					onCancel={handleCancel}
					selected={selected as INamedEntity[]}
				/>
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={data}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(CustomerCrudActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default CustomersTable;
