import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import * as api from './dashboardTypes.api';
import { DashboardTypeFormType, IDashboardTypeRequest, IDashboardTypeResponse } from './dashboardTypes.types';
import { dashboardTypeFormToRequest, dashboardTypeResponseToForm } from './dashboardTypes.converters';
import { Widget } from '../Dashboard/dashboard.types';
import { typeDefaults } from '../Dashboard/dashboard.const';
import { findEmptySpace } from '../Dashboard/dashboard.tools';
import { heightRowToPx } from '../Dashboard/dashboard.converters';

export const useDashboardTypes = () => {
	const { data: dashboardTypes, isLoading: isDashboardTypesLoading } = useQuery({
		queryKey: ['dashboardTypes'],
		queryFn: () => api.getDashboardTypes()
	});
	const queryClient = useQueryClient();

	const updateDashboardTypes = () => {
		queryClient.invalidateQueries({ queryKey: ['dashboardTypes'] });
	};

	const deleteDashboardType = useCallback(
		(id: string) => {
			queryClient.setQueryData<IDashboardTypeResponse[]>(['dashboardTypes'], (old) => {
				if (!old) return undefined;
				return old?.filter((st) => st.uuid !== id);
			});
		},
		[queryClient]
	);

	return {
		dashboardTypes,
		isDashboardTypesLoading,
		updateDashboardTypes,
		deleteDashboardType
	};
};

export const useDashboardType = (id?: string) => {
	const queryClient = useQueryClient();

	const { data: dashboardType, isLoading: isDashboardTypeLoading } = useQuery<IDashboardTypeResponse | undefined>({
		queryKey: ['dashboardType', { id }],
		queryFn: () => api.getDashboardType(id as string),
		enabled: !!id
	});

	const updateDashboardType = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: ['dashboardType', { id }] });
	}, [id, queryClient]);

	return {
		dashboardType,
		isDashboardTypeLoading,
		updateDashboardType
	};
};

export const useSaveDashboardType = (id?: string, onSaveSuccess?: (dashboardType: IDashboardTypeResponse) => void) => {
	const queryClient = useQueryClient();

	const updateDashboardType = useCallback(
		(dashboardTypeId: string) => {
			queryClient.invalidateQueries({ queryKey: ['dashboardType', { id: dashboardTypeId }] });
		},
		[queryClient]
	);

	const createMutation = useMutation({
		mutationFn: (dashboardType: IDashboardTypeRequest) => api.createDashboardType(dashboardType),
		onSuccess: (dashboardTypeResp: IDashboardTypeResponse) => {
			updateDashboardType(dashboardTypeResp.uuid);
			if (onSaveSuccess) onSaveSuccess(dashboardTypeResp);
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (dashboardType: IDashboardTypeRequest) => api.updateDashboardType(dashboardType),
		onSuccess: (dashboardTypeResp: IDashboardTypeResponse) => {
			updateDashboardType(dashboardTypeResp.uuid);
			if (onSaveSuccess) onSaveSuccess(dashboardTypeResp);
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const save = useCallback(
		(data: DashboardTypeFormType) => {
			const filteredData = dashboardTypeFormToRequest(data);
			if (data.id) {
				updateMutation.mutate(filteredData);
			} else {
				createMutation.mutate(filteredData);
			}
		},
		[createMutation, updateMutation]
	);

	return {
		save,
		isLoading: createMutation.isPending || updateMutation.isPending
	};
};

export const useDashboardTemplate = (dashboardTemplateId?: string) => {
	const { dashboardType } = useDashboardType(dashboardTemplateId);
	const { save } = useSaveDashboardType();

	const setDashboard = useCallback(
		(data: DashboardTypeFormType) => {
			save(data);
		},
		[save]
	);

	return { dashboardType, setDashboard };
};

export const useDashboardTemplateWidgets = (dashboardTemplateId?: string) => {
	const { dashboardType, setDashboard } = useDashboardTemplate(dashboardTemplateId);
	const dashboardTypeForm = useMemo(() => {
		return dashboardType ? dashboardTypeResponseToForm(dashboardType) : undefined;
	}, [dashboardType]);

	const updateWidgets = useCallback(
		(widgets: Partial<Widget>[]) => {
			if (dashboardTypeForm === undefined) {
				toast.error('Something went wrong. Dashboard type is not exist');
			} else {
				const newDashboard = cloneDeep<DashboardTypeFormType>(dashboardTypeForm);

				widgets.forEach((widget) => {
					const existingWidgetIndex = newDashboard?.widgets?.findIndex((w) => w.metadata.id === widget?.metadata?.id);

					if (
						existingWidgetIndex !== -1 &&
						newDashboard?.widgets &&
						existingWidgetIndex !== undefined &&
						newDashboard?.widgets[existingWidgetIndex]
					) {
						const old = newDashboard.widgets[existingWidgetIndex];

						newDashboard.widgets[existingWidgetIndex] = {
							...old,
							...widget,
							...(widget.metadata ? { metadata: { ...old.metadata, ...widget.metadata } } : {}),
							...(widget.settings ? { settings: { ...widget.settings, type: widget.type } } : {})
						};
					} else {
						newDashboard?.widgets?.push(widget as Widget);
					}
				});

				setDashboard(newDashboard);
			}
		},
		[dashboardTypeForm, setDashboard]
	);

	const addWidget = useCallback(
		(newWidget: Omit<Widget, 'x' | 'y'>) => {
			if (dashboardTypeForm === undefined) {
				toast.error('Something went wrong. Dashboard type is not exist');
			} else {
				const { x, y } = findEmptySpace(dashboardTypeForm?.widgets || [], newWidget.w, newWidget.h);

				const widget = {
					...newWidget,
					metadata: newWidget.metadata ? { ...newWidget.metadata, isType: true } : undefined,
					settings: newWidget.settings
						? {
								...newWidget.settings,
								type: newWidget.type
						  }
						: undefined,
					x,
					y
				};

				const widgetCenter = widget.y + widget.h / 2;
				window.scrollTo({
					top: heightRowToPx(widgetCenter) + 56 - window.innerHeight / 2,
					behavior: 'smooth'
				});

				updateWidgets([widget]);
			}
		},
		[dashboardTypeForm, updateWidgets]
	);

	const removeWidget = useCallback(
		(id: string) => {
			if (dashboardTypeForm === undefined) {
				toast.error('Something went wrong. Dashboard type is not exist');
			} else {
				const newDashboard = cloneDeep<DashboardTypeFormType>(dashboardTypeForm);
				newDashboard.widgets = newDashboard.widgets?.filter((widget) => widget.metadata.id !== id);

				setDashboard(newDashboard);
			}
		},
		[dashboardTypeForm, setDashboard]
	);

	const updateWidget = useCallback(
		(id: string, widget: Partial<Widget>) => {
			if (dashboardTypeForm === undefined) {
				toast.error('Something went wrong. Dashboard type is not exist');
			} else {
				updateWidgets([{ ...widget, metadata: widget.metadata ? { ...widget.metadata, id } : { id } }]);
			}
		},
		[updateWidgets, dashboardTypeForm]
	);

	const mergedWidgets = useMemo(() => {
		return (dashboardTypeForm?.widgets || []).map((widget) => ({
			...widget,
			...(typeDefaults[widget.type] || {}),
			...(widget.minimized ? { noResize: true, h: 1 } : {})
		}));
	}, [dashboardTypeForm]);

	return {
		widgets: mergedWidgets,
		widgetsOrg: dashboardTypeForm?.widgets || [],
		updateWidgets,
		addWidget,
		updateWidget,
		removeWidget
	};
};
