import { FC, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import FeaturesetsTable from '../components/FeaturesetsTable';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { getFeaturesets } from '../featuresets.api';
import { FeaturesetCrudActionTypes } from '../featuresets.enums';

const FeaturesetsTableContainer: FC = () => {
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<Record<string, string> | undefined>();
	const queryClient = useQueryClient();

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback(
		(id: string) => {
			queryClient.setQueryData<{ id: string }[]>(['featuresets'], (old) => {
				if (!old) return undefined;
				return old.filter((hc) => hc.id !== id);
			});
		},
		[queryClient]
	);

	const { data, isLoading } = useQuery<Record<string, string>[], Error>({
		queryKey: ['featuresets'],
		queryFn: async () => {
			const resp = await getFeaturesets();
			return resp;
		}
	});

	const handleAction = useCallback(
		(type: FeaturesetCrudActionTypes, data?: Record<string, string>) => {
			switch (type) {
				case FeaturesetCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case FeaturesetCrudActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case FeaturesetCrudActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case FeaturesetCrudActionTypes.DELETE:
					if (data) {
						setEntityForDelete(data);
					}
					break;
			}
		},
		[navigate]
	);

	return (
		<>
			<FeaturesetsTable onAction={handleAction} data={data} isLoading={isLoading} />
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.AXES}
				entity={entityForDelete as unknown as IEntity}
			/>
		</>
	);
};

export default FeaturesetsTableContainer;
