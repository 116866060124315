import axios from '../../httpCommon';
import { IAxisResponseDto } from '../Axes';
import { MeasurementResponseDto } from '../Measurements';
import { ControlChannelRequestDto, ControlChannelResponseDto } from './controlChannel.types';

export const getControlChannels = async (stationId?: string): Promise<ControlChannelResponseDto[]> => {
	const response = await axios.get(`/quasar/control-channels/station/${stationId}`);

	return response.data;
};

export const getControlChannelById = async (id?: string): Promise<ControlChannelResponseDto> => {
	const response = await axios.get(`/quasar/control-channels/${id}`);

	return response.data;
};

export const getControlChannelsByAxisId = async (axisId?: string): Promise<ControlChannelResponseDto[]> => {
	const response = await axios.get(`/quasar/control-channels/axis/${axisId}`);

	return response.data;
};

export const getAxes = async (stationId?: string): Promise<IAxisResponseDto[]> => {
	const response = await axios.get(`/quasar/axis/station/${stationId}`);

	return response.data?.length ? response.data : [];
};

export const getMeasurements = async (stationId?: string): Promise<MeasurementResponseDto[]> => {
	const response = await axios.get(`/quasar/measurements/station/${stationId}`);

	return response.data?.length ? response.data : [];
};

export const calculateDitherRawValue = async (ccUuid: string, amplitude: number): Promise<number> => {
	const response = await axios.post('/quasar/control-channels/calculateDitherRawValue', { ccUuid, amplitude });

	return response.data?.amplitudeRawValue;
};

export const createControlChannel = async (
	controlChannel: ControlChannelRequestDto
): Promise<ControlChannelResponseDto> => {
	const response = await axios.post('/quasar/control-channels', controlChannel);

	return response.data;
};

export const updateControlChannel = async (
	controlChannel: ControlChannelRequestDto,
	id: string
): Promise<ControlChannelResponseDto> => {
	const response = await axios.put(`/quasar/control-channels/${id}`, controlChannel);
	return response.data;
};

export const checkName = async (name: string, parentId: string, id?: string): Promise<boolean> => {
	const response = await axios.post('/quasar/control-channels/isNameUnique', { id, name, parentId });
	return response.data === true;
};
