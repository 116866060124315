import { grpc } from '@improbable-eng/grpc-web';
import {
	LinkRequest,
	LinkResponse,
	LinkResult,
	LinkResultMap,
	UnlinkRequest,
	UnlinkResponse,
	UnlinkResult,
	UnlinkResultMap,
	productService
} from '@tactun/grpc-client';
import { IProductListItem, ProductFormType } from './products.types';

interface ILinkResponse {
	status?: LinkResultMap[keyof LinkResultMap];
	error?: string;
	signature?: string;
}

interface IUnlinkResponse {
	status?: UnlinkResultMap[keyof UnlinkResultMap];
	error?: string;
}

export const linkDevice = async (product: ProductFormType, server: string, jwt: string): Promise<ILinkResponse> => {
	return new Promise((resolve) => {
		if (process.env.REACT_APP_DEVICE_URL) {
			const request = new LinkRequest();

			request.setStationId(product.stationId);
			request.setControllerModel(product.model);
			if (product.controllerSerialNumber) request.setSerialNumber(product.controllerSerialNumber);
			request.setProductName(product.name);
			request.setProductPlatform(product.platform);
			request.setProductVersion(product.version);

			// create custom metadata/header object to pass into the request
			const metadata = new grpc.Metadata();
			metadata.set('authorization', jwt);
			metadata.set('server', server);

			grpc.unary(productService.Product.Link, {
				request: request,
				host: process.env.REACT_APP_DEVICE_URL,
				metadata,
				transport: grpc.CrossBrowserHttpTransport({ withCredentials: false }),
				onEnd: (output: grpc.UnaryOutput<LinkResponse>) => {
					if (output.status === grpc.Code.OK) {
						resolve({
							status: output.message?.getResult(),
							error: output.message?.getError()
						});
					} else {
						resolve({
							status: LinkResult.LINK_ERROR,
							error: output.statusMessage
						});
					}
				}
			});
		}
	});
};

export const unlinkDevice = async (
	product: IProductListItem,
	server: string,
	jwt: string
): Promise<IUnlinkResponse> => {
	return new Promise((resolve) => {
		if (process.env.REACT_APP_DEVICE_URL) {
			const request = new UnlinkRequest();
			request.setStationId(product.stationId);
			request.setControllerModel(product.model);
			request.setSerialNumber(product.serialNumber);
			request.setProductUuid(product.id);

			// create custom metadata/header object to pass into the request
			const metadata = new grpc.Metadata();
			metadata.set('authorization', jwt);
			metadata.set('server', server);

			grpc.unary(productService.Product.Unlink, {
				request: request,
				host: process.env.REACT_APP_DEVICE_URL,
				metadata,
				transport: grpc.CrossBrowserHttpTransport({ withCredentials: false }),
				onEnd: (output: grpc.UnaryOutput<UnlinkResponse>) => {
					if (output.status === grpc.Code.OK) {
						resolve({
							status: output.message?.getResult(),
							error: output.message?.getError()
						});
					} else {
						resolve({
							status: UnlinkResult.UNLINK_ERROR,
							error: output.statusMessage
						});
					}
				}
			});
		}
	});
};
