import { WidgetTypes } from './dashboard.enums';
import ButtonWidget from '../ButtonWidget/containers/ButtonWidget';
import ControlAxisWidget from '../ControlAxisWidget';
import SafetyWidget from '../SafetyWidget';
import TestInformation from '../TestInformationWidget';
import ControlTestWidget from '../ControlTestWidget';
import ControlStageWidget from '../ControlStageWidget';
import GraphWidget from '../GraphWidget';
import ReadoutWidget from '../ReadoutWidget';
import ResultsTableWidget from '../ResultsTableWidget';
import EventViewerWidget from '../EventViewerWidget';
import StageInformationWidget from '../StageInformationWidget';
import SensorCalibrationWidget from '../SensorCalibrationWidget';
import OffsetCalibrationWidget from '../OffsetCalibrationWidget';

export const typeDefaults = {
	[WidgetTypes.GRAPH]: {
		minW: 12,
		maxW: 24,
		minH: 5
	},
	[WidgetTypes.READOUT]: {
		minW: 4,
		minH: 5,
		maxH: 20
	},
	[WidgetTypes.RESULTS]: {
		minW: 8,
		minH: 3
	},
	[WidgetTypes.EVENT_VIEWER]: {
		minW: 7,
		minH: 3
	},
	[WidgetTypes.TEST_INFORMATION]: {
		minW: 6,
		minH: 4,
		noResize: true
	},
	[WidgetTypes.STAGE_INFORMATION]: {
		minW: 7,
		minH: 4
	},
	[WidgetTypes.SAFETY]: {
		minW: 2,
		maxW: 4,
		minH: 3,
		maxH: 5,
		noResize: true
	},
	[WidgetTypes.CONTROL_AXIS]: {
		minW: 2,
		maxW: 4,
		minH: 9,
		maxH: 15
	},
	[WidgetTypes.CONTROL_TEST]: {
		minW: 2,
		maxW: 4,
		minH: 5,
		maxH: 8
	},
	[WidgetTypes.CONTROL_STAGE]: {
		noResize: true
	},
	[WidgetTypes.SENSOR_CALIBRATION]: {
		noResize: true
	},
	[WidgetTypes.BUTTON_WIDGET]: {
		minW: 2,
		minH: 2
	},
	[WidgetTypes.OFFSET_CALIBRATION]: {
		maxW: 8,
		minW: 4,
		maxH: 6,
		minH: 6
	}
};

export const widgetTypesComponentsMapping = {
	[WidgetTypes.GRAPH]: GraphWidget,
	[WidgetTypes.TEST_INFORMATION]: TestInformation,
	[WidgetTypes.READOUT]: ReadoutWidget,
	[WidgetTypes.RESULTS]: ResultsTableWidget,
	[WidgetTypes.EVENT_VIEWER]: EventViewerWidget,
	[WidgetTypes.STAGE_INFORMATION]: StageInformationWidget,
	[WidgetTypes.CONTROL_AXIS]: ControlAxisWidget,
	[WidgetTypes.CONTROL_TEST]: ControlTestWidget,
	[WidgetTypes.CONTROL_STAGE]: ControlStageWidget,
	[WidgetTypes.SAFETY]: SafetyWidget,
	[WidgetTypes.BUTTON_WIDGET]: ButtonWidget,
	[WidgetTypes.SENSOR_CALIBRATION]: SensorCalibrationWidget,
	[WidgetTypes.OFFSET_CALIBRATION]: OffsetCalibrationWidget
};
