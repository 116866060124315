import { useMemo, useRef } from 'react';
import { PageLayout, Form, Switch, Dropdown } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import styles from './Calculations.module.scss';

interface AxesProps {
	isCreate?: boolean;
	form: UseFormReturn<Record<string, string>>;
}

const Calculations: React.FC<AxesProps> = ({ isCreate, form }) => {
	const { t } = useTranslation('axes');
	const formId = useRef('axesForm');
	const { control, register } = form;

	const title = useMemo<string>(() => {
		return isCreate ? t('Create a Feature-Set') : t('Edit a Feature-Set');
	}, [isCreate, t]);

	return (
		<>
			<PageLayout info={title}>
				<PageLayout.Header title={t('Calculations')} />
				<Form className={styles.container} id={formId.current}>
					<input type="hidden" {...register('stationId')} />
					<div>Statistical</div>
					<Controller
						name="type0"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<Controller name="type" control={control} render={({ field }) => <Dropdown {...field} filter />} />
					<div>Measurement at Peak Load</div>
					<Controller
						name="type1"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Measurement at Specimen Break</div>
					<Controller
						name="type2"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Modulus of Elasticity</div>
					<Controller
						name="type3"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Yield Offset</div>
					<Controller
						name="type4"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Energy</div>
					<Controller
						name="type5"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Maximum Load over Width</div>
					<Controller
						name="type6"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Data point</div>
					<Controller
						name="type7"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Tangent Modulus</div>
					<Controller
						name="type8"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Secant Modulus</div>
					<Controller
						name="type9"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Chord Modulus</div>
					<Controller
						name="type10"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Measurement at Yield</div>
					<Controller
						name="type11"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Resistance to Slippage</div>
					<Controller
						name="type12"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
					<div>Peak and Valley Detection</div>
					<Controller
						name="type13"
						control={control}
						render={({ field }) => (
							<Switch inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={!!field.value} />
						)}
					/>
					<div></div>
				</Form>
			</PageLayout>
		</>
	);
};

export default Calculations;
