import React from 'react';
import { Icon } from '@tactun/ui';
import styles from './EmptyStageCard.module.scss';

interface EmptyStageCardProps {
	onClick: () => void;
}

const EmptyStageCard: React.FC<EmptyStageCardProps> = ({ onClick }) => {
	return (
		<div className={styles.container} onClick={onClick} data-testid="axesBox">
			<Icon name="plus" size="3rem" data-testid="emptyAdd" />
		</div>
	);
};

export default React.memo(EmptyStageCard);
