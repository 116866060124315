export enum ControlStageActionTypes {
	START_STATION = 'START_STATION',
	STOP_STATION = 'STOP_STATION',
	START_STAGE = 'START_STAGE',
	STOP_STAGE = 'STOP_STAGE',
	START_TEST = 'START_TEST',
	STOP_TEST = 'STOP_TEST',
	SAVE_CONTROL_CHANNEL = 'SAVE_CONTROL_CHANNEL',
	SAVE_TEST = 'SAVE_TEST'
}
