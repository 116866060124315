import { useCallback, useEffect } from 'react';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { WidgetsLayoutTypes, WidgetsTypes } from '../../customWidget.enums';
import { ICustomWidgetForm, ISubframeForm } from '../../customWidget.types';
import ButtonWidgetForm from '../../../ButtonWidget/components/ButtonWidgetForm';

import styles from './SubframesForm.module.scss';
import { useParams } from 'react-router-dom';
import { FrameOrientation } from '../../../ButtonWidget/buttonWidget.enums';

interface SubframesFormProps {}

const SubframesForm: React.FC<SubframesFormProps> = () => {
	const { watch, setValue, getValues, control } = useFormContext<ICustomWidgetForm>();
	const { stationId } = useParams();

	const { fields: subFrames } = useFieldArray({
		control,
		name: 'subFrames'
	});

	const activeSubframe = watch('activeSubframe');
	const handleTabChange = useCallback(
		(e: TabViewTabChangeEvent) => {
			setValue('activeSubframe', e.index);
			setValue('activeButton', null);
		},
		[setValue]
	);

	const layoutType = watch('layoutType');
	const type = watch('type');

	useEffect(() => {
		let layoutsCount = 0;
		if (layoutType === WidgetsLayoutTypes.SINGLE) {
			layoutsCount = 1;
		} else if (layoutType === WidgetsLayoutTypes.QUADRO) {
			layoutsCount = 4;
		} else if (
			layoutType === WidgetsLayoutTypes.DOUBLE_HORIZONTAL ||
			layoutType === WidgetsLayoutTypes.DOUBLE_VERTICAL
		) {
			layoutsCount = 2;
		}

		const current = getValues('subFrames');
		if (layoutsCount && layoutsCount !== current?.length) {
			let layouts: ISubframeForm[] = [];

			new Array(layoutsCount).fill(0).forEach((_, i) => {
				let oldIndex = i;
				if (current?.length > 2 && layoutType === WidgetsLayoutTypes.DOUBLE_VERTICAL && i === 1) {
					oldIndex = 2;
				} else if (current?.length === 2 && layoutType === WidgetsLayoutTypes.QUADRO && i === 1) {
					oldIndex = 2;
				} else if (current?.length === 2 && layoutType === WidgetsLayoutTypes.QUADRO && i === 2) {
					oldIndex = 1;
				}
				const layout: ISubframeForm = current?.[oldIndex] || {
					orientation: FrameOrientation.VERTICAL
				};

				layouts.push(layout);
			});

			setValue('subFrames', layouts);
			if (layoutsCount < current?.length) {
				setValue('activeButton', null);
				setValue('activeSubframe', 0);
			}
		}
	}, [layoutType, getValues, setValue]);

	return (
		<div className={styles.btnWidgetContainer}>
			<TabView renderActiveOnly={false} onTabChange={handleTabChange} activeIndex={activeSubframe}>
				{subFrames?.map((_, index) => (
					<TabPanel header={`Subframe ${index + 1}`} key={index}>
						{type === WidgetsTypes.BUTTON_WIDGET && (
							<ButtonWidgetForm frameIndex={index} stationId={stationId as string} />
						)}
					</TabPanel>
				))}
			</TabView>
		</div>
	);
};

export default SubframesForm;
