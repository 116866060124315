import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { InputText, Icon, Button } from '@tactun/ui';
import { GroupMenu, useGroup } from '../../modules/Group';
import { INamedEntity } from '../../types';
import styles from './TableFilters.module.scss';

interface ITableFiltersProps {
	onFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onCancel: () => void;
	selected?: INamedEntity[];
}

const TableFilters: React.FC<ITableFiltersProps> = ({ onFilterChange, onCancel, selected }) => {
	const { t } = useTranslation();
	const [filter, setFilter] = React.useState('');
	const { rename, ungroup, remove, selectedGroup, selectGroup } = useGroup();
	const filterRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
	const hasSelectedItems = selected && selected.length > 0;
	const isActionMenuVisible = hasSelectedItems || selectedGroup;

	const handleCancel = useCallback(() => {
		if (selectedGroup) {
			selectGroup(undefined);
		}
		onCancel();
	}, [onCancel, selectGroup, selectedGroup]);

	const handleFilterChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onFilterChange(e);
			setFilter(e.target.value);
		},
		[onFilterChange]
	);

	const handleFilterClean = useCallback(() => {
		if (filterRef.current) {
			setFilter('');
			Object?.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set?.call(filterRef.current, '');
			filterRef.current.dispatchEvent(new Event('input', { bubbles: true, cancelable: false }));
		}
	}, []);

	return (
		<>
			{isActionMenuVisible && (
				<div className={styles.actionBarContainer}>
					<div>
						<span className={styles.selected}>
							{`${
								selectedGroup
									? t('Group “{{groupName}}”', {
											groupName: selectedGroup.name
									  })
									: ''
							}
							${selectedGroup && selected?.length ? ' - ' : ''}
							${
								selected?.length
									? t('{{selectedCount}} item selected', {
											selectedCount: selected?.length
									  })
									: ''
							}`}
						</span>
					</div>
					<div className={styles.actionsContainer}>
						{selectedGroup && !hasSelectedItems && (
							<>
								<Button
									label={t('Rename')}
									variant="text"
									color="success"
									icon="t-icon-edit"
									onClick={rename}
									className={styles.btn}
									pt={{
										icon: { className: styles.btnIcon }
									}}
								/>
								<Button
									label={t('Ungroup')}
									variant="text"
									color="success"
									icon="t-icon-ungroup"
									onClick={ungroup}
									className={styles.btn}
									pt={{
										icon: { className: styles.btnIcon }
									}}
								/>

								<div className={styles.verticalLine} />
							</>
						)}
						{selectedGroup && hasSelectedItems && (
							<>
								<Button
									label={t('Remove from group')}
									variant="text"
									color="success"
									icon="t-icon-remove-from-group"
									onClick={() => remove(selected.map((item) => item.id))}
									className={styles.btn}
									pt={{
										icon: { className: styles.btnIcon }
									}}
								/>
								<div className={styles.verticalLine} />
							</>
						)}
						{!selectedGroup && selected && selected.length > 0 && <GroupMenu />}
						<Button label={t('Cancel')} variant="text" color="success" onClick={handleCancel} className={styles.btn} />
					</div>
				</div>
			)}
			<div className={cx(styles.searchContainer, { [styles.hide]: isActionMenuVisible })}>
				<InputText
					data-testid="searchField"
					leftIcon={<Icon name="search" size={18} />}
					rightIcon={filter ? <Icon name="zoom-delete" size={10} onClick={handleFilterClean} /> : undefined}
					name="tableSearch"
					ref={filterRef}
					className={styles.searchField}
					onChange={handleFilterChange}
				/>
			</div>
		</>
	);
};

export default React.memo(TableFilters);
