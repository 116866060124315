import { trace as traceTauri, info as infoTauri, error as errorTauri, attachConsole } from 'tauri-plugin-log-api';

(async function attachForConsole() {
	await attachConsole();
})();

type ErrorWithMessage = {
	message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
	return (
		typeof error === 'object' &&
		error !== null &&
		'message' in error &&
		typeof (error as Record<string, unknown>).message === 'string'
	);
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
	if (typeof maybeError === 'string') return { message: maybeError };

	if (isErrorWithMessage(maybeError)) return maybeError;

	try {
		return new Error(JSON.stringify(maybeError));
	} catch {
		// fallback in case there's an error stringifying the maybeError
		// like with circular references for example.
		return new Error(String(maybeError));
	}
}

function getErrorMessage(error: unknown) {
	return toErrorWithMessage(error).message;
}

export const tTrace = (error: unknown) => {
	const message = getErrorMessage(error);
	if (window.__TAURI_INVOKE__) {
		traceTauri(message);
	} else {
		// eslint-disable-next-line no-console
		console.log(message);
	}
};

export const tInfo = (error: unknown) => {
	const message = getErrorMessage(error);
	if (window.__TAURI_INVOKE__) {
		infoTauri(message);
	} else {
		// eslint-disable-next-line no-console
		console.info(message);
	}
};

export const tError = (error: unknown) => {
	const message = getErrorMessage(error);
	if (window.__TAURI_INVOKE__) {
		errorTauri(message);
	} else {
		// eslint-disable-next-line no-console
		console.error(message);
	}
};
