// source: specimen.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.specimen.Specimen', null, global);
goog.exportSymbol('proto.specimen.SpecimenStatus', null, global);
goog.exportSymbol('proto.specimen.SpecimenValueType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.specimen.Specimen = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.specimen.Specimen, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.specimen.Specimen.displayName = 'proto.specimen.Specimen';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.specimen.Specimen.prototype.toObject = function(opt_includeInstance) {
  return proto.specimen.Specimen.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.specimen.Specimen} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.specimen.Specimen.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    specimenId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    batchId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    material: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    testId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    testName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    paramsMap: (f = msg.getParamsMap()) ? f.toObject(includeInstance, undefined) : [],
    gaugeValType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    gaugeValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    gaugeEquation: jspb.Message.getFieldWithDefault(msg, 13, ""),
    gaugeUnit: (f = msg.getGaugeUnit()) && common_pb.UnitFormula.toObject(includeInstance, f),
    areaValType: jspb.Message.getFieldWithDefault(msg, 15, 0),
    areaValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    areaEquation: jspb.Message.getFieldWithDefault(msg, 17, ""),
    areaUnit: (f = msg.getAreaUnit()) && common_pb.UnitFormula.toObject(includeInstance, f),
    itemName: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.specimen.Specimen}
 */
proto.specimen.Specimen.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.specimen.Specimen;
  return proto.specimen.Specimen.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.specimen.Specimen} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.specimen.Specimen}
 */
proto.specimen.Specimen.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecimenId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterial(value);
      break;
    case 7:
      var value = /** @type {!proto.specimen.SpecimenStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestName(value);
      break;
    case 10:
      var value = msg.getParamsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readFloat, null, "", 0.0);
         });
      break;
    case 11:
      var value = /** @type {!proto.specimen.SpecimenValueType} */ (reader.readEnum());
      msg.setGaugeValType(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGaugeValue(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setGaugeEquation(value);
      break;
    case 14:
      var value = new common_pb.UnitFormula;
      reader.readMessage(value,common_pb.UnitFormula.deserializeBinaryFromReader);
      msg.setGaugeUnit(value);
      break;
    case 15:
      var value = /** @type {!proto.specimen.SpecimenValueType} */ (reader.readEnum());
      msg.setAreaValType(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAreaValue(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAreaEquation(value);
      break;
    case 18:
      var value = new common_pb.UnitFormula;
      reader.readMessage(value,common_pb.UnitFormula.deserializeBinaryFromReader);
      msg.setAreaUnit(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.specimen.Specimen.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.specimen.Specimen.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.specimen.Specimen} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.specimen.Specimen.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpecimenId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMaterial();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getTestId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTestName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getParamsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeFloat);
  }
  f = message.getGaugeValType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getGaugeUnit();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      common_pb.UnitFormula.serializeBinaryToWriter
    );
  }
  f = message.getAreaValType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getAreaUnit();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      common_pb.UnitFormula.serializeBinaryToWriter
    );
  }
  f = message.getItemName();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.specimen.Specimen.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string specimen_id = 2;
 * @return {string}
 */
proto.specimen.Specimen.prototype.getSpecimenId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setSpecimenId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string item_id = 3;
 * @return {string}
 */
proto.specimen.Specimen.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string batch_id = 4;
 * @return {string}
 */
proto.specimen.Specimen.prototype.getBatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setBatchId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.clearBatchId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.specimen.Specimen.prototype.hasBatchId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.specimen.Specimen.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string material = 6;
 * @return {string}
 */
proto.specimen.Specimen.prototype.getMaterial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setMaterial = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional SpecimenStatus status = 7;
 * @return {!proto.specimen.SpecimenStatus}
 */
proto.specimen.Specimen.prototype.getStatus = function() {
  return /** @type {!proto.specimen.SpecimenStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.specimen.SpecimenStatus} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string test_id = 8;
 * @return {string}
 */
proto.specimen.Specimen.prototype.getTestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setTestId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string test_name = 9;
 * @return {string}
 */
proto.specimen.Specimen.prototype.getTestName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setTestName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * map<string, float> params = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.specimen.Specimen.prototype.getParamsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.clearParamsMap = function() {
  this.getParamsMap().clear();
  return this;
};


/**
 * optional SpecimenValueType gauge_val_type = 11;
 * @return {!proto.specimen.SpecimenValueType}
 */
proto.specimen.Specimen.prototype.getGaugeValType = function() {
  return /** @type {!proto.specimen.SpecimenValueType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.specimen.SpecimenValueType} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setGaugeValType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional double gauge_value = 12;
 * @return {number}
 */
proto.specimen.Specimen.prototype.getGaugeValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setGaugeValue = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.clearGaugeValue = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.specimen.Specimen.prototype.hasGaugeValue = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string gauge_equation = 13;
 * @return {string}
 */
proto.specimen.Specimen.prototype.getGaugeEquation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setGaugeEquation = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.clearGaugeEquation = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.specimen.Specimen.prototype.hasGaugeEquation = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional common.UnitFormula gauge_unit = 14;
 * @return {?proto.common.UnitFormula}
 */
proto.specimen.Specimen.prototype.getGaugeUnit = function() {
  return /** @type{?proto.common.UnitFormula} */ (
    jspb.Message.getWrapperField(this, common_pb.UnitFormula, 14));
};


/**
 * @param {?proto.common.UnitFormula|undefined} value
 * @return {!proto.specimen.Specimen} returns this
*/
proto.specimen.Specimen.prototype.setGaugeUnit = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.clearGaugeUnit = function() {
  return this.setGaugeUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.specimen.Specimen.prototype.hasGaugeUnit = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional SpecimenValueType area_val_type = 15;
 * @return {!proto.specimen.SpecimenValueType}
 */
proto.specimen.Specimen.prototype.getAreaValType = function() {
  return /** @type {!proto.specimen.SpecimenValueType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.specimen.SpecimenValueType} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setAreaValType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional double area_value = 16;
 * @return {number}
 */
proto.specimen.Specimen.prototype.getAreaValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setAreaValue = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.clearAreaValue = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.specimen.Specimen.prototype.hasAreaValue = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string area_equation = 17;
 * @return {string}
 */
proto.specimen.Specimen.prototype.getAreaEquation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setAreaEquation = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.clearAreaEquation = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.specimen.Specimen.prototype.hasAreaEquation = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional common.UnitFormula area_unit = 18;
 * @return {?proto.common.UnitFormula}
 */
proto.specimen.Specimen.prototype.getAreaUnit = function() {
  return /** @type{?proto.common.UnitFormula} */ (
    jspb.Message.getWrapperField(this, common_pb.UnitFormula, 18));
};


/**
 * @param {?proto.common.UnitFormula|undefined} value
 * @return {!proto.specimen.Specimen} returns this
*/
proto.specimen.Specimen.prototype.setAreaUnit = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.clearAreaUnit = function() {
  return this.setAreaUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.specimen.Specimen.prototype.hasAreaUnit = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string item_name = 19;
 * @return {string}
 */
proto.specimen.Specimen.prototype.getItemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.specimen.Specimen} returns this
 */
proto.specimen.Specimen.prototype.setItemName = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * @enum {number}
 */
proto.specimen.SpecimenStatus = {
  NOT_TESTED: 0,
  TEST_IN_PROGRESS: 1,
  BROKEN: 2,
  TESTED: 3
};

/**
 * @enum {number}
 */
proto.specimen.SpecimenValueType = {
  VALUE: 0,
  EQUATION: 1
};

goog.object.extend(exports, proto.specimen);
