import { boolean, InferType, mixed, number, object, ref, string } from 'yup';
import { emptyStringToNull } from '../../tools/yupHelpers';
import { ControlAlgorithmsAdditional, ControlAlgorithmsBase } from './controlAlgorithms.enums';

export const pidAlgSchema = object({
	kp: number().transform(emptyStringToNull).notOneOf([0]).required('Proportional gain (Kp) is a required field'),
	ki: number()
		.transform(emptyStringToNull)
		.min(0, 'This field must be greater than or equal to 0')
		.required('Integral gain (Ki) is a required field'),
	kd: number()
		.transform(emptyStringToNull)
		.min(0, 'This field must be greater than or equal to 0')
		.required('Derivative gain (Kd) is a required field')
});

export const adaptiveAmplitudeAlgSchema = object({
	fbId: string().required('Feedback measurement is a required field'),
	adjustmentPeriod: number()
		.transform(emptyStringToNull)
		.positive('Only positive number is accepted')
		.required('Adjustment period is a required field'),
	step: number()
		.transform(emptyStringToNull)
		.positive('Only positive number is accepted')
		.required('Step (%) is a required field'),
	targetValue: number()
		.transform(emptyStringToNull)
		.positive('Only positive number is accepted')
		.required('Target value is a required field'),
	minFeedbackValue: number(),
	maxFeedbackValue: number(),
	allowedError: number()
		.transform(emptyStringToNull)
		.required('Allowed error is a required field')
		.when('minFeedbackValue', {
			is: (minFeedbackValue?: number) => minFeedbackValue !== undefined,
			then: (schema) =>
				schema.min(ref('minFeedbackValue'), ({ min }) => `Allowed error must be greater than or equal to ${min}`)
		})
		.when('maxFeedbackValue', {
			is: (maxFeedbackValue?: number) => {
				return maxFeedbackValue !== undefined;
			},
			then: (schema) =>
				schema.max(ref('maxFeedbackValue'), ({ max }) => `Allowed error must be less than or equal to ${max}`)
		}),
	meanTargetVal: number()
		.transform(emptyStringToNull)
		.optional()
		.when('$additionalAlgorithms', {
			is: ($additionalAlgorithms: ControlAlgorithmsAdditional[]) => {
				return $additionalAlgorithms?.some((alg) => alg === ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN);
			},
			then: (schema) => schema.required('Mean target value is a required field')
		}),
	meanStep: number()
		.transform(emptyStringToNull)
		.optional()
		.when('$additionalAlgorithms', {
			is: ($additionalAlgorithms: ControlAlgorithmsAdditional[]) => {
				return $additionalAlgorithms?.some((alg) => alg === ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN);
			},
			then: (schema) => schema.required('Mean step is a required field')
		}),
	meanAllowedError: number()
		.transform(emptyStringToNull)
		.optional()
		.when('$additionalAlgorithms', {
			is: ($additionalAlgorithms: ControlAlgorithmsAdditional[]) => {
				return $additionalAlgorithms?.some((alg) => alg === ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN);
			},
			then: (schema) => schema.required('Mean allowed error is a required field')
		})
});

export const feedForwardAlgSchema = object({
	velocityFeedForward: number()
		.transform(emptyStringToNull)
		.min(0, 'Only positive number is accepted')
		.required('Velocity Feed Forward is a required field'),
	accelerationFeedForward: number()
		.transform(emptyStringToNull)
		.min(0, 'Only positive number is accepted')
		.required('Acceleration Feed Forward is a required field'),
	jerkFeedForward: number()
		.transform(emptyStringToNull)
		.min(0, 'Only positive number is accepted')
		.required('Jerk Feed Forward is a required field')
});

export const ditherAlgSchema = object({
	amplitude: number()
		.transform(emptyStringToNull)
		.min(0, ({ min }) => `Minimum allowed number is ${min}`)
		.required('Amplitude is a required field'),
	amplitudeRaw: number().optional(),
	frequency: number()
		.transform(emptyStringToNull)
		.min(100, ({ min }) => `Minimum allowed number is ${min}`)
		.max(1000, ({ max }) => `Maximum allowed number is ${max}`)
		.required('Frequency is a required field')
});

export const asymmetricPIDAlgSchema = object({
	pGainForward: number()
		.transform(emptyStringToNull)
		.notOneOf([0])
		.required('Proportional gain for forward control is a required field'),
	pGainReverse: number()
		.transform(emptyStringToNull)
		.notOneOf([0])
		.required('Proportional gain for reverse control is a required field'),
	integralGain: number()
		.transform(emptyStringToNull)
		.min(0, 'This field must be greater than or equal to 0')
		.required('Integral gain (Ki) is a required field'),
	derivativeGain: number()
		.transform(emptyStringToNull)
		.min(0, 'This field must be greater than or equal to 0')
		.required('Derivative gain (Kd) is a required field')
});

export const controlAlgBaseSchema = object({
	baseAlgorithm: mixed<ControlAlgorithmsBase>().required(),
	additionalAlgorithms: mixed<ControlAlgorithmsAdditional[]>().required(),
	isDitherEnabled: boolean().required(),
	pid: mixed<InferType<typeof pidAlgSchema>>()
		.optional()
		.nullable()
		.when('baseAlgorithm', {
			is: (baseAlgorithm: ControlAlgorithmsBase) => {
				return baseAlgorithm === ControlAlgorithmsBase.PID;
			},
			then: () => pidAlgSchema.required(),
			otherwise: (schema) => schema.optional()
		}),
	asymmetricPid: mixed<InferType<typeof asymmetricPIDAlgSchema>>()
		.optional()
		.nullable()
		.when('baseAlgorithm', {
			is: (baseAlgorithm: ControlAlgorithmsBase) => baseAlgorithm === ControlAlgorithmsBase.ASYMMETRIC_PID,
			then: () => asymmetricPIDAlgSchema.required(),
			otherwise: (schema) => schema.optional()
		}),
	adaptiveAmplitude: mixed<InferType<typeof adaptiveAmplitudeAlgSchema>>()
		.optional()
		.nullable()
		.when('additionalAlgorithms', {
			is: (additionalAlgorithms?: ControlAlgorithmsAdditional[]) => {
				return additionalAlgorithms?.some(
					(alg) =>
						alg === ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE ||
						alg === ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN
				);
			},
			then: () => adaptiveAmplitudeAlgSchema.required(),
			otherwise: (schema) => schema.optional()
		}),
	dither: mixed<InferType<typeof ditherAlgSchema>>()
		.optional()
		.nullable()
		.when('isDitherEnabled', {
			is: (isDitherEnabled: boolean) => isDitherEnabled,
			then: () => ditherAlgSchema.required(),
			otherwise: (schema) => schema.optional()
		}),
	feedForward: mixed<InferType<typeof feedForwardAlgSchema>>()
		.optional()
		.nullable()
		.when('additionalAlgorithms', {
			is: (additionalAlgorithms?: ControlAlgorithmsAdditional[]) =>
				additionalAlgorithms?.some((alg) => alg === ControlAlgorithmsAdditional.FEED_FORWARD),
			then: () => feedForwardAlgSchema.required(),
			otherwise: (schema) => schema.optional()
		})
});
