import { useEffect, useMemo, useRef } from 'react';
import {
	PageLayout,
	Form,
	InputText,
	Button,
	InputNumber,
	Dropdown,
	BottomBar,
	InfoBadge,
	InfoBadgeIcons
} from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller, FieldValues } from 'react-hook-form';
import { QuantityFormType, UnitFormType } from '../../units.types';
import { UnitSystemTypes } from '../../units.enums';
import UnitTypeTable from '../UnitTypeTable';
import { IListItemNumber } from '../../../../types';
import styles from './Unit.module.scss';

interface UnitProps {
	isCreate: boolean;
	isLoading: boolean;
	form: UseFormReturn<QuantityFormType>;
	unitForm: UseFormReturn<UnitFormType>;
	units: FieldValues;
	systemTypes: IListItemNumber[];
	isSystemQuantity: boolean;
	onAddUnit: () => void;
	onDeleteUnit: (unit: UnitFormType) => void;
	onEditUnit: (unit: UnitFormType) => void;
	onDefaultChange: (unit: UnitFormType) => void;
	onBack: () => void;
	onSave: () => void;
}

const Unit: React.FC<UnitProps> = ({
	isCreate,
	isLoading,
	form,
	units,
	unitForm,
	systemTypes,
	onBack,
	onSave,
	onAddUnit,
	onEditUnit,
	onDefaultChange,
	onDeleteUnit,
	isSystemQuantity
}) => {
	const { t } = useTranslation('units');
	const formId = useRef('quantityForm');
	const {
		control,
		formState: { errors },
		register
	} = form;

	const {
		control: unitControl,
		formState: { errors: unitErrors },
		register: unitRegister,
		setValue
	} = unitForm;

	const title = useMemo<string>(() => {
		return isCreate ? t('Create a quantity') : t('Edit a quantity');
	}, [isCreate, t]);

	const noneUnits = useMemo(() => {
		return units.filter((unit: UnitFormType) => unit.systemType === UnitSystemTypes.NONE);
	}, [units]);

	const metricUnits = useMemo(() => {
		return units.filter((unit: UnitFormType) => unit.systemType === UnitSystemTypes.METRIC);
	}, [units]);

	const imperialUnits = useMemo(() => {
		return units.filter((unit: UnitFormType) => unit.systemType === UnitSystemTypes.IMPERIAL);
	}, [units]);

	useEffect(() => {
		setValue(
			'units',
			units.map(
				(u: UnitFormType) =>
					({
						scale: u.scale,
						offset: u.offset,
						systemType: u.systemType
					}) as UnitFormType
			)
		);
	}, [setValue, units]);

	return (
		<>
			<PageLayout info={t('Units')}>
				<PageLayout.Header title={title} onBack={onBack} />
				<Form className={styles.container} onSubmit={onSave} id={formId.current}>
					<input type="hidden" {...register('id')} />
					<Controller
						name="name"
						control={control}
						render={({ field }) => <InputText label={t('Quantity name*')} {...field} error={errors.name?.message} />}
					/>
					<Form.Divider className="FormDivider" />
					<input type="hidden" {...unitRegister('id')} />
					<input type="hidden" {...unitRegister('parentId')} />
					<input type="hidden" {...unitRegister('units')} />
					<label className={styles.label}>Unit properties</label>
					<Controller
						name="name"
						control={unitControl}
						render={({ field }) => (
							<InputText label={t('Unit*')} {...field} id="unitName" error={unitErrors.name?.message} />
						)}
					/>
					<Controller
						name="systemType"
						control={unitControl}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={systemTypes}
								label={t('Unit System*')}
								error={unitErrors.systemType?.message}
								filter
							/>
						)}
					/>
					<Controller
						name="scale"
						control={unitControl}
						render={({ field }) => <InputNumber {...field} label={t('Scale*')} error={unitErrors.scale?.message} />}
					/>
					<div className={styles.btnWithInput}>
						<Controller
							name="offset"
							control={unitControl}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Offset')}
									error={unitErrors.offset?.message}
									className={styles.fullWidth}
								/>
							)}
						/>
						<Button
							variant="contained"
							color="primary"
							icon="t-icon-add"
							type="button"
							className={styles.btnAdd}
							onClick={onAddUnit}
						/>
					</div>
					<div className={styles.errorMessageCustom}>{errors.units?.message}</div>
				</Form>
				<div className={styles.unitTable}>
					<UnitTypeTable
						name={t('None')}
						units={noneUnits}
						isSystemQuantity={isSystemQuantity}
						onDeleteUnit={onDeleteUnit}
						onEditUnit={onEditUnit}
						onDefaultChange={onDefaultChange}
					/>
					<UnitTypeTable
						name={t('Metric')}
						units={metricUnits}
						isSystemQuantity={isSystemQuantity}
						onDeleteUnit={onDeleteUnit}
						onEditUnit={onEditUnit}
						onDefaultChange={onDefaultChange}
					/>
					<UnitTypeTable
						name={t('Imperial')}
						units={imperialUnits}
						isSystemQuantity={isSystemQuantity}
						onDeleteUnit={onDeleteUnit}
						onEditUnit={onEditUnit}
						onDefaultChange={onDefaultChange}
					/>
				</div>
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button label={t('Cancel')} data-testid="cancelBtn" variant="contained" color="success" onClick={onBack} />
				<Button
					label={t('Save')}
					data-testid="saveBtn"
					variant="contained"
					color="secondary"
					type="submit"
					form={formId.current}
				/>
			</BottomBar>
		</>
	);
};

export default Unit;
