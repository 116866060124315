import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as api from './station.api';
import { useStationConfiguration } from './station.grpc';
import { ConfiguredStation, StationResponseDto } from './station.types';
import { StationRuleResponseDto } from '../Rules';
import { ActionDtoObjectTypes } from '../Actions';
import { useSystemStatus } from '../Connection/connection.hooks';
import { stationResponseToGRPC } from './station.converters';
import { calculateMessageHash } from '../../tools/protobufHelpers';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useTest } from '../Tests/tests.hooks';

const setRuleActionCOntrolChannels = (stationDto?: StationResponseDto) => {
	stationDto?.stationRules?.forEach((stationRule: StationRuleResponseDto) => {
		stationRule.rulePositiveActions?.forEach((action) => {
			if (action.type === ActionDtoObjectTypes.CONTROL) {
				action.controlChannel = stationDto.controlChannels?.find((channel) => channel.id === action.controlChannelUuid);
			}
		});
		stationRule.ruleNegativeActions?.forEach((action) => {
			if (action.type === ActionDtoObjectTypes.CONTROL) {
				action.controlChannel = stationDto.controlChannels?.find((channel) => channel.id === action.controlChannelUuid);
			}
		});
	});

	return stationDto;
};

export const useStationId = () => {
	const { testId, stationId } = useParams();
	const { testDto } = useTest(testId);

	return stationId || testDto?.stationId;
};

export const useStations = (isEnabled: boolean = true) => {
	const { data: stations, isLoading: isStationsLoading } = useQuery({
		queryKey: ['stations'],
		// When we provide enable option - !!stationId, the stationId is guaranteed
		queryFn: () => api.getStations(),
		enabled: isEnabled
	});

	const queryClient = useQueryClient();

	const updateStations = () => {
		queryClient.invalidateQueries({ queryKey: ['stations'] });
	};

	return {
		stations,
		isStationsLoading,
		updateStations
	};
};

export const useStationsByControllerModel = (model?: string) => {
	const { data: stations, isLoading: isStationsLoading } = useQuery({
		queryKey: ['stations', { model }],
		// When we provide enable option - !!stationId, the stationId is guaranteed
		queryFn: () => api.getStationControllerModel(model)
	});

	const queryClient = useQueryClient();

	const updateStations = () => {
		queryClient.invalidateQueries({ queryKey: ['stations', { model }] });
	};

	return {
		stations,
		isStationsLoading,
		updateStations
	};
};

export const useStation = (stationId?: string) => {
	const { data: stationDto, isLoading } = useQuery<StationResponseDto>({
		queryKey: ['stations', stationId],
		// When we provide enable option - !!stationId, the stationId is guaranteed
		queryFn: () => api.getStationById(stationId),
		enabled: !!stationId
	});

	const queryClient = useQueryClient();

	const updateStation = () => {
		queryClient.invalidateQueries({ queryKey: ['stations', stationId] });
	};

	return {
		stationDto: stationDto,
		isStationLoading: isLoading,
		updateStation
	};
};

export const useStationFull = (stationId?: string) => {
	const { data, error, isLoading } = useQuery<StationResponseDto, { response: any }>({
		queryKey: ['stations_full', { stationId }],
		staleTime: 0,
		gcTime: 500,
		retry: false,
		// When we provide enable option - !!stationId, the stationId is guaranteed
		queryFn: () => api.getFullStationById(stationId),
		enabled: !!stationId
	});

	const stationDto = useMemo(() => setRuleActionCOntrolChannels(data), [data]);

	const queryClient = useQueryClient();

	const updateStation = () => {
		queryClient.invalidateQueries({ queryKey: ['stations_full', { stationId }] });
	};

	return {
		stationDto: stationDto,
		isStationLoading: isLoading,
		isStationNotFound: error?.response?.status === 404,
		updateStation
	};
};

export const useStationHash = (stationId?: string) => {
	const [hash, setHash] = useState<number>();
	const { isStationLoading, isStationNotFound, stationDto } = useStationFull(stationId);

	useEffect(() => {
		if (stationDto) {
			const stationConfig = stationResponseToGRPC(stationDto);
			const hash = calculateMessageHash(stationConfig);

			setHash(hash);
		} else {
			setHash(undefined);
		}
	}, [stationDto]);

	return {
		hash,
		isStationNotFound,
		isCalculatingHash: isStationLoading
	};
};

export const useStationName = (stationId?: string) => {
	const { stationDto } = useStation(stationId);

	const stationName = useMemo(() => {
		return stationDto?.name || '';
	}, [stationDto]);

	return { stationName };
};

export const useConfigureDevice = (onConfigured?: (data: { isConfigured: boolean; stationId: string }) => void) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleConfigurationFinished = useCallback(
		(data: { isConfigured: boolean; stationId: string }) => {
			setIsLoading(false);
			if (data.isConfigured) toast.success('Station configured successfully');
			if (onConfigured) onConfigured(data);
		},
		[onConfigured]
	);

	const { configure, error } = useStationConfiguration(handleConfigurationFinished);

	const getStationAndConfigure = useCallback(
		async (stationId: string) => {
			setIsLoading(true);
			try {
				let stationFull = await api.getFullStationById(stationId);
				stationFull = setRuleActionCOntrolChannels(stationFull) as StationResponseDto;
				configure(stationFull);
			} catch (e: any) {
				toast.error(e?.message);
				setIsLoading(false);
			}
		},
		[configure]
	);

	useEffect(() => {
		toast.error(error?.message);
	}, [error]);

	return useMemo(() => ({ configure: getStationAndConfigure, isLoading }), [isLoading, getStationAndConfigure]);
};

export const useStationTypes = () => {
	const { data: stationTypes, isLoading: isStationTypesLoading } = useQuery<string[]>({
		queryKey: ['stationTypes'],
		queryFn: () => api.getStationTypes()
	});

	return {
		stationTypes: stationTypes || [],
		isStationTypesLoading
	};
};

export const useConfiguredStation = (stationId?: string) => {
	const configuredStationRef = useRef<ConfiguredStation>();
	const [configuredStation, setConfiguredStation] = useState<ConfiguredStation>();
	const { subscribeToStationsStatuses } = useSystemStatus();

	useEffect(() => {
		let unsubscribe: (() => void) | undefined;
		if (stationId) {
			unsubscribe = subscribeToStationsStatuses(stationId, (data) => {
				if (!data) {
					configuredStationRef.current = undefined;
					setConfiguredStation(undefined);
				} else if (
					configuredStationRef.current?.hash !== data.hash ||
					configuredStationRef.current?.state !== data.state ||
					configuredStationRef.current?.stationId !== data.stationId
				) {
					const config = { hash: data.hash, stationId: data.stationId, state: data.state };
					configuredStationRef.current = config;
					setConfiguredStation(config);
				}
			});
		}

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [stationId, subscribeToStationsStatuses]);

	return configuredStation;
};
