import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { grpc } from '@improbable-eng/grpc-web';
import {
	calibrationService,
	CalibrationUsedChannelsRequest,
	CalibrationUsedChannelsResponse
} from '@tactun/grpc-client';
import { ICalibrationError } from './sensorCalibrationWidget.types';
import { CalibrationRequestDto } from '../Calibrations';
import { createCalibration, updateCalibration } from '../Calibrations/calibrations.api';
import { tError } from '../../tools/logger';

/* const saveCalibrationOnDevice = async (request: CalibrationWidgetRequest) => {
	return new Promise<boolean>((resolve, reject: (err: ICalibrationError) => void) => {
		if (process.env.REACT_APP_DEVICE_URL && request) {
			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

			grpc.unary(calibrationService.WebCalibrationSave.SensorCalibration, {
				request: request,
				host: process.env.REACT_APP_DEVICE_URL,
				transport: myTransport,
				onEnd: (output: UnaryOutput<CalibrationWidgetResponse>) => {
					const err = output.message?.getError();

					if (err) {
						const message = grpcErrorToMessage(err, output.statusMessage);
						reject({
							code: err.getErrorType().toString() ?? '',
							message: message
						});
					} else {
						resolve(true);
					}
				}
			});
		}
	});
};
 */
export const useGetUsedSensors = (stationId?: string) => {
	const [usedSensors, setData] = useState<string[]>([]);
	const [error, setError] = useState<ICalibrationError>();
	const grpcRequestRef = useRef<grpc.Request>();

	useEffect(() => {
		if (stationId) {
			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

			const request = new CalibrationUsedChannelsRequest();
			request.setStationId(stationId);

			grpcRequestRef.current = grpc.invoke(calibrationService.WebCalibrationUsedChannels.SensorCalibration, {
				request: request,
				host: process.env.REACT_APP_DEVICE_URL || '',
				transport: myTransport,
				onEnd: (code: grpc.Code, message: string) => {
					tError(`GRPC stream end code: "${code.toString()}" \nGRPC stream end message: "${message}" \n `);

					if (code !== grpc.Code.OK) {
						setError({ code: code.toString(), message });
					}
				},
				onMessage(res: CalibrationUsedChannelsResponse) {
					const sensorIds = res.getSensorIdList();
					setData(sensorIds);
				}
			});
		}
		return () => {
			grpcRequestRef.current?.close();
		};
	}, [stationId]);

	return useMemo(() => {
		return { usedSensors, getUsedSensorsError: error };
	}, [usedSensors, error]);
};

export const useSaveCalibration = () => {
	const [error, setError] = useState<ICalibrationError>();

	const saveCalibration = useCallback(
		async (request: CalibrationRequestDto, stationId: string, calibrationId?: string) => {
			/* const calibrationGrpcRequest = new CalibrationWidgetRequest();
			calibrationGrpcRequest.setStationId(stationId);
			calibrationGrpcRequest.setSensorId(request.sensorId);
			const calibrationGrpc = calibrationRequestToCalibrationGRPCConverter(request);
			calibrationGrpcRequest.setCalibration(calibrationGrpc); */

			try {
				//await saveCalibrationOnDevice(calibrationGrpcRequest);
				if (calibrationId === undefined) {
					await createCalibration(request);
				} else {
					await updateCalibration(request, calibrationId);
				}
			} catch (e) {
				const err = e as ICalibrationError;
				setError({ code: err.code, message: err.message });
			}
		},
		[]
	);

	return useMemo(() => {
		return { saveCalibration, saveError: error };
	}, [error, saveCalibration]);
};
