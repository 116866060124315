import { toast } from 'react-toastify';
import { useCallback } from 'react';
import { grpc } from '@improbable-eng/grpc-web';
import { DeleteLogsRequest, LoggingVoid, logService } from '@tactun/grpc-client';
import { UnaryOutput } from '@improbable-eng/grpc-web/dist/typings/unary';
import { tError } from '../../tools/logger';

export const useSpecimenLog = () => {
	const downloadLogs = useCallback(async (testId: string, specimenItemId: string, deviceIp: string) => {
		if (window.__TAURI_INVOKE__) {
			try {
				await window.__TAURI_INVOKE__('export_csv', {
					testId,
					specimenItemId,
					deviceIp
				});
			} catch (e) {
				tError(e);
			}
		}
	}, []);

	return { downloadLogs };
};

export const deleteLogs = async (logId: string): Promise<boolean> =>
	new Promise((resolve) => {
		if (process.env.REACT_APP_DEVICE_URL) {
			const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });
			const deleteLogRequest = new DeleteLogsRequest();
			deleteLogRequest.addLogId(logId);

			grpc.unary(logService.Logging.DeleteLogs, {
				request: deleteLogRequest,
				host: process.env.REACT_APP_DEVICE_URL,
				transport: myTransport,
				onEnd: (output: UnaryOutput<LoggingVoid>) => {
					if (output.status === grpc.Code.OK) {
						resolve(true);
					} else {
						toast.error(output.statusMessage ? output.statusMessage : 'Error deleting logs in deleteLogs');

						resolve(false);
					}
				}
			});
		}
	});
