import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IAxisListItem } from '../axes.types';
import AxesTable from '../components/AxesTable';
import { AxesCrudActionTypes } from '../axes.enums';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { useStation } from '../../Stations/stations.hooks';
import { responseListItemConverter } from '../axes.converters';
import { useAxes } from '../axes.hooks';
import { useDevice } from '../../StationChannels';

const AxesTableContainer: FC = () => {
	const navigate = useNavigate();
	const { stationId } = useParams();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const { axes, isAxesLoading, deleteAxis } = useAxes(stationId);
	const { stationDto } = useStation(stationId);
	const { device } = useDevice(stationDto?.controllerModel);
	const stationName = stationDto?.name || '';

	const axesList = useMemo(() => axes?.map((axis) => responseListItemConverter(axis)) || [], [axes]);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handleDeleted = useCallback((id: string) => deleteAxis(id), [deleteAxis]);

	const handleAction = useCallback(
		(type: AxesCrudActionTypes, data?: IAxisListItem) => {
			switch (type) {
				case AxesCrudActionTypes.CREATE:
					navigate('./create');
					break;
				case AxesCrudActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case AxesCrudActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case AxesCrudActionTypes.DELETE:
					setEntityForDelete(data as IAxisListItem);
					break;
			}
		},
		[navigate]
	);

	return (
		<>
			<AxesTable
				onAction={handleAction}
				data={axesList}
				isLoading={isAxesLoading}
				stationName={stationName}
				maxAxesAllowed={device?.numberOfAxes}
			/>
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.AXES}
				entity={entityForDelete}
				checkUsage
			/>
		</>
	);
};

export default AxesTableContainer;
