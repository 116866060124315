import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Dropdown, Form, Checkbox, InputText, InputNumber, Modal } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { UnitFormType } from '../../units.types';
import { unitFormSchema } from '../../units.schemas';
import { IListItemNumber } from '../../../../types';
import styles from './EditUnitModal.module.scss';

interface IEditUnitModalProps {
	unit?: UnitFormType;
	systemTypes: IListItemNumber[];
	units: UnitFormType[];
	onSave: (unit: UnitFormType) => void;
}

const modalId = 'editUnitModal';
const formId = 'editUnitForm';

const EditUnitModal: React.FC<IEditUnitModalProps> = ({ unit, systemTypes, onSave, units }) => {
	const { t } = useTranslation('units');
	const {
		register,
		control,
		formState: { errors },
		handleSubmit,
		watch,
		reset
	} = useForm<UnitFormType>({
		mode: 'onBlur',
		defaultValues: {
			...unit,
			units: []
		},
		resolver: yupResolver(unitFormSchema)
	});

	const handleSave = useCallback(() => {
		handleSubmit((data) => {
			onSave(data);
			Modal.hide(modalId);
		})();
	}, [handleSubmit, onSave]);

	useEffect(() => {
		reset({ ...unit, units: units.filter((u) => u.id !== unit?.id) });
	}, [reset, unit, units]);

	const systemType = watch('systemType');

	const isDefaultDisabled = useMemo(
		() => units.filter((u) => u.systemType === systemType)?.length === 1,
		[systemType, units]
	);

	return (
		<Modal id={modalId}>
			<Modal.Header>{t('Edit a Unit')}</Modal.Header>
			<Modal.Content>
				<Form className={styles.container} onSubmit={handleSave} id={formId}>
					<input type="hidden" {...register('id')} />
					<input type="hidden" {...register('units')} />
					<div className="editUnit">
						<Controller
							name="name"
							control={control}
							render={({ field }) => (
								<InputText
									label={t('Unit*')}
									{...field}
									id="unitName"
									error={errors.name?.message}
									className={styles.inputWidth}
								/>
							)}
						/>
						<Controller
							name="systemType"
							control={control}
							render={({ field }) => (
								<Dropdown
									{...field}
									options={systemTypes}
									label={t('Unit System*')}
									disabled={watch('isDefault')}
									error={errors.systemType?.message}
									filter
									className={styles.inputWidth}
								/>
							)}
						/>
						<Controller
							name="scale"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Scale*')}
									error={errors.scale?.message}
									className={styles.inputWidth}
								/>
							)}
						/>
						<Controller
							name="offset"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Offset')}
									error={errors.offset?.message}
									className={styles.inputWidth}
								/>
							)}
						/>
						<Controller
							name="isDefault"
							control={control}
							render={({ field, formState }) => (
								<Checkbox
									label={t('Mark as default')}
									{...field}
									checked={!!field.value}
									disabled={isDefaultDisabled || formState.defaultValues?.systemType !== watch('systemType')}
									className={styles.markDefault}
								/>
							)}
						/>
					</div>
				</Form>
			</Modal.Content>
			<Modal.Footer>
				<Button onClick={() => Modal.hide(modalId)} color="success" variant="text">
					{t('Cancel')}
				</Button>
				<Button onClick={handleSave} color="secondary" variant="contained">
					{t('Save')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default React.memo(EditUnitModal);
