import { FC, useEffect } from 'react';
import { Dropdown, GroupInputFrame, InputNumber, InputText } from '@tactun/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEntitiesAsList, useNumberEnumList } from '../../../hooks';
import { VariableResponseDto } from '../../Variables';
import { WaveformDefinitionMode, WaveformValueType } from '../waveforms.enums';
import { useControllerName } from '../waveforms.hooks';
import { WaveformSinewaveFormErrors } from '../waveforms.types';
import { getFieldByFormPrefix } from '../waveforms.tools';

export interface SinewaveWaveformFormViewProps {
	variables: VariableResponseDto[];
	formPrefix?: string;
	errors?: WaveformSinewaveFormErrors;
	feedbackUnit: string;
	isRunning?: boolean;
	isControlStage?: boolean;
}

const SinewaveWaveformFormView: FC<SinewaveWaveformFormViewProps> = ({
	variables,
	formPrefix,
	errors,
	feedbackUnit,
	isRunning,
	isControlStage = false
}) => {
	const { t } = useTranslation('waveforms');

	const {
		control,
		register,
		watch,
		setValue,
		formState: { dirtyFields }
	} = useFormContext();

	const difinitionModeCN = useControllerName('defMode', formPrefix);
	const difinitionModeOptions = useNumberEnumList(WaveformDefinitionMode);

	const valueTypeOptions = useNumberEnumList(WaveformValueType, 'waveforms');
	const variableOptions = useEntitiesAsList(variables);

	const amplitudeTypeCN = useControllerName('amplitudeType', formPrefix);
	const amplitudeType = watch(amplitudeTypeCN);
	const amplitudeValueCN = useControllerName('amplitudeValue', formPrefix);
	const amplitudeVariableIdCN = useControllerName('amplitudeVariableId', formPrefix);

	const meanTypeCN = useControllerName('meanType', formPrefix);
	const meanType = watch(meanTypeCN);
	const meanValueCN = useControllerName('meanValue', formPrefix);
	const meanVariableIdCN = useControllerName('meanVariableId', formPrefix);

	const frequencyCN = useControllerName('frequency', formPrefix);
	const startingPhaseCN = useControllerName('startingPhase', formPrefix);
	const endingPhaseCN = useControllerName('endingPhase', formPrefix);
	const numberOfCyclesCN = useControllerName('numberOfCycles', formPrefix);

	const meanTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.meanType;
	useEffect(() => {
		if (meanTypeDirtyField) {
			setValue(meanValueCN, null);
			setValue(meanVariableIdCN, null);
		}
	}, [formPrefix, meanValueCN, meanVariableIdCN, setValue, meanTypeDirtyField]);

	const amplitudeTypeDirtyField = getFieldByFormPrefix(dirtyFields, formPrefix)?.amplitudeType;
	useEffect(() => {
		if (amplitudeTypeDirtyField) {
			setValue(amplitudeValueCN, null);
			setValue(amplitudeVariableIdCN, null);
		}
	}, [formPrefix, setValue, amplitudeTypeDirtyField, amplitudeValueCN, amplitudeVariableIdCN]);

	return (
		<>
			<div />
			<Controller
				name={difinitionModeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Definition mode*')}
						options={difinitionModeOptions}
						data-testid="difinitionModeCN"
						error={errors?.defMode?.message}
						{...field}
						disabled={isRunning}
					/>
				)}
			/>
			<div />
			<Controller
				name={amplitudeTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Amplitude*')}
						options={valueTypeOptions}
						data-testid="amplitudeTypeCN"
						error={errors?.amplitudeType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{amplitudeType === WaveformValueType.VARIABLE ? (
					<Controller
						name={amplitudeVariableIdCN}
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								label={t('Amplitude variable*')}
								data-testid="amplitudeVariableIdCN"
								options={variableOptions}
								error={errors?.amplitudeVariableId?.message}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<InputNumber
						data-testid="amplitudeValueCN"
						{...register(amplitudeValueCN, { deps: [frequencyCN] })}
						label={t('Amplitude constant value*')}
						error={errors?.amplitudeValue?.message}
					/>
				)}
				<InputText data-testid="feedbackUnit" disabled value={feedbackUnit} />
			</GroupInputFrame>
			<Controller
				name={meanTypeCN}
				control={control}
				render={({ field }) => (
					<Dropdown
						label={t('Mean*')}
						options={valueTypeOptions}
						data-testid="meanTypeCN"
						error={errors?.meanType?.message}
						{...field}
						disabled={isRunning || isControlStage}
					/>
				)}
			/>
			<GroupInputFrame>
				{meanType === WaveformValueType.VARIABLE ? (
					<Controller
						name={meanVariableIdCN}
						control={control}
						render={({ field }) => (
							<Dropdown
								data-testid="meanVariableIdCN"
								{...field}
								label={t('Mean variable*')}
								options={variableOptions}
								disabled={isRunning}
							/>
						)}
					/>
				) : (
					<InputNumber
						data-testid="meanValueCN"
						{...register(meanValueCN)}
						label={t('Mean constant value*')}
						error={errors?.meanValue?.message}
					/>
				)}
				<InputText data-testid="feedbackUnit" disabled value={feedbackUnit} />
			</GroupInputFrame>
			<GroupInputFrame>
				<InputNumber
					data-testid="frequencyCN"
					{...register(frequencyCN, { deps: [amplitudeValueCN] })}
					label={t('Frequency*')}
					error={errors?.frequency?.message}
				/>
				<InputText data-testid="Hz" disabled value="Hz" />
			</GroupInputFrame>
			<GroupInputFrame>
				<InputNumber
					data-testid="startingPhaseCN"
					{...register(startingPhaseCN)}
					label={t('Starting Phase')}
					error={errors?.startingPhase?.message}
					disabled={isRunning}
					isInteger
				/>
				<InputText data-testid="deg" disabled value={'deg'} />
			</GroupInputFrame>
			<GroupInputFrame>
				<InputNumber
					data-testid="endingPhaseCN"
					{...register(endingPhaseCN)}
					label={t('Ending Phase')}
					error={errors?.endingPhase?.message}
					isInteger
				/>
				<InputText data-testid="deg2" disabled value={'deg'} />
			</GroupInputFrame>
			<InputNumber
				data-testid="numberOfCyclesCN"
				{...register(numberOfCyclesCN)}
				label={t('No of cycles*')}
				error={errors?.numberOfCycles?.message}
				isInteger
			/>
		</>
	);
};

export default SinewaveWaveformFormView;
