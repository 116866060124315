import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TestsTable from '../components/TestsTable';
import { TestListItem } from '../tests.types';
import { TestsTableActionTypes } from '../tests.enums';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { responseListItemConverter } from '../tests.converters';
import { useTests } from '../tests.hooks';

const TestsTableContainer: React.FC = () => {
	const navigate = useNavigate();
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const { isTestsLoading, deleteTest, tests } = useTests();
	const testList = useMemo(() => tests?.map((test) => responseListItemConverter(test)) || [], [tests]);

	const handleAction = useCallback(
		(type: TestsTableActionTypes, data?: TestListItem) => {
			switch (type) {
				case TestsTableActionTypes.EDIT:
					navigate(`./${data?.id}`);
					break;
				case TestsTableActionTypes.RUN_TEST:
					navigate(`./${data?.id}/dashboard`);
					break;
				case TestsTableActionTypes.CREATE:
					navigate('./create');
					break;
				case TestsTableActionTypes.DUPLICATE:
					alert('Duplicate functional is not implemented yet.');
					break;
				case TestsTableActionTypes.DELETE:
					setEntityForDelete(data as IEntity);
					break;
			}
		},
		[navigate]
	);

	const handleDeleteCancel = useCallback(() => {
		setEntityForDelete(undefined);
	}, []);

	const handlePostDelete = useCallback((id: string) => deleteTest(id), [deleteTest]);

	return (
		<>
			<TestsTable onAction={handleAction} data={testList} isLoading={isTestsLoading} />
			<EntityDeleter
				onDeleted={handlePostDelete}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.TEST}
				entity={entityForDelete}
			/>
		</>
	);
};

export default React.memo(TestsTableContainer);
