import React from 'react';
import { Form, Dropdown, InputNumber } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { TestLoopFormType } from '../../testLoops.types';
import { TestLoopRunForCalculationDto } from '../../testLoops.enums';
import { useEntitiesAsList, useNumberEnumList } from '../../../../hooks';
import { useTestStages } from '../../../Tests/tests.hooks';
import styles from './TestLoopForm.module.scss';

interface TestLoopFormProps {
	form: UseFormReturn<TestLoopFormType>;
	testId?: string;
	isCreate: boolean;
}

const formId = 'testLoopForm';
const TestLoopForm: React.FC<TestLoopFormProps> = ({ form, testId, isCreate }) => {
	const { t } = useTranslation('testLoops');
	const { stages = [] } = useTestStages(testId);
	const stageOptions = useEntitiesAsList(stages);
	const {
		control,
		register,
		formState: { errors }
	} = form;
	const runForCalcOptions = useNumberEnumList(TestLoopRunForCalculationDto);

	return (
		<Form className={styles.modalContainer} id={formId}>
			<input type="hidden" {...register('id')} />
			<input type="hidden" {...register('testId')} />
			<Controller
				name="startStageId"
				control={control}
				render={({ field }) => (
					<Dropdown
						data-testid="startStageId"
						className={styles.labelContainer}
						{...field}
						options={stageOptions}
						label={t('Start stage*')}
						error={errors.startStageId?.message}
						filter
					/>
				)}
			/>
			<Controller
				name="endStageId"
				control={control}
				render={({ field }) => (
					<Dropdown
						data-testid="endStageId"
						className={styles.labelContainer}
						{...field}
						options={stageOptions}
						label={t('End stage*')}
						error={errors.endStageId?.message}
						filter
					/>
				)}
			/>
			<Controller
				control={control}
				name="iter"
				render={({ field }) => (
					<InputNumber {...field} label="Number of iterations" error={errors?.iter?.message} data-testid="iter" />
				)}
			/>
			<Controller
				name="runCalcFor"
				control={control}
				render={({ field }) => (
					<Dropdown
						data-testid="runCalcFor"
						className={styles.labelContainer}
						{...field}
						options={runForCalcOptions}
						label={t('Run calculation for')}
						error={errors.runCalcFor?.message}
						filter
					/>
				)}
			/>
		</Form>
	);
};

export default TestLoopForm;
