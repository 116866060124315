import { FC, useMemo } from 'react';
import { Dropdown, Switch } from '@tactun/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useControlAlgorithmTypes } from '../../controlAlgorithms.hooks';
import { ControlAlgorithmsBase } from '../../controlAlgorithms.enums';
import { ControlAlgorithmFormErrors } from '../../controlAlgorithms.types';
import { useEnumList } from '../../../../hooks';

interface ControlAlgorithmBaseFormProps {
	formPrefix: string;
	isAxisBasedOnAnalogOut: boolean;
	isOpenLoopAvailable: boolean;
	disabled: boolean;
	errors?: ControlAlgorithmFormErrors;
	isControlStage?: boolean;
}

const ControlAlgorithmBaseForm: FC<ControlAlgorithmBaseFormProps> = ({
	formPrefix,
	isAxisBasedOnAnalogOut,
	isOpenLoopAvailable,
	isControlStage,
	disabled,
	errors
}) => {
	const { t } = useTranslation('controlAlgorithm');

	const { control, watch } = useFormContext();
	const additionalAlgorithms = watch(`${formPrefix}.additionalAlgorithms`);
	const baseAlgorithm = watch(`${formPrefix}.baseAlgorithm`);
	const additionalAlgorithmsList = useControlAlgorithmTypes(additionalAlgorithms, baseAlgorithm);
	const baseAlgorithmsList = useEnumList(ControlAlgorithmsBase, 'controlAlgorithm');
	const baseAlgorithmsListFiltered = useMemo(
		() =>
			baseAlgorithmsList.map((item) =>
				isOpenLoopAvailable ? item : { ...item, isDisabled: item.value === ControlAlgorithmsBase.OPEN_LOOP }
			),
		[baseAlgorithmsList, isOpenLoopAvailable]
	);

	return (
		<>
			<Controller
				name={`${formPrefix}.baseAlgorithm`}
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						label={t('Control type*')}
						options={baseAlgorithmsListFiltered}
						error={errors?.baseAlgorithm?.message}
						disabled={disabled || isControlStage}
						data-testid="baseAlgorithm"
						filter
					/>
				)}
			/>
			<Controller
				name={`${formPrefix}.additionalAlgorithms`}
				control={control}
				render={({ field }) => (
					<Dropdown
						isMulti
						{...field}
						label={t('Algorithm types')}
						options={additionalAlgorithmsList}
						error={errors?.additionalAlgorithms?.message}
						data-testid="additionalAlgorithms"
						filter
					/>
				)}
			/>
			<Controller
				name={`${formPrefix}.isDitherEnabled`}
				control={control}
				render={({ field }) => (
					<Switch
						label={t('Dither')}
						disabled={!isAxisBasedOnAnalogOut}
						inputId={field.name}
						data-testid="isDitherEnabled"
						onChange={(e) => field.onChange(e.value)}
						checked={!!field.value}
					/>
				)}
			/>
		</>
	);
};

export default ControlAlgorithmBaseForm;
