export const getOrders = async (): Promise<Record<string, string>[]> => {
	return new Promise((resolve) =>
		resolve([
			{
				id: '1',
				orderID: '12982',
				company: 'Company 1',
				controllerSerialNumber: '000029813',
				product: 'System_name',
				date: '12/04/2023, 13:04:56'
			},
			{
				id: '2',
				orderID: '12988',
				company: 'ABC Company',
				controllerSerialNumber: '000062325',
				product: 'System_name',
				date: '12/06/2023, 13:04:56'
			},
			{
				id: '3',
				orderID: '12989',
				company: 'XYZ Company',
				controllerSerialNumber: '000061678',
				product: 'System_name',
				date: '12/06/2023, 13:04:56'
			},
			{
				id: '4',
				orderID: '22222',
				company: 'Company 3',
				controllerSerialNumber: '000062379',
				product: 'System_name',
				date: '12/06/2023, 13:04:56'
			},
			{
				id: '5',
				orderID: '23984',
				company: 'Company 4',
				controllerSerialNumber: '000062355',
				product: 'System_name',
				date: '12/06/2023, 13:04:56'
			},
			{
				id: '6',
				orderID: '23985',
				company: 'Company 9',
				controllerSerialNumber: '000061678',
				product: 'System_name',
				date: '12/06/2023, 13:04:56'
			},
			{
				id: '7',
				orderID: '24826',
				company: 'Company 12',
				controllerSerialNumber: '000061444',
				product: 'System_name',
				date: '12/06/2023, 13:04:56'
			},
			{
				id: '8',
				orderID: '36987',
				company: 'Company 20',
				controllerSerialNumber: '000061179',
				product: 'System_name',
				date: '12/06/2023, 13:04:56'
			}
		])
	);
};

export const getOrder = async (id: string): Promise<Record<string, string>> => {
	return new Promise((resolve) =>
		resolve({
			id: '2',
			orderID: '12988',
			company: 'Company 2',
			controllerSerialNumber: '062378',
			product: 'System 2',
			date: '12/06/2023, 13:04:56'
		})
	);
};
