import { ChannelType } from './stationChannels.enums';
import axios from '../../httpCommon';
import {
	IChannel,
	IDevice,
	IStationChannel,
	StationChannelRequestDto,
	IStationChannelResponseDto
} from './stationChannels.types';

export const getChannelById = async (id?: string): Promise<IStationChannelResponseDto> => {
	const response = await axios.get(`/quasar/station-channels/${id}`);

	return response.data;
};

export const checkName = async (name: string, parentId?: string, id?: string): Promise<boolean> => {
	const response = await axios.post('/quasar/station-channels/isNameUnique', { id, name, parentId });
	return response.data;
};

export const createStationChannel = async (
	stationChannel: StationChannelRequestDto
): Promise<IStationChannelResponseDto> => {
	const response = await axios.post('/quasar/station-channels', stationChannel);
	return response.data;
};

export const updateStationChannel = async (
	stationChannel: StationChannelRequestDto,
	id: string
): Promise<IStationChannelResponseDto> => {
	const response = await axios.put(`/quasar/station-channels/${id}`, stationChannel);

	return response.data;
};

export const getUnusedChannels = async (systemId?: string, type?: ChannelType): Promise<IChannel[]> => {
	const response = await axios.get<IChannel[]>(`/quasar/station-channels/channels-not-used/${systemId}/${type}`);

	return response.data;
};

export const getChannelTypes = async (systemId: string | undefined): Promise<IStationChannel[]> => {
	const response = await axios.get(`/quasar/station-channels/system/${systemId}`);

	return response.data;
};

export const getStationChannels = async (stationId?: string): Promise<IStationChannelResponseDto[]> => {
	const response = await axios.get(`/quasar/station-channels/station/${stationId}`);

	return response.data ?? [];
};

export const getUnusedStationChannels = async (
	stationId: string,
	types: ChannelType[]
): Promise<IStationChannelResponseDto[]> => {
	const response = await axios.get(
		`/quasar/station-channels/station/${stationId}/available-station-channels/${types.join(',')}`
	);

	return response.data ?? [];
};

export const getStationById = async (id?: string): Promise<{ controllerModel: string }> => {
	const response = await axios.get(`/quasar/stations/${id}`);

	return response.data;
};

export const getDeviceByModel = async (model?: string): Promise<IDevice> => {
	const response = await axios.get(`/quasar/devices/${model}`);
	return response.data;
};

export const checkIsChannelInUse = async (channelId?: string) => {
	const response = await axios.delete(`/quasar/station-channels/checkIsInUse/${channelId}`, {
		// TODO: Check why backend returning 302
		validateStatus: () => true
	});

	return response.data;
};
