import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import OffsetCalibrationDialog from '../components/OffsetCalibrationDialog';
import { offsetCalibrationConfigurationSchema } from '../offsetCalibration.schemas';
import { useEntitiesAsList } from '../../../hooks';
import { ControlChannelResponseDto, useAxisControlChannels, useControlChannel } from '../../ControlChannels';
import { responseControlAlgorithmsFormConverter } from '../../ControlAlgorithms';
import { OffsetCalibrationStatus } from '../offsetCalibration.enums';
import { OffsetCalibrationConfigurationFormType } from '../offsetCalibration.types';

interface IOffsetCalibrationDialogContainerProps {
	axisId: string;
	limit?: number;
	calibrationStatus: OffsetCalibrationStatus;
	offsetUnit?: string;
	onRunningChange: (
		data: OffsetCalibrationConfigurationFormType,
		channel: ControlChannelResponseDto,
		isStop: boolean
	) => void;
	isDialogVisible: boolean;
	onDialogVisibilityChange: (isRunning: boolean) => void;
}

const OffsetCalibrationDialogContainer = ({
	limit,
	axisId,
	calibrationStatus,
	onRunningChange,
	isDialogVisible,
	offsetUnit,
	onDialogVisibilityChange
}: IOffsetCalibrationDialogContainerProps) => {
	const { t } = useTranslation('offsetCalibration');

	const configurationForm = useForm({
		defaultValues: { limit },
		mode: 'onBlur',
		resolver: yupResolver(offsetCalibrationConfigurationSchema)
	});
	const { reset, getValues, setValue, handleSubmit } = configurationForm;

	const { controlChannels } = useAxisControlChannels(axisId);
	const controlChannelsOption = useEntitiesAsList(controlChannels || []);

	const controlChannelId = configurationForm.watch('controlChannelId');
	const { controlChannel } = useControlChannel(controlChannelId);

	useEffect(() => {
		if (controlChannel) {
			const controlAlg = responseControlAlgorithmsFormConverter(controlChannel.controlAlgorithm);
			const formData = getValues();
			reset({ ...formData, controlAlgorithmForm: controlAlg }, { keepErrors: true, keepDirty: true });
		}
	}, [controlChannel, getValues, reset]);

	useEffect(() => {
		if (limit) {
			setValue('limit', limit);
		}
	}, [limit, setValue]);

	useEffect(() => {
		return () => {
			reset();
		};
	}, [reset]);

	const handleHideDialog = useCallback(() => {
		onDialogVisibilityChange(false);
	}, [onDialogVisibilityChange]);

	const handleToggleCalibration = useCallback(() => {
		if (controlChannel) {
			const isStop = calibrationStatus === OffsetCalibrationStatus.IN_PROGRESS;
			handleSubmit((data) => {
				onRunningChange(data, controlChannel, isStop);
			})();
		}
	}, [calibrationStatus, onRunningChange, handleSubmit, controlChannel]);

	return (
		<FormProvider {...configurationForm}>
			<OffsetCalibrationDialog
				title={t('Offset Calibration')}
				calibrationStatus={calibrationStatus}
				offsetUnit={offsetUnit}
				onHide={handleHideDialog}
				onToggleCalibration={handleToggleCalibration}
				isVisible={isDialogVisible}
				controlChannels={controlChannelsOption}
			/>
		</FormProvider>
	);
};
export default OffsetCalibrationDialogContainer;
