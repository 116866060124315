import {
	ValOrId,
	WF_Constant,
	WF_DuoHaverSine,
	WF_DuoRamp,
	WF_Dwell,
	WF_HaverSine,
	WF_Ramp,
	WF_Sine,
	WF_Squarewave,
	WF_SweepSine,
	WF_TaperedSine,
	WFProps
} from '@tactun/grpc-client';
import {
	WaveformDefinitionMode,
	WaveformDirection,
	WaveformEndChannelType,
	WaveformRampDirection,
	WaveformType,
	WaveformValueType
} from './waveforms.enums';
import {
	WaveformConstantType,
	WaveformDto,
	WaveformDuoHaversineType,
	WaveformDuoRampType,
	WaveformDwellType,
	WaveformFormType,
	WaveformHaversineType,
	WaveformRampType,
	WaveformSinewaveType,
	WaveformSquareSineType,
	WaveformSweepSineType,
	WaveformTaperedSineType
} from './waveforms.types';
import { isNotNullOrUndefined } from '../../tools/common';

const getEndChMeasIdAndType = (
	endChannel?: WaveformEndChannelType | string | null
): { measurementId: string | null; endChannelType: WaveformEndChannelType | null } => {
	const measurementId =
		endChannel && endChannel !== WaveformEndChannelType.SET_POINT && endChannel !== WaveformEndChannelType.TIME
			? endChannel
			: null;

	let endChannelType;
	if (measurementId) {
		endChannelType = WaveformEndChannelType.MEASUREMENT;
	} else {
		endChannelType = endChannel !== undefined ? (endChannel as WaveformEndChannelType) : null;
	}

	return { measurementId, endChannelType };
};

const getEndCh = (
	endChannelType: WaveformEndChannelType | undefined,
	measId: string | undefined | null
): WaveformEndChannelType | string | null => {
	let endChannel: string | WaveformEndChannelType | null;
	if (endChannelType === WaveformEndChannelType.MEASUREMENT && measId) {
		endChannel = measId;
	} else {
		endChannel = endChannelType !== undefined ? (endChannelType as WaveformEndChannelType) : null;
	}

	return endChannel;
};

export const waveformFormToRequestConverter = (waveformBase: WaveformFormType): WaveformDto => {
	switch (waveformBase.type as WaveformType) {
		case WaveformType.RAMP: {
			const waveform = waveformBase as WaveformRampType;
			const { measurementId: endChannelMeasurementId, endChannelType } = getEndChMeasIdAndType(waveform.endChannel);
			return {
				wfType: WaveformType.RAMP,
				defMode: waveform.defMode as WaveformDefinitionMode,
				direction: waveform.rampDirection as WaveformRampDirection,
				directionRel:
					waveform.rampDirection === WaveformRampDirection.AUTODETECT ? waveform.directionRel || false : undefined,

				rateType: waveform.rateType as WaveformValueType,
				rateVal: waveform.rateValue,
				rateVarId: waveform.rateVariableId,

				endChannelType: endChannelType as WaveformEndChannelType,
				measId: endChannelMeasurementId,
				endValueType: waveform.endChannelValueType as WaveformValueType,
				endVal: waveform.endChannelValue as number,
				endVarId: waveform.endChannelVariableId as string,
				invEndCond: !!waveform.isInvertEndCondition
			};
		}
		case WaveformType.DWELL: {
			const waveform = waveformBase as WaveformDwellType;
			return {
				wfType: WaveformType.DWELL,
				durationType: waveform.durationType as WaveformValueType,
				durationVal: waveform.durationValue,
				durationVarId: waveform.durationVariableId
			};
		}
		case WaveformType.CONSTANT: {
			const waveform = waveformBase as WaveformConstantType;
			return {
				wfType: WaveformType.CONSTANT,
				valueType: waveform.valueType as WaveformValueType,
				valueVarId: waveform.variableId,
				valueVal: waveform.value,

				durationType: waveform.durationType as WaveformValueType,
				durationVarId: waveform.durationVariableId,
				durationVal: waveform.durationValue
			};
		}
		case WaveformType.SINE: {
			const waveform = waveformBase as WaveformSinewaveType;
			return {
				wfType: WaveformType.SINE,
				defMode: waveform.defMode as WaveformDefinitionMode,

				amplitudeType: waveform.amplitudeType as WaveformValueType,
				amplitudeVal: waveform.amplitudeValue,
				amplitudeVarId: waveform.amplitudeVariableId,

				meanType: waveform.meanType as WaveformValueType,
				meanVal: waveform.meanValue,
				meanVarId: waveform.meanVariableId,

				frequencyVal: waveform.frequency as number,
				startPhase: waveform.startingPhase as number,
				endPhase: waveform.endingPhase as number,
				cycles: waveform.numberOfCycles as number
			};
		}
		case WaveformType.DUO_RAMP: {
			const waveform = waveformBase as WaveformDuoRampType;
			const { measurementId: endChannelMeasurementId, endChannelType } = getEndChMeasIdAndType(waveform.endChannel);
			return {
				wfType: WaveformType.DUO_RAMP,
				defMode: waveform.defMode as WaveformDefinitionMode,
				direction: waveform.direction as WaveformDirection,

				upRateType: waveform.upRateType as WaveformValueType,
				upRateVal: waveform.upRateValue,
				upRateVarId: waveform.upRateVariableId,

				downRateType: waveform.downRateType as WaveformValueType,
				downRateVal: waveform.downRateValue,
				downRateVarId: waveform.downRateVariableId,

				endChannelType: endChannelType as WaveformEndChannelType,
				measId: endChannelMeasurementId,
				upperEndType: waveform.upEndChannelValueType as WaveformValueType,
				upperEndVal: waveform.upEndChannelValue,
				upperEndVarId: waveform.upEndChannelVariableId,

				lowerEndType: waveform.lowerEndChannelValueType as WaveformValueType,
				lowerEndVal: waveform.lowerEndChannelValue,
				lowerEndVarId: waveform.lowerEndChannelVariableId,

				invEndCond: waveform.isInvertEndCondition as boolean,
				cycles: waveform.numberOfCycles as number
			};
		}
		case WaveformType.HAVER_SINE: {
			const waveform = waveformBase as WaveformHaversineType;
			return {
				wfType: WaveformType.HAVER_SINE,
				defMode: waveform.defMode as WaveformDefinitionMode,
				direction: waveform.direction as WaveformDirection,

				durationType: waveform.durationType as WaveformValueType,
				durationVal: waveform.durationValue,
				durationVarId: waveform.durationVariableId,

				amplitudeType: waveform.amplitudeType as WaveformValueType,
				amplitudeVal: waveform.amplitudeValue,
				amplitudeVarId: waveform.amplitudeVariableId,

				meanType: waveform.meanType as WaveformValueType,
				meanVal: waveform.meanValue,
				meanVarId: waveform.meanVariableId
			};
		}
		case WaveformType.DUO_HAVER_SINE: {
			const waveform = waveformBase as WaveformDuoHaversineType;
			return {
				wfType: WaveformType.DUO_HAVER_SINE,
				defMode: waveform.defMode as WaveformDefinitionMode,
				direction: waveform.direction as WaveformDirection,

				upDurationType: waveform.upDurationType as WaveformValueType,
				upDurationVal: waveform.upDurationValue,
				upDurationVarId: waveform.upDurationVariableId,

				downDurationType: waveform.downDurationType as WaveformValueType,
				downDurationVal: waveform.downDurationValue,
				downDurationVarId: waveform.downDurationVariableId,

				amplitudeType: waveform.amplitudeType as WaveformValueType,
				amplitudeVal: waveform.amplitudeValue,
				amplitudeVarId: waveform.amplitudeVariableId,

				meanType: waveform.meanType as WaveformValueType,
				meanVal: waveform.meanValue,
				meanVarId: waveform.meanVariableId,

				cycles: waveform.numberOfCycles as number
			};
		}
		case WaveformType.TAPERED_SINE: {
			const waveform = waveformBase as WaveformTaperedSineType;
			return {
				wfType: WaveformType.TAPERED_SINE,
				defMode: waveform.defMode as WaveformDefinitionMode,
				amplitudeType: waveform.amplitudeType as WaveformValueType,
				amplitudeVal: waveform.amplitudeValue,
				amplitudeVarId: waveform.amplitudeVariableId,

				meanType: waveform.meanType as WaveformValueType,
				meanVal: waveform.meanValue,
				meanVarId: waveform.meanVariableId,

				frequencyVal: waveform.frequency as number,
				endFrequencyVal: waveform.envelopFrequency as number,
				cycles: waveform.numberOfCycles as number
			};
		}
		case WaveformType.SWEEP_SINE: {
			const waveform = waveformBase as WaveformSweepSineType;
			return {
				wfType: WaveformType.SWEEP_SINE,
				defMode: waveform.defMode as WaveformDefinitionMode,

				amplitudeType: waveform.amplitudeType as WaveformValueType,
				amplitudeVal: waveform.amplitudeValue,
				amplitudeVarId: waveform.amplitudeVariableId,

				meanType: waveform.meanType as WaveformValueType,
				meanVal: waveform.meanValue,
				meanVarId: waveform.meanVariableId,

				startFrequencyVal: waveform.startFrequency as number,
				endFrequencyVal: waveform.endFrequency as number,
				phase: waveform.phase as number,
				cycles: waveform.numberOfCycles as number
			};
		}
		case WaveformType.SQUAREWAVE: {
			const waveform = waveformBase as WaveformSquareSineType;
			return {
				wfType: WaveformType.SQUAREWAVE,
				defMode: waveform.defMode as WaveformDefinitionMode,

				amplitudeType: waveform.amplitudeType as WaveformValueType,
				amplitudeVal: waveform.amplitudeValue,
				amplitudeVarId: waveform.amplitudeVariableId,

				meanType: waveform.meanType as WaveformValueType,
				meanVal: waveform.meanValue,
				meanVarId: waveform.meanVariableId,

				startDirection: waveform.direction as WaveformDirection,
				frequencyVal: waveform.frequency as number,
				cycles: waveform.numberOfCycles as number
			};
		}
	}
};

export const waveformResponseToFormConverter = (response: WaveformDto): WaveformFormType => {
	switch (response.wfType) {
		case WaveformType.RAMP: {
			const endChannel = getEndCh(response.endChannelType, response.measId);
			return {
				type: WaveformType.RAMP,
				defMode: response.defMode as WaveformDefinitionMode,
				rampDirection: response.direction as WaveformRampDirection,
				directionRel: response.directionRel,

				rateType: response.rateType as WaveformValueType,
				rateValue: response.rateVal !== undefined ? response.rateVal : null,
				rateVariableId: response.rateVarId !== undefined ? response.rateVarId : null,

				endChannel: endChannel !== null ? endChannel : '',
				endChannelValueType: response.endValueType,
				endChannelValue: response.endVal !== undefined ? response.endVal : null,
				endChannelVariableId: response.endVarId !== undefined ? response.endVarId : null,
				isInvertEndCondition: !!response.invEndCond
			};
		}
		case WaveformType.DWELL:
			return {
				type: WaveformType.DWELL,
				durationType: response.durationType as WaveformValueType,
				durationValue: response.durationVal !== undefined ? response.durationVal : null,
				durationVariableId: response.durationVarId !== undefined ? response.durationVarId : null
			};
		case WaveformType.CONSTANT:
			return {
				type: WaveformType.CONSTANT,
				valueType: response.valueType as WaveformValueType,
				variableId: response.valueVarId !== undefined ? response.valueVarId : null,
				value: response.valueVal !== undefined ? response.valueVal : null,
				durationType: response.durationType as WaveformValueType,
				durationVariableId: response.durationVarId !== undefined ? response.durationVarId : null,
				durationValue: response.durationVal !== undefined ? response.durationVal : null
			};
		case WaveformType.SINE:
			return {
				type: WaveformType.SINE,
				defMode: response.defMode as WaveformDefinitionMode,

				amplitudeType: response.amplitudeType as WaveformValueType,
				amplitudeValue: response.amplitudeVal !== undefined ? response.amplitudeVal : null,
				amplitudeVariableId: response.amplitudeVarId !== undefined ? response.amplitudeVarId : null,

				meanType: response.meanType as WaveformValueType,
				meanValue: response.meanVal !== undefined ? response.meanVal : null,
				meanVariableId: response.meanVarId !== undefined ? response.meanVarId : null,

				frequency: response.frequencyVal,
				startingPhase: response.startPhase,
				endingPhase: response.endPhase,
				numberOfCycles: response.cycles
			};
		case WaveformType.DUO_RAMP: {
			const endChannel = getEndCh(response.endChannelType, response.measId);

			return {
				type: WaveformType.DUO_RAMP,
				defMode: response.defMode as WaveformDefinitionMode,
				direction: response.direction as WaveformDirection,

				upRateType: response.upRateType as WaveformValueType,
				upRateValue: response.upRateVal !== undefined ? response.upRateVal : null,
				upRateVariableId: response.upRateVarId !== undefined ? response.upRateVarId : null,

				downRateType: response.downRateType as WaveformValueType,
				downRateValue: response.downRateVal !== undefined ? response.downRateVal : null,
				downRateVariableId: response.downRateVarId !== undefined ? response.downRateVarId : null,

				endChannel: endChannel !== null ? endChannel : '',
				upEndChannelValueType: response.upperEndType as WaveformValueType,
				upEndChannelValue: response.upperEndVal !== undefined ? response.upperEndVal : null,
				upEndChannelVariableId: response.upperEndVarId !== undefined ? response.upRateVarId : null,

				lowerEndChannelValueType: response.lowerEndType as WaveformValueType,
				lowerEndChannelValue: response.lowerEndVal !== undefined ? response.lowerEndVal : null,
				lowerEndChannelVariableId: response.lowerEndVarId !== undefined ? response.lowerEndVarId : null,

				isInvertEndCondition: response.invEndCond,
				numberOfCycles: response.cycles
			};
		}
		case WaveformType.HAVER_SINE:
			return {
				type: WaveformType.HAVER_SINE,
				defMode: response.defMode as WaveformDefinitionMode,
				direction: response.direction as WaveformDirection,

				durationType: response.durationType as WaveformValueType,
				durationValue: response.durationVal !== undefined ? response.durationVal : null,
				durationVariableId: response.durationVarId !== undefined ? response.durationVarId : null,

				amplitudeType: response.amplitudeType as WaveformValueType,
				amplitudeValue: response.amplitudeVal !== undefined ? response.amplitudeVal : null,
				amplitudeVariableId: response.amplitudeVarId !== undefined ? response.amplitudeVarId : null,

				meanType: response.meanType as WaveformValueType,
				meanValue: response.meanVal !== undefined ? response.meanVal : null,
				meanVariableId: response.meanVarId !== undefined ? response.meanVarId : null
			};
		case WaveformType.DUO_HAVER_SINE:
			return {
				type: WaveformType.DUO_HAVER_SINE,
				defMode: response.defMode as WaveformDefinitionMode,
				direction: response.direction as WaveformDirection,

				upDurationType: response.upDurationType as WaveformValueType,
				upDurationValue: response.upDurationVal !== undefined ? response.upDurationVal : null,
				upDurationVariableId: response.upDurationVarId !== undefined ? response.upDurationVarId : null,

				downDurationType: response.downDurationType as WaveformValueType,
				downDurationValue: response.downDurationVal !== undefined ? response.downDurationVal : null,
				downDurationVariableId: response.upDurationVal !== undefined ? response.downDurationVarId : null,

				amplitudeType: response.amplitudeType as WaveformValueType,
				amplitudeValue: response.amplitudeVal !== undefined ? response.amplitudeVal : null,
				amplitudeVariableId: response.amplitudeVarId !== undefined ? response.amplitudeVarId : null,

				meanType: response.meanType as WaveformValueType,
				meanValue: response.meanVal !== undefined ? response.meanVal : null,
				meanVariableId: response.meanVarId !== undefined ? response.meanVarId : null,

				numberOfCycles: response.cycles
			};
		case WaveformType.TAPERED_SINE:
			return {
				type: WaveformType.TAPERED_SINE,
				defMode: response.defMode as WaveformDefinitionMode,
				amplitudeType: response.amplitudeType as WaveformValueType,
				amplitudeValue: response.amplitudeVal !== undefined ? response.amplitudeVal : null,
				amplitudeVariableId: response.amplitudeVarId !== undefined ? response.amplitudeVarId : null,

				meanType: response.meanType as WaveformValueType,
				meanValue: response.meanVal !== undefined ? response.meanVal : null,
				meanVariableId: response.meanVarId !== undefined ? response.meanVarId : null,

				frequency: response.frequencyVal,
				envelopFrequency: response.endFrequencyVal,
				numberOfCycles: response.cycles
			};
		case WaveformType.SWEEP_SINE:
			return {
				type: WaveformType.SWEEP_SINE,
				defMode: response.defMode as WaveformDefinitionMode,

				amplitudeType: response.amplitudeType as WaveformValueType,
				amplitudeValue: response.amplitudeVal !== undefined ? response.amplitudeVal : null,
				amplitudeVariableId: response.amplitudeVarId !== undefined ? response.amplitudeVarId : null,

				meanType: response.meanType as WaveformValueType,
				meanValue: response.meanVal !== undefined ? response.meanVal : null,
				meanVariableId: response.meanVarId !== undefined ? response.meanVarId : null,

				startFrequency: response.startFrequencyVal,
				endFrequency: response.endFrequencyVal,
				phase: response.phase,
				numberOfCycles: response.cycles
			};
		case WaveformType.SQUAREWAVE:
			return {
				type: WaveformType.SQUAREWAVE,
				defMode: response.defMode as WaveformDefinitionMode,

				amplitudeType: response.amplitudeType as WaveformValueType,
				amplitudeValue: response.amplitudeVal !== undefined ? response.amplitudeVal : null,
				amplitudeVariableId: response.amplitudeVarId !== undefined ? response.amplitudeVarId : null,

				meanType: response.meanType as WaveformValueType,
				meanValue: response.meanVal !== undefined ? response.meanVal : null,
				meanVariableId: response.meanVarId !== undefined ? response.meanVarId : null,

				direction: response.startDirection,
				frequency: response.frequencyVal,
				numberOfCycles: response.cycles
			};
	}
};

export const waveformFormToGRPCRequestConverter = (waveformBase: WaveformFormType): WFProps => {
	const wf = new WFProps();

	if (waveformBase.type === WaveformType.RAMP) {
		const wfRamp = new WF_Ramp();
		const waveform = waveformBase as WaveformRampType;

		if (isNotNullOrUndefined(waveform.defMode)) wfRamp.setDefMode(waveform.defMode);
		if (isNotNullOrUndefined(waveform.rampDirection)) wfRamp.setDir(waveform.rampDirection);

		if (waveform.rampDirection === WaveformRampDirection.AUTODETECT && isNotNullOrUndefined(waveform.directionRel)) {
			wfRamp.setDirectRelationship(waveform.directionRel);
		}
		const rate = new ValOrId();
		if (isNotNullOrUndefined(waveform.rateValue)) rate.setValue(waveform.rateValue);
		if (isNotNullOrUndefined(waveform.rateVariableId)) rate.setVarId(waveform.rateVariableId);
		wfRamp.setRate(rate);

		if (waveform.endChannel) {
			wfRamp.setEndChannelType(
				waveform.endChannel !== WaveformEndChannelType.TIME && waveform.endChannel !== WaveformEndChannelType.SET_POINT
					? WaveformEndChannelType.MEASUREMENT
					: (waveform.endChannel as WaveformEndChannelType)
			);
		}
		if (
			isNotNullOrUndefined(waveform.endChannel) &&
			(waveform.endChannel as WaveformEndChannelType) !== WaveformEndChannelType.TIME &&
			(waveform.endChannel as WaveformEndChannelType) !== WaveformEndChannelType.SET_POINT
		)
			wfRamp.setMeasId(waveform.endChannel as string);

		const endChannel = new ValOrId();
		if (isNotNullOrUndefined(waveform.endChannelValue)) endChannel.setValue(waveform.endChannelValue);
		if (isNotNullOrUndefined(waveform.endChannelVariableId)) endChannel.setVarId(waveform.endChannelVariableId);
		wfRamp.setEnd(endChannel);

		if (isNotNullOrUndefined(waveform.isInvertEndCondition)) wfRamp.setInvEndCond(waveform.isInvertEndCondition);

		wf.setWfRamp(wfRamp);
	}

	if (waveformBase.type === WaveformType.DUO_RAMP) {
		const wfDuoRamp = new WF_DuoRamp();
		const waveform = waveformBase as WaveformDuoRampType;

		if (isNotNullOrUndefined(waveform.defMode)) wfDuoRamp.setDefMode(waveform.defMode);
		if (isNotNullOrUndefined(waveform.direction)) wfDuoRamp.setDir(waveform.direction);

		const upRate = new ValOrId();
		if (isNotNullOrUndefined(waveform.upRateValue)) upRate.setValue(waveform.upRateValue);
		if (isNotNullOrUndefined(waveform.upRateVariableId)) upRate.setVarId(waveform.upRateVariableId);
		wfDuoRamp.setUpRate(upRate);

		const downRate = new ValOrId();
		if (isNotNullOrUndefined(waveform.downRateValue)) downRate.setValue(waveform.downRateValue);
		if (isNotNullOrUndefined(waveform.downRateVariableId)) downRate.setVarId(waveform.downRateVariableId);
		wfDuoRamp.setDownRate(downRate);

		if (waveform.endChannel) {
			wfDuoRamp.setChannelType(
				waveform.endChannel !== WaveformEndChannelType.TIME && waveform.endChannel !== WaveformEndChannelType.SET_POINT
					? WaveformEndChannelType.MEASUREMENT
					: (waveform.endChannel as WaveformEndChannelType)
			);
		}
		if (
			waveform.endChannel &&
			(waveform.endChannel as WaveformEndChannelType) !== WaveformEndChannelType.TIME &&
			(waveform.endChannel as WaveformEndChannelType) !== WaveformEndChannelType.SET_POINT
		)
			wfDuoRamp.setMeasId(waveform.endChannel as string);

		const lowerEnd = new ValOrId();
		if (isNotNullOrUndefined(waveform.lowerEndChannelValue)) lowerEnd.setValue(waveform.lowerEndChannelValue);
		if (isNotNullOrUndefined(waveform.lowerEndChannelVariableId)) lowerEnd.setVarId(waveform.lowerEndChannelVariableId);
		wfDuoRamp.setLowerEnd(lowerEnd);

		const upperEnd = new ValOrId();
		if (isNotNullOrUndefined(waveform.upEndChannelValue)) upperEnd.setValue(waveform.upEndChannelValue);
		if (isNotNullOrUndefined(waveform.upEndChannelVariableId)) upperEnd.setVarId(waveform.upEndChannelVariableId);
		wfDuoRamp.setUpperEnd(upperEnd);

		if (isNotNullOrUndefined(waveform.isInvertEndCondition)) wfDuoRamp.setInvEndCond(waveform.isInvertEndCondition);
		if (isNotNullOrUndefined(waveform.numberOfCycles)) wfDuoRamp.setCycles(waveform.numberOfCycles);

		wf.setWfDuoRamp(wfDuoRamp);
	}

	if (waveformBase.type === WaveformType.HAVER_SINE) {
		const wfHaverSine = new WF_HaverSine();
		const waveform = waveformBase as WaveformHaversineType;

		if (isNotNullOrUndefined(waveform.defMode)) wfHaverSine.setDefMode(waveform.defMode);
		if (isNotNullOrUndefined(waveform.direction !== null)) wfHaverSine.setDir(waveform.direction);

		const duration = new ValOrId();
		if (isNotNullOrUndefined(waveform.durationValue)) duration.setValue(waveform.durationValue);
		if (isNotNullOrUndefined(waveform.durationVariableId)) duration.setVarId(waveform.durationVariableId);
		wfHaverSine.setDuration(duration);

		const amplitude = new ValOrId();
		if (isNotNullOrUndefined(waveform.amplitudeValue)) amplitude.setValue(waveform.amplitudeValue);
		if (isNotNullOrUndefined(waveform.amplitudeVariableId)) amplitude.setVarId(waveform.amplitudeVariableId);
		wfHaverSine.setAmplitude(amplitude);

		const mean = new ValOrId();
		if (isNotNullOrUndefined(waveform.meanValue)) mean.setValue(waveform.meanValue);
		if (isNotNullOrUndefined(waveform.meanVariableId)) mean.setVarId(waveform.meanVariableId);
		wfHaverSine.setMean(mean);

		wf.setWfHaverSine(wfHaverSine);
	}

	if (waveformBase.type === WaveformType.DUO_HAVER_SINE) {
		const wfDuoHaverSine = new WF_DuoHaverSine();
		const waveform = waveformBase as WaveformDuoHaversineType;

		if (isNotNullOrUndefined(waveform.defMode)) wfDuoHaverSine.setDefMode(waveform.defMode);
		if (isNotNullOrUndefined(waveform.direction)) wfDuoHaverSine.setDir(waveform.direction);

		const upDur = new ValOrId();
		if (isNotNullOrUndefined(waveform.upDurationValue)) upDur.setValue(waveform.upDurationValue);
		if (isNotNullOrUndefined(waveform.upDurationVariableId)) upDur.setVarId(waveform.upDurationVariableId);
		wfDuoHaverSine.setDurationUp(upDur);

		const downDur = new ValOrId();
		if (isNotNullOrUndefined(waveform.downDurationValue)) downDur.setValue(waveform.downDurationValue);
		if (isNotNullOrUndefined(waveform.downDurationVariableId)) downDur.setVarId(waveform.downDurationVariableId);
		wfDuoHaverSine.setDurationDown(downDur);

		const amp = new ValOrId();
		if (isNotNullOrUndefined(waveform.amplitudeValue)) amp.setValue(waveform.amplitudeValue);
		if (isNotNullOrUndefined(waveform.amplitudeVariableId)) amp.setVarId(waveform.amplitudeVariableId);
		wfDuoHaverSine.setAmplitude(amp);

		const mean = new ValOrId();
		if (isNotNullOrUndefined(waveform.meanValue)) mean.setValue(waveform.meanValue);
		if (isNotNullOrUndefined(waveform.meanVariableId)) mean.setVarId(waveform.meanVariableId);
		wfDuoHaverSine.setMean(mean);

		if (isNotNullOrUndefined(waveform.numberOfCycles)) wfDuoHaverSine.setCycles(waveform.numberOfCycles);

		wf.setWfDuoHaverSine(wfDuoHaverSine);
	}

	if (waveformBase.type === WaveformType.SINE) {
		const wfSine = new WF_Sine();
		const waveform = waveformBase as WaveformSinewaveType;

		if (isNotNullOrUndefined(waveform.defMode)) wfSine.setDefMode(waveform.defMode);

		const amp = new ValOrId();
		if (isNotNullOrUndefined(waveform.amplitudeValue)) amp.setValue(waveform.amplitudeValue);
		if (isNotNullOrUndefined(waveform.amplitudeVariableId)) amp.setVarId(waveform.amplitudeVariableId);
		wfSine.setAmplitude(amp);

		const mean = new ValOrId();
		if (isNotNullOrUndefined(waveform.meanValue)) mean.setValue(waveform.meanValue);
		if (isNotNullOrUndefined(waveform.meanVariableId)) mean.setVarId(waveform.meanVariableId);
		wfSine.setMean(mean);

		if (isNotNullOrUndefined(waveform.frequency)) wfSine.setFreqVal(waveform.frequency);
		if (isNotNullOrUndefined(waveform.startingPhase)) wfSine.setStartPhase(waveform.startingPhase);
		if (isNotNullOrUndefined(waveform.endingPhase)) {
			wfSine.setEndPhase(waveform.endingPhase);
		} else {
			wfSine.setEndPhase(waveform.startingPhase || 0);
		}
		if (isNotNullOrUndefined(waveform.numberOfCycles)) wfSine.setCycles(waveform.numberOfCycles);

		wf.setWfSine(wfSine);
	}

	if (waveformBase.type === WaveformType.TAPERED_SINE) {
		const wfTaperedSine = new WF_TaperedSine();
		const waveform = waveformBase as WaveformTaperedSineType;
		if (isNotNullOrUndefined(waveform.defMode) !== null && waveform.defMode !== undefined)
			wfTaperedSine.setDefMode(waveform.defMode);

		const amp = new ValOrId();
		if (isNotNullOrUndefined(waveform.amplitudeValue)) amp.setValue(waveform.amplitudeValue);
		if (isNotNullOrUndefined(waveform.amplitudeVariableId)) amp.setVarId(waveform.amplitudeVariableId);
		wfTaperedSine.setAmplitude(amp);

		const mean = new ValOrId();
		if (isNotNullOrUndefined(waveform.meanValue)) mean.setValue(waveform.meanValue);
		if (isNotNullOrUndefined(waveform.meanVariableId)) mean.setVarId(waveform.meanVariableId);
		wfTaperedSine.setMean(mean);

		if (isNotNullOrUndefined(waveform.frequency)) wfTaperedSine.setFreqVal(waveform.frequency);
		if (isNotNullOrUndefined(waveform.envelopFrequency)) wfTaperedSine.setEndFreqVal(waveform.envelopFrequency);
		if (isNotNullOrUndefined(waveform.numberOfCycles)) wfTaperedSine.setCycles(waveform.numberOfCycles);

		wf.setWfTaperedSine(wfTaperedSine);
	}

	if (waveformBase.type === WaveformType.DWELL) {
		const wfDwell = new WF_Dwell();
		const waveform = waveformBase as WaveformDwellType;

		const dur = new ValOrId();
		if (isNotNullOrUndefined(waveform.durationValue)) dur.setValue(waveform.durationValue);
		if (isNotNullOrUndefined(waveform.durationVariableId)) dur.setVarId(waveform.durationVariableId);
		wfDwell.setDuration(dur);

		wf.setWfDwell(wfDwell);
	}

	if (waveformBase.type === WaveformType.CONSTANT) {
		const wfConstant = new WF_Constant();
		const waveform = waveformBase as WaveformConstantType;

		const value = new ValOrId();
		if (isNotNullOrUndefined(waveform.variableId)) value.setVarId(waveform.variableId);
		if (isNotNullOrUndefined(waveform.value)) value.setValue(waveform.value);
		wfConstant.setValue(value);

		const dur = new ValOrId();
		if (isNotNullOrUndefined(waveform.durationValue)) dur.setValue(waveform.durationValue);
		if (isNotNullOrUndefined(waveform.durationVariableId)) dur.setVarId(waveform.durationVariableId);
		wfConstant.setDuration(dur);

		wf.setWfConstant(wfConstant);
	}

	if (waveformBase.type === WaveformType.SWEEP_SINE) {
		const wfSweepSine = new WF_SweepSine();
		const waveform = waveformBase as WaveformSweepSineType;

		if (isNotNullOrUndefined(waveform.defMode)) wfSweepSine.setDefMode(waveform.defMode);

		const amp = new ValOrId();
		if (isNotNullOrUndefined(waveform.amplitudeValue)) amp.setValue(waveform.amplitudeValue);
		if (isNotNullOrUndefined(waveform.amplitudeVariableId)) amp.setVarId(waveform.amplitudeVariableId);
		wfSweepSine.setAmplitude(amp);

		const mean = new ValOrId();
		if (isNotNullOrUndefined(waveform.meanValue)) mean.setValue(waveform.meanValue);
		if (isNotNullOrUndefined(waveform.meanVariableId)) mean.setVarId(waveform.meanVariableId);
		wfSweepSine.setMean(mean);

		if (isNotNullOrUndefined(waveform.startFrequency)) wfSweepSine.setStartFreqVal(waveform.startFrequency);
		if (isNotNullOrUndefined(waveform.endFrequency)) wfSweepSine.setEndFreqVal(waveform.endFrequency);
		if (isNotNullOrUndefined(waveform.phase)) wfSweepSine.setPhase(waveform.phase);
		if (isNotNullOrUndefined(waveform.numberOfCycles)) wfSweepSine.setCycles(waveform.numberOfCycles);

		wf.setWfSweepSine(wfSweepSine);
	}

	if (waveformBase.type === WaveformType.SQUAREWAVE) {
		const wfSquarewaveSine = new WF_Squarewave();
		const waveform = waveformBase as WaveformSquareSineType;

		if (isNotNullOrUndefined(waveform.defMode)) wfSquarewaveSine.setDefMode(waveform.defMode);

		const amp = new ValOrId();
		if (isNotNullOrUndefined(waveform.amplitudeValue)) amp.setValue(waveform.amplitudeValue);
		if (isNotNullOrUndefined(waveform.amplitudeVariableId)) amp.setVarId(waveform.amplitudeVariableId);
		wfSquarewaveSine.setAmplitude(amp);

		const mean = new ValOrId();
		if (isNotNullOrUndefined(waveform.meanValue)) mean.setValue(waveform.meanValue);
		if (isNotNullOrUndefined(waveform.meanVariableId)) mean.setVarId(waveform.meanVariableId);
		wfSquarewaveSine.setMean(mean);

		if (isNotNullOrUndefined(waveform.direction)) wfSquarewaveSine.setStartDir(waveform.direction);
		if (isNotNullOrUndefined(waveform.numberOfCycles)) wfSquarewaveSine.setCycles(waveform.numberOfCycles);
		if (isNotNullOrUndefined(waveform.frequency)) wfSquarewaveSine.setFreqVal(waveform.frequency);

		wf.setWfSquarewave(wfSquarewaveSine);
	}

	return wf;
};
