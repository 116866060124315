import { Dropdown, GroupInputFrame, InputNumber, InputText } from '@tactun/ui';
import React, { FC, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TriggerSpecimenProperties } from '../../triggers.enums';
import { MeasurementResponseDto } from '../../../Measurements';
import { RuleTrigger } from '../../triggers.types';
import { useEntityOptions } from '../../../../hooks';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';

export interface SpecimenTriggerProps {
	form: UseFormReturn<RuleTrigger>;
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[];
}

const SpecimenTriggerForm: FC<SpecimenTriggerProps> = ({ form, measurements }) => {
	const { t } = useTranslation();
	const {
		control,
		watch,
		formState: { errors }
	} = form;

	const measurementsOptions = useEntityOptions(measurements);

	const objectId = watch('objectId');
	const property = watch('property');
	const thresholdMeasurementId = watch('thresholdMeasurementId');

	const measurementSuffix = useMemo(() => {
		const currentMeasurement = measurements.find((measurement) => measurement.id === objectId);
		return currentMeasurement?.unit.name || '';
	}, [measurements, objectId]);

	const thresholdSuffix = useMemo(() => {
		const thresholdMeasurement = measurements.find((measurement) => measurement.id === thresholdMeasurementId);
		return thresholdMeasurement?.unit.name || '';
	}, [measurements, thresholdMeasurementId]);

	return (
		<>
			{property === TriggerSpecimenProperties.BREAK && (
				<>
					<Controller
						control={control}
						name="objectId"
						render={({ field }) => (
							<Dropdown
								{...field}
								label={t('Measurement*')}
								options={measurementsOptions}
								data-testid="ruleObjectId"
								error={errors.objectId?.message}
							/>
						)}
					/>

					<GroupInputFrame>
						<Controller
							name="threshold"
							control={control}
							render={({ field }) => (
								<InputNumber
									data-testid="ruleThresholdGroup"
									{...field}
									label={t('Threshold*')}
									error={errors.threshold?.message}
								/>
							)}
						/>
						<InputText disabled value={measurementSuffix} />
					</GroupInputFrame>

					<GroupInputFrame>
						<Controller
							name="dropPercentage"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Drop Percentage*')}
									data-testid="ruleDropPercentage"
									error={errors.dropPercentage?.message}
								/>
							)}
						/>
						<InputText disabled value="%" />
					</GroupInputFrame>
				</>
			)}

			{property === TriggerSpecimenProperties.YIELD && (
				<>
					<Controller
						control={control}
						name="stressMeasurementId"
						render={({ field }) => (
							<Dropdown
								{...field}
								label={t('Stress Measurement*')}
								options={measurementsOptions}
								data-testid="ruleStressMeasurement"
								error={errors.stressMeasurementId?.message}
							/>
						)}
					/>
					<Controller
						control={control}
						name="strainMeasurementId"
						render={({ field }) => (
							<Dropdown
								{...field}
								label={t('Strain Measurement*')}
								options={measurementsOptions}
								data-testid="ruleStrainMeasurement"
								error={errors.strainMeasurementId?.message}
							/>
						)}
					/>
					<Controller
						control={control}
						name="thresholdMeasurementId"
						render={({ field }) => (
							<Dropdown
								{...field}
								label={t('Threshold Measurement*')}
								data-testid="ruleThresholdMeasurementId"
								options={measurementsOptions}
								error={errors.thresholdMeasurementId?.message}
							/>
						)}
					/>

					<GroupInputFrame>
						<Controller
							name="threshold"
							control={control}
							render={({ field }) => (
								<InputNumber
									data-testid="ruleThreshold"
									{...field}
									label={t('Threshold*')}
									error={errors.threshold?.message}
								/>
							)}
						/>
						<InputText disabled value={thresholdSuffix} />
					</GroupInputFrame>
				</>
			)}
		</>
	);
};

export default SpecimenTriggerForm;
