import { Converter } from '../../types';
import { ISpecimenTypeRequest, ISpecimenTypeResponse, SpecimenFormType } from './specimenTypes.types';

export const responseFormConverter: Converter<ISpecimenTypeResponse, SpecimenFormType> = (response) => {
	const dimensions = Object.entries(response.dimensions).map(([key, value]) => {
		return {
			id: key,
			name: key,
			unit: value.id,
			unitName: value.name
		};
	});

	return {
		id: response.id,
		name: response.name,
		dimensions: dimensions,
		areaEquation: response.areaEquation,
		areaEquationUnitId: response.areaEquationUnit.id || '',
		areaEquationUnit: response.areaEquationUnit,
		gaugeLengthEquation: response.gaugeLengthEquation,
		gaugeLengthEquationUnitId: response.gaugeLengthEquationUnit.id || '',
		gaugeLengthEquationUnit: response.gaugeLengthEquationUnit,
		imageUrl: response.imageUrl,
		imageId: response.imageId
	};
};

export const formRequestConverter: Converter<SpecimenFormType, ISpecimenTypeRequest> = (form) => {
	const dimensionsObj = form.dimensions?.reduce((prev, { name, unit }) => ({ ...prev, [name]: unit }), {});

	return {
		id: form.id,
		name: form.name,
		dimensions: dimensionsObj as Record<string, any>,
		areaEquation: form.areaEquation,
		areaEquationUnitId: form.areaEquationUnitId,
		gaugeLengthEquation: form.gaugeLengthEquation,
		gaugeLengthEquationUnitId: form.gaugeLengthEquationUnitId,
		imageId: form.imageId
	};
};
