import { Modal } from '@tactun/ui';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EntityDeleter, { EntityTypes, IEntity } from '../../EntityDeleter';
import { useTest } from '../../Tests/tests.hooks';
import CreateGraphModal from '../components/CreateGraphModal';
import GraphTable from '../components/GraphTable';
import { graphListItemConverter } from '../testDashboardConfiguration.converter';
import { DashboardConfigurationActions, Modals } from '../testDashboardConfiguration.enums';
import { useGraphs } from '../testDashboardConfiguration.hooks';
import { GraphListItem } from '../testDashboardConfiguration.types';

const GraphTableContainer: FC = () => {
	const [entityForDelete, setEntityForDelete] = useState<IEntity | undefined>();
	const [entityForUpdate, setEntityForUpdate] = useState('');
	const { testId } = useParams();
	const { testDto } = useTest(testId);
	const { updateGraphs, graphs, isGraphsLoading } = useGraphs<GraphListItem>(testId, graphListItemConverter);

	const onSaveSuccess = () => {
		handleCloseGraphModal();
		updateGraphs();
	};

	const handleDeleted = () => updateGraphs();
	const handleDeleteCancel = () => setEntityForDelete(undefined);

	const handleAction = (action: DashboardConfigurationActions, data?: GraphListItem) => {
		switch (action) {
			case DashboardConfigurationActions.CREATE: {
				Modal.show(Modals.CREATE_GRAPH_MODAL);
				break;
			}
			case DashboardConfigurationActions.EDIT: {
				if (data) {
					setEntityForUpdate(data.id);
					Modal.show(Modals.CREATE_GRAPH_MODAL);
				}
				break;
			}
			case DashboardConfigurationActions.DELETE: {
				setEntityForDelete(data as IEntity);
				break;
			}
		}
	};

	const handleCloseGraphModal = () => {
		Modal.hide(Modals.CREATE_GRAPH_MODAL);
		setEntityForUpdate('');
	};

	const onSaveError = (error: Error) => toast.error(error.message);

	return (
		<>
			<GraphTable onAction={handleAction} isLoading={isGraphsLoading} data={graphs} />
			<EntityDeleter
				onDeleted={handleDeleted}
				onCanceled={handleDeleteCancel}
				entityType={EntityTypes.GRAPH}
				entity={entityForDelete}
			/>
			{testId && testDto?.stationId && (
				<CreateGraphModal
					onSaveSuccess={onSaveSuccess}
					onSaveError={onSaveError}
					testId={testId}
					stationId={testDto.stationId}
					graphId={entityForUpdate}
					handleClose={handleCloseGraphModal}
				/>
			)}
		</>
	);
};

export default GraphTableContainer;
