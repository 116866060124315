import { Converter } from '../../types';
import { WidgetsStatuses, WidgetsTypes } from './widgetsConfiguration.enums';
import {
	AxisConfig,
	ControlAxisWidgetDto,
	ControlTestWidgetDto,
	WidgetControlAxisForm,
	WidgetControlTestForm,
	WidgetForm,
	WidgetRequestDto,
	WidgetResponseDto,
	WidgetsListItem
} from './widgetsConfiguration.types';
import { IAxisResponseDto } from '../Axes';
import { defaultButtonConfigs, defaultLinkButtonConfig, defaultStageEnableBit } from './widgetsConfiguration.const';

export const responseListItemConverter: Converter<WidgetResponseDto, WidgetsListItem> = (response) => {
	return {
		id: response.uuid,
		name: response.name,
		type: WidgetsTypes[response.widget.type],
		status: WidgetsStatuses[response.uuid ? WidgetsStatuses.CONFIGURED : WidgetsStatuses.NOT_CONFIGURED]
	};
};

export const responseFormConverter: Converter<WidgetResponseDto, WidgetForm> = (input) => {
	switch (input.widget.type) {
		case WidgetsTypes.CONTROL_TEST: {
			const { type, ...other } = input.widget;

			return {
				id: input.uuid,
				name: input.name,
				stationId: input.stationUuid,
				type: WidgetsTypes.CONTROL_TEST,
				controlTest: other
			} as WidgetControlTestForm;
		}
		case WidgetsTypes.CONTROL_AXIS: {
			return {
				id: input.uuid,
				name: input.name,
				stationId: input.stationUuid,
				type: WidgetsTypes.CONTROL_AXIS,
				controlAxis: (input.widget as ControlAxisWidgetDto).items
			} as WidgetControlAxisForm;
		}
	}
};

export const controlTestFormRequestConverter: Converter<WidgetControlTestForm, ControlTestWidgetDto> = (input) => ({
	type: WidgetsTypes.CONTROL_TEST,
	startStopButton: input.controlTest.startStopButton,
	pauseResumeButton: input.controlTest.pauseResumeButton,
	jumpToNextStageButton: input.controlTest.jumpToNextStageButton,
	generateReportButton: input.controlTest.generateReportButton
});

export const controlAxisFormRequestConverter: Converter<WidgetControlAxisForm, ControlAxisWidgetDto> = (input) => ({
	type: WidgetsTypes.CONTROL_AXIS,
	items: input.controlAxis
});

export const formRequestConverter: Converter<WidgetForm, WidgetRequestDto> = (input) => {
	return {
		name: input.name,
		stationUuid: input.stationId,
		widget:
			input.type === WidgetsTypes.CONTROL_TEST
				? controlTestFormRequestConverter(input as WidgetControlTestForm)
				: controlAxisFormRequestConverter(input as WidgetControlAxisForm)
	};
};

export const axesToControlAxisConfig = (
	axis: IAxisResponseDto,
	config: AxisConfig = {},
	multiAxis: boolean
): AxisConfig => ({
	axisUuid: axis.id,
	firstStageEnableBit: axis.actuator.s1EnableBitChs ? config.firstStageEnableBit || defaultStageEnableBit : undefined,
	secondStageEnableBit: axis.actuator.s2EnableBitChs ? config.secondStageEnableBit || defaultStageEnableBit : undefined,
	buttonConfigs: config.buttonConfigs || defaultButtonConfigs,
	linkedUnlinkedButton: multiAxis ? config.linkedUnlinkedButton || defaultLinkButtonConfig : undefined
});
