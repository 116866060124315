import React, { forwardRef, Ref } from 'react';
import cx from 'classnames';
import { RadioButton as PrimRadioButton, RadioButtonProps } from 'primereact/radiobutton';

import styles from './RadioButton.module.scss';

export interface IRadioButton extends RadioButtonProps {
	label?: React.ReactNode;
}

const RadioButton: React.FC<IRadioButton> = forwardRef(
	({ id, name, label, className, ...rest }, ref: Ref<HTMLInputElement>) => {
		return (
			<div className={cx(styles.radioContainer, className)}>
				<PrimRadioButton className={styles.field} inputRef={ref} id={id || name} name={name} {...rest} />
				{label && (
					<label className={styles.radioLabel} htmlFor={id || name}>
						{label}
					</label>
				)}
			</div>
		);
	}
);
RadioButton.displayName = 'RadioButton';

export default React.memo(RadioButton);
