import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { usePrevious } from '../../hooks';

export interface IChangedField {
	field: string;
	value: any;
}

export const useChangedField = (subForm: string, isRunning: boolean, isDirty: boolean): IChangedField | null => {
	const fields = useWatch({ name: subForm });
	const prevFields = usePrevious(fields);

	const changedField = useMemo(() => {
		if (!prevFields || !fields) return null;
		if (!isDirty) return null;
		if (!isRunning) return null;
		if (Object.keys(prevFields).length !== Object.keys(fields).length) return null;

		for (const field in fields) {
			if (fields[field] !== prevFields[field]) {
				return { field, value: fields[field] };
			}
		}
		return null;
	}, [fields, isDirty, isRunning, prevFields]);

	return changedField;
};
