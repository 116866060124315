import React, { useCallback, useEffect, useState } from 'react';
import { Modal, useIsChanged } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { endpointMapping, labelMapping } from '../helpers';
import { EntityTypes } from '../entityDeleter.enums';
import { IEntity, IEntityCheckResult } from '../entityDeleter.types';
import * as api from '../entityDeleter.api';

export interface IEntityDeleterContainerProps {
	entity?: IEntity;
	entityType: EntityTypes;
	checkUsage?: boolean;
	onCanceled: () => void;
	onChecked: (canBeDeleted: boolean) => void;
}

// TODO: This component is created for Units. In feature it should be used in EntityDeleterContainer to reduce code duplication
const EntityCheckBeforeDeleteContainer: React.FC<IEntityDeleterContainerProps> = ({
	entity,
	entityType,
	checkUsage = false,
	onCanceled,
	onChecked
}) => {
	const { t } = useTranslation('entityDeleter');
	const isChanged = useIsChanged(entity);
	const [usedMeta, setUsedMeta] = useState<Record<string, string>>({});
	const infoModalId = `${entityType}-delete-Info`;

	const checkDeleteMutation = useMutation({
		mutationFn: (id: string) => api.checkIsInUse(endpointMapping[entityType], id),
		onSuccess: (data) => {
			let isUsed = false;
			if (data.entityNameAndIds) {
				const response = data as IEntityCheckResult;

				isUsed = Object.entries(response.entityNameAndIds).reduce((prev, curr) => {
					return prev || curr[1].length > 0;
				}, false);

				if (isUsed) {
					const usedByEntityTypes = Object.keys(response.entityNameAndIds)
						.map((name) => t(name))
						.join(', ');
					const usedByEntities = Object.values(response.entityNameAndIds)
						.reduce((prev, curr) => {
							return prev.concat(curr);
						}, [])
						.join(', ');
					setUsedMeta({ usedByEntityTypes, usedByEntities });

					Modal.show(infoModalId);
				} else {
					onChecked(true);
				}
			} else {
				onChecked(false);
			}
		},
		onError: (e: Error) => toast.error(e.message)
	});

	useEffect(() => {
		if (isChanged) {
			if (checkUsage && entity?.id) {
				checkDeleteMutation.mutate(entity.id);
			} else {
				onChecked(true);
			}
		}
	}, [checkUsage, checkDeleteMutation, entity, entityType, onChecked, isChanged]);

	const handleCloseInfoModal = useCallback(() => {
		Modal.hide(infoModalId);
		onCanceled();
	}, [onCanceled, infoModalId]);

	return (
		<Modal id={infoModalId} onClose={handleCloseInfoModal}>
			<Modal.Header>{t('Warning')}</Modal.Header>
			<Modal.Content>
				{t(
					`The {{entityLabel}} is used in the following {{usedByEntityTypes}} and cannot be deleted: ({{usedByEntities}}). 
                        Remove the {{entityLabel}} from the {{usedByEntityTypes}} listed to be able to delete it`,
					{
						entityLabel: labelMapping[entityType],
						usedByEntities: usedMeta.usedByEntities,
						usedByEntityTypes: usedMeta.usedByEntityTypes
					}
				)}
			</Modal.Content>
		</Modal>
	);
};

export default React.memo(EntityCheckBeforeDeleteContainer);
