import axios from '../../httpCommon';
import { IEntityCheckResult, IDeleteResponse } from './entityDeleter.types';

export const checkIsInUse = async (endpointPath: string, id: string): Promise<IEntityCheckResult> => {
	const response = await axios.delete(`/${endpointPath}/checkIsInUse/${id}`);
	return response.data;
};

export const deleteEntity = async (endpointPath: string, id: string): Promise<IDeleteResponse> => {
	const response = await axios.delete(`/${endpointPath}/${id}`);
	return response.data;
};
