import { SensorsTypes } from '../Sensors/sensors.enums';
import { CalibrationsTypes } from './calibrations.enums';

export const defaultPiecewiseCallibration = {
	type: CalibrationsTypes.PIECEWISE,
	calibrationData: [
		{
			usd: 0,
			csd: 0
		},
		{
			usd: 1,
			csd: 1
		}
	]
};

export const calibrationFormId = 'calibrationForm';

export const typesWithInversePolarity = [
	SensorsTypes.DIFFERENTIAL_ENCODER,
	SensorsTypes.SINGLE_ENDED_ENCODER,
	SensorsTypes.LOAD_CELL,
	SensorsTypes.LVDT
];
