import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
	TriggerType,
	TestProp,
	TestPropMap,
	TriggerAuxDevice,
	AuxDeviceProp,
	AuxiliaryDeviceType,
	VariableType,
	SpecimenTriggerType
} from '@tactun/grpc-client';
import { isEmpty } from 'lodash';
import { Channel, DashboardRequestDto, DashboardResponseDto, Widget, WidgetTypeDefinition } from './dashboard.types';
import * as api from './dashboard.api';
import { uuid } from '../../tools';
import { subscribeToEvents, subscribeToReadout } from './dashboard.grpc';
import WidgetContext from './components/Widget/widgetContext';
import { useGraphs, useReadouts } from '../TestDashboardConfiguration/testDashboardConfiguration.hooks';
import { graphResponseDtoToSettingsConverter, heightRowToPx } from './dashboard.converters';
import { readoutResponseFormConverter } from '../TestDashboardConfiguration/testDashboardConfiguration.converter';
import { widgetDefinitions } from './dashboard.widgetDefinitions';
import { typeDefaults } from './dashboard.const';
import { DashboardTypes, EventTriggerTypes, WidgetTypes } from './dashboard.enums';
import { GraphDto } from '../GraphWidget/graphWidget.types';
import { readoutFormToWidgetSettings } from '../ReadoutWidget/readout.converters';
import { ReadoutData, ReadoutFormType } from '../ReadoutWidget/readout.types';
import { IAuxiliaryDeviceResponseDto } from '../AuxiliaryDevices';
import { findEmptySpace } from './dashboard.tools';
import { useRunningTestInfo, useSystemStatus } from '../Connection/connection.hooks';
import { useStationId } from '../Stations';

const builtTestDashboardWidgets = (graphs: GraphDto[], readouts: ReadoutFormType[]) => {
	const newWidgets: Widget[] = [];

	const addWidgetToDashboard = (type: WidgetTypes, metadata: Record<string, any>, other?: Record<string, any>) => {
		const widgetType = widgetDefinitions.find(({ type: t }) => t === type) as WidgetTypeDefinition;
		const newWidget = {
			type: type,
			minimized: false,
			w: widgetType.initialSize.w,
			h: widgetType.initialSize.h
		};
		const { x, y } = findEmptySpace(newWidgets, newWidget.w, newWidget.h);
		newWidgets.push({
			...newWidget,
			x,
			y,
			metadata: {
				...metadata,
				id: uuid()
			},
			...(other || {}),
			settings: other?.settings
				? {
						...other.settings,
						type: type
				  }
				: undefined
		});
	};
	// addWidgetToDashboard(WidgetTypes.SAFETY, {}, { x: 20, y: 0, w: 2, h: 4 });
	// addWidgetToDashboard(WidgetTypes.STAGE_INFORMATION, {}, { x: 0, y: 0, w: 12, h: 4 });
	addWidgetToDashboard(WidgetTypes.TEST_INFORMATION, {}, { x: 16, y: 0, w: 8, h: 4 });
	// addWidgetToDashboard(WidgetTypes.CONTROL_AXIS, {}, { x: 22, y: 0, w: 2, h: 11 });
	addWidgetToDashboard(WidgetTypes.CONTROL_TEST, {}, { x: 0, y: 0, w: 2, h: 6 });

	if (graphs.length) {
		const graph = graphs.pop() as GraphDto;
		addWidgetToDashboard(WidgetTypes.GRAPH, graph.meta, { x: 2, y: 4, w: 20, h: 10, settings: graph.settings });
	}
	readouts.forEach((readout) =>
		addWidgetToDashboard(WidgetTypes.READOUT, readoutFormToWidgetSettings(readout), {
			settings: { channel: readout.dataChannel[0] }
		})
	);
	graphs.forEach((graph) => addWidgetToDashboard(WidgetTypes.GRAPH, graph.meta, { settings: graph.settings }));

	return newWidgets;
};

export const useDashboard = (): [
	DashboardResponseDto | undefined,
	(d: DashboardResponseDto | ((d: DashboardResponseDto) => void), silent?: boolean) => DashboardResponseDto
] => {
	const { testId, stationId } = useParams();
	const type = testId ? DashboardTypes.PIPELINE : DashboardTypes.STATION;
	const id = testId ? testId : stationId;

	// Dashboard state
	const { data: dashboard, error } = useQuery<DashboardResponseDto, { response?: any }>({
		queryKey: ['widgets', { id }],
		queryFn: () => api.getDashboard(id as string),
		retry: false
	});

	// Update Dashboard
	const { mutate: update } = useMutation({
		mutationFn: (data: Partial<DashboardRequestDto>) => api.updateDashboard(data),
		onError: (e: Error) => toast.error(e.message)
	});

	const queryClient = useQueryClient();
	const setDashboard = useCallback(
		(data: any, silent = false) => {
			queryClient.setQueryData(['widgets', { id }], data);
			const { data: dashboard } = queryClient.getQueryState<DashboardResponseDto>(['widgets', { id }]) || {};

			if (dashboard?.uuid && !silent) {
				update(dashboard);
			}
			return dashboard as DashboardResponseDto;
		},
		[id, queryClient, update]
	);

	// Add new Dashboard
	const { mutate: createDashboard } = useMutation({
		mutationFn: (data: Omit<DashboardRequestDto, 'originUuid' | 'type' | 'name'>) =>
			api.createDashboard({
				...data,
				name: '',
				originUuid: id as string,
				type
			}),
		onSuccess: (data) => setDashboard(data, true),
		onError: (e: Error) => toast.error(e.message)
	});

	// Create a new dashboard for test if no dashboard found
	const noDashboardFound = error?.response?.status === 404;
	const { graphs, isGraphsLoading } = useGraphs<GraphDto>(
		noDashboardFound ? testId : undefined,
		graphResponseDtoToSettingsConverter
	);
	const { readouts, isReadoutsLoading } = useReadouts<ReadoutFormType>(
		noDashboardFound ? testId : undefined,
		readoutResponseFormConverter
	);
	const needToCreateDashboard = useMemo(
		() => noDashboardFound && !isReadoutsLoading && !isGraphsLoading,
		[isGraphsLoading, isReadoutsLoading, noDashboardFound]
	);

	useEffect(() => {
		if (needToCreateDashboard) {
			const widgets = type === DashboardTypes.PIPELINE ? builtTestDashboardWidgets(graphs, readouts) : [];
			createDashboard({ widgets });
		}
	}, [createDashboard, graphs, readouts, needToCreateDashboard, type]);

	return [dashboard, setDashboard];
};

export const useDashboardWidgets = () => {
	const [dashboard, setDashboard] = useDashboard();

	const setWidgets = useCallback(
		(update: (widgets: Widget[]) => Widget[]) => {
			const dashboard = setDashboard((dashboard: Partial<DashboardResponseDto>) =>
				dashboard
					? {
							...dashboard,
							widgets: update(dashboard.widgets || [])
					  }
					: dashboard
			);

			return dashboard?.widgets;
		},
		[setDashboard]
	);

	const addWidget = useCallback(
		(newWidget: Omit<Widget, 'x' | 'y'>) => {
			const newData = setWidgets((prevWidgets) => {
				const { x, y } = findEmptySpace(prevWidgets, newWidget.w, newWidget.h);
				const widget = {
					...newWidget,
					settings: newWidget.settings
						? {
								...newWidget.settings,
								type: newWidget.type
						  }
						: undefined,
					x,
					y
				};

				return [...prevWidgets, widget];
			});

			const addedWidget = newData?.find(({ metadata }) => metadata.id === newWidget.metadata.id);

			if (addedWidget !== undefined) {
				const widgetCenter = addedWidget.y + addedWidget.h / 2;
				window.scrollTo({
					top: heightRowToPx(widgetCenter) + 56 - window.innerHeight / 2,
					behavior: 'smooth'
				});
			}

			return addedWidget;
		},
		[setWidgets]
	);

	const removeWidget = useCallback(
		(id: string) =>
			setWidgets((prevWidgets) => {
				return prevWidgets.filter((widget) => widget.metadata.id !== id);
			}),
		[setWidgets]
	);

	const updateWidget = useCallback(
		(id: string, newWidget: Partial<Widget>) => {
			if (Object.keys(newWidget).length) {
				setWidgets((prevWidgets) =>
					prevWidgets.map((widget) =>
						widget.metadata.id === id
							? {
									...widget,
									...newWidget,
									...(newWidget.settings ? { settings: { ...newWidget.settings, type: widget.type } } : {})
							  }
							: widget
					)
				);
			}
		},
		[setWidgets]
	);

	const mergedWidgets = useMemo(
		() =>
			(dashboard?.widgets || []).map((widget) => ({
				...widget,
				...(typeDefaults[widget.type] || {}),
				...(widget.minimized ? { noResize: true, h: 1 } : {})
			})),
		[dashboard]
	);

	return {
		widgets: mergedWidgets,
		updateWidgets: setWidgets,
		addWidget,
		updateWidget,
		removeWidget
	};
};

export const useReadoutData = (channel?: Channel): ReadoutData | null => {
	const { id } = useContext(WidgetContext);
	const [data, setData] = useState<ReadoutData | null>(null);

	useEffect(() => {
		setData(null);

		if (!channel) return;

		const subscription = subscribeToReadout(id, channel, (newData) => {
			setData(newData);
		});

		return subscription.unsubscribe;
	}, [id, channel]);

	return data;
};

interface ITestState {
	testId?: string;
	specimenId?: string;
	state: TestPropMap[keyof TestPropMap];
}

const testInfoInitialValue: ITestState = { state: TestProp.STOPPED, testId: undefined, specimenId: undefined };
export const useTestState = (
	testIdToSubscribe: string | undefined,
	isDisabled: boolean = false
): { testInfo: ITestState; resetEvents: () => void } => {
	const [testInfo, setTestInfo] = useState<ITestState>(testInfoInitialValue);
	const { subscribeToStationsStatuses, isStreamOpen } = useRunningTestInfo();

	useEffect(() => {
		let subscription: { unsubscribe(): void };

		if (!isDisabled) {
			subscription = subscribeToEvents([TriggerType.TRG_TEST], (newEvents) => {
				const testProp = newEvents.find((event) => event.trigger?.triggerTest?.testProp !== undefined)?.trigger
					?.triggerTest?.testProp;
				if (testProp) {
					setTestInfo((current) => ({
						...current,
						state: testProp
					}));
				}
			});
		}

		return () => {
			if (subscription) {
				//subscription.unsubscribe();
			}
		};
	}, [isDisabled]);

	useEffect(() => {
		let unSubscribe: () => void;
		if (!isDisabled && isStreamOpen && testIdToSubscribe) {
			unSubscribe = subscribeToStationsStatuses(testIdToSubscribe, (data) => {
				setTestInfo((current) => {
					if (current.testId === data?.testId && current.specimenId === data?.specimenId) {
						return current;
					} else {
						return {
							testId: data?.testId,
							specimenId: data?.specimenId,
							state: current.state === TestProp.STOPPED && data?.testId ? TestProp.STARTED : current.state
						};
					}
				});
			});
		}

		return () => {
			if (unSubscribe) {
				unSubscribe();
			}
		};
	}, [isDisabled, isStreamOpen, subscribeToStationsStatuses, testIdToSubscribe]);
	const resetEvents = useCallback(() => setTestInfo(testInfoInitialValue), []);

	return { testInfo, resetEvents };
};

export const useSpecimenBreak = (): { isBreak: boolean; resetEvents: () => void } => {
	const [isBreak, setIsBreak] = useState<boolean>(false);

	useEffect(() => {
		const subscription = subscribeToEvents([TriggerType.TRG_SPECIMEN], (newEvents) => {
			const isBreakEventFired = newEvents.some(
				(event) => event.trigger?.triggerSpecimen?.type === SpecimenTriggerType.SPECIMEN_BREAK
			);
			setIsBreak(isBreakEventFired);
		});

		return subscription.unsubscribe;
	}, []);

	const resetEvents = useCallback(() => setIsBreak(false), [setIsBreak]);
	return { isBreak, resetEvents };
};

export const useEStop = (auxDevices?: IAuxiliaryDeviceResponseDto[]) => {
	const [status, setStatus] = useState<boolean>(false);

	useEffect(() => {
		const eStopAuxDevice = auxDevices?.find((aux) => aux.type === AuxiliaryDeviceType.PHYSICAL_E_STOP);
		if (eStopAuxDevice) {
			const subscription = subscribeToEvents([TriggerType.TRG_AUX_DEVICE], (newEvents) => {
				const eStopEvent = newEvents.find((event) => event.trigger?.triggerAux?.auxDevId === eStopAuxDevice?.id);
				if (eStopEvent) {
					setStatus(eStopEvent.trigger?.triggerAux?.auxDevProp === AuxDeviceProp.PROP_ON);
				}
			});

			return subscription.unsubscribe;
		}
	}, [auxDevices]);

	return { status };
};

export const useLimits = (auxDevices?: IAuxiliaryDeviceResponseDto[]) => {
	const [limits, setLimits] = useState<TriggerAuxDevice.AsObject[]>([]);

	useEffect(() => {
		const eStopAuxDevices = auxDevices
			?.filter((aux) => aux.type === AuxiliaryDeviceType.MECHANICAL_LIMITS)
			.map((aux) => aux.id);

		if (eStopAuxDevices?.length) {
			const subscription = subscribeToEvents([EventTriggerTypes.AUX, EventTriggerTypes.MEASURE], (newEvents) => {
				const limitEvents: TriggerAuxDevice.AsObject[] = newEvents
					.filter(
						(event) =>
							event.trigger?.type !== TriggerType.TRG_AUX_DEVICE &&
							event.trigger?.triggerAux !== undefined &&
							eStopAuxDevices?.includes(event.trigger?.triggerAux?.auxDevId)
					)
					.map((event) => event.trigger?.triggerAux as TriggerAuxDevice.AsObject);

				if (limitEvents?.length) {
					setLimits((limits) => {
						return limitEvents.reduce((allLimits, currentLimitEvent) => {
							if (currentLimitEvent?.auxDevProp === AuxDeviceProp.PROP_OFF) {
								return allLimits.filter(({ auxDevId }) => auxDevId !== currentLimitEvent.auxDevId);
							}

							if (!allLimits.find(({ auxDevId }) => auxDevId === currentLimitEvent.auxDevId)) {
								return [...allLimits, currentLimitEvent];
							}

							return allLimits;
						}, limits);
					});
				}
			});

			return subscription.unsubscribe;
		}
	}, [auxDevices]);

	return { limits };
};

export const useIsTestRunning = () => {
	const [isRunning, setIsRunning] = useState(false);

	useEffect(() => {
		const subscription = subscribeToEvents([TriggerType.TRG_TEST], (newEvents) => {
			newEvents.forEach((event) => {
				setIsRunning(event.trigger?.triggerTest?.testProp === TestProp.STARTED);
			});
		});

		return subscription.unsubscribe;
	}, []);

	return isRunning;
};

export const useColumnWidth = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
	const [columnWidth, setColumnWidth] = useState<number>(0);
	useEffect(() => {
		const onWindowResize = () => {
			const gutter = window.innerWidth >= 1920 ? 16 : 12;
			if (ref.current?.offsetWidth) {
				setColumnWidth((ref.current.offsetWidth - gutter) / 24 - gutter);
			}
		};
		onWindowResize();
		window.addEventListener('resize', onWindowResize);

		return () => window.removeEventListener('resize', onWindowResize);
	}, [ref]);

	return columnWidth;
};

export const useDashboardVariables = <T extends any = Record<string, string | number | boolean>>(
	select?: (v: Record<string, string | number | boolean>) => T
): T | undefined => {
	const stationId = useStationId();
	const { subscribeToStationsStatuses } = useSystemStatus();

	const queryClient = useQueryClient();
	const { data: variables } = useQuery({
		queryKey: ['station-variables', stationId],
		queryFn: () => {
			const variables: Record<string, string | number | boolean> = {};

			const unsubscribe = subscribeToStationsStatuses(stationId as string, (data) => {
				const newVariables: Record<string, string | number | boolean> = {};
				// we need to receive all variables just once and then with the other subscription we will get only the changes
				unsubscribe();

				data?.variablesList?.forEach((variable) => {
					switch (variable.type) {
						case VariableType.VARIABLE_TYPE_NUMERIC:
							newVariables[variable.id] = variable.defaultValNum;
							break;
						case VariableType.VARIABLE_TYPE_STRING:
							newVariables[variable.id] = variable.defaultValStr;
							break;
						case VariableType.VARIABLE_TYPE_BOOLEAN:
							newVariables[variable.id] = variable?.defaultValBool;
							break;
					}
				});

				queryClient.setQueryData<Record<string, string | number | boolean>>(['station-variables', stationId], (data) =>
					data ? { ...data, ...newVariables } : newVariables
				);
			});

			return variables;
		},
		enabled: !!stationId,
		select,
		staleTime: 60 * 1000,
		gcTime: 0
	});

	useEffect(() => {
		const { unsubscribe } = subscribeToEvents(
			[TriggerType.TRG_VARIABLE],
			(newEvents) => {
				const newVariables: Record<string, string | number | boolean> = {};
				newEvents.forEach((event) => {
					const variable = event.trigger?.triggerVariable?.variable;
					switch (variable?.type) {
						case VariableType.VARIABLE_TYPE_NUMERIC:
							newVariables[variable.id] = variable.defaultValNum;
							break;
						case VariableType.VARIABLE_TYPE_STRING:
							newVariables[variable.id] = variable.defaultValStr;
							break;
						case VariableType.VARIABLE_TYPE_BOOLEAN:
							newVariables[variable.id] = variable?.defaultValBool;
							break;
					}
				});

				if (!isEmpty(newVariables)) {
					queryClient.setQueryData<Record<string, string | number | boolean>>(
						['station-variables', stationId],
						(data) => (data ? { ...data, ...newVariables } : newVariables)
					);
				}
			},
			true
		);
		return () => {
			unsubscribe();
		};
	}, [queryClient, stationId]);

	return variables;
};

export const useDashboardColumnWidth = () => {
	const ref = useRef<HTMLDivElement | null>(null);

	const [columnWidth, setColumnWidth] = useState<number>(0);
	useEffect(() => {
		const onWindowResize = () => {
			const gutter = window.innerWidth >= 1920 ? 16 : 12;
			if (ref.current?.offsetWidth) {
				setColumnWidth((ref.current.offsetWidth - gutter) / 24 - gutter);
			}
		};
		onWindowResize();
		window.addEventListener('resize', onWindowResize);

		return () => window.removeEventListener('resize', onWindowResize);
	}, []);

	return { columnWidth, gridRef: ref };
};
