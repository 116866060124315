import { Specimen, SpecimenStatus, SpecimenValueType, UnitFormula } from '@tactun/grpc-client';
import { formatDate } from '../../tools';
import { Converter } from '../../types';
import { SpecimenModes } from './specimens.enums';
import {
	SpecimenForm,
	SpecimenFormType,
	SpecimenListItem,
	SpecimenRequestDto,
	SpecimenResponseDto
} from './specimens.types';

export const responseListItemConverter: Converter<SpecimenResponseDto, SpecimenListItem> = (response) => {
	return {
		id: response.uuid,
		name: response.batchId || response.specimenId,
		type: response.specimenType.name,
		material: response.specimenMaterial,
		testName: response.pipelineName,
		test: response.pipelineUuid,
		isBatch: !!response.batchId,
		pipelineUuid: response.pipelineUuid,
		testDate:
			response.items?.[0]?.specimenTest.modifiedAt &&
			response.items?.[0]?.specimenTest.specimenTestStatus !== SpecimenStatus.NOT_TESTED &&
			response.items?.[0]?.specimenTest.specimenTestStatus !== SpecimenStatus.TEST_IN_PROGRESS
				? new Date(response.items[0].specimenTest.modifiedAt).getTime()
				: undefined,
		children: response.items.map((item, index: number) => {
			return {
				...item,
				createdAt: formatDate(new Date(item.createdAt).getTime()),
				specimenTest: {
					...item.specimenTest,
					id: `${response.specimenId} ${index + 1}`,
					createdAt: formatDate(new Date(item.specimenTest.createdAt).getTime())
				}
			};
		})
	};
};

export const responseFormConverter: Converter<SpecimenResponseDto, SpecimenForm> = (input) => {
	let dimensions = {};
	if (input.specimenType?.dimensions) {
		dimensions = Object.keys(input.specimenType.dimensions).reduce(
			(res: Record<string, number>, dimensionKey: string) => {
				res[dimensionKey] = input.specimenType.dimensions[dimensionKey].value;
				return res;
			},
			{}
		);
	}
	return {
		specimenId: input.specimenId,
		mode: input.items.length > 1 ? SpecimenModes.BATCH : SpecimenModes.SINGLE,
		uuid: input.uuid,
		dimensions,
		batchId: input.batchId,
		testId: input.pipelineUuid,
		specimenTypeUuid: input.specimenType.id,
		quantity: input.items.length,
		specimenMaterial: input.specimenMaterial
	};
};

export const formRequestConverter = (input: SpecimenFormType): SpecimenRequestDto => {
	let batchSettings;
	if (input.batchId && input.quantity > 1) {
		batchSettings = {
			quantity: input.quantity,
			batchId: input.batchId
		};
	}
	return {
		uuid: input.uuid || undefined,
		specimenId: input.specimenId,
		dimensionsValues: input.dimensions as Record<string, number>,
		pipelineUuid: input.testId !== null ? input.testId : undefined,
		batchSettings,
		specimenMaterial: input.specimenMaterial !== null ? input.specimenMaterial : undefined,
		specimenTypeUuid: input.specimenTypeUuid
	};
};

export const specimenResponseDtoToSpecimenGRPCConverter: Converter<SpecimenResponseDto, Specimen> = (resp) => {
	const specimen = new Specimen();
	specimen.setId(resp.uuid);
	specimen.setBatchId(resp.batchId);
	specimen.setType(resp.specimenType.id);
	specimen.setMaterial(resp.specimenMaterial);
	specimen.setSpecimenId(resp.specimenId);

	const map = specimen.getParamsMap();
	if (resp.specimenType.dimensions) {
		Object.keys(resp.specimenType.dimensions).forEach((paramName) => {
			map.set(paramName, resp.specimenType.dimensions[paramName].value);
		});
	}

	if (resp.items.length) {
		specimen.setItemId(resp.items[0].uuid);
		specimen.setItemName(`${resp.specimenId} 1`);
	}

	if (resp.specimenType.gaugeLengthEquationUnit) {
		const unitFormula = new UnitFormula();
		unitFormula.setName(resp.specimenType.gaugeLengthEquationUnit.name);
		if (resp.specimenType.gaugeLengthEquationUnit.scale !== null)
			unitFormula.setScale(resp.specimenType.gaugeLengthEquationUnit.scale);
		if (
			resp.specimenType.gaugeLengthEquationUnit.offset !== undefined &&
			resp.specimenType.gaugeLengthEquationUnit.offset !== null
		)
			unitFormula.setOffset(resp.specimenType.gaugeLengthEquationUnit.offset);

		specimen.setGaugeUnit(unitFormula);
	}
	if (resp.specimenType.areaEquationUnit) {
		const unitFormula = new UnitFormula();
		unitFormula.setName(resp.specimenType.areaEquationUnit.name);
		if (resp.specimenType.areaEquationUnit.scale !== null)
			unitFormula.setScale(resp.specimenType.areaEquationUnit.scale);
		if (resp.specimenType.areaEquationUnit.offset !== null && resp.specimenType.areaEquationUnit.offset !== undefined)
			unitFormula.setOffset(resp.specimenType.areaEquationUnit.offset);

		specimen.setAreaUnit(unitFormula);
	}
	//specimen.setTestId();
	//specimen.setTestName();

	if (resp.areaValue !== undefined) {
		specimen.setAreaValType(SpecimenValueType.VALUE);
		specimen.setAreaValue(resp.areaValue);
	}

	if (resp.gaugeLengthValue !== undefined) {
		specimen.setGaugeValType(SpecimenValueType.VALUE);
		specimen.setGaugeValue(resp.gaugeLengthValue);
	}

	return specimen;
};

export const specimenResponseDtoListToSpecimensGRPCConverter: Converter<SpecimenResponseDto[], Specimen[]> = (resp) => {
	return resp.map((r) => specimenResponseDtoToSpecimenGRPCConverter(r));
};
