import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLayout } from '@tactun/ui';
import { IconButton } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { DataTableExpandedRows } from 'primereact/datatable';
import { SpecimenListItem } from '../../specimens.types';
import { SpecimenModes, SpecimenStatusesDto, SpecimenTableActionTypes } from '../../specimens.enums';
import { CrudActions, TableFilters } from '../../../../components';
// import SpecimenTabs from '../SpecimenTabs';
import SpecimenTableRowContent from '../SpecimenTableRowContent';
import SpecimenStatusLabel from '../SpecimenStatusLabel';
import { useFormatDateColumn, useTableConfigs } from '../../../../hooks';

interface SpecimenTableProps {
	onAction: (type: SpecimenTableActionTypes, data?: SpecimenListItem) => void;
	data?: SpecimenListItem[];
	isLoading: boolean;
	specimenMode: SpecimenModes;
}

const SpecimensTable: React.FC<SpecimenTableProps> = ({ data, isLoading, onAction, specimenMode }) => {
	const { t } = useTranslation('specimen');
	const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows>([]);
	const formatDateColumn = useFormatDateColumn();

	const transColumns = useMemo<any[]>(() => {
		const res = [
			{ key: 'type', field: 'type', header: t('Specimen type') },
			{ key: 'material', field: 'material', header: t('Specimen material') },
			...(specimenMode === SpecimenModes.SINGLE
				? [
						{
							body: ({ children }: SpecimenListItem) => (
								<SpecimenStatusLabel status={children?.[0].specimenTest.specimenTestStatus} />
							),
							field: 'status',
							header: t('Specimen status'),
							key: 'status'
						},
						{ field: 'testDate', header: t('Tested date'), body: formatDateColumn, key: 'date' }
				  ]
				: []),
			{ key: 'testName', field: 'testName', header: t('Test') }
		];

		return res;
	}, [t, specimenMode, formatDateColumn]);
	const { filter, selected, columnComponents, handleSelectionChange, handleFilterChange, handleCancel } =
		useTableConfigs<SpecimenListItem>(transColumns, data);

	const createMenuItems = useCallback(
		(data: SpecimenListItem) => {
			const status = data.children?.[0].specimenTest.specimenTestStatus;
			return [
				{
					label: t('Duplicate'),
					icon: 't-icon-duplicate',
					disabled: true,
					command: () => onAction(SpecimenTableActionTypes.DUPLICATE, data)
				},
				{
					label: t('Test Report'),
					disabled: status !== SpecimenStatusesDto.BROKEN && status !== SpecimenStatusesDto.TESTED,
					command: () => onAction(SpecimenTableActionTypes.TEST_REPORT, data)
				},
				{
					label: t('Test Logs'),
					disabled: status !== SpecimenStatusesDto.BROKEN && status !== SpecimenStatusesDto.TESTED,
					command: () => onAction(SpecimenTableActionTypes.TEST_LOGS, data)
				},
				{ label: t('Playback'), disabled: true, command: () => onAction(SpecimenTableActionTypes.PLAYBACK, data) },
				{
					label: t('Delete'),
					icon: 't-icon-delete',
					className: 'red-menu-item',
					disabled: status === SpecimenStatusesDto.TEST_IN_PROGRESS,
					command: () => onAction(SpecimenTableActionTypes.DELETE, data)
				}
			];
		},
		[onAction, t]
	);

	const createChildMenuItems = useCallback(
		() => [{ label: t('View') }, { label: t('Run Test') }, { label: t('Test Report') }, { label: t('Analyze') }],
		[t]
	);

	const handleCreate = useCallback(() => {
		onAction(SpecimenTableActionTypes.CREATE);
	}, [onAction]);

	useEffect(() => {
		setExpandedRows([]);
	}, [transColumns, specimenMode]);

	return (
		<PageLayout info={t('Specimens')}>
			<PageLayout.Header title={t('Specimens')}>
				<CrudActions onCreate={handleCreate} entityLabel={t('Specimen')} />
			</PageLayout.Header>
			<PageLayout.Filters>
				<TableFilters onFilterChange={handleFilterChange} onCancel={handleCancel} selected={selected} />
			</PageLayout.Filters>
			<DataTable
				visibleRowsCount={8}
				value={data}
				loading={isLoading}
				dataKey="id"
				globalFilter={filter}
				showGridlines
				columnResizeMode="fit"
				expandedRows={expandedRows}
				onRowToggle={(e) => setExpandedRows(e.data)}
				rowExpansionTemplate={(item: SpecimenListItem) => (
					<SpecimenTableRowContent specimenTests={item.children} createMenuItems={createChildMenuItems} />
				)}
				selection={selected}
				onSelectionChange={handleSelectionChange}
				selectionMode="multiple"
			>
				{specimenMode === SpecimenModes.BATCH && (
					<DataTable.Column exportable={false} expander align="right" headerStyle={{ width: '3em' }} />
				)}
				<DataTable.Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
				<DataTable.Column
					sortable
					resizeable
					field="name"
					header={specimenMode === SpecimenModes.SINGLE ? t('Specimen ID') : t('Specimen batch ID')}
				/>
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(SpecimenTableActionTypes.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="dahsboard-small"
							fontSize="1.2rem"
							title={t('Dashboard')}
							onClick={() => onAction(SpecimenTableActionTypes.RUN_SPECIMEN_TEST, rowData)}
							disabled={rowData.status !== SpecimenStatusesDto.NOT_TESTED}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				{specimenMode === SpecimenModes.BATCH && (
					<DataTable.Column
						body={(rowData) => <IconButton icon="show" fontSize="1.2rem" title={t('View')} />}
						exportable={false}
						align="right"
						headerStyle={{ width: '3em' }}
					/>
				)}
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
		</PageLayout>
	);
};

export default React.memo(SpecimensTable);
