import { useMemo } from 'react';
import { DataTable, IconButton } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { ITableColumns } from '../../../../types';
import styles from './TestMeasurementsTable.module.scss';
import { TestMeasurementResponseDto } from '../../testMeasurements.types';

interface TestMeasurementsTableProps {
	measurements?: TestMeasurementResponseDto[];
	onEdit: (measurement: TestMeasurementResponseDto) => void;
	isLoading: boolean;
}

const TestMeasurementsTable: React.FC<TestMeasurementsTableProps> = ({ measurements, isLoading, onEdit }) => {
	const { t } = useTranslation('testMeasurements');

	const transColumns = useMemo<ITableColumns[]>(() => {
		return [
			{ field: 'name', header: t('Measurement Name') },
			{ field: 'unit.name', header: t('Unit') },
			{ field: 'sensor.name', header: t('Sensor') },
			{ field: 'inverseReading', header: t('Inverse Reading') }
		];
	}, [t]);

	const columnComponents = transColumns.map((col) => <DataTable.Column resizeable key={col.field} {...col} />);

	return (
		<>
			<div className={styles.title}>{t('Measurements of the current test')}</div>
			<DataTable value={measurements} loading={isLoading} showGridlines columnResizeMode="fit">
				{columnComponents}
				<DataTable.Column
					body={(rowData) => <IconButton icon="edit" className={styles.editBtn} onClick={() => onEdit(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
			</DataTable>
		</>
	);
};

export default TestMeasurementsTable;
