import React, { useCallback } from 'react';
import { useConfigureDevice, useConfiguredStation, useStation, useStationHash } from '../../Stations';
import ConfigureStationBottomBar from '../components/ConfigureStationBottomBar';
import { useConnectionStore } from '../../Connection/connection.store';

interface IConfigureStationBottomBarContainerProps {
	stationId?: string;
}

const ConfigureStationBottomBarContainer: React.FC<IConfigureStationBottomBarContainerProps> = ({ stationId }) => {
	const { connectedDevice } = useConnectionStore();
	const configuredInfo = useConfiguredStation(stationId);
	const { stationDto } = useStation(stationId);
	// Do not calculate hash if configured station is different
	const { hash } = useStationHash(configuredInfo?.stationId === stationId ? stationId : undefined);

	const { configure, isLoading } = useConfigureDevice();

	const handleConfigure = useCallback(() => {
		if (stationId) {
			configure(stationId);
		}
	}, [configure, stationId]);

	const isConfigAvailable =
		connectedDevice !== null && stationId !== undefined && connectedDevice?.model === stationDto?.controllerModel;

	return (
		<>
			{isConfigAvailable && (
				<ConfigureStationBottomBar
					isConfigureDisabled={false}
					isNotUpToDate={hash !== configuredInfo?.hash}
					isConfiguring={isLoading}
					showConfigured={configuredInfo?.stationId === stationId}
					onConfigure={handleConfigure}
				/>
			)}
		</>
	);
};
export default React.memo(ConfigureStationBottomBarContainer);
