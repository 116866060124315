import { Button, Icon, Modal } from '@tactun/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { testSequencerRulesModalId } from '../../../TestSequencerRules';
import { StageTypes, TestPrePostActionTypes } from '../../tests.enums';
import styles from './GlobalConfigurationPanel.module.scss';

interface IGlobalConfigurationPanelProps {
	testId?: string;
	testName?: string;
	onConfigureTestPrePostAction: (actionType: TestPrePostActionTypes) => void;
}

const GlobalConfigurationPanel: React.FC<IGlobalConfigurationPanelProps> = ({
	onConfigureTestPrePostAction,
	testName,
	testId
}) => {
	const { t } = useTranslation('test');

	return (
		<div className={styles.container}>
			<p>{testName}</p>
			<div className={styles.centralContainer}>
				{t('Global Configuration')}
				<Icon
					name="rule"
					data-testid="globalConfigurationRule"
					size={24}
					className={styles.icon}
					onClick={() => Modal.show(testSequencerRulesModalId, { testId: testId, stageType: StageTypes.GLOBAL })}
				/>
				<Icon
					name="variables"
					data-testid="globalConfigurationVariable"
					size={24}
					className={styles.icon}
					onClick={() => onConfigureTestPrePostAction(TestPrePostActionTypes.CONFIGURE_TEST_VARIABLES)}
				/>
				<Icon
					name="measurements"
					data-testid="globalConfigurationMeasurements"
					size={24}
					className={styles.icon}
					onClick={() => onConfigureTestPrePostAction(TestPrePostActionTypes.CONFIGURE_TEST_MEASUREMENTS)}
				/>
			</div>
			<Button
				icon="t-icon-loop"
				className={styles.configureBtn}
				label={t('Create loop')}
				data-testid="globalConfigurationLoop"
				variant="text"
				color="success"
				onClick={() => onConfigureTestPrePostAction(TestPrePostActionTypes.CONFIGURE_TEST_LOOPS)}
			/>
		</div>
	);
};

export default React.memo(GlobalConfigurationPanel);
