import Collapse from 'rc-collapse';
import type { CollapseProps } from 'rc-collapse';
import Icon from '../Icon';
import './TcCollapse.scss';

const TcCollapse = (props: CollapseProps) => {
	return (
		<Collapse
			expandIcon={(props) => (
				<Icon size={14} name={'isActive' in props && props.isActive ? 'arrow-chevron-up' : 'arrow-chevron-down'} />
			)}
			{...props}
		/>
	);
};

export default TcCollapse;
