import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as api from '../widgetsConfiguration.api';
import { WidgetForm, WidgetRequestDto } from '../widgetsConfiguration.types';
import { widgetFormSchema } from '../widgetsConfiguration.schemas';
import WidgetConfiguration from '../components/WidgetConfiguration';
import { useWidget } from '../widgetsConfiguration.hooks';
import { useNumberEnumList, useOnce } from '../../../hooks';
import { deleteEmptyFields } from '../../../tools';
import { formRequestConverter, responseFormConverter } from '../widgetsConfiguration.converters';
import { useStationName } from '../../Stations/stations.hooks';
import { WidgetsTypes } from '../widgetsConfiguration.enums';

const defaultValues = {
	name: '',
	type: undefined,
	stationId: ''
};

const WidgetsContainer: React.FC = () => {
	const navigate = useNavigate();
	const { stationId, widgetId } = useParams();
	const isCreate = !widgetId;
	const { widget: currentWidgetDto } = useWidget(widgetId, stationId);
	const widgetTypesList = useNumberEnumList(WidgetsTypes);
	const { stationName } = useStationName(stationId);

	const form = useForm<WidgetForm>({
		defaultValues: { ...defaultValues, stationId },
		mode: 'onBlur',
		// @ts-ignore TODO: fix typing
		resolver: yupResolver(widgetFormSchema)
	});
	const { handleSubmit, reset } = form;

	const createMutation = useMutation({
		mutationFn: (widget: WidgetRequestDto) => api.createWidget(widget),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { widget: WidgetRequestDto; widgetId: string }) => api.updateWidget(data.widget, data.widgetId),
		onSuccess: () => handleBack(),
		onError: (e: Error) => toast.error(e.message)
	});

	const handleBack = useCallback(() => {
		navigate(`/stations/${stationId}/widgets`);
	}, [navigate, stationId]);

	const handleReset = useCallback(() => {
		if (currentWidgetDto) {
			reset({ ...responseFormConverter(currentWidgetDto), stationId }, { keepDirty: true, keepTouched: true });
		} else {
			reset({ ...defaultValues, stationId }, { keepDirty: true, keepTouched: true });
		}
	}, [currentWidgetDto, reset, stationId]);

	const handleSave = handleSubmit((data) => {
		const filteredData = deleteEmptyFields<WidgetRequestDto>(formRequestConverter(data));

		if (widgetId) {
			updateMutation.mutate({
				widget: filteredData,
				widgetId
			});
		} else {
			createMutation.mutate(filteredData);
		}
	});

	const initTrigger = useOnce(!!currentWidgetDto);

	// Setup data for edit
	useEffect(() => {
		if (initTrigger) {
			handleReset();
		}
	}, [initTrigger, handleReset]);

	return (
		<WidgetConfiguration
			isCreate={isCreate}
			isLoading={createMutation.isPending || updateMutation.isPending}
			form={form}
			onBack={handleBack}
			onSave={handleSave}
			types={widgetTypesList || []}
			stationName={stationName}
		/>
	);
};

export default React.memo(WidgetsContainer);
