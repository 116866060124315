import { Modal, ModalContextProvider } from '@tactun/ui';
import React from 'react';
import { useCallback, useState } from 'react';
import ConnectionBadge from '../components/ConnectionBadge';
import ControllerSelectionModal from '../components/ControllerSelectionModal';
import ControllerSelectionModalContent from '../components/ControllerSelectionModalContent';
import ControllerEditContainer from './ControllerEditContainer';
import ControllerAddManuallyContainer from './ControllerAddManuallyContainer';
import {
	controllerEditModalId,
	controllerSelectionModalId,
	controllerAddManuallyModalId,
	controllerConfigureNetworkModalId,
	controllerLogConfirmationModalId
} from '../connection.const';
import { useDeviceConnection, useDiscoverDevices } from '../connection.hooks';
import { IDevice } from '../connection.types';
import { useManuallyAddedDevicesStore } from '../connection.store';
import ControllerConfigureNetworkContainer from './ControllerConfigureNetworkContainer';
import { ConfirmationModal } from '../../../components';
import { useTranslation } from 'react-i18next';
import { collectDiagnostics } from '../connection.api';

const ConnectionContainer = () => {
	const { t } = useTranslation('connection');
	const [isDiscovered, setIsDiscovered] = useState(false);
	const { devices, discoverDevices, isDiscovering } = useDiscoverDevices();
	const { connect, disconnect, reboot, stopNatsSync, connectedDevice } = useDeviceConnection();
	const { devices: manuallyAddedDevices, removeDevice } = useManuallyAddedDevicesStore();

	const handleToggleConnectionModal = useCallback(() => {
		Modal.show(controllerSelectionModalId, { isOpen: true });
	}, []);

	const handleAddController = useCallback(() => {
		Modal.show(controllerAddManuallyModalId);
	}, []);

	const handleRemoveController = useCallback(
		(device: IDevice) => {
			removeDevice(device);
		},
		[removeDevice]
	);

	const handleToggleConnectionToDevice = useCallback(
		(device: IDevice) => {
			if (connectedDevice === null) {
				connect(device);
			} else {
				stopNatsSync();
				disconnect();
			}
		},
		[connect, disconnect, stopNatsSync, connectedDevice]
	);

	const handleAfterOpen = useCallback(() => {
		if (!isDiscovered) {
			if (devices.length === 0) {
				discoverDevices();
			} else {
				setIsDiscovered(true);
			}
		} else {
			discoverDevices();
			setIsDiscovered(true);
		}
	}, [devices.length, discoverDevices, isDiscovered]);

	const handleRefresh = useCallback(() => {
		discoverDevices();
		setIsDiscovered(true);
	}, [discoverDevices]);

	const handleReboot = useCallback(() => {
		reboot();
	}, [reboot]);

	const handleEdit = useCallback((device: IDevice) => {
		Modal.show(controllerEditModalId, { device });
	}, []);

	const handleConfigureNetwork = useCallback((device: IDevice) => {
		Modal.show(controllerConfigureNetworkModalId);
	}, []);

	const handleSendLogs = useCallback(() => {
		ConfirmationModal.show(controllerLogConfirmationModalId);
	}, []);

	const deviceListWithStatus = React.useMemo(
		() =>
			devices.map((device) => {
				return { ...device, isConnected: device.ip === connectedDevice?.ip };
			}),
		[connectedDevice?.ip, devices]
	);

	const manuallyAddedDevicesListWithStatus = React.useMemo(
		() => manuallyAddedDevices.map((device) => ({ ...device, isConnected: device.ip === connectedDevice?.ip })),
		[connectedDevice?.ip, manuallyAddedDevices]
	);

	return (
		<>
			<ConnectionBadge isConnected={connectedDevice !== null} onToggleConnection={handleToggleConnectionModal} />
			<ControllerSelectionModal onAfterOpen={handleAfterOpen}>
				<ControllerSelectionModalContent
					isLoading={isDiscovering}
					devices={deviceListWithStatus}
					manuallyAddedDevices={manuallyAddedDevicesListWithStatus}
					onAddController={handleAddController}
					onRemoveController={handleRemoveController}
					onConfigureNetwork={handleConfigureNetwork}
					onEdit={handleEdit}
					onRefresh={handleRefresh}
					onReboot={handleReboot}
					onSendLogs={handleSendLogs}
					onToggleConnection={handleToggleConnectionToDevice}
				/>
			</ControllerSelectionModal>
			<ModalContextProvider>
				<ControllerEditContainer />
				<ControllerAddManuallyContainer />
				<ControllerConfigureNetworkContainer />
			</ModalContextProvider>
			<ConfirmationModal
				title={t('Warning')}
				confirmBtnText={t('Confirm')}
				id={controllerLogConfirmationModalId}
				onConfirm={() => {
					Modal.hide(controllerLogConfirmationModalId);
					collectDiagnostics();
				}}
			>
				{t('By Confirming, you consent to collect and download controller logs for diagnosis purposes.')}
			</ConfirmationModal>
		</>
	);
};

export default ConnectionContainer;
