import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { specimenStatusInfo } from '../../specimens.const';
import { SpecimenStatusesDto } from '../../specimens.enums';
import styles from './SpecimenStatusLabel.module.scss';

interface SpecimenStatusLabelProps {
	status?: SpecimenStatusesDto;
}

const SpecimenStatusLabel: FC<SpecimenStatusLabelProps> = ({ status }) => {
	const { t } = useTranslation('specimens');
	const { className, label } = specimenStatusInfo[status || SpecimenStatusesDto.NOT_TESTED];

	return <span className={`${styles.wrapper} ${styles[className]}`}>{t(label)}</span>;
};

export default SpecimenStatusLabel;
