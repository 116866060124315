import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEntitiesAsList } from '../../hooks';
import { IAxisResponseDto } from '../Axes';
import { MeasurementResponseDto } from '../Measurements';
import { CalculationTypes, LimitTypes } from './calculation.enums';
import * as api from './calculation.api';
import { INamedEntity } from '../../types';
import { useUnitsMap } from '../Units';
import { TestMeasurementResponseDto } from '../TestMeasurements';

export const useMeasurementLabelsByType = (type: CalculationTypes | undefined): string[] => {
	const { t } = useTranslation('calculation');

	return useMemo(() => {
		switch (type) {
			case CalculationTypes.ENERGY:
				return [t('X-axis Measurement*'), t('Y-axis Measurement*')];
			case CalculationTypes.YIELD_OFFSET:
			case CalculationTypes.MODULUS_OF_ELASTICITY:
				return [t('Stress Measurement*'), t('Strain Measurement*')];
			case CalculationTypes.MEASUREMENT_AT_YIELD:
				return [t('Measurement*'), t('Stress Measurement*'), t('Strain Measurement*')];
			case CalculationTypes.MEASUREMENT_AT_PEAK_LOAD:
			case CalculationTypes.MEASUREMENT_AT_BREAK:
				return [t('Measurement*'), t('Load Measurement*')];
			case CalculationTypes.MINIMUM:
			case CalculationTypes.MEAN:
			case CalculationTypes.MAXIMUM:
				return [t('Measurement*'), t('')];
			case CalculationTypes.MAXIMUM_LOAD_OVER_WIDTH:
				return [t('Load Measurement*'), t('')];
			default:
				return ['', ''];
		}
	}, [type, t]);
};

export const useUnitOfLimits = (
	measurements: (MeasurementResponseDto | TestMeasurementResponseDto)[] | undefined,
	m1?: string,
	m2?: string,
	m3?: string,
	limitType?: LimitTypes,
	type?: CalculationTypes
): Partial<INamedEntity> | undefined => {
	if (limitType !== undefined && measurements) {
		switch (limitType) {
			case LimitTypes.STRESS_POINT:
				return measurements?.find(
					(m) =>
						(type === CalculationTypes.MEASUREMENT_AT_YIELD && m.id === m2) ||
						(type !== CalculationTypes.MEASUREMENT_AT_YIELD && m.id === m1)
				)?.unit;
			case LimitTypes.STRAIN_POINT:
				return measurements?.find(
					(m) =>
						(type === CalculationTypes.MEASUREMENT_AT_YIELD && m.id === m3) ||
						(type !== CalculationTypes.MEASUREMENT_AT_YIELD && m.id === m2)
				)?.unit;
			default:
				return {
					name: '%'
				};
		}
	}
};

export const useMeasurementAndAxesList = (
	measurements?: (MeasurementResponseDto | TestMeasurementResponseDto)[],
	axes?: IAxisResponseDto[],
	isListNeeded: boolean = false
) => {
	const { t } = useTranslation('calculation');
	const measurementsList = useEntitiesAsList(measurements);
	const axesList = useEntitiesAsList(axes);

	const measurementsAndAxes = useMemo(() => {
		if (isListNeeded) {
			return [
				{
					label: t('Measurements'),
					options: measurementsList.map((m) => ({ ...m, type: 'MEASUREMENT' }))
				},
				{ label: t('Cycles'), options: axesList.map((a) => ({ ...a, type: 'AXES' })) }
			];
		}
		return [];
	}, [isListNeeded, axesList, measurementsList, t]);

	return measurementsAndAxes;
};

export const useCalculations = (stationId?: string) => {
	const units = useUnitsMap();

	const { data, isLoading: isCalculationsLoading } = useQuery({
		queryKey: ['calculations', { stationId }],
		queryFn: () => api.getCalculations(stationId as string),
		enabled: !!stationId
	});

	const calculations = useMemo(() => {
		return data?.map((item) => ({
			...item,
			unitName: item.calculation.unit ? units[item.calculation.unit] : ''
		}));
	}, [data, units]);

	const queryClient = useQueryClient();

	const updateCalculations = () => {
		queryClient.invalidateQueries({ queryKey: ['calculations', { stationId }] });
	};

	return {
		calculations,
		isCalculationsLoading,
		updateCalculations
	};
};
