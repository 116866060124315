import axios from '../../httpCommon';
import { SpecimenModes, SpecimenStatusesDto } from './specimens.enums';
import { SpecimenRequestDto, SpecimenResponseDto } from './specimens.types';

export const getSpecimens = async (specimenMode?: SpecimenModes): Promise<SpecimenResponseDto[]> => {
	const response = await axios.get(`/cassiopeia/specimen/${specimenMode}`, {
		params: { page: 0, size: 400 }
	});
	return response.data.response.data as SpecimenResponseDto[];
};

export const getSpecimen = async (specimenUUID?: string): Promise<SpecimenResponseDto> => {
	const response = await axios.get(`/cassiopeia/specimen/${specimenUUID}`);

	return response.data.response as SpecimenResponseDto;
};

export const createSpecimen = async (specimen: SpecimenRequestDto) => {
	const response = await axios.post('/cassiopeia/specimen', specimen);
	return response.data;
};

export const updateSpecimen = async (specimen: SpecimenRequestDto) => {
	const response = await axios.put('/cassiopeia/specimen', specimen);
	return response.data;
};

export const updateSpecimenStatus = async (specimen: {
	itemUuid: string;
	status: SpecimenStatusesDto;
	testUuid?: string;
}) => {
	const response = await axios.put('/cassiopeia/specimen/status', specimen);
	return response.data;
};

export const getSpecimensByType = async (specimenType: string): Promise<SpecimenResponseDto[]> => {
	const response = await axios.get(`/cassiopeia/specimen/specimen-type/${specimenType}`, {
		params: { page: 0, size: 400 }
	});
	return response.data.response.data as SpecimenResponseDto[];
};

export const checkId = async (prop: string, uuid?: string): Promise<boolean> => {
	const response = await axios.post('/cassiopeia/specimen/specimenIdIsUnique', { uuid, prop });
	return response.data.response.isUnique === true;
};

export const checkBatchId = async (prop: string, uuid?: string): Promise<boolean> => {
	const response = await axios.post('/cassiopeia/specimen/specimenBatchIdIsUnique', { uuid, prop });
	return response.data.response.isUnique === true;
};
