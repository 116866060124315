import React, { Fragment, useEffect } from 'react';
import { useMeasurements } from '../../Measurements/measurements.hooks';
import { ControlAlgorithmsAdditional, ControlAlgorithmsBase } from '../controlAlgorithms.enums';
import PidAlgorithmForm from '../components/PidAlgorithmForm';
import AsymmetricPidAlgForm from '../components/AsymmetricPidAlgForm';
import AdaptiveAmplitudeAlgForm from '../components/AdaptiveAmplitudeAlgForm';
import FeedForwardAlgForm from '../components/FeedForwardAlgForm';
import DitherAlgForm from '../components/DitherAlgForm';
import { Form, useIsChanged } from '@tactun/ui';
import { useFormContext } from 'react-hook-form';
import ControlAlgorithmBaseForm from '../components/ControlAlgorithmBaseForm';
import { useTestMeasurements } from '../../TestMeasurements/testMeasurements.hooks';
import { calculateDitherRawValue } from '../../ControlChannels';
import { ControlAlgorithmFormErrors } from '../controlAlgorithms.types';
import { tError } from '../../../tools/logger';

export interface IControlAlgorithmsContainerProps {
	formPrefix: string;
	stationId?: string;
	testId?: string;
	ccId?: string;
	isControlWithCurrent: boolean;
	isRunning?: boolean;
	isDisabled?: boolean;
	isAxisBasedOnAnalogOut: boolean;
	isOpenLoopAvailable: boolean;
	isControlStage?: boolean;
	errors?: ControlAlgorithmFormErrors;
}

const ControlAlgorithmsContainer: React.FC<IControlAlgorithmsContainerProps> = ({
	formPrefix,
	stationId,
	testId,
	ccId,
	isRunning,
	isControlWithCurrent,
	isDisabled = false,
	isAxisBasedOnAnalogOut,
	isOpenLoopAvailable,
	isControlStage = false,
	errors
}) => {
	const { measurements } = useMeasurements(stationId);
	const { testMeasurements } = useTestMeasurements(testId);

	const { watch, setValue } = useFormContext();

	const baseAlgorithm = watch(`${formPrefix}.baseAlgorithm`);
	const isBaseAlgorithmChanged = useIsChanged(baseAlgorithm);
	const additionalAlgorithms = watch(`${formPrefix}.additionalAlgorithms`);
	const isDitherEnabled = watch(`${formPrefix}.isDitherEnabled`);
	const amplitude = watch(`${formPrefix}.dither.amplitude`);

	// Reset form if base algorithm changed
	useEffect(() => {
		if (isBaseAlgorithmChanged) {
			if (baseAlgorithm === ControlAlgorithmsBase.PID) {
				setValue(`${formPrefix}.asymmetricPid`, null);
			}
			if (baseAlgorithm === ControlAlgorithmsBase.ASYMMETRIC_PID) {
				setValue(`${formPrefix}.pid`, null);
			}
			if (baseAlgorithm === ControlAlgorithmsBase.OPEN_LOOP) {
				setValue(`${formPrefix}.pid`, null);
				setValue(`${formPrefix}.asymmetricPid`, null);
			}
		}
	}, [isBaseAlgorithmChanged, formPrefix, setValue, baseAlgorithm]);

	// Disable dither if not available
	useEffect(() => {
		if (!isAxisBasedOnAnalogOut) {
			setValue(`${formPrefix}.isDitherEnabled`, false);
		}
	}, [formPrefix, isAxisBasedOnAnalogOut, setValue]);

	// Reset dither if disabled
	useEffect(() => {
		if (!isDitherEnabled) {
			setValue(`${formPrefix}.dither`, null);
		}
	}, [isDitherEnabled, formPrefix, setValue]);

	// Set default base algorithm if open loop is not available
	useEffect(() => {
		if (!isOpenLoopAvailable && baseAlgorithm === ControlAlgorithmsBase.OPEN_LOOP) {
			setValue(`${formPrefix}.baseAlgorithm`, ControlAlgorithmsBase.PID);
		}
	}, [isOpenLoopAvailable, formPrefix, setValue, baseAlgorithm]);

	// Unset feed forward if open loop is selected
	useEffect(() => {
		const additionalAlgorithms = watch(`${formPrefix}.additionalAlgorithms`) as ControlAlgorithmsAdditional[];
		const isFeedForwardSelected = additionalAlgorithms?.includes(ControlAlgorithmsAdditional.FEED_FORWARD);
		if (baseAlgorithm === ControlAlgorithmsBase.OPEN_LOOP && isFeedForwardSelected) {
			setValue(
				`${formPrefix}.additionalAlgorithms`,
				additionalAlgorithms.filter((alg) => alg !== ControlAlgorithmsAdditional.FEED_FORWARD)
			);
		}
	}, [baseAlgorithm, formPrefix, setValue, watch]);

	// Calculate and set dither raw value
	useEffect(() => {
		if (amplitude && ccId) {
			calculateDitherRawValue(ccId, amplitude)
				.then((value) => {
					setValue(`${formPrefix}.dither.amplitudeRaw`, value, {
						shouldValidate: true,
						shouldDirty: true
					});
				})
				.catch((e) => {
					tError(e);
				});
		}
	}, [amplitude, ccId, formPrefix, setValue]);

	return (
		<>
			<ControlAlgorithmBaseForm
				formPrefix={formPrefix}
				isAxisBasedOnAnalogOut={isAxisBasedOnAnalogOut}
				isOpenLoopAvailable={isOpenLoopAvailable}
				disabled={isDisabled || !!isRunning}
				isControlStage={isControlStage}
				errors={errors}
			/>
			{baseAlgorithm === ControlAlgorithmsBase.PID && (
				<>
					<Form.Divider className="formDivider" />
					<PidAlgorithmForm formPrefix={formPrefix} errors={errors?.pid} disabled={isDisabled} />
				</>
			)}
			{baseAlgorithm === ControlAlgorithmsBase.ASYMMETRIC_PID && (
				<>
					<Form.Divider className="formDivider" />
					<AsymmetricPidAlgForm formPrefix={formPrefix} errors={errors?.asymmetricPid} disabled={isDisabled} />
				</>
			)}
			{additionalAlgorithms?.map((algorithm: ControlAlgorithmsAdditional) => {
				switch (algorithm) {
					case ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE:
						return (
							<Fragment key={algorithm}>
								<Form.Divider className="formDivider" />
								<AdaptiveAmplitudeAlgForm
									formPrefix={formPrefix}
									measurementsList={measurements || testMeasurements}
									isWithMean={false}
									errors={errors?.adaptiveAmplitude}
									disabled={!!isRunning}
								/>
							</Fragment>
						);
					case ControlAlgorithmsAdditional.ADAPTIVE_AMPLITUDE_MEAN:
						return (
							<Fragment key={algorithm}>
								<Form.Divider className="formDivider" />
								<AdaptiveAmplitudeAlgForm
									formPrefix={formPrefix}
									measurementsList={measurements || testMeasurements}
									isWithMean
									disabled={!!isRunning}
									errors={errors?.adaptiveAmplitude}
								/>
							</Fragment>
						);
					case ControlAlgorithmsAdditional.FEED_FORWARD:
						return (
							<Fragment key={algorithm}>
								<Form.Divider className="formDivider" />
								<FeedForwardAlgForm formPrefix={formPrefix} errors={errors?.feedForward} />
							</Fragment>
						);
					default:
						return null;
				}
			})}
			{isDitherEnabled && (
				<>
					<Form.Divider className="formDivider" />
					<DitherAlgForm formPrefix={formPrefix} errors={errors?.dither} isControlWithCurrent={isControlWithCurrent} />
				</>
			)}
		</>
	);
};

export default ControlAlgorithmsContainer;
