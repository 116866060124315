import React, { useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Icon } from '@tactun/ui';
import { Menu } from 'primereact/menu';
import { useTranslation } from 'react-i18next';
import WidgetContext from './widgetContext';
import { widgetDefinitions } from '../../dashboard.widgetDefinitions';
import { uuid } from '../../../../tools';
import { tInfo } from '../../../../tools/logger';
import styles from './Widget.module.scss';

interface WidgetHeaderProps {
	children?: React.ReactNode;
	disableMinimize?: boolean;
	disablePopOut?: boolean;
	disableRemove?: boolean;
	customActions?: { label: string; command: () => void; icon?: string }[];
}

const WidgetHeader: React.FC<WidgetHeaderProps> = (props) => {
	const { children, disableMinimize, disableRemove, customActions } = props;
	const { t } = useTranslation('dashboard');
	const widgetContent = useContext(WidgetContext);

	const menu = useRef<Menu>(null);

	if (!widgetContent.widget) {
		tInfo('WidgetHeader component must be used inside Widget Component');
	}

	const { id, widget, updateWidget, removeWidget, addWidget } = widgetContent;

	const contextMenuItems = useMemo(() => {
		const actions = [];

		if (!disableMinimize) {
			actions.push({
				label: widget?.minimized ? t('Maximize') : t('Minimize'),
				icon: widget?.minimized ? 't-icon-maximize' : 't-icon-minimize',
				command: () => updateWidget(id, { minimized: !widget?.minimized })
			});
		}
		if (customActions) {
			actions.push(...customActions);
		}
		const currentWidgetDef = widget?.type !== undefined ? widgetDefinitions[widget.type] : null;
		if (currentWidgetDef?.maxPerDashboard && currentWidgetDef.maxPerDashboard > 1) {
			actions.push({
				label: t('Duplicate'),
				icon: 't-icon-duplicate',
				command: () => {
					if (widget) {
						const widgetCopy = { ...widget, metadata: { ...widget.metadata, id: uuid() } };
						addWidget(widgetCopy);
					}
				}
			});
		}

		if (!disableRemove) {
			actions.push({
				label: t('Remove'),
				icon: 't-icon-zoom-remove',
				command: () => removeWidget(id)
			});
		}
		return actions;
	}, [disableMinimize, customActions, t, disableRemove, widget, updateWidget, id, addWidget, removeWidget]);

	return (
		<div className={classNames(styles.widgetHeader, 'widget-header')}>
			<div className={styles.widgetHeaderTitle}>{children}</div>
			{!!contextMenuItems.length && (
				<Icon name="properties-horizontal" className={styles.contextButton} onClick={(e) => menu.current?.toggle(e)} />
			)}
			<Menu model={contextMenuItems} popup ref={menu} className={styles.menu} />
		</div>
	);
};

export default React.memo(WidgetHeader);
