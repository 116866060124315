import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@tactun/ui';
import { DataTable, KebabMenu } from '@tactun/ui';
import { CrudActions } from '../../../../components';
import { ITableColumns } from '../../../../types';
import { DashboardConfigurationActions } from '../../testDashboardConfiguration.enums';
import styles from './ReadoutTable.module.scss';
import { ReadoutListItem } from '../../testDashboardConfiguration.types';

interface IReadoutTableProps {
	onAction: (type: DashboardConfigurationActions, data?: ReadoutListItem) => void;
	data?: ReadoutListItem[];
	isLoading: boolean;
}

const ReadoutTable: React.FC<IReadoutTableProps> = ({ data, isLoading, onAction }) => {
	const { t } = useTranslation('dashboardConfiguration');

	const transColumns = useMemo<ITableColumns[]>(() => {
		return [
			{ field: 'dataChannel', header: t('Data Channel') },
			{ field: 'readoutType', header: t('Readout Type') }
		];
	}, [t]);

	const createMenuItems = useCallback(
		(data: ReadoutListItem) => [
			{
				label: t('Duplicate'),
				disabled: true,
				icon: 't-icon-duplicate',
				command: () => onAction(DashboardConfigurationActions.DUPLICATE, data)
			},
			{
				label: t('Delete'),
				icon: 't-icon-delete',
				className: 'red-menu-item',
				command: () => onAction(DashboardConfigurationActions.DELETE, data)
			}
		],
		[onAction, t]
	);

	const handleCreate = useCallback(() => {
		onAction(DashboardConfigurationActions.CREATE);
	}, [onAction]);

	const columnComponents = transColumns.map((col) => <DataTable.Column sortable resizeable key={col.field} {...col} />);

	return (
		<>
			<DataTable value={data} loading={isLoading} dataKey="id" showGridlines columnResizeMode="fit">
				{columnComponents}
				<DataTable.Column
					body={(rowData) => (
						<IconButton
							icon="edit"
							fontSize="1.2rem"
							title={t('Edit')}
							onClick={() => onAction(DashboardConfigurationActions.EDIT, rowData)}
						/>
					)}
					exportable={false}
					align="right"
					headerStyle={{ width: '3em' }}
				/>
				<DataTable.Column
					body={(rowData) => <KebabMenu model={createMenuItems(rowData)} />}
					exportable={false}
					align="right"
					headerStyle={{ width: '1em' }}
				/>
			</DataTable>
			<div className={styles.tableFooter}>
				<CrudActions onCreate={handleCreate} entityLabel={t('Readout')} />
			</div>
		</>
	);
};

export default React.memo(ReadoutTable);
