import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Modal } from '@tactun/ui';
import { WidgetSettingsStates } from '../../../Dashboard/dashboard.enums';
import AddWidgetSettings from '../../../Dashboard/components/AddWidgetSettings';
import { graphDefaultValues } from '../../../TestDashboardConfiguration';
import { WidgetSettingsModalProps } from '../../../Dashboard/dashboard.types';
import GraphAddExisting from './GraphAddExisting';
import GraphForm from '../../../TestDashboardConfiguration/components/GraphForm';
import { graphFormToStateConverter } from '../../graphWidget.converters';
import { SimplifiedMeasurement, useMeasurements } from '../../../Measurements';
import { useAxes } from '../../../Axes/axes.hooks';
import { dataChannelTreeToChannels } from '../../../Dashboard/dashboard.converters';
import { graphSettingsSchema } from '../../graphWidget.schemas';
import { useStationId } from '../../../Stations';
import addGraphImage from '../../assets/add-graph.svg';
import styles from './GraphSettingsModal.module.scss';

export const GraphSettingsModal: FC<WidgetSettingsModalProps> = (props) => {
	const { onCancel, onSave, isType } = props;
	const { t } = useTranslation('dashboard');
	const { testId } = useParams();
	const stationId = useStationId();
	const { measurements = [] } = useMeasurements(stationId);
	const { axes = [] } = useAxes(stationId);
	// const initialState = testId ? WidgetSettingsStates.INITIAL : WidgetSettingsStates.CREATE_NEW;
	const [state, setState] = useState<WidgetSettingsStates>(WidgetSettingsStates.CREATE_NEW);
	const form = useForm({
		mode: 'onBlur',
		defaultValues: { ...graphDefaultValues, testId, isType },
		resolver: yupResolver(graphSettingsSchema)
	});
	const { handleSubmit, setValue } = form;

	const simplifiedMeasurements = useMemo<Record<string, SimplifiedMeasurement>>(() => {
		return measurements.reduce<Record<string, SimplifiedMeasurement>>((result, { id, unit }) => {
			result[id] = {
				id,
				quantityId: unit?.quantity,
				unitId: unit?.id
			};
			return result;
		}, {});
	}, [measurements]);

	// Set simplifiedMeasurements for validations
	useEffect(() => {
		if (simplifiedMeasurements) {
			setValue('simplifiedMeasurements', simplifiedMeasurements);
		}
	}, [setValue, simplifiedMeasurements]);

	const handleSave = useCallback(() => {
		handleSubmit((data) => {
			const dcX = dataChannelTreeToChannels(data.dcX, measurements, axes);
			const dcY1 = dataChannelTreeToChannels(data.dcY1, measurements, axes);
			const dcY2 = dataChannelTreeToChannels(data.dcY2, measurements, axes);
			const graphState = graphFormToStateConverter({ ...data, dcX, dcY1, dcY2 });

			onSave(graphState.meta, graphState.settings);
		})();
	}, [handleSubmit, onSave, measurements, axes]);

	const onAddExisting = () => {
		setState(WidgetSettingsStates.ADD_EXISTING);
	};
	const onCreateNew = () => {
		setState(WidgetSettingsStates.CREATE_NEW);
	};

	return (
		<>
			<Modal.Header>
				{state === WidgetSettingsStates.INITIAL && t('Graph widget')}
				{state === WidgetSettingsStates.ADD_EXISTING && t('Add existing graph')}
				{state === WidgetSettingsStates.CREATE_NEW && t('Create new graph')}
			</Modal.Header>
			<Modal.Content>
				<div className={styles.contentWrapper}>
					{state === WidgetSettingsStates.INITIAL && (
						<AddWidgetSettings
							resource="Graph"
							onAddExisting={onAddExisting}
							onAddNew={onCreateNew}
							image={addGraphImage}
						/>
					)}
					{state === WidgetSettingsStates.CREATE_NEW && (
						<GraphForm measurements={measurements} axes={axes} form={form} isAdvanced isType={isType} />
					)}
					{state === WidgetSettingsStates.ADD_EXISTING && <GraphAddExisting form={form} />}
				</div>
			</Modal.Content>
			{state !== WidgetSettingsStates.INITIAL && (
				<Modal.Footer>
					{/*{initialState === WidgetSettingsStates.INITIAL ? (*/}
					{/*	<Button onClick={() => setState(WidgetSettingsStates.INITIAL)} color="success" variant="text">*/}
					{/*		{t('Back')}*/}
					{/*	</Button>*/}
					{/*) : (*/}
					<Button onClick={onCancel} color="success" variant="text">
						{t('Cancel')}
					</Button>
					{/*)}*/}
					<Button onClick={handleSave} color="secondary" variant="contained">
						{t('Save')}
					</Button>
				</Modal.Footer>
			)}
		</>
	);
};

export default GraphSettingsModal;
