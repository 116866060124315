import { number, object, ref, string } from 'yup';
import { emptyStringToNull } from '../../tools/yupHelpers';
import { controlAlgBaseSchema } from '../ControlAlgorithms/controlAlgorithms.schemas';

export const offsetCalibrationSchema = object({
	axisId: string().required('Axis is a required field'),
	offset: number().transform(emptyStringToNull).required('Offset is a required field')
});

export const offsetCalibrationConfigurationSchema = object({
	limit: number().transform(emptyStringToNull).required('Limit is a required field'),
	controlChannelId: string().required('Control is a required field'),
	controlAlgorithmForm: controlAlgBaseSchema.optional(),
	allowedVariation: number().transform(emptyStringToNull).positive().required('Allowed variation is a required field'),
	delay: number()
		.transform(emptyStringToNull)
		.positive()
		.min(100, ({ min }) => `The delay must be equal to or more than the ${min}`)
		.required('Delay is a required field'),
	step: number().transform(emptyStringToNull).positive().required('Step is a required field'),
	maximumOffset: number()
		.transform(emptyStringToNull)
		.positive()
		.max(ref('limit'), ({ max }) => `The maximum offset must be equal to or less than the channel limit [${max}]`)
		.required('Maximum offset is a required field')
});
