import React, { useMemo } from 'react';
import { PageLayout, Button, BottomBar, InfoBadge, InfoBadgeIcons } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { calibrationFormId } from '../../calibrations.const';

interface CalibrationPageProps {
	isCreate: boolean;
	isLoading: boolean;
	sensorName: string;
	onBack: () => void;
	stationName: string;
	children: React.ReactNode;
}

const CalibrationPage: React.FC<CalibrationPageProps> = ({
	isCreate,
	stationName,
	isLoading,
	sensorName,
	children,
	onBack
}) => {
	const { t } = useTranslation('calibrations');

	const title = useMemo<string>(() => {
		return isCreate ? t(`Create ${sensorName} Calibration`) : t(`Edit ${sensorName} Calibration`);
	}, [isCreate, sensorName, t]);

	return (
		<>
			<PageLayout info={stationName}>
				<PageLayout.Header title={title} onBack={onBack} />
				{children}
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button label={t('Cancel')} data-testid="cancelBtn" variant="contained" color="success" onClick={onBack} />
				<Button
					label={t('Save')}
					data-testid="saveBtn"
					variant="contained"
					color="secondary"
					type="submit"
					form={calibrationFormId}
				/>
			</BottomBar>
		</>
	);
};

export default React.memo(CalibrationPage);
