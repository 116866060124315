import React from 'react';
import { DataTable, EDataTableThemes } from '@tactun/ui';
import styles from './StageInformation.module.scss';
import { IStageInfo } from '../../stageInformation.types';
import WidgetHeader from '../../../Dashboard/components/Widget/WidgetHeader';
import WidgetContent from '../../../Dashboard/components/Widget/WidgetContent';

const data: IStageInfo[] = [
	{
		id: 1,
		axis: 'Axis 1',
		circle: '2 / 7'
	},
	{
		id: 2,
		axis: 'Axis 2',
		circle: '2 / 7'
	},
	{
		id: 3,
		axis: 'Axis 3',
		circle: '2 / 7'
	}
];

const StageInformation: React.FC = () => {
	return (
		<>
			<WidgetHeader>Stage Information</WidgetHeader>
			<WidgetContent className={styles.widgetContainer}>
				<div className={styles.header}>
					<span>Loop iteration </span>
					<span className={styles.headerValue}>2 / 8</span>
				</div>
				<DataTable className={styles.tableContainer} value={data} dataKey="id" theme={EDataTableThemes.WIDGET}>
					<DataTable.Column header="Axis name" field="axis" />
					<DataTable.Column header="Current cycle" field="circle" />
				</DataTable>
			</WidgetContent>
		</>
	);
};

export default React.memo(StageInformation);
