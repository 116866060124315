import { boolean, lazy, mixed, number, object, ref, string } from 'yup';
import { emptyStringToNull } from '../../tools/yupHelpers';
import {
	WaveformDefinitionMode,
	WaveformDirection,
	WaveformEndChannelType,
	WaveformRampDirection,
	WaveformType,
	WaveformValueType
} from './waveforms.enums';

export const waveformBaseSchema = object({
	type: mixed<WaveformType>().required('Setpoint is a required field')
});

export const waveformDwellSchema = object({
	durationType: mixed<WaveformValueType>().required('Duration is a required field'),
	durationValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.positive('Only positive number is accepted')
		.when('durationType', {
			is: WaveformValueType.VALUE,
			then: (schema) => schema.required('Duration constant value is a required field')
		}),
	durationVariableId: string()
		.nullable()
		.when('durationType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Duration variable is a required field')
		})
});

export const waveformConstantSchema = object({
	durationType: mixed<WaveformValueType>().required('Duration is a required field'),
	durationValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.positive('Only positive number is accepted')
		.when('durationType', {
			is: WaveformValueType.VALUE,
			then: (schema) => schema.required('Duration value is a required field')
		}),
	durationVariableId: string()
		.nullable()
		.when('durationType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Duration variable is a required field')
		}),
	valueType: mixed<WaveformValueType>().required('Value is a required field'),
	value: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('valueType', {
			is: WaveformValueType.VALUE,
			then: (schema) => schema.required('Value is a required field')
		}),
	variableId: string()
		.nullable()
		.when('valueType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Variable is a required field')
		})
});

export const waveformRampSchema = object({
	defMode: mixed<WaveformDefinitionMode>().required('Definition mode is a required field'),
	rampDirection: mixed<WaveformRampDirection>().required('Movement Direction is a required field'),
	directionRel: boolean().optional(),
	rateType: mixed<WaveformValueType>().required('Rate is a required field'),
	rateValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.positive('Only positive number is accepted')
		.when('rateType', {
			is: WaveformValueType.VALUE,
			then: (schema) => schema.required('Rate value is a required field')
		})
		.when('rateLimit', {
			is: (rateLimit: number | null) => rateLimit !== null && rateLimit !== undefined,
			then: (schema) => schema.max(ref('rateLimit')),
			otherwise: (schema) => schema.max(Number.MAX_VALUE)
		}),
	rateVariableId: string()
		.nullable()
		.when('rateType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Rate variable is a required field')
		}),
	endChannel: mixed<WaveformEndChannelType | string>().required('End channel value is a required field'),
	endChannelValueType: mixed<WaveformValueType>().required('Value type is a required field'),
	endChannelValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when(['endChannelValueType', 'defMode'], {
			is: WaveformValueType.VALUE,
			then: (schema) => schema.required('Value is a required field')
		})
		.when(['endChannel'], {
			is: WaveformEndChannelType.TIME,
			then: (schema) => schema.positive('Only positive number is accepted')
		}),
	endChannelVariableId: string()
		.nullable()
		.when(['endChannelValueType'], {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Variable is a required field')
		}),
	isInvertEndCondition: boolean().required()
});

export const waveformSinewaveForm = object({
	defMode: mixed<WaveformDefinitionMode>().required('Definition mode is a required field'),
	amplitudeType: mixed<WaveformValueType>().required('Amplitude is a required field'),
	amplitudeValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.positive('Only positive number is accepted')
		.when('amplitudeType', {
			is: WaveformValueType.VALUE,
			then: (schema) =>
				schema
					.required('Amplitude value is a required field')
					.test('amplitudeValueCheck', 'Should not exceed Rate limit of the Control', function (value, context) {
						const { rateLimit, frequency } = context.parent;
						if (rateLimit !== null && frequency !== null && frequency !== undefined && value) {
							return value <= rateLimit / (2 * Math.PI * frequency);
						}
						return true;
					})
		}),
	amplitudeVariableId: string()
		.nullable()
		.when('amplitudeType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Amplitude variable is a required field')
		}),
	meanType: mixed<WaveformValueType>().required('Mean is a required field'),
	meanValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('meanType', {
			is: WaveformValueType.VALUE,
			then: (schema) => schema.required('Mean value is a required field')
		}),
	meanVariableId: string()
		.nullable()
		.when('meanType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Mean variable is a required field')
		}),
	frequency: number()
		.transform(emptyStringToNull)
		.positive('Only positive number is accepted')
		.required('Frequency is a required field')
		.test('rateLimitCheck', 'Should not exceed Rate limit of the Control', function (value, context) {
			const { amplitudeType, rateLimit, amplitudeValue } = context.parent;
			if (
				amplitudeType === WaveformValueType.VALUE &&
				rateLimit !== null &&
				amplitudeValue !== null &&
				amplitudeValue !== undefined
			) {
				return value <= rateLimit / (2 * Math.PI * amplitudeValue);
			}
			return true;
		})
		.max(1000, ({ max }) => `Frequency must be less than or equal to ${max}`),
	startingPhase: number().transform(emptyStringToNull).min(0).max(360).integer().nullable(),
	endingPhase: number().transform(emptyStringToNull).min(0).max(360).integer().nullable(),
	numberOfCycles: number()
		.transform(emptyStringToNull)
		.min(1, ({ min }) => `No of cycles must be greater than or equal to ${min}`)
		.max(4294967291)
		.integer('No of cycles must be an integer')
		.nullable()
		.required('No of cycles is a required field')
});

export const waveformDuoRampSchema = object({
	defMode: mixed<WaveformDefinitionMode>().required('Definition mode is a required field'),
	direction: mixed<WaveformDirection>().required('Direction is a required field'),
	upRateType: mixed<WaveformValueType>().required('Up rate is a required field'),
	upRateValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.positive('Only positive number is accepted')
		.when('upRateType', {
			is: WaveformValueType.VALUE,
			then: (schema) => schema.required('Up Rate value is a required field')
		})
		.when('rateLimit', {
			is: (rateLimit: number | null) => rateLimit !== null,
			then: (schema) => schema.max(ref('rateLimit')),
			otherwise: (schema) => schema.max(Number.MAX_VALUE)
		}),
	upRateVariableId: string()
		.nullable()
		.when('upRateType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Variable is a required field')
		}),
	downRateType: mixed<WaveformValueType>().required('Down rate is a required field'),
	downRateValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.positive('Only positive number is accepted')
		.when('downRateType', {
			is: WaveformValueType.VALUE,
			then: (schema) => schema.required('Down rate value is a required field')
		})
		.when('rateLimit', {
			is: (rateLimit: number | null) => rateLimit !== null,
			then: (schema) => schema.max(ref('rateLimit')),
			otherwise: (schema) => schema.max(Number.MAX_VALUE)
		}),
	downRateVariableId: string()
		.nullable()
		.when('downRateType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Variable is a required field')
		}),
	endChannel: mixed<WaveformEndChannelType | string>().required('End channel value is a required field'),
	upEndChannelValueType: mixed<WaveformValueType>().required('Upper end value is a required field'),
	upEndChannelValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('upEndChannelValueType', {
			is: WaveformValueType.VALUE,
			then: (schema) =>
				schema
					.required('Upper End channel value is a required field')
					.when(['lowerEndChannelValueType', 'lowerEndChannelValue'], {
						is: (lowerEndChannelValueType: WaveformValueType, lowerEndChannelValue: number | undefined) =>
							lowerEndChannelValueType === WaveformValueType.VALUE && typeof lowerEndChannelValue === 'number',
						then: (schema) => schema.moreThan(ref('lowerEndChannelValue'))
					})
		}),
	upEndChannelVariableId: string()
		.nullable()
		.when('upEndChannelValueType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Upper End channel variable is a required field')
		}),
	lowerEndChannelValueType: mixed<WaveformValueType>().required('Lower end value is a required field'),
	lowerEndChannelValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('lowerEndChannelValueType', {
			is: WaveformValueType.VALUE,
			then: (schema) =>
				schema
					.required('Lower End channel value is a required field')
					.when(['upEndChannelValueType', 'upEndChannelValue'], {
						is: (upEndChannelValueType: WaveformValueType, upEndChannelValue: number | undefined) =>
							upEndChannelValueType === WaveformValueType.VALUE && typeof upEndChannelValue === 'number',
						then: (schema) => schema.lessThan(ref('upEndChannelValue'))
					})
		}),
	lowerEndChannelVariableId: string()
		.nullable()
		.when('lowerEndChannelValueType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Lower End channel variable is a required field')
		}),
	isInvertEndCondition: boolean().required(),
	numberOfCycles: number()
		.transform(emptyStringToNull)
		.min(1, ({ min }) => `No of cycles must be greater than or equal to ${min}`)
		.max(4294967291)
		.integer()
		.positive('Only positive number is accepted')
		.nullable()
		.required('Number of cycles is a required field')
});

export const waveformHaversineSchema = object({
	defMode: mixed<WaveformDefinitionMode>().required('Definition mode is a required field'),
	direction: mixed<WaveformDirection>().required('Direction is a required field'),
	durationType: mixed<WaveformValueType>().required('Duration is a required field'),
	durationValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('durationType', {
			is: WaveformValueType.VALUE,
			then: (schema) =>
				schema
					.required('Duration value is a required field')
					.positive('Only positive number is accepted')
					.test('durationValueCheck', 'Should not exceed Rate limit of the Control', function (dur, context) {
						const { amplitudeType, rateLimit, amplitudeValue } = context.parent;
						if (
							amplitudeType === WaveformValueType.VALUE &&
							rateLimit !== null &&
							amplitudeValue !== null &&
							amplitudeValue !== undefined &&
							dur
						) {
							const allowedMinValue = (Math.PI * amplitudeValue) / rateLimit;

							return dur < allowedMinValue
								? this.createError({
										message: ` The Duration value should be greather or equal to “${
											Math.floor(allowedMinValue * 100) / 100
										}”.`
								  })
								: true;
						}
						return true;
					})
		}),
	durationVariableId: string()
		.nullable()
		.when('durationType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Duration variable is a required field')
		}),
	amplitudeType: mixed<WaveformValueType>().required('Amplitude is a required field'),
	amplitudeValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('amplitudeType', {
			is: WaveformValueType.VALUE,
			then: (schema) =>
				schema
					.required('Amplitude Value is a required field')
					.positive('Only positive number is accepted')
					.test('amplitudeValueCheck', 'Should not exceed Rate limit of the Control', function (ampl, context) {
						const { rateLimit, durationType, durationValue } = context.parent;
						if (
							durationType === WaveformValueType.VALUE &&
							rateLimit !== null &&
							durationValue !== null &&
							durationValue !== undefined &&
							ampl
						) {
							const allowedMaxValue = (durationValue * rateLimit) / Math.PI;

							return ampl > allowedMaxValue
								? this.createError({
										message: `The Amplitude value should be in the “0-${Math.floor(allowedMaxValue * 100) / 100}” range`
								  })
								: true;
						}
						return true;
					})
		}),
	amplitudeVariableId: string()
		.nullable()
		.when('amplitudeType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Variable is a required field')
		}),
	meanType: mixed<WaveformValueType>().required('Mean is a required field'),
	meanValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('meanType', {
			is: WaveformValueType.VALUE,
			then: (schema) => schema.required('Mean value is a required field')
		}),
	meanVariableId: string()
		.nullable()
		.when('meanType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Mean variable is a required field')
		})
});

export const waveformDuoHaversineSchema = object({
	defMode: mixed<WaveformDefinitionMode>().required('Definition mode is a required field'),
	direction: mixed<WaveformDirection>().required('Direction is a required field'),
	upDurationType: mixed<WaveformValueType>().required('Up duration is a required field'),
	upDurationValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('upDurationType', {
			is: WaveformValueType.VALUE,
			then: (schema) =>
				schema
					.required('Up duration value is a required field')
					.positive('Up duration value is a required field')
					.test('upDurationValueCheck', 'Should not exceed Rate limit of the Control', function (upDuration, context) {
						const { amplitudeType, rateLimit, amplitudeValue } = context.parent;
						if (
							amplitudeType === WaveformValueType.VALUE &&
							rateLimit !== null &&
							amplitudeValue !== null &&
							amplitudeValue !== undefined &&
							upDuration
						) {
							const allowedMinValue = (Math.PI * amplitudeValue) / rateLimit;

							return upDuration < allowedMinValue
								? this.createError({
										message: ` The Up Duration value should be greather or equal to “${
											Math.floor(allowedMinValue * 100) / 100
										}”.`
								  })
								: true;
						}
						return true;
					})
		}),
	upDurationVariableId: string()
		.nullable()
		.when('upDurationType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Up duration variable is a required field')
		}),
	downDurationType: mixed<WaveformValueType>().required('Down duration is a required field'),
	downDurationValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('downDurationType', {
			is: WaveformValueType.VALUE,
			then: (schema) =>
				schema
					.required('Down duration value is a required field')
					.positive('Only positive number is accepted')
					.test(
						'downDurationValueCheck',
						'Should not exceed Rate limit of the Control',
						function (downDuration, context) {
							const { amplitudeType, rateLimit, amplitudeValue } = context.parent;
							if (
								amplitudeType === WaveformValueType.VALUE &&
								rateLimit !== null &&
								amplitudeValue !== null &&
								amplitudeValue !== undefined &&
								downDuration
							) {
								const allowedMinValue = (Math.PI * amplitudeValue) / rateLimit;

								return downDuration < allowedMinValue
									? this.createError({
											message: ` The Up Duration value should be greather or equal to “${
												Math.floor(allowedMinValue * 100) / 100
											}”.`
									  })
									: true;
							}
							return true;
						}
					)
		}),
	downDurationVariableId: string()
		.nullable()
		.when('downDurationType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Down duration variable is a required field')
		}),
	amplitudeType: mixed<WaveformValueType>().required('Amplitude is a required field'),
	amplitudeValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('amplitudeType', {
			is: WaveformValueType.VALUE,
			then: (schema) =>
				schema
					.required('Amplitude value is a required field')
					.positive('Only positive number is accepted')
					.test('amplitudeValueCheck', 'Should not exceed Rate limit of the Control', function (ampl, context) {
						const { rateLimit, upDurationValue, downDurationValue } = context.parent;
						if (rateLimit !== null) {
							if (upDurationValue !== null && upDurationValue !== undefined && ampl) {
								const allowedMaxValue = (upDurationValue * rateLimit) / Math.PI;

								return ampl > allowedMaxValue
									? this.createError({
											message: `The Amplitude value should be in the “0-${
												Math.floor(allowedMaxValue * 100) / 100
											}” range`
									  })
									: true;
							}
							if (downDurationValue !== null && downDurationValue !== undefined && ampl) {
								const allowedMaxValue = (downDurationValue * rateLimit) / Math.PI;

								return ampl > allowedMaxValue
									? this.createError({
											message: `The Amplitude value should be in the “0-${
												Math.floor(allowedMaxValue * 100) / 100
											}” range`
									  })
									: true;
							}
						}
						return true;
					})
		}),
	amplitudeVariableId: string()
		.nullable()
		.when('amplitudeType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Amplitude variable is a required field')
		}),
	meanType: mixed<WaveformValueType>().required('Mean is a required field'),
	meanValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('meanType', {
			is: WaveformValueType.VALUE,
			then: (schema) => schema.required('Mean value is a required field')
		}),
	meanVariableId: string()
		.nullable()
		.when('meanType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Mean variable is a required field')
		}),
	numberOfCycles: number()
		.transform(emptyStringToNull)
		.min(1, ({ min }) => `No of cycles must be greater than or equal to ${min}`)
		.max(4294967291)
		.positive('Only positive number is accepted')
		.nullable()
		.required('Number of cycles is a required field')
});

export const waveformTaperedSineSchema = object({
	defMode: mixed<WaveformDefinitionMode>().required('Definition mode is a required field'),
	amplitudeType: mixed<WaveformValueType>().required('Amplitude is a required field'),
	amplitudeValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('amplitudeType', {
			is: WaveformValueType.VALUE,
			then: (schema) =>
				schema
					.required('Amplitude value is a required field')
					.positive('Only positive number is accepted')
					.test('amplitudeValueCheck', 'Should not exceed Rate limit of the Control', function (value, context) {
						const { rateLimit, frequency } = context.parent;
						if (rateLimit !== null && frequency !== null && frequency !== undefined && value) {
							return value <= rateLimit / (2 * Math.PI * frequency);
						}
						return true;
					})
		}),
	amplitudeVariableId: string()
		.nullable()
		.when('amplitudeType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Amplitude variable is a required field')
		}),
	meanType: mixed<WaveformValueType>().required('Mean is a required field'),
	meanValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('meanType', {
			is: WaveformValueType.VALUE,
			then: (schema) => schema.required('Mean value is a required field')
		}),
	meanVariableId: string()
		.nullable()
		.when('meanType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Mean variable is a required field')
		}),
	frequency: number()
		.transform(emptyStringToNull)
		.positive('Only positive number is accepted')
		.required('Frequency is a required field')
		.test('rateLimitCheck', 'Should not exceed Rate limit of the Control', function (value, context) {
			const { amplitudeType, rateLimit, amplitudeValue } = context.parent;
			if (
				amplitudeType === WaveformValueType.VALUE &&
				rateLimit !== null &&
				amplitudeValue !== null &&
				amplitudeValue !== undefined
			) {
				return value <= rateLimit / (2 * Math.PI * amplitudeValue);
			}
			return true;
		})
		.max(1000, ({ max }) => `Frequency must be less than or equal to ${max}`),
	envelopFrequency: number()
		.transform(emptyStringToNull)
		.positive('Only positive number is accepted')
		.max(1000, ({ max }) => `Envelop frequency must be less than or equal to ${max}`)
		.nullable()
		.lessThan(ref('frequency'), ({ less }) => `Envelop frequency must be less than ${less}`)
		.required('Envelop frequency is a required field'),
	numberOfCycles: number()
		.transform(emptyStringToNull)
		.min(1, ({ min }) => `No of cycles must be greater than or equal to ${min}`)
		.max(4294967291)
		.nullable()
		.required('Number of cycles is a required field')
});

export const waveformSquareSineSchema = object({
	defMode: mixed<WaveformDefinitionMode>().required('Definition mode is a required field'),
	direction: mixed<WaveformDirection>().required('Starting Direction is a required field'),
	amplitudeType: mixed<WaveformValueType>().required('Amplitude is a required field'),
	amplitudeValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('amplitudeType', {
			is: WaveformValueType.VALUE,
			then: (schema) =>
				schema.required('Amplitude value is a required field').positive('Only positive number is accepted')
		}),
	amplitudeVariableId: string()
		.nullable()
		.when('amplitudeType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Variable is a required field')
		}),
	meanType: mixed<WaveformValueType>().required('Mean is a required field'),
	meanValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('meanType', {
			is: WaveformValueType.VALUE,
			then: (schema) => schema.required('Mean value is a required field')
		}),
	meanVariableId: string()
		.nullable()
		.when('meanType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Mean variable is a required field')
		}),
	frequency: number()
		.transform(emptyStringToNull)
		.positive('Only positive number is accepted')
		.max(1000, ({ max }) => `Frequency must be less than or equal to ${max}`)
		.nullable()
		.required('Frequency is a required field'),
	numberOfCycles: number()
		.transform(emptyStringToNull)
		.min(1, ({ min }) => `No of cycles must be greater than or equal to ${min}`)
		.max(4294967291)
		.integer()
		.nullable()
		.required('Number of cycles is a required field')
});

export const waveformSweepSineSchema = object({
	defMode: mixed<WaveformDefinitionMode>().required('Definition mode is a required field'),
	amplitudeType: mixed<WaveformValueType>().required('Amplitude is a required field'),
	amplitudeValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('amplitudeType', {
			is: WaveformValueType.VALUE,
			then: (schema) =>
				schema
					.required('Amplitude value is a required field')
					.positive('Only positive number is accepted')
					.test('amplitudeValueCheck', 'Should not exceed Rate limit of the Control', function (value, context) {
						const { rateLimit, startFrequency, endFrequency } = context.parent;
						if (rateLimit !== null && value) {
							return (
								(startFrequency !== null &&
									startFrequency !== undefined &&
									value <= rateLimit / (2 * Math.PI * startFrequency)) ||
								(endFrequency !== null &&
									endFrequency !== undefined &&
									value <= rateLimit / (2 * Math.PI * endFrequency))
							);
						}
					})
		}),
	amplitudeVariableId: string()
		.nullable()
		.when('amplitudeType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Variable is a required field')
		}),
	meanType: mixed<WaveformValueType>().required('Mean is a required field'),
	meanValue: number()
		.transform(emptyStringToNull)
		.nullable()
		.when('meanType', {
			is: WaveformValueType.VALUE,
			then: (schema) => schema.required('Mean value is a required field')
		}),
	meanVariableId: string()
		.nullable()
		.when('meanType', {
			is: WaveformValueType.VARIABLE,
			then: (schema) => schema.required('Variable is a required field')
		}),
	startFrequency: number()
		.transform(emptyStringToNull)
		.positive('Only positive number is accepted')
		.max(1000, ({ max }) => `Start frequency must be less than or equal to ${max}`)
		.nullable()
		.required('Start frequency is a required field')
		.test('rateLimitCheck', 'Should not exceed Rate limit of the Control', function (value, context) {
			const { amplitudeType, rateLimit, amplitudeValue } = context.parent;
			if (
				amplitudeType === WaveformValueType.VALUE &&
				rateLimit !== null &&
				amplitudeValue !== null &&
				amplitudeValue !== undefined
			) {
				return value <= rateLimit / (2 * Math.PI * amplitudeValue);
			}
			return true;
		}),
	endFrequency: number()
		.transform(emptyStringToNull)
		.positive('Only positive number is accepted')
		.max(1000, ({ max }) => `End frequency must be less than or equal to ${max}`)
		.nullable()
		.required('End frequency is a required field')
		.test('rateLimitCheck', 'Should not exceed Rate limit of the Control', function (value, context) {
			const { amplitudeType, rateLimit, amplitudeValue } = context.parent;
			if (
				amplitudeType === WaveformValueType.VALUE &&
				rateLimit !== null &&
				amplitudeValue !== null &&
				amplitudeValue !== undefined
			) {
				return value <= rateLimit / (2 * Math.PI * amplitudeValue);
			}
			return true;
		}),
	phase: number().transform(emptyStringToNull).min(0).max(360).nullable().integer().notRequired(),
	numberOfCycles: number()
		.transform(emptyStringToNull)
		.min(1, ({ min }) => `No of cycles must be greater than or equal to ${min}`)
		.max(4294967291)
		.integer()
		.nullable()
		.required('Number of cycles is a required field')
});

export const waveformSchema = lazy((type?: WaveformType) => {
	if (type === WaveformType.RAMP) return waveformBaseSchema.concat(waveformRampSchema);

	if (type === WaveformType.DUO_RAMP) return waveformBaseSchema.concat(waveformDuoRampSchema);

	if (type === WaveformType.DWELL) return waveformBaseSchema.concat(waveformDwellSchema);

	if (type === WaveformType.CONSTANT) return waveformBaseSchema.concat(waveformConstantSchema);

	if (type === WaveformType.SINE) return waveformBaseSchema.concat(waveformSinewaveForm);

	if (type === WaveformType.HAVER_SINE) return waveformBaseSchema.concat(waveformHaversineSchema);

	if (type === WaveformType.DUO_HAVER_SINE) return waveformBaseSchema.concat(waveformDuoHaversineSchema);

	if (type === WaveformType.TAPERED_SINE) return waveformBaseSchema.concat(waveformTaperedSineSchema);

	if (type === WaveformType.SQUAREWAVE) return waveformBaseSchema.concat(waveformSquareSineSchema);

	return waveformBaseSchema.concat(waveformSweepSineSchema);
});
