export enum AuxiliaryDeviceTableActionTypes {
	EDIT = 'EDIT_STATION_CHANNEL',
	CRETE = 'CRETE_STATION_CHANNEL',
	DUPLICATE = 'DUPLICATE_STATION_CHANNEL',
	DELETE = 'DELETE_STATION_CHANNEL'
}

export enum AuxiliaryDeviceType {
	MECHANICAL_LIMITS = 0,
	PHYSICAL_E_STOP
}

export enum AuxiliaryDeviceStatus {
	ENABLED = 0,
	DISABLED
}

export enum AuxiliaryDeviceDirection {
	UP = 0,
	DOWN
}
