import React, { useEffect, useMemo } from 'react';
import { Dropdown, InputNumber, DropdownOptionString, GroupInputFrame, InputText } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, Controller } from 'react-hook-form';
import { CalculationFormType } from '../../calculation.types';
import { IListItemNumber } from '../../../../types';
import { MeasurementResponseDto } from '../../../Measurements';
import { CalculationChannelTypes, StatisticalCalcRanges } from '../../calculation.enums';
import { STATISTICAL_TYPES_LIST } from '../../calculation.const';
import { TestMeasurementResponseDto } from '../../../TestMeasurements';

interface StatisticalCalculationProps {
	measurements?: (MeasurementResponseDto | TestMeasurementResponseDto)[];
	measurementsAndAxes: DropdownOptionString[];
	quantities: DropdownOptionString[];
	statisticalCalcRanges: IListItemNumber[];
	form: UseFormReturn<CalculationFormType>;
}

const StatisticalCalculation: React.FC<StatisticalCalculationProps> = ({
	measurements,
	statisticalCalcRanges,
	quantities,
	measurementsAndAxes,
	form
}) => {
	const { t } = useTranslation('calculation');
	const {
		control,
		formState: { errors },
		watch,
		setValue
	} = form;
	const type = watch('type');
	const measurementOrAxesId1 = watch('measurementOrAxesId1');
	const measurementOrAxesId2 = watch('measurementOrAxesId2');
	const isCustomRange = watch('statCalcRange') === StatisticalCalcRanges.CUSTOM;
	const isStatistical = useMemo(() => STATISTICAL_TYPES_LIST.some((st) => st === type), [type]);

	const startValUnit = useMemo(() => {
		return measurements?.find((m) => m.id === measurementOrAxesId1)?.unit;
	}, [measurementOrAxesId1, measurements]);
	const startValUnitName = startValUnit?.name || '';

	useEffect(() => {
		setValue('startValUnit', startValUnit?.id);
	}, [startValUnit, setValue]);

	const endValUnit = useMemo(() => {
		return measurements?.find((m) => m.id === measurementOrAxesId2)?.unit;
	}, [measurementOrAxesId2, measurements]);
	const endValUnitName = endValUnit?.name || '';

	useEffect(() => {
		setValue('endValUnit', endValUnit?.id);
	}, [endValUnit, setValue]);

	const visibleQuantities = useMemo(() => {
		const currentMeasurement = measurementOrAxesId1
			? measurements?.find((m) => m.id === measurementOrAxesId1)
			: undefined;
		return currentMeasurement ? quantities?.filter((q) => q.value === currentMeasurement.unit.quantity) : quantities;
	}, [measurementOrAxesId1, measurements, quantities]);

	// Reset type specific values after unmount values
	useEffect(() => {
		return () => {
			setValue('statCalcRange', undefined);
			setValue('measurementOrAxesId1', undefined);
			setValue('startVal', undefined);
			setValue('measurementOrAxesId2', undefined);
			setValue('endVal', undefined);
			setValue('unit', undefined);
		};
	}, [setValue]);

	useEffect(() => {
		if (isStatistical) {
			const isMeasurement = !!measurements?.find((m) => m.id === measurementOrAxesId1)
				? CalculationChannelTypes.MEASUREMENT
				: CalculationChannelTypes.CYCLE;
			setValue('ch1Type', isMeasurement);
		}
	}, [isStatistical, measurementOrAxesId1, measurements, setValue]);

	useEffect(() => {
		if (isStatistical) {
			const isMeasurement = !!measurements?.find((m) => m.id === measurementOrAxesId2)
				? CalculationChannelTypes.MEASUREMENT
				: CalculationChannelTypes.CYCLE;
			setValue('ch2Type', isMeasurement);
		}
	}, [isStatistical, measurementOrAxesId2, measurements, setValue, type]);

	return (
		<>
			<Controller
				name="statCalcRange"
				control={control}
				render={({ field }) => (
					<Dropdown
						{...field}
						options={statisticalCalcRanges}
						label={t('Range*')}
						error={errors.statCalcRange?.message}
						data-testid="statCalcRange"
						filter
					/>
				)}
			/>
			{isCustomRange && (
				<>
					<Controller
						name="measurementOrAxesId1"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={measurementsAndAxes}
								label={t('Start Channel*')}
								error={errors.measurementOrAxesId1?.message}
								data-testid="measurementOrAxesId1"
								filter
							/>
						)}
					/>
					<GroupInputFrame>
						<Controller
							name="startVal"
							control={control}
							render={({ field }) => (
								<InputNumber
									{...field}
									label={t('Start Value*')}
									error={errors.startVal?.message}
									data-testid="startVal"
								/>
							)}
						/>
						<InputText disabled value={startValUnitName} />
					</GroupInputFrame>

					<Controller
						name="measurementOrAxesId2"
						control={control}
						render={({ field }) => (
							<Dropdown
								{...field}
								options={measurementsAndAxes}
								label={t('End Channel*')}
								error={errors.measurementOrAxesId1?.message}
								data-testid="measurementOrAxesId1"
								filter
							/>
						)}
					/>
					<GroupInputFrame>
						<Controller
							name="endVal"
							control={control}
							render={({ field }) => (
								<InputNumber {...field} label={t('End Value*')} error={errors.endVal?.message} data-testid="endVal" />
							)}
						/>
						<InputText disabled value={endValUnitName} />
					</GroupInputFrame>
				</>
			)}
			<Controller
				name="unit"
				control={control}
				render={({ field }) => (
					<Dropdown
						data-testid="unit"
						{...field}
						options={visibleQuantities}
						label={t('Unit*')}
						error={errors.unit?.message}
						filter
					/>
				)}
			/>
		</>
	);
};

export default StatisticalCalculation;
