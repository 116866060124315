import { TFunction } from 'i18next';
import { DataLoggingTypes } from './testDataLogging.enums';
import { Converter } from '../../types';
import { DataChannelType } from '../DataChannel';
import {
	TestDataLoggingFormType,
	TestDataLoggingListItem,
	TestDataLoggingRequestDto,
	TestDataLoggingResponseDto
} from './testDataLogging.types';
import { StageTypes } from '../Tests/tests.enums';
import { AxisLog, ChannelType, LoggingPeriodic, LoggingPnV, LoggingTimed, TypedId } from '@tactun/grpc-client';
import { convertToSeconds } from '../Units/units.tool';

export const responseListItemConverter = (input: TestDataLoggingResponseDto, t: TFunction): TestDataLoggingListItem => {
	const channels = [
		...(input.measurements?.length > 0
			? input.measurements.map((m) => `${m.name} ${t(DataChannelType[DataChannelType.MEASUREMENT])}`)
			: []),
		...(input.cycleAxes?.length > 0
			? input.cycleAxes.map((a) => `${a.name} ${t(DataChannelType[DataChannelType.CYCLE])}`)
			: []),
		...(input.spAxes?.length > 0
			? input.spAxes.map((a) => `${a.name} ${t(DataChannelType[DataChannelType.SET_POINT])}`)
			: []),
		...(input.compSpAxes?.length > 0
			? input.compSpAxes.map((a) => `${a.name} ${t(DataChannelType[DataChannelType.COMP_SET_POINT])}`)
			: [])
	];
	const channelsName = channels.join(', ');

	return {
		id: input.id,
		name: input.name,
		channels: channelsName,
		type: DataLoggingTypes[input.dataLoggingType]
	};
};

export const responseFormConverter: Converter<TestDataLoggingResponseDto, TestDataLoggingFormType> = (input) => {
	const dataChannels = [
		...input.measurements.map((m) => ({ id: m.id, type: DataChannelType.MEASUREMENT })),
		...input.cycleAxes.map((a) => ({ id: a.id, type: DataChannelType.CYCLE })),
		...input.spAxes.map((a) => ({ id: a.id, type: DataChannelType.SET_POINT })),
		...input.compSpAxes.map((a) => ({ id: a.id, type: DataChannelType.COMP_SET_POINT }))
	];
	return {
		id: input.id,
		name: input.name,
		dur: input.dur,
		interval: input.interval,
		rate: input.rate,
		stageId: input.stageId,
		testId: input.testId,
		stageType: input.stageType,
		dataChannels: dataChannels,
		dataLoggingType: input.dataLoggingType,
		durationUnitId: input.durationUnit?.id,
		intervalUnitId: input.intervalUnit?.id,
		timeUnits: [],
		extremumTypes: input.extremumTypes,
		refAxisId: input.refAxis?.id
	};
};

export const formRequestConverter: Converter<TestDataLoggingFormType, TestDataLoggingRequestDto> = (input) => {
	const measurementIds = input.dataChannels
		.filter((dc) => dc.type === DataChannelType.MEASUREMENT && dc.id !== 'undefined')
		.map((dc) => dc.id);
	const spAxisIds = input.dataChannels
		.filter((dc) => dc.type === DataChannelType.SET_POINT && dc.id !== 'undefined')
		.map((dc) => dc.id);
	const compSpAxisIds = input.dataChannels
		.filter((dc) => dc.type === DataChannelType.COMP_SET_POINT && dc.id !== 'undefined')
		.map((dc) => dc.id);
	const cycleAxisIds = input.dataChannels
		.filter((dc) => dc.type === DataChannelType.CYCLE && dc.id !== 'undefined')
		.map((dc) => dc.id);

	return {
		name: input.name,
		stageId: input.stageId,
		testId: input.testId,
		dataLoggingType: input.dataLoggingType,
		stageType: input.stageType as StageTypes,
		rate: input.rate || undefined,
		interval: input.interval || undefined,
		dur: input.dur || undefined,
		measurementIds,
		spAxisIds,
		cycleAxisIds,
		compSpAxisIds,
		durationUnitId: input.durationUnitId,
		intervalUnitId: input.intervalUnitId,
		extremumTypes: input.extremumTypes,
		refAxisId: input.refAxisId
	};
};

export const testDataLoggingResponseDtoToAxisLogGRPCConverter: Converter<TestDataLoggingResponseDto, AxisLog> = (
	log
) => {
	const axisLog = new AxisLog();
	axisLog.setRtype(log.dataLoggingType);

	log.measurements.forEach((m) => {
		const typedId = new TypedId();
		typedId.setChannelId(m.id);
		typedId.setType(ChannelType.MEASUREMENT);

		axisLog.addTypedid(typedId);
	});

	log.cycleAxes.forEach((cycle) => {
		const typedId = new TypedId();
		typedId.setChannelId(cycle.id);
		typedId.setType(ChannelType.CYCLE);

		axisLog.addTypedid(typedId);
	});

	log.spAxes.forEach((setPoint) => {
		const typedId = new TypedId();
		typedId.setChannelId(setPoint.id);
		typedId.setType(ChannelType.SET_POINT);

		axisLog.addTypedid(typedId);
	});

	log.compSpAxes.forEach((compSetPoint) => {
		const typedId = new TypedId();
		typedId.setChannelId(compSetPoint.id);
		typedId.setType(ChannelType.COMP_SET_POINT);

		axisLog.addTypedid(typedId);
	});

	if (log.dataLoggingType === DataLoggingTypes.TIMED) {
		const timed = new LoggingTimed();
		if (log.rate !== undefined) timed.setRate(log.rate);
		axisLog.setTimed(timed);
	}

	if (log.dataLoggingType === DataLoggingTypes.PERIODIC) {
		const periodic = new LoggingPeriodic();
		if (log.interval !== undefined && log.intervalUnit)
			periodic.setInterval(convertToSeconds(log.interval, log.intervalUnit) * 1_000_000);
		if (log.dur !== undefined && log.intervalUnit)
			periodic.setDuration(convertToSeconds(log.dur, log.intervalUnit) * 1_000_000);
		if (log.rate !== undefined) periodic.setRate(log.rate);

		axisLog.setPeriodic(periodic);
	}

	if (log.dataLoggingType === DataLoggingTypes.PEAK_VALLEY) {
		const pnv = new LoggingPnV();
		if (log.extremumTypes !== undefined) pnv.setExtremum(log.extremumTypes);
		if (log.refAxis !== undefined) pnv.setRefAxisId(log.refAxis.id);

		axisLog.setPnv(pnv);
	}
	return axisLog;
};

export const testDataLoggingResponseDtoListToAxisLogGRPCConverter: Converter<
	TestDataLoggingResponseDto[],
	AxisLog[]
> = (resp) => {
	return resp.map((r) => testDataLoggingResponseDtoToAxisLogGRPCConverter(r));
};
