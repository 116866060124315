import axios from '../../httpCommon';
import { GroupsRequestDto, GroupsResponseDto, UngroupRequestDto } from './group.types';

const url = '/quasar/groups';

export const getGroupsByType = async (type: string) => {
	const response = await axios.get<GroupsResponseDto[]>(`${url}/type/${type}`);

	return response.data;
};

export const createGroup = async (group: GroupsRequestDto) => {
	const response = await axios.post<GroupsResponseDto>(url, group);
	return response.data;
};

export const updateGroup = async (group: GroupsRequestDto, id: string) => {
	const response = await axios.put<GroupsResponseDto>(`${url}/${id}`, group);
	return response.data;
};

export const deleteGroup = async (id: string) => {
	const response = await axios.delete<string>(`${url}/${id}`);
	return response.data;
};

export const ungroupByItemIds = async (group: UngroupRequestDto) => {
	const response = await axios.put<GroupsResponseDto>(`${url}/${group.id}/remove`, group);
	return response.data;
};
