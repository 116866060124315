import React from 'react';
import { Button } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import styles from './ConfigureStationBottomBar.module.scss';

export interface IConfigureStationBottomBarProps {
	onConfigure?: () => void;
	showConfigured?: boolean;
	isNotUpToDate?: boolean;
	isConfiguring?: boolean;
	isConfigureDisabled?: boolean;
}

const ConfigureStationBottomBar: React.FC<IConfigureStationBottomBarProps> = ({
	onConfigure,
	isNotUpToDate,
	isConfigureDisabled,
	isConfiguring,
	showConfigured = false
}) => {
	const { t } = useTranslation();

	return (
		<>
			{showConfigured ? (
				<Button
					icon="t-icon-configuration_applied"
					className={cx(styles.configureBtn, {
						[styles.btnNotClickable]: !isNotUpToDate,
						[styles.btnOutdated]: isNotUpToDate
					})}
					label={t(isNotUpToDate ? 'Configuration outdated' : 'Configuration applied')}
					data-testid="configAppliedBtn"
					variant="text"
					color={isNotUpToDate ? 'danger' : 'success'}
					onClick={isNotUpToDate ? onConfigure : undefined}
				/>
			) : (
				<Button
					icon="t-icon-configure"
					className={styles.configureBtn}
					label={isConfiguring ? t('Applying...') : t('Apply configuration')}
					data-testid="applyConfigureBtn"
					variant="text"
					color="secondary"
					disabled={isConfigureDisabled || isConfiguring}
					onClick={onConfigure}
					loading={isConfiguring}
				/>
			)}
		</>
	);
};
export default ConfigureStationBottomBar;
