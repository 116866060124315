import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Subject, Subscription } from 'rxjs';
import { StationStatus } from '@tactun/grpc-client';
import { IDevice } from './connection.types';
import { grpc } from '@improbable-eng/grpc-web';

export interface ConnectionState {
	connectedDevice: IDevice | null;
	connectionRef: grpc.Request | null;
	stationsSubject: Subject<StationStatus.AsObject[]>;
	subscriptions: Subscription[];
	addSubscription: (subscription: Subscription) => void;
	removeSubscription: (subscription: Subscription) => void;
	setConnectionRef: (ref: grpc.Request | null) => void;
	setConnectedDevice: (connectedDevice: IDevice | null) => void;
}

export interface ManuallyAddedDevicesState {
	devices: IDevice[];
	addDevice: (device: IDevice) => void;
	removeDevice: (device: IDevice) => void;
	updateDevice: (device: IDevice) => void;
}

export interface SystemStatusSubscription {
	subscription: Subscription;
	key: string;
}

export const useConnectionStore = create<ConnectionState>((set) => ({
	connectedDevice: null,
	connectionRef: null,
	stationsSubject: new Subject<StationStatus.AsObject[]>(),
	subscriptions: [],
	setConnectionRef: (connectionRef) => set({ connectionRef }),
	setConnectedDevice: (connectedDevice) => set({ connectedDevice }),
	addSubscription: (subscription) => set((state) => ({ subscriptions: [...state.subscriptions, subscription] })),
	removeSubscription: (subscription) =>
		set((state) => ({
			// unsubscribe and remove subscription
			subscriptions: state.subscriptions.filter((s) => {
				if (s === subscription) {
					s.unsubscribe();
					return false;
				}
				return true;
			})
		}))
}));

export const useManuallyAddedDevicesStore = create<
	ManuallyAddedDevicesState,
	[['zustand/persist', ManuallyAddedDevicesState]]
>(
	persist(
		(set) => {
			return {
				devices: [],
				addDevice: (device: IDevice) =>
					set((state) => {
						const devicesCopy = state.devices.map((d) => ({ ...d }));
						devicesCopy.push(device);

						return { devices: devicesCopy };
					}),
				removeDevice: (device: IDevice) =>
					set((state) => {
						let devicesCopy = state.devices.map((d) => ({ ...d }));
						devicesCopy = devicesCopy.filter((d) => d.id !== device.id);

						return { devices: devicesCopy };
					}),
				updateDevice: (device: IDevice) =>
					set((state) => {
						const devicesCopy = state.devices.map((d) => (d.id === device.id ? device : { ...d }));

						return { devices: devicesCopy };
					})
			};
		},
		{
			name: 'manually-added-devices-storage'
		}
	)
);
