import { FormEvent, memo, useMemo } from 'react';
import { PageLayout, Button, BottomBar, InfoBadge, InfoBadgeIcons } from '@tactun/ui';
import { useTranslation } from 'react-i18next';
import { specimenCreateEditFormId } from '../../specimens.const';
import SpecimenFormContainer from '../../containers/SpecimenFormContainer';

interface SpecimensProps {
	isCreate: boolean;
	isLoading: boolean;
	onBack: () => void;
	onSave: (event: FormEvent<HTMLFormElement>) => void;
}

const Specimens: React.FC<SpecimensProps> = ({ isCreate, isLoading, onBack, onSave }) => {
	const { t } = useTranslation('specimens');

	const title = useMemo<string>(() => {
		return isCreate ? t('Create a Specimen') : t('Edit a Specimen');
	}, [isCreate, t]);

	return (
		<>
			<PageLayout info={t('Specimen list')}>
				<PageLayout.Header title={title} onBack={onBack} />
				<SpecimenFormContainer onSave={onSave} isForDashboard={false} />
			</PageLayout>
			<BottomBar>
				{isLoading && (
					<InfoBadge data-testid="progressInfo" icon={InfoBadgeIcons.loading}>
						{t('Saving...')}
					</InfoBadge>
				)}
				<Button label={t('Cancel')} data-testid="cancelBtn" variant="contained" color="success" onClick={onBack} />
				<Button
					label={t('Save')}
					data-testid="saveBtn"
					variant="contained"
					color="secondary"
					type="submit"
					form={specimenCreateEditFormId}
				/>
			</BottomBar>
		</>
	);
};

export default memo(Specimens);
