import { useRef, useMemo, useCallback } from 'react';
import { grpc } from '@improbable-eng/grpc-web';
import { ruleService, Error as GrpcError } from '@tactun/grpc-client';
import { UnaryOutput } from '@improbable-eng/grpc-web/dist/typings/unary';
import { StationRuleResponseDto } from './rules.types';
import { rulesToRuleApplyConverter } from './rules.converters';
import { tError } from '../../tools/logger';

export interface ControlStageError {
	code: string;
	message: string;
}

export interface ControlStageData {
	data: number;
}

export const useExecuteRules = (stationId?: string) => {
	const grpcRequestRef = useRef<grpc.Request>();

	const execute = useCallback(
		(rules: StationRuleResponseDto[]) => {
			if (process.env.REACT_APP_DEVICE_URL && stationId) {
				const request = rulesToRuleApplyConverter(rules, stationId);
				const myTransport = grpc.CrossBrowserHttpTransport({ withCredentials: false });

				grpcRequestRef.current = grpc.unary(ruleService.WebRules.Apply, {
					request: request,
					host: process.env.REACT_APP_DEVICE_URL,
					transport: myTransport,
					onEnd: (output: UnaryOutput<GrpcError>) => {
						if (output.status !== grpc.Code.OK) {
							tError(
								`GRPC stream end code: "${output.status.toString()}" \nGRPC stream end message: "${output.message}" \n `
							);
						}
					}
				});
			}
		},
		[stationId]
	);

	return useMemo(() => {
		return { execute };
	}, [execute]);
};
