import { ISubframeForm } from './customWidget.types';
import { WidgetsLayoutTypes } from './customWidget.enums';
import { FrameOrientation } from '../ButtonWidget/buttonWidget.enums';
// import { ButtonForm } from '../ButtonWidget';

export const getNewProportion = (proportion: number, oldSize: number, newSize: number) => {
	return Math.floor(((oldSize * proportion) / 100 / newSize) * 100);
};

// const calculateWidth = (button: ButtonForm): number => {
// 	const widths = [48];
// 	if (button.pressedText) {
// 		const textLength = button.pressedText.length;
// 		const fontSize = 14;
//
// 		widths.push(textLength * fontSize + 24 + (button.pressedIcon ? 24 : 0));
// 	}
// 	if (button.releasedText) {
// 		const textLength = button.releasedText.length;
// 		const fontSize = 14;
//
// 		widths.push(textLength * fontSize + 24 + (button.releasedIcon ? 24 : 0));
// 	}
//
// 	return Math.max(...widths);
// };

export const getMinSizes = (
	subFrames?: ISubframeForm[],
	subFrameSize?: [number, number],
	layoutType?: WidgetsLayoutTypes
): {
	minWidth: number;
	minHeight: number;
	subFrameWidth?: [number, number];
	subFrameHeight?: [number, number];
} => {
	const width = subFrameSize?.[0] || 100;
	const height = subFrameSize?.[1] || 100;
	const h1 = height ? height / 100 : 1;
	const h2 = 1 - h1;
	const w1 = width ? width / 100 : 1;
	const w2 = 1 - w1;

	const minMaxes =
		subFrames?.map((subFrame) => {
			const buttonMinMaxes =
				subFrame.buttons?.map((button) => {
					const isSimetric = false; //button.shape === ButtonShape.ROUND;
					const width = 48; //calculateWidth(button);

					return {
						minWidth: Math.max(width, 48),
						minHeight: isSimetric ? Math.max(width, 48) : 48
					};
				}, []) || [];

			const minWidth = buttonMinMaxes.length ? Math.max(...buttonMinMaxes.map(({ minWidth: w }) => w)) : 48;
			const minHeight = buttonMinMaxes.length ? Math.max(...buttonMinMaxes.map(({ minHeight: h }) => h)) : 48;

			return {
				minWidth:
					subFrame.orientation === FrameOrientation.VERTICAL || !subFrame.numberOfButtons
						? minWidth
						: minWidth * subFrame.numberOfButtons,
				minHeight:
					subFrame.orientation === FrameOrientation.VERTICAL && subFrame.numberOfButtons
						? minHeight * subFrame.numberOfButtons
						: minHeight
			};
		}) || [];

	if (layoutType === WidgetsLayoutTypes.DOUBLE_VERTICAL && minMaxes.length === 2) {
		return {
			minWidth: Math.max(...minMaxes.map(({ minWidth }) => minWidth)),
			minHeight: Math.max(minMaxes[0].minHeight / h1, minMaxes[1].minHeight / h2),
			subFrameHeight: [minMaxes[0].minHeight, minMaxes[1].minHeight]
		};
	}
	if (layoutType === WidgetsLayoutTypes.DOUBLE_HORIZONTAL && minMaxes.length === 2) {
		return {
			minWidth: Math.max(minMaxes[0].minWidth / w1, minMaxes[1].minWidth / w2),
			minHeight: Math.max(...minMaxes.map(({ minHeight }) => minHeight)),
			subFrameWidth: [minMaxes[0].minWidth, minMaxes[1].minWidth]
		};
	}

	if (layoutType === WidgetsLayoutTypes.QUADRO && minMaxes.length === 4) {
		return {
			minWidth: Math.max(
				minMaxes[0].minWidth / w1,
				minMaxes[2].minWidth / w1,
				minMaxes[1].minWidth / w2,
				minMaxes[3].minWidth / w2
			),
			minHeight: Math.max(
				minMaxes[0].minHeight / h1,
				minMaxes[1].minHeight / h1,
				minMaxes[2].minHeight / h2,
				minMaxes[3].minHeight / h2
			),
			subFrameWidth: [
				Math.max(minMaxes[0].minWidth, minMaxes[2].minWidth),
				Math.max(minMaxes[1].minWidth, minMaxes[3].minWidth)
			],
			subFrameHeight: [
				Math.max(minMaxes[0].minHeight, minMaxes[1].minHeight),
				Math.max(minMaxes[2].minHeight, minMaxes[3].minHeight)
			]
		};
	}
	return minMaxes[0] || { minWidth: 48, minHeight: 48 };
};
