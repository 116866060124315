import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { TestLoopRequestDto, TestLoopResponseDto } from './testLoops.types';
import * as api from './testLoops.api';

export const useTestLoops = (testId?: string, onSaveSuccess?: () => void) => {
	const queryKey = useMemo(() => ['test-loops', { testId }], [testId]);
	const { data, isLoading } = useQuery<TestLoopResponseDto[], Error>({
		queryKey,
		// When we provide enable option - !!stationId, the stationId is guaranteed
		queryFn: () => api.getTestLoops(testId as string),
		enabled: !!testId
	});
	const queryClient = useQueryClient();

	const addNewItem = useCallback(() => {
		queryClient.invalidateQueries({ queryKey });
	}, [queryClient, queryKey]);

	const updateItem = useCallback(() => {
		queryClient.invalidateQueries({ queryKey });
	}, [queryClient, queryKey]);

	const createMutation = useMutation({
		mutationFn: (data: { testLoop: TestLoopRequestDto; testId: string }) =>
			api.createTestLoop(data.testLoop, data.testId),
		onSuccess: () => {
			addNewItem();
			if (onSaveSuccess) {
				onSaveSuccess();
			}
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const updateMutation = useMutation({
		mutationFn: (data: { testLoop: TestLoopRequestDto; testLoopId: string }) =>
			api.updateTestLoop(data.testLoop, data.testLoopId),
		onSuccess: () => {
			updateItem();
			if (onSaveSuccess) {
				onSaveSuccess();
			}
		},
		onError: (e: Error) => toast.error(e.message)
	});

	const saveTestLoop = useCallback(
		(data: TestLoopRequestDto, testLoopId?: string) => {
			if (testLoopId) {
				updateMutation.mutate({
					testLoop: data,
					testLoopId
				});
			} else {
				if (testId) {
					createMutation.mutate({ testLoop: data, testId });
				}
			}
		},
		[createMutation, testId, updateMutation]
	);

	const deleteTestLoop = useCallback(
		(id: string) => {
			queryClient.invalidateQueries({ queryKey });
		},
		[queryClient, queryKey]
	);

	return useMemo(
		() => ({
			isLoading,
			isCreating: createMutation.isPending,
			isUpdating: updateMutation.isPending,
			loops: data,
			saveTestLoop,
			deleteTestLoop
		}),
		[isLoading, createMutation.isPending, updateMutation.isPending, data, saveTestLoop, deleteTestLoop]
	);
};

export const useTestLoop = (loopId?: string) => {
	const { data: testLoop, isLoading: isTestLoopLoading } = useQuery<TestLoopResponseDto>({
		queryKey: ['test-loops', { loopId }],
		queryFn: () => api.getTestLoop(loopId as string),
		enabled: !!loopId
	});

	return {
		testLoop,
		isTestLoopLoading
	};
};
