import React from 'react';
import TestVariablesModal from '../TestVariablesModal';
import TestVariablesContainer from '../../containers/TestVariablesContainer';

interface ITestVariablesProps {
	testId?: string;
}

const TestVariables: React.FC<ITestVariablesProps> = ({ testId }) => {
	return (
		<TestVariablesModal>
			<TestVariablesContainer testId={testId} />
		</TestVariablesModal>
	);
};

export default React.memo(TestVariables);
