import React, { forwardRef, memo } from 'react';
import { SelectInstance } from 'react-select';
import styles from './LayoutDropdown.module.scss';
import { DropdownProps } from '@tactun/ui/src/components/FormElements/Dropdown';
import { Dropdown } from '@tactun/ui';
import { WidgetsLayoutTypes } from '../../customWidget.enums';
import LayoutDropdownOption from './LayoutDropdownOption';
import { useNumberEnumList } from '../../../../hooks';

const LayoutDropdown = forwardRef(
	(props: DropdownProps<number, false>, ref: React.ForwardedRef<SelectInstance<number, false>>) => {
		const layoutTypes = useNumberEnumList(WidgetsLayoutTypes, 'widgets');

		return (
			<Dropdown
				{...props}
				ref={ref}
				options={layoutTypes}
				panelClassName={styles.dropdownPanel}
				OptionComponent={LayoutDropdownOption}
			/>
		);
	}
);

export default memo(LayoutDropdown);
