import axios from '../../httpCommon';
import { IImageUploadResponse } from './imageUploader.types';

export const uploadImage = async (data: FormData): Promise<IImageUploadResponse> => {
	const response = await axios.post('/quasar/image/upload', data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});

	return response.data;
};
